import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActionTypeEnum } from '@modules/business-data/business-data.model';
import { GoogleDrivePickerService } from './google-drive-picker.service';

@Component({
  selector: 'df-google',
  templateUrl: './google.component.html',
  styleUrl: './google.component.scss',
})
export class GoogleComponent implements AfterViewInit {
  type = ActionTypeEnum.GOOGLE_DRIVE;
  @Input() form!: FormGroup;

  constructor(
    public googleDrivePickerService: GoogleDrivePickerService,
    private changes: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.googleDrivePickerService.setForm(this.form);
    this.googleDrivePickerService.gapiLoaded();
    this.googleDrivePickerService.gisLoaded();

    this.googleDrivePickerService.updateView.subscribe(() => this.changes.detectChanges());
  }

  handleAuthClick() {
    this.googleDrivePickerService.handleAuthClick();
  }

  removeField(url: string) {
    this.googleDrivePickerService.removeField(url);
  }
}
