import { gql } from 'apollo-angular';

export const GetIntegrationsCategoriesDocument = gql`
  query getIntegrationsCategories {
    getIntegrationsCategories {
      id
      name
    }
  }
`;
