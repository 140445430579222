import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { Funnel } from '@shared/models/funnel.model';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { Flow } from '@shared/models/flow.model';
import {
  FunnelStatisticsOutputGraphql,
  FunnelStatusOutputGraphql,
  MarkActivityDoneInputGraphql,
} from '@modules/graphql/graphql-types';
import { FlowItem } from '@shared/models/flow-item.model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { FunnelViewMode } from '@modules/funnels/shared/enums/funnel-view-mode.enum';
import { map } from 'rxjs/operators';
import { FunnelTab } from '@modules/funnels/shared/enums/funnel-tab.enum';
import { Step } from '@shared/models/step.model';
import { UntypedFormControl } from '@angular/forms';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { FunnelGeneratePdfModalComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-generate-pdf-modal/funnel-generate-pdf-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { FetchResult } from '@apollo/client/core';
import { GetFunnelStatisticsQuery } from '@modules/funnels/shared/graphql/queries/get-funnel-statistics.query.generated';
import { Activity } from '@shared/models/activity.model';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';

@Injectable({
  providedIn: 'root',
})
export class FunnelManageService {
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();
  private _funnel?: Funnel;
  private _funnelTactics: {
    [stepId: number]: { [funnelTacticId: number]: FunnelTactic };
  } = {};

  currentFunnel$: BehaviorSubject<Funnel | null> = new BehaviorSubject<Funnel | null>(null);
  funnel$: EventEmitter<Funnel> = new EventEmitter<Funnel>();
  funnelTactics$: EventEmitter<{
    [stepId: number]: { [funnelTacticId: number]: FunnelTactic };
  }> = new EventEmitter<{
    [stepId: number]: { [funnelTacticId: number]: FunnelTactic };
  }>();

  private readonly _viewMode$: BehaviorSubject<FunnelViewMode | null> = new BehaviorSubject<FunnelViewMode | null>(
    null,
  );
  readonly viewMode$: Observable<FunnelViewMode | null> = this._viewMode$.asObservable();
  private readonly _currentTab$: BehaviorSubject<FunnelTab | null> = new BehaviorSubject<FunnelTab | null>(null);
  readonly currentTab$: Observable<FunnelTab | null> = this._currentTab$.asObservable();

  readonly searchFunnelTactic: UntypedFormControl = new UntypedFormControl('');
  readonly expandAll: UntypedFormControl = new UntypedFormControl(false);
  readonly expandSummary: UntypedFormControl = new UntypedFormControl(true);
  canManage$: Observable<boolean> = of(true);

  get detailsMode$(): Observable<boolean> {
    return combineLatest([this.viewMode$, this.currentTab$]).pipe(
      map(
        ([viewMode, currentTab]) =>
          (viewMode === FunnelViewMode.DETAILS || viewMode === FunnelViewMode.ONBOARDING) &&
          currentTab !== FunnelTab.CONTENT_GENERATE &&
          currentTab !== FunnelTab.CONTENT_GENERATOR_LIBRARY,
      ),
    );
  }

  getTacicsCount() {
    return this.funnel?.tactics?.length ?? 0;
  }

  isSelectedTab(tab: FunnelTab): Observable<boolean> {
    return this.currentTab$.pipe(map((currentTab) => currentTab === tab));
  }

  set funnelTactics(funnelTactics: { [key: number]: { [key: number]: FunnelTactic } }) {
    this._funnelTactics = funnelTactics;
    this.funnelTactics$.emit(this._funnelTactics);
  }

  get funnelTactics(): {
    [stepId: number]: { [funnelTacticId: number]: FunnelTactic };
  } {
    return this._funnelTactics;
  }

  set funnel(funnel: Funnel | undefined) {
    this._funnel = new Funnel(funnel);
    if (funnel) {
      this.setFunnelTactics();
      this.funnel$.emit(funnel); // TODO: remove me and use currentFunnel$ Subject only
      this.currentFunnel$.next(funnel);
    }
  }

  set loading(loading: boolean) {
    this._loading$.next(loading);
  }

  set viewMode(viewMode: FunnelViewMode) {
    this._viewMode$.next(viewMode);
  }

  set currentTab(currentTab: FunnelTab) {
    this._currentTab$.next(currentTab);
  }

  get funnel(): Funnel | undefined {
    return this._funnel;
  }

  constructor(
    private tacticGraphqlService: TacticGraphqlService,
    private funnelGraphqlService: FunnelGraphqlService,
    private t: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  generatePDF(logo: string) {
    return this.funnelGraphqlService.generatePDF(this._funnel!.id, logo);
  }

  setFunnelTactics() {
    this.funnel?.tactics.map((funnelTactic: FunnelTactic) => {
      // if step exists
      if (this.funnelTactics[funnelTactic.step.id]) {
        this.funnelTactics[funnelTactic.step.id][funnelTactic.id] = funnelTactic;
      } else {
        this.funnelTactics[funnelTactic.step.id] = {
          [funnelTactic.id]: funnelTactic,
        };
      }
    });
  }

  setFunnelStepSummaries() {
    this.funnelGraphqlService
      .getFunnelStatistics(this.funnel!.id)
      .subscribe((res: FetchResult<GetFunnelStatisticsQuery>) => {
        this.funnel!.summary = res.data ? (res.data.getFunnelStatistics as FunnelStatisticsOutputGraphql[]) : [];
        this.funnel!.summary.map((s: FunnelStatisticsOutputGraphql) => {
          this.funnel!.summaryStepMap.set(s.step.id, s);
        });
        this.funnel$.emit(this.funnel);
      });
  }

  clearAllActiveFunnelTacticAndFlows() {
    this.clearConnections();
    this.clearHighlightForFunnelTactics();
  }

  clearConnections() {
    this.funnel?.flows.map((f: Flow) => {
      f.items = f.items.map((flowItem: FlowItem) => {
        flowItem.connection = undefined;
        return flowItem;
      });
      f.highlighted = false;
      return f;
    });
  }

  clearHighlightForFunnelTactics() {
    if (this.funnel) {
      this.funnel.tactics = this.funnel!.tactics.map((funnelTactic: FunnelTactic) => {
        funnelTactic.isHighlighted = false;
        return funnelTactic;
      });
    }
    this.funnel$.emit(this.funnel);
  }

  highlightFunnelTacticForFlow(flow: Flow) {
    this.funnel!.tactics = this.funnel!.tactics.map((funnelTactic: FunnelTactic) => {
      funnelTactic.isHighlighted = flow.highlighted && flow.tacticIds?.includes(funnelTactic.id);
      return funnelTactic;
    });
    this.funnel$.emit(this.funnel);
  }

  getHighlightFlow(): Flow | null {
    return this.funnel?.flows.filter((f) => f.highlighted)[0] || null;
  }

  recalculateStatsInFunnel() {
    const statsPerStep: { [stepId: number]: FunnelStatusOutputGraphql } = {};
    this.funnel!.stats = this.funnel!.stats.map((stat: FunnelStatusOutputGraphql) => {
      stat.allCount = 0;
      stat.doneCount = 0;
      statsPerStep[stat.step.id] = stat;
      return stat;
    });
    Object.values(this.funnelTactics).map((obj: { [funnelTacticId: number]: FunnelTactic }) => {
      Object.values(obj).map((funnelTactic: FunnelTactic) => {
        funnelTactic.isDone ? statsPerStep[funnelTactic.step.id].doneCount++ : '';
        statsPerStep[funnelTactic.step.id].allCount++;
        statsPerStep[funnelTactic.step.id].step = funnelTactic.step;
      });
    });
    this.funnel!.stats = Object.values(Object.values(statsPerStep));
    this.funnel?.setStatsToMap();
    this.funnel$.emit(this.funnel);
  }

  sortedFunnelTactics(step: Step): FunnelTactic[] {
    if (!this.funnelTactics[step.id]) {
      return [];
    }
    let funnelTacticsArr = Object.values(this.funnelTactics[step.id]) as FunnelTactic[];
    funnelTacticsArr = funnelTacticsArr.sort((a: FunnelTactic, b: FunnelTactic) => {
      return a.position - b.position;
    });
    return funnelTacticsArr;
  }

  exportToPDF(): void {
    this.dialogService
      .open<number>(new PolymorpheusComponent(FunnelGeneratePdfModalComponent, this.injector), {
        size: 'l',
        dismissible: true,
        closeable: true,
        label: this.t.instant('Funnels.Manage.Export funnel to PDF'),
      })
      .subscribe();
  }

  markDoneActivities(funnelTactic: FunnelTactic): void {
    funnelTactic.tactic.activities
      .filter((activity) => funnelTactic.doneActivities.some((done) => done.activity.id === activity.id))
      .forEach((activity) => (activity.done = true));
  }

  markActivityDone(activity: Activity, funnelTactic: FunnelTactic) {
    activity.done = !activity.done;
    activity.done
      ? funnelTactic.doneActivities.push({
          id: -1,
          activity: { id: activity.id } as Activity,
        })
      : funnelTactic.doneActivities.splice(
          funnelTactic.doneActivities.findIndex((item) => item.activity.id === activity.id)!,
          1,
        );
    this.markDoneActivities(funnelTactic);
    const funnel = this.funnel!;
    funnel.doneActivitiesCount += activity.done ? 1 : -1;
    this.funnel = funnel;
    const input: MarkActivityDoneInputGraphql = {
      funnelTacticId: funnelTactic.id,
      activityId: activity.id,
      isDone: activity.done,
    };
    this.tacticGraphqlService.markActivityDone(input).toPromise();
    return funnelTactic;
  }

  clearSelection(): void {
    this.expandSummary.setValue(true);
    this.expandAll.setValue(false);
  }

  clearService() {
    this.funnel = undefined;
    this.funnelTactics = {};
    this.clearSelection();
  }
}
