import { gql } from 'apollo-angular';

export const GetAssetsResolutionsDocument =
  gql(/* GraphQL*/ `query getAssetsResolutions($input: AssetsResolutionsListInputGraphql!)
{
  getAssetsResolutions(input: $input)
  {
    id
    width
    height
  }
}

`);
