<tui-island
  [size]="'s'"
  class="h-full p-6 relative"
>
  <img
    class="absolute bottom-0 right-0"
    *ngIf="icon"
    [src]="icon"
    alt=""
  />
  <div class="w-full h-full flex flex-col justify-between">
    <div class="flex items-end flex-wrap">
      <span class="tui-text_h1 tui-primary font-light m-0">{{ done }}</span>
      <span class="tui-text_h5 tui-text-05 pb-0.5">/&nbsp;{{ todo }}</span>
    </div>
    <div>
      <h5 class="tui-text_body-m-1 font-semibold md:text-xl tui-text-03 mt-4">
        {{ title }}
      </h5>
      <p
        *ngIf="done > 0"
        class="text-sm md:text-base tui-text-05 font-medium mt-2"
        [class.tui-primary]="done > 0"
      >
        {{
          done === 0
            ? subtitle
            : done === todo
              ? ('Funnels.Overview.Well done!' | translate)
              : ('Funnels.Overview.Keep it going!' | translate)
        }}
      </p>
    </div>
  </div>
</tui-island>
