<form
  *ngIf="activityControl"
  [formGroup]="activityControl"
>
  <tui-select
    class="activity-type"
    [valueContent]="preview"
    [formControlName]="EActivityForm.typeId"
    [tuiTextfieldLabelOutside]="true"
  >
    {{ 'Tactics.Manage.Activity type' | translate }}
    <tui-data-list-wrapper
      *tuiDataList
      [items]="activityTypes$ | async"
      [itemContent]="listContent"
    ></tui-data-list-wrapper>
  </tui-select>
  <ng-template
    #listContent
    let-item
  >
    <div class="flex items-center">
      <img
        [src]="Config.ASSETS + item.image"
        class="mr-2 w-6 h-6"
        alt=""
        *ngIf="item.image"
      />
      <span>{{ item.name }}</span>
    </div>
  </ng-template>
  <ng-template
    #preview
    let-item
  >
    <div class="flex items-center">
      <img
        [src]="Config.ASSETS + item.image"
        class="mr-2 w-6 h-6"
        alt=""
        *ngIf="item.image"
      />
      <div class="flex flex-col">
        <span class="uppercase tui-text-04 tui-text_body-xs font-bold">{{
          'Tactics.Manage.Activity type' | translate
        }}</span>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </ng-template>
</form>
