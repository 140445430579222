import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { FunnelManageFlowDataModalComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-manage-flow-data-modal/funnel-manage-flow-data-modal.component';
import { TuiButtonComponent, TuiDialogService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { FetchResult } from '@apollo/client/core';
import { EditFlowMutation } from '@modules/funnels/shared/graphql/mutations/edit-flow.mutation.generated';
import { Flow } from '@shared/models/flow.model';
import { FunnelCancelConfirmFlowModalComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-cancel-confirm-flow-modal/funnel-cancel-confirm-flow-modal.component';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { FunnelPermissionEnum, PermissionType } from '@modules/graphql/graphql-types';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { FunnelCreateTacticModalComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-create-tactic-modal/funnel-create-tactic-modal.component';
import { ActivatedRoute } from '@angular/router';
import { FunnelViewMode } from '@modules/funnels/shared/enums/funnel-view-mode.enum';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { ContentGeneratorRouteParam } from '@modules/funnels/shared/enums/content-generator-route-param.enum';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { FunnelStepsService } from '@shared/services/funnel-steps.service';

@Component({
  selector: 'df-funnel-manage-content-sub-header',
  templateUrl: './funnel-manage-content-sub-header.component.html',
  styleUrls: ['./funnel-manage-content-sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageContentSubHeaderComponent implements OnInit, OnDestroy {
  FunnelPermissionEnum = FunnelPermissionEnum;
  readonly EOnboardingStep = EOnboardingStep;

  @Input() empty = true;

  sub: Subscription = new Subscription();
  removingLoading = false;
  isSharedMode = false;
  loading = false;

  @HostBinding('class.editMode')
  get isEditMode() {
    return this.funnelFlowManageService.editMode;
  }

  @ViewChild('editButton') editButton!: TuiButtonComponent;

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler() {
    if (this.funnelFlowManageService.editMode) {
      this.turnOffEditModeFlow();
    }
  }

  constructor(
    public basePanelService: BasePanelService,
    private changes: ChangeDetectorRef,
    public funnelManageService: FunnelManageService,
    public funnelFlowManageService: FunnelFlowManageService,
    private funnelGraphqlService: FunnelGraphqlService,
    private funnelStepsService: FunnelStepsService,
    private s: SnackbarService,
    private t: TranslateService,
    public n: NavigateService,
    private route: ActivatedRoute,
    private readonly pricingService: PricingService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    public readonly onboardingService: OnboardingService,
  ) {}

  ngOnInit(): void {
    this.changes.reattach();
    this.listenShortNavChange();
    this.listenFlowEditMode();
    this.isDetailsMode();
    this.listenUpdates();
    this.listenLoading();
    this.listenOnboardingStepChange();
  }

  private listenUpdates() {
    const sub = combineLatest([this.funnelFlowManageService.editMode$, this.funnelManageService.funnel$]).subscribe(
      () => {
        this.changes.detectChanges();
      },
    );
    this.sub.add(sub);
  }

  private listenLoading() {
    this.sub.add(
      this.funnelManageService.loading$.subscribe((val: boolean) => {
        this.loading = val;
        this.changes.detectChanges();
      }),
    );
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  isDetailsMode(): void {
    this.isSharedMode = this.route.snapshot.data['type'] === FunnelViewMode.SHARED;
  }

  listenShortNavChange() {
    const sub = this.basePanelService.emitter.subscribe(() => {
      this.changes.markForCheck();
    });
    this.sub.add(sub);
  }

  listenFlowEditMode() {
    const sub = this.funnelFlowManageService.editMode$.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  editFlowName() {
    this.dialogService
      .open<number>(new PolymorpheusComponent(FunnelManageFlowDataModalComponent, this.injector), {
        size: 's',
        dismissible: true,
        label: this.t.instant('Funnels.Manage.Edit flow name'),
      })
      .subscribe();
  }

  removeFlow() {
    this.removingLoading = true;
    this.changes.detectChanges();
    this.funnelManageService.funnel!.flows = this.funnelManageService.funnel!.flows.filter(
      (f) => f.id !== this.funnelFlowManageService.flow!.id,
    );
    this.funnelManageService.funnel$.emit(this.funnelManageService.funnel);
    this.funnelGraphqlService.removeFlow(this.funnelFlowManageService.flow!.id).subscribe(() => {
      this.removingLoading = false;
      this.s.success(this.t.instant('Funnels.Manage.Flow successfully removed'));
      this.changes.detectChanges();
    });
    this.funnelFlowManageService.editMode = false;
  }

  saveFlow() {
    this.funnelFlowManageService
      .saveItemsInFlow()
      .subscribe({
        next: (res: FetchResult<EditFlowMutation>) => {
          this.s.success(this.t.instant('Funnels.Manage.Flow saved properly'));
          this.funnelManageService.funnel!.flows = this.funnelManageService.funnel!.flows.map((f: Flow) => {
            if (f.id === res.data?.editFlow.id) {
              f = new Flow(res.data.editFlow);
            }
            return f;
          });
          this.funnelFlowManageService.editMode = false;
        },
        error: () => {
          this.s.error(this.t.instant('Funnels.Manage.There is problem with saving flow. Try again.'));
        },
      })
      .add(() => {
        this.funnelFlowManageService.loading = false;
        this.funnelFlowManageService.loading$.emit(false);
        this.changes.detectChanges();
      });
  }

  turnOffEditModeFlow() {
    this.dialogService
      .open<number>(new PolymorpheusComponent(FunnelCancelConfirmFlowModalComponent, this.injector), {
        size: 's',
        dismissible: true,
        closeable: true,
        label: this.t.instant('Funnels.Manage.Unsaved changes'),
      })
      .subscribe();
  }

  exportToPDF(): void {
    this.funnelManageService.exportToPDF();
  }

  goToContentGenerate(): void {
    if (this.onboardingService.onboardingRunning) {
      this.onboardingService.currentStep?.nextStep(() => {
        this.onboardingService._currentStepIndex = 6;
        const firstTactic: FunnelTactic = this.funnelManageService.sortedFunnelTactics(
          this.funnelStepsService.steps[0],
        )[0];
        this.n.go(
          'funnels/f/onboarding/content-generator',
          {},
          {
            queryParams: {
              [ContentGeneratorRouteParam.TACTIC]: firstTactic.id,
              [ContentGeneratorRouteParam.ACTIVITY]: firstTactic.tactic.activities[0].id,
              [ContentGeneratorRouteParam.ASSET]: firstTactic.tactic.activities[0]?.assets[0]?.id,
            },
          },
        );
      });
    } else {
      this.pricingService.checkPricing(PermissionType.AiCopywriting).subscribe(() =>
        this.n.go('funnels/f/d/:id/content-generator', {
          id: this.funnelManageService.funnel?.id,
        }),
      );
    }
  }

  createTactic(): void {
    this.dialogService
      .open<number>(new PolymorpheusComponent(FunnelCreateTacticModalComponent, this.injector), {
        size: 'm',
        dismissible: true,
        closeable: true,
      })
      .subscribe();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
