import {
  RecommendationOutputGraphql,
  StepOutputGraphql,
  TacticRecommendationOutputGraphql,
} from '@modules/graphql/graphql-types';
import { Funnel } from '@shared/models/funnel.model';
import { Tactic } from '@shared/models/tactic.model';

export class TacticRecommendation implements TacticRecommendationOutputGraphql {
  recommendations!: Recommendation[];
  step!: StepOutputGraphql;
}

export class Recommendation implements RecommendationOutputGraphql {
  id!: number;
  funnel!: Funnel;
  points!: number;
  tactic!: Tactic;
  usedInFunnel!: boolean;
  step?: StepOutputGraphql;

  constructor(data?: any, step?: StepOutputGraphql) {
    data ? Object.assign(this, data) : '';
    this.tactic = new Tactic(data?.tactic);
    this.step = step as StepOutputGraphql;
  }
}
