import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { GraphicsOutputMetadata, IntegrationOutputData } from '@shared/models/integration.model';
import FileSaver from 'file-saver';
import { Config } from '@shared/configs/config';
import { SaveLibraryAssetInputGraphql } from '@modules/graphql/graphql-types';
import { AssetsService } from '@modules/assets/shared/assets.service';
import { UserService } from '@shared/services/user.service';
import { dataURLtoFileOrBlob } from '@shared/helpers/base64-to-file.helper';
import file2Base64 from '@shared/helpers/file-to-base64.helper';

@Component({
  selector: 'df-graphics-generator-item-tile',
  templateUrl: './graphics-generator-item-tile.component.html',
  styleUrls: ['./graphics-generator-item-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphicsGeneratorItemTileComponent extends AbstractSubscriptionComponent {
  readonly Config = Config;
  readonly graphicsDataEnum = GraphicsOutputMetadata;
  @Input() data: IntegrationOutputData | null = null;
  @Input() size: 'm' | 'l' = 'l';
  loading = true;

  constructor(
    public readonly changes: ChangeDetectorRef,
    private http: HttpClient,
    private assetsService: AssetsService,
    private userService: UserService,
  ) {
    super();
  }

  finishLoading(): void {
    this.loading = false;
    this.changes.detectChanges();
  }

  async copyToAssetLibrary() {
    const context = sessionStorage.getItem('last-funnel-marketing-generated-config')
      ? JSON.parse(sessionStorage.getItem('last-funnel-marketing-generated-config')!)
      : [];
    const file = await dataURLtoFileOrBlob(this.data?.value['data'], {
      filename: 'image',
    });
    const b64 = await file2Base64(file as File);

    const params: SaveLibraryAssetInputGraphql = {
      integration: 'abyssales',
      funnelId: this.userService.User!.contextFunnel.id,
      context,
      content: b64,
    };
    this.assetsService.saveLibraryAsset(params).subscribe();
  }

  download(): void {
    // handle Unsplash api trigger download link
    // eslint-disable-next-line no-prototype-builtins
    if (this.data?.metadata!.hasOwnProperty(this.graphicsDataEnum.UNSPLASH_TRIGGER_LINK)) {
      const triggerLink = `${this.data?.metadata![this.graphicsDataEnum.UNSPLASH_TRIGGER_LINK]}&client_id=${Config.API_UNSPLASH_ACCESS_KEY}`;
      const sub = this.http.get(triggerLink).subscribe();
      this.sub.add(sub);
    }
    FileSaver.saveAs(
      this.data?.value['data'],
      `${this.data?.metadata![this.graphicsDataEnum.TYPE]}_${this.data?.metadata![this.graphicsDataEnum.SIZE]}`,
    );
  }
}
