/* eslint-disable @angular-eslint/no-output-native */
import { Observable, Subject, Subscription } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnDestroy,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { TuiInputTagComponent, TuiMultiSelectComponent, TuiSelectComponent } from '@taiga-ui/kit';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { TuiContextWithImplicit, TuiIdentityMatcher, TuiStringHandler } from '@taiga-ui/cdk';
import { Apollo } from 'apollo-angular';
import { FetchResult } from '@apollo/client/core';

@Component({
  template: '',
})
export class BaseCustomSelectComponent implements OnInit, AfterViewInit, OnDestroy {
  sub: Subscription = new Subscription();

  @Input() defaultItems: any[] = [];
  @Input() query?: any;
  @Input() variables?: any;
  @Input() controlName!: string;
  @Input() disabled = false;
  @Input() isCleaner = false;
  @Input() formGroup!: UntypedFormGroup;
  @Input() maxItems?: number;
  @Input() size: TuiSizeS | TuiSizeL = 'm';

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('component', { static: true }) component!:
    | TuiMultiSelectComponent<any>
    | TuiInputTagComponent
    | TuiSelectComponent<any>;

  readonly search$: Subject<string> = new Subject<string>();
  readonly items$: Observable<ReadonlyArray<any> | null> = this.search$.pipe(
    filter((value) => value !== null),
    switchMap((search) => this.serverRequest(search).pipe(startWith<ReadonlyArray<any> | null>(null))),
  );
  readonly stringify: TuiStringHandler<
    { id: number; name: string } | TuiContextWithImplicit<{ id: number; name: string }>
  > = (item) => ('name' in item ? item.name : item.$implicit.name);

  readonly identityMatcher: TuiIdentityMatcher<{ id: number; name: string }> = (obj1, obj2) => obj1.id === obj2.id;

  constructor(
    public apollo: Apollo,
    public changes: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.listenFormChanges();
    this.listenItemsSelected();
  }

  ngAfterViewInit() {
    this.search$.next('');
  }

  listenItemsSelected() {
    if (!this.maxItems) return;
    const control = this.formGroup.get(this.controlName);
    const sub = control?.valueChanges.subscribe(() => {
      const arr = control?.value || [];
      const length = arr.length;
      if (length > this.maxItems! && Array.isArray(arr)) {
        arr.shift();
        control?.patchValue(arr);
      }
    });
    this.sub.add(sub);
  }

  listenFormChanges() {
    const sub = this.items$.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  detectChanges() {
    this.changes.detectChanges();
    this.component.nativeFocusableElement?.click();
  }

  onSearchChange(searchQuery: string | null): void {
    if (searchQuery === null) {
      return;
    }
    this.search$.next(searchQuery);
  }

  private serverRequest(searchQuery: string): Observable<ReadonlyArray<any>> {
    return this.apollo
      .query<any>({
        query: this.query,
        variables: {
          input: {
            search: searchQuery,
            records: 100,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map(this.transformResponse.bind(this)));
  }

  private transformResponse(res: FetchResult<any>): ReadonlyArray<any> {
    let records: any[] = [];

    if (res.data) {
      Object.values(res.data).map((arr) => {
        if (Array.isArray((arr as { records: any[]; total: number }).records)) {
          records = (arr as { records: any[]; total: number }).records.concat(records);
        }
      });
      return records;
    }
    return [];
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
