import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { TacticController } from '@modules/tactics/shared/controllers/tactic.controller';
import { TacticSettingsViewModeEnum } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-settings-view-mode.enum';
import { ActivatedRoute } from '@angular/router';
import { AdminTokenService } from '@shared/services/admin-token.service';
import { MixpanelEventName, MixpanelService } from '@shared/services/mixpanel.service';

@Component({
  selector: 'df-tactic-settings-header',
  templateUrl: './tactic-settings-header.component.html',
  styleUrls: ['./tactic-settings-header.component.scss'],
})
export class TacticSettingsHeaderComponent extends AbstractSubscriptionComponent implements OnInit {
  loading = false;
  anyChanges = false;
  isEditMode = false;

  @Input() isPublic = false;

  constructor(
    public tacticSettingsService: TacticSettingsService,
    private changes: ChangeDetectorRef,
    public n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
    private route: ActivatedRoute,
    private adminTokenService: AdminTokenService,
    private mixpanelService: MixpanelService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenChanges();
    this.listenLoading();
    this.listenViewMode();
  }

  private listenChanges(): void {
    this.sub.add(
      this.tacticSettingsService.changesInForm$.subscribe((changes) => {
        this.anyChanges = changes;
        this.changes.detectChanges();
      }),
    );
  }

  private listenLoading(): void {
    this.sub.add(
      this.tacticSettingsService.loading$.subscribe((loading) => {
        this.loading = loading;
        this.changes.detectChanges();
      }),
    );
  }

  private listenViewMode(): void {
    this.sub.add(
      this.tacticSettingsService.viewMode$.subscribe((viewMode) => {
        this.isEditMode = viewMode === TacticSettingsViewModeEnum.EDIT;
        this.changes.detectChanges();
      }),
    );
  }

  cancel(): void {
    if (this.isEditMode || this.isPublic) {
      this.n.go('tactics/preview/p/:id', {
        id: this.tacticSettingsService.tactic?.id,
      });
    } else {
      this.n.go('/tactics/list/all');
    }
  }

  save(): void {
    this.tacticSettingsService.formGroup!.markAllAsTouched();
    this.tacticSettingsService.updateFormControls();
    if (!this.tacticSettingsService.formGroup!.valid) return;
    this.removeMarkedAsRemoveFromEditors();
    if (this.isEditMode || this.isPublic) {
      this.saveTactic();
    } else {
      this.createTactic();
    }

    this.mixpanelService.trackEvent(MixpanelEventName.ButtonClick, {
      time: Date.now(),
      URL: location.href,
      button_name: 'save',
    });
  }

  /**
   * Remove elements inside Quill editor marked with class "remove"
   */
  removeMarkedAsRemoveFromEditors() {
    const editors = document.querySelectorAll('.ql-editor');
    editors.forEach((editor) => {
      const toRemove = editor.querySelectorAll('.customEmbed.remove, .mention.remove');
      toRemove.forEach((v) => {
        v.replaceWith('');
      });
    });
  }

  saveTactic(): void {
    this.tacticSettingsService.editTactic().subscribe({
      next: (isSuccess: boolean) => {
        if (isSuccess) {
          this.tacticSettingsService._changesInForm$.next(false);
          this.s.success(this.t.instant('Tactics.Settings.tactic saved'));
          if (this.isPublic) {
            this.n.router.navigate(['.'], {
              relativeTo: this.route,
              queryParams: { auth: this.adminTokenService.token },
            });
          } else {
            const tacticController = new TacticController();
            tacticController.preview(this.tacticSettingsService.tactic!.id);
          }
        } else {
          this.s.error(this.t.instant('Tactics.Settings.tactic save error'));
        }
      },
      error: (err) => console.log(err),
    });
  }

  createTactic(): void {
    this.tacticSettingsService.createTactic().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.tacticSettingsService._changesInForm$.next(false);
        this.s.success(this.t.instant('Tactics.Settings.tactic created'));
        const tacticController = new TacticController();
        tacticController.preview(this.tacticSettingsService.tactic!.id);
      } else {
        this.s.error(this.t.instant('Tactics.Settings.tactic create error'));
      }
    });
  }
}
