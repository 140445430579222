import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Config } from '@shared/configs/config';
import { GraphicsOutputMetadata, IntegrationOutputData } from '@shared/models/integration.model';
import FileSaver from 'file-saver';
import { AiDesignGraphicTileSize } from '../../enums/ai-design-graphic-tile-size.enum';
import { convertImageToBase64 } from '@shared/helpers/image-to-base64.helper';
import { AiDesignUploadedFile } from '../../../pages/funnel-panel/components/funnel-graphics/components/funnel-graphics-ai-design-form/funnel-graphics-ai-design-form.model';
import { AssetsService } from '@modules/assets/shared/assets.service';
import { UserService } from '@shared/services/user.service';
import { SaveLibraryAssetInputGraphql } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-funnel-graphic-ai-design-tile',
  templateUrl: './funnel-graphic-ai-design-tile.component.html',
  styleUrls: ['./funnel-graphic-ai-design-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelGraphicAiDesignTileComponent {
  @Input() data: IntegrationOutputData | null = null;
  @Input() size: AiDesignGraphicTileSize = AiDesignGraphicTileSize.LARGE;
  @Input() hideDownloadButton = false;

  @Output() imagePreview: EventEmitter<IntegrationOutputData> = new EventEmitter<IntegrationOutputData>();
  @Output() fileAddedEvent: EventEmitter<AiDesignUploadedFile> = new EventEmitter<AiDesignUploadedFile>();

  readonly Config = Config;
  readonly graphicsDataEnum = GraphicsOutputMetadata;

  loading = true;

  constructor(
    public readonly changes: ChangeDetectorRef,
    private assetsService: AssetsService,
    private userService: UserService,
  ) {}

  finishLoading(): void {
    this.loading = false;
    this.changes.detectChanges();
  }

  addToAssetLibrary() {
    const context = sessionStorage.getItem('last-funnel-ai-design-generated-config')
      ? JSON.parse(sessionStorage.getItem('last-funnel-ai-design-generated-config')!)
      : [];

    const params: SaveLibraryAssetInputGraphql = {
      integration: this.data?.value['isVideo'] ? 'stablediffusionvideo' : 'stablediffusion',
      funnelId: this.userService.User!.contextFunnel.id,
      content: this.data?.value['data'] ?? '',
      context,
    };

    this.assetsService.saveLibraryAsset(params).subscribe();
  }

  download(): void {
    const url: string = this.data?.value['data'];
    if (!url) return;
    FileSaver.saveAs(url, this.extractFileNameFromUrl(url));
  }

  generate() {
    convertImageToBase64(this.data?.value['data'], (data) =>
      this.fileAddedEvent.emit({
        base64: data?.base64?.replace(/^data:image\/[a-z]+;base64,/, '')!,
        width: data.width,
        height: data.height,
      }),
    );
  }

  private extractFileNameFromUrl(url: string): string {
    if (!url) return url;
    const lastIndex: number = url.lastIndexOf('/');
    return url.substring(lastIndex + 1, url.length);
  }

  openImagePreview(): void {
    this.imagePreview.emit(this.data!);
  }

  get buttonSize() {
    return this.size === AiDesignGraphicTileSize.LARGE ? 's' : 'xs';
  }

  get iconSize() {
    return this.size === AiDesignGraphicTileSize.LARGE
      ? { width: '1rem', height: '1rem' }
      : { width: '0.75rem', height: '0.75rem' };
  }
}
