import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SEOService } from '@core/services/seo.service';
import { environment } from '@environments/environment';
import { swiperFunnelConfig } from '@modules/funnels/modules/funnel-manage/pages/funnel-manage/components/funnel-manage-content/swiper-funnel-config.const';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { FunnelTacticOutputGraphql } from '@modules/graphql/graphql-types';
import { TacticPreviewPopupComponent } from '@modules/tactics/modules/tactic-preview/pages/tactic-preview-popup/tactic-preview-popup.component';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';

import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { Funnel } from '@shared/models/funnel.model';
import { Step } from '@shared/models/step.model';
import { FunnelStepsService } from '@shared/services/funnel-steps.service';
import { UserService } from '@shared/services/user.service';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { BehaviorSubject } from 'rxjs';
import { SwiperComponent } from 'swiper/angular';
import { PublicFunnelSendEmailModalComponent } from './components/public-funnel-send-email-modal/public-funnel-send-email-modal.component';
import { SnackbarService } from '@core/services/snackbar.service';

@Component({
  selector: 'df-public-funnel',
  templateUrl: './public-funnel.component.html',
  styleUrls: ['./public-funnel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicFunnelComponent extends AbstractSubscriptionComponent implements OnInit {
  @ViewChild('swiperComponent') swiperComponent!: SwiperComponent;
  @Input() inCampaign = false;

  swiperFunnelConfig = swiperFunnelConfig;
  steps: Step[] = [];
  modals = [];
  funnel!: Funnel;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly dialogTacticsPreview;
  private readonly dialogSendEmail;

  constructor(
    public funnelStepsService: FunnelStepsService,
    public funnelManageService: FunnelManageService,
    public funnelFlowManageService: FunnelFlowManageService,
    public n: NavigateService,
    private route: ActivatedRoute,
    private funnelGraphqlService: FunnelGraphqlService,
    private changes: ChangeDetectorRef,
    private userService: UserService,
    private seoService: SEOService,
    private s: SnackbarService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {
    super();
    this.dialogTacticsPreview = this.dialogService.open<number>(
      new PolymorpheusComponent(TacticPreviewPopupComponent, this.injector),
      {
        dismissible: true,
      },
    );

    this.dialogSendEmail = this.dialogService.open<number>(
      new PolymorpheusComponent(PublicFunnelSendEmailModalComponent, this.injector),
      {
        dismissible: true,
      },
    );
  }

  ngOnInit(): void {
    this.loading$.next(true);

    this.sub.add(
      this.funnelStepsService.getSteps().subscribe((steps) => {
        this.steps = steps;
        this.changes.detectChanges();
      }),
    );

    let funnelId = this.inCampaign ? this.route?.snapshot.params['funnelId'] : this.route.snapshot.params['id'];
    if (!funnelId) funnelId = this.route.parent?.snapshot.params['id'];

    this.sub.add(
      this.funnelGraphqlService.getPublicFunnelById(Number(funnelId)).subscribe((funnelData) => {
        this.funnel = new Funnel(funnelData.data?.getPublicFunnelById);
        this.funnelManageService.funnel = new Funnel(this.funnel);

        this.generateCustomPromptOutput(this.funnel?.tactics as FunnelTacticOutputGraphql[]);

        this.seoService.updateTitle(this.funnel?.name ?? 'Digital First Funnel');
        this.seoService.addOpengraphTags(`${environment.ASSETS}opengraph/funnel-${this.funnel?.id}`);
        this.changes.detectChanges();
      }),
    );

    if (!this.userService?.User && !this.inCampaign) {
      setTimeout(() => this.showTacticsPreviewDialog(), 10000);
    }
  }
  async generateCustomPromptOutput(tactics: FunnelTacticOutputGraphql[]) {
    this.loading$.next(false);
  }

  async addFunnelToClientLibrary() {
    //If has account and user login
    if (this.userService?.User?.id) {
      this.funnelGraphqlService.cloneFunnel(this.funnel.id).subscribe((res) => {
        this.s.success('Funnel add to your library');
        this.n.go(`/funnels/f/d/${res.data?.cloneFunnel.id}/manage`);
      });
    } else {
      // if user dont have account or not login
      this.funnelGraphqlService.cloneFunnelForAnonymousUser(this.funnel.id).subscribe((res) => {
        localStorage.setItem(
          'dfai-anonymous-user',
          JSON.stringify({
            funnelId: res.data?.cloneFunnelForAnonymousUser.id,
          }),
        );
      });

      this.n.go('/sign-up');
    }
  }

  showEmailDialog() {
    this.dialogSendEmail.subscribe();
  }

  showTacticsPreviewDialog(): void {
    if (location.href.includes('public/funnel')) this.dialogTacticsPreview.subscribe();
  }

  sortedFunnelTactics(step: Step): FunnelTactic[] {
    if (!this.funnelManageService?.funnelTactics[step.id]) {
      return [];
    }
    let funnelTacticsArr = Object.values(this.funnelManageService.funnelTactics[step.id]) as FunnelTactic[];
    funnelTacticsArr = funnelTacticsArr.sort((a: FunnelTactic, b: FunnelTactic) => {
      return a.position - b.position;
    });

    return funnelTacticsArr;
  }
}
