import { gql } from 'apollo-angular';
import { FunnelFragment } from '@modules/funnels/shared/graphql/fragments/funnel.fragment';

export const SetFunnelToOwnerDocument = gql`
  mutation setFunnelToOwner($funnelId: Int!) {
    setFunnelToOwner(funnelId: $funnelId) {
      ...Funnel
    }
  }
  ${FunnelFragment}
`;
