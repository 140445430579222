import { gql } from 'apollo-angular';

export const CreateAyrshareAccountDocument = gql`
  mutation createAyrshareAccount {
    createAyrshareAccount {
      status
      profileKey
      title
    }
  }
`;
