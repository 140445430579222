import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Input, Output } from '@angular/core';
import { Config } from '@shared/configs/config';
import { ActivityBenchmark } from '@shared/models/activity-benchmark.model';
import { BenchmarkTypeEnum } from '@modules/graphql/graphql-types';
import FileSaver from 'file-saver';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ActivityBenchmarkPreviewModalComponent } from '../activity-benchmark-preview-modal/activity-benchmark-preview-modal.component';
import { TuiDialogService } from '@taiga-ui/core';
import { Regex } from '@shared/configs/regex';

@Component({
  selector: 'df-activity-benchmark-item-preview',
  templateUrl: './activity-benchmark-item-preview.component.html',
  styleUrls: ['./activity-benchmark-item-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityBenchmarkItemPreviewComponent {
  readonly Config = Config;
  readonly Regex = Regex;
  readonly BenchmarkTypeEnum = BenchmarkTypeEnum;

  @Input() benchmark!: ActivityBenchmark;
  @Input() editMode = false;
  @Input() disabled = false;
  @Input() removeLoading = false;

  @Output() benchmarkRemoved: EventEmitter<ActivityBenchmark> = new EventEmitter<ActivityBenchmark>();
  @Output() benchmarkRestored: EventEmitter<ActivityBenchmark> = new EventEmitter<ActivityBenchmark>();
  @Output() onBenchmarkEdit: EventEmitter<ActivityBenchmark> = new EventEmitter<ActivityBenchmark>();

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private dialogService: TuiDialogService,
  ) {}

  get viewLink() {
    let viewLink = this.benchmark.link;

    if (this.benchmark.link) {
      viewLink = this.benchmark.link?.replace('http://', '').replace('https://', '');
    }
    return viewLink;
  }

  removeBenchmark(): void {
    this.benchmarkRemoved.emit(this.benchmark);
  }

  restoreBenchmark(): void {
    this.benchmarkRestored.emit(this.benchmark);
  }

  editBenchmark(): void {
    this.onBenchmarkEdit.emit(this.benchmark);
  }

  downloadAttachment(): void {
    this.benchmark.attachment
      ? FileSaver.saveAs(Config.ASSETS + this.benchmark.attachment!.file, this.benchmark.attachment!.name)
      : FileSaver.saveAs(this.benchmark.file!, this.benchmark.file?.name);
  }

  openBenchmarkModal(): void {
    if (this.benchmark.type.type === BenchmarkTypeEnum.File) {
      if (
        Regex.fileImage.test(this.benchmark.attachment?.file!) ||
        Regex.fileVideo.test(this.benchmark.attachment?.file!)
      ) {
        this.dialogService
          .open<number>(new PolymorpheusComponent(ActivityBenchmarkPreviewModalComponent, this.injector), {
            data: {
              benchmark: this.benchmark,
            },
            dismissible: false,
            closeable: false,
            size: 'm',
          })
          .subscribe();
      }
    } else {
      window.open(this.benchmark.link, '_blank');
    }
  }
}
