import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractRegisterComponent } from '@modules/authorization/shared/abstracts/abstract-register.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { RegisterAppSumoMutation } from '@modules/authorization/shared/graphql/mutations/register-app-sumo.mutation.generated';
import { AuthOutputGraphql, SocialLoginInputGraphql } from '@modules/graphql/graphql-types';
import { ValidateAppSumoTokenQuery } from '@modules/authorization/shared/graphql/queries/validate-app-sumo-token.query.generated';
import { UntypedFormControl } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { AppSumoErrorEnum } from '@modules/authorization/pages/app-sumo-error/app-sumo-error.enum';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { GraphqlErrorEnum } from '@modules/graphql/enums/graphql-error.enum';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { MergeAppSumoAccountModalComponent } from '@modules/authorization/pages/app-sumo-register/components/merge-app-sumo-account-modal/merge-app-sumo-account-modal.component';
import { TuiDialogService } from '@taiga-ui/core';
import { filter } from 'rxjs/operators';
import { UserService } from '@shared/services/user.service';
import { LoginWithSocialMediaAccountMutation } from '@modules/authorization/shared/graphql/mutations/ login-with-social-media-account.mutation.generated';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';

@Component({
  selector: 'df-app-sumo-register',
  templateUrl: './app-sumo-register.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSumoRegisterComponent extends AbstractRegisterComponent<RegisterAppSumoMutation> implements OnInit {
  validationLoading = true;
  registerFieldsHidden = false;

  constructor(
    recaptchaService: ReCaptchaV3Service,
    changes: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authGraphqlService: AuthGraphqlService,
    private router: Router,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    private userService: UserService,
    protected eventService: EventsService,
    protected funnelService: FunnelGraphqlService,
  ) {
    super(changes, recaptchaService, funnelService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkToken();
  }

  private checkToken(): void {
    const token: string = this.route.snapshot.queryParams['token'];
    if (!token) {
      this.n.go(
        'appsumo-invalid',
        {},
        {
          skipLocationChange: true,
        },
      );
    } else {
      this.authGraphqlService.validateAppSumoToken(token).subscribe(
        (res: FetchResult<ValidateAppSumoTokenQuery>) => {
          this.formGroup.addControl('appSumoToken', new UntypedFormControl(token));
          this.getControl('email')?.setValue(res.data?.validateAppSumoToken.appSumoEmail);
          this.validationLoading = false;
          this.changes.detectChanges();
        },
        () => {
          this.n.go(
            'appsumo-invalid',
            {},
            {
              skipLocationChange: true,
              queryParams: { error: AppSumoErrorEnum.INVALID_TOKEN },
            },
          );
        },
      );
    }
  }

  protected getRegisterRequest(token: string): Observable<FetchResult<RegisterAppSumoMutation>> {
    return this.authGraphqlService.registerAppSumo({ ...this.formGroup.value }, token);
  }

  protected successSignUp(res: FetchResult<RegisterAppSumoMutation>) {
    this.authController.saveAuthData(res.data?.registerAppSumo as AuthOutputGraphql);
    this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.Signup, {
      userID: res.data!.registerAppSumo.user!.id,
    });

    this.redirectAfterSign();
  }

  protected errorSignUp(error: ApolloError) {
    if (
      error.graphQLErrors?.length &&
      (error.graphQLErrors[0] as CustomGraphqlErrorInterface).errorCode === GraphqlErrorEnum.ALREADY_EXISTS
    ) {
      this.mergeAccount();
    } else {
      super.errorSignUp(error);
    }
  }

  protected redirectAfterSign() {
    this.n
      .go(Config.MAIN_VIEW)
      ?.then(() =>
        this.router.navigate(
          [
            '',
            {
              outlets: { pricing: this.userService.resolvePricingPacketsUrl() },
            },
          ],
          {
            skipLocationChange: true,
          },
        ),
      )
      .finally(() => {
        // set info in the localStorage
        this.userService.setNewUserRegistered();
        this.s.success(this.t.instant('Auth.Register.Thank you for register a new account. Confirm your e-mail.'));
      });
  }

  mergeAccount(): void {
    if (this.userService.User) {
      this.mergeAccountPopup();
    } else {
      this.loading = true;
      this.changes.detectChanges();
      this.authController.getCaptcha().subscribe((token: string | null) => {
        if (token) {
          this.authGraphqlService
            .login(this.formGroup.get('email')?.value, this.formGroup.get('password')?.value, false, token)
            .subscribe((res) => {
              this.authController.saveAuthData(res.data?.login as AuthOutputGraphql);
              this.mergeAccountPopup();
            }, this.errorLoginForMerge.bind(this));
        }
      });
    }
  }

  private errorLoginForMerge(): void {
    this.s.error(this.t.instant('Auth.AppSumo.Account exists. Sign in.'));
    this.loading = false;
    this.registerFieldsHidden = true;
    this.changes.detectChanges();
  }

  private mergeAccountPopup(): void {
    this.loading = false;
    this.changes.detectChanges();
    this.dialogService
      .open<boolean>(new PolymorpheusComponent(MergeAppSumoAccountModalComponent, this.injector), {
        size: 'm',
        dismissible: true,
        closeable: true,
        label: this.t.instant('Auth.AppSumo.Merge accounts'),
        data: {
          token: this.formGroup.get('appSumoToken')?.value,
        },
      })
      .pipe(filter((res: boolean) => res))
      .subscribe(() => this.redirectAfterSign());
  }

  protected getSocialLoginRequest(
    loginData: SocialLoginInputGraphql,
  ): Observable<FetchResult<LoginWithSocialMediaAccountMutation>> {
    return this.authGraphqlService.loginWithSocialMediaAccount({
      ...loginData,
    });
  }
}
