<div class="statement-select w-full flex items-center">
  <div
    *ngIf="statementInput"
    class="slider-wrapper"
  >
    <div class="slider-placeholder-container">
      <span class="input-range-placeholder tui-text_body-s">{{ 'Statement.More activities' | translate }}</span>
      <span class="input-range-placeholder tui-text_body-s">{{ 'Statement.More budget' | translate }}</span>
    </div>
    <input
      (change)="onValueChange($event)"
      type="range"
      [min]="statementInput.minValue?.value"
      [max]="statementInput.maxValue?.value"
      [value]="selectedValue"
      class="slider"
      step="1"
    />
    <div class="dots-container">
      <span
        *ngFor="let dot of dots"
        class="dot"
        [style.left]="dot.left"
        [class.dot-incognito]="dot.value === selectedValue"
        (click)="onDotClickChangeValue(dot.value)"
      ></span>
    </div>
    <div class="slider-placeholder-container">
      <span class="input-range-placeholder tui-text_body-s">{{ statementInput.minValue?.value }}</span>
      <span class="input-range-placeholder tui-text_body-s">{{ statementInput.maxValue?.value }}</span>
    </div>
  </div>
</div>
