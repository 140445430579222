import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FieldCategoryEnum } from '@modules/graphql/graphql-types';
import { FUNNEL_ID_FORM_CONTROL_NAME } from '@modules/statement/shared/models/statement.configuration';
import { StatementFormService } from '@modules/statement/shared/services/statement-form.service';
import { StatementNewParametersService } from '@modules/statement/shared/services/statement-new-parameters.service';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'df-marketing-campaign-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss'],
})
export class CampaignStatementComponent {
  items = [
    {
      id: 1,
      name: 'I want to generate more leads',
    },
    {
      id: 2,
      name: 'I want to make more sales',
    },
    {
      id: 3,
      name: 'I want to gain first customers',
    },
  ];

  goalAmountOptions = [
    {
      id: 1,
      name: 'Month',
    },
    {
      id: 2,
      name: 'Quarter',
    },
    {
      id: 3,
      name: 'Year',
    },
  ];

  FieldCategoryEnum = FieldCategoryEnum;
  formSubmitted = false;

  constructor(
    public statementFormService: StatementFormService,
    public statementNewParametersService: StatementNewParametersService,
    public statementService: StatementService,
    private route: ActivatedRoute,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext,
  ) {
    if (!this.statementFormService.isFormReady) this.statementNewParametersService.getParameters();
  }

  form = new FormGroup({
    answer: new FormControl(null, Validators.required),
  });

  getAnswer() {
    return this.form.get('answer') as FormControl;
  }

  statementFormSubmit() {
    this.formSubmitted = true;

    this.setStatementFormValues();
    this.statementFormService.form.markAllAsTouched();
    this.statementFormService.form.updateValueAndValidity();

    const values = this.statementFormService.form.getRawValue();
    this.statementService.saveStatement({ ...values, ...this.remapFields(), configurationId: 1 }, true, this.context);
  }

  private remapFields() {
    const productTypeField = this.statementFormService.form.get(FieldCategoryEnum.ProductType);
    const targetCharacteristicsField = this.statementFormService.form.get(FieldCategoryEnum.TargetCharacteristics);
    const goalTypeField = this.statementFormService.form.get(FieldCategoryEnum.GoalType);
    const marketingAdvancementField = this.statementFormService.form.get(FieldCategoryEnum.MarketingAdvancement);
    const targetLocationField = this.statementFormService.form.get(FieldCategoryEnum.TargetLocation);
    const goalPeriodField = this.statementFormService.form.get(FieldCategoryEnum.GoalPeriod);

    return {
      [FieldCategoryEnum.ProductType]: productTypeField?.value[0].id,
      [FieldCategoryEnum.TargetCharacteristics]: targetCharacteristicsField?.value[0].id,
      [FieldCategoryEnum.GoalType]: goalTypeField?.value[0].id,
      [FieldCategoryEnum.MarketingAdvancement]: marketingAdvancementField?.value[0].id,
      [FieldCategoryEnum.TargetLocation]: targetLocationField?.value[0].id,
      [FieldCategoryEnum.GoalPeriod]: goalPeriodField?.value[0].id,
      [FieldCategoryEnum.GoalMeasureLeads]: 2,
      [FieldCategoryEnum.GoalMeasureSales]: 2,
      [FieldCategoryEnum.GoalMeasureFirstCustomer]: 2,
    };
  }

  private setStatementFormValues() {
    const funnelId = this.route.snapshot.params['funnelId'];

    this.statementFormService.form.patchValue({
      [FUNNEL_ID_FORM_CONTROL_NAME]: Number(funnelId),
      [FieldCategoryEnum.ProductName]: 'My product',
      [FieldCategoryEnum.TargetSegment]: [8],
      [FieldCategoryEnum.Engagement]: 5,
      [FieldCategoryEnum.AvgProductMargin]: 2,
      [FieldCategoryEnum.ObjectiveType]: [90],
      [FieldCategoryEnum.OfferPolicy]: [41],
      [FieldCategoryEnum.PaymentFrequency]: [37],
      [FieldCategoryEnum.PaymentMethod]: [30],
      [FieldCategoryEnum.PricingCharacteristics]: [54],
      [FieldCategoryEnum.TargetChannels]: [71],
      [FieldCategoryEnum.B2bProductType]: null,
      [FieldCategoryEnum.B2cProductType]: [10],
      [FieldCategoryEnum.TargetPerson]: [62],
      [FieldCategoryEnum.TargetJob]: null,
    });
  }
}
