<div class="header">
  <h2 class="header-title">
    {{ headerTitle() }} <span class="header-subtitle">{{ headerSubTitle() }}</span>
  </h2>
  <div class="header-icon">
    <df-tactic-step-icon
      (click)="regenerateClick.emit()"
      [type]="'process'"
    ></df-tactic-step-icon>
    <df-tactic-step-icon
      (click)="onDownloadClickHandler()"
      [type]="'download'"
    ></df-tactic-step-icon>
  </div>
</div>

<div
  *ngIf="isRegenerating()"
  class="image"
></div>

<div
  *ngIf="!isRegenerating()"
  class="image"
  [ngStyle]="{ backgroundImage: 'url(' + imageSrc() + ')' }"
></div>

<div class="content">
  <div class="content__header">
    <h2 class="content__header-title">
      {{ isRegenerating() ? '...' : contentTitle() }}
    </h2>
  </div>

  <div class="content__main-text">
    {{ isRegenerating() ? '...' : contentText() }}
  </div>
</div>
