import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '@shared/configs/config';
import { UntypedFormGroup } from '@angular/forms';
import { EActivityForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-form.enum';
import { TacticActivitiesParametersService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-activities-parameters.service';
import { ActivityType } from '@shared/models/activity.model';

@Component({
  selector: 'df-activity-form-activity-type',
  templateUrl: './activity-form-activity-type.component.html',
  styleUrls: ['./activity-form-activity-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityFormActivityTypeComponent {
  Config = Config;
  EActivityForm = EActivityForm;

  activityTypes$: Observable<ActivityType[]>;
  @Input() activityControl: UntypedFormGroup | null = null;

  constructor(private readonly tacticActivitiesParametersService: TacticActivitiesParametersService) {
    this.activityTypes$ = this.tacticActivitiesParametersService.activityTypes$;
  }
}
