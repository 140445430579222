<div class="features flex flex-col justify-start items-stretch gap-y-4">
  <h2 class="tui-text_h4 mt-6 mb-1 font-semibold">
    {{ 'Auth.Onboarding.Features you will love' | translate }}
  </h2>

  <div
    class="features__item flex flex-row justify-start items-start gap-x-3"
    *ngFor="let feature of features"
  >
    <svg-icon
      class="flex-shrink-0 flex-grow-0"
      [src]="feature.icon"
      [svgStyle]="{ width: '2.5rem', height: '2.5rem' }"
    ></svg-icon>
    <div>
      <h3 class="tui-text_body-m-1">{{ feature.title }}</h3>
      <p class="text-xs">{{ feature.content }}</p>
    </div>
  </div>

  <h6 class="tui-text_h6 text-center font-semibold mt-1">
    {{ 'Auth.Onboarding.and more' | translate }}
  </h6>
</div>
