<!-- custom dialog close icon -->
<button
  class="btn-close absolute top-5 right-7"
  tuiIconButton
  type="button"
  appearance="outline"
  [icon]="iconClose"
  (click)="close()"
></button>
<ng-template #iconClose>
  <svg-icon
    class="tui-text-02"
    [src]="'/assets/img/ic-close.svg'"
  ></svg-icon>
</ng-template>

<h2 class="tui-text_h5 tui-text-03 w-full capitalize">
  {{ 'Tactics.File preview' | translate }}
</h2>
<!-- download -->
<button
  *ngIf="benchmark.type.type === BenchmarkTypeEnum.File"
  (click)="downloadAttachment()"
  class="btn-download mt-2 rounded-lg"
  tuiButton
  type="button"
  appearance="secondary"
  size="m"
  [icon]="iconDownload"
>
  {{ 'Tactics.Download' | translate }}
</button>
<ng-template #iconDownload>
  <svg-icon
    [svgStyle]="{ width: '1rem', height: '1rem' }"
    [src]="'/assets/img/ic-download.svg'"
  ></svg-icon>
</ng-template>
<!-- img -->
<div class="mt-6 flex flex-wrap lg:flex-nowrap justify-between items-stretch">
  <ng-container
    *ngIf="
      Regex.fileImage.test(benchmark.attachment?.file!) || Regex.fileImage.test(benchmark.file?.name!);
      then imgTmpl;
      else videoTmpl
    "
  >
  </ng-container>
  <!-- img -->
  <ng-template #imgTmpl>
    <img
      class="object-cover"
      [src]="fileSrc"
      alt=""
    />
  </ng-template>
  <!-- video -->
  <ng-template #videoTmpl>
    <ng-container *ngIf="linkSrc; then videoLink; else videoFile"> </ng-container>
    <!-- video file -->
    <ng-template #videoFile>
      <video
        width="100%"
        controls
      >
        <source [src]="fileSrc" />
        {{ 'Your browser does not support the video tag.' | translate }}
      </video>
    </ng-template>
    <!-- video link -->
    <ng-template #videoLink>
      <iframe
        class="w-full h-[25rem]"
        [src]="linkSrc || '' | sanitizeResourceUrl"
        frameborder="0"
      ></iframe>
    </ng-template>
  </ng-template>
</div>
