import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { Observable, of } from 'rxjs';
import { PermissionType } from '@modules/graphql/graphql-types';

@Injectable()
export class FunnelContentGenerateCanActivateGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authStorageService: AuthStorageService,
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const user = this.userService.User;

    if (user && this.authStorageService.jwt) {
      if (
        user.hasAccess(PermissionType.FunnelManagement, user!.funnelsCount) &&
        user.hasAccess(PermissionType.AiCopywriting)
      ) {
        return of(true);
      } else {
        this.n.go('funnels/f/d/:id/preview', { id: route.parent?.params.id });
        return of(false);
      }
    }

    if (user && this.authStorageService.jwt) return of(true);

    this.s.error(this.t.instant('Sign in to access the application'));
    this.n.go('sign-in');
    return of(false);
  }
}
