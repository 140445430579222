import {
  AttachmentOutputGraphql,
  TeamMemberOutputGraphql,
  TeamOutputGraphql,
  TeamRoleEnum,
  UserOutputGraphql,
} from '@modules/graphql/graphql-types';
import { User } from '@shared/models/user.model';
import { UntypedFormControl } from '@angular/forms';

export class Team implements TeamOutputGraphql {
  id!: number;
  name = '';
  icon: AttachmentOutputGraphql | null = null;
  members: TeamMember[] = [];
  createdAt = '';

  get ownersIds() {
    return this.members.filter((member) => member.role === TeamRoleEnum.Creator).map((m) => m.id);
  }

  constructor(data?: Team | TeamOutputGraphql) {
    data ? Object.assign(this, data) : '';
    data ? (this.members = data.members.map((member) => new TeamMember(member))) : '';
  }

  getOwner(me: User): TeamMember {
    const creators: TeamMember[] = this.members.filter((member) => member.role === TeamRoleEnum.Creator);
    const meCreator: TeamMember | undefined = creators.find((creator) => !!creator.user && creator.user.id === me.id);
    return meCreator ?? creators[0];
  }

  getMyRole(me: User): TeamRoleEnum {
    const meMember: TeamMember = this.members.find((m) => !!m.user && m.user.id === me.id)!;
    return meMember.role;
  }
}

export class TeamMember implements TeamMemberOutputGraphql {
  id!: number;
  role: TeamRoleEnum = TeamRoleEnum.Member;
  email: string | null = null;
  user: User | null = null;
  addedAsPermission?: boolean = false;

  constructor(data?: TeamMember | TeamMemberOutputGraphql) {
    data ? Object.assign(this, data) : '';
    data?.user ? (this.user = new User(data.user as UserOutputGraphql)) : '';
  }
}

export enum TeamMemberStatus {
  MEMBER = 'member',
  TO_INVITE = 'to_invite',
  TO_REMOVE = 'to_remove',
}

export class TeamMemberInvitee extends TeamMember {
  status: TeamMemberStatus = TeamMemberStatus.MEMBER;
  roleControl: UntypedFormControl = new UntypedFormControl(this.role);

  constructor(data?: TeamMember | TeamMemberOutputGraphql) {
    super(data);
    this.status = TeamMemberStatus.MEMBER;
    this.roleControl = new UntypedFormControl(this.role);
  }
}
