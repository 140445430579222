import { Component, Inject, Injector, OnInit } from '@angular/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { environment } from '@environments/environment';
import { TacticPreviewPopupComponent } from '@modules/tactics/modules/tactic-preview/pages/tactic-preview-popup/tactic-preview-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user.service';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'df-public-tactic-preview',
  templateUrl: './public-tactic-preview.component.html',
  styleUrls: ['./public-tactic-preview.component.scss'],
})
export class PublicTacticPreviewComponent implements OnInit {
  private readonly dialog;

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private userService: UserService,
    private s: SnackbarService,
    private clipboardService: ClipboardService,
    private t: TranslateService,
  ) {
    this.dialog = this.dialogService.open<number>(
      new PolymorpheusComponent(TacticPreviewPopupComponent, this.injector),
      {
        dismissible: true,
      },
    );
  }

  APP_URL = environment.APP_URL;

  ngOnInit(): void {
    if (!this.userService?.User) {
      setTimeout(() => this.showDialog(), 10000);
    }
  }

  shareTactic() {
    this.clipboardService.copy(window.location.href);
    this.s.success(this.t.instant('Tactics.Preview.link copied'));
  }

  showDialog(): void {
    this.dialog.subscribe();
  }
}
