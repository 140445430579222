<div class="w-full h-full overflow-y-auto py-5 px-4 md:px-6 lg:px-7.5 tui-base-02">
  <h3 class="text-base md:text-xl font-semibold tui-text-03 mb-4 mt-0">
    {{ 'Funnels.Statistics.Statistics' | translate }}
  </h3>
  <div class="statistics-section">
    <h3 class="tui-text_h3 mt-6 font-semibold">
      {{ 'Funnels.Statistics.Numbers that matter!' | translate }}
    </h3>
    <h4 class="tui-text_body-l-2 tui-text-05 mt-2">
      {{ 'Funnels.Statistics.Track the results of your campaign by using our analytical tools.' | translate }}
    </h4>
    <router-outlet></router-outlet>
  </div>
</div>
