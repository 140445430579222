import { Injectable } from '@angular/core';
import { BaseListService, ListPageDirection } from '@shared/services/base-list.service';
import { User } from '@shared/models/user.model';
import {
  FilterTypes,
  ListFiltersFieldInput,
  ListInput,
  OrderTypes,
  SkillOutputGraphql,
  TacticListInputGraphql,
} from '@modules/graphql/graphql-types';
import { UsersFiltersService } from '@modules/users/modules/users-list/shared/services/users-filters.service';
import { ListSortItem } from '@shared/modules/ui/components/list-sub-header/list-sub-header.component';
import { GetPublicUsersDocument } from '@modules/users/shared/graphql/queries/get-public-users.query';
import { IFilter } from '@shared/interfaces/filter.interface';

@Injectable({
  providedIn: 'root',
})
export class UsersListService extends BaseListService<User, ListInput> {
  static readonly defaultQuery = {
    page: 1,
    records: 20,
    search: '',
    sortDirection: OrderTypes.Asc,
    filters: [],
    sortField: 'a.createdAt',
  };

  constructor(public usersFiltersService: UsersFiltersService) {
    super(UsersListService.defaultQuery, GetPublicUsersDocument);
  }

  initService() {
    super.initService();
    this.listenFiltersChange();
  }

  recordTransform(record): User {
    return new User(record);
  }

  listenFiltersChange() {
    const sub = this.usersFiltersService.filterFormGroup.valueChanges.subscribe(() => {
      this.setFirstPage();
      this.getRecords(ListPageDirection.NONE).toPromise();
    });
    this.sub.add(sub);
  }

  prepareQuery() {
    this.query.sortField = this.usersFiltersService.filterFormGroup.get('sort')?.value['sortField'];
    this.query.sortDirection = this.usersFiltersService.filterFormGroup.get('sort')?.value['sortDirection'];
    this.query.search = this.usersFiltersService.filterFormGroup.get('search')?.value;
    const skills = this.usersFiltersService.filterFormGroup.get('skills')?.value as IFilter<SkillOutputGraphql>[];
    const tactics = this.usersFiltersService.filterFormGroup.get('tactics')?.value;
    const pro = this.usersFiltersService.filterFormGroup.get('pro')?.value as any[];
    this.query.filters = [
      {
        field: UsersListAvailableFilters.SKILLS,
        operator: FilterTypes.In,
        value: skills?.length ? skills.map((v) => v.id.toString()) : [],
      },
    ];
    if (tactics !== null) {
      this.query.filters.push({
        field: UsersListAvailableFilters.TACTICS,
        operator: FilterTypes.Eq,
        value: [tactics.value ? '1' : '0'],
      });
    }
    if (pro.length) {
      this.query.filters.push({
        field: UsersListAvailableFilters.PRO,
        operator: FilterTypes.Eq,
        value: pro.map((v) => (v.value ? '1' : '0')),
      });
    }
  }

  transformQueryToFilters(params: TacticListInputGraphql) {
    this.usersFiltersService.sorts.map((sort: ListSortItem) => {
      if (sort.sortField === params.sortField && sort.sortDirection === params.sortDirection) {
        this.usersFiltersService.filterFormGroup.get('sort')?.setValue(sort);
      }
    });
    this.usersFiltersService.filterFormGroup.get('search')?.setValue(params.search);
    params.filters?.map((obj: ListFiltersFieldInput) => {
      switch (obj.field) {
        case UsersListAvailableFilters.SKILLS:
          this.usersFiltersService.initSkillsIds = obj.value?.map((i) => Number(i)) || [];
          break;
        case UsersListAvailableFilters.TACTICS:
          this.usersFiltersService.initTactics = obj.value ? obj.value[0] === '1' : null;
          break;
        case UsersListAvailableFilters.PRO:
          this.usersFiltersService.initPro = obj.value ? obj.value[0] === '1' : null;
          break;
      }
    });
  }
}

export enum UsersListAvailableFilters {
  SKILLS = 'skills.id',
  TACTICS = 'tactics.isPaid',
  PRO = 'a.isPro',
}
