import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Config } from '@shared/configs/config';

@Injectable()
export class SelectPlanCanActivateGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authStorageService: AuthStorageService,
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.userService.getMeRequest().pipe(
      map(() => {
        if (this.userService.User && this.authStorageService.jwt) {
          if (this.userService.User.hasActivePlan()) {
            this.n.go(Config.MAIN_VIEW);
            return false;
          }
          return true;
        }
        this.s.error(this.t.instant('Sign in to access the application'));
        this.n.go('sign-in');
        return false;
      }),
    );
  }
}
