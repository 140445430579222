<div
  class="tactic-tile flex justify-between items-start overflow-hidden {{
    colorClass ? colorClass : tactic?.colorStepClasses
  }}"
  [ngClass]="{
    'tactic-tile--no-shadow': noShadow,
    'ml-0 mr-0': noLeftRightMargin,
    'tactic-tile--top-rated flex-col sm:flex-row': tactic?.isTopRated,
    'cursor-pointer': !funnel && !toCopy,
    'tactic-tile--paid': tactic?.isPaid && tactic?.owner?.id !== userService.User?.id
  }"
  (click)="addToFunnel()"
>
  <div
    class="tactic-tile__image w-full sm:w-1/2"
    *ngIf="tactic?.isTopRated"
    (click)="preview()"
  >
    <img
      loading="lazy"
      [src]="(tactic?.image?.file | assetsFile) ?? tactic?.imageUrl ?? '/assets/img/placeholder.jpg'"
      onerror="this.onerror=null;this.src='/assets/img/placeholder.jpg'"
      class="w-full h-full object-cover"
      [alt]="tactic?.name"
    />
  </div>
  <div
    (click)="toggleFavourite()"
    class="absolute top-3 right-3 cursor-pointer z-10 rounded-full p-1 tui-base-01-bg shadow-md"
  >
    <svg-icon
      [svgStyle]="{ width: '1rem', height: '1rem' }"
      [src]="tactic?.isFavourite ? '/assets/img/ic_heart_alt_filled.svg' : '/assets/img/ic_heart_alt.svg'"
    ></svg-icon>
  </div>
  <div
    class="flex flex-col justify-between items-start p-3 relative h-full"
    (click)="preview()"
    [ngClass]="{
      'w-full sm:w-1/2': tactic?.isTopRated,
      'w-full': !tactic?.isTopRated
    }"
  >
    <div class="to-hover tactic-tile__header flex flex-col items-start justify-start">
      <div class="flex flex-row mb-1 h-6">
        <svg-icon
          [src]="'/assets/img/ic-' + step.type + '-fill.svg'"
          class="mr-1"
          *ngFor="let step of tactic?.funnelSteps"
        ></svg-icon>
      </div>
      <h2
        class="tui-text-01 tui-text_body-17 line-clamp-5 break-words"
        [ngClass]="{ 'tui-text-04': !tactic?.funnelSteps?.length }"
        [innerHTML]="tactic?.name"
      ></h2>
      <div class="w-full flex justify-start items-stretch mt-1">
        <df-tactic-verification-badge
          *ngIf="tactic?.funnelSteps?.length && tactic?.owner?.id === userService.User?.id && !tactic?.isPrivate"
          [tactic]="tactic"
          [small]="true"
        ></df-tactic-verification-badge>
      </div>
      <div class="mt-1 h-6 tui-text-04 tui-text_body-m-4">
        <br />
        <ng-container *ngIf="!tactic?.funnelSteps?.length">
          {{ 'Tactics.List.draft of tactic' | translate }}
        </ng-container>
      </div>
    </div>
    <div class="w-full">
      <df-tactic-tile-actions
        class="md:hidden"
        [tactic]="tactic"
        [funnel]="funnel"
        [toCopy]="toCopy"
        [isRemoveFromFunnelAction]="isRemoveFromFunnelAction"
        [step]="step"
        (previewInModal)="openPreviewInModal()"
        (edit)="edit()"
        (preview)="preview()"
        (addToFunnelEvent)="addToFunnel()"
        [preventAction]="onboardingService.onboardingRunning"
      >
      </df-tactic-tile-actions>
      <div
        *ngIf="showPromptIntegrationTag(tactic)"
        class="tag tag__prompt-integration flex items-center py-1 pl-1 mb-2"
      >
        <svg-icon
          [src]="'/assets/img/thunder.svg'"
          class="mr-1"
        ></svg-icon>
        <span>Prompts</span>
      </div>
      <df-created-by
        [user]="!!tactic?.root ? (tactic?.root)!.owner : tactic?.owner"
        class="hidden md:flex"
        *ngIf="!noOwner"
      ></df-created-by>
    </div>

    <div class="tactic-tile__actions absolute w-full h-3/5 bottom-0 left-0 right-0 p-3 flex flex-col justify-between">
      <div class="w-full">
        <div class="flex flex-row items-center justify-start">
          <span
            class="tui-text_body-s tui-text-02 mr-2"
            *ngIf="tactic?.levelOfAdvancement"
          >
            {{ 'Tactics.List.level' | translate }}:
          </span>
          <span class="tui-text_body-m-6">
            {{ tactic?.levelOfAdvancement?.name }}
          </span>
        </div>
      </div>

      <df-tactic-tile-actions
        [tactic]="tactic"
        [toCopy]="toCopy"
        [funnel]="funnel"
        [isRemoveFromFunnelAction]="isRemoveFromFunnelAction"
        [step]="step"
        (chooseToCopy)="chooseToCopy()"
        (edit)="edit()"
        (previewInModal)="openPreviewInModal()"
        (preview)="preview()"
        (remove)="remove()"
        (addToFunnelEvent)="addToFunnel()"
        [preventAction]="!onboardingService.onboardingCompleted"
      >
      </df-tactic-tile-actions>
    </div>
  </div>
</div>

<div
  class="absolute left-4 top-4 z-10 flex items-center rounded-full tui-primary-bg uppercase text-white tui-text_body-xs font-bold h-6 pl-2 pr-2"
  *ngIf="tactic?.isTopRated"
>
  <svg-icon
    [src]="'/assets/img/ic_star.svg'"
    class="mr-2"
  ></svg-icon>
  {{ 'Tactics.top rated' | translate }}
</div>
<ng-content></ng-content>
