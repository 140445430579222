import { gql } from 'apollo-angular';

export const EditFlowDocument = gql`
  mutation editFlow($input: FlowInputGraphql!, $flowId: Int!) {
    editFlow(input: $input, flowId: $flowId) {
      id
      name
      items {
        from
        to
      }
    }
  }
`;
