import { gql } from 'apollo-angular';

export const GetCompanyDataDocument = gql`
  query getCompanyData($funnelId: Int!) {
    getCompanyData(funnelId: $funnelId) {
      url
      businessName
    }
  }
`;
