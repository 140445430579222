import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Integration, IntegrationInputConfiguration } from '@shared/models/integration.model';
import { FunnelGraphicsGeneratorService } from '../../funnel-graphics-generator.service';
import { UntypedFormControl } from '@angular/forms';
import { rejectedImages } from '@shared/helpers/file-input.helper';
import { TuiFileLike } from '@taiga-ui/kit';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { getAcceptedFileExtensions } from '@shared/helpers/content-generator.helper';
import { copyToClipboard } from '@shared/helpers/clipboard.helper';
import { sleep } from '@shared/helpers/sleep.helper';
import file2Base64 from '@shared/helpers/file-to-base64.helper';
import { AiDesignUploadedFile } from './funnel-graphics-ai-design-form.model';
import { AssetContext } from '@modules/assets/shared/assets.model';

enum GraphicModels {
  stableDiffusion = 'stablediffusion',
  dalle3 = 'dalle3',
}

@Component({
  selector: 'df-funnel-graphics-ai-design-form',
  templateUrl: './funnel-graphics-ai-design-form.component.html',
  styleUrls: ['./funnel-graphics-ai-design-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelGraphicsAiDesignFormComponent implements OnInit {
  @Input() integration: Integration | null = null;

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fileAddedEvent: EventEmitter<AiDesignUploadedFile> = new EventEmitter<AiDesignUploadedFile>();

  maskingMode = false;

  constructor(
    public readonly graphicService: FunnelGraphicsGeneratorService,
    private s: SnackbarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
  ) {}

  get loading$() {
    return this.graphicService.loading$;
  }

  get form() {
    return this.graphicService.form;
  }

  ngOnInit(): void {
    this.tooglDisableField(['image_strength']);
    this.listenModelChange();
  }

  listenModelChange() {
    const modelConf = this.getConfiguration('image_integration');
    const modelField = this.form.get(`${modelConf?.id}`);

    if (!modelField) return;

    modelField.valueChanges.subscribe((model) => {
      switch (model.value) {
        case GraphicModels.dalle3:
          this.tooglDisableField(['image_ratio'], false);
          break;
        case GraphicModels.stableDiffusion:
          this.tooglDisableField(['image_ratio'], true);
          break;
      }
    });
  }

  onSubmit() {
    const textarea = document.querySelector('textarea.t-input') as HTMLTextAreaElement;
    textarea.focus();
    textarea.blur();

    this.setDefaultValues();
    this.changes.detectChanges();

    if (!this.form.valid) return;
    this.mapAndSave(this.form.getRawValue());

    this.formSubmitted.emit();
  }

  setDefaultValues() {
    //Set bar default value before submit
    const sampleConf = this.getConfiguration('samples');
    const ratioConf = this.getConfiguration('image_ratio');

    const sample = this.form.get(`${sampleConf?.id}`)?.value;
    const ratio = this.form.get(`${ratioConf?.id}`)?.value;

    if (!sample) this.form.get(`${sampleConf?.id}`)?.setValue(4);
    if (!ratio) this.form.get(`${ratioConf?.id}`)?.setValue(2);
  }

  mapAndSave(values: Record<number, string>) {
    const keys = Object.keys(values);
    const realFields: Array<IntegrationInputConfiguration | undefined> = [];
    const finalData: AssetContext[] = [];

    keys.forEach((key) => realFields.push(this.getFieldNameById(+key)));

    realFields.forEach((field) => {
      if (values[`${field?.id}`]) {
        const conf = {
          key: field!.label,
          value:
            typeof values[`${field?.id}`] === 'object' ? values[`${field?.id}`]?.label : String(values[`${field?.id}`]),
        };
        finalData.push(conf);
      }
    });

    sessionStorage.setItem('last-funnel-ai-design-generated-config', JSON.stringify(finalData));
  }

  getFieldNameById(id: number): IntegrationInputConfiguration | undefined {
    return this.integration?.inputsConfigurations?.find((prop) => prop.id === id);
  }

  copyPrompt() {
    const text = this.t.instant('Funnels.Graphics.An astronaut playing basketball with cats in space, digital art');
    copyToClipboard(text, this.s, this.t);
  }

  getConfiguration(property: string) {
    return this.integration?.inputsConfigurations.find((prop) => prop.property === property);
  }

  getFormControl(id: number): UntypedFormControl {
    return this.form?.get(id.toString()) as UntypedFormControl;
  }

  rejectedImages(e: readonly TuiFileLike[], config: IntegrationInputConfiguration) {
    rejectedImages(e, this.s, this.t, this.getAcceptedImagesExtensions(config));
  }

  getAcceptedImagesExtensions(config: IntegrationInputConfiguration): string {
    return getAcceptedFileExtensions(config.validationPatterns);
  }

  tooglDisableField(fields: string | string[], forceValue: boolean | null = null) {
    if (typeof fields === 'string') fields = [fields];

    fields.forEach((field) => {
      const conf = this.getConfiguration(field);
      const formField = this.form.get(`${conf?.id}`);

      if (forceValue === true) return formField?.enable();
      if (forceValue === false) return formField?.disable();

      if (formField?.disabled) formField.enable();
      else formField?.disable();
    });
  }

  setDefaultModel() {
    const modelConf = this.getConfiguration('image_integration');
    const modelField = this.form.get(`${modelConf?.id}`);
    if (!modelField || !modelConf) return;

    modelField.setValue(modelConf?.metadata?.options[0]);
  }

  fileRemoved() {
    this.tooglDisableField(['width', 'height', 'image_strength', 'image_ratio', 'image_integration']);
  }

  async fileAdded() {
    await sleep(500);
    const conf = this.getConfiguration('init_image');
    const file = this.form.get(`${conf?.id}`)?.value;
    this.setDefaultModel();
    this.tooglDisableField(['width', 'height', 'image_strength', 'image_ratio', 'image_integration']);

    if (!file) {
      this.maskingMode = false;
      return this.fileAddedEvent.emit({ base64: null });
    }

    this.maskingMode = true;

    const base64 = await file2Base64(file);
    const image = new Image();
    image.src = `data:image/png;base64, ${base64}`;

    image.onload = () => {
      const height = image.height;
      const width = image.width;

      this.fileAddedEvent.emit({ base64, width, height });
    };
  }
}
