import { ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TuiDialogService } from '@taiga-ui/core';
import { FunnelsFilterService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-filter.service';
import { FunnelController } from '@modules/funnels/shared/controllers/funnel.controller';

@Component({
  selector: 'df-funnels-list-panel',
  templateUrl: './funnels-list-panel.component.html',
  styleUrls: ['./funnels-list-panel.component.scss'],
})
export class FunnelsListPanelComponent implements OnInit {
  funnelController: FunnelController = new FunnelController();

  constructor(
    public n: NavigateService,
    private t: TranslateService,
    private s: SnackbarService,
    private changes: ChangeDetectorRef,
    private funnelFiltersService: FunnelsFilterService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  ngOnInit(): void {
    if (this.funnelFiltersService.projects?.length) {
      this.funnelFiltersService.getFilters(true);
    }
  }

  createNewFunnel(): void {
    this.funnelController.createFunnel();
  }
}
