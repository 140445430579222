import { gql } from 'apollo-angular';
import { FunnelPublicFragment } from '../fragments/funnel-public.fragment';

export const GetPublicFunnelByIdDocument = gql`
  query getPublicFunnelById($funnelId: Int!) {
    getPublicFunnelById(funnelId: $funnelId) {
      ...FunnelPublic
    }
  }
  ${FunnelPublicFragment}
`;
