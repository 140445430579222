<ng-container *ngIf="userService.currentUser$ | async as user">
  <ng-container *ngIf="!tactic.isPaid || tactic.owner?.id === user.id">
    <tui-hosted-dropdown
      *ngIf="type === AddToFunnelButtonType.DROPDOWN"
      class="w-full"
      tuiDropdownAlign="left"
      [(open)]="dropdownOpened"
      [content]="dropdownSteps"
    >
      <button
        tuiHostedDropdownHost
        (click)="mixpanelAddToFunnelButtonClick('parent_add_to_funnel')"
        class="w-full"
        *ngIf="user.contextFunnel"
        tuiButton
        size="l"
        appearance="primary"
        type="button"
      >
        <svg-icon
          [src]="'/assets/img/funnel-add.svg'"
          class="md:mr-1"
        ></svg-icon>
        <span class="hidden lg:inline-flex">{{ 'Tactics.Preview.Add to funnel' | translate }}</span>
      </button>
    </tui-hosted-dropdown>

    <ng-container *ngIf="type === AddToFunnelButtonType.FLOAT">
      <div class="extended-button">
        <button
          tuiIconButton
          type="button"
          size="xl"
          class="rounded-full shadow-lg"
          (click)="extendedOpened = !extendedOpened"
          [icon]="icon"
        ></button>
        <ng-template #icon>
          <svg-icon [src]="'/assets/img/funnel-add.svg'"></svg-icon>
        </ng-template>
        <div
          class="extended-button__dropdown"
          [ngClass]="{ 'extended-button__dropdown--opened': extendedOpened }"
        >
          <div class="flex items-center justify-center pl-4 pr-12">
            <div
              class="flex w-10 h-10 rounded-full border-solid border {{
                step.type
              }}-border mr-4 items-center justify-center cursor-pointer"
              *ngFor="let step of steps"
            >
              <svg-icon
                [src]="'/assets/img/ic-' + step.type + '.svg'"
                [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
                (click)="addToFunnel(step)"
              ></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <tui-hosted-dropdown
      [(open)]="dropdownOpened"
      [content]="dropdownSteps"
      *ngIf="type === AddToFunnelButtonType.ICON"
    >
      <button
        tuiHostedDropdownHost
        tuiIconButton
        type="button"
        appearance="secondary"
        [icon]="iconTooltip"
        (click)="
          $event.stopPropagation();
          mixpanelAddToFunnelButtonClick('parent_add_to_funnel');
          dropdownOpened = !dropdownOpened
        "
      ></button>
      <ng-template #iconTooltip>
        <svg-icon [src]="'/assets/img/funnel-add-small.svg'"></svg-icon>
      </ng-template>
    </tui-hosted-dropdown>

    <ng-template #dropdownSteps>
      <div class="flex flex-col justify-start items-start w-64 p-4 pb-0">
        <p class="tui-text-04 tui-text-body-s-2 font-bold uppercase mb-2">
          {{ 'add to' | translate }}
          {{ (userService.currentUser$ | async)?.contextFunnel?.name }}
        </p>
        <div
          class="w-full flex justify-between mb-4"
          *ngFor="let step of steps"
        >
          <div class="flex items-center">
            <svg-icon
              [src]="'/assets/img/ic-' + step.type + '-fill.svg'"
              class="mr-2"
            ></svg-icon>
            <span class="inline-flex {{ step.type }}-text">{{ step.name }}</span>
          </div>
          <df-add-remove-link-button
            [count]="step.contextFunnelTacticCount!"
            [loading]="!!step.loading"
            [disabled]="loading"
            (onAdd)="addToFunnel(step); mixpanelAddToFunnelButtonClick('child_add_to_funnel', step)"
            (onRemove)="removeFromFunnel(step)"
          ></df-add-remove-link-button>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
