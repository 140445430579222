import { Apollo } from 'apollo-angular';
import { EventEmitter, Injectable } from '@angular/core';
import { IFilter } from '@shared/interfaces/filter.interface';
import { FilterAdvancementsQuery } from '@modules/tactics/shared/graphql/queries/filter-advancemenets.query.generated';
import { FilterAdvancementsDocument } from '@modules/tactics/shared/graphql/queries/filter-advancemenets.query';
import { ApolloQueryResult } from '@apollo/client/core';
import { FilterTacticsTypesQuery } from '@modules/tactics/shared/graphql/queries/filter-tactic-types.query.generated';
import { FilterTacticTypesDocument } from '@modules/tactics/shared/graphql/queries/filter-tactic-types.query';
import { FilterStepsQuery } from '@modules/tactics/shared/graphql/queries/filter-steps.query.generated';
import { FilterStepsDocument } from '@modules/tactics/shared/graphql/queries/filter-steps.query';
import { Step } from '@shared/models/step.model';
import { TacticParametersLoading } from '@modules/tactics/enums/tactic-parameters-loading.enum';

@Injectable({
  providedIn: 'root',
})
export class TacticParametersService {
  types: IFilter<any>[] = [];
  advancements: IFilter<any>[] = [];
  steps: IFilter<Step>[] = [];
  events$: EventEmitter<TacticParametersLoading> = new EventEmitter<TacticParametersLoading>();

  constructor(private apollo: Apollo) {
    this.getAdvancements();
    this.getSteps();
    this.getTypes();
  }

  getTypes(): void {
    this.apollo
      .query<FilterTacticsTypesQuery>({
        query: FilterTacticTypesDocument,
        variables: {
          input: {},
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe((res: ApolloQueryResult<FilterTacticsTypesQuery>) => {
        this.types = res.data.filterTacticsTypes.records;
        this.events$.emit(TacticParametersLoading.TYPES);
      });
  }

  getSteps(): void {
    this.apollo
      .query<FilterStepsQuery>({
        query: FilterStepsDocument,
        variables: {
          input: {},
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe((res: ApolloQueryResult<FilterStepsQuery>) => {
        this.steps = res.data.filterSteps.records as IFilter<Step>[];
        this.events$.emit(TacticParametersLoading.STEPS);
      });
  }

  getAdvancements(): void {
    this.apollo
      .query<FilterAdvancementsQuery>({
        query: FilterAdvancementsDocument,
        variables: {
          input: {},
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe((res: ApolloQueryResult<FilterAdvancementsQuery>) => {
        this.advancements = res.data.filterAdvancements.records;
        this.events$.emit(TacticParametersLoading.ADVANCEMENTS);
      });
  }
}
