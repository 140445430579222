import { IntegrationOutputData } from '@shared/models/integration.model';
import { FunnelManageService } from './../../../../shared/services/funnel-manage.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractFunnelSubpageComponent } from '@shared/abstracts/funnel-subpage.component.abstract';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { Subscription } from 'rxjs';
import { FunnelContentGeneratorLibraryService } from './services/funnel-content-generator-library.service';
import { Funnel } from '@shared/models/funnel.model';
import { NavigateService } from '@core/routes/services/navigate.service';
import { debounceTime } from 'rxjs/operators';

const FUNNEL_CHANGED_SUBSCRIPTIONS_DEBOUNCE_TIME_MS = 500;

@Component({
  selector: 'df-funnel-content-generator-library',
  templateUrl: './funnel-content-generator-library.component.html',
  styleUrls: ['./funnel-content-generator-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelContentGeneratorLibraryComponent extends AbstractFunnelSubpageComponent implements OnInit {
  private funnel: Funnel | null = null;

  outputsData: Array<IntegrationOutputData> = [];
  loading = false;

  constructor(
    protected readonly route: ActivatedRoute,
    private readonly navigateService: NavigateService,
    protected readonly funnelManageService: FunnelManageService,
    private userService: UserService,
    public contentLibraryService: FunnelContentGeneratorLibraryService,
  ) {
    super(route, funnelManageService);
  }

  get funnelId(): number | null {
    return this.funnel?.id ?? null;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.funnel = this.userService.User?.contextFunnel ?? null;
    this.listenForFunnelChange();
    this.fetchGeneratedContent();
  }

  private fetchGeneratedContent(): void {
    this.contentLibraryService.startNewSearch(true);
  }

  public onCreateNewContent(): void {
    if (!this.funnel) return;
    this.navigateService.go('/funnels/f/d/:id/content-generator', {
      id: this.funnel.id,
    });
  }

  private listenForFunnelChange(): void {
    const subscription: Subscription = this.funnelManageService.funnel$
      .pipe(debounceTime(FUNNEL_CHANGED_SUBSCRIPTIONS_DEBOUNCE_TIME_MS))
      .subscribe({
        next: (funnel: Funnel) => this.handleRefreshContextAfterFunnelChanged(funnel),
      });
    this.sub.add(subscription);
  }

  private handleRefreshContextAfterFunnelChanged(funnel: Funnel): void {
    if (funnel && funnel.id === this.funnel?.id) return;
    this.funnel = funnel;
    this.contentLibraryService.clearFilters();
    this.contentLibraryService.setFunnelId = this.funnel.id;
    this.fetchGeneratedContent();
  }

  public loadMoreContent(): void {
    this.contentLibraryService.loadMoreContent();
  }
}
