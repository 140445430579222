import { gql } from 'apollo-angular';

export const IntegrationOutputDataFragment = gql/* GraphQL*/ `
  fragment IntegrationOutputData on OutputDataGraphql {
    id
    value
    originalValue
    createdAt
    modifiedAt
    isSelected
    metadata
    refNr
    configuration {
      id
      type
      metadata
      validationPatterns {
        id
        rules
      }
    }
  }
`;
