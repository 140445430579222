import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Config } from '@shared/configs/config';
import { ActivityBenchmark } from '@shared/models/activity-benchmark.model';
import { BenchmarkTypeEnum } from '@modules/graphql/graphql-types';
import FileSaver from 'file-saver';
import {
  removeCustomElementById,
  restoreCustomElementById,
} from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-data/helpers/quill.helper';

@Component({
  selector: 'df-activity-benchmark-item',
  templateUrl: './activity-benchmark-item.component.html',
  styleUrls: ['./activity-benchmark-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityBenchmarkItemComponent {
  Config = Config;
  BenchmarkTypeEnum = BenchmarkTypeEnum;

  @Input() benchmark!: ActivityBenchmark;
  @Input() editMode = false;
  @Input() disabled = false;
  @Input() removeLoading = false;
  @Input() editorElem?: Element | undefined;

  @Output() benchmarkRemoved: EventEmitter<ActivityBenchmark> = new EventEmitter<ActivityBenchmark>();
  @Output() benchmarkRestored: EventEmitter<ActivityBenchmark> = new EventEmitter<ActivityBenchmark>();
  @Output() onBenchmarkEdit: EventEmitter<ActivityBenchmark> = new EventEmitter<ActivityBenchmark>();

  removeBenchmark(): void {
    this.benchmarkRemoved.emit(this.benchmark);
    if (this.editorElem) {
      removeCustomElementById(this.editorElem, this.benchmark.uuid);
    }
  }

  restoreBenchmark(): void {
    this.benchmarkRestored.emit(this.benchmark);
    restoreCustomElementById(this.editorElem, this.benchmark.uuid);
  }

  editBenchmark(): void {
    this.onBenchmarkEdit.emit(this.benchmark);
  }

  downloadAttachment(): void {
    this.benchmark.attachment
      ? FileSaver.saveAs(Config.ASSETS + this.benchmark.attachment!.file, this.benchmark.attachment!.name)
      : FileSaver.saveAs(this.benchmark.file!, this.benchmark.file?.name);
  }

  public onNavigate(): void {
    if (!this.benchmark.link) return;
    const preparedUrl: string = !/^http[s]?:\/\//.test(this.benchmark.link as string)
      ? 'https:\\\\' + this.benchmark.link
      : this.benchmark.link;
    window.open(preparedUrl, '_blank');
  }
}
