import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { NUMBER_OF_STEPS, STATEMENT_STEP_ENUM } from '../../models/statement.configuration';
import { StatementService } from '../../services/statement.service';

@Component({
  selector: 'df-statement-controls',
  templateUrl: './statement-controls.component.html',
  styleUrls: ['./statement-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementControlsComponent extends AbstractSubscriptionComponent implements OnInit {
  step = 0;
  readonly LAST_STEP: number = NUMBER_OF_STEPS;
  STATEMENT_STEP_ENUM = STATEMENT_STEP_ENUM;

  @Output() onNextStep: EventEmitter<void> = new EventEmitter<void>();
  @Output() onPreviousStep: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public readonly statementService: StatementService,
    private changes: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeChangeStepSubscription();
  }

  private initializeChangeStepSubscription(): void {
    this.sub.add(
      this.statementService.stepNr.asObservable().subscribe({
        next: (step) => {
          this.setStep(step);
          this.changes.detectChanges();
        },
      }),
    );
  }

  private setStep(step: number | null): void {
    this.step = step === null ? 0 : step;
  }

  public nextStep(): void {
    this.onNextStep.emit();
  }

  public previousStep(): void {
    this.onPreviousStep.emit();
  }
}
