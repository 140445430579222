import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FunnelGraphicsGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-graphics/funnel-graphics-generator.service';
import { IntegrationOutputData } from '@shared/models/integration.model';
import { swiperFunnelGraphicsConfig } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-graphics/components/funnel-graphics-welcome-modal/swiper-funnel-graphics-config.const';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { Observable } from 'rxjs';
import { GraphicsGeneratorItemTileComponent } from '@modules/funnels/modules/funnel-manage/shared/components/graphics-generator-item-tile/graphics-generator-item-tile.component';

@Component({
  selector: 'df-funnel-graphics-welcome-modal',
  templateUrl: './funnel-graphics-welcome-modal.component.html',
  styleUrls: ['./funnel-graphics-welcome-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelGraphicsWelcomeModalComponent implements OnInit {
  swiperConfig = swiperFunnelGraphicsConfig;
  graphics: IntegrationOutputData[] = [];

  loadingDownload$!: Observable<boolean>;

  @ViewChildren(GraphicsGeneratorItemTileComponent)
  results?: QueryList<GraphicsGeneratorItemTileComponent>;

  constructor(
    private readonly funnelGraphicsGeneratorService: FunnelGraphicsGeneratorService,
    private readonly changes: ChangeDetectorRef,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { graphics: IntegrationOutputData[] } }, number>,
  ) {}

  ngOnInit(): void {
    this.fetchGraphics();
    this.loadingDownload$ = this.funnelGraphicsGeneratorService.loadingZip$;
  }

  private fetchGraphics(): void {
    this.graphics = this.context.data?.graphics ?? [];
    this.changes.detectChanges();
    this.funnelGraphicsGeneratorService
      .getValidatedOutputData(this.graphics)
      .then((graphics: IntegrationOutputData[]) => {
        this.graphics = graphics;
        this.changes.detectChanges();
        this.results?.forEach((result) => result.changes.detectChanges());
      });
  }

  skip(): void {
    this.context.completeWith(0);
  }

  downloadAll(): void {
    this.funnelGraphicsGeneratorService.downloadOutputsZip(this.graphics);
  }
}
