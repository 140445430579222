import { gql } from 'apollo-angular';

export const OwnerFragment = gql`
  fragment OwnerFields on PublicUserOutputGraphql {
    id
    firstName
    lastName
    avatar {
      id
      file
      name
    }
    website
    description
    country
    favouritesCount
    tacticsCount
    experienceYears
    position
    skills {
      id
      name
    }
    services {
      id
      name
      description
      price
    }
    isPro
    isBlocked
  }
`;
