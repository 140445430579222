import { OnboardingHelpMark } from '@shared/models/onboarding-step.model';
import { AbstractOnboardingMultiElementsFactory } from '@shared/abstracts/onboarding-multi-elements-factory.abstract';

export class OnboardingHelpMarkFactory extends AbstractOnboardingMultiElementsFactory<OnboardingHelpMark> {
  constructor(anchorClass = 'onboarding-help-mark-anchor') {
    super(anchorClass);
  }

  protected _renderElement(element: OnboardingHelpMark, anchor: Element): [Element, Element] {
    const helpMark = document.createElement('div');
    helpMark.classList.add('onboarding-help-mark');
    helpMark.appendChild(this._renderHelpMarkAnchor());
    anchor.appendChild(helpMark);

    const tooltip = this._renderTooltip(element);

    helpMark.addEventListener('mouseenter', () => {
      tooltip.style.maxWidth = anchor.getBoundingClientRect().width * 0.9 + 'px';
      const tooltipAnchorRect = helpMark.getBoundingClientRect();
      tooltip.style.top = tooltipAnchorRect.bottom + 12 + 'px';
      tooltip.style.right = window.innerWidth - tooltipAnchorRect.right - 4 + 'px';
      tooltip.style.transform = 'scale(1)';
    });
    helpMark.addEventListener('mouseleave', () => (tooltip.style.transform = 'scale(0)'));

    this.container.appendChild(tooltip);
    return [helpMark, tooltip];
  }

  private _renderHelpMarkAnchor(): Element {
    const anchor = document.createElement('div');
    anchor.classList.add('onboarding-help-mark__anchor');
    const questionMark = document.createElement('img');
    questionMark.src = 'assets/img/ic_question_mark.svg';
    questionMark.style.display = 'block';
    anchor.appendChild(questionMark);
    return anchor;
  }

  private _renderTooltip(element: OnboardingHelpMark): HTMLDivElement {
    const tooltip = document.createElement('div');
    tooltip.classList.add('onboarding-help-mark__tooltip');
    if (element.title) tooltip.appendChild(this._renderTitle(element.title));
    if (element.text) tooltip.appendChild(this._renderText(element.text));
    return tooltip;
  }

  private _renderTitle(title: string): Element {
    const helpMarkTitle = document.createElement('h5');
    helpMarkTitle.classList.add('onboarding-help-mark__title');
    helpMarkTitle.innerText = title;
    return helpMarkTitle;
  }

  private _renderText(text: string): Element {
    const helpMarkText = document.createElement('p');
    helpMarkText.classList.add('onboarding-help-mark__text');
    helpMarkText.innerText = text;
    return helpMarkText;
  }
}
