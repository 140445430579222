<nav class="flex items-center justify-between px-3 sm:px-6 py-4">
  <svg-icon
    class="max-w-3-4"
    [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
  ></svg-icon>

  <div
    class="flex justify-end items-center"
    *ngIf="!userService.User"
  >
    <button
      tuiButton
      class="hidden sm:block ml-2"
      type="button"
      appearance="outline"
      [routerLink]="n.getPath('sign-up')"
    >
      {{ 'Auth.Login.Sign up' | translate }}
    </button>

    <button
      tuiButton
      class="ml-2"
      type="button"
      appearance="primary"
      [routerLink]="n.getPath('sign-in')"
    >
      {{ 'Auth.Login.Sign in' | translate }}
    </button>
  </div>
</nav>

<df-packets [public]="true"></df-packets>
