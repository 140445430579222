import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, merge, Observable, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { EFunnelSettingsForm } from '@modules/funnels/modules/funnel-settings/shared/enums/funnel-settings-form.enum';
import { Funnel } from '@shared/models/funnel.model';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { map } from 'rxjs/operators';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { GetFunnelQuery } from '@modules/funnels/shared/graphql/queries/get-funnel.query.generated';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { FunnelSettingsViewModeEnum } from '@modules/funnels/modules/funnel-settings/shared/enums/funnel-settings-view-mode.enum';
import { AttachmentOutputGraphql, FunnelInputGraphql } from '@modules/graphql/graphql-types';
import { CreateFunnelMutation } from '@modules/funnels/shared/graphql/mutations/create-funnel.mutation.generated';
import { BasePanelEvent, BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { UserService } from '@shared/services/user.service';
import { ResourceTeamsService } from '@shared/modules/resource-teams/services/resource-teams.service';
import { IContainerTab } from '@shared/interfaces/container-tab.interface';
import { BusinessDataService } from '@modules/business-data/business-data.service';

@Injectable({
  providedIn: 'root',
})
export class FunnelSettingsService {
  private readonly _viewMode$: BehaviorSubject<FunnelSettingsViewModeEnum> =
    new BehaviorSubject<FunnelSettingsViewModeEnum>(FunnelSettingsViewModeEnum.CREATE);
  readonly viewMode$: Observable<FunnelSettingsViewModeEnum> = this._viewMode$.asObservable();

  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  readonly _changesInForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly changesInForm$: Observable<boolean> = this._changesInForm$.asObservable();

  private _funnel: Funnel | null = null;
  private _resourceTeamsService: ResourceTeamsService | null = null;

  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  sub: Subscription = new Subscription();
  tabs: IContainerTab[] = [];
  activeTab: IContainerTab = this.tabs[0];

  set resourceTeamsService(resourceTeamsService: ResourceTeamsService | null) {
    this._resourceTeamsService = resourceTeamsService;
  }

  get resourceTeamsService(): ResourceTeamsService | null {
    return this._resourceTeamsService;
  }

  set loading(value: boolean) {
    this._loading$.next(value);
  }

  set viewMode(mode: FunnelSettingsViewModeEnum) {
    this._viewMode$.next(mode);
  }

  get funnel(): Funnel | null {
    return this._funnel;
  }

  set funnel(funnel: Funnel | null) {
    this._funnel = funnel;
  }

  constructor(
    private funnelGraphqlService: FunnelGraphqlService,
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
    private basePanelService: BasePanelService,
    private userService: UserService,
    private businessDataService: BusinessDataService,
  ) {}

  initService(): void {
    this.sub = new Subscription();
    this.formGroup = new UntypedFormGroup({
      [EFunnelSettingsForm.name]: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Regex.whiteSpace),
      ]),
      [EFunnelSettingsForm.projectName]: new UntypedFormControl(null),
      [EFunnelSettingsForm.image]: new UntypedFormControl(''),
    });
    this.listenChangesInForm();
  }

  fetchFunnel(id: number) {
    this._loading$.next(true);
    const sub = this.funnelGraphqlService
      .getFunnel(Number(id))
      .pipe(map((res: FetchResult<GetFunnelQuery>) => res.data?.getFunnel as Funnel))
      .subscribe({
        next: (res: Funnel) => {
          this.funnel = res;

          this.formGroup.patchValue(
            {
              [EFunnelSettingsForm.name]: this.funnel.name,
              [EFunnelSettingsForm.projectName]: this.funnel.project ? [this.funnel.project.name] : [],
            },
            { emitEvent: false },
          );
        },
        error: (error: ApolloError) => {
          this.n.go('funnels/list/all');
          if (error.graphQLErrors?.length) {
            switch ((error.graphQLErrors[0] as CustomGraphqlErrorInterface).message) {
              case 'Not allowed':
                this.s.error(this.t.instant('Funnels.Manage.You do not have access to this funnel.'));
                break;
              default:
                this.s.error(this.t.instant('Funnels.Manage.There is problem with getting funnel data. Try again.'));
                break;
            }
          }
        },
      });
    sub.add(() => {
      this._loading$.next(false);
    });

    return sub;
  }

  listenChangesInForm(): void {
    this.sub.add(
      merge(
        this.formGroup.get(EFunnelSettingsForm.name)!.valueChanges,
        this.formGroup.get(EFunnelSettingsForm.projectName)!.valueChanges,
        this.formGroup.get(EFunnelSettingsForm.image)!.valueChanges,
      ).subscribe((val) => {
        if (val) {
          this._changesInForm$.next(true);
        }
      }),
    );
  }

  createFunnel(): Observable<FetchResult<CreateFunnelMutation>> {
    this._loading$.next(true);
    return this.funnelGraphqlService.createFunnel(this.getFormObject(), this.getImage()).pipe(
      map((res: FetchResult<CreateFunnelMutation>) => {
        this.funnel = new Funnel(res.data?.createFunnel);
        if (!this.userService.User?.isSemrushPlan())
          this.resourceTeamsService!.prepareCreateResources({
            funnelId: this.funnel.id,
          }).map((sub) => sub.toPromise());
        return res;
      }),
    );
  }

  editFunnel(): Observable<boolean> {
    this._loading$.next(true);
    const editObs: { [key: string]: Observable<any> | Observable<any>[] } = {
      editingFunnel: this.funnelGraphqlService.editFunnel(this.getFormObject(), this.funnel!.id),
    };

    this.formGroup.get(EFunnelSettingsForm.image)?.value
      ? (editObs[EFunnelSettingsForm.image] = this.funnelGraphqlService.createFunnelImage(
          this.funnel!.id,
          this.getImage(),
        ))
      : '';

    return new Observable<boolean>((observer) => {
      forkJoin(editObs)
        .subscribe({
          next: (res) => {
            this.funnel = res.editingFunnel.data?.editFunnel as Funnel;
            res.image ? (this!.funnel.image = res.image.data?.createFunnelImage as AttachmentOutputGraphql) : '';
            this.formGroup.get(EFunnelSettingsForm.image)?.reset();
            this.resourceTeamsService?.sendRequestsForResourceTeams({ funnelId: this.funnel.id }).subscribe({
              next: () => {
                observer.next(true);
              },
              error: () => {
                observer.next(false);
                this.s.error(
                  this.t.instant('Funnels.Settings.There is problem with sharing funnel in teams. Try again'),
                );
              },
            });
          },
          error: () => {
            observer.next(false);
          },
        })
        .add(() => {
          observer.next(true);
          observer.complete();
          this._loading$.next(false);
        });
    });
  }

  private getFormObject(): FunnelInputGraphql {
    return {
      name: this.formGroup.get(EFunnelSettingsForm.name)?.value,
      projectName: this.formGroup.get(EFunnelSettingsForm.projectName)?.value?.length
        ? this.formGroup.get(EFunnelSettingsForm.projectName)?.value[0]
        : '',
    };
  }

  private getImage() {
    return this.formGroup.get(EFunnelSettingsForm.image)?.value
      ? this.formGroup.get(EFunnelSettingsForm.image)?.value
      : null;
  }

  removeFunnel() {
    this._loading$.next(true);
    return this.funnelGraphqlService.removeFunnel(this.funnel!.id).pipe(
      map(() => {
        this.basePanelService.emitter.emit(BasePanelEvent.RELOAD_FUNNELS_TO_CONTEXT);
        const user = this.userService.User!;
        user.funnelsCount--;
        this.userService.User = user;
      }),
    );
  }

  clearService() {
    this.formGroup.reset();
    this.funnel = null;
    this.tabs = [];
    this.resourceTeamsService = null;
    this.sub.unsubscribe();
    this._loading$.next(false);
    this._changesInForm$.next(false);
  }
}
