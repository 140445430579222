/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Injectable()
export class NavigateService {
  private url = {
    current: '',
    previous: '',
    secondPrevious: '',
  };

  constructor(
    public router: Router,
    private route: ActivatedRoute,
  ) {}

  /**
   *
   *
   * @param {string} name
   * @param {*} [params] - you can add route params in object which could be replaced, e.x. {id: 23}
   * @param {NavigationExtras} [extras]
   * @returns
   * @memberof NavigateService
   */
  go(url = '', params?: Record<string, any>, extras?: NavigationExtras) {
    if (!url || url === '' || url === '/') {
      this.router.navigate(['/']);
    }

    const path = url.startsWith('/') ? url : `/${url}`;
    const routePath = this.getRouteWithRouteParams(path, params);

    return this.router.navigate([routePath], extras);
  }

  getRouteWithRouteParams(route: string, params: Record<string, any> = {}) {
    for (const key in params ?? {}) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      route = route.replace(`:${key}`, params[key]);
    }
    return route;
  }

  /**
   *
   *
   * @param {string} name
   * @param {*} [queryParams={}]
   * @param {*} [params]
   * @param {boolean} [clearCurrentParams]
   * @returns
   * @memberof NavigateService
   */
  getPath(
    url: string,
    queryParams: Record<string, any> = {},
    params?: Record<string, any>,
    clearCurrentParams?: boolean,
  ) {
    const path = url.startsWith('/') ? url : `/${url}`;

    !clearCurrentParams ? (queryParams = Object.assign(queryParams, this.route.snapshot.queryParams)) : null;
    const routePath = this.getRouteWithRouteParams(path, params);

    return routePath;
  }

  pushPreviousUrl(url: string) {
    this.url.secondPrevious = this.url.previous;
    this.url.previous = this.url.current;
    this.url.current = url;
  }

  /**
   *
   * Get previous URL
   * @readonly
   * @type {string}
   * @memberof NavigateService
   */
  get previousUrl(): string {
    return this.url.previous;
  }

  get currentUrl(): string {
    return this.url.current;
  }

  /**
   *
   * Get second previous URL
   * @readonly
   * @type {string}
   * @memberof NavigateService
   */
  get secondPreviousUrl(): string {
    return this.url.secondPrevious;
  }
}

export interface RoutePathName {
  [key: string]: string;
}
