/* eslint-disable @typescript-eslint/no-this-alias */
import {
  ListNew,
  setQuillCustomElementsEvents,
} from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-data/helpers/quill.helper';
import { EActivityTagName } from './enums/activity-tag-name.enum';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  Inject,
  Renderer2,
  Injector,
} from '@angular/core';
import { EActivityForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-form.enum';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { QuillModules } from 'ngx-quill';
import Quill from 'quill';
import 'quill-mention';
import { Config } from '@shared/configs/config';
import { BenchmarkTypeEnum } from '@modules/graphql/graphql-types';
import { EActivityBenchmarkForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-benchmark-form.enum';
import { ETacticControlState } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-edit-status.enum';
import { ActivityBenchmark, BenchmarkType } from '@shared/models/activity-benchmark.model';
import { TacticActivitiesParametersService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-activities-parameters.service';
import {
  EQuillElemClass,
  EQuillCustomBlot,
  CustomEmbedBlot,
  setCursorPosition,
  prepareQuillIcons,
  prepareQuillLinks,
  EQuillDenotationChar,
} from './helpers/quill.helper';
import { Regex } from '@shared/configs/regex';
import { TacticPreviewService } from '@modules/tactics/modules/tactic-preview/shared/services/tactic-preview.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TuiFileLike, TuiInputFilesComponent } from '@taiga-ui/kit';
import { rejectedImages } from '@shared/helpers/file-input.helper';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TuiDialogService } from '@taiga-ui/core';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import * as uuid from 'uuid';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-activity-form-data',
  templateUrl: './activity-form-data.component.html',
  styleUrls: ['./activity-form-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityFormDataComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly Config = Config;
  readonly Regex = Regex;
  readonly EActivityForm = EActivityForm;
  readonly EActivityTagName = EActivityTagName;
  readonly BenchmarkTypeEnum = BenchmarkTypeEnum;
  readonly EActivityBenchmarkForm = EActivityBenchmarkForm;
  readonly ETacticControlState = ETacticControlState;
  readonly EQuillElemClass = EQuillElemClass;
  readonly EQuillCustomBlot = EQuillCustomBlot;
  readonly EQuillDenotationChar = EQuillDenotationChar;
  @Input() activityControl: UntypedFormGroup | null = null;
  @ViewChild('fileInput') fileInput!: TuiInputFilesComponent;
  @ViewChild('imageInput') imageInput!: TuiInputFilesComponent;

  fileControl: UntypedFormControl = new UntypedFormControl();
  imageControl: UntypedFormControl = new UntypedFormControl();

  editorElem: Element | undefined = undefined;
  quillEditorRef: any;
  quillToolbar: any;
  quillIcons = Quill.import('ui/icons');
  benchmarkTypes: Array<BenchmarkType> = [];
  modules: QuillModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike', { list: 'ordered' }, { list: 'bullet' }],
        ['image', 'link', 'file', 'video', 'undo', 'redo'],
      ],
      handlers: {
        redo() {
          this.quill.history.redo();
        },
        undo() {
          this.quill.history.undo();
        },
      },
    },
    mention: {
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: [EQuillDenotationChar.BENCHMARKS, EQuillDenotationChar.ASSETS],
      source: async (searchTerm, renderList, mentionChar) => {
        const matches = await this.getMatchesList(searchTerm, mentionChar);
        renderList(matches);
      },
      onSelect: (item, insertItem) => {
        insertItem(item);
        // add events for Assets/Benchmarks newly inserted into the Quill editor text
        this.setDescriptionEvents();
      },
    },
    history: { delay: 2000, maxStack: 500, userOnly: true },
  };
  promptOptions$;

  async getMatchesList(searchTerm, mentionChar: string) {
    return new Promise((resolve) => {
      let values;
      let matches: Array<any> = [];

      if (mentionChar === EQuillDenotationChar.BENCHMARKS) {
        values = this.atValueBenchmarks;
      } else {
        values = this.hashValuesAssets;
      }

      if (searchTerm.length === 0) {
        matches = values;
      } else {
        for (let i = 0; i < values.length; i++) {
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
            matches.push(values[i]);
          }
        }
      }
      resolve(matches);
    });
  }
  get benchmarksArray(): UntypedFormArray | null {
    return this.activityControl ? this.tacticSettingsService.getBenchmarksArray(this.activityControl) : null;
  }

  get assetsArray(): UntypedFormArray | null {
    return this.activityControl ? this.tacticSettingsService.getAssetsArray(this.activityControl) : null;
  }

  get hashValuesAssets(): Array<{
    id: number;
    value: string;
    link: string;
    disabled?: boolean;
  }> {
    const result: Array<{
      id: number;
      value: string;
      link: string;
      disabled?: boolean;
    }> = [];
    this.assetsArray?.length ? null : result.push({ id: 1, value: 'No assets', link: '', disabled: true });

    this.assetsArray?.value.forEach((v) => {
      let assetResolutions = '';
      v.asset.selectedResolutions.forEach((r) => {
        assetResolutions += `${r.width}x${r.height}px,`;
      });

      const assetType = v.asset.asset.fileType || v.asset.asset.headline || v.asset.asset.primaryText;
      const assetName = `${v.asset.asset.placement.name} ${assetResolutions} ${assetType}`;

      // link
      result.push({
        id: v.asset.uuid,
        value: assetName,
        link: assetName,
      });
    });
    return result;
  }

  get atValueBenchmarks() {
    const result: Array<{
      id: number;
      value: string;
      link: string;
      disabled?: boolean;
    }> = [];
    this.benchmarksArray?.length
      ? null
      : result.push({
          id: 1,
          value: 'No benchmarks',
          link: '',
          disabled: true,
        });

    this.benchmarksArray?.value.forEach((v) => {
      let benchmarkName = '';
      let benchmarkLink = '';
      if (v.benchmark.type.type === BenchmarkTypeEnum.File) {
        benchmarkName = v.benchmark.attachment?.name || v.benchmark.file?.name;
        benchmarkLink = Config.ASSETS + v.benchmark.attachment?.file;
      } else {
        benchmarkName = v.benchmark.link;
        benchmarkLink = v.benchmark.link;
      }
      result.push({
        id: v.benchmark.uuid,
        value: benchmarkName,
        link: benchmarkLink,
      });
    });
    return result;
  }

  constructor(
    private readonly tacticSettingsService: TacticSettingsService,
    private readonly tacticActivitiesParametersService: TacticActivitiesParametersService,
    private changes: ChangeDetectorRef,
    private elementRef: ElementRef,
    private tacticPreviewService: TacticPreviewService,
    private t: TranslateService,
    private s: SnackbarService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    private renderer: Renderer2,
    private injector: Injector,
    public userService: UserService,
  ) {
    super();
    this.promptOptions$ = this.tacticSettingsService.getPrompts();
  }

  stringifyPrompt(t) {
    return t.name;
  }

  parsePrompts(x, y) {
    return x.id === y.id;
  }

  ngOnInit(): void {
    this.listenPreviewChanges();
    this.listenFileChanges();

    this.prepareQuill();
    setTimeout(() => {
      this.setDescriptionEvents();
    }, 500);
  }

  listenPreviewChanges() {
    this.tacticPreviewService.$changesInPreview.subscribe(() => this.changes.detectChanges());
  }

  listenFileChanges() {
    this.sub.add(this.fileControl.valueChanges.subscribe((file: File) => this.readFile(EActivityTagName.A, file)));
    this.sub.add(this.imageControl.valueChanges.subscribe((file: File) => this.readFile(EActivityTagName.IMG, file)));
  }

  prepareQuill() {
    Quill.register(CustomEmbedBlot);
    Quill.register(ListNew);

    prepareQuillIcons(this.quillIcons);
    prepareQuillLinks();
    this.getBenchmarkTypes();
  }

  getBenchmarkTypes() {
    this.tacticActivitiesParametersService.benchmarkTypes$.subscribe((v) => (this.benchmarkTypes = v));
  }

  /**
   * Auto format links on paste
   * @param event Event
   */
  onPaste(event) {
    event.preventDefault();
    const text = (event.clipboardData || (<any>window).clipboardData || window['clipboardData'])?.getData('text');
    // check if tooltip is visible - user is using toolbar
    const tooltip = this.elementRef.nativeElement.querySelector('.ql-tooltip.ql-editing:not(.ql-hidden)');
    if (tooltip) {
      const input = tooltip.querySelector('input') as HTMLInputElement;
      input.value = text;
    } else {
      // user is pasting directly into quill editor
      this.quillEditorRef.focus();
      const range: { index: number; length: number } = this.quillEditorRef.getSelection();
      // auto format urls
      if (range.length) {
        // replace selected text
        setCursorPosition(this.quillEditorRef, range.index);
        setTimeout(() => {
          this.quillEditorRef.insertText(range.index, text, Quill.sources.USER);
          setCursorPosition(this.quillEditorRef, range.index + text.length);
        }, 100);
      } else {
        if (Regex.url.test(text)) {
          const newUuid = uuid.v4();
          this.quillEditorRef.insertEmbed(
            range.index,
            EQuillCustomBlot.CUSTOM_EMBED,
            { id: newUuid, text, class: EQuillElemClass.LINK, href: text },
            Quill.sources.USER,
          );
          const benchmarkTypeLink = this.benchmarkTypes.find((v) => v.type === BenchmarkTypeEnum.Link);

          this.createBenchmark(benchmarkTypeLink, text, null, newUuid);
          setCursorPosition(this.quillEditorRef, range.index + 1);
        } else {
          // remove formatting on paste
          this.quillEditorRef.insertText(range.index, text, Quill.sources.USER);
          setCursorPosition(this.quillEditorRef, range.index + text.length);
        }
      }
    }
  }

  getEditorInstance(editorInstance: Quill) {
    this.quillEditorRef = editorInstance;
    // const mention = this.quillEditorRef.getModule('mention');
    this.quillToolbar = editorInstance.getModule('toolbar');
    this.addImageHandler();
    this.addFileHandler();
    this.addLinkHandler();

    this.addVideoHandler();
  }

  addLinkHandler() {
    const linkHandler = (value) => {
      const benchmarkTypeLink = this.benchmarkTypes.find((v) => v.type === BenchmarkTypeEnum.Link);
      if (value) {
        const tooltip = this.quillEditorRef.theme.tooltip;
        tooltip.edit('link');
        tooltip.textbox.placeholder = 'Embed URL';
        const that = this;
        tooltip.save = function (this: any) {
          let value = this.textbox.value;
          if (value) {
            // Quill.sources.USER actualize the formControl value of editor
            value = ~value.indexOf('http') ? value : 'http://' + value;
            const range: { index: number; length: number } = that.quillEditorRef.getSelection(true);
            let text = this.quill.getContents(range.index, range.length);
            text = text?.ops[0]?.insert || value;
            this.quill.deleteText(range.index, range.length);
            const newUuid = uuid.v4();
            this.quill.insertEmbed(
              range.index,
              EQuillCustomBlot.CUSTOM_EMBED,
              { id: newUuid, text, class: EQuillElemClass.LINK, href: value },
              Quill.sources.USER,
            );
            setCursorPosition(this.quill, range.index + 1);
            // add new Benchmark-Link from editor
            that.createBenchmark(benchmarkTypeLink, value, null, newUuid);
            const tooltip = that.elementRef.nativeElement.querySelector('.ql-tooltip.ql-editing');
            if (tooltip) {
              const input = tooltip.querySelector('input') as HTMLInputElement;
              input.value = ''; // reset after save
            }
          }
        };
      }
    };
    this.quillToolbar.addHandler('link', linkHandler);
  }

  addVideoHandler() {
    const videoHandler = (value) => {
      const benchmarkTypeLink = this.benchmarkTypes.find((v) => v.type === BenchmarkTypeEnum.Link);

      if (value) {
        const tooltip = this.quillEditorRef.theme.tooltip;
        const that = this;
        tooltip.save = function (this: any) {
          const range: { index: number; length: number } = that.quillEditorRef.getSelection(true);

          const value = this.textbox.value;
          if (value) {
            const newUuid = uuid.v4();
            this.quill.insertEmbed(
              range.index,
              EQuillCustomBlot.CUSTOM_EMBED,
              {
                id: newUuid,
                class: EQuillElemClass.VIDEO,
                href: value,
                text: value,
              },
              Quill.sources.USER,
            );
            setCursorPosition(this.quill, range.index + 1);
            // add new Benchmark-Link from editor
            that.createBenchmark(benchmarkTypeLink, value, null, newUuid);
          }
          tooltip.hide();
        };
        tooltip.edit('video');
        tooltip.textbox.placeholder = 'Embed Video';
      }
    };
    this.quillToolbar.addHandler('video', videoHandler);
  }

  addImageHandler() {
    this.quillToolbar.addHandler('image', () => this.imageInput?.nativeInput?.input?.click());
  }

  addFileHandler() {
    this.quillToolbar.addHandler('file', () => this.fileInput?.nativeInput?.input?.click());
  }

  rejectedImages(e: TuiFileLike | readonly TuiFileLike[]) {
    rejectedImages(e, this.s, this.t);
    this.changes.detectChanges();
  }

  handleFileErrors(tagName: string, file: File): Array<{ translate: string; data?: any }> {
    // check file type
    const testType =
      tagName === EActivityTagName.IMG ? Regex.fileImage.test(file.name) : !Regex.fileBanned.test(file.name);

    const maxSize = tagName === EActivityTagName.IMG ? Config.MAX_IMG_SIZE : Config.MAX_FILE_SIZE;
    const errors: Array<{ translate: string; data?: any }> = [];
    if (file.size > maxSize) {
      errors.push({
        translate: 'Tactics.Manage.Max file size {{size}} MB.',
        data: { size: Math.round(maxSize / 1000000) },
      });
    }

    if (!testType) {
      tagName === EActivityTagName.IMG
        ? errors.push({
            translate: 'Tactics.Manage.You could only upload images.',
          })
        : errors.push({
            translate: 'Tactics.Manage.Wrong file extensions. We do not accept: {{extensions}}.',
            data: { extensions: Config.FORBIDDEN_BENCHMARKS_EXTENSIONS },
          });
    }

    return errors;
  }

  readFile(tagName: string, file: File) {
    // check file type
    const errors: Array<{ translate: string; data?: any }> = this.handleFileErrors(tagName, file);

    // this.s.error(this.t.instant(
    if (errors.length) {
      errors.forEach((err: { translate: string; data?: any }) => this.s.error(this.t.instant(err.translate, err.data)));
      this.imageControl.setValue(null, { emitEvent: false });
      this.fileControl.setValue(null, { emitEvent: false });
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        const range = this.quillEditorRef.getSelection();
        let className = '';
        if (tagName === EActivityTagName.IMG) {
          className = EQuillElemClass.IMG;
        } else if (tagName === EActivityTagName.A) {
          className = EQuillElemClass.FILE;
        }
        const newUuid = uuid.v4();

        this.quillEditorRef.insertEmbed(
          range.index,
          EQuillCustomBlot.CUSTOM_EMBED,
          { id: newUuid, text: file.name, class: className, href: file.name },
          Quill.sources.USER,
        );
        setCursorPosition(this.quillEditorRef, range.index + 1);

        const benchmarkTypeFile = this.benchmarkTypes.find((v) => v.type === BenchmarkTypeEnum.File);
        // add new Benchmark-File from editor
        this.createBenchmark(benchmarkTypeFile, null, file, newUuid);
        // reset hidden input
        this.imageControl.setValue(null, { emitEvent: false });
        this.fileControl.setValue(null, { emitEvent: false });
      };
      reader.readAsDataURL(file);
    }
  }

  createBenchmark(benchmarkType: any, link: string | null, file: File | null, uuid: string) {
    const benchmarkControl: UntypedFormGroup = new UntypedFormGroup({
      [EActivityBenchmarkForm.benchmarkTypeId]: new UntypedFormControl(benchmarkType),
      [EActivityBenchmarkForm.link]: new UntypedFormControl(link),
      [EActivityBenchmarkForm.file]: new UntypedFormControl(file),
      [EActivityBenchmarkForm.activityId]: new UntypedFormControl(null),
      [EActivityBenchmarkForm.benchmark]: new UntypedFormControl(null),
      [EActivityBenchmarkForm.state]: new UntypedFormControl([ETacticControlState.CREATE]),
      [EActivityBenchmarkForm.uuid]: new UntypedFormControl(uuid),
    });

    this.tacticSettingsService.createBenchmark(this.benchmarksArray!, benchmarkControl, uuid);
    this.tacticSettingsService._changesInForm$.next(true);
    this.setDescriptionEvents(); // for newly added in description
  }

  // events inside description
  setDescriptionEvents() {
    const benchmarks: ActivityBenchmark[] = this.benchmarksArray?.value.map((v) => v.benchmark);
    const assets: ActivityAsset[] = this.assetsArray?.value.map((v) => v.asset);
    setQuillCustomElementsEvents(
      '.ql-editor',
      this.elementRef,
      benchmarks,
      assets,
      this.renderer,
      this.dialogService,
      this.sub,
      this.injector,
    );

    this.editorElem = this.elementRef.nativeElement.querySelector('.ql-editor');
  }
}
