import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

interface CheckboxItem {
  id: number;
  name: string;
  description?: string;
  image?: boolean;
}

@Component({
  selector: 'df-awsome-checkbox',
  templateUrl: './awsome-checkbox.component.html',
  styleUrls: ['./awsome-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AwsomeCheckboxComponent {
  @Input() checkboxItems: CheckboxItem[] = [];
  @Input() form!: FormGroup;
  @Input() fieldName!: string;

  checkboxError = false;

  constructor(private changes: ChangeDetectorRef) {}

  setOption(option: CheckboxItem, event: Event) {
    this.checkboxError = false;
    event.stopPropagation();
    event.preventDefault();
    this.form.get(this.fieldName)?.setValue(option);
    this.changes.detectChanges();
  }
}
