/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { TuiFilterComponent } from '@taiga-ui/kit';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[tuiFilterMaxItems]',
})
export class TuiFilterMaxItemsDirective implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();
  @Input() tuiFilter!: TuiFilterComponent<any>;
  @Input() maxItems = 1;

  ngOnInit() {
    this.listenItemsSelected();
    if (this.maxItems === 0) this.maxItems = 1;
  }

  listenItemsSelected() {
    const sub = this.tuiFilter.control?.valueChanges.subscribe(() => {
      const arr = this.tuiFilter.control?.value || [];
      const length = arr.length;
      if (length > this.maxItems && Array.isArray(arr)) {
        arr.shift();
        this.tuiFilter.control?.patchValue(arr, { emitEvent: false });
      }
    });
    this.sub.add(sub);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
