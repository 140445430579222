<form
  *ngIf="form"
  [formGroup]="form"
  [validate-form]="form"
  (ngSubmit)="form.markAllAsTouched(); formSubmitted.emit(true)"
>
  <div class="mt-5 flex flex-col justify-start items-stretch">
    <div *ngFor="let configuration of integration.inputsConfigurations">
      <p
        class="tui-text_menu-header tui-text-02 uppercase text-center"
        *ngIf="InputTypeEnum.InputFileFromUnsplash === configuration.type"
      >
        {{ 'Funnels.Graphics.OR' | translate }}
      </p>
      <df-content-generator-form-fields-template
        (removeControlEvent)="removeControl($event)"
        (addControlEvent)="addControl($event)"
        [form]="form"
        [configuration]="configuration"
      ></df-content-generator-form-fields-template>
    </div>
  </div>
</form>
