import { gql } from 'apollo-angular';

export const GetCanvasTemplatesDocument = gql`
  query getCanvasTemplates {
    getCanvasTemplates {
      id
      name
      prompt {
        id
        name
        description
      }
      description
      status
      category {
        name
        id
        description
      }
    }
  }
`;
