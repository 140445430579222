import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPlanComponent {
  constructor(
    private authService: AuthStorageService,
    private readonly n: NavigateService,
    private readonly userService: UserService,
  ) {}

  get isMarketingCampaignUser(): boolean {
    return !!this.userService.User?.isMarketingCampaignUser;
  }

  get userId(): number {
    return this.userService.User?.id || 0;
  }

  logout() {
    this.authService.logout();
    this.n.go('sign-in');
  }
}
