<div class="template-content-wrapper">
  <df-full-list-loader *ngIf="templateService.loading$ | async"></df-full-list-loader>
  <tui-input
    icon="tuiIconSearchLarge"
    iconAlign="left"
    [tuiTextfieldCleaner]="true"
    [tuiTextfieldLabelOutside]="true"
    [(ngModel)]="searchPhrase"
    (ngModelChange)="onSearch($event)"
    class="search md:flex-grow"
  >
    {{ 'Tactics.List.Search by key words' | translate }}
    <input tuiTextfield />
  </tui-input>
  <div
    class="template-items-container"
    *ngIf="(templateService.loading$ | async) === false"
  >
    <div
      class="template-item"
      *ngFor="let integrationTemplates of templateService.collection$ | async"
    >
      <div
        class="category-container"
        *ngIf="integrationTemplates.integrations.length"
      >
        <p
          class="flex"
          (click)="integrationTemplates.isExpanded = !integrationTemplates.isExpanded"
        >
          <span class="tui-text_body-m-15 tui-text-02 mr-2">{{ integrationTemplates.category.name }}</span>
          <button
            tuiIconButton
            type="button"
            size="xs"
            appearance="flat"
            icon="tuiIconChevronUp"
            [ngClass]="{ 'rotate-180': !integrationTemplates.isExpanded }"
          ></button>
        </p>
        <div *ngIf="integrationTemplates.isExpanded">
          <div
            *ngFor="let integration of integrationTemplates.integrations"
            class="integration-item"
            (click)="onSelectIntegration(integration)"
          >
            <svg-icon
              *ngIf="integration.icon"
              [src]="Config.ASSETS + integration.icon"
              [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
            ></svg-icon>
            <span class="tui-text_body-m-15 tui-text-01">{{ integration.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
