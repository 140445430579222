import { gql } from 'apollo-angular';

export const GetScheduledPostsDocument = gql`
  query getScheduledPosts {
    getScheduledPosts {
      id
      post
      platforms
      status
      created
      errors {
        message
        platform
      }
    }
  }
`;
