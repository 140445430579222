<div
  class="select-wrapper"
  [class.display-inline]="displayInline"
>
  <ng-content></ng-content>
  <span class="icon-container">
    <tui-svg
      class="dropdown-icon"
      src="tuiIconChevronDownLarge"
      [class.icon_rotated]="isOpen"
    ></tui-svg>
  </span>
</div>
