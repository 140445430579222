import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { IFilter } from '@shared/interfaces/filter.interface';
import { FilterStepsQuery } from '@modules/tactics/shared/graphql/queries/filter-steps.query.generated';
import { FilterStepsDocument } from '@modules/tactics/shared/graphql/queries/filter-steps.query';
import { FetchResult } from '@apollo/client/core';
import { FilterTypes, OrderTypes, TacticListTypeEnum } from '@modules/graphql/graphql-types';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Step } from '@shared/models/step.model';
import { FilterTacticsCategoriesDocument } from '@modules/tactics/shared/graphql/queries/filter-tactics-categories.query';
import { FilterTacticsCategoriesQuery } from '@modules/tactics/shared/graphql/queries/filter-tactics-categories.query.generated';
import { forkJoin } from 'rxjs';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ListSortItem } from '@shared/modules/ui/components/list-sub-header/list-sub-header.component';
import { TuiDialogService } from '@taiga-ui/core';
import { BaseFilters } from '@shared/interfaces/base-filters.interface';
import { BaseFiltersService } from '@shared/services/base-filters.service';
import { NavigateService } from '@core/routes/services/navigate.service';

@Injectable({
  providedIn: 'root',
})
export class TacticsFiltersService extends BaseFiltersService implements BaseFilters {
  sorts: ListSortItem[] = [
    {
      sortField: 'a.createdAt',
      name: 'Most recent',
      sortDirection: OrderTypes.Desc,
    },
    {
      sortField: 'a.name',
      name: 'A-Z',
      sortDirection: OrderTypes.Asc,
    },
    {
      sortField: 'a.popularity',
      name: 'Most popular',
      sortDirection: OrderTypes.Desc,
    },
  ];
  readonly defaultSort: ListSortItem = {
    sortField: 'a.createdAt',
    name: 'Most recent',
    sortDirection: OrderTypes.Desc,
  };
  steps: IFilter<Step>[] = [];
  stepsLoaded?: boolean = false;
  categories: IFilter<any>[] = [];
  paidFilters: { name: string; value: boolean }[] = [
    { name: 'Paid tactics', value: true },
    { name: 'Free tactics', value: false },
  ];
  purchasedFilters: { name: string; value: FilterTypes }[] = [
    { name: 'Copied from library', value: FilterTypes.IsNot },
    { name: 'Created', value: FilterTypes.Is },
  ];
  dfFilters: { name: string; value: boolean }[] = [{ name: 'DF Tactics', value: true }];
  proFilters: { name: string; value: boolean }[] = [{ name: 'Pro profile', value: true }];
  filterFormGroup: UntypedFormGroup = new UntypedFormGroup({
    sort: new UntypedFormControl(this.sorts[0]),
    step: new UntypedFormControl([]),
    type: new UntypedFormControl(TacticListTypeEnum.All),
    search: new UntypedFormControl(''),
    categories: new UntypedFormControl([]),
    isPaid: new UntypedFormControl(null),
    purchased: new UntypedFormControl(null),
    pro: new UntypedFormControl([]),
    df: new UntypedFormControl(null),
  });
  initCategoriesIds: number[] = [];
  initStepsIds: number[] = [];
  initPaid: boolean | null = null;
  initPurchased: FilterTypes | null = null;
  initPro: boolean | null = null;
  initDF: boolean | null = null;
  areFiltersLoaded = false;
  $filtersLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Inject(Injector) readonly injector: Injector,
    @Inject(TuiDialogService) readonly dialogService: TuiDialogService,
    private apollo: Apollo,
    private s: SnackbarService,
    private t: TranslateService,
    private n: NavigateService,
  ) {
    super(injector, dialogService);
    this.getFilters();
  }

  resetFiltersForm() {
    this.filterFormGroup = new UntypedFormGroup({
      sort: new UntypedFormControl(this.sorts[0]),
      step: new UntypedFormControl([]),
      type: new UntypedFormControl(TacticListTypeEnum.All),
      search: new UntypedFormControl(''),
      categories: new UntypedFormControl([]),
      isPaid: new UntypedFormControl(null),
      purchased: new UntypedFormControl(null),
      pro: new UntypedFormControl([]),
      df: new UntypedFormControl(null),
    });
  }

  clearFilters() {
    this.filterFormGroup.patchValue(
      {
        sort: this.sorts[0],
        step: [],
        type: TacticListTypeEnum.All,
        search: '',
        categories: [],
        isPaid: null,
        purchased: null,
        pro: [],
        df: null,
      },
      { emitEvent: true },
    );
  }

  getFilters() {
    forkJoin({
      steps: this.apollo.query<FilterStepsQuery>({
        query: FilterStepsDocument,
        variables: {
          input: {},
        },
      }),
      categories: this.apollo.query<FilterTacticsCategoriesQuery>({
        query: FilterTacticsCategoriesDocument,
        variables: {
          input: {
            records: 120,
          },
        },
      }),
    }).subscribe({
      next: (data: { steps: FetchResult<FilterStepsQuery>; categories: FetchResult<FilterTacticsCategoriesQuery> }) => {
        this.steps = data.steps.data?.filterSteps.records! as IFilter<Step>[];
        this.categories = data.categories.data?.filterTacticsCategories.records!;
        this.$filtersLoaded.emit(true);
        this.areFiltersLoaded = true;
      },
      error: () => {
        this.s.error(
          this.t.instant('Tactics.List.There is problem with getting filters. Refresh your view and try again.'),
        );
      },
    });
  }

  public checkFilters(): void {
    this.checkChosenCategories();
    this.checkChosenSteps();
    this.checkIsPaid();
    this.checkPurchased();
    this.checkChosenPro();
    this.checkChosenDF();
  }

  private checkChosenCategories() {
    const chosen: IFilter<any>[] = [];
    this.categories.map((category: IFilter<any>) => {
      this.initCategoriesIds.indexOf(category.id) !== -1 ? chosen.push(category) : '';
    });
    this.initCategoriesIds = [];
    chosen.length ? this.filterFormGroup.get('categories')?.patchValue(chosen, { emitEvent: false }) : '';
  }

  private checkChosenSteps() {
    const chosen: IFilter<Step>[] = this.steps.filter(
      (step: IFilter<Step>) => this.initStepsIds.indexOf(step.id) !== -1,
    );
    this.initStepsIds = [];
    chosen.length ? this.filterFormGroup.get('step')?.patchValue(chosen, { emitEvent: false }) : '';
  }

  private checkIsPaid() {
    if (this.initPaid !== null) {
      this.filterFormGroup.get('isPaid')?.patchValue(this.paidFilters.find((fil) => fil.value === this.initPaid)!, {
        emitEvent: false,
      });
      this.initPaid = null;
    }
  }

  private checkPurchased() {
    this.filterFormGroup
      .get('purchased')
      ?.patchValue(this.purchasedFilters.find((fil) => fil.value === this.initPurchased) ?? null, { emitEvent: false });
    this.initPurchased = null;
  }

  private checkChosenPro() {
    if (this.initPro !== null) {
      this.filterFormGroup.get('pro')?.patchValue(this.proFilters.filter((fil) => fil.value === this.initPro)!, {
        emitEvent: false,
      });
      this.initPro = null;
    }
  }

  private checkChosenDF() {
    this.filterFormGroup.get('df')?.patchValue(
      this.dfFilters.filter((fil) => {
        return fil.value === this.initDF;
      })!,
      {
        emitEvent: false,
      },
    );
  }

  public setStepFilter(step: Step) {
    this.filterFormGroup.get('step')?.patchValue([step], { emitEvent: false });
    this.initStepsIds = [Number(step.id)];
  }

  addDefaultSort(): void {
    if (!this.defaultSortOnList()) {
      this.sorts.unshift(this.defaultSort);
      this.filterFormGroup.get('sort')?.setValue(this.sorts[0]);
    }
  }

  removeDefaultSort(): void {
    if (this.defaultSortOnList()) {
      this.sorts.shift();
      this.filterFormGroup.get('sort')?.setValue(this.sorts[0]);
    }
  }

  private defaultSortOnList(): boolean {
    return this.sorts.some((s) => s.sortField === this.defaultSort.sortField);
  }
}
