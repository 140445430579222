<div class="statement-select w-full md:w-auto flex items-center">
  <tui-hosted-dropdown
    #dropdown
    class="relative"
    [class.z-50]="dropdown.open"
    (openChange)="changes.detectChanges(); dropdownOpen.emit(dropdown.open)"
    [content]="content"
    [canOpen]="controlName!.length > 0"
    [tuiDropdownAlign]="'left'"
  >
    <df-statement-placeholder
      [name]="name"
      *ngIf="!form?.get(controlName)?.value || !form?.get(controlName)?.value?.length"
    ></df-statement-placeholder>
    <div
      class="h-10 w-3 invisible"
      #toClick
    ></div>
  </tui-hosted-dropdown>

  <ng-template #content>
    <div class="flex flex-col">
      <tui-data-list>
        <tui-opt-group
          tuiMultiSelectGroup
          [(ngModel)]="value"
        >
          <button
            [class.option-checkbox]="maxItems === 1"
            *ngFor="let item of items"
            (click)="onChange(item)"
            [value]="item.id"
            tuiOption
          >
            <span class="max-w-xs whitespace-normal block">
              {{ 'Statement.Form.' + item.name | translate }}
            </span>
          </button>
        </tui-opt-group>
      </tui-data-list>
    </div>
  </ng-template>
  <df-statement-input-error
    [control]="form.get(controlName)"
    [formSubmitted]="formSubmitted"
  ></df-statement-input-error>
</div>
<div
  class="fixed top-0 left-0 right-0 bottom-0 z-40"
  *ngIf="dropdown.open"
  (click)="dropdown.updateOpen(false)"
></div>
