<div class="header">
  <h2 class="header-title">Newsletter</h2>
  <div class="header-icon">
    <df-tactic-step-icon
      (click)="regenerateClick.emit()"
      [type]="'process'"
    ></df-tactic-step-icon>
  </div>
</div>

<article>
  <h1>{{ isRegenerating() ? '...' : item().title }}</h1>
  <span>
    {{ isRegenerating() ? '...' : item().description }}
  </span>
</article>

<article>
  <h2>
    {{ isRegenerating() ? '...' : item().call_to_action }}
  </h2>
</article>
