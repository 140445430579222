<df-full-list-loader *ngIf="loading$ | async"></df-full-list-loader>

<section class="recommender flex flex-col h-full items-start ml-72 justify-center">
  <h1 class="mr-44 font-bold">Choose your goal</h1>
  <form
    class="flex flex-col w-[550px]"
    [formGroup]="form"
    [validate-form]="form"
    (submit)="onSubmit()"
  >
    <df-awsome-checkbox
      [form]="form"
      [fieldName]="'answer'"
      [checkboxItems]="(items$ | async) ?? []"
    ></df-awsome-checkbox>

    <button
      type="submit"
      tuiButton
    >
      {{ 'Recommender.Save' | translate }}
    </button>
  </form>
</section>
