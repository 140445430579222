<div
  class="w-full flex flex-row justify-between border border-solid border-gray-200 p-3 mb-1 rounded-lg item relative"
  [class.tui-base-02]="disabled"
>
  <div class="block mr-2 md:mr-4 w-6 flex-shrink-0 flex-grow-0 flex flex-col justify-center items-center">
    <svg-icon
      [src]="Config.ASSETS + benchmark.type.image!"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      *ngIf="benchmark.type"
    ></svg-icon>
  </div>

  <div
    class="flex flex-col flex-grow"
    [class.opacity-50]="disabled"
  >
    <span class="tui-text_body-s-2 tui-text-01">{{ benchmark.type.name }}</span>
    <span class="w-full tui-text_body-xs tui-text-01 overflow-ellipsis overflow-hidden line-clamp-1 break-words">
      {{
        benchmark.type.type === BenchmarkTypeEnum.Link
          ? benchmark.link
          : benchmark.attachment?.name ?? benchmark.file?.name
      }}
    </span>
  </div>

  <div
    class="md:absolute md:right-3 md:pl-2 flex flex-row justify-end items-center flex-shrink-0 ml-2 duration-200 buttons-hidden"
    [class.tui-base-02]="disabled"
    [class.tui-base-01-bg]="!disabled"
  >
    <button
      *ngIf="editMode"
      (click)="disabled ? restoreBenchmark() : removeBenchmark()"
      class="mr-2 rounded-lg"
      tuiIconButton
      type="button"
      appearance="outline"
      [icon]="disabled ? iconRestore : iconTrash"
      size="s"
      shape="square"
      [disabled]="removeLoading"
      [showLoader]="removeLoading"
    ></button>
    <ng-template #iconTrash>
      <svg-icon
        class="tui-text-04"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
        [src]="'/assets/img/_ic_trash.svg'"
      ></svg-icon>
    </ng-template>
    <ng-template #iconRestore
      ><svg-icon
        class="tui-text-04"
        [src]="'/assets/img/ic_refresh.svg'"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
      ></svg-icon
    ></ng-template>
    <button
      *ngIf="editMode && !disabled"
      (click)="editBenchmark()"
      class="mr-2 rounded-lg"
      tuiIconButton
      type="button"
      appearance="outline"
      [icon]="iconEdit"
      size="s"
      shape="square"
    ></button>
    <ng-template #iconEdit>
      <svg-icon
        class="tui-text-04"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
        [src]="'/assets/img/ic-edit.svg'"
      ></svg-icon>
    </ng-template>
    <button
      *ngIf="benchmark.type.type === BenchmarkTypeEnum.File && !disabled"
      (click)="downloadAttachment()"
      class="mr-2 rounded-lg"
      tuiIconButton
      type="button"
      appearance="outline"
      [icon]="iconDownload"
      size="s"
      shape="square"
    ></button>
    <ng-template #iconDownload>
      <svg-icon
        class="tui-text-04"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
        [src]="'/assets/img/ic-download.svg'"
      ></svg-icon>
    </ng-template>
    <a
      *ngIf="benchmark.type.type === BenchmarkTypeEnum.Link && !disabled"
      class="rounded-xl tui-primary tui-accent-13-bg"
      tuiButton
      type="button"
      size="s"
      appearance="accent"
      (click)="onNavigate()"
    >
      <span class="tui-text_body-m-2 no-underline">{{ 'view' | translate }}</span>
    </a>
  </div>
</div>
