import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { StatementFormService } from '../shared/services/statement-form.service';

@Injectable()
export class StatementNextStepInFunnelGuard implements CanActivate {
  constructor(
    private statementFormService: StatementFormService,
    private navigateService: NavigateService,
    private readonly userService: UserService,
    private readonly router: Router,
  ) {}

  canActivate() {
    if (this.statementFormService.isFormReady) return true;
    const funnelId: number | null = this.resolveFunnelId();
    if (!funnelId) return false;
    return this.router.navigate([this.resolveRedirectPath(funnelId)]);
  }

  private resolveFunnelId(): number | null {
    return this.userService?.User?.contextFunnel?.id ?? null;
  }

  private resolveRedirectPath(funnelId: number): string {
    return this.navigateService.getPath('funnels/manage/:id/statement/1', {}, { id: funnelId });
  }
}
