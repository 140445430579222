<div class="flex flex-col justify-start items-stretch">
  <h3 class="tui-text_h5 mb-2 mt-0">
    {{ 'Funnels.Manage.New tactic' | translate }}
  </h3>
  <p class="tui-text-02 mb-5">
    {{ 'Funnels.Manage.Add tactics to the funnel' | translate }}
  </p>
  <form
    [formGroup]="form"
    [validate-form]="form"
    (ngSubmit)="submit()"
  >
    <div
      class="tui-form__row"
      [class.pb-5]="loading$ | async"
    >
      <tui-input
        [formControlName]="EFunnelCreateTacticForm.name"
        [attr.data-error-control]="EFunnelCreateTacticForm.name"
      >
        {{ 'Funnels.Manage.Tactic name' | translate }}
      </tui-input>
      <df-error-input
        *ngIf="(loading$ | async) === false"
        [control]="form.get(EFunnelCreateTacticForm.name)"
      ></df-error-input>
    </div>

    <div class="tui-form__row">
      <label class="tui-text-02 labels mb-2 flex flex-row justify-start items-center">
        {{ 'Tactics.funnel step' | translate }}
        <svg-icon
          class="ml-2 tui-text-04"
          [svgStyle]="{ width: '0.75rem', height: '0.75rem' }"
          [src]="'/assets/img/ic-info.svg'"
          [tuiHint]="stepTooltip"
        ></svg-icon>
        <ng-template #stepTooltip>
          <div [innerHTML]="'Tactics.Manage.stepInfo' | translate"></div>
        </ng-template>
      </label>
      <tui-filter
        (toggledItem)="handleFunnelStepSelected($event)"
        [formControlName]="EFunnelCreateTacticForm.funnelSteps"
        [attr.data-error-control]="EFunnelCreateTacticForm.funnelSteps"
        size="l"
        [content]="stepContent"
        [identityMatcher]="identityMatcher"
        [items]="tacticParametersService.steps"
      ></tui-filter>
      <ng-template
        let-data
        #stepContent
      >
        <div class="flex items-center">
          <svg-icon
            [src]="'/assets/img/ic-' + data.object.type + '.svg'"
            class="mr-2"
          ></svg-icon>
          {{ data.name }}
        </div>
      </ng-template>
      <df-error-input [control]="form.get(EFunnelCreateTacticForm.funnelSteps)"></df-error-input>
    </div>

    <div class="flex flex-col justify-start items-stretch sm:flex-row sm:justify-between sm:items-center mt-8">
      <div class="flex justify-start items-center">
        <tui-checkbox-labeled [formControlName]="EFunnelCreateTacticForm.another">
          <span class="-mt-1 text-sm tui-text-02 font-medium leading-4">{{
            'Funnels.Manage.Create another tactic' | translate
          }}</span>
        </tui-checkbox-labeled>
      </div>

      <div class="mt-2 w-full sm:w-auto flex justify-end sm:mt-0 flex-grow-0">
        <button
          size="xl"
          tuiButton
          appearance="outline"
          type="button"
          (click)="cancel()"
          [disabled]="!!(loading$ | async)"
          class="mr-2"
        >
          {{ 'cancel' | translate }}
        </button>
        <button
          size="xl"
          tuiButton
          appearance="primary"
          type="submit"
          [disabled]="!!(loading$ | async)"
          [showLoader]="!!(loading$ | async)"
        >
          {{ 'save' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
