import { XPartnerService } from './../../../../shared/services/x-partner.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Config } from '@shared/configs/config';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { TuiDialogService } from '@taiga-ui/core';
import { UserService } from '@shared/services/user.service';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { DateTime } from 'luxon';
import { interval } from 'rxjs';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';

@Component({
  selector: 'df-end-trial',
  templateUrl: './end-trial.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EndTrialComponent extends AbstractSubscriptionComponent {
  Config = Config;

  loading = false;
  lastSendTime: DateTime | null = null;

  constructor(
    public n: NavigateService,
    private authStorageService: AuthStorageService,
    private userService: UserService,
    private authGraphqlService: AuthGraphqlService,
    private changes: ChangeDetectorRef,
    private t: TranslateService,
    private s: SnackbarService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    public xPartnerService: XPartnerService,
  ) {
    super();
  }

  closeAllDialogs() {
    this.sub.add(
      this.dialogService.subscribe((dialogOption) => dialogOption.map((dialog) => dialog.completeWith(true))),
    );
  }

  sendEmailAgain() {
    this.lastSendTime = DateTime.local().plus({ seconds: 30 });
    this.startSendAgainInterval();
    this.loading = true;
    this.changes.detectChanges();
    this.authGraphqlService.resendEmailConfirm(this.userService.User?.email!).subscribe(() => {
      this.s.success(this.t.instant('Auth.EndTrial.Confirmation email was send again'));
      this.loading = false;
      this.changes.detectChanges();
    });
  }

  startSendAgainInterval() {
    const int = interval(1000).subscribe(() => {
      if (this.lastSendTime!.diffNow('seconds').seconds < 1) {
        this.lastSendTime = null;
        int.unsubscribe();
      }
      this.changes.detectChanges();
    });
    this.sub.add(int);
  }

  backToSignIn() {
    this.authStorageService.logout();
    this.closeAllDialogs();
    this.n.go('sign-in');
  }
}
