import { gql } from 'apollo-angular';
import { TeamFragment } from '@modules/user-settings/modules/teams/shared/graphql/fragments/team.fragment';

export const EditTeamDocument = gql`
  mutation editTeam($input: EditTeamInputGraphql!, $icon: Upload) {
    editTeam(input: $input, icon: $icon) {
      ...TeamFragment
    }
  }
  ${TeamFragment}
`;
