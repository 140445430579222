import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';

@Component({
  selector: 'df-onboarding-congratulations-modal',
  templateUrl: './onboarding-congratulations-modal.component.html',
  styleUrls: ['./onboarding-congratulations-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingCongratulationsModalComponent {
  constructor(@Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<any, number>) {}

  close(): void {
    this.context.completeWith(true);
  }
}
