<ng-container *ngIf="!disableAllTabs">
  <div
    data-test="header-tab"
    *ngFor="let tab of tabs"
  >
    <a
      [routerLink]="tab.route"
      [queryParams]="tab.queryParams"
      routerLinkActive="active"
    >
      <svg-icon
        [src]="tab.icon"
        *ngIf="tab.icon"
        class="mr-4"
      ></svg-icon>
      {{ tab.name }}
    </a>
  </div>
</ng-container>
