import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { ContentGeneratorAssetTileComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/components/content-generator-tactic-details/components/content-generator-asset-tile/content-generator-asset-tile.component';
import { ContentGeneratorEventEnum } from '@modules/funnels/shared/enums/content-generator-selection-type.enum';
import { filter, tap } from 'rxjs/operators';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { FunnelContentGeneratorService } from '../../services/funnel-content-generator.service';
import { ContentGeneratorEventService } from '@modules/funnels/modules/funnel-manage/shared/services/content-generator-event.service';

@Component({
  selector: 'df-content-generator-tactic-details',
  templateUrl: './content-generator-tactic-details.component.html',
  styleUrls: ['./content-generator-tactic-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorTacticDetailsComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly TacticDetailsTabType = TacticDetailsTabType;
  readonly EOnboardingStep = EOnboardingStep;
  readonly tabs: TacticDetailsTab[] = [
    {
      title: 'Funnels.Content generator.Assets',
      type: TacticDetailsTabType.ASSETS,
    },
    {
      title: 'Funnels.Content generator.Description',
      type: TacticDetailsTabType.DESCRIPTION,
    },
  ];

  activeTab: TacticDetailsTabType = TacticDetailsTabType.ASSETS;

  readonly expandAll: UntypedFormControl = new UntypedFormControl(false);
  @ViewChildren(ContentGeneratorAssetTileComponent)
  assets?: QueryList<ContentGeneratorAssetTileComponent>;

  constructor(
    private readonly changes: ChangeDetectorRef,
    public readonly funnelContentGeneratorService: FunnelContentGeneratorService,
    public readonly onboardingService: OnboardingService,
    private readonly contentGeneratorEventService: ContentGeneratorEventService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenExpandAllChanges();
    this.listenSelectionChanges();
    this.listenOnboardingStepChange();
  }

  private listenExpandAllChanges(): void {
    this.sub.add(this.expandAll.valueChanges.subscribe((val) => this.assets?.forEach((asset) => asset.toggle(val))));
  }

  private listenSelectionChanges(): void {
    this.sub.add(
      this.contentGeneratorEventService
        .getSubscription()
        .pipe(
          tap(() => this.changes.detectChanges()),
          filter((types: ContentGeneratorEventEnum[]) => types.includes(ContentGeneratorEventEnum.ACTIVITY_CHANGE)),
        )
        .subscribe(() => this.changeTab(this.tabs[0].type)),
    );
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  changeTab(tab: TacticDetailsTabType): void {
    this.activeTab = tab;
    this.changes.detectChanges();
  }
}

enum TacticDetailsTabType {
  ASSETS,
  DESCRIPTION,
}

interface TacticDetailsTab {
  title: string;
  type: TacticDetailsTabType;
}
