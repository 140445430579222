import { gql } from 'apollo-angular';

export const FilterTacticTypesDocument = gql(/* GraphQL*/ `query filterTacticsTypes($input: FilterInputGraphql!)
{
  filterTacticsTypes(input: $input)
  {
    records{
      name,
      id
    },
    total
  }
}

`);
