import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserService } from '@shared/services/user.service';

@Injectable()
export class LoginAsUserGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.userService.User && this.userService.User.isAdmin) return true;
    return false;
  }
}
