import { gql } from 'apollo-angular';
import { FunnelFragment } from '@modules/funnels/shared/graphql/fragments/funnel.fragment';

export const ShareFunnelDocument = gql`
  mutation shareFunnel($input: FunnelShareInputGraphql!) {
    shareFunnel(input: $input) {
      ...Funnel
    }
  }
  ${FunnelFragment}
`;
