import { gql } from 'apollo-angular';

export const FilterStatementsParametersDocument =
  gql(/* GraphQL*/ `query filterStatementsParameters($input: FilterInputGraphql!, $category: StatementsParametersTypesEnum!)
{
  filterStatementsParameters(input: $input, category: $category)
  {
    records
    {
      id
      name
      object
      {
        id
        name
        isB2b
        isB2c
        icon
      }
    }
  }
}
`);
