import { gql } from 'apollo-angular';
import { ContentGenerationOutputFragment } from '@modules/funnels/shared/graphql/fragments/content-generation-output.fragment';

export const GenerateDefaultImagesDocument = gql`
  mutation generateDefaultImages($funnelId: Int!) {
    generateDefaultImages(funnelId: $funnelId) {
      ...ContentGenerationOutput
    }
  }
  ${ContentGenerationOutputFragment}
`;
