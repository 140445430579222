import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { cloneDeep } from '@apollo/client/utilities';
import { FieldCategoryEnum, FieldTypeEnum, StatementInputGraphql } from '@modules/graphql/graphql-types';
import { UserService } from '@shared/services/user.service';
import {
  B2B_NAME,
  B2C_NAME,
  EXPECTED_LENGTH_FOR_MULTI_SELECT,
  EXPECTED_LENGTH_FOR_SINGLE_SELECT,
  FUNNEL_ID_FORM_CONTROL_NAME,
  LEADS_GOAL_NAME,
  SALES_GOAL_NAME,
  SHORT_TEXT_MAX_LENGTH,
  STEP_1_MANDATORY_FIELDS,
  STEP_2_MANDATORY_FIELDS,
  STEP_3_MANDATORY_FIELDS,
} from '../models/statement.configuration';
import {
  Statement,
  StatementAnswer,
  StatementDependency,
  StatementFormControlDependency,
  StatementInput,
  StatementInputOption,
  StepValidationResult,
} from '../models/statement.interface';
import { naturalNumberValidator, statementFormArrayValidator } from '../validators/statement-form.validator';

@Injectable({
  providedIn: 'root',
})
export class StatementFormService {
  stepValidationResults: StepValidationResult[] = [];
  private _isFromReady = false;
  private statementConfigurationId: number | null = null;

  private _form: UntypedFormGroup = new UntypedFormGroup({});
  private _targetSegmentB2bId: number | null = null;
  private _targetSegmentB2cId: number | null = null;
  private _salesGoalId: number | null = null;
  private _leadsGoalId: number | null = null;
  private statement: Statement | null = null;

  private statementFormDependencies: StatementFormControlDependency[] = [];

  get form(): UntypedFormGroup {
    return this._form;
  }

  get targetSegmentB2bId(): number | null {
    return this._targetSegmentB2bId;
  }

  get targetSegmentB2cId(): number | null {
    return this._targetSegmentB2cId;
  }

  get leadsGoalId(): number | null {
    return this._leadsGoalId;
  }

  get salesGoalId(): number | null {
    return this._salesGoalId;
  }

  get isFormReady(): boolean {
    return this._isFromReady;
  }

  get isB2C(): boolean {
    const values: any[] = this.form.get(FieldCategoryEnum.TargetSegment)?.value;
    return !!(values?.length && this.targetSegmentB2cId !== null && values[0].id === this.targetSegmentB2cId);
  }

  get isB2B(): boolean {
    const values: any[] = this.form.get(FieldCategoryEnum.TargetSegment)?.value;
    return !!(values?.length && this.targetSegmentB2bId !== null && values[0].id === this.targetSegmentB2bId);
  }

  get isSalesGoal(): boolean {
    const values: any[] = this.form.get(FieldCategoryEnum.GoalType)?.value;
    return !!(values?.length && this._salesGoalId !== null && values[0].id === this._salesGoalId);
  }

  get isLeadsGoal(): boolean {
    const values: any[] = this.form.get(FieldCategoryEnum.GoalType)?.value;
    return !!(values?.length && this._leadsGoalId !== null && values[0].id === this._leadsGoalId);
  }

  constructor(private readonly userService: UserService) {
    this.prepareForm();
  }

  private prepareForm(): void {
    this._isFromReady = false;
    this._form = new UntypedFormGroup({});
    this._form.addControl(FUNNEL_ID_FORM_CONTROL_NAME, new UntypedFormControl());
    this.statementFormDependencies = [];
    this.initializeStepValidationResults();
  }

  public addFormControl(statementInput: StatementInput): void {
    switch (statementInput.type) {
      case FieldTypeEnum.ShortText:
        this.handleTextFieldType(statementInput, SHORT_TEXT_MAX_LENGTH);
        break;
      case FieldTypeEnum.Multiselect:
        this.handleSelectFieldType(statementInput, EXPECTED_LENGTH_FOR_MULTI_SELECT);
        break;
      case FieldTypeEnum.Range:
        this.handleRangeFieldType(statementInput);
        break;
      case FieldTypeEnum.Select:
        this.handleSelectFieldType(statementInput, EXPECTED_LENGTH_FOR_SINGLE_SELECT);
        break;
      case FieldTypeEnum.Percent:
      case FieldTypeEnum.Number:
        this.handleNumberFieldType(statementInput);
        break;
    }
  }

  public setFunnelId(funnelId: number): void {
    this.form?.get(FUNNEL_ID_FORM_CONTROL_NAME)?.setValue(funnelId, { emitEvent: false });
  }

  private initializeStepValidationResults(): void {
    this.stepValidationResults = [
      { step: 1, validationResult: false },
      { step: 2, validationResult: false },
      { step: 3, validationResult: false },
    ];
  }

  public handleStatementFieldDenendency(statementInput: StatementInput): void {
    if (!statementInput.dependsOnField || !statementInput.dependsOnOption) return;
    this.statementFormDependencies.push({
      category: statementInput.category,
      dependency: {
        category: statementInput.dependsOnField.category,
        id: statementInput.dependsOnOption.id,
      },
    });
  }

  public handleTargerSegmentIds(statementInput: StatementInput): void {
    if (FieldCategoryEnum.TargetSegment !== statementInput.category) return;
    statementInput.options.forEach((option: StatementInputOption) => {
      if (option.name.toUpperCase() === B2B_NAME) this._targetSegmentB2bId = option.id;
      if (option.name.toUpperCase() === B2C_NAME) this._targetSegmentB2cId = option.id;
    });
  }

  public handleSalesAndLeadsIds(statementInput: StatementInput): void {
    if (FieldCategoryEnum.GoalType !== statementInput.category) return;
    statementInput.options.forEach((option: StatementInputOption) => {
      if (option.name.toUpperCase() === SALES_GOAL_NAME) this._salesGoalId = option.id;
      if (option.name.toUpperCase() === LEADS_GOAL_NAME) this._leadsGoalId = option.id;
    });
  }

  private handleNumberFieldType(statementInput: StatementInput): void {
    const formControl: UntypedFormControl = new UntypedFormControl();
    const validators: any[] = [];
    if (statementInput.required) validators.push(Validators.required);
    validators.push(naturalNumberValidator());
    formControl.setValidators(validators);
    this._form.addControl(statementInput.category, formControl, {
      emitEvent: false,
    });
  }

  private handleSelectFieldType(statementInput: StatementInput, expectedLength: number): void {
    const formControl: UntypedFormControl = new UntypedFormControl([]);
    const validators: any[] = [];
    if (statementInput.required) validators.push(Validators.required);
    validators.push(statementFormArrayValidator(expectedLength));
    formControl.setValidators(validators);
    this._form.addControl(statementInput.category, formControl, {
      emitEvent: false,
    });
  }

  private handleTextFieldType(statementInput: StatementInput, maxLength: number): void {
    const formControl: UntypedFormControl = new UntypedFormControl('');
    const validators: ValidatorFn[] = [];
    if (statementInput.required) validators.push(Validators.required);
    if (maxLength) validators.push(Validators.maxLength(maxLength));
    formControl.setValidators(validators);
    this._form.addControl(statementInput.category, formControl, {
      emitEvent: false,
    });
  }

  private handleRangeFieldType(statementInput: StatementInput): void {
    const formControl: UntypedFormControl = new UntypedFormControl();
    const validators: any[] = [];
    if (statementInput.required) validators.push(Validators.required);
    if (this.hasDigitValue(statementInput.minValue?.value))
      validators.push(Validators.min(Number(statementInput.minValue?.value)));
    if (this.hasDigitValue(statementInput.maxValue?.value))
      validators.push(Validators.max(Number(statementInput.maxValue?.value)));
    formControl.setValidators(validators);
    this._form.addControl(statementInput.category, formControl, {
      emitEvent: false,
    });
  }

  private hasDigitValue(value: number | null | undefined): boolean {
    return null !== value && undefined !== value && !isNaN(value);
  }

  public validateForm(validateFields: FieldCategoryEnum[] = [], step: number): boolean {
    for (let index = 0; index < validateFields.length; index++) {
      const dependency: StatementFormControlDependency | null = this.getDependency(validateFields[index]);
      if (dependency && !this.isDependencyConditaionMet(dependency.dependency)) continue;
      if (this.form.get(validateFields[index])?.invalid) {
        this.setStepValidationResult(step, false);
        return false;
      }
    }
    this.setStepValidationResult(step, true);
    return true;
  }

  private setStepValidationResult(step: number, validationResult: boolean): void {
    for (let index = 0; index < this.stepValidationResults.length; index++) {
      if (this.stepValidationResults[index].step === step) {
        this.stepValidationResults[index].validationResult = validationResult;
        break;
      }
    }
  }

  private isDependencyConditaionMet(dependency: StatementDependency): boolean {
    const values: StatementInputOption[] = this.form.get(dependency.category)?.value;
    for (let index = 0; index < values.length; index++) {
      if (values[index].id === dependency.id) return true;
    }
    return false;
  }

  private getDependency(fieldCategory: FieldCategoryEnum): StatementFormControlDependency | null {
    for (let index = 0; index < this.statementFormDependencies.length; index++) {
      if (this.statementFormDependencies[index].category === fieldCategory)
        return this.statementFormDependencies[index];
    }
    return null;
  }

  public performFullValidation(): void {
    this.validateForm(STEP_1_MANDATORY_FIELDS, 1);
    this.validateForm(STEP_2_MANDATORY_FIELDS, 2);
    this.validateForm(STEP_3_MANDATORY_FIELDS, 3);
  }

  public isFormValid(): boolean {
    for (let index = 0; index < this.stepValidationResults.length; index++) {
      if (!this.stepValidationResults[index].validationResult) return false;
    }
    return true;
  }

  setIsFormReady(value: boolean) {
    this._isFromReady = value;
    this.checkStatement();
  }

  private checkStatement(): void {
    if (this.statement) this.patchValue(this.statement);
  }

  public collectFormData(): StatementInputGraphql {
    this.validateFunnelId();
    const form = cloneDeep(this.form.value);
    Object.keys(this.form.controls).map((key) => {
      if (Array.isArray(form[key])) {
        form[key] = form[key].map((i) => i?.id) || [];
      } else if (key === FieldCategoryEnum.ProductName || key === FUNNEL_ID_FORM_CONTROL_NAME) {
        /* empty */
      } else {
        form[key] = Number(form[key]);
      }
    });
    this.preapreB2bFields(form);
    this.preapreB2cFields(form);
    form.configurationId = this.statementConfigurationId;
    return form as StatementInputGraphql;
  }

  preapreB2bFields(form: any): void {
    if (!this.isB2B) return;
    form.b2cProductType = null;
    form.targetPerson = null;
  }

  preapreB2cFields(form: any): void {
    if (!this.isB2C) return;
    form.b2bProductType = null;
    form.targetJob = null;
  }

  private validateFunnelId(): void {
    const funnelId: number | undefined = this.userService.User?.contextFunnel.id;
    if (!funnelId) return;
    this.form.get(FUNNEL_ID_FORM_CONTROL_NAME)?.setValue(funnelId, { emitEvent: false });
  }

  public validateAccess(step: number): boolean {
    for (let index = 0; index < this.stepValidationResults.length; index++) {
      if (this.stepValidationResults[index].step < step && !this.stepValidationResults[index].validationResult)
        return false;
    }
    return true;
  }

  public resetForm(): void {
    this.prepareForm();
  }

  public patchValue(statement: Statement) {
    if (!this.isFormReady) {
      this.statement = statement;
      return;
    }
    this.form.get(FUNNEL_ID_FORM_CONTROL_NAME)?.setValue(statement.funnelId, { emitEvent: false });
    statement.answers.forEach((answer: StatementAnswer) => {
      this.isSelectLikeType(answer.field.type)
        ? this.handleSelectLikeFormControl(answer)
        : this.handleNotSelectLikeFormControl(answer);
    });
    this.statement = null;
  }

  private isSelectLikeType(type: FieldTypeEnum): boolean {
    return [FieldTypeEnum.Multiselect, FieldTypeEnum.Select].includes(type);
  }

  private handleNotSelectLikeFormControl(answer: StatementAnswer): void {
    this.form.get(answer.field.category)?.setValue(answer.value.data, { emitEvent: false });
  }

  private handleSelectLikeFormControl(answer: StatementAnswer): void {
    const selectedStatementOption: StatementInputOption[] = [];
    const selectedOptions: number[] = answer.value?.data ?? [];
    answer.field.options.forEach((option: StatementInputOption) => {
      if (selectedOptions.includes(option.id)) selectedStatementOption.push(option);
    });
    this.form.get(answer.field.category)?.setValue(selectedStatementOption, { emitEvent: false });
  }

  public setConfigurationId(configurationId: number | null): void {
    this.statementConfigurationId = configurationId;
  }

  public clearForm(): void {
    Object.keys(FieldCategoryEnum).forEach((key) => {
      this.form.get(FieldCategoryEnum[key])?.setValue(null);
    });
  }
}
