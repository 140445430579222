import { gql } from 'apollo-angular';

export const getMetricsDocument = gql`
  query getMetrics {
    getMetrics {
      id
      name
    }
  }
`;
