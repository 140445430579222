import { Component, ChangeDetectionStrategy, Input, Inject, Injector } from '@angular/core';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ActivityAssetPreviewModalComponent } from '@modules/tactics/shared/components/activity-asset-preview-modal/activity-asset-preview-modal.component';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'df-content-generator-asset-short',
  templateUrl: './content-generator-asset-short.component.html',
  styleUrls: ['./content-generator-asset-short.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorAssetShortComponent {
  @Input() asset: ActivityAsset | null = null;
  readonly Config = Config;

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  openAssetPreview(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogService
      .open<number>(new PolymorpheusComponent(ActivityAssetPreviewModalComponent, this.injector), {
        data: {
          asset: this.asset,
        },
        dismissible: true,
      })
      .subscribe();
  }
}
