import { Injectable } from '@angular/core';
import { LayoutStyleType } from '@shared/enums/layout-style-type.enum';
import { LayoutViewType } from '@shared/enums/layout-view-type.enum';
import { LayoutConfig } from '@shared/interfaces/layout-config.interface';
import { BehaviorSubject } from 'rxjs';

const LayoutConfigData: LayoutConfig[] = [
  {
    minWidth: 1536,
    styleType: LayoutStyleType.Xxl,
    viewType: LayoutViewType.Desktop,
  },
  {
    minWidth: 1280,
    styleType: LayoutStyleType.Xl,
    viewType: LayoutViewType.Desktop,
  },
  {
    minWidth: 1024,
    styleType: LayoutStyleType.Lg,
    viewType: LayoutViewType.Tablet,
  },
  {
    minWidth: 768,
    styleType: LayoutStyleType.Md,
    viewType: LayoutViewType.Tablet,
  },
  {
    minWidth: 640,
    styleType: LayoutStyleType.Sm,
    viewType: LayoutViewType.Mobile,
  },
  {
    minWidth: 0,
    styleType: LayoutStyleType.Xs,
    viewType: LayoutViewType.Mobile,
  },
];

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  protected config: LayoutConfig;
  protected timeout: NodeJS.Timeout | null = null;

  public onResize: BehaviorSubject<LayoutConfig>;

  constructor() {
    this.config = this.getConfig();
    this.onResize = new BehaviorSubject<LayoutConfig>(this.getConfig());

    window.addEventListener(
      'resize',
      () => {
        if (null !== this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.onResizeCallback();
        }, 100);
      },
      false,
    );
  }

  public onResizeCallback(): void {
    const newConfig: LayoutConfig = this.checkConfig(window.innerWidth);

    if (this.getConfig().minWidth != newConfig.minWidth) {
      this.config = newConfig;
      this.onResize.next(this.getConfig());
    }
  }

  protected getConfig(): LayoutConfig {
    if (!this.config) {
      this.config = this.checkConfig(window.innerWidth);
    }

    return this.config;
  }

  protected checkConfig(width: number): LayoutConfig {
    const temp: LayoutConfig[] = LayoutConfigData.sort((a: LayoutConfig, b: LayoutConfig) => {
      return a.minWidth > b.minWidth ? -1 : 1;
    }).filter((config: LayoutConfig) => {
      return config.minWidth <= width;
    });

    return (0 < temp.length ? temp.shift() : LayoutConfigData.slice(0, 1).shift()) ?? ({} as LayoutConfig);
  }
}
