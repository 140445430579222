import { gql } from 'apollo-angular';

export const SearchContentOutputsDataDocument = gql`
  query searchContentOutputsData($input: OutputsDataSearchInputGraphql!) {
    searchContentOutputsData(input: $input) {
      records {
        id
        value
        createdAt
        modifiedAt
        isSelected
        configuration {
          validationPatterns {
            id
            rules
          }
        }
        integration {
          id
          type
          name
          icon
          description
          category {
            name
          }
        }
        tactic {
          id
          name
        }
      }
    }
  }
`;
