import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Config } from '@shared/configs/config';
import { OnboardingFormStep } from '@shared/models/onboarding-step.model';
import { getOnboardingFormSteps } from '@modules/authorization/shared/consts/onboarding-form-steps.const';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-onboarding-step-bar',
  templateUrl: './onboarding-step-bar.component.html',
  styleUrls: ['./onboarding-step-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingStepBarComponent {
  readonly Config = Config;
  readonly steps: OnboardingFormStep[];
  @Input() activeIndex = 1;
  @Input() alignLeft = false;

  constructor(
    private readonly t: TranslateService,
    public readonly userService: UserService,
  ) {
    this.steps = getOnboardingFormSteps(this.t, this.userService);
  }
}
