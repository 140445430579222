import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { Activity } from '@shared/models/activity.model';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { MarkActivityDoneInputGraphql } from '@modules/graphql/graphql-types';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';

@Component({
  selector: 'df-funnel-manage-tactic-activities',
  templateUrl: './funnel-manage-tactic-activities.component.html',
  styleUrls: ['./funnel-manage-tactic-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageTacticActivitiesComponent {
  private _funnelTactic: FunnelTactic | undefined;

  get funnelTactic(): FunnelTactic | undefined {
    return this._funnelTactic;
  }

  @Input() expandAll = false;

  @Input() previewMode = false;

  @Input() set funnelTactic(tactic: FunnelTactic | undefined) {
    this._funnelTactic = tactic;
    if (this._funnelTactic) {
      this._funnelTactic.doneActivities.forEach(
        (doneActivity) =>
          (this._funnelTactic!.tactic.activities.find((activity) => activity.id === doneActivity.activity.id)!.done =
            true),
      );
    }
    this.doneActivities = this._funnelTactic?.doneActivities.length ?? 0;
    this.changes.detectChanges();
  }

  doneActivities = 0;

  constructor(
    public readonly changes: ChangeDetectorRef,
    private readonly _tacticGraphqlService: TacticGraphqlService,
    private funnelFlowManageService: FunnelFlowManageService,
  ) {}

  onDropdownChange() {
    setTimeout(() => {
      this.funnelFlowManageService.paintConnections();
    }, 600);
  }

  toggleDone(activity: Activity): void {
    if (!this.previewMode) {
      activity.done = !activity.done;
      activity.done
        ? this.funnelTactic!.doneActivities.push({
            id: -1,
            activity: { id: activity.id } as Activity,
          })
        : this.funnelTactic!.doneActivities.splice(
            this.funnelTactic!.doneActivities.findIndex((item) => item.activity.id === activity.id)!,
            1,
          );
      this.doneActivities += activity.done ? 1 : -1;
      const input: MarkActivityDoneInputGraphql = {
        funnelTacticId: this.funnelTactic!.id,
        activityId: activity.id,
        isDone: activity.done,
      };
      this._tacticGraphqlService.markActivityDone(input).toPromise();
      this.changes.detectChanges();
    }
  }
}
