import { Injector, isDevMode } from '@angular/core';
import { onError } from '@apollo/client/link/error';
import { NavigateService } from '@core/routes/services/navigate.service';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { GraphqlErrorEnum } from '@modules/graphql/enums/graphql-error.enum';
import { TuiDialogService } from '@taiga-ui/core';
import { LightPaywallModalComponent } from '@modules/pricing/shared/components/light-paywall-modal/light-paywall-modal.component';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { filter } from 'rxjs/operators';
import { ActiveSubscriptionService } from '@shared/services/active-subscription.service';
import { UserService } from '@shared/services/user.service';
import { NotEnoughCreditsSmallModalComponent } from '@modules/pricing/shared/components/not-enough-credits-small-modal/not-enough-credits-small-modal.component';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { SemrushService } from '@shared/services/semrush.service';

export const graphqlErrorMiddleware = (
  n: NavigateService,
  d: TuiDialogService,
  injector: Injector,
  as: ActiveSubscriptionService,
  userService: UserService,
  authStorageService: AuthStorageService,
) => {
  return onError(({ response }) => {
    if (response?.errors) {
      response?.errors.forEach((graphQLError: CustomGraphqlErrorInterface) => {
        if (isDevMode()) {
          console.error(`[Graphql error]: ${JSON.stringify(graphQLError)}`);
        }

        switch (graphQLError.errorCode) {
          case GraphqlErrorEnum.TRIAL_ENDED:
            n.go('end-trial');
            response.errors = [];
            break;
          case GraphqlErrorEnum.FORBIDDEN:
            if (location.href.includes('sign-in') || location.href.includes('sign-up')) break;
            authStorageService?.logout();
            n.go('no-auth');
            response.errors = [];
            break;
          case GraphqlErrorEnum.NO_PERMISSION_PAYMENT:
            if (userService?.User?.isSemrushPlan()) {
              const semrushService = injector.get(SemrushService);
              semrushService.showUpsell();
              return;
            }

            d.open<LightPaywallModalComponent>(new PolymorpheusComponent(LightPaywallModalComponent, injector), {
              dismissible: true,
              closeable: true,
              size: 'm',
              data: {
                permission: graphQLError.data['permission'],
              },
            })
              .pipe(filter((res) => !!res))
              .subscribe(() =>
                n.router.navigate(
                  [
                    '',
                    {
                      outlets: {
                        pricing: userService?.resolvePricingPacketsUrl() ?? 'pricing/packets',
                      },
                    },
                  ],
                  {
                    skipLocationChange: true,
                  },
                ),
              );
            response.errors = [];
            break;
          case GraphqlErrorEnum.ACCOUNT_BLOCKED:
            // auto logout blocked user
            n.go('auto-logout');
            break;
          case GraphqlErrorEnum.NO_ACTIVE_SUBSCRIPTION:
            as.markNoActiveSubscriptionError(injector);
            response.errors = [];
            break;
          case GraphqlErrorEnum.NO_TEAM:
            // if user haven't any team
            n.go('no-team');
            response.errors = [];
            break;
          case GraphqlErrorEnum.NO_ENOUGH_POINTS:
            d.open<NotEnoughCreditsSmallModalComponent>(
              new PolymorpheusComponent(NotEnoughCreditsSmallModalComponent, injector),
              {
                dismissible: true,
                closeable: true,
                size: 'm',
                data: {
                  permission: graphQLError.data['permission'],
                },
              },
            )
              .pipe(filter((res) => !!res))
              .subscribe(() =>
                n.router.navigate(
                  [
                    '',
                    {
                      outlets: {
                        pricing: userService.resolvePricingPacketsUrl(),
                      },
                    },
                  ],
                  {
                    skipLocationChange: true,
                  },
                ),
              );
            response.errors = [];
            break;
        }

        if (graphQLError.extensions?.response?.statusCode) {
          switch (graphQLError.extensions?.response?.statusCode) {
            case 403:
              if (location.href.includes('sign-in') || location.href.includes('sign-up')) break;
              n.go('no-access');
              response.errors = [];
              return;
          }
        }

        if (graphQLError.message === 'Unauthorized') {
          authStorageService?.logout();
          n.go('no-auth');
        }
      });
    }
  });
};
