<!-- custom dialog close icon -->
<button
  class="ml-auto -mt-2 mb-2 sm:hidden"
  tuiIconButton
  type="button"
  appearance="outline"
  [icon]="icon"
  (click)="close()"
></button>
<ng-template #icon>
  <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
</ng-template>

<div class="filters-container flex flex-col w-full relative">
  <!-- icon chevron up -->
  <button
    tuiIconButton
    type="button"
    size="xs"
    appearance="flat"
    icon="tuiIconChevronUp"
    class="hidden sm:block tui-primary absolute top-2 right-2"
    [ngClass]="{ 'rotate-180': !showFilters }"
    (click)="toggleFilters()"
  ></button>

  <!-- filters clear -->
  <div class="flex justify-center sm:justify-start">
    <button class="tui-text-03 tui-text_menu-header">
      {{ 'Filters.FILTERS' | translate }}
    </button>

    <button
      class="hidden sm:block ml-4 tui-text-04 tui-text_menu-header"
      (click)="clearAllFilters()"
    >
      {{ 'Filters.CLEAR ALL' | translate }}
    </button>
  </div>

  <!-- filters list -->
  <div
    *ngIf="showFilters"
    class="filters-list w-full h-full relative"
  >
    <div class="block flex-shrink-0">
      <ng-container *ngTemplateOutlet="filters"></ng-container>
    </div>

    <ng-template #filters>
      <form
        [formGroup]="form"
        class="filters-form flex flex-col items-stretch md:flex-row md:flex-wrap gap-3 mt-5"
      >
        <!-- search input  -->
        <tui-input
          icon="tuiIconSearchLarge"
          iconAlign="left"
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
          [formControl]="search"
          class="search md:flex-grow"
          (keydown.enter)="onEnter($event)"
        >
          {{ 'Tactics.List.Search by key words' | translate }}

          <input tuiTextfield />
        </tui-input>

        <!-- category -->
        <tui-multi-select
          class="filters-form__category"
          formControlName="categories"
          [editable]="false"
          [valueContent]="selectedInArray('categories')"
          [tuiTextfieldCleaner]="true"
        >
          {{ 'Tactics.List.Category' | translate }}
          <tui-data-list-wrapper
            *tuiDataList
            tuiMultiSelectGroup
            [items]="filtersService.categories"
            [itemContent]="stringifyFilter"
          >
          </tui-data-list-wrapper>
        </tui-multi-select>

        <!-- step -->
        <tui-multi-select
          class="filters-form__step"
          formControlName="step"
          [editable]="false"
          [valueContent]="selectedInArray('step')"
          [tuiTextfieldCleaner]="true"
        >
          {{ 'Tactics.List.Funnel step' | translate }}
          <tui-data-list-wrapper
            *tuiDataList
            tuiMultiSelectGroup
            [items]="filtersService.steps"
            [itemContent]="stepContent"
          >
          </tui-data-list-wrapper>
        </tui-multi-select>

        <ng-template
          #stepContent
          let-step
        >
          <div class="flex items-center justify-start">
            <svg-icon
              [src]="'/assets/img/ic-' + step?.object?.type + '.svg'"
              class="mr-2"
              [svgStyle]="{ width: '1rem', height: '1rem' }"
            ></svg-icon>
            <span>{{ step.name }}</span>
          </div>
        </ng-template>

        <!-- PRO filter -->
        <tui-filter
          *ngIf="allItemsListMode$ | async"
          class="filters-form__toggle"
          formControlName="pro"
          size="l"
          [content]="proContent"
          [items]="filtersService.proFilters"
          [class.pointer-events-none]="
            listService.loading || listService.loadingNextPage || listService.loadingPrevPage
          "
        >
        </tui-filter>
        <ng-template
          let-item
          #proContent
        >
          <div class="opt-label">
            <svg-icon [src]="'/assets/img/ic_pro_star.svg'"></svg-icon>
            <span class="opt-name tui-accent-18">{{ item.name }}</span>
          </div>
        </ng-template>

        <!-- DF filter -->
        <tui-filter
          *ngIf="filtersService.dfFilters && (allItemsListMode$ | async)"
          class="filters-form__toggle"
          formControlName="df"
          size="l"
          [content]="dfContent"
          [items]="filtersService.dfFilters"
          [class.pointer-events-none]="
            listService.loading || listService.loadingNextPage || listService.loadingPrevPage
          "
        >
        </tui-filter>
        <ng-template
          let-item
          #dfContent
        >
          <div class="opt-label">
            <svg-icon
              [src]="'/assets/img/logo-signet.svg'"
              [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
            ></svg-icon>
            <span class="opt-name">{{ item.name }}</span>
          </div>
        </ng-template>

        <!-- favourite -->
        <button
          tuiIconButton
          class="filters-form__toggle short tui-accent-19"
          appearance="outline"
          size="l"
          [routerLink]="n.getPath('tactics/list/favourite')"
          routerLinkActive="active-hidden"
        >
          <div class="opt-label">
            <tui-svg src="tuiIconHeartLarge"></tui-svg>
            <span class="opt-name tui-accent-19">{{ 'Favourite' | translate }}</span>
          </div>
        </button>

        <button
          tuiIconButton
          class="filters-form__toggle short tui-accent-19"
          appearance="outline"
          size="l"
          [routerLink]="n.getPath('/tactics/list/all')"
          [class.active-hidden]="!isFavouriteShowing()"
        >
          <div class="opt-label">
            <svg-icon
              [src]="'/assets/img/ic_heart_alt_filled.svg'"
              [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
            >
            </svg-icon>
            <span class="opt-name tui-accent-19">{{ 'Favourite' | translate }}</span>
          </div>
        </button>
      </form>
    </ng-template>

    <!-- selected tags (categories) -->
    <div class="hidden sm:block sm:mt-3 md:mt-1">
      <df-tags-list-filter [filtersService]="filtersService"></df-tags-list-filter>
    </div>
  </div>

  <!-- buttons for mobile view -->
  <div class="flex justify-between mt-20 sm:hidden">
    <button
      tuiButton
      appearance="flat"
      class="w-1/2"
      (click)="clearAllFilters()"
    >
      <span class="tui-primary tui-text_body-m-7">
        {{ 'Filters.Clear all filters' | translate }}
      </span>
    </button>
    <button
      tuiButton
      appearance="primary"
      class="w-1/2"
      (click)="applyFilters()"
    >
      <span class="tui-text_body-m-7">
        {{ 'Filters.Apply filters' | translate }}
      </span>
    </button>
  </div>
</div>
