import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import {
  AccountSetupParameterCategoriesEnum,
  AccountSetupParameterOutputGraphql,
  SaveAccountSetupInputGraphql,
  WorksForEnum,
} from '@modules/graphql/graphql-types';
import { map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { GetAccountSetupParametersQuery } from '@modules/authorization/shared/graphql/queries/get-account-setup-parameters.query.generated';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { UserService } from '@shared/services/user.service';

@Injectable()
export class SetupAccountService {
  static readonly OTHER_OPTION = 'other';
  form: UntypedFormGroup = new UntypedFormGroup({
    worksFor: new UntypedFormControl(null, [Validators.required]),
    parameters: new UntypedFormGroup({
      [AccountSetupParameterCategoriesEnum.MostImportant]: new UntypedFormControl(null, [Validators.required]),
      [AccountSetupParameterCategoriesEnum.CurrentJobRole]: new UntypedFormControl(null, [Validators.required]),
      [AccountSetupParameterCategoriesEnum.HowHearAboutApp]: new UntypedFormControl(null, [Validators.required]),
    }),
  });

  parameters: {
    [category: string]: {
      options: { id: number | null; name: string }[];
      other: boolean;
    };
  } = {
    [AccountSetupParameterCategoriesEnum.MostImportant]: {
      options: [],
      other: true,
    },
    [AccountSetupParameterCategoriesEnum.HowHearAboutApp]: {
      options: [],
      other: true,
    },
    [AccountSetupParameterCategoriesEnum.CurrentJobRole]: {
      options: [],
      other: false,
    },
  };

  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();
  private readonly _submitLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly submitLoading$: Observable<boolean> = this._submitLoading$.asObservable();

  get parametersForm(): UntypedFormGroup {
    return this.form.get('parameters') as UntypedFormGroup;
  }

  constructor(
    private readonly authGraphqlService: AuthGraphqlService,
    private readonly t: TranslateService,
    private readonly s: SnackbarService,
    private readonly n: NavigateService,
    private readonly userService: UserService,
  ) {
    this.fetchFormParameters();
  }

  addOtherControl(category: AccountSetupParameterCategoriesEnum | string): void {
    this.parametersForm.addControl(category + '_other', new UntypedFormControl('', [Validators.required]));
  }

  removeOtherControl(category: AccountSetupParameterCategoriesEnum | string): void {
    this.parametersForm.removeControl(category + '_other');
  }

  private fetchFormParameters(): void {
    this.authGraphqlService
      .getAccountSetupParameters()
      .pipe(
        map((res: FetchResult<GetAccountSetupParametersQuery>) =>
          res.data ? res.data.getAccountSetupParameters : null,
        ),
      )
      .subscribe(
        (res: AccountSetupParameterOutputGraphql[] | null) => {
          if (res?.length) {
            Object.keys(this.parameters).forEach(
              (key) =>
                (this.parameters[key].options = [
                  ...res
                    .filter((param) => param.category === key)
                    .map((param) => ({
                      id: param.id,
                      name: param.name,
                    })),
                  ...(this.parameters[key].other
                    ? [
                        {
                          id: null,
                          name: this.t.instant(SetupAccountService.OTHER_OPTION),
                        },
                      ]
                    : []),
                ]),
            );
          }
        },
        () => this.s.defaultError(),
        () => this._loading$.next(false),
      );
  }

  saveForm(): void {
    this._submitLoading$.next(true);
    this.authGraphqlService.saveAccountSetup(...this.getFormData()).subscribe(
      () => {
        this.s.success(this.t.instant('Auth.Onboarding.Setup form success'));
        const user = this.userService.User!;
        user.setupFormFilled = true;
        this.userService.User = user;
        this.n.go('select-plan');
      },
      () => {
        this.s.error(this.t.instant('Auth.Onboarding.Setup form error'));
        this._submitLoading$.next(false);
      },
    );
  }

  private getFormData(): [WorksForEnum, SaveAccountSetupInputGraphql[]] {
    const value = this.form.getRawValue();
    value.parameters = Object.keys(this.parameters).map((key) => ({
      category: key,
      parameter: {
        id: value.parameters[key].id,
        customValue: !value.parameters[key].id ? value.parameters[key + '_other'] : null,
      },
    }));
    return [value.worksFor, value.parameters];
  }
}
