<form [formGroup]="formGroup">
  <tui-select
    #component
    *tuiLet="items$ | async as items"
    [tuiTextfieldSize]="size"
    [readOnly]="disabled"
    (change)="change.emit($event)"
    (ngModelChange)="change.emit($event)"
    [valueContent]="stringify"
    [tuiTextfieldCleaner]="isCleaner"
    [formControlName]="controlName"
  >
    <ng-content></ng-content>
    <tui-data-list *tuiDataList>
      <button
        tuiOption
        size="m"
        *ngFor="let item of items"
        [value]="item"
      >
        {{ item.name }}
      </button>
    </tui-data-list>
  </tui-select>
</form>
