import { gql } from 'apollo-angular';

export const GetLibraryAssetsDocument = gql`
  query getLibraryAssets($input: GetLibraryAssetInputGraphql!) {
    getLibraryAssets(input: $input) {
      id
      funnelId
      integration
      type
      value
      fileId
      createdAt
      updatedAt
      context {
        key
        value
      }
    }
  }
`;
