import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { UserService } from '@shared/services/user.service';
import { TuiDialogContext } from '@taiga-ui/core';

@Component({
  selector: 'df-not-enough-credits-small-modal',
  templateUrl: './not-enough-credits-small-modal.component.html',
  styleUrls: ['./not-enough-credits-small-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotEnoughCreditsSmallModalComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<boolean>,
    public readonly userService: UserService,
  ) {}

  goToPackets() {
    this.context.completeWith(true);
  }

  close(): void {
    this.context.completeWith(false);
  }
}
