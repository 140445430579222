import { OnboardingFormStep } from '@shared/models/onboarding-step.model';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user.service';

export const getOnboardingFormSteps = (t: TranslateService, u: UserService): OnboardingFormStep[] => {
  return [
    { index: 1, name: t.instant('Auth.Onboarding.Get started') },
    { index: 2, name: t.instant('Auth.Onboarding.Set Up Account') },
    ...(u.User?.hasActivePlan() ? [] : [{ index: 3, name: t.instant('Auth.Onboarding.Select Plan') }]),
  ];
};
