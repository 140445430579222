import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { FetchResult } from '@apollo/client/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { FunnelManageTacticDropdownMenuComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-tactic-dropdown-menu/funnel-manage-tactic-dropdown-menu.component';
import { TacticAccordionActivityComponent } from '@modules/tactics/modules/tactic-preview/pages/tactic-preview/components/tactic-preview-activities/components/tactic-accordion-activity/tactic-accordion-activity.component';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { Activity } from '@shared/models/activity.model';
import { GetFunnelTacticQuery } from '@modules/funnels/shared/graphql/queries/get-funnel-tactic.query.generated';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { TranslateService } from '@ngx-translate/core';
import { FunnelPermissionEnum } from '@modules/graphql/graphql-types';
import { Subject } from 'rxjs';
import { SpecialTacticName } from '../funnel-manage-columns/components/tactic-columns/tactic-columns.component';

@Component({
  selector: 'df-funnel-manage-tactic-preview',
  templateUrl: './funnel-manage-tactic-preview.component.html',
  styleUrls: ['./funnel-manage-tactic-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageTacticPreviewComponent implements OnInit {
  FunnelPermissionEnum = FunnelPermissionEnum;

  @Input() funnelTactic!: FunnelTactic;
  @Input() skipServerRequest = false;
  @Input() scrollToActivity: number | null = null;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onChanges: EventEmitter<FunnelTactic> = new EventEmitter<FunnelTactic>();
  loading = false;
  @ViewChild('dropdownMenu')
  dropdownMenuComponent!: FunnelManageTacticDropdownMenuComponent;
  @ViewChildren('accordionActivityComponents')
  accordionActivityComponents!: QueryList<TacticAccordionActivityComponent>;

  activitiesExpanded = false;
  activityId: number | null = null;
  refreshList = false;

  constructor(
    private readonly funnelGraphqlService: FunnelGraphqlService,
    private readonly tacticGraphqlService: TacticGraphqlService,
    private readonly s: SnackbarService,
    private readonly changes: ChangeDetectorRef,
    public readonly n: NavigateService,
    private readonly t: TranslateService,
    public readonly funnelManageService: FunnelManageService,
  ) {}

  ngOnInit(): void {
    if (this.skipServerRequest) return;
    this.fetchTacticData();
  }

  isSpecialTactic(funnelTactic: FunnelTactic) {
    if (!funnelTactic || !funnelTactic.name) return false;

    const specialTacticsNames = Object.values<string>(SpecialTacticName);

    if (specialTacticsNames.includes(funnelTactic.name)) {
      return true;
    }

    return false;
  }

  detectChanges(): void {
    this.changes.detectChanges();
    this.onChanges.emit(this.funnelTactic);
  }

  private fetchTacticData(): Promise<void> {
    const result = new Subject<void>();

    this.funnelGraphqlService.getFunnelTactic(this.funnelTactic.id).subscribe(
      (res: FetchResult<GetFunnelTacticQuery>) => {
        this.funnelTactic = new FunnelTactic(res.data?.getFunnelTactic);
        this.funnelManageService.markDoneActivities(this.funnelTactic);
        this.setGeneratedContent();
        this.scrollViewToActivity();
        this.loading = false;
        this.changes.detectChanges();
        result.next();
        result.complete();
      },
      () => {
        this.s.defaultError();
        result.error(new Error('Wystąpił błąd podczas pobierania danych.'));
      },
    );

    return result.toPromise();
  }

  toggleDone(): void {
    this.dropdownMenuComponent.toggleDone();
  }

  toggleActivityDone(activity: Activity): void {
    this.funnelTactic = this.funnelManageService.markActivityDone(activity, this.funnelTactic!);
    this.detectChanges();
  }

  onOpenAccordionChange() {
    const opened = this.accordionActivityComponents.filter(
      (a: TacticAccordionActivityComponent) => a.open && !a.emptyActivity,
    );
    const closed = this.accordionActivityComponents.filter(
      (a: TacticAccordionActivityComponent) => !a.open && !a.emptyActivity,
    );

    const notEmpty = this.accordionActivityComponents.filter((a: TacticAccordionActivityComponent) => !a.emptyActivity);
    if (opened.length === notEmpty.length) {
      this.activitiesExpanded = true;
    } else if (closed.length === notEmpty.length) {
      this.activitiesExpanded = false;
    } else {
      this.activitiesExpanded = false;
    }
    this.changes.detectChanges();
  }

  onExpandChange(e) {
    e = e as { srcElement: HTMLInputElement };
    this.accordionActivityComponents
      .filter((a: TacticAccordionActivityComponent) => !a.emptyActivity)
      .map((a: TacticAccordionActivityComponent) => (e.srcElement.checked ? a.openToggle(true) : a.closeToggle()));
  }

  deleteTactic(): void {
    this.loading = true;
    this.changes.detectChanges();
    this.tacticGraphqlService.removeTactic(this.funnelTactic.tactic.id).subscribe(
      () => {
        this.dropdownMenuComponent.removeTacticFromFunnel(false);
        this.s.success(this.t.instant('Funnels.Manage.Tactic removed successfully!'));
        this.onClose.emit(true);
      },
      () => {
        this.s.defaultError();
        this.loading = false;
        this.changes.detectChanges();
      },
    );
  }

  private setGeneratedContent(): void {
    if (this.funnelTactic.selectedOutputsData.length) {
      this.funnelTactic.tactic.activities.forEach((activity) =>
        activity.assets.forEach(
          (asset) =>
            (asset.integrationSelectedOutput =
              this.funnelTactic!.selectedOutputsData.find((output) => output.activityAsset?.id === asset.id) ?? null),
        ),
      );
    }
  }

  private scrollViewToActivity(): void {
    if (this.scrollToActivity === null) return;
    document.getElementById('activity-' + this.scrollToActivity)?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(
      () =>
        this.accordionActivityComponents
          .get(this.funnelTactic.tactic.activities.findIndex((a) => a.id === this.scrollToActivity))
          ?.openToggle(),
      600,
    );
  }

  public async getRefreshListVal(val: boolean) {
    if (val) {
      try {
        await this.fetchTacticData();
        this.openAccordionRefreshList();
        this.changes.detectChanges();
      } catch (e) {
        console.error(e);
      }
    }
  }

  private openAccordionRefreshList() {
    document.getElementById('activity-' + this.activityId)?.scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => {
      this.accordionActivityComponents
        .get(this.funnelTactic.tactic.activities.findIndex((a) => a.id === this.activityId))
        ?.openToggle(true);
    }, 600);
  }

  public getActivityId(val: number) {
    if (val) {
      this.activityId = val;
    }
  }
}
