import { XPartnerService } from '@shared/services/x-partner.service';
import { ApolloLink } from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';
import { BaseStorageService } from '@core/services/base-storage.service';
import { AdminTokenService } from '@shared/services/admin-token.service';
import { environment } from '@environments/environment';
import { Partners } from '@shared/enums/partners.enum';
import { StorageKey } from '@shared/enums/storage-key.enum';

export const getAuthMiddleware = (a: AdminTokenService, xp: XPartnerService, isSemrush = false) => {
  return new ApolloLink((operation, forward) => {
    const jwt =
      xp.isWhitelabelPartner || xp.isPlaystrict
        ? xp.partnerJwt
        : localStorage.getItem(BaseStorageService.PREFIX + '-' + StorageKey.JWT);
    const adminJwt = a.token;
    let httpHeaders = new HttpHeaders();

    httpHeaders = httpHeaders.set('Content-Security-Policy', "frame-ancestors 'self' https://*.semrush.com");

    if (jwt || adminJwt) {
      const token = adminJwt ?? JSON.parse(jwt!).token;
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
    }

    if (xp.isPartner) {
      httpHeaders = httpHeaders.set(XPartnerService.COOKIE_KEY, xp.partnerName);
    }

    if (xp.partnerName === 'playstrict') {
      httpHeaders = httpHeaders.set(XPartnerService.COOKIE_KEY, 'playstrict');
    }

    if (isSemrush) {
      httpHeaders = httpHeaders.set(
        XPartnerService.COOKIE_KEY,
        environment.partners[Partners.SEMRUSH].PUBLIC_KEY as string,
      );
    }

    operation.setContext({
      headers: httpHeaders,
    });

    return forward(operation);
  });
};
