import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { BaseCustomSelectComponent } from '@shared/modules/custom-selects/components/base-custom-select.component';
import { Apollo } from 'apollo-angular';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';

@Component({
  selector: 'df-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectComponent extends BaseCustomSelectComponent {
  @Input() size: TuiSizeS | TuiSizeL = 'l';

  constructor(
    public apollo: Apollo,
    public changes: ChangeDetectorRef,
  ) {
    super(apollo, changes);
  }

  detectChanges() {
    this.changes.detectChanges();
    this.component.nativeFocusableElement?.click();
    this.component.nativeFocusableElement?.click();
  }
}
