import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Flow } from '@shared/models/flow.model';
import { FlowItem } from '@shared/models/flow-item.model';
import { FlowsController } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/flows.controller';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { EditFlowMutation } from '@modules/funnels/shared/graphql/mutations/edit-flow.mutation.generated';
import { EVENT_CONNECTION_CLICK, EVENT_CONNECTION_MOUSEOUT, EVENT_CONNECTION_MOUSEOVER } from '@jsplumb/browser-ui';
import { Connection } from '@jsplumb/core';

@Injectable({
  providedIn: 'root',
})
export class FunnelFlowManageService {
  private _flow?: Flow;
  private _editMode = false;
  private flowsController?: FlowsController;

  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern(Regex.whiteSpace),
    ]),
    items: new UntypedFormControl([]),
  });
  loading = false;

  flow$: EventEmitter<Flow> = new EventEmitter<Flow>();
  editMode$: EventEmitter<boolean> = new EventEmitter<boolean>();
  loading$: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _panelShort = true;
  togglePanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  get panelShort(): boolean {
    return this._panelShort;
  }

  set panelShort(value: boolean) {
    this._panelShort = value;
    this.togglePanelEmitter.emit(value);
  }

  set flow(flow: Flow | undefined) {
    this._flow = flow;
    if (flow) {
      this.flow$.emit(flow);
    }
  }

  get flow(): Flow | undefined {
    return this._flow;
  }

  set editMode(editMode: boolean) {
    this._editMode = editMode;
    if (!editMode) {
      this.flow ? (this.flow.highlighted = false) : '';
      this.flow = undefined;
      this.form.reset();
      this.funnelManageService.clearHighlightForFunnelTactics();
      this.removeHoverListener();
    } else {
      this.setHoverListener();
    }
    this.paintConnections();
    this.editMode$.emit(editMode);
  }

  get editMode(): boolean {
    return this._editMode;
  }

  constructor(
    private funnelGraphQlService: FunnelGraphqlService,
    private funnelManageService: FunnelManageService,
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  initFlowsController(container: Element, elementSelector = 'dataFunnelTacticId') {
    this.flowsController = new FlowsController(container, elementSelector);
  }

  clearService() {
    this.editMode = false;
    this.clearAllConnections();
  }

  revalidateElement(el: Element) {
    this.flowsController?.jsPlumbInstance.revalidate(el);
  }

  paintConnections() {
    this.clearAllConnections();
    if (this.editMode) {
      this.paintSingleConnectionsForEditingFlow();
    } else {
      this.paintSingleConnectionsForFunnel();
    }
  }

  private setHoverListener() {
    this.flowsController?.jsPlumbInstance.bind(EVENT_CONNECTION_MOUSEOVER, (e: Connection) => {
      e.showOverlay('toRemove');
      e.addClass('remove');
    });
    this.flowsController?.jsPlumbInstance.bind(EVENT_CONNECTION_MOUSEOUT, (e: Connection) => {
      e.hideOverlay('toRemove');
      e.removeClass('remove');
    });
    this.flowsController?.jsPlumbInstance.bind(EVENT_CONNECTION_CLICK, (e: Connection) => {
      this.removeConnection(e);
    });
  }

  private removeConnection(connection: Connection) {
    this.flow!.items = this.flow!.items.filter((flowItem) => {
      if (
        (flowItem.from === connection.data.fromId && flowItem.to === connection.data.toId) ||
        (flowItem.from === connection.data.toId && flowItem.to === connection.data.fromId)
      ) {
        this.flowsController?.jsPlumbInstance.deleteConnection(flowItem.connection!);
        return false;
      }
      return true;
    });
    this.funnelManageService.funnel!.flows = this.funnelManageService.funnel!.flows.map((flow: Flow) => {
      if (flow.id === this.flow!.id) {
        flow = this.flow!;
      }
      return flow;
    });
    this.funnelManageService.funnel$.emit(this.funnelManageService.funnel);
    this.flow?.setTacticsIds();
    this.funnelManageService.highlightFunnelTacticForFlow(this.flow!);
  }

  private removeHoverListener() {
    this.flowsController?.jsPlumbInstance.unbind(EVENT_CONNECTION_MOUSEOVER);
    this.flowsController?.jsPlumbInstance.unbind(EVENT_CONNECTION_MOUSEOUT);
    this.flowsController?.jsPlumbInstance.unbind(EVENT_CONNECTION_CLICK);
  }

  private paintSingleConnectionsForEditingFlow() {
    this.flow?.items.map((flowItem: FlowItem) => {
      if (!flowItem.connection) {
        flowItem.connection = this.flowsController?.createConnection(flowItem.from, flowItem.to, this.flow!);
      }
    });
    this.flow!.highlighted = this.editMode;
    this.funnelManageService.highlightFunnelTacticForFlow(this.flow!);
  }

  private paintSingleConnectionsForFunnel() {
    this.funnelManageService.funnel?.flows.map((flow: Flow) => {
      flow.items.map((flowItem: FlowItem) => {
        if (!flowItem.connection && flow.highlighted) {
          flowItem.connection = this.flowsController?.createConnection(flowItem.from, flowItem.to, flow);
        }
      });
    });
  }

  clearAllConnections(clearAlsoHighlight?: boolean) {
    this.flowsController?.jsPlumbInstance.deleteEveryConnection();
    if (this.flow) {
      clearAlsoHighlight ? (this.flow.highlighted = false) : '';
      this.flow.items.map((flowItem: FlowItem) => {
        flowItem.connection = undefined;
      });
    }
    this.funnelManageService.funnel?.flows.map((flow: Flow) => {
      clearAlsoHighlight ? (flow.highlighted = false) : '';
      flow.items.map((flowItem: FlowItem) => {
        flowItem.connection = undefined;
      });
    });
  }

  saveItemsInFlow(): Observable<FetchResult<EditFlowMutation>> {
    this.form.get('items')?.setValue(this.prepareFlowItemsForRequest(this.flow?.items));
    this.form.get('name')?.setValue(this.flow!.name);
    this.loading = true;
    this.loading$.emit(this.loading);
    return this.funnelGraphQlService.editFlow(this.form.value, this.flow!.id);
  }

  prepareFlowItemsForRequest(items?: FlowItem[]) {
    return items
      ? items.map((i: FlowItem) => {
          return {
            from: i.from,
            to: i.to,
          };
        })
      : [];
  }
}
