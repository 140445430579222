<div class="w-full flex flex-col items-stretch">
  <div class="flex justify-between items-center mb-2">
    <p
      class="w-full font-bold uppercase tui-text-05 tui-text_body-xs"
      [innerHTML]="'Tactics.Manage.Benchmarks' | translate"
    ></p>
    <a
      (click)="openNewBenchmarkModal()"
      class="flex items-center tui-primary cursor-pointer no-underline tui-text_body-m-8 whitespace-nowrap"
    >
      <svg-icon
        class="mr-2"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
        src="/assets/img/ic_add_filled.svg"
      ></svg-icon>
      {{ 'Tactics.Manage.Add benchmark' | translate }}
    </a>
  </div>
  <df-activity-benchmark-item
    *ngFor="let benchmark of benchmarksArray.controls"
    [benchmark]="getBenchmarkData(benchmark)"
    [editMode]="true"
    [disabled]="isBenchmarkToRemove(benchmark)"
    (benchmarkRemoved)="remove(benchmark)"
    (benchmarkRestored)="restore(benchmark)"
    (onBenchmarkEdit)="openEditBenchmarkModal(benchmark)"
    [editorElem]="editorElem"
  ></df-activity-benchmark-item>
</div>
