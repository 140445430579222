import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FetchResult } from '@apollo/client/core';
import { LoginMutation } from '@modules/authorization/shared/graphql/mutations/login.mutation.generated';
import { SocialLoginInputGraphql } from '@modules/graphql/graphql-types';
import { Observable } from 'rxjs';
import { LoginWithSocialMediaAccountMutation } from '@modules/authorization/shared/graphql/mutations/ login-with-social-media-account.mutation.generated';
import { AbstractLoginComponent } from '@modules/authorization/shared/abstracts/abstract-login.component';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'df-public-login',
  templateUrl: './public-login.component.html',
  styleUrls: ['../../../authorization/shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicLoginComponent extends AbstractLoginComponent {
  constructor(changes: ChangeDetectorRef, route: ActivatedRoute, recaptchaService: ReCaptchaV3Service) {
    super(changes, recaptchaService, route);
  }

  protected getLoginRequest(token: string): Observable<FetchResult<LoginMutation>> {
    return this.authGraphqlService.login(
      this.formGroup.get('email')?.value,
      this.formGroup.get('password')?.value,
      this.formGroup.get('rememberMe')?.value,
      token,
    );
  }

  protected successSignIn(res: FetchResult<LoginMutation>) {
    super.successSignIn(res);
    this.redirectAfterSign();
  }

  protected getSocialLoginRequest(
    loginData: SocialLoginInputGraphql,
  ): Observable<FetchResult<LoginWithSocialMediaAccountMutation>> {
    return this.authGraphqlService.loginWithSocialMediaAccount(loginData);
  }

  protected successSignInBySocialAccount(res: FetchResult<LoginWithSocialMediaAccountMutation>) {
    super.successSignInBySocialAccount(res);
    this.redirectAfterSign();
  }

  protected redirectAfterSign() {
    window!.top!.location.href = Config.BASE_URL;
  }
}
