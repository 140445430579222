import { gql } from 'apollo-angular';

export const AddOrEditCanvasCategoryDocument = gql`
  mutation editOrAddCategory($input: CanvasCategoryAddOrInputInput!) {
    editOrAddCategory(input: $input) {
      id
      name
      description
    }
  }
`;
