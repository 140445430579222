<div class="content-wrapper">
  <h3 class="tui-text_h5">
    {{ 'Funnels.Statistics.To see and compare your reports follow those simple steps first:' | translate }}
  </h3>
  <div
    *ngFor="let step of CREATE_REPORT_STEPS"
    class="create-report-step"
  >
    <span
      class="mx-2.5 sm: mr-[0.5rem] w-8 h-8 rounded-md tui-text-05 flex items-center justify-center tui-text_body-m-3 flex-shrink-0 border border-solid border-gray-200"
    >
      {{ step.step }}
    </span>
    <span class="tui-text_body-l-2 tui-text-05">{{ step.label | translate }} </span>
    <span
      *ngIf="step.link"
      class="url-wrapper"
    >
      -
      <a
        [href]="step.link"
        target="_blank"
        >{{ step.linkLabel ? (step.linkLabel | translate) : step.link }}</a
      >
    </span>
  </div>
  <div class="action-tiles-container">
    <df-funnel-statistics-report-tile
      *ngFor="let actionTile of actionTiles"
      [report]="actionTile"
      (click)="onOpenForm(actionTile)"
    ></df-funnel-statistics-report-tile>
  </div>
</div>
