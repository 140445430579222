<df-full-list-loader *ngIf="!dialogMode && socialsLoading"></df-full-list-loader>
<div
  class="auth-panel flex justify-center items-center flex-col"
  [ngClass]="{ 'py-10 min-h-screen': !dialogMode, dialog: dialogMode }"
>
  <div class="auth-panel__container flex flex-col justify-start items-start">
    <img
      [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
      [alt]="xPartnerService.partner.SYSTEM_NAME"
      class="login__logo mb-0"
    />
    <h1 class="tui-text_h2 font-semibold tui-text-03 pl-0 pr-0 mt-4 w-auto">
      {{ 'Auth.Login.title' | translate }}
    </h1>
    <form
      class="flex flex-col"
      [formGroup]="formGroup"
      [validate-form]="formGroup"
      (submit)="signIn()"
    >
      <div class="tui-form__row">
        <tui-input
          data-test="email-input"
          #emailInput
          [tuiTextfieldCustomContent]="getControl('email')?.valid ? success : ''"
          formControlName="email"
        >
          {{ 'email' | translate }}
        </tui-input>
        <df-error-input
          data-test="error-input"
          [control]="getControl('email')"
        ></df-error-input>
        <ng-template #success>
          <tui-svg
            class="tui-accent-07 tui-space_left-3"
            src="tuiIconCheckLarge"
          ></tui-svg>
        </ng-template>
      </div>

      <div class="tui-form__row">
        <tui-input-password
          data-test="password-input"
          tuiHintContent
          tuiHintDirection="right"
          tuiHintMode="onDark"
          formControlName="password"
        >
          {{ 'password' | translate }}
        </tui-input-password>
      </div>

      <div class="tui-form__row flex justify-between flex-row items-center mt-6 gap-x-4">
        <tui-checkbox-labeled
          size="l"
          class="inline-flex"
          formControlName="rememberMe"
        >
          <span class="tui-text-body-s-2">{{ 'Auth.Login.Keep me logged in' | translate }}</span>
        </tui-checkbox-labeled>
        <a
          tuiLink
          (click)="forgotPassword()"
          [ngClass]="{
            'forget-animation': wrongDataAmount % 2 === 0 && wrongDataAmount > 1
          }"
        >
          <span class="tui-text-body-xs-2">{{ 'Auth.Login.Forgot password' | translate }}</span>
        </a>
      </div>

      <div class="tui-form__row mt-10">
        <button
          data-test="sign-in-button"
          size="xl"
          tuiButton
          type="submit"
          class="w-full block"
          appearance="primary"
          [showLoader]="loading"
          [disabled]="loading || !formGroup.valid"
        >
          {{
            !dialogMode
              ? ('Auth.Register.Sign in' | translate)
              : trial
                ? ('Auth.Register.Sign in and start trial' | translate)
                : ('Auth.Register.Sign in and buy plan' | translate: { name: planToBuy?.name })
          }}
        </button>
      </div>
      <hr class="mt-8" />
      <div class="social-login tui-form-row flex flex-col items-center mt-4">
        <span class="tui-text_body-m-2 tui-text-02 mb-4">
          {{ 'Auth.Login.Login with' | translate }}
        </span>
        <df-socials-authorization
          data-test="socials-auth-buttons"
          (signInWithGoogle)="signInWithGoogle()"
          (signInWithFacebook)="signInWithFB()"
        ></df-socials-authorization>
      </div>
      <div class="tui-form__row flex justify-between items-center mt-8">
        <span class="tui-text_body-m-2 tui-text-02">
          {{ "Auth.Login.Don't have an account yet" | translate }}
        </span>
        <a
          data-test="sign-up-link"
          tuiLink
          [routerLink]="n.getPath('sign-up')"
          [queryParams]="tacticRedirect ? { 'tactic-redirect': true } : null"
          class=""
          *ngIf="!dialogMode"
        >
          {{ 'Auth.Login.Sign up' | translate }}
        </a>
        <a
          tuiLink
          (click)="goToSignUp()"
          class=""
          *ngIf="dialogMode"
        >
          {{ 'Auth.Login.Sign up' | translate }}
        </a>
      </div>
    </form>
  </div>
</div>
