import { EventEmitter, Injectable } from '@angular/core';
import { ListSortItem } from '@shared/modules/ui/components/list-sub-header/list-sub-header.component';
import { FunnelListTypeEnum, OrderTypes } from '@modules/graphql/graphql-types';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Apollo } from 'apollo-angular';
import { FilterProjectsQuery } from '@modules/funnels/shared/graphql/queries/filter-projects.query.generated';
import { FilterProjectsDocument } from '@modules/funnels/shared/graphql/queries/filter-projects.query';
import { FetchResult } from '@apollo/client/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { RemoveTagDocument } from '../../pages/funnels-list-panel/components/funnels-list-projects/graphql/mutations/remove-tag.mutation';
import { RemoveTagsMutation } from '../../pages/funnels-list-panel/components/funnels-list-projects/graphql/mutations/remove-tag.mutation.generated';

@Injectable({
  providedIn: 'root',
})
export class FunnelsFilterService {
  sorts: ListSortItem[] = [
    {
      sortField: 'a.name',
      name: 'A-Z',
      sortDirection: OrderTypes.Asc,
    },
    {
      sortField: 'tacticsCount',
      name: 'Most tactics',
      sortDirection: OrderTypes.Desc,
    },
  ];

  projects: IFilter<any>[] = [];
  filterFormGroup: UntypedFormGroup = new UntypedFormGroup({
    sort: new UntypedFormControl(this.sorts[0]),
    projects: new UntypedFormControl([]),
    type: new UntypedFormControl(FunnelListTypeEnum.All),
    search: new UntypedFormControl(''),
  });
  initProjectsIds: number[] = [];
  areFiltersLoaded = false;
  $filtersLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private apollo: Apollo,
    private t: TranslateService,
    private s: SnackbarService,
  ) {
    this.getFilters();
  }

  removeTag(tagId: number) {
    return this.apollo.mutate<RemoveTagsMutation>({
      mutation: RemoveTagDocument,
      variables: {
        tagId,
      },
    });
  }

  getFilters(withoutEmit?: boolean) {
    this.apollo
      .query<FilterProjectsQuery>({
        query: FilterProjectsDocument,
        variables: {
          input: {
            page: 1,
            records: 100,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe({
        next: (res: FetchResult<FilterProjectsQuery>) => {
          this.projects = res?.data?.filterProjects?.records!;
          !withoutEmit ? this.$filtersLoaded.emit(true) : '';
          this.areFiltersLoaded = true;
        },
        error: () => {
          this.s.error(
            this.t.instant('Tactics.List.There is problem with getting filters. Refresh your view and try again.'),
          );
        },
      });
  }
}
