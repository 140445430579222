<form [formGroup]="form">
  <tui-select
    class="hidden md:block sm:w-60"
    [valueContent]="stringifyCategory"
    [tuiTextfieldCleaner]="true"
    formControlName="purchased"
  >
    {{ 'Tactics.List.Badge' | translate }}
    <tui-data-list *tuiDataList>
      <button
        tuiOption
        *ngFor="let item of tacticsFiltersService.purchasedFilters"
        [value]="item"
      >
        {{ item.name }}
      </button>
    </tui-data-list>
  </tui-select>
</form>
