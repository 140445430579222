import { IFilter } from '@shared/interfaces/filter.interface';
import { User } from '@shared/models/user.model';
import {
  AttachmentOutputGraphql,
  FunnelPermissionEnum,
  StepOutputGraphql,
  TacticBadgeEnum,
  TacticMetricOutputGraphql,
  TacticOutputGraphql,
  TacticRateOutputGraphql,
  TacticShareOutputGraphql,
  VerificationStatusEnum,
} from '@modules/graphql/graphql-types';
import { Activity } from '@shared/models/activity.model';
import { DateTime } from 'luxon';

export class Tactic implements TacticOutputGraphql {
  id!: number;
  name!: string;
  target?: string;
  categories!: IFilter<any>[];
  segments!: IFilter<any>[];
  effects!: IFilter<any>[];
  funnelSteps!: StepOutputGraphql[];
  stepId?: number;
  type?: IFilter<any>;
  levelOfAdvancement?: IFilter<any>;
  publiclyVisible?: boolean;
  requiresBudget?: boolean;
  tacticDetails = '';
  owner?: User;
  inMyFunnel = false;
  rate!: number;
  myRate!: TacticRateOutputGraphql;
  colorStepClasses = '';
  activities!: Activity[];
  hasCheckedActivity?: boolean = false;
  shares!: TacticShareOutputGraphql[];
  isBanner!: boolean;
  isTopRated!: boolean;
  isPrivate!: boolean;
  imageUrl?: string;
  firstTopBanner?: boolean = false;
  shortDescription?: string;
  popularity?: number;
  isFavourite!: boolean;
  copyForSale!: string;
  currency?: string;
  isPaid = false;
  price?: number;
  root?: Tactic;
  canBePayed!: boolean;
  activitiesCount!: number;
  benchmarksCount!: number;
  assetsCount!: number;
  purchased = false;
  sharetribeListingUuid?: string;
  verificationStatus?: VerificationStatusEnum;
  badge?: TacticBadgeEnum;
  modifiedAt!: string;
  modified?: DateTime;
  currentUserPermission!: FunnelPermissionEnum;
  createdAt!: string;
  image?: AttachmentOutputGraphql;
  metrics!: TacticMetricOutputGraphql[];

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
    this.deserialize();
  }

  deserialize() {
    this.owner ? (this.owner = new User(this.owner)) : '';
    this.root ? (this.root = new Tactic(this.root)) : '';
    this.rate ? (this.rate = Math.round(this.rate * 2) / 2) : (this.rate = 0);
    this.colorStepClasses = this?.funnelSteps?.map((step) => step.type).join(' ') || '';
    this.modified = DateTime.fromISO(this.modifiedAt);
    this.setShortDescription();
  }

  setShortDescription() {
    const tagsToReplace = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
    };
    this.shortDescription = this.tacticDetails?.replace(/[&<>]/g, function (tag) {
      return tagsToReplace[tag] || tag;
    });
  }

  hasData(): boolean {
    return this.badge !== TacticBadgeEnum.NoData;
  }
}
