import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { TacticMetricOutputGraphql } from './../../../../../../../graphql/graphql-types';
import { ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { filter, map, switchMap } from 'rxjs/operators';
import { TacticMetricFormModalComponent } from './components/tactic-metric-form-modal/tactic-metric-form-modal.component';
import { GetTacticsMetricsQuery } from './graphql/queries/get-tactic-metrics.query.generated';
import { ActivatedRoute } from '@angular/router';
import { TacticMetricFormService } from './services/tactic-metric-form.service';
import { DOCUMENT } from '@angular/common';
import { TacticMetricData } from './interfaces/tactic-metric-data.interface';
import { ETacticMetricControlState } from './enums/tactic-metric-control-state.enum';

@Component({
  selector: 'df-tactic-settings-metrics',
  templateUrl: './tactic-settings-metrics.component.html',
  styleUrls: ['./tactic-settings-metrics.component.scss'],
})
export class TacticSettingsMetricsComponent implements OnInit {
  readonly ETacticMetricControlState = ETacticMetricControlState;
  readonly tacticMetricsLimit: number = 5;

  get metrics(): TacticMetricData[] {
    return this.tacticMetricFormService.tacticMetricsInputList;
  }

  get metricsWithoutRemoved(): TacticMetricData[] {
    return this.tacticMetricFormService.tacticMetricsInputList.filter(
      (m) => m.status !== ETacticMetricControlState.REMOVE,
    );
  }

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    @Inject(DOCUMENT) private document: Document,
    private s: SnackbarService,
    private t: TranslateService,
    private dialogService: TuiDialogService,
    public readonly tacticSettingsService: TacticSettingsService,
    private tacticGraphqlService: TacticGraphqlService,
    private changes: ChangeDetectorRef,
    private route: ActivatedRoute,
    private tacticMetricFormService: TacticMetricFormService,
  ) {}
  ngOnInit(): void {
    this.fetchMetrics();
  }

  fetchMetrics() {
    this.tacticMetricFormService.tacticMetricsInputList = []; // clear onInit

    this.route.params
      .pipe(
        filter((params) => params.id),
        switchMap((params) => {
          return this.tacticGraphqlService
            .getTacticsMetrics(+params.id)
            .pipe(map((res: FetchResult<GetTacticsMetricsQuery>) => (res.data ? res.data.getTacticsMetrics : null)));
        }),
      )
      .subscribe((res: TacticMetricOutputGraphql[] | null) => {
        res!.forEach((r) => {
          this.tacticMetricFormService.tacticMetricsInputList.push({
            output: r,
            status: ETacticMetricControlState.UNTOUCHED,
          });
        });

        this.changes.detectChanges();
      });
  }

  openTacticMetricModal(index?: number, id?: number): void {
    index !== null && index !== undefined ? this.tacticMetricFormService.tacticMetricIndex.next(index) : null;
    if ((index == null || index === undefined) && this.metricsWithoutRemoved.length === this.tacticMetricsLimit) {
      // Adding max 5 tactics
      return;
    }
    id ? this.tacticMetricFormService.tacticMetricId.next(id) : null;
    this.dialogService
      .open<number>(new PolymorpheusComponent(TacticMetricFormModalComponent, this.injector), {
        size: 'm',
        closeable: false,
        dismissible: false,
      })
      .subscribe();
  }

  remove(index: number): void {
    this.tacticMetricFormService.removeTacticMetric(index);
    this.document.querySelector('tui-dropdown-box')?.remove();
    this.tacticSettingsService._changesInForm$.next(true);
    this.changes.detectChanges();
  }
}
