import { Injectable } from '@angular/core';
import { BaseStorageService } from '@core/services/base-storage.service';
import { User } from '@shared/models/user.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { StorageKey } from '@shared/enums/storage-key.enum';
import {
  ChangePasswordInputGraphql,
  ListInput,
  SendMessageInputGraphql,
  SubscriptionPlanOutputGraphql,
  UserProfileInputGraphql,
} from '@modules/graphql/graphql-types';
import { Apollo } from 'apollo-angular';
import { MeQuery } from '@shared/graphql/queries/me.query.generated';
import { MeDocument } from '@shared/graphql/queries/me.query';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { RemoveProfileMutation } from '@shared/graphql/mutations/remove-profile.mutation.generated';
import { RemoveProfileDocument } from '@shared/graphql/mutations/remove-profile.mutation';

import { ChangePasswordDocument } from '@shared/graphql/mutations/change-password.mutation';
import { EditProfileMutation } from '@shared/graphql/mutations/edit-profile.mutation.generated';
import { EditProfileDocument } from '@shared/graphql/mutations/edit-profile.mutation';
import { CreateUserAvatarMutation } from '@shared/graphql/mutations/create-user-avatar.mutation.generated';
import { CreateUserAvatarDocument } from '@shared/graphql/mutations/create-user-avatar.mutation';
import { map } from 'rxjs/operators';
import { ChangePasswordMutation } from '@shared/graphql/mutations/change-password.mutation.generated';
import { GetUserTacticsQuery } from '@modules/users/shared/graphql/queries/get-user-tactics.query.generated';
import { GetUserTacticsDocument } from '@modules/users/shared/graphql/queries/get-user-tactics.query';
import { SendMessageMutation } from '@modules/users/shared/graphql/mutations/send-message.mutation.generated';
import { SendMessageDocument } from '@modules/users/shared/graphql/mutations/send-message.mutation';
import { MeSettingsDocument } from '@shared/graphql/queries/me-settings.query';
import { MeSettingsQuery } from '@shared/graphql/queries/me-settings.query.generated';
import { Funnel } from '@shared/models/funnel.model';
import { SetContextFunnelMutation } from '@shared/graphql/mutations/set-context-funnel.mutation.generated';
import { SetContextFunnelDocument } from '@shared/graphql/mutations/set-context-funnel.mutation';
import { EndTutorialMutation } from '@modules/users/shared/graphql/mutations/end-tutorial.mutation.generated';
import { EndTutorialDocument } from '@modules/users/shared/graphql/mutations/end-tutorial.mutation';
import { GenerateSubscriptionsManagementUrlMutation } from '@shared/graphql/mutations/generate-subscriptions-management-url.mutation.generated';
import { GenerateSubscriptionsManagementUrlDocument } from '@shared/graphql/mutations/generate-subscriptions-management-url.mutation';
import { GetSkillsQuery } from '@shared/graphql/queries/get-skills.query.generated';
import { GetSkillsDocument } from '@shared/graphql/queries/get-skills.query';
import { AddUserSkillMutation } from '@shared/graphql/mutations/add-user-skill.mutation.generated';
import { AddUserSkillDocument } from '@shared/graphql/mutations/add-user-skill.mutation';
import { RemoveUserSkillMutation } from '@shared/graphql/mutations/remove-user-skill.mutation.generated';
import { RemoveUserSkillDocument } from '@shared/graphql/mutations/remove-user-skill.mutation';
import { SetAffiliationIdMutation } from '@shared/graphql/mutations/set-affiliation-id.mutation.generated';
import { SetAffiliationIdDocument } from '@shared/graphql/mutations/set-affiliation-id.mutation';
import { SetAppSumoSubscriptionMutation } from '@shared/graphql/mutations/set-app-sumo-subscription.mutation.generated';
import { SetAppSumoSubscriptionDocument } from '@shared/graphql/mutations/set-app-sumo-subscription.mutation';
import { UserEndedOnboardingMutation } from '@shared/graphql/mutations/user-ended-onboarding.mutation.generated';
import { UserEndedOnboardingDocument } from '@shared/graphql/mutations/user-ended-onboarding.mutation';
import { GenerateUserStripeAccountLoginUrlMutation } from '@shared/graphql/mutations/generate-user-stripe-account-login-url.mutation.generated';
import { GenerateUserStripeAccountLoginUrlDocument } from '@shared/graphql/mutations/generate-user-stripe-account-login-url.mutation';
import { GenerateUserStripeVerificationUrlMutation } from '@shared/graphql/mutations/generate-user-stripe-verification-url.mutation.generated';
import { GenerateUserStripeVerificationUrlDocument } from '@shared/graphql/mutations/generate-user-stripe-verification-url.mutation';
import { EventsService, GoogleAnalyticsEvent } from './events.service';
import { uniqueId } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseStorageService {
  currentUserEmitter?: BehaviorSubject<User>;
  currentUser$?: Observable<User>;

  private readonly _credits$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  readonly credits$: Observable<number> = this._credits$.asObservable();

  private readonly _userEvents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly userEvents$: Observable<boolean> = this._userEvents$.asObservable();

  private _fetchedUser = false;

  get activePlanName(): string {
    return this.User?.activePlans[0]?.name || '';
  }

  constructor(
    private apollo: Apollo,
    private eventsService: EventsService,
  ) {
    super();
    this.setEmitter();
  }

  setEmitter() {
    if (this.User) {
      this.currentUserEmitter = new BehaviorSubject<User>(this.User as User);
      this.currentUser$ = this.currentUserEmitter.asObservable();
    }
  }

  get User(): User | null {
    const user = this.get(StorageKey.USER);
    return user ? new User(JSON.parse(user)) : null;
  }

  set User(user: User | null) {
    this._userEvents$.next(true);
    if (!user) return;
    const u = new User(user);
    this._credits$.next(user.credits);
    this.put(StorageKey.USER, JSON.stringify(u));
    if (!this.currentUserEmitter) {
      this.setEmitter();
    }
    this.currentUserEmitter?.next(u);
  }

  get isAppSumo(): boolean {
    return (this.User?.activePlans.filter((p) => p.name.includes('AppSumo')).length ?? 0) > 0;
  }

  get planName(): string {
    return this.User?.activePlans[0]?.name || '';
  }

  get planCredits(): number {
    return this.User?.activePlans[0]?.credits || 0;
  }

  get discordName(): string | null {
    return this.User?.discordName || null;
  }

  clearUser(): void {
    this.User = null;
    this.delete(StorageKey.USER);
  }

  isNewUserRegistered() {
    return this.get(StorageKey.NEW_USER_REGISTERED) === 'true';
  }

  setNewUserRegistered() {
    this.put(StorageKey.NEW_USER_REGISTERED, 'true');
  }

  clearNewUserRegistered() {
    this.delete(StorageKey.NEW_USER_REGISTERED);
  }

  createUserAvatar(file: File): Observable<FetchResult<CreateUserAvatarMutation>> {
    return this.apollo
      .mutate<CreateUserAvatarMutation>({
        mutation: CreateUserAvatarDocument,
        variables: {
          file,
        },
        context: {
          useMultipart: true,
        },
      })
      .pipe(
        map((res) => {
          if (res.data?.createUserAvatar) {
            this.User = res.data.createUserAvatar as User;
          }
          return res;
        }),
      );
  }

  deleteUser(): Observable<FetchResult<RemoveProfileMutation>> {
    return this.apollo.mutate<RemoveProfileMutation>({
      mutation: RemoveProfileDocument,
    });
  }

  editUser(input: UserProfileInputGraphql): Observable<FetchResult<EditProfileMutation>> {
    return this.apollo.mutate<EditProfileMutation>({
      mutation: EditProfileDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  setContextFunnel(funnel: Funnel) {
    if (funnel.name === 'Onboarding') return;

    const user = this.User;
    user!.contextFunnel = funnel;
    this.User = user;

    return this.apollo.mutate<SetContextFunnelMutation>({
      mutation: SetContextFunnelDocument,
      variables: {
        funnelId: funnel.id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getUserTactics(userId: number, query: ListInput): Observable<FetchResult<GetUserTacticsQuery>> {
    return this.apollo.query<GetUserTacticsQuery>({
      query: GetUserTacticsDocument,
      variables: {
        userId,
        query,
      },
      fetchPolicy: 'no-cache',
    });
  }

  changePassword(input: ChangePasswordInputGraphql): Observable<FetchResult<ChangePasswordMutation>> {
    return this.apollo.mutate<ChangePasswordMutation>({
      mutation: ChangePasswordDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getMeRequest(force = false): Observable<User | null> {
    if (!this._fetchedUser || force) {
      return this.apollo
        .query<MeQuery>({
          query: MeDocument,
          fetchPolicy: 'no-cache',
        })
        .pipe(
          map((res: FetchResult<MeQuery>) => {
            if (res.data) {
              this._fetchedUser = true;
              const user: User = new User(res.data?.me as User);
              this.User = user;
              this.setGAPaidStatus();
              this.eventsService.setUpIntercome(user);
              return user;
            } else {
              return null;
            }
          }),
        );
    } else {
      return of(this.User);
    }
  }

  getMe(force = false) {
    return this.getMeRequest(force).subscribe({
      error: (error: ApolloError) => {
        console.error(error);
      },
    });
  }

  generateSubscriptionManagementUrl(): Observable<FetchResult<GenerateSubscriptionsManagementUrlMutation>> {
    return this.apollo.mutate<GenerateSubscriptionsManagementUrlMutation>({
      mutation: GenerateSubscriptionsManagementUrlDocument,
      fetchPolicy: 'no-cache',
    });
  }
  generateUserStripeAccountLoginUrl(): Observable<FetchResult<GenerateUserStripeAccountLoginUrlMutation>> {
    return this.apollo.mutate<GenerateUserStripeAccountLoginUrlMutation>({
      mutation: GenerateUserStripeAccountLoginUrlDocument,
      fetchPolicy: 'no-cache',
    });
  }
  generateUserStripeVerificationUrl(): Observable<FetchResult<GenerateUserStripeVerificationUrlMutation>> {
    return this.apollo.mutate<GenerateUserStripeVerificationUrlMutation>({
      mutation: GenerateUserStripeVerificationUrlDocument,
      fetchPolicy: 'no-cache',
    });
  }

  getUserSettingsMe(): Observable<FetchResult<MeSettingsQuery>> {
    return this.apollo.query<MeSettingsQuery>({
      query: MeSettingsDocument,
      fetchPolicy: 'no-cache',
    });
  }

  sendMessage(input: SendMessageInputGraphql): Observable<FetchResult<SendMessageMutation>> {
    return this.apollo.mutate<SendMessageMutation>({
      mutation: SendMessageDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  endTutorial(tutorialEnded: boolean): Observable<FetchResult<EndTutorialMutation>> {
    return this.apollo.mutate<EndTutorialMutation>({
      mutation: EndTutorialDocument,
      variables: {
        tutorialEnded,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getSkills(): Observable<FetchResult<GetSkillsQuery>> {
    return this.apollo.query<GetSkillsQuery>({
      query: GetSkillsDocument,
      fetchPolicy: 'no-cache',
    });
  }

  addUserSkill(id: number): Observable<FetchResult<AddUserSkillMutation>> {
    return this.apollo.mutate<AddUserSkillMutation>({
      mutation: AddUserSkillDocument,
      variables: {
        skillId: id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeUserSkill(id: number): Observable<FetchResult<RemoveUserSkillMutation>> {
    return this.apollo.mutate<RemoveUserSkillMutation>({
      mutation: RemoveUserSkillDocument,
      variables: {
        skillId: id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  setAffiliationId(affiliationId: string): Observable<FetchResult<SetAffiliationIdMutation>> {
    return this.apollo.mutate<SetAffiliationIdMutation>({
      mutation: SetAffiliationIdDocument,
      variables: {
        affiliationId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  setAppSumoSubscription(token: string): Observable<FetchResult<SetAppSumoSubscriptionMutation>> {
    return this.apollo.mutate<SetAppSumoSubscriptionMutation>({
      mutation: SetAppSumoSubscriptionDocument,
      variables: {
        token,
      },
      fetchPolicy: 'no-cache',
    });
  }

  saveBeforePaymentUrl(url: string, hiddenUrl?: string): void {
    this.put(StorageKey.URL, url);
    if (hiddenUrl) {
      this.put(StorageKey.HIDDEN_URL, hiddenUrl);
    }
  }

  getBeforePaymentUrl(): string | null {
    return this.get(StorageKey.URL);
  }

  getHiddenBeforePaymentUrl(): string | null {
    return this.get(StorageKey.HIDDEN_URL);
  }

  clearBeforePaymentUrl(): void {
    this.delete(StorageKey.URL);
    this.delete(StorageKey.HIDDEN_URL);
  }

  savePlanToBuy(planId: number): void {
    this.put(StorageKey.PLAN_TO_BUY, planId.toString());
  }

  getPlanToBuy(): number | null {
    const planToBuy: string | null = this.get(StorageKey.PLAN_TO_BUY);
    return planToBuy ? (isNaN(+planToBuy) ? null : +planToBuy) : null;
  }

  clearPlanToBuy(): void {
    this.delete(StorageKey.PLAN_TO_BUY);
  }

  updateCredits(credits: number): void {
    const user = this.User!;
    user.credits = credits;
    this.User = user;
  }

  saveSelectedTrial(plan: SubscriptionPlanOutputGraphql | string): void {
    this.put(StorageKey.SELECTED_TRIAL, plan['name'] ?? plan);
  }

  getSelectedTrial(): string | null {
    const trial = this.get(StorageKey.SELECTED_TRIAL);
    this.delete(StorageKey.SELECTED_TRIAL);
    return trial;
  }

  userEndedOnboarding(): Observable<FetchResult<UserEndedOnboardingMutation>> {
    return this.apollo.mutate<UserEndedOnboardingMutation>({
      mutation: UserEndedOnboardingDocument,
      fetchPolicy: 'no-cache',
    });
  }

  resolvePricingPacketsUrl(fullPlan = false): string {
    if (fullPlan) return 'pricing/packets';
    return this.User?.isMarketingCampaignUser ? 'pricing/packets-campaign' : 'pricing/packets';
  }

  private setGAPaidStatus() {
    if (!this.User) return;
    if (this.User.successPlanPaid) return;

    const subscription = this.User.activePlans[0];

    this.eventsService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.Purchase, {
      ecommerce: {
        transaction_id: uniqueId(),
        value: subscription.price / 100,
        currency: 'USD',
        items: [
          {
            item_name: subscription.name,
            item_variant: subscription.paymentInterval,
            price: subscription.price / 100,
            quantity: 1,
          },
        ],
      },
    });
  }
}
