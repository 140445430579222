import { gql } from 'apollo-angular';

export const GetPublicUsersDocument = gql/* GraphQL*/ `
  query getPublicUsers($query: ListInput!) {
    getPublicUsers(query: $query) {
      records {
        id
        firstName
        lastName
        tacticsCount
        favouritesCount
        experienceYears
        avatar {
          file
        }
        skills {
          id
          name
        }
        isPro
      }
      count
      page
      perPage
    }
  }
`;
