<tui-island class="w-full mt-6">
  <div class="flex items-start xl:items-center flex-col xl:flex-row">
    <img
      [src]="illustration"
      alt=""
      class="hidden xl:flex -mt-16 -mb-24 mr-4 -ml-12"
    />
    <div class="w-full lg:w-3/5">
      <h3 class="tui-text_h3 font-medium mb-2">
        {{ title }}
      </h3>
      <p class="text-base tui-text-05">
        {{ subtitle }}
      </p>
    </div>
    <button
      *ngIf="!userService.User?.isSemrushPlan()"
      size="xl"
      tuiButton
      type="submit"
      appearance="accent"
      class="mt-8 xl:mt-0"
      (click)="onButtonClick.emit(true)"
    >
      {{ buttonText }}
    </button>
  </div>
</tui-island>
