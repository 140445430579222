<div class="auth-panel flex justify-center items-start lg:items-center min-h-screen flex-col p-10">
  <div class="auth-panel__container flex flex-col justify-start items-start">
    <h1 class="tui-text_h2 font-semibold tui-text-03 pl-0 pr-0 mt-4 w-auto">
      {{ 'Auth.NoAuthorization.title' | translate }}
    </h1>
    <p class="tui-text-02">
      {{ 'Auth.NoAuthorization.description' | translate }}
    </p>
  </div>
</div>
