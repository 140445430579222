import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@core/services/snackbar.service';
import { ActionTypeEnum } from '@modules/business-data/business-data.model';
import { Regex } from '@shared/configs/regex';

@Component({
  selector: 'df-scrape-website-form',
  templateUrl: './scrape-website-form.component.html',
  styleUrl: './scrape-website-form.component.scss',
})
export class ScrapeWebsiteFormComponent {
  type = ActionTypeEnum.SCRAPE_WEBSITE;
  @Input() form!: FormGroup;

  constructor(private s: SnackbarService) {}

  getFormArray() {
    return this.form.get(this.type) as FormArray;
  }

  addNewField() {
    this.getFormArray().push(new FormControl('', Validators.pattern(Regex.softUrl)));
  }

  removeField(index: number) {
    this.getFormArray().removeAt(index);
    this.s.info('Website URL removed');
  }
}
