import { PermissionsDecoratorService } from '@core/permissions/services/permissions-decorator.service';
import { CORE_MODULES } from '@core/consts/core-modules.const';
import { CORE_DIRECTIVES } from '@core/consts/core-directives.const';
import { CORE_PIPES } from '@core/consts/core-pipes.const';
import { CORE_COMPONENTS } from '@core/consts/core-components.const';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [CORE_DIRECTIVES, CORE_PIPES, CORE_COMPONENTS],
  exports: [CORE_MODULES, CORE_COMPONENTS, CORE_PIPES, CORE_DIRECTIVES],
  imports: [CORE_MODULES],
  providers: [PermissionsDecoratorService, CORE_PIPES],
  bootstrap: [],
})
export class CoreModule {}
