import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PaymentIntervalEnum } from '@modules/graphql/graphql-types';

interface PaymentTabDetail {
  index: number;
  name: string;
}

interface PaymentTab {
  index: number;
  name: string;
  interval: PaymentIntervalEnum;
  discount?: PaymentTabDetail | null;
}

const PAYMENT_PERIOD_TABS: PaymentTab[] = [
  {
    index: 0,
    name: 'Pricing.Annual billing',
    interval: PaymentIntervalEnum.Year,
    discount: {
      index: 0,
      name: 'Pricing.Annual discount',
    },
  },
  {
    index: 1,
    name: 'Pricing.Monthly billing',
    interval: PaymentIntervalEnum.Month,
    discount: null,
  },
];

@Component({
  selector: 'df-pricing-period-switch',
  templateUrl: './pricing-period-switch.component.html',
  styleUrls: ['./pricing-period-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingPeriodSwitchComponent implements OnInit {
  readonly PaymentIntervalEnum = PaymentIntervalEnum;

  readonly PAYMENT_PERIOD_TABS = PAYMENT_PERIOD_TABS;
  selectedTab: PaymentTab = PAYMENT_PERIOD_TABS[0];
  isTabResolved = false;

  constructor(
    public pricingService: PricingService,
    private readonly changes: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setPricing('year');
    this.resolveSelectedTab();
  }

  setPricing(paymentIntervalType: string) {
    this.pricingService.setPaymentInterval(paymentIntervalType);
  }

  public setTab(tab: PaymentTab): void {
    this.selectedTab = tab;
    this.changes.detectChanges();
  }

  private resolveSelectedTab(): void {
    const selectedInterval: PaymentIntervalEnum = this.pricingService.paymentInterval as PaymentIntervalEnum;
    for (let index = 0; index < PAYMENT_PERIOD_TABS.length; index++) {
      if (PAYMENT_PERIOD_TABS[index].interval === selectedInterval) {
        this.selectedTab = PAYMENT_PERIOD_TABS[index];
        break;
      }
    }
    this.isTabResolved = true;
    this.changes.detectChanges();
  }
}
