import { gql } from 'apollo-angular';
import { EditTacticFragment } from '@modules/tactics/shared/graphql/fragments/edit-tactic.fragment';

export const GetTacticForEditDocument = gql/* GraphQL*/ `
  query getTacticForEdit($id: Int!) {
    getTactic(id: $id) {
      ...EditTactic
    }
  }
  ${EditTacticFragment}
`;
