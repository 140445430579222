<div>
  <img
    class="mx-auto mb-6"
    src="assets/img/modal-notification-icon.svg"
    alt=""
  />
  <h2 class="text-[24px] font-semibold text-[#191C30E5] mb-2">We couldn't recognize some of the content you added.</h2>
  <p class="text-[16px] font-medium text-[#1B1F3BA6]">Discover the types of content we support.</p>

  <div class="my-8">
    <p
      class="flex"
      *ngFor="let error of errors.graphQLErrors"
    >
      <img
        src="assets/img/text-files.svg"
        alt=""
      />
      {{ error.message }}
    </p>
  </div>

  <tui-accordion-item class="accordion mb-10">
    <div class="flex">
      <svg-icon
        class="mr-4"
        src="assets/img/tip.svg"
      ></svg-icon>
      Suggestions for Sharing Data Information
    </div>
    <ng-template tuiAccordionItemContent>
      When submitting data please ensure that it is closely related to your business and predominantly comprises textual
      information. This allows our AI to thoroughly analyze and extract key insights, facilitating the generation of a
      comprehensive strategy tailored to your needs.
    </ng-template>
  </tui-accordion-item>
</div>
