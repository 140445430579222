import { gql } from 'apollo-angular';
import { BenchmarkFragment } from '@modules/tactics/shared/graphql/fragments/benchmark.fragment';
import { IntegrationOutputDataFragment } from '@modules/funnels/shared/graphql/fragments/integration-output-data.fragment';
import { IntegrationFragment } from '@modules/funnels/shared/graphql/fragments/integration.fragment';
import { CustomPromptFragment } from '@modules/tactics/shared/graphql/fragments/custom-prompt.fragment';

export const GetFunnelTacticDocument = gql`
  query getFunnelTactic($id: Int!) {
    getFunnelTactic(id: $id) {
      id
      position
      name
      isDone
      doneActivities {
        activity {
          id
        }
      }
      step {
        id
        type
      }
      tactic {
        id
        name
        badge
        categories {
          name
        }
        effects {
          name
        }
        funnelSteps {
          id
          type
          name
        }
        type {
          name
        }
        activities {
          id
          name
          description
          copy
          position
          type {
            id
            name
            image
          }
          assets {
            id
            asset {
              id
              native
              fileType
              ratio
              primaryText
              headline
              description
              maxFileSize
              adType {
                id
                name
              }
              platform {
                id
                name
                image
              }
              placement {
                id
                name
              }
              properties {
                key
                value
              }
              integrations {
                ...Integration
              }
            }
            selectedResolutions {
              id
              width
              height
            }
          }
          benchmarks {
            ...BenchmarkFragment
          }
          customPrompts {
            ...CustomPromptFragment
          }
        }
        assetsCount
      }
      selectedOutputsData {
        ...IntegrationOutputData
        activityAsset {
          id
        }
      }
    }
  }
  ${BenchmarkFragment}
  ${CustomPromptFragment}
  ${IntegrationOutputDataFragment}
  ${IntegrationFragment}
`;
