import { gql } from 'apollo-angular';

export const GetAccountSetupParametersDocument = gql`
  query getAccountSetupParameters {
    getAccountSetupParameters {
      id
      name
      category
    }
  }
`;
