import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GeneratePDFDocument } from './graphql/queries/generate-pdf.query';
import { GeneratePdfQuery } from './graphql/queries/generate-pdf.query.generated';

@Injectable({
  providedIn: 'root',
})
export class FunnelCanvasesService {
  constructor(private apollo: Apollo) {}

  generatePDF(instanceId: number) {
    return this.apollo.mutate<GeneratePdfQuery>({
      fetchPolicy: 'no-cache',
      mutation: GeneratePDFDocument,
      variables: { instanceId },
    });
  }
}
