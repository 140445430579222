import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';

@Component({
  selector: 'df-funnel-manage-columns',
  templateUrl: './funnel-manage-columns.component.html',
  styleUrls: ['./funnel-manage-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageColumnsComponent {
  @Input() tactic?: FunnelTactic;
}
