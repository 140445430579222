<div
  *ngFor="let option of checkboxItems"
  class="awsome-checkbox flex flex-row justify-start items-center relative mt-3 gap-x-3 py-6 pr-10 pl-3 sm:p-3"
  [class.error]="checkboxError"
  (click)="setOption(option, $event)"
  [class.checked]="form.get(fieldName)?.value?.id === option.id"
>
  <img
    *ngIf="option?.image"
    class="flex-shrink-0 hidden sm:block"
    [src]="'assets/img/illustration_setup_clients.svg'"
    [alt]="option.name"
  />
  <div>
    <h6 class="tui-text_h6 tui-text-03 mb-2 font-semibold">
      {{ option.name }}
    </h6>
    <p class="tui-text-body-s-2 tui-text-02">
      {{ option.description }}
    </p>
  </div>
  <div class="absolute top-3 right-3">
    <tui-primitive-checkbox class="awsome-checkbox__checkbox"></tui-primitive-checkbox>
  </div>
</div>

<df-error-input
  [control]="form.get(fieldName)"
  [manualShowingError]="checkboxError"
></df-error-input>
