import { gql } from 'apollo-angular';
import { FunnelFragment } from '@modules/funnels/shared/graphql/fragments/funnel.fragment';

export const GetFunnelDocument = gql`
  query getFunnel($funnelId: Int!) {
    getFunnel(funnelId: $funnelId) {
      ...Funnel
    }
  }
  ${FunnelFragment}
`;
