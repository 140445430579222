import { gql } from 'apollo-angular';

export const GetCategoryDocument = gql`
  query getCategory {
    getCategory {
      id
      name
      label
      category {
        name
        label
      }
    }
  }
`;
