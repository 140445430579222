<header class="mb-8 m-auto print:mt-4 print:break-before-page md:ml-5">
  <h2 class="font-bold text-[24px] text-[#2E4FFF] col">
    {{ 'MarketingCampaign.Business canvases' | translate }}
  </h2>
  <p class="text-[#191C30BF] font-medium text-[16px] mt-4">
    {{ 'MarketingCampaign.Craft, refine, and communicate your business strategies effectively' | translate }}
  </p>
</header>

<div
  id="typewriter-canvas"
  class="print:break-before-page"
></div>

<df-skeleton
  [showSkeleton]="showSkeleton"
  [rows]="1"
  [cols]="3"
></df-skeleton>
