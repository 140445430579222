import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FunnelGraphicsGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-graphics/funnel-graphics-generator.service';
import { ContentGeneratorEventEnum } from '@modules/funnels/shared/enums/content-generator-selection-type.enum';
import { TuiDialog } from '@taiga-ui/cdk';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { filter } from 'rxjs/operators';
import { EContentGeneratorStylesForm } from '../../../../enums/content-generator-styles-form';
import { StylesFormService } from '../../../../services/styles-form.service';

@Component({
  selector: 'df-content-generator-styles-modal',
  templateUrl: './content-generator-styles-modal.component.html',
  styleUrls: ['./content-generator-styles-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorStylesModalComponent implements OnInit, OnDestroy {
  readonly EContentGeneratorStylesForm = EContentGeneratorStylesForm;
  readonly ContentGeneratorEventEnum = ContentGeneratorEventEnum;
  stylesList: Array<{ label: string; photo: string; value: string }> = [];
  get form() {
    return this.stylesFormService.form;
  }

  constructor(
    private stylesFormService: StylesFormService,
    public readonly funnelGraphicsGeneratorService: FunnelGraphicsGeneratorService,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<
      {
        data: {
          metadata: {
            options: Array<{ label: string; photo: string; value: string }>;
          };
        };
      },
      number
    >,
  ) {}

  ngOnInit(): void {
    this.getStylesList();
  }

  getStylesList() {
    this.stylesList = this.context.data?.metadata?.options as Array<{
      label: string;
      photo: string;
      value: string;
    }>;
  }

  closeModal() {
    this.stylesFormService.stylesModalSub$.unsubscribe();
  }

  saveSelection() {
    const selectionValue = this.form.controls[EContentGeneratorStylesForm.STYLE].value;
    this.stylesFormService.formControlId$.pipe(filter((id) => !!id)).subscribe((id) => {
      if (id) {
        // patch form value
        this.funnelGraphicsGeneratorService.form.controls[id].patchValue([selectionValue]);
        this.stylesFormService.changesInGeneratorForm.next(true);
      }
    });

    this.closeModal();
  }

  ngOnDestroy(): void {
    this.closeModal();
  }
}
