export enum ETacticSettingsFormEnum {
  name = 'name',
  target = 'target',
  categoriesIds = 'categoriesIds',
  segmentsIds = 'segmentsIds',
  effectsIds = 'effectsIds',
  funnelStepsIds = 'funnelStepsIds',
  typeId = 'typeId',
  levelOfAdvancementId = 'levelOfAdvancementId',
  requiresBudget = 'requiresBudget',
  tacticDetails = 'tacticDetails',
  // badge = 'badge',
  image = 'image',
  accessibility = 'accessibility',
  activities = 'activities',
}
