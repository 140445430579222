import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TacticPreviewService } from '@modules/tactics/modules/tactic-preview/shared/services/tactic-preview.service';
import { Config } from '@shared/configs/config';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TacticPreviewActivitiesComponent } from '@modules/tactics/modules/tactic-preview/pages/tactic-preview/components/tactic-preview-activities/tactic-preview-activities.component';
import { Tactic } from '@shared/models/tactic.model';
import { createSlug } from '@shared/helpers/create-slug.helper';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { AdminTokenService } from '@shared/services/admin-token.service';

@Component({
  selector: 'df-tactic-preview',
  templateUrl: './tactic-preview.component.html',
  styleUrls: ['./tactic-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticPreviewComponent extends AbstractSubscriptionComponent implements OnInit {
  @ViewChild('tacticPreviewActivitiesComponent')
  tacticPreviewActivitiesComponent!: TacticPreviewActivitiesComponent;
  @Input() tactic?: Tactic;
  @Input() inModal = false;
  @Input() context: any;
  @Input() isPublic = false;
  adminView = false;

  constructor(
    public tacticPreviewService: TacticPreviewService,
    private n: NavigateService,
    private route: ActivatedRoute,
    private router: Router,
    private changes: ChangeDetectorRef,
    private adminTokenService: AdminTokenService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.tacticPreviewService.isPublic = this.isPublic;
    this.listenRouteChange();
  }

  listenRouteChange(): void {
    this.sub.add(
      this.route.params.subscribe((params) => {
        const id = params['id'] || this.tactic?.id;
        if (id) {
          let isPublic: boolean = this.isPublic;
          if (this.route.snapshot.queryParamMap.has('auth')) {
            this.adminTokenService.token = this.route.snapshot.queryParams.auth ?? null;
            this.router.navigate(['.'], {
              relativeTo: this.route,
              queryParams: null,
            });
            isPublic = false;
            this.adminView = true;
          }
          this.tacticPreviewService.getTactic(id, isPublic, this.adminView).add(() => this.changes.detectChanges());
        } else {
          this.n.go(Config.MAIN_VIEW);
        }
      }),
    );
  }

  openActivities() {
    this.tacticPreviewActivitiesComponent.mobileOpened = true;
    this.changes.detectChanges();
  }

  buyTactic(): void {
    const slug = createSlug(this.tacticPreviewService.tactic?.name ?? 't');
    window.location.href =
      Config.MARKETPLACE_URL + `/l/${slug}/${this.tacticPreviewService.tactic?.sharetribeListingUuid}`;
  }
}
