import { AppInjector } from '@core/services/app-injector.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user.service';
import { TeamGraphqlService } from '@modules/user-settings/modules/teams/shared/services/team-graphql.service';
import { map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { CreateTeamMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/create-team.mutation.generated';
import { Observable } from 'rxjs';
import { TeamsService } from '@modules/user-settings/modules/teams/shared/services/teams.service';
import { Team, TeamMember } from '@shared/models/team.model';
import {
  EditTeamInputGraphql,
  TeamMemberInputGraphql,
  TeamOutputGraphql,
  TeamRoleEnum,
} from '@modules/graphql/graphql-types';
import { GetTeamQuery } from '@modules/user-settings/modules/teams/shared/graphql/queries/get-team.query.generated';
import { AddTeamMembersMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/add-team-members.mutation.generated';
import { EditTeamMemberMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/edit-team-member.mutation.generated';
import { RemoveTeamMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/remove-team.mutation.generated';
import { EditTeamMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/edit-team.mutation.generated';
import { LeaveTeamMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/leave-team.mutation.generated';
import { RemoveTeamMemberMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/remove-team-member.mutation.generated';

export class TeamController {
  s!: SnackbarService;
  t!: TranslateService;
  teamGraphQLService!: TeamGraphqlService;
  userService!: UserService;
  teamsService!: TeamsService;

  constructor() {
    const injector = AppInjector.getInjector();
    this.s = injector.get(SnackbarService);
    this.t = injector.get(TranslateService);
    this.teamsService = injector.get(TeamsService);
    this.teamGraphQLService = injector.get(TeamGraphqlService);
  }

  createTeam(name: string, icon?: File): Observable<FetchResult<CreateTeamMutation>> {
    return this.teamGraphQLService.createTeam(name, icon).pipe(
      map((res: FetchResult<CreateTeamMutation>) => {
        if (!res?.data) return res;

        const teams: Team[] = this.teamsService.teams;
        teams.push(new Team(res.data?.createTeam as TeamOutputGraphql));
        this.teamsService.teams = teams;
        return res;
      }),
    );
  }

  removeTeam(teamId: number): Observable<FetchResult<RemoveTeamMutation>> {
    return this.teamGraphQLService.removeTeam(teamId).pipe(
      map((res: FetchResult<RemoveTeamMutation>) => {
        const teams: Team[] = this.teamsService.teams;
        const teamIndex = teams.findIndex((team) => team.id === teamId);
        if (teamIndex !== -1) {
          teams.splice(teamIndex, 1);
          this.teamsService.teams = teams;
        }
        return res;
      }),
    );
  }

  editTeam(name: string, id: number, icon?: File): Observable<FetchResult<EditTeamMutation>> {
    return this.teamGraphQLService.editTeam({ name, id } as EditTeamInputGraphql, icon).pipe(
      map((res: FetchResult<EditTeamMutation>) => {
        const teams: Team[] = this.teamsService.teams;
        const teamIndex = teams.findIndex((team) => team.id === id);
        if (teamIndex !== -1) {
          teams[teamIndex] = res.data?.editTeam as Team;
          this.teamsService.teams = teams;
        }
        return res;
      }),
    );
  }

  leaveTeam(id: number): Observable<FetchResult<LeaveTeamMutation>> {
    return this.teamGraphQLService.leaveTeam(id).pipe(
      map((res: FetchResult<LeaveTeamMutation>) => {
        const teams: Team[] = this.teamsService.teams;
        const teamIndex = teams.findIndex((team) => team.id === id);
        if (teamIndex !== -1) {
          teams.splice(teamIndex, 1);
          this.teamsService.teams = teams;
        }
        return res;
      }),
    );
  }

  getTeam(id: number): Observable<Team | null> {
    return this.teamGraphQLService
      .getTeam(id)
      .pipe(
        map((res: FetchResult<GetTeamQuery>) => (res.data ? new Team(res.data.getTeam as TeamOutputGraphql) : null)),
      );
  }

  addTeamMembers(teamId: number, members: TeamMemberInputGraphql[]): Observable<FetchResult<AddTeamMembersMutation>> {
    return this.teamGraphQLService.addTeamMembers(teamId, members).pipe(
      map((res: FetchResult<AddTeamMembersMutation>) => {
        const teams: Team[] = this.teamsService.teams;
        const teamIndex = teams.findIndex((team) => team.id === teamId);
        if (teamIndex !== -1) {
          teams[teamIndex].members = teams[teamIndex].members.concat(res.data?.addTeamMembers as TeamMember[]);
          this.teamsService.teams = teams;
        }
        return res;
      }),
    );
  }

  editTeamMember(
    teamId: number,
    memberId: number,
    role: TeamRoleEnum,
  ): Observable<FetchResult<EditTeamMemberMutation>> {
    return this.teamGraphQLService.editTeamMember(memberId, role).pipe(
      map((res: FetchResult<EditTeamMemberMutation>) => {
        const teams: Team[] = this.teamsService.teams;
        const teamIndex = teams.findIndex((team) => team.id === teamId);
        if (teamIndex !== -1) {
          const memberIndex = teams[teamIndex].members.findIndex((member) => member.id === memberId);
          if (memberIndex !== -1) {
            teams[teamIndex].members[memberIndex] = res.data?.editTeamMember as TeamMember;
          }
          this.teamsService.teams = teams;
        }
        return res;
      }),
    );
  }

  removeTeamMember(teamId: number, memberId: number): Observable<FetchResult<RemoveTeamMemberMutation>> {
    return this.teamGraphQLService.removeTeamMember(memberId).pipe(
      map((res: FetchResult<RemoveTeamMemberMutation>) => {
        const teams: Team[] = this.teamsService.teams;
        const teamIndex = teams.findIndex((team) => team.id === teamId);
        if (teamIndex !== -1) {
          const memberIndex = teams[teamIndex].members.findIndex((member) => member.id === memberId);
          if (memberIndex !== -1) {
            teams[teamIndex].members.splice(memberIndex, 1);
          }
          this.teamsService.teams = teams;
        }
        return res;
      }),
    );
  }
}
