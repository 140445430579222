<div class="w-full">
  <df-tactic-settings-tab-title></df-tactic-settings-tab-title>

  <form
    [formGroup]="form"
    [validate-form]="form"
  >
    <div class="tui-form__row tui-form__row_multi-fields">
      <div class="tui-form__multi-field">
        <tui-input
          [formControlName]="ETacticSettingsFormEnum.target"
          [attr.data-error-control]="ETacticSettingsFormEnum.target"
        >
          {{ 'Tactics.target' | translate }}
        </tui-input>
        <df-error-input [control]="getControl(ETacticSettingsFormEnum.target)"></df-error-input>
      </div>
      <div class="tui-form__multi-field">
        <df-multi-select
          #categoryMultiselect
          (change)="dfCategoryError.setErrorVisible(true)"
          [controlName]="ETacticSettingsFormEnum.categoriesIds"
          [formGroup]="form"
          [query]="FilterTacticsCategoriesDocument"
          [attr.data-error-control]="ETacticSettingsFormEnum.categoriesIds"
        >
          {{ 'Tactics.category' | translate }}
        </df-multi-select>
        <df-error-input
          [control]="getControl(ETacticSettingsFormEnum.categoriesIds)"
          #dfCategoryError
        ></df-error-input>
      </div>
    </div>
    <div class="tui-form__row tui-form__row_multi-fields">
      <div class="tui-form__multi-field">
        <df-multi-select
          #segmentsMultiselect
          (change)="dfSegmentError.setErrorVisible(true)"
          [controlName]="ETacticSettingsFormEnum.segmentsIds"
          [formGroup]="form"
          [query]="FilterSegmentsDocument"
          [attr.data-error-control]="ETacticSettingsFormEnum.segmentsIds"
        >
          {{ 'Tactics.segment' | translate }}
        </df-multi-select>
        <df-error-input
          [control]="getControl(ETacticSettingsFormEnum.segmentsIds)"
          #dfSegmentError
        ></df-error-input>
      </div>
      <div class="tui-form__multi-field">
        <df-single-select
          #effectsMultiselect
          (change)="dfEffectError.setErrorVisible(true)"
          [size]="'l'"
          [controlName]="ETacticSettingsFormEnum.effectsIds"
          [formGroup]="form"
          [query]="FilterEffectsDocument"
          [attr.data-error-control]="ETacticSettingsFormEnum.effectsIds"
        >
          {{ 'Tactics.effect' | translate }}
        </df-single-select>
        <df-error-input
          [control]="getControl(ETacticSettingsFormEnum.effectsIds)"
          #dfEffectError
        ></df-error-input>
      </div>
    </div>
    <div class="tui-form__row">
      <label class="tui-text-02 labels mb-2 flex flex-row justify-start items-center">
        {{ 'Tactics.type' | translate }}
        <svg-icon
          class="ml-2"
          [src]="'/assets/img/ic-info.svg'"
          [tuiHint]="typeTooltip"
          [tuiHintDirection]="'right'"
        ></svg-icon>
        <ng-template #typeTooltip>
          <div [innerHTML]="'Tactics.Manage.typeInfo' | translate"></div>
        </ng-template>
      </label>
      <tui-filter
        tuiFilterMaxItems
        #tuiTypes
        [tuiFilter]="tuiTypes"
        [maxItems]="1"
        [identityMatcher]="identityMatcher"
        [formControlName]="ETacticSettingsFormEnum.typeId"
        [attr.data-error-control]="ETacticSettingsFormEnum.typeId"
        size="m"
        [content]="typeContent"
        [items]="tacticParametersService.types"
      ></tui-filter>
      <ng-template
        let-data
        #typeContent
      >
        <span class="font-semibold">{{ data.name }}</span>
      </ng-template>
      <df-error-input [control]="getControl(ETacticSettingsFormEnum.typeId)"></df-error-input>
    </div>
    <div class="tui-form__row">
      <label class="tui-text-02 labels mb-2 flex flex-row justify-start items-center">
        {{ 'Tactics.level of advancement' | translate }}
        <svg-icon
          class="ml-2"
          [src]="'/assets/img/ic-info.svg'"
          [tuiHint]="levelTooltip"
        ></svg-icon>
        <ng-template #levelTooltip>
          <div [innerHTML]="'Tactics.Manage.levelInfo' | translate"></div>
        </ng-template>
      </label>
      <tui-filter
        tuiFilterMaxItems
        #tuiLevels
        [tuiFilter]="tuiLevels"
        [maxItems]="1"
        [identityMatcher]="identityMatcher"
        [formControlName]="ETacticSettingsFormEnum.levelOfAdvancementId"
        [attr.data-error-control]="ETacticSettingsFormEnum.levelOfAdvancementId"
        size="m"
        [content]="levelContent"
        [items]="tacticParametersService.advancements"
      ></tui-filter>
      <ng-template
        let-data
        #levelContent
      >
        <span class="font-semibold">{{ data.name }}</span>
      </ng-template>
      <df-error-input [control]="getControl(ETacticSettingsFormEnum.levelOfAdvancementId)"></df-error-input>
    </div>
    <div class="tui-form__row">
      <label class="tui-text-02 labels mb-2 flex flex-row justify-start items-center">
        {{ 'Tactics.funnel step' | translate }}
        <svg-icon
          class="ml-2"
          [src]="'/assets/img/ic-info.svg'"
          [tuiHint]="stepTooltip"
        ></svg-icon>
        <ng-template #stepTooltip>
          <div [innerHTML]="'Tactics.Manage.stepInfo' | translate"></div>
        </ng-template>
      </label>
      <tui-filter
        [formControlName]="ETacticSettingsFormEnum.funnelStepsIds"
        [attr.data-error-control]="ETacticSettingsFormEnum.funnelStepsIds"
        size="m"
        [content]="stepContent"
        [identityMatcher]="identityMatcher"
        [items]="tacticParametersService.steps"
      ></tui-filter>
      <ng-template
        let-data
        #stepContent
      >
        <div class="flex items-center">
          <svg-icon
            [src]="'/assets/img/ic-' + data.object.type + '.svg'"
            class="mr-2"
          ></svg-icon>
          <span class="font-semibold">{{ data.name }}</span>
        </div>
      </ng-template>
      <df-error-input [control]="getControl(ETacticSettingsFormEnum.funnelStepsIds)"></df-error-input>
    </div>
    <div class="tui-form__row flex flex-row justify-start items-center mb-3">
      <label class="tui-text-03 tui-text_body-s mr-4">
        {{ 'Tactics.requires budget' | translate }}
      </label>
      <tui-toggle
        size="l"
        formControlName="requiresBudget"
      ></tui-toggle>
    </div>
    <div class="tui-form__row">
      <div class="ql-wrapper">
        <quill-editor
          [scrollingContainer]="'.ql-container'"
          [modules]="modules"
          [placeholder]="'Tactics.Manage.Tactic details' | translate"
          [formControlName]="'tacticDetails'"
          classes="i18n-ex"
        >
        </quill-editor>
      </div>
    </div>
  </form>
</div>
