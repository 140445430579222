import { Apollo } from 'apollo-angular';
import { FetchResult } from '@apollo/client/core';
import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { StatementConfigurationDocument } from '../graphql/queries/statements-configuration.query';
import { FieldCategoryEnum } from '@modules/graphql/graphql-types';
import { StatementFormService } from './statement-form.service';
import { GetStatementConfigurationQuery } from '../graphql/queries/statements-configuration.query.generated';
import { StatementInput } from '../models/statement.interface';

@Injectable({
  providedIn: 'root',
})
export class StatementNewParametersService {
  marketingAdvancement: StatementInput | null = null;
  productName: string | null = null;
  engagement: StatementInput | null = null;
  productType: StatementInput | null = null;
  b2cProductType: StatementInput | null = null;
  b2bProductType: StatementInput | null = null;
  sellingMethod: StatementInput | null = null;
  paymentMethod: StatementInput | null = null;
  paymentFrequency: StatementInput | null = null;
  offerPolicy: StatementInput | null = null;
  targetCharacteristics: StatementInput | null = null;
  pricingCharacteristics: StatementInput | null = null;
  avgProductMargin: number | null = null;
  goalType: StatementInput | null = null;
  goalPeriod: StatementInput | null = null;
  targetSegment: StatementInput | null = null;
  targetPerson: StatementInput | null = null;
  targetJob: StatementInput | null = null;
  targetChannels: StatementInput | null = null;
  targetLocation: StatementInput | null = null;
  goalMeasureSales: number | null = null;
  goalMeasureLeads: number | null = null;
  objectiveType: StatementInput | null = null;

  onParametersLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private apollo: Apollo,
    private statementFormService: StatementFormService,
  ) {}

  public getParameters() {
    this.apollo
      .query<GetStatementConfigurationQuery>({
        query: StatementConfigurationDocument,
      })
      .pipe(
        map((value: FetchResult<GetStatementConfigurationQuery>) => {
          this.statementFormService.setConfigurationId(value.data?.getStatementConfiguration.id ?? null);
          return value.data?.getStatementConfiguration.fields;
        }),
      )
      .subscribe({
        next: (res: any) => {
          this.statementFormService.resetForm();
          res.map((obj) => {
            this[obj.category] = obj;
            this.statementFormService.addFormControl(obj);
            this.statementFormService.handleStatementFieldDenendency(obj);
            if (obj.category === FieldCategoryEnum.TargetSegment) this.statementFormService.handleTargerSegmentIds(obj);
            if (obj.category === FieldCategoryEnum.GoalType) this.statementFormService.handleSalesAndLeadsIds(obj);
          });
          this.statementFormService.setIsFormReady(true);
          this.onParametersLoaded.emit(true);
        },
      });
  }
}
