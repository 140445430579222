<form
  [formGroup]="form"
  [validate-form]="form"
  (submit)="addAsset()"
  class="pt-5"
>
  <ng-container *ngIf="(platforms$ | async)?.length">
    <tui-select
      #platformSelect
      class="w-full"
      [tuiTextfieldSize]="'l'"
      [formControlName]="EActivityAssetForm.platformId"
      [attr.data-error-control]="EActivityAssetForm.platformId"
      [valueContent]="platformContent"
    >
      {{ 'Tactics.Manage.Platform' | translate }}
      <tui-data-list-wrapper
        *tuiDataList
        [items]="platforms$ | async"
        [itemContent]="platformContent"
      >
      </tui-data-list-wrapper>
    </tui-select>
    <df-error-input
      [control]="form.get(EActivityAssetForm.platformId)"
      [listenFocusChanges]="false"
    ></df-error-input>
  </ng-container>

  <ng-container *ngIf="(adTypes$ | async)?.length">
    <tui-select
      #adTypeSelect
      class="w-full mt-2"
      [tuiTextfieldSize]="'l'"
      [formControlName]="EActivityAssetForm.addTypeId"
      [attr.data-error-control]="EActivityAssetForm.addTypeId"
      [valueContent]="platformContent"
    >
      {{ 'Tactics.Manage.Ad type' | translate }}
      <tui-data-list-wrapper
        *tuiDataList
        [items]="adTypes$ | async"
        [itemContent]="platformContent"
      >
      </tui-data-list-wrapper>
    </tui-select>
    <df-error-input
      [control]="form.get(EActivityAssetForm.addTypeId)"
      [listenFocusChanges]="false"
    ></df-error-input>
  </ng-container>

  <ng-container *ngIf="(placements$ | async)?.length">
    <tui-select
      #placementSelect
      class="w-full mt-2"
      [tuiTextfieldSize]="'l'"
      [formControlName]="EActivityAssetForm.placementId"
      [attr.data-error-control]="EActivityAssetForm.placementId"
      [valueContent]="platformContent"
    >
      {{ 'Tactics.Manage.Placement' | translate }}
      <tui-data-list-wrapper
        *tuiDataList
        [items]="placements$ | async"
        [itemContent]="platformContent"
      >
      </tui-data-list-wrapper>
    </tui-select>
    <df-error-input
      [control]="form.get(EActivityAssetForm.placementId)"
      [listenFocusChanges]="false"
    ></df-error-input>
  </ng-container>

  <ng-template
    #platformContent
    let-item
  >
    {{ item.name }}
  </ng-template>

  <ng-container *ngIf="(resolutions$ | async)?.length">
    <tui-multi-select
      #resolutionsSelect
      class="w-full mt-2"
      [tuiTextfieldSize]="'m'"
      [formControlName]="EActivityAssetForm.resolutionsIds"
      [attr.data-error-control]="EActivityAssetForm.resolutionsIds"
      [editable]="false"
      [stringify]="stringify"
    >
      {{ 'Tactics.Manage.Resolutions' | translate }}
      <tui-data-list-wrapper
        *tuiDataList
        tuiMultiSelectGroup
        [items]="resolutions$ | async"
        [itemContent]="resolutionContent"
      >
      </tui-data-list-wrapper>
    </tui-multi-select>
    <df-error-input
      [control]="form.get(EActivityAssetForm.resolutionsIds)"
      [listenFocusChanges]="false"
    ></df-error-input>
  </ng-container>

  <ng-template
    #resolutionContent
    let-item
  >
    {{ item.width }}x{{ item.height }}px
  </ng-template>

  <df-page-list-loader *ngIf="loading$ | async"></df-page-list-loader>

  <div class="w-full mt-10 flex flex-row justify-center items-center">
    <button
      size="xl"
      tuiButton
      type="button"
      class="mr-4"
      appearance="outline"
      (click)="discard()"
    >
      <svg-icon
        [src]="'/assets/img/ic-close.svg'"
        class="mr-2"
      ></svg-icon>
      {{ 'cancel' | translate }}
    </button>

    <button
      size="xl"
      tuiButton
      type="submit"
      appearance="primary"
      [showLoader]="loading"
      [disabled]="loading"
    >
      <svg-icon
        [src]="'/assets/img/ic-check.svg'"
        class="mr-2"
      ></svg-icon>
      {{ 'add' | translate }}
    </button>
  </div>
</form>
