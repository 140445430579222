<form
  [formGroup]="form"
  [validate-form]="form"
  (submit)="submit()"
  class="pt-5"
>
  <ng-container *ngIf="(types$ | async)?.length">
    <tui-select
      class="w-full"
      [tuiTextfieldSize]="'l'"
      [formControlName]="EActivityBenchmarkForm.benchmarkTypeId"
      [attr.data-error-control]="EActivityBenchmarkForm.benchmarkTypeId"
      [valueContent]="typeContent"
    >
      {{ 'Tactics.Manage.Benchmark type' | translate }}
      <tui-data-list-wrapper
        *tuiDataList
        [items]="types$ | async"
        [itemContent]="typeContent"
      >
      </tui-data-list-wrapper>
    </tui-select>
    <df-error-input
      [control]="form.get(EActivityBenchmarkForm.benchmarkTypeId)"
      [listenFocusChanges]="false"
    ></df-error-input>
  </ng-container>

  <ng-template
    #typeContent
    let-item
  >
    {{ item.name }}
  </ng-template>

  <ng-container [ngSwitch]="form.get(EActivityBenchmarkForm.benchmarkTypeId)?.value?.type">
    <ng-container *ngSwitchCase="BenchmarkTypeEnum.Link">
      <tui-input
        class="w-full mt-1"
        [formControlName]="EActivityBenchmarkForm.link"
        [attr.data-error-control]="EActivityBenchmarkForm.link"
      >
        {{ 'Tactics.Manage.Link' | translate }}
      </tui-input>
      <df-error-input [control]="form.get(EActivityBenchmarkForm.link)"></df-error-input>
    </ng-container>
    <ng-container *ngSwitchCase="BenchmarkTypeEnum.File">
      <div class="flex flex-col items-stretch justify-start w-full relative mt-2">
        <span class="tui-text-body-s-2 tui-text-03">{{ 'Tactics.Manage.Attachment' | translate }}</span>

        <tui-island
          size="s"
          class="my-2"
          *ngIf="
            !form.get(EActivityBenchmarkForm.file)?.value &&
            (form.get(EActivityBenchmarkForm.attachmentId)?.value ||
              form.get(EActivityBenchmarkForm.benchmark)?.value?.attachment)
          "
        >
          <div class="w-full flex justify-start items-center">
            <div class="block mr-2 md:mr-4 w-6 flex-shrink-0 flex-grow-0 flex flex-col justify-center items-center">
              <svg-icon
                [src]="Config.ASSETS + form.get(EActivityBenchmarkForm.benchmarkTypeId)?.value?.image!"
                [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
              ></svg-icon>
            </div>

            <p>
              {{
                form.get(EActivityBenchmarkForm.attachmentId)?.value?.name ??
                  form.get(EActivityBenchmarkForm.benchmark)?.value?.attachment.name
              }}
            </p>
          </div>
        </tui-island>

        <df-input-file
          class="mt-1 w-full"
          [maxFileSize]="15000000"
          [link]="'choose file' | translate"
          [form]="form"
          [formName]="EActivityBenchmarkForm.file"
          [attr.data-error-control]="EActivityBenchmarkForm.file"
        ></df-input-file>
        <df-error-input
          [control]="form.get(EActivityBenchmarkForm.file)"
          [listenFocusChanges]="false"
        ></df-error-input>
      </div>
    </ng-container>
  </ng-container>

  <div class="w-full mt-10 flex flex-row justify-center items-center">
    <button
      size="xl"
      tuiButton
      type="button"
      class="mr-4"
      appearance="outline"
      (click)="discard()"
    >
      <svg-icon
        [src]="'/assets/img/ic-close.svg'"
        class="mr-2"
      ></svg-icon>
      {{ 'cancel' | translate }}
    </button>

    <button
      size="xl"
      tuiButton
      type="submit"
      appearance="primary"
    >
      <svg-icon
        [src]="'/assets/img/ic-check.svg'"
        class="mr-2"
      ></svg-icon>
      {{ 'save' | translate }}
    </button>
  </div>
</form>
