import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injector, Input } from '@angular/core';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { TuiDialogService } from '@taiga-ui/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { isControlToRemove } from '@modules/tactics/modules/tactic-settings/shared/helpers/tactic-form.helper';
import { EActivityAssetForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-asset-form.enum';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { ActivityAssetModalComponent } from '../activity-asset-modal/activity-asset-modal.component';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'df-activity-form-assets',
  templateUrl: './activity-form-assets.component.html',
  styleUrls: ['./activity-form-assets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityFormAssetsComponent {
  Config = Config;
  @Input() assetsArray: UntypedFormArray = new UntypedFormArray([]);

  isAssetToRemove(assetControl: AbstractControl): boolean {
    return isControlToRemove(assetControl);
  }

  constructor(
    private s: SnackbarService,
    private t: TranslateService,
    private readonly changes: ChangeDetectorRef,
    private readonly tacticGraphqlService: TacticGraphqlService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly tacticSettingsService: TacticSettingsService,
  ) {}

  getAssetData(assetControl: AbstractControl): ActivityAsset {
    return assetControl.get(EActivityAssetForm.asset)?.value;
  }

  openNewAssetModal(): void {
    this.dialogService
      .open<ActivityAsset>(new PolymorpheusComponent(ActivityAssetModalComponent, this.injector), {
        size: 's',
        dismissible: false,
        closeable: true,
        data: {
          assetsArray: this.assetsArray,
        },
        label: this.t.instant('Tactics.Manage.Add asset'),
      })
      .subscribe(() => this.changes.detectChanges());
  }

  removeAsset(assetControl: AbstractControl): void {
    this.tacticSettingsService.removeAsset(this.assetsArray, assetControl as UntypedFormGroup);
    this.changes.detectChanges();
  }

  restoreAsset(assetControl: AbstractControl): void {
    this.tacticSettingsService.restoreAsset(this.assetsArray, assetControl as UntypedFormGroup);
    this.changes.detectChanges();
  }
}
