import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getAppFeaturesList } from '@modules/authorization/shared/consts/onboarding-features.const';

@Component({
  selector: 'df-tactic-preview-popup',
  templateUrl: './tactic-preview-popup.component.html',
  styleUrls: ['./tactic-preview-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticPreviewPopupComponent {
  readonly features;
  constructor(private readonly t: TranslateService) {
    this.features = getAppFeaturesList(this.t);
  }
}
