import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-funnel-no-access-banner',
  templateUrl: './funnel-no-access-banner.component.html',
  styleUrls: ['./funnel-no-access-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelNoAccessBannerComponent {
  pricingUrl: string;
  constructor(private readonly userService: UserService) {
    this.pricingUrl = this.userService.resolvePricingPacketsUrl();
  }
}
