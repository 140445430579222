<div class="flex justify-center md:justify-between items-center w-full">
  <df-header-title
    data-test="header-title"
    class="flex-shrink-0"
  ></df-header-title>

  <div class="fixed bottom-0 left-0 right-0 p-2 tui-base-01-bg z-50 md:hidden">
    <div class="w-full grid grid-cols-2 gap-x-3">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>
  </div>

  <div class="hidden md:flex flex-nowrap justify-end items-stretch w-full gap-x-3">
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>
</div>
<ng-template #buttons>
  <button
    data-test="cancel-button"
    size="l"
    tuiButton
    type="button"
    appearance="outline"
    [disabled]="loading"
    (click)="cancel()"
  >
    <span class="tui-primary font-semibold">{{ 'cancel' | translate }}</span>
  </button>
  <button
    data-test="save-button"
    size="l"
    tuiButton
    type="submit"
    appearance="primary"
    [showLoader]="loading"
    [disabled]="loading || (isEditMode && !anyChanges)"
    (click)="save()"
  >
    <span class="font-semibold">{{ 'save' | translate }}</span>
  </button>
</ng-template>
