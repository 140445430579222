<df-full-list-loader *ngIf="loading"></df-full-list-loader>
<div class="w-full h-full flex flex-col justify-start items-stretch tui-base-02 flex-nowrap">
  <div class="flex-shrink-0 flex flex-col justify-start items-stretch px-6 pt-5 pb-10">
    <div class="flex justify-between items-center">
      <div class="w-5/6 h-5">
        <df-funnel-tactic-badges
          [steps]="funnelTactic.tactic.funnelSteps"
          [funnelTactic]="funnelTactic"
          appearance="outline"
        ></df-funnel-tactic-badges>
      </div>

      <div class="flex justify-end w-1/6">
        <a
          class="btn-share self-center mr-2"
          tuiIconButton
          appearance="outline"
          size="s"
          [routerLink]="n.getPath('tactics/preview/p/:id', {}, { id: funnelTactic.tactic.id })"
          [icon]="share"
        >
          <ng-template #share>
            <svg-icon
              src="assets/img/icon-share.svg"
              [svgStyle]="{
                width: '1.25rem',
                height: '1.25rem',
                display: 'inline'
              }"
            ></svg-icon>
          </ng-template>
        </a>
        <df-funnel-manage-tactic-dropdown-menu
          #dropdownMenu
          [funnelTactic]="funnelTactic"
          appearance="outline"
          size="s"
          [short]="true"
          tuiDropdownAlign="right"
          (onChanges)="detectChanges()"
        ></df-funnel-manage-tactic-dropdown-menu>

        <button
          type="button"
          tuiIconButton
          size="s"
          appearance="outline"
          [icon]="close"
          class="ml-2 btn-close"
          (click)="onClose.emit(true)"
        ></button>
        <ng-template #close>
          <svg-icon
            [src]="'/assets/img/ic-close.svg'"
            [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
          ></svg-icon>
        </ng-template>
      </div>
    </div>

    <div
      class="mt-4"
      [class.mb-8]="!funnelTactic.tactic.hasData()"
    >
      <h1
        class="flex text-[40px] text-[#191c30e6] leading-normal font-bold m-0"
        [ngClass]="{ 'tui-text-04 line-through': funnelTactic.isDone }"
      >
        <tui-primitive-checkbox
          *ngIf="
            funnelTactic.tactic.hasData() &&
            funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor
          "
          class="activity-checkbox"
          (click)="toggleDone()"
          [ngClass]="{ checked: !!funnelTactic.isDone }"
          [value]="!!funnelTactic.isDone"
        ></tui-primitive-checkbox>
        <span class="line-clamp-3">{{ funnelTactic.name || funnelTactic.tactic.name }}</span>
      </h1>
    </div>

    <div
      class="mt-3"
      *ngIf="funnelTactic.tactic.hasData()"
    >
      <p
        class="inline tui-text-02 text-sm font-medium mr-2"
        *ngIf="!!funnelTactic.name"
      >
        {{ funnelTactic.tactic.name }}
      </p>
    </div>
  </div>

  <div class="tui-base-01-bg h-full overflow-hidden border-t border-solid tui-base-04-border flex justify-between">
    <ng-container *ngIf="funnelTactic.tactic.hasData(); else noDataPlaceholder">
      <div class="overflow-y-auto w-full px-6 py-6">
        <div class="flex justify-between items-center flex-nowrap">
          <div class="flex flex-col justify-start items-start">
            <h4 class="text-base font-semibold">
              {{ 'Funnels.Manage.Activities' | translate }}
            </h4>

            <ng-container *ngIf="funnelTactic.isDone; else activities">
              <div class="tui-accent-07 uppercase flex justify-between items-center tui-text_body-xs font-bold">
                <svg-icon
                  [src]="'/assets/img/ic_check_circle.svg'"
                  class="mr-1"
                  [svgStyle]="{ width: '1rem', height: '1rem' }"
                ></svg-icon>
                <span
                  [innerHTML]="'working' | translate"
                  class="flex-grow"
                ></span>
              </div>
            </ng-container>
            <ng-template #activities>
              <p class="tui-text-05 tui-text_body-xs">
                {{
                  'Funnels.Manage.:doneCount :count activities done'
                    | translate
                      : {
                          doneCount: funnelTactic.doneActivities.length,
                          count: funnelTactic.tactic.activities.length
                        }
                }}
              </p>
            </ng-template>
          </div>

          <tui-island
            size="s"
            class="h-10 py-2.5 px-0 rounded-xl box-border before:border-0"
          >
            <div class="flex w-full h-full items-center">
              <tui-toggle
                class="flex-shrink-0 toggle-helper"
                size="m"
                [(ngModel)]="activitiesExpanded"
                (change)="onExpandChange($event)"
              ></tui-toggle>
              <span class="w-full tui-text-01 tui-text_body-m-4 ml-2 whitespace-nowrap">
                {{ 'Funnels.Manage.Expand all' | translate }}
              </span>
            </div>
          </tui-island>
        </div>

        <div class="mt-6">
          <df-tactic-accordion-activity
            *ngFor="let activity of funnelTactic.tactic.activities; let i = index"
            [index]="i + 1"
            [activity]="activity"
            [tactic]="funnelTactic"
            [funnelPreview]="true"
            [funnelPreviewPermission]="funnelManageService.funnel?.currentUserPermission"
            [activityDone]="activity.done"
            (openAccordionChange)="onOpenAccordionChange()"
            (toggleDone)="toggleActivityDone(activity)"
            (steps)="([funnelTactic.step])"
            [contentGenerateMode]="true"
            (refreshList)="getRefreshListVal($event)"
            (activityIdEmit)="getActivityId($event)"
            [funnelTacticId]="funnelTactic.id"
            #accordionActivityComponents
            id="activity-{{ activity.id }}"
          ></df-tactic-accordion-activity>
          <h3
            class="w-full tui-text_h5 text-center tui-text-04 mt-10"
            *ngIf="!funnelTactic.tactic.activities?.length && !loading"
          >
            {{ 'Tactics.Preview.No activities' | translate }}
          </h3>
        </div>
      </div>

      <div
        class="w-full overflow-y-auto"
        *ngIf="isSpecialTactic(funnelTactic)"
      >
        <df-funnel-manage-columns [tactic]="funnelTactic"></df-funnel-manage-columns>
      </div>
    </ng-container>
    <ng-template #noDataPlaceholder>
      <div class="flex flex-col justify-start items-center">
        <img
          src="assets/img/folder.svg"
          alt="folder"
          class="w-auto max-w-90 mt-6 lg:mt-18 xl:mt-28"
        />
        <p class="tui-text_body-m-1 tui-text-03 font-normal mt-6 lg:mt-10 xl:mt-12">
          {{ 'Funnels.Manage.This tactic is empty' | translate }}
        </p>

        <button
          tuiButton
          class="edit-btn mt-6"
          type="button"
          appearance="flat"
          [routerLink]="n.getPath('tactics/settings/t/:id', {}, { id: funnelTactic.tactic.id })"
          *ngIf="funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor"
        >
          <span class="font-medium">{{ 'Funnels.Manage.Edit tactic' | translate }}</span>
        </button>

        <button
          tuiButton
          class="mt-2"
          type="button"
          appearance="flat"
          (confirm)="deleteTactic()"
          mwlConfirmationPopover
          [popoverTitle]="'Funnels.Manage.Removing tactic' | translate"
          [popoverMessage]="'Funnels.Manage.Do you really want to remove your tactic?' | translate"
          placement="top"
          [appendToBody]="false"
          *ngIf="funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor"
        >
          <span class="font-medium text-red-500">{{ 'Funnels.Manage.Delete tactic' | translate }}</span>
        </button>
      </div>
    </ng-template>
  </div>
</div>
