import { ReportStep } from './funnel-statistics.enum';
import { CreateReportStep } from './funnel-statistics.interface';

export const WHATAGRAPH_URL = 'https://live.whatagraph.com/auth/register/';

export const CREATE_REPORT_STEPS: CreateReportStep[] = [
  {
    step: ReportStep.ONE,
    label: 'Funnels.Statistics.Open the page',
    link: WHATAGRAPH_URL,
    linkLabel: 'Funnels.Statistics.Click here',
  },
  {
    step: ReportStep.TWO,
    label: 'Funnels.Statistics.Create your account',
  },
  {
    step: ReportStep.THREE,
    label: 'Funnels.Statistics.Prepare the report that is the most important for you',
  },
  {
    step: ReportStep.FOUR,
    label: 'Funnels.Statistics.Copy the report link and paste it to one of the channels below.',
  },
  {
    step: ReportStep.FIVE,
    label: 'Funnels.Statistics.Compare the newest datas and analyse the numbers for the better results',
  },
];

export const URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const WHATAGRAPH_NAMES = ['reports.live'];
