<div
  class="steps-container"
  *ngIf="step > 0"
>
  <div
    class="flex step"
    [class.active-step]="STATEMENT_STEP_ENUM.ONE === step"
    [class.success-background]="step1Success"
  >
    <span
      *ngIf="!step1Success"
      class="tui-text_body-m"
      >{{ STATEMENT_STEP_ENUM.ONE }}</span
    >
    <svg-icon
      *ngIf="step1Success"
      [src]="'/assets/img/statement-check-icon.svg'"
    ></svg-icon>
  </div>
  <div
    class="flex step"
    [class.active-step]="STATEMENT_STEP_ENUM.TWO === step"
    [class.success-background]="step2Success"
  >
    <span
      *ngIf="!step2Success"
      class="tui-text_body-m"
      >{{ STATEMENT_STEP_ENUM.TWO }}</span
    >
    <svg-icon
      *ngIf="step2Success"
      [src]="'/assets/img/statement-check-icon.svg'"
    ></svg-icon>
  </div>
  <div
    class="flex step"
    [class.active-step]="STATEMENT_STEP_ENUM.THREE === step"
  >
    <span class="tui-text_body-m">{{ STATEMENT_STEP_ENUM.THREE }}</span>
  </div>
</div>
