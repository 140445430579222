<form
  class="max-w-md m-auto flex flex-col py-8 px-4 pt-36"
  [formGroup]="formGroup"
  [validate-form]="formGroup"
  (submit)="signUp()"
>
  <ng-template #success>
    <tui-svg
      class="tui-accent-07 tui-space_left-3"
      src="tuiIconCheckLarge"
    ></tui-svg>
  </ng-template>
  <div class="tui-form__row">
    <tui-input
      [tuiTextfieldCustomContent]="getControl('firstName')?.valid ? success : ''"
      formControlName="firstName"
    >
      {{ 'name' | translate }} *
    </tui-input>
    <df-error-input [control]="getControl('firstName')"></df-error-input>
  </div>

  <div class="tui-form__row">
    <tui-input
      [tuiTextfieldCustomContent]="getControl('lastName')?.valid && getControl('lastName')?.touched ? success : ''"
      formControlName="lastName"
    >
      {{ 'last name' | translate }}
    </tui-input>
    <df-error-input [control]="getControl('lastName')"></df-error-input>
  </div>

  <div class="tui-form__row">
    <tui-input
      [tuiTextfieldCustomContent]="getControl('email')?.valid ? success : ''"
      formControlName="email"
    >
      {{ 'email' | translate }} *
    </tui-input>
    <df-error-input [control]="getControl('email')"></df-error-input>
  </div>

  <div class="tui-form__row">
    <tui-input-password
      [tuiTextfieldCustomContent]="getControl('password')?.valid ? success : ''"
      tuiHintContent
      tuiHintDirection="right"
      tuiHintMode="onDark"
      formControlName="password"
    >
      {{ 'password' | translate }} *
    </tui-input-password>
    <df-error-input [control]="getControl('password')"></df-error-input>
  </div>

  <div class="tui-form__row mt-4">
    <tui-checkbox-labeled
      size="l"
      class="inline-flex"
      formControlName="rulesAccepted"
    >
      <span
        [innerHTML]="
          'Auth.Register.I agree to Digital First Terms and Privacy Policy'
            | translate
              : {
                  system: xPartnerService.partner.SYSTEM_NAME,
                  privacy: Config.PRIVACY_POLICY
                }
        "
      ></span>
    </tui-checkbox-labeled>
    <df-error-input [control]="getControl('rulesAccepted')"></df-error-input>
  </div>

  <div class="tui-form__row mt-4">
    <button
      size="xl"
      tuiButton
      type="submit"
      class="w-full block"
      appearance="primary"
      [showLoader]="loading"
      [disabled]="loading || !formGroup.valid"
    >
      {{ 'Auth.Register.Sign up' | translate }}
    </button>
  </div>
  <hr class="mt-8" />
  <div class="tui-form__row flex justify-between items-center mt-8">
    <span class="tui-text_body-m-2 tui-text-02">
      {{ 'Auth.Register.Have an account' | translate }}
    </span>
    <a
      tuiLink
      [routerLink]="n.getPath('public/login')"
    >
      {{ 'Auth.Register.Sign in' | translate }}
    </a>
  </div>
</form>
