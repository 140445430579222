import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import {
  PromotionDataOutputGraphql,
  PromotionTypeEnum,
  SubscriptionPlanOutputGraphql,
} from '@modules/graphql/graphql-types';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PricingGraphqlService } from '@modules/pricing/shared/services/pricing-graphql.service';
import { map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { CheckPromotionCodeQuery } from '@modules/pricing/shared/graphql/queries/check-promotion-code.query.generated';
import { ErrorInputComponent } from '@shared/modules/ui/components/error-input/error-input.component';
import { SnackbarService } from '@core/services/snackbar.service';
import { PacketsComponent } from '@modules/pricing/pages/packets/packets.component';

@Component({
  selector: 'df-pricing-promotion-code-modal',
  templateUrl: './pricing-promotion-code-modal.component.html',
  styleUrls: ['./pricing-promotion-code-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingPromotionCodeModalComponent {
  codeValidationLoading = false;
  readonly PromotionTypeEnum = PromotionTypeEnum;

  form: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl(null, [Validators.required]),
  });
  promotion: PromotionDataOutputGraphql | null = null;
  appliedCode: string | null = null;

  get plan(): SubscriptionPlanOutputGraphql | undefined {
    return this.context.data?.plan;
  }

  get trial(): boolean {
    return !!this.context.data?.trial;
  }

  get packetsComponent(): PacketsComponent | undefined {
    return this.context.data?.packetsComponent;
  }

  @ViewChild('codeError') codeError!: ErrorInputComponent;

  constructor(
    private readonly pricingGraphqlService: PricingGraphqlService,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<
      {
        data: {
          plan: SubscriptionPlanOutputGraphql;
          trial: boolean;
          packetsComponent: PacketsComponent;
        };
      },
      boolean
    >,
    private readonly changes: ChangeDetectorRef,
    private readonly el: ElementRef,
    private s: SnackbarService,
  ) {}

  checkCode(): void {
    if (this.form.valid || !this.plan) {
      this.codeValidationLoading = true;
      this.appliedCode = this.form.getRawValue()['code'];
      this.changes.detectChanges();
      this.pricingGraphqlService
        .checkPromotionCode(this.plan!.id, this.appliedCode!)
        .pipe(map((res: FetchResult<CheckPromotionCodeQuery>) => res.data?.checkPromotionCode))
        .subscribe({
          next: this._checkPromoCodeHandler.bind(this),
          error: () => this.s.defaultError(),
          complete: () => {
            this.codeValidationLoading = false;
            this.changes.detectChanges();
          },
        });
    } else {
      this.form.markAllAsTouched();
      this.changes.detectChanges();
    }
  }

  removeCode(): void {
    this.appliedCode = null;
    this.promotion = null;
    this.changes.detectChanges();
  }

  goToPayment(): void {
    if (this.promotion && this.appliedCode && this.plan) {
      this.packetsComponent?.handlePlanChoice(this.plan, this.appliedCode, this.trial);
    }
  }

  private _checkPromoCodeHandler(promotion: PromotionDataOutputGraphql | null | undefined): void {
    if (!promotion) {
      this.removeCode();
      this.form.get('code')?.setErrors({ codeInvalid: true });
      this.codeError.setErrorVisible(true);
    } else {
      this.promotion = promotion;
    }
    this.changes.detectChanges();
  }
}
