<div class="flex items-center justify-between w-full">
  <div class="w-5/6 h-5">
    <df-funnel-tactic-badges
      [steps]="[step]"
      [funnelTactic]="funnelTactic"
      [doneEffect]="true"
    ></df-funnel-tactic-badges>
  </div>
  <div class="flex items-center justify-end w-1/6">
    <df-funnel-manage-tactic-dropdown-menu
      [funnelTactic]="funnelTactic"
      (onChanges)="detectChanges($event)"
    ></df-funnel-manage-tactic-dropdown-menu>
  </div>
</div>
<h1
  class="tui-text-03 tui-text_h5 line-clamp-3 break-words mt-2 mb-0 font-medium cursor-pointer"
  (click)="toggleTacticPreview(true)"
  [ngClass]="{ 'tui-text-04': funnelTactic.isDone }"
  [tuiHighlight]="funnelManageService.searchFunnelTactic.value"
>
  {{ funnelTactic.name || funnelTactic.tactic.name }}
  <df-funnel-manage-tactic-preview
    *tuiSidebar="tacticPreviewOpen; direction: 'left'"
    [funnelTactic]="funnelTactic"
    (onClose)="toggleTacticPreview(false)"
    (onChanges)="detectChanges($event)"
  ></df-funnel-manage-tactic-preview>
</h1>
<p
  class="tui-text-04 tui-text_body-s-2 font-normal mt-2"
  [tuiHighlight]="funnelManageService.searchFunnelTactic.value"
  *ngIf="!!funnelTactic.name"
>
  {{ funnelTactic.tactic.name }}
</p>
<df-funnel-manage-tactic-activities
  class="mt-2"
  [previewMode]="cannotEdit || (canManage$ | async) === false"
  #activities
  [funnelTactic]="funnelTactic"
  (detectChanges)="detectChanges($event)"
></df-funnel-manage-tactic-activities>
