import { gql } from 'apollo-angular';

export const GetAssetDocument = gql`
  query getAsset($input: GetAssetInputGraphql!) {
    getAsset(input: $input) {
      id
      funnelId
      integration
      type
      value
      fileId
      createdAt
      updatedAt
      context {
        key
        value
      }
    }
  }
`;
