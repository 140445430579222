<ng-container *ngIf="tactic?.verificationStatus as status">
  <div
    class="rounded-lg font-bold uppercase py-1 px-2 text-xs flex items-center"
    [ngClass]="{
      'p-0.5': small,
      'tui-base-01': status !== VerificationStatusEnum.InProgress,
      'tui-accent-14-bg': status === VerificationStatusEnum.Failed,
      'tui-accent-07-bg': status === VerificationStatusEnum.Success,
      'tui-accent-02-bg tui-text-01': small && status === VerificationStatusEnum.InProgress,
      'tui-text-01 border border-solid tui-accent-02-border': !small && status === VerificationStatusEnum.InProgress
    }"
  >
    <svg-icon
      [src]="'/assets/img/ic_status_' + status + '.svg'"
      [svgStyle]="{
        width: small ? '1rem' : '1.25rem',
        height: small ? '1rem' : '1.25rem'
      }"
      class="mr-1"
    ></svg-icon>
    <span>{{ 'Tactics.status ' + status | translate }}</span>
  </div>
</ng-container>
