<header class="mb-8 print:mt-4 print:break-before-page">
  <h2 class="font-bold text-[24px] text-[#2E4FFF] col">
    {{ 'MarketingCampaign.Campaign execution' | translate }}
  </h2>
  <p class="text-[#191C30BF] font-medium text-[16px] mt-4">
    {{ 'MarketingCampaign.Campaign execution Don’t wait and start selling now.' | translate }}
  </p>
</header>

<div
  *ngIf="campaign"
  class="controller grid grid-cols-1 lg:grid-cols-3 grid-rows-1 gap-6"
>
  <div
    class="bg-white py-6 px-4 rounded-[12px] flex justify-center items-center"
    *ngFor="let banner of campaign.data.campaignExecutionBanner"
  >
    <img
      [src]="banner.banner"
      alt=""
    />
  </div>
</div>

<df-skeleton
  [showSkeleton]="!campaign"
  [rows]="1"
  [cols]="3"
></df-skeleton>
