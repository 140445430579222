import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { Funnel } from '@shared/models/funnel.model';
import { ActivatedRoute } from '@angular/router';
import { CanvasStep } from './shared/canvas-step.type';
import { AbstractFunnelSubpageComponent } from '@shared/abstracts/funnel-subpage.component.abstract';
import { LanguageService } from '@core/services/language.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-funnel-overview',
  templateUrl: './funnel-overview.component.html',
  styleUrls: ['./funnel-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelOverviewComponent extends AbstractFunnelSubpageComponent implements OnInit {
  todoActivitiesCount = 0;
  canvasesSteps: CanvasStep[] = [];
  doneCanvases = 0;
  language = '';

  constructor(
    public readonly funnelManageService: FunnelManageService,
    private readonly changes: ChangeDetectorRef,
    protected route: ActivatedRoute,
    private readonly languageService: LanguageService,
    public userService: UserService,
  ) {
    super(route, funnelManageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._countActivities(this.funnelManageService.funnel);
    this.listenFunnelChanges();
    this._setLanguage();
  }

  private _setLanguage() {
    this.language = this.languageService.getCurrentLanguage();
    this.changes.detectChanges();
  }

  listenFunnelChanges(): void {
    this.sub.add(this.funnelManageService.funnel$.subscribe(this._countActivities.bind(this)));
  }

  private _countActivities(funnel: Funnel | undefined): void {
    this.todoActivitiesCount = 0;
    funnel?.tactics.forEach((tactic) => (this.todoActivitiesCount += tactic.tactic.activities.length));
    this.changes.detectChanges();
  }
}
