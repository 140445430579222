import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { ShareTiptapDocumentModalComponent } from '@shared/components/business-flow-header/share-tiptap-document-modal/share-tiptap-document-modal.component';

@Component({
  selector: 'df-business-flow-header',
  templateUrl: './business-flow-header.component.html',
  styleUrl: './business-flow-header.component.scss',
})
export class BusinessFlowHeaderComponent {
  @Input() activeLink = null;
  @Input() docName = '';
  @Input() loading = false;
  @Input() tiptapShareUrl = '';

  @Output() onNextButtonClick = new EventEmitter<void>();

  readonly workFlowName: string;
  flowLinks: { link: string; label: string }[] = [];

  private readonly funnelId: number;
  private readonly goBackUrl: string;

  constructor(
    private route: ActivatedRoute,
    private n: NavigateService,
    private readonly dialog: TuiDialogService,
  ) {
    this.funnelId = +this.route?.parent?.snapshot.params['id'];
    this.goBackUrl = this.route?.parent?.snapshot.queryParams['goBackUrl'];
    this.workFlowName = this.route?.parent?.snapshot.queryParams['workFlowName'];
    this.initFlowLinks();
  }

  get isLastStep(): boolean {
    const lastRouteUrl = this.flowLinks.length ? this.flowLinks[this.flowLinks.length - 1].link : '';

    if (lastRouteUrl) {
      return window.location.href.includes(lastRouteUrl);
    }

    return false;
  }

  onBackClick() {
    this.n.go(this.goBackUrl);
  }

  onShareClick(): void {
    this.dialog
      .open(new PolymorpheusComponent(ShareTiptapDocumentModalComponent), {
        closeable: false,
        size: 's',
        data: {
          tiptapShareUrl: this.tiptapShareUrl,
          docName: this.docName,
        },
      })
      .subscribe();
  }

  private initFlowLinks() {
    this.flowLinks = [
      {
        link: `/funnels/f/d/${this.funnelId}/flow`,
        label: 'Go-to market strategy',
      },
      {
        link: `/funnels/f/d/${this.funnelId}/master-visuals`,
        label: 'Master visuals',
      },
      {
        link: `/funnels/f/d/${this.funnelId}/campaign`,
        label: 'Campaign',
      },
    ];
  }
}
