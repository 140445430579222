<div
  class="border border-solid tui-base-04-border rounded-lg tui-text-04-border-hvr p-4"
  *ngIf="type"
>
  <div class="w-full flex justify-between items-center">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="OutputTypeEnum.Text">
        <div
          class="flex justify-center items-center h-16 w-16 tui-accent-13-border border border-solid rounded-md mr-2"
        >
          <svg-icon src="assets/img/ic-text-left.svg"></svg-icon>
        </div>

        <div class="flex-grow flex flex-col items-start">
          <h5 class="text-sm m-0 font-semibold">
            {{ 'Funnels.Content generator.Generate your copy' | translate }}
          </h5>
          <p class="tui-text-body-xs tui-text-05 m-0">
            {{ 'Funnels.Content generator.Add your copy to an asset' | translate }}
          </p>
        </div>

        <button
          tuiButton
          appearance="accent"
          type="button"
          size="xs"
          (click)="generateContent.emit(true)"
        >
          <span class="text-sm font-normal px-1 py-0.5">{{ 'Funnels.Content generator.Generate' | translate }}</span>
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
