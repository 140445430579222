import { TacticsListCanActivateGuard } from '@modules/tactics/modules/tactics-list/guards/tactics-list-can-activate.guard';
import { TacticsListPanelComponent } from '@modules/tactics/modules/tactics-list/pages/tactics-list-panel/tactics-list-panel.component';
import { TacticsTilesComponent } from '@modules/tactics/modules/tactics-list/pages/tactics-tiles/tactics-tiles.component';
import { TacticListTypeEnum } from '@modules/graphql/graphql-types';
import { TacticsPreviewCanActivateGuard } from '@modules/tactics/modules/tactic-preview/guards/tactics-preview-can-activate.guard';
import { TacticPreviewComponent } from '@modules/tactics/modules/tactic-preview/pages/tactic-preview/tactic-preview.component';
import { TacticsListHeaderComponent } from '@modules/tactics/modules/tactics-list/shared/components/tactics-list-header/tactics-list-header.component';
import { TacticPreviewHeaderComponent } from '@modules/tactics/modules/tactic-preview/pages/tactic-preview/components/tactic-preview-header/tactic-preview-header.component';
import { TacticSettingsComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/tactic-settings.component';
import { TacticSettingsCanDeactivateGuard } from '@modules/tactics/modules/tactic-settings/guards/tactic-settings-can-deactivate.guard';
import { TacticSettingsHeaderComponent } from '@modules/tactics/modules/tactic-settings/shared/components/tactic-settings-header/tactic-settings-header.component';
import { TacticSettingsViewModeEnum } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-settings-view-mode.enum';
import { TacticSettingsCanActivateGuard } from '@modules/tactics/modules/tactic-settings/guards/tactic-settings-can-activate.guard';
import { Routes } from '@angular/router';

export const TacticsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/tactics/list/all',
  },
  {
    path: 'list',
    loadChildren: () =>
      import('@modules/tactics/modules/tactics-list/tactics-list.module').then((m) => m.TacticsListModule),
    canActivate: [TacticsListCanActivateGuard],
  },
  {
    path: 'preview',
    loadChildren: () =>
      import('@modules/tactics/modules/tactic-preview/tactic-preview.module').then((m) => m.TacticPreviewModule),
    canActivate: [TacticsPreviewCanActivateGuard],
  },
  {
    path: 'settings',
    canActivate: [TacticSettingsCanActivateGuard],
    loadChildren: () =>
      import('@modules/tactics/modules/tactic-settings/tactic-settings.module').then((m) => m.TacticSettingsModule),
  },
];

export const TacticsListRoutes = [
  {
    path: '',
    component: TacticsListPanelComponent,
    children: [
      {
        path: 'all',
        component: TacticsTilesComponent,
        data: {
          metaTitle: 'Tactics.all tactics',
          type: TacticListTypeEnum.All,
          viewTitle: 'Tactics.tactics',
          headerComponent: TacticsListHeaderComponent,
          showRequestCard: true,
        },
      },
      {
        path: 'my-tactics',
        component: TacticsTilesComponent,
        data: {
          metaTitle: 'Tactics.my tactics',
          type: TacticListTypeEnum.My,
          viewTitle: 'Tactics.tactics',
          headerComponent: TacticsListHeaderComponent,
        },
      },
      {
        path: 'dfai',
        component: TacticsTilesComponent,
        data: {
          metaTitle: 'Tactics.dfai tactics',
          type: TacticListTypeEnum.DigitalFirst,
          viewTitle: 'Tactics.tactics',
          headerComponent: TacticsListHeaderComponent,
        },
      },
      {
        path: 'favourite',
        component: TacticsTilesComponent,
        data: {
          metaTitle: 'Tactics.favourite tactics',
          type: TacticListTypeEnum.Favourite,
          viewTitle: 'Tactics.tactics',
          headerComponent: TacticsListHeaderComponent,
        },
      },
    ],
  },
];

export const TacticPreviewRoutes = [
  {
    path: 'p/:id',
    component: TacticPreviewComponent,
    data: {
      metaTitle: 'Tactics.preview tactic',
      shortLeftNavigation: false,
      isClose: false,
      headerComponent: TacticPreviewHeaderComponent,
    },
  },
];

export const TacticSettingsRoutes = [
  {
    path: 'create',
    component: TacticSettingsComponent,
    canDeactivate: [TacticSettingsCanDeactivateGuard],
    data: {
      metaTitle: 'Tactics.create new tactic',
      viewTitle: 'Tactics.Settings.create title',
      shortLeftNavigation: false,
      headerComponent: TacticSettingsHeaderComponent,
      type: TacticSettingsViewModeEnum.CREATE,
    },
  },
  {
    path: 't/:id',
    component: TacticSettingsComponent,
    canDeactivate: [TacticSettingsCanDeactivateGuard],
    data: {
      metaTitle: 'Tactics.settings',
      viewTitle: 'Tactics.Settings.title',
      shortLeftNavigation: false,
      headerComponent: TacticSettingsHeaderComponent,
      type: TacticSettingsViewModeEnum.EDIT,
    },
  },
];
