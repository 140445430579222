import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Config } from '@shared/configs/config';
import { ActivityBenchmark } from '@shared/models/activity-benchmark.model';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { EActivityBenchmarkForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-benchmark-form.enum';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { ActivityBenchmarkModalComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-benchmark-modal/activity-benchmark-modal.component';
import { isControlToRemove } from '@modules/tactics/modules/tactic-settings/shared/helpers/tactic-form.helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'df-activity-form-benchmarks',
  templateUrl: './activity-form-benchmarks.component.html',
  styleUrls: ['./activity-form-benchmarks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityFormBenchmarksComponent implements OnInit, OnDestroy {
  Config = Config;
  @Input() benchmarksArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() editorElem: Element | undefined;
  sub: Subscription = new Subscription();

  isBenchmarkToRemove(benchmarkControl: AbstractControl): boolean {
    return isControlToRemove(benchmarkControl);
  }

  constructor(
    private s: SnackbarService,
    private t: TranslateService,
    private readonly changes: ChangeDetectorRef,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly tacticSettingsService: TacticSettingsService,
  ) {}

  ngOnInit(): void {
    this.listenChanges();
  }

  private listenChanges(): void {
    this.sub.add(
      this.tacticSettingsService.changesInForm$.subscribe(() => {
        this.changes.detectChanges();
      }),
    );
  }

  getBenchmarkData(benchmarkControl: AbstractControl): ActivityBenchmark {
    return benchmarkControl.get(EActivityBenchmarkForm.benchmark)?.value;
  }

  openNewBenchmarkModal(): void {
    this.dialogService
      .open<ActivityBenchmark>(new PolymorpheusComponent(ActivityBenchmarkModalComponent, this.injector), {
        size: 's',
        dismissible: false,
        closeable: true,
        data: {
          benchmarksArray: this.benchmarksArray,
        },
        label: this.t.instant('Tactics.Manage.Add benchmark'),
      })
      .subscribe(() => this.changes.detectChanges());
  }

  openEditBenchmarkModal(benchmarkControl: AbstractControl): void {
    this.dialogService
      .open<ActivityBenchmark>(new PolymorpheusComponent(ActivityBenchmarkModalComponent, this.injector), {
        size: 's',
        dismissible: false,
        closeable: true,
        data: {
          benchmarkControl,
        },
        label: this.t.instant('Tactics.Manage.Edit benchmark'),
      })
      .subscribe(() => this.changes.detectChanges());
  }

  remove(benchmarkControl: AbstractControl): void {
    this.tacticSettingsService.removeBenchmark(this.benchmarksArray, benchmarkControl as UntypedFormGroup);
    this.changes.detectChanges();
  }

  restore(benchmarkControl: AbstractControl): void {
    this.tacticSettingsService.restoreBenchmark(this.benchmarksArray, benchmarkControl as UntypedFormGroup);
    this.changes.detectChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
