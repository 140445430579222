import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { FunnelSettingsService } from '@modules/funnels/modules/funnel-settings/shared/services/funnel-settings.service';
import { FunnelSettingsViewModeEnum } from '@modules/funnels/modules/funnel-settings/shared/enums/funnel-settings-view-mode.enum';
import { NavigateService } from '@core/routes/services/navigate.service';
import { FetchResult } from '@apollo/client/core';
import { CreateFunnelMutation } from '@modules/funnels/shared/graphql/mutations/create-funnel.mutation.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user.service';
import { BasePanelEvent, BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';
import { Router } from '@angular/router';
import { Funnel } from '@shared/models/funnel.model';

@Component({
  selector: 'df-funnel-settings-header',
  templateUrl: './funnel-settings-header.component.html',
  styleUrls: ['./funnel-settings-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelSettingsHeaderComponent extends AbstractSubscriptionComponent implements OnInit {
  isEditMode = false;
  loading = false;
  anyChanges = false;

  constructor(
    public funnelSettings: FunnelSettingsService,
    private changes: ChangeDetectorRef,
    private n: NavigateService,
    private router: Router,
    private s: SnackbarService,
    private t: TranslateService,
    private userService: UserService,
    private basePanelService: BasePanelService,
    private eventService: EventsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenChanges();
    this.listenLoading();
    this.listenViewMode();
  }

  private listenViewMode(): void {
    this.sub.add(
      this.funnelSettings.viewMode$.subscribe((viewMode) => {
        this.isEditMode = viewMode === FunnelSettingsViewModeEnum.EDIT;
        this.changes.detectChanges();
      }),
    );
  }

  private listenChanges(): void {
    this.sub.add(
      this.funnelSettings.changesInForm$.subscribe((changes) => {
        this.anyChanges = changes;
        this.changes.detectChanges();
      }),
    );
  }

  private listenLoading(): void {
    this.sub.add(
      this.funnelSettings.loading$.subscribe((loading) => {
        this.loading = loading;
        this.changes.detectChanges();
      }),
    );
  }

  cancel(): void {
    if (this.isEditMode) {
      this.n.go('funnels/f/d/:id', { id: this.funnelSettings.funnel?.id });
    } else {
      this.n.go('funnels/list/all');
    }
  }

  save(): void {
    this.funnelSettings.formGroup!.markAllAsTouched();
    if (!this.funnelSettings.formGroup!.valid) return;
    if (this.isEditMode) {
      this.saveFunnel();
    } else {
      this.createFunnel();
    }
  }

  saveFunnel() {
    this.funnelSettings.editFunnel().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.funnelSettings._changesInForm$.next(false);
        this.s.success(this.t.instant("Funnels.Manage.Funnel's data saved successfully."));
        this.n.go('funnels/f/d/:id', { id: this.funnelSettings.funnel?.id });
      } else {
        this.s.error(this.t.instant("Funnels.Manage.There is problem with editing funnel's data. Try again."));
      }
    });
  }

  createFunnel(): void {
    this.funnelSettings
      .createFunnel()
      ?.subscribe({
        next: (res) => {
          this.userService.setContextFunnel(new Funnel(res.data?.createFunnel))?.subscribe();
          this.increaseUserFunnelsAmount();
          this.sendDataLayerEvents(res);
          this.funnelSettings.clearService();
          this.s.success(this.t.instant('Funnels.Settings.funnel created successfully'));
          this.n.go('funnels/f/d/:id/business-data', {
            id: res.data?.createFunnel.id,
          });
          this.basePanelService.emitter.emit(BasePanelEvent.RELOAD_FUNNELS_TO_CONTEXT);
        },
        error: () => {
          this.s.error(this.t.instant('UserSettings.Teams.There is error with creating new team. Try again.'));
        },
      })
      .add(() => {
        this.funnelSettings.loading = false;
      });
  }

  private increaseUserFunnelsAmount(): void {
    const user = this.userService.User!;
    user.funnelsCount++;
    this.userService.User = user;
  }

  private sendDataLayerEvents(res: FetchResult<CreateFunnelMutation>) {
    this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.FunnelAdd, {
      userID: this.userService.User?.id,
    });

    if (res.data?.createFunnel.id === res.data?.createFunnel.owner.firstFunnel?.id) {
      this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.FunnelNew, {
        userID: this.userService.User?.id,
      });
    }
  }
}
