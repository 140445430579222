import { Injectable, OnDestroy } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '@shared/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class MemoryLogService implements OnDestroy {
  memoryLogs$ = new BehaviorSubject<string[]>([]);

  private funnelId!: number;
  private readonly sub = new Subscription();

  constructor(
    private readonly socket: Socket,
    private readonly router: Router,
    private readonly userService: UserService,
  ) {
    this.funnelId = this.userService.User!.contextFunnel!.id;
    this.socket.connect();
    this.updateFunnelIdOnNavigation();
  }

  ngOnDestroy(): void {
    this.socket.disconnect();
    this.sub.unsubscribe();
  }

  listen(): void {
    this.socket.emit('subscribe', `funnel:${this.funnelId}`);
    this.socket.on(`funnel:${this.funnelId}`, (message: string) => {
      this.memoryLogs$.next([...this.memoryLogs$.value, message]);
    });
  }

  stopListening(): void {
    this.socket.emit('unsubscribe', `funnel:${this.funnelId}`);
    this.memoryLogs$.next([]);
    this.socket.removeAllListeners(`funnel:${this.funnelId}`);
  }

  private updateFunnelIdOnNavigation(): void {
    this.sub.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.funnelId = this.userService.User!.contextFunnel!.id;
        }
      }),
    );
  }
}
