import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from '@shared/modules/custom-selects/components/multi-select/multi-select.component';
import { TuiDataListWrapperModule, TuiInputTagModule, TuiMultiSelectModule, TuiSelectModule } from '@taiga-ui/kit';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiDataListModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { BaseCustomSelectComponent } from '@shared/modules/custom-selects/components/base-custom-select.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { SingleSelectComponent } from './components/single-select/single-select.component';
import { TagSelectComponent } from './components/tag-select/tag-select.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MultiSelectComponent, BaseCustomSelectComponent, SingleSelectComponent, TagSelectComponent],
  imports: [
    CommonModule,
    TuiMultiSelectModule,
    TuiSelectModule,
    TuiInputTagModule,
    TuiDataListWrapperModule,
    FormsModule,
    TuiDataListModule,
    TuiTextfieldControllerModule,
    TuiLetModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [MultiSelectComponent, SingleSelectComponent, TagSelectComponent],
})
export class CustomSelectsModule {}
