import { gql } from 'apollo-angular';

export const GetCanvasInstanceByFunnelAndTemplateDocument = gql`
  query getCanvasInstanceByFunnelAndTemplate($funnelId: Int!, $templateId: Int!) {
    getCanvasInstanceByFunnelAndTemplate(funnelId: $funnelId, templateId: $templateId) {
      id
      positionValues {
        value
        position {
          label
          id
        }
      }
      template {
        id
        name
      }
    }
  }
`;
