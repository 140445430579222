import { gql } from 'apollo-angular';

export const BenchmarkFragment = gql`
  fragment BenchmarkFragment on BenchmarkOutputGraphql {
    id
    uuid
    link
    attachment {
      file
      name
      id
    }
    type {
      id
      name
      type
      image
    }
    iconUrl
    name
    description
  }
`;
