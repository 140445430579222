/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Directive({
  selector: '[formGroup]',
})
export class NativeElementFormInjectorDirective implements OnInit {
  @Input('validate-form') form?: UntypedFormGroup;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.form) {
      this.form['nativeElement'] = this.el.nativeElement;
    }
  }
}
