<tui-island
  [size]="'s'"
  class="h-full recommendations-island relative px-6 pt-4 pb-8 overflow-hidden"
>
  <img
    src="assets/img/overview-recommendations.png"
    alt=""
    class="sm:block object-contain max-h-full w-72 absolute bottom-0 -right-16 xl:right-0"
  />
  <ng-container *ngIf="recommendedCount === 0">
    <div class="relative h-full flex flex-col justify-between items-start">
      <h3
        class="text-xl xl:text-2xl text-left font-semibold tui-text-03 py-3 text-left w-60"
        [innerHTML]="'Funnels.Overview.Unlock recommendations' | translate"
      ></h3>
      <button
        tuiButton
        [size]="'l'"
        class="w-auto"
        appearance="secondary"
        routerLink="../statement"
      >
        {{ 'Funnels.Overview.Start now' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="recommendedCount > 0">
    <div class="relative h-full flex flex-col justify-between items-start w-2/4">
      <div>
        <h2 class="tui-text_h1 tui-primary font-light text-left">
          {{ recommendedCount }}
        </h2>
        <h3
          class="text-2xl tui-text-03 font-semibold text-left"
          [innerHTML]="'Funnels.Overview.Recommended tactics' | translate"
        ></h3>
      </div>
      <button
        tuiButton
        type="button"
        [size]="'l'"
        class="w-auto mt-4"
        appearance="secondary"
        routerLink="../recommendations"
      >
        {{ 'Funnels.Overview.View tactics' | translate }}
      </button>
    </div>
  </ng-container>
</tui-island>
