import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { OutputsDataSearchInputGraphql } from '@modules/graphql/graphql-types';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { GetIntegrationsCategoriesDocument } from '../graphgql/queries/get-integrations-categories.query';
import { GetIntegrationsCategoriesQuery } from '../graphgql/queries/get-integrations-categories.query.generated';
import { SearchContentOutputsDataDocument } from '../graphgql/queries/search-content-outputs-data.query';
import { SearchContentOutputsDataQuery } from '../graphgql/queries/search-content-outputs-data.query.generated';

@Injectable({
  providedIn: 'root',
})
export class FunnelContentGeneratorLibraryGraphqlService {
  constructor(private apollo: Apollo) {}

  fetchCategories(): Observable<FetchResult<GetIntegrationsCategoriesQuery>> {
    return this.apollo.query<GetIntegrationsCategoriesQuery>({
      query: GetIntegrationsCategoriesDocument,
    });
  }

  fetchGeneratedContent(input: OutputsDataSearchInputGraphql): Observable<FetchResult<SearchContentOutputsDataQuery>> {
    return this.apollo.query<SearchContentOutputsDataQuery>({
      query: SearchContentOutputsDataDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
