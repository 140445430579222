import { Injectable, EventEmitter } from '@angular/core';
import { BaseStorageService } from './base-storage.service';
import { JWT } from '@core/interfaces/jwt.interface';

@Injectable({ providedIn: 'root' })
export class StorageService extends BaseStorageService {
  emmiter: EventEmitter<StorageEvent> = new EventEmitter();

  readonly twoWeeks: number = 1000 * 60 * 60 * 24 * 14;

  constructor() {
    super();
  }

  get jwt(): string | null {
    const jwtStr = this.get('jwt');
    if (!jwtStr) {
      return null;
    }

    const jwt = JSON.parse(jwtStr);
    const now = Date.now();
    if (now > jwt.expire) {
      this.delete('jwt');
      return null;
    }
    return jwt.token;
  }

  set jwt(jwt: string | null) {
    const jwtModel = {
      token: jwt,
      expire: Date.now() + this.twoWeeks,
    };
    this.put('jwt', JSON.stringify(jwtModel));
  }

  get isLogged() {
    return this.jwt?.toString().length;
  }

  setSignedIn(login: JWT) {
    this.jwt = login.token;
  }

  logout() {
    this.delete('jwt');
  }
}

export class StorageEvent {
  type?: StorageEventType;
}

export enum StorageEventType {}
