import { gql } from 'apollo-angular';
import { FunnelFragment } from '@modules/funnels/shared/graphql/fragments/funnel.fragment';

export const EditFunnelDocument = gql`
  mutation editFunnel($input: FunnelInputGraphql!, $funnelId: Int!) {
    editFunnel(input: $input, funnelId: $funnelId) {
      ...Funnel
    }
  }
  ${FunnelFragment}
`;
