import { gql } from 'apollo-angular';
import { TeamFragment } from '@modules/user-settings/modules/teams/shared/graphql/fragments/team.fragment';

export const GetTeamDocument = gql`
  query getTeam($id: Int!) {
    getTeam(id: $id) {
      ...TeamFragment
    }
  }
  ${TeamFragment}
`;
