import { Injectable } from '@angular/core';
import { MixpanelTrackEventDocument } from '@shared/graphql/mutations/mixpanel-track-event.mutation';
import { TrackEventMutation } from '@shared/graphql/mutations/mixpanel-track-event.mutation.generated';
import { Apollo } from 'apollo-angular';

export enum MixpanelEventName {
  PageView = 'page_view',
  ButtonClick = 'button_click',
}

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor(private apollo: Apollo) {}

  trackEvent(eventName: MixpanelEventName, eventData: Record<string, string | number>) {
    this.apollo
      .mutate<TrackEventMutation>({
        mutation: MixpanelTrackEventDocument,
        variables: {
          input: {
            eventName,
            eventData,
          },
        },
      })
      .subscribe();
  }
}
