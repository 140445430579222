import { gql } from 'apollo-angular';

export const GetUnreceivedNotificationsDocument = gql`
  query getUnreceivedNotifications {
    getUnreceivedNotifications {
      createdAt
      id
      message
      type
      received
      data {
        feeAmount
      }
    }
  }
`;
