<div
  class="statement-error-input-wrapper"
  [class.belongs-to-input]="isInputError"
>
  <df-error-input
    [control]="control"
    [manualShowingError]="formSubmitted"
    [skipTuiInputCheck]="skipTuiInputCheck"
  ></df-error-input>
</div>
