import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FunnelManageTacticNameService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-tactic-name-modal/funnel-manage-tactic-name.service';
import { UntypedFormGroup } from '@angular/forms';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { finalize } from 'rxjs/operators';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';

@Component({
  selector: 'df-funnel-manage-tactic-name-modal',
  templateUrl: './funnel-manage-tactic-name-modal.component.html',
  styleUrls: ['./funnel-manage-tactic-name-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FunnelManageTacticNameService],
})
export class FunnelManageTacticNameModalComponent implements OnInit {
  readonly form: UntypedFormGroup;
  loading = false;

  get funnelTactic(): FunnelTactic {
    return this.context.data.funnelTactic;
  }

  constructor(
    private readonly funnelManageTacticNameService: FunnelManageTacticNameService,
    private readonly changes: ChangeDetectorRef,
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<any, boolean>,
  ) {
    this.form = this.funnelManageTacticNameService.form;
  }

  ngOnInit(): void {
    this._patchFormData();
  }

  submit(): void {
    if (this.form.valid) {
      this.loading = true;
      this.changes.detectChanges();
      this.funnelManageTacticNameService
        .updateName()
        .pipe(
          finalize(() => {
            this.loading = false;
            this.changes.detectChanges();
          }),
        )
        .subscribe((value: FunnelTactic) => this.context.completeWith(value.name));
    } else {
      this.form.markAllAsTouched();
      this.changes.detectChanges();
    }
  }

  discard(): void {
    this.context.completeWith();
  }

  private _patchFormData(): void {
    this.form.patchValue({
      funnelTacticId: this.funnelTactic.id,
      name: this.funnelTactic.name,
    });
  }
}
