import { gql } from 'apollo-angular';

export const FindOnePromptDocument = gql`
  query findOnePrompt($customEntityId: Int!) {
    findOnePrompt(customEntityId: $customEntityId) {
      name
      description
      settingsIntegration
      promptText
      promptProperty
      category
      contentGeneratorCategory
      contentGeneratorIcon
      status
      settingsActive
      customPromptFields {
        customPromptId
        fieldId
        label
        helpText
        fieldType
        optionValues
        defaultValueId
        defaultValue
        required
        order
        name
      }
    }
  }
`;
