<df-register
  *ngIf="viewMode === viewModeEnum.REGISTER"
  [dialogMode]="true"
  [trial]="trial"
  [planToBuy]="planToBuy"
  (navigateToSignIn)="navigateToSignInHandler()"
  (signedUp)="authorizedUserHandler()"
></df-register>
<df-login
  *ngIf="viewMode === viewModeEnum.LOGIN"
  [dialogMode]="true"
  [trial]="trial"
  [planToBuy]="planToBuy"
  (navigateToSignUp)="navigateToSignUpHandler()"
  (signedIn)="authorizedUserHandler()"
></df-login>
