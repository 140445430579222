import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const ChangePasswordDocument = gql`
  mutation changePassword($input: ChangePasswordInputGraphql!) {
    changePassword(input: $input) {
      ...UserStorage
    }
  }
  ${UserStorageFragment}
`;
