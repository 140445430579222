import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@shared/services/user.service';

/**
 * Remove links from text is user is semrush user
 */
@Pipe({ name: 'linkRemover', pure: true })
export class LinkRemoverPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(value?: string): string {
    const isSemrush = this.userService.User?.isSemrushPlan();
    const includeLinks = value?.includes('https://');

    if (!value) return '';
    if (!isSemrush) return value;
    if (isSemrush && !includeLinks) return value;

    const doc = new DOMParser().parseFromString(value, 'text/html');

    doc.querySelectorAll('a').forEach((link) => {
      const text = link.innerText;
      const parent = link.parentElement;

      const spanElement = document.createElement('span');
      spanElement.innerText = text;

      parent?.appendChild(spanElement);
      parent?.removeChild(link);

      const grapdParent = parent?.parentElement;
      grapdParent?.removeAttribute('data-href');
    });

    return doc.documentElement.innerHTML ?? '';
  }
}
