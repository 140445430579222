import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { Apollo } from 'apollo-angular';
import { SnackbarService } from '@core/services/snackbar.service';
import { ActivatedRoute } from '@angular/router';
import { catchError, of, map } from 'rxjs';
import { SaveEmailToGetResponseDocument } from './graphql/mutations/save-email-to-getresponse.mutation';
import { SaveEmailToGetResponseMutation } from './graphql/mutations/save-email-to-getresponse.mutation.generated';
import { captureException } from '@sentry/angular-ivy';
@Component({
  selector: 'df-send-email-modal',
  templateUrl: './public-funnel-send-email-modal.component.html',
  styleUrls: ['./public-funnel-send-email-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicFunnelSendEmailModalComponent {
  constructor(
    private apollo: Apollo,
    private s: SnackbarService,
    private route: ActivatedRoute,
  ) {}

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    privacyPolicy: new FormControl(false, Validators.requiredTrue),
  });

  Config = Config;

  onSubmit() {
    if (!this.form.valid) return;

    const data = this.form.getRawValue();
    const funnelId = Number(this.route.snapshot.params.id);

    this.apollo
      .mutate<SaveEmailToGetResponseMutation>({
        mutation: SaveEmailToGetResponseDocument,
        variables: {
          email: data.email,
          funnelId,
        },
      })
      .pipe(
        catchError((err) => {
          captureException(err);
          this.s.error('Something went wrong. Please try later.');
          return of(null);
        }),
        map((res) => res?.data?.saveEmailToGetResponse),
      )
      .subscribe((res) => {
        if (res) this.s.success('Email send.');
      });
  }
}
