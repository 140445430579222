<div class="w-full h-fit p-4 pb-4 overflow-y-hidden">
  <p
    class="text-sm font-normal i18n-dynamic"
    *ngIf="IntegrationTypeEnum.Writesonic === integration?.type"
  >
    <span> {{ 'Funnels.Content generator.Every time you click...prefix' | translate }}</span>
    <span> {{ integration?.name }} </span>
    <span> {{ 'Funnels.Content generator.Every time you click...sufix' | translate }}</span>
  </p>
  <p
    class="text-sm font-normal i18n-dynamic my-4"
    *ngIf="IntegrationTypeEnum.Abyssale === integration?.type"
  >
    {{ 'Funnels.Graphics.Enter your own texts...' | translate }}
  </p>

  <p
    class="text-sm font-normal i18n-dynamic"
    *ngIf="IntegrationTypeEnum.Abyssale === integration?.type"
  >
    {{ 'Funnels.Graphics.See how to generate tailored graphics' | translate }}
    <a
      href="https://help.digitalfirst.ai/en/articles/9099958-create-tailored-social-media-graphics#h_dbc05466ee"
      target="_blank"
      >here</a
    >
  </p>

  <df-content-generator-form-fields
    #formFieldsComponent
    *ngIf="integration"
    [form]="form"
    [integration]="integration"
    (formSubmitted)="submit()"
  ></df-content-generator-form-fields>
  <ng-content></ng-content>
</div>
<div class="w-full z-[99999] sticky bottom-[3px] flex flex-col justify-center items-stretch px-4 py-4">
  <button
    data-test="generate-graphics-button"
    tuiButton
    appearance="primary"
    type="button"
    size="xl"
    (click)="submit()"
    [disabled]="loading"
  >
    <span class="tui-text-body-m-3 ml-2.5">{{ generateButtonLabel }}</span>
  </button>
</div>
