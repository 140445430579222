<div class="flex items-center">
  <div class="rounded-full w-16 h-16 min-w-16 {{ step.type }} mr-4 flex justify-center items-center tui-base-01">
    <svg-icon
      [src]="'/assets/img/ic-' + step.type + '.svg'"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
    ></svg-icon>
  </div>
  <div class="w-3/5 text-left flex flex-col items-start tui-text_body-xs">
    <h4 class="{{ step.type }}-text uppercase font-bold">
      {{ step.name }}
    </h4>
    <div
      class="flex items-start mt-1"
      *ngIf="showDoneCount"
    >
      <svg-icon
        [src]="'/assets/img/ic_check_circle.svg'"
        class="mr-2"
      ></svg-icon>
      <h5
        class="uppercase tui-text-01 font-bold"
        *ngIf="funnelManageService.funnel?.statsStepMap?.get(step.id)"
      >
        {{
          'Funnels.Manage.:doneCount of :allCount done'
            | translate
              : {
                  doneCount: funnelManageService.funnel!.statsStepMap!.get(step.id)?.doneCount,
                  allCount: funnelManageService.funnel!.statsStepMap!.get(step.id)?.allCount
                }
        }}
      </h5>
    </div>
  </div>
</div>
<div class="flex justify-end add-button">
  <button
    [permission]="{
      group: PermissionsGroups.FUNNELS,
      action: 'EDIT',
      objectCreatorId: funnelManageService.funnel?.owner?.id
    }"
    appearance="flat"
    tuiIconButton
    class="tui-text-01"
    (click)="goToTacticList()"
    [size]="'s'"
    [icon]="icon"
  ></button>
  <ng-template #icon>
    <svg-icon
      [src]="'/assets/img/ic-add.svg'"
      [svgStyle]="{ width: '1rem', height: '1rem' }"
    ></svg-icon>
  </ng-template>
</div>
