<tui-accordion
  class="mx-4 md:mx-6 lg:mx-10"
  [rounded]="true"
>
  <tui-accordion-item
    class="w-full text-center"
    [showArrow]="false"
    [size]="'s'"
  >
    <div
      class="flex items-center tui-primary tui-text_body-m w-full justify-center"
      *ngIf="!projects?.length"
    >
      <svg-icon
        [src]="'/assets/img/ic-categories.svg'"
        class="mr-4"
      ></svg-icon>
      {{ 'Funnels.List.projects' | translate }}
    </div>

    <div
      class="flex flex-row items-center w-full justify-between"
      *ngIf="projects?.length"
    >
      <div class="overflow-x-auto flex-inline">
        <tui-badge
          *ngFor="let project of projects"
          class="badge outline ml-1 mr-1"
          size="m"
          [hoverable]="true"
        >
          <span class="flex items-center justify-center pl-3 pr-3">
            {{ project.name }}
            <svg-icon
              [src]="'/assets/img/ic-close.svg'"
              class="ml-2"
              (click)="removeProject(project.id, $event)"
              [svgStyle]="{ width: '14px', height: '14px' }"
            ></svg-icon>
          </span>
        </tui-badge>
      </div>
      <svg-icon
        [src]="'/assets/img/ic-categories.svg'"
        class="ml-4 ic-category"
      ></svg-icon>
    </div>

    <ng-template tuiAccordionItemContent>
      <form
        [formGroup]="funnelsFiltersService.filterFormGroup"
        class="w-full p-4 pb-0"
      >
        <tui-filter
          [identityMatcher]="identityMatcher"
          formControlName="projects"
          size="s"
          [content]="typeContent"
          [items]="funnelsFiltersService.projects"
        ></tui-filter>

        <ng-template
          let-data
          #typeContent
        >
          <div class="flex items-center">
            {{ data.name }}
            <button
              (click)="removeTag(data)"
              appearance="outline"
              size="xs"
              tuiIconButton
              [icon]="close"
            ></button>
          </div>
        </ng-template>

        <ng-template #close>
          <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
        </ng-template>
      </form>
    </ng-template>
  </tui-accordion-item>
</tui-accordion>
