import { gql } from 'apollo-angular';
import { IntegrationOutputDataFragment } from '../fragments/integration-output-data.fragment';

export const GetLastOutputsDataDocument = gql`
  query getLastOutputsData($records: Int, $integrationId: Int!, $funnelId: Int!) {
    getLastOutputsData(records: $records, integrationId: $integrationId, funnelId: $funnelId) {
      ...IntegrationOutputData
    }
  }
  ${IntegrationOutputDataFragment}
`;
