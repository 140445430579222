import { LogoutComponent } from './../../pages/logout/logout.component';
import { LoginComponent } from '@modules/authorization/pages/login/login.component';
import { AuthPanelComponent } from '@modules/authorization/pages/auth-panel/auth-panel.component';
import { RegisterComponent } from '@modules/authorization/pages/register/register.component';
import { ConfirmEmailComponent } from '@modules/authorization/pages/confirm-email/confirm-email.component';
import { NoAuthorizationComponent } from '@modules/authorization/pages/no-authorization/no-authorization.component';
import { NoAccessComponent } from '@modules/authorization/pages/no-access/no-access.component';
import { EndTrialComponent } from '@modules/authorization/pages/end-trial/end-trial.component';
import { ConfirmChangeEmailComponent } from '@modules/authorization/pages/confirm-change-email/confirm-change-email.component';
import { AppSumoRegisterComponent } from '@modules/authorization/pages/app-sumo-register/app-sumo-register.component';
import { AppSumoErrorComponent } from '@modules/authorization/pages/app-sumo-error/app-sumo-error.component';
import { AppSumoCanActivateGuard } from '@modules/authorization/shared/guards/app-sumo-can-activate.guard';
import { NoTeamComponent } from '@modules/authorization/pages/no-team/no-team.component';
import { AuthPanelCanActivateGuard } from '@modules/authorization/shared/guards/auth-panel-can-activate.guard';
import { EAuthViewMode } from '@modules/authorization/shared/enums/auth-view-mode.enum';
import { OnboardingSetupFormComponent } from '@modules/authorization/pages/onboarding-setup-form/onboarding-setup-form.component';
import { SetUpAccountCanActivateGuard } from '@modules/authorization/shared/guards/set-up-account-can-activate.guard';
import { SelectPlanComponent } from '@modules/authorization/pages/select-plan/select-plan.component';
import { PermissionsResolver } from '@shared/services/permissions.resolver';
import { SelectPlanCanActivateGuard } from '@modules/authorization/shared/guards/select-plan-can-activate.guard';
import { SemrushComponent } from '@modules/authorization/pages/semrush/semrush.component';
import { Routes } from '@angular/router';
import { NoAuthorizationSemrushComponent } from '@modules/authorization/pages/no-authorization-semrush/no-authorization-semrush.component';
import { LoginAsUserComponent } from '@modules/authorization/pages/login-as-user/login-as-user.component';
import { LoginAsUserGuard } from '@modules/authorization/pages/login-as-user/login-as-user-guard';

export const AuthorizationRoutes: Routes = [
  {
    path: 'login-as-user',
    component: LoginAsUserComponent,
    canActivate: [LoginAsUserGuard],
  },
  {
    path: '',
    component: AuthPanelComponent,
    canActivate: [AuthPanelCanActivateGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/sign-in',
      },
      {
        path: 'sign-in',
        component: LoginComponent,
        canActivate: [],
        data: {
          metaTitle: 'Auth.login',
          type: EAuthViewMode.LOGIN,
        },
      },
      {
        path: 'sign-in/:token',
        component: LoginComponent,
        data: {
          metaTitle: 'Auth.login',
          type: EAuthViewMode.LOGIN,
        },
      },
      {
        path: 'sign-up',
        component: RegisterComponent,
        canActivate: [],
        data: {
          metaTitle: 'Auth.register',
          type: EAuthViewMode.REGISTER,
        },
      },
      {
        path: 'sign-up/:recommedationUrl',
        component: RegisterComponent,
        canActivate: [],
        data: {
          metaTitle: 'Auth.register',
          type: EAuthViewMode.REGISTER,
        },
      },
      {
        path: 'semrush',
        component: SemrushComponent,
        canActivate: [],
        data: {
          metaTitle: 'Auth.semrush',
          type: EAuthViewMode.SEMRUSH,
        },
      },
      {
        path: 'confirm-email/:token',
        component: ConfirmEmailComponent,
        data: {
          metaTitle: 'Auth.confirm-email',
          type: EAuthViewMode.CONFIRM_EMAIL,
        },
      },
      {
        path: 'change-email/:token',
        component: ConfirmChangeEmailComponent,
        data: {
          metaTitle: 'Auth.confirm-email',
          type: EAuthViewMode.CHANGE_EMAIL,
        },
      },
      {
        path: 'no-auth',
        component: NoAuthorizationComponent,
        data: {
          metaTitle: 'Auth.no authorization',
          type: EAuthViewMode.NO_AUTH,
        },
      },
      {
        path: 'no-auth-semrush',
        component: NoAuthorizationSemrushComponent,
        data: {
          metaTitle: 'Auth.no authorization',
          type: EAuthViewMode.NO_AUTH,
        },
      },
      {
        path: 'no-team',
        component: NoTeamComponent,
        data: {
          metaTitle: 'Auth.no team',
          type: EAuthViewMode.NO_TEAM,
        },
      },
      {
        path: 'end-trial',
        component: EndTrialComponent,
        data: {
          metaTitle: 'Auth.end trial',
          type: EAuthViewMode.END_TRIAL,
        },
      },
      {
        path: 'no-access',
        component: NoAccessComponent,
        data: {
          metaTitle: 'Auth.no access',
          type: EAuthViewMode.NO_ACCESS,
        },
      },
      {
        path: 'appsumo-sign-up',
        component: AppSumoRegisterComponent,
        canActivate: [AppSumoCanActivateGuard],
        data: {
          metaTitle: 'Auth.appsumo register',
          type: EAuthViewMode.APP_SUMO_REGISTER,
        },
      },
      {
        path: 'appsumo-invalid',
        component: AppSumoErrorComponent,
        data: {
          metaTitle: 'Auth.appsumo error',
          type: EAuthViewMode.APP_SUMO_ERROR,
        },
      },
      {
        path: 'auto-logout',
        component: LogoutComponent,
        data: {
          metaTitle: 'Auth.account Blocked',
          type: EAuthViewMode.ACCOUNT_BLOCKED,
        },
      },
    ],
  },
  {
    path: 'set-up-account',
    component: OnboardingSetupFormComponent,
    canActivate: [SetUpAccountCanActivateGuard],
    data: {
      metaTitle: 'Auth.set up account',
      type: EAuthViewMode.SET_UP_ACCOUNT_FORM,
    },
  },
  {
    path: 'select-plan',
    component: SelectPlanComponent,
    canActivate: [SelectPlanCanActivateGuard],
    data: {
      metaTitle: 'Auth.select plan',
      type: EAuthViewMode.SELECT_PLAN,
    },
    resolve: {
      globalData: PermissionsResolver,
    },
  },
];
