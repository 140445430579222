import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'df-canvas-position',
  templateUrl: './canvas-position.component.html',
  styleUrls: ['./canvas-position.component.scss'],
})
export class CanvasPositionComponent implements OnInit {
  @Input() position;

  ngOnInit(): void {
    this.disableValues();
  }

  get positionValues(): FormArray {
    return this.position.get('positionValues') as FormArray;
  }

  public allowEdit(c: AbstractControl): void {
    c.enable();
  }

  public deletePositionValue(index: number): void {
    this.positionValues.removeAt(index);
  }

  public addPositionValue(): void {
    this.positionValues.push(
      new FormGroup({
        value: new FormControl(''),
      }),
    );
  }

  private disableValues(): void {
    this.positionValues.controls.forEach((c: AbstractControl) => c.get('value')?.disable());
  }
}
