export enum EStep1Form {
  funnelId = 'funnelId',
  businessTypeId = 'businessTypeId',
  productName = 'productName',
  segmentsB2cIds = 'segmentsB2cIds',
  segmentsB2bIds = 'segmentsB2bIds',
  targetGroupsTypesIds = 'targetGroupsTypesIds',
  valuePropositionsIds = 'valuePropositionsIds',
  customersPainsIds = 'customersPainsIds',
  problemsIds = 'problemsIds',
}
