<div class="flex flex-col justify-start items-stretch tui-base-01 px-4 py-3.5 {{ step.type }}-light">
  <div
    class="flex justify-between items-center cursor-pointer"
    (click)="toggle($event)"
    #funnelManageHeader
  >
    <div class="flex items-center w-full pr-3">
      <svg-icon
        class="mr-3"
        [src]="'/assets/img/ic-' + step.type + '.svg'"
        [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      ></svg-icon>
      <div class="text-wrapper">
        <h4 class="text-base font-semibold">
          {{ step.name }}
        </h4>
        <h5
          class="uppercase font-semibold text-xs"
          *ngIf="funnelManageService.funnel?.statsStepMap?.get(step.id)"
        >
          {{
            'Funnels.Manage.:doneCount of :allCount done'
              | translate
                : {
                    doneCount: funnelManageService.funnel?.statsStepMap!.get(step.id)?.doneCount,
                    allCount: funnelManageService.funnel?.statsStepMap!.get(step.id)?.allCount
                  }
          }}
        </h5>
      </div>
    </div>
    <button
      class="{{ step.type }}  h-6 w-6 rounded-lg flex justify-center items-center"
      (click)="getRecomendedTactics($event, step)"
    >
      <svg-icon
        *ngIf="!loading"
        [src]="'/assets/img/ic_plus_bold.svg'"
      ></svg-icon>
      <img
        *ngIf="loading"
        src="/assets/img/icon_loader.gif"
        alt=""
        srcset=""
      />
    </button>
  </div>

  <tui-expand [expanded]="expanded">
    <ng-template tuiExpandContent>
      <div class="grid grid-cols-3 gap-x-3 pt-2.5">
        <div class="{{ step.type }} rounded-xl p-3">
          <h6 class="text-xl leading-none font-bold">
            {{ funnelManageService.funnel?.statsStepMap?.get(step.id)?.allCount }}
          </h6>
          <span class="tui-text-body-s-2 font-semibold mt-1">{{ 'Funnels.Manage.Tactics' | translate }}</span>
        </div>
        <div class="{{ step.type }} rounded-xl p-2.5">
          <h6 class="text-xl leading-none font-bold">
            {{ funnelManageService.funnel?.summaryStepMap?.get(step.id)?.activitiesCount }}
          </h6>
          <span class="tui-text-body-s-2 font-semibold mt-1">{{ 'Funnels.Manage.Activities' | translate }}</span>
        </div>
        <div class="{{ step.type }} rounded-xl p-2.5">
          <h6 class="text-xl leading-none font-bold">
            {{ funnelManageService.funnel?.summaryStepMap?.get(step.id)?.assetsCount }}
          </h6>
          <span class="tui-text-body-s-2 font-semibold mt-1">{{ 'Funnels.Manage.Assets' | translate }}</span>
        </div>
      </div>
    </ng-template>
  </tui-expand>
</div>
