import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { Config } from '@shared/configs/config';
import { XPartnerService } from '@shared/services/x-partner.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@shared/models/user.model';

@Injectable()
export class AuthPanelCanActivateGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authStorageService: AuthStorageService,
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
    private xPartnerService: XPartnerService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let canActivate$: Observable<boolean> = of(true);
    if (this.xPartnerService.isWhitelabelPartner) {
      if (state.url === '/no-auth') {
        canActivate$ = of(false);
        this.xPartnerService.saveFailedAuthentication();
        this.n.go('xp-no-auth');
      } else if (this.authStorageService.jwt) {
        canActivate$ = this.userService.getMeRequest(true).pipe(
          map((user: User | null) => {
            if (user) {
              this.n.go(Config.MAIN_VIEW);
              return false;
            }
            return true;
          }),
        );
      } else {
        canActivate$ = of(false);
      }
    }
    return canActivate$;
  }
}
