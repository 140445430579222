import { Injectable } from '@angular/core';
import {
  GetAssetInputGraphql,
  GetLibraryAssetInputGraphql,
  SaveLibraryAssetInputGraphql,
} from '@modules/graphql/graphql-types';
import { Apollo } from 'apollo-angular';
import { SaveLibraryAssetMutation } from './graphql/mutations/save-library-asset.mutation.generated';
import { GetLibraryAssetsQuery } from './graphql/queries/get-library-assets.query.generated';
import { SaveLibraryAssetDocument } from './graphql/mutations/save-library-asset.mutation';
import { GetLibraryAssetsDocument } from './graphql/queries/get-library-assets.query';
import { map, tap } from 'rxjs';
import { DeleteAssetDocument } from './graphql/mutations/delete-asset.mutation';
import { DeleteAssetMutation } from './graphql/mutations/delete-asset.mutation.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { GetAssetDocument } from './graphql/queries/get-asset.query';
import { GetAssetQuery } from './graphql/queries/get-asset.query.generated';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(
    private apollo: Apollo,
    private snackbarService: SnackbarService,
    private t: TranslateService,
  ) {}

  saveLibraryAsset(input: SaveLibraryAssetInputGraphql) {
    return this.apollo
      .mutate<SaveLibraryAssetMutation>({
        mutation: SaveLibraryAssetDocument,
        variables: {
          input,
        },
      })
      .pipe(
        map((res) => res?.data?.saveLibraryAsset),
        tap((res) => {
          if (res?.success) {
            this.snackbarService.success(this.t.instant('AssetsLibrary.Success' + res.process));
          } else {
            this.snackbarService.error(this.t.instant('AssetsLibrary.Error'));
          }
        }),
      );
  }

  getLibraryAssets(input: GetLibraryAssetInputGraphql) {
    return this.apollo
      .query<GetLibraryAssetsQuery>({
        query: GetLibraryAssetsDocument,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .pipe(map((res) => res.data.getLibraryAssets));
  }

  getAsset(input: GetAssetInputGraphql) {
    return this.apollo
      .query<GetAssetQuery>({
        query: GetAssetDocument,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .pipe(map((res) => res.data.getAsset));
  }

  deleteAsset(assetId: number) {
    return this.apollo
      .mutate<DeleteAssetMutation>({
        mutation: DeleteAssetDocument,
        variables: {
          assetId,
        },
      })
      .pipe(
        map((res) => res?.data?.deleteAsset),
        tap((res) => {
          if (res?.success) {
            this.snackbarService.success(this.t.instant('AssetsLibrary.Success' + res.process));
          } else {
            this.snackbarService.error(this.t.instant('AssetsLibrary.Error'));
          }
        }),
      );
  }
}
