import { TranslateService } from '@ngx-translate/core';

export const getAppFeaturesList = (t: TranslateService): { title: string; content: string; icon: string }[] => {
  return [
    {
      title: t.instant('Auth.Onboarding.Marketing Tactics'),
      content: t.instant('Auth.Onboarding.Marketing Tactics content'),
      icon: 'assets/img/feature_tactics.svg',
    },
    {
      title: t.instant('Auth.Onboarding.Growth hackers'),
      content: t.instant('Auth.Onboarding.Growth hackers content'),
      icon: 'assets/img/feature_hackers.svg',
    },
    {
      title: t.instant('Auth.Onboarding.Funnels'),
      content: t.instant('Auth.Onboarding.Funnels content'),
      icon: 'assets/img/feature_funnels.svg',
    },
    {
      title: t.instant('Auth.Onboarding.AI'),
      content: t.instant('Auth.Onboarding.AI content'),
      icon: 'assets/img/feature_ai.svg',
    },
    {
      title: t.instant('Auth.Onboarding.Team Sharing'),
      content: t.instant('Auth.Onboarding.Team Sharing content'),
      icon: 'assets/img/feature_team.svg',
    },
  ];
};
