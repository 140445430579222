import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ResetPasswordComponent } from '@modules/authorization/pages/reset-password/reset-password.component';
import { ActivatedRoute } from '@angular/router';
import { FetchResult } from '@apollo/client/core';
import { LoginMutation } from '@modules/authorization/shared/graphql/mutations/login.mutation.generated';
import { SocialLoginInputGraphql, SubscriptionPlanOutputGraphql } from '@modules/graphql/graphql-types';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { TuiInputComponent } from '@taiga-ui/kit';
import { Observable } from 'rxjs';
import { LoginWithSocialMediaAccountMutation } from '@modules/authorization/shared/graphql/mutations/ login-with-social-media-account.mutation.generated';
import { AbstractLoginComponent } from '@modules/authorization/shared/abstracts/abstract-login.component';
import { AuthSourceEnum } from '@modules/authorization/shared/configs/auth-source.enum';

@Component({
  selector: 'df-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends AbstractLoginComponent implements AfterViewInit, OnInit {
  @Input() dialogMode = false;
  @Input() trial = false;
  @Input() planToBuy: SubscriptionPlanOutputGraphql | null = null;
  @Output() navigateToSignUp: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() signedIn: EventEmitter<boolean> = new EventEmitter<boolean>();

  tacticRedirect = false;
  source: AuthSourceEnum | undefined = undefined;

  @ViewChild('emailInput', { static: true }) emailInput!: TuiInputComponent;

  constructor(changes: ChangeDetectorRef, route: ActivatedRoute, recaptchaService: ReCaptchaV3Service) {
    super(changes, recaptchaService, route);
  }

  ngOnInit() {
    if (this.route.snapshot.params['token']) {
      this.openResetPasswordDialog(this.route.snapshot.params['token']);
    }

    if (this.route.snapshot.queryParams['credential']) {
      this.signInByCredentialToken(this.route.snapshot.queryParams['credential']);
    }

    this.tacticRedirect = this.route.snapshot.queryParamMap.has('tactic-redirect');
    this.source = this.route.snapshot.queryParams.source;
    super.ngOnInit();
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  setFocus() {
    this.emailInput.nativeFocusableElement?.focus();
  }

  goToSignUp(): void {
    this.navigateToSignUp.emit(true);
  }

  notifySign(): void {
    this.signedIn.emit(true);
  }

  protected redirectAfterSign() {
    if (this.source === AuthSourceEnum.MARKETPLACE) {
      window.location.href = this.Config.MARKETPLACE_URL;
    }
    if (this.tacticRedirect) {
      this.n.go('/tactics/list/all');
      return;
    }
    super.redirectAfterSign();
  }

  protected successSignInBySocialAccount(res: FetchResult<LoginWithSocialMediaAccountMutation>) {
    super.successSignInBySocialAccount(res);
    const toBuyId = this.authController.userService.getPlanToBuy();
    if (!this.dialogMode && toBuyId !== null) {
      this.n
        .go('public/pricing', undefined, { queryParams: { toBuy: toBuyId } })
        ?.then(() => this.authController.userService.clearPlanToBuy());
    } else {
      this.dialogMode ? this.notifySign() : this.redirectAfterSign();
    }
  }

  protected getLoginRequest(token: string): Observable<FetchResult<LoginMutation>> {
    (window as any).fpr('referral', {
      email: this.formGroup.get('email')?.value,
    });

    return this.authGraphqlService.login(
      this.formGroup.get('email')?.value,
      this.formGroup.get('password')?.value,
      this.formGroup.get('rememberMe')?.value,
      token,
      this.tacticRedirect,
    );
  }

  protected successSignIn(res: FetchResult<LoginMutation>) {
    super.successSignIn(res);
    this.dialogMode ? this.notifySign() : this.redirectAfterSign();
  }

  protected getSocialLoginRequest(
    loginData: SocialLoginInputGraphql,
  ): Observable<FetchResult<LoginWithSocialMediaAccountMutation>> {
    return this.authGraphqlService.loginWithSocialMediaAccount({
      ...loginData,
      redirectedFromTacticsLpPage: this.tacticRedirect,
    });
  }

  private openResetPasswordDialog(token: string) {
    this.dialogService
      .open<number>(new PolymorpheusComponent(ResetPasswordComponent, this.injector), {
        data: {
          token,
        },
        dismissible: true,
        label: this.t.instant('Auth.Reset Password.title'),
      })
      .subscribe();
  }
}
