import { gql } from 'apollo-angular';

export const CreateEmptyTacticDocument = gql(/* GraphQL*/ `mutation createEmptyTactic {
  createEmptyTactic {
    id
    name
    owner
    {
      firstTactic
      {
        id
      }
    }
  }
}
`);
