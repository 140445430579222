import { gql } from 'apollo-angular';

export const createStatementDocument = gql`
  mutation createStatement($input: StatementInputGraphql!) {
    createStatement(input: $input) {
      id
      answers {
        field {
          id
          category
          type
          required
          minValue {
            id
            name
            value
          }
          maxValue {
            id
            name
            value
          }
          dependsOnField {
            id
            category
          }
          dependsOnOption {
            id
            name
          }
          options {
            id
            name
          }
        }
        value
      }
    }
  }
`;
