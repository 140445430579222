<div class="flex items-start justify-start w-full">
  <div class="flex flex-col justify-center items-start mr-8">
    <h2 class="tui-text-01 tui-text_h4 mb-6 font-light">
      <span class="font-bold block mb-4 tui-text_h2">{{ 'Pricing.Oops :(' | translate }}</span>
      {{ 'Pricing.To access' | translate }}
      <span class="font-bold block">{{ permissionName }}</span>
      {{ 'Pricing.you need a better plan.' | translate }}
    </h2>
  </div>
  <img
    src="/assets/img/pricing-gif.gif"
    width="300"
    alt="pricing oops"
    class="hidden md:inline-flex rounded-2xl"
  />
</div>
<div
  class="flex-col flex items-start justify-start w-full mt-8"
  *ngIf="!xPartnerService.isWhitelabelPartner && !userService.User?.isSemrushPlan()"
>
  <h3 class="tui-text-01 tui-text_h6 mb-6 font-light">
    {{ 'Pricing.Get access to new features with our' | translate }}
    <span
      class="tui-primary cursor-pointer font-bold"
      (click)="goToPackets()"
      >{{ 'Pricing.Pricing Plans' | translate }}</span
    >
  </h3>
  <button
    size="l"
    tuiButton
    type="submit"
    (click)="goToPackets()"
    appearance="primary"
  >
    {{ 'Pricing.Manage Your Plan' | translate }}
  </button>
</div>
