export class Regex {
  static readonly decimal = /^\d+$/;
  static readonly phone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.0-9]*$/;
  static readonly email =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static readonly special = /[_=!@+\-[\].,/'";:~`|\\#$%^&*(),.?":{}|<>]/;
  static readonly password = /^((?=.*?[A-Z])|(?=.*?[a-z]))(?=.*?[0-9])(?=.*?[^\w\s]).{7,}$/;
  static readonly whiteSpace = /^.*[^ ].*$/;
  static readonly url =
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
  static readonly softUrl =
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})$/;
  static readonly integer = /^[0-9]*$/;
  static readonly min_max: RegExp = /\.\{\d+,\d+}/;
  static readonly fileImage: RegExp = /^(.*)\.(jpg|jpeg|png|bmp|gif)$/i; // config.ts ACCEPTED_IMAGES_EXTENSIONS
  static readonly fileVideo: RegExp = /^(.*)\.(mp4|mov|avi|wmv|mkv|flv)$/i;
  static readonly fileBanned: RegExp = /^(.*)\.(exe|mcs|sh|zip|7z|gz|rar|tar|bz|bz2|php|js|bat)$/i; // config.ts FORBIDDEN_BENCHMARKS_EXTENSIONS
}
