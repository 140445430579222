import {
  EOnboardingElementType,
  OnboardingDOMElementFactory,
  OnboardingElement,
} from '@shared/models/onboarding-step.model';
import { OnboardingAccentLabelFactory } from '@shared/factories/onboarding-accent-label.factory';
import { OnboardingTooltipFactory } from '@shared/factories/onboarding-tooltip.factory';
import { OnboardingArrowFactory } from '@shared/factories/onboarding-arrow.factory';
import { AbstractOnboardingFactory } from '@shared/abstracts/onboarding-factory.abstract';
import { OnboardingFullscreenFocusFactory } from '@shared/factories/onboarding-fullscreen-focus.factory';

export class OnboardingElementFactory extends AbstractOnboardingFactory<OnboardingElement> {
  renderElement(element: OnboardingElement): Element {
    let factory: OnboardingDOMElementFactory<OnboardingElement>;
    switch (element.type) {
      case EOnboardingElementType.accentLabel:
        factory = new OnboardingAccentLabelFactory(this.container, this.anchor);
        break;
      case EOnboardingElementType.tooltip:
        factory = new OnboardingTooltipFactory(this.container, this.anchor);
        break;
      case EOnboardingElementType.arrow:
        factory = new OnboardingArrowFactory(this.container, this.anchor);
        break;
      case EOnboardingElementType.fullscreen:
        factory = new OnboardingFullscreenFocusFactory(this.container, this.anchor);
        break;
    }
    return factory.renderElement(element);
  }
}
