import { gql } from 'apollo-angular';

export const SaveInputDataDocument = gql`
  mutation saveInputData($input: InputsInputGraphql!) {
    saveInputData(input: $input) {
      id
      funnelId
      brief
      urls {
        url
        isParsed
      }
      files {
        url
        extension
      }
      createdAt
      editedAt
    }
  }
`;
