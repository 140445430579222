<div
  class="flex"
  *ngIf="asset"
>
  <div class="block mr-2 md:mr-4 w-8 flex-shrink-0 flex-grow-0 flex flex-col justify-center items-center">
    <svg-icon
      [src]="Config.ASSETS + asset.asset.platform.image!"
      [svgStyle]="{ width: '2rem', height: '2rem' }"
      *ngIf="asset.asset.platform"
    ></svg-icon>
  </div>

  <div class="flex flex-col flex-grow justify-center max-w-90">
    <p class="tui-text_body-s-2 font-semibold">
      {{ asset.asset.placement.name }}
      <svg-icon
        (click)="openAssetPreview($event)"
        src="/assets/img/ic-info-circle.svg"
        [svgStyle]="{ width: '0.875rem', height: '0.875rem' }"
        class="ml-1 cursor-pointer inline-block align-top"
      ></svg-icon>
    </p>
    <span class="tui-text_body-xs tui-text-05"
      ><ng-container *ngFor="let resolution of asset.selectedResolutions">
        {{ resolution.width }}x{{ resolution.height }}px,</ng-container
      >
      {{ asset.asset.fileType | or: asset.asset.headline | or: asset.asset.primaryText }}</span
    >
  </div>
</div>
