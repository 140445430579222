<div class="w-full border border-solid tui-primary-border rounded-3xl tui-base-01-bg overflow-hidden">
  <div class="w-full h-full flex flex-col md:flex-row justify-start items-start md:items-center p-5">
    <div class="md:flex-grow">
      <h3 class="tui-text_body-xl font-semibold mb-2">
        {{ 'Funnels.Onboarding.This is funnel dashboard' | translate }}
      </h3>
      <p class="text-sm md:text-base md:max-w-lg mb-4 md:mb-0">
        {{ 'Funnels.Onboarding.We prepared your basic flow...' | translate }}
      </p>
    </div>
    <button
      tuiButton
      type="button"
      size="xl"
      appearance="primary"
      (click)="buttonClick.emit(true)"
    >
      {{ 'Funnels.Onboarding.Ok, let’s start!' | translate }}
    </button>
  </div>
</div>
