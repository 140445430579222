import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Campaign } from '../../campaign.component';

@Component({
  selector: 'df-campaign-content-strategy-plan',
  templateUrl: './content-strategy-plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignContentStrategyPlanComponent {
  @Input() campaign!: Campaign;
}
