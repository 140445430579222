<div class="auth-panel flex justify-center items-center min-h-screen flex-col pt-10 pb-10">
  <img
    [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
    [alt]="xPartnerService.partner.SYSTEM_NAME"
    class="login__logo mb-0"
  />

  <ng-container *ngIf="confirmed === true">
    <h1 class="tui-text_h1 tui-text-03 pl-5 pr-5 text-center mt-6 lg:mt-10">
      {{ 'Auth.ConfirmChangeEmail.E-mail confirmed' | translate }}
    </h1>
    <form class="flex flex-col">
      <hr class="mb-3" />
      <p>
        {{ 'Auth.ConfirmChangeEmail.Thank you for confirmation your new e-mail.' | translate }}
      </p>
      <div class="tui-form__row mt-10">
        <button
          size="xl"
          tuiButton
          type="submit"
          class="w-full block"
          appearance="primary"
          (click)="login()"
        >
          {{ 'Auth.Login.Sign in' | translate }}
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="confirmed === false">
    <h1 class="tui-text_h1 tui-accent pl-5 pr-5 text-center mt-6 lg:mt-10">
      {{ 'Auth.ConfirmChangeEmail.E-mail not confirmed' | translate }}
    </h1>
    <form class="flex flex-col">
      <hr class="mb-3" />
      <p>
        {{
          'Auth.ConfirmChangeEmail.There is some problem with this confirmation. Did you click on the newest
        confirmation e-mail?' | translate
        }}
      </p>
    </form>
  </ng-container>

  <ng-container *ngIf="confirmed === null">
    <tui-loader
      class="inline-flex tui-space_right-2"
      size="xxl"
      [showLoader]="loading"
    >
      <div class="w-40 h-40"></div>
    </tui-loader>
  </ng-container>
</div>
