import { gql } from 'apollo-angular';

export const CreateSubscriptionDocument = gql`
  mutation createSubscription($planId: Int!, $promotionCode: String, $isTrial: Boolean, $priceId: Int) {
    createSubscription(planId: $planId, promotionCode: $promotionCode, isTrial: $isTrial, priceId: $priceId) {
      created
      redirectUrl
    }
  }
`;
