import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Integration } from '@shared/models/integration.model';
import { ContentGeneratorTemplatesFormService } from '../../services/content-generator-templates-form.service';

@Component({
  selector: 'df-content-generator-template-form',
  templateUrl: './content-generator-template-form.component.html',
  styleUrls: ['./content-generator-template-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorTemplateFormComponent {
  @Input() integration: Integration | null = null;

  constructor(private readonly templatesFormService: ContentGeneratorTemplatesFormService) {}

  get loading$() {
    return this.templatesFormService.loading$;
  }

  get form() {
    return this.templatesFormService.form;
  }

  handleDataSubmitted(): void {
    this.templatesFormService.generateContent(this.integration!);
  }
}
