import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { EResourceViewType } from '@shared/modules/resource-teams/enums/resource-view-type.enum';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { ResourceTeamsService } from '@shared/modules/resource-teams/services/resource-teams.service';
import { Funnel } from '@shared/models/funnel.model';
import { Tactic } from '@shared/models/tactic.model';
import { TUI_ARROW } from '@taiga-ui/kit';
import { User } from '@shared/models/user.model';
import { Team } from '@shared/models/team.model';
import { TeamsService } from '@modules/user-settings/modules/teams/shared/services/teams.service';
import { UserService } from '@shared/services/user.service';
import { FunnelPermissionEnum, PermissionType, TeamRoleEnum } from '@modules/graphql/graphql-types';
import {
  EResourceTeamStatus,
  ResourceTeam,
} from '@modules/funnels/modules/funnel-settings/shared/models/resource-team.model';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { ResourceTeamsSingleTeamComponent } from '@shared/modules/resource-teams/components/resource-teams-single-team/resource-teams-single-team.component';

@Component({
  selector: 'df-resource-teams',
  templateUrl: './resource-teams.component.html',
  styleUrls: ['./resource-teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceTeamsComponent extends AbstractSubscriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  TeamRoleEnum = TeamRoleEnum;

  readonly arrow = TUI_ARROW;
  readonly user: User;
  hostedOpen = false;

  teams: Team[] = [];
  loading = false;

  @Input() resourceTypeView!: EResourceViewType;
  @Input() title: string | null = null;

  @Input()
  set funnel(funnel: Funnel | null | undefined) {
    if (
      funnel?.id &&
      funnel.currentUserPermission === FunnelPermissionEnum.Editor &&
      !this.resourceTeamsService.resourceTeams.length
    ) {
      //for first init only
      this.resourceTeamsService.fetchResourceTeams({ funnelId: funnel.id });
    }
    this.changes.detectChanges();
  }

  @Input()
  set tactic(tactic: Tactic | null | undefined) {
    if (
      tactic?.id &&
      tactic.currentUserPermission === FunnelPermissionEnum.Editor &&
      !this.resourceTeamsService.resourceTeams.length
    ) {
      //for first init only
      this.resourceTeamsService.fetchResourceTeams({ tacticId: tactic.id });
    }
    this.changes.detectChanges();
  }

  @Output() onResourcesChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onComponentReady: EventEmitter<ResourceTeamsService> = new EventEmitter<ResourceTeamsService>();

  @ViewChildren(ResourceTeamsSingleTeamComponent)
  teamsComponents!: QueryList<ResourceTeamsSingleTeamComponent>;
  get resourceTeams() {
    return this.resourceTeamsService.resourceTeams;
  }

  constructor(
    public resourceTeamsService: ResourceTeamsService,
    private teamsService: TeamsService,
    readonly userService: UserService,
    private changes: ChangeDetectorRef,
    private pricingService: PricingService,
  ) {
    super();
    this.user = this.userService.User!;
  }

  ngOnInit(): void {
    this.resourceTeamsService.initService(this.resourceTypeView);
    this.listenLoading();
    this.listenChangesInForm();
    this.filterTeamsThroughAddedResources();
  }

  ngAfterViewInit() {
    this.onComponentReady.emit(this.resourceTeamsService);
  }

  private listenLoading() {
    this.sub.add(
      this.resourceTeamsService.loading$.subscribe((val) => {
        this.loading = val;
        this.filterTeamsThroughAddedResources();
        this.changes.detectChanges();
      }),
    );
  }

  private listenChangesInForm() {
    this.sub.add(
      this.resourceTeamsService.resourceChanges$.subscribe((val: boolean) => {
        this.filterTeamsThroughAddedResources();
        this.onResourcesChange.emit(val);
        this.changes.detectChanges();
      }),
    );
  }

  addResourceTeam(team: Team) {
    this.pricingService.checkPricing(PermissionType.TeamCollaboration).subscribe(() => {
      this.resourceTeamsService.addResourceTeam(
        new ResourceTeam({
          id: this.resourceTeamsService.resourceTeams.length + Date.now(),
          team: team,
          status: EResourceTeamStatus.TO_CREATE,
          permissions: [],
        }),
      );
      this.filterTeamsThroughAddedResources();
      this.addAllTeamMembers(team);
      this.hostedOpen = false;
      this.changes.detectChanges();
    });
  }

  private filterTeamsThroughAddedResources() {
    const resourcesTeamsIds: number[] = this.resourceTeamsService.resourceTeams.map(
      (resourceTeam) => resourceTeam.team.id,
    );
    this.teams = this.teamsService.teams.filter((t) => resourcesTeamsIds.indexOf(t.id) === -1);
    this.changes.detectChanges();
  }

  private addAllTeamMembers(team: Team): void {
    this.teamsComponents.find((t) => t.resourceTeam.team.id === team.id)?.addAllMembers();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.resourceTeamsService.clearService();
  }
}
