<div
  *ngIf="form"
  [formGroup]="form"
  [validate-form]="form"
>
  <df-business-data-form-header [type]="type"></df-business-data-form-header>
  <div
    *ngIf="form"
    [formArrayName]="type"
  >
    <div *ngFor="let url of getFormArray().controls; let urlIndex = index">
      <label tuiLabel="YouTube link">
        <tui-input
          data-test="youtube-link-input"
          [tuiTextfieldCustomContent]="remove"
          type="text"
          [formControlName]="urlIndex"
        >
          Paste YouTube link

          <ng-template #remove>
            <svg-icon
              (click)="removeField(urlIndex)"
              src="assets/img/_ic_trash.svg"
              class="tui-space_left-3 cursor-pointer pointer-events-auto"
            ></svg-icon>
          </ng-template>
        </tui-input>
      </label>
      <df-error-input
        class="mb-6"
        [control]="url"
      ></df-error-input>
    </div>
  </div>

  <button
    data-test="add-new-button"
    type="button"
    (click)="addNewField()"
    class="mt-4 ml-auto flex text-base font-semibold text-[#2E4FFF] items-center"
  >
    <img
      class="mr-2"
      alt=""
      src="assets/img/icon-left-plus.svg"
    />Add new
  </button>
</div>
