<div
  class="auth-panel flex justify-center items-center flex-col"
  [ngClass]="{ 'pt-5 pb-10 md:py-10 h-full': !dialogMode, dialog: dialogMode }"
>
  <div
    class="auth-panel__container flex flex-col justify-start items-start"
    [class.wide]="!dialogMode"
  >
    <img
      [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
      [alt]="xPartnerService.partner.SYSTEM_NAME"
      class="login__logo mb-0"
      *ngIf="dialogMode"
    />
    <h1 class="tui-text_h2 font-semibold tui-text-03 pl-0 pr-0 mt-4 w-auto">
      {{ (dialogMode ? 'Auth.Register.title' : 'Auth.Onboarding.Begin your trial') | translate }}
    </h1>
    <p
      *ngIf="!dialogMode"
      class="tui-text_h6 tui-text-02 font-semibold mb-6 -mt-4"
    >
      {{ 'Auth.Onboarding.trial' | translate }}
    </p>
    <form
      class="flex flex-col"
      [formGroup]="formGroup"
      [validate-form]="formGroup"
      (submit)="signUp()"
    >
      <ng-template #success>
        <tui-svg
          class="tui-accent-07 tui-space_left-3"
          src="tuiIconCheckLarge"
        ></tui-svg>
      </ng-template>

      <div class="tui-form__row tui-form__row_multi-fields">
        <div class="tui-form__multi-field">
          <tui-input
            data-test="first-name-input"
            [tuiTextfieldCustomContent]="getControl('firstName')?.valid ? success : ''"
            formControlName="firstName"
          >
            {{ 'name' | translate }} *
          </tui-input>
          <df-error-input
            data-test="first-name-input-error"
            [control]="getControl('firstName')"
          ></df-error-input>
        </div>

        <div class="tui-form__multi-field mt-helper">
          <tui-input
            data-test="last-name-input"
            [tuiTextfieldCustomContent]="
              getControl('lastName')?.valid && getControl('lastName')?.touched ? success : ''
            "
            formControlName="lastName"
          >
            {{ 'last name' | translate }}
          </tui-input>
          <df-error-input [control]="getControl('lastName')"></df-error-input>
        </div>
      </div>

      <div lass="tui-form__row">
        <tui-input
          data-test="email-input"
          [tuiTextfieldCustomContent]="getControl('email')?.valid ? success : ''"
          formControlName="email"
        >
          {{ 'email' | translate }} *
        </tui-input>
        <df-error-input
          data-test="email-input-error"
          [control]="getControl('email')"
        ></df-error-input>
      </div>

      <div lass="tui-form__row">
        <tui-input-password
          data-test="password-input"
          [tuiTextfieldCustomContent]="getControl('password')?.valid ? success : ''"
          tuiHintContent
          tuiHintDirection="right"
          tuiHintMode="onDark"
          formControlName="password"
        >
          {{ 'password' | translate }} *
        </tui-input-password>
        <df-error-input
          data-test="password-input-error"
          [control]="getControl('password')"
        ></df-error-input>
      </div>

      <div lass="tui-form__row">
        <tui-checkbox-labeled
          data-test="policy-checkbox"
          size="l"
          class="inline-flex"
          formControlName="rulesAccepted"
        >
          <span
            class="tui-text-body-s-2"
            [innerHTML]="
              'Auth.Register.I agree to Digital First Terms and Privacy Policy'
                | translate
                  : {
                      system: xPartnerService.partner.SYSTEM_NAME,
                      privacy: Config.PRIVACY_POLICY
                    }
            "
          ></span>
        </tui-checkbox-labeled>
        <df-error-input
          data-test="policy-checkbox-error"
          [control]="getControl('rulesAccepted')"
        ></df-error-input>
      </div>

      <div class="tui-form__row">
        <button
          data-test="create-account-button"
          size="xl"
          tuiButton
          type="submit"
          class="w-full block"
          appearance="primary"
          [showLoader]="loading"
          [disabled]="loading || !formGroup.valid"
        >
          {{
            !dialogMode
              ? ('Auth.Register.Create Account' | translate)
              : trial
                ? ('Auth.Register.Sign up and start trial' | translate)
                : ('Auth.Register.Save and buy plan' | translate: { name: planToBuy?.name })
          }}
        </button>
      </div>

      <div
        class="flex flex-col items-center mt-4"
        *ngIf="!dialogMode"
      >
        <span class="tui-text-body-xs tui-text-02 uppercase font-bold mb-4">
          {{ 'Auth.Register.or log in with' | translate }}
        </span>
        <df-socials-authorization
          (signInWithGoogle)="signInWithGoogle()"
          (signInWithFacebook)="signInWithFB()"
        ></df-socials-authorization>
      </div>

      <div class="tui-form__row flex {{ dialogMode ? 'justify-between' : 'justify-center' }} gap-x-3 items-center mt-8">
        <span class="tui-text_body-m-2 tui-text-02">
          {{ 'Auth.Register.Have an account' | translate }}
        </span>
        <a
          data-test="sign-in-link"
          tuiLink
          [routerLink]="n.getPath('sign-in')"
          [queryParams]="tacticRedirect ? { 'tactic-redirect': true } : null"
          class="tui-primary"
          *ngIf="!dialogMode"
        >
          {{ 'Auth.Register.Sign in' | translate }}
        </a>
        <a
          data-test="sign-in-link"
          tuiLink
          (click)="goToSignIn()"
          class="tui-primary"
          *ngIf="dialogMode"
        >
          {{ 'Auth.Register.Sign in' | translate }}
        </a>
      </div>
    </form>
  </div>
</div>
