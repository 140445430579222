<button
  tuiButton
  type="button"
  size="xl"
  appearance="outline"
  (click)="onSkipOnboarding()"
  class="onboarding-action"
>
  <span class="i18n-dynamic">{{ 'Funnels.Onboarding.Skip onboarding' | translate }}</span>
</button>
