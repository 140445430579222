<form
  [formGroup]="form"
  [validate-form]="form"
  (submit)="submit()"
  class="pt-4"
>
  <tui-input formControlName="name">
    {{ 'Funnels.Manage.Your custom name' | translate }}
  </tui-input>
  <df-error-input [control]="form.get('name')"></df-error-input>

  <div class="flex items-center mt-3 justify-center">
    <button
      size="l"
      tuiButton
      type="button"
      class="mr-2"
      appearance="outline"
      (click)="discard()"
    >
      <svg-icon
        [src]="'/assets/img/ic-close.svg'"
        class="mr-2"
      ></svg-icon>
      {{ 'cancel' | translate }}
    </button>

    <button
      size="l"
      tuiButton
      type="submit"
      appearance="primary"
      [showLoader]="loading"
      [disabled]="loading"
    >
      <svg-icon
        [src]="'/assets/img/ic-check.svg'"
        class="mr-2"
      ></svg-icon>
      {{ 'save' | translate }}
    </button>
  </div>
</form>
