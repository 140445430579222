<tui-island
  class="relative h-auto md:h-80 md:pl-16 overflow-hidden"
  size="s"
>
  <img
    src="assets/img/funnel-empty-recommendations.png"
    alt=""
    class="absolute -rotate-12 -bottom-10 lg:-bottom-20 left-4 lg:left-10 w-72 lg:w-96 md:max-w-1-2"
  />
  <div
    class="h-full flex flex-col justify-start md:justify-center items-center md:items-start text-center md:text-left md:ml-4 lg:ml-10 md:pl-64 lg:pl-96 mb-52 sm:mb-64 md:mb-0 mt-10 md:mt-0"
  >
    <div class="text-lg sm:text-2xl lg:text-3xl xl:text-4xl leading-normal font-semibold tui-text-03">
      <ng-content></ng-content>
    </div>
    <button
      tuiButton
      [size]="'l'"
      appearance="primary"
      [routerLink]="['', { outlets: { pricing: pricingUrl } }]"
      [skipLocationChange]="true"
      class="mt-4"
    >
      <span class="font-semibold">{{ 'Funnels.Recommendations.Open pricing plans' | translate }}</span>
    </button>
  </div>
</tui-island>
