import { ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { TuiDialogService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Canvas, CanvasCategory, DisplayedCanvasCategory, Position } from './models';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import {
  CanvasAnswerSuggestionsOutput,
  CanvasCreateInstanceInput,
  CanvasInstancePositionValueInput,
  CanvasInstancePositionValueOutput,
  CanvasPositionOutput,
  CanvasPositionValueOutput,
  CanvasTemplateStatus,
  PermissionType,
} from '@modules/graphql/graphql-types';

import { SnackbarService } from '@core/services/snackbar.service';
import { AbstractFunnelSubpageComponent } from '@shared/abstracts/funnel-subpage.component.abstract';
import { CanvasService } from '@shared/services/canvas.service';
import { GlobalDataService } from '@shared/services/global-data.service';
import { HasFormCanDeactivate } from '@shared/guards/form-not-dirty.guard';
import { ConfimLeavePageDialogComponent } from '@shared/components/confim-leave-page-dialog/confim-leave-page-dialog.component';
import { BusinessDataComponent } from '@modules/business-data/business-data.component';
import { BusinessDataService } from '@modules/business-data/business-data.service';
import { UserService } from '@shared/services/user.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { FunnelCanvasesService } from './funnel-canvases.service';
import FileSaver from 'file-saver';
import { MixpanelEventName, MixpanelService } from '@shared/services/mixpanel.service';

@Component({
  selector: 'df-funnel-canvases',
  templateUrl: './funnel-canvases.component.html',
  styleUrls: ['./funnel-canvases.component.scss'],
})
export class FunnelCanvasesComponent extends AbstractFunnelSubpageComponent implements HasFormCanDeactivate, OnInit {
  public access = true;
  public allCanvas!: Canvas[];
  public categories: CanvasCategory[] = [];
  public displayedCategories: DisplayedCanvasCategory[] = [];
  public selectedCanvas: Canvas | null = null;
  public selectedCanvasInstance?: any;
  public searchForm: FormGroup = new FormGroup({ search: new FormControl('') });
  public form!: FormGroup;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public funnelId!: number;
  public formSubmitted = false;

  private canvasSuggestions?: CanvasAnswerSuggestionsOutput;
  private navBusinessData;
  private companyDataExist = false;

  get permissionName(): string {
    return this.globalData.getSinglePermission(PermissionType.FunnelManagement)?.name ?? '';
  }

  get positions(): FormArray {
    return this.form.get('positions') as FormArray;
  }

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    public router: Router,
    public userService: UserService,
    public n: NavigateService,
    private t: TranslateService,
    private s: SnackbarService,
    private formBuilder: FormBuilder,
    private mixpanelService: MixpanelService,
    private changes: ChangeDetectorRef,
    private readonly snackbar: SnackbarService,
    protected readonly funnelManageService: FunnelManageService,
    protected readonly route: ActivatedRoute,
    private readonly globalData: GlobalDataService,
    private readonly canvasService: CanvasService,
    private readonly businessDataService: BusinessDataService,
    private readonly funnnelCanvasService: FunnelCanvasesService,
  ) {
    super(route, funnelManageService);
    this.navBusinessData = this.router.getCurrentNavigation()?.extras.state;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getFunnelId();
    this.getCanvasTemplates();
    this.checkCompanyDataExist();
    this.handleSearch();
  }

  public goToCompanyData(): void {
    this.router.navigate(['../business-data'], {
      relativeTo: this.route,
    });
  }

  public generatePDF(): void {
    if (!this.selectedCanvasInstance?.id) {
      this.s.error(this.t.instant('Funnels.Canvases.Save canvas before generate PDF'));
      return;
    }

    this.loading$.next(true);
    this.funnnelCanvasService.generatePDF(this.selectedCanvasInstance.id).subscribe((res) => {
      if (!res) {
        this.s.error(this.t.instant('Funnels.Canvases.Something went wrong. Please try again'));
        return;
      }

      FileSaver.saveAs(res!.data!.generatePDF, 'canvas.pdf');

      this.sendMixpanelEvent('download');
      this.loading$.next(false);
    });
  }

  public checkCompanyDataExist() {
    this.businessDataService
      .getCompanyData(this.funnelId)
      .subscribe((res) => (this.companyDataExist = !!res.data?.getCompanyData.url));
  }

  public clearForm() {
    const positions = this.form?.get('positions') as FormArray;
    positions?.controls?.forEach((position) => {
      const positionValues = position?.get('positionValues') as FormArray;
      positionValues?.clear();
    });
  }

  public selectCanvasTemplate(id: number): void {
    if (this.form?.touched && !this.formSubmitted) {
      this.dialogService
        .open<boolean>(new PolymorpheusComponent(ConfimLeavePageDialogComponent, this.injector), {
          closeable: true,
          dismissible: false,
          size: 's',
          label: this.t.instant('Navigation.Guards.Leaving'),
        })
        .pipe(
          take(1),
          filter((confirmed: boolean) => confirmed),
          switchMap(() => this.getSelectedCanvasTemplate(id)),
          catchError((_err) => {
            this.snackbar.error(_err, true);
            return of(null);
          }),
        )
        .subscribe();
    } else {
      this.formSubmitted = false;
      this.selectedCanvasInstance = null;
      this.sub.add(this.getSelectedCanvasTemplate(id).subscribe());
    }
  }

  public submitForm(): void {
    this.form.markAsPristine();

    const values: CanvasInstancePositionValueInput[] = [];

    this.form.getRawValue().positions.forEach((p: Position) => {
      p.positionValues.forEach(
        (v: CanvasPositionValueOutput) => v.value && values.push({ positionTemplateId: p.id, value: v.value }),
      );
    });

    const input: CanvasCreateInstanceInput = {
      positionValue: values,
      templateId: this.selectedCanvas!.id,
      funnelId: this.funnelId,
    };

    this.sub.add(
      this.canvasService
        .createCanvasInstance(input)
        .pipe(
          tap((res) => {
            this.snackbar.success(this.t.instant('saveSuccess'));
            this.selectedCanvasInstance = res.data?.createCanvasInstance as Canvas;
          }),
          catchError((_err) => {
            this.snackbar.error(_err, true);
            return of(null);
          }),
        )
        .subscribe(() => (this.formSubmitted = true)),
    );
  }

  public close(): void {
    this.router.navigate(['../manage'], { relativeTo: this.route });
  }

  private getSelectedCanvasTemplate(id: number): Observable<CanvasAnswerSuggestionsOutput> {
    return this.canvasService.getCanvasTemplate(id).pipe(
      take(1),
      tap((template) => (this.selectedCanvas = template as Canvas)),
      switchMap(() => this.getCanvasInstance()),
      tap(() => this.initCanvasForm()),
      tap(() => this.changes.markForCheck()),
    );
  }

  private assignExistingValues(position: CanvasPositionOutput): FormGroup[] {
    const existingValues: CanvasInstancePositionValueOutput[] = this.selectedCanvasInstance.positionValues.filter(
      (positionValue: CanvasInstancePositionValueOutput) => positionValue.position.id === position.id,
    );

    const values: FormGroup[] =
      existingValues?.map((value: CanvasInstancePositionValueOutput) =>
        this.formBuilder.group({ value: [value.value] }),
      ) ?? [];

    return values;
  }

  private getDefaultCanvas() {
    const canvasNameFromRoute = this.route.snapshot.queryParams['canvas'];
    let defaultCanvasName = 'Lean Canvas';

    if (canvasNameFromRoute) {
      defaultCanvasName = canvasNameFromRoute;
    }

    const id = this.allCanvas.find((c: Canvas) => c.name === defaultCanvasName)?.id;
    if (id) this.selectCanvasTemplate(id);
  }

  private assignSuggestions() {
    const suggestions: any = this.canvasSuggestions?.suggestions;

    for (const position of this.positions.controls) {
      if (suggestions && suggestions[position?.value?.label?.toLowerCase()]) {
        const pv = (position as FormGroup).controls['positionValues'] as FormArray;

        if (Array.isArray(suggestions[position.value.label.toLowerCase()])) {
          suggestions[position.value.label.toLowerCase()].forEach((element) => {
            console.log(element);
            pv.push(
              new FormGroup({
                value: new FormControl(element),
              }),
            );
          });
        } else {
          pv.push(
            new FormGroup({
              value: new FormControl(suggestions[position.value.label.toLowerCase()]),
            }),
          );
        }
      }
    }

    this.changes.detectChanges();
  }

  private pascalCaseToSpaceSeparated(input: string): string {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  private snakeCamelToSpaceSeparated(input: string): string {
    const words = input
      .split(/_|(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return words;
  }

  private isSnakeCaseOrCamelCase(input: string): boolean {
    return /^[a-zA-Z0-9_]+$/.test(input);
  }

  private isPascalCase(input: string): boolean {
    return /^[A-Z][a-zA-Z]*$/.test(input);
  }

  private initCanvasForm(): void {
    this.form = this.formBuilder.group({
      id: this.selectedCanvas!.id,
      positions: this.formBuilder.array([]),
    });

    this.selectedCanvas?.positions?.forEach((position: CanvasPositionOutput) => {
      let positionValues: FormGroup[] = [];

      if (this.selectedCanvasInstance && !this.navBusinessData) {
        positionValues = this.assignExistingValues(position);
      }

      this.positions.push(
        this.formBuilder.group({
          id: [position.id],
          description: [position.description, Validators.required],
          label: [position.label, Validators.required],
          positionValues: this.formBuilder.array(positionValues),
        }),
      );
    });
  }

  private getCanvasTemplates(): void {
    this.sub.add(
      this.canvasService
        .getCanvasTemplates()
        .pipe(
          map((canvas: any[]) => canvas.filter((c: Canvas) => c.status === CanvasTemplateStatus.Active)),
          tap((canvas: Canvas[]) => {
            this.allCanvas = canvas;
            this.categories = this.groupByCategory(canvas);
            this.displayedCategories = JSON.parse(JSON.stringify(this.categories));
            this.displayedCategories.forEach((c: DisplayedCanvasCategory) => (c.display = true));
            this.getDefaultCanvas();
          }),
          tap((_canvas: Canvas[]) => this.changes.markForCheck()),
          catchError((_err) => {
            this.snackbar.error(_err, true);
            return of(null);
          }),
        )
        .subscribe(),
    );
  }

  private handleSearch(): void {
    this.sub.add(
      this.searchForm
        .get('search')
        ?.valueChanges.pipe(
          tap((val: string) => {
            this.displayedCategories.forEach((cat: DisplayedCanvasCategory) => {
              cat.canvases = this.categories
                .find((ca: CanvasCategory) => ca.id === cat.id)!
                .canvases.filter((c: Canvas) => c.name?.toLowerCase()?.includes(val.toLowerCase()));
              cat.display = Boolean(cat.canvases.length);
            });
          }),
        )
        .subscribe(),
    );
  }

  private groupByCategory(canvas: Canvas[]): CanvasCategory[] {
    const result: CanvasCategory[] = [];
    canvas.forEach((c: Canvas) => {
      if (!c.category) {
        return;
      }

      const existing = result.find((cat: CanvasCategory) => cat.id === c.category?.id);
      existing ? existing.canvases.push(c) : result.push({ ...c.category, canvases: [c] });
    });

    return result;
  }

  getSuggestionsIfCompanyDataSet(canvasTemplateId: number) {
    if (!this.companyDataExist) {
      return this.openCompanyDataDialog(this.getSuggestions.bind(this), canvasTemplateId);
    }

    this.sendMixpanelEvent('generate_with_AI');
    this.getSuggestions(canvasTemplateId);
  }

  sendMixpanelEvent(buttonId: string) {
    this.mixpanelService.trackEvent(MixpanelEventName.ButtonClick, {
      time: Date.now(),
      URL: location.href,
      button_name: buttonId,
      canvas_name: this.selectedCanvas?.name ?? 'No Canvas Selected',
    });
  }

  private getSuggestions(canvasTemplateId: number) {
    this.companyDataExist = true;
    this.loading$.next(true);

    return this.canvasService.getCanvasSuggestions(canvasTemplateId).subscribe((res) => {
      if (!res) {
        this.s.error('AI couldn’t fill a canvas. Please try again.');
        this.loading$.next(false);
        return;
      }

      if (res.suggestions && Object.keys(res.suggestions).length > 1) {
        this.fixSuggestionsFormat(res.suggestions);
      } else {
        res.suggestions = null;
      }

      this.form.markAllAsTouched();
      this.canvasSuggestions = res ?? null;
      this.assignSuggestions();
      this.loading$.next(false);
    });
  }

  private openCompanyDataDialog(callback: (canvasTemplateId: number) => Subscription, canvasTemplateId: number): void {
    this.dialogService
      .open(new PolymorpheusComponent(BusinessDataComponent, this.injector), {
        size: 'page',
        closeable: true,
        dismissible: true,
      })
      .subscribe({
        next: () => {
          this.companyDataExist = true;
          callback(canvasTemplateId);
        },
      });
  }

  private getFunnelId() {
    this.route.parent?.params.subscribe((params) => (this.funnelId = +params['id']));
  }

  private fixSuggestionsFormat(suggestions: Record<string, any>): void {
    Object.keys(suggestions).forEach((key) => {
      const value = suggestions[key];
      let spaceCaseKey = key;

      if (this.isPascalCase(key)) {
        spaceCaseKey = this.pascalCaseToSpaceSeparated(key);
        suggestions[spaceCaseKey.toLowerCase()] = suggestions[key];
      }
      if (this.isSnakeCaseOrCamelCase(key)) {
        spaceCaseKey = this.snakeCamelToSpaceSeparated(key);
        suggestions[spaceCaseKey.toLowerCase()] = suggestions[key];
      }
      if (typeof value === 'object' && !Array.isArray(value) && !(value instanceof String)) {
        suggestions[spaceCaseKey.toLowerCase()] = '';
      }
    });
  }

  private getCanvasInstance(): Observable<any> {
    return this.canvasService.getCanvasInstanceByFunnelTemplate(this.funnelId, this.selectedCanvas!.id).pipe(
      tap((instance) => (this.selectedCanvasInstance = instance)),
      catchError((_err) => {
        this.selectedCanvasInstance = null;
        return of(null);
      }),
    );
  }
}
