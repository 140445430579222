import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Funnel } from '@shared/models/funnel.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Regex } from '@shared/configs/regex';
import { FetchResult } from '@apollo/client/core';
import { ShareFunnelMutation } from '@modules/funnels/shared/graphql/mutations/share-funnel.mutation.generated';
import { FunnelPubliclyInputGraphql, FunnelShareOutputGraphql } from '@modules/graphql/graphql-types';
import { FunnelSettingsService } from '@modules/funnels/modules/funnel-settings/shared/services/funnel-settings.service';
import { TUI_ARROW } from '@taiga-ui/kit';
import { environment } from '@environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-funnel-settings-share',
  templateUrl: './funnel-settings-share.component.html',
  styleUrls: ['./funnel-settings-share.component.scss'],
})
export class FunnelSettingsShareComponent implements OnInit {
  APP_URL = environment.APP_URL;

  loading = false;
  invitedAgainSharesId: number[] = [];

  form: UntypedFormGroup = new UntypedFormGroup({
    emails: new UntypedFormControl([]),
  });

  shareForm: UntypedFormGroup = new UntypedFormGroup({
    isPublic: new UntypedFormControl(false),
  });

  showPublicLink = false;

  readonly arrow = TUI_ARROW;

  singleEmail: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  get emails(): string[] {
    return this.form.get('emails')?.value || [];
  }

  constructor(
    private funnelGraphqlService: FunnelGraphqlService,
    private s: SnackbarService,
    private t: TranslateService,
    private clipboardService: ClipboardService,
    private changes: ChangeDetectorRef,
    public funnelSettingsService: FunnelSettingsService,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.setFunnelIsPublic(), 300);
  }

  getFunnel() {
    return this.funnelSettingsService.funnel ?? this.userService.User?.contextFunnel!;
  }

  setFunnelIsPublic() {
    this.shareForm.get('isPublic')?.setValue(!!this.getFunnel()?.isPublic);
    this.showPublicLink = !!this.getFunnel()?.isPublic;
  }

  onPublicLinkCopy(link: string) {
    this.clipboardService.copy(link);
    this.s.success(this.t.instant('Funnels.Link copied'));
  }

  onChangeFunnelPublic() {
    const input: FunnelPubliclyInputGraphql = {
      funnelId: this.getFunnel().id,
      isPublic: this.shareForm.get('isPublic')?.value,
    };

    this.funnelGraphqlService.setPublicly(input).subscribe();
    this.showPublicLink = this.shareForm.get('isPublic')?.value;
  }

  addEmail() {
    this.singleEmail.markAsTouched();
    const emailsToAd: string[] = this.singleEmail?.value.split(',').map((e) => e.trim().toLowerCase()) || [];
    let emails: string[] = this.emails;
    if (!this.isValidEmails(emailsToAd)) return;
    if (!this.isAlreadyAddedEmail(emailsToAd)) {
      emails = emailsToAd.concat(emails);
      this.form.get('emails')?.setValue(emails);
      this.loading = true;
      this.changes.detectChanges();
      this.sendShare();
      this.form.reset();
    } else {
      this.s.error(this.t.instant('Funnels.Manage.Funnel is already shared for this e-mail'));
    }
    this.singleEmail.reset();
    this.changes.detectChanges();
  }

  isValidEmails(emailsToAd) {
    const notValidEmails: string[] = [];
    emailsToAd.map((email: string) => {
      if (!Regex.email.test(email)) {
        notValidEmails.push(email);
      }
    });
    if (notValidEmails.length) {
      this.s.error(
        this.t.instant('Funnels.Manage.not valid email', {
          emails: notValidEmails.join(', '),
        }),
      );
      return false;
    }
    return true;
  }

  sendShare() {
    this.funnelGraphqlService
      .shareFunnel(this.getFunnel().id, this.form.get('emails')?.value)
      .subscribe({
        next: (res: FetchResult<ShareFunnelMutation>) => {
          this.funnelSettingsService.funnel = new Funnel(res.data?.shareFunnel);
          this.s.success(this.t.instant('Funnels.Manage.Funnel shared successfully'));
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  isAlreadyAddedEmail(emailToAd: string[]): boolean {
    let isAlready = false;
    this.funnelSettingsService.funnel?.shares.map((share: FunnelShareOutputGraphql) => {
      emailToAd.map((emailToAd: string) => {
        if (share.email === emailToAd) {
          isAlready = true;
        }
      });
    });
    return isAlready;
  }

  onNextInvite(share: FunnelShareOutputGraphql) {
    this.form.get('emails')?.setValue([share.email]);
    this.invitedAgainSharesId.push(share.id);
    this.sendShare();
    this.s.success(this.t.instant('Funnels.Manage.Funnel shared again successfully'));
    this.form.reset();
  }

  onRemoveInvite(share: FunnelShareOutputGraphql) {
    this.funnelSettingsService.funnel!.shares = this.funnelSettingsService.funnel!.shares.filter(
      (s) => s.id !== share.id,
    );
    this.changes.detectChanges();
    this.funnelGraphqlService.removeShareFunnel(share.id).toPromise();
  }
}
