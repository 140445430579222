import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'df-business-data-error',
  templateUrl: './business-data-error.component.html',
  styleUrl: './business-data-error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessDataErrorComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<any, any>,
  ) {}

  get errors(): any {
    return this.context.data.errors;
  }
}
