<div class="filters-container flex flex-col w-full relative">
  <button
    tuiIconButton
    type="button"
    size="xs"
    appearance="flat"
    icon="tuiIconChevronUp"
    class="hidden sm:block tui-primary absolute top-2 right-2"
    [ngClass]="{ 'rotate-180': !filtersDesktopVisible }"
    (click)="toggleFilters()"
  ></button>

  <div class="flex justify-center sm:justify-start">
    <button class="tui-text-03 tui-text_menu-header">
      {{ 'Filters.FILTERS' | translate }}
    </button>

    <button
      class="ml-4 tui-text-04 tui-text_menu-header"
      (click)="clearFilters()"
    >
      {{ 'Filters.CLEAR ALL' | translate }}
    </button>
  </div>

  <div
    *ngIf="filtersDesktopVisible"
    class="filters-list w-full h-full relative"
  >
    <div class="block flex-shrink-0">
      <ng-container *ngTemplateOutlet="filters"></ng-container>
    </div>

    <ng-template #filters>
      <form
        [formGroup]="contentLibraryService.form"
        class="filters-form flex flex-col items-stretch md:flex-row md:flex-wrap gap-3 mt-5"
      >
        <tui-input
          icon="tuiIconSearchLarge"
          iconAlign="left"
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
          [formControlName]="FormField.SEARCH"
          class="search md:flex-grow"
        >
          {{ 'Tactics.List.Search by key words' | translate }}

          <input tuiTextfield />
        </tui-input>

        <tui-multi-select
          class="filters-form__category"
          [formControlName]="FormField.CATEGORIES"
          [editable]="false"
          [valueContent]="selectedInArray(FormField.CATEGORIES)"
          [tuiTextfieldCleaner]="true"
        >
          {{ 'category' | translate }}
          <tui-data-list-wrapper
            *tuiDataList
            tuiMultiSelectGroup
            [items]="categories"
            [itemContent]="categoryContent"
          >
          </tui-data-list-wrapper>
        </tui-multi-select>

        <ng-template
          #categoryContent
          let-category
        >
          <div class="flex items-center justify-start">
            <span>{{ 'Funnels.Content generator.Category.' + category.name | translate }}</span>
          </div>
        </ng-template>
      </form>
    </ng-template>
  </div>
</div>
