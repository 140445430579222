import { gql } from 'apollo-angular';
import { FullTacticFragment } from '@modules/tactics/shared/graphql/fragments/full-tactic.fragment';

export const SetTacticAsPayedDocument = gql/* GraphQL*/ `
  mutation setTacticAsPayed($input: PayedTacticInputGraphql!) {
    setTacticAsPayed(input: $input) {
      ...FullTactic
    }
  }
  ${FullTacticFragment}
`;
