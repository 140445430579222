import { Component, EventEmitter, input } from '@angular/core';
import { Email } from '../tactic-columns/tactic-columns.component';
import { TacticStepIconComponent } from '../tactic-step-icon/tactic-step-icon.component';

@Component({
  selector: 'df-tactic-newsletter',
  standalone: true,
  imports: [TacticStepIconComponent],
  templateUrl: './tactic-newsletter.component.html',
  styleUrl: './tactic-newsletter.component.css',
})
export class TacticNewsletterComponent {
  public item = input.required<Email>();
  public regenerateClick = new EventEmitter<void>();
  public isRegenerating = input.required<boolean>();
}
