import { Resolve } from '@angular/router';
import { Step } from '@shared/models/step.model';
import { FunnelStepsService } from '@shared/services/funnel-steps.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';

@Injectable()
export class FunnelModuleResolver implements Resolve<Step[]> {
  constructor(
    private funnelStepsService: FunnelStepsService,
    private t: TranslateService,
    private s: SnackbarService,
    private n: NavigateService,
  ) {}

  async resolve() {
    const steps = await this.funnelStepsService
      .getSteps()
      .toPromise()
      .catch((e) => {
        this.s.error(this.t.instant('Funnels.Manage.There is problem with getting funnels steps. Try again.'));
        this.n.go(Config.MAIN_VIEW);
        throw e;
      });

    return steps ? steps : [];
  }
}
