import { Component } from '@angular/core';
import { Config } from '@shared/configs/config';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
})
export class Page404Component {
  Config = Config;

  constructor(public n: NavigateService) {}
}
