import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

export const copyToClipboard = (text: string, s: SnackbarService, t: TranslateService) => {
  const elem = document.createElement('textarea');
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand('copy');
  document.body.removeChild(elem);
  s.success(t.instant('Copied!'));
};
