import { SearchSimpleFunnelsDocument } from '@modules/funnels/shared/graphql/queries/search-simple-funnels.query';
import { SearchFunnelsQuery } from '@modules/funnels/shared/graphql/queries/search-funnels.query.generated';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { NavigationEnd, Router } from '@angular/router';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { FunnelAddToProfileModalComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-add-to-profile-modal/funnel-add-to-profile-modal.component';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { FunnelPermissionEnum, PermissionType, FunnelListTypeEnum } from '@modules/graphql/graphql-types';
import { UserService } from '@shared/services/user.service';
import { KompassifyService, KompassifyTourUuid } from '@shared/services/kompassify.service';
import { FunnelTab } from '@modules/funnels/shared/enums/funnel-tab.enum';
import { filter, map } from 'rxjs/operators';
import { FunnelConfirmRemoveModalComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-confirm-remove-modal/funnel-confirm-remove-modal.component';
import { TuiContextWithImplicit, TuiIdentityMatcher, TuiStringHandler, TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { TUI_ARROW } from '@taiga-ui/kit';
import { Apollo } from 'apollo-angular';
import { SnackbarService } from '@core/services/snackbar.service';
import { FetchResult } from '@apollo/client/core';
import { Funnel } from '@shared/models/funnel.model';

@Component({
  selector: 'df-funnel-manage-header',
  templateUrl: './funnel-manage-header.component.html',
  styleUrls: ['./funnel-manage-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageHeaderComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  FunnelPermissionEnum = FunnelPermissionEnum;
  FunnelListTypeEnum = FunnelListTypeEnum;
  sub: Subscription = new Subscription();
  currentTab: FunnelTab | null = null;
  helpStep = 0;
  readonly FunnelTab = FunnelTab;
  readonly detailsMode$: Observable<boolean>;
  // dropdown funnel switch
  open = false;
  readonly arrow = TUI_ARROW;
  readonly stringify: TuiStringHandler<
    { id: number; name: string } | TuiContextWithImplicit<{ id: number; name: string }>
  > = (item) => ('name' in item ? item.name : item.$implicit.name);
  funnelsList: Funnel[] = [];
  funnelVal: Funnel;

  readonly identityMatcher: TuiIdentityMatcher<Funnel> = (items1, items2) => {
    return items1.id === items2.id;
  };

  @ViewChild('dropdownComponent')
  dropdownComponent!: TuiHostedDropdownComponent;
  @ViewChild('buttonFunnelSwitch') buttonFunnelSwitch!: ElementRef<HTMLElement>;

  constructor(
    private t: TranslateService,
    public n: NavigateService,
    public funnelManageService: FunnelManageService,
    public funnelFlowManageService: FunnelFlowManageService,
    private changes: ChangeDetectorRef,
    private pricingService: PricingService,
    public userService: UserService,
    public kompassifyService: KompassifyService,
    private router: Router,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(TUI_IS_MOBILE) readonly isMobile: boolean,
    private readonly _apollo: Apollo,
    private s: SnackbarService,
  ) {
    this.funnelVal = this.userService.User?.contextFunnel!;
    this.detailsMode$ = this.funnelManageService.detailsMode$;
  }

  ngOnInit(): void {
    this.funnelManageService.funnel$.subscribe((funnel) => this.setFunnel(funnel));
    this.getFunnels();
    this.listenUpdates();
    this.listenTabChange();
    this.listenUrl();
  }

  private setFunnel(funnel: Funnel): void {
    this.funnelVal = funnel;
    this.changes.detectChanges();
  }

  listenUpdates() {
    const sub = combineLatest([this.funnelFlowManageService.editMode$, this.funnelManageService.funnel$]).subscribe(
      () => {
        this.changes.detectChanges();
      },
    );
    this.sub.add(sub);
  }

  listenTabChange(): void {
    this.sub.add(
      this.funnelManageService.currentTab$.subscribe((currentTab: FunnelTab | null) => {
        this.currentTab = currentTab;
        this.kompassifyService.unlockKompassify();
        this.changes.detectChanges();
      }),
    );
  }

  listenUrl(): void {
    this._setHelpStep(this.router.url);
    this.sub.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map((event) => (event as NavigationEnd).url),
        )
        .subscribe(this._setHelpStep.bind(this)),
    );
  }

  private _setHelpStep(url: string): void {
    const path = url.split('/').reverse()[0];
    this.helpStep = path === '1' || path === '2' || path === '3' ? Number(path) - 1 : 0;
    this.changes.detectChanges();
  }

  runStatementTutorial() {
    this.kompassifyService.unlockKompassify();
    this.kompassifyService.runTutorial(KompassifyTourUuid.FUNNEL_STATEMENT, this.helpStep);
    this.kompassifyService.listenBlockKompassify(this.helpStep + 1);
  }

  goToContentLibrary(): void {
    this.pricingService
      .checkPricing(PermissionType.FunnelManagement, this.userService.User!.funnelsCount)
      .subscribe(() =>
        this.n.go('/funnels/f/d/:id/content-generator-library', {
          id: this.funnelManageService.funnel?.id,
        }),
      );
  }

  goToManage(): void {
    this.pricingService
      .checkPricing(PermissionType.FunnelManagement, this.userService.User!.funnelsCount)
      .subscribe(() =>
        this.n.go('funnels/f/d/:id/manage', {
          id: this.funnelManageService.funnel?.id,
        }),
      );
  }

  share() {
    this.pricingService.checkPricing(PermissionType.FunnelAndTacticShare).subscribe((_) => {
      this.n.go('funnels/settings/:id', {
        id: this.funnelManageService.funnel?.id,
      });
    });
  }

  edit() {
    this.n.go('funnels/settings/:id', {
      id: this.funnelManageService.funnel?.id,
    });
  }

  addToMyProfile(): void {
    this.dialogService
      .open<number>(new PolymorpheusComponent(FunnelAddToProfileModalComponent, this.injector), {
        size: 'm',
        dismissible: true,
        closeable: true,
        data: {
          funnel: this.funnelManageService.funnel,
        },
        label: this.t.instant('Funnels.Manage.Add funnel to profile'),
      })
      .subscribe();
  }

  exportToPDF(): void {
    this.funnelManageService.exportToPDF();
  }

  toggleActivities(): void {
    this.funnelManageService.expandAll.setValue(!this.funnelManageService.expandAll.value);
    this.dropdownComponent.open = false;
  }

  toggleSummary(): void {
    this.funnelManageService.expandSummary.setValue(!this.funnelManageService.expandSummary.value);
    this.dropdownComponent.open = false;
  }

  delete(): void {
    this.dialogService
      .open<boolean>(new PolymorpheusComponent(FunnelConfirmRemoveModalComponent, this.injector), {
        size: 's',
        dismissible: true,
        closeable: true,
        label: this.t.instant('Funnels.List.Removing funnel'),
      })
      .pipe(filter((res) => res))
      .subscribe(() => this.n.go('funnels/list/all'));
  }

  getFunnels(): void {
    this._apollo
      .query<SearchFunnelsQuery>({
        query: SearchSimpleFunnelsDocument,
        variables: {
          query: {
            page: 1,
            records: 100,
            type: FunnelListTypeEnum.OnlyMy,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe({
        next: (res: FetchResult<SearchFunnelsQuery>) => {
          this.funnelsList = res?.data?.searchFunnels?.records?.map((record) => new Funnel(record)) || [];
        },
        error: () => {
          this.s.error(this.t.instant('There is problem with getting your funnels. Refresh your view and try again.'));
        },
      });
  }

  setContextFunnel(funnel: Funnel) {
    const oldFunnel = this.userService.User?.contextFunnel;
    this.userService.setContextFunnel(funnel);
    this.router.navigateByUrl(this.router.url.replace(oldFunnel?.id.toString() || '', funnel.id.toString()));
  }

  ngOnDestroy() {
    this.kompassifyService.unlockKompassify();
    this.sub.unsubscribe();
  }
}
