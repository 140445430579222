import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EStep1Form } from '@modules/statement/shared/models/step1-form.enum';
import { TuiContextWithImplicit, TuiIdentityMatcher, TuiStringHandler } from '@taiga-ui/cdk';
import { NavigateService } from '@core/routes/services/navigate.service';
import { UserService } from '@shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StatementFirstInputGraphql } from '@modules/graphql/graphql-types';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { StatementPublicSaveModalComponent } from '@modules/statement/shared/components/statement-public-save-modal/statement-public-save-modal.component';
import { cloneDeep } from '@apollo/client/utilities';
import { Model } from '@shared/models/model.model';
import { XPartnerService } from '@shared/services/x-partner.service';
import { StatementParametersService } from '@modules/statement/shared/services/statement-parameters.service';

@Component({
  selector: 'df-statement-public',
  templateUrl: './statement-public.component.html',
  styleUrls: ['./statement-public.component.scss', '../../shared/scss/statement.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StatementParametersService],
})
export class StatementPublicComponent implements OnInit, OnDestroy {
  EStep1Form = EStep1Form;
  loading = false;
  sub: Subscription = new Subscription();
  form: UntypedFormGroup;
  hideFooter = false;

  constructor(
    public n: NavigateService,
    private t: TranslateService,
    public statementParametersService: StatementParametersService,
    private userService: UserService,
    private changes: ChangeDetectorRef,
    public statementService: StatementService,
    private dialogService: TuiDialogService,
    private injector: Injector,
    public xPartnerService: XPartnerService,
  ) {
    this.form = this.initializeForm();
  }

  readonly stringify: TuiStringHandler<
    { id: number; name: string } | TuiContextWithImplicit<{ id: number; name: string }>
  > = (item) => ('name' in item ? item.name : item.$implicit.name);

  readonly identityMatcher: TuiIdentityMatcher<{ id: number; name: string }> = (obj1, obj2) => obj1.id === obj2.id;

  ngOnInit(): void {
    this.setStatementData();

    this.listenEndLoadingParameters();
    this.listenChangeTargetType();
  }

  private initializeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      [EStep1Form.businessTypeId]: new UntypedFormControl(),
      [EStep1Form.productName]: new UntypedFormControl(''),
      [EStep1Form.targetGroupsTypesIds]: new UntypedFormControl(),
      [EStep1Form.valuePropositionsIds]: new UntypedFormControl(),
      [EStep1Form.customersPainsIds]: new UntypedFormControl(),
      [EStep1Form.problemsIds]: new UntypedFormControl(),
      [EStep1Form.segmentsB2bIds]: new UntypedFormControl(),
      [EStep1Form.segmentsB2cIds]: new UntypedFormControl(),
    });
  }

  private setStatementData(): void {
    this.statementService.isPublic = true;
    this.statementService.stepNr.next(1);
  }

  private listenEndLoadingParameters(): void {
    const sub = this.statementParametersService.onParametersLoaded.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  private listenChangeTargetType(): void {
    const sub = this.form.get(EStep1Form.targetGroupsTypesIds)?.valueChanges.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  isB2C(): boolean {
    const value: Model[] = this.form.get(EStep1Form.targetGroupsTypesIds)?.value;
    return !!(value?.length && value[0].isB2c);
  }

  isB2B(): boolean {
    const value: Model[] = this.form.get(EStep1Form.targetGroupsTypesIds)?.value;
    return !!(value?.length && value[0].isB2b);
  }

  public openPublicSaveStatement(): void {
    this.hideFooter = true;
    this.statementService.publicStatementForm = this.getObject();
    this.dialogService
      .open<StatementPublicSaveModalComponent>(
        new PolymorpheusComponent(StatementPublicSaveModalComponent, this.injector),
        {
          dismissible: true,
          closeable: true,
          size: 's',
          data: {
            textToCopy: this.createStatementTextToCopy(),
          },
        },
      )
      .subscribe({
        complete: () => {
          this.hideFooter = false;
          this.changes.detectChanges();
        },
      });
  }

  private createStatementTextToCopy(): string {
    let text = this.t.instant('Statement.Our');
    text += ` ${this.getSelectValueForTextToCopy(EStep1Form.businessTypeId)}`;
    text += ` ${this.form.get(EStep1Form.productName)?.value}`;
    text += ` ${this.t.instant('Statement.helps')}`;
    if (this.isB2B() || this.isB2C()) {
      text += ` ${this.getSelectValueForTextToCopy(EStep1Form.targetGroupsTypesIds)}`;
      text += ` ${this.getSelectValueForTextToCopy(this.isB2B() ? EStep1Form.segmentsB2bIds : EStep1Form.segmentsB2cIds)}.`;
    }
    text += ` ${this.t.instant('Statement.It’s most important advantages are')}`;
    text += ` ${this.getSelectValueForTextToCopy(EStep1Form.valuePropositionsIds)},`;
    text += ` ${this.t.instant('Statement.but people might be afraid of')}`;
    text += ` ${this.getSelectValueForTextToCopy(EStep1Form.customersPainsIds)}.`;
    text += ` ${this.t.instant('Statement.to help me with')}`;
    text += ` ${this.getSelectValueForTextToCopy(EStep1Form.problemsIds)}.`;

    return text;
  }

  private getSelectValueForTextToCopy(formControlName: string): string {
    const val: [] = this.form.get(formControlName)?.value || [];
    if (val.length) {
      return val.map((obj) => obj['name']).join(', ');
    } else {
      return ' ';
    }
  }

  private getObject(): StatementFirstInputGraphql {
    const form = cloneDeep(this.form.value);
    Object.keys(EStep1Form).map((key) => {
      if (Array.isArray(form[key])) {
        form[key] = form[key].map((i) => i?.id) || [];
      } else {
        if (key !== EStep1Form.productName && key !== EStep1Form.businessTypeId) {
          form[key] = [];
        }
      }
    });
    form[EStep1Form.businessTypeId]?.length
      ? (form[EStep1Form.businessTypeId] = form[EStep1Form.businessTypeId][0])
      : (form[EStep1Form.businessTypeId] = null);
    form[EStep1Form.funnelId] = this.userService.User?.contextFunnel?.id;
    return form as StatementFirstInputGraphql;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
