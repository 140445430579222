import { gql } from 'apollo-angular';

export const RateTacticDocument = gql(/* GraphQL*/ `mutation rateTactic($input: TacticRateInputGraphql!) {
  rateTactic (input: $input) {
    rate
    tactic
    {
      id
      rate
      myRate
      {
        rate
        id
      }
    }
  }
}
`);
