import { IFilter } from '@shared/interfaces/filter.interface';
import { Apollo } from 'apollo-angular';
import { FetchResult } from '@apollo/client/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Model } from '@shared/models/model.model';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ChannelOwned } from '@shared/models/channel-owned.model';
import { FilterStatementsParametersQuery } from '../graphql/queries/filter-statements-parameters.query.generated';
import { FilterStatementsParametersDocument } from '../graphql/queries/filter-statements-parameters.query';

@Injectable()
export class StatementParametersService {
  businessType: IFilter<any>[] = [];
  segmentB2c: IFilter<any>[] = [];
  segmentB2b: IFilter<any>[] = [];
  valueProposition: IFilter<any>[] = [];
  customerSPain: IFilter<any>[] = [];
  problem: IFilter<any>[] = [];
  model: Model[] = [];
  channelsAlreadyOwned: ChannelOwned[] = [];
  purchaseMethod: IFilter<any>[] = [];
  paymentFrequency: IFilter<any>[] = [];
  revenueCharacteristics: IFilter<any>[] = [];

  onParametersLoaded: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private apollo: Apollo,
    private s: SnackbarService,
  ) {
    this.getParameters();
  }

  getParameters() {
    const parametersCalls: any[] = [];
    Object.keys(StatementsParametersTypesEnum).map((key: string) => {
      if (this[key]) {
        parametersCalls.push(
          this.apollo
            .query<FilterStatementsParametersQuery>({
              query: FilterStatementsParametersDocument,
              variables: {
                category: key,
                input: { records: 200 },
              },
            })
            .pipe(
              map((value: FetchResult<FilterStatementsParametersQuery>) => {
                return {
                  key,
                  value: value.data?.filterStatementsParameters.records,
                };
              }),
            ),
        );
      }
    });

    forkJoin(parametersCalls).subscribe({
      next: (res: any[]) => {
        res.map((obj) => {
          if (
            obj.key === StatementsParametersTypesEnum.model ||
            obj.key === StatementsParametersTypesEnum.channelsAlreadyOwned
          ) {
            this[obj.key] = obj.value.map((o) => o.object);
          } else {
            this[obj.key] = obj.value;
          }
        });
        this.onParametersLoaded.emit(true);
      },
    });
  }
}

export enum StatementsParametersTypesEnum {
  model = 'model',
  businessType = 'businessType',
  budget = 'budget',
  community = 'community',
  problem = 'problem',
  valueProposition = 'valueProposition',
  segmentB2c = 'segmentB2c',
  segmentB2b = 'segmentB2b',
  customerSPain = 'customerSPain',
  channelsAlreadyOwned = 'channelsAlreadyOwned',
  purchaseMethod = 'purchaseMethod',
  paymentFrequency = 'paymentFrequency',
  revenueCharacteristics = 'revenueCharacteristics',
}
