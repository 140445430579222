import { gql } from 'apollo-angular';

export const AddCanvasSectionDocument = gql`
  mutation addCanvasFormSection($input: CanvasSectionInput!) {
    addCanvasFormSection(input: $input) {
      id
      name
      description
      img
      hierarchyPosition
    }
  }
`;
