import { gql } from 'apollo-angular';
import { IntegrationFragment } from '@modules/funnels/shared/graphql/fragments/integration.fragment';

export const GetBannerbearIntegrationsDocument = gql`
  query getBannerbearIntegrations {
    getBannerbearIntegrations {
      ...Integration
    }
  }
  ${IntegrationFragment}
`;
