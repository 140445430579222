import { EventEmitter, Injectable } from '@angular/core';
import {
  FilterTypes,
  ListFiltersFieldInput,
  OrderTypes,
  TacticListInputGraphql,
  TacticListTypeEnum,
} from '@modules/graphql/graphql-types';
import { Tactic } from '@shared/models/tactic.model';
import { Observable } from 'rxjs';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Step } from '@shared/models/step.model';
import { ListSortItem } from '@shared/modules/ui/components/list-sub-header/list-sub-header.component';
import { BaseListService, ListPageDirection } from '@shared/services/base-list.service';
import { BaseList } from '@shared/interfaces/base-list.interface';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { SearchTacticsDocument } from '@modules/tactics/shared/graphql/queries/search-tactics.query';
import { FetchResult } from '@apollo/client/core';
import { SearchTacticsQuery } from '@modules/tactics/shared/graphql/queries/search-tactics.query.generated';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TacticsListService extends BaseListService<Tactic, TacticListInputGraphql> implements BaseList {
  static readonly defaultQuery = {
    page: 1,
    records: 30,
    search: '',
    sortDirection: OrderTypes.Asc,
    sortField: 'a.createdAt',
    filters: [],
    type: TacticListTypeEnum.All,
  };

  topBannerTactics: Tactic[] = [];
  tacticsListEmitter: EventEmitter<TacticsListEvent> = new EventEmitter<TacticsListEvent>();

  constructor(
    public tacticFiltersService: TacticsFiltersService,
    private tacticGraphqlService: TacticGraphqlService,
  ) {
    super(TacticsListService.defaultQuery, SearchTacticsDocument);
  }

  initService(noUpdateUrl?: boolean) {
    super.initService(noUpdateUrl);
    this.listenFiltersChange();
  }

  recordTransform(record): Tactic {
    return new Tactic(record);
  }

  getBannerTactics() {
    return this.tacticGraphqlService.getTopBannerTactics().subscribe({
      next: (res: FetchResult<SearchTacticsQuery>) => {
        this.topBannerTactics = res?.data?.searchTactics?.records?.map((t) => new Tactic(t)) || [];
      },
    });
  }

  getTactics(
    pageDirection: ListPageDirection = ListPageDirection.NONE,
    noUpdateUrl = false,
  ): Observable<Tactic[] | null> {
    return new Observable((observer) => {
      if (this.tacticFiltersService.filterFormGroup.get('type')?.value === TacticListTypeEnum.MyFunnel) {
        observer.next(null);
        observer.complete();
        return;
      } // separate query for my funnel for now

      this.getRecords(pageDirection, noUpdateUrl)
        .subscribe((res) => {
          observer.next(res);
        })
        .add(() => {
          observer.complete();
        });
    });
  }

  listenFiltersChange() {
    const sub = this.tacticFiltersService.filterFormGroup.valueChanges.pipe(debounceTime(400)).subscribe(() => {
      new Promise((resolve) => {
        this.setFirstPage();
        this.initNewSearch();
        resolve(true);
      }).then(() => {
        this.getRecords(ListPageDirection.NONE).toPromise();
      });
    });
    this.sub.add(sub);
  }

  prepareQuery() {
    this.query.sortField = this.tacticFiltersService.filterFormGroup.get('sort')?.value['sortField'];
    this.query.sortDirection = this.tacticFiltersService.filterFormGroup.get('sort')?.value['sortDirection'];
    this.query['type'] = this.tacticFiltersService.filterFormGroup.get('type')?.value as TacticListTypeEnum;
    this.query.search = this.tacticFiltersService.filterFormGroup.get('search')?.value;
    const steps = this.tacticFiltersService.filterFormGroup.get('step')?.value as IFilter<Step>[];
    const categories = this.tacticFiltersService.filterFormGroup.get('categories')?.value as IFilter<any>[];
    const isPaid = this.tacticFiltersService.filterFormGroup.get('isPaid')?.value;
    const purchased = this.tacticFiltersService.filterFormGroup.get('purchased')?.value;
    const pro = this.tacticFiltersService.filterFormGroup.get('pro')?.value as any[];
    const df = this.tacticFiltersService.filterFormGroup.get('df')?.value;
    this.query.filters = [
      {
        field: TacticListAvailableFilters.STEP,
        operator: FilterTypes.In,
        value: steps?.length ? steps.map((v) => v.id.toString()) : [],
      },
      {
        field: TacticListAvailableFilters.CATEGORY,
        operator: FilterTypes.In,
        value: categories?.length ? categories.map((v) => v.id.toString()) : [],
      },
    ];
    if (isPaid !== null) {
      this.query.filters.push({
        field: TacticListAvailableFilters.PAID,
        operator: FilterTypes.Eq,
        value: [isPaid.value ? '1' : '0'],
      });
    }
    if (purchased !== null) {
      this.query.filters.push({
        field: TacticListAvailableFilters.PURCHASED,
        operator: purchased.value,
        value: [],
      });
    }
    if (pro.length) {
      this.query.filters.push({
        field: TacticListAvailableFilters.PRO,
        operator: FilterTypes.Eq,
        value: pro.map((v) => (v.value ? '1' : '0')),
      });
    }
    if (df?.length) {
      this.query.filters.push({
        field: TacticListAvailableFilters.DF,
        operator: FilterTypes.Is,
        value: null,
      });
    }

    this.updateUrl();
  }

  transformQueryToFilters(params: TacticListInputGraphql) {
    this.tacticFiltersService.sorts.map((sort: ListSortItem) => {
      if (sort.sortField === params.sortField) {
        this.tacticFiltersService.filterFormGroup.get('sort')?.setValue(sort);
      }
    });
    this.tacticFiltersService.filterFormGroup.get('search')?.setValue(params.search);
    params.filters?.map((obj: ListFiltersFieldInput) => {
      switch (obj.field) {
        case TacticListAvailableFilters.STEP:
          this.tacticFiltersService.initStepsIds = obj.value?.map((i) => Number(i)) || [];
          break;
        case TacticListAvailableFilters.CATEGORY:
          this.tacticFiltersService.initCategoriesIds = obj.value?.map((i) => Number(i)) || [];
          break;
        case TacticListAvailableFilters.PAID:
          this.tacticFiltersService.initPaid = obj.value ? obj.value[0] === '1' : null;
          break;
        case TacticListAvailableFilters.PURCHASED:
          this.tacticFiltersService.initPurchased = obj.operator;
          break;
        case TacticListAvailableFilters.DF:
          this.tacticFiltersService.initDF = obj.value ? obj.value[0] === '1' : null;
          break;
        case TacticListAvailableFilters.PRO:
          this.tacticFiltersService.initPro = obj.value ? obj.value[0] === '1' : null;
          break;
      }
    });
  }
}

export class TacticsListEvent {
  type!: TacticsListEventType;
  tactic?: Tactic;
}

export enum TacticsListEventType {
  RELOAD_FUNNEL,
  CHOOSE_TO_COPY,
}

export enum TacticListAvailableFilters {
  CATEGORY = 'category.id',
  STEP = 'step.id',
  PAID = 'a.isPaid',
  PURCHASED = 'root.id',
  PRO = 'owner.isPro',
  DF = 'a.owner',
}
