import { gql } from 'apollo-angular';
import { IntegrationOutputDataFragment } from '@modules/funnels/shared/graphql/fragments/integration-output-data.fragment';

export const ContentGenerationOutputFragment = gql/* GraphQL*/ `
  fragment ContentGenerationOutput on ContentGenerationOutputGraphql {
    outputs {
      ...IntegrationOutputData
    }
    userCredits
  }
  ${IntegrationOutputDataFragment}
`;
