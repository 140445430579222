import { Injectable } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Injectable()
export class PermissionsDecoratorService {
  private static userService: UserService | undefined = undefined;
  public constructor(service: UserService) {
    PermissionsDecoratorService.userService = service;
  }
  public static getUserService(): UserService | null {
    if (!PermissionsDecoratorService.userService) {
      return null;
    }
    return PermissionsDecoratorService.userService;
  }
}
