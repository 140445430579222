import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FunnelsFilterService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-filter.service';
import { FunnelsListService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-list.service';
import { TuiIdentityMatcher } from '@taiga-ui/cdk';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Subscription } from 'rxjs';
import { ListEvent, ListEventType } from '@shared/services/base-list.service';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'df-funnels-list-projects',
  templateUrl: './funnels-list-projects.component.html',
  styleUrls: ['./funnels-list-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelsListProjectsComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();

  readonly identityMatcher: TuiIdentityMatcher<{ id: number; name: string }> = (obj1, obj2) => obj1.id === obj2.id;

  get projects(): IFilter<any>[] | undefined {
    return this.funnelsFiltersService.filterFormGroup.get('projects')?.value;
  }

  constructor(
    public funnelsFiltersService: FunnelsFilterService,
    public funnelsListService: FunnelsListService,
    private changes: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.listenReadUrlParams();
    this.listenRouteChange();
  }

  listenReadUrlParams() {
    const sub = this.funnelsListService.emitter.subscribe((event: ListEvent) => {
      if (event.type === ListEventType.READ_URL_PARAMS) {
        this.checkChosenProjects();
      }
    });
    this.sub.add(sub);
  }

  listenRouteChange() {
    const sub = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.checkChosenProjects();
    });
    this.sub.add(sub);
  }

  checkChosenProjects() {
    const chosen: IFilter<any>[] = [];
    this.funnelsFiltersService.projects?.map((project: IFilter<any>) => {
      this.funnelsFiltersService.initProjectsIds.indexOf(project.id) !== -1 ? chosen.push(project) : '';
    });
    this.funnelsFiltersService.initProjectsIds = [];
    this.funnelsFiltersService.filterFormGroup.get('projects')?.patchValue(chosen, { emitEvent: false });
    this.changes.detectChanges();
  }

  removeProject(id: number, e) {
    e.preventDefault();
    e.stopPropagation();
    let projects = this.projects;
    projects = projects?.filter((c) => c.id !== id);
    this.funnelsFiltersService.filterFormGroup.get('projects')?.setValue(projects);
    this.changes.detectChanges();
  }

  removeTag({ id }: { id: number }) {
    this.funnelsFiltersService.removeTag(id).subscribe(() => {
      this.funnelsFiltersService.projects = this.funnelsFiltersService.projects.filter((c) => c.id !== id);
      this.changes.detectChanges();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
