import { DoneActivityOutputGraphql, FunnelTacticOutputGraphql } from '@modules/graphql/graphql-types';
import { Funnel } from '@shared/models/funnel.model';
import { Tactic } from '@shared/models/tactic.model';
import { Step } from '@shared/models/step.model';
import { IntegrationOutputData } from '@shared/models/integration.model';

export class FunnelTactic implements FunnelTacticOutputGraphql {
  id!: number;
  name?: string | null;
  funnel!: Funnel;
  tactic!: Tactic;
  step!: Step;
  position!: number;
  isDone!: boolean;
  isHighlighted?: boolean;
  isActiveToConnect?: boolean;
  doneActivities!: DoneActivityOutputGraphql[];
  selectedOutputsData: IntegrationOutputData[] = [];
  onboardingActionTactic = false;

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
    data ? (this.tactic = new Tactic(data.tactic)) : '';
  }
}
