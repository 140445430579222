import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

import { Notification } from '@shared/models/notification.model';
import { NotificationTypeEnum } from '@modules/graphql/graphql-types';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';
import { TuiDialog } from '@taiga-ui/cdk';
import { TuiAlertOptions } from '@taiga-ui/core';

@Component({
  selector: 'df-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationModalComponent {
  readonly Config = Config;
  readonly NotificationTypeEnum = NotificationTypeEnum;
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialog<TuiAlertOptions<Notification>, boolean>,
    private readonly n: NavigateService,
  ) {}

  get notification(): Notification | undefined {
    return this.context.data;
  }

  action(type: NotificationTypeEnum): void {
    switch (type) {
      case NotificationTypeEnum.FillOutStripe:
        window.open(Config.MARKETPLACE_URL + `/account/payments`, '_blank');
        break;
      case NotificationTypeEnum.FillOutProfile:
        this.n.go('account');
        break;
      case NotificationTypeEnum.BookAdemo:
        window.open(Config.BOOK_A_DEMO_URL, '_blank');
        break;
    }
    this.context.completeWith(true);
  }
}
