<header class="mb-8 print:mt-4 print:break-before-page">
  <h2 class="font-bold text-[24px] text-[#2E4FFF] col leading-none">
    {{ 'MarketingCampaign.Campaign Key Message' | translate }}
  </h2>
  <p class="text-[#191C30BF] font-medium text-[16px] mt-4">
    {{ 'MarketingCampaign.Craft the core message for your campaign.' | translate }}
  </p>
</header>

<div
  *ngIf="campaign"
  class="controller grid grid-cols-1 lg:grid-cols-2 grid-rows-3 gap-6 print:grid-cols-1"
>
  <div class="bg-white py-6 px-4 rounded-[12px] print:py-2">
    <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4">
      {{ 'MarketingCampaign.Big Idea' | translate }}
    </h2>
    <h2 class="font-bold text-[24px] leading-loose">
      {{ campaign.data.bigIdea }}
    </h2>
  </div>
  <div class="bg-white py-6 px-4 rounded-[12px] print:py-2">
    <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4">
      {{ 'MarketingCampaign.Tone of voice' | translate }}
    </h2>
    <p class="text-[14px] text-[#191C30BF]">{{ campaign.data.toneOfVoice }}</p>
  </div>
  <div class="bg-white py-6 px-4 rounded-[12px] print:py-2">
    <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4">
      {{ 'MarketingCampaign.Call to action' | translate }}
    </h2>
    <p class="text-[14px] text-[#191C30BF]">{{ campaign.data.cta }}</p>
  </div>
  <div class="bg-white py-6 px-4 rounded-[12px] print:py-2">
    <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4">
      {{ 'MarketingCampaign.Problem Awareness' | translate }}
    </h2>
    <p
      class="text-[14px] text-[#191C30BF]"
      *ngFor="let problem of campaign.data.problemAwareness"
    >
      {{ problem }}
    </p>
  </div>
  <div class="bg-white py-6 px-4 rounded-[12px] print:py-2">
    <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4">
      {{ 'MarketingCampaign.Solution highlight' | translate }}
    </h2>
    <p
      class="text-[14px] text-[#191C30BF]"
      *ngFor="let solution of campaign.data.solutionHighlight"
    >
      {{ solution }}
    </p>
  </div>
  <div class="bg-white py-6 px-4 rounded-[12px] print:py-2">
    <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4">
      {{ 'MarketingCampaign.Result emphasis' | translate }}
    </h2>
    <p
      class="text-[14px] text-[#191C30BF]"
      *ngFor="let result of campaign.data.resultEmphasis"
    >
      {{ result }}
    </p>
  </div>
</div>

<df-skeleton
  [showSkeleton]="!campaign"
  [rows]="1"
  [cols]="3"
></df-skeleton>
