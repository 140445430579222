import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Campaign } from '../../campaign.component';

@Component({
  selector: 'df-campaign-marketing-channels-tactics',
  templateUrl: './marketing-channels-tactics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignMarketingChannelsTacticsComponent {
  @Input() campaign!: Campaign;
}
