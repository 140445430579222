<div class="flex flex-col items-center py-6 px-4">
  <div class="rounded-full tui-accent-08 p-4">
    <svg-icon src="/assets/img/ic_percentage.svg"></svg-icon>
  </div>
  <h5 class="tui-text_h5 mt-1 tui-text-03 text-center">
    {{ 'Pricing.Enter promo code' | translate }}
  </h5>

  <form
    [formGroup]="form"
    [validate-form]="form"
    (submit)="checkCode()"
    class="mt-8 flex justify-between items-stretch w-full"
  >
    <div class="mr-2 flex-grow">
      <tui-input
        formControlName="code"
        class="w-full"
        [tuiTextfieldSize]="'l'"
        [tuiTextfieldLabelOutside]="true"
      >
        {{ 'Pricing.Enter promo code' | translate }}
      </tui-input>
      <df-error-input
        [control]="form.get('code')"
        #codeError
      ></df-error-input>
    </div>

    <button
      tuiButton
      type="submit"
      appearance="accent"
      size="l"
      [disabled]="form.invalid"
      [showLoader]="codeValidationLoading"
    >
      {{ 'Pricing.Apply' | translate }}
    </button>
  </form>

  <div
    *ngIf="promotion && appliedCode && !codeValidationLoading"
    class="w-full mt-3"
  >
    <p class="tui-text-body-m-6 tui-accent-07 mb-1 font-semibold">
      {{ 'Pricing.Promocode successfuly added' | translate }}
    </p>
    <div class="rounded-lg border border-solid tui-accent-07-border p-3 flex justify-between items-center w-full">
      <div class="rounded-full tui-accent-08 p-3.5 mr-4">
        <svg-icon src="/assets/img/ic_tag.svg"></svg-icon>
      </div>
      <div class="flex-grow">
        <h6 class="tui-text-body-m-6 tui-text-03 font-semibold">
          {{ 'Pricing.:promocode Applied' | translate: { promocode: appliedCode } }}
        </h6>
        <p class="m-0 tui-text-body-m-2 tui-text-05">
          -{{
            promotion.type === PromotionTypeEnum.Percent
              ? promotion.value + '%'
              : (promotion.value | price | currency: promotion.currency?.toUpperCase())
          }}
        </p>
      </div>
      <button
        tuiButton
        type="button"
        appearance="secondary"
        class="text-red-600"
        size="s"
        (click)="removeCode()"
      >
        {{ 'remove' | translate }}
      </button>
    </div>
  </div>

  <button
    tuiButton
    type="button"
    appearance="primary"
    (click)="goToPayment()"
    [disabled]="!promotion || !appliedCode"
    [showLoader]="packetsComponent?.buyPlanLoading ?? false"
    class="mt-12"
    size="l"
  >
    {{ 'Pricing.Go to payment' | translate }}
  </button>
</div>
