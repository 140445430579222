import { Inject, Injectable, Injector } from '@angular/core';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import {
  ContentGenerationOutput,
  Integration,
  IntegrationInputConfiguration,
  IntegrationOutputData,
} from '@shared/models/integration.model';
import { FetchResult } from '@apollo/client/core';
import { GetBannerbearIntegrationsQuery } from '@modules/funnels/shared/graphql/queries/get-bannerbear-integrations.query.generated';
import { AbstractContentGeneratorService } from '@shared/abstracts/content-generator.service.abstract';
import { ContentGeneratorEventEnum } from '@modules/funnels/shared/enums/content-generator-selection-type.enum';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { GenerateImagesForFunnelMutation } from '@modules/funnels/shared/graphql/mutations/generate-images-for-funnel.mutation.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { FunnelGraphicsWelcomeModalComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-graphics/components/funnel-graphics-welcome-modal/funnel-graphics-welcome-modal.component';
import { catchError, delay, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { GenerateDefaultImagesMutation } from '@modules/funnels/shared/graphql/mutations/generate-default-images.mutation.generated';
import { Funnel } from '@shared/models/funnel.model';
import { CookieService } from 'ngx-cookie-service';
import {
  AttachmentOutputGraphql,
  ContentGenerationOutputGraphql,
  CreateInputDataGraphql,
  InputTypeEnum,
  IntegrationTypeEnum,
  PermissionType,
} from '@modules/graphql/graphql-types';
import { CreateContentGenerationImageMutation } from '@modules/funnels/shared/graphql/mutations/create-content-generation-image.mutation.generated';
import { DownloadOutputsDataZipMutation } from '@modules/funnels/shared/graphql/mutations/download-outputs-data-zip.mutation.generated';
import FileSaver from 'file-saver';
import { Config } from '@shared/configs/config';
import { UserService } from '@shared/services/user.service';
import { GetOutputsDataQuery } from '@modules/funnels/shared/graphql/queries/get-outputs-data.query.generated';
import { FunnelGraphicsCloseAlertComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-graphics/components/funnel-graphics-close-alert/funnel-graphics-close-alert.component';
import { ContentGeneratorEventService } from '@modules/funnels/modules/funnel-manage/shared/services/content-generator-event.service';
import { CreditsService } from '@shared/services/credits.service';
import { GenerateContentCostBadgeService } from '@modules/funnels/modules/funnel-manage/shared/components/generate-content-cost-badge/generate-content-cost-badge.service';
import { FunnelGraphicTabEnum } from '@modules/funnels/modules/funnel-manage/shared/enums/funnel-graphic-tab';
import { GetIntegrationsQuery } from '@modules/funnels/shared/graphql/queries/get-integrations.query.generated';
import { FunnelGraphicTab } from '@modules/funnels/modules/funnel-manage/shared/interfaces/funnel-graphic-tab';
import { GenerateContentMutation } from '@modules/funnels/shared/graphql/mutations/generate-content.mutation.generated';
import { GetLastOutputsDataQuery } from '@modules/funnels/shared/graphql/queries/get-last-outputs-data.query.generated';
import file2Base64 from '@shared/helpers/file-to-base64.helper';
import { AiDesignUploadedFile } from './components/funnel-graphics-ai-design-form/funnel-graphics-ai-design-form.model';
import { dataURLtoFileOrBlob } from '@shared/helpers/base64-to-file.helper';
import { MixpanelEventName, MixpanelService } from '@shared/services/mixpanel.service';

const INTEGRATION_LOADER_DELAY_MS = 500;
const HISTORY_DATA_RECORDS = 8;
const FUNNEL_GRAPHIC_TABS: FunnelGraphicTab[] = [
  {
    name: 'Funnels.Graphics.AI Design',
    index: 0,
    type: FunnelGraphicTabEnum.AI_DESIGN,
  },
  {
    name: 'Funnels.Graphics.Marketing Graphics',
    index: 1,
    type: FunnelGraphicTabEnum.BANNER_GENERATOR,
  },
];

@Injectable()
export class FunnelGraphicsGeneratorService extends AbstractContentGeneratorService {
  integration: Integration | null = null;
  upscaleIntegration: Integration | null = null;
  videoIntegration: Integration | null = null;
  aiDesignImageMask: null | string = null;

  readonly WELCOME_POPUP_COOKIE_NAME = 'funnel-graphics-welcome-';
  private readonly _generatedGraphics$: BehaviorSubject<IntegrationOutputData[]> = new BehaviorSubject<
    IntegrationOutputData[]
  >([]);
  readonly generatedGraphics$: Observable<IntegrationOutputData[]> = this._generatedGraphics$.asObservable();

  private readonly _generatedGraphicsHistory$: BehaviorSubject<IntegrationOutputData[]> = new BehaviorSubject<
    IntegrationOutputData[]
  >([]);
  readonly generatedGraphicsHistory$: Observable<IntegrationOutputData[]> =
    this._generatedGraphicsHistory$.asObservable();

  protected readonly _loadingZip$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loadingZip$: Observable<boolean> = this._loadingZip$.asObservable();

  protected readonly _loadingWelcomePopup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loadingWelcomePopup$: Observable<boolean> = this._loadingWelcomePopup$.asObservable();

  protected readonly _selectedTab$: BehaviorSubject<FunnelGraphicTab> = new BehaviorSubject<FunnelGraphicTab>(
    FUNNEL_GRAPHIC_TABS[0],
  );
  readonly selectedTab$: Observable<FunnelGraphicTab> = this._selectedTab$.asObservable();

  protected readonly _integrationLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly integrationLoading$: Observable<boolean> = this._integrationLoading$.asObservable();

  private initializationInProgress = false;

  get tabs(): FunnelGraphicTab[] {
    return FUNNEL_GRAPHIC_TABS;
  }

  get currentTab(): FunnelGraphicTab {
    return this._selectedTab$.getValue();
  }

  constructor(
    private readonly funnelGraphqlService: FunnelGraphqlService,
    private readonly funnelManageService: FunnelManageService,
    private readonly mixpanelService: MixpanelService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private cookieService: CookieService,
    private readonly userService: UserService,
    protected readonly contentGeneratorEventService: ContentGeneratorEventService,
    s: SnackbarService,
    t: TranslateService,
    private readonly creditsService: CreditsService,
    protected readonly costBadgeService: GenerateContentCostBadgeService,
  ) {
    super(s, t, contentGeneratorEventService, costBadgeService);
  }

  setTab(tab: FunnelGraphicTab): void {
    this._selectedTab$.next(tab);
    this.clearCollections();
    this.restoreLoaders();
    this.initializeService();
  }

  private clearCollections(): void {
    this._generatedGraphics$.next([]);
    this._generatedGraphicsHistory$.next([]);
  }

  private restoreLoaders(): void {
    this._integrationLoading$.next(false);
    this._loadingWelcomePopup$.next(false);
    this._loadingZip$.next(false);
    this._loading$.next(false);
  }

  saveMask(base64: string) {
    this.aiDesignImageMask = base64;
  }

  async saveFormImageField(base64?: string, forceUpdate = false) {
    if (!base64) return;

    const property = this.getIntegrationField('init_image');
    const fileField = this.form.get(`${property?.id}`);

    if (!fileField?.value || forceUpdate) {
      const file = await dataURLtoFileOrBlob(`data:image/png;base64,${base64}`, {
        filename: 'image.jpg',
        mainType: 'image/jpg',
      });
      fileField?.setValue(file);
    }
  }

  initializeService(): void {
    if (!this.userService.User?.hasAccess(PermissionType.AiCopywriting)) return;
    if (this.initializationInProgress) return;
    this.initializationInProgress = true;
    this._integrationLoading$.next(true);
    switch (this.currentTab.type) {
      case FunnelGraphicTabEnum.AI_DESIGN:
        this.initializeStableDiffusionIntegration();
        break;
      case FunnelGraphicTabEnum.BANNER_GENERATOR:
        this.initializeBannerbearIntegration();
        break;
      default:
        this._integrationLoading$.next(false);
        this.initializationInProgress = false;
        break;
    }
  }

  handleFunnelChanged(): void {
    this.setTab(this.currentTab);
  }

  private initializeBannerbearIntegration(): void {
    this.funnelGraphqlService
      .getBannerbearIntegrations()
      .subscribe((res: FetchResult<GetBannerbearIntegrationsQuery>) => {
        this.integration = (res.data?.getBannerbearIntegrations as Integration[])[0];
        this.finishInitializeIntegration();
      });
  }

  private initializeDalleIntegration(): void {
    this.funnelGraphqlService
      .getIntegrations(IntegrationTypeEnum.Dalle)
      .subscribe((res: FetchResult<GetIntegrationsQuery>) => {
        this.integration = (res.data?.getIntegrations as Integration[])[0];
        this.finishInitializeIntegration();
        this.costBadgeService.setCreditsPrice(this.integration.creditsPrice);
      });
  }

  private initializeStableDiffusionIntegration(): void {
    this.funnelGraphqlService
      .getIntegrations(IntegrationTypeEnum.Stablediffusion)
      .subscribe((res: FetchResult<GetIntegrationsQuery>) => {
        this.integration = (res.data?.getIntegrations as Integration[])[2];
        this.upscaleIntegration = (res.data?.getIntegrations as Integration[])[1];
        this.videoIntegration = (res.data?.getIntegrations as Integration[])[0];
        this.finishInitializeIntegration();
        this.costBadgeService.setCreditsPrice(this.integration.creditsPrice);
        // this.getLastOutputsData();
      });
  }

  generateVideo() {
    if (!this.creditsService.canGenerateContent(this.videoIntegration!.creditsPrice)) {
      this.creditsService.showNotEnoughCreditsPopup();
      return;
    }

    this.makeAiDesignVideo();
  }

  private finishInitializeIntegration(): void {
    this.initForm();
    this.initializationInProgress = false;
    setTimeout(() => {
      this._integrationLoading$.next(false);
    }, INTEGRATION_LOADER_DELAY_MS);
  }

  private initForm(): void {
    this.initFormFields(this.integration!);
    this.initFormVideoFields(this.videoIntegration!);
    this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
  }

  generateContent(): void {
    if (this._loading$.getValue()) return;

    this.mixpanelService.trackEvent(MixpanelEventName.ButtonClick, {
      time: Date.now(),
      URL: location.href,
      button_name: 'generate_graphics',
      graphic_type: FunnelGraphicTabEnum.AI_DESIGN === this.currentTab.type ? 'AI_design' : 'marketing_graphics',
    });

    switch (this.currentTab.type) {
      case FunnelGraphicTabEnum.BANNER_GENERATOR:
        this.generateBannerGraphics();
        break;
      case FunnelGraphicTabEnum.AI_DESIGN:
        this.generateAiDesignGraphics();
        break;
      default:
        break;
    }
  }

  async upscaleAiDesignGraphics(aiDesignUploadedFile: AiDesignUploadedFile | null): Promise<void> {
    const imageField = this.getIntegrationField('init_image');
    const funnelId = this.funnelManageService.funnel!.id;
    const integrationId = this.upscaleIntegration!.id;

    if (!aiDesignUploadedFile || !imageField) return;

    this._loading$.next(true);

    const imageUpscaleField = this.getUpscaleField('image');
    const image = this.form.get(`${imageField?.id}`)?.value;

    const data: CreateInputDataGraphql[] = [
      {
        inputConfigurationId: imageUpscaleField!.id,
        value: { data: await file2Base64(image) },
      },
    ];

    this.funnelGraphqlService
      .generateContent({
        funnelId: funnelId,
        integrationId: integrationId,
        inputsData: data,
      })
      .subscribe(async (res) => {
        this._loading$.next(false);
        const value = res.data?.generateContent.outputs[0].value.data;
        await this.saveFormImageField(value, true);
        this.saveMask(value);
      });
  }

  async makeAiDesignVideo(): Promise<void> {
    const formValues = this.videoForm.getRawValue();
    const funnelId = this.funnelManageService.funnel!.id;
    const integrationId = this.videoIntegration!.id;
    this._loading$.next(true);

    const data: CreateInputDataGraphql[] = [];

    for (const key in formValues) {
      data.push({
        inputConfigurationId: +key,
        value: {
          data: formValues[key],
        },
      });
    }

    this.funnelGraphqlService
      .generateContent({
        funnelId: funnelId,
        integrationId: integrationId,
        inputsData: data,
      })
      .subscribe(async (res) => {
        this._loading$.next(false);
        this.generateContentSuccess(this.prepareGenerateAiDesignOutputData(res, true));
      });
  }

  getVideoField(name: string) {
    return this.videoIntegration?.inputsConfigurations.find((i) => i.property === name);
  }

  getUpscaleField(name: string) {
    return this.upscaleIntegration?.inputsConfigurations.find((i) => i.property === name);
  }

  getIntegrationField(name: string) {
    return this.integration?.inputsConfigurations.find((i) => i.property === name);
  }

  getLastOutputsData() {
    return this.funnelGraphqlService
      .getLastOutputsData(HISTORY_DATA_RECORDS, this.integration!.id, this.funnelManageService.funnel!.id)
      .subscribe((response) => {
        this.handleAiDesignHistoryData(response);
      });
  }

  private async generateAiDesignGraphics(): Promise<void> {
    if (!this.creditsService.canGenerateContent(this.integration!.creditsPrice)) {
      this.creditsService.showNotEnoughCreditsPopup();
      return;
    }

    this.prepareGenerateContent();
    const integrationId: number = this.integration!.id;
    const funnelId: number = this.funnelManageService.funnel!.id;

    const fileField = this.getIntegrationField('init_image');
    const file = this.form.get(`${fileField?.id}`)?.value;

    const maskField = this.getIntegrationField('mask_image');

    const data: CreateInputDataGraphql[] = this.getInputsData();

    if (file) {
      for (const d of data) {
        if (d.inputConfigurationId === fileField?.id) {
          d.value.data = await file2Base64(file);

          if (!maskField) break;
          if (d.value.data === this.aiDesignImageMask) break;

          this.form.get(`${maskField?.id}`)?.setValue(this.aiDesignImageMask);
          break;
        }
      }
    }

    const generateAiDesignGraphic = this.funnelGraphqlService.generateContent({
      funnelId: funnelId,
      integrationId: integrationId,
      inputsData: data,
    });

    const getLastOutputsData = this.funnelGraphqlService.getLastOutputsData(
      HISTORY_DATA_RECORDS,
      integrationId,
      funnelId,
    );

    forkJoin([generateAiDesignGraphic, getLastOutputsData])
      .pipe(
        catchError((err) => {
          this.generateContentError();
          return throwError(err);
        }),
      )
      .subscribe({
        next: (responses) => {
          if (this.currentTab.type !== FunnelGraphicTabEnum.AI_DESIGN) return;
          this.handleAiDesignHistoryData(responses[1]);
          this.generateContentSuccess(this.prepareGenerateAiDesignOutputData(responses[0]));
        },
      });
  }

  private prepareGenerateContent(): void {
    this._loading$.next(true);
    this.clearCollections();
  }

  private generateBannerGraphics(): void {
    if (!this.creditsService.canGenerateContent(this.selectedGraphicTypes * this.integration!.creditsPrice)) {
      this.creditsService.showNotEnoughCreditsPopup();
      return;
    }

    this.prepareGenerateContent();
    const funnelId: number = this.funnelManageService.funnel!.id;

    const obs: Observable<FetchResult<CreateContentGenerationImageMutation>>[] =
      this.integration!.inputsConfigurations.filter(
        (i) => i.type === InputTypeEnum.InputFile && !!this.form.get(i.id.toString())?.value,
      ).map((config: IntegrationInputConfiguration) =>
        this.funnelGraphqlService.createContentGenerationImage(funnelId, this.form.get(config.id.toString())?.value),
      );

    forkJoin(obs)
      .pipe(
        catchError((err) => {
          console.log(err);
          this.generateContentError();
          return throwError(err);
        }),
        switchMap((res: FetchResult<CreateContentGenerationImageMutation>[]) =>
          this.funnelGraphqlService.generateImagesForFunnel({
            integrationId: this.integration!.id,
            funnelId,
            inputsData: this.getInputDataWithImages(res),
          }),
        ),
      )
      .subscribe(
        (res) => {
          if (this.currentTab.type !== FunnelGraphicTabEnum.BANNER_GENERATOR) return;
          this.generateContentSuccess(this.prepareGenerateBannerOutputData(res));
        },
        () => this.generateContentError(),
      );
  }

  private getInputDataWithImages(res: FetchResult<CreateContentGenerationImageMutation>[]): CreateInputDataGraphql[] {
    const inputsData: CreateInputDataGraphql[] = this.getInputsData();
    inputsData
      .filter(
        (data) =>
          this.integration!.inputsConfigurations.find((config) => config.id === data.inputConfigurationId)?.type ===
          InputTypeEnum.InputFile,
      )
      .forEach((inputData, index) => {
        if (res[index]) {
          inputData.value = {
            data: res[index].data?.createContentGenerationImage.id,
          };
        }
      });
    return inputsData;
  }

  private prepareGenerateBannerOutputData(response: FetchResult<GenerateImagesForFunnelMutation>) {
    return new ContentGenerationOutput(response!.data?.generateImagesForFunnel as ContentGenerationOutputGraphql);
  }

  private prepareGenerateAiDesignOutputData(response: FetchResult<GenerateContentMutation>, isVideo = false) {
    const generateContent = {
      ...response!.data?.generateContent,
      outputs: response!.data?.generateContent.outputs.map((output) => ({
        ...output,
        value: isVideo
          ? { data: output.value.data, isVideo: isVideo }
          : {
              data: `data:image/png;base64,${output.value.data}`,
              isVideo: isVideo,
            },
      })),
    };

    return new ContentGenerationOutput(generateContent as ContentGenerationOutputGraphql);
  }

  private handleAiDesignHistoryData(response: FetchResult<GetLastOutputsDataQuery>): void {
    this._generatedGraphicsHistory$.next(response.data?.getLastOutputsData as IntegrationOutputData[]);
  }

  protected generateContentSuccess(output?: ContentGenerationOutput): void {
    this.getValidatedOutputData(output!.outputs).then((outputs) => {
      this._generatedGraphics$.next(outputs);
      this.userService.updateCredits(output!.userCredits);
      super.generateContentSuccess();
    });
  }

  async getValidatedOutputData(originals: IntegrationOutputData[]): Promise<IntegrationOutputData[]> {
    let toRefetch: number[] = originals.filter((o) => o.value['data'] === null).map((o) => o.id);
    let limit = 10;
    while (toRefetch.length > 0 && limit > 0) {
      limit--;
      const data: IntegrationOutputData[] =
        (await this.funnelGraphqlService
          .getOutputsData(toRefetch)
          .pipe(
            delay(3000),
            map((res: FetchResult<GetOutputsDataQuery>) =>
              res.data ? (res.data.getOutputsData as IntegrationOutputData[]) : [],
            ),
          )
          .toPromise()) ?? [];
      originals.filter((o) => o.value['data'] === null).forEach((item, index) => (item.value = data[index].value));
      toRefetch = originals.filter((o) => o.value['data'] === null).map((o) => o.id);
    }
    return originals;
  }

  openWelcomePopup(): void {
    (this.funnelManageService.funnel
      ? of(this.funnelManageService.funnel)
      : this.funnelManageService.funnel$.pipe(
          filter((data) => !!data),
          take(1),
        )
    )
      .pipe(
        switchMap((funnel: Funnel | undefined) =>
          of(this.cookieService.check(this.WELCOME_POPUP_COOKIE_NAME + funnel?.id)),
        ),
        filter((isCookie: boolean) => !isCookie),
        tap(() => this._loadingWelcomePopup$.next(true)),
        switchMap(() => this.fetchDefaultGraphics()),
        filter((graphics: IntegrationOutputData[]) => graphics.length > 0),
        tap(() => this._loadingWelcomePopup$.next(false)),
        switchMap((graphics: IntegrationOutputData[]) =>
          this.dialogService.open<number>(
            new PolymorpheusComponent(FunnelGraphicsWelcomeModalComponent, this.injector),
            {
              closeable: true,
              dismissible: false,
              size: 'l',
              data: {
                graphics,
              },
            },
          ),
        ),
      )
      .subscribe();
  }

  fetchDefaultGraphics(): Observable<IntegrationOutputData[]> {
    return this.funnelGraphqlService.generateDefaultImages(this.funnelManageService.funnel?.id!).pipe(
      map((res: FetchResult<GenerateDefaultImagesMutation>) => {
        this.cookieService.set(this.WELCOME_POPUP_COOKIE_NAME + this.funnelManageService.funnel?.id!, 'seen', 365);
        const output: ContentGenerationOutput = new ContentGenerationOutput(
          res!.data?.generateDefaultImages as ContentGenerationOutputGraphql,
        );
        this.userService.updateCredits(output.userCredits);
        return output.outputs ?? [];
      }),
    );
  }

  downloadOutputsZip(outputs: IntegrationOutputData[], name?: string): void {
    if (this.currentTab.type !== FunnelGraphicTabEnum.BANNER_GENERATOR) return;
    this._loadingZip$.next(true);
    this.funnelGraphqlService
      .downloadOutputsDataZip(
        name?.length ? name : this.funnelManageService.funnel!.name,
        this.funnelManageService.funnel!.id,
        outputs.map((output) => output.id),
      )
      .pipe(
        map(
          (res: FetchResult<DownloadOutputsDataZipMutation>) =>
            res.data?.downloadOutputsDataZip as AttachmentOutputGraphql,
        ),
      )
      .subscribe(
        (file: AttachmentOutputGraphql) => {
          FileSaver.saveAs(Config.ASSETS + file.file, file.name);
          this._loadingZip$.next(false);
        },
        () => {
          this.s.error(
            this.t.instant('Funnels.Graphics.Something went wrong during saving zip file. Please try again.'),
          );
          this._loadingZip$.next(false);
        },
      );
  }

  openConfirmExitModal(): Observable<boolean> {
    if (this._generatedGraphics$.value.length || this.form.dirty) {
      return new Observable<boolean>((subscriber) => {
        let closedWithButton = false;
        this.dialogService
          .open<boolean>(new PolymorpheusComponent(FunnelGraphicsCloseAlertComponent, this.injector), {
            closeable: true,
            dismissible: false,
            size: 's',
            label: this.t.instant('Funnels.Graphics.Leaving this page?'),
          })
          .subscribe(
            (res) => {
              subscriber.next(res);
              closedWithButton = true;
            },
            () => subscriber.next(false),
            () => {
              if (!closedWithButton) {
                subscriber.next(false);
              }
              subscriber.complete();
            },
          );
      });
    } else {
      return of(true);
    }
  }

  protected generateContentError(): void {
    this.s.error(
      this.t.instant(
        'Funnels.Content generator.Something went wrong during generating content. Please check the data and try again.',
      ),
    );
    this._loading$.next(false);
  }
}
