import { gql } from 'apollo-angular';
import { IntegrationOutputDataFragment } from '@modules/funnels/shared/graphql/fragments/integration-output-data.fragment';

export const MarkOutputDataAsSelectedDocument = gql`
  mutation markOutputDataAsSelected($isSelected: Boolean!, $outputDataId: Int!) {
    markOutputDataAsSelected(isSelected: $isSelected, outputDataId: $outputDataId) {
      ...IntegrationOutputData
    }
  }
  ${IntegrationOutputDataFragment}
`;
