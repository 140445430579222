import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { environment } from '@environments/environment';
import { AssetLibraryOutputGraphql, Maybe } from '@modules/graphql/graphql-types';
import { TranslateService } from '@ngx-translate/core';
import { copyToClipboard } from '@shared/helpers/clipboard.helper';
import FileSaver from 'file-saver';

@Component({
  selector: 'df-asset-tile',
  templateUrl: './asset-tile.component.html',
  styleUrls: ['./asset-tile.component.scss'],
})
export class AssetTileComponent {
  @Input() asset!: AssetLibraryOutputGraphql;
  @Output() deleteAssetEvent = new EventEmitter<AssetLibraryOutputGraphql>();
  showSidebar = false;

  constructor(
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  getImagePath(path: Maybe<string> | undefined = '') {
    return environment.NEW_CDN_PATH + '/' + path;
  }

  removeHTML(text) {
    return text.replace(/<\/?[^>]+(>|$)/g, '');
  }

  truncateText(text) {
    return text.length > 500 ? text.substring(0, 500) + '...' : text;
  }

  downloadAsset(path: Maybe<string> | undefined = '') {
    const fullPath = this.getImagePath(path);
    FileSaver.saveAs(fullPath);
  }

  copyToClipboard(value: Maybe<string> | undefined) {
    copyToClipboard(value as string, this.s, this.t);
  }

  deleteAsset() {
    this.deleteAssetEvent.emit(this.asset);
  }
}
