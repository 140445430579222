import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { GlobalDataService } from '@shared/services/global-data.service';
import { Permission } from '@shared/models/permission.model';

@Injectable()
export class PermissionsResolver implements Resolve<Permission[]> {
  constructor(
    private globalDataService: GlobalDataService,
    private t: TranslateService,
    private s: SnackbarService,
  ) {}

  async resolve() {
    const data = await this.globalDataService
      .getPermissions()
      .toPromise()
      .catch((e) => {
        this.s.defaultError();
        throw e;
      });

    return data ? data : [];
  }
}
