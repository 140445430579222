import { gql } from 'apollo-angular';

export const getTacticsMetricsDocument = gql`
  query getTacticsMetrics($tacticId: Int!) {
    getTacticsMetrics(tacticId: $tacticId) {
      id
      measurementPeriod
      isCyclical
      cycleMeasurementPeriod
      metric {
        id
        name
      }
    }
  }
`;
