import { gql } from 'apollo-angular';

export const GetPermissionsDocument = gql`
  query getPermissions {
    getPermissions {
      id
      type
      name
      isAvailable
      isAvailableInAppSumo
      plansCount
    }
  }
`;
