import { gql } from 'apollo-angular';

export const SearchSimpleFunnelsDocument = gql(/* GraphQL*/ `query searchSimpleFunnels($query: FunnelsListInputGraphql!)
{
  searchFunnels(query: $query)
  {
    records
    {
      id
      name
      tactics
      {
        id
        tactic
        {
          id
        }
        step
        {
          id
        }
      }
    }
  }
}

`);
