import { XPartnerService } from './../../../../shared/services/x-partner.service';
import { Component } from '@angular/core';
import { Config } from '@shared/configs/config';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
})
export class NoAccessComponent {
  Config = Config;

  constructor(
    public n: NavigateService,
    public xPartnerService: XPartnerService,
  ) {}
}
