<div
  *ngIf="recommendation"
  class="tactic-tile flex justify-between items-start {{ recommendation.step?.type }}"
  [class.in-use]="recommendation.usedInFunnel"
  (click)="select()"
>
  <div
    *ngIf="recommendation.usedInFunnel"
    class="tui-text-01-bg py-0.5 px-3 uppercase font-semibold tui-base-01 text-xs rounded-md absolute -top-2.5 right-5"
  >
    {{ 'Funnels.Recommendations.In use' | translate }}
  </div>
  <div class="flex flex-col justify-between items-start p-3 relative h-full w-full">
    <div class="tactic-tile__header flex flex-col items-start justify-start w-full">
      <div class="flex justify-between mb-1 self-stretch">
        <div class="flex h-6">
          <svg-icon
            [src]="'/assets/img/ic-' + step.type + '-fill.svg'"
            class="mr-1"
            *ngFor="let step of recommendation.tactic?.funnelSteps"
          ></svg-icon>
        </div>

        <tui-checkbox
          *ngIf="recommendationFormControl"
          size="l"
          [formControl]="recommendationFormControl"
        ></tui-checkbox>
      </div>
      <h2
        class="tui-text-01 tui-text_body-17 line-clamp-5 break-words"
        [innerHTML]="recommendation.tactic.name"
      ></h2>
      <div class="mt-1 h-6 tui-text-04 tui-text_body-m-4"></div>
    </div>
    <div class="w-full">
      <df-created-by [user]="recommendation.tactic.owner"></df-created-by>
    </div>
  </div>
</div>
