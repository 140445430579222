import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TuiDialogService } from '@taiga-ui/core';
import { TacticsListSidebarComponent } from '@modules/tactics/modules/tactics-list/pages/tactics-list-panel/components/tactics-list-sidebar/tactics-list-sidebar.component';
import { TacticController } from '@modules/tactics/shared/controllers/tactic.controller';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { OnboardingService } from '@shared/services/onboarding.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { onboardingFadeAndSlide } from '@shared/animations/onboarding.animations';
import { MediaQuery } from '@shared/configs/media-query';
import isMobile from 'is-mobile';
import { Router } from '@angular/router';

@Component({
  selector: 'df-tactics-list-panel',
  templateUrl: './tactics-list-panel.component.html',
  styleUrls: ['./tactics-list-panel.component.scss'],
  animations: [onboardingFadeAndSlide()],
})
export class TacticsListPanelComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  readonly EOnboardingStep = EOnboardingStep;
  tacticController: TacticController = new TacticController();
  @ViewChild('sidebar', { static: true }) sidebar!: TacticsListSidebarComponent;
  showFilters: boolean | null = null;

  constructor(
    public n: NavigateService,
    private t: TranslateService,
    private s: SnackbarService,
    private changes: ChangeDetectorRef,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    public tacticsFiltersService: TacticsFiltersService,
    public onboardingService: OnboardingService,
    public router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenOnboardingStepChange();
    this.redirectOnboardToStatement();

    this.showFilters = this.onboardingService.onboardingRunning ? window.innerWidth >= MediaQuery.SM : null;
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.tacticsFiltersService.clearFilters();
  }

  createNewTactic(): void {
    this.tacticController.createTactic().toPromise();
  }

  openMobile() {
    this.sidebar.openMobile();
  }

  redirectOnboardToStatement() {
    if (isMobile()) {
      this.onboardingService.redirectMobileOnboarding();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.showFilters = this.onboardingService.onboardingCompleted ? null : window.innerWidth >= MediaQuery.SM;
    this.redirectOnboardToStatement();
  }
}
