import { Component, input } from '@angular/core';
import { TacticFrameIconType } from './tactic-step-icon.models';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'df-tactic-step-icon',
  standalone: true,
  templateUrl: './tactic-step-icon.component.html',
  styleUrl: './tactic-step-icon.component.css',
  imports: [CommonModule],
})
export class TacticStepIconComponent {
  public type = input.required<TacticFrameIconType>();
}
