import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FunnelViewMode } from '@modules/funnels/shared/enums/funnel-view-mode.enum';
import { Funnel } from '@shared/models/funnel.model';
import { filter, map, pluck } from 'rxjs/operators';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { GetFunnelByPromotionCodeQuery } from '@modules/funnels/shared/graphql/queries/get-funnel-by-promotion-code.query.generated';
import { GetFunnelQuery } from '@modules/funnels/shared/graphql/queries/get-funnel.query.generated';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { UserService } from '@shared/services/user.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import {
  TacticsListEvent,
  TacticsListEventType,
  TacticsListService,
} from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { UserFunnelsListService } from '@modules/base-panel/pages/base-panel/services/user-funnels-list.service';
import { FunnelPermissionEnum } from '@modules/graphql/graphql-types';
import { GetOnboardingFunnelQuery } from '@modules/funnels/shared/graphql/queries/get-onboarding-funnel.query.generated';
import { OnboardingService } from '@shared/services/onboarding.service';

@Component({
  selector: 'df-funnel-panel',
  templateUrl: './funnel-panel.component.html',
  styleUrls: ['./funnel-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class FunnelPanelComponent implements OnInit, OnDestroy {
  readonly Config = Config;
  detailsMode = false;
  private readonly sub: Subscription = new Subscription();

  constructor(
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
    private route: ActivatedRoute,
    public router: Router,
    private readonly basePanelService: BasePanelService,
    private readonly changes: ChangeDetectorRef,
    public funnelManageService: FunnelManageService,
    private funnelGraphqlService: FunnelGraphqlService,
    public funnelFlowManageService: FunnelFlowManageService,
    public userService: UserService,
    private userFunnelsListService: UserFunnelsListService,
    private tacticsListService: TacticsListService,
    private readonly onboardingService: OnboardingService,
  ) {}

  ngOnInit(): void {
    this.setViewModeObserver();
    this.listenDetailsModeChange();
    this.listenShortNavChange();
    this.listenChangeRouteId();
    this.listenReloadFunnel();
  }

  get funnelId(): number {
    return this.route.snapshot.params['id'];
  }

  isBusinessURL() {
    return !location.href.includes('business-data');
  }

  setViewModeObserver(): void {
    this.sub.add(this.route.data.pipe(pluck('type')).subscribe((type) => (this.funnelManageService.viewMode = type)));
  }

  listenDetailsModeChange(): void {
    this.sub.add(
      this.funnelManageService.detailsMode$.subscribe((detailsMode) => {
        this.detailsMode = detailsMode;
        this.changes.detectChanges();
      }),
    );
  }

  listenShortNavChange() {
    this.sub.add(this.basePanelService.emitter.subscribe(() => this.changes.markForCheck()));
  }

  listenChangeRouteId() {
    const viewMode = this.route.snapshot.data['type'];
    if (viewMode === FunnelViewMode.PROMOTION) {
      this.sub.add(this.route.params.subscribe((params) => this.getFunnel(params['slug'], viewMode)));
    } else if (viewMode === FunnelViewMode.ONBOARDING) {
      this.getFunnel(undefined, FunnelViewMode.ONBOARDING);
    } else {
      this.sub.add(
        this.route.params.subscribe((params) => {
          if (!isNaN(+params['id'])) {
            const id = Number(params['id']);
            if (this.funnelManageService.funnel?.id !== id) {
              this.getFunnel(params['id'], viewMode);
            }
          } else {
            this.n.go(Config.MAIN_VIEW);
          }
        }),
      );
    }
  }

  listenReloadFunnel() {
    this.sub.add(
      this.funnelGraphqlService.refreshEmitter
        .pipe(filter((e: boolean) => e.valueOf()))
        .subscribe(() => this.getFunnel(this.funnelManageService.funnel!.id.toString())),
    );

    this.sub.add(
      this.tacticsListService.tacticsListEmitter
        .pipe(filter((e: TacticsListEvent) => e.type === TacticsListEventType.RELOAD_FUNNEL))
        .subscribe(() => this.getFunnel(this.funnelManageService.funnel!.id.toString())),
    );
  }

  getFunnel(id?: string, viewMode: FunnelViewMode = FunnelViewMode.DETAILS) {
    this.funnelManageService.loading = true;
    this.funnelManageService.clearSelection();
    const request: Observable<Funnel> =
      viewMode === FunnelViewMode.PROMOTION
        ? this.funnelGraphqlService
            .getFunnelByPromotionCode(id!)
            .pipe(
              map((res: FetchResult<GetFunnelByPromotionCodeQuery>) => res.data?.getFunnelByPromotionCode as Funnel),
            )
        : viewMode === FunnelViewMode.ONBOARDING
          ? this.funnelGraphqlService
              .getOnboardingFunnel()
              .pipe(map((res: FetchResult<GetOnboardingFunnelQuery>) => res.data?.getOnboardingFunnel as Funnel))
          : this.funnelGraphqlService
              .getFunnel(Number(id))
              .pipe(map((res: FetchResult<GetFunnelQuery>) => res.data?.getFunnel as Funnel));
    request
      .subscribe({
        next: (funnel: Funnel) => {
          this.funnelFlowManageService.clearAllConnections(true);
          this.funnelFlowManageService.editMode = false;
          this.funnelManageService.funnelTactics = {};
          this.funnelManageService.funnel = funnel;
          this.funnelManageService.setFunnelStepSummaries();
          if (
            funnel?.owner?.id === this.userService.User?.id ||
            funnel?.currentUserPermission === FunnelPermissionEnum.Editor
          ) {
            if (viewMode === FunnelViewMode.DETAILS) {
              this.userFunnelsListService.setContextFunnel(funnel);
            }
          } else if (funnel.shares.some((share) => share.email === this.userService.User?.email)) {
            this.n.go('funnels/f/shared/:id', { id: funnel.id });
          }
        },
        error: (error: ApolloError) => {
          this.n.go('home');
          if (error.graphQLErrors?.length) {
            switch ((error.graphQLErrors[0] as CustomGraphqlErrorInterface).message) {
              case 'Not allowed':
                this.s.error(this.t.instant('Funnels.Manage.You do not have access to this funnel.'));
                break;
              default:
                this.s.error(this.t.instant('Funnels.Manage.There is problem with getting funnel data. Try again.'));
                break;
            }
          }
        },
      })
      .add(() => {
        this.funnelManageService.loading = false;
        this.onboardingService.loading = false;
      });
  }

  ngOnDestroy() {
    this.funnelManageService.clearService();
    this.funnelFlowManageService.clearService();
    this.sub.unsubscribe();
  }
}
