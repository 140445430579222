import { gql } from 'apollo-angular';

export const GetCanvasTemplateDocument = gql`
  query getCanvasTemplate($id: Int!) {
    getCanvasTemplate(id: $id) {
      id
      parentId
      name
      description
      img
      hierarchyPosition
      status
      prompt {
        id
        name
        description
      }
      category {
        id
        name
        description
      }
      positions {
        id
        label
        description
        img
        type
        requirement
        visibility
        defaultValue
        defaultValueType
        hierarchyPosition
        positionValues {
          id
          name
          value
          default
          hierarchyPosition
        }
        section {
          id
          name
          description
          img
          hierarchyPosition
        }
      }
    }
  }
`;
