import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  AppSumoRegisterInputGraphql,
  RegisterInputGraphql,
  SaveAccountSetupInputGraphql,
  SocialLoginInputGraphql,
  WorksForEnum,
} from '@modules/graphql/graphql-types';
import { RegisterMutation } from '@modules/authorization/shared/graphql/mutations/register.mutation.generated';
import { RegisterDocument } from '@modules/authorization/shared/graphql/mutations/register.mutation';
import { LoginMutation } from '@modules/authorization/shared/graphql/mutations/login.mutation.generated';
import { LoginDocument } from '@modules/authorization/shared/graphql/mutations/login.mutation';
import { RequestPasswordResetMutation } from '@modules/authorization/shared/graphql/mutations/request-password-reset.mutation.generated';
import { RequestPasswordResetDocument } from '@modules/authorization/shared/graphql/mutations/request-password-reset.mutation';
import { ConfirmPasswordResetMutation } from '@modules/authorization/shared/graphql/mutations/confirm-password-reset.mutation.generated';
import { ConfirmPasswordResetDocument } from '@modules/authorization/shared/graphql/mutations/confirm-password-reset.mutation';
import { ConfirmEmailMutation } from '@modules/authorization/shared/graphql/mutations/confirm-email.mutation.generated';
import { ConfirmEmailDocument } from '@modules/authorization/shared/graphql/mutations/confirm-email.mutation';
import { ResendEmailConfirmMutation } from '@modules/authorization/shared/graphql/mutations/resend-email-confirm.mutation.generated';
import { ResendEmailConfirmDocument } from '@modules/authorization/shared/graphql/mutations/resend-email-confirm.mutation';
import { ConfirmEmailChangeMutation } from '@modules/authorization/shared/graphql/mutations/confirm-change-email.mutation.generated';
import { ConfirmChangeEmailDocument } from '@modules/authorization/shared/graphql/mutations/confirm-change-email.mutation';
import { ResendEmailChangeMutation } from '@modules/authorization/shared/graphql/mutations/resend-email-change.mutation.generated';
import { ResendEmailChangeDocument } from '@modules/authorization/shared/graphql/mutations/resend-email-change.mutation';
import { LoginWithSocialMediaAccountMutation } from '@modules/authorization/shared/graphql/mutations/ login-with-social-media-account.mutation.generated';
import { LoginWithSocialMediaAccountDocument } from '@modules/authorization/shared/graphql/mutations/ login-with-social-media-account.mutation';
import { RegisterAppSumoMutation } from '@modules/authorization/shared/graphql/mutations/register-app-sumo.mutation.generated';
import { RegisterAppSumoDocument } from '@modules/authorization/shared/graphql/mutations/register-app-sumo.mutation';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { ValidateAppSumoTokenQuery } from '@modules/authorization/shared/graphql/queries/validate-app-sumo-token.query.generated';
import { ValidateAppSumoTokenDocument } from '@modules/authorization/shared/graphql/queries/validate-app-sumo-token.query';
import { IsMarketplaceStripeAccountVerifiedQuery } from '@modules/authorization/shared/graphql/queries/is-marketplace-stripe-account-verified.query.generated';
import { IsMarketplaceStripeAccountVerifiedDocument } from '@modules/authorization/shared/graphql/queries/is-marketplace-stripe-account-verified.query';
import { GetAccountSetupParametersQuery } from '@modules/authorization/shared/graphql/queries/get-account-setup-parameters.query.generated';
import { GetAccountSetupParametersDocument } from '@modules/authorization/shared/graphql/queries/get-account-setup-parameters.query';
import { SaveAccountSetupMutation } from '@modules/authorization/shared/graphql/mutations/save-account-setup.mutation.generated';
import { SaveAccountSetupDocument } from '@modules/authorization/shared/graphql/mutations/save-account-setup.mutation';

@Injectable({
  providedIn: 'root',
})
export class AuthGraphqlService {
  constructor(private apollo: Apollo) {}

  login(email: string, password: string, rememberMe: boolean, recaptcha: string, redirectedFromTacticsLpPage = false) {
    return this.apollo.mutate<LoginMutation>({
      mutation: LoginDocument,
      variables: {
        email,
        password,
        rememberMe,
        redirectedFromTacticsLpPage,
      },
      context: {
        headers: {
          recaptcha,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  loginWithSocialMediaAccount(input: SocialLoginInputGraphql) {
    return this.apollo.mutate<LoginWithSocialMediaAccountMutation>({
      mutation: LoginWithSocialMediaAccountDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  register(input: RegisterInputGraphql, recaptcha: string) {
    return this.apollo.mutate<RegisterMutation>({
      mutation: RegisterDocument,
      variables: {
        input,
      },
      context: {
        headers: {
          recaptcha,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  requestPasswordReset(email: string) {
    return this.apollo.mutate<RequestPasswordResetMutation>({
      mutation: RequestPasswordResetDocument,
      variables: { email },
      fetchPolicy: 'no-cache',
    });
  }

  confirmPasswordReset(token: string, password: string) {
    return this.apollo.mutate<ConfirmPasswordResetMutation>({
      mutation: ConfirmPasswordResetDocument,
      variables: { token, password },
      fetchPolicy: 'no-cache',
    });
  }

  confirmEmail(token: string) {
    return this.apollo.mutate<ConfirmEmailMutation>({
      mutation: ConfirmEmailDocument,
      variables: { token },
      fetchPolicy: 'no-cache',
    });
  }

  confirmChangeEmail(token: string) {
    return this.apollo.mutate<ConfirmEmailChangeMutation>({
      mutation: ConfirmChangeEmailDocument,
      variables: { token },
      fetchPolicy: 'no-cache',
    });
  }

  resendEmailConfirm(email: string) {
    return this.apollo.mutate<ResendEmailConfirmMutation>({
      mutation: ResendEmailConfirmDocument,
      variables: { email },
      fetchPolicy: 'no-cache',
    });
  }

  resendEmailChange() {
    return this.apollo.mutate<ResendEmailChangeMutation>({
      mutation: ResendEmailChangeDocument,
      fetchPolicy: 'no-cache',
    });
  }

  registerAppSumo(input: AppSumoRegisterInputGraphql, recaptcha: string) {
    return this.apollo.mutate<RegisterAppSumoMutation>({
      mutation: RegisterAppSumoDocument,
      variables: {
        input,
      },
      context: {
        headers: {
          recaptcha,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  validateAppSumoToken(token: string): Observable<FetchResult<ValidateAppSumoTokenQuery>> {
    return this.apollo.query<ValidateAppSumoTokenQuery>({
      query: ValidateAppSumoTokenDocument,
      variables: {
        token,
      },
      fetchPolicy: 'no-cache',
    });
  }

  isMarketplaceStripeAccountVerified(): Observable<FetchResult<IsMarketplaceStripeAccountVerifiedQuery>> {
    return this.apollo.mutate<IsMarketplaceStripeAccountVerifiedQuery>({
      mutation: IsMarketplaceStripeAccountVerifiedDocument,
      fetchPolicy: 'no-cache',
    });
  }

  getAccountSetupParameters(): Observable<FetchResult<GetAccountSetupParametersQuery>> {
    return this.apollo.query<GetAccountSetupParametersQuery>({
      query: GetAccountSetupParametersDocument,
      fetchPolicy: 'no-cache',
    });
  }

  saveAccountSetup(
    worksFor: WorksForEnum,
    parameters: SaveAccountSetupInputGraphql[],
  ): Observable<FetchResult<SaveAccountSetupMutation>> {
    return this.apollo.mutate<SaveAccountSetupMutation>({
      mutation: SaveAccountSetupDocument,
      variables: {
        worksFor,
        parameters,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
