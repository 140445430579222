import { gql } from 'apollo-angular';
import { FunnelFragment } from '@modules/funnels/shared/graphql/fragments/funnel.fragment';

export const GetFunnelByPromotionCodeDocument = gql`
  query getFunnelByPromotionCode($promotionCode: String!) {
    getFunnelByPromotionCode(promotionCode: $promotionCode) {
      ...Funnel
    }
  }
  ${FunnelFragment}
`;
