import { gql } from 'apollo-angular';

export const GetUserTacticsDocument = gql/* GraphQL*/ `
  query getUserTactics($userId: Int!, $query: ListInput!) {
    getUserTactics(userId: $userId, query: $query) {
      records {
        id
        name
        rate
        funnelSteps {
          id
          name
          type
        }
        owner {
          id
          avatar {
            file
          }
        }
        isPaid
        price
        currency
        purchased
      }
    }
  }
`;
