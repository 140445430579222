import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'df-funnel-manage-onboarding-banner',
  templateUrl: './funnel-manage-onboarding-banner.component.html',
  styleUrls: ['./funnel-manage-onboarding-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageOnboardingBannerComponent {
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
