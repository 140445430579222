<df-full-list-loader *ngIf="loading"></df-full-list-loader>

<df-funnel-content-genereter-library-no-content
  *ngIf="!posts.length && !loading"
  [funnelId]="funnelId"
  description="Ayrshare.You dont have any scheduled posts"
  descriptionLine2="Go to your tactics to create them"
  [emitEventInsteadRedirect]="true"
  (buttonClicked)="editPost()"
>
</df-funnel-content-genereter-library-no-content>

<div
  *ngIf="posts.length && !loading"
  class="bg-[#f2f3f5] w-full h-full overflow-y-scroll"
>
  <div class="w-full flex mt-8">
    <button
      tuiButton
      class="ml-auto"
      size="m"
      (click)="editPost()"
    >
      {{ 'Ayrshare.Publish new content' | translate }}
    </button>
  </div>

  <table
    tuiTable
    class="table p-6 w-full"
    [columns]="columns"
  >
    <thead>
      <tr tuiThGroup>
        <th
          class="ayrshare__th py-3 align-middle w-[170px]"
          tuiTh
          *tuiHead="'created'"
        >
          {{ 'Ayrshare.Publication Date' | translate }}
        </th>
        <th
          class="ayrshare__th py-3 align-middle max-w-[500px]"
          tuiTh
          *tuiHead="'post'"
          [sorter]="null"
        >
          {{ 'Ayrshare.Content' | translate }}
        </th>
        <th
          class="ayrshare__th py-3 align-middle w-[300px]"
          tuiTh
          *tuiHead="'platforms'"
          [sorter]="null"
        >
          {{ 'Ayrshare.Platforms' | translate }}
        </th>
        <th
          class="ayrshare__th py-3 align-middle"
          tuiTh
          *tuiHead="'status'"
          [sorter]="null"
        >
          {{ 'Ayrshare.Status' | translate }}
        </th>
        <th
          class="ayrshare__th py-3 align-middle w-[170px]"
          tuiTh
          *tuiHead="'action'"
          [sorter]="null"
        >
          {{ 'Ayrshare.Options' | translate }}
        </th>
      </tr>
    </thead>
    <tbody
      tuiTbody
      [data]="sortedPosts"
      *tuiLet="sortablePosts | tuiTableSort as sortedPosts"
    >
      <tr
        *ngFor="let item of sortedPosts; let inext = index"
        tuiTr
        class="h-[90px]"
      >
        <td
          *tuiCell="'created'"
          tuiTd
          class="align-middle"
        >
          {{ item.created }}
        </td>
        <td
          *tuiCell="'post'"
          tuiTd
          class="align-middle"
        >
          {{ shortenText(item.post) }}
        </td>

        <td
          *tuiCell="'platforms'"
          tuiTd
          class="align-middle"
        >
          <svg-icon
            *ngFor="let platform of item.platforms"
            [src]="getPath(platform)"
          ></svg-icon>
        </td>

        <td
          *tuiCell="'status'"
          tuiTd
          class="align-middle"
        >
          <span
            *ngIf="item.status !== 'error'"
            [class]="'ayrshare__status ayrshare__status--' + item.status"
          >
            {{ transformStatus(item.status) }}</span
          >
          <span
            tuiHintAppearance="onDark"
            tuiHintDirection="right"
            [tuiHint]="item.errors[0].message + '<br>Platform: ' + item.errors[0].platform"
            *ngIf="item.status === 'error'"
            [class]="'ayrshare__status ayrshare__status--error'"
          >
            {{ transformStatus(item.status) }}</span
          >
        </td>
        <td
          *tuiCell="'action'"
          tuiTd
          class="align-middle table-actions"
        >
          <div class="flex justify-around">
            <svg-icon
              *ngIf="item.status === 'pending' || item.status === 'error'"
              (click)="editPost(item)"
              [src]="'/assets/img/eye.svg'"
              [svgStyle]="{ width: '1rem', height: '1rem' }"
              class="tui-base-06 cursor-pointer"
            >
            </svg-icon>
            <svg-icon
              *ngIf="item.status === 'pending' || item.status === 'error'"
              (click)="editPost(item)"
              [src]="'/assets/img/pen.svg'"
              [svgStyle]="{ width: '1rem', height: '1rem' }"
              class="tui-base-06 cursor-pointer"
            >
            </svg-icon>
            <svg-icon
              *ngIf="item.status === 'pending' || item.status === 'error'"
              (click)="deletePost(item)"
              [svgStyle]="{ width: '1rem', height: '1rem' }"
              [src]="'/assets/img/red-trash.svg'"
              class="tui-base-06 cursor-pointer delete"
            ></svg-icon>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td [colSpan]="columns.length">
          <tui-table-pagination
            (paginationChange)="getSortablePost()"
            [total]="posts.length"
            [(page)]="page"
            [(size)]="size"
          ></tui-table-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<div
  class="df-publications__wrapper"
  *tuiSidebar="openPublication; direction: 'right'"
>
  <df-publications
    (closePublication)="closePublication()"
    [type]="'post'"
    [id]="id | async"
  ></df-publications>
</div>
