import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { Activity } from '@shared/models/activity.model';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { FunnelPermissionEnum } from '@modules/graphql/graphql-types';
import { OnboardingService } from '@shared/services/onboarding.service';

@Component({
  selector: 'df-funnel-manage-tactic-activities',
  templateUrl: './funnel-manage-tactic-activities.component.html',
  styleUrls: ['./funnel-manage-tactic-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageTacticActivitiesComponent extends AbstractSubscriptionComponent implements OnInit {
  FunnelPermissionEnum = FunnelPermissionEnum;

  private _funnelTactic: FunnelTactic | undefined;

  get funnelTactic(): FunnelTactic | undefined {
    return this._funnelTactic;
  }

  @Input() previewMode = false;

  @Input() set funnelTactic(tactic: FunnelTactic | undefined) {
    this._funnelTactic = tactic;
    if (this._funnelTactic) {
      this.funnelManageService.markDoneActivities(this._funnelTactic);
    }
    this.changes.detectChanges();
  }

  activityPreview: number | null = null;
  @Output() detectChanges: EventEmitter<FunnelTactic> = new EventEmitter<FunnelTactic>();

  constructor(
    public readonly changes: ChangeDetectorRef,
    private readonly _tacticGraphqlService: TacticGraphqlService,
    public readonly funnelFlowManageService: FunnelFlowManageService,
    public readonly funnelManageService: FunnelManageService,
    public readonly onboardingService: OnboardingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenExpandAllChanges();
    this.listenEditModeChange();
  }

  private listenExpandAllChanges(): void {
    this.sub.add(
      this.funnelManageService.expandAll.valueChanges.subscribe(() => {
        this.onDropdownChange();
        this.changes.detectChanges();
      }),
    );
  }

  private listenEditModeChange(): void {
    this.sub.add(this.funnelFlowManageService.editMode$.subscribe(() => this.changes.detectChanges()));
  }

  onDropdownChange() {
    setTimeout(() => {
      this.funnelFlowManageService.paintConnections();
    }, 600);
  }

  toggleDone(activity: Activity, event: Event): void {
    if (!this.previewMode) {
      event.stopPropagation();
      event.preventDefault();
      this.funnelManageService.markActivityDone(activity, this.funnelTactic!);
      this.changes.detectChanges();
    }
  }

  toggleTacticPreview(open: boolean, activity: number | null): void {
    this.activityPreview = open ? activity : null;
    this.changes.detectChanges();
  }

  zoneChange(change: boolean): void {
    if (!change) this.toggleTacticPreview(change, null);
  }
}
