import { gql } from 'apollo-angular';

export const GetRecommenderItemsDocument = gql`
  query getRecommenderItems {
    getRecommenderItems {
      id
      name
      funnel {
        id
      }
    }
  }
`;
