import { XPartnerService } from './../../shared/services/x-partner.service';
import { Injector, NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, NamedOptions } from 'apollo-angular';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { Config } from '@shared/configs/config';
import { getAuthMiddleware } from '@modules/graphql/middlewares/auth.middleware';
import { graphqlErrorMiddleware } from '@modules/graphql/middlewares/graphql-error.middleware';
import { NavigateService } from '@core/routes/services/navigate.service';
import { HttpClient } from '@angular/common/http';
import { TuiDialogModule, TuiDialogService } from '@taiga-ui/core';
import { AdminTokenService } from '@shared/services/admin-token.service';
import { ActiveSubscriptionService } from '@shared/services/active-subscription.service';
import { UserService } from '@shared/services/user.service';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { AuthStorageService } from '@core/services/auth-storage.service';

function getLink(httpLink: HttpLink) {
  const http = httpLink.create({
    uri: Config.API,
    extractFiles: (body) => extractFiles(body, isExtractableFile),
  });

  return http;
}

export function createApollo(
  httpLink: HttpLink,
  n: NavigateService,
  d: TuiDialogService,
  injector: Injector,
  a: AdminTokenService,
  xp: XPartnerService,
  as: ActiveSubscriptionService,
  us: UserService,
  ass: AuthStorageService,
) {
  return {
    resolvers: {},
    link: ApolloLink.from([
      getAuthMiddleware(a, xp),
      graphqlErrorMiddleware(n, d, injector, as, us, ass),
      getLink(httpLink),
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  };
}

@NgModule({
  imports: [TuiDialogModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [
        HttpLink,
        NavigateService,
        TuiDialogService,
        Injector,
        AdminTokenService,
        XPartnerService,
        ActiveSubscriptionService,
      ],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(
        httpLink: HttpLink,
        n: NavigateService,
        d: TuiDialogService,
        injector: Injector,
        a: AdminTokenService,
        xp: XPartnerService,
        as: ActiveSubscriptionService,
        us: UserService,
        ass: AuthStorageService,
      ): NamedOptions {
        return {
          SemrushClient: {
            resolvers: {},
            link: ApolloLink.from([
              getAuthMiddleware(a, xp, true),
              graphqlErrorMiddleware(n, d, injector, as, us, ass),
              getLink(httpLink),
            ]),
            cache: new InMemoryCache(),
          },
        };
      },
      deps: [
        HttpLink,
        NavigateService,
        TuiDialogService,
        Injector,
        HttpClient,
        AdminTokenService,
        XPartnerService,
        ActiveSubscriptionService,
      ],
    },
  ],
})
export class GraphQLModule {}
