import { NgModule } from '@angular/core';
import { TacticPreviewComponent } from './pages/tactic-preview/tactic-preview.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { TacticPreviewHeaderComponent } from './pages/tactic-preview/components/tactic-preview-header/tactic-preview-header.component';
import { TacticPreviewActivitiesComponent } from './pages/tactic-preview/components/tactic-preview-activities/tactic-preview-activities.component';
import { TacticPreviewContentComponent } from './pages/tactic-preview/components/tactic-preview-content/tactic-preview-content.component';
import { TacticsModule } from '@modules/tactics/tactics.module';
import { TacticAccordionActivityComponent } from './pages/tactic-preview/components/tactic-preview-activities/components/tactic-accordion-activity/tactic-accordion-activity.component';
import { TacticModalPreviewComponent } from './pages/tactic-modal-preview/tactic-modal-preview.component';
import { TacticPreviewPopupComponent } from './pages/tactic-preview-popup/tactic-preview-popup.component';
import { PricingModule } from '@modules/pricing/pricing.module';
import { ReplaceVariablesPipe } from './shared/pipe/replace-variables.pipe';
import { TuiHintModule, TuiTooltipModule } from '@taiga-ui/core';
import { TacticPreviewRoutes } from '@core/routes/configs/tactics.routes';

@NgModule({
  declarations: [
    TacticPreviewComponent,
    TacticPreviewHeaderComponent,
    TacticPreviewActivitiesComponent,
    TacticPreviewContentComponent,
    TacticAccordionActivityComponent,
    TacticModalPreviewComponent,
    TacticPreviewPopupComponent,
    ReplaceVariablesPipe,
  ],
  imports: [
    SharedModule,
    TacticsModule,
    TuiTooltipModule,
    TuiHintModule,
    RouterModule.forChild(TacticPreviewRoutes),
    PricingModule,
  ],
  exports: [TacticPreviewComponent, TacticAccordionActivityComponent],
})
export class TacticPreviewModule {}
