import { animate, state, style, transition, trigger } from '@angular/animations';

export function onboardingFade() {
  return trigger('onboardingFade', [
    state('in', style({ opacity: 1 })),
    state('out', style({ opacity: 0 })),
    transition('out => in', animate('400ms ease-in')),
    transition('in => out', animate('400ms ease-out')),
  ]);
}
export function onboardingFadeAndSlide() {
  return trigger('onboardingFadeAndSlide', [
    state('in', style({ opacity: 1, transform: 'translateY(0)' })),
    state('out', style({ opacity: 0, transform: 'translateY(2rem)' })),
    transition('out => in', animate('400ms ease-in')),
    transition('in => out', animate('400ms ease-out')),
  ]);
}
export function onboardingHide() {
  return trigger('onboardingHide', [
    state('in', style({ height: '*' })),
    state('out', style({ height: 0 })),
    transition('in => out', animate('400ms ease-out')),
    transition('out => in', animate('400ms ease-in')),
  ]);
}
