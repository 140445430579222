import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TUI_ARROW } from '@taiga-ui/kit';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { StatementInputOption } from '../../models/statement.interface';

@Component({
  selector: 'df-statement-select',
  templateUrl: './statement-select.component.html',
  styleUrls: ['./statement-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementSelectComponent extends AbstractSubscriptionComponent implements OnInit {
  value = [];

  @Input() form!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() name!: string;
  @Input() maxItems = 1;
  @Input() items: StatementInputOption[] | undefined = [];
  @Input() readonly = false;
  @Input() formSubmitted = false;
  @ViewChild('toClick') toClick!: ElementRef;
  @Output() dropdownOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly arrow = TUI_ARROW;

  constructor(public changes: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.setValue();
  }

  private handleFormControlValueChange(): void {
    const arr = this.form.get(this.controlName)?.value || [];
    const length = arr.length;
    if (length > this.maxItems && Array.isArray(arr)) {
      arr.shift();
      this.form.get(this.controlName)?.patchValue(arr, { emitEvent: false });
    }
    this.setValue();
  }

  setValue() {
    this.value = this.form?.get(this.controlName)?.value?.map((i) => i?.id) || [];
  }

  onChange(item: StatementInputOption) {
    const arr: StatementInputOption[] = this.form.get(this.controlName)?.value || [];
    let isInArr = -1;
    arr.map((i: StatementInputOption, index: number) => {
      if (i.id === item.id) isInArr = index;
    });
    isInArr !== -1 ? arr.splice(isInArr, 1) : arr.push(item);
    this.form.get(this.controlName)?.setValue(arr);
    this.handleFormControlValueChange();
    this.changes.detectChanges();
  }

  getValue() {
    return (this.form?.get(this.controlName)?.value?.map((i) => i?.name) || []).join(', ');
  }
}
