import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FunnelGraphicsComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-graphics/funnel-graphics.component';

@Injectable()
export class FunnelGraphicsCanDeactivateGuard implements CanDeactivate<FunnelGraphicsComponent> {
  canDeactivate(
    component: FunnelGraphicsComponent,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.graphicService.openConfirmExitModal();
  }
}
