import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { ActiveSubscriptionService } from '@shared/services/active-subscription.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HasActiveSubscriptionGuard implements CanActivate {
  constructor(private readonly activeSubscriptionService: ActiveSubscriptionService) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.activeSubscriptionService.getNoActiveSubscription) this.activeSubscriptionService.showPricing();
    return true;
  }
}
