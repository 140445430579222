import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { TacticListTypeEnum } from '@modules/graphql/graphql-types';
import { HeaderTab } from '@shared/modules/ui/components/header-tabs/header-tabs.component';

export const tacticsListTabsConst = (n: NavigateService, t: TranslateService, _modalList = false): HeaderTab[] => {
  const tabs = [
    {
      name: t.instant('Metatitles.Tactics.List of tactics'),
      route: n.getPath('/tactics/list/all'),
      type: TacticListTypeEnum.All,
    },
    {
      name: t.instant('Tactics.List.My tactics'),
      route: n.getPath('tactics/list/my-tactics'),
      type: TacticListTypeEnum.My,
    },
    {
      name: t.instant('Tactics.List.DF.AI tactics'),
      route: n.getPath('tactics/list/dfai'),
      type: TacticListTypeEnum.DigitalFirst,
    },
  ];

  return tabs;
};
