import { ChangeDetectorRef, Component, HostBinding, HostListener, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StatementSelectComponent } from '@modules/statement/shared/components/statement-select/statement-select.component';
import { StatementInputOption } from '../../models/statement.interface';

@Component({
  selector: 'df-statement-inline-value',
  templateUrl: './statement-inline-value.component.html',
  styleUrls: ['./statement-inline-value.component.scss'],
})
export class StatementInlineValueComponent {
  @Input() form!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() component!: StatementSelectComponent;

  constructor(private changes: ChangeDetectorRef) {}

  @HostBinding('class.hasValue')
  get hasValue() {
    return this.form?.get(this.controlName)?.value?.length;
  }

  @HostListener('click')
  onClick() {
    this.component?.toClick?.nativeElement?.click();
  }

  get items(): StatementInputOption[] {
    return this.form.get(this.controlName)?.value ?? [];
  }
}
