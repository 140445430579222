import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TacticAddComponent } from '../tactic-ad/tactic-ad.component';
import { TacticStepWrapperComponent } from '../tactic-step-wrapper/tactic-step-wrapper.component';
import { TacticNewsletterComponent } from '../tactic-newsletter/tactic-newsletter.component';
import { TacticLandingComponent } from '../tactic-landing/tactic-landing.component';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { SpecialTacticsService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-columns/components/tactic-columns/services/special-tactics.service';
import { BaseWorkFlowsService } from '@modules/work-flows/services/base-work-flows.service';

interface Items {
  ads: string[];
  images: string[];
}

export interface Email {
  title: string;
  description: string;
  call_to_action: string;
}

export enum SpecialTacticName {
  FacebbokAds = 'Facebook Ads',
  Email = 'Email',
  LandingPage = 'Landing Page',
  SocialPosts = 'Social Posts',
}

@Component({
  selector: 'df-tactic-columns',
  standalone: true,
  imports: [
    TacticAddComponent,
    TacticStepWrapperComponent,
    TacticNewsletterComponent,
    TacticLandingComponent,
    CommonModule,
    SharedModule,
  ],
  templateUrl: './tactic-columns.component.html',
  styleUrl: './tactic-columns.component.css',
})
export class TacticColumnsComponent implements OnInit {
  funnelId!: number;
  itemsAds!: Items;
  emailItem!: Email;
  type: 'ads' | 'newsletter' | 'landing' | 'social' = 'ads';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly cd: ChangeDetectorRef,
    private readonly specialTacticsService: SpecialTacticsService,
  ) {}

  @Input() funnelTactic?: FunnelTactic;

  isLoading = true;
  regeneratedAdds: number[] = [];
  isRegeneratingNewsletter = false;
  docName!: string;

  ngOnInit() {
    this.funnelId = +this.route.parent?.snapshot.params['id'];
    this.docName = localStorage.getItem(BaseWorkFlowsService.CURRENT_DOC_NAME_KEY)!;

    switch (this.funnelTactic?.tactic?.name) {
      case SpecialTacticName.FacebbokAds: {
        this.type = 'ads';
        break;
      }

      case SpecialTacticName.Email: {
        this.type = 'newsletter';
        break;
      }

      case SpecialTacticName.LandingPage: {
        this.type = 'landing';
        break;
      }

      case SpecialTacticName.SocialPosts: {
        this.type = 'social';
        break;
      }
    }

    if (this.type === 'ads' || this.type === 'social') this.generateAds();
    if (this.type === 'newsletter') this.generateNewsletter();
    if (this.type === 'landing') this.isLoading = false;
  }

  getText(text: string) {
    return text.replaceAll('"', '');
  }

  generateAds() {
    this.specialTacticsService.generateAds(this.docName, this.funnelId).subscribe((data) => {
      this.itemsAds = data;
      this.isLoading = false;
      this.cd.detectChanges();
    });
  }

  regenerateAdd(index: number) {
    this.regeneratedAdds.push(index);

    this.specialTacticsService.generateAds(this.docName, this.funnelId).subscribe((data) => {
      this.itemsAds.ads[index] = data.ads[index];
      this.itemsAds.images[index] = data.images[index];
      this.cd.detectChanges();
      this.regeneratedAdds = this.regeneratedAdds.filter((v) => v !== index);
    });
  }

  isAddRegenerating(index: number) {
    return this.regeneratedAdds.includes(index);
  }

  regenerateNewsletter() {
    this.isRegeneratingNewsletter = true;

    this.specialTacticsService.generateNewsletter(this.docName, this.funnelId).subscribe((data) => {
      this.emailItem = data;
      this.isRegeneratingNewsletter = false;
      this.cd.detectChanges();
    });
  }

  generateNewsletter() {
    this.specialTacticsService.generateNewsletter(this.docName, this.funnelId).subscribe((data) => {
      this.emailItem = data;
      this.isLoading = false;
      this.cd.detectChanges();
    });
  }
}
