<div class="w-full h-full flex flex-col justify-start items-stretch flex-nowrap">
  <div class="h-14 relative flex-shrink-0">
    <tui-island
      size="s"
      class="h-10 px-4 py-2.5 rounded-xl box-border absolute top-2 right-4"
      *ngIf="
        funnelContentGeneratorService.selectedActivity?.assets?.length && activeTab === TacticDetailsTabType.ASSETS
      "
    >
      <div class="flex w-full h-full items-center">
        <span class="w-full tui-text-01 tui-text_body-s mr-2 whitespace-nowrap">
          {{ 'Funnels.Manage.Expand all' | translate }}
        </span>
        <tui-toggle
          class="flex-shrink-0"
          [formControl]="expandAll"
          size="m"
        ></tui-toggle>
      </div>
    </tui-island>
  </div>
  <div class="flex-grow overflow-y-auto tui-base-10-bg">
    <ng-container *ngIf="funnelContentGeneratorService.selectedActivity as activity; else noData">
      <ng-container [ngSwitch]="activeTab">
        <ng-container *ngSwitchCase="TacticDetailsTabType.ASSETS">
          <ng-container *ngIf="activity.assets?.length; else noAssets">
            <div class="flex flex-col justify-start items-stretch gap-y-2 px-4 py-6 w-full">
              <div *ngFor="let asset of activity.assets; let first = first; let i = index">
                <df-content-generator-asset-tile
                  [asset]="asset"
                  [index]="i"
                ></df-content-generator-asset-tile>
              </div>
            </div>
          </ng-container>
          <ng-template #noAssets>
            <ng-container
              [ngTemplateOutlet]="placeholder"
              [ngTemplateOutletContext]="{ $implicit: 'Empty assets list' }"
            ></ng-container>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="TacticDetailsTabType.DESCRIPTION">
          <div class="flex flex-col items-stretch px-4 py-6">
            <section *ngIf="activity.description">
              <h3 class="tui-text-body-m-6 tui-text-03 font-semibold mb-2">
                {{ 'Tactics.Preview.Description' | translate }}
              </h3>
              <div
                class="tui-text_body-s tui-text-05"
                [innerHTML]="activity.description | paragraph"
              ></div>
            </section>

            <section
              *ngIf="activity.copy"
              class="mt-6"
            >
              <h3 class="tui-text-body-m-6 tui-text-03 font-semibold mb-2">
                {{ 'Tactics.Preview.Copy' | translate }}
              </h3>
              <div
                class="tui-text_body-s tui-text-05"
                [innerHTML]="activity.copy | paragraph"
              ></div>
            </section>

            <section
              *ngIf="activity.benchmarks?.length"
              class="mt-6"
            >
              <h3 class="tui-text-body-m-6 tui-text-03 font-semibold mb-2">
                {{ 'Tactics.Preview.Benchmarks' | translate }}
              </h3>
              <df-activity-benchmark-item
                *ngFor="let benchmark of activity.benchmarks"
                [benchmark]="benchmark"
                class="white"
              ></df-activity-benchmark-item>
            </section>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noData>
      <ng-container
        [ngTemplateOutlet]="placeholder"
        [ngTemplateOutletContext]="{
          $implicit: 'Your generated content will appear here'
        }"
      ></ng-container>
    </ng-template>
  </div>
</div>
<ng-template
  #placeholder
  let-text
>
  <div class="w-full h-full flex flex-col justify-center items-center">
    <img
      src="assets/img/folder.svg"
      alt="folder"
    />
    <p class="tui-text_body-m-1 tui-text-03 font-normal mt-12">
      {{ 'Funnels.Content generator.' + text | translate }}
    </p>
  </div>
</ng-template>
