import { Injectable } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { SetAffiliationIdMutation } from '@shared/graphql/mutations/set-affiliation-id.mutation.generated';
import { UserOutputGraphql } from '@modules/graphql/graphql-types';
import { User } from '@shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AffiliationService {
  get promoterId(): string | null {
    return this._promoterId;
  }

  set promoterId(value: string | null) {
    this._promoterId = value;
  }

  private _promoterId: string | null = null;

  constructor(
    private readonly userService: UserService,
    private readonly authStorageService: AuthStorageService,
  ) {}

  saveUserPromoter(): void {
    if (!!this.userService.User?.affiliationId || !this._promoterId || !this.authStorageService.jwt) return;
    this.userService
      .setAffiliationId(this._promoterId)
      .pipe(
        map((res: FetchResult<SetAffiliationIdMutation>) =>
          res.data ? (res.data.setAffiliationId as UserOutputGraphql) : null,
        ),
      )
      .subscribe((user: UserOutputGraphql | null) => {
        if (user) {
          this.userService.User = new User(user);
          this.promoterId = null;
        }
      });
  }
}
