<df-full-list-loader *ngIf="socialsLoading"></df-full-list-loader>

<form
  class="max-w-md m-auto flex flex-col py-8 px-4 pt-36"
  [formGroup]="formGroup"
  [validate-form]="formGroup"
  (submit)="signIn()"
>
  <div class="tui-form__row">
    <tui-input
      #emailInput
      [tuiTextfieldCustomContent]="getControl('email')?.valid ? success : ''"
      formControlName="email"
    >
      {{ 'email' | translate }}
    </tui-input>
    <df-error-input [control]="getControl('email')"></df-error-input>
    <ng-template #success>
      <tui-svg
        class="tui-accent-07 tui-space_left-3"
        src="tuiIconCheckLarge"
      ></tui-svg>
    </ng-template>
  </div>

  <div class="tui-form__row">
    <tui-input-password
      tuiHintContent
      tuiHintDirection="right"
      tuiHintMode="onDark"
      formControlName="password"
    >
      {{ 'password' | translate }}
    </tui-input-password>
  </div>

  <div class="tui-form__row flex justify-between flex-col sm:flex-row items-start sm:items-center mt-10">
    <tui-checkbox-labeled
      size="l"
      class="inline-flex"
      formControlName="rememberMe"
    >
      {{ 'Auth.Login.Keep me logged in' | translate }}
    </tui-checkbox-labeled>
    <a
      class="mt-5 sm:mt-0"
      tuiLink
      (click)="forgotPassword()"
      [ngClass]="{
        'forget-animation': wrongDataAmount % 2 === 0 && wrongDataAmount > 1
      }"
    >
      {{ 'Auth.Login.Forgot password' | translate }}
    </a>
  </div>

  <div class="tui-form__row mt-10">
    <button
      size="xl"
      tuiButton
      type="submit"
      class="w-full block"
      appearance="primary"
      [showLoader]="loading"
      [disabled]="loading || !formGroup.valid"
    >
      {{ 'Auth.Register.Sign in' | translate }}
    </button>
  </div>
  <hr class="mt-8" />
  <div class="social-login tui-form-row flex flex-col items-center mt-4">
    <span class="tui-text_body-m-2 tui-text-02 mb-4">
      {{ 'Auth.Login.Login with' | translate }}
    </span>
    <df-socials-authorization
      (signInWithGoogle)="signInWithGoogle()"
      (signInWithFacebook)="signInWithFB()"
    ></df-socials-authorization>
  </div>
  <div class="tui-form__row flex justify-between items-center mt-8">
    <span class="tui-text_body-m-2 tui-text-02">
      {{ "Auth.Login.Don't have an account yet" | translate }}
    </span>
    <a
      tuiLink
      [routerLink]="n.getPath('public/register')"
    >
      {{ 'Auth.Login.Sign up' | translate }}
    </a>
  </div>
</form>
