import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { filter, map, pluck } from 'rxjs/operators';
import { BenchmarkType } from '@shared/models/activity-benchmark.model';
import { BenchmarkTypeEnum } from '@modules/graphql/graphql-types';
import { Config } from '@shared/configs/config';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Regex } from '@shared/configs/regex';
import { EActivityBenchmarkForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-benchmark-form.enum';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { getBenchmarkForm } from '@modules/tactics/modules/tactic-settings/shared/helpers/tactic-form.helper';
import { ETacticControlState } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-edit-status.enum';
import { TacticActivitiesParametersService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-activities-parameters.service';

@Component({
  selector: 'df-activity-benchmark-modal',
  templateUrl: './activity-benchmark-modal.component.html',
  styleUrls: ['./activity-benchmark-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityBenchmarkModalComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly Config = Config;
  form: UntypedFormGroup = new UntypedFormGroup({});
  readonly EActivityBenchmarkForm: typeof EActivityBenchmarkForm = EActivityBenchmarkForm;
  readonly BenchmarkTypeEnum: typeof BenchmarkTypeEnum = BenchmarkTypeEnum;
  editMode = false;

  readonly types$: Observable<BenchmarkType[]>;

  get benchmarksArray(): UntypedFormArray | null {
    return this.context.data.benchmarksArray ?? null;
  }

  get benchmarkControl(): UntypedFormGroup | null {
    return this.context.data.benchmarkControl ?? null;
  }

  constructor(
    private s: SnackbarService,
    private t: TranslateService,
    private readonly changes: ChangeDetectorRef,
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<any, boolean>,
    private readonly tacticSettingsService: TacticSettingsService,
    private readonly tacticActivitiesParametersService: TacticActivitiesParametersService,
  ) {
    super();
    this.types$ = this.tacticActivitiesParametersService.benchmarkTypes$;
  }

  ngOnInit(): void {
    this._initForm();
    this._setFormMode();
    this._setFormListeners();
  }

  submit(): void {
    if (this.form.valid && (this.benchmarksArray || this.benchmarkControl)) {
      this.editMode
        ? this.tacticSettingsService.editBenchmark(this.benchmarkControl!)
        : this.tacticSettingsService.createBenchmark(this.benchmarksArray!, this.form);
      this.context.completeWith(this.form.get(EActivityBenchmarkForm.benchmark)?.value);
    } else {
      this.form.markAllAsTouched();
      this.changes.detectChanges();
    }
  }

  discard(): void {
    this.context.completeWith();
  }

  private _initForm(): void {
    this.form = this.benchmarkControl ?? getBenchmarkForm(ETacticControlState.CREATE);
  }

  private _setFormMode(): void {
    this.editMode = !!this.benchmarkControl;
  }

  private _setFormListeners(): void {
    this.sub.add(
      this.form.get(EActivityBenchmarkForm.benchmarkTypeId)?.valueChanges.subscribe((value: BenchmarkType | null) => {
        if (value) {
          if (value.type === BenchmarkTypeEnum.Link) {
            this.form.removeControl(EActivityBenchmarkForm.file);
            this.form.addControl(
              EActivityBenchmarkForm.link,
              new UntypedFormControl(null, [Validators.required, Validators.pattern(Regex.url)]),
            );
          } else {
            this.form.removeControl(EActivityBenchmarkForm.link);
            this.form.addControl(EActivityBenchmarkForm.file, new UntypedFormControl(null, [Validators.required]));
          }
        } else {
          this.form.removeControl(EActivityBenchmarkForm.file);
          this.form.removeControl(EActivityBenchmarkForm.link);
        }
      }),
    );
    this.sub.add(
      this.form.valueChanges
        .pipe(
          filter((data) => !!data.file),
          pluck('file'),
          map((file: File) => file.name.split('.').reverse()[0]),
        )
        .subscribe((ext: string) =>
          Config.FORBIDDEN_BENCHMARKS_EXTENSIONS.indexOf(ext) < 0 ? '' : this._rejectFile(),
        ),
    );
  }

  private _rejectFile(): void {
    this.s.error(
      this.t.instant('Tactics.Manage.Wrong file extensions. We do not accept: {{extensions}}.', {
        extensions: Config.FORBIDDEN_BENCHMARKS_EXTENSIONS,
      }),
    );
    this.form.get(EActivityBenchmarkForm.file)?.setValue(null, { emitEvent: false });
  }
}
