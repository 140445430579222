<div
  class="w-full h-full flex flex-col items-center justify-center"
  *ngIf="
    !funnelsListService.records.length &&
    !funnelsListService.loading &&
    !funnelsFiltersService.filterFormGroup.get('search')?.value?.length &&
    !funnelsFiltersService.filterFormGroup.get('projects')?.value?.length
  "
>
  <img
    src="/assets/img/funnels-empty.png"
    alt=""
  />
  <h5 class="tui-text_h5 tui-primary mt-4">
    {{ 'Funnels.List.You don’t have any funnel' | translate }}
  </h5>
  <p class="tui-text_body-m tui-text-03">
    {{ 'Funnels.List.Dont be shy, start creating' | translate }}
  </p>
  <button
    size="m"
    class="hidden sm:inline-flex mt-4"
    (click)="createNewFunnel()"
    tuiButton
    appearance="outline"
  >
    <svg-icon
      [src]="'/assets/img/ic_plus.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'Funnels.List.Create new funnel' | translate }}
  </button>
</div>

<div
  data-test="search-result-text"
  class="w-full h-full flex flex-col items-center justify-center"
  *ngIf="
    !funnelsListService.records.length &&
    !funnelsListService.loading &&
    (funnelsFiltersService.filterFormGroup.get('search')?.value?.length ||
      funnelsFiltersService.filterFormGroup.get('projects')?.value?.length)
  "
>
  <svg-icon
    [src]="'/assets/img/ic-no-records.svg'"
    [svgStyle]="{ width: '150px', height: '150px' }"
  ></svg-icon>
  <h5 class="tui-text_h5 tui-primary mt-4">
    {{ 'Funnels.List.No result' | translate }}
  </h5>
  <p class="tui-text_body-m tui-text-03">
    {{ 'Funnels.List.Try other filters or search name' | translate }}
  </p>
</div>
