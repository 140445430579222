import { Component, Input } from '@angular/core';

@Component({
  selector: 'df-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {
  @Input() showSkeleton = true;
  @Input() rows = 1;
  @Input() cols = 1;

  getFields() {
    const arr = [];
    arr.length = this.cols * this.rows;
    return arr;
  }
}
