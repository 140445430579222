import { gql } from 'apollo-angular';
import { ContentGenerationOutputFragment } from '@modules/funnels/shared/graphql/fragments/content-generation-output.fragment';

export const GenerateContentDocument = gql`
  mutation generateContent($input: GenerateContentInputGraphql!) {
    generateContent(input: $input) {
      ...ContentGenerationOutput
    }
  }
  ${ContentGenerationOutputFragment}
`;
