<div class="content flex w-full h-full">
  <div class="content__img-ctr hidden lg:flex w-5/12 h-full justify-center items-center">
    <img
      src="assets/img/books.png"
      alt="{{ 'Books' }}"
    />
  </div>

  <div class="content__text-ctr flex flex-col w-11/12 lg:w-6/12 min-h-full h-fit px-8 py-8">
    <h2 class="tui-text-01 tui-text_h2 mb-2 font-semibold">
      {{ 'Tactics.Preview.Content Access' | translate }}
    </h2>

    <p class="tui-text-05 tui-text_body-l-2 whitespace-pre-line">
      {{
        'Tactics.Preview.This feature is under your current plan. Change your plan to Pro to explore all features.'
          | translate
      }}
    </p>

    <p class="tui-text_body-m-1 mt-7 mb-1">
      {{ 'Tactics.Preview.After purchase you will access:' | translate }}
    </p>

    <div
      class="item px-3 py-3 my-2 flex items-center"
      *ngFor="let item of list"
    >
      <svg-icon
        [src]="'/assets/img/ic_check_square.svg'"
        class="icon mr-2"
      ></svg-icon>
      <div class="tui-text_body-m-1 inline-block w-9/10 sm:w-full">
        {{ item | translate }}
      </div>
    </div>

    <div class="buttons flex mt-auto">
      <button
        class="mb-4 sm:mb-0 mr-3"
        size="l"
        tuiButton
        type="submit"
        (click)="close()"
        appearance="outline"
      >
        {{ 'Tactics.Preview.No, thanks' | translate }}
      </button>
      <button
        size="l"
        tuiButton
        type="submit"
        (click)="goToPackets()"
        appearance="primary"
      >
        {{ 'Tactics.Preview.View pricing' | translate }}
      </button>
    </div>
  </div>

  <div class="content__close-btn w1/12 ml-auto">
    <button
      class="mt-5 mr-5"
      tuiIconButton
      type="button"
      appearance="outline"
      [icon]="icon"
      (click)="close()"
    ></button>
    <ng-template #icon>
      <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
    </ng-template>
  </div>
</div>
