<button
  data-test="add-content-button"
  tuiIconButton
  type="button"
  size="xl"
  class="float-button fixed bottom-2 md:bottom-10 z-50 right-6 rounded-full shadow-lg"
  [icon]="icon"
></button>
<ng-template #icon>
  <svg-icon [src]="'/assets/img/ic-add.svg'"></svg-icon>
</ng-template>
