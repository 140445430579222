import { gql } from 'apollo-angular';

export const FunnelFragment = gql/* GraphQL*/ `
  fragment Funnel on FunnelOutputGraphql {
    id
    name
    isFavourite
    createdAt
    modifiedAt
    isPublic
    lastEditor {
      id
      firstName
      lastName
      avatar {
        file
      }
    }
    stats {
      allCount
      doneCount
      step {
        id
        name
        type
      }
    }
    project {
      id
      name
    }
    image {
      id
      file
    }
    currentUserPermission
    owner {
      id
      firstName
      lastName
      avatar {
        file
      }
      firstFunnel {
        id
      }
    }
    flows {
      id
      name
      items {
        from
        to
      }
    }
    shares {
      id
      email
    }
    tactics {
      id
      position
      name
      isDone
      doneActivities {
        activity {
          id
        }
      }
      step {
        id
      }
      selectedOutputsData {
        id
        activityAsset {
          id
        }
      }
      tactic {
        id
        name
        activities {
          id
          name
          assets {
            id
          }
        }
        type {
          name
        }
        effects {
          name
        }
        funnelSteps {
          id
          type
          name
        }
        assetsCount
        badge
      }
    }
    doneTacticsCount
    doneActivitiesCount
    recommendationsCount
  }
`;
