import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { Config } from '@shared/configs/config';
import { TuiFileLike } from '@taiga-ui/kit';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Funnel } from '@shared/models/funnel.model';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { FilterProjectsDocument } from '@modules/funnels/shared/graphql/queries/filter-projects.query';
import { FetchResult } from '@apollo/client/core';
import { CreateFunnelMutation } from '@modules/funnels/shared/graphql/mutations/create-funnel.mutation.generated';
import { AttachmentOutputGraphql, FunnelInputGraphql } from '@modules/graphql/graphql-types';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { EditFunnelMutation } from '@modules/funnels/shared/graphql/mutations/edit-funnel.mutation.generated';
import { forkJoin } from 'rxjs';
import { CreateFunnelImageMutation } from '@modules/funnels/shared/graphql/mutations/create-funnel-image.mutation.generated';
import { BasePanelEvent, BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { UserService } from '@shared/services/user.service';
import { rejectedImages } from '@shared/helpers/file-input.helper';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';

@Component({
  selector: 'df-funnel-data-modal',
  templateUrl: './funnel-data-modal.component.html',
  styleUrls: ['./funnel-data-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelDataModalComponent implements OnInit {
  Config = Config;
  FilterProjectsDocument = FilterProjectsDocument;

  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern(Regex.whiteSpace),
    ]),
    projectName: new UntypedFormControl(null),
    image: new UntypedFormControl(''),
  });
  loading = false;

  constructor(
    private s: SnackbarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private n: NavigateService,
    public funnelManageService: FunnelManageService,
    private funnelGraphqlService: FunnelGraphqlService,
    private basePanelService: BasePanelService,
    private userService: UserService,
    private eventsService: EventsService,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { funnel: Funnel } }, boolean>,
  ) {}

  ngOnInit(): void {
    if (this.context.data?.funnel) {
      this.setDataToForm(this.context.data.funnel as Funnel);
    }
  }

  setDataToForm(funnel: Funnel) {
    this.form.patchValue({
      name: funnel.name,
      projectName: funnel.project ? [funnel.project.name] : [],
    });
    this.changes.detectChanges();
  }

  rejectedImages(e: TuiFileLike | readonly TuiFileLike[]) {
    rejectedImages(e, this.s, this.t);
    this.changes.detectChanges();
  }

  save() {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;
    this.loading = true;
    this.changes.detectChanges();
    if (this.funnelManageService.funnel?.id) {
      this.editFunnel();
    } else {
      this.createFunnel();
    }
  }

  createFunnel() {
    this.funnelGraphqlService
      .createFunnel(this.getFormObject(), this.getImage())
      .subscribe({
        next: (res: FetchResult<CreateFunnelMutation>) => {
          this.n.go('funnels/f/d/:id', { id: res.data?.createFunnel.id });
          this.basePanelService.emitter.emit(BasePanelEvent.RELOAD_FUNNELS_TO_CONTEXT);
          this.context.completeWith(true);
          const user = this.userService.User!;
          user.funnelsCount++;
          this.userService.User = user;
          this.eventsService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.FunnelAdd, {
            userID: this.userService.User?.id,
          });

          if (res.data?.createFunnel.id === res.data?.createFunnel.owner.firstFunnel?.id) {
            this.eventsService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.FunnelNew, {
              userID: this.userService.User?.id,
            });
          }
        },
        error: () => {
          this.s.error(this.t.instant('Funnels.Manage.There is problem with creating new funnel. Try again.'));
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  editFunnel() {
    const editObs = {
      editing: this.funnelGraphqlService.editFunnel(this.getFormObject(), this.funnelManageService.funnel!.id),
    };
    this.form.get('image')?.value
      ? (editObs['image'] = this.funnelGraphqlService.createFunnelImage(
          this.funnelManageService.funnel!.id,
          this.getImage(),
        ))
      : '';

    forkJoin(editObs)
      .subscribe({
        next: (res: { editing: FetchResult<EditFunnelMutation>; image?: FetchResult<CreateFunnelImageMutation> }) => {
          this.s.success(this.t.instant("Funnels.Manage.Funnel's data saved successfully."));
          this.funnelManageService.funnel = res.editing.data?.editFunnel as Funnel;
          res.image
            ? (this.funnelManageService!.funnel.image = res.image.data?.createFunnelImage as AttachmentOutputGraphql)
            : '';
          this.basePanelService.emitter.emit(BasePanelEvent.RELOAD_FUNNELS_TO_CONTEXT);
          this.context.completeWith(true);
        },
        error: () => {
          this.s.error(this.t.instant("Funnels.Manage.There is problem with editing funnel's data. Try again."));
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  getFormObject(): FunnelInputGraphql {
    return {
      name: this.form.get('name')?.value,
      projectName: this.form.get('projectName')?.value?.length ? this.form.get('projectName')?.value[0] : '',
    };
  }

  getImage() {
    return this.form.get('image')?.value ? this.form.get('image')?.value : null;
  }

  cancel() {
    this.context.completeWith(false);
  }
}
