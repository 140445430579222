<div class="auth-panel flex justify-center items-start lg:items-center min-h-screen flex-col p-10">
  <div class="auth-panel__container flex flex-col justify-start items-start">
    <img
      [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
      [alt]="xPartnerService.partner.SYSTEM_NAME"
      class="login__logo mb-0"
    />
    <h1 class="tui-text_h2 font-semibold tui-text-03 pl-0 pr-0 mt-4 w-auto">
      {{ 'Auth.NoAccess.title' | translate }}
    </h1>
    <p class="tui-text-02">
      {{ 'Auth.NoAccess.description' | translate }}
    </p>
    <div class="tui-form__row mt-10">
      <button
        [routerLink]="n.getPath(Config.MAIN_VIEW)"
        size="xl"
        tuiButton
        type="submit"
        class="w-full block"
        appearance="primary"
      >
        {{ 'Auth.NoAccess.button' | translate }}
      </button>
    </div>
  </div>
</div>
