import { gql } from 'apollo-angular';

export const GetAssetsPlacementsDocument =
  gql(/* GraphQL*/ `query getAssetsPlacements($input: AssetsPlacementsListInputGraphql!)
{
  getAssetsPlacements(input: $input)
  {
    id
    name
  }
}

`);
