import { gql } from 'apollo-angular';

export const AddTeamMembersDocument = gql`
  mutation addTeamMembers($input: AddMembersInputGraphql!) {
    addTeamMembers(input: $input) {
      id
      role
      email
      user {
        id
        firstName
        lastName
        email
        avatar {
          id
          file
        }
      }
    }
  }
`;
