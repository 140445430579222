<df-full-list-loader *ngIf="loading$ | async"></df-full-list-loader>

<div
  *ngIf="!inCampaign"
  class="funnel-preview-header flex items-center justify-center"
>
  <p class="funnel-preview-header__desc">
    Create account to get access to biggest marketing tactics library, manage own funnels and use AI tools to automate
    marketing execution.
    <a
      target="_blank"
      href="https://app.digitalfirst.ai/public/pricing"
      >🔥 Free trial 🔥</a
    >
  </p>
</div>

<div
  *ngIf="!inCampaign"
  class="flex flex-row justify-between items-center funnel-preview-name"
>
  <h1>{{ funnelManageService.funnel?.name }}</h1>
  <div>
    <button
      class="mr-4"
      tuiButton
      (click)="showEmailDialog()"
    >
      Send by email
    </button>
    <button
      tuiButton
      (click)="addFunnelToClientLibrary()"
    >
      Get funnel
    </button>
  </div>
</div>

<div class="funnel-preview-wrapper">
  <swiper
    #swiperComponent
    cdkDropListGroup
    class="pt-2 w-full funnel__container"
    [config]="swiperFunnelConfig"
    [spaceBetween]="0"
  >
    <ng-template
      swiperSlide
      *ngFor="let step of steps ?? []"
    >
      <tui-island
        [size]="'s'"
        class="column flex flex-col items-start justify-start h-full  ml-2 mr-2 {{ step.type }}"
        [ngClass]="{
          'column--empty': !funnelManageService.funnel?.tactics?.length && !funnelManageService.loading
        }"
      >
        <df-funnel-manage-content-step-header
          [showDoneCount]="false"
          [step]="step"
        ></df-funnel-manage-content-step-header>
        <div
          cdkDropList
          [cdkDropListData]="{ step: step }"
          class="mt-4 pl-2 pr-2 w-full"
          *ngFor="let funnelTactic of sortedFunnelTactics(step)"
        >
          <div class="flex items-center justify-between w-full public-funnel-items tile {{ step.type }}">
            <div class="flex flex-col items-center flex-nowrap w-5/6">
              <div class="flex flex-row mb-1 h-6 flex-shrink w-full">
                <svg-icon
                  [src]="'/assets/img/ic-' + step.type + '.svg'"
                  class="mr-2"
                  [ngClass]="{ 'opacity-70': funnelTactic.isDone }"
                  *ngFor="let step of funnelTactic.tactic.funnelSteps"
                ></svg-icon>

                <div
                  class="ml-2 font-bold truncate rounded-md tui-base-02 flex flex-shrink items-center h-6 pl-2 pr-2 uppercase tui-text_body-xs tui-text-02"
                  *ngIf="funnelTactic.tactic?.type"
                  [ngClass]="{
                    'tui-support-23-bg opacity-70': funnelTactic.isDone
                  }"
                >
                  <span class="truncate">{{ funnelTactic.tactic!.type!.name }}</span>
                </div>
                <div
                  class="ml-2 font-bold truncate rounded-md tui-base-02 flex flex-shrink items-center h-6 pl-2 pr-2 uppercase tui-text_body-xs tui-text-02"
                  *ngIf="funnelTactic.tactic?.effects?.length"
                  [ngClass]="{
                    'tui-support-23-bg opacity-70': funnelTactic.isDone
                  }"
                >
                  <span class="truncate">{{ funnelTactic.tactic!.effects[0].name }}</span>
                </div>
              </div>

              <df-public-funnel-modal
                [inCampaign]="inCampaign"
                (tacticClicked)="showTacticsPreviewDialog()"
                [funnelTactic]="funnelTactic"
              ></df-public-funnel-modal>
              <p
                class="tui-text-04 tui-text_body-s-2 font-normal -mt-2.5 mb-3"
                *ngIf="!!funnelTactic.name"
              >
                {{ funnelTactic.tactic.name }}
              </p>
              <df-funnel-manage-tactic-activities
                [expandAll]="true"
                class="mt-2 w-full"
                [previewMode]="true"
                #activities
                [funnelTactic]="funnelTactic"
              ></df-funnel-manage-tactic-activities>
            </div>
          </div>
        </div>
      </tui-island>
    </ng-template>
  </swiper>
</div>
