import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { BasePanelEvent, BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { UserService } from '@shared/services/user.service';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext } from '@taiga-ui/core';

@Component({
  selector: 'df-funnel-confirm-remove-modal',
  templateUrl: './funnel-confirm-remove-modal.component.html',
  styleUrls: ['./funnel-confirm-remove-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelConfirmRemoveModalComponent {
  loading = false;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<boolean>,
    private t: TranslateService,
    public n: NavigateService,
    private s: SnackbarService,
    public funnelManageService: FunnelManageService,
    public funnelFlowManageService: FunnelFlowManageService,
    private funnelGraphqlService: FunnelGraphqlService,
    private basePanelService: BasePanelService,
    private userService: UserService,
    private changes: ChangeDetectorRef,
  ) {}

  close(): void {
    this.context.completeWith(false);
  }

  confirm(): void {
    this.funnelManageService.loading = true;
    this.loading = true;
    this.changes.detectChanges();
    this.funnelGraphqlService.removeFunnel(this.funnelManageService.funnel!.id).subscribe(() => {
      this.s.success(this.t.instant('Funnels.List.Funnel removed successfully'));
      this.basePanelService.emitter.emit(BasePanelEvent.RELOAD_FUNNELS_TO_CONTEXT);
      const user = this.userService.User!;
      user.funnelsCount--;
      this.userService.User = user;
      this.context.completeWith(true);
    });
  }
}
