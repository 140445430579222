import { gql } from 'apollo-angular';

export const GetPostDocument = gql`
  query getPost($id: String!) {
    getPost(id: $id) {
      id
      post
      platforms
      status
      created
      mediaUrls
    }
  }
`;
