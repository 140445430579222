import { gql } from 'apollo-angular';
import { TeamFragment } from '@modules/user-settings/modules/teams/shared/graphql/fragments/team.fragment';

export const CreateTeamDocument = gql`
  mutation createTeam($input: CreateTeamInputGraphql!, $icon: Upload) {
    createTeam(input: $input, icon: $icon) {
      ...TeamFragment
    }
  }
  ${TeamFragment}
`;
