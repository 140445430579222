import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetsComponent } from './assets/assets.component';
import { AssetTileComponent } from './assets/components/asset-tile/asset-tile.component';
import { SharedModule } from '@shared/shared.module';
import { TuiScrollbarModule } from '@taiga-ui/core';

@NgModule({
  declarations: [AssetsComponent, AssetTileComponent],
  imports: [CommonModule, SharedModule, TuiScrollbarModule],
  exports: [AssetsComponent, AssetTileComponent],
})
export class AssetsModule {}
