import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Regex } from '@shared/configs/regex';
import { debounceTime } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'df-error-input',
  templateUrl: './error-input.component.html',
  styleUrls: ['./error-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe],
})
export class ErrorInputComponent implements OnDestroy, AfterViewInit {
  private sub: Subscription = new Subscription();
  private _manualShowingError: boolean | null = null;
  private _listenFocusChanges: boolean | null = null;
  private tuiInput?: HTMLElement;

  show = false;
  hasBeenFocusedOut = false;

  @Input() control: AbstractControl | null = null;
  @Input() errorMessage = '';
  @Input() skipTuiInputCheck = false;

  @Input()
  set listenFocusChanges(value: boolean) {
    this._listenFocusChanges = value;
    this.listenFocusOutEvent();
  }

  @Input()
  set manualShowingError(value: boolean) {
    this._manualShowingError = value;
    this.setErrorVisible();
  }

  constructor(
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private decimalPipe: DecimalPipe,
  ) {}

  ngAfterViewInit() {
    this.listenValueChange();
    this.listenFocusOutEvent();
  }

  listenFocusOutEvent() {
    if (this.control && this.control.parent) {
      if (this.control && !this.control.parent['nativeElement']) {
        return;
      }

      this.tuiInput =
        this.control.parent['nativeElement'].querySelector('[formControlName="' + this.getControlName() + '"]') ||
        this.control.parent['nativeElement'].querySelector('[data-error-control="' + this.getControlName() + '"]') ||
        this.control.parent['nativeElement'].querySelector('[ng-reflect-name="' + this.getControlName() + '"]');
      this.listenTouchedChange();

      const nativeElement =
        this.control.parent['nativeElement'].querySelector('[formControlName="' + this.getControlName() + '"] input') ||
        this.control.parent['nativeElement'].querySelector('[ng-reflect-name="' + this.getControlName() + '"] input');

      if (nativeElement) {
        const listen: boolean = this._listenFocusChanges === null ? true : this._listenFocusChanges;
        if (listen) {
          nativeElement.addEventListener('focusout', () => {
            this.hasBeenFocusedOut = true;
            this.setErrorVisible();
          });
        } else {
          nativeElement.removeEventListener('focusout');
        }
      } else {
        console.warn('There is problem with native element to find for:', this.getControlName());
      }
    }
  }

  listenValueChange() {
    if (!this.control) {
      console.error('No form control provided in df-error-input');
      return;
    }

    const subValue = this.control.valueChanges.pipe(debounceTime(300)).subscribe(this.setErrorVisible.bind(this));

    this.sub.add(subValue);
  }

  listenTouchedChange() {
    if (this.tuiInput) {
      const observer = new MutationObserver(() => {
        if (this.tuiInput?.classList.contains('ng-touched')) {
          this.hasBeenFocusedOut = true;
          this.setErrorVisible();
          observer.disconnect();
        }
      });
      observer.observe(this.tuiInput as Node, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }
  }

  getErrorMessage() {
    if (this.errorMessage) {
      return this.errorMessage;
    }

    if (this.control && !this.control.valid) {
      return this.getDefaultErrorMessages();
    }
  }

  getDefaultErrorMessages() {
    if (this.control?.errors?.required) {
      return this.t.instant('Validation.This field is required');
    }

    if (this.control?.errors?.pattern) {
      if (this.control?.errors?.pattern.requiredPattern === Regex.whiteSpace.toString()) {
        return this.t.instant('Validation.Value contains only spaces');
      }

      if (this.control?.errors?.pattern.requiredPattern === Regex.email.toString()) {
        return this.t.instant('Validation.Provide correct e-mail address');
      }

      if (this.control?.errors?.pattern.requiredPattern === Regex.password.toString()) {
        return this.t.instant('Validation.Password required: 7 characters, one number and one special character');
      }

      if (this.control?.errors?.pattern.requiredPattern === Regex.url.toString()) {
        return this.t.instant("Validation.It's not correct website address");
      }
    }

    if (this.control?.errors?.minlength) {
      return this.t.instant('Validation.Required min :count chars', {
        count: this.control?.errors?.minlength.requiredLength,
      });
    }

    if (this.control?.errors?.maxlength) {
      return this.t.instant('Validation.Required max :count chars', {
        count: this.control?.errors?.maxlength.requiredLength,
      });
    }

    if (this.control?.errors?.codeInvalid) {
      return this.t.instant('Validation.Promocode invalid');
    }

    if (this.control?.errors?.confirmPassword) {
      return this.t.instant('Validation.Password confirmation invalid');
    }

    if (this.control?.errors?.greaterThan) {
      return this.t.instant('Validation.Value must be greater than :value', {
        value: this.control?.errors?.greaterThan.value,
      });
    }

    if (this.control?.errors?.max) {
      return this.t.instant('Validation.Maximum value is :value', {
        value: this.decimalPipe.transform(this.control?.errors?.max.max),
      });
    }

    return this.t.instant('Validation.Wrong value');
  }

  setErrorVisible(skipFirstFocus = false) {
    if (!this.skipTuiInputCheck && !skipFirstFocus && !(this.hasBeenFocusedOut || !this.tuiInput)) return;
    if (this._manualShowingError !== null) {
      this.show = this._manualShowingError;
      this.changes.detectChanges();
      return;
    }

    this.show = !this.control?.valid as boolean;
    this.changes.detectChanges();
  }

  private getControlName(): string | null {
    if (!this.control) return '';

    const group = <UntypedFormGroup>this.control.parent;

    if (!group) {
      return null;
    }

    let name = '';

    Object.keys(group.controls).forEach((key) => {
      const childControl = group.get(key);

      if (childControl !== this.control) {
        return;
      }

      name = key;
    });

    return name;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
