import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getAppFeaturesList } from '@modules/authorization/shared/consts/onboarding-features.const';

@Component({
  selector: 'df-onboarding-features-list',
  templateUrl: './onboarding-features-list.component.html',
  styleUrls: ['./onboarding-features-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingFeaturesListComponent {
  readonly features;
  constructor(private readonly t: TranslateService) {
    this.features = getAppFeaturesList(this.t);
  }
}
