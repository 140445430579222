import { Component } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'df-landing-page',
  templateUrl: './landing-page.component.html',
})
export class LandingPageComponent {
  readonly funnelId: number;

  constructor(
    private readonly n: NavigateService,
    private route: ActivatedRoute,
  ) {
    this.funnelId = this.route?.parent?.snapshot.params['id'];
  }
}
