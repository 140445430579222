import { BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { NavigateService } from '@core/routes/services/navigate.service';

export enum HelpItemType {
  HELP = 'help',
  TUTORIALS = 'tutorials',
  FAQ = 'faq',
}

export const helpItemConst = (basePanelService: BasePanelService, n: NavigateService): HelpItem[] => {
  return [
    {
      name: 'help',
      type: HelpItemType.HELP,
      title: 'Funnels.Overview.Help center',
      subtitle: 'Funnels.Overview.Help center sub',
      icon: 'assets/img/ic_headphones.svg',
      onRedirect() {
        window.open('https://help.digitalfirst.ai/', '_blank');
      },
    },
    {
      name: 'tutorials',
      type: HelpItemType.TUTORIALS,
      title: 'Funnels.Overview.Tutorials',
      subtitle: 'Funnels.Overview.Tutorials sub',
      icon: 'assets/img/ic_presentation_play.svg',
      onRedirect() {
        window.open('https://www.youtube.com/playlist?list=PLaj6572KCsJTEQJ19Opr8K92HaFfKeIEO ', '_blank');
      },
    },
    {
      name: 'faq',
      type: HelpItemType.FAQ,
      title: 'Funnels.Overview.FAQ',
      subtitle: 'Funnels.Overview.FAQ sub',
      icon: 'assets/img/ic_help.svg',
      onRedirect() {
        window.open(
          'https://help.digitalfirst.ai/en/articles/9076595-get-answers-to-frequently-asked-questions-general',
          '_blank',
        );
      },
    },
  ];
};

export interface HelpItem {
  name: string;
  title: string;
  subtitle: string;
  icon: string;
  type: HelpItemType;
  onRedirect(): void;
}
