import { gql } from 'apollo-angular';
import { IntegrationFragment } from '@modules/funnels/shared/graphql/fragments/integration.fragment';

export const GetIntegrationsDocument = gql`
  query getIntegrations($integrationType: IntegrationTypeEnum!) {
    getIntegrations(integrationType: $integrationType) {
      ...Integration
    }
  }
  ${IntegrationFragment}
`;
