<div
  class="loading"
  *ngIf="isLoading"
>
  <ng-container *ngTemplateOutlet="loadingTemplate ? loadingTemplate : defaultLoading"></ng-container>
</div>
<div
  class="error"
  *ngIf="hasError"
>
  <ng-container *ngTemplateOutlet="errorTemplate ? errorTemplate : defaultError"></ng-container>
</div>

<ng-template #defaultLoading>
  <p>{{ 'loading' | translate }}</p>
</ng-template>

<ng-template #defaultError>
  <p>{{ 'error' | translate }}</p>
</ng-template>

<div [ngStyle]="{ border: borderCss }">
  <canvas id="canvas"></canvas>
</div>
<div
  class="toolbar"
  *ngIf="!isLoading"
>
  <div class="tools">
    <div class="row">
      <span
        *ngFor="let drawingSizeName of drawingSizesName"
        class="size btn"
        [style.width.px]="drawingSizes[drawingSizeName] * 0.8 + 8"
        [style.height.px]="drawingSizes[drawingSizeName] * 0.8 + 8"
        [style.borderRadius.px]="drawingSizes[drawingSizeName] * 0.4 + 4"
        [class.selected]="currentSize === drawingSizeName"
        [title]="'title' | translate"
        (click)="selectDrawingSize(drawingSizeName)"
      >
      </span>

      <input
        style="display: none"
        type="file"
        #fileInput
        (change)="importPhotoFromFile($event)"
        accept="image/*"
      />
      <i
        class="material-icons btn"
        *ngIf="enableLoadAnotherImage && !hasImage"
        (click)="fileInput.click()"
        [title]="'file' | translate"
        >attach_file</i
      >
      <i
        class="material-icons btn"
        *ngIf="enableLoadAnotherImage && !hasImage"
        (click)="importPhotoFromUrl()"
        [title]="'drive file' | translate"
        >insert_drive_file</i
      >
      <i
        class="material-icons btn"
        *ngIf="enableRemoveImage && hasImage"
        (click)="removeImage()"
        [title]="'clear' | translate"
        >clear</i
      >

      <i
        class="material-icons btn"
        (click)="clearCanvas()"
        [title]="'delete' | translate"
        >delete</i
      >
    </div>
  </div>
  <div class="buttons">
    <a
      href="#"
      class="button btn-primary"
      (click)="saveImage(); $event.preventDefault()"
      >{{ 'save' | translate }}</a
    >
    <a
      href="#"
      class="button btn-light"
      *ngIf="showCancelButton"
      (click)="cancelAction(); $event.preventDefault()"
      >{{ 'cancle' | translate }}</a
    >
  </div>

  <ng-content></ng-content>
</div>
