import { gql } from 'apollo-angular';

export const PublicPostDocument = gql`
  mutation publishPost($input: AyrshareCreatePostInputGraphql!) {
    publishPost(input: $input) {
      status
      id
      post
    }
  }
`;
