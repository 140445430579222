<!--    only if it's modal to copy activities from tactic -->
<div
  class="tactic-tile-actions w-full flex flex-row items-center justify-end relative"
  *ngIf="toCopy"
>
  <button
    tuiButton
    type="button"
    appearance="secondary"
    (click)="$event.stopPropagation(); chooseToCopy.emit(tactic)"
  >
    {{ 'Tactics.Manage.copy from this' | translate }}
  </button>
</div>

<!--    only if it's not modal to copy activities from tactic -->
<div *ngIf="!toCopy">
  <!--    only for data list modal and adding tactic to my funnel to static step-->
  <div
    class="tactic-tile-actions w-full flex flex-row items-center justify-end relative"
    *ngIf="funnel"
  >
    <ng-container
      *ngIf="
        (step && tactic && funnel && funnel.tacticsStepMap[step.id] && !funnel.tacticsStepMap[step.id][tactic.id]) ||
        !funnel.tacticsStepMap[step!.id]
      "
    >
      <ng-container *ngTemplateOutlet="addToFunnelButton"></ng-container>
    </ng-container>
  </div>

  <!--    actions for typically tile list -->
  <div
    class="tactic-tile-actions w-full flex flex-row items-center justify-end relative"
    *ngIf="!funnel"
  >
    <ng-container *ngIf="preventAction">
      <ng-container *ngTemplateOutlet="addToFunnelButton"></ng-container>
    </ng-container>
    <ng-container *ngIf="canBeAdded && !preventAction">
      <df-add-to-funnel-button
        [tactic]="tactic!"
        [type]="AddToFunnelButtonType.ICON"
      ></df-add-to-funnel-button>
    </ng-container>

    <ng-container *ngIf="!isRemoveFromFunnelAction">
      <button
        *ngIf="tactic?.currentUserPermission === FunnelPermissionEnum.Editor"
        tuiIconButton
        type="button"
        class="ml-2"
        appearance="secondary"
        [icon]="icon"
        (click)="$event.stopPropagation(); edit.emit(tactic)"
      ></button>
      <ng-template #icon>
        <svg-icon [src]="'/assets/img/ic-edit.svg'"></svg-icon>
      </ng-template>
    </ng-container>

    <button
      (click)="$event.stopPropagation()"
      *ngIf="!isRemoveFromFunnelAction && tactic?.currentUserPermission === FunnelPermissionEnum.Editor"
      mwlConfirmationPopover
      [popoverTitle]="'Tactics.List.Removing tactic' | translate"
      [popoverMessage]="'Tactics.List.Do you really want to remove this tactic?' | translate"
      [appendToBody]="true"
      [closeOnOutsideClick]="true"
      placement="top"
      popoverClass="confirmation-popover"
      (confirm)="remove.emit()"
      class="ml-2 tui-accent"
      tuiIconButton
      type="button"
      appearance="secondary"
      [icon]="iconTrash"
    ></button>
    <ng-template #iconTrash>
      <svg-icon [src]="'/assets/img/ic-trash.svg'"></svg-icon>
    </ng-template>
  </div>
</div>
<ng-template #addToFunnelButton>
  <button
    tuiHostedDropdownHost
    tuiIconButton
    type="button"
    appearance="secondary"
    [icon]="iconTooltip"
    (click)="$event.stopPropagation(); addToFunnelEvent.emit(tactic)"
  ></button>
  <ng-template #iconTooltip>
    <svg-icon [src]="'/assets/img/funnel-add-small.svg'"></svg-icon>
  </ng-template>
</ng-template>
