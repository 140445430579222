import { DialogTallyRecComponent } from './../../../modules/tactics-list/shared/components/dialog-tally-rec/dialog-tally-rec.component';
import { TuiDialogService } from '@taiga-ui/core';
import { Component, HostListener, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'df-tactic-rec-tile',
  templateUrl: './tactic-rec-tile.component.html',
  styleUrls: ['./tactic-rec-tile.component.scss'],
})
export class TacticRecTileComponent {
  constructor(
    private dialogService: TuiDialogService,
    private injector: Injector,
  ) {}

  @HostListener('click')
  openTallyModal() {
    return this.dialogService
      .open<boolean>(new PolymorpheusComponent(DialogTallyRecComponent, this.injector), {
        dismissible: false,
        size: 'm',
      })
      .subscribe();
  }
}
