<div class="w-full sm:w-3/4 m-auto flex flex-col">
  <p class="tui-text_body-m-2 text-center mb-10">
    {{ 'Auth.Reset Password.description' | translate }}
  </p>
  <form
    [formGroup]="formGroup"
    [validate-form]="formGroup"
    (submit)="submit()"
  >
    <div class="tui-form__row">
      <tui-input-password
        tuiHintContent
        tuiHintDirection="right"
        tuiHintMode="onDark"
        formControlName="password"
      >
        {{ 'new password' | translate }}
      </tui-input-password>
      <df-error-input [control]="formGroup.get('password')"></df-error-input>
    </div>

    <div class="tui-form__row mt-10 flex justify-center">
      <button
        size="xl"
        tuiButton
        type="submit"
        appearance="primary"
        [showLoader]="loading"
        [disabled]="loading || !formGroup.valid"
      >
        {{ 'submit' | translate }}
      </button>
    </div>
  </form>
</div>
