import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { GetContentGenerationIntegrationsQuery } from '@modules/funnels/modules/funnel-manage/shared/graphql/queries/get-content-generation-integrations.query.generated';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { Integration } from '@shared/models/integration.model';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FunnelContentGeneratorLibraryService } from '../../funnel-content-generator-library/services/funnel-content-generator-library.service';
import { IntegrationTemplate, IntegrationTemplateCategory } from '../models/funnel-content-generator.interface';

enum GeneratorIntegrationPartIndex {
  CATEGORIES = 0,
  INTEGRATIONS = 1,
}
@Injectable({
  providedIn: 'root',
})
export class ContentGeneratorTemplatesService {
  integrations: Integration[] = [];

  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  private readonly _collection$: BehaviorSubject<IntegrationTemplate[]> = new BehaviorSubject<IntegrationTemplate[]>(
    [],
  );
  readonly collection$: Observable<IntegrationTemplate[]> = this._collection$.asObservable();

  constructor(
    private readonly funnelGraphqlService: FunnelGraphqlService,
    private readonly funnelLibraryService: FunnelContentGeneratorLibraryService,
  ) {}

  public getGeneratorIntegrations(): void {
    this._loading$.next(true);
    const generatorIntegrationParts = {};
    generatorIntegrationParts[GeneratorIntegrationPartIndex.CATEGORIES] = this.funnelLibraryService.fetchCategories();
    generatorIntegrationParts[GeneratorIntegrationPartIndex.INTEGRATIONS] = this.fetchIntegrations();
    forkJoin(generatorIntegrationParts).subscribe({
      next: (data) => this.handleResponseData(data),
      error: () => this._loading$.next(false),
      complete: () => this._loading$.next(false),
    });
  }

  private fetchIntegrations() {
    return this.funnelGraphqlService
      .getGeneratorIntegrations()
      .pipe(
        map((res: FetchResult<GetContentGenerationIntegrationsQuery>) =>
          res.data ? (res.data.getContentGenerationIntegrations as Integration[]) : [],
        ),
      );
  }

  private handleResponseData(data: any[]): void {
    if (!data || data.length < Object.keys(GeneratorIntegrationPartIndex).length) return;
    const categories: IntegrationTemplateCategory[] = data[GeneratorIntegrationPartIndex.CATEGORIES];
    this.integrations = data[GeneratorIntegrationPartIndex.INTEGRATIONS];
    const preparedData: IntegrationTemplate[] = [];
    categories.forEach((category) => {
      preparedData.push({
        isExpanded: true,
        category: { id: category.id, name: category.name },
        integrations: this.integrations.filter((integration) => integration.category?.id === category.id),
      });
    });
    this._collection$.next(preparedData);
  }

  public filterData(searchPhrase: string): void {
    const collection = this._collection$.getValue();
    collection.forEach((item) => {
      item.integrations = this.integrations.filter(
        (integration) =>
          integration.category?.id === item.category.id &&
          integration.name.toLowerCase().indexOf(searchPhrase.toLowerCase()) >= 0,
      );
      item.isExpanded = !!item.integrations.length;
    });
    this._collection$.next(collection);
  }
}
