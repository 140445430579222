import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { GetBaseWorkFlowsQuery } from '../graphql/queries/get-base-work-flows.query.generated';
import { GetBaseWorkFlows } from '../graphql/queries/get-base-work-flows.query';
import { BaseWorkFlowOutputGraphql, WorkFlowQueryInputGraphql } from '@modules/graphql/graphql-types';
import { UserService } from '@shared/services/user.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AbstractWorkFlowsService } from '@modules/work-flows/abstract/abstract-work-flows.service';
import { RemoveBaseWorkFlowMutation } from '../graphql/mutations/remove-base-work-flow.mutation.generated';
import { RemoveBaseWorkFlow } from '../graphql/mutations/remove-base-work-flow.mutation';

@Injectable({
  providedIn: 'root',
})
export class BaseWorkFlowsService extends AbstractWorkFlowsService<BaseWorkFlowOutputGraphql> {
  constructor(
    private readonly apollo: Apollo,
    userService: UserService,
    navigateService: NavigateService,
  ) {
    super(userService, navigateService);
  }

  getWorkFlows(input: WorkFlowQueryInputGraphql): Observable<BaseWorkFlowOutputGraphql[]> {
    return this.apollo
      .query<GetBaseWorkFlowsQuery>({
        query: GetBaseWorkFlows,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((response) => response.data?.getBaseWorkFlows ?? []));
  }

  removeWorkFlow(id: number): Observable<boolean> {
    return this.apollo
      .mutate<RemoveBaseWorkFlowMutation>({
        mutation: RemoveBaseWorkFlow,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((response) => !!response.data?.removeBaseWorkFlow));
  }
}
