import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'df-public-recommender-set-url-modal',
  templateUrl: './public-recommender-set-url-form.component.html',
  styleUrls: ['./public-recommender-set-url-form.component.scss'],
})
export class PublicRecommenderSetUrlModalComponent {
  @Inject(POLYMORPHEUS_CONTEXT)
  form = new FormGroup({
    url: new FormControl('', [Validators.required, Validators.pattern(Regex.url)]),
  });

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<string>,
  ) {}

  onSubmit() {
    if (!this.form.valid) return;
    const value = this.form.get('url')?.value as string;

    this.context.completeWith(value);
  }
}
