import { NgModule } from '@angular/core';
import { StatementPanelComponent } from './shared/components/statement-panel/statement-panel.component';
import { SharedModule } from '@shared/shared.module';
import { StatementWelcomeOnBoardComponent } from './pages/statement-welcome-on-board/statement-welcome-on-board.component';
import { StatementSelectComponent } from './shared/components/statement-select/statement-select.component';
import { StatementInputComponent } from './shared/components/statement-input/statement-input.component';
import { StatementStepFooterComponent } from './shared/components/statement-step-footer/statement-step-footer.component';
import { StatementAfterFormFilledComponent } from './pages/statement-after-form-filled/statement-after-form-filled.component';
import { StatementPlaceholderComponent } from './shared/components/statement-placeholder/statement-placeholder.component';
import { StatementInlineValueComponent } from './shared/components/statement-inline-value/statement-inline-value.component';
import { StatementAfterSkippedComponent } from './pages/statement-after-skipped/statement-after-skipped.component';
import { StatementStepRecommendationsComponent } from './pages/statement-step-recomendations/statement-step-recommendations.component';
import { TutorialCardComponent } from './pages/statement-step-recomendations/components/tutorial-card/tutorial-card.component';
import { StatementTacticCardComponent } from './pages/statement-step-recomendations/components/statement-tactic-card/statement-tactic-card.component';
import { StatementTacticsComponent } from './pages/statement-step-recomendations/components/statement-tactics/statement-tactics.component';
import { SwiperModule } from 'swiper/angular';
import { StatementTacticsStepHeaderComponent } from './pages/statement-step-recomendations/components/statement-tactics-step-header/statement-tactics-step-header.component';
import { CreateNewContextFunnelDialogComponent } from './pages/statement-step-recomendations/components/create-new-context-funnel-dialog/create-new-context-funnel-dialog.component';
import { StatementPublicSaveModalComponent } from './shared/components/statement-public-save-modal/statement-public-save-modal.component';
import { StatementCongratulationsModalComponent } from './shared/components/statement-congratulations-modal/statement-congratulations-modal.component';
import { StatementSelectWrapperComponent } from './shared/components/statement-select-wrapper/statement-select-wrapper.component';
import { StatementContentWrapperComponent } from './shared/components/statement-new-content-wrapper/statement-content-wrapper.component';
import { StatementStepsComponent } from './shared/components/statement-steps/statement-steps.component';
import { StatementControlsComponent } from './shared/components/statement-controls/statement-controls.component';
import { StatementSectionHeaderComponent } from './shared/components/statement-section-header/statement-section-header.component';
import { StatementFormStep1Component } from './pages/statement-form-step1/statement-form-step1.component';
import { StatementFormStep2Component } from './pages/statement-form-step2/statement-form-step2.component';
import { StatementMaxItemsPipe } from './shared/pipes/statement-max-items.pipe';
import { StatementTilesRadioComponent } from './shared/components/statement-tiles-radio/statement-tiles-radio.component';
import { StatementOptionsSliderInputComponent } from './shared/components/statement-options-slider-input/statement-options-slider-input.component';
import { StatementFormStep3Component } from './pages/statement-form-step3/statement-form-step3.component';
import { StatementValuesSliderInputComponent } from './shared/components/statement-values-slider-input/statement-values-slider-input.component';
import { StatementInputErrorComponent } from './shared/components/statement-input-error/statement-input-error.component';
import { StatementPublicComponent } from './pages/statement-public/statement-public.component';

@NgModule({
  declarations: [
    StatementPanelComponent,
    StatementWelcomeOnBoardComponent,
    StatementSelectComponent,
    StatementInputComponent,
    StatementStepFooterComponent,
    StatementAfterFormFilledComponent,
    StatementPlaceholderComponent,
    StatementInlineValueComponent,
    StatementAfterSkippedComponent,
    StatementStepRecommendationsComponent,
    TutorialCardComponent,
    StatementTacticCardComponent,
    StatementTacticsComponent,
    StatementTacticsStepHeaderComponent,
    CreateNewContextFunnelDialogComponent,
    StatementPublicSaveModalComponent,
    StatementCongratulationsModalComponent,
    StatementSelectWrapperComponent,
    StatementContentWrapperComponent,
    StatementStepsComponent,
    StatementControlsComponent,
    StatementSectionHeaderComponent,
    StatementFormStep1Component,
    StatementFormStep2Component,
    StatementMaxItemsPipe,
    StatementTilesRadioComponent,
    StatementOptionsSliderInputComponent,
    StatementFormStep3Component,
    StatementValuesSliderInputComponent,
    StatementInputErrorComponent,
    StatementPublicComponent,
  ],
  imports: [SharedModule, SwiperModule],
  exports: [
    StatementPublicComponent,
    StatementContentWrapperComponent,
    StatementSectionHeaderComponent,
    StatementSelectWrapperComponent,
    StatementSelectComponent,
    StatementInlineValueComponent,
    StatementInputComponent,
    StatementMaxItemsPipe,
    StatementTilesRadioComponent,
    StatementOptionsSliderInputComponent,
  ],
})
export class StatementModule {}
