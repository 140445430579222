<div
  *ngIf="showSkeleton"
  [class]="'controller p-6 grid grid-cols-1 md:grid-cols-' + cols + ' gap-4'"
>
  <div
    [class]="'w-3/12 min-w-[25vw] min-h-[60vh] bg-white py-6 px-4 rounded-[12px] h-full skeleton'"
    *ngFor="let i of getFields()"
  >
    <div class="skeleton skeleton-text w-full"></div>
  </div>
</div>
