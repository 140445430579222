import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { Config } from '@shared/configs/config';
import { CanvaCreation } from '@shared/models/creation.model';

@Component({
  selector: 'df-activity-asset-preview-modal',
  templateUrl: './activity-asset-preview-modal.component.html',
  styleUrls: ['./activity-asset-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityAssetPreviewModalComponent {
  Config = Config;
  selectedCreation: CanvaCreation | null = null;

  get asset(): ActivityAsset {
    return this.context.data.asset;
  }

  constructor(
    private readonly changes: ChangeDetectorRef,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { asset: ActivityAsset } }, boolean>,
  ) {}

  setSelectedCreation(creation: CanvaCreation | null): void {
    this.selectedCreation = creation;
    this.changes.detectChanges();
  }
}
