import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'df-tactics-list-onboarding-banner',
  templateUrl: './tactics-list-onboarding-banner.component.html',
  styleUrls: ['./tactics-list-onboarding-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticsListOnboardingBannerComponent {
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
