import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Campaign } from '../../campaign.component';

@Component({
  selector: 'df-campaign-execution',
  templateUrl: './campaign-execution.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignExecutionPlanComponent {
  @Input() campaign!: Campaign;
}
