import { gql } from 'apollo-angular';

export const CreateCustomPromptOutputDocument =
  gql(/* GraphQL*/ `mutation createCustomPromptOutput($customPromptOutputCreate: CustomPromptOutputCreateGraphql!) {
  createCustomPromptOutput(customPromptOutputCreate: $customPromptOutputCreate) {
    customPromptId
    value
  }
}
`);
