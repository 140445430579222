import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TacticSettingsComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/tactic-settings.component';

@Injectable()
export class TacticSettingsCanDeactivateGuard implements CanDeactivate<TacticSettingsComponent> {
  canDeactivate(
    component: TacticSettingsComponent,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.openConfirmExitModal();
  }
}
