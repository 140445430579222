import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';
import { AppSumoErrorEnum } from '@modules/authorization/pages/app-sumo-error/app-sumo-error.enum';

@Injectable()
export class AppSumoCanActivateGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authStorageService: AuthStorageService,
    private n: NavigateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.userService.User && this.authStorageService.jwt) {
      if (route.queryParams['token']) {
        this.n.go(
          'appsumo-invalid',
          {},
          {
            queryParams: {
              ...route.queryParams,
              error: AppSumoErrorEnum.ALREADY_LOGGED_IN,
            },
          },
        );
      } else {
        this.n.go(Config.MAIN_VIEW);
      }
      return false;
    }
    return true;
  }
}
