import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TeamManageComponent } from '@modules/user-settings/modules/teams/modules/team-manage/pages/team-manage/team-manage.component';

@Injectable()
export class TeamManageCanDeactivateGuard implements CanDeactivate<TeamManageComponent> {
  canDeactivate(
    component: TeamManageComponent,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.openConfirmExitModal();
  }
}
