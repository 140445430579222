import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { UserService } from '@shared/services/user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicPricingCampaignGuard implements CanActivate {
  constructor(
    private readonly userService: UserService,
    private readonly navigateService: NavigateService,
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userService.User || (this.userService.User && this.userService.User.isMarketingCampaignUser)) return true;

    this.navigateService.go('public/pricing');
    return false;
  }
}
