import { gql } from 'apollo-angular';

export const SaveLibraryAssetDocument = gql`
  mutation saveLibraryAsset($input: SaveLibraryAssetInputGraphql!) {
    saveLibraryAsset(input: $input) {
      success
      process
    }
  }
`;
