<div class="df-container pb-20 sm:pb-8">
  <form
    class="df-container-content"
    [formGroup]="form"
    [validate-form]="form"
  >
    <df-full-list-loader *ngIf="loading"></df-full-list-loader>
    <div class="df-two-columns">
      <div
        class="df-column justify-between"
        *ngIf="funnelSettingsService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor || !isEditMode"
      >
        <div class="flex flex-col justify-start items-stretch">
          <h3 class="df-column-title">
            {{ 'UserSettings.Teams.General' | translate }}
          </h3>

          <div class="tui-form__row">
            <tui-input
              data-test="funnel-name-input"
              [formControlName]="EFunnelSettingsForm.name"
              [attr.data-error-control]="EFunnelSettingsForm.name"
              [required]="true"
              [readOnly]="loading"
            >
              {{ 'Funnels.funnel name' | translate }}*
            </tui-input>
            <df-error-input
              data-test="funnel-name-input-error"
              [control]="getControl(EFunnelSettingsForm.name)"
            ></df-error-input>
          </div>
          <div class="tui-form__row">
            <p class="text-sm tui-text-03 mb-2.5">
              {{ 'Funnels.project' | translate }}
            </p>
            <df-tag-select
              data-test="select-project-input"
              [maxItems]="1"
              [size]="'l'"
              [controlName]="EFunnelSettingsForm.projectName"
              [formGroup]="form"
              [query]="FilterProjectsDocument"
            >
              {{ 'Funnels.Settings.Select project' | translate }}
            </df-tag-select>
          </div>

          <div class="tui-form__row">
            <p class="text-sm tui-text-03 mb-2.5">
              {{ 'UserSettings.Teams.icon' | translate }}
            </p>
            <div class="flex flex-row items-center justify-start flex-wrap overflow-auto">
              <tui-avatar
                class="mr-4"
                *ngIf="funnelSettingsService.funnel?.image"
                [size]="'l'"
                [avatarUrl]="imageUrl"
                [autoColor]="true"
              ></tui-avatar>
              <df-input-file
                class="m-2"
                #iconInput
                [ngClass]="{
                  'w-auto flex-grow': funnelSettingsService.funnel?.image,
                  'w-full': !funnelSettingsService.funnel?.image
                }"
                [maxFileSize]="10000000"
                [form]="form"
                [link]="'choose an image' | translate"
                [accept]="Config.ACCEPTED_IMAGES_EXTENSIONS"
                [formName]="EFunnelSettingsForm.image"
                [multiple]="false"
              ></df-input-file>
            </div>
          </div>

          <div
            class="bg-[#F2F4FF] rounded-[16px] p-6 mt-4"
            *ngIf="isEditMode"
          >
            <h3 class="text-[18px] font-semibold text-[#191C30E5] flex items-center mb-4">
              Training data
              <tui-tag
                class="tag text-[#D5BA11] bg-[#fffbe8] ml-2"
                [hoverable]="false"
                [value]="'New'"
              ></tui-tag>
            </h3>
            <p class="text-[14px] font-medium text-[#1B1F3BA6] mb-6">
              Submit your data to include additional business details and receive more accurate results.
            </p>

            <button
              data-test="add-data-buttton"
              [routerLink]="n.getPath('funnels/f/d/:id/business-data', {}, { id: funnelSettingsService.funnel?.id })"
              type="button"
              class="bg-[#fff] w-full"
              tuiButton
              appearance="outline"
            >
              <span class="tui-primary font-semibold">Add data</span>
            </button>
          </div>
        </div>

        <div class="hidden lg:block">
          <ng-container *ngTemplateOutlet="deleteTeamButton"></ng-container>
        </div>
      </div>
      <div
        *ngIf="!userService.User?.isSemrushPlan()"
        class="df-column pl-0 pt-0 pr-0 w-full"
        [ngClass]="{
          'w-full': funnelSettingsService.funnel?.currentUserPermission !== FunnelPermissionEnum.Editor
        }"
      >
        <tui-tabs
          class="h-14 pl-4"
          *ngIf="funnelSettingsService.activeTab"
          [(activeItemIndex)]="tabIndex"
          (activeItemIndexChange)="setActiveTab($event)"
        >
          <button
            data-test="create-funnel-tab-name"
            tuiTab
            *ngFor="let tab of funnelSettingsService.tabs"
          >
            {{ tab.name }}
          </button>
        </tui-tabs>
        <div class="p-4">
          <df-resource-teams
            data-test="create-funnel-tab-action-buttons"
            [resourceTypeView]="EResourceViewType.FUNNEL"
            (onComponentReady)="onResourceTeamsComponentReady($event)"
            (onResourcesChange)="funnelSettingsService._changesInForm$.next($event)"
            [funnel]="this.funnelSettingsService.funnel"
            *ngIf="
              funnelSettingsService.activeTab &&
              funnelSettingsService.activeTab.type === EContainerTabType.TEAM &&
              (this.funnelSettingsService?.funnel || !isEditMode)
            "
          >
          </df-resource-teams>

          <df-funnel-settings-share
            *ngIf="funnelSettingsService.activeTab && funnelSettingsService.activeTab.type === EContainerTabType.SHARE"
          ></df-funnel-settings-share>
        </div>
        <div class="lg:hidden">
          <ng-container *ngTemplateOutlet="deleteTeamButton"></ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #deleteTeamButton>
  <div
    class="flex flex-col sm:flex-row w-full sm:justify-between items-center"
    *ngIf="isEditMode"
  >
    <button
      data-test="delete-funnel-buttom-button"
      size="xl"
      tuiButton
      class="tui-accent-01"
      type="button"
      appearance="outline"
      mwlConfirmationPopover
      [popoverTitle]="'Funnels.Settings.Deleting funnel' | translate"
      [popoverMessage]="
        'Funnels.Settings.Do you really want to remove this funnel? You will loose all data.' | translate
      "
      [appendToBody]="true"
      placement="top"
      *ngIf="funnelSettingsService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor"
      (confirm)="deleteFunnel()"
    >
      {{ 'Funnels.Settings.Delete funnel' | translate }}
    </button>
    <span class="tui-text-04 tui-text_body-s mt-3 sm:mt-0 sm:ml-3">
      {{
        'Funnels.Settings.Created'
          | translate
            : {
                date: (funnelSettingsService.funnel?.createdAt | dateFormat: 'DDD' : language)
              }
      }}
    </span>
  </div>
</ng-template>
