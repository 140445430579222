import { gql } from 'apollo-angular';
import { EditTacticFragment } from '@modules/tactics/shared/graphql/fragments/edit-tactic.fragment';

export const CreateTacticDocument = gql/* GraphQL*/ `
  mutation createTactic($input: TacticInputGraphql!) {
    createTactic(input: $input) {
      ...EditTactic
    }
  }
  ${EditTacticFragment}
`;
