import { gql } from 'apollo-angular';

export const GetSkillsDocument = gql`
  query getSkills {
    getSkills {
      id
      name
    }
  }
`;
