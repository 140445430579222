import { Component, Input } from '@angular/core';
import { TacticStepIconComponent } from '../tactic-step-icon/tactic-step-icon.component';
import { CommonModule } from '@angular/common';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-tactic-landing',
  standalone: true,
  imports: [TacticStepIconComponent, CommonModule],
  templateUrl: './tactic-landing.component.html',
  styleUrl: './tactic-landing.component.css',
})
export class TacticLandingComponent {
  @Input() funnelId!: number;

  constructor(private n: NavigateService) {}

  onEditLanding(): void {
    this.n.go(`/funnels/f/d/${this.funnelId}/landing-page`);
  }
}
