import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GetResponseEvent } from '@modules/graphql/graphql-types';
import { TriggerGetResponseEventMutation } from '@shared/graphql/mutations/trigger-get-response-event.mutation.generated';
import { TriggerGetResponseEventDocument } from '@shared/graphql/mutations/trigger-get-response-event.mutation';

@Injectable({
  providedIn: 'root',
})
export class GetResponseEventsService {
  constructor(private readonly apollo: Apollo) {}

  triggerGetResponseEvent(eventType: GetResponseEvent): void {
    this.apollo
      .mutate<TriggerGetResponseEventMutation>({
        mutation: TriggerGetResponseEventDocument,
        variables: {
          eventType,
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe();
  }
}
