import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { AyrsharePost, AyrshareService } from '@shared/services/ayrshare.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'df-ayrshare-content-calendar',
  templateUrl: './content-calendar.component.html',
  styleUrls: ['./content-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentCalendarComponent extends AbstractSubscriptionComponent implements OnInit {
  posts: AyrsharePost[] = [];
  columns: string[] = [];
  openPublication = false;
  sortablePosts: AyrsharePost[] = [];
  id = new BehaviorSubject<number | string>(0);
  loading = true;

  channelList = [
    {
      name: 'facebook',
      icon: 'fb.svg',
    },
    {
      name: 'facebook-groups',
      icon: 'fb-new.svg',
    },
    {
      name: 'google-business-profile',
      icon: 'google.svg',
    },
    {
      name: 'instagram',
      icon: 'instagram.svg',
    },
    {
      name: 'linkedin',
      icon: 'linkedin.svg',
    },
    {
      name: 'pinterest',
      icon: 'pinterest.svg',
    },
    {
      name: 'reddit',
      icon: 'reddit.svg',
    },
    {
      name: 'telegram',
      icon: 'twitter.svg',
    },
    {
      name: 'tiktok',
      icon: 'tiktok.svg',
    },
    {
      name: 'twitter',
      icon: 'twitter.svg',
    },
    {
      name: 'youtube',
      icon: 'youtube.svg',
    },
  ];

  page = 0;
  size = 10;

  constructor(
    private route: ActivatedRoute,
    private ayrshareService: AyrshareService,
    private changes: ChangeDetectorRef,
  ) {
    super();
  }

  get funnelId(): number {
    return Number(this.route.parent!.snapshot.params['id']);
  }

  ngOnInit(): void {
    this.getPosts();
  }

  getPath(platform: string) {
    const item = this.channelList.find((item) => item.name === platform);
    return `/assets/img/${item?.icon}`;
  }

  closePublication() {
    this.openPublication = false;
  }

  transformStatus(status: string) {
    switch (status) {
      case 'pending':
        return 'Planned';
      case 'error':
        return 'Error';
      case 'success':
        return 'Published';
    }
  }

  editPost(item?: AyrsharePost) {
    if (item?.id) this.id.next(item.id);
    this.openPublication = true;
    this.changes?.detectChanges();
  }

  shortenText(text: string) {
    if (text.length > 100) {
      return text.slice(0, 300) + '...';
    }
    return text;
  }

  deletePost(item: AyrsharePost) {
    this.sub.add(
      this.ayrshareService.deletePost(item.id).subscribe((res) => {
        if (res) {
          this.posts = this.posts.filter((p) => p.id !== item.id);
          this.changes.detectChanges();
        }
      }),
    );
  }

  getSortablePost() {
    const startIndex = this.page * this.size;
    const endIndex = startIndex + this.size;

    this.sortablePosts = this.posts.slice(startIndex, endIndex);
    this.changes.detectChanges();
  }

  private getPosts() {
    this.sub.add(
      this.ayrshareService.getScheduledPosts().subscribe((posts) => {
        this.posts = posts?.map((post) => {
          post.created = new Date(post.created).toLocaleString();
          return post;
        });

        this.getSortablePost();
        this.columns = ['created', 'post', 'platforms', 'status', 'action'];
        this.loading = false;
        this.changes.detectChanges();
      }),
    );
  }
}
