import { IntegrationFragment } from '@modules/funnels/shared/graphql/fragments/integration.fragment';
import { gql } from 'apollo-angular';

export const GetContentGenerationIntegrationsDocument = gql`
  query getContentGenerationIntegrations {
    getContentGenerationIntegrations {
      ...Integration
      icon
      category {
        id
        name
      }
    }
  }
  ${IntegrationFragment}
`;
