import {
  ContentGenerationOutputGraphql,
  InputConfigurationOutputGraphql,
  InputDataGraphql,
  InputTypeEnum,
  IntegrationCategoryOutputGraphql,
  IntegrationOutputGraphql,
  IntegrationTypeEnum,
  OutputConfigurationGraphql,
  OutputDataGraphql,
  OutputPropertyGraphql,
  OutputTypeEnum,
  ValidationPatternOutputGraphql,
} from '@modules/graphql/graphql-types';
import { ActivityAsset } from '@shared/models/activity-asset.model';

export class IntegrationInputConfiguration implements InputConfigurationOutputGraphql {
  id!: number;
  position = 0;
  label!: string;
  description?: string;
  placeholder?: string;
  default?: string;
  metadata?: Record<string, any>;
  property!: string;
  required = false;
  type!: InputTypeEnum;
  validationPatterns: IntegrationValidationPattern[] = [];
}

export class IntegrationOutputConfiguration implements OutputConfigurationGraphql {
  id!: number;
  type!: OutputTypeEnum;
  metadata?: JSON;
  outputProperties: IntegrationOutputProperty[] = [];
  validationPatterns: IntegrationValidationPattern[] = [];
}

export class IntegrationOutputProperty implements OutputPropertyGraphql {
  id!: number;
  type!: InputTypeEnum;
  validationPatterns: IntegrationValidationPattern[] = [];
}

export class IntegrationValidationPattern implements ValidationPatternOutputGraphql {
  id!: number;
  rules!: JSON;
}

export class Integration implements IntegrationOutputGraphql {
  id!: number;
  name!: string;
  description!: string;
  type!: IntegrationTypeEnum;
  outputType!: OutputTypeEnum;
  creditsPrice!: number;
  icon?: string;
  category?: IntegrationCategoryOutputGraphql;
  inputsConfigurations: IntegrationInputConfiguration[] = [];
  outputsConfigurations: OutputConfigurationGraphql[] = [];

  constructor(data?: Integration | IntegrationOutputGraphql) {
    data ? Object.assign(this, data) : '';
    this.inputsConfigurations = this.inputsConfigurations.sort((a, b) => (a.position > b.position ? 1 : -1));
  }
}

export class ContentGenerationOutput implements ContentGenerationOutputGraphql {
  outputs: IntegrationOutputData[] = [];
  userCredits = 0;

  constructor(data?: ContentGenerationOutput | ContentGenerationOutputGraphql) {
    this.outputs = data ? (data.outputs as IntegrationOutputData[]) : [];
    this.userCredits = data?.userCredits ?? 0;
  }
}

export class IntegrationOutputData implements OutputDataGraphql {
  id!: number;
  value!: JSON;
  activityAsset!: ActivityAsset;
  configuration!: OutputConfigurationGraphql;
  createdAt!: Date;
  isSelected = false;
  originalValue!: JSON;
  modifiedAt?: Date;
  metadata?: JSON;
  integration!: IntegrationOutputGraphql;
}

export class IntegrationInputData implements InputDataGraphql {
  id!: number;
  value!: JSON;
  createdAt!: Date;
  modifiedAt!: Date;
  configuration!: IntegrationInputConfiguration;
}

export enum GraphicsOutputMetadata {
  TYPE = 'type',
  SIZE = 'size',
  USAGE = 'usage',
  UNSPLASH_TRIGGER_LINK = 'unsplashTriggerLink',
}
