import {
  FunnelPermissionEnum,
  ResourcePermissionOutputGraphql,
  ResourceTeamOutputGraphql,
} from '@modules/graphql/graphql-types';
import { Team, TeamMember } from '@shared/models/team.model';
import { UntypedFormControl } from '@angular/forms';

export class ResourceTeam implements ResourceTeamOutputGraphql {
  id!: number;
  permissions!: ResourcePermission[];
  team!: Team;
  status: EResourceTeamStatus = EResourceTeamStatus.TO_CREATE;

  constructor(data: ResourceTeam | ResourceTeamOutputGraphql) {
    data ? Object.assign(this, data) : '';
    this.team ? (this.team = new Team(this.team)) : '';
    this.setPermissionStatuses();
    this.setAddedAsPermission();
  }

  private setPermissionStatuses() {
    this.permissions = this.permissions.map((p: ResourcePermission) => {
      p = new ResourcePermission(p);
      p.status = EPermissionResourceStatus.ADDED;
      return p;
    });
  }

  setAddedAsPermission() {
    const permissionsTeamMembersIds: number[] = this.permissions.map((p) => p.teamMember.id);
    this.team.members.map((m: TeamMember) => {
      if (permissionsTeamMembersIds.indexOf(m.id) !== -1) {
        m.addedAsPermission = true;
      } else {
        m.addedAsPermission = false;
      }
      return m;
    });
  }
}

export class ResourcePermission implements ResourcePermissionOutputGraphql {
  id!: number;
  permission!: FunnelPermissionEnum;
  teamMember!: TeamMember;
  status: EPermissionResourceStatus = EPermissionResourceStatus.TO_INVITE;
  permissionControl: UntypedFormControl = new UntypedFormControl(this.permission);

  constructor(data: ResourcePermission | ResourcePermissionOutputGraphql) {
    data ? Object.assign(this, data) : '';
    this.permissionControl = new UntypedFormControl(this.permission);
    this.teamMember ? (this.teamMember = new TeamMember(this.teamMember)) : '';
  }
}

export enum EResourceTeamStatus {
  TO_CREATE,
  TO_REMOVE,
  ADDED,
}

export enum EPermissionResourceStatus {
  TO_INVITE,
  TO_REMOVE,
  TO_REMOVE_RESOURCE_TEAM,
  TO_EDIT,
  ADDED,
}
