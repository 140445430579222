/* eslint-disable no-prototype-builtins */
/* eslint-disable no-case-declarations */
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Integration, IntegrationInputConfiguration } from '@shared/models/integration.model';
import { CreateInputDataGraphql, InputTypeEnum } from '@modules/graphql/graphql-types';
import { ContentGeneratorEventEnum } from '@modules/funnels/shared/enums/content-generator-selection-type.enum';
import { getDefaultControlHelper, getValidatorsForConfigurationHelper } from '@shared/helpers/content-generator.helper';
import { BehaviorSubject, Observable } from 'rxjs';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ICustomImageFormatMetadataOption } from '@modules/funnels/modules/funnel-manage/shared/interfaces/custom-image-format-metadata-option.interface';
import { customImageFormatMultiselectValidator } from '@shared/validators/custom-image-format-multiselect.validator';
import { ContentGeneratorEventService } from '@modules/funnels/modules/funnel-manage/shared/services/content-generator-event.service';
import { GenerateContentCostBadgeService } from '@modules/funnels/modules/funnel-manage/shared/components/generate-content-cost-badge/generate-content-cost-badge.service';

export abstract class AbstractContentGeneratorService {
  protected _form: UntypedFormGroup = new UntypedFormGroup({});
  protected _videoForm: UntypedFormGroup = new UntypedFormGroup({});
  protected selectedGraphicTypes = 0;

  get form(): UntypedFormGroup {
    return this._form;
  }

  get videoForm(): UntypedFormGroup {
    return this._videoForm;
  }

  protected readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  protected constructor(
    protected readonly s: SnackbarService,
    protected readonly t: TranslateService,
    protected readonly contentGeneratorEventService: ContentGeneratorEventService,
    protected readonly costBadgeService: GenerateContentCostBadgeService,
  ) {}

  protected initFormVideoFields(videoIntegration: Integration) {
    this._videoForm = new UntypedFormGroup({});

    videoIntegration.inputsConfigurations.forEach((config) => {
      this._videoForm.addControl(config.id.toString(), getDefaultControlHelper(config));
    });
  }

  protected initFormFields(integration: Integration): void {
    this.costBadgeService.setCreditsPrice(0);
    this.selectedGraphicTypes = 0;
    this._form = new UntypedFormGroup({});
    integration.inputsConfigurations.forEach((config) => {
      switch (config.type) {
        case InputTypeEnum.Select:
          this.form.addControl(
            config.id.toString(),
            new UntypedFormControl(
              this.getSelectOption(config, config.default),
              getValidatorsForConfigurationHelper(config),
            ),
          );
          break;
        case InputTypeEnum.StringArray:
          this.form.addControl(config.id.toString(), new UntypedFormArray([getDefaultControlHelper(config)]));
          break;
        case InputTypeEnum.CustomImageFormatMultiselect:
          // default false
          const customImageMetadata = config!.metadata as unknown as {
            options: Array<ICustomImageFormatMetadataOption>;
          };
          const options = customImageMetadata.options.map(() => {
            const newInput = new UntypedFormControl(false);
            return newInput;
          });
          // add custom validator
          const validators: ValidatorFn[] = [];
          if (config.required) {
            validators.push(customImageFormatMultiselectValidator());
          }
          const sizesFormArray = new UntypedFormArray(options, validators);
          sizesFormArray.valueChanges.subscribe((val) => {
            this.selectedGraphicTypes = val.filter((item) => item).length;
            this.costBadgeService.setCreditsPrice(this.selectedGraphicTypes * integration.creditsPrice);
            val = val as unknown as {
              options: Array<ICustomImageFormatMetadataOption>;
            };
            // map boolean value to metadata.options[] value
            const toSplice: Array<number> = [];
            val.forEach((v, key) => {
              if (v) {
                val[key] = customImageMetadata.options[key].value;
              } else {
                toSplice.push(key);
              }
            });
          });
          this.form.addControl(config.id.toString(), sizesFormArray);
          break;

        case InputTypeEnum.Bar:
          this.form.addControl(config.id.toString(), getDefaultControlHelper(config));
          if (config?.metadata?.defaultValue && config?.metadata?.type !== 'select')
            this.form.get(config.id.toString())?.setValue(config.metadata.defaultValue);
          break;
        default:
          this.form.addControl(config.id.toString(), getDefaultControlHelper(config));
          break;
      }
    });
  }

  protected getSelectOption(
    configuration: IntegrationInputConfiguration,
    value?: string,
  ): { label: string; value: string } | undefined {
    const options = configuration.metadata!['options'] as {
      label: string;
      value: string;
    }[];
    return options.find((option) => option.value === value);
  }

  protected getInputsData(): CreateInputDataGraphql[] {
    return Object.keys(this.form.controls).map((key) => {
      let data =
        !!this.form.get(key)?.value && this.form.get(key)?.value.hasOwnProperty('value')
          ? this.form.get(key)?.value.value
          : this.form.get(key)?.value;

      // InputTypeEnum.customImageFormatMultiselect
      if (data instanceof Array) {
        data = data.filter((v) => !!v);
      }
      // InputTypeEnum.InputFileFromUnsplash
      if (data instanceof Object && data.hasOwnProperty('src') && data.hasOwnProperty('triggerLink')) {
        data = { src: data.src, triggerLink: data.triggerLink };
      }

      return {
        inputConfigurationId: +key,
        value: {
          data,
        },
      };
    });
  }

  abstract generateContent(): void;

  protected generateContentSuccess(): void {
    this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
    this._loading$.next(false);
  }

  protected generateContentError(): void {
    this.s.error(
      this.t.instant(
        'Funnels.Content generator.Something went wrong during generating content. Please check the data and try again.',
      ),
    );
    this._loading$.next(false);
  }
}
