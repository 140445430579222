import { Injectable, Injector, OnDestroy } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Router } from '@angular/router';
import { GlobalDataService } from '@shared/services/global-data.service';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { StatementGraphqlService } from '@modules/statement/shared/services/statement-graphql.service';
import { UserService } from '@shared/services/user.service';
import { Maybe, PermissionType, StatementInputGraphql } from '@modules/graphql/graphql-types';
import { finalize, map, tap } from 'rxjs/operators';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { StatementCongratulationsModalComponent } from '@modules/statement/shared/components/statement-congratulations-modal/statement-congratulations-modal.component';
import { Recommendation, TacticRecommendation } from '@shared/models/recommendations.model';
import { GetTacticsRecommendationsQuery } from '@modules/statement/shared/graphql/queries/get-recomended-tactics.query.generated';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { BaseStorageService } from '@core/services/base-storage.service';
import { CreateStatementMutation } from '../graphql/mutations/create-statement.mutation.generated';
import { GetStatementQuery } from '../graphql/queries/get-new-statement.query.generated';
import { Statement } from '../models/statement.interface';
import {
  TacticsListService,
  TacticsListEventType,
} from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';

@Injectable({
  providedIn: 'root',
})
export class StatementService extends BaseStorageService implements OnDestroy {
  stepNr: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  funnelId: number | null = null;
  statement: null | Statement = null;
  startPreviousUrl = '';
  loadingTactics = true;
  publicStatementForm: any = {};
  alreadyPublicStatementSend = false;
  forScreenshot = false;
  missingPermission: PermissionType | null = null;
  funnelMode = false;
  recommendations: any[] | null = null;
  recommendationsAccess = false;
  openStatementOnboarding = false;
  isPublic = false;
  private sub: Subscription = new Subscription();
  private _refreshStatement = true;
  private _refreshRecommendations = false;
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();
  private readonly _creatingStatementLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly creatingStatementLoader$: Observable<boolean> = this._creatingStatementLoader$.asObservable();
  private readonly _loadingRecommendations$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly loadingRecommendations$: Observable<boolean> = this._loadingRecommendations$.asObservable();
  private readonly _statementContainerScrollTop$: Subject<boolean> = new Subject<boolean>();
  readonly statementContainerScrollTop$: Observable<boolean> = this._statementContainerScrollTop$.asObservable();

  constructor(
    private n: NavigateService,
    public router: Router,
    private globalDataService: GlobalDataService,
    private statementGraphqlService: StatementGraphqlService,
    private dialogService: TuiDialogService,
    private injector: Injector,
    private userService: UserService,
    private funnelManageService: FunnelManageService,
    private tacticsListService: TacticsListService,
  ) {
    super();
    this.getPermissions();
    this.sub.add(
      this.funnelManageService.funnel$.subscribe({
        next: () => (this.statement = null),
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  getPermissions() {
    if (!this.globalDataService.permissions.length) this.globalDataService.getPermissions().toPromise();
  }

  fetchStatement(funnelId?: number): Observable<Statement | null> {
    this._loading$.next(true);
    this.funnelId = funnelId ?? this.userService.User?.contextFunnel?.id ?? null;
    if (!this.funnelId) {
      this.statement = null;
    } else if (!this.statement || this.statement.funnelId !== this.funnelId) {
      return this.statementGraphqlService.getNewStatement(this.funnelId).pipe(
        map((res: FetchResult<GetStatementQuery> | undefined) => {
          const statement: Statement | null = res?.data?.getStatement ? (res.data.getStatement as Statement) : null;
          if (statement) statement.funnelId = this.funnelId ?? 0;
          return statement;
        }),
        tap((statement: Statement | null) => (this.statement = statement)),
        finalize(() => this._loading$.next(false)),
      );
    }
    return of(this.statement).pipe(finalize(() => this._loading$.next(false)));
  }

  readPreviousUrl() {
    if (!window.location.pathname.includes('statement:statement')) {
      this.startPreviousUrl = this.n.previousUrl;
    }
  }

  closeStatement() {
    this.startPreviousUrl = '';
    this.router.navigate([{ outlets: { statement: null } }]);
  }

  skipStatement(): void {
    this.router.navigate([{ outlets: { statement: null } }]).then(() => this.n.go('/tactics/list/all'));
  }

  openSaveAndFinishDialog(): void {
    this.dialogService
      .open<StatementCongratulationsModalComponent>(
        new PolymorpheusComponent(StatementCongratulationsModalComponent, this.injector),
        {
          dismissible: true,
          closeable: true,
          size: 's',
        },
      )
      .subscribe();
  }

  refreshRecommendations(): void {
    this._refreshRecommendations = true;
  }

  fetchRecommendations(force = false): void {
    const records = 1000;
    const user = this.userService.User!;

    if (user.contextFunnel?.id) {
      if (force || user.contextFunnel.id !== this.funnelId || this._refreshRecommendations) {
        this.recommendations = null;
        this._refreshRecommendations = false;
        this.funnelId = user.contextFunnel.id;
        this._loadingRecommendations$.next(true);
        this.statementGraphqlService
          .getRecommendedTactics(user.contextFunnel.id, records)
          .pipe(
            map((res: FetchResult<GetTacticsRecommendationsQuery>) =>
              res.data ? (res.data.getTacticsRecommendations as TacticRecommendation[]) : [],
            ),
          )
          .subscribe(this._parseRecommendations.bind(this));
      }
    } else {
      this.recommendations = null;
    }
  }

  fetchRecommendationsAi(): Observable<any> {
    const user = this.userService.User!;
    // statement is filled & access
    // if (user.hasAccess(PermissionType.TacticsRecommendations) && this.statement?.id && !!user.contextFunnel?.id) return this.canvasesRecommsService.getTacticsRecommendations(user.contextFunnel?.id);
    // statement is not filled or no acces => no recommendations
    this.recommendations = [];
    return of([]);
  }

  private _parseRecommendations(recommendations: TacticRecommendation[]): void {
    this.recommendations = [];

    recommendations.forEach((recommendation) =>
      this.recommendations!.push(
        ...recommendation.recommendations.map((r) => new Recommendation(r, recommendation.step)),
      ),
    );
    this.recommendations.sort((a, b) => (a.tactic.id < b.tactic.id ? 1 : -1));
    const funnel = this.funnelManageService.funnel!;

    if (!funnel) return this._loadingRecommendations$.next(false);

    funnel.recommendationsCount = this.recommendations.length;
    this.funnelManageService.funnel = funnel;
    this._loadingRecommendations$.next(false);
  }

  public naviageToStep(step: number): void {
    if (this.funnelMode) {
      this.n.go('funnels/f/d/:id/statement/' + step, {
        id: this.userService.User?.contextFunnel.id,
      });
    } else {
      this.router.navigate(['', { outlets: { statement: 'statement/' + (step + 1) } }], {
        skipLocationChange: !!this.startPreviousUrl,
      });
    }
  }

  public performStatementContainerScrollTop(): void {
    this._statementContainerScrollTop$.next(true);
  }

  public saveStatement(
    formData: StatementInputGraphql,
    marketingCampaign = false,
    campaignContext: null | TuiDialogContext = null,
  ): void {
    this._creatingStatementLoader$.next(true);
    this.statementGraphqlService.createStatement(formData).subscribe({
      next: (res: FetchResult<CreateStatementMutation>) => {
        const statement: Statement | null = res?.data?.createStatement ? (res.data.createStatement as Statement) : null;
        if (statement) statement.funnelId = this.resolveFunnelId(formData.funnelId);
        this.statement = statement;

        if (!marketingCampaign) {
          const user = this.userService.User!;
          user.filledStatement = true;
          this.userService.User = user;
          this.openSaveAndFinishDialog();
        }

        marketingCampaign ? campaignContext?.completeWith() : this.redirectToBasePage();
      },
      error: () => {
        this._creatingStatementLoader$.next(false);
      },
      complete: () => {
        this._creatingStatementLoader$.next(false);
        this.sub.add(
          this.tacticsListService.tacticsListEmitter.emit({
            type: TacticsListEventType.RELOAD_FUNNEL,
          }),
        );
      },
    });
  }

  private resolveFunnelId(funnelId: Maybe<number> | undefined): number | undefined {
    return funnelId ? funnelId : undefined;
  }

  private redirectToBasePage(): void {
    this.funnelMode && this.funnelId
      ? this.n.go('funnels/f/d/:id', { id: this.funnelId })
      : this.router.navigate(['/funnels']);
  }

  public setReloadStatement(value: boolean): void {
    this._refreshStatement = value;
  }

  public getRefreshStatement(): boolean {
    const value: boolean = this._refreshStatement;
    this._refreshStatement = true;
    return value;
  }
}
