export enum FunnelTab {
  OVERVIEW = 'OVERVIEW',
  MANAGE = 'MANAGE',
  PREVIEW = 'PREVIEW',
  PROMOTION = 'PROMOTION',
  STATEMENT = 'STATEMENT',
  RECOMMENDATIONS = 'RECOMMENDATIONS',
  CANVASES = 'CANVASES',
  SHARED = 'SHARED',
  CONTENT_GENERATE = 'CONTENT_GENERATE',
  GRAPHICS = 'GRAPHICS',
  CONTENT_GENERATOR_LIBRARY = 'CONTENT_GENERATOR_LIBRARY',
  STATISTICS = 'STATISTICS',
  CONTENT_CALENDAR = 'CONTENT_CALENDAR',
  AI_STRATEGY = 'AI_STRATEGY',
  ASSETS_LIBRARY = 'ASSETS_LIBRARY',
}
