import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { BaseCustomSelectComponent } from '@shared/modules/custom-selects/components/base-custom-select.component';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { Apollo } from 'apollo-angular';
import { IFilter } from '@shared/interfaces/filter.interface';

@Component({
  selector: 'df-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.scss'],
})
export class TagSelectComponent extends BaseCustomSelectComponent {
  @Input() size: TuiSizeS | TuiSizeL = 'l';

  constructor(
    public apollo: Apollo,
    public changes: ChangeDetectorRef,
  ) {
    super(apollo, changes);
  }

  itemsToString(items: readonly IFilter<any>[] | null) {
    return items ? items.map((f) => (f.name ? f.name : f)) : [];
  }

  detectChanges() {
    this.changes.detectChanges();
    this.component.nativeFocusableElement?.blur();
  }

  isInputHidden() {
    if (!this.maxItems) return false;
    if (
      this.formGroup?.get(this.controlName)?.value?.length &&
      this.formGroup?.get(this.controlName)?.value?.length >= this.maxItems
    ) {
      return true;
    }
    return false;
  }
}
