import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AssetsService } from '../shared/assets.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AssetLibraryOutputGraphql,
  GetLibraryAssetInputGraphql,
  LibraryFilterInputGraphql,
} from '@modules/graphql/graphql-types';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';

@Component({
  selector: 'df-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  limit = 10;
  offset = 0;
  assets: AssetLibraryOutputGraphql[] = [];
  loading = false;

  constructor(
    private assetService: AssetsService,
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef,
    private s: SnackbarService,
    private t: TranslateService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loading = true;
    this.getAssets();

    this.sub.add(
      this.router.events.subscribe((val) => {
        this.route.queryParamMap.subscribe((params) => {
          if (val instanceof NavigationEnd) {
            this.loading = true;
            switch (params?.get('type')) {
              case 'all':
              default:
                this.getAssets();
                break;

              case 'image':
                this.getAssets({ type: 'file' });
                break;

              case 'content':
                this.getAssets({ type: 'string' });
                break;

              case 'prompt':
                this.getAssets({ type: 'string', integration: 'prompt' });
                break;
            }
          }
        });
      }),
    );
  }

  getAssets(filter: LibraryFilterInputGraphql | null = null) {
    const input: GetLibraryAssetInputGraphql = {
      funnelId: +this.route?.parent?.snapshot.params['id'],
      filter,
    };

    this.sub.add(
      this.assetService.getLibraryAssets(input).subscribe((res) => {
        this.assets = res as AssetLibraryOutputGraphql[];
        this.loading = false;
        this.changes.detectChanges();
      }),
    );
  }

  deleteAsset(asset: AssetLibraryOutputGraphql) {
    this.sub.add(
      this.assetService.deleteAsset(asset.id).subscribe((res) => {
        if (res) {
          this.s.success(this.t.instant('AssetsLibrary.SuccessDelete'));
          this.getAssets();
        }
      }),
    );
  }
}
