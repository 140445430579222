import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AssetAdType, AssetPlacement, AssetPlatform, AssetResolution } from '@shared/models/activity-asset.model';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiContextWithImplicit, TuiDialog, TuiStringHandler } from '@taiga-ui/cdk';
import { filter, finalize } from 'rxjs/operators';
import { TuiMultiSelectComponent, TuiSelectComponent } from '@taiga-ui/kit';
import { TacticActivitiesParametersService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-activities-parameters.service';
import { EActivityAssetForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-asset-form.enum';
import {
  getNewAssetForm,
  getResolutionsControl,
} from '@modules/tactics/modules/tactic-settings/shared/helpers/tactic-form.helper';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';

@Component({
  selector: 'df-activity-asset-modal',
  templateUrl: './activity-asset-modal.component.html',
  styleUrls: ['./activity-asset-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityAssetModalComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup = getNewAssetForm();
  readonly EActivityAssetForm: typeof EActivityAssetForm = EActivityAssetForm;
  loading = false;

  readonly platforms$: Observable<AssetPlatform[]>;
  readonly adTypes$: Observable<AssetAdType[]>;
  readonly placements$: Observable<AssetPlacement[]>;
  readonly resolutions$: Observable<AssetResolution[] | null>;
  readonly loading$: Observable<boolean>;
  @ViewChild('platformSelect')
  platformSelectComponent!: TuiSelectComponent<any>;
  @ViewChild('adTypeSelect') adTypeSelectComponent?: TuiSelectComponent<any>;
  @ViewChild('placementSelect')
  placementSelectComponent?: TuiSelectComponent<any>;
  @ViewChild('resolutionsSelect')
  resolutionsMultiselectComponent?: TuiMultiSelectComponent<any>;

  get assetsArray(): UntypedFormArray | null {
    return this.context.data.assetsArray ?? null;
  }

  constructor(
    private readonly tacticActivitiesParametersService: TacticActivitiesParametersService,
    private readonly changes: ChangeDetectorRef,
    private readonly tacticSettingsService: TacticSettingsService,
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<any, boolean>,
  ) {
    super();

    this.platforms$ = this.tacticActivitiesParametersService.platforms$;
    this.adTypes$ = this.tacticActivitiesParametersService.adTypes$;
    this.placements$ = this.tacticActivitiesParametersService.placements$;
    this.resolutions$ = this.tacticActivitiesParametersService.resolutions$;
    this.loading$ = this.tacticActivitiesParametersService.loading$;
  }

  readonly stringify: TuiStringHandler<AssetResolution | TuiContextWithImplicit<AssetResolution>> = (item) =>
    'width' in item && 'height' in item
      ? `${item.width}x${item.height}`
      : `${item.$implicit.width}x${item.$implicit.height}`;

  ngOnInit(): void {
    this._listenPlatformChanges();
    this._listenAdTypeChanges();
    this._listenPlacementChanges();
    this._listenPlacementsLengthChanges();
  }

  addAsset(): void {
    if (this.form.valid && this.assetsArray) {
      this.loading = true;
      this.tacticSettingsService
        .createAsset(this.assetsArray, this.form)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.changes.detectChanges();
          }),
        )
        .subscribe((value) => this.context.completeWith(value));
    } else {
      this.form.markAllAsTouched();
      this._detectSelectsChanges();
      this.changes.detectChanges();
    }
  }

  discard(): void {
    this.context.completeWith();
  }

  private _detectSelectsChanges(): void {
    this.platformSelectComponent.checkControlUpdate();
    this.adTypeSelectComponent?.checkControlUpdate();
    this.placementSelectComponent?.checkControlUpdate();
    this.resolutionsMultiselectComponent?.checkControlUpdate();
  }

  private _listenPlatformChanges(): void {
    this.sub.add(
      this.form.get(EActivityAssetForm.platformId)?.valueChanges.subscribe((value: AssetPlatform | null) => {
        this.form.get(EActivityAssetForm.addTypeId)?.setValue(null);
        this.form.get(EActivityAssetForm.addTypeId)?.markAsUntouched();
        if (value) {
          this.tacticActivitiesParametersService.fetchAdTypes(value.id);
        } else {
          this.tacticActivitiesParametersService.resetAdTypes();
        }
        this.changes.detectChanges();
      }),
    );
  }

  private _listenAdTypeChanges(): void {
    this.sub.add(
      this.form.get(EActivityAssetForm.addTypeId)?.valueChanges.subscribe((value: AssetAdType | null) => {
        this.form.get(EActivityAssetForm.placementId)?.setValue(null);
        this.form.get(EActivityAssetForm.placementId)?.markAsUntouched();
        if (value) {
          this.tacticActivitiesParametersService.fetchPlacements(
            this.form.value[EActivityAssetForm.platformId].id,
            value.id,
          );
        } else {
          this.tacticActivitiesParametersService.resetPlacements();
        }
        this.changes.detectChanges();
      }),
    );
  }

  private _listenPlacementChanges(): void {
    this.sub.add(
      this.form.get(EActivityAssetForm.placementId)?.valueChanges.subscribe((value: AssetPlacement | null) => {
        this.form.get(EActivityAssetForm.resolutionsIds)?.setValue([]);
        this.form.get(EActivityAssetForm.resolutionsIds)?.markAsUntouched();
        if (value) {
          this.tacticActivitiesParametersService
            .fetchResolutions(
              this.form.value[EActivityAssetForm.platformId].id,
              this.form.value[EActivityAssetForm.addTypeId].id,
              value.id,
            )
            .add(() => this._updateResolutionsControl());
        } else {
          this.tacticActivitiesParametersService.resetResolutions();
        }
        this.changes.detectChanges();
      }),
    );
  }

  private _listenPlacementsLengthChanges(): void {
    this.sub.add(
      this.placements$
        .pipe(filter((placements: AssetPlacement[]) => placements.length === 1))
        .subscribe((placements: AssetPlacement[]) =>
          this.form.get(EActivityAssetForm.placementId)?.setValue(placements[0]),
        ),
    );
  }

  private _updateResolutionsControl(): void {
    if (this.tacticActivitiesParametersService.resolutionsLength) {
      this.form.addControl(EActivityAssetForm.resolutionsIds, getResolutionsControl());
    } else {
      this.form.removeControl(EActivityAssetForm.resolutionsIds);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.tacticSettingsService.resetAssetsParametersData();
  }
}
