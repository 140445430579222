import { Injectable } from '@angular/core';
import { GetResponseEvent } from '@modules/graphql/graphql-types';
import { GetResponseEventsService } from '@shared/services/get-response-events.service';
import { STATEMENT_STEP_ENUM } from '../models/statement.configuration';
import { SentEvent } from '../models/statement.interface';

@Injectable({
  providedIn: 'root',
})
export class StatementEventsService {
  private alreadySentEvents: SentEvent[] = [];

  constructor(private readonly getResponseEventsService: GetResponseEventsService) {}

  completedStatementStep(step: STATEMENT_STEP_ENUM, funnelId: number | null): void {
    const event: GetResponseEvent | null = this.resolveCompletedStatementStepGetResponseEvent(step);
    if (!event || !funnelId) return;
    if (this.hasAlreadySentEvent(funnelId, event)) return;
    this.storeEvent(funnelId, event);
    this.executeSendEvent(event);
  }

  sendSkipStatementEvent(): void {
    this.executeSendEvent(GetResponseEvent.UserSkippedStatement);
  }

  private hasAlreadySentEvent(funnelId: number, event: GetResponseEvent): boolean {
    for (let index = 0; index < this.alreadySentEvents.length; index++) {
      if (this.alreadySentEvents[index].funnelId === funnelId && this.alreadySentEvents[index].event === event)
        return true;
    }
    return false;
  }

  private storeEvent(funnelId: number, event: GetResponseEvent): void {
    this.alreadySentEvents.push({ funnelId: funnelId, event: event });
  }

  private resolveCompletedStatementStepGetResponseEvent(step: STATEMENT_STEP_ENUM | null): GetResponseEvent | null {
    switch (step) {
      case STATEMENT_STEP_ENUM.ONE:
        return GetResponseEvent.UserCompletedStatementStep_1;
      case STATEMENT_STEP_ENUM.TWO:
        return GetResponseEvent.UserCompletedStatementStep_2;
      case STATEMENT_STEP_ENUM.THREE:
        return GetResponseEvent.UserCompletedStatementStep_3;
      default:
        return null;
    }
  }

  private executeSendEvent(event: GetResponseEvent): void {
    this.getResponseEventsService.triggerGetResponseEvent(event);
  }

  public clearStoredEvents(): void {
    this.alreadySentEvents = [];
  }
}
