import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { DashboardInputGraphql, DashboardTypeEnum } from '@modules/graphql/graphql-types';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetDashboardsQuery } from '../graphql/queries/get-dashboard.query.generated';
import { StatisticReport } from '../models/funnel-statistics.interface';
import { FunnelStatisticsGraphqlService } from './funnel-statistics-graphql.service';

interface NewRaportForm {
  funnelId: number;
  reportUrl: string;
  reportName: string;
  reportType: DashboardTypeEnum;
}

@Injectable({
  providedIn: 'root',
})
export class FunnelStatisticsService {
  private _statisticReport: StatisticReport[] = [];

  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  private readonly _processingForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly processingForm$: Observable<boolean> = this._processingForm$.asObservable();

  get statisticReport(): StatisticReport[] {
    return this._statisticReport;
  }

  constructor(
    private navigateService: NavigateService,
    private funnelStatisticsGraphqlService: FunnelStatisticsGraphqlService,
  ) {}

  public setLoader(loader: boolean): void {
    this._loading$.next(loader);
  }

  public saveNewReport(form: NewRaportForm): void {
    this._processingForm$.next(true);
    this.createDashboard({
      funnelId: form.funnelId,
      link: form.reportUrl,
      name: form.reportName,
      type: form.reportType,
    });
  }

  public removeDashboard(id: number) {
    this.setLoader(true);
    return this.funnelStatisticsGraphqlService.removeDashboard(id).pipe();
  }

  public createDashboard(input: DashboardInputGraphql) {
    this._processingForm$.next(true);
    this.funnelStatisticsGraphqlService
      .createDashboard(input)
      .pipe()
      .subscribe({
        next: () => this.finishCreateDashboard(input.funnelId),
        error: () => this.finishCreateDashboard(input.funnelId),
      });
  }

  private finishCreateDashboard(funnelId: number): void {
    this._processingForm$.next(false);
    this.navigateService.go('reports-list', { id: funnelId });
  }

  public fetchDashboards(funnelId: number) {
    this.setLoader(true);
    return this.funnelStatisticsGraphqlService.getDashboards(funnelId, 200).pipe(
      map((res: FetchResult<GetDashboardsQuery>) => {
        return res?.data?.getDashboards ? res?.data?.getDashboards : [];
      }),
    );
  }
}
