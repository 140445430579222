import { Component, Input } from '@angular/core';

@Component({
  selector: 'df-tactic-prameter-box',
  templateUrl: './tactic-prameter-box.component.html',
  styleUrls: ['./tactic-prameter-box.component.scss'],
})
export class TacticPrameterBoxComponent {
  @Input() icon = '';
  @Input() label = '';
  @Input() value = '';
  @Input() valueColorClass = '';
}
