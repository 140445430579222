import { gql } from 'apollo-angular';

export const FilterStepsDocument = gql(/* GraphQL*/ `query filterSteps($input: FilterInputGraphql!)
{
  filterSteps(input: $input)
  {
    records{
      name
      id
      object
      {
        id
        name
        type
      }
    }
    total
  }
}

`);
