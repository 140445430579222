import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { STATEMENT_STEP_ENUM } from '../../models/statement.configuration';
import { StatementFormService } from '../../services/statement-form.service';
import { StatementService } from '../../services/statement.service';

@Component({
  selector: 'df-statement-steps',
  templateUrl: './statement-steps.component.html',
  styleUrls: ['./statement-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementStepsComponent extends AbstractSubscriptionComponent implements OnInit {
  step = 0;
  STATEMENT_STEP_ENUM = STATEMENT_STEP_ENUM;

  constructor(
    private readonly statementService: StatementService,
    private changes: ChangeDetectorRef,
    private statementFormService: StatementFormService,
  ) {
    super();
  }

  get step1Success(): boolean {
    return this.step > STATEMENT_STEP_ENUM.ONE && this.statementFormService.stepValidationResults[0].validationResult;
  }

  get step2Success(): boolean {
    return this.step > STATEMENT_STEP_ENUM.TWO && this.statementFormService.stepValidationResults[1].validationResult;
  }

  ngOnInit(): void {
    this.initializeChangeStepSubscription();
  }

  private initializeChangeStepSubscription(): void {
    this.sub.add(
      this.statementService.stepNr.asObservable().subscribe({
        next: (step) => this.setStep(step),
      }),
    );
  }

  private setStep(step: number | null): void {
    this.step = step === null ? 0 : step;
    this.changes.detectChanges();
  }
}
