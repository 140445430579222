<div class="flex flex-col justify-center items-center md:min-h-[100vh] gap-2 px-2 md:gap-2 md:px-2">
  <form
    class="min-w-[325px] mt-6 md:mt-0"
    [formGroup]="form"
    [validate-form]="form"
  >
    <h1 class="md:mr-44 font-bold">Choose your goal</h1>
    <df-awsome-checkbox
      [form]="form"
      [fieldName]="'answer'"
      [checkboxItems]="items"
    ></df-awsome-checkbox>
  </form>

  <div>
    <df-statement-content-wrapper class="relative">
      <form
        (submit)="statementFormSubmit()"
        *ngIf="statementFormService.isFormReady"
        [formGroup]="statementFormService.form"
        [validate-form]="statementFormService.form"
        class="statement-container container overflow-hidden m-auto flex flex-col justify-start items-start"
      >
        <div
          class="statement-text"
          #statementStep1Container
        >
          <div class="w-full leading-[3rem] md:leading-[4rem]">
            <ng-container>
              <df-statement-section-header [header]="'Describe your business '"></df-statement-section-header>
              <span class="statement-text-fragment">{{ 'Statement.Our' | translate }}</span>
              <!-- Product Type -->
              <df-statement-select-wrapper
                class="custom-mobile-select custom-mobile-select-small-margin"
                #productTypeSelectWrapper
              >
                <df-statement-select
                  #productType
                  [name]="'Statement.product type' | translate"
                  [form]="statementFormService.form"
                  [maxItems]="statementNewParametersService.productType?.type | statementMaxItems"
                  [controlName]="FieldCategoryEnum.ProductType"
                  [items]="statementNewParametersService.productType?.options"
                  [formSubmitted]="formSubmitted"
                  (dropdownOpen)="productTypeSelectWrapper.onDropdownChange($event)"
                >
                </df-statement-select>
                <df-statement-inline-value
                  [controlName]="FieldCategoryEnum.ProductType"
                  [form]="statementFormService.form"
                  [component]="productType"
                >
                </df-statement-inline-value>
              </df-statement-select-wrapper>

              <span class="statement-text-fragment">{{ 'Statement.is dedicated for' | translate }}</span>

              <!-- Target characteristics -->
              <df-statement-select-wrapper
                class="custom-mobile-select"
                #targetCharacteristicSelectWrapper
              >
                <df-statement-select
                  #targetCharacteristic
                  [name]="'Statement.target characteristics' | translate"
                  [form]="statementFormService.form"
                  [maxItems]="statementNewParametersService.targetCharacteristics?.type | statementMaxItems"
                  [controlName]="FieldCategoryEnum.TargetCharacteristics"
                  [items]="statementNewParametersService.targetCharacteristics?.options"
                  [formSubmitted]="formSubmitted"
                  (dropdownOpen)="targetCharacteristicSelectWrapper.onDropdownChange($event)"
                >
                </df-statement-select>
                <df-statement-inline-value
                  [controlName]="FieldCategoryEnum.TargetCharacteristics"
                  [form]="statementFormService.form"
                  [component]="targetCharacteristic"
                >
                </df-statement-inline-value>
              </df-statement-select-wrapper>
              <br />

              <span class="statement-text-fragment">{{ 'Statement.located in' | translate }}</span>

              <!-- Target location -->
              <df-statement-select-wrapper
                class="custom-mobile-select"
                #targetLocationSelectWrapper
              >
                <df-statement-select
                  #targetLocation
                  [name]="'Statement.location' | translate"
                  [form]="statementFormService.form"
                  [maxItems]="statementNewParametersService.targetLocation?.type | statementMaxItems"
                  [controlName]="FieldCategoryEnum.TargetLocation"
                  [items]="statementNewParametersService.targetLocation?.options"
                  [formSubmitted]="formSubmitted"
                  (dropdownOpen)="targetLocationSelectWrapper.onDropdownChange($event)"
                >
                </df-statement-select>
                <df-statement-inline-value
                  [controlName]="FieldCategoryEnum.TargetLocation"
                  [form]="statementFormService.form"
                  [component]="targetLocation"
                >
                </df-statement-inline-value>
              </df-statement-select-wrapper>
              <span class="statement-text-fragment">.</span>
              <br />
              <br />
              <!-- goalType -->
              <span class="statement-text-fragment">{{
                'Statement.Mostly, marketing contributes to sales by' | translate
              }}</span>
              <df-statement-tiles-radio
                class="mr-[0.5rem]"
                [form]="statementFormService.form"
                [controlName]="FieldCategoryEnum.GoalType"
                [items]="statementNewParametersService.goalType?.options ?? []"
                [formSubmitted]="formSubmitted"
              ></df-statement-tiles-radio>
              <span class="statement-text-fragment">.</span>
              <br />
              <br />
              <span class="statement-text-fragment">{{
                'Statement.I describe my marketing skills as an' | translate
              }}</span>

              <df-statement-select-wrapper
                class="custom-mobile-select"
                #marketingAdvancementSelectWrapper
              >
                <df-statement-select
                  #marketingAdvancement
                  [name]="'Statement.skill' | translate"
                  [form]="statementFormService.form"
                  [maxItems]="statementNewParametersService.marketingAdvancement?.type | statementMaxItems"
                  [controlName]="FieldCategoryEnum.MarketingAdvancement"
                  [items]="statementNewParametersService.marketingAdvancement?.options"
                  [formSubmitted]="formSubmitted"
                  (dropdownOpen)="marketingAdvancementSelectWrapper.onDropdownChange($event)"
                >
                </df-statement-select>
                <df-statement-inline-value
                  [controlName]="FieldCategoryEnum.MarketingAdvancement"
                  [form]="statementFormService.form"
                  [component]="marketingAdvancement"
                >
                </df-statement-inline-value>
              </df-statement-select-wrapper>
            </ng-container>

            <ng-container *ngIf="getAnswer()?.value?.id === 1">
              <br /><br /><span class="statement-text-fragment">The goal is to generate</span>
              <df-statement-input
                [form]="statementFormService.form"
                [controlName]="FieldCategoryEnum.GoalMeasureLeads"
                [name]="'Statement.amount' | translate"
                [container]="statementStep1Container"
                [formSubmitted]="formSubmitted"
              >
              </df-statement-input>
              <span class="statement-text-fragment">leads in</span>
              <df-statement-select-wrapper
                class="custom-mobile-select"
                #goalPeriodSelectWrapper
              >
                <df-statement-select
                  #goalPeriod
                  [name]="'Statement.time' | translate"
                  [form]="statementFormService.form"
                  [maxItems]="statementNewParametersService.goalPeriod?.type | statementMaxItems"
                  [controlName]="FieldCategoryEnum.GoalPeriod"
                  [items]="statementNewParametersService.goalPeriod?.options"
                  [formSubmitted]="formSubmitted"
                  (dropdownOpen)="goalPeriodSelectWrapper.onDropdownChange($event)"
                >
                </df-statement-select>
                <df-statement-inline-value
                  [controlName]="FieldCategoryEnum.GoalPeriod"
                  [form]="statementFormService.form"
                  [component]="goalPeriod"
                >
                </df-statement-inline-value>
              </df-statement-select-wrapper>
            </ng-container>

            <ng-container *ngIf="getAnswer()?.value?.id === 2">
              <br /><br /><span class="statement-text-fragment">The goal is to generate</span>
              <df-statement-input
                [form]="statementFormService.form"
                [controlName]="FieldCategoryEnum.GoalMeasureSales"
                [name]="'Statement.amount' | translate"
                [container]="statementStep1Container"
                [formSubmitted]="formSubmitted"
              >
              </df-statement-input>
              <span class="statement-text-fragment">of a extra sale, in</span>
              <df-statement-select-wrapper
                class="custom-mobile-select"
                #goalPeriodSelectWrapper
              >
                <df-statement-select
                  #goalPeriod
                  [name]="'Statement.time' | translate"
                  [form]="statementFormService.form"
                  [maxItems]="statementNewParametersService.goalPeriod?.type | statementMaxItems"
                  [controlName]="FieldCategoryEnum.GoalPeriod"
                  [items]="statementNewParametersService.goalPeriod?.options"
                  [formSubmitted]="formSubmitted"
                  (dropdownOpen)="goalPeriodSelectWrapper.onDropdownChange($event)"
                >
                </df-statement-select>
                <df-statement-inline-value
                  [controlName]="FieldCategoryEnum.GoalPeriod"
                  [form]="statementFormService.form"
                  [component]="goalPeriod"
                >
                </df-statement-inline-value>
              </df-statement-select-wrapper>
            </ng-container>

            <ng-container *ngIf="getAnswer()?.value?.id === 3">
              <br /><br /><span class="statement-text-fragment">The goal is to generate</span>
              <df-statement-input
                [form]="statementFormService.form"
                [controlName]="FieldCategoryEnum.GoalMeasureFirstCustomer"
                [name]="'Statement.amount' | translate"
                [container]="statementStep1Container"
                [formSubmitted]="formSubmitted"
              >
              </df-statement-input>
              <span class="statement-text-fragment">first customers in</span>
              <df-statement-select-wrapper
                class="custom-mobile-select"
                #goalPeriodSelectWrapper
              >
                <df-statement-select
                  #goalPeriod
                  [name]="'Statement.time' | translate"
                  [form]="statementFormService.form"
                  [maxItems]="statementNewParametersService.goalPeriod?.type | statementMaxItems"
                  [controlName]="FieldCategoryEnum.GoalPeriod"
                  [items]="statementNewParametersService.goalPeriod?.options"
                  [formSubmitted]="formSubmitted"
                  (dropdownOpen)="goalPeriodSelectWrapper.onDropdownChange($event)"
                >
                </df-statement-select>
                <df-statement-inline-value
                  [controlName]="FieldCategoryEnum.GoalPeriod"
                  [form]="statementFormService.form"
                  [component]="goalPeriod"
                >
                </df-statement-inline-value>
              </df-statement-select-wrapper>
            </ng-container>
          </div>
        </div>

        <button
          [showLoader]="!!(statementService.creatingStatementLoader$ | async)"
          [disabled]="!!(statementService.creatingStatementLoader$ | async)"
          type="submit"
          class="absolute bottom-[10px] right-[10px] md:bottom-[5.5rem] md:right-[50px]"
          tuiButton
        >
          Save
        </button>
      </form>
    </df-statement-content-wrapper>
  </div>
</div>
