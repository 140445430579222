<div class="flex items-center">
  <div class="flex flex-col justify-center items-start mr-8">
    <h2 class="font-bold block mb-4 tui-text_h2">
      {{ 'Statement.Great' | translate }}
    </h2>
    <h3 class="tui-text-01 tui-text_h5 mb-8">
      {{ "Statement.You've just filled up your first statement!" | translate }}<br />
    </h3>
  </div>
  <img
    src="/assets/img/statement.gif"
    alt="statement"
    width="150"
    class="hidden md:inline-flex rounded-2xl"
  />
</div>

<h3 class="tui-text-01 tui-text_h5 mt-8">{{ 'Statement.What do you want to do with this?' | translate }}<br /></h3>
<div
  class="flex flex-col items-start justify-start"
  *ngIf="!sendingOnEmail; else emailInput"
>
  <button
    size="l"
    tuiButton
    type="submit"
    class="mb-4 mt-4"
    (click)="openEmailInput()"
    appearance="primary"
  >
    {{ 'Statement.Send on e-mail' | translate }}
  </button>
  <button
    size="l"
    tuiButton
    type="submit"
    class="mb-4"
    (click)="register()"
    appearance="outline"
  >
    {{ 'Statement.Sign up' | translate }}
  </button>
  <button
    size="l"
    tuiButton
    type="submit"
    class="mb-4"
    (click)="copyClipboard()"
    appearance="outline"
  >
    {{ 'Statement.Copy to clipboard' | translate }}
  </button>
</div>

<ng-template #emailInput>
  <div class="tui-form__row w-full flex flex-nowrap flex-col md:flex-row">
    <div class="w-full">
      <tui-input
        tuiAutoFocus
        [formControl]="singleEmail"
      >
        {{ 'email' | translate }}
      </tui-input>
      <df-error-input [control]="singleEmail"></df-error-input>
    </div>
    <button
      size="l"
      tuiButton
      type="button"
      [showLoader]="loading"
      [disabled]="loading"
      appearance="primary"
      class="ml-0 md:ml-2 whitespace-nowrap"
      (click)="sendOnEmail()"
    >
      {{ 'send' | translate }}
    </button>
  </div>
</ng-template>
