import { AuthPanelComponent } from '@modules/authorization/pages/auth-panel/auth-panel.component';
import { CampaignSetUrlComponent } from '@modules/marketing-campaign/pages/campaign-set-url/campaign-set-url.component';

import { CampaignComponent } from '@modules/marketing-campaign/pages/campaign/campaign.component';

export const MarketingCampaignRoutes = [
  {
    path: 'set-url',
    component: AuthPanelComponent,
    children: [
      {
        path: '',
        component: CampaignSetUrlComponent,
      },
    ],
  },
  {
    path: 'campaign/:funnelId',
    component: CampaignComponent,
  },
  {
    path: 'campaing/:funnelId',
    component: CampaignComponent,
  },
];
