<df-full-list-loader *ngIf="loading"></df-full-list-loader>
<div class="df-container pb-20 md:pb-8">
  <form
    class="df-container-content"
    [formGroup]="form"
    [validate-form]="form"
  >
    <div class="df-two-columns">
      <div
        class="df-column justify-between"
        *ngIf="tacticSettingsService?.tactic?.currentUserPermission === FunnelPermissionEnum.Editor || !isEditMode"
      >
        <div class="flex flex-col justify-start items-stretch">
          <h3 class="df-column-title">
            {{ 'UserSettings.Teams.General' | translate }}
          </h3>

          <div class="tui-form__row">
            <tui-input
              [formControlName]="ETacticSettingsFormEnum.name"
              [attr.data-error-control]="ETacticSettingsFormEnum.name"
              [required]="true"
            >
              {{ 'Tactics.name' | translate }}
            </tui-input>
            <df-error-input [control]="getControl('name')"></df-error-input>
          </div>

          <div class="tui-form__row">
            <p class="text-sm tui-text-03 mb-2.5">
              {{ 'Tactics.image' | translate }}
            </p>
            <div class="flex flex-row items-center justify-start">
              <tui-avatar
                class="mr-4"
                *ngIf="tacticSettingsService.tactic?.image && !form.get(ETacticSettingsFormEnum.image)?.value"
                [size]="'l'"
                [avatarUrl]="tacticSettingsService.tactic?.image?.file | assetsFile"
                [autoColor]="true"
              ></tui-avatar>
              <df-input-file
                #iconInput
                [ngClass]="{
                  'w-auto flex-grow': tacticSettingsService.tactic?.image,
                  'w-full': !tacticSettingsService.tactic?.image
                }"
                [maxFileSize]="10000000"
                [form]="form"
                [link]="'choose an image' | translate"
                [accept]="Config.ACCEPTED_IMAGES_EXTENSIONS"
                [formName]="ETacticSettingsFormEnum.image"
                [multiple]="false"
                size="l"
              ></df-input-file>
            </div>
          </div>

          <df-tactic-settings-accessibility
            *ngIf="!isPublic && !userService.User?.isSemrushPlan()"
            class="mt-6"
          ></df-tactic-settings-accessibility>

          <df-tactic-verification-banner
            *ngIf="
              !isPublic &&
              isEditMode &&
              !!tacticSettingsService.tactic?.verificationStatus &&
              tacticSettingsService.tactic?.verificationStatus !== VerificationStatusEnum.Success &&
              !tacticSettingsService.tactic?.isPrivate
            "
            class="w-full mt-6"
            [tactic]="tacticSettingsService.tactic"
            [vertical]="true"
          ></df-tactic-verification-banner>
        </div>

        <div class="hidden lg:block">
          <ng-container *ngTemplateOutlet="deleteTacticButton"></ng-container>
        </div>
      </div>
      <div class="df-column pl-0 pt-0 pr-0 w-full">
        <tui-tabs
          class="h-14 px-4"
          *ngIf="tacticSettingsService.activeTab as activeTab"
          (activeItemIndexChange)="setActiveTab($event)"
          [activeItemIndex]="activeTab.index"
        >
          <button
            tuiTab
            *ngFor="let tab of tacticSettingsService.tabs"
          >
            <span [class.warning-dot]="tab.invalid && tab !== tacticSettingsService.activeTab">{{ tab.name }}</span>
          </button>
        </tui-tabs>
        <div
          class="p-4"
          *ngIf="tacticSettingsService.activeTab"
        >
          <df-tactic-settings-about
            [class.hidden]="tacticSettingsService.activeTab.type !== EContainerTabType.ABOUT"
          ></df-tactic-settings-about>

          <df-tactic-settings-activities
            [class.hidden]="tacticSettingsService.activeTab.type !== EContainerTabType.ACTIVITIES"
          ></df-tactic-settings-activities>

          <df-tactic-settings-metrics
            [class.hidden]="tacticSettingsService.activeTab.type !== EContainerTabType.METRICS"
          ></df-tactic-settings-metrics>

          <df-resource-teams
            [title]="
              tacticSettingsService.activeTab.sectionTitle
                ? tacticSettingsService.activeTab.sectionTitle
                : tacticSettingsService.activeTab.name
                  ? tacticSettingsService.activeTab.name
                  : ''
            "
            [resourceTypeView]="EResourceViewType.TACTIC"
            (onComponentReady)="onResourceTeamsComponentReady($event)"
            (onResourcesChange)="tacticSettingsService._changesInForm$.next($event)"
            [tactic]="this.tacticSettingsService.tactic"
            *ngIf="this.tacticSettingsService?.tactic || !isEditMode"
            [class.hidden]="tacticSettingsService.activeTab.type !== EContainerTabType.TEAM"
          >
          </df-resource-teams>

          <df-tactic-settings-share
            [class.hidden]="tacticSettingsService.activeTab.type !== EContainerTabType.SHARE"
          ></df-tactic-settings-share>
        </div>
        <div class="lg:hidden">
          <ng-container *ngTemplateOutlet="deleteTacticButton"></ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #deleteTacticButton>
  <div
    class="flex flex-col sm:flex-row w-full sm:justify-between items-center px-4 lg:px-0"
    *ngIf="
      !loading && isEditMode && tacticSettingsService?.tactic?.currentUserPermission === FunnelPermissionEnum.Editor
    "
  >
    <button
      size="xl"
      tuiButton
      class="tui-accent-01"
      type="button"
      appearance="outline"
      mwlConfirmationPopover
      [popoverTitle]="'Tactics.Settings.Deleting tactic' | translate"
      [popoverMessage]="
        'Tactics.Settings.Do you really want to remove this tactic? You will loose all data.' | translate
      "
      [appendToBody]="true"
      placement="top"
      (confirm)="deleteTactic()"
    >
      {{ 'Tactics.Settings.Delete tactic' | translate }}
    </button>
    <span class="tui-text-04 tui-text_body-s mt-3 sm:mt-0 sm:ml-3">
      {{
        'Tactics.Settings.Created'
          | translate
            : {
                date: (tacticSettingsService.tactic?.createdAt | dateFormat: 'DDD' : language)
              }
      }}
    </span>
  </div>
</ng-template>
<df-onboarding-video
  [src]="'https://help.digitalfirst.ai/en/articles/9148523-create-your-own-marketing-tactics#h_4dd24a2d58'"
  type="link"
></df-onboarding-video>
