import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, map } from 'rxjs/operators';

import {
  CanvasCategoryAddOrInputInput,
  CanvasCreateInstanceInput,
  CanvasFormInput,
  CanvasSectionInput,
} from '@modules/graphql/graphql-types';
import { GetCanvasTemplateQuery } from '@modules/canvas/shared/graphql/queries/get-canvas-template.query.generated';
import { GetCanvasTemplateDocument } from '@modules/canvas/shared/graphql/queries/get-canvas-template.query';
import { GetCanvasTemplatesQuery } from '@modules/canvas/shared/graphql/queries/get-canvas-templates.query.generated';
import { GetCanvasTemplatesDocument } from '@modules/canvas/shared/graphql/queries/get-canvas-templates.query';
import { GetCanvasCategoriesQuery } from '@modules/canvas/shared/graphql/queries/get-canvas-categories.query.generated';
import { GetCanvasCategoriesDocument } from '@modules/canvas/shared/graphql/queries/get-canvas-categories.query';
import { GetCanvasCategoryQuery } from '@modules/canvas/shared/graphql/queries/get-canvas-category.query.generated';
import { GetCanvasCategoryDocument } from '@modules/canvas/shared/graphql/queries/get-canvas-category.query';
import { GetCanvasSectionsQuery } from '@modules/canvas/shared/graphql/queries/get-canvas-sections.query.generated';
import { GetCanvasSectionsDocument } from '@modules/canvas/shared/graphql/queries/get-canvas-sections.query';
import { RemoveCanvasCategoryMutation } from '@modules/canvas/shared/graphql/mutations/remove-canvas-category.mutation.generated';
import { RemoveCanvasCategoryDocument } from '@modules/canvas/shared/graphql/mutations/remove-canvas-category.mutation';
import { EditOrAddCategoryMutation } from '@modules/canvas/shared/graphql/mutations/add-or-edit-canvas-category.mutation.generated';
import { AddOrEditCanvasCategoryDocument } from '@modules/canvas/shared/graphql/mutations/add-or-edit-canvas-category.mutation';
import { AddCanvasFormSectionMutation } from '@modules/canvas/shared/graphql/mutations/add-canvas-section.mutation.generated';
import { AddCanvasSectionDocument } from '@modules/canvas/shared/graphql/mutations/add-canvas-section.mutation';
import { AddCanvasMutation } from '@modules/canvas/shared/graphql/mutations/add-canvas.mutation.generated';
import { AddCanvasDocument } from '@modules/canvas/shared/graphql/mutations/add-canvas.mutation';
import { CreateCanvasInstanceMutation } from '@modules/canvas/shared/graphql/mutations/create-canvas-instance.mutation.generated';
import { CreateCanvasInstanceDocument } from '@modules/canvas/shared/graphql/mutations/create-canvas-instance.mutation';
import { GetCanvasInstanceByFunnelAndTemplateQuery } from '@modules/canvas/shared/graphql/queries/get-canvas-instance-by-funnel-any-template.query.generated';
import { GetCanvasInstanceByFunnelAndTemplateDocument } from '@modules/canvas/shared/graphql/queries/get-canvas-instance-by-funnel-any-template.query';
import { GetCanvasAnswerSuggestionsQuery } from '@modules/canvas/shared/graphql/queries/get-canvas-answer-suggestions.query.generated';
import { GetCanvasAnswerSuggestionsDocument } from '@modules/canvas/shared/graphql/queries/get-canvas-answer-suggestions.query';
import { GetPromptForCanvasDocument } from '@modules/canvas/shared/graphql/queries/get-prompt-for-canvas.query';
import { GetPromptForCanvasQuery } from '@modules/canvas/shared/graphql/queries/get-prompt-for-canvas.query.generated';
import { of } from 'rxjs';
import { captureException } from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class CanvasService {
  constructor(private apollo: Apollo) {}

  getPromptForCanvas() {
    return this.apollo
      .query<GetPromptForCanvasQuery>({
        query: GetPromptForCanvasDocument,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res?.data?.getPromptForCanvas));
  }

  getCanvasTemplate(id: number) {
    return this.apollo
      .query<GetCanvasTemplateQuery>({
        query: GetCanvasTemplateDocument,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res?.data?.getCanvasTemplate));
  }

  getCanvasTemplates() {
    return this.apollo
      .query<GetCanvasTemplatesQuery>({
        query: GetCanvasTemplatesDocument,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data.getCanvasTemplates));
  }

  getCanvasInstanceByFunnelTemplate(funnelId: number, templateId: number) {
    return this.apollo
      .query<GetCanvasInstanceByFunnelAndTemplateQuery>({
        query: GetCanvasInstanceByFunnelAndTemplateDocument,
        variables: {
          funnelId,
          templateId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data.getCanvasInstanceByFunnelAndTemplate));
  }

  getCategories() {
    return this.apollo
      .query<GetCanvasCategoriesQuery>({
        query: GetCanvasCategoriesDocument,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res?.data?.getCanvasCategories));
  }

  getCategory(id: number) {
    return this.apollo
      .query<GetCanvasCategoryQuery>({
        query: GetCanvasCategoryDocument,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res?.data?.getCanvasCategory));
  }

  getCanvasSuggestions(canvasTemplateId: number) {
    return this.apollo
      .query<GetCanvasAnswerSuggestionsQuery>({
        query: GetCanvasAnswerSuggestionsDocument,
        variables: { canvasTemplateId },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        catchError((err) => {
          captureException(err);
          return of(null);
        }),
        map((res) => res?.data?.getCanvasAnswerSuggestions ?? null),
      );
  }

  getSections() {
    return this.apollo
      .query<GetCanvasSectionsQuery>({
        query: GetCanvasSectionsDocument,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res?.data?.getCanvasSections));
  }

  removeCategory(id: number) {
    return this.apollo.mutate<RemoveCanvasCategoryMutation>({
      mutation: RemoveCanvasCategoryDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  addOrEditCategory(input: CanvasCategoryAddOrInputInput) {
    return this.apollo.mutate<EditOrAddCategoryMutation>({
      mutation: AddOrEditCanvasCategoryDocument,
      fetchPolicy: 'no-cache',
      variables: {
        input,
      },
    });
  }

  setSection(input: CanvasSectionInput) {
    return this.apollo.mutate<AddCanvasFormSectionMutation>({
      mutation: AddCanvasSectionDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  setCanvas(input: CanvasFormInput) {
    return this.apollo.mutate<AddCanvasMutation>({
      mutation: AddCanvasDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createCanvasInstance(input: CanvasCreateInstanceInput) {
    return this.apollo.mutate<CreateCanvasInstanceMutation>({
      mutation: CreateCanvasInstanceDocument,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }
}
