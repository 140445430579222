<section class="auth-panel w-full h-full overflow-y-auto flex flex-col justify-start items-stretch">
  <df-onboarding-step-bar *ngIf="isRegister"></df-onboarding-step-bar>
  <div class="flex-grow flex items-stretch">
    <div
      class="w-full lg:w-1/2"
      [class.flex-grow]="appSumo"
    >
      <router-outlet></router-outlet>
    </div>
    <div
      class="auth-panel__illustration w-1/2 h-full hidden lg:flex flex-shrink-0"
      [ngClass]="appSumo ? 'appsumo lg:hidden xl:flex w-auto justify-start items-center' : 'justify-end items-center'"
      *ngIf="!isRegister"
    >
      <img
        [src]="'/assets/img/' + (appSumo ? 'auth-appsumo.png' : 'auth-df.png')"
        [alt]="xPartnerService.partner.SYSTEM_NAME"
        class="lg:-ml-2 xl:-ml-8"
      />
    </div>
    <div
      class="hidden lg:flex w-1/2 flex-shrink-0 h-full"
      *ngIf="isRegister"
    >
      <df-onboarding-features-list class="mx-auto self-center"></df-onboarding-features-list>
    </div>
  </div>
</section>
