import { gql } from 'apollo-angular';

export const MarkFunnelTacticAsDoneDocument =
  gql(/* GraphQL*/ `mutation markFunnelTacticAsDone($isDone: Boolean!, $funnelTacticId: Int!) {
  markFunnelTacticAsDone (isDone: $isDone, funnelTacticId: $funnelTacticId) {
    id
    isDone
    tactic
    {
      id
      name
      funnelSteps
      {
        id
        type
        name
      }
    }
    step
    {
      id
    }
    position
  }
}
`);
