import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { UntypedFormGroup } from '@angular/forms';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { IFilter } from '@shared/interfaces/filter.interface';

@Component({
  selector: 'df-tactic-purchased-filter',
  templateUrl: './tactic-purchased-filter.component.html',
  styleUrls: ['./tactic-purchased-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticPurchasedFilterComponent {
  get form(): UntypedFormGroup {
    return this.tacticsFiltersService.filterFormGroup;
  }

  readonly stringifyCategory: TuiStringHandler<IFilter<any> | TuiContextWithImplicit<IFilter<any>>> = (item) =>
    'name' in item ? item.name : item.$implicit.name;
  constructor(public readonly tacticsFiltersService: TacticsFiltersService) {}
}
