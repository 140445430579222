import { gql } from 'apollo-angular';

export const GetCanvasCategoriesDocument = gql`
  query getCanvasCategories {
    getCanvasCategories {
      id
      name
      description
    }
  }
`;
