import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeResourceUrl',
})
export class SanitizeResourceUrlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string | undefined): SafeHtml {
    return value ? this._sanitizer.bypassSecurityTrustResourceUrl(value) : '';
  }
}
