import { gql } from 'apollo-angular';
import { TipTapDocumentFragment } from './tip-tap-document.fragment';
import { OwnerFragment } from './owner.fragment';

export const BaseWorkFlowFragment = gql`
  fragment BaseWorkFlowFields on BaseWorkFlowOutputGraphql {
    id
    goal
    name
    description
    type
    isPublic
    owner {
      ...OwnerFields
    }
    tipTapTemplateDocument {
      ...TipTapDocumentFields
    }
  }
  ${TipTapDocumentFragment}
  ${OwnerFragment}
`;
