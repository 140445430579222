import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PricingService, UPCOMING_FEATURES, UpcomingFeature } from '@modules/pricing/shared/services/pricing.service';
import { PricingGraphqlService } from '@modules/pricing/shared/services/pricing-graphql.service';
import { FetchResult } from '@apollo/client/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import {
  GetResponseEvent,
  PaymentIntervalEnum,
  PlanPermissionOutputGraphql,
  PriceOutputGraphql,
  SubscriptionPlanOutputGraphql,
} from '@modules/graphql/graphql-types';
import { GlobalDataService } from '@shared/services/global-data.service';
import { CreateSubscriptionMutation } from '@modules/pricing/shared/graphql/mutations/create-subscription.mutation.generated';
import { UserService } from '@shared/services/user.service';
import { Permission } from '@shared/models/permission.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { AuthModalComponent } from '@modules/public/shared/components/register-modal/auth-modal.component';
import { filter, map, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { PricingPromotionCodeModalComponent } from '@modules/pricing/shared/components/pricing-promotion-code-modal/pricing-promotion-code-modal.component';
import { GetResponseEventsService } from '@shared/services/get-response-events.service';
import { GenerateSubscriptionsManagementUrlMutation } from '@shared/graphql/mutations/generate-subscriptions-management-url.mutation.generated';
import { GetMarketingCampaignSubscriptionsPlansQuery } from '@modules/pricing/shared/graphql/queries/get-campaign-subscriptions-plans.query.generated';

@Component({
  selector: 'df-packets-campaign',
  templateUrl: './packets-campaign.component.html',
  styleUrls: ['./packets-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PacketsCampaignComponent implements OnInit, OnDestroy {
  readonly Config = Config;
  readonly PaymentIntervalEnum = PaymentIntervalEnum;

  plans: SubscriptionPlanOutputGraphql[] = [];
  public = true;
  loading = true;
  buyPlanLoading = false;
  userCurrentPlansIds: number[] = [];
  permissions: Permission[];
  credits: Array<number> = [];
  creditsList: number[] = [1200];
  buyFromParam = true;
  readonly upcomingFeatures: UpcomingFeature[] = UPCOMING_FEATURES;
  wentToCheckout = false;

  defaultTrialPlan: SubscriptionPlanOutputGraphql | null = null;

  urlToPayments: string | null = null;

  get usedTrial(): boolean {
    return !!this.userService.User?.usedTrial;
  }

  get usingTrial(): boolean {
    return this.userService.User?.trialDaysLeft !== null;
  }

  get trialEnded(): boolean | null {
    return this.userService.User?.recentlyEndedTrial ?? null;
  }

  get noActivePlan(): boolean {
    return !!this.userService.User && !this.userService.User!.hasActivePlan();
  }

  constructor(
    public pricingService: PricingService,
    private pricingGraphqlService: PricingGraphqlService,
    public globalData: GlobalDataService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
    public navigateService: NavigateService,
    private changes: ChangeDetectorRef,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly getResponseEventsService: GetResponseEventsService,
  ) {
    this.permissions = this.globalData.permissions.filter((item) => item.isAvailable);
  }

  ngOnInit(): void {
    this.setLoader(true);
    if (!this.userService.User) {
      const sub = this.getPackets();
      sub.add(() => (this.public ? this._checkBuyParam() : (this.buyFromParam = false)));
      sub.add(() => this.setLoader(false));
    } else {
      const sub = this.userService.getMe();
      sub.add(() => this._sendSeenPricingEvent());
      sub.add(() => this.getPackets().add(() => (this.public ? this._checkBuyParam() : (this.buyFromParam = false))));

      this.userService.generateSubscriptionManagementUrl().subscribe({
        next: (res: FetchResult<GenerateSubscriptionsManagementUrlMutation>) => {
          this.urlToPayments = res.data?.generateSubscriptionsManagementUrl.redirectUrl!;
          this.changes.detectChanges();
        },
        complete: () => this.setLoader(false),
      });
    }
    this.setCredits();
  }

  private setLoader(value: boolean): void {
    this.loading = value;
    this.changes.detectChanges();
  }

  setCredits() {
    this.credits = this.creditsList;
  }

  onHoverAccess(index: number) {
    this.clearAllHighlighted();
    const toHighlight = document.querySelectorAll(`[data-access-id="${index}"]`);
    toHighlight.forEach((el) => {
      el.classList.add('hovered');
    });
  }

  clearAllHighlighted() {
    const highlighted = document.querySelectorAll('.packets__info-list__access.hovered');
    highlighted.forEach((el) => {
      el.classList.remove('hovered');
    });
  }

  getPackets(): Subscription {
    const request: Observable<SubscriptionPlanOutputGraphql[]> = this.pricingGraphqlService
      .getCampaignPnackets()
      .pipe(
        map(
          (res: FetchResult<GetMarketingCampaignSubscriptionsPlansQuery>) =>
            res.data?.getMarketingCampaignSubscriptionsPlans as SubscriptionPlanOutputGraphql[],
        ),
      );

    return request.subscribe({
      next: (res: SubscriptionPlanOutputGraphql[]) => {
        this.plans = res;
        this.defaultTrialPlan = this.plans.find((p) => p.name.includes(Config.DEFAULT_TRIAL_PLAN)) ?? null;
        this._setPacketsIdsToPermissions();
        this._setCurrentUserActivePlans();
        this.changes.detectChanges();
      },
      error: () => {
        this.snackbarService.error(
          this.translateService.instant('Pricing.There is problem with getting packets. Try again.'),
        );
      },
    });
  }

  /**
   * Get price for specified pricingService.paymentInterval: PaymentIntervalEnum
   */
  getPriceForInterval(prices: PriceOutputGraphql[]): PriceOutputGraphql | null {
    const paymentIntervalValue = this.pricingService.paymentInterval;
    return prices.find((p) => p.paymentInterval === paymentIntervalValue) ?? null;
  }

  getIntegerMonthlyPriceAmount(amount: number) {
    const paymentIntervalValue = this.pricingService.paymentInterval;

    switch (paymentIntervalValue) {
      case PaymentIntervalEnum.Month:
        return amount;
      case PaymentIntervalEnum.Year:
        return parseInt((amount / 12).toString().replace(',', '')); // integer without commas
      case PaymentIntervalEnum.Lifetime:
        return amount;
    }
  }

  private _setCurrentUserActivePlans() {
    this.userCurrentPlansIds = [];
    this.userService.User?.activePlans.map((p: SubscriptionPlanOutputGraphql) => {
      this.userCurrentPlansIds.push(p.id);
    });
  }

  isPlanActive(plan: SubscriptionPlanOutputGraphql) {
    const paymentIntervalValue = this.getPriceForInterval(plan.prices)!.paymentInterval;
    const userCurrentPlanPaymentInterval = this.userService.User?.activeSubscription.price.paymentInterval || '';
    return this.userCurrentPlansIds.indexOf(plan.id) !== -1 && paymentIntervalValue === userCurrentPlanPaymentInterval;
  }

  private _setPacketsIdsToPermissions() {
    this.globalData.permissions.map((p) => {
      this.plans.map((plan: SubscriptionPlanOutputGraphql) => {
        const foundPermissions: PlanPermissionOutputGraphql[] = plan.permissions.filter(
          (per) => per.permissionType.id === p.id,
        );
        if (foundPermissions.length) {
          p.planPermissions[plan.id] = foundPermissions[0];
        }
      });
    });
  }

  choosePlan(plan: SubscriptionPlanOutputGraphql, promoCode = false): void {
    this.getLoggedInStatus(plan, !this.usedTrial)
      .pipe(tap(() => this.changes.detectChanges()))
      .subscribe(() => {
        promoCode
          ? this._usePromotionalCode(plan, !this.usedTrial)
          : this.handlePlanChoice(plan, undefined, !this.usedTrial);
      });
  }

  private _usePromotionalCode(plan: SubscriptionPlanOutputGraphql, trial = false): void {
    this.dialogService
      .open<boolean>(new PolymorpheusComponent(PricingPromotionCodeModalComponent, this.injector), {
        size: 's',
        data: {
          plan,
          packetsComponent: this,
          trial,
        },
        dismissible: true,
        closeable: true,
      })
      .subscribe();
  }

  private getLoggedInStatus(plan: SubscriptionPlanOutputGraphql, trial = false): Observable<boolean> {
    let logged$: Observable<boolean> = of(true);
    if (this.public && !this.userService.User) {
      this.buyPlanLoading = false;
      this.changes.detectChanges();
      logged$ = this.dialogService
        .open<boolean>(new PolymorpheusComponent(AuthModalComponent, this.injector), {
          size: 'm',
          data: {
            plan,
            trial,
          },
        })
        .pipe(
          filter((authorized) => authorized),
          tap(() => this._setCurrentUserActivePlans()),
        );
    }
    return logged$;
  }

  handlePlanChoice(plan: SubscriptionPlanOutputGraphql, promoCode?: string, trial = false): void {
    if (!trial && !(this.usingTrial && !this.urlToPayments) && !this.noActivePlan) {
      this.goToPaymentSettings();
      return;
    }

    this.buyPlanLoading = true;
    this.changes.detectChanges();
    trial ? this._saveTrial(plan) : '';
    this._saveUrl();
    if (trial && this.usedTrial) {
      if (this.usingTrial) {
        this.wentToCheckout = true;
        this.navigateService.router.navigate(['payment/success']);
      } else if (this.trialEnded) {
        this.snackbarService.error(
          this.translateService.instant(
            'Pricing.You have already used your trial version of Digitalfirst.ai. Purchase subscription plan to continue using the platform.',
          ),
        );
      } else {
        this.snackbarService.defaultError();
      }
    } else {
      this._buyPlan(plan, promoCode, trial);
    }
  }

  private _buyPlan(plan: SubscriptionPlanOutputGraphql, promoCode?: string, trial = false) {
    const price = this.getPriceForInterval(plan.prices);
    this.pricingGraphqlService.createSubscription(plan.id, promoCode, trial, price!.id).subscribe({
      next: (res: FetchResult<CreateSubscriptionMutation>) => {
        this.wentToCheckout = true;
        window.location.href = res.data?.createSubscription.redirectUrl!;
      },
      error: () => {
        this.snackbarService.error(
          this.translateService.instant('Pricing.There is problem with create subscription plan. Try again.'),
        );
        this.buyPlanLoading = false;
        this.changes.detectChanges();
      },
    });
  }

  goToPaymentSettings(): void {
    if (!this.urlToPayments) return;
    window.open(this.urlToPayments, '_self');
  }

  private _saveTrial(plan: SubscriptionPlanOutputGraphql): void {
    this.userService.saveSelectedTrial(plan);
  }

  private _saveUrl(): void {
    const statementUrl: RegExpMatchArray | null = this.router.url.match(/\(statement:[^()]+\)/);
    this.userService.saveBeforePaymentUrl(window.location.pathname, statementUrl ? statementUrl[0] : undefined);
  }

  private _checkBuyParam(): void {
    const toBuyId: number | undefined = +this.route.snapshot.queryParams['toBuy'];
    if (!isNaN(toBuyId)) {
      const toBuy: SubscriptionPlanOutputGraphql | undefined = this.plans.find((item) => item.id === toBuyId);
      if (toBuy) {
        this.buyFromParam = true;
        this.buyPlanLoading = true;
        this.changes.detectChanges();
        this.choosePlan(toBuy);
        return;
      }
    }
  }

  goToFunnel(): void {
    this.router.navigate([{ outlets: { statement: null, pricing: null } }]).then(() => {
      if (this.userService.User) {
        this.navigateService.go('funnels/f/d/:id', {
          id: this.userService.User.contextFunnel.id,
        });
      } else {
        this.navigateService.go('sign-up');
      }
    });
  }

  private _sendSeenPricingEvent(): void {
    if (this.userService.User) {
      this.getResponseEventsService.triggerGetResponseEvent(
        this.userService.User.hasActivePlan()
          ? GetResponseEvent.UserWithPlanSeenPricing
          : GetResponseEvent.UserWithoutPlanSeenPricing,
      );
    }
  }

  ngOnDestroy(): void {
    if (this.userService.User && !this.wentToCheckout) {
      this.getResponseEventsService.triggerGetResponseEvent(GetResponseEvent.UserSeenPricingWithoutBuying);
    }
  }
}
