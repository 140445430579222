import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Campaign } from '../../campaign.component';

@Component({
  selector: 'df-campaign-strategy',
  templateUrl: './campaign-strategy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignStrategyComponent {
  @Input() campaign!: Campaign;
}
