import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { AuthorizationModule } from '@modules/authorization/authorization.module';

import { TuiTagModule } from '@taiga-ui/kit';

import { NgxPrintModule } from 'ngx-print';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { CampaignSetUrlComponent } from './pages/campaign-set-url/campaign-set-url.component';
import { CampaignComponent } from './pages/campaign/campaign.component';
import { AidaComponent } from './pages/campaign/components/aida/aida.component';
import { CampaignKeyMessageComponent } from './pages/campaign/components/campaign-key-message/campaign-key-message.component';
import { CampaignStrategyComponent } from './pages/campaign/components/campaign-strategy/campaign-strategy.component';
import { CampaignMarketingChannelsTacticsComponent } from './pages/campaign/components/marketing-channels-tactics/marketing-channels-tactics.component';
import { SkeletonComponent } from './pages/campaign/components/skeleton/skeleton.component';
import { CampaignTargetAudienceComponent } from './pages/campaign/components/target-audience/target-audience.component';
import { MarketingCampaignRoutes } from './shared/config/marketing-campaign.router';
import { CampaignExecutionPlanComponent } from './pages/campaign/components/campaign-execution/campaign-execution.component';
import { CampaignContentStrategyPlanComponent } from './pages/campaign/components/content-strategy-plan/content-strategy-plan.component';
import { CampaignCanvasComponent } from './pages/campaign/components/canvas/canvas.component';

import { StatementModule } from '@modules/statement/statement.module';
import { MarketingFunnelComponent } from './pages/campaign/components/marketing-funnel/marketing-funnel.component';
import { PublicModule } from '@modules/public/public.module';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { CampaignStatementComponent } from './pages/campaign/components/statement/statement.component';

@NgModule({
  declarations: [
    CampaignSetUrlComponent,
    CampaignComponent,
    CampaignKeyMessageComponent,
    CampaignTargetAudienceComponent,
    CampaignStrategyComponent,
    CampaignStrategyComponent,
    CampaignContentStrategyPlanComponent,
    CampaignMarketingChannelsTacticsComponent,
    CampaignExecutionPlanComponent,
    CampaignComponent,
    CampaignCanvasComponent,
    AidaComponent,
    SkeletonComponent,
    CampaignStatementComponent,
    MarketingFunnelComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(MarketingCampaignRoutes),
    PublicModule,
    AuthorizationModule,
    TuiTagModule,
    NgxPrintModule,
    TuiScrollbarModule,
    StatementModule,
    VirtualScrollerModule,
  ],
  exports: [CampaignComponent],
})
export class MarketingCampaignModule {}
