import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Integration, IntegrationInputConfiguration } from '@shared/models/integration.model';
import { ContentGeneratorFormFieldsComponent } from '@modules/funnels/modules/funnel-manage/shared/components/content-generator-form/components/content-generator-form-fields/content-generator-form-fields.component';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { IntegrationTypeEnum } from '@modules/graphql/graphql-types';
import { AssetContext } from '@modules/assets/shared/assets.model';
import { MixpanelEventName, MixpanelService } from '@shared/services/mixpanel.service';

@Component({
  selector: 'df-content-generator-form',
  templateUrl: './content-generator-form.component.html',
  styleUrls: ['./content-generator-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorFormComponent implements OnInit {
  readonly EOnboardingStep = EOnboardingStep;
  @Input() integration: Integration | null = null;
  @Input() form: UntypedFormGroup | null = null;
  @Input() loading = false;
  @Input() generateButtonLabel = '';
  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('formFieldsComponent')
  formFieldsComponent?: ContentGeneratorFormFieldsComponent;

  IntegrationTypeEnum = IntegrationTypeEnum;

  constructor(
    public readonly onboardingService: OnboardingService,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit(): void {
    console.log(this.integration);
    this.handleOnboardingForm();
  }

  private handleOnboardingForm(): void {
    if (this.onboardingService.onboardingRunning) this.form?.disable();
  }

  submit(): void {
    this.mapAndSave(this.form!.getRawValue());

    if (this.form?.valid || this.onboardingService.onboardingRunning) {
      this.formSubmitted.emit(true);
    } else {
      for (const inner in this.form?.controls) {
        this.form.get(inner)?.markAsTouched();
        this.form.get(inner)?.updateValueAndValidity();
      }

      this.formFieldsComponent?.detectFormFieldsChanges();
    }

    if (this?.integration?.type !== IntegrationTypeEnum.Abyssale) {
      this.mixpanelService.trackEvent(MixpanelEventName.ButtonClick, {
        time: Date.now(),
        URL: location.href,
        button_name: 'generate_text',
        content_template: this.integration!.name,
      });
    }
  }

  mapAndSave(values: Record<number, string>) {
    const keys = Object.keys(values);
    const realFields: Array<IntegrationInputConfiguration | undefined> = [];
    const finalData: AssetContext[] = [];

    keys.forEach((key) => realFields.push(this.getFieldNameById(+key)));

    realFields.forEach((field) => {
      if (
        values[`${field?.id}`] &&
        field!.label !== 'Upload your logo' &&
        field!.label !== 'Select ad size' &&
        field?.label !== 'Template'
      ) {
        const conf = {
          key: field!.label,
          value: String(values[`${field?.id}`]),
        };
        finalData.push(conf);
      }
    });

    sessionStorage.setItem('last-funnel-marketing-generated-config', JSON.stringify(finalData));
  }

  getFieldNameById(id: number): IntegrationInputConfiguration | undefined {
    return this.integration?.inputsConfigurations?.find((prop) => prop.id === id);
  }
}
