import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Config } from '@shared/configs/config';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { ContentGeneratorModalComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/components/content-generator-modal/content-generator-modal.component';
import { IntegrationOutputGraphql, OutputTypeEnum } from '@modules/graphql/graphql-types';
import { Integration } from '@shared/models/integration.model';
import { ContentGeneratorRouteParam } from '@modules/funnels/shared/enums/content-generator-route-param.enum';
import { FunnelContentGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/services/funnel-content-generator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, pluck } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { OnboardingService } from '@shared/services/onboarding.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';

@Component({
  selector: 'df-content-generator-asset-tile',
  templateUrl: './content-generator-asset-tile.component.html',
  styleUrls: ['./content-generator-asset-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorAssetTileComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  readonly Config = Config;
  readonly EOnboardingStep = EOnboardingStep;

  textIntegrations: Integration[] = [];
  expanded = false;
  paramSub: Subscription = new Subscription();

  private _asset: ActivityAsset | null = null;

  get asset(): ActivityAsset | null {
    return this._asset;
  }

  @Input() set asset(value: ActivityAsset | null) {
    this._asset = value;
    this.textIntegrations =
      value?.asset.integrations.filter((integration) => integration.outputType === OutputTypeEnum.Text) ?? [];
    this.listenAssetParamChanges();
  }

  @Input() index = 0;

  constructor(
    private readonly changes: ChangeDetectorRef,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly funnelContentGeneratorService: FunnelContentGeneratorService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly onboardingService: OnboardingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenOnboardingStepChange();
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  toggleAsset(): void {
    if (this.expanded) {
      this.toggle(false);
      if (this.route.snapshot.queryParams[ContentGeneratorRouteParam.ASSET] === this._asset?.id.toString()) {
        this.router.navigate(['.'], {
          queryParams: {
            [ContentGeneratorRouteParam.TACTIC]: this.funnelContentGeneratorService.selectedTactic?.id,
            [ContentGeneratorRouteParam.ACTIVITY]: this.funnelContentGeneratorService.selectedActivity?.id,
          },
          relativeTo: this.route,
        });
      }
    } else {
      this.router.navigate(['.'], {
        queryParams: {
          [ContentGeneratorRouteParam.TACTIC]: this.funnelContentGeneratorService.selectedTactic?.id,
          [ContentGeneratorRouteParam.ACTIVITY]: this.funnelContentGeneratorService.selectedActivity?.id,
          [ContentGeneratorRouteParam.ASSET]: this._asset?.id,
        },
        relativeTo: this.route,
      });
    }
  }

  toggle(opened?: boolean): void {
    if (this.textIntegrations.length) {
      this.expanded = opened ?? !this.expanded;
      this.changes.detectChanges();
    }
  }

  openContentGenerator(integration: IntegrationOutputGraphql): void {
    if (this.onboardingService.onboardingRunning) {
      this.onboardingService.currentStep?.nextStep();
    }
    this.dialogService
      .open<number>(new PolymorpheusComponent(ContentGeneratorModalComponent, this.injector), {
        data: {
          asset: this._asset,
          integration: new Integration(integration),
        },
        size: 'fullscreen',
        dismissible: false,
        closeable: false,
      })
      .subscribe();
  }

  private listenAssetParamChanges(): void {
    this.paramSub.unsubscribe();
    this.paramSub = this.route.queryParams
      .pipe(
        pluck(ContentGeneratorRouteParam.ASSET),
        filter((assetId) => !!assetId && +assetId === this._asset?.id),
      )
      .subscribe(() => this.toggle(true));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.paramSub.unsubscribe();
  }
}
