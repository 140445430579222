import { gql } from 'apollo-angular';

export const UpdateCustomPromptOutputDocument =
  gql(/* GraphQL*/ `mutation updateCustomPromptOutput($customPromptOutputUpdate: CustomPromptOutputUpdateGraphql!) {
  updateCustomPromptOutput(customPromptOutputUpdate: $customPromptOutputUpdate) {
    funnelTacticId
    customPromptId
    value
    input
    id
  }
}
`);
