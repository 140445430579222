import { gql } from 'apollo-angular';

export const GetFunnelStatisticsDocument = gql`
  query getFunnelStatistics($funnelId: Int!) {
    getFunnelStatistics(funnelId: $funnelId) {
      step {
        id
        name
        type
      }
      activitiesCount
      assetsCount
      tacticsCount
    }
  }
`;
