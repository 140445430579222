import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiInputComponent } from '@taiga-ui/kit';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FetchResult } from '@apollo/client/core';
import { CreateFlowMutation } from '@modules/funnels/shared/graphql/mutations/create-flow.mutation.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { EditFlowMutation } from '@modules/funnels/shared/graphql/mutations/edit-flow.mutation.generated';
import { Flow } from '@shared/models/flow.model';
import { FlowItem } from '@shared/models/flow-item.model';
import { UserService } from '@shared/services/user.service';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';
import { TuiDialogContext } from '@taiga-ui/core';

@Component({
  selector: 'df-funnel-manage-flow-data-modal',
  templateUrl: './funnel-manage-flow-data-modal.component.html',
  styleUrls: ['./funnel-manage-flow-data-modal.component.scss'],
})
export class FunnelManageFlowDataModalComponent implements OnInit, OnDestroy {
  loading = false;
  @ViewChild('tuiInputComponent') tuiInputComponent!: TuiInputComponent;

  constructor(
    public funnelFlowManageService: FunnelFlowManageService,
    private changes: ChangeDetectorRef,
    private funnelGraphqlService: FunnelGraphqlService,
    private funnelManageService: FunnelManageService,
    private s: SnackbarService,
    private t: TranslateService,
    private userService: UserService,
    private eventService: EventsService,
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<boolean>,
  ) {}

  ngOnInit(): void {
    if (this.funnelFlowManageService.flow) {
      this.funnelFlowManageService.form.get('name')?.setValue(this.funnelFlowManageService.flow.name);
      this.funnelFlowManageService.form.get('items')?.setValue(
        this.funnelFlowManageService.flow.items.map((i: FlowItem) => {
          return {
            from: i.from,
            to: i.to,
          };
        }),
      );
    }
  }

  cancel() {
    this.context.completeWith(false);
  }

  submit() {
    this.funnelFlowManageService.form.markAllAsTouched();
    if (!this.funnelFlowManageService.form.valid) return;
    this.loading = true;
    this.changes.detectChanges();
    if (this.funnelFlowManageService.flow) {
      this.editFlow();
    } else {
      this.createFlow();
    }
  }

  createFlow() {
    this.funnelGraphqlService
      .createFlow(
        { name: this.funnelFlowManageService.form.value['name'], items: [] },
        this.funnelManageService.funnel!.id,
      )
      .subscribe({
        next: (res: FetchResult<CreateFlowMutation>) => {
          const flow = new Flow(res.data?.createFlow);
          this.funnelManageService.funnel?.flows.push(flow);
          this.funnelFlowManageService.flow = flow;
          this.funnelFlowManageService.editMode = true;
          this.s.success(this.t.instant('Funnels.Manage.New flow created successfully.'));

          this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.FlowAdd, {
            userID: this.userService.User?.id,
          });
          this.context.completeWith(true);
        },
        error: () => {
          this.s.error(this.t.instant('Funnels.Manage.There is problem with creating new flow. Try again.'));
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  editFlow() {
    this.funnelGraphqlService
      .editFlow(this.funnelFlowManageService.form.value, this.funnelFlowManageService.flow!.id)
      .subscribe({
        next: (res: FetchResult<EditFlowMutation>) => {
          const flow = new Flow(res.data?.editFlow);
          this.funnelFlowManageService.flow = flow;
          this.funnelManageService.funnel!.flows = this.funnelManageService.funnel!.flows.map((f: Flow) => {
            if (f.id === res.data?.editFlow.id) {
              f = flow;
            }
            return f;
          });
          this.funnelManageService.funnel$.emit(this.funnelManageService.funnel);
          this.s.success(this.t.instant('Funnels.Manage.New name of flow saved successfully.'));
          this.context.completeWith(true);
        },
        error: () => {
          this.s.error(this.t.instant('Funnels.Manage.There is problem with saving new flow name. Try again.'));
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  prepareFlowItemsForRequest(items?: FlowItem[]) {
    return items
      ? items.map((i: FlowItem) => {
          return {
            from: i.from,
            to: i.to,
          };
        })
      : [];
  }

  ngOnDestroy() {
    this.funnelFlowManageService.form.reset();
  }
}
