import {
  AttachmentOutputGraphql,
  FunnelOutputGraphql,
  FunnelPermissionEnum,
  FunnelShareOutputGraphql,
  FunnelStatisticsOutputGraphql,
  FunnelStatusOutputGraphql,
  FunnelTacticOutputGraphql,
  TagOutputGraphql,
} from '@modules/graphql/graphql-types';
import { Tactic } from '@shared/models/tactic.model';
import { User } from '@shared/models/user.model';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { Flow } from '@shared/models/flow.model';
import { DateTime } from 'luxon';

export class Funnel implements FunnelOutputGraphql {
  id!: number;
  name!: string;
  isPublic!: boolean | null;
  image!: AttachmentOutputGraphql;
  project!: TagOutputGraphql;
  tactics: FunnelTactic[] = [];
  tacticsStepMap: Map<number, { [key: number]: Tactic }> = new Map();
  statsStepMap: Map<number, FunnelStatusOutputGraphql> = new Map<number, FunnelStatusOutputGraphql>();
  owner!: User;
  flows: Flow[] = [];
  stats: FunnelStatusOutputGraphql[] = [];
  shares!: FunnelShareOutputGraphql[];
  isFavourite!: boolean;
  createdAt!: string;
  created?: DateTime;
  isInContext = false;
  promotionCode!: string;
  doneTacticsCount!: number;
  doneActivitiesCount!: number;
  recommendationsCount!: number;
  tacticsCount = 0;
  activitiesCount = 0;
  assetsCount = 0;
  summary: FunnelStatisticsOutputGraphql[] = [];
  currentUserPermission: FunnelPermissionEnum = FunnelPermissionEnum.ViewOnly;
  summaryStepMap: Map<number, FunnelStatisticsOutputGraphql> = new Map<number, FunnelStatisticsOutputGraphql>();
  lastEditor?: User;
  modifiedAt!: string;
  modified?: DateTime;

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
    this.deserialize();
  }

  deserialize() {
    if (this.tactics.length) {
      this.tacticsStepMap = new Map();
      this.tactics = this.tactics.map((t: FunnelTactic | FunnelTacticOutputGraphql) => {
        const tactic: FunnelTactic = t instanceof FunnelTactic ? t : new FunnelTactic(t);
        this.setTacticToMap(tactic);
        return tactic;
      });
    }
    this.setStatsToMap();
    this.flows.length ? (this.flows = this.flows.map((f) => new Flow(f))) : '';
    this.created = DateTime.fromISO(this.createdAt);
    this.modified = DateTime.fromISO(this.modifiedAt);
    this.lastEditor ? (this.lastEditor = new User(this.lastEditor)) : '';
    this.owner ? (this.owner = new User(this.owner)) : '';
  }

  setTacticToMap(t: FunnelTacticOutputGraphql) {
    if (t.tactic) {
      if (this.tacticsStepMap[t.step.id]) {
        this.tacticsStepMap[t.step.id][t.id] = t.tactic;
      } else {
        this.tacticsStepMap[t.step.id] = { [t.id]: t.tactic };
      }
    }
  }

  setStatsToMap() {
    this.stats?.map((s: FunnelStatusOutputGraphql) => {
      this.statsStepMap.set(s.step.id, s);
    });
  }
}
