import { Component, Inject, OnInit } from '@angular/core';
import { Tactic } from '@shared/models/tactic.model';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';

@Component({
  selector: 'df-tactic-modal-preview',
  templateUrl: './tactic-modal-preview.component.html',
  styleUrls: ['./tactic-modal-preview.component.scss'],
})
export class TacticModalPreviewComponent implements OnInit {
  tactic!: Tactic;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { tactic: Tactic } }, boolean>,
  ) {}

  ngOnInit(): void {
    if (this.context?.data.tactic) {
      this.tactic = this.context.data.tactic;
    }
  }
}
