import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const RegisterAppSumoDocument = gql`
  mutation registerAppSumo($input: AppSumoRegisterInputGraphql!) {
    registerAppSumo(input: $input) {
      token
      user {
        ...UserStorage
      }
    }
  }
  ${UserStorageFragment}
`;
