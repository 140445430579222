import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthPanelComponent } from './pages/auth-panel/auth-panel.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '@environments/environment';
import { SharedModule } from '@shared/shared.module';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ThankYouForgotComponent } from './pages/thank-you-forgot/thank-you-forgot.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SuccessResetPasswordComponent } from './pages/success-reset-password/success-reset-password.component';
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component';

import { NoAuthorizationComponent } from './pages/no-authorization/no-authorization.component';
import { NoAccessComponent } from './pages/no-access/no-access.component';
import { EndTrialComponent } from './pages/end-trial/end-trial.component';
import { ConfirmChangeEmailComponent } from './pages/confirm-change-email/confirm-change-email.component';
import { AppSumoRegisterComponent } from './pages/app-sumo-register/app-sumo-register.component';
import { AppSumoErrorComponent } from './pages/app-sumo-error/app-sumo-error.component';
import { AppSumoCanActivateGuard } from '@modules/authorization/shared/guards/app-sumo-can-activate.guard';
import { MergeAppSumoAccountModalComponent } from './pages/app-sumo-register/components/merge-app-sumo-account-modal/merge-app-sumo-account-modal.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { NoTeamComponent } from './pages/no-team/no-team.component';
import { AuthPanelCanActivateGuard } from '@modules/authorization/shared/guards/auth-panel-can-activate.guard';
import { OnboardingStepBarComponent } from './shared/components/onboarding-step-bar/onboarding-step-bar.component';
import { OnboardingFeaturesListComponent } from './shared/components/onboarding-features-list/onboarding-features-list.component';
import { SocialsAuthorizationComponent } from './shared/components/socials-authorization/socials-authorization.component';
import { OnboardingSetupFormComponent } from './pages/onboarding-setup-form/onboarding-setup-form.component';
import { SetUpAccountCanActivateGuard } from '@modules/authorization/shared/guards/set-up-account-can-activate.guard';
import { SelectPlanComponent } from './pages/select-plan/select-plan.component';
import { SelectPlanCanActivateGuard } from '@modules/authorization/shared/guards/select-plan-can-activate.guard';
import { PricingModule } from '@modules/pricing/pricing.module';
import { SemrushComponent } from './pages/semrush/semrush.component';
import { AuthorizationRoutes } from './shared/configs/authorization.routes';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { NoAuthorizationSemrushComponent } from './pages/no-authorization-semrush/no-authorization-semrush.component';
import { LoginAsUserComponent } from './pages/login-as-user/login-as-user.component';
import { LoginAsUserGuard } from './pages/login-as-user/login-as-user-guard';

@NgModule({
  declarations: [
    AuthPanelComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ThankYouForgotComponent,
    ResetPasswordComponent,
    SuccessResetPasswordComponent,
    ConfirmEmailComponent,
    NoAuthorizationComponent,
    NoAuthorizationSemrushComponent,
    NoAccessComponent,
    EndTrialComponent,
    ConfirmChangeEmailComponent,
    AppSumoRegisterComponent,
    AppSumoErrorComponent,
    MergeAppSumoAccountModalComponent,
    LogoutComponent,
    NoTeamComponent,
    OnboardingStepBarComponent,
    OnboardingFeaturesListComponent,
    SocialsAuthorizationComponent,
    OnboardingSetupFormComponent,
    SelectPlanComponent,
    SemrushComponent,
    LoginAsUserComponent,
  ],
  imports: [
    SharedModule,
    RecaptchaV3Module,
    RouterModule.forChild(AuthorizationRoutes),
    PricingModule,
    GoogleSigninButtonModule,
  ],
  providers: [
    AppSumoCanActivateGuard,
    LoginAsUserGuard,
    AuthPanelCanActivateGuard,
    SetUpAccountCanActivateGuard,
    SelectPlanCanActivateGuard,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.CAPTCHA },
  ],
  bootstrap: [],
  exports: [RegisterComponent, LoginComponent, SocialsAuthorizationComponent],
})
export class AuthorizationModule {}
