import { gql } from 'apollo-angular';

export const GenerateNewsletterDocument = gql`
  mutation generateNewsletter($docName: String!, $funnelId: Float!) {
    generateNewsletter(docName: $docName, funnelId: $funnelId) {
      title
      description
      call_to_action
    }
  }
`;
