import { Injectable, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotifWidgetService {
  private renderer!: Renderer2;

  setRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }

  setXPartnerClass(): void {
    this.renderer.addClass(document.body, 'x-partner');
  }
}
