<df-full-list-loader *ngIf="loading$ | async"></df-full-list-loader>

<div class="max-w-lg w-full h-full justify-center flex flex-col py-8 px-4 pt-36">
  <img
    class="login__logo mb-0 w-6/12"
    src="/assets/img/Logo-dark.svg"
    alt="Digitalfirst.ai"
  />
  <h1 class="tui-text_h2 font-semibold tui-text-03 pl-0 pr-0 mt-4 w-auto">
    {{ 'MarketingCampaign.Prepare your campaning strategy in' | translate }}
    <span class="text-[#2E4FFF]">{{ '3 minutes! ' | translate }} </span>
  </h1>

  <form
    class="bg-white p-5 rounded-[1rem]"
    [formGroup]="form"
    [validate-form]="form"
    (submit)="save()"
  >
    <div class="tui-form__row">
      <label
        class="text-[#1B1F3BA6] text-[14px] font-medium"
        [tuiLabel]="'MarketingCampaign.email' | translate"
      >
        <tui-input
          class="min-w-full"
          formControlName="email"
        >
          {{ 'MarketingCampaign.email' | translate }}
        </tui-input>
      </label>

      <p class="text-[#1B1F3BA6] text-[13px] font-medium m-0">
        {{ 'MarketingCampaign.Why do we need your email' | translate }}
        <a
          class="text-[#2E4FFF]"
          href="https://www.digitalfirst.ai/legal/privacy"
          >Terms of use</a
        >
      </p>
      <df-error-input [control]="form.get('email')"></df-error-input>
    </div>

    <div class="tui-form__row">
      <label
        class="text-[#1B1F3BA6] text-[14px] font-medium"
        [tuiLabel]="'MarketingCampaign.Your business name' | translate"
      >
        <tui-input
          class="min-w-full"
          formControlName="businessName"
        >
          {{ 'MarketingCampaign.Business name' | translate }}
        </tui-input>
      </label>
      <df-error-input [control]="form.get('businessName')"></df-error-input>
    </div>

    <div class="tui-form__row">
      <label
        class="text-[#1B1F3BA6] text-[14px] font-medium"
        [tuiLabel]="'MarketingCampaign.Provide your comany website' | translate"
      >
        <tui-input
          class="min-w-full"
          formControlName="url"
        >
          https://yourcompany.com
        </tui-input>
      </label>
      <df-error-input [control]="form.get('url')"></df-error-input>
    </div>

    <div class="tui-form__row">
      <label
        class="text-[#1B1F3BA6] text-[14px] font-medium"
        [tuiLabel]="'MarketingCampaign.Your company logo' | translate"
      >
        <df-input-file
          [form]="form"
          class="min-w-full"
          formName="logo"
          [link]="'MarketingCampaign.Put your company logo' | translate"
        >
        </df-input-file>
      </label>
      <p class="text-[#1B1F3BA6] text-[13px] font-medium m-0">
        {{ 'JPG or PNG file, max size 2.5mb' | translate }}
      </p>
      <df-error-input [control]="form.get('logo')"></df-error-input>
    </div>

    <div *ngIf="showBriefField">
      <p class="text-[#e96363]">
        {{ "MarketingCampaign.We couldn't learn a data from your website, please add some informations" | translate }}
      </p>

      <div class="tui-form__row">
        <label
          class="text-[#1B1F3BA6] text-[14px] font-medium"
          [tuiLabel]="'MarketingCampaign.Brief' | translate"
        >
          <tui-text-area
            class="min-w-full"
            formControlName="brief"
          >
            {{ 'MarketingCampaign.Put your brief' | translate }}</tui-text-area
          >
        </label>
        <df-error-input [control]="form.get('brief')"></df-error-input>
      </div>
    </div>

    <div class="tui-form__row">
      <button
        class="w-full"
        type="submit"
        tuiButton
      >
        Start
      </button>
    </div>
  </form>
</div>
