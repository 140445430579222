import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Recommendation } from '@shared/models/recommendations.model';
import { FunnelRecommendationsItemComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-recommendations/components/funnel-recommendations-section/components/funnel-recommendations-item/funnel-recommendations-item.component';

@Component({
  selector: 'df-funnel-recommendations-section',
  templateUrl: './funnel-recommendations-section.component.html',
  styleUrls: ['./funnel-recommendations-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelRecommendationsSectionComponent {
  readonly selectedRecommendations: Recommendation[] = [];
  toSelect = 0;
  @Input() title = '';
  @Output() addSelectedToFunnel: EventEmitter<Recommendation[]> = new EventEmitter<Recommendation[]>();
  @ViewChildren(FunnelRecommendationsItemComponent)
  items!: QueryList<FunnelRecommendationsItemComponent>;

  private _recommendations: Recommendation[] = [];

  get recommendations(): Recommendation[] {
    return this._recommendations;
  }

  @Input() set recommendations(value: Recommendation[]) {
    this._recommendations = value;
    this.toSelect = value.filter((val) => !val.usedInFunnel).length;
    this.changes.detectChanges();
  }

  constructor(private readonly changes: ChangeDetectorRef) {}

  recommendationSelectedHandler(recommendation: Recommendation, selected: boolean): void {
    selected
      ? this.selectedRecommendations.push(recommendation)
      : this.selectedRecommendations.splice(
          this.selectedRecommendations.findIndex((rec) => rec.id === recommendation.id),
          1,
        );
  }

  addSelectedRecommendations(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.addSelectedToFunnel.emit(this.selectedRecommendations);
  }

  selectAll(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.items.forEach((item) => item.select(true));
  }
}
