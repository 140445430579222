<button
  class="absolute top-4 right-11"
  size="m"
  tuiIconButton
  type="button"
  appearance="outline"
  (click)="closeModal()"
  [icon]="closeIcon"
>
  <ng-template #closeIcon>
    <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
  </ng-template>
</button>

<div class="wrapper flex flex-col py-[3.18rem] px-11">
  <h1 class="heading tui-text-03 mb-8">
    {{ 'Funnels.Graphics.Search photos on Unsplash' | translate }}
  </h1>

  <!-- search field -->
  <form
    [formGroup]="form"
    [validate-form]="form"
    class="flex w-full justify-center mb-8"
    (submit)="searchPhotos()"
  >
    <div class="flex-col w-5/12 mr-4">
      <tui-input
        class="w-full"
        [formControlName]="EUnsplashForm.SEARCH"
        [attr.data-error-control]="EUnsplashForm.SEARCH"
        [tuiTextfieldLabelOutside]="true"
      >
      </tui-input>
      <df-error-input [control]="form.get(EUnsplashForm.SEARCH)"></df-error-input>
    </div>
    <button tuiButton>
      {{ 'Funnels.Graphics.Search' | translate }}
    </button>
  </form>

  <div class="flex-col items-center">
    <!-- items -->
    <div class="flex flex-wrap overflow-y-auto justify-center w-full">
      <div
        *ngFor="let photo of photosList"
        class="flex-col m-3 w-full md:w-3/12 h-fit"
      >
        <div class="image-data relative flex justify-center w-full">
          <!-- image -->
          <img
            class="image-data__img object-contain w-fit"
            [src]="photo.small + '&' + unsplashLinkEnding"
            [alt]="photo.small"
          />
          <div class="image-data__shadow absolute top-0 left-0 justify-center items-center z-10 h-full w-full">
            <!-- button -->
            <button
              class="button"
              tuiButton
              appearance="secondary"
              (click)="saveSelection(photo)"
            >
              {{ 'Funnels.Graphics.Select' | translate }}
            </button>
          </div>
        </div>
        <p class="tui-text-body-xs tui-text-03 text-center">
          {{ 'Funnels.Graphics.by' | translate }}
          <a
            class="tui-text-body-xs tui-text-03"
            [href]="photo.userLink + '&' + unsplashLinkEnding"
            >{{ photo.userName }}</a
          >
          {{ 'Funnels.Graphics.on' | translate }}
          <a
            class="tui-text-body-xs tui-text-03"
            [href]="unsplashUrl"
            >Unsplash</a
          >
        </p>
      </div>
      <div
        *ngIf="!photosList.length && !searchText"
        class="tui-text-body-s-2 !text-xl tui-text-03 mt-8"
      >
        {{ 'Funnels.Graphics.Start searching!' | translate }}
      </div>
      <div
        *ngIf="!photosList.length && searchText"
        class="tui-text-body-s-2 !text-xl tui-text-03 mt-8"
      >
        {{ 'Funnels.Graphics.No results found for' | translate }}:&nbsp;{{ searchText }}&nbsp;{{ errorMessage }}
      </div>
    </div>
    <div
      *ngIf="totalPages > pageNr + 1 && searchText"
      class="flex w-full justify-center mt-4"
    >
      <button
        tuiButton
        appearance="primary"
        (click)="loadMore()"
      >
        {{ 'Funnels.Graphics.Load more' | translate }}
      </button>
    </div>
  </div>
</div>
