import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IntegrationOutputData } from '@shared/models/integration.model';
import { FunnelContentGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/services/funnel-content-generator.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ContentGeneratorItemEditModalComponent } from '@modules/funnels/modules/funnel-manage/shared/components/content-generator-item-tile/components/content-generator-item-edit-modal/content-generator-item-edit-modal.component';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { copyToClipboard } from '@shared/helpers/clipboard.helper';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { ContentGeneratorEventService } from '../../services/content-generator-event.service';
import { ContentTypeEnum } from '../../enums/content-tile-type.enum';
import { SaveLibraryAssetInputGraphql } from '@modules/graphql/graphql-types';
import { AssetsService } from '@modules/assets/shared/assets.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-content-generator-item-tile',
  templateUrl: './content-generator-item-tile.component.html',
  styleUrls: ['./content-generator-item-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorItemTileComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly EOnboardingStep = EOnboardingStep;
  @Input() asset: ActivityAsset | null | undefined = null;
  @Input() data: IntegrationOutputData | null = null;
  @Input() displayMode = false;
  @Input() tileType: ContentTypeEnum = ContentTypeEnum.ASSET_LIBRARY;
  @Output() selectData: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteData: EventEmitter<void> = new EventEmitter<void>();
  @Output() regenerateContent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly funnelContentGeneratorService: FunnelContentGeneratorService,
    private readonly s: SnackbarService,
    private readonly t: TranslateService,
    private readonly changes: ChangeDetectorRef,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    public readonly onboardingService: OnboardingService,
    private readonly contentGeneratorEventService: ContentGeneratorEventService,
    private assetsService: AssetsService,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenContentGeneratorEvents();
  }

  private listenContentGeneratorEvents(): void {
    this.sub.add(this.contentGeneratorEventService.getSubscription().subscribe(() => this.changes.detectChanges()));
  }

  addToAssetLibrary() {
    const params: SaveLibraryAssetInputGraphql = {
      integration: 'writesonic',
      funnelId: this.userService.User!.contextFunnel.id,
      content: this.data?.value['data'] ?? '',
      context: [],
    };
    this.assetsService.saveLibraryAsset(params).subscribe();
  }

  editOutput(): void {
    if (this.onboardingService.onboardingRunning) return;
    this.dialogService
      .open(new PolymorpheusComponent(ContentGeneratorItemEditModalComponent, this.injector), {
        size: 's',
        data: {
          output: this.data,
          asset: this.asset,
          isContentLibrary: this.tileType === ContentTypeEnum.TEMPLATE_LIBRARY,
        },
        dismissible: true,
        closeable: true,
      })
      .subscribe();
  }

  copyToClipboard(): void {
    if (this.onboardingService.onboardingRunning) return;
    copyToClipboard(this?.data?.value['data'], this.s, this.t);
  }

  deleteOutput(): void {
    if (this.onboardingService.onboardingRunning) return;
    ContentTypeEnum.TEMPLATE_LIBRARY === this.tileType
      ? this.deleteData.emit()
      : this.displayMode
        ? this.funnelContentGeneratorService.removeAssetSelectedOutput(this.asset!)
        : this.funnelContentGeneratorService.removeOutput(this.data!, this.asset!);
  }

  onRegenerate(): void {
    if (this.onboardingService.onboardingRunning) return;
    this.regenerateContent.emit(true);
  }
}
