import { gql } from 'apollo-angular';

export const GetActivityTypesDocument = gql(/* GraphQL*/ `query getActivityTypes
{
  getActivityTypes
  {
    id
    name
    image
  }
}

`);
