import { gql } from 'apollo-angular';

export interface Url {
  url: string;
  isParsed: string | null;
}

export interface ApiFile {
  url: string;
  extension: string;
  mimeType: string;
  name: string;
  isParsed: string | null;
}

export interface GetInputDataResponse {
  id: number;
  funnelId: number;
  brief?: string | null;
  urls: Url[] | null;
  files: ApiFile[] | null;
  createdAt: string;
  editedAt: string;
}

export const GetInputDataDocument = gql`
  query getInputData($funnelId: Int!) {
    getInputData(funnelId: $funnelId) {
      id
      editedAt
      funnelId
      brief
      urls {
        url
        isParsed
      }
      files {
        url
        extension
        mimeType
        name
        isParsed
      }

      createdAt
    }
  }
`;
