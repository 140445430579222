import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { TacticsListCanActivateGuard } from '@modules/tactics/modules/tactics-list/guards/tactics-list-can-activate.guard';
import { TacticParametersService } from '@modules/tactics/shared/services/tactic-parameters.service';
import { TacticTileComponent } from './shared/components/tactic-tile/tactic-tile.component';
import { TacticsPreviewCanActivateGuard } from '@modules/tactics/modules/tactic-preview/guards/tactics-preview-can-activate.guard';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { TacticPrameterBoxComponent } from './shared/components/tactic-prameter-box/tactic-prameter-box.component';
import { TacticTileActionsComponent } from './shared/components/tactic-tile/components/tactic-tile-acions/tactic-tile-actions.component';
import { AddToFunnelButtonComponent } from './shared/components/add-to-funnel-button/add-to-funnel-button.component';
import { ActivityAssetPreviewModalComponent } from './shared/components/activity-asset-preview-modal/activity-asset-preview-modal.component';
import { ActivityAssetItemComponent } from '@modules/tactics/shared/components/activity-asset-item/activity-asset-item.component';
import { ActivityBenchmarkItemComponent } from '@modules/tactics/shared/components/activity-benchmark-item/activity-benchmark-item.component';
import { PutTacticOnMarketplaceModalComponent } from './shared/components/put-tactic-on-marketplace-modal/put-tactic-on-marketplace-modal.component';
import { PaidTacticsChecksComponent } from './shared/components/paid-tactics-checks/paid-tactics-checks.component';
import { TacticPriceBadgeComponent } from './shared/components/tactic-price-badge/tactic-price-badge.component';
import { TacticVerificationBadgeComponent } from './shared/components/tactic-verification-badge/tactic-verification-badge.component';
import { TacticVerificationBannerComponent } from './shared/components/tactic-verification-banner/tactic-verification-banner.component';
import { DialogPublicProfileComponent } from './shared/components/dialog-public-profile/dialog-public-profile.component';
import { TacticRecTileComponent } from './shared/components/tactic-rec-tile/tactic-rec-tile.component';
import { ContentAccessDialogComponent } from './shared/components/content-access-dialog/content-access-dialog.component.component';
import { AssetContentGeneratedBadgeComponent } from './shared/components/asset-content-generated-badge/asset-content-generated-badge.component';
import { TacticSettingsCanActivateGuard } from '@modules/tactics/modules/tactic-settings/guards/tactic-settings-can-activate.guard';
import { ActivityBenchmarkItemPreviewComponent } from './shared/components/activity-benchmark-item-preview/activity-benchmark-item-preview.component';
import { ActivityBenchmarkPreviewModalComponent } from './shared/components/activity-benchmark-preview-modal/activity-benchmark-preview-modal.component';
import { ActivityAssetItemPreviewComponent } from './shared/components/activity-asset-item-preview/activity-asset-item-preview.component';
import { ActivityPromptChatComponent } from './shared/components/activity-prompt-chat/activity-prompt-chat.component';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { TacticsRoutes } from '@core/routes/configs/tactics.routes';

@NgModule({
  declarations: [
    TacticTileComponent,
    TacticPrameterBoxComponent,
    TacticTileActionsComponent,
    AddToFunnelButtonComponent,
    ActivityAssetPreviewModalComponent,
    ActivityBenchmarkPreviewModalComponent,
    ActivityAssetItemComponent,
    ActivityAssetItemPreviewComponent,
    ActivityBenchmarkItemComponent,
    ActivityBenchmarkItemPreviewComponent,
    PutTacticOnMarketplaceModalComponent,
    PaidTacticsChecksComponent,
    TacticPriceBadgeComponent,
    TacticVerificationBadgeComponent,
    TacticVerificationBannerComponent,
    DialogPublicProfileComponent,
    TacticRecTileComponent,
    ContentAccessDialogComponent,
    AssetContentGeneratedBadgeComponent,
    ActivityBenchmarkPreviewModalComponent,
    ActivityAssetItemPreviewComponent,
    ActivityPromptChatComponent,
  ],
  imports: [SharedModule, TuiScrollbarModule, RouterModule.forChild(TacticsRoutes)],
  exports: [
    TacticTileComponent,
    TacticPrameterBoxComponent,
    TacticTileActionsComponent,
    AddToFunnelButtonComponent,
    ActivityBenchmarkItemComponent,
    ActivityBenchmarkItemPreviewComponent,
    ActivityAssetItemComponent,
    ActivityAssetItemPreviewComponent,
    PaidTacticsChecksComponent,
    TacticPriceBadgeComponent,
    TacticVerificationBannerComponent,
    TacticRecTileComponent,
    ContentAccessDialogComponent,
    AssetContentGeneratedBadgeComponent,
    ActivityPromptChatComponent,
  ],
  providers: [
    TacticsListCanActivateGuard,
    TacticSettingsCanActivateGuard,
    TacticsPreviewCanActivateGuard,
    TacticParametersService,
    TacticGraphqlService,
  ],
})
export class TacticsModule {}
