/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { User } from '@shared/models/user.model';

declare const dataLayer: {
  push: (data: Record<string, any>) => void;
};

export enum GoogleAnalyticsEvent {
  Signup = 'sign-up',
  Signin = 'sign-in',
  VPN = 'vpn',
  TacticNew = 'Tactic_new',
  TacticAdd = 'TacticAdd',
  FlowAdd = 'Flow_add',
  FunnelAdd = 'Funnel_add',
  FunnelNew = 'Funnel_new',
  StatementDone = 'Statement_done',
  leadAistrategy = 'lead_aistrategy',
  Purchase = 'purchase',
  VirtualPageView = 'virtual_pageview',
}

interface IntercomeParams {
  api_base: string;
  app_id: string;
  name?: string;
  email?: string;
  created_at?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  setUpIntercome(user?: User) {
    try {
      window['Intercom']('shutdown'); // clear possible already running intercome

      if (user?.isSemrushPlan()) {
        return;
      }

      const intercomeParams: IntercomeParams = {
        api_base: 'https://api-iam.intercom.io',
        app_id: environment.INTERCOME_APP_ID,
      };

      if (user) {
        intercomeParams.name = user.firstName + ' ' + user.lastName;
        intercomeParams.email = user.email;
        intercomeParams.created_at = user.createdAt;
      }

      window['Intercom']('boot', intercomeParams);

      this.hideIntercome(); // Initially hide the intercome
    } catch (e) {
      Sentry.captureException('Intercome boot Error:' + e);
    }
  }

  showIntercome(): void {
    document?.querySelector('.intercom-lightweight-app')?.classList?.remove('hidden');
    window['Intercom']('show');
  }

  async pushToGoogleAnalyticsEvent(event: GoogleAnalyticsEvent, metadata: Record<string, string | number | any> = {}) {
    try {
      await dataLayer.push({
        event: event,
        ...metadata,
      });
    } catch (e) {
      Sentry.captureException('GoogleAnalytics Error:' + e);
    }
  }

  async pushToSmartlookEvent(userId: number, metadata: Record<string, string | number | undefined> = {}) {
    if (!userId) return;

    try {
      if (!(window as any)?.smartlook) return;
      await (window as any)?.smartlook('identify', userId, { ...metadata });
    } catch (e) {
      Sentry.captureException('Smartlook Error:' + e);
    }
  }

  private hideIntercome(): void {
    const interval = setInterval(() => {
      const intercome = document.querySelector('.intercom-lightweight-app');
      console.log('tryign to hide intercome', intercome);
      if (intercome) {
        intercome.classList.add('hidden');
        clearInterval(interval);
      }
    }, 500);
  }
}
