import { Component } from '@angular/core';
import { BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';

@Component({
  selector: 'df-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
})
export class HeaderTitleComponent {
  constructor(public basePanelService: BasePanelService) {}
}
