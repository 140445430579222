import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnInit,
  Output,
  Inject,
} from '@angular/core';
import { TacticController } from '@modules/tactics/shared/controllers/tactic.controller';
import { Tactic } from '@shared/models/tactic.model';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Step } from '@shared/models/step.model';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import {
  TacticsListEventType,
  TacticsListService,
} from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { TacticModalPreviewComponent } from '@modules/tactics/modules/tactic-preview/pages/tactic-modal-preview/tactic-modal-preview.component';
import { ListEventType } from '@shared/services/base-list.service';
import { Funnel } from '@shared/models/funnel.model';
import { FunnelTacticInputGraphql, VerificationStatusEnum } from '@modules/graphql/graphql-types';
import { FetchResult } from '@apollo/client/core';
import { CreateFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/create-funnel-tactic.mutation.generated';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { UserService } from '@shared/services/user.service';
import { Observable } from 'rxjs';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { OnboardingService } from '@shared/services/onboarding.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';

@Component({
  selector: 'df-tactic-tile',
  templateUrl: './tactic-tile.component.html',
  styleUrls: ['./tactic-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticTileComponent extends AbstractSubscriptionComponent implements OnInit {
  PermissionsGroups = PermissionsGroups;
  readonly VerificationStatusEnum = VerificationStatusEnum;

  private _tactic?: Tactic;
  private tacticController: TacticController = new TacticController();

  @Output() onAddToFunnel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() noShadow = false;
  @Input() colorClass = '';
  @Input() isRemoveFromFunnelAction = false;
  @Input() step: Step | null = null;
  @Input() noLeftRightMargin = false;
  @Input() toCopy = false;
  @Input() noOwner = false;
  @Input() funnel?: Funnel;

  @Input()
  set tactic(tactic: Tactic | undefined) {
    this._tactic = tactic;
    this.changes.detectChanges();
  }

  get tactic(): Tactic | undefined {
    return this._tactic;
  }

  @HostBinding('class.top-rated')
  get isTopRated() {
    return this.tactic?.isTopRated;
  }

  constructor(
    private changes: ChangeDetectorRef,
    private tacticsListService: TacticsListService,
    private tacticGraphqlService: TacticGraphqlService,
    private s: SnackbarService,
    private t: TranslateService,
    public userService: UserService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    public onboardingService: OnboardingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenOnboardingStepChange();
  }

  showPromptIntegrationTag(tactic: Tactic | undefined): boolean {
    if (!tactic) return false;

    const arr = this.tactic?.activities?.filter((a) => a.customPrompts) ?? [];
    return arr?.some((a) => a.customPrompts && a.customPrompts.length > 0) ?? false;
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  edit() {
    if (!this.tactic) return;
    this.tacticController.openEdit(this.tactic?.id);
  }

  addToFunnel() {
    if (this.onboardingService.onboardingRunning) {
      this.onboardingService.currentStep?.nextStep();
      return;
    }
    if (!this.tactic) return;
    if (this.funnel?.tacticsStepMap[this.step!.id]) {
      this.funnel!.tacticsStepMap[this.step!.id][this.tactic!.id] = this.tactic;
    } else {
      this.funnel!.tacticsStepMap[this.step!.id] = {
        [this.tactic!.id]: this.tactic,
      };
    }
    this.changes.detectChanges();
    this.tacticController
      .createFunnelTactic(this.getCreateFunnelTacticObject())
      .subscribe((res: FetchResult<CreateFunnelTacticMutation>) => {
        this.addFunnelTacticToContextFunnel(new FunnelTactic(res.data?.createFunnelTactic));
        this.s.success(this.t.instant('Funnels.Manage.Tactic added to funnel successfully.'));
        this.onAddToFunnel.emit(true);
      });
  }

  addFunnelTacticToContextFunnel(funnelTactic: FunnelTactic) {
    const user = this.userService.User;
    if (user?.contextFunnel) {
      user.contextFunnel.tactics.push(funnelTactic);
      this.userService.User = user;
    }
  }

  preview() {
    if (location.href.includes('public/tactics')) return this.tacticController.publicPreview(this.tactic!.id);
    if (!this.tactic || this.funnel || this.toCopy || this.onboardingService.onboardingRunning) return;

    this.tacticController.preview(this.tactic?.id);
  }

  remove() {
    if (!this.tactic) return;
    this.tacticController.remove(this.tactic?.id).subscribe({
      next: () => {
        const user = this.userService.User!;
        if (this.tactic?.id === user.tacticDraft?.id) {
          user.tacticDraft = undefined;
        }
        if (this.tactic?.isPaid) {
          user.paidTacticCount--;
        }
        this.userService.User = user;
        this.s.success(this.t.instant('Tactics.Tactic successfully removed'));
        this.tacticsListService.records = this.tacticsListService.records.filter((t) => t.id !== this.tactic?.id!);
        this.tacticsListService.emitter.emit({
          type: ListEventType.END_GETTING_RECORDS,
        });
      },
      error: () => {
        this.s.defaultError();
      },
    });
  }

  chooseToCopy() {
    this.tacticsListService.tacticsListEmitter.emit({
      type: TacticsListEventType.CHOOSE_TO_COPY,
      tactic: this.tactic,
    });
  }

  openPreviewInModal() {
    this.dialogService
      .open<number>(new PolymorpheusComponent(TacticModalPreviewComponent, this.injector), {
        size: 'l',
        dismissible: true,
        closeable: true,
        data: {
          tactic: this.tactic,
        },
      })
      .subscribe();
  }

  getCreateFunnelTacticObject(): FunnelTacticInputGraphql {
    return {
      tacticId: this.tactic!.id,
      funnelId: this.funnel!.id,
      stepId: this.step!.id,
      position: 0,
    };
  }

  toggleFavourite() {
    if (this.onboardingService.onboardingRunning) return;
    const request: Observable<any> = this.tactic?.isFavourite
      ? this.tacticGraphqlService.removeTacticFromFavourites(this.tactic!.id)
      : this.tacticGraphqlService.addTacticToFavourites(this.tactic!.id);
    this.tactic!.isFavourite = !this.tactic!.isFavourite;
    this.changes.detectChanges();
    request.toPromise();
  }
}
