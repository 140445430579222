import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { AbstractFunnelSubpageComponent } from '@shared/abstracts/funnel-subpage.component.abstract';
import { FunnelContentGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/services/funnel-content-generator.service';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { ContentGeneratorRouteParam } from '@modules/funnels/shared/enums/content-generator-route-param.enum';
import { Integration } from '@shared/models/integration.model';
import { ContentGeneratorTemplatesFormService } from './services/content-generator-templates-form.service';
import { debounceTime } from 'rxjs/operators';
import { ContentGeneratorTabEnum } from '@modules/funnels/modules/funnel-manage/shared/enums/content-generator-tab.enum';

interface ContentGeneratorTab {
  name: string;
  index: number;
  type: ContentGeneratorTabEnum;
}

const CONTENT_GENERATOR_TABS: ContentGeneratorTab[] = [
  {
    name: 'Funnels.Templates',
    index: 0,
    type: ContentGeneratorTabEnum.TEMPLATES,
  },
  {
    name: 'Funnels.Tactic Assets',
    index: 1,
    type: ContentGeneratorTabEnum.TACTIC_ASSETS,
  },
];

@Component({
  selector: 'df-funnel-content-generator',
  templateUrl: './funnel-content-generator.component.html',
  styleUrls: ['./funnel-content-generator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FunnelContentGeneratorService],
})
export class FunnelContentGeneratorComponent extends AbstractFunnelSubpageComponent implements OnInit {
  readonly EOnboardingStep = EOnboardingStep;
  readonly ContentGeneratorTabEnum = ContentGeneratorTabEnum;
  readonly CONTENT_GENERATOR_TABS: ContentGeneratorTab[] = CONTENT_GENERATOR_TABS;

  selectedIntegration: Integration | null = null;

  selectedTab: ContentGeneratorTab = CONTENT_GENERATOR_TABS[ContentGeneratorTabEnum.TEMPLATES];
  isTabResolved = false;

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly funnelManageService: FunnelManageService,
    public readonly onboardingService: OnboardingService,
    private readonly changes: ChangeDetectorRef,
    private readonly templatesFormService: ContentGeneratorTemplatesFormService,
    private readonly router: Router,
    private readonly funnelContentGeneratorService: FunnelContentGeneratorService,
  ) {
    super(route, funnelManageService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.resolveSelectedTabInitValue();
    this.listenOnboardingStepChange();
    this.initializeFunnelChangedSubscription();
  }

  private resolveSelectedTabInitValue(): void {
    const tacticId: string = this.route.snapshot.queryParams[ContentGeneratorRouteParam.TACTIC];
    this.setTab(
      tacticId
        ? CONTENT_GENERATOR_TABS[ContentGeneratorTabEnum.TACTIC_ASSETS]
        : CONTENT_GENERATOR_TABS[ContentGeneratorTabEnum.TEMPLATES],
    );
    this.isTabResolved = true;
    this.changes.detectChanges();
  }

  private initializeFunnelChangedSubscription(): void {
    this.sub.add(
      this.funnelManageService.funnel$.pipe(debounceTime(300)).subscribe(() => this.clearContentGeneratorData()),
    );
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  public setTab(tab: ContentGeneratorTab): void {
    this.selectedTab = tab;
    this.changes.detectChanges();
  }

  public onSelectIntegration(integration: any): void {
    if (!this.isNewIntegration(integration)) return;
    this.selectedIntegration = null;
    setTimeout(() => {
      this.initializeForm(integration);
      this.selectedIntegration = integration;
      this.templatesFormService.clearData();
      this.changes.detectChanges();
    });
  }

  private isNewIntegration(integration: Integration): boolean {
    return !!(
      !this.selectedIntegration ||
      (this.selectedIntegration && this.selectedIntegration?.id !== integration.id)
    );
  }

  private initializeForm(integration: Integration): void {
    if (integration) this.templatesFormService.initForm(integration);
  }

  private clearContentGeneratorData(): void {
    this.clearGetParams();
    this.funnelContentGeneratorService.selectTactic(null);
    this.selectedIntegration = null;
    this.templatesFormService.clearData();
    this.changes.detectChanges();
  }

  public onTemplateTabSelected(): void {
    this.clearContentGeneratorData();
  }

  private clearGetParams(): void {
    this.router.navigate(['.'], {
      queryParams: {},
      relativeTo: this.route,
    });
  }
}
