import { gql } from 'apollo-angular';

export const DuplicateFunnelTacticDocument = gql`
  mutation duplicateFunnelTactic($funnelTacticId: Int!) {
    duplicateFunnelTactic(funnelTacticId: $funnelTacticId) {
      id
      position
      name
      isDone
      doneActivities {
        activity {
          id
        }
      }
      step {
        id
      }
      tactic {
        id
        name
        activities {
          id
          name
        }
        type {
          name
        }
        effects {
          name
        }
        funnelSteps {
          id
          type
          name
        }
      }
    }
  }
`;
