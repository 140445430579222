import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomInjectorService {
  private componentReferenceCollection: ComponentRef<any>[] = [];

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
  ) {}

  appendComponentToBody(component: any): ComponentRef<any> {
    const componentRef: ComponentRef<any> = this.createComponentRef(component);
    this.appRef.attachView(componentRef.hostView);
    const domElem: HTMLElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.componentReferenceCollection.push(componentRef);
    return componentRef;
  }

  destroyInjectedComponents(): void {
    this.componentReferenceCollection.forEach((componentRef: ComponentRef<any>) =>
      this.destroyInjectedComponentByComponentReference(componentRef),
    );
    this.componentReferenceCollection = [];
  }

  removeComponent(componentRef: ComponentRef<any> | null): void {
    this.destroyInjectedComponentByComponentReference(componentRef);
    this.removeFromCollection(componentRef);
  }

  private destroyInjectedComponentByComponentReference(componentRef: ComponentRef<any> | null): void {
    if (!componentRef) return;
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  private createComponentRef(component: any): ComponentRef<any> {
    return this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
  }

  private removeFromCollection(componentRef: ComponentRef<any> | null): void {
    if (!componentRef) return;
    for (let index = 0; index < this.componentReferenceCollection.length; index++) {
      if (Object.is(this.componentReferenceCollection[index], componentRef)) {
        this.componentReferenceCollection.splice(index, 1);
        index--;
      }
    }
  }
}
