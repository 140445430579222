<df-full-list-loader *ngIf="loading"></df-full-list-loader>
<div class="w-full h-full flex flex-col justify-start items-stretch flex-nowrap pt-3">
  <div
    *ngIf="funnelContentGeneratorService.selectedTactic as funnelTactic"
    class="flex-shrink-0 flex flex-col justify-start items-stretch px-3 pb-5"
  >
    <div class="flex justify-between items-center">
      <div class="w-5/6 h-5">
        <df-funnel-tactic-badges
          [steps]="[funnelTactic.step]"
          [funnelTactic]="funnelTactic"
        ></df-funnel-tactic-badges>
      </div>

      <div class="flex justify-end w-1/6">
        <a
          class="inline tui-primary"
          [routerLink]="n.getPath('tactics/preview/p/:id', {}, { id: funnelTactic.tactic.id })"
          target="_blank"
        >
          <svg-icon
            src="assets/img/ic_external_link.svg"
            [svgStyle]="{
              width: '1.25rem',
              height: '1.25rem',
              display: 'inline',
              'vertical-align': 'top'
            }"
          ></svg-icon>
        </a>
      </div>
    </div>

    <h1 class="mt-2 tui-text-03 tui-text_h5 line-clamp-3 break-words m-0">
      {{ funnelTactic.name || funnelTactic.tactic.name }}
    </h1>

    <div class="mt-2 tui-text-body-xs tui-text-05 lowercase">
      <span
        *ngFor="let tag of funnelTactic.tactic.categories"
        class="inline-block mr-0.5"
        >#{{ tag.name }}</span
      >
    </div>
  </div>

  <div
    class="border-solid tui-base-04-border flex-grow overflow-y-auto p-3 flex flex-col justify-start items-stretch"
    [class.border-t]="funnelContentGeneratorService.selectedTactic"
  >
    <h4 class="tui-text-body-xs font-bold tui-text-02 uppercase">
      {{ 'Funnels.Content generator.Tactic activities' | translate }}
    </h4>

    <div
      class="mt-1"
      *ngIf="funnelContentGeneratorService.selectedTactic as funnelTactic; else selectTactic"
    >
      <div
        *ngFor="let activity of funnelTactic.tactic.activities; let i = index"
        (click)="selectActivity(activity)"
        class="flex justify-start items-center rounded-xl border border-solid tui-base-04-border p-2 mt-2 cursor-pointer hover:border-blue-700"
        [ngClass]="{
          'no-assets': !activity.assets.length,
          'tui-accent-13-bg tui-primary-border': highlighted === activity.id
        }"
      >
        <span
          class="mr-2 w-8 h-8 rounded-xl tui-text-05 flex items-center justify-center tui-text_body-m-3 flex-shrink-0 border border-solid tui-base-04-border font-medium"
        >
          {{ i + 1 }}
        </span>

        <div>
          <h5 class="text-sm font-medium line-clamp-1 break-words m-0">
            {{ activity.name }}
          </h5>
          <p class="m-0 tui-text-05 tui-text-body-xs">
            {{ activity.assets.length }}&nbsp;{{ 'Funnels.Manage.Assets' | translate }}
          </p>
        </div>
      </div>
      <h3
        class="w-full tui-text_h5 tui-text-04 text-center mt-10"
        *ngIf="!funnelTactic.tactic.activities?.length && !loading"
      >
        {{ 'Tactics.Preview.No activities' | translate }}
      </h3>
    </div>
  </div>
</div>
<ng-template #selectTactic>
  <div class="flex-grow flex flex-col justify-center items-center text-center">
    <h4 class="w-7/12 tui-text_body-m-1 tui-text-03 font-normal">
      {{ 'Funnels.Content generator.Select tactic to view the list of assets' | translate }}
    </h4>
  </div>
</ng-template>
