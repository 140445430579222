import { gql } from 'apollo-angular';

export const GetStatementDocument = gql`
  query getStatement($funnelId: Int!) {
    getStatement(funnelId: $funnelId) {
      id
      answers {
        value
        field {
          id
          category
          type
          required
          minValue {
            id
            name
            value
          }
          maxValue {
            id
            name
            value
          }
          dependsOnField {
            id
            category
          }
          dependsOnOption {
            id
            name
          }
          options {
            id
            name
          }
        }
      }
    }
  }
`;
