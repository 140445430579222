import { gql } from 'apollo-angular';

export const GetPromptForCanvasDocument = gql`
  query getPromptForCanvas {
    getPromptForCanvas {
      id
      name
      description
    }
  }
`;
