<form
  [formGroup]="form"
  class="statement-input w-full md:w-auto"
>
  <tui-input-inline
    #input
    [formControlName]="controlName"
    *ngIf="!isNumberInput"
  >
    {{ name }}
  </tui-input-inline>
  <input
    (ngModelChange)="onNumberChange()"
    [formControlName]="controlName"
    *ngIf="isNumberInput"
    min="{{ INPUT_CONFIGURATION.min }}"
    max="{{ INPUT_CONFIGURATION.max }}"
    step="{{ INPUT_CONFIGURATION.step }}"
    type="number"
    placeholder="{{ name }}"
  />
</form>
<div
  class="statement-input__to-calc"
  #toCalcInput
>
  {{ form.get(this.controlName)?.value }}
</div>
<df-statement-input-error
  [control]="form.get(controlName)"
  [formSubmitted]="formSubmitted"
  [skipTuiInputCheck]="true"
  [isInputError]="true"
></df-statement-input-error>
