<header class="mb-8 print:mt-4 print:break-before-page">
  <h2 class="font-bold text-[24px] text-[#2E4FFF] col">
    {{ 'MarketingCampaign.Campaign Strategy' | translate }}
  </h2>
  <p class="text-[#191C30BF] font-medium text-[16px] mt-4">
    {{ 'MarketingCampaign.Boost your sales thanks to tactics used by best agencies in the world.' | translate }}
  </p>
</header>

<div
  *ngIf="campaign"
  class="controller grid grid-cols-1 lg:grid-cols-3 grid-rows-1 gap-6"
>
  <div
    class="bg-white py-6 px-4 rounded-[12px]"
    *ngFor="let strategy of campaign.data.campaignExecution"
  >
    <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4">
      {{ strategy.campaign }}
    </h2>

    <h3 class="text-[16px] font-semibold text-[#191C30E5] mb-4">
      {{ 'MarketingCampaign.Slogan' | translate }}
    </h3>
    <p class="text-[14px] text-[#191C30BF]">{{ strategy.slogan }}</p>

    <h3 class="text-[16px] font-semibold text-[#191C30E5] mt-4 mb-4">
      {{ 'MarketingCampaign.Description' | translate }}
    </h3>
    <p class="text-[14px] text-[#191C30BF]">{{ strategy.description }}</p>
  </div>
</div>

<df-skeleton
  [showSkeleton]="!campaign"
  [rows]="1"
  [cols]="3"
></df-skeleton>
