<div class="w-screen h-screen flex flex-col justify-center items-center tui-text-03">
  <h1 class="tui-text_h5">Ups! 404. There is no such link.</h1>
  <div class="tui-form__row mt-10">
    <button
      [routerLink]="n.getPath(Config.MAIN_VIEW)"
      size="xl"
      tuiButton
      type="submit"
      class="w-full block"
      appearance="primary"
    >
      {{ 'Auth.NoAccess.button' | translate }}
    </button>
  </div>
</div>
