import { gql } from 'apollo-angular';

export const CreateFunnelTacticDocument =
  gql(/* GraphQL*/ `mutation createFunnelTactic($input: FunnelTacticInputGraphql!) {
  createFunnelTactic (input: $input) {
    id
    name
    isDone
    doneActivities {
      activity {
        id
      }
    }
    selectedOutputsData {
      id
      activityAsset {
        id
      }
    }
    tactic {
      id
      name
      activities {
        id
        name
      }
      type {
        name
      }
      effects {
        name
      }
      funnelSteps {
        id
        type
        name
      }
      assetsCount
      badge
    }
    step
    {
      id
    }
    position
  }
}
`);
