<div class="auth-panel flex justify-center items-center flex-col py-10 min-h-screen">
  <div class="auth-panel__container flex flex-col justify-start items-start">
    <img
      [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
      [alt]="xPartnerService.partner.SYSTEM_NAME"
      class="login__logo mb-0"
    />
    <h1 class="tui-text_h2 font-semibold tui-text-03 pl-0 pr-0 mt-4 w-auto">
      {{ 'Auth.NoTeam.title' | translate }}
    </h1>
    <p class="tui-text-03 mb-2">
      {{ 'Auth.NoTeam.description' | translate }}
    </p>

    <form
      class="flex flex-col w-full mt-2"
      [formGroup]="formGroup"
      [validate-form]="formGroup"
      (submit)="createTeam()"
    >
      <ng-template #success>
        <tui-svg
          class="tui-accent-07 tui-space_left-3"
          src="tuiIconCheckLarge"
        ></tui-svg>
      </ng-template>

      <div class="tui-form__row">
        <tui-input
          [tuiTextfieldCustomContent]="getControl('name')?.valid ? success : ''"
          formControlName="name"
        >
          {{ 'Auth.NoTeam.team name' | translate }} *
        </tui-input>
        <df-error-input [control]="getControl('name')"></df-error-input>
      </div>
      <div class="tui-form__row">
        <label class="tui-text-03 tui-text-body-m-2 mb-1 block">
          {{ 'Auth.NoTeam.icon' | translate }}
        </label>
        <df-input-file
          [form]="formGroup"
          #imageInput
          class="w-full"
          [maxFileSize]="10000000"
          [link]="'choose an image' | translate"
          [accept]="Config.ACCEPTED_IMAGES_EXTENSIONS"
          [formName]="'icon'"
        ></df-input-file>
      </div>

      <div class="tui-form__row mt-8">
        <button
          size="xl"
          tuiButton
          type="submit"
          class="w-full block"
          appearance="primary"
          [showLoader]="loading"
          [disabled]="loading || !formGroup.valid"
        >
          {{ 'Auth.NoTeam.button' | translate }}
        </button>
      </div>

      <div class="border border-solid border-gray-200 rounded-lg p-4 mt-8">
        <h5 class="tui-text-body-m-6 tui-text-02 mb-1 font-semibold">
          {{ 'Auth.NoTeam.tip' | translate }}
        </h5>
        <p class="tui-text-05 break-words">
          {{ 'Auth.NoTeam.teamtip' | translate }}
        </p>
      </div>
    </form>
  </div>
</div>
