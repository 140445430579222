import { SwiperOptions } from 'swiper';

export const swiperFunnelConfig: SwiperOptions = {
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    700: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 1,
    },
    980: {
      slidesPerView: 2,
    },
    1320: {
      slidesPerView: 3,
    },
    1440: {
      slidesPerView: 4,
    },
  },
  pagination: { clickable: true, clickableClass: 'onboarding-action' },
  direction: 'horizontal',
  centeredSlidesBounds: true,
  slidesPerView: 4,
  allowTouchMove: window.innerWidth < 900,
  edgeSwipeDetection: 'prevent',
  touchEventsTarget: 'container',
  on: {
    slideChange: (swiper) => {
      swiper?.pagination?.render();
      swiper?.pagination?.update();
    },
  },
};
