<tui-island
  size="s"
  class="pt-2.5 px-4 lg:px-10 pb-12 relative"
  *ngIf="data"
>
  <div class="flex flex-col flex-nowrap justify-start items-stretch">
    <div class="text-center mb-4">
      <p class="text-base font-semibold w-full tui-text-03 mb-1.5">
        {{ data.metadata![graphicsDataEnum.TYPE] }},&nbsp;{{ data.metadata![graphicsDataEnum.SIZE] }}
      </p>
      <p class="tui-text-05 text-sm w-full">
        {{ data.metadata![graphicsDataEnum.USAGE] }}
      </p>
    </div>
    <div
      class="w-full relative overflow-hidden"
      [ngClass]="{ 'h-80': size === 'l', 'h-56': size === 'm' }"
    >
      <img
        *ngIf="data.value['data'] as src"
        [src]="src"
        [alt]="data.configuration.type"
        class="w-full h-full object-contain pointer-events-none"
        [class.blur-md]="loading"
        (load)="finishLoading()"
        hideMissingImage
      />
      <div
        class="w-full h-full absolute top-0 left-0"
        *ngIf="loading"
      >
        <df-full-list-loader></df-full-list-loader>
      </div>
    </div>
  </div>
  <button
    tuiIconButton
    appearance="outline"
    type="button"
    [icon]="iconDownload"
    size="s"
    shape="square"
    (click)="download()"
    class="absolute top-2.5 right-2.5 current-color-fill"
  ></button>
  <ng-template #iconDownload>
    <svg-icon
      class="tui-text-05"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      [src]="'/assets/img/ic-download.svg'"
    ></svg-icon>
  </ng-template>

  <button
    tuiIconButton
    appearance="outline"
    type="button"
    [icon]="iconAsset"
    size="s"
    shape="square"
    (click)="copyToAssetLibrary()"
    class="absolute top-2.5 right-12 current-color-fill"
  ></button>
  <ng-template #iconAsset>
    <svg-icon
      class="tui-text-05 asset-icon"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      [src]="'/assets/img/bookmark.svg'"
    ></svg-icon>
  </ng-template>
</tui-island>
