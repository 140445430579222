import { gql } from 'apollo-angular';

export const CreateCustomPromptDocument = gql`
  mutation createCustomPrompt($customPromptInput: CustomPromptInputGraphql!) {
    createCustomPrompt(customPromptInput: $customPromptInput) {
      id
      name
      category
      description
      customPromptFields {
        fieldId
        name
        label
        helpText
        fieldType
        optionValues
        defaultValueId
        defaultValue
        required
        order
      }
      promptText
      promptProperty
      settingsIntegration
      settingsActive
    }
  }
`;
