import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'df-funnel-overview-stats',
  templateUrl: './funnel-overview-stats.component.html',
  styleUrls: ['./funnel-overview-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelOverviewStatsComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() done = 0;
  @Input() todo = 0;
  @Input() icon = '';
}
