import { gql } from 'apollo-angular';

export const FilterTacticsCategoriesDocument =
  gql(/* GraphQL*/ `query filterTacticsCategories($input: FilterInputGraphql!)
{
  filterTacticsCategories(input: $input)
  {
    records{
      name,
      id
    },
    total
  }
}

`);
