import { gql } from 'apollo-angular';
import { FullActivityFragment } from '@modules/tactics/shared/graphql/fragments/full-activity.fragment';

export const GetPubliclyVisibleTacticDocument = gql/* GraphQL*/ `
  query getPubliclyVisibleTactic($id: Int!) {
    getPubliclyVisibleTactic(id: $id) {
      id
      name
      target
      type {
        id
        name
      }
      levelOfAdvancement {
        id
        name
      }
      categories {
        name
        id
      }
      segments {
        name
        id
      }
      effects {
        name
        id
      }
      funnelSteps {
        name
        id
        type
      }
      requiresBudget
      tacticDetails
      owner {
        id
        firstName
        lastName
        avatar {
          file
        }
      }
      rate
      activities {
        ...FullActivity
      }
      imageUrl
    }
  }
  ${FullActivityFragment}
`;
