<div class="flex flex-col image-wrapper">
  <div
    class="image-container"
    [class.size-l]="size === 'l'"
    [class.size-m]="size === 'm'"
    [class.size-s]="size === 's'"
    *ngIf="data && !data.value['isVideo']"
  >
    <img
      *ngIf="data.value"
      [src]="data.value['data']"
      [alt]="data.configuration.type"
      class="image"
      [class.blur-md]="loading"
      (load)="finishLoading()"
      (click)="openImagePreview()"
    />
    <div
      class="loader-container"
      *ngIf="loading"
    >
      <df-full-list-loader></df-full-list-loader>
    </div>
  </div>

  <div *ngIf="data && data.value['isVideo']">
    <video
      width="320"
      height="240"
      controls
    >
      <source
        [src]="data.value['data']"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  </div>

  <div class="controls flex justify-around mb-2">
    <button *ngIf="data && !data.value['isVideo']">
      <svg-icon
        src="/assets/img/icon-thunder.svg"
        (click)="generate()"
      ></svg-icon>
    </button>
    <button>
      <svg-icon
        src="/assets/img/icon-download.svg"
        (click)="download()"
      ></svg-icon>
    </button>
    <button>
      <svg-icon
        *ngIf="data?.value"
        src="/assets/img/bookmark.svg"
        class="asset-icon"
        (click)="addToAssetLibrary()"
      ></svg-icon>
    </button>
  </div>
</div>
