import { TuiDialogService } from '@taiga-ui/core';
import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OrderTypes, SkillOutputGraphql } from '@modules/graphql/graphql-types';
import { ListSortItem } from '@shared/modules/ui/components/list-sub-header/list-sub-header.component';
import { Apollo } from 'apollo-angular';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { FetchResult } from '@apollo/client/core';
import { IFilter } from '@shared/interfaces/filter.interface';
import { GetSkillsQuery } from '@shared/graphql/queries/get-skills.query.generated';
import { GetSkillsDocument } from '@shared/graphql/queries/get-skills.query';
import { Subscription } from 'rxjs';
import { BaseFilters } from '@shared/interfaces/base-filters.interface';

@Injectable({
  providedIn: 'root',
})
// extends BaseFiltersService
export class UsersFiltersService implements BaseFilters {
  // BaseFilters interface
  steps = null;
  categories = null;
  dfFilters = null;

  sorts: ListSortItem[] = [
    {
      sortField: 'a.id',
      name: 'Most recent',
      sortDirection: OrderTypes.Desc,
    },
    {
      sortField: 'tacticsCount',
      name: 'Tactics: Amount down',
      sortDirection: OrderTypes.Desc,
    },
    {
      sortField: 'tacticsCount',
      name: 'Tactics: Amount up',
      sortDirection: OrderTypes.Asc,
    },
    {
      sortField: 'a.experienceYears',
      name: 'Exp: Highest first',
      sortDirection: OrderTypes.Desc,
    },
    {
      sortField: 'a.experienceYears',
      name: 'Exp: Lowest first',
      sortDirection: OrderTypes.Asc,
    },
    {
      sortField: 'a.sortName',
      name: 'A-Z',
      sortDirection: OrderTypes.Asc,
    },
    {
      sortField: 'a.sortName',
      name: 'Z-A',
      sortDirection: OrderTypes.Desc,
    },
  ];

  tacticFilters: { name: string; value: boolean }[] = [
    { name: 'Paid tactics', value: true },
    { name: 'Free tactics', value: false },
  ];
  proFilters: { name: string; value: boolean }[] = [{ name: 'Pro', value: true }];
  filterFormGroup: UntypedFormGroup = new UntypedFormGroup({
    sort: new UntypedFormControl(this.sorts[0]),
    search: new UntypedFormControl(''),
    skills: new UntypedFormControl([]),
    tactics: new UntypedFormControl(null),
    pro: new UntypedFormControl([]),
  });
  skills: IFilter<any>[] = [];
  initSkillsIds: number[] = [];
  initTactics: boolean | null = null; // true - paid, false - free
  initPro: boolean | null = null;
  areFiltersLoaded = false;
  $filtersLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  $dialogObs: Subscription = new Subscription();

  constructor(
    @Inject(Injector) readonly injector: Injector,
    @Inject(TuiDialogService) readonly dialogService: TuiDialogService,
    private apollo: Apollo,
    private s: SnackbarService,
    private t: TranslateService,
  ) {
    // super(injector, dialogService);
    this.getFilters();
  }

  clearFilters() {
    this.filterFormGroup.patchValue(
      {
        sort: this.sorts[0],
        search: '',
        skills: [],
        tactics: null,
        pro: [],
      },
      { emitEvent: false },
    );
  }

  getFilters() {
    this.apollo
      .query<GetSkillsQuery>({
        query: GetSkillsDocument,
        fetchPolicy: 'no-cache',
      })
      .subscribe({
        next: (data: FetchResult<GetSkillsQuery>) => {
          this.skills =
            data?.data?.getSkills!.map(
              (skill) =>
                ({
                  ...skill,
                  object: skill,
                }) as IFilter<SkillOutputGraphql>,
            ) || [];
          this.$filtersLoaded.emit(true);
          this.areFiltersLoaded = true;
        },
        error: () => {
          this.s.error(
            this.t.instant('Users.List.There is problem with getting filters. Refresh your view and try again.'),
          );
        },
      });
  }

  public checkFilters(): void {
    this.checkChosenSkills();
    this.checkChosenTactics();
    this.checkChosenPro();
  }

  private checkChosenSkills() {
    const chosen: IFilter<any>[] = [];
    this.skills.map((skill: IFilter<any>) => {
      this.initSkillsIds.indexOf(skill.id) !== -1 ? chosen.push(skill) : '';
    });
    this.initSkillsIds = [];
    chosen.length ? this.filterFormGroup.get('skills')?.patchValue(chosen, { emitEvent: false }) : '';
  }

  private checkChosenTactics() {
    if (this.initTactics !== null) {
      this.filterFormGroup
        .get('tactics')
        ?.patchValue(this.tacticFilters.find((fil) => fil.value === this.initTactics)!, {
          emitEvent: false,
        });
      this.initTactics = null;
    }
  }

  private checkChosenPro() {
    if (this.initPro !== null) {
      this.filterFormGroup.get('pro')?.patchValue(this.proFilters.filter((fil) => fil.value === this.initPro)!, {
        emitEvent: false,
      });
      this.initPro = null;
    }
  }

  showAsDialog() {}
  closeDialog(): void {
    // close the dialog = unsubscribe from Observable to close a dialog (this observable is returned from open method)
    this.$dialogObs.unsubscribe();
  }
}
