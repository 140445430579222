import { Injectable, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { registerLocaleData } from '@angular/common';
import localeEN from '@angular/common/locales/en';
import { Subscription, timer } from 'rxjs';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  get availableLanguages() {
    const langs: Languages[] = [];
    Object.keys(Languages).forEach((key) => {
      langs.push(Languages[key]);
    });
    return langs;
  }

  constructor(
    private storage: StorageService,
    private translate: TranslateService,
  ) {}

  saveLanguage() {
    // TODO logic on save language
    timer(300).subscribe(() => (window.location.href = window.location.pathname));
  }

  useLanguage(lang: string): Subscription {
    return this.translate.use(lang).subscribe(
      () => {
        this.registerMomentLocale(lang);
        this.setLangToHtmlTag(lang);
      },
      (err) => {
        if (isDevMode()) {
          console.error(`Problem with '${lang}' language initialization.'`);
          console.error(err);
        }
      },
    );
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }

  registerMomentLocale(lang: string) {
    DateTime.local().setLocale(lang);
    switch (lang) {
      case Languages.EN:
        registerLocaleData(localeEN);
        break;
    }
  }

  getInitLanguage(): Languages {
    // if (this.storage.isLogged) { ;TODO if the srevice should get a lang from user
    //   return this.storage.UserPerson.language as Languages;
    // }

    switch (this.translate.getBrowserLang()) {
      case Languages.EN:
        return this.translate.getBrowserLang() as Languages;
      default:
        return Languages.EN;
    }
  }

  setLangToHtmlTag(lang: string) {
    document.getElementsByTagName('html')[0].setAttribute('lang', lang);
  }

  isValidLanguage(testingLanguage: Languages): boolean {
    return !!this.availableLanguages.filter((lang) => lang === testingLanguage).length;
  }
}

/**
 * @description
 * @export
 * @enum {number}
 */
export enum Languages {
  EN = 'en',
}
