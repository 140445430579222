import { gql } from 'apollo-angular';

export const AddCanvasDocument = gql`
  mutation addCanvas($input: CanvasFormInput!) {
    addCanvas(input: $input) {
      id
      name
      description
      img
      hierarchyPosition
      category {
        id
        name
        description
      }
      positions {
        label
        description
        img
        type
        requirement
        visibility
        defaultValue
        defaultValueType
        hierarchyPosition
        positionValues {
          id
          name
          value
          default
          hierarchyPosition
        }
      }
    }
  }
`;
