export interface OnboardingStep {
  type?: EOnboardingStep;
  routeName?: string;
  nextStep(customAction?: () => void): void;
  elements?: OnboardingElements[];
  helpMarks?: OnboardingHelpMark[];
  index?: number;
  name?: string;
}

export enum EOnboardingStep {
  TACTICS_INTRO = 1,
  TACTICS_ADD_TO_FUNNEL = 2,
  FUNNEL_DASHBOARD = 3,
  FUNNEL_TACTIC_DROP = 4,
  FUNNEL_FLOW_FINISHED = 5,
}

export interface OnboardingFormStep {
  index: number;
  name: string;
}

export enum EOnboardingElementType {
  accentLabel,
  tooltip,
  arrow,
  fullscreen,
}

export interface OnboardingElement {
  type: EOnboardingElementType;
}

export interface OnboardingAccentLabel extends OnboardingElement {
  text: string;
  position?: 'top' | 'bottom' | 'above' | 'below';
  rotate?: 'left' | 'right';
  maxWidth?: string;
}

export interface OnboardingTooltip extends OnboardingElement {
  title: string;
  subtitle: string;
  position: 'top' | 'bottom' | 'left' | 'right';
}

export interface OnboardingArrow extends OnboardingElement {
  position: 'top' | 'bottom' | 'left' | 'right';
  distance: number;
}

export interface OnboardingFullscreenFocus extends OnboardingElement {
  action(node: HTMLElement): void;
}

export interface OnboardingDOMElement {
  parent: Element;
  child: Element;
}

export type OnboardingElements =
  | OnboardingAccentLabel
  | OnboardingTooltip
  | OnboardingArrow
  | OnboardingFullscreenFocus;

export interface OnboardingDOMElementFactory<T extends OnboardingElement> {
  renderElement(element: T): Element;
}

export interface OnboardingMultiDOMElementFactory<T> {
  renderElements(elements: T[]): OnboardingDOMElement[];
}

export interface OnboardingHelpMark {
  title?: string;
  text?: string;
}
