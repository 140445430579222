import { PromptOptions } from './prompt-options';
import { Injectable, Provider } from '@angular/core';
import { AbstractTuiDialogService, TUI_DIALOGS } from '@taiga-ui/cdk';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

import { ContentAccessDialogComponent } from './content-access-dialog.component.component';

@Injectable({
  providedIn: 'root',
})
export class ContentAccessDialogService extends AbstractTuiDialogService<PromptOptions> {
  readonly defaultOptions = {} as const;

  readonly component = new PolymorpheusComponent(ContentAccessDialogComponent);
}

// Add this provider to app module
export const PROMPT_PROVIDER: Provider = {
  provide: TUI_DIALOGS,
  useExisting: ContentAccessDialogService,
  multi: true,
};
