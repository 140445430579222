<form [formGroup]="formGroup">
  <tui-input-tag
    *tuiLet="itemsToString(items$ | async) as items"
    [tuiTextfieldSize]="size"
    [readOnly]="disabled"
    separator=" "
    [inputHidden]="isInputHidden()"
    (change)="change.emit($event)"
    (ngModelChange)="change.emit($event)"
    [tuiTextfieldLabelOutside]="true"
    [tuiTextfieldCleaner]="isCleaner"
    [placeholder]="'Type value and click enter to add new tag' | translate"
    [formControlName]="controlName"
    (searchChange)="onSearchChange($event)"
  >
    <ng-content></ng-content>

    <tui-data-list-wrapper
      *tuiDataList
      [emptyContent]="'Click enter to add new tag' | translate"
      [items]="items"
    ></tui-data-list-wrapper>
  </tui-input-tag>
</form>
