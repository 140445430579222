import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { Config } from '@shared/configs/config';
import { Integration } from '@shared/models/integration.model';
import { ContentGeneratorTemplatesService } from '../../services/content-generator-templates.service';

@Component({
  selector: 'df-content-generator-templates',
  templateUrl: './content-generator-templates.component.html',
  styleUrls: ['./content-generator-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorTemplatesComponent implements OnInit {
  searchPhrase = '';
  Config = Config;

  constructor(readonly templateService: ContentGeneratorTemplatesService) {}
  @Output() selectedIntegration: EventEmitter<Integration> = new EventEmitter<Integration>();
  @Output() templateTabSelected: EventEmitter<null> = new EventEmitter<null>();

  ngOnInit(): void {
    this.templateTabSelected.emit();
    this.templateService.getGeneratorIntegrations();
  }

  public onSelectIntegration(integration: Integration): void {
    this.selectedIntegration.emit(integration);
  }

  public onSearch(searchPhrase: string): void {
    this.templateService.filterData(searchPhrase);
  }
}
