import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'paragraph', pure: true })
export class ParagraphPipe implements PipeTransform {
  transform(value?: string, paragraphClass = ''): string {
    return value
      ? value
          .split('\n')
          .map((p) => (p.length > 0 ? `<p class="${paragraphClass}">${p}</p>` : `<br>`))
          .join('')
      : '';
  }
}
