<p
  class="w-full text-center mt-4 px-4 tui-text_body-l tui-text-02"
  [innerHTML]="
    'Funnels.Graphics.Are you sure you want to navigate away from this page? You will loose your data.' | translate
  "
></p>
<div class="flex items-center mt-8 justify-center">
  <button
    size="l"
    tuiButton
    type="button"
    class="mr-4"
    appearance="outline"
    (click)="cancel()"
  >
    {{ 'cancel' | translate }}
  </button>

  <button
    size="l"
    tuiButton
    type="submit"
    appearance="primary"
    class="danger"
    (click)="confirmExit()"
  >
    {{ 'sure' | translate }}
  </button>
</div>
