import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { XPartnerService } from '@shared/services/x-partner.service';
import { debounceTime } from 'rxjs/operators';
import { OnboardingService } from '@shared/services/onboarding.service';

@Component({
  selector: 'df-free-trial-bar',
  templateUrl: './free-trial-bar.component.html',
  styleUrls: ['./free-trial-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeTrialBarComponent extends AbstractSubscriptionComponent implements OnInit {
  barHidden = true;
  pricingUrl: string;
  readonly USER_TRIAL_BAR_COOKIE_NAME = 'user-trial-bar-closed--';

  get userId(): number {
    return this.userService.User?.id ?? 0;
  }

  get trialDaysLeft(): number | null {
    return this.userService.User?.trialDaysLeft ?? null;
  }

  get trialEnded(): boolean | null {
    return this.userService.User?.recentlyEndedTrial ?? null;
  }

  get usedTrial(): boolean {
    return this.userService.User?.usedTrial ?? false;
  }

  get finishedOnboarding(): boolean {
    return this.onboardingService.onboardingCompleted;
  }

  constructor(
    private readonly userService: UserService,
    private readonly cookieService: CookieService,
    private readonly changes: ChangeDetectorRef,
    private readonly xPartnerService: XPartnerService,
    private readonly onboardingService: OnboardingService,
  ) {
    super();
    this.pricingUrl = this.userService.resolvePricingPacketsUrl(true);
  }

  ngOnInit(): void {
    if (!this.xPartnerService.isPartner) {
      this.listenUserChanges();
    }
  }

  getTrialText() {
    return 'Trial.Your free trial ends...';
  }

  private listenUserChanges(): void {
    this.sub.add(this.userService.userEvents$.pipe(debounceTime(500)).subscribe(() => this.checkBarClosed()));
  }

  private checkBarClosed(): void {
    this.barHidden = !this.userService.User
      ? true
      : this.trialDaysLeft === null &&
        this.trialEnded === true &&
        this.cookieService.check(this.USER_TRIAL_BAR_COOKIE_NAME + this.userService.User?.id);
    this.changes.detectChanges();
  }

  hideBar(): void {
    this.cookieService.set(this.USER_TRIAL_BAR_COOKIE_NAME + this.userService.User?.id, 'true', 365);
    this.checkBarClosed();
  }
}
