import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@shared/configs/config';
import { Subscription } from 'rxjs';
import { IUnsplashSearchData } from './interfaces/unsplash-search-data.interface';

@Injectable({
  providedIn: 'root',
})
export class ContentGeneratorUnsplashModalService {
  readonly Config = Config;
  tuiDialogSub$: Subscription = new Subscription();

  constructor(private http: HttpClient) {}

  getSearchResult(text: string, pageNr: number) {
    return this.http.get<IUnsplashSearchData>(
      `${Config.API_UNSPLASH_URL}search/photos?page=${pageNr}&query=${text}&client_id=${Config.API_UNSPLASH_ACCESS_KEY}`,
    );
  }

  downloadFile(url: string) {
    return this.http.get<{ type: string; size: string }>(url, {
      responseType: 'blob' as 'json',
    });
  }
}
