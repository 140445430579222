<div class="w-full h-full relative border border-solid tui-primary-border rounded-3xl tui-base-01-bg overflow-hidden">
  <div class="w-full h-full flex flex-col justify-start items-start p-5 md:max-w-lg">
    <h3 class="tui-text_body-xl font-semibold mb-2">
      {{ 'Tactics.Onboarding.This is Tactic Library' | translate }}
    </h3>
    <p class="text-sm md:text-base mb-4 flex-grow">
      {{ 'Tactics.Onboarding.Our library contains...' | translate }}
    </p>
    <button
      class="w-full md:w-auto"
      tuiButton
      type="button"
      size="xl"
      appearance="primary"
      (click)="buttonClick.emit(true)"
    >
      {{ 'Tactics.Onboarding.Ok, let’s start!' | translate }}
    </button>
  </div>

  <img
    src="assets/img/illustration_onboarding_tactic_library.png"
    alt="onboarding"
    class="hidden xl:block absolute right-0 top-0 h-full w-auto"
  />
</div>
