import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  ElementRef,
  ViewChild,
  Inject,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FetchResult } from '@apollo/client/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { GetCategoryQuery } from '@modules/prompt/shared/graphql/queries/get-category.query.generated';
import { PromptService } from '@modules/prompt/shared/services/prompt.service';
import { TacticGraphqlService } from '../../services/tactic-graphql.service';
import {
  ChatgptModelVersion,
  ChatgptRole,
  ChatMessage,
  MessageHistory,
  SendMessageData,
} from '@shared/components/chatgpt-message-box/chatgpt-message-box.model';
import { ChatgptMessageBoxService } from '@shared/components/chatgpt-message-box/chatgpt-message-box.service';
import { sortBy } from 'lodash-es';
import { TuiDialogContext, TuiDialogService, TuiScrollbarComponent } from '@taiga-ui/core';
import { uuid4 } from '@sentry/utils';
import { TranslateService } from '@ngx-translate/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { ResolvePromptInputGraphql, ResolvePromptVariableInputGraphql } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-activity-prompt-chat',
  templateUrl: './activity-prompt-chat.component.html',
  styleUrls: ['./activity-prompt-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityPromptChatComponent implements OnInit {
  @Output()
  public openChat: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Output()
  public refreshList: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input() data: string | null = null;
  @Input() isEditValue = false;
  @Input() isEditValueShowBtn = false;
  @Input() activityId: number | null = null;
  @Input() funnelTacticId: number | null = null;
  @Input() funnelId: number | null = null;
  @Input() isRefreshValue = false;

  private _customPromptData: any;
  @Input()
  set customPromptData(value: any) {
    this._customPromptData = value;
    setTimeout(() => {
      this.getPromptData(this._customPromptData);
    }, 300);
  }

  get customPromptData(): any {
    return this._customPromptData;
  }

  public promptText: string | null = null;
  public categories: any[] = [];
  public loading = true;
  public loadingCategory = false;
  public categoryItem: any;
  public loadingCustomPromptData = true;

  public customPromptFields: any;

  public form: FormGroup = new FormGroup({});
  public editForm: FormGroup | null = null;
  public chatForm: FormGroup = new FormGroup({
    message: new FormControl('', [Validators.required]),
    modelVersion: new FormControl('v3.5-turbo - Good quality, and faster', Validators.required),
  });

  public chatFormEdit: FormGroup = new FormGroup({
    message: new FormControl('', [Validators.required]),
    modelVersion: new FormControl('v3.5-turbo - Good quality, and faster', Validators.required),
  });

  messages: ChatMessage[] = [];

  public promptTextArray: string[] = [];
  public nameFieldArray: string[] = [];

  public formDataMap = new Map<string, any>();
  public formDataMapValue: any;
  public categoriesMap = new Map<string, any>();
  public id: number | null = null;

  public fullOutputCustomPrompt: string | null = null;
  public originalFullOutputCustomPrompt: string | null = null;
  public isFullOutputCustomPrompt = false;
  public outputCustomPrompt: string | null = null;
  public isOutputCustomPrompt = false;
  public resetForm = false;
  public isEditMessage = false;
  public isEditMessageChat = false;
  public isRefreshValueEdit = false;

  public v: string | null = null;
  public chatEditFormText: string | null = null;
  public editFormText: string | null = null;
  public wordCount = 0;
  history: MessageHistory = {};
  historyId!: string;

  @ViewChild(TuiScrollbarComponent, { read: ElementRef })
  private readonly scrollBar?: ElementRef<HTMLElement>;
  public customPromptId: number | null = null;
  public isDisabledBtn = false;
  public isDisabledBtnCustomPrompt = false;

  mapEditMessage: Map<number, string> = new Map<number, string>();
  mapEditMessageChat: Map<number, string> = new Map<number, string>();
  getCatalogByDef: any;
  getCatalogByDefMap = new Map<number, any>();
  refreshValueEditMap = new Map<number, string>();

  constructor(
    private changes: ChangeDetectorRef,
    protected promptService: PromptService,
    private s: SnackbarService,
    private t: TranslateService,
    protected tacticGraphqlService: TacticGraphqlService,
    public chatgptMessageBoxService: ChatgptMessageBoxService,
    public element: ElementRef,
    public rendered: Renderer2,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {
    this.getCategory();
  }

  async ngOnInit(): Promise<void> {
    await this.chatgptMessageBoxService.getIntegrationData();
    this.historyId = uuid4();

    setTimeout(() => {
      this.getHistory();
    }, 500);

    if (this.isEditValue) {
      const customPromptOutputFilterArray = this.customPromptData?.customPromptOutput.filter(
        (el) => el.funnelTacticId === this.funnelTacticId,
      );
      this.fullOutputCustomPrompt = customPromptOutputFilterArray[0]?.value;
      this.originalFullOutputCustomPrompt = customPromptOutputFilterArray[0]?.value;
      this.outputCustomPrompt = customPromptOutputFilterArray[0]?.input;
      this.editFormText = customPromptOutputFilterArray[0]?.value;
      this.isEditValueShowBtn = true;
      this.editForm = new FormGroup({
        message: new FormControl(customPromptOutputFilterArray[0]?.value),
      });
    }
  }

  async getHistory() {
    const outputs = await this.chatgptMessageBoxService.getHistory();

    const groupedOutput = outputs!.reduce(
      (result, item) => ({
        ...result,
        [item['historyId']]: [...(result[item['historyId']] || []), item],
      }),
      {},
    );

    this.history = sortBy(groupedOutput, (item) => (item[0] as any).createdAt).reverse() as unknown as MessageHistory;
    this.changes.detectChanges();
  }

  closeChat() {
    this.formDataMap.clear();
    this.customPromptData = undefined;
    this.loadingCustomPromptData = false;
    this.isEditValue = false;
    this.openChat.emit(false);
    this.changes.detectChanges();
  }

  public getPromptData(data: any) {
    this.loadingCategory = false;
    if (undefined !== data && !this.loadingCategory) {
      this.customPromptFields = data.customPromptFields;
      this.promptText = data?.promptText;
      this.customPromptId = data?.id;
      if (this.isEditValue || this.isRefreshValue) {
        const cutomPromptOutputFilterArray = data?.customPromptOutput.filter(
          (el) => el.funnelTacticId === this.funnelTacticId,
        );
        this.id = cutomPromptOutputFilterArray[0]?.id;
      }

      if (null !== this.promptText) {
        const arrNameField = this.promptText.match(/([^${]+(?=}))/g);
        const wordsArray = this.promptText.split(/\${(.*?)\}/g);

        if (wordsArray.length > 0 && null !== arrNameField) {
          this.promptTextArray = wordsArray.filter((i) => !arrNameField.includes(i));
          this.nameFieldArray = arrNameField;
        }
        this.form = new FormGroup({});
        this.formDataMap.clear();

        if (null !== arrNameField && arrNameField.length > 0) {
          arrNameField.map((item) => {
            this.customPromptFields.map((el, index) => {
              if (item === el.name) {
                let defaultValue = null;
                if (el.defaultValueId !== null) {
                  this.getDataWarehouse(el.defaultValueId, index);
                }
                setTimeout(() => {
                  defaultValue =
                    el.defaultValueId && this.getCatalogByDefMap.has(index) ? this.getCatalogByDefMap.get(index) : null;
                  this.formDataMap.set(el.fieldId, el);
                  if (el.required) {
                    this.form?.addControl(item, new FormControl(defaultValue, Validators.required));
                  } else {
                    this.form?.addControl(item, new FormControl(defaultValue));
                  }
                }, 400);
              }
            });
          });
        }

        setTimeout(() => {
          this.loadingCategory = true;
        }, 500);
      }
    }
  }

  get formDataMapVal() {
    return Array.from(this.formDataMap.values());
  }

  public getCategory() {
    this.promptService
      .getCategory()
      .subscribe({
        next: (res: FetchResult<GetCategoryQuery>) => {
          if (res.data?.getCategory) {
            this.categories = res.data?.getCategory;
            this.categories.forEach((el) => this.categoriesMap.set(el.id, el.label));

            this.loading = false;
          }
          this.changes.detectChanges();
        },
        error: () => {
          this.s.defaultError();
          this.loading = false;
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  getDataWarehouse(categoryId, index: number) {
    this.tacticGraphqlService.getDataWarehouse(categoryId).subscribe({
      next: (res) => {
        this.getCatalogByDef = res?.data?.getCatalogByDef;
        this.getCatalogByDefMap.set(index, res?.data?.getCatalogByDef?.data?.value);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  onSubmit() {
    this.isDisabledBtnCustomPrompt = true;
    if (this.form.valid) {
      const arr: Record<string, string | ResolvePromptVariableInputGraphql[]>[] = [];

      this.nameFieldArray.forEach((item) => {
        arr.push({
          name: item,
          value: this.form.get(item)?.value ? this.form.get(item)?.value : '',
        });
      });

      const data: ResolvePromptInputGraphql = {
        id: this.customPromptId!,
        variables: arr as ResolvePromptVariableInputGraphql[],
      };

      this.isFullOutputCustomPrompt = true;
      this.tacticGraphqlService.resolveCustomPrompt(data).subscribe({
        next: (res) => {
          this.promptTextArray = [];
          this.outputCustomPrompt = res?.data?.resolveCustomPrompt?.output as string;
          this.isOutputCustomPrompt = true;
          this.isFullOutputCustomPrompt = false;
          this.fullOutputCustomPrompt = res?.data?.resolveCustomPrompt?.full_output as string;

          this.onFormSubmit();

          this.resetForm = true;

          this.changes.detectChanges();
        },
        error: () => {
          this.s.error(this.t.instant('Tactics.Prompt.Error. Try again.'));
          this.isFullOutputCustomPrompt = false;
          this.changes.detectChanges();
        },
      });
    } else {
      this.s.error(this.t.instant('Tactics.Prompt.Please fill required field'));
      this.isDisabledBtnCustomPrompt = false;
    }
  }

  public editMessage(data: string, edit?: boolean) {
    if (!edit) {
      this.isEditMessage = true;
      this.editForm = new FormGroup({
        message: new FormControl(data),
      });
      this.isDisabledBtn = false;

      this.editFormText = data;
    } else {
      this.isEditValueShowBtn = true;
      this.editForm = new FormGroup({
        message: new FormControl(data),
      });
      this.isDisabledBtn = false;
      this.editFormText = data;
    }
  }

  public editMessageChat(i: number, data: string) {
    this.isEditMessageChat = true;
    this.isRefreshValueEdit = true;
    this.mapEditMessageChat.set(i, data);
    this.chatFormEdit = new FormGroup({
      message: new FormControl(data),
    });

    this.chatEditFormText = data;
  }

  public onSubmitEditMessage(data?: string) {
    if (
      null !== this.activityId &&
      null !== this.id &&
      null !== this.customPromptId &&
      null !== this.fullOutputCustomPrompt &&
      null !== this.outputCustomPrompt
    ) {
      this.tacticGraphqlService
        .updateCustomPromptOutput({
          funnelTacticId: this.funnelTacticId,
          activityId: this.activityId,
          id: this.id,
          customPromptId: this.customPromptId,
          value: this.isRefreshValue && data !== undefined ? data : this.fullOutputCustomPrompt,
          input: this.outputCustomPrompt,
        })
        .subscribe({
          next: (res) => {
            this.fullOutputCustomPrompt = res?.data?.updateCustomPromptOutput?.value as string;
            this.s.success('Success! Updated message!');
            this.isDisabledBtn = true;
            this.refreshList.emit(true);

            this.changes.detectChanges();
          },
          error: () => {
            this.s.error('Error! Cannot save updated message!');
            this.refreshList.emit(false);
            this.isDisabledBtn = false;
            this.changes.detectChanges();
          },
        });
    }
  }

  public onUpdate(updateFormType: string, edit?: boolean, index?: number) {
    switch (updateFormType) {
      case 'edit-form': {
        if (null !== this.fullOutputCustomPrompt && null !== this.editForm && !edit) {
          this.isEditMessage = false;
          this.fullOutputCustomPrompt = this.editForm.value.message;
        }
        break;
      }
      case 'edit-chat-form': {
        if (null !== this.fullOutputCustomPrompt && null !== this.chatFormEdit && !edit) {
          this.isEditMessageChat = false;
          this.fullOutputCustomPrompt = this.chatFormEdit.value.message;

          this.mapEditMessageChat.clear();

          if (index) {
            this.mapEditMessage.set(index, this.chatFormEdit.value.message);
          }
        }
        break;
      }
      case 'edit-value-form': {
        if (null !== this.fullOutputCustomPrompt && null !== this.editForm && edit) {
          this.fullOutputCustomPrompt = this.editForm.value.message;
          const customPromptOutputFilterArray = this.customPromptData?.customPromptOutput.filter(
            (el) => el.funnelTacticId === this.funnelTacticId,
          );
          this.outputCustomPrompt = customPromptOutputFilterArray[0]?.input;
          this.isEditValueShowBtn = false;
        }
        break;
      }
      default: {
        console.log('Sorry');
        break;
      }
    }
  }

  public cancelMessage(edit?: boolean) {
    if (!edit) {
      this.editForm = null;
      this.isEditMessage = false;
    } else {
      this.editForm = null;
      this.isEditValueShowBtn = false;
      this.isDisabledBtn = true;
    }
  }

  public cancelMessageChat() {
    this.isEditMessageChat = false;
    this.mapEditMessageChat.clear();
  }

  onFormSubmit() {
    if (!this.chatForm.valid && this.outputCustomPrompt === null) return;
    if (this.wordCount > 1000) return;

    const message = this.chatForm.get('message')?.value ? this.chatForm.get('message')?.value : this.outputCustomPrompt;
    const context = this.messages.map((item) => ({
      content: item.content,
      role: item.role,
    }));
    const modelVersion = this.getModelVersion();

    this.messages.push({
      role: ChatgptRole.User,
      content: message,
    });

    this.changes.detectChanges();
    this.sendMessage({
      message,
      role: ChatgptRole.User,
      modelVersion,
      context,
    });
    this.isDisabledBtnCustomPrompt = false;
    this.chatForm.get('message')?.setValue('');
  }

  onPressEnter(event) {
    event.stopPropagation();
    event.preventDefault();
    this.onFormSubmit();
  }

  getModelVersion(): ChatgptModelVersion {
    const modelVersionRaw = this.chatForm.get('modelVersion')?.value;
    return this.chatgptMessageBoxService.getModelVersion(modelVersionRaw);
  }

  sendMessage({ message, role = ChatgptRole.User, modelVersion, context = [] }: SendMessageData) {
    const oldHistory = this.history;

    this.loading = true;
    this.changes.detectChanges();
    this.scrolledToBottom();
    this.changes.detectChanges();

    this.chatgptMessageBoxService.sendMessage(message, role, modelVersion, context, this.historyId).subscribe(
      (data) => {
        const responseMessage = data?.outputs[0].value.data as ChatMessage;

        if (!responseMessage) return;

        this.messages.push({ ...responseMessage });
        this.getHistory();
        this.loading = false;
        this.changes.detectChanges();
        this.scrolledToBottom();
        this.changes.detectChanges();
      },
      (errors) => {
        if (errors.length) {
          this.loading = false;
          return;
        }

        const interval = setInterval(async () => {
          await this.getHistory();

          if (JSON.stringify(oldHistory) !== JSON.stringify(this.history)) {
            clearInterval(interval);
            this.loading = false;
            this.historyClicked(this.historyId);
            this.scrolledToBottom();
          }
        }, 30000);
      },
    );
  }

  historyClicked(historyId: string) {
    const history = this.history as any;
    const item = history.find((item) => item[0].historyId === historyId);
    this.setNewContext(
      historyId,
      sortBy(item, (i) => i.createdAt),
    );
    this.scrolledToBottom();
  }

  scrolledToBottom() {
    this.scrollBar?.nativeElement.scrollTo({
      top: this.scrollBar?.nativeElement.scrollHeight - 500,
      behavior: 'smooth',
    });
  }

  setNewContext(historyId = uuid4(), messages: ChatMessage[] = []) {
    this.historyId = historyId;
    this.messages = [...messages];
  }

  public save(data?: any) {
    if (
      null !== this.activityId &&
      null !== this.customPromptId &&
      null !== this.fullOutputCustomPrompt &&
      null !== this.outputCustomPrompt
    ) {
      this.tacticGraphqlService
        .createCustomPromptOutput({
          funnelTacticId: this.funnelTacticId,
          activityId: this.activityId,
          customPromptId: this.customPromptId,
          value: data ? data : this.fullOutputCustomPrompt,
          input: this.outputCustomPrompt,
        })
        .subscribe({
          next: (res) => {
            this.fullOutputCustomPrompt = res?.data?.createCustomPromptOutput?.value as string;
            this.isEditMessage = false;
            this.s.success('Success! Created message!');
            this.refreshList.emit(true);
            this.isDisabledBtn = true;
            if (document.body.querySelector(`#activity-${this.activityId}`)) {
              this.element.nativeElement.querySelector(`body #activity-${this.activityId}`)?.click();
            }

            this.changes.detectChanges();
          },
          error: () => {
            this.refreshList.emit(false);
            this.isDisabledBtn = false;
            this.changes.detectChanges();
          },
        });
    }
  }

  showDialog(content: PolymorpheusContent<TuiDialogContext>): void {
    if (this.customPromptData?.customPromptOutput === null && this.fullOutputCustomPrompt !== null) {
      this.dialogService.open(content).subscribe();
    } else {
      this.closeChat();
    }
  }

  closeDialog(observer) {
    observer.complete();
    this.closeChat();
  }
}
