import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { PublicRecommenderService } from './public-recommender.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RecommenderOutputGraphql } from '@modules/graphql/graphql-types';
import { NavigateService } from '@core/routes/services/navigate.service';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { PublicRecommenderSetUrlModalComponent } from './components/public-recommender-set-url-form/public-recommender-set-url-form.component';

@Component({
  selector: 'df-public-recommender',
  templateUrl: './public-recommender.component.html',
  styleUrls: ['./public-recommender.component.scss'],
})
export class PublicRecommenderComponent extends AbstractSubscriptionComponent implements OnInit {
  url = '';
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  items$: Observable<RecommenderOutputGraphql[]>;
  form!: FormGroup;

  private readonly dialog;

  constructor(
    private recommenderService: PublicRecommenderService,
    private funnelGraphqlService: FunnelGraphqlService,
    private route: ActivatedRoute,
    private s: SnackbarService,
    private t: TranslateService,
    private n: NavigateService,
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super();
    this.items$ = this.recommenderService.getRecommenderItems();

    this.dialog = this.dialogs.open<number>(
      new PolymorpheusComponent(PublicRecommenderSetUrlModalComponent, this.injector),
      {
        dismissible: false,
        closeable: false,
        label: 'Set your URL',
      },
    );
  }

  ngOnInit() {
    this.setUrl();
    this.initForm();
  }

  initForm() {
    this.form = this.recommenderService.initForm();
  }

  onSubmit() {
    if (this?.form?.invalid || !this.url) return;

    const selectedItem = this.form.get('answer')!.value as RecommenderOutputGraphql;

    this.loading$.next(true);

    this.sub.add(
      this.funnelGraphqlService.cloneFunnelForAnonymousUser(selectedItem.funnel!.id).subscribe((funnelRes) => {
        const cloneFunnelId = funnelRes.data?.cloneFunnelForAnonymousUser?.id;
        if (!cloneFunnelId) return this.s.error(this.t.instant('Recommender.FunnelNotFound'));

        this.recommenderService.setCompanyData('', this.url, cloneFunnelId).subscribe(() => {
          const path = 'public/funnel/:id';

          this.recommenderService.put(
            'dfai-anonymous-user',
            JSON.stringify({
              funnelId: cloneFunnelId,
              url: this.url,
              name: selectedItem.name,
            }),
          );

          this.loading$.next(false);
          this.n.go(path, { id: cloneFunnelId });
        });
      }),
    );
  }

  private setUrl() {
    this.sub.add(
      this.route.queryParams.subscribe((params) => {
        this.url = params.url;

        if (!this.url)
          this.dialog.subscribe({
            next: (data) => (this.url = data),
          });
      }),
    );
  }
}
