import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Tactic } from '@shared/models/tactic.model';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Step } from '@shared/models/step.model';
import { Funnel } from '@shared/models/funnel.model';
import { UserService } from '@shared/services/user.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AddToFunnelButtonType } from '@modules/tactics/shared/components/add-to-funnel-button/add-to-funnel-button.component';
import { FunnelPermissionEnum } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-tactic-tile-actions',
  templateUrl: './tactic-tile-actions.component.html',
  styleUrls: ['./tactic-tile-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticTileActionsComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  AddToFunnelButtonType = AddToFunnelButtonType;
  FunnelPermissionEnum = FunnelPermissionEnum;
  private _tactic?: Tactic;
  steps: Step[] = [];
  subs: Subscription = new Subscription();
  canBeAdded = false;

  @Input() isRemoveFromFunnelAction = false;
  @Input() step: Step | null = null;
  @Input() toCopy = false;
  @Input() funnel?: Funnel;
  @Input() preventAction = false;

  @Input()
  set tactic(tactic: Tactic | undefined) {
    this._tactic = tactic;
    this.changes.detectChanges();
  }

  get tactic(): Tactic | undefined {
    return this._tactic;
  }

  @Output() addToFunnelEvent: EventEmitter<Tactic> = new EventEmitter<Tactic>();
  @Output() preview: EventEmitter<Tactic> = new EventEmitter<Tactic>();
  @Output() edit: EventEmitter<Tactic> = new EventEmitter<Tactic>();
  @Output() remove: EventEmitter<Tactic> = new EventEmitter<Tactic>();
  @Output() chooseToCopy: EventEmitter<Tactic> = new EventEmitter<Tactic>();
  @Output() previewInModal: EventEmitter<Tactic> = new EventEmitter<Tactic>();

  constructor(
    private changes: ChangeDetectorRef,
    public userService: UserService,
    public s: SnackbarService,
    public t: TranslateService,
  ) {}

  ngOnInit(): void {
    this.checkForPermissionToAdd();
  }

  checkForPermissionToAdd() {
    if (this.userService.User?.tacticDraft?.id !== this._tactic?.id) {
      this.canBeAdded = true;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
