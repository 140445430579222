import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SnackbarService } from '@core/services/snackbar.service';

import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { TranslateService } from '@ngx-translate/core';
import { captureException } from '@sentry/angular-ivy';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { dataURLtoFileOrBlob } from '@shared/helpers/base64-to-file.helper';
import { copyToClipboard } from '@shared/helpers/clipboard.helper';
import file2Base64 from '@shared/helpers/file-to-base64.helper';
import { AyrshareAccount, AyrshareService } from '@shared/services/ayrshare.service';
import { UserService } from '@shared/services/user.service';

import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { TuiPushService } from '@taiga-ui/kit';
import { catchError, of, map, take, switchMap } from 'rxjs';

interface Channel {
  name: string;
  icon: string;
  checked: boolean;
}

interface FormData {
  channels: string[];
  image: null | File | string;
  text: string;
  publishOn: [TuiDay, TuiTime] | Date;
}

@Component({
  selector: 'df-publications',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationComponent extends AbstractSubscriptionComponent implements OnInit {
  @Input() id: number | string | null = null;
  @Input() type: 'post' | 'tactic' = 'tactic';
  @Output() closePublication: EventEmitter<void> = new EventEmitter<void>();

  showNotification = true;
  today: [TuiDay, TuiTime] = [
    new TuiDay(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    new TuiTime(new Date().getHours(), new Date().getMinutes()),
  ];

  channelList: Channel[] = [
    {
      name: 'facebook',
      icon: 'fb.svg',
      checked: false,
    },
    {
      name: 'facebook-groups',
      icon: 'fb-new.svg',
      checked: false,
    },
    {
      name: 'google-business-profile',
      icon: 'google.svg',
      checked: false,
    },
    {
      name: 'instagram',
      icon: 'instagram.svg',
      checked: false,
    },
    {
      name: 'linkedin',
      icon: 'linkedin.svg',
      checked: false,
    },
    {
      name: 'pinterest',
      icon: 'pinterest.svg',
      checked: false,
    },
    {
      name: 'reddit',
      icon: 'reddit.svg',
      checked: false,
    },
    {
      name: 'telegram',
      icon: 'twitter.svg',
      checked: false,
    },
    {
      name: 'tiktok',
      icon: 'tiktok.svg',
      checked: false,
    },
    {
      name: 'twitter',
      icon: 'twitter.svg',
      checked: false,
    },
    {
      name: 'youtube',
      icon: 'youtube.svg',
      checked: false,
    },
  ];

  form = new FormGroup({
    channels: new FormControl<string[]>([], [Validators.required]),
    image: new FormControl<null | File>(null),
    text: new FormControl('', [Validators.required]),
    publishOn: new FormControl(this.today, [Validators.required]),
  });

  ayrshareAccount?: AyrshareAccount;
  showText = true;
  dateError!: string;
  channelsError!: string;

  constructor(
    private tacticGraphqlService: TacticGraphqlService,
    private ayrshareService: AyrshareService,
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private userService: UserService,
    @Inject(TuiPushService) protected readonly push: TuiPushService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getAyrshareAccount();
    if (this.id) this.type === 'tactic' ? this.getTacticPrompt() : this.getPost();

    this.form.get('publishOn')?.valueChanges.subscribe((value) => {
      if (value && value[1])
        this.validateDate(new Date(value[0].year, value[0].month, value[0].day, value[1].hours, value[1].minutes));
    });
  }

  getAyrshareAccount() {
    this.sub.add(
      this.ayrshareService.getAccount().subscribe((res) => {
        if (!res) {
          this.s.warning(this.t.instant('Ayrshare.Connect your account'));
          this.n.go('/account/settings/personal-data');
          return;
        }

        this.ayrshareAccount = res;
        this.changes.detectChanges();
      }),
    );
  }

  editText(value: boolean) {
    this.showText = value;
  }

  copyToClipboard(data: string): void {
    copyToClipboard(data, this.s, this.t);
  }

  getPost() {
    this.sub.add(
      this.ayrshareService.getPost(String(this.id)).subscribe(async (res) => {
        if (!res) return;
        const result = res.data.getPost;
        const date = new Date(result.created as string);
        const file = (await dataURLtoFileOrBlob(result.mediaUrls[0], {
          mainType: 'image/png',
          returnBlob: false,
        })) as File;
        console.log(result.platforms);
        this.form.get('text')?.setValue(result.post);
        this.form.get('channels')?.setValue(result.platforms);
        this.form
          .get('publishOn')
          ?.setValue([
            new TuiDay(date.getFullYear(), date.getMonth(), date.getDate()),
            new TuiTime(date.getHours(), date.getMinutes()),
          ]);
        this.form.get('image')?.setValue(file);

        result.platforms?.forEach((platform) =>
          this.channelList?.forEach((channel) => (channel.checked = channel.name === platform)),
        );
        this.changes.detectChanges();
      }),
    );
  }

  getTacticPrompt() {
    const id = this.id;
    if (!id) {
      this.closePublication.emit();
      return;
    }

    this.sub.add(
      this.tacticGraphqlService
        .getCustomPromptOutput(id as number)
        .pipe(
          catchError((e) => {
            captureException(e);
            return of(null);
          }),
          map((res) => res?.data?.getCustomPromptOutput),
        )
        .subscribe((res) => {
          if (!res) return;
          this.form.get('text')?.setValue(res.value);
        }),
    );
  }

  getChannels() {
    return this.channelList.filter((channel) => this.ayrshareAccount?.activeSocialAccounts.includes(channel.name));
  }

  getControl(name: string) {
    return this.form.get(name);
  }

  onChannelClick(channel: Channel) {
    channel.checked = !channel.checked;
    const channels = this.form.get('channels') as FormControl;

    if (channel.checked) channels.setValue([...channels.value, channel]);
    else channels.setValue([...channels.value.filter((c) => c.name !== channel.name)]);

    this.validateChannels(channels.value);

    this.changes.detectChanges();
  }

  selectAll(event: Event) {
    const e = event as unknown as { target: { checked: boolean } };
    const channels = this.form.get('channels') as FormControl;

    this.channelList.forEach((channel) => (channel.checked = e?.target?.checked ?? false));

    if (e.target.checked) channels.setValue([...this.channelList]);
    else channels.setValue([]);

    document
      .querySelectorAll<HTMLInputElement>('.ayrshare__checkbox')
      .forEach((input) => (input.checked = e?.target?.checked ?? false));

    this.changes.detectChanges();
  }

  onAddChannelClick() {
    this.ayrshareService.generateAccountUrl().subscribe((res) => {
      const page = window.open(res);
      const timer = setInterval(() => {
        if (page?.closed) {
          this.getAyrshareAccount();
          clearInterval(timer);
        }
      }, 500);
    });
  }

  async onSubmit() {
    this.form.markAllAsTouched();

    const formData = this.form.getRawValue() as unknown as FormData;
    formData.publishOn = new Date(
      formData.publishOn[0].year,
      formData.publishOn[0].month,
      formData.publishOn[0].day,
      formData.publishOn[1].hours,
      formData.publishOn[1].minutes,
    );

    const additionalValidators = [this.validateDate(formData.publishOn), this.validateChannels(formData.channels)];

    if (additionalValidators.some((v) => !v)) {
      return;
    }

    this.changes.detectChanges();
    if (!this.form.valid) return;

    if (formData.image) {
      formData.image = await file2Base64(formData.image as File);
    }

    this.sub.add(
      this.ayrshareService
        .publishPost({
          publishDate: formData?.publishOn.toString(),
          mediaUrls: formData?.image ? [formData?.image] : [],
          platforms: formData?.channels,
          post: formData?.text,
        })
        .subscribe((res) => {
          if (res) {
            this.closePublication.emit();
            this.push
              .open(
                'Additional description that has been planned for [Date/] or has been published. If it has been scheduled user can preview it in Content calendar',
                {
                  type: this.t.instant('Ayrshare.Content has ben sucessfuly scheduled!'),
                  icon: 'tuiIconCheckCircleLarge',
                  iconColor: '#46A716',
                  buttons: ['Content calendar'],
                },
              )
              .pipe(
                take(1),
                switchMap(() =>
                  this.n.go('funnels/f/d/:id/content-calendar', {
                    id: this.userService.User?.contextFunnel?.id,
                  }),
                ),
              )
              .subscribe();
            return;
          }

          this.s.error(this.t.instant('Ayrshare.Something wrong'));
        }),
    );
  }
  private validateChannels(channels: string[]) {
    if (!channels.length) {
      this.channelsError = 'This field is required';
      this.changes.detectChanges();
      return false;
    }

    this.channelsError = '';
    this.changes.detectChanges();
    return true;
  }

  private validateDate(date: Date) {
    if (date.getTime() < Date.now()) {
      this.dateError = "Publication time can't be in the past";
      this.changes.detectChanges();
      return false;
    }

    this.dateError = '';
    this.changes.detectChanges();
    return true;
  }
}
