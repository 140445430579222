import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';
import { OnboardingService } from '@shared/services/onboarding.service';

@Injectable()
export class OnboardingCanActivateGuard implements CanActivate {
  constructor(
    private n: NavigateService,
    private onboardingService: OnboardingService,
  ) {}

  canActivate(): boolean {
    if (this.onboardingService.onboardingRunning) {
      return true;
    }
    this.n.go(Config.MAIN_VIEW);
    return false;
  }
}
