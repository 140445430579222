<header class="border-b-[#E0E0E0] border-b-[1px] border-solid p-6 flex justify-between">
  <div class="flex items-center">
    <img
      class="cursor-pointer"
      (click)="onBackClick()"
      src="/assets/img/arrow-back.svg"
      alt=""
    />
    <h2 class="text-[20px] text-[#b1b2b9] font-medium">{{ userService.User?.contextFunnel?.name }} / AI Strategy</h2>
  </div>

  <div class="ml-auto flex items-center">
    <span
      *ngIf="isEditMode"
      class="text-[14px] text-[#1B1F3BA6] font-medium ml-2"
      >Saved {{ getLastEditDate() }}
    </span>
    <button
      data-test="save-and-exit-button"
      class="mx-2 hidden md:block"
      (click)="saveAndExit()"
      tuiButton
      type="button"
      appearance="outline"
    >
      <span class="tui-primary font-semibold">Save and exit</span>
    </button>
    <button
      data-test="generate-button"
      class="hidden md:block"
      tuiButton
      (click)="generate()"
      type="button"
    >
      Generate
    </button>
  </div>
</header>

<div class="md:grid md:grid-cols-[minmax(200px,650px)_minmax(60%,1fr)]">
  <form
    [formGroup]="form"
    [validate-form]="form"
    (submit)="onSubmit()"
    class="overflow-y-scroll md:overflow-y-auto mb-12"
  >
    <df-business-data-list
      [form]="form"
      (actionChange)="changeShowedForm($event)"
    ></df-business-data-list>
  </form>

  <form
    class="hidden md:block bg-[#f8faff] px-4 lg:px-8 py-6 lg:py-12 relative"
    [formGroup]="form"
    [validate-form]="form"
    (submit)="onSubmit()"
  >
    <div class="flex flex-col justify-between rounded-[24px] bg-[#FFF] py-12 px-16 h-full">
      <df-scrape-website-form
        [class.businessHidden]="selectedFormType !== ActionTypeEnum.SCRAPE_WEBSITE"
        [form]="form"
      ></df-scrape-website-form>
      <df-write-description-form
        [class.businessHidden]="selectedFormType !== ActionTypeEnum.WRITE_DESCRIPTION"
        [form]="form"
      ></df-write-description-form>
      <df-upload-files
        [class.businessHidden]="selectedFormType !== ActionTypeEnum.UPLOAD_FILES"
        [form]="form"
      ></df-upload-files>
      <df-recordings
        [class.businessHidden]="selectedFormType !== ActionTypeEnum.RECORDINGS"
        [form]="form"
      ></df-recordings>
      <df-youtube-content
        [class.businessHidden]="selectedFormType !== ActionTypeEnum.YOUTUBE"
        [form]="form"
      ></df-youtube-content>
      <df-google
        [class.businessHidden]="selectedFormType !== ActionTypeEnum.GOOGLE_DRIVE"
        [form]="form"
      ></df-google>
      <df-dropbox
        [class.businessHidden]="selectedFormType !== ActionTypeEnum.DROPBOX"
        [form]="form"
      ></df-dropbox>
      <button
        class="self-end mt-6"
        (click)="onContinueClick()"
        tuiButton
        type="button"
        appearance="outline"
      >
        <span class="tui-primary font-semibold">Continue</span>
      </button>
    </div>
  </form>

  <div
    class="md:hidden black fixed bottom-0 left-0 border-t-[1px] border-t-[#E0E0E0] border-solid w-full p-4 bg-[#fff] grid grid-cols-[1fr,1fr] gap-4"
  >
    <button
      (click)="saveAndExit()"
      tuiButton
      type="button"
      appearance="outline"
    >
      <span class="tui-primary font-semibold">Save and exit</span>
    </button>
    <button
      tuiButton
      (click)="generate()"
      type="button"
    >
      Generate
    </button>
  </div>
</div>

<df-full-list-loader *ngIf="loading"></df-full-list-loader>
