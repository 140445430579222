<div
  class="flex justify-start items-stretch flex-wrap -m-1"
  *ngIf="categories?.length"
>
  <tui-badge
    *ngFor="let category of categories"
    class="badge outline m-1 border rounded-full tui-base-04-border"
    size="m"
    [hoverable]="true"
  >
    <span class="flex items-center justify-center pl-3 pr-3 tui-text-02 tui-text_body-xs !font-semibold">
      {{ category.name }}
      <svg-icon
        [src]="'/assets/img/ic-close.svg'"
        class="ml-2 tui-base-04-svg"
        (click)="removeCategory(category.id, $event)"
        [svgStyle]="{ width: '14px', height: '14px' }"
      ></svg-icon>
    </span>
  </tui-badge>
</div>
