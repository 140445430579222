<div
  class="w-full h-full flex flex-row justify-between border border-solid border-[#E0E0E0] mb-1 rounded-lg item relative overflow-hidden cursor-pointer h-box-shadow-box"
  [class.p-2]="benchmark.type.type === BenchmarkTypeEnum.Link"
  [class.tui-base-02]="disabled"
>
  <ng-container [ngSwitch]="benchmark.type.type">
    <!-- Link -->
    <ng-container *ngSwitchCase="BenchmarkTypeEnum.Link">
      <a
        (click)="openBenchmarkModal()"
        class="benchmark-link w-full h-full no-underline tui-primary hover:underline hover:opacity-80"
        [title]="viewLink"
      >
        <!-- data -->
        <div class="w-full h-full justify-between flex items-center">
          <!-- banner -->
          <ng-container *ngIf="benchmark.iconUrl; then banner; else noBanner"></ng-container>
          <ng-template #banner>
            <img
              class="h-4 w-4 object-cover"
              [src]="benchmark.iconUrl"
              alt=""
            />
          </ng-template>
          <ng-template #noBanner>
            <!-- no banner in metadata of url -->
            <img
              class="h-4 w-4 object-cover"
              src="/assets/img/icon-url.svg"
              alt=""
            />
          </ng-template>

          <div class="benchmark-link__title tui-text_menu-header tui-primary tui-text_body-18 bg-white ml-1">
            {{ benchmark.name }}
          </div>
          <div class="benchmark-link__url tui-primary tui-text_body-18 bg-white ml-1">
            {{ viewLink }}
          </div>
        </div>
      </a>
    </ng-container>

    <!-- File -->
    <ng-container *ngSwitchCase="BenchmarkTypeEnum.File">
      <div class="benchmark-file w-full h-full">
        <ng-container
          *ngIf="
            Regex.fileImage.test(benchmark.attachment!.file) || Regex.fileVideo.test(benchmark.attachment!.file);
            then imgTmpl;
            else fileTmpl
          "
        >
        </ng-container>
        <!-- img -->
        <ng-template #imgTmpl>
          <ng-container *ngIf="Regex.fileImage.test(benchmark.attachment!.file); then imgTmplImg; else imgTmplVideo">
          </ng-container>

          <ng-template #imgTmplImg>
            <img
              class="h-24 w-full object-cover"
              [src]="Config.ASSETS + benchmark.attachment?.file"
              [alt]="benchmark.attachment?.name"
              (click)="openBenchmarkModal()"
              [title]="benchmark.attachment?.name"
            />
            <div class="flex items-center h-[34px] p-2 tui-primary tui-text_body-18 bg-white">
              <svg-icon
                class="tui-primary"
                [svgStyle]="{ width: '1rem', height: '1rem' }"
                [src]="'/assets/img/icon-copy.svg'"
              ></svg-icon>
              <span class="line-clamp-1 ml-1 hover:underline">{{ benchmark.attachment?.name }}</span>
            </div>
          </ng-template>

          <ng-template #imgTmplVideo>
            <video
              class="h-full object-cover"
              width="100%"
              height="100%"
              (click)="openBenchmarkModal()"
              [title]="benchmark.attachment?.name"
            >
              <source [src]="Config.ASSETS + benchmark.attachment?.file" />
              {{ 'Your browser does not support the video tag.' | translate }}
            </video>
          </ng-template>
        </ng-template>
        <!-- other than img -->
        <ng-template #fileTmpl>
          <div class="flex flex-col w-full h-full justify-between items-center">
            <!-- button -->
            <button
              *ngIf="benchmark.type.type === BenchmarkTypeEnum.File && !disabled"
              (click)="downloadAttachment()"
              class="rounded-lg mt-4"
              tuiIconButton
              type="button"
              appearance="outline"
              [icon]="iconDownload"
              size="s"
              shape="square"
            ></button>
            <ng-template #iconDownload>
              <svg-icon
                class="tui-text-04"
                [svgStyle]="{ width: '1rem', height: '1rem' }"
                [src]="'/assets/img/ic-download.svg'"
              ></svg-icon>
            </ng-template>
            <!-- file name -->
            <div
              class="flex items-center w-full h-[34px] p-2 tui-primary tui-text_body-18 bg-white hover:opacity-80 hover:underline"
            >
              <svg-icon
                class="tui-primary"
                [svgStyle]="{ width: '1rem', height: '1rem' }"
                [src]="'/assets/img/icon-copy.svg'"
              ></svg-icon>
              <span class="line-clamp-1 ml-1">{{ benchmark.attachment?.name }}</span>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</div>
