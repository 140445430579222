import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FetchResult } from '@apollo/client/core';
import {
  FilterTypes,
  IntegrationCategoryOutputGraphql,
  ListFiltersFieldInput,
  OrderTypes,
  OutputDataGraphql,
  OutputsDataSearchInputGraphql,
} from '@modules/graphql/graphql-types';
import { UserService } from '@shared/services/user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetIntegrationsCategoriesQuery } from '../graphgql/queries/get-integrations-categories.query.generated';
import { SearchContentOutputsDataQuery } from '../graphgql/queries/search-content-outputs-data.query.generated';
import { ContentLibraryFormField } from '../models/funnel-content-generator-library.enum';
import { FunnelContentGeneratorLibraryGraphqlService } from './funnel-content-generator-library-graphql.service';

const START_SEARCH_BASE_TIMEOUT_MS = 1500;
const SEARCH_OFFSET = 20;
const PAGE_DEFAULT_VALUE = 1;
const COLLECTION_INIT_VALUE: OutputDataGraphql[] = [];
const CATEGORY_FIELD_NAME = 'category.id';
const FUNNEL_ID_FIELD_NAME = 'funnel.id';

@Injectable({
  providedIn: 'root',
})
export class FunnelContentGeneratorLibraryService {
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  private readonly _moreContentLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly moreContentLoading$: Observable<boolean> = this._moreContentLoading$.asObservable();

  private readonly _hasMoreData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly hasMoreData$: Observable<boolean> = this._hasMoreData$.asObservable();

  private readonly _collection$: BehaviorSubject<OutputDataGraphql[]> = new BehaviorSubject<OutputDataGraphql[]>(
    COLLECTION_INIT_VALUE,
  );
  readonly collection$: Observable<OutputDataGraphql[]> = this._collection$.asObservable();

  private _form: UntypedFormGroup = this.initializeForm();
  private _page: number = PAGE_DEFAULT_VALUE;
  private _timeout!: NodeJS.Timeout;

  get form(): UntypedFormGroup {
    return this._form;
  }

  set setFunnelId(value: number) {
    this._form.get(ContentLibraryFormField.FUNNEL_ID)?.setValue(value, { emitEvent: false });
  }

  set setCollection(collection: OutputDataGraphql[]) {
    this._collection$.next(collection);
  }

  constructor(
    private readonly graphqlService: FunnelContentGeneratorLibraryGraphqlService,
    private readonly userService: UserService,
  ) {}

  private initializeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      [ContentLibraryFormField.FUNNEL_ID]: new UntypedFormControl(null, [Validators.required]),
      [ContentLibraryFormField.CATEGORIES]: new UntypedFormControl(),
      [ContentLibraryFormField.SEARCH]: new UntypedFormControl(),
    });
  }

  public prepareNewSearch(): void {
    this._page = PAGE_DEFAULT_VALUE;
    this.switchMainLoader(true);
    this.switchSecondaryLoader(false);
    this._hasMoreData$.next(true);
    this.setCollection = COLLECTION_INIT_VALUE;
  }

  public startNewSearch(skipTimeout = false): void {
    if (this._timeout) clearTimeout(this._timeout);
    const timeout: number = skipTimeout ? 0 : START_SEARCH_BASE_TIMEOUT_MS;
    this._timeout = setTimeout(() => this.performNewSearch(), timeout);
  }

  private performNewSearch(): void {
    this.prepareNewSearch();
    this.performSearch(this.switchMainLoader.bind(this));
  }

  private switchMainLoader(value: boolean): void {
    this._loading$.next(value);
  }

  private switchSecondaryLoader(value: boolean): void {
    this._moreContentLoading$.next(value);
  }

  public loadMoreContent(): void {
    this.performSearch(this.switchSecondaryLoader.bind(this));
  }

  private performSearch(loaderFunction: (param: boolean) => void): void {
    this.validateFunnelId();
    if (this.form.invalid) return;
    loaderFunction(true);
    const formData: OutputsDataSearchInputGraphql = this.collectFormData();
    this.graphqlService
      .fetchGeneratedContent(formData)
      .pipe(
        map((response: FetchResult<SearchContentOutputsDataQuery>) =>
          response.data ? (response.data.searchContentOutputsData.records as OutputDataGraphql[]) : [],
        ),
      )
      .subscribe({
        next: (data: OutputDataGraphql[]) => this.handleSearchResponse(data),
        complete: () => {
          this._page++;
          loaderFunction(false);
        },
      });
  }

  private handleSearchResponse(data: OutputDataGraphql[]): void {
    this._hasMoreData$.next(data.length < SEARCH_OFFSET);
    const newCollection: OutputDataGraphql[] = this._collection$.getValue().concat(data);
    this._collection$.next(newCollection);
  }

  private collectFormData(): OutputsDataSearchInputGraphql {
    return {
      page: this._page,
      search: this.form.get(ContentLibraryFormField.SEARCH)?.value,
      records: SEARCH_OFFSET,
      sortDirection: OrderTypes.Desc,
      sortField: 'id',
      filters: this.resolveFilters(),
    };
  }

  private resolveFilters(): ListFiltersFieldInput[] {
    const preparedFilters: ListFiltersFieldInput[] = [];

    // FUNNEL ID
    preparedFilters.push({
      field: FUNNEL_ID_FIELD_NAME,
      operator: FilterTypes.Eq,
      value: [String(this.form.get(ContentLibraryFormField.FUNNEL_ID)?.value)],
    });

    // CATEGORIES
    const filterCategories = this.form.get(ContentLibraryFormField.CATEGORIES)?.value;
    preparedFilters.push({
      field: CATEGORY_FIELD_NAME,
      operator: FilterTypes.In,
      value: filterCategories ? filterCategories.map((filter) => String(filter.id)) : [],
    });

    return preparedFilters;
  }

  private validateFunnelId(): void {
    const funnelId: number | undefined = this.userService.User?.contextFunnel?.id;
    if (funnelId) this.setFunnelId = funnelId;
  }

  public clearFilters(): void {
    Object.keys(ContentLibraryFormField).forEach((key) => {
      this.form.get(ContentLibraryFormField[key])?.setValue(null);
    });
  }

  public fetchCategories() {
    return this.graphqlService
      .fetchCategories()
      .pipe(
        map((response: FetchResult<GetIntegrationsCategoriesQuery>) =>
          response.data ? (response.data.getIntegrationsCategories as IntegrationCategoryOutputGraphql[]) : [],
        ),
      );
  }
}
