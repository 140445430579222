<div class="w-full">
  <df-tactic-settings-tab-title>
    <button
      *ngIf="metricsWithoutRemoved.length < tacticMetricsLimit"
      tuiButton
      appearance="accent"
      (click)="openTacticMetricModal()"
    >
      <svg-icon
        [src]="'/assets/img/ic_add_filled.svg'"
        class="mr-2"
      ></svg-icon>
      {{ 'Tactics.Settings.Metrics.Add metric' | translate }}
    </button>
  </df-tactic-settings-tab-title>

  <!-- list -->
  <ng-container *ngFor="let tacticMetric of metrics; let index = index">
    <tui-island
      class="py-3 mt-2"
      size="s"
      *ngIf="tacticMetric && tacticMetric.status !== ETacticMetricControlState.REMOVE"
    >
      <div class="metric w-full inline-grid grid-cols-3 justify-between items-center">
        <div>
          <p class="tui-text_body-stui-text-01 font-bold">
            {{ tacticMetric.output!.metric.name }}
          </p>
          <p class="tui-text_body-xs tui-text-05 font-semibold">
            {{ 'Tactics.Settings.Metrics.Measure' | translate }}:&nbsp;{{ tacticMetric.output!.measurementPeriod }}
          </p>
        </div>
        <div class="metric__cycle-measurement flex tui-text_body-xs tui-text-01 font-normal">
          <div
            class="inline-flex"
            *ngIf="tacticMetric.output!.isCyclical && tacticMetric.output!.cycleMeasurementPeriod"
          >
            <svg-icon
              class="metric__cycle-measurement__icon"
              [src]="'/assets/img/ic-time-schedule.svg'"
            ></svg-icon>
            &nbsp;
            {{ 'Tactics.Settings.Metrics.Cycle measurement' | translate }}:&nbsp;{{
              tacticMetric.output!.cycleMeasurementPeriod
            }}
          </div>
        </div>

        <!-- options -->
        <tui-hosted-dropdown
          class="options"
          tuiDropdownAlign="right"
          [content]="dropdown"
          tuiDropdownDirection="bottom"
        >
          <button
            tuiIconButton
            appearance="outline"
            size="m"
            type="button"
            icon="tuiIconMoreVertLarge"
            shape="square"
          ></button>
        </tui-hosted-dropdown>
        <ng-template #dropdown>
          <tui-data-list>
            <button
              tuiOption
              size="m"
              (click)="openTacticMetricModal(index, tacticMetric.output!.id)"
            >
              {{ 'edit' | translate }}
            </button>

            <button
              tuiOption
              size="m"
              class="text-red-500"
              (click)="remove(index)"
            >
              {{ 'delete' | translate }}
            </button>
          </tui-data-list>
        </ng-template>
      </div>
    </tui-island>
  </ng-container>
</div>
