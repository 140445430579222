<div
  class="flex flex-row flex-wrap md:flex-nowrap justify-between border border-solid border-gray-200 p-3 mb-1 rounded-lg"
  [class.tui-base-02]="disabled"
>
  <div class="block mr-2 md:mr-4 w-6 flex-shrink-0 flex-grow-0 flex flex-col justify-center items-center">
    <svg-icon
      [src]="Config.ASSETS + asset.asset.platform.image!"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      *ngIf="asset.asset.platform"
    ></svg-icon>
  </div>

  <div
    class="flex flex-col flex-grow justify-center"
    [class.opacity-50]="disabled"
  >
    <span class="tui-text_body-s-2 tui-text-01">{{ asset.asset.placement.name }}</span>
    <span class="tui-text_body-xs tui-text-01"
      ><ng-container *ngFor="let resolution of asset.selectedResolutions">
        {{ resolution.width }}x{{ resolution.height }}px,</ng-container
      >
      {{ asset.asset.fileType | or: asset.asset.headline | or: asset.asset.primaryText }}</span
    >
  </div>

  <div
    class="md:absolute md:right-3 md:top-3 md:pl-2 flex flex-row justify-end items-center flex-shrink-0 md:ml-2 w-full md:w-auto mt-2 md:mt-0 duration-200 buttons-hidden"
    [class.tui-base-02]="disabled"
    [class.tui-base-01-bg]="!disabled"
  >
    <ng-container *ngIf="!contentGenerateMode; else contentGenerate">
      <button
        *ngIf="editMode"
        (click)="disabled ? restoreAsset(asset) : removeAsset(asset)"
        class="mr-2 rounded-lg"
        tuiIconButton
        type="button"
        appearance="outline"
        [icon]="disabled ? iconRestore : iconTrash"
        size="s"
        shape="square"
        [disabled]="removeLoading"
        [showLoader]="removeLoading"
      ></button>
      <ng-template #iconTrash>
        <svg-icon
          class="tui-text-04"
          [svgStyle]="{ width: '1rem', height: '1rem' }"
          [src]="'/assets/img/_ic_trash.svg'"
        ></svg-icon>
      </ng-template>
      <ng-template #iconRestore
        ><svg-icon
          class="tui-text-04"
          [src]="'/assets/img/ic_refresh.svg'"
          [svgStyle]="{ width: '1rem', height: '1rem' }"
        ></svg-icon
      ></ng-template>
      <button
        *ngIf="!disabled"
        (click)="openAssetPreview()"
        tuiButton
        type="button"
        size="s"
        appearance="accent"
      >
        <span class="tui-text_body-m-2">{{ 'view' | translate }}</span>
      </button>
    </ng-container>
    <ng-template #contentGenerate>
      <df-asset-content-generated-badge
        [generated]="!!asset.integrationSelectedOutput"
        [integrationName]="'Funnels.Content generator.Integrations.' + OutputTypeEnum.Text | translate"
      ></df-asset-content-generated-badge>
    </ng-template>
  </div>
</div>
