import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TuiInputComponent } from '@taiga-ui/kit';

export const DEFAULT_STATEMENT_NUMBER_INPUT_CONFIGURATION = {
  step: 1,
  min: 0,
  max: 999999999,
  maxDigits: 9,
};

@Component({
  selector: 'df-statement-input',
  templateUrl: './statement-input.component.html',
  styleUrls: ['./statement-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementInputComponent extends AbstractSubscriptionComponent implements OnInit {
  @Input() form!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() name!: string;
  @Input() container!: HTMLDivElement;
  @Input() formSubmitted = false;
  @Input() isNumberInput = false;

  @ViewChild('input') input!: TuiInputComponent;
  @ViewChild('toCalcInput') toCalcInput!: ElementRef;

  readonly INPUT_CONFIGURATION = DEFAULT_STATEMENT_NUMBER_INPUT_CONFIGURATION;

  constructor(private changes: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.sub.add(
      this.form.get(this.controlName)?.statusChanges.subscribe({
        next: () => this.changes.detectChanges(),
      }),
    );
  }

  onNumberChange() {
    const value: number = this.form.get(this.controlName)?.value;
    if (value < this.INPUT_CONFIGURATION.min) {
      this.form.get(this.controlName)?.setValue(this.INPUT_CONFIGURATION.min);
    } else if (String(value).length > this.INPUT_CONFIGURATION.maxDigits) {
      this.form.get(this.controlName)?.setValue(String(value).slice(0, this.INPUT_CONFIGURATION.maxDigits));
    } else if (value > this.INPUT_CONFIGURATION.max) {
      this.form.get(this.controlName)?.setValue(this.INPUT_CONFIGURATION.max);
    }
    this.changes.detectChanges();
  }
}
