<df-full-list-loader *ngIf="validationLoading"></df-full-list-loader>
<div class="max-w-md px-4 md:p-6 mx-auto flex justify-center items-start flex-col py-10 min-h-screen">
  <img
    src="/assets/img/logo-with-appsumo.svg"
    alt="{{ Config.SYSTEM_NAME }}"
  />
  <h1 class="text-4xl font-semibold tui-text-03 mt-8 lg:mt-14 mb-6 lg:mb-10 leading-normal">
    {{ 'Auth.Register.welcome to' | translate }}&nbsp;<span class="tui-primary">{{ Config.SYSTEM_NAME }}</span>
  </h1>
  <form
    class="flex flex-col w-full"
    [formGroup]="formGroup"
    [validate-form]="formGroup"
    (submit)="registerFieldsHidden ? mergeAccount() : signUp()"
    *ngIf="!validationLoading"
  >
    <ng-template #success>
      <tui-svg
        class="tui-accent-07 tui-space_left-3"
        src="tuiIconCheckLarge"
      ></tui-svg>
    </ng-template>

    <div
      class="tui-form__row flex justify-between"
      *ngIf="!registerFieldsHidden"
    >
      <div class="flex-grow mr-2">
        <tui-input
          [tuiTextfieldCustomContent]="getControl('firstName')?.valid ? success : ''"
          formControlName="firstName"
        >
          {{ 'name' | translate }} *
        </tui-input>
        <df-error-input [control]="getControl('firstName')"></df-error-input>
      </div>

      <div class="flex-grow ml-2">
        <tui-input
          [tuiTextfieldCustomContent]="getControl('lastName')?.valid && getControl('lastName')?.touched ? success : ''"
          formControlName="lastName"
        >
          {{ 'last name' | translate }}
        </tui-input>
        <df-error-input [control]="getControl('lastName')"></df-error-input>
      </div>
    </div>

    <div class="tui-form__row mb-4">
      <tui-input
        [tuiTextfieldLabelOutside]="true"
        [readOnly]="true"
        [focusable]="false"
        formControlName="email"
      >
        {{ 'email' | translate }} *
      </tui-input>
    </div>

    <div class="tui-form__row">
      <tui-input-password
        [tuiTextfieldCustomContent]="getControl('password')?.valid ? success : ''"
        tuiHintContent
        tuiHintDirection="right"
        tuiHintMode="onDark"
        formControlName="password"
      >
        {{ 'password' | translate }} *
      </tui-input-password>
      <df-error-input [control]="getControl('password')"></df-error-input>
    </div>

    <div
      class="tui-form__row mt-4"
      *ngIf="!registerFieldsHidden"
    >
      <tui-checkbox-labeled
        size="l"
        class="inline-flex"
        formControlName="rulesAccepted"
      >
        <span
          [innerHTML]="
            'Auth.Register.I agree to Digital First Terms and Privacy Policy'
              | translate: { system: Config.SYSTEM_NAME, privacy: Config.PRIVACY_POLICY }
          "
        ></span>
      </tui-checkbox-labeled>
      <df-error-input [control]="getControl('rulesAccepted')"></df-error-input>
    </div>

    <div class="tui-form__row mt-4">
      <button
        size="xl"
        tuiButton
        type="submit"
        class="w-full block"
        appearance="primary"
        [showLoader]="loading"
        [disabled]="loading || !formGroup.valid"
      >
        {{ (registerFieldsHidden ? 'Auth.Login.Sign in' : 'Auth.Register.Create Account') | translate }}
      </button>
    </div>
  </form>
</div>
