<h2 class="tui-text_h5 tui-text-03 w-full">
  {{ 'Tactics.Asset preview' | translate }}
</h2>

<div class="flex flex-wrap lg:flex-nowrap justify-between items-stretch">
  <!--  <div class="w-full max-w-screen-md lg:w-3/6 flex-shrink-0" [ngClass]="{'': selectedCreation}">-->
  <div class="w-full">
    <p class="uppercase tui-text-05 tui-text_body-xs mt-5 mb-2 font-bold">
      {{ 'Tactics.Asset recommendation' | translate }}
    </p>

    <div class="flex flex-col items-stretch border border-solid border-gray-200 p-3 mb-1 rounded-lg">
      <div class="flex flex-row items-center w-full">
        <div class="block mr-2 md:mr-4 w-6 flex-shrink-0 flex-grow-0 flex flex-col justify-center items-center">
          <svg-icon
            [src]="Config.ASSETS + asset.asset.platform.image!"
            [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
            *ngIf="asset.asset.platform"
          ></svg-icon>
        </div>

        <div class="flex flex-col flex-grow">
          <span class="tui-text_body-s-2 tui-text-01">{{ asset.asset.placement.name }}</span>
          <span class="tui-text_body-xs tui-text-01"
            ><ng-container *ngFor="let resolution of asset.selectedResolutions">
              {{ resolution.width }}x{{ resolution.height }}px,</ng-container
            >
            {{ asset.asset.fileType | or: asset.asset.headline | or: asset.asset.primaryText }}</span
          >
        </div>
      </div>

      <div class="w-full pl-0 md:pl-10 mt-3 grid grid-cols-5 tui-text_body-s tui-text-01 gap-y-2 gap-x-1">
        <ng-container *ngFor="let property of asset.asset.properties">
          <div class="col-span-2 tui-text-02">{{ property.key }}</div>
          <div class="col-span-3">{{ property.value || '-' }}</div>
        </ng-container>
      </div>
    </div>
  </div>

  <!--  <div class="flex flex-col justify-start flex-grow lg:ml-6" [ngClass]="{'hidden lg:flex': !selectedCreation}">-->
  <!--    <p class="flex flex-col uppercase tui-text-05 tui-text_body-xs mt-5 mb-2 font-bold">-->
  <!--      {{'Tactics.Asset creation preview' | translate }}-->
  <!--    </p>-->
  <!--    <div class="flex flex-col flex-grow justify-center items-stretch tui-base-02 p-6 mb-1 rounded-lg">-->
  <!--      <img *ngIf="selectedCreation" [src]="Config.ASSETS + selectedCreation.file.file" class="w-full max-h-screen">-->
  <!--    </div>-->
  <!--  </div>-->
</div>
