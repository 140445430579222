import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Tactic } from '@shared/models/tactic.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { ClipboardService } from 'ngx-clipboard';
import { NavigateService } from '@core/routes/services/navigate.service';
import { UserService } from '@shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { FetchResult } from '@apollo/client/core';
import { ShareTacticMutation } from '@modules/tactics/shared/graphql/mutations/share-tactic.mutation.generated';
import { Regex } from '@shared/configs/regex';
import { TacticShareOutputGraphql } from '@modules/graphql/graphql-types';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TUI_ARROW } from '@taiga-ui/kit';
import { environment } from '@environments/environment';

@Component({
  selector: 'df-tactic-settings-share',
  templateUrl: './tactic-settings-share.component.html',
  styleUrls: ['./tactic-settings-share.component.scss'],
})
export class TacticSettingsShareComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  private destroyed = new Subscription();

  loading = false;
  invitedAgainSharesId: number[] = [];
  readonly APP_URL = environment.APP_URL;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    link: new UntypedFormControl(''),
    publiclyVisible: new UntypedFormControl(false),
  });

  emails: string[] = [];
  readonly arrow = TUI_ARROW;
  showSharedLink = true;

  constructor(
    private tacticGraphqlService: TacticGraphqlService,
    public tacticSettingsService: TacticSettingsService,
    private clipboardService: ClipboardService,
    private navigateService: NavigateService,
    private changes: ChangeDetectorRef,
    public userService: UserService,
    private t: TranslateService,
    private s: SnackbarService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.tacticSettingsService.tactic) {
      this.listenTactic();
    } else {
      this.setTacticData();
    }

    setTimeout(() => this.setPubliclyVisible(), 300);
  }

  setPubliclyVisible() {
    this.formGroup.get('publiclyVisible')?.setValue(!!this.tacticSettingsService.tactic?.publiclyVisible);
    this.showSharedLink = !!this.tacticSettingsService.tactic?.publiclyVisible;
    this.changes.detectChanges();
  }

  onPubliclyVisibleChange() {
    this.tacticGraphqlService
      .editTactic(
        {
          requiresBudget: this.tacticSettingsService.tactic?.requiresBudget,
          tacticDetails: this.tacticSettingsService.tactic?.tacticDetails,
          typeId: this.tacticSettingsService.tactic?.type?.id,
          levelOfAdvancementId: this.tacticSettingsService.tactic?.levelOfAdvancement?.id,
          badge: this.tacticSettingsService.tactic?.badge,
          name: this.tacticSettingsService.tactic?.name!,
          target: this.tacticSettingsService.tactic?.target!,
          categoriesIds: this.tacticSettingsService.tactic?.categories.map((i) => i.id)!,
          segmentsIds: this.tacticSettingsService.tactic?.segments.map((i) => i.id)!,
          effectsIds: this.tacticSettingsService.tactic?.effects.map((i) => i.id)!,
          funnelStepsIds: this.tacticSettingsService.tactic?.funnelSteps.map((i) => i.id)!,
          publiclyVisible: this.formGroup.get('publiclyVisible')?.value,
        },
        this.tacticSettingsService.tactic?.id!,
      )
      .subscribe();

    this.showSharedLink = this.formGroup.get('publiclyVisible')?.value;
  }

  onPublicLinkCopy(link: string) {
    this.clipboardService.copy(link);
    this.s.success(this.t.instant('Tactics.Preview.link copied'));
  }

  private listenTactic() {
    this.sub.add(
      this.tacticSettingsService.tactic$.subscribe(() => {
        this.setTacticData();
      }),
    );
  }

  private setTacticData() {
    this.setPreviewUrl();
  }

  inviteEmail(): void {
    if (!this.processMails()) return;
    if (this.tacticSettingsService.tactic) {
      this.loading = true;
      const sub = this.tacticGraphqlService
        .shareTactic(this.tacticSettingsService.tactic?.id, this.emails)
        .subscribe({
          next: (res: FetchResult<ShareTacticMutation>) => {
            this.tacticSettingsService.tactic = new Tactic(res.data?.shareTactic);
            this.changes.detectChanges();
          },
        })
        .add(() => {
          this.loading = false;
          this.changes.detectChanges();
        });
      if (this.emails.length !== 0) {
        this.s.success(this.t.instant('Tactics.Preview.Email sent'));
      } else {
        this.s.error(this.t.instant('Tactics.Preview.Enter a valid email'));
      }
      this.destroyed.add(sub);
    }
    this.formGroup.get('email')?.reset();
  }

  processMails(): boolean {
    if (this.formGroup.get('email')?.value) {
      let separatedEmails = this.formGroup.get('email')?.value;
      separatedEmails = separatedEmails
        .split(',')
        .map((email) => email.trim())
        .filter((string) => string !== '');
      const notValidEmails: string[] = [];
      separatedEmails.forEach((email) => {
        if (Regex.email.test(email)) {
          this.emails.push(email.toLowerCase());
        } else {
          notValidEmails.push(email);
        }
      });
      if (notValidEmails.length) {
        this.s.error(
          this.t.instant('Tactics.Preview.not valid email', {
            emails: notValidEmails.join(', '),
          }),
        );
        return false;
      }
    }
    this.emails = this.emails.filter((email, index) => this.emails.indexOf(email) == index);
    return true;
  }

  onNextInvite(mail: TacticShareOutputGraphql): void {
    this.invitedAgainSharesId.push(mail.id);
    this.tacticGraphqlService.shareTactic(this.tacticSettingsService.tactic!.id, [mail.email]).toPromise();
    this.s.success(this.t.instant('Tactics.Preview.Tactic shared again successfully'));
  }

  onRemove(email: TacticShareOutputGraphql): void {
    this.tacticSettingsService.tactic!.shares = this.tacticSettingsService.tactic!.shares.filter(
      (mail) => mail.id !== email.id,
    );
    this.emails = this.emails.filter((mail) => mail !== email.email);
    this.changes.detectChanges();
    this.tacticGraphqlService.removeShareTactic(email.id).toPromise();
  }

  onCopy(): void {
    this.clipboardService.copy(this.formGroup.get('link')?.value);
    this.s.success(this.t.instant('Tactics.Preview.link copied'));
  }

  setPreviewUrl(): void {
    if (this.tacticSettingsService.tactic) {
      this.formGroup
        .get('link')
        ?.setValue(
          `${window.location.origin}${this.navigateService.getPath('tactics/preview/p/:id', {}, { id: this.tacticSettingsService.tactic.id })}`,
        );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroyed.unsubscribe();
  }
}
