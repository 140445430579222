<div class="flex flex-col">
  <button class="self-end">
    <svg-icon
      src="/assets/img/ic-close.svg"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      (click)="close()"
    ></svg-icon>
  </button>

  <h2 class="text-[1.5rem] mb-8 font-semibold w-4/5 leading-8">Share your Flow</h2>

  <div class="w-full">
    <div class="flex items-center gap-4 mb-8">
      <tui-input
        [tuiTextfieldLabelOutside]="true"
        [readOnly]="true"
        class="grow"
      >
        <input
          class="bg-[--tui-accent-05-lighter] text-ellipsis"
          tuiTextfield
          [(ngModel)]="tiptapShareUrl"
        />
      </tui-input>
      <button
        class="w-[20px] h-[20px]"
        (click)="copyToClipboard()"
      >
        <svg-icon [src]="'/assets/img/prompt-copy.svg'"></svg-icon>
      </button>
    </div>

    <tui-checkbox-labeled
      size="l"
      class="inline-flex mb-8"
      [(ngModel)]="passwordEnabled"
    >
      Protect your Flow with a password
    </tui-checkbox-labeled>

    <ng-container *ngIf="passwordEnabled">
      <div class="w-full mb-1">Password</div>
      <tui-input
        [tuiTextfieldLabelOutside]="true"
        [formControl]="password"
        class="mb-8"
      >
        <input
          tuiTextfield
          type="password"
        />
      </tui-input>
    </ng-container>

    <button
      (click)="submit()"
      tuiButton
      class="w-[100%]"
      [disabled]="password.invalid"
    >
      <span class="text-[1.5rem]"> Save </span>
    </button>
  </div>
</div>
