import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { TuiDialogService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT, PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ThankYouForgotComponent } from '@modules/authorization/pages/thank-you-forgot/thank-you-forgot.component';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialog } from '@taiga-ui/cdk';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';

@Component({
  selector: 'df-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit {
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.email)]),
  });
  loading = false;

  constructor(
    private authGraphqlService: AuthGraphqlService,
    private changes: ChangeDetectorRef,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { email: string } }, boolean>,
    private t: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.context.data.email) {
      this.formGroup.get('email')?.setValue(this.context.data.email);
    }
  }

  submit() {
    if (!this.formGroup.valid) return;
    this.loading = true;
    this.changes.detectChanges();
    this.authGraphqlService
      .requestPasswordReset(this.formGroup.get('email')?.value)
      .subscribe({
        next: this.success.bind(this),
        error: this.success.bind(this),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  success() {
    this.context.completeWith(true);
    this.changes.detectChanges();
    this.dialogService
      .open<number>(new PolymorpheusComponent(ThankYouForgotComponent, this.injector), {
        dismissible: true,
        label: this.t.instant('Auth.Forgot Password.Thank you!'),
      })
      .subscribe();
  }
}
