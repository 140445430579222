import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';

@Component({
  selector: 'df-funnel-tactic-onboarding-placeholder',
  templateUrl: './funnel-tactic-onboarding-placeholder.component.html',
  styleUrls: ['./funnel-tactic-onboarding-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelTacticOnboardingPlaceholderComponent implements AfterViewInit {
  constructor(
    private readonly el: ElementRef,
    private readonly funnelManageService: FunnelManageService,
    private readonly changes: ChangeDetectorRef,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.setHeight();
  }

  private setHeight(): void {
    const funnelTactic: FunnelTactic | undefined = this.funnelManageService.funnel?.tactics.find(
      (t) => t.onboardingActionTactic,
    );
    if (!funnelTactic) return;

    const tile: HTMLElement = document.querySelector(`[dataFunnelTacticId="${funnelTactic.id.toString()}"]`)!;
    this.renderer.setStyle(this.el.nativeElement, 'height', tile.offsetHeight + 4 + 'px');
  }
}
