import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Tactic } from '@shared/models/tactic.model';
import { Activity } from '@shared/models/activity.model';

@Injectable({
  providedIn: 'root',
})
export class PaidTacticValidationService {
  get tacticValidationForm(): UntypedFormGroup {
    return this._tacticValidationForm;
  }

  private _tacticValidationForm: UntypedFormGroup = this.getTacticValidationForm();

  validateTactic(tactic: Tactic, withActivities = true): void {
    this._tacticValidationForm.reset();
    this.tacticValidationForm.patchValue(tactic);
    if (withActivities) {
      this.tacticValidationForm.get(PaidTacticConditions.activities)?.enable();
      if (this.tacticValidationForm.get(PaidTacticConditions.activities)?.valid) {
        if (!this.validateActivities(tactic.activities)) {
          this.tacticValidationForm.get(PaidTacticConditions.activities)?.setErrors({ required: true });
        }
      }
    } else {
      this.tacticValidationForm.get(PaidTacticConditions.activities)?.disable();
    }
  }

  validateActivities(activities: Activity[]): boolean {
    return activities.some((activity) => this.getActivityValidationForm(activity).valid);
  }

  private getTacticValidationForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      [PaidTacticConditions.funnelStep]: new UntypedFormControl(null, [Validators.required, Validators.minLength(1)]),
      [PaidTacticConditions.category]: new UntypedFormControl(null, [Validators.required, Validators.minLength(1)]),
      [PaidTacticConditions.budget]: new UntypedFormControl(null, [Validators.required]),
      [PaidTacticConditions.levelOfAdvancement]: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
      [PaidTacticConditions.segment]: new UntypedFormControl(null, [Validators.required, Validators.minLength(1)]),
      [PaidTacticConditions.effect]: new UntypedFormControl(null, [Validators.required, Validators.minLength(1)]),
      [PaidTacticConditions.name]: new UntypedFormControl(null, [Validators.required, Validators.minLength(5)]),
      [PaidTacticConditions.description]: new UntypedFormControl(null, [
        Validators.required,
        minLengthHTMLValidator(20),
      ]),
      [PaidTacticConditions.activities]: new UntypedFormControl(null, [Validators.required, Validators.minLength(1)]),
    });
  }

  private getActivityValidationForm(activity: Activity): UntypedFormGroup {
    return new UntypedFormGroup({
      [PaidTacticActivityConditions.name]: new UntypedFormControl(activity[PaidTacticActivityConditions.name], [
        Validators.required,
        Validators.minLength(5),
      ]),
      [PaidTacticActivityConditions.type]: new UntypedFormControl(activity[PaidTacticActivityConditions.type], [
        Validators.required,
      ]),
    });
  }
}

export enum PaidTacticConditions {
  funnelStep = 'funnelSteps',
  category = 'categories',
  budget = 'requiresBudget',
  levelOfAdvancement = 'levelOfAdvancement',
  segment = 'segments',
  effect = 'effects',
  name = 'name',
  description = 'tacticDetails',
  activities = 'activities',
}

export enum PaidTacticActivityConditions {
  name = 'name',
  type = 'type',
}

export function minLengthHTMLValidator(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const plainText = control.value ? control.value.replace(/<[^>]*>/g, '') : '';
    return plainText.length < min ? { minlength: { requiredLength: min, actualLength: plainText.length } } : null;
  };
}
