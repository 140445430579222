<tui-island
  [size]="'s'"
  class="h-full recommendations-island relative px-6 py-8 overflow-hidden"
>
  <img
    src="assets/img/overview-content-generator.png"
    alt=""
    class="hidden sm:block object-contain max-h-full absolute bottom-0 -right-96 lg:-right-32 xl:right-0"
  />

  <div class="relative h-full flex flex-col justify-between items-start sm:w-2/3">
    <h2
      class="tui-text_h2 tui-text-03 font-semibold"
      [innerHTML]="'Funnels.Overview.Content generator' | translate"
    ></h2>
    <p
      class="tui-text_body-l-2 tui-text-05 text-left"
      [innerHTML]="'Funnels.Overview.Generate content for your tactic base on statement and canvases' | translate"
    ></p>
    <button
      tuiButton
      type="button"
      [size]="'l'"
      class="w-auto mt-4"
      appearance="primary"
      routerLink="../content-generator"
    >
      {{ 'Funnels.Overview.Start now' | translate }}
      <tui-svg
        class="tui-space_left-3"
        src="tuiIconArrowRight"
      ></tui-svg>
    </button>
  </div>
</tui-island>
