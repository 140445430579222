import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { CheckCompanyDataExistDocument } from './graphql/check-company-data-exist.mutation';
import { CheckCompanyDataExistMutation } from './graphql/check-company-data-exist.mutation.generated';
import { SetCompanyDataDocument } from './graphql/set-company-data.mutation';
import { SetCompanyDataMutation } from './graphql/set-company-data.mutation.generated';
import { GetCompanyDataDocument } from './graphql/get-company-data.query';
import { GetCompanyDataQuery } from './graphql/get-company-data.query.generated';
import { PerformPromptBaseOnCompanyDataDocument } from './graphql/perform-prompt-base-on-company-data.mutation';
import { PerformPromptBaseOnCompanyDataMutation } from './graphql/perform-prompt-base-on-company-data.mutation.generated';
import { InputsInputGraphql } from '@modules/graphql/graphql-types';
import { SaveInputDataDocument } from './graphql/save-input-data.mutation';
import { SaveInputDataMutation } from './graphql/save-input-data.mutation.generated';
import { GetInputDataQuery } from './graphql/get-input-data.query.generated';
import { GetInputDataDocument } from './graphql/get-input-data.query';
import { CheckResearchIsReadyQuery } from '@modules/business-data/graphql/check-research-is-ready.query.generated';
import { CheckResearchIsReadyDocument } from '@modules/business-data/graphql/check-research-is-ready.query';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BusinessDataService {
  constructor(private apollo: Apollo) {}

  setCompanyData(businessName: string, url: string, funnelId: number) {
    return this.apollo.mutate<SetCompanyDataMutation>({
      mutation: SetCompanyDataDocument,
      variables: { input: { businessName, url, funnelId } },
      fetchPolicy: 'no-cache',
    });
  }

  getCompanyData(funnelId: number) {
    return this.apollo.query<GetCompanyDataQuery>({
      query: GetCompanyDataDocument,
      variables: { funnelId },
      fetchPolicy: 'no-cache',
    });
  }

  checkCompanyDataExist(funnelId: number) {
    return this.apollo.mutate<CheckCompanyDataExistMutation>({
      mutation: CheckCompanyDataExistDocument,
      variables: { funnelId },
      fetchPolicy: 'no-cache',
    });
  }

  performPromptBaseOnCompanyData(promptId: number, activityId: number, funnelTacticId: number, funnelId: number) {
    return this.apollo.mutate<PerformPromptBaseOnCompanyDataMutation>({
      mutation: PerformPromptBaseOnCompanyDataDocument,
      variables: { input: { promptId, funnelId, activityId, funnelTacticId } },
      fetchPolicy: 'no-cache',
    });
  }

  setUserInputData(input: InputsInputGraphql) {
    return this.apollo.mutate<SaveInputDataMutation>({
      mutation: SaveInputDataDocument,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }

  getInputData(funnelId: number) {
    return this.apollo
      .query<GetInputDataQuery>({
        query: GetInputDataDocument,
        variables: { funnelId },
        fetchPolicy: 'no-cache',
      })
      .pipe(catchError(() => of(null)));
  }

  checkCanvasIsGenerated(funnelId: number): Observable<boolean> {
    return this.apollo
      .query<CheckResearchIsReadyQuery>({
        query: CheckResearchIsReadyDocument,
        variables: { funnelId },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data.checkResearchIsReady));
  }
}
