import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Funnel } from '@shared/models/funnel.model';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { FunnelsListService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-list.service';
import { ListEventType } from '@shared/services/base-list.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { BasePanelEvent, BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { FunnelController } from '@modules/funnels/shared/controllers/funnel.controller';
import { UserService } from '@shared/services/user.service';
import { FunnelPermissionEnum } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-funnel-tile-actions',
  templateUrl: './funnel-tile-actions.component.html',
  styleUrls: ['./funnel-tile-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelTileActionsComponent implements OnInit {
  PermissionsGroups = PermissionsGroups;
  removeLoading = false;
  copyLoading = false;
  FunnelPermissionEnum = FunnelPermissionEnum;
  funnelsCount = 1;

  funnelController: FunnelController = new FunnelController();
  @Input() funnel!: Funnel;

  constructor(
    private n: NavigateService,
    private funnelGraphqlService: FunnelGraphqlService,
    private funnelsListService: FunnelsListService,
    private s: SnackbarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private basePanelService: BasePanelService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.funnelsCount = this.userService.User?.funnelsCount ?? 0;
  }

  remove() {
    this.removeLoading = true;
    this.funnelGraphqlService.removeFunnel(this.funnel!.id).subscribe(() => {
      this.basePanelService.emitter.emit(BasePanelEvent.RELOAD_FUNNELS_TO_CONTEXT);
      const user = this.userService.User!;
      user.funnelsCount--;

      this.funnelsCount = user.funnelsCount;
      this.userService.User = user;
      this.basePanelService.emitter.emit(BasePanelEvent.RELOAD_FUNNELS_TO_CONTEXT);
    });
    this.s.success(this.t.instant('Funnels.List.Funnel removed successfully'));
    this.funnelsListService.records = this.funnelsListService.records.filter((f) => f.id !== this.funnel!.id);
    this.funnelsListService.emitter.emit({
      type: ListEventType.END_GETTING_RECORDS,
    });
  }

  copy() {
    this.copyLoading = true;
    this.changes.detectChanges();
    this.funnelController
      .copyFunnel(this.funnel!)
      .subscribe({
        next: () => {
          this.basePanelService.emitter.emit(BasePanelEvent.RELOAD_FUNNELS_TO_CONTEXT);
        },
        error: () => {
          this.s.defaultError();
        },
      })
      .add(() => {
        this.copyLoading = false;
        this.changes.detectChanges();
      });
  }

  preview() {
    this.n.go('funnels/f/d/:id', { id: this.funnel.id });
  }
}
