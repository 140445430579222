import { Component, OnInit, ChangeDetectionStrategy, Injector, Inject } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { FunnelStatisticsAddNewReportFormComponent } from '../funnel-statistics-add-new-report-from/funnel-statistics-add-new-report-form.component';
import { DashboardTypeEnum } from '@modules/graphql/graphql-types';
import { CREATE_REPORT_STEPS } from '../../models/funnel-statistics.model';
import { StatisticReport } from '../../models/funnel-statistics.interface';

@Component({
  selector: 'df-funnel-statistics-add-new-report-intro',
  templateUrl: './funnel-statistics-add-new-report-intro.component.html',
  styleUrls: ['./funnel-statistics-add-new-report-intro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelStatisticsAddNewReportIntroComponent implements OnInit {
  CREATE_REPORT_STEPS = CREATE_REPORT_STEPS;

  actionTiles: StatisticReport[] = [];

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.actionTiles = [
      {
        name: this.translateService.instant('Funnels.Statistics.General'),
        type: DashboardTypeEnum.General,
      },
      {
        name: DashboardTypeEnum.Facebook.toString(),
        type: DashboardTypeEnum.Facebook,
      },
      {
        name: DashboardTypeEnum.Instagram.toString(),
        type: DashboardTypeEnum.Instagram,
      },
      {
        name: DashboardTypeEnum.Google.toString(),
        type: DashboardTypeEnum.Google,
      },
    ];
  }

  public onOpenForm(report: StatisticReport): void {
    this.openDialog(report.type);
  }

  private openDialog(reportType: DashboardTypeEnum | null | undefined): void {
    this.dialogService
      .open<number>(new PolymorpheusComponent(FunnelStatisticsAddNewReportFormComponent, this.injector), {
        size: 's',
        dismissible: false,
        closeable: true,
        label: this.translateService.instant('Funnels.Statistics.New Report'),
        data: {
          reportType: reportType,
        },
      })
      .subscribe();
  }
}
