import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Permission } from '@shared/models/permission.model';
import { SubscriptionPlanOutputGraphql } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-packets-permission-limit',
  templateUrl: './packets-permission-limit.component.html',
  styleUrls: ['./packets-permission-limit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PacketsPermissionLimitComponent {
  @Input() pricePermission!: Permission;
  @Input() plan!: SubscriptionPlanOutputGraphql;
}
