import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { Apollo } from 'apollo-angular';
import { LoginAsUserDocument } from './login-as-user.mutation';
import { catchError } from 'rxjs';
import { SnackbarService } from '@core/services/snackbar.service';
import { LoginAsUserMutation } from './login-as-user.mutation.generated';
import { AuthController } from '@modules/authorization/shared/controllers/auth.controller';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthOutputGraphql } from '@modules/graphql/graphql-types';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-login-as-user',
  templateUrl: './login-as-user.component.html',
  styles: [''],
})
export class LoginAsUserComponent {
  readonly form = new FormGroup({
    email: new FormControl('', [Validators.pattern(Regex.email), Validators.required]),
  });
  authController: AuthController;

  constructor(
    private apollo: Apollo,
    private s: SnackbarService,
    private recaptchaService: ReCaptchaV3Service,
    private n: NavigateService,
  ) {
    this.authController = new AuthController(this.recaptchaService);
  }

  getControl() {
    return this.form.get('email');
  }

  onSubmit() {
    if (this.form.valid) {
      const email = this.form.value.email;
      this.apollo
        .mutate<LoginAsUserMutation>({
          mutation: LoginAsUserDocument,
          variables: {
            email,
          },
        })
        .pipe(
          catchError((err) => {
            this.s.error(err.message);
            return err;
          }),
        )
        .subscribe((res: any) => {
          if (res.data?.loginAsUser) {
            this.authController.saveAuthData(res.data?.loginAsUser as AuthOutputGraphql);
            this.n.go('/home');
          }
        });
    }
  }
}
