import { AbstractOnboardingFactory } from '@shared/abstracts/onboarding-factory.abstract';
import { OnboardingFullscreenFocus } from '@shared/models/onboarding-step.model';

export class OnboardingFullscreenFocusFactory extends AbstractOnboardingFactory<OnboardingFullscreenFocus> {
  renderElement(element: OnboardingFullscreenFocus): Element {
    const container: HTMLElement = document.createElement('div');
    container.classList.add('onboarding-focus-fullscreen-container');
    this.container.prepend(container);
    const fullscreen: HTMLElement = this.anchor.cloneNode(true) as HTMLElement;
    fullscreen.querySelector('.onboarding-action')?.addEventListener('click', () => element.action(fullscreen));
    fullscreen.classList.add('onboarding-focus-fullscreen');
    fullscreen.style.width = this.anchorRect.width + 'px';
    const scale = Math.min(
      (window.innerWidth * 0.8) / this.anchorRect.width,
      (window.innerHeight * 0.7) / this.anchorRect.height,
    );
    const extra = fullscreen.querySelector('.onboarding-focus-fullscreen-extra') as HTMLElement;
    // extra.style.transform = `scale(${1 / scale})`;
    container.appendChild(fullscreen);
    let translateX =
      this.anchorRect.x +
      this.anchorRect.width / 2 -
      (fullscreen.getBoundingClientRect().x + fullscreen.getBoundingClientRect().width / 2);
    let translateY =
      this.anchorRect.y +
      this.anchorRect.height / 2 -
      (fullscreen.getBoundingClientRect().y + fullscreen.getBoundingClientRect().height / 2);
    fullscreen.style.top = translateY + 'px';
    translateX *= -1 / scale;
    translateY *= -1 / scale;

    if (fullscreen.classList.contains('onboarding-temp-anchor')) {
      fullscreen.style.height = 'max-content';
      fullscreen.style.maxWidth = '90vw';
    } else {
      fullscreen.style.height = this.anchorRect.height + 'px';
      fullscreen.style.left = translateX + 'px';
    }

    // fullscreen.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
    setTimeout(() => {
      const helpMarks = fullscreen.getElementsByClassName('onboarding-help-mark');
      for (let i = 0; i < helpMarks.length; i++) {
        (helpMarks.item(i) as HTMLElement).style.transform = `scale(${1 / scale})`;
      }
    }, 200);

    extra.style.width = fullscreen.getBoundingClientRect().width + 'px';
    fullscreen.style.transitionDuration = '300ms';
    fullscreen.style.opacity = '1';
    return container;
  }
}
