import { ChangeDetectorRef, Component, OnDestroy, AfterContentInit, Input } from '@angular/core';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { TuiIdentityMatcher } from '@taiga-ui/cdk';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Subscription } from 'rxjs';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ListEvent, ListEventType } from '@shared/services/base-list.service';
import { UsersListService } from '@modules/users/modules/users-list/shared/services/users-list.service';
import { FilterTypeService } from '@shared/services/filter-type.service';
import { UsersFiltersService } from '@modules/users/modules/users-list/shared/services/users-filters.service';

@Component({
  selector: 'df-tags-list-filter',
  templateUrl: './tags-list-filter.component.html',
  styleUrls: ['./tags-list-filter.component.scss'],
})
export class TagsListFilterComponent implements AfterContentInit, OnDestroy {
  @Input() filtersService!: TacticsFiltersService | UsersFiltersService;
  listService!: TacticsListService | UsersListService;

  sub: Subscription = new Subscription();

  readonly identityMatcher: TuiIdentityMatcher<{ id: number; name: string }> = (obj1, obj2) => obj1.id === obj2.id;

  get categories(): IFilter<any>[] | undefined {
    return this.tacticFiltersService.filterFormGroup.get('categories')?.value;
  }

  constructor(
    public tacticFiltersService: TacticsFiltersService,
    public tacticsListService: TacticsListService,
    private usersListService: UsersListService,
    private filterTypeService: FilterTypeService,
    private changes: ChangeDetectorRef,
    private router: Router,
  ) {}

  listenReadUrlParams() {
    const sub = this.listService.emitter.subscribe((event: ListEvent) => {
      if (event.type === ListEventType.READ_URL_PARAMS) {
        this.changes.detectChanges();
      }
    });
    this.sub.add(sub);
  }

  listenRouteChange() {
    const sub = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.changes.detectChanges());
    this.sub.add(sub);
  }

  listenFormChanges() {
    this.sub.add(this.filtersService.filterFormGroup.valueChanges.subscribe(() => this.changes.detectChanges()));
  }

  ngAfterContentInit() {
    const set = this.filterTypeService.setServices(this.filtersService);
    this.listService = set.listService;
    this.filtersService = set.filtersService;

    // when this.listService is set
    this.listenReadUrlParams();
    this.listenRouteChange();
    this.listenFormChanges();
  }

  removeCategory(id: number, e) {
    e.preventDefault();
    e.stopPropagation();
    let categories = this.categories;
    categories = categories?.filter((c) => c.id !== id);
    this.tacticFiltersService.filterFormGroup.get('categories')?.setValue(categories);
    this.changes.detectChanges();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
