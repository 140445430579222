interface CallbackParams {
  base64: string | null;
  width: number;
  height: number;
}

export function convertImageToBase64(imgUrl: string, callback: (data: CallbackParams) => void) {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    ctx?.drawImage(image, 0, 0);
    const dataUrl = canvas.toDataURL();
    callback &&
      callback({
        base64: dataUrl,
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
  };
  image.src = imgUrl;
}
