import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import {
  TacticsListEventType,
  TacticsListService,
} from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { TiptapMode } from '@modules/tiptap/tiptap-mode.enum';

@Component({
  selector: 'df-tiptap',
  templateUrl: './tiptap.component.html',
})
export class TiptapComponent implements OnInit {
  funnelId!: number;
  docName!: string;
  loading = false;
  mode!: TiptapMode;
  tiptapShareUrl!: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly n: NavigateService,
    private readonly funnelGraphqlService: FunnelGraphqlService,
    private readonly tacticsListService: TacticsListService,
  ) {}

  ngOnInit() {
    this.funnelId = Number(this.route?.parent?.snapshot.params['id']);
    this.docName = this.route?.parent?.snapshot.queryParams['docName'];
    this.mode = this.route?.parent?.snapshot.queryParams['mode'] as TiptapMode;
  }

  onNextButtonClick() {
    this.loading = true;

    this.funnelGraphqlService.createTactics(this.funnelId, this.docName).subscribe(() => {
      this.tacticsListService.tacticsListEmitter.emit({
        type: TacticsListEventType.RELOAD_FUNNEL,
      });

      this.n.go(
        `/funnels/f/d/${this.funnelId}/master-visuals`,
        { id: this.funnelId },
        { queryParams: { docName: this.docName } },
      );
    });
  }
}
