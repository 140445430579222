export enum GraphqlErrorEnum {
  NOT_FOUND = 1,
  ALREADY_EXISTS = 2,
  RULES_NOT_ACCEPTED = 3,
  INVALID_CREDENTIALS = 4,
  INVALID_TOKEN = 5,
  ITEM_NOT_EXISTS = 6,
  ITEM_ALREADY_EXISTS = 7,
  INVALID_PASSWORD = 9,
  NO_TEAM = 25,
  VALIDATION_ERROR = 600,
  FORBIDDEN = 700,
  NOT_ALLOWED = 701,
  ACCOUNT_BLOCKED = 702,
  TRIAL_ENDED = 800,
  NO_PERMISSION_PAYMENT = 801,
  NO_ENOUGH_POINTS = 24,
  NO_ACTIVE_SUBSCRIPTION = 27,
}
