<df-tactic-settings-tab-title>
  <button
    tuiButton
    appearance="accent"
    (click)="addActivity()"
  >
    {{ 'Tactics.Settings.New activity' | translate }}
  </button>
</df-tactic-settings-tab-title>

<div class="w-full">
  <div
    cdkDropList
    class="w-full"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      *ngFor="let activity of activitiesArray.controls; let i = index"
      cdkDrag
      [cdkDragDisabled]="isActivityToRemove(activity)"
      class="flex items-center mt-3"
    >
      <svg-icon
        [src]="'/assets/img/ic-move.svg'"
        [class.cursor-move]="!isActivityToRemove(activity)"
        (click)="$event.stopPropagation(); $event.preventDefault()"
        class="mr-3"
      ></svg-icon>
      <df-tactic-settings-activity
        [index]="i + 1"
        [activity]="getActivityFormGroup(activity)"
        [class.to-remove]="isActivityToRemove(activity)"
        [disabled]="isActivityToRemove(activity)"
      ></df-tactic-settings-activity>
    </div>
  </div>
  <div>
    <button
      size="m"
      tuiButton
      type="button"
      class="block w-full bg-white mt-3"
      appearance="outline"
      [icon]="iconAdd"
      (click)="addActivity()"
    ></button>
    <ng-template #iconAdd>
      <svg-icon
        [src]="'/assets/img/ic-add.svg'"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
      ></svg-icon>
    </ng-template>
  </div>
</div>
