<div class="funnel-tile-actions w-full flex flex-row items-center justify-between relative">
  <button
    data-test="delete-funnel-button"
    (click)="$event.stopPropagation()"
    *ngIf="funnel?.currentUserPermission === FunnelPermissionEnum.Editor && funnelsCount > 1"
    class="tui-accent"
    tuiIconButton
    type="button"
    mwlConfirmationPopover
    [popoverTitle]="'Funnels.List.Removing funnel' | translate"
    [popoverMessage]="'Funnels.List.Do you really want to remove your funnel? You will loose all data.' | translate"
    [appendToBody]="true"
    placement="right"
    (confirm)="remove()"
    appearance="secondary"
    [showLoader]="removeLoading"
    [disabled]="removeLoading"
    [icon]="iconTrash"
  ></button>
  <ng-template #iconTrash>
    <svg-icon [src]="'/assets/img/ic-trash.svg'"></svg-icon>
  </ng-template>
  <div class="flex items-center">
    <button
      data-test="copy-funnel-button"
      (click)="$event.stopPropagation(); copy()"
      tuiIconButton
      type="button"
      appearance="secondary"
      [showLoader]="copyLoading"
      [disabled]="copyLoading"
      [icon]="iconCopy"
    ></button>
    <ng-template #iconCopy>
      <svg-icon [src]="'/assets/img/ic_copy.svg'"></svg-icon>
    </ng-template>
    <button
      data-test="info-funnel-button"
      class="ml-2"
      (click)="$event.stopPropagation(); preview()"
      tuiIconButton
      type="button"
      appearance="secondary"
      [icon]="iconPreview"
    ></button>
    <ng-template #iconPreview>
      <svg-icon [src]="'/assets/img/ic-tooltip.svg'"></svg-icon>
    </ng-template>
  </div>
</div>
