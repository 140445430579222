import { NgModule } from '@angular/core';
import { PricingPanelComponent } from './pages/pricing-panel/pricing-panel.component';
import { PacketsComponent } from './pages/packets/packets.component';
import { SharedModule } from '@shared/shared.module';
import { PacketsPermissionComponent } from './pages/packets/components/packets-permission/packets-permission.component';
import { PacketsPermissionLimitComponent } from './pages/packets/components/packets-permission/components/packets-permission-limit/packets-permission-limit.component';
import { LightPaywallModalComponent } from './shared/components/light-paywall-modal/light-paywall-modal.component';
import { PricingPromotionCodeModalComponent } from './shared/components/pricing-promotion-code-modal/pricing-promotion-code-modal.component';
import { NotEnoughCreditsModalComponent } from './shared/components/not-enough-credits-modal/not-enough-credits-modal.component';
import { ConfirmStartTrialModalComponent } from './shared/components/confirm-start-trial-modal/confirm-start-trial-modal.component';
import { StartTrialModalComponent } from './shared/components/start-trial-modal/start-trial-modal.component';
import { PricingPeriodSwitchComponent } from './pages/packets/components/pricing-period-switch/pricing-period-switch.component';
import { PacketsCampaignComponent } from './pages/packets-campaign/packets-campaign.component';
import { NotEnoughCreditsSmallModalComponent } from './shared/components/not-enough-credits-small-modal/not-enough-credits-small-modal.component';

@NgModule({
  declarations: [
    PricingPanelComponent,
    PacketsComponent,
    PacketsPermissionComponent,
    PacketsPermissionLimitComponent,
    LightPaywallModalComponent,
    PricingPromotionCodeModalComponent,
    NotEnoughCreditsModalComponent,
    NotEnoughCreditsSmallModalComponent,
    ConfirmStartTrialModalComponent,
    StartTrialModalComponent,
    PricingPeriodSwitchComponent,
    PacketsCampaignComponent,
  ],
  imports: [SharedModule],
  exports: [PacketsComponent, PacketsCampaignComponent, LightPaywallModalComponent],
})
export class PricingModule {}
