import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '@shared/services/user.service';
import { TuiDialog } from '@taiga-ui/cdk';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { FunnelStatisticsService } from '../../services/funnel-statistics.service';
import { whatagraphUrlValidator } from '../../validatiors/funnel-statistics.validator';

export enum ReportFormField {
  reportName = 'reportName',
  reportType = 'reportType',
  reportUrl = 'reportUrl',
  funnelId = 'funnelId',
}

@Component({
  selector: 'df-funnel-statistics-add-new-report-form',
  templateUrl: './funnel-statistics-add-new-report-form.component.html',
  styleUrls: ['./funnel-statistics-add-new-report-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelStatisticsAddNewReportFormComponent implements OnInit {
  ReportFormField = ReportFormField;

  formSubmitted = false;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    [ReportFormField.funnelId]: new UntypedFormControl(null),
    [ReportFormField.reportName]: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60),
    ]),
    [ReportFormField.reportType]: new UntypedFormControl(null),
    [ReportFormField.reportUrl]: new UntypedFormControl(null, [Validators.required, whatagraphUrlValidator()]),
  });

  constructor(
    private readonly changes: ChangeDetectorRef,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { reportType: string } }, boolean>,
    public funnelStatisticsService: FunnelStatisticsService,
    private readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    if (this.context.data?.reportType)
      this.formGroup.get(ReportFormField.reportType)?.setValue(this.context.data?.reportType);
    this.formGroup.get(ReportFormField.funnelId)?.setValue(this.userService.User?.contextFunnel.id);
  }

  public onSubmitForm(): void {
    this.formSubmitted = true;
    this.changes.detectChanges();
    if (this.formGroup.invalid) return;
    this.funnelStatisticsService.saveNewReport(this.formGroup.value);
    this.context.completeWith(true);
  }
}
