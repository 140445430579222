import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiIdentityMatcher } from '@taiga-ui/cdk';
import {
  EFunnelCreateTacticForm,
  FunnelCreateTacticService,
} from '@modules/funnels/modules/funnel-manage/shared/components/funnel-create-tactic-modal/funnel-create-tactic.service';
import { UntypedFormGroup } from '@angular/forms';
import { TacticParametersService } from '@modules/tactics/shared/services/tactic-parameters.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Step } from '@shared/models/step.model';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TacticParametersLoading } from '@modules/tactics/enums/tactic-parameters-loading.enum';
import { TuiDialogContext } from '@taiga-ui/core';

@Component({
  selector: 'df-funnel-create-tactic-modal',
  templateUrl: './funnel-create-tactic-modal.component.html',
  styleUrls: ['./funnel-create-tactic-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FunnelCreateTacticService],
})
export class FunnelCreateTacticModalComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly EFunnelCreateTacticForm = EFunnelCreateTacticForm;
  loading$: Observable<boolean>;
  readonly identityMatcher: TuiIdentityMatcher<{ id: number; name: string }> = (obj1, obj2) => obj1.id === obj2.id;

  get form(): UntypedFormGroup {
    return this.funnelCreateTacticService.form;
  }

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<boolean>,
    private readonly changes: ChangeDetectorRef,
    private readonly funnelCreateTacticService: FunnelCreateTacticService,
    public tacticParametersService: TacticParametersService,
  ) {
    super();
    this.loading$ = this.funnelCreateTacticService.loading$;
  }

  ngOnInit(): void {
    this.listenStepsLoaded();
  }

  private listenStepsLoaded(): void {
    this.sub.add(
      this.tacticParametersService.events$
        .pipe(filter((event: TacticParametersLoading) => event === TacticParametersLoading.STEPS))
        .subscribe(() => this.changes.detectChanges()),
    );
  }

  handleFunnelStepSelected(step: IFilter<Step>) {
    this.form.get(EFunnelCreateTacticForm.funnelSteps)?.setValue([step]);
    this.form.get(EFunnelCreateTacticForm.funnelStepsIds)?.setValue([step.id]);
  }

  cancel(): void {
    this.context.completeWith(false);
  }

  submit(): void {
    if (this.form.valid) {
      this.funnelCreateTacticService
        .createTactic()
        .subscribe(() =>
          this.form.get(EFunnelCreateTacticForm.another)?.value
            ? this.createAnotherTactic()
            : this.context.completeWith(true),
        );
    } else {
      this.form.markAllAsTouched();
    }
  }

  private createAnotherTactic(): void {
    this.form.get(EFunnelCreateTacticForm.name)?.reset();
    this.changes.detectChanges();
  }
}
