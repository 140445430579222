import { gql } from 'apollo-angular';
import { BaseWorkFlowFragment } from '../fragments/base-work-flow.fragment';

export const GetBaseWorkFlows = gql`
  query GetBaseWorkFlows($input: WorkFlowQueryInputGraphql!) {
    getBaseWorkFlows(input: $input) {
      ...BaseWorkFlowFields
    }
  }
  ${BaseWorkFlowFragment}
`;
