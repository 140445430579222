<div class="py-1 px-8 border-b-[1px] border-[#E0E0E0] flex items-center justify-between border-solid">
  <button
    type="button"
    class="p-2"
    (click)="onBackClick()"
  >
    <svg-icon src="/assets/img/arrow-back.svg"></svg-icon>
  </button>

  <div class="text-base">
    <span class="text-zinc-600">Flows / </span>
    <span class="text-slate-950 font-medium">{{ workFlowName }}</span>
  </div>

  <button
    tuiButton
    size="m"
    type="button"
    appearance="outline"
    (click)="onShareClick()"
  >
    <span class="inline-flex mr-2 text-xs">{{ 'share' | translate }}</span>
    <svg-icon
      [src]="'/assets/img/ic_share.svg'"
      [svgStyle]="{ width: '0.75rem', height: '0.75rem' }"
    ></svg-icon>
  </button>

  <!--  Temporary removing masters and tactics -->

  <!--  <nav class="flex">-->
  <!--    <div-->
  <!--      *ngFor="let item of flowLinks; let i = index"-->
  <!--      class="mr-8 last-of-type:mr-0 flex items-center"-->
  <!--    >-->
  <!--      <a-->
  <!--        [routerLink]="item.link"-->
  <!--        class="link"-->
  <!--        routerLinkActive="link&#45;&#45;active"-->
  <!--        [queryParams]="{ docName }"-->
  <!--      >-->
  <!--        <span class="link-circle">{{ i + 1 }}</span>-->
  <!--        <span class="text">{{ item.label }}</span>-->
  <!--      </a>-->
  <!--    </div>-->
  <!--  </nav>-->

  <!--  <button-->
  <!--    [class.invisible]="isLastStep"-->
  <!--    size="m"-->
  <!--    tuiButton-->
  <!--    type="button"-->
  <!--    (click)="onNextButtonClick.emit()"-->
  <!--    [showLoader]="loading"-->
  <!--  >-->
  <!--    <span class="font-semibold">Next</span>-->
  <!--  </button>-->
</div>
