import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { ActivatedRoute } from '@angular/router';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ChangeDetectorRef, Component, Injector, OnInit, Inject, OnDestroy } from '@angular/core';
import { TacticMetricFormService } from '../../services/tactic-metric-form.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { ETacticMetricForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-metric-form.enum';
import { TuiIdentityMatcher } from '@taiga-ui/cdk';
import { PolymorpheusComponent, POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { CloseAlertComponent } from '@shared/components/close-alert/close-alert.component';
import { NumberSymbol } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { FetchResult } from '@apollo/client/core';
import { MetricOutputGraphql, TacticMetricPeriodEnum } from '@modules/graphql/graphql-types';
import { GetMetricsQuery } from '../../graphql/queries/get-metrics.query.generated';
import { map } from 'rxjs/operators';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';

@Component({
  selector: 'df-tactic-metric-form-modal',
  templateUrl: './tactic-metric-form-modal.component.html',
  styleUrls: ['./tactic-metric-form-modal.component.scss'],
})
export class TacticMetricFormModalComponent implements OnInit, OnDestroy {
  readonly ETacticMetricForm = ETacticMetricForm;
  readonly TacticMetricPeriodEnum = TacticMetricPeriodEnum;
  sub = new Subscription();
  loading = false;
  anyChanges = false;
  tacticId: number | null = null;

  readonly identityMatcher: TuiIdentityMatcher<{ id: number; name: string }> = (obj1, obj2) => obj1.id === obj2.id;
  metrics: IFilter<MetricOutputGraphql>[] = [];
  periods: IFilter<{ id: NumberSymbol; name: string }>[] = [];

  get form() {
    return this.tacticMetricFormService.form;
  }

  getControl(path: string): UntypedFormControl {
    return this.form.get(path) as UntypedFormControl;
  }

  constructor(
    private readonly apollo: Apollo,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean>,
    private dialogService: TuiDialogService,
    private tacticMetricFormService: TacticMetricFormService,
    private changes: ChangeDetectorRef,
    private s: SnackbarService,
    private t: TranslateService,
    private tacticGraphqlService: TacticGraphqlService,
    private route: ActivatedRoute,
    private tacticSettingsService: TacticSettingsService,
  ) {}

  ngOnInit(): void {
    this.getTacticId();
    this.checkFormValueChanges();
    this.getPeriods();
    this.getMetrics();
    this.listenCycleChange();
    this.setEditFormData();
  }

  setEditFormData() {
    const sub = this.tacticMetricFormService.tacticMetricIndex.subscribe((index) => {
      if (index !== null) {
        const element = this.tacticMetricFormService.tacticMetricsInputList[index];
        let formValue = element.formValue;
        if (!formValue) {
          // convert output to formValue
          // const metric = this.metrics.find(el => el.id === element.output?.metric.id);

          const period = this.periods.find((el) => el.name === element.output?.measurementPeriod);
          const cycleMeasurement = this.periods.find((el) => el.name === element.output?.cycleMeasurementPeriod);
          formValue = {
            [ETacticMetricForm.metric]: element.output?.metric,
            [ETacticMetricForm.period]: period ? [period] : [],
            [ETacticMetricForm.cycle]: element.output?.isCyclical,
            [ETacticMetricForm.cycleMeasurement]: cycleMeasurement,
          };
        }
        this.form.patchValue(formValue);
      }

      this.anyChanges = false;

      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  getTacticId() {
    this.route.params.subscribe((res) => {
      this.tacticId = res.id || null;
    });
  }

  listenCycleChange() {
    const sub = this.form.get(ETacticMetricForm.cycle)?.valueChanges.subscribe((res) => {
      res ? null : this.form.get(ETacticMetricForm.cycleMeasurement)?.patchValue(null);
    });
    this.sub.add(sub);
  }
  getMetrics() {
    return this.tacticGraphqlService
      .getMetrics()
      .pipe(map((res: FetchResult<GetMetricsQuery>) => (res.data ? res.data.getMetrics : null)))
      .subscribe((res: MetricOutputGraphql[] | null) => {
        this.metrics = res as unknown as IFilter<MetricOutputGraphql>[];
        this.changes.detectChanges();
      });
  }

  getPeriods() {
    this.periods = Object.keys(TacticMetricPeriodEnum).map((label) => ({
      id: TacticMetricPeriodEnum[label],
      name: TacticMetricPeriodEnum[label],
    }));
  }

  checkFormValueChanges() {
    this.sub.add(
      this.form.valueChanges.subscribe(() => {
        this.anyChanges = true;
        this.changes.detectChanges();
      }),
    );
  }

  close(checkChanges = true) {
    if (checkChanges) {
      this.openConfirmExitModal().subscribe((res) => {
        res ? this.closeModal() : null;
      });
    } else {
      this.closeModal();
    }
  }

  closeModal() {
    this.context.completeWith(true);
    this.tacticMetricFormService.tacticMetricIndex.next(null);
    this.tacticMetricFormService.tacticMetricId.next(null);
    this.form.reset();
  }

  send() {
    if (this.form.valid) {
      this.tacticId
        ? this.tacticMetricFormService.pushTacticMetric(this.tacticId)
        : this.tacticMetricFormService.pushTacticMetric();
      this.closeModal();
      this.tacticSettingsService._changesInForm$.next(true);
    } else {
      this.form.markAllAsTouched();
    }
    this.changes.detectChanges();
  }

  openConfirmExitModal(): Observable<boolean> {
    if (this.anyChanges) {
      return new Observable<boolean>((subscriber) => {
        this.dialogService
          .open<boolean>(new PolymorpheusComponent(CloseAlertComponent, this.injector), {
            closeable: false,
            dismissible: false,
            size: 's',
            label: this.t.instant('Tactics.Settings.Metrics.Leaving this page?'),
          })
          .subscribe((val) => {
            subscriber.next(val);
          });
      });
    } else {
      return of(true);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
