import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { Integration } from '@shared/models/integration.model';
import { FunnelGraphicsGeneratorService } from '../../funnel-graphics-generator.service';
import { UntypedFormControl } from '@angular/forms';
import { AiDesignUploadedFile } from './funnel-graphics-ai-video-form.model';

@Component({
  selector: 'df-funnel-graphics-ai-video-form',
  templateUrl: './funnel-graphics-ai-video-form.component.html',
  styleUrls: ['./funnel-graphics-ai-video-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelGraphicsAiVideoFormComponent {
  @Input() integration: Integration | null = null;
  @Input() image: AiDesignUploadedFile | null = null;

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  maskingMode = false;

  constructor(
    public readonly graphicService: FunnelGraphicsGeneratorService,
    private changes: ChangeDetectorRef,
  ) {}

  get loading$() {
    return this.graphicService.loading$;
  }

  get form() {
    return this.graphicService.videoForm;
  }

  onSubmit() {
    this.setDefaultValues();
    this.changes.detectChanges();

    if (!this.form.valid) return;
    this.formSubmitted.emit();
  }

  setDefaultValues() {
    //Set bar default value before submit
    const seedConf = this.getConfiguration('seed');
    const cfgConf = this.getConfiguration('cfg_scale');
    const motionBucketConf = this.getConfiguration('motion_bucket_id');
    const imageConf = this.getConfiguration('image');

    const seed = this.form.get(`${seedConf?.id}`)?.value;
    const cfg = this.form.get(`${cfgConf?.id}`)?.value;
    const motionBucket = this.form.get(`${motionBucketConf?.id}`)?.value;
    const image = this.form.get(`${imageConf?.id}`)?.value;

    if (!image) this.form.get(`${imageConf?.id}`)?.setValue(this.image?.base64);
    if (!seed) this.form.get(`${seedConf?.id}`)?.setValue(0);
    if (!cfg) this.form.get(`${cfgConf?.id}`)?.setValue(25);
    if (!motionBucket) this.form.get(`${motionBucketConf?.id}`)?.setValue(40);
  }

  getConfiguration(property: string) {
    return this.integration?.inputsConfigurations.find((prop) => prop.property === property);
  }

  getFormControl(id: number): UntypedFormControl {
    return this.form?.get(id.toString()) as UntypedFormControl;
  }
}
