<tui-island
  [size]="'s'"
  class="h-full recommendations-island relative px-6 pt-6 pb-8 overflow-hidden"
>
  <img
    src="assets/img/overview-content-funnel.png"
    alt=""
    class="sm:block object-contain max-h-full max-w-xs xl:max-w-sm absolute bottom-0 right-0 md:-right-16 lg:right-0 xl:-right-8"
  />
  <!-- text -->
  <div class="relative h-full flex flex-col justify-between items-start sm:w-2/4">
    <h3
      class="text-2xl tui-text-03 font-semibold text-left"
      [innerHTML]="'Funnels.Overview.Content funnel' | translate"
    ></h3>
    <button
      tuiButton
      type="button"
      [size]="'l'"
      class="w-auto mt-4"
      appearance="secondary"
      routerLink="../preview"
    >
      {{ 'Funnels.Overview.Go to funnel' | translate }}
    </button>
  </div>
</tui-island>
