import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { LuxonModule } from 'luxon-angular';
import {
  TuiAccordionModule,
  TuiAvatarModule,
  TuiBadgeModule,
  TuiBreadcrumbsModule,
  TuiCheckboxBlockModule,
  TuiCheckboxLabeledModule,
  TuiCheckboxModule,
  TuiDataListWrapperModule,
  TuiFilterModule,
  TuiHighlightModule,
  TuiInputInlineModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputPasswordModule,
  TuiInputTagModule,
  TuiIslandModule,
  TuiLineClampModule,
  TuiMultiSelectModule,
  TuiRadioLabeledModule,
  TuiRadioModule,
  TuiSelectModule,
  TuiSliderModule,
  TuiStepperModule,
  TuiTabsModule,
  TuiTextAreaModule,
  TuiToggleModule,
  TuiInputFilesModule,
  TuiInputDateTimeModule,
  TuiPushModule,
} from '@taiga-ui/kit';
import {
  TuiAlertModule,
  TuiButtonModule,
  TuiCalendarModule,
  TuiDataListModule,
  TuiDialogModule,
  TuiDropdownModule,
  TuiErrorModule,
  TuiExpandModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLabelModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiNotificationModule,
  TuiPrimitiveCheckboxModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiTooltipModule,
} from '@taiga-ui/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@shared/modules/ui/ui.module';
import { TuiActiveZoneModule, TuiAutoFocusModule, TuiLetModule } from '@taiga-ui/cdk';
import { TuiToolbarModule } from '@taiga-ui/addon-editor';
import { QuillModule } from 'ngx-quill';
import { CustomSelectsModule } from '@shared/modules/custom-selects/custom-selects.module';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ClipboardModule } from 'ngx-clipboard';
import { TuiSidebarModule } from '@taiga-ui/addon-mobile';

const TUI_MODULES = [
  TuiAvatarModule,
  TuiHostedDropdownModule,
  TuiDataListModule,
  TuiDataListWrapperModule,
  TuiButtonModule,
  TuiInputModule,
  TuiInputFilesModule,
  TuiInputPasswordModule,
  TuiCheckboxModule,
  TuiCheckboxLabeledModule,
  TuiTextfieldControllerModule,
  TuiSvgModule,
  TuiErrorModule,
  TuiHintModule,
  TuiLinkModule,
  TuiNotificationModule,
  TuiDialogModule,
  TuiLoaderModule,
  TuiMultiSelectModule,
  TuiLetModule,
  TuiSelectModule,
  TuiFilterModule,
  TuiToggleModule,
  TuiRadioModule,
  TuiRadioLabeledModule,
  TuiToolbarModule,
  TuiDropdownModule,
  TuiActiveZoneModule,
  TuiTextAreaModule,
  TuiInputInlineModule,
  TuiTabsModule,
  TuiAccordionModule,
  TuiBadgeModule,
  TuiIslandModule,
  TuiCheckboxBlockModule,
  TuiHostedDropdownModule,
  TuiBreadcrumbsModule,
  TuiAutoFocusModule,
  TuiExpandModule,
  TuiLineClampModule,
  TuiStepperModule,
  TuiInputNumberModule,
  TuiTooltipModule,
  TuiHighlightModule,
  TuiSidebarModule,
  TuiPrimitiveCheckboxModule,
  TuiSliderModule,
  TuiInputTagModule,
  TuiAlertModule,
  TuiLabelModule,
  TuiCalendarModule,
  TuiInputDateTimeModule,
  TuiPushModule,
];

export const SHARED_MODULES = [
  TUI_MODULES,
  RouterModule,
  CoreModule,
  LuxonModule,
  AngularSvgIconModule.forRoot(),
  TranslateModule,
  UiModule,
  ScrollingModule,
  ClipboardModule,
  CustomSelectsModule,
  ConfirmationPopoverModule.forRoot({
    confirmButtonType: 'danger',
  }),
  QuillModule.forRoot(),
];
