<div class="features flex flex-col justify-start items-stretch gap-y-4">
  <div>
    <h2 class="tui-text_h4 mt-6 mb-1 font-semibold text-center">
      {{
        'Tactics.Preview.Create a Digital First AI account to get access to biggest marketing tactics library.'
          | translate
      }}
    </h2>
  </div>

  <div
    class="features__item flex flex-row justify-start items-start gap-x-3"
    *ngFor="let feature of features"
  >
    <svg-icon
      class="flex-shrink-0 flex-grow-0"
      [src]="feature.icon"
      [svgStyle]="{ width: '2.5rem', height: '2.5rem' }"
    ></svg-icon>
    <div>
      <h3 class="tui-text_body-m-1">{{ feature.title }}</h3>
      <p class="text-xs">{{ feature.content }}</p>
    </div>
  </div>

  <h6 class="tui-text_h6 text-center font-semibold mt-1">
    {{ 'Auth.Onboarding.and more' | translate }}
  </h6>
</div>

<div class="flex justify-center mt-8 px-36">
  <a
    class="w-full modal-button text-white"
    href="https://app.digitalfirst.ai/public/pricing"
    tuiButton
    >{{
      'Start free
    trial' | translate
    }}</a
  >
</div>
