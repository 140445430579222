import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ETacticSettingsFormEnum } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-settings-form.enum';
import { Regex } from '@shared/configs/regex';
import { Tactic } from '@shared/models/tactic.model';
import { ETacticControlState } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-edit-status.enum';
import { EActivityForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-form.enum';
import { Activity } from '@shared/models/activity.model';
import { ActivityBenchmark } from '@shared/models/activity-benchmark.model';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import {
  ActivityInputGraphql,
  AddAssetInputGraphql,
  AddBenchmarkInputGraphql,
  BenchmarkTypeEnum,
  EditBenchmarksInputGraphql,
  TacticInputGraphql,
} from '@modules/graphql/graphql-types';
import { EActivityBenchmarkForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-benchmark-form.enum';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { forkJoin, Observable, of } from 'rxjs';
import { EActivityAssetForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-asset-form.enum';
import { EContainerTabType } from '@shared/interfaces/container-tab.interface';

export const getTacticForm = () => {
  return new UntypedFormGroup({
    [ETacticSettingsFormEnum.name]: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern(Regex.whiteSpace),
    ]),
    [ETacticSettingsFormEnum.image]: new UntypedFormControl(null),
    [ETacticSettingsFormEnum.accessibility]: new UntypedFormControl(true),
    [ETacticSettingsFormEnum.target]: new UntypedFormControl('', [
      Validators.minLength(2),
      Validators.pattern(Regex.whiteSpace),
    ]),
    [ETacticSettingsFormEnum.categoriesIds]: new UntypedFormControl(null, [Validators.required]),
    [ETacticSettingsFormEnum.segmentsIds]: new UntypedFormControl(null, [Validators.required]),
    [ETacticSettingsFormEnum.effectsIds]: new UntypedFormControl(null, [Validators.required]),
    [ETacticSettingsFormEnum.typeId]: new UntypedFormControl(null, [Validators.required]),
    [ETacticSettingsFormEnum.levelOfAdvancementId]: new UntypedFormControl(null, [Validators.required]),
    [ETacticSettingsFormEnum.funnelStepsIds]: new UntypedFormControl(null, [Validators.required]),
    [ETacticSettingsFormEnum.requiresBudget]: new UntypedFormControl(false),
    [ETacticSettingsFormEnum.tacticDetails]: new UntypedFormControl(''),
    [ETacticSettingsFormEnum.activities]: new UntypedFormArray([]),
    // [ETacticSettingsFormEnum.badge]: new FormControl(null),
  });
};

export const patchTacticFormData = (form: UntypedFormGroup, tactic: Tactic) => {
  form.patchValue(
    {
      [ETacticSettingsFormEnum.name]: tactic.name,
      [ETacticSettingsFormEnum.accessibility]: tactic.isPrivate,
      [ETacticSettingsFormEnum.target]: tactic?.target,
      [ETacticSettingsFormEnum.categoriesIds]: tactic?.categories,
      [ETacticSettingsFormEnum.effectsIds]: tactic?.effects?.length ? tactic?.effects[0] : null,
      [ETacticSettingsFormEnum.segmentsIds]: tactic?.segments,
      [ETacticSettingsFormEnum.typeId]: tactic?.type ? [tactic?.type] : [],
      [ETacticSettingsFormEnum.levelOfAdvancementId]: tactic?.levelOfAdvancement ? [tactic?.levelOfAdvancement] : [],
      [ETacticSettingsFormEnum.funnelStepsIds]: tactic?.funnelSteps,
      [ETacticSettingsFormEnum.requiresBudget]: tactic?.requiresBudget,
      [ETacticSettingsFormEnum.tacticDetails]: tactic?.tacticDetails,
    },
    { emitEvent: false },
  );
};

export const getTacticActivityForm = (state: ETacticControlState = ETacticControlState.UNTOUCHED) => {
  return new UntypedFormGroup({
    [EActivityForm.id]: new UntypedFormControl(null),
    [EActivityForm.name]: new UntypedFormControl(''),
    [EActivityForm.description]: new UntypedFormControl(''),
    [EActivityForm.copy]: new UntypedFormControl(''),
    [EActivityForm.typeId]: new UntypedFormControl(null),
    [EActivityForm.benchmarks]: new UntypedFormArray([]),
    [EActivityForm.assets]: new UntypedFormArray([]),
    [EActivityForm.state]: new UntypedFormControl([state]),
    [EActivityForm.opened]: new UntypedFormControl(false),
    [EActivityForm.customPrompts]: new UntypedFormControl([]),
  });
};

export const getBenchmarkForm = (
  state: ETacticControlState = ETacticControlState.UNTOUCHED,
  activityId?: number,
): UntypedFormGroup => {
  return new UntypedFormGroup({
    [EActivityBenchmarkForm.activityId]: new UntypedFormControl(activityId ?? null),
    [EActivityBenchmarkForm.benchmarkTypeId]: new UntypedFormControl(null, [Validators.required]),
    [EActivityBenchmarkForm.benchmark]: new UntypedFormControl(null),
    [EActivityBenchmarkForm.state]: new UntypedFormControl([state]),
  });
};

export const getResolutionsControl = (): UntypedFormControl => {
  return new UntypedFormControl([], [Validators.required]);
};

export const getNewAssetForm = (state: ETacticControlState = ETacticControlState.CREATE): UntypedFormGroup => {
  return new UntypedFormGroup({
    [EActivityAssetForm.activityId]: new UntypedFormControl(null),
    [EActivityAssetForm.platformId]: new UntypedFormControl(null, [Validators.required]),
    [EActivityAssetForm.addTypeId]: new UntypedFormControl(null, [Validators.required]),
    [EActivityAssetForm.placementId]: new UntypedFormControl(null, [Validators.required]),
    [EActivityAssetForm.resolutionsIds]: getResolutionsControl(),
    [EActivityAssetForm.asset]: new UntypedFormControl(null),
    [EActivityAssetForm.state]: new UntypedFormControl([state]),
    [EActivityAssetForm.uuid]: new UntypedFormControl([null]),
  });
};

export const getAssetForm = (
  asset?: ActivityAsset,
  state: ETacticControlState = ETacticControlState.UNTOUCHED,
): UntypedFormGroup => {
  return new UntypedFormGroup({
    [EActivityAssetForm.asset]: new UntypedFormControl(asset ?? null),
    [EActivityAssetForm.state]: new UntypedFormControl([state]),
  });
};

export const patchTacticActivitiesFormData = (formArray: UntypedFormArray, activities: Activity[]) => {
  activities.forEach((activity) => {
    const activityForm: UntypedFormGroup = getTacticActivityForm();
    activityForm.patchValue({
      [EActivityForm.id]: activity.id,
      [EActivityForm.name]: activity.name,
      [EActivityForm.description]: activity.description,
      [EActivityForm.typeId]: activity.type ?? null,
      [EActivityForm.copy]: activity.copy,
      [EActivityForm.customPrompts]: activity.customPrompts,
    });
    patchBenchmarksFormData(activityForm.get(EActivityForm.benchmarks) as UntypedFormArray, activity.benchmarks);
    patchAssetsFormData(activityForm.get(EActivityForm.assets) as UntypedFormArray, activity.assets);
    formArray.push(activityForm, { emitEvent: false });
  });
};

const patchBenchmarksFormData = (formArray: UntypedFormArray, benchmarks: ActivityBenchmark[]) => {
  benchmarks.forEach((benchmark) => {
    const benchmarkForm: UntypedFormGroup = getBenchmarkForm();
    benchmarkForm.removeControl(EActivityBenchmarkForm.activityId);
    benchmarkForm.addControl(
      EActivityBenchmarkForm.benchmarkId,
      new UntypedFormControl(benchmark.id, [Validators.required]),
    );
    benchmarkForm.patchValue({
      [EActivityBenchmarkForm.benchmarkTypeId]: benchmark.type,
      [EActivityBenchmarkForm.benchmark]: benchmark,
    });

    if (benchmark.type.type == BenchmarkTypeEnum.Link) {
      benchmarkForm.addControl(
        EActivityBenchmarkForm.link,
        new UntypedFormControl(benchmark.link, [Validators.required, Validators.pattern(Regex.url)]),
      );
    } else {
      benchmarkForm.addControl(EActivityBenchmarkForm.file, new UntypedFormControl(null));
    }
    formArray.push(benchmarkForm, { emitEvent: false });
  });
};

const patchAssetsFormData = (formArray: UntypedFormArray, assets: ActivityAsset[]) => {
  assets.forEach((asset) => {
    const assetForm: UntypedFormGroup = getAssetForm(asset);
    formArray.push(assetForm, { emitEvent: false });
  });
};

export const getTacticFormObject = (formGroup: UntypedFormGroup): TacticInputGraphql => {
  const form = formGroup.getRawValue();
  Object.keys(form).map((key) => {
    if (Array.isArray(form[key])) {
      form[key] = form[key].map((i) => i.id);
    }
  });
  form[ETacticSettingsFormEnum.typeId] = form[ETacticSettingsFormEnum.typeId][0];
  form[ETacticSettingsFormEnum.levelOfAdvancementId] = form[ETacticSettingsFormEnum.levelOfAdvancementId][0];
  form[ETacticSettingsFormEnum.effectsIds] = [form[ETacticSettingsFormEnum.effectsIds]['id']];
  delete form[ETacticSettingsFormEnum.activities];
  delete form[ETacticSettingsFormEnum.accessibility];
  delete form[ETacticSettingsFormEnum.image];
  return form as TacticInputGraphql;
};

export const getActivityFormObject = (data): ActivityInputGraphql => {
  const value = { ...data };
  value[EActivityForm.typeId] = value[EActivityForm.typeId] ? value[EActivityForm.typeId].id : null;
  delete value[EActivityForm.benchmarks];
  delete value[EActivityForm.assets];
  delete value[EActivityForm.id];
  delete value[EActivityForm.state];
  delete value[EActivityForm.opened];
  return value;
};

export const getBenchmarkFormObject = (data): AddBenchmarkInputGraphql | EditBenchmarksInputGraphql => {
  const value = { ...data };
  value[EActivityBenchmarkForm.benchmarkTypeId] = value[EActivityBenchmarkForm.benchmarkTypeId]?.id
    ? value[EActivityBenchmarkForm.benchmarkTypeId].id
    : value[EActivityBenchmarkForm.benchmarkTypeId];
  value[EActivityBenchmarkForm.uuid] = value[EActivityBenchmarkForm.benchmark].uuid;
  delete value[EActivityBenchmarkForm.file];
  delete value[EActivityBenchmarkForm.benchmark];
  delete value[EActivityBenchmarkForm.state];
  delete value[EActivityBenchmarkForm.attachmentId];
  return value;
};

export const getAssetFormObject = (data): AddAssetInputGraphql => {
  const value = { ...data };
  value[EActivityAssetForm.platformId] = value[EActivityAssetForm.platformId]?.id
    ? value[EActivityAssetForm.platformId].id
    : value[EActivityAssetForm.platformId];
  value[EActivityAssetForm.addTypeId] = value[EActivityAssetForm.addTypeId]?.id
    ? value[EActivityAssetForm.addTypeId].id
    : value[EActivityAssetForm.addTypeId];
  value[EActivityAssetForm.placementId] = value[EActivityAssetForm.placementId]?.id
    ? value[EActivityAssetForm.placementId].id
    : value[EActivityAssetForm.placementId];
  value[EActivityAssetForm.resolutionsIds] = value[EActivityAssetForm.resolutionsIds]?.length
    ? value[EActivityAssetForm.resolutionsIds].map((item) => item.id)
    : null;
  // value[EActivityAssetForm.uuid] =  value[EActivityAssetForm.asset].uuid;

  delete value[EActivityAssetForm.asset];
  delete value[EActivityAssetForm.state];
  return value;
};

export const removeControlFromArray = (formArray: UntypedFormArray, control: AbstractControl) => {
  moveItemInArray(
    formArray.controls,
    formArray.controls.findIndex((a) => a === control),
    formArray.length - 1,
  );
  const state = control.get(EActivityForm.state)?.value;
  control.get(EActivityForm.state)?.setValue([...state, ETacticControlState.REMOVE]);
  control.disable({ emitEvent: false });
};

export const restoreControlInArray = (formArray: UntypedFormArray, control: AbstractControl) => {
  control.enable({ emitEvent: false });
  const state: ETacticControlState[] = control.get(EActivityForm.state)?.value;
  control.get(EActivityForm.state)?.setValue(state.filter((s) => s !== ETacticControlState.REMOVE));
  moveItemInArray(
    formArray.controls,
    formArray.controls.findIndex((a) => a === control),
    getActiveArrayLength(formArray),
  );
};

export const isControlToRemove = (control: AbstractControl): boolean => {
  return control?.get(EActivityForm.state)?.value.includes(ETacticControlState.REMOVE);
};

export const getActiveArrayLength = (formArray: UntypedFormArray): number => {
  const index = formArray.controls.findIndex((c: AbstractControl) =>
    c.get(EActivityBenchmarkForm.state)?.value.includes(ETacticControlState.REMOVE),
  );
  return index >= 0 ? index : formArray.length;
};

export const combineRequests = (requests: Observable<any | any[]>[]): Observable<any | any[]> => {
  return requests.length ? forkJoin(requests) : of(true);
};

export const getFormTabsValidity = (form: UntypedFormGroup): { [tab: string]: boolean } => {
  const formTabs: { [tab: string]: ETacticSettingsFormEnum[] } = {
    [EContainerTabType.ABOUT]: [
      ETacticSettingsFormEnum.target,
      ETacticSettingsFormEnum.categoriesIds,
      ETacticSettingsFormEnum.segmentsIds,
      ETacticSettingsFormEnum.effectsIds,
      ETacticSettingsFormEnum.funnelStepsIds,
      ETacticSettingsFormEnum.typeId,
      ETacticSettingsFormEnum.levelOfAdvancementId,
      ETacticSettingsFormEnum.requiresBudget,
      ETacticSettingsFormEnum.tacticDetails,
    ],
    [EContainerTabType.ACTIVITIES]: [ETacticSettingsFormEnum.activities],
  };
  const formTabsValidity: { [tab: string]: boolean } = {};
  Object.keys(formTabs).forEach(
    (tab) => (formTabsValidity[tab] = formTabs[tab].some((control) => !form.get(control)?.valid)),
  );
  return formTabsValidity;
};
