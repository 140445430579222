import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-funnel-content-genereter-library-no-content',
  templateUrl: './funnel-content-genereter-library-no-content.component.html',
  styleUrls: ['./funnel-content-genereter-library-no-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelContentGenereterLibraryNoContentComponent {
  @Input() funnelId: number | null = null;
  @Input() description = 'Funnels.Content generator.You dont have any generated Content';
  @Input() descriptionLine2 = 'Funnels.Content generator.Start creating now';
  @Input() buttonLabel = 'Funnels.Content generator.Create content';
  @Input() link = '/funnels/f/d/:id/content-generator';
  @Input() emitEventInsteadRedirect = false;

  @Output() buttonClicked = new EventEmitter();

  constructor(private readonly navigateService: NavigateService) {}

  public onCreateNewContent(): void {
    if (!this.funnelId) return;
    if (this.emitEventInsteadRedirect) {
      this.buttonClicked.emit();
      return;
    }

    this.navigateService.go(this.link, { id: this.funnelId });
  }
}
