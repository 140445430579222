<svg-icon
  [src]="'/assets/img/add-first-tactic.svg'"
  class="block md:hidden xl:block"
></svg-icon>
<h2 class="tui-text_h2 mt-6 font-semibold">
  {{ 'Funnels.Manage.Add first tactic' | translate }}
</h2>
<h4 class="tui-text_body-l-2 tui-text-05 mt-2">
  {{ 'Funnels.Manage.Create your funnel' | translate }}
</h4>
<button
  size="xl"
  tuiButton
  type="submit"
  appearance="primary"
  class="mt-8"
  routerLink="../recommendations"
>
  <span class="font-semibold">{{ 'Funnels.Manage.View Tactic Recommendations' | translate }}</span>
</button>
