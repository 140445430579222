import { Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { FormControl } from '@angular/forms';
import { copyToClipboard } from '@shared/helpers/clipboard.helper';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'df-share-tiptap-document-modal',
  templateUrl: './share-tiptap-document-modal.component.html',
  styleUrl: './share-tiptap-document-modal.component.scss',
})
export class ShareTiptapDocumentModalComponent {
  readonly password = new FormControl<string>('');

  passwordEnabled = false;

  tiptapShareUrl = '';
  docName = '';

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialog<
      {
        data: { tiptapShareUrl: string; docName: string };
      },
      string | null
    >,
    private readonly s: SnackbarService,
    private readonly t: TranslateService,
    private readonly httpClient: HttpClient,
    private readonly storageService: StorageService,
  ) {
    this.tiptapShareUrl = context.data.tiptapShareUrl;
    this.docName = context.data.docName;
  }

  close(): void {
    this.context.completeWith(null);
  }

  copyToClipboard(): void {
    copyToClipboard(this.tiptapShareUrl, this.s, this.t);
  }

  submit(): void {
    const payload = {
      docName: this.docName,
    };

    if (this.passwordEnabled) {
      payload['password'] = this.password.value;
    }

    this.httpClient
      .post(`${environment.API_REST}/tiptap/share`, payload, {
        headers: {
          Authorization: `Bearer ${this.storageService.jwt}`,
        },
      })
      .subscribe({
        next: () => {
          this.s.success('Setings saved');
          this.context.completeWith(null);
        },
        error: () => {
          this.s.defaultError();
          return;
        },
      });
  }
}
