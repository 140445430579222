import { gql } from 'apollo-angular';

export const GetCanvasSectionsDocument = gql`
  query getCanvasSections {
    getCanvasSections {
      id
      name
      description
      hierarchyPosition
    }
  }
`;
