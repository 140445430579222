import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { HeaderTab } from '@shared/modules/ui/components/header-tabs/header-tabs.component';
import { Funnel } from '@shared/models/funnel.model';
import { UserService } from '@shared/services/user.service';

export const funnelPanelTabsConst = (
  n: NavigateService,
  t: TranslateService,
  f: Funnel,
  manage: boolean,
  userService: UserService,
): HeaderTab[] => {
  const tabs = [
    {
      name: t.instant('Funnels.funnel'),
      route: n.getPath(manage ? 'funnels/f/d/:id/manage' : 'funnels/f/d/:id/preview', {}, { id: f.id }),
    },
    {
      name: t.instant('Funnels.overview'),
      route: n.getPath('funnels/f/d/:id/overview', {}, { id: f.id }),
    },
    {
      name: t.instant('Funnels.statement'),
      route: n.getPath('funnels/f/d/:id/statement/1', {}, { id: f.id }),
    },
    {
      name: t.instant('Funnels.recommended tactics'),
      route: n.getPath('funnels/f/d/:id/recommendations', {}, { id: f.id }),
    },
    {
      name: t.instant('Funnels.canvases'),
      route: n.getPath('funnels/f/d/:id/canvases', {}, { id: f.id }),
    },
    {
      name: t.instant('Funnels.graphics'),
      route: n.getPath('funnels/f/d/:id/graphics', {}, { id: f.id }),
    },
    {
      name: t.instant('Funnels.AI Strategy'),
      route: n.getPath('funnels/f/d/:id/marketing-campaign/campaign', {}, { id: f.id }),
    },
    // {
    //   name: t.instant('Funnels.Content Calendar'),
    //   route: n.getPath('funnels/f/d/:id/content-calendar', {}, { id: f.id }),
    // },
  ];

  // Commented out due to DEV-1088
  // if (!userService.User?.isSemrushPlan()) {
  //   tabs.push({
  //     name: t.instant('Funnels.Statistics.Statistics'),
  //     route: n.getPath('/funnels/f/d/:id/statistics', {}, { id: f.id }),
  //   });
  // }

  return tabs;
};
