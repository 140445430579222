<df-full-list-loader *ngIf="contentLibraryService.loading$ | async"></df-full-list-loader>
<div
  class="df-container"
  infiniteScroll
  [infiniteScrollThrottle]="50"
  (scrolled)="loadMoreContent()"
  [scrollWindow]="false"
  [infiniteScrollDisabled]="
    !!(contentLibraryService.hasMoreData$ | async) ||
    !!(contentLibraryService.moreContentLoading$ | async) ||
    !!(contentLibraryService.loading$ | async)
  "
>
  <df-funnel-content-generator-library-filters></df-funnel-content-generator-library-filters>
  <div
    *ngIf="(contentLibraryService.loading$ | async) === false && (contentLibraryService.collection$ | async)?.length"
    class="integrations-ouptuts flex flex-wrap gap-4 mt-4"
  >
    <df-funnel-content-generator-library-tile
      *ngFor="let outputData of contentLibraryService.collection$ | async"
      [outputData]="outputData"
      class="integrations-ouptuts__item h-[14.5rem]"
    ></df-funnel-content-generator-library-tile>
  </div>
  <div class="mt-4">
    <df-page-list-loader
      *ngIf="(contentLibraryService.loading$ | async) === false && (contentLibraryService.moreContentLoading$ | async)"
    ></df-page-list-loader>
  </div>
  <div
    *ngIf="(contentLibraryService.loading$ | async) === false && !(contentLibraryService.collection$ | async)?.length"
    class="no-content-wrapper"
  >
    <df-funnel-content-genereter-library-no-content
      [funnelId]="funnelId"
    ></df-funnel-content-genereter-library-no-content>
  </div>
  <df-button-new (click)="onCreateNewContent()"></df-button-new>

  <df-onboarding-video [src]="'https://www.youtube.com/embed/CXLaTDW0mng?si=BQjontfPwktQmBIb'"></df-onboarding-video>
</div>
