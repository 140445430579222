import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function statementFormArrayValidator(maxLength = 0): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: any = control.value;
    if (value === null || value === undefined || !Array.isArray(value)) return { notArrayError: true };
    if (maxLength > 0 && value.length > maxLength) return { maxLenghtError: true };
    if (value.length === 0) return { emptyCollectionError: true };
    return null;
  };
}

export function naturalNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: any = control.value;
    return null === value || undefined === value || isNaN(value) ? { incorrectValueError: true } : null;
  };
}
