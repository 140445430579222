import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { XPartnerService } from './../../../../../shared/services/x-partner.service';
import { KompassifyService, KompassifyTourEventType } from '@shared/services/kompassify.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'df-statement-step-footer',
  templateUrl: './statement-step-footer.component.html',
  styleUrls: ['./statement-step-footer.component.scss'],
})
export class StatementStepFooterComponent extends AbstractSubscriptionComponent implements OnInit {
  get noEvents(): boolean {
    return this._noEvents;
  }

  set noEvents(value: boolean) {
    this._noEvents = value;
    this.changes.detectChanges();
  }

  @Input() nextLink = '';
  @Input() prevLink = '';
  @Input() prevSkipLocationChange = false;
  @Input() nextText = '';
  @Input() prevText = '';
  @Input() skipText = '';
  @Input() step = 1;
  @Input() loading = false;
  @Input() isPublic = false;

  @Output() onNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSkip: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _noEvents = false;

  constructor(
    private readonly kompassifyService: KompassifyService,
    private readonly changes: ChangeDetectorRef,
    public xPartnerService: XPartnerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub.add(
      this.kompassifyService.tourEvent$
        .pipe(filter((event) => event.type === KompassifyTourEventType.SHOWING))
        .subscribe(this.disableAndWaitForClosing.bind(this)),
    );
  }

  private disableAndWaitForClosing(): void {
    this.noEvents = true;
    this.sub.add(
      this.kompassifyService.tourEvent$
        .pipe(
          filter((event) => [KompassifyTourEventType.NEXT, KompassifyTourEventType.SKIPPED].includes(event.type)),
          take(1),
        )
        .subscribe(() => (this.noEvents = false)),
    );
  }
}
