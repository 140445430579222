<ng-container *ngIf="!loading">
  <a
    class="add-remove-link"
    [class.cursor-default]="disabled"
    tuiLink
    icon="tuiIconPlus"
    iconAlign="left"
    *ngIf="count <= 0"
    (click)="add()"
    >{{ 'add' | translate }}</a
  >
  <span
    class="add-remove-count"
    *ngIf="count > 0"
  >
    <a
      class="add-remove-link"
      [class.cursor-default]="disabled"
      tuiLink
      icon="tuiIconMinus"
      iconAlign="left"
      (click)="remove()"
    ></a>
    {{ count }}
    <a
      class="add-remove-link"
      [class.cursor-default]="disabled"
      tuiLink
      icon="tuiIconPlus"
      iconAlign="right"
      (click)="add()"
    ></a>
  </span>
</ng-container>

<tui-loader
  *ngIf="loading"
  [size]="'xs'"
></tui-loader>
