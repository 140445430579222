<div
  class="w-full h-full absolute top-0 left-0 z-50 tui-base-01-bg"
  *ngIf="public && buyFromParam && (buyPlanLoading || loading)"
></div>
<df-full-list-loader *ngIf="loading || buyPlanLoading"></df-full-list-loader>

<div class="container-center pb-10 md:pb-20 lg:pb-36">
  <header
    class="px-4 md:px-8 lg:px-16 pb-8 md:pb-10 text-center sm:text-left flex flex-col items-center sm:items-start"
  >
    <h1
      class="{{
        !public && noActivePlan ? 'tui-text_h3' : 'tui-text_h1'
      }} tui-primary mb-1.5 md:mb-3.5 mt-4 md:mt-6 lg:mt-16"
    >
      {{ (!public && noActivePlan ? 'Pricing.Select your plan to continue' : 'Pricing.Pricing Plans') | translate }}
    </h1>
    <p
      class="tui-text_h5"
      [class.max-w-lg]="!appSumo && usedTrial"
    >
      {{
        (appSumo
          ? 'Pricing.See details of your AppSumo Lifetime Plan'
          : !usedTrial
            ? getTrialText()
            : 'Pricing.Start
      growing with the best options for you!'
        ) | translate
      }}
    </p>
  </header>

  <div
    id="packets"
    class="packets-container tui-base-01-bg rounded-3xl border-0 shadow-2xl px-4 sm:px-4 md:px-6 xl:px-8 py-6 md:py-8 packets-{{
      plans.length === 1 ? 3 : plans.length
    }}"
  >
    <div
      class="packets__trial my-6"
      *ngIf="!public && noActivePlan"
    >
      <svg-icon
        src="assets/img/ic_exclamation_mark.svg"
        class="mb-3 md:mb-0 md:mr-3"
      ></svg-icon>
      <p
        class="tui-text_h6 font-semibold tui-text-03"
        [innerHTML]="'Pricing.Select a plan that fits to your needs!' | translate"
      ></p>
    </div>

    <div
      class="packets"
      [ngClass]="{ 'packets-appsumo': appSumo }"
    >
      <div
        class="packets__container"
        *ngIf="!loading"
      >
        <div class="packets__col">
          <div class="packets__packet">
            <p class="tui-text_h2 tui-text-03 font-semibold mb-6 h-tui-text_h2">
              {{ 'Pricing.Our Plans' | translate }}
            </p>
            <p>All pricing plans are listed in USD and paid annually</p>
            <df-pricing-period-switch
              class="hidden"
              *ngIf="!this.appSumo"
            ></df-pricing-period-switch>
          </div>
        </div>

        <ng-container *ngFor="let plan of plans; let i = index">
          <div class="packets__col">
            <div
              *ngIf="plan.name === 'Pro Plan'"
              class="save-now-badge"
            >
              Save now 53%
            </div>
            <div
              *ngIf="plan.name === 'Expert Plan'"
              class="save-now-badge"
            >
              Save now 60%
            </div>
            <div
              *ngIf="plan.name === 'Unlimited Lifetime'"
              class="save-now-badge save-now-badge--unlimited"
            >
              Placeholder
            </div>
            <tui-island
              *ngIf="getPriceForInterval(plan.prices, plan.strength === 501) as price"
              class="packets__packet"
              [ngClass]="{
                active: isPlanActive(plan),
                presale: plan.isPresale,
                popular: plan.name === defaultTrialPlan?.name
              }"
            >
              <div class="packets__packet--popular-badge py-0.5 px-1.5">
                {{ 'Pricing.Most popular' | translate }}
              </div>
              <div class="packets__packet--presale-badge px-2.5 py-1">
                <svg-icon
                  class="mr-1.5"
                  src="/assets/img/ic-percentage.svg"
                ></svg-icon>
                <span>{{ 'Pricing.Presale' | translate }}</span>
              </div>
              <div class="flex items-center mb-8">
                <p
                  *ngIf="plan.name === 'Unlimited Lifetime'"
                  class="packets__packet--name unlimited-text"
                >
                  ENTERPRISE
                </p>
                <p
                  *ngIf="plan.name === 'Expert Plan'"
                  class="tui-text-03 packets__packet--name"
                >
                  EXPERT
                </p>
                <p
                  *ngIf="plan.name === 'Pro Plan'"
                  class="tui-text-03 packets__packet--name"
                >
                  PRO
                </p>
                <p
                  *ngIf="appSumo"
                  class="tui-text-03 packets__packet--name"
                >
                  {{ plan.name }}
                </p>
                <span
                  class="text-sm ml-3 text-[--tui-primary]"
                  *ngIf="isPlanActive(plan)"
                >
                  {{ (usingTrial ? 'Pricing.Trial version' : 'Pricing.Current plan') | translate }}
                </span>
              </div>
              <div id="block_container">
                <p
                  id="bloc-left"
                  class="i18n-ex tui-primary packets__packet--price"
                >
                  <span
                    *ngIf="plan.name !== 'Unlimited Lifetime'"
                    class="currency"
                  >
                    {{ 0 | currency: price.currency.toUpperCase() : 'symbol' : '0.0-0' | slice: 0 : 1 }}
                  </span>
                  <span
                    class="text-base mb-8"
                    *ngIf="plan.name === 'Unlimited Lifetime'; else elseBlock"
                    style="color: #ffffff"
                    >Custom setup and account monitoring, dedicated AI consultations & training, safe rooms, early
                    access to features</span
                  >
                  <ng-template #elseBlock>
                    {{
                      getIntegerMonthlyPriceAmount(price.amount, plan.strength === 501)!
                        | currency: price.currency.toUpperCase() : '' : '1.0-0'
                    }}</ng-template
                  >
                  <ng-container
                    id="#bloc-right"
                    *ngIf="
                      pricingService.checkPaymentInterval(PaymentIntervalEnum.Month) &&
                      !appSumo &&
                      plan.strength !== 501
                    "
                    class="mo"
                  >
                    <span class="mo">
                      {{
                        price.paymentInterval !== PaymentIntervalEnum.Lifetime
                          ? ('Pricing.Mo' | translate)
                          : ('Pricing.month' | translate)
                      }}
                    </span>
                  </ng-container>
                  <ng-container
                    id="#bloc-right"
                    *ngIf="
                      pricingService.checkPaymentInterval(PaymentIntervalEnum.Year) && !appSumo && plan.strength !== 501
                    "
                  >
                    <span class="mo">
                      {{
                        price.paymentInterval !== PaymentIntervalEnum.Lifetime
                          ? ('Pricing.Mo' | translate)
                          : ('Pricing.annual' | translate)
                      }}
                    </span>
                  </ng-container>
                  <ng-container
                    id="#bloc-right"
                    *ngIf="
                      pricingService.checkPaymentInterval(PaymentIntervalEnum.Lifetime) &&
                      appSumo &&
                      plan.strength !== 501
                    "
                  >
                    <span class="mo">
                      {{ 'Pricing.Mo' | translate }}
                    </span>
                  </ng-container>
                </p>
              </div>

              <div class="flex items-center">
                <div
                  class="amount__year"
                  *ngIf="plan.strength !== 501"
                >
                  <div *ngIf="pricingService.checkPaymentInterval(PaymentIntervalEnum.Month)">
                    <span> ${{ calculateYearPrice(price.amount | price) }} / billed yearly </span>
                  </div>

                  <div
                    class="flex flex-col"
                    *ngIf="pricingService.checkPaymentInterval(PaymentIntervalEnum.Year)"
                  >
                    <span
                      *ngIf="plan.strength === 300"
                      class="amount__year-text amount__year-text--grey"
                      >$1699 / YR</span
                    >
                    <span
                      *ngIf="plan.strength === 400"
                      class="amount__year-text amount__year-text--grey"
                      >$2999 / YR</span
                    >
                    <span class="amount__year-text"> ${{ price.amount | price }} / billed yearly </span>
                  </div>

                  <div *ngIf="pricingService.checkPaymentInterval(PaymentIntervalEnum.Lifetime) && appSumo">
                    <span> ${{ calculateYearPrice(price.amount | price) }} / billed yearly </span>
                  </div>
                </div>
              </div>

              <div
                class="packets-description"
                *ngIf="LayoutViewType.Mobile === layoutConfig.viewType"
              >
                <p
                  class="packets-description__head"
                  [ngClass]="{
                    'packets-description__head--unlimited': plan.name === 'Unlimited Lifetime'
                  }"
                >
                  {{ 'Pricing.Ai Superpowers.Ai Superpowers' | translate }}
                </p>
                <div class="packets-description-item">
                  <p
                    class="packets-description-item__name"
                    [ngClass]="{
                      'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                    [tuiHint]="'Pricing.Ai Superpowers.AI Strategy - tooltip' | translate"
                    [tuiHintShowDelay]="0"
                    [tuiHintHideDelay]="0"
                  >
                    {{ 'Pricing.Ai Superpowers.AI Strategy' | translate }}
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Pro Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Expert Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val packets-description-item__val--unlimited"
                    *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                  >
                    Unlimited
                  </p>
                </div>
                <div class="packets-description-item">
                  <p
                    class="packets-description-item__name"
                    [ngClass]="{
                      'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                    [tuiHint]="'Pricing.Ai Superpowers.Business canvases - tooltip' | translate"
                    [tuiHintShowDelay]="0"
                    [tuiHintHideDelay]="0"
                  >
                    {{ 'Pricing.Ai Superpowers.Business canvases' | translate }}
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Pro Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Expert Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val packets-description-item__val--unlimited"
                    *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                  >
                    Unlimited
                  </p>
                </div>
                <div class="packets-description-item">
                  <p
                    class="packets-description-item__name"
                    [ngClass]="{
                      'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                    [tuiHint]="'Pricing.Ai Superpowers.ChatGPT-4 - tooltip' | translate"
                    [tuiHintShowDelay]="0"
                    [tuiHintHideDelay]="0"
                  >
                    {{ 'Pricing.Ai Superpowers.ChatGPT-4' | translate }}
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Pro Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Expert Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val packets-description-item__val--unlimited"
                    *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                  >
                    Unlimited
                  </p>
                </div>
                <div class="packets-description-item">
                  <p
                    class="packets-description-item__name"
                    [ngClass]="{
                      'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                    [tuiHint]="'Pricing.Ai Superpowers.AI copywriting - tooltip' | translate"
                    [tuiHintShowDelay]="0"
                    [tuiHintHideDelay]="0"
                  >
                    {{ 'Pricing.Ai Superpowers.AI copywriting' | translate }}
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Pro Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Expert Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val packets-description-item__val--unlimited"
                    *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                  >
                    Unlimited
                  </p>
                </div>
                <div class="packets-description-item">
                  <p
                    class="packets-description-item__name"
                    [ngClass]="{
                      'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                    [tuiHint]="'Pricing.Ai Superpowers.AI Design creator - tooltip' | translate"
                    [tuiHintShowDelay]="0"
                    [tuiHintHideDelay]="0"
                  >
                    {{ 'Pricing.Ai Superpowers.AI Design creator' | translate }}
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Pro Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Expert Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val packets-description-item__val--unlimited"
                    *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                  >
                    Unlimited
                  </p>
                </div>
                <div class="packets-description-item">
                  <p
                    class="packets-description-item__name"
                    [ngClass]="{
                      'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                    [tuiHint]="'Pricing.Ai Superpowers.Marketing Graphics - tooltip' | translate"
                    [tuiHintShowDelay]="0"
                    [tuiHintHideDelay]="0"
                  >
                    {{ 'Pricing.Ai Superpowers.Marketing Graphics' | translate }}
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Pro Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Expert Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val packets-description-item__val--unlimited"
                    *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                  >
                    Unlimited
                  </p>
                </div>
                <div class="packets-description-item">
                  <p
                    class="packets-description-item__name"
                    [ngClass]="{
                      'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                    [tuiHint]="'Pricing.Ai Superpowers.Asset library - tooltip' | translate"
                    [tuiHintShowDelay]="0"
                    [tuiHintHideDelay]="0"
                  >
                    {{ 'Pricing.Ai Superpowers.Asset library' | translate }}
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Pro Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'Expert Plan'"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val"
                    *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                  >
                    <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                  </p>
                  <p
                    class="packets-description-item__val packets-description-item__val--unlimited"
                    *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                  >
                    Unlimited
                  </p>
                </div>
                <tui-expand [expanded]="planeNameArray[i]">
                  <p
                    class="packets-description__head"
                    [ngClass]="{
                      'packets-description__head--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                  >
                    {{ 'Pricing.Marketing features.Marketing features' | translate }}
                  </p>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Marketing features.Tactics recommendations - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Marketing features.Tactics recommendations' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Marketing features.Tactics library - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Marketing features.Tactics library' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Marketing features.Create own tactics - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Marketing features.Create own tactics' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="
                        'Pricing.Marketing features.Publish tactics and marketplace services - tooltip' | translate
                      "
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Marketing features.Publish tactics and marketplace services' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Marketing features.Funnel & tactics share - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Marketing features.Funnel & tactics share' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <p
                    class="packets-description__head"
                    [ngClass]="{
                      'packets-description__head--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                  >
                    {{ 'Pricing.Team.Team' | translate }}
                  </p>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Team.Team collaboration - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Team.Team collaboration' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      up to 3
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      up to 10
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      up to 0
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      up to 3
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      up to 10
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      up to 25
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Team.Flow management - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Team.Flow management' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Team.Hire Growth Hackers - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Team.Hire Growth Hackers' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Team.Become a Growth Hacker - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Team.Become a Growth Hacker' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <p
                    class="packets-description__head"
                    [ngClass]="{
                      'packets-description__head--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                  >
                    {{ 'Pricing.Other features.Other features' | translate }}
                  </p>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Other features.Funnel management - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Other features.Funnel management' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      up to 10
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      up to 50
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      up to 1
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      up to 10
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      Unlimited
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      Unlimited
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Other features.Export PDF with funnel - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Other features.Export PDF with funnel' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="
                        'Pricing.Other features.NFT token with possibility to resell plan - tooltip' | translate
                      "
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Other features.NFT token with possibility to resell plan' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="
                        'Pricing.Other features.Vote the roadmap and decide about features - tooltip' | translate
                      "
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Other features.Vote the roadmap and decide about features' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="
                        'Become AI-ready with our comprehensive strategic workshops, focused on leveraging AI for your campaign strategies and execution. AI is a new frontier, and we\'re here to guide you through every step. Get untlimited consultations to tailor Digital First AI perfectly to your current operations and needs.'
                      "
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      AI-ready Strategic Workshops
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>

                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="
                        'With a Dedicated Customer Success Manager, you’ll receive a bespoke setup of your Digital First AI account. Get insights into achieving your marketing objectives with AI, from generating AI marketing strategies to running campaigns and getting the content you need. Benefit from tailored support, implementation and solutions–ensuring a personalized experience from start to finish.'
                      "
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      Personalized Implementation & Setup
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>

                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="
                        'Receive dedicated support throughout your Digital First AI experience, from onboarding, trainings for team members, to ongoing consultations. A dedicated Customer Success Manager will ensure you and your team are well-equipped for AI-driven marketing, offering personalized training and regular check-ins.'
                      "
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      Tailor-made Onboarding and Trainings
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>

                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="
                        'Directly request custom enterprise-level features that cater specifically to your business needs. With custom implementation and direct feedback for our team, you\'ll be able to shape Digital First AI\'s capabilities.'
                      "
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      Custom Feature Requests
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>

                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="
                        'Be at the forefront of the AI marketing revolution as an early adopter of Digital First AI. Your company will have early access to enterprise-level features, contributing to this transformative and epoch-making period of marketing innovation.'
                      "
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      Early Access Beta Testing
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>

                  <p
                    class="packets-description__head"
                    [ngClass]="{
                      'packets-description__head--unlimited': plan.name === 'Unlimited Lifetime'
                    }"
                  >
                    {{ 'Pricing.Social.Social' | translate }}
                  </p>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Social.Discord community access - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Social.Discord community access' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                  <div class="packets-description-item">
                    <p
                      class="packets-description-item__name"
                      [ngClass]="{
                        'packets-description-item__name--unlimited': plan.name === 'Unlimited Lifetime'
                      }"
                      [tuiHint]="'Pricing.Social.Discord token owners channels - tooltip' | translate"
                      [tuiHintShowDelay]="0"
                      [tuiHintHideDelay]="0"
                    >
                      {{ 'Pricing.Social.Discord token owners channels' | translate }}
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Pro Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'Expert Plan'"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 1' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 2' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val"
                      *ngIf="plan.name === 'AppSumo Tier 3' && appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
                    </p>
                    <p
                      class="packets-description-item__val packets-description-item__val--unlimited"
                      *ngIf="plan.name === 'Unlimited Lifetime' && !appSumo"
                    >
                      <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
                    </p>
                  </div>
                </tui-expand>
                <button
                  class="packets-description__btn"
                  type="button"
                  (click)="toggleMobileDescription(i)"
                >
                  {{ planeNameArray[i] === false ? 'More +' : 'Less -' }}
                </button>
              </div>

              <ng-container *ngIf="!appSumo && plan.strength !== 501">
                <button
                  data-test="start-free-trial-button"
                  class="self-center w-full packets__button"
                  [ngClass]="{
                    'tui-base-01-bg': price.amount > 0,
                    'mb-7': !plan.trialDays
                  }"
                  tuiButton
                  (click)="price.amount > 0 ? choosePlan(plan) : goToFunnel()"
                  [appearance]="'primary'"
                  size="l"
                  type="button"
                >
                  {{
                    (price.amount === 0
                      ? 'Pricing.free plan'
                      : !(noActivePlan || (usingTrial && !urlToPayments))
                        ? 'Pricing.Manage'
                        : !usedTrial
                          ? 'Pricing.start trial'
                          : 'Pricing.select plan'
                    ) | translate
                  }}
                </button>

                <p
                  class="tui-text_body-s tui-text-05 mb-0.5 self-center"
                  *ngIf="xPartnerService.getIsPartner(Partners.OXFORD_IE) && plan.name.includes('Expert')"
                >
                  {{ 'Pricing.trial days' | translate: { days: 30 } }}
                </p>

                <p
                  class="m-0 mt-1 tui-text_body-s tui-text-04 self-center packets__packet--promo"
                  *ngIf="price.amount > 0 && (noActivePlan || (usingTrial && !urlToPayments))"
                >
                  {{ 'Pricing.or' | translate }}&nbsp;<a
                    class="tui-text_body-s tui-primary no-underline hover:underline cursor-pointer"
                    (click)="choosePlan(plan, true)"
                    >{{ 'Pricing.use promotional code' | translate }}</a
                  >
                </p>
              </ng-container>
              <ng-container *ngIf="plan.strength === 501">
                <button
                  class="self-center w-full packets__button"
                  [ngClass]="{
                    'tui-base-01-bg': price.amount > 0,
                    'mb-7': !plan.trialDays
                  }"
                  tuiButton
                  [appearance]="'primary'"
                  size="l"
                  type="button"
                  (click)="openIntercom()"
                >
                  Let's talk
                </button>
              </ng-container>
            </tui-island>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="LayoutViewType.Mobile !== layoutConfig.viewType">
        <div
          class="packets-details"
          *ngIf="!loading"
        >
          <div class="packets-details__row packets-details__row--head">
            <p class="packets-details__text">
              {{ 'Pricing.Ai Superpowers.Ai Superpowers' | translate }}
            </p>
            <div
              class="helper-bg"
              *ngIf="!appSumo"
            ></div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Ai Superpowers.AI Strategy - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Ai Superpowers.AI Strategy' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text packets-details__text--unlimited packets-details__text--center">
                Unlimited
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Ai Superpowers.Business canvases - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Ai Superpowers.Business canvases' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text packets-details__text--unlimited packets-details__text--center">
                Unlimited
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Ai Superpowers.ChatGPT-4 - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Ai Superpowers.ChatGPT-4' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text packets-details__text--unlimited packets-details__text--center">
                Unlimited
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Ai Superpowers.AI copywriting - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Ai Superpowers.AI copywriting' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text packets-details__text--unlimited packets-details__text--center">
                Unlimited
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Ai Superpowers.AI Design creator - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Ai Superpowers.AI Design creator' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text packets-details__text--unlimited packets-details__text--center">
                Unlimited
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Ai Superpowers.Marketing Graphics - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Ai Superpowers.Marketing Graphics' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text packets-details__text--unlimited packets-details__text--center">
                Unlimited
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Ai Superpowers.Asset library - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Ai Superpowers.Asset library' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-with-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text packets-details__text--unlimited packets-details__text--center">
                Unlimited
              </p>
            </div>
          </div>

          <div class="packets-details__row packets-details__row--head">
            <p class="packets-details__text">
              {{ 'Pricing.Marketing features.Marketing features' | translate }}
            </p>
            <div
              class="helper-bg"
              *ngIf="!appSumo"
            ></div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Marketing features.Tactics recommendations - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Marketing features.Tactics recommendations' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Marketing features.Tactics library - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Marketing features.Tactics library' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Marketing features.Create own tactics - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Marketing features.Create own tactics' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Marketing features.Publish tactics and marketplace services - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Marketing features.Publish tactics and marketplace services' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Marketing features.Funnel & tactics share - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Marketing features.Funnel & tactics share' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>

          <div class="packets-details__row packets-details__row--head">
            <p class="packets-details__text">
              {{ 'Pricing.Team.Team' | translate }}
            </p>
            <div
              class="helper-bg"
              *ngIf="!appSumo"
            ></div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Team.Team collaboration - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Team.Team collaboration' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text packets-details__text--center">up to 3</p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text packets-details__text--center">up to 10</p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text packets-details__text--unlimited packets-details__text--center">
                up to 25
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Team.Flow management - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Team.Flow management' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Team.Hire Growth Hackers - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Team.Hire Growth Hackers' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Team.Become a Growth Hacker - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Team.Become a Growth Hacker' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>

          <div class="packets-details__row packets-details__row--head">
            <p class="packets-details__text">
              {{ 'Pricing.Other features.Other features' | translate }}
            </p>
            <div
              class="helper-bg"
              *ngIf="!appSumo"
            ></div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Other features.Funnel management - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Other features.Funnel management' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text packets-details__text--center">up to 10</p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text packets-details__text--center">
                {{ appSumo ? 'Unlimited' : 'up to 50' }}
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text packets-details__text--unlimited packets-details__text--center">
                Unlimited
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Other features.Export PDF with funnel - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Other features.Export PDF with funnel' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Other features.NFT token with possibility to resell plan - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Other features.NFT token with possibility to resell plan' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Other features.Vote the roadmap and decide about features - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Other features.Vote the roadmap and decide about features' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="
                  'Become AI-ready with our comprehensive strategic workshops, focused on leveraging AI for your campaign strategies and execution. AI is a new frontier, and we\'re here to guide you through every step. Get untlimited consultations to tailor Digital First AI perfectly to your current operations and needs.'
                "
                tuiHintDirection="right"
              >
                AI-ready Strategic Workshop
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>

          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="
                  'With a Dedicated Customer Success Manager, you’ll receive a bespoke setup of your Digital First AI account. Get insights into achieving your marketing objectives with AI, from generating AI marketing strategies to running campaigns and getting the content you need. Benefit from tailored support, implementation and solutions–ensuring a personalized experience from start to finish.'
                "
                tuiHintDirection="right"
              >
                Personalized Implementation & Setup
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>

          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="
                  'Receive dedicated support throughout your Digital First AI experience, from onboarding, trainings for team members, to ongoing consultations. A dedicated Customer Success Manager will ensure you and your team are well-equipped for AI-driven marketing, offering personalized training and regular check-ins.'
                "
                tuiHintDirection="right"
              >
                Tailor-made Onboarding and Trainings
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>

          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="
                  'Directly request custom enterprise-level features that cater specifically to your business needs. With custom implementation and direct feedback for our team, you\'ll be able to shape Digital First AI\'s capabilities.'
                "
                tuiHintDirection="right"
              >
                Custom Feature Requests
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>

          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="
                  'Be at the forefront of the AI marketing revolution as an early adopter of Digital First AI. Your company will have early access to enterprise-level features, contributing to this transformative and epoch-making period of marketing innovation.'
                "
                tuiHintDirection="right"
              >
                Early Access Beta Testing
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>

          <div class="packets-details__row packets-details__row--head">
            <p class="packets-details__text">
              {{ 'Pricing.Social.Social' | translate }}
            </p>
            <div
              class="helper-bg"
              *ngIf="!appSumo"
            ></div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Social.Discord community access - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Social.Discord community access' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
          <div class="packets-details__row">
            <div class="packets-details__item">
              <p
                class="packets-details__name"
                [tuiHint]="'Pricing.Social.Discord token owners channels - tooltip' | translate"
                tuiHintDirection="right"
              >
                {{ 'Pricing.Social.Discord token owners channels' | translate }}
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div class="packets-details__item">
              <p class="packets-details__text-icon">
                <svg-icon src="/assets/img/ic-pricing-uncheck.svg"></svg-icon>
              </p>
            </div>
            <div
              class="packets-details__item"
              *ngIf="!appSumo"
            >
              <p class="packets-details__text-icon packets-details__text--unlimited helper-rounded">
                <svg-icon src="/assets/img/ic-pricing-check.svg"></svg-icon>
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
