import { gql } from 'apollo-angular';
import { IntegrationOutputDataFragment } from '@modules/funnels/shared/graphql/fragments/integration-output-data.fragment';

export const EditOutputDataDocument = gql`
  mutation editOutputData($input: EditOutputDataInputGraphql!) {
    editOutputData(input: $input) {
      ...IntegrationOutputData
    }
  }
  ${IntegrationOutputDataFragment}
`;
