import { EventEmitter, Injectable, Injector } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { Observable, Subscriber, Subscription, of } from 'rxjs';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Router } from '@angular/router';
import { PaymentIntervalEnum, PermissionType } from '@modules/graphql/graphql-types';
import { Config } from '@shared/configs/config';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { LightPaywallModalComponent } from '@modules/pricing/shared/components/light-paywall-modal/light-paywall-modal.component';
import { ContentAccessDialogService } from '@modules/tactics/shared/components/content-access-dialog/content-access-dialog.service';
import { SemrushService } from '@shared/services/semrush.service';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  readonly PaymentIntervalEnum = PaymentIntervalEnum;
  currentStream: EventEmitter<PricingStreamEvent> = new EventEmitter<PricingStreamEvent>();
  currentObserver: Subscriber<boolean> | null = null;
  sub: Subscription = new Subscription();
  lookingForPermission: PermissionType | null = null;
  paymentInterval!: string; // currently chosen interval

  constructor(
    private userService: UserService,
    private n: NavigateService,
    private router: Router,
    public t: TranslateService,
    private dialogService: TuiDialogService,
    private injector: Injector,
    private contentAccessDialogService: ContentAccessDialogService,
    private semrushService: SemrushService,
  ) {
    this.listenCancelStream();
    this.initializePaymentInterval();
  }

  private listenCancelStream() {
    this.currentStream.subscribe((e: PricingStreamEvent) => {
      if (e.type === PricingStreamEventType.CANCEL_STREAM) {
        this.clearSubscription();
        if (window.location.href.indexOf('sign') !== -1) {
          //for statement or registration process
          this.n.go(Config.MAIN_VIEW)?.then(() => {
            this.router.navigate([{ outlets: { pricing: null } }], {
              skipLocationChange: true,
            });
          });
        } else {
          this.router.navigate([{ outlets: { pricing: null } }], {
            skipLocationChange: true,
          });
        }
      }
    });
  }

  private clearSubscription() {
    this.currentObserver?.complete();
    this.currentObserver = null;
    this.lookingForPermission = null;
    this.sub.unsubscribe();
    this.sub = new Subscription();
  }

  public initializePaymentInterval(): void {
    this.paymentInterval = PaymentIntervalEnum.Year;
  }

  public checkPricing(type: PermissionType, currentAmount = 0): Observable<boolean> {
    const user = this.userService.User!;
    this.lookingForPermission = type;
    return new Observable<boolean>((observer) => {
      this.currentObserver = observer;
      if (user.hasAccess(type, currentAmount)) {
        //can do the thing
        observer.next(true);
        observer.complete();
        this.clearSubscription();
        return;
      }
      //need buy packet
      if (type === PermissionType.AiCopywriting) {
        this.contentAccessDialogService.open('').subscribe({
          complete: () => {
            observer.complete();
            this.clearSubscription();
          },
        });
      } else {
        this.openPaywallModal().subscribe({
          next: (res: boolean) => {
            if (res) {
              this.openPackets();
            }
          },
          complete: () => {
            observer.complete();
            this.clearSubscription();
          },
        });
      }
    });
  }

  private openPaywallModal() {
    if (this.userService.User?.isSemrushPlan()) {
      this.semrushService.showUpsell();
      return of(false);
    }

    return this.dialogService.open<boolean>(new PolymorpheusComponent(LightPaywallModalComponent, this.injector), {
      dismissible: true,
      closeable: true,
      size: 'm',
    });
  }

  openPackets() {
    this.router.navigate(['', { outlets: { pricing: this.userService.resolvePricingPacketsUrl() } }], {
      skipLocationChange: true,
    });
  }

  setPaymentInterval(paymentIntervalType: string) {
    this.paymentInterval = paymentIntervalType;
  }

  checkPaymentInterval(paymentInterval: string) {
    return this.paymentInterval.toString() === paymentInterval.toString();
  }
}

export interface PricingStreamEvent {
  type: PermissionType | PricingStreamEventType;
  access: boolean;
}

export enum PricingStreamEventType {
  CANCEL_STREAM,
}

export interface UpcomingFeature {
  name: string;
}

export const UPCOMING_FEATURES: UpcomingFeature[] = [
  { name: 'Analytics Integration' },
  { name: 'AI Recommendations 2.0 / Growth Machine' },
  { name: 'Webflow Integration' },
  { name: 'Buffer Integration' },
  { name: 'Growth-Hackers Marketplace' },
  { name: 'Business & Marketing Canvases (x7)' },
  { name: 'Export funnels to PDF' },
  { name: 'Zapier Integration' },
  { name: 'CRM Integration' },
  { name: 'AI Landing Pages' },
  { name: '1-Click Content Generation' },
  { name: 'Marketing Tasks Management' },
  { name: 'BannerBear integration (automatic design)' },
  { name: 'Workspaces' },
  { name: 'Marketing Assistant' },
];
