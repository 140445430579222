<div
  class="help-ctr w-full justify-between items-center"
  *ngIf="!userService.User?.isSemrushPlan()"
>
  <df-funnel-overview-help-item
    class="help-ctr__tile"
    *ngFor="let item of helpItems; let i = index"
    [title]="item.title | translate"
    [subtitle]="item.subtitle | translate"
    [icon]="item.icon"
    (onRedirect)="item.onRedirect()"
  >
  </df-funnel-overview-help-item>
</div>
