import { Component, OnInit } from '@angular/core';
import { BaseWorkFlowsService } from '@modules/work-flows/services/base-work-flows.service';

@Component({
  selector: 'df-funnel-campaign',
  templateUrl: './funnel-campaign.component.html',
})
export class FunnelCampaignComponent implements OnInit {
  docName!: string;

  ngOnInit(): void {
    this.docName = localStorage.getItem(BaseWorkFlowsService.CURRENT_DOC_NAME_KEY)!;
  }
}
