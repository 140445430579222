import { gql } from 'apollo-angular';

export const ConfirmEmailDocument = gql/* GraphQL*/ `
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token) {
      token
      user {
        firstName
        lastName
        email
      }
    }
  }
`;
