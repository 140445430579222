import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AppInjector } from '@core/services/app-injector.service';
import { NotEnoughCreditsSmallModalComponent } from '@modules/pricing/shared/components/not-enough-credits-small-modal/not-enough-credits-small-modal.component';
import { TuiDialogOptions, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CreditsService {
  private readonly injector: Injector;
  private readonly dialogService: TuiDialogService;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
  ) {
    this.injector = AppInjector.getInjector();
    this.dialogService = this.injector.get(TuiDialogService);
  }

  private hasEnoughCredits(requiredCredits: number): boolean {
    return (this.userService.User?.credits || 0) >= requiredCredits;
  }

  canGenerateContent(requiredCredits: number): boolean {
    return this.hasEnoughCredits(requiredCredits);
  }

  showNotEnoughCreditsPopup(): void {
    this.dialogService
      .open<number>(
        new PolymorpheusComponent(NotEnoughCreditsSmallModalComponent, this.injector),
        this.resolveModalConfiguration(),
      )
      .subscribe((response) => this.handleModalResponse(!!response));
  }

  private resolveModalConfiguration(): Partial<TuiDialogOptions<object>> {
    return {
      data: {},
      size: 's',
      dismissible: false,
      closeable: true,
    };
  }

  private handleModalResponse(shouldNavigateToPricing: boolean): void {
    if (!shouldNavigateToPricing) return;
    this.router.navigate(['', { outlets: { pricing: this.userService.resolvePricingPacketsUrl() } }], {
      skipLocationChange: true,
    });
  }
}
