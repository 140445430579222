import { gql } from 'apollo-angular';

export const FindAllPromptsDocument = gql(`query findAllPrompts
{
    findAllPrompts
  {
    id
    name
    description
    settingsIntegration
    promptText
    promptProperty
    category
    status
    settingsActive
  }
}

`);
