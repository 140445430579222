<ng-container *ngIf="funnelTactic">
  <div class="flex items-center flex-shrink">
    <ng-container *ngFor="let step of steps">
      <svg-icon
        *ngIf="step.type as type"
        [src]="'/assets/img/ic-' + type + '.svg'"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
        class="mr-1.5"
      ></svg-icon>
    </ng-container>
  </div>

  <div
    *ngFor="let badge of badges"
    class="mr-1.5 font-bold truncate rounded-md flex flex-shrink items-center h-full pl-2 pr-2 uppercase tui-text_body-xs tui-text-02"
    [ngClass]="{
      'border border-solid tui-base-04-border': appearance === 'outline',
      'tui-base-02': appearance === 'flat',
      'tui-support-23-bg opacity-70': funnelTactic.isDone && doneEffect
    }"
  >
    <span class="truncate">{{ badge }}</span>
  </div>
</ng-container>
