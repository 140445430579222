import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';

@Component({
  selector: 'df-public-funnel-modal',
  templateUrl: './public-funnel-modal.component.html',
  styleUrls: ['./public-funnel-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicFunnelModalComponent {
  @Input() funnelTactic!: FunnelTactic;
  @Input() inCampaign = false;
  @Output() tacticClicked = new EventEmitter();

  showModal = false;

  toggleTacticPreview(value: boolean) {
    if (this.inCampaign) {
      this.tacticClicked.emit();
      return;
    }

    this.showModal = value;
  }
}
