import { Component, Inject } from '@angular/core';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { GlobalDataService } from '@shared/services/global-data.service';
import { UserService } from '@shared/services/user.service';
import { XPartnerService } from '@shared/services/x-partner.service';

@Component({
  selector: 'df-light-paywall-modal',
  templateUrl: './light-paywall-modal.component.html',
  styleUrls: ['./light-paywall-modal.component.scss'],
})
export class LightPaywallModalComponent {
  hasPresale: boolean;

  get permissionName(): string {
    return (
      this.context?.data?.permission?.name ??
      this.globalData.getSinglePermission(this.pricingService!.lookingForPermission!)?.name ??
      ''
    );
  }

  constructor(
    public pricingService: PricingService,
    public globalData: GlobalDataService,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { permission: { name: string } } }, boolean>,
    public userService: UserService,
    public xPartnerService: XPartnerService,
  ) {
    this.hasPresale =
      this.userService.User?.activePlans.some((plan) => plan.name?.toLowerCase().includes('presale')) ?? false;
  }

  goToPackets() {
    this.context.completeWith(true);
  }

  close(): void {
    this.context.completeWith(false);
  }
}
