<div class="tui-base-01-bg border border-solid tui-base-04-border tui-text-04-border-hvr rounded-lg p-4">
  <div
    class="flex flex-col justify-start items-stretch"
    *ngIf="data"
  >
    <div class="flex justify-between items-center">
      <p class="tui-text-body-xs tui-text-05">
        <span>{{ data.modifiedAt ?? data.createdAt | date: 'medium' }}</span
        >&nbsp;<span *ngIf="!displayMode && (data.isSelected || !!data.modifiedAt)"
          >•
          <span [class.tui-primary]="data.isSelected">{{
            'Funnels.Content generator.' + (data.isSelected ? 'Selected' : 'Edited') | translate
          }}</span></span
        >
      </p>

      <div class="flex justify-end items-center gap-x-2.5 show-on-hover">
        <!--  REGENERATE -->
        <button
          *ngIf="displayMode && !onboardingService.onboardingRunning"
          tuiIconButton
          size="s"
          type="button"
          [icon]="regenerate"
          appearance="outline"
          (click)="onRegenerate()"
          [tuiHint]="regenerateTooltip"
          tuiHintDirection="top-right"
          [tuiHintShowDelay]="500"
        >
          <ng-template #regenerate>
            <svg-icon
              class="tui-text-04"
              src="assets/img/_ic_refresh.svg"
              [svgStyle]="{ width: '1rem', height: '1rem' }"
            ></svg-icon>
          </ng-template>
          <ng-template #regenerateTooltip>{{ 'Funnels.Content generator.Regenerate' | translate }}</ng-template>
        </button>

        <!--   EDIT   -->
        <button
          *ngIf="!onboardingService.onboardingRunning"
          tuiIconButton
          size="s"
          type="button"
          [icon]="edit"
          appearance="outline"
          (click)="editOutput()"
          [tuiHint]="editTooltip"
          tuiHintDirection="top-right"
          [tuiHintShowDelay]="500"
        >
          <ng-template #edit>
            <svg-icon
              class="no-fill tui-text-04"
              src="assets/img/_ic_edit.svg"
              [svgStyle]="{ width: '1rem', height: '1rem' }"
            ></svg-icon>
          </ng-template>
          <ng-template #editTooltip>{{ 'Funnels.Content generator.Edit' | translate }}</ng-template>
        </button>

        <!--  COPY TO CLIPBOARD  -->
        <button
          *ngIf="!onboardingService.onboardingRunning"
          tuiIconButton
          size="s"
          [icon]="copy"
          type="button"
          appearance="outline"
          (click)="copyToClipboard()"
          [tuiHint]="copyTooltip"
          tuiHintDirection="top-right"
          [tuiHintShowDelay]="500"
        >
          <ng-template #copy>
            <svg-icon
              class="tui-text-04"
              src="assets/img/_ic_copy_to_clipboard.svg"
              [svgStyle]="{ width: '1rem', height: '1rem' }"
            ></svg-icon>
          </ng-template>
          <ng-template #copyTooltip>{{ 'Funnels.Content generator.Copy' | translate }}</ng-template>
        </button>

        <!--   DELETE  -->
        <button
          *ngIf="!onboardingService.onboardingRunning"
          tuiIconButton
          size="s"
          [icon]="trash"
          type="button"
          appearance="outline"
          (confirm)="deleteOutput()"
          [tuiHint]="trashTooltip"
          tuiHintDirection="top-right"
          [tuiHintShowDelay]="500"
          mwlConfirmationPopover
          [popoverTitle]="'Funnels.Content generator.Delete' | translate"
          [popoverMessage]="'Funnels.Content generator.Do your really want to delete this copy?' | translate"
          [appendToBody]="true"
          placement="left"
        >
          <ng-template #trash>
            <svg-icon
              class="tui-text-04"
              src="assets/img/_ic_trash.svg"
              [svgStyle]="{ width: '1rem', height: '1rem' }"
            ></svg-icon>
          </ng-template>
          <ng-template #trashTooltip>{{ 'Funnels.Content generator.Delete' | translate }}</ng-template>
        </button>

        <button
          *ngIf="!onboardingService.onboardingRunning"
          tuiIconButton
          size="s"
          [icon]="asset"
          type="button"
          appearance="outline"
          (click)="addToAssetLibrary(); selectData.emit(true)"
          [tuiHint]="assetTooltip"
          tuiHintDirection="top-right"
          [tuiHintShowDelay]="500"
        >
          <ng-template #asset>
            <svg-icon
              class="tui-text-04 asset-icon"
              src="assets/img/bookmark.svg"
              [svgStyle]="{ width: '1rem', height: '1rem' }"
            ></svg-icon>
          </ng-template>
          <ng-template #assetTooltip>{{ 'Funnels.Content generator.Add to asset library' | translate }}</ng-template>
        </button>
      </div>
    </div>
    <div class="i18n-ex my-2.5 tui-text-03 whitespace-pre-line">
      {{ data.value['data'] }}
    </div>
    <div class="tui-text-body-xs tui-text-05">
      {{
        'Funnels.Content generator.:words / :letters'
          | translate
            : {
                words: data.value['data'].split(' ').length,
                letters: data.value['data'].replaceAll(' ', '').length
              }
      }}
    </div>
  </div>
</div>
