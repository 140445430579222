import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { SendMessageToChatGPTDocument } from './graphql/mutations/send-message-to-chatgpt.mutation';
import { SendMessageToChatGptMutation } from './graphql/mutations/send-message-to-chatgpt.mutation.generated';
import { GetIntegrationsQuery } from '@modules/funnels/shared/graphql/queries/get-integrations.query.generated';
import { GetIntegrationsDocument } from '@modules/funnels/shared/graphql/queries/get-integrations.query';
import { IntegrationTypeEnum } from '@modules/graphql/graphql-types';
import { Integration } from '@shared/models/integration.model';
import { UserService } from '@shared/services/user.service';
import { map } from 'rxjs/operators';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { ChatgptModelVersion, ChatgptRole, ChatMessage, MessageHistoryRawData } from './chatgpt-message-box.model';
import { lastValueFrom } from 'rxjs';
import { InputDeleteByRefNrDocument } from '@shared/graphql/mutations/input-delete-by-refnr.mutation';
import { InputDeleteByRefNrMutation } from '@shared/graphql/mutations/input-delete-by-refnr.mutation.generated';
import { OutputDeleteByRefNrDocument } from '@shared/graphql/mutations/output-delete-by-refnr.mutation';
import { OutputDeleteByRefNrMutation } from '@shared/graphql/mutations/output-delete-by-refnr.mutation.generated';

@Injectable({
  providedIn: 'root',
})
export class ChatgptMessageBoxService {
  integration: Integration | null = null;

  constructor(
    private apollo: Apollo,
    private userService: UserService,
    private funnelGraphqlService: FunnelGraphqlService,
  ) {}

  async getIntegrationData(): Promise<void> {
    const response = await this.apollo
      .query<GetIntegrationsQuery>({
        query: GetIntegrationsDocument,
        fetchPolicy: 'no-cache',
        variables: {
          integrationType: IntegrationTypeEnum.ChatGpt,
        },
      })
      .toPromise();

    this.integration = response?.data.getIntegrations[0] as Integration;
  }

  deleteHistory(items: ChatMessage[]) {
    items.forEach((item) => {
      if (item?.type === 'input') {
        this.deleteInput(item.refNr!).subscribe();
      } else {
        this.deleteOutput(item.refNr!).subscribe();
      }
    });
  }

  async getHistory() {
    const rawInputs = await this.getInput();
    const outputs = (await this.getOutput()) as MessageHistoryRawData[];
    const inputs: MessageHistoryRawData[] = [];

    rawInputs?.forEach((input) => {
      const restOfData = rawInputs.filter((i) => i.refNr === input.refNr);
      const content = restOfData.find((data) => data.configuration.property === 'content');
      const historyId = restOfData.find((data) => data.configuration.property === 'historyId');

      const data = {
        refNr: restOfData[0].refNr,
        type: 'input',
        createdAt: input.createdAt,
        role: 'user',
        content: content?.value.data,
        historyId: historyId?.value.data,
      } as any;

      if (inputs.find((i) => i.content === data.content && i.historyId === data.historyId)) return;

      inputs.push(data);
    });

    return [...inputs, ...outputs];
  }

  getModelVersion(modelVersionRaw: string): ChatgptModelVersion {
    let modelVersion = ChatgptModelVersion.GPTV4;

    if (modelVersionRaw.includes('v3.5-turbo') || modelVersionRaw.includes('GPT 3.5'))
      modelVersion = ChatgptModelVersion.GPTV3Turbo;
    return modelVersion;
  }

  sendMessage(
    message: string,
    role: string = ChatgptRole.User,
    modelVersion: ChatgptModelVersion,
    context: ChatMessage[],
    historyId: string,
  ) {
    const integrationMessageId = this.integration?.inputsConfigurations.find(
      (input) => input.property === 'content',
    )!.id;
    const integratioRoleId = this.integration?.inputsConfigurations.find((input) => input.property === 'role')!.id;
    const integratioModelVersion = this.integration?.inputsConfigurations.find(
      (input) => input.property === 'modelVersion',
    )!.id;
    const integratiohistoryId = this.integration?.inputsConfigurations.find(
      (input) => input.property === 'historyId',
    )!.id;
    const integratioContext = this.integration?.inputsConfigurations.find((input) => input.property === 'context')!.id;

    const input = {
      integrationId: this.integration?.id,
      funnelId: this.userService.User?.contextFunnel.id,
      inputsData: [
        {
          inputConfigurationId: integrationMessageId,
          value: { data: message },
        },
        {
          inputConfigurationId: integratioRoleId,
          value: { data: role },
        },
        {
          inputConfigurationId: integratioModelVersion,
          value: { data: modelVersion },
        },
        {
          inputConfigurationId: integratiohistoryId,
          value: { data: historyId },
        },
        {
          inputConfigurationId: integratioContext,
          value: { data: context },
        },
      ],
    };

    return this.apollo
      .mutate<SendMessageToChatGptMutation>({
        mutation: SendMessageToChatGPTDocument,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response) => {
          if (!response?.data) throw 'NO_PERMISSION';
          return response.data!.sendMessageToChatGPT;
        }),
      );
  }

  private deleteInput(refNr: string) {
    return this.apollo.mutate<InputDeleteByRefNrMutation>({
      mutation: InputDeleteByRefNrDocument,
      variables: {
        refNr,
      },
    });
  }

  private deleteOutput(refNr: string) {
    return this.apollo.mutate<OutputDeleteByRefNrMutation>({
      mutation: OutputDeleteByRefNrDocument,
      variables: {
        refNr,
      },
    });
  }

  private getInput() {
    return lastValueFrom(
      this.funnelGraphqlService
        .getLastGeneratedIntegrationInputsData(this.integration!.id)
        .pipe(
          map(
            (response) =>
              response.data?.getLastGeneratedIntegrationInputsData?.filter(
                (item) =>
                  item.configuration.type === 'inputText' &&
                  item.funnel?.id === this.userService.User?.contextFunnel.id,
              )!,
          ),
        ),
    );
  }

  private getOutput() {
    return lastValueFrom(
      this.funnelGraphqlService
        .getLastOutputsData(20, this.integration!.id, this.userService.User?.contextFunnel.id!)
        .pipe(
          map((response) => response.data?.getLastOutputsData?.filter((item) => item.configuration.type === 'text')),
          map((items) =>
            items?.map((item) => ({
              ...item.value.data,
              createdAt: item.createdAt,
              refNr: item.refNr,
              type: 'output',
            })),
          ),
        ),
    );
  }
}
