import { Injectable } from '@angular/core';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { UsersFiltersService } from '@modules/users/modules/users-list/shared/services/users-filters.service';
import { UsersListService } from '@modules/users/modules/users-list/shared/services/users-list.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterTypeService {
  listService!: TacticsListService | UsersListService;
  filtersService!: TacticsFiltersService | UsersFiltersService;
  $dialogSub: BehaviorSubject<Subscription> = new BehaviorSubject(new Subscription());

  constructor(
    private tacticsListService: TacticsListService,
    private usersListService: UsersListService,
  ) {}

  closeDialog(): void {
    // unsubscribe from Observable to close a TUI dialog (this observable is returned from open method)
    const sub = this.$dialogSub.subscribe((item) => {
      item.unsubscribe();
    });
    sub.unsubscribe();
  }

  public setServices(filtersService: TacticsFiltersService | UsersFiltersService) {
    if (!(this.filtersService && this.listService)) {
      // remember listService for TUI dialog
      if (filtersService instanceof TacticsFiltersService) {
        this.listService = this.tacticsListService; // BaseListService<Tactic, TacticListInputGraphql>
      } else if (this.filtersService instanceof UsersFiltersService) {
        this.listService = this.usersListService; // BaseListService<User, ListInput>
      }
      this.filtersService = filtersService;
    }

    return {
      listService: this.listService,
      filtersService: this.filtersService,
    };
  }
}
