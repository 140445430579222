<swiper
  [autoplay]="{
    delay: 5000
  }"
  [loop]="true"
  [pagination]="{
    dynamicBullets: true,
    dynamicMainBullets: 3
  }"
  class="tactic-top-banner"
  #swiper
>
  <ng-template
    swiperSlide
    *ngFor="let tactic of tactics"
  >
    <div class="w-full flex h-full justify-end sm:justify-start flex-col-reverse sm:flex-row">
      <div class="p-3 lg:p-8 w-full flex flex-col justify-between relative">
        <div class="absolute top-4 right-4 tui-text-04 tui-text_body-xs">
          {{ activeIndex + 1 }}/{{ tactics.length }}
        </div>
        <div class="w-full">
          <div class="flex items-center justify-between w-full">
            <div class="flex items-center flex-wrap">
              <div class="flex flex-row h-6 mr-2">
                <svg-icon
                  [src]="'/assets/img/ic-' + step.type + '-fill.svg'"
                  class="mr-1"
                  *ngFor="let step of tactic?.funnelSteps"
                ></svg-icon>
              </div>
              <div
                class="rounded-full h-6 pl-2 pr-2 flex items-center justify-center tui-accent-02-bg uppercase font-bold tui-text_body-xs"
              >
                {{ 'Tactics.tactic of the month' | translate }}
              </div>
            </div>
          </div>
          <a
            [routerLink]="n.getPath('tactics/preview/p/:id', {}, { id: tactic.id })"
            class="w-full line-clamp-2 lg:line-clamp-3 tui-text-01 tui-text_h2 mt-4 no-underline break-words"
          >
            {{ tactic.name }}
          </a>
          <div class="w-full mb-2"></div>
          <p
            *ngIf="tactic.shortDescription"
            class="tui-text-05 tui-text_body-s hidden w-full lg:line-clamp-2"
          >
            {{ tactic.shortDescription }}
          </p>
        </div>
        <div class="w-full mt-8 sm:mt-2">
          <df-created-by [user]="tactic.owner"></df-created-by>
        </div>
      </div>
      <div
        class="tactic-top-banner__image"
        (click)="n.go('tactics/preview/p/:id', { id: tactic.id })"
      >
        <img
          loading="lazy"
          [src]="(tactic.image?.file | assetsFile) ?? tactic.imageUrl ?? '/assets/img/placeholder.jpg'"
          onerror="this.onerror=null;this.src='/assets/img/placeholder.jpg'"
          [alt]="tactic.name"
        />
      </div>
    </div>
  </ng-template>
</swiper>
