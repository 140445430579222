import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class ListUrlController {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
  ) {}

  readParamsFromUrl() {
    const queryParams = {};
    this.route.snapshot.queryParamMap.keys.forEach((key: string) => {
      const paramVal = this.route.snapshot.queryParamMap.get(key)!;
      if (paramVal.indexOf(',') > -1 && paramVal.indexOf('{') < 0) {
        queryParams[key] = paramVal.split(',');
      }
      // if object in string
      else if (paramVal.indexOf('{') > -1 && paramVal.indexOf('}') > -1) {
        queryParams[key] = JSON.parse(paramVal);
      } else if (this.isNumeric(paramVal)) {
        queryParams[key] = Number(paramVal);
      } else {
        queryParams[key] = paramVal;
      }
    });
    return queryParams;
  }

  setParamsToUrl(params) {
    const paramsForBrowserUrl: any = {};
    for (const k in params) {
      if (!Array.isArray(params[k]) && typeof params[k] === 'object') {
        paramsForBrowserUrl[k] = params[k].toString();
      } else if ((params[k] || params[k] === 0) && params[k].toString().length && !Array.isArray(params[k])) {
        paramsForBrowserUrl[k] = params[k].toString().trim();
      } else if (Array.isArray(params[k])) {
        paramsForBrowserUrl[k] = JSON.stringify(params[k]);
      }
    }

    const createdUrl = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: paramsForBrowserUrl,
      preserveFragment: true,
    });

    this.location.replaceState(this.router.serializeUrl(createdUrl));
  }

  isNumeric(str: string) {
    if (typeof str !== 'string') return false;
    return !isNaN(Number(str)) && !isNaN(parseFloat(str));
  }
}
