import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HeaderTab } from '@shared/modules/ui/components/header-tabs/header-tabs.component';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { funnelPanelTabsConst } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-panel-subheader/funnel-panel-tabs.const';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { UserService } from '@shared/services/user.service';
import { PermissionType } from '@modules/graphql/graphql-types';
import { Funnel } from '@shared/models/funnel.model';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'df-funnel-panel-subheader',
  templateUrl: './funnel-panel-subheader.component.html',
  styleUrls: ['./funnel-panel-subheader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelPanelSubheaderComponent extends AbstractSubscriptionComponent implements OnInit {
  tabs: HeaderTab[] = [];
  isAssetsLibraryPath!: boolean;

  constructor(
    private readonly n: NavigateService,
    private readonly t: TranslateService,
    private readonly funnelManageService: FunnelManageService,
    private readonly changes: ChangeDetectorRef,
    private readonly userService: UserService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isAssetsLibraryPath = location.href.includes('assets-library');
    this.setTabs(this.funnelManageService.funnel);
    this.sub.add(this.funnelManageService.funnel$.subscribe((funnel) => this.setTabs(funnel)));

    this.sub.add(
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          setTimeout(() => {
            this.isAssetsLibraryPath = location.href.includes('assets-library');
            this.setTabs(this.funnelManageService.funnel);
            this.sub.add(this.funnelManageService.funnel$.subscribe((funnel) => this.setTabs(funnel)));
          }, 250);
        }
      }),
    );
  }

  private setTabs(funnel: Funnel | undefined): void {
    if (this.isAssetsLibraryPath) {
      this.tabs = [
        {
          name: this.t.instant('AssetsLibrary.All assets'),
          route: this.n.getPath('/funnels/f/d/:id/assets-library', {}, { id: funnel?.id }),
          queryParams: {
            type: 'all',
          },
        },
        {
          name: this.t.instant('AssetsLibrary.Images'),
          route: this.n.getPath('/funnels/f/d/:id/assets-library', {}, { id: funnel?.id }),
          queryParams: {
            type: 'image',
          },
        },
        {
          name: this.t.instant('AssetsLibrary.Content'),
          route: this.n.getPath('/funnels/f/d/:id/assets-library', {}, { id: funnel?.id }),
          queryParams: {
            type: 'content',
          },
        },
        {
          name: this.t.instant('AssetsLibrary.Prompts'),
          route: this.n.getPath('/funnels/f/d/:id/assets-library', {}, { id: funnel?.id }),
          queryParams: {
            type: 'prompt',
          },
        },
      ];

      this.changes.detectChanges();
    } else {
      this.tabs = funnel
        ? funnelPanelTabsConst(
            this.n,
            this.t,
            funnel,
            this.userService.User?.hasAccess(PermissionType.FunnelManagement, this.userService.User?.funnelsCount) ??
              false,
            this.userService,
          )
        : [];
      this.changes.detectChanges();
    }
  }
}
