import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import {
  EPermissionResourceStatus,
  EResourceTeamStatus,
  ResourcePermission,
  ResourceTeam,
} from '@modules/funnels/modules/funnel-settings/shared/models/resource-team.model';
import { map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { CreateResourceTeamMutation } from '@modules/funnels/modules/funnel-settings/shared/graphql/mutations/create-resource-team.mutation.generated';
import {
  CreateResourceTeamInputGraphql,
  ResourcePermissionOutputGraphql,
  ResourcesTeamsListInputGraphql,
  ResourceTeamOutputGraphql,
} from '@modules/graphql/graphql-types';
import { EditResourcePermissionMutation } from '@modules/funnels/modules/funnel-settings/shared/graphql/mutations/edit-resource-permission.mutation.generated';
import { AddResourcePermissionMutation } from '@modules/funnels/modules/funnel-settings/shared/graphql/mutations/add-resource-permission.mutation.generated';
import { ResourceTeamsGraphQLService } from '@shared/modules/resource-teams/services/resource-teams-graph-ql.service';
import { EResourceViewType } from '@shared/modules/resource-teams/enums/resource-view-type.enum';
import { GetResourceTeamsByResourceQuery } from '@modules/funnels/modules/funnel-settings/shared/graphql/queries/get-resource-teams-by-resource.query.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class ResourceTeamsService {
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  readonly _resourceChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly resourceChanges$: Observable<boolean> = this._resourceChanges$.asObservable();

  private _resourceTeams: ResourceTeam[] = [];
  private _resourceViewType: EResourceViewType = EResourceViewType.TACTIC;

  sub: Subscription = new Subscription();

  get resourceViewType(): EResourceViewType {
    return this._resourceViewType;
  }

  set resourceViewType(resourceViewType: EResourceViewType) {
    this._resourceViewType = resourceViewType;
  }

  get resourceTeams(): ResourceTeam[] {
    return this._resourceTeams;
  }

  set resourceTeams(resourceTeams: ResourceTeam[]) {
    this._resourceTeams = resourceTeams;
  }

  addResourceTeam(resourceTeam: ResourceTeam) {
    this._resourceTeams.push(resourceTeam);
    this._resourceChanges$.next(true);
  }

  removeResourceTeam(resourceTeam: ResourceTeam) {
    this._resourceTeams = this.resourceTeams.filter((r) => r.id !== resourceTeam.id);
    this._resourceChanges$.next(true);
  }

  constructor(
    private resourceTermsGraphqlService: ResourceTeamsGraphQLService,
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  initService(resourceViewType: EResourceViewType) {
    this.resourceViewType = resourceViewType;
  }

  fetchResourceTeams(input: ResourcesTeamsListInputGraphql) {
    this._loading$.next(true);
    this.sub.add(
      this.resourceTermsGraphqlService
        .getResourceTeamsByResource(input)
        .subscribe({
          next: (res: FetchResult<GetResourceTeamsByResourceQuery>) => {
            (res.data?.getResourceTeamsByResource as ResourceTeamOutputGraphql[]).map(
              (res: ResourceTeamOutputGraphql) => {
                const resourceTeam = new ResourceTeam(res);
                resourceTeam.status = EResourceTeamStatus.ADDED;
                this._resourceTeams.push(resourceTeam);
              },
            );
            this._loading$.next(false);
          },
          error: () => {
            this.s.error(this.t.instant('ResourceTeams.Error during getting teams. Try again.'));
            this._loading$.next(false);
          },
        })
        .add(() => {
          this._loading$.next(false);
        }),
    );
  }

  sendRequestsForResourceTeams(data: { funnelId?: number; tacticId?: number }): Observable<any> {
    const obsArr = [
      ...this.prepareCreateResources(data),
      ...this.prepareRemoveResources(),
      ...this.prepareEditPermissionResources(),
      ...this.prepareRemovePermissionResources(),
      ...this.prepareAddPermissionResources(),
    ];

    return obsArr.length
      ? combineLatest(obsArr)
      : new Observable((observer) => {
          observer.next(true);
          observer.complete();
        });
  }

  private prepareRemoveResources(): Observable<any>[] {
    const toRemoveResources = this.resourceTeams.filter((r) => r.status === EResourceTeamStatus.TO_REMOVE);
    this.resourceTeams = this.resourceTeams.filter((r) => r.status !== EResourceTeamStatus.TO_REMOVE);
    this._resourceChanges$.next(true);
    return toRemoveResources.map((r: ResourceTeam) => {
      return this.resourceTermsGraphqlService.removeResourceTeam(r.id);
    });
  }

  prepareCreateResources(data: { funnelId?: number; tacticId?: number }): Observable<any>[] {
    const toCreateResources = this.resourceTeams.filter((r) => r.status === EResourceTeamStatus.TO_CREATE);

    return toCreateResources.map((r: ResourceTeam) => {
      const input: CreateResourceTeamInputGraphql = {
        teamId: r.team.id,
        teamMembers: r.permissions.map((p: ResourcePermission) => {
          return {
            teamMemberId: p.teamMember.id,
            permission: p.permissionControl.value,
          };
        }),
      };
      this.resourceViewType === EResourceViewType.FUNNEL
        ? (input.funnelId = data.funnelId!)
        : (input.tacticId = data.tacticId!);
      return this.resourceTermsGraphqlService.createResourceTeam(input).pipe(
        map((res: FetchResult<CreateResourceTeamMutation>) => {
          r.id = res.data!.createResourceTeam.id;
          r.status = EResourceTeamStatus.ADDED;
          r.permissions = (res.data!.createResourceTeam!.permissions as ResourcePermissionOutputGraphql[]).map(
            (p: ResourcePermissionOutputGraphql) => {
              const permission = new ResourcePermission(p);
              permission.status = EPermissionResourceStatus.ADDED;
              return permission;
            },
          );
          this._resourceChanges$.next(true);
        }),
      );
    });
  }

  private prepareEditPermissionResources() {
    let toEditPermissionResource: ResourcePermission[] = [];
    this.resourceTeams.map((resourceTeam: ResourceTeam) => {
      toEditPermissionResource = toEditPermissionResource.concat(
        resourceTeam.permissions.filter((permission) => permission.status === EPermissionResourceStatus.TO_EDIT),
      );
    });
    this._resourceChanges$.next(true);
    return toEditPermissionResource.map((permission: ResourcePermission) => {
      return this.resourceTermsGraphqlService
        .editResourcePermission({
          id: permission.id,
          permission: permission.permissionControl.value,
        })
        .pipe(
          map((res: FetchResult<EditResourcePermissionMutation>) => {
            permission.permission = res.data?.editResourcePermission.permission!;
          }),
        );
    });
  }

  private prepareAddPermissionResources() {
    let toAddPermissionResource: {
      permission: ResourcePermission;
      resourceTeam: ResourceTeam;
    }[] = [];
    this.resourceTeams
      .filter((resourceTerm) => resourceTerm.status === EResourceTeamStatus.ADDED)
      .map((resourceTeam: ResourceTeam) => {
        toAddPermissionResource = toAddPermissionResource.concat(
          resourceTeam.permissions
            .filter((permission) => permission.status === EPermissionResourceStatus.TO_INVITE)
            .map((permission) => {
              return { permission, resourceTeam };
            }),
        );
      });
    this._resourceChanges$.next(true);
    return toAddPermissionResource.map(
      (toAddPermission: { permission: ResourcePermission; resourceTeam: ResourceTeam }) => {
        return this.resourceTermsGraphqlService
          .addResourcePermission({
            teamMemberId: toAddPermission.permission.teamMember.id,
            resourceTeamId: toAddPermission.resourceTeam.id,
            permission: toAddPermission.permission.permissionControl.value,
          })
          .pipe(
            map((res: FetchResult<AddResourcePermissionMutation>) => {
              toAddPermission.permission.id = res.data?.addResourcePermission.id!;
              toAddPermission.permission.status = EPermissionResourceStatus.ADDED;
            }),
          );
      },
    );
  }

  private prepareRemovePermissionResources() {
    const toRemovePermissionResources: ResourcePermission[] = [];
    this.resourceTeams.map((resourceTeam: ResourceTeam) => {
      resourceTeam.permissions = resourceTeam.permissions.filter((permission) => {
        if (permission.status === EPermissionResourceStatus.TO_REMOVE) {
          toRemovePermissionResources.push(permission);
        }
        return permission.status !== EPermissionResourceStatus.TO_REMOVE;
      });
      return resourceTeam;
    });
    this._resourceChanges$.next(true);
    return toRemovePermissionResources.map((permission: ResourcePermission) => {
      return this.resourceTermsGraphqlService.removeResourcePermission(permission.id);
    });
  }

  clearService() {
    this.resourceViewType = EResourceViewType.FUNNEL;
    this.resourceTeams = [];
    this.sub.unsubscribe();
    this._loading$.next(false);
    this._resourceChanges$.next(false);
  }
}
