<div
  class="notification-modal flex justify-start items-start"
  *ngIf="notification?.type as type"
  [ngClass]="{ 'book-a-demo': type === NotificationTypeEnum.BookAdemo }"
>
  <div class="notification-modal__img">
    <img
      class="scale-90"
      [src]="'assets/img/notifications/notification_' + type + '.svg'"
      alt=""
    />
  </div>
  <div class="notification-modal__data pl-3">
    <h3 class="tui-text_h5 text-base leading-snug">
      {{ 'Notifications.title ' + type | translate: { data: (notification?.data?.feeAmount || 0 | price) } }}
    </h3>
    <p
      class="text-xs tui-text-05 mb-2 mt-1"
      [innerHtml]="'Notifications.subtitle ' + type | translate"
    ></p>
    <button
      tuiButton
      type="button"
      appearance="accent"
      size="s"
      (click)="action(type)"
    >
      <span class="text-xs">{{ 'Notifications.action ' + type | translate }}</span>
    </button>
  </div>
</div>
