import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FunnelStepsService } from '@shared/services/funnel-steps.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { Step } from '@shared/models/step.model';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { FunnelContentGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/services/funnel-content-generator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, pluck, take } from 'rxjs/operators';
import { ContentGeneratorRouteParam } from '@modules/funnels/shared/enums/content-generator-route-param.enum';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';

@Component({
  selector: 'df-content-generator-steps',
  templateUrl: './content-generator-steps.component.html',
  styleUrls: ['./content-generator-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorStepsComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly EOnboardingStep = EOnboardingStep;
  expanded: { [step: number]: boolean } = {};
  highlighted: number | null = null;

  constructor(
    public readonly funnelStepsService: FunnelStepsService,
    public readonly funnelManageService: FunnelManageService,
    private readonly changes: ChangeDetectorRef,
    private readonly funnelContentGeneratorService: FunnelContentGeneratorService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly onboardingService: OnboardingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenFunnelChanges();
    this.listenStepChanges();
    this.listenOnboardingStepChange();
  }

  private listenFunnelChanges(): void {
    this.sub.add(this.funnelManageService.funnel$.subscribe(() => this.changes.detectChanges()));
    if (!this.funnelManageService.funnel) {
      this.funnelManageService.funnel$
        .pipe(
          filter((val) => !!val),
          take(1),
        )
        .subscribe(() => this.listenTacticParamChange());
    } else {
      setTimeout(() => this.listenTacticParamChange(), 500);
    }
  }

  private listenTacticParamChange(): void {
    this.sub.add(
      this.route.queryParams
        .pipe(
          pluck(ContentGeneratorRouteParam.TACTIC),
          filter(
            (funnelTacticId) =>
              !!funnelTacticId && this.funnelContentGeneratorService.selectedTactic?.id !== +funnelTacticId,
          ),
          map((funnelTacticId: number) =>
            this.funnelManageService.funnel?.tactics.find((t) => t.id === +funnelTacticId),
          ),
          filter((tactic) => !!tactic),
        )
        .subscribe((tactic) => this.highlightTactic(tactic!)),
    );
  }

  private listenStepChanges(): void {
    this.funnelStepsService.steps$.subscribe((steps) => {
      steps.map((step) => (this.expanded[step.id] = false));
      this.changes.detectChanges();
    });
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  toggleStep(step: Step, opened?: boolean): void {
    if (this.funnelManageService.sortedFunnelTactics(step).length) {
      this.expanded[step.id] = typeof opened === 'boolean' ? opened : !this.expanded[step.id];
      this.changes.detectChanges();
    }
  }

  selectTactic(event: Event, tactic: FunnelTactic): void {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['.'], {
      queryParams: { [ContentGeneratorRouteParam.TACTIC]: tactic.id },
      relativeTo: this.route,
    });
  }

  private highlightTactic(tactic: FunnelTactic): void {
    this.funnelContentGeneratorService.selectTactic(tactic);
    this.expanded[tactic.step.id] = true;
    this.highlighted = tactic.id;
    this.changes.detectChanges();
  }
}
