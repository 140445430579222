import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Funnel } from '@shared/models/funnel.model';
import { Config } from '@shared/configs/config';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-funnel-tile',
  templateUrl: './funnel-tile.component.html',
  styleUrls: ['./funnel-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelTileComponent {
  Config = Config;

  @Input() funnel?: Funnel;

  constructor(
    private funnelGraphqlService: FunnelGraphqlService,
    private changes: ChangeDetectorRef,
    private n: NavigateService,
  ) {}

  toggleFavourite() {
    const request = this.funnel?.isFavourite
      ? this.funnelGraphqlService.removeFunnelFromFavourites(this.funnel!.id)
      : this.funnelGraphqlService.addFunnelToFavourites(this.funnel!.id);
    this.funnel!.isFavourite = !this.funnel!.isFavourite;
    this.changes.detectChanges();
    request.toPromise();
  }

  preview() {
    this.n.go('funnels/f/d/:id', { id: this.funnel?.id });
  }
}
