import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { FunnelTab } from '@modules/funnels/shared/enums/funnel-tab.enum';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import {
  TuiDialogService,
  TuiHorizontalDirection,
  TuiHostedDropdownComponent,
  TuiSizeXL,
  TuiSizeXS,
} from '@taiga-ui/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import {
  TacticsListEventType,
  TacticsListService,
} from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user.service';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { filter, map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { DuplicateFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/duplicate-funnel-tactic.mutation.generated';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { FunnelManageTacticNameModalComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-tactic-name-modal/funnel-manage-tactic-name-modal.component';
import { FunnelPermissionEnum, FunnelStatusOutputGraphql } from '@modules/graphql/graphql-types';
import { Flow } from '@shared/models/flow.model';
import { FlowItem } from '@shared/models/flow-item.model';
import { OnboardingService } from '@shared/services/onboarding.service';

@Component({
  selector: 'df-funnel-manage-tactic-dropdown-menu',
  templateUrl: './funnel-manage-tactic-dropdown-menu.component.html',
  styleUrls: ['./funnel-manage-tactic-dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageTacticDropdownMenuComponent {
  PermissionsGroups = PermissionsGroups;
  menuOpened = false;
  canManage$: Observable<boolean>;
  FunnelPermissionEnum = FunnelPermissionEnum;

  @Input() funnelTactic!: FunnelTactic;
  @Input() appearance = 'flat';
  @Input() size: TuiSizeXS | TuiSizeXL = 'xs';
  @Input() tuiDropdownAlign: TuiHorizontalDirection = 'left';
  @Input() short = false;
  @Output() onChanges: EventEmitter<FunnelTactic> = new EventEmitter<FunnelTactic>();
  @ViewChild(TuiHostedDropdownComponent) dropdown!: TuiHostedDropdownComponent;

  constructor(
    public n: NavigateService,
    private tacticGraphqlService: TacticGraphqlService,
    public funnelManageService: FunnelManageService,
    private tacticsListService: TacticsListService,
    private statementService: StatementService,
    private s: SnackbarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private userService: UserService,
    public funnelFlowManageService: FunnelFlowManageService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    public onboardingService: OnboardingService,
  ) {
    this.canManage$ = this.funnelManageService.isSelectedTab(FunnelTab.MANAGE);
  }

  private detectChanges(): void {
    this.changes.detectChanges();
    this.onChanges.emit(this.funnelTactic);
  }

  toggleDone() {
    this.menuOpened = false;
    this.funnelTactic.isDone = !this.funnelTactic.isDone;
    this.updateFunnelStepStats(this.funnelTactic.isDone);
    this.tacticGraphqlService.markFunnelTacticAsDone(this.funnelTactic.isDone, this.funnelTactic.id).toPromise();
    this.detectChanges();
  }

  duplicateTactic() {
    this.menuOpened = false;
    this.tacticGraphqlService
      .duplicateFunnelTactic(this.funnelTactic.id)
      .pipe(map((res: FetchResult<DuplicateFunnelTacticMutation>) => new FunnelTactic(res.data?.duplicateFunnelTactic)))
      .subscribe((funnelTactic: FunnelTactic) => {
        if (funnelTactic) {
          this.tacticsListService.tacticsListEmitter.emit({
            type: TacticsListEventType.RELOAD_FUNNEL,
          });
          this.s.success(this.t.instant('Funnels.Manage.Tactic duplicated successfully.'));
        }
        this.detectChanges();
      });
  }

  editTacticName() {
    this.menuOpened = false;
    this.dialogService
      .open<string>(new PolymorpheusComponent(FunnelManageTacticNameModalComponent, this.injector), {
        size: 's',
        data: {
          funnelTactic: this.funnelTactic,
        },
        dismissible: true,
        closeable: true,
        label: this.t.instant('Funnels.Manage.Edit tactic name'),
      })
      .pipe(filter((val) => val !== undefined))
      .subscribe((newName: string | null) => {
        this.funnelTactic.name = newName;
        this.detectChanges();
      });
  }

  updateFunnelStepStats(moreDone: boolean) {
    this.funnelManageService.funnel!.stats = this.funnelManageService.funnel!.stats.map(
      (s: FunnelStatusOutputGraphql) => {
        if (s.step.id === this.funnelTactic.step.id) {
          moreDone ? s.doneCount++ : s.doneCount--;
        }
        return s;
      },
    );
    this.funnelManageService.funnel?.setStatsToMap();
    this.funnelManageService.funnel!.doneTacticsCount += moreDone ? 1 : -1;
    this.funnelManageService.funnel$.emit(this.funnelManageService.funnel);
  }

  removeTacticFromFunnel(fromDB = true) {
    this.menuOpened = false;
    delete this.funnelManageService.funnelTactics[this.funnelTactic.step.id][this.funnelTactic.id];
    this.removeFunnelTacticFromContextFunnel();
    this.removeFlowItemsInEditingFlow();
    this.removeFunnelTacticFromFunnel();
    if (fromDB) {
      this.tacticGraphqlService.removeFunnelTactic(this.funnelTactic.id).subscribe({
        next: () => {
          this.s.success(this.t.instant('Tactics.Preview.Tactic removed from funnel successfully.'));
          this.statementService.refreshRecommendations();
          this.funnelManageService.setFunnelStepSummaries();
        },
        error: () =>
          this.s.error(
            this.t.instant('Tactics.Preview.Something went wrong with removing tactic from funnel. Try again.'),
          ),
      });
    }
    this.updateFlowConnections();
  }

  private removeFlowItemsInEditingFlow() {
    if (!this.funnelFlowManageService.editMode) return;
    this.funnelFlowManageService.flow!.items = this.funnelFlowManageService.flow!.items.filter((flowItem: FlowItem) => {
      return flowItem.from !== this.funnelTactic.id && flowItem.to !== this.funnelTactic.id;
    });
    this.funnelFlowManageService.flow?.setTacticsIds();
  }

  private removeFunnelTacticFromContextFunnel() {
    const user = this.userService.User;
    if (user?.contextFunnel.id === this.funnelManageService.funnel!.id) {
      for (let i = 0; i < user!.contextFunnel?.tactics?.length; i++) {
        const funnelTactic = user!.contextFunnel.tactics[i];
        if (funnelTactic.id === this.funnelTactic.id) {
          user!.contextFunnel.tactics.splice(i, 1);
          break;
        }
      }
      this.userService.User = user;
    }
  }

  private removeFunnelTacticFromFunnel(): void {
    this.funnelManageService.funnel!.tactics = this.funnelManageService.funnel!.tactics.filter(
      (f) => f.id !== this.funnelTactic.id,
    );
    this.funnelManageService.funnel!.flows = this.funnelManageService.funnel!.flows.map((flow: Flow) => {
      flow.items = flow.items.filter((flowItem: FlowItem) => {
        return flowItem.to !== this.funnelTactic.id && flowItem.from !== this.funnelTactic.id;
      });
      flow.setTacticsIds();
      return flow;
    });
    this.funnelManageService.recalculateStatsInFunnel();
  }

  private updateFlowConnections(): void {
    this.funnelFlowManageService.paintConnections();
    this.funnelManageService.funnel$.emit(this.funnelManageService.funnel);
    const highlightedFlow = this.funnelManageService.getHighlightFlow();
    highlightedFlow ? this.funnelManageService.highlightFunnelTacticForFlow(highlightedFlow) : '';
    this.funnelFlowManageService.paintConnections();
  }

  highlightFunnelTacticFlows() {
    this.menuOpened = false;
    let foundFlow = false;
    this.funnelManageService.funnel!.flows = this.funnelManageService.funnel!.flows.map((flow: Flow) => {
      flow.highlighted = false;
      flow.items.map((flowItem: FlowItem) => {
        if ((flowItem.from === this.funnelTactic.id || flowItem.to === this.funnelTactic.id) && !foundFlow) {
          flow.highlighted = true;
          foundFlow = true;
          this.funnelManageService.highlightFunnelTacticForFlow(flow);
        }
        return flowItem;
      });
      return flow;
    });
    if (!foundFlow) {
      this.s.error(this.t.instant('Funnels.Manage.There is no flow connected with this tactic.'));
    } else {
      this.funnelManageService.funnel$.emit(this.funnelManageService.funnel);
      this.funnelFlowManageService.paintConnections();
    }
  }
}
