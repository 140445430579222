import { Inject, Injectable } from '@angular/core';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SnackbarService {
  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    private t: TranslateService,
  ) {}

  success(message: string): Subscription {
    return this.notificationsService
      .open(message, {
        status: TuiNotification.Success,
      })
      .subscribe();
  }

  info(message: string): Subscription {
    return this.notificationsService
      .open(message, {
        status: TuiNotification.Info,
      })
      .subscribe();
  }

  error(message: string, autoClose?: boolean | number): Subscription {
    return this.notificationsService
      .open(message, {
        status: TuiNotification.Error,
        autoClose: autoClose || true,
      })
      .subscribe();
  }

  defaultError() {
    return this.notificationsService
      .open(this.t.instant('Error. Please try again do the action.'), {
        status: TuiNotification.Error,
      })
      .subscribe();
  }

  warning(message: string): Subscription {
    return this.notificationsService
      .open(message, {
        status: TuiNotification.Warning,
      })
      .subscribe();
  }
}
