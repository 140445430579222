import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RouteData } from '@shared/models/route-data.model';
import isMobile from 'is-mobile';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasePanelService implements OnDestroy {
  readonly basePanelScroll$: Observable<Event>;

  mobile!: boolean;
  routeData: RouteData = new RouteData();
  userSetNavigation = false;
  emitter: EventEmitter<BasePanelEvent> = new EventEmitter<BasePanelEvent>();
  novuNotoficationsSubject = new Subject<boolean>();

  private readonly _basePanelScroll$: Subject<Event>;
  private readonly sub: Subscription = new Subscription();

  constructor(@Inject(DOCUMENT) private document: Document) {
    this._basePanelScroll$ = new Subject<Event>();
    this.basePanelScroll$ = this._basePanelScroll$;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  setScrollListener(scroll$: Observable<Event>): void {
    this.sub.add(scroll$.subscribe((event) => this._basePanelScroll$.next(event)));
  }

  setShortLeftNavigation(value: boolean, byUser?: boolean) {
    if ((!value && this.userSetNavigation && !byUser) || isMobile()) {
      value = false;
    }
    this.routeData.shortLeftNavigation = value;
    this.emitter.emit(BasePanelEvent.CHANGE_NAVIGATION_SHORT);
    this.routeData.shortLeftNavigation!
      ? this.document.body.classList.add('menuShort')
      : this.document.body.classList.remove('menuShort');
  }

  openChat() {
    this.emitter.emit(BasePanelEvent.OPEN_CHAT);
  }

  showNovuNotification(open: boolean) {
    this.novuNotoficationsSubject.next(open);
  }
}

export enum BasePanelEvent {
  CHANGE_NAVIGATION_SHORT,
  RELOAD_FUNNELS_TO_CONTEXT,
  OPEN_CHAT,
}
