export enum EAuthViewMode {
  LOGIN = 'sign-in',
  REGISTER = 'sign-up',
  SEMRUSH = 'semrush',
  CONFIRM_EMAIL = 'confirm-email',
  CHANGE_EMAIL = 'change-email',
  NO_AUTH = 'no-auth',
  NO_TEAM = 'no-team',
  END_TRIAL = 'end-trail',
  NO_ACCESS = 'no-access',
  APP_SUMO_REGISTER = 'appsumo-sign-up',
  APP_SUMO_ERROR = 'appsumo-invalid',
  ACCOUNT_BLOCKED = 'auto-logout',
  SET_UP_ACCOUNT_FORM = 'set-up-account',
  SELECT_PLAN = 'select-plan',
}
