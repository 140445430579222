import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { OrderTypes } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-list-sub-header',
  templateUrl: './list-sub-header.component.html',
  styleUrls: ['./list-sub-header.component.scss'],
})
export class ListSubHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  search: UntypedFormControl = new UntypedFormControl('');
  sub: Subscription = new Subscription();
  firstSetValue = false;

  @Input() form!: UntypedFormGroup;
  @Input() searchControlName!: string;
  @Input() sortControlName!: string;
  @Input() sorts: ListSortItem[] = [];

  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSort: EventEmitter<string> = new EventEmitter<string>();

  readonly stringify: TuiStringHandler<
    | { sortField: string; sortDirection: string; name: string }
    | TuiContextWithImplicit<{
        sortField: string;
        sortDirection: string;
        name: string;
      }>
  > = (item) => ('name' in item ? item.name : item.$implicit.name);

  constructor(private changes: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.debounceSearching();
  }

  ngAfterViewInit() {
    if (this.form.get(this.searchControlName)?.value) {
      this.search.patchValue(this.form.get(this.searchControlName)?.value);
    }
  }

  debounceSearching() {
    const sub = this.search?.valueChanges.pipe(debounceTime(400)).subscribe((value) => {
      const currentValue = this.form.get(this.searchControlName)?.value;
      if ((currentValue && currentValue.trim().toLowerCase() !== value.trim().toLowerCase()) || !currentValue) {
        this.onSearch.emit(this.search.value);
        this.form.get(this.searchControlName)?.setValue(value.trim());
      }
    });
    this.sub.add(sub);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

export interface ListSortItem {
  sortField: string;
  name: string;
  sortDirection: OrderTypes;
}
