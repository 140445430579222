import { gql } from 'apollo-angular';

export const UserStorageFragment = gql/* GraphQL*/ `
  fragment UserStorage on UserOutputGraphql {
    registrationMethod
    id
    firstName
    lastName
    email
    newEmail
    tutorialEnded
    funnelsCount
    registeredThroughAppSumo
    createdAt
    firstFunnel {
      id
    }
    avatar {
      id
      file
    }
    activeSubscription {
      id
      plan {
        id
        name
      }
      price {
        id
        amount
        paymentInterval
      }
    }
    activePlans {
      id
      name
      credits
      creditsInitial
      strength
      price
    }
    permissions {
      hasLimit
      limit
      permissionType {
        type
      }
    }
    contextFunnel {
      id
      name
      isPublic
      tactics {
        id
        tactic {
          id
        }
        step {
          id
        }
      }
    }
    tacticDraft {
      id
      name
    }
    filledStatement
    isPublic
    paidTacticCount
    skills {
      id
      name
    }
    services {
      id
      name
      price
      description
    }
    isPro
    isAdmin
    affiliationId
    credits
    usedTrial
    trialDaysLeft
    recentlyEndedTrial
    setupFormFilled
    hasEndedOnboarding
    isMarketingCampaignUser
    discordId
    discordName
  }
`;
