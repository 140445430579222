<div class="w-full mt-3 mb-6 flex flex-col lg:flex-row lg:justify-between items-stretch lg:items-start">
  <form
    [formGroup]="form"
    class="lg:w-5/12"
  >
    <p class="uppercase tui-text-04 tui-text_body-xs font-bold mb-2">
      {{ 'Funnels.Manage.Company logo' | translate }}
    </p>
    <df-input-file
      (ngModelChange)="logoChange($event)"
      #logoInput
      [maxFileSize]="10000000"
      [link]="'choose an image' | translate"
      [accept]="Config.ACCEPTED_IMAGES_EXTENSIONS"
      [formName]="'logo'"
      [form]="form"
    ></df-input-file>
  </form>
</div>

<p
  class="w-auto h-6 tui-primary-bg tui-base-01 uppercase font-bold tui-text_body-xs flex items-center px-2 rounded-tl-md rounded-tr-md"
>
  {{ 'Funnels.Manage.PDF preview' | translate }}
</p>
<div
  class="w-full overflow-auto border-2 border-solid tui-primary-border relative"
  [ngClass]="{ 'overflow-hidden': loading }"
>
  <df-full-list-loader
    *ngIf="loading"
    class="backdrop-blur-sm"
  ></df-full-list-loader>
  <div
    id="pdf-container"
    [ngClass]="{ rendering: loading }"
  >
    <div class="flex justify-between items-center">
      <div class="pdf-header flex justify-start items-center mb-4 px-4 pt-4">
        <img
          class="max-w-md max-h-24 object-contain mr-3"
          *ngIf="image"
          alt="company logo"
          [src]="'data:image/png;base64,' + image"
        />
        <div
          class="flex flex-col justify-center items-start w-full"
          [ngClass]="{ 'w-8/12': image }"
        >
          <h2 class="tui-text_h3 m-0 w-full line-clamp-2 leading-normal">
            {{ funnelManageService.funnel?.name }}
          </h2>
          <h4
            class="pdf-text tui-text_h6 tui-text-05 font-medium w-full"
            *ngIf="highlightedFlow as flow"
          >
            {{ 'Funnels.Manage.flow' | translate }}&nbsp;{{ flow.name }}
          </h4>
        </div>
      </div>
      <div>
        <p class="mb-1">Powered by</p>
        <img
          class="max-w-[150px]"
          src="/assets/img/logo-dark.png"
          alt=""
        />
      </div>
    </div>
    <div
      id="funnel-wrapper"
      class="flex flex-nowrap justify-start items-stretch relative"
    >
      <tui-island
        [size]="'s'"
        *ngFor="let step of funnelStepsService.steps"
        class="column flex flex-col items-start justify-start h-full ml-2 mr-2 {{ step.type }}-faded p-0"
        [ngClass]="{
          'column--empty': !funnelManageService.funnel?.tactics?.length
        }"
      >
        <div class="flex items-center w-full {{ step.type }}-light rounded-tl-xl rounded-tr-xl tui-base-01 px-4 py-3.5">
          <img
            [src]="'/assets/img/' + step.type + '.png'"
            [alt]="step.type"
            class="h-5 w-5 mr-3"
          />
          <h4 class="text-base font-semibold">
            <span class="pdf-step">{{ step.name }}</span>
          </h4>
        </div>

        <div class="mt-1 mb-3 px-3 w-full h-full flex-1">
          <ng-container *ngFor="let funnelTactic of funnelManageService.sortedFunnelTactics(step)">
            <div
              class="{{
                step.type
              }}-border w-full mt-2 mx-auto rounded-lg border-solid border p-3 tui-base-01-bg z-40 relative"
              [ngClass]="{ highlight: funnelTactic.isHighlighted }"
              [attr.dataPDFFunnelTacticId]="funnelTactic.id.toString()"
            >
              <h1 class="tui-text-03 tui-text_h5 line-clamp-3 break-words m-0 font-medium">
                <span class="pdf-text">{{ funnelTactic.name || funnelTactic.tactic.name }}</span>
              </h1>
              <p
                class="tui-text-04 tui-text_body-s-2 font-normal mt-2"
                *ngIf="!!funnelTactic.name"
              >
                <span class="pdf-text">{{ funnelTactic.tactic.name }}</span>
              </p>
              <p class="flex flex-row justify-start items-center uppercase tui-text-04 tui-text_body-xs font-bold mt-2">
                <span class="pdf-text">{{
                  'Tactics.Preview.:number Activities' | translate: { number: funnelTactic.tactic.activities.length }
                }}</span>
              </p>
              <ul
                class="tui-text_body-xs list-disc list-inside pl-2"
                *ngIf="funnelTactic.tactic.activities.length"
              >
                <li
                  class="mt-2"
                  *ngFor="let activity of funnelTactic.tactic.activities"
                >
                  <span class="pdf-text">{{ activity.name }}</span>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </tui-island>
    </div>
  </div>
</div>
<div class="w-full flex justify-end mt-4">
  <button
    tuiButton
    appearance="primary"
    type="button"
    size="l"
    [showLoader]="loading"
    (click)="generate()"
  >
    <svg-icon
      [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
      class="mr-2"
      [src]="'/assets/img/ic-download.svg'"
    ></svg-icon>
    {{ 'Funnels.Manage.Download PDF' | translate }}
  </button>
</div>
