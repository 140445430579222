import { gql } from 'apollo-angular';

export const CreateManyFunnelTacticsDocument =
  gql(/* GraphQL*/ `mutation createManyFunnelsTactics($inputs: [CreateManyFunnelTacticInputGraphql!]!, $funnelId: Int!) {
  createManyFunnelsTactics (inputs: $inputs, funnelId: $funnelId) {
    id
    isDone
    tactic
    {
      id
      name
      funnelSteps
      {
        id
        type
        name
      }
    }
    step
    {
      id
    }
    position
  }
}
`);
