import { gql } from 'apollo-angular';

export const CanvaCreationFragment = gql`
  fragment CanvaCreationFragment on CanvaCreationOutputGraphql {
    id
    canvaId
    file {
      file
      name
      id
    }
    resolution {
      id
      width
      height
    }
  }
`;
