import { ActivityBenchmark } from '@shared/models/activity-benchmark.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { Config } from '@shared/configs/config';
import { BenchmarkTypeEnum } from '@modules/graphql/graphql-types';
import FileSaver from 'file-saver';
import { Regex } from '@shared/configs/regex';
import { extractVideoUrl } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-data/helpers/quill.helper';
@Component({
  selector: 'df-activity-benchmark-preview-modal',
  templateUrl: './activity-benchmark-preview-modal.component.html',
  styleUrls: ['./activity-benchmark-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityBenchmarkPreviewModalComponent implements OnInit {
  readonly Config = Config;
  readonly Regex = Regex;
  readonly BenchmarkTypeEnum = BenchmarkTypeEnum;
  fileSrc: string | ArrayBuffer | null = null;
  linkSrc: string | null = null;
  loading = false;

  get benchmark(): ActivityBenchmark {
    return this.context.data.benchmark;
  }

  constructor(
    private readonly changes: ChangeDetectorRef,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { benchmark: ActivityBenchmark } }, boolean>,
  ) {}

  ngOnInit(): void {
    if (this.benchmark.file && !this.benchmark.attachment) {
      const reader = new FileReader();
      this.loading = true;
      reader.onload = () => {
        this.fileSrc = reader.result;
        this.loading = false;
        this.changes.detectChanges();
      };
      reader.readAsDataURL(this.benchmark.file);
    } else if (this.benchmark.attachment) {
      this.fileSrc = Config.ASSETS + this.benchmark.attachment?.file;
    } else {
      this.linkSrc = this.benchmark.link ? extractVideoUrl(this.benchmark.link) : null;
    }
    this.handleAsideElement('-1');
  }

  handleAsideElement(zIndex: string) {
    // collision:
    // tui-root > aside[tuisidebar]
    // tui-root > tui-portal-host > tui-dialog-host
    const aside: HTMLElement = document.querySelector('aside[tuisidebar]') as HTMLElement;
    aside ? (aside.style.zIndex = zIndex) : null;
  }

  close() {
    this.handleAsideElement('auto');
    this.context.completeWith(false);
  }

  downloadAttachment(): void {
    this.benchmark.attachment
      ? FileSaver.saveAs(Config.ASSETS + this.benchmark.attachment!.file, this.benchmark.attachment!.name)
      : FileSaver.saveAs(this.benchmark.file!, this.benchmark.file?.name);
  }
}
