/* eslint-disable @angular-eslint/directive-selector */
import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { CheckPermission } from '../check-permission';
import { PermissionDecorator } from '../interfaces/permission-decorator.interface';
import { User } from '@shared/models/user.model';
import { UserService } from '@shared/services/user.service';
import { TacticsPermissionsConfig } from '@core/permissions/configs/tactics-permissions.config';

/**
 * @description Directive to control permissions on DOM element. Important! You can't use it with non DOM elements, e.x. ng-container or ng-template
 * @author Robert Juszczyk
 * @export
 * @class PermissionDirective
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Directive({
  selector: '[permission]',
})
export class PermissionDirective implements AfterViewInit, OnChanges, OnDestroy {
  TacticsPermissionsConfig = TacticsPermissionsConfig;

  user!: User;
  observer!: MutationObserver;

  @Input() permission!: PermissionDecorator;

  constructor(
    private userService: UserService,
    private el: ElementRef,
  ) {
    this.user = this.userService.User!;
  }

  ngAfterViewInit() {
    if (!this.checkHostElement()) {
      return;
    }
    this.checkPermissions();
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        this.checkPermissions();
      });
    });
    this.observer.observe(this.el.nativeElement, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  }

  ngOnChanges(): void {
    this.checkPermissions();
  }

  checkPermissions() {
    if (!this.checkHostElement()) {
      return;
    }
    const ctrl = new CheckPermission(this.permission);
    if (!ctrl.check()) {
      this.el.nativeElement.style.display = 'none';
    } else {
      this.el.nativeElement.style.removeProperty('display');
    }
  }

  checkHostElement() {
    if (!this.el.nativeElement || this.el.nativeElement.style === undefined) {
      console.error(
        'ERROR Error: There is a problem with host element for permission directive. This is probably problem that you used ng-container or ng-template as host element.',
      );
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    if (this.checkHostElement()) {
      this.observer.disconnect();
    }
  }
}
