import { appendTitle } from './../helpers/create-head-links.helper';
import { Injectable } from '@angular/core';
import { Partners } from '@shared/enums/partners.enum';
import { CookieService } from 'ngx-cookie-service';
import { IconsJSON } from '@shared/types/icons-json.type';
import { appendHeadLink, appendHeadMeta, appendManifest } from '@shared/helpers/create-head-links.helper';
import { PartnerConfig } from '@shared/interfaces/partner-config.interface';
import { PartnersConfig } from '@shared/configs/partners.config';
import { PartnerLanguageCustomizationService } from './partner-language-customization.service';

@Injectable({
  providedIn: 'root',
})
export class XPartnerService {
  static readonly COOKIE_KEY: string = 'x-partner';
  static readonly TOKEN_KEY: string = 'x-partner-token';
  private readonly _isPartner: boolean = false;
  private readonly _partnerName: string = '';
  private _noAuth = false;

  readonly partner: PartnerConfig;

  get isPartner() {
    return this._isPartner;
  }

  get partnerName(): string {
    return this._partnerName;
  }

  get isWhitelabelPartner(): boolean {
    return this._isPartner && this.partner?.WHITELABEL_PARTNER;
  }

  get isPlaystrict(): boolean {
    return this._isPartner && this.partnerName === 'playstrict';
  }

  get partnerJwt(): string {
    return this.cookieService.get(XPartnerService.TOKEN_KEY);
  }

  get authenticationFailed(): boolean {
    return this._noAuth;
  }

  constructor(
    private cookieService: CookieService,
    private partnerLangService: PartnerLanguageCustomizationService,
  ) {
    this._partnerName = this.cookieService.get(XPartnerService.COOKIE_KEY);
    this._isPartner = this.cookieService.check(XPartnerService.COOKIE_KEY) && this._partnerName !== 'false';

    this.partner = this._isPartner ? PartnersConfig[this._partnerName] : PartnersConfig['default'];
    this.partnerLangService.handlePartnerLanguageCustomization(this.partner);
  }

  getIsPartner(partner: Partners): boolean {
    return this._isPartner && this._partnerName === partner;
  }

  saveFailedAuthentication(): void {
    this._noAuth = true;
  }

  setHead(document: Document) {
    const data: IconsJSON = this.partner.getIconsData();
    appendManifest(document, data.manifest.href);
    appendHeadMeta(document, data.meta.name, data.meta.content);
    appendHeadLink(document, data.favicon.rel, data.favicon.href, undefined, data.favicon.type);
    data.icons.forEach((icon) => {
      appendHeadLink(document, icon.rel, icon.href, icon.sizes);
    });

    this.setTitle();
  }

  setTitle() {
    appendTitle(document, this.partner.SYSTEM_NAME);
  }
}
