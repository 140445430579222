<df-full-list-loader *ngIf="funnelsListService.loading"></df-full-list-loader>
<df-funnels-list-projects data-test="funnel-list-filters"></df-funnels-list-projects>
<virtual-scroller
  #scroll
  [scrollDebounceTime]="200"
  [bufferAmount]="20"
  (vsEnd)="onEnd($event)"
  [items]="funnelsListService.records"
>
  <df-funnels-tiles-empty-state *ngIf="!funnelsListService.records.length"></df-funnels-tiles-empty-state>
  <df-load-prev-page
    *ngIf="showLoadPrev()"
    (onLoadPrevPage)="onLoadPrevPage()"
    [loading]="funnelsListService.loadingPrevPage"
    [text]="'Tactics.List.Load previous tactics' | translate"
  ></df-load-prev-page>
  <div
    class="scroll-container px-4 md:px-6 lg:px-10 pb-10"
    #container
  >
    <df-funnel-tile
      data-test="funnel-tile"
      *ngFor="let item of scroll.viewPortItems"
      [funnel]="item"
    ></df-funnel-tile>
  </div>
  <df-page-list-loader *ngIf="funnelsListService.loadingNextPage"></df-page-list-loader>
</virtual-scroller>
