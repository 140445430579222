<df-full-list-loader *ngIf="funnelStatisticsService.processingForm$ | async"></df-full-list-loader>

<form
  [formGroup]="formGroup"
  (submit)="onSubmitForm()"
>
  <div class="tui-form__row">
    <tui-input [formControlName]="ReportFormField.reportName">
      {{ 'Funnels.Statistics.Name your report' | translate }}
    </tui-input>
    <df-error-input
      [control]="formGroup.get(ReportFormField.reportName)"
      [manualShowingError]="formSubmitted"
    ></df-error-input>
  </div>
  <div class="tui-form__row">
    <tui-input [formControlName]="ReportFormField.reportUrl">
      {{ 'Funnels.Statistics.Paste a link to your report' | translate }}
    </tui-input>
    <df-error-input
      [control]="formGroup.get(ReportFormField.reportUrl)"
      [manualShowingError]="formSubmitted"
    ></df-error-input>
  </div>
  <div class="button-wrapper">
    <button
      size="l"
      tuiButton
      type="submit"
      appearance="primary"
      class="danger"
    >
      {{ 'Funnels.Statistics.Save' | translate }}
    </button>
  </div>
</form>
