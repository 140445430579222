import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'df-load-prev-page',
  templateUrl: './load-prev-page.component.html',
  styleUrls: ['./load-prev-page.component.scss'],
})
export class LoadPrevPageComponent {
  @Input() loading = false;
  @Input() text = '';
  @Output() onLoadPrevPage: EventEmitter<any> = new EventEmitter<any>();

  loadPrevPage() {
    this.onLoadPrevPage.emit(true);
  }
}
