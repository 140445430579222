import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { XPartnerService } from '@shared/services/x-partner.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'df-partner-no-auth',
  templateUrl: './partner-no-auth.component.html',
  styleUrls: ['./partner-no-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerNoAuthComponent implements OnInit {
  constructor(
    private readonly xPartnerService: XPartnerService,
    private readonly n: NavigateService,
  ) {}

  ngOnInit(): void {
    if (!this.xPartnerService.authenticationFailed) {
      this.n.go(Config.MAIN_VIEW);
    }
  }
}
