import { gql } from 'apollo-angular';
import { TeamFragment } from '@modules/user-settings/modules/teams/shared/graphql/fragments/team.fragment';

export const GetResourceTeamsByResourceDocument = gql`
  query getResourceTeamsByResource($input: ResourcesTeamsListInputGraphql!) {
    getResourceTeamsByResource(input: $input) {
      id
      permissions {
        id
        permission
        teamMember {
          id
          email
          user {
            id
            firstName
            lastName
            email
            avatar {
              file
            }
          }
        }
      }
      team {
        ...TeamFragment
      }
    }
  }
  ${TeamFragment}
`;
