<div
  *ngIf="tactic"
  class="rounded-lg warning-banner p-4 flex flex-col items-start justify-start"
  [ngClass]="{
    'md:flex-row md:justify-between md:items-center': !vertical
  }"
>
  <div class="flex items-center">
    <svg-icon
      src="assets/img/ic_warning_yellow.svg"
      [svgStyle]="{ width: '2.5rem', height: '2.5rem' }"
    ></svg-icon>
    <p class="ml-3 text-sm font-semibold">
      {{ 'Tactics.verification warning' | translate }}
    </p>
  </div>
  <df-tactic-verification-badge
    [tactic]="tactic"
    class="flex-shrink-0 mt-3"
    [ngClass]="{
      'md:mt-0 md:ml-2': !vertical
    }"
  ></df-tactic-verification-badge>
</div>
