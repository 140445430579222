<div class="popup-container">
  <svg-icon src="assets/img/golden-coin.svg"></svg-icon>
  <h4 class="mt-5 mb-3 text-xl tui-text-03 font-semibold">
    {{ 'Pricing.Not enough credits' | translate }}
  </h4>
  <h4 class="text-sm font-medium tui-text-05">
    {{
      'Pricing.Not enough credits ...'
        | translate
          : {
              packageName: userService.planName,
              credits: userService.planCredits
            }
    }}
  </h4>
  <div class="button-wrapper">
    <button
      tuiButton
      appearance="outline"
      size="xl"
      (click)="close()"
    >
      <span class="tui-primary">{{ 'Pricing.Cancel' | translate }}</span>
    </button>
    <button
      tuiButton
      appearance="primary"
      size="xl"
      (click)="goToPackets()"
    >
      <span>{{ 'Pricing.Upgrade your plan to get more credits' | translate }}</span>
    </button>
  </div>
</div>
