import { NgModule } from '@angular/core';
import { PublicTacticPreviewComponent } from './pages/public-tactic-preview/public-tactic-preview.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { TacticPreviewModule } from '@modules/tactics/modules/tactic-preview/tactic-preview.module';
import { PublicStatementComponent } from './pages/public-statement/public-statement.component';
import { StatementModule } from '@modules/statement/statement.module';
import { PublicPricingComponent } from './pages/public-pricing/public-pricing.component';
import { PricingModule } from '@modules/pricing/pricing.module';
import { AuthorizationModule } from '@modules/authorization/authorization.module';
import { AuthModalComponent } from './shared/components/register-modal/auth-modal.component';
import { PublicLoginComponent } from './pages/public-login/public-login.component';
import { PublicRegisterComponent } from './pages/public-register/public-register.component';
import { PublicTacticManageComponent } from './pages/public-tactic-manage/public-tactic-manage.component';
import { TacticSettingsModule } from '@modules/tactics/modules/tactic-settings/tactic-settings.module';
import { PublicPricingCampaignComponent } from './pages/public-pricing-campaign/public-pricing-campaign.component';
import { PublicFunnelComponent } from './pages/public-funnel/public-funnel.component';
import { SwiperModule } from 'swiper/angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FunnelStepsService } from '@shared/services/funnel-steps.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelManageContentStepHeaderComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-manage/components/funnel-manage-content/components/funnel-manage-content-step-header/funnel-manage-content-step-header.component';
import { FunnelManageTacticActivitiesComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-tactic-activities/funnel-manage-tactic-activities.component';
import { FunnelManageModule } from '@modules/funnels/modules/funnel-manage/funnel-manage.module';
import { PublicFunnelModalComponent } from './pages/public-funnel/components/public-funnel-modal/public-funnel-modal.component';
import { PublicRoutes } from './shared/configs/public.routes';

import { PublicTacticsComponent } from './pages/public-tactics/public-tactics.component';
import { TacticsListModule } from '@modules/tactics/modules/tactics-list/tactics-list.module';
import { PublicRecommenderComponent } from './pages/public-recommender/public-recommender.component';
import { PublicFunnelSendEmailModalComponent } from './pages/public-funnel/components/public-funnel-send-email-modal/public-funnel-send-email-modal.component';
import { PublicRecommenderSetUrlModalComponent } from './pages/public-recommender/components/public-recommender-set-url-form/public-recommender-set-url-form.component';

@NgModule({
  declarations: [
    PublicTacticsComponent,
    PublicTacticPreviewComponent,
    PublicStatementComponent,
    PublicPricingComponent,
    PublicLoginComponent,
    PublicRegisterComponent,
    PublicTacticManageComponent,
    PublicPricingCampaignComponent,
    PublicFunnelComponent,
    PublicFunnelSendEmailModalComponent,
    PublicFunnelModalComponent,
    PublicRecommenderComponent,
    AuthModalComponent,
    PublicRecommenderComponent,
    PublicRecommenderSetUrlModalComponent,
    FunnelManageContentStepHeaderComponent,
    FunnelManageTacticActivitiesComponent,
  ],
  providers: [FunnelStepsService, FunnelManageService],
  imports: [
    SharedModule,
    TacticPreviewModule,
    TacticsListModule,
    StatementModule,
    RouterModule.forChild(PublicRoutes),
    PricingModule,
    AuthorizationModule,
    TacticSettingsModule,
    SwiperModule,
    DragDropModule,
    FunnelManageModule,
  ],
  exports: [PublicFunnelComponent],
})
export class PublicModule {}
