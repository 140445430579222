import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { Subscription } from 'rxjs';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { IVerticalTab } from '@shared/modules/ui/components/vertical-tabs/vertical-tab.interface';
import { Step } from '@shared/models/step.model';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { tacticsListTabsConst } from '@modules/tactics/modules/tactics-list/shared/components/tactics-list-header/tactics-list-tabs.const';
import { TacticListTypeEnum } from '@modules/graphql/graphql-types';
import { HeaderTab } from '@shared/modules/ui/components/header-tabs/header-tabs.component';
import { filter } from 'rxjs/operators';
import { ListEvent, ListEventType } from '@shared/services/base-list.service';

@Component({
  selector: 'df-tactics-list-header',
  templateUrl: './tactics-list-header.component.html',
  styleUrls: ['./tactics-list-header.component.scss'],
})
export class TacticsListHeaderComponent implements OnInit, OnDestroy {
  tabs: HeaderTab[] = [];
  dummyFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  sub: Subscription = new Subscription();
  mobileOpened = false;
  isMyFunnel = false;
  disableAllTabs = false;

  @HostBinding('class.mobile-opened')
  get isMobileOpened() {
    return this.mobileOpened;
  }

  @HostBinding('class.modal-list')
  get isModalList() {
    return this.modalList;
  }

  @Input() modalList = false;
  @Input() staticStep: Step | null = null;

  readonly stringify: TuiStringHandler<
    | { sortField: string; sortDirection: string; name: string }
    | TuiContextWithImplicit<{
        sortField: string;
        sortDirection: string;
        name: string;
      }>
  > = (item) => ('name' in item ? item.name : item.$implicit.name);

  constructor(
    public t: TranslateService,
    public n: NavigateService,
    public tacticsFiltersService: TacticsFiltersService,
    private tacticsListService: TacticsListService,
    private changes: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.tabs = tacticsListTabsConst(this.n, this.t, this.modalList);
    this.listenChangeRoute();
    this.listenListEvents();
  }

  private listenListEvents() {
    const sub = this.tacticsListService.emitter.subscribe((res: ListEvent) => {
      switch (res.type) {
        case ListEventType.START_GETTING_RECORDS:
          this.disableAllTabs = true;
          this.changes.detectChanges();
          break;
        case ListEventType.END_GETTING_RECORDS:
          this.disableAllTabs = false;
          this.changes.detectChanges();
          break;
      }
    });

    this.sub.add(sub);
  }

  listenChangeRoute() {
    this.isMyFunnel = this.route.snapshot?.firstChild?.data?.type === TacticListTypeEnum.MyFunnel;
    this.changes.detectChanges();
    const sub = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.closeMobile();
      this.isMyFunnel = this.route.snapshot?.firstChild?.data?.type === TacticListTypeEnum.MyFunnel;
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  onTabChange(tab: IVerticalTab) {
    this.isMyFunnel = tab.type === TacticListTypeEnum.MyFunnel;
    this.tacticsFiltersService.filterFormGroup.get('type')?.setValue(tab.type);
    this.changes.detectChanges();
  }

  openMobile() {
    this.mobileOpened = true;
  }

  closeMobile() {
    this.mobileOpened = false;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
