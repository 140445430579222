import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarketingCampaignService } from '@modules/marketing-campaign/shared/services/marketing-campaign.service';

import Typewriter from 'typewriter-effect/dist/core';

interface Aida {
  action: string[];
  attention: string[];
  desire: string[];
  interest: string[];
}

@Component({
  selector: 'df-aida',
  templateUrl: './aida.component.html',
  styleUrls: ['./aida.component.scss'],
})
export class AidaComponent implements OnInit {
  @Input() campaignLoading = true;
  @Output() loadedSuccess = new EventEmitter();

  aida?: Aida;
  showSkeleton = true;
  funnelId!: number;

  constructor(
    private marketingCampaignService: MarketingCampaignService,
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.funnelId = Number(this.route.snapshot.params['funnelId']);

    if (this.funnelId) {
      const timer = setInterval(() => this.getAida(timer), 10000);
      this.getAida(timer);
    } else {
      this.route.parent?.params.subscribe(() => {
        this.funnelId = +this.route.parent?.snapshot.params['id'];
        this.aida = undefined;
        this.showSkeleton = true;

        this.clear();
        this.changes.detectChanges();

        const timer = setInterval(() => this.getAida(timer), 10000);
        this.getAida(timer);
      });
    }
  }

  clear() {
    const element = document.querySelector('#typewriter');

    while (element?.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  getAida(timer = null) {
    const type = 'aida';

    this.marketingCampaignService.getDataByType(this.funnelId, type).subscribe((res) => {
      if (res?.data && res.data[0]) {
        if (timer) clearInterval(timer);

        this.aida = res.data[0].data;
        this.loadedSuccess.emit();
        this.showSkeleton = false;
        this.animate();
        this.changes.detectChanges();
      }
    });
  }

  animate() {
    const element = document.querySelector('#typewriter');

    this.clear();

    let template = `<div class="p-2 controller flex flex-col items-center md:p-6">`;
    let width = 100;

    for (const key in this.aida) {
      template += `<div class="bg-white py-6 px-4 rounded-[12px] mb-6 flex flex-col justify-center items-center print:mb-4" style="width:${width}%">
          <h2 class="text-[#2E4FFF] font-bold capitalize text-[1.5rem] mb-6 print:mb-2">${key}</h2>`;

      for (const value of this.aida[key]) {
        template += `<p class="text-center">${value}</p>`;
      }

      template += `</div>`;
      width -= 10;
    }

    template += `</div>`;
    if (this.campaignLoading) {
      const typewriter = new Typewriter(element, {
        delay: 1,
      });
      typewriter.typeString(template).start();
    } else {
      element?.insertAdjacentHTML('beforeend', template);
    }
  }
}
