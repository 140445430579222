<!-- sort -->
<div class="sort-subheader">
  <div
    class="flex flex-nowrap justify-between items-center h-12"
    *ngIf="currentTab"
  >
    <h2 class="tui-text_body-m-1 tui-text-01">{{ currentTab.name }}</h2>
    <div class="flex justify-end">
      <ng-content
        select="[purchased]"
        class="mr-3"
        *ngIf="currentTab.type === TacticListTypeEnum.My"
      ></ng-content>

      <button
        tuiButton
        appearance="outline"
        class="sm:hidden mr-3 tui-base-01-bg"
        (click)="showFilters()"
      >
        <svg-icon
          class="tui-primary"
          [src]="'/assets/img/ic_sliders_v-alt.svg'"
        ></svg-icon>
      </button>
      <ng-content select="[sort]"></ng-content>
      <df-sort-list-filter [filtersService]="filtersService"></df-sort-list-filter>
    </div>
  </div>
</div>
