import { Injectable, Injector } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';
import { UserService } from '@shared/services/user.service';
import { NavigationStart } from '@angular/router';

const PRICING_URL_PART = 'pricing/packets';

@Injectable({
  providedIn: 'root',
})
export class ActiveSubscriptionService {
  private noActiveSubscription: boolean | null = null;
  private sub: Subscription = new Subscription();
  private openPricing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userService: UserService | null = null;
  private currentUserId: number | null = null;

  constructor(private readonly n: NavigateService) {
    this.listenOpenPricing();
  }

  get getNoActiveSubscription(): boolean | null {
    return this.noActiveSubscription;
  }

  markNoActiveSubscriptionError(i: Injector): void {
    if (this.userService === null) {
      this.userService = i.get(UserService);
      this.currentUserId = this.userService.User?.id ?? null;
      this.listenUserChange();
    }
    if (this.noActiveSubscription === null) {
      this.sub.unsubscribe();
      this.sub = new Subscription();
    }
    this.noActiveSubscription = true;
    this.n.go('pricing/packets');
  }

  private listenRouteChange(): void {
    const sub = this.n.router.events
      .pipe(filter((event): event is NavigationStart => event instanceof NavigationStart))
      .subscribe((event) => {
        if (event.url.indexOf(PRICING_URL_PART) > 0) return;
        this.noActiveSubscription ? this.openPricing() : '';
      });
    this.sub.add(sub);
  }

  private listenOpenPricing(): void {
    this.openPricing$
      .pipe(
        filter((val) => val),
        throttleTime(500),
      )
      .subscribe(() => this.showPricingPanel());
  }

  private listenUserChange(): void {
    this.userService!.userEvents$.subscribe(() => {
      const id = this.userService!.User?.id ?? null;
      id !== this.currentUserId ? this.reset() : '';
      this.currentUserId = id;
    });
  }

  private openPricing(): void {
    this.openPricing$.next(true);
  }

  private showPricingPanel(): void {
    this.n.router.navigate(['', { outlets: { pricing: this.userService?.resolvePricingPacketsUrl() } }], {
      skipLocationChange: true,
    });
  }

  private reset(): void {
    this.noActiveSubscription = null;
    this.sub.unsubscribe();
  }

  public showPricing(): void {
    this.showPricingPanel();
  }
}
