import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const SetAppSumoSubscriptionDocument = gql`
  mutation setAppSumoSubscription($token: String!) {
    setAppSumoSubscription(token: $token) {
      ...UserStorage
    }
  }
  ${UserStorageFragment}
`;
