import { TuiDialogService } from '@taiga-ui/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { IntegrationInputConfiguration, IntegrationValidationPattern } from '@shared/models/integration.model';
import { InputTypeEnum } from '@modules/graphql/graphql-types';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { getAcceptedFileExtensions } from '@shared/helpers/content-generator.helper';
import { TuiFileLike, TuiInputComponent, TuiSelectComponent, TuiTextAreaComponent } from '@taiga-ui/kit';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { rejectedImages } from '@shared/helpers/file-input.helper';
import { Regex } from '@shared/configs/regex';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ContentGeneratorStylesModalComponent } from '../content-generator-styles-modal/content-generator-styles-modal.component';
import { StylesFormService } from '../../../../services/styles-form.service';
import { Subscription } from 'rxjs';
import { ICustomImageTemplateMetadataOption } from '../../../../interfaces/custom-image-template-metadata-option.interface';
import { ContentGeneratorUnsplashModalComponent } from '../content-generator-unsplash-modal/content-generator-unsplash-modal.component';
import { ContentGeneratorUnsplashModalService } from '../content-generator-unsplash-modal/content-generator-unsplash-modal.service';

@Component({
  selector: 'df-content-generator-form-fields-template',
  templateUrl: './content-generator-form-fields-template.component.html',
  styleUrls: ['./content-generator-form-fields-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorFormFieldsTemplateComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup | null = null;
  @Input() configuration;
  @Output() addControlEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeControlEvent: EventEmitter<[id: number, index: number]> = new EventEmitter<
    [id: number, index: number]
  >();
  @Output() fileAddedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fileRemovedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly InputTypeEnum = InputTypeEnum;
  confUnsplash: IntegrationInputConfiguration | undefined;
  confBackground: IntegrationInputConfiguration | undefined;

  @ViewChildren(TuiInputComponent) inputs?: QueryList<TuiInputComponent>;
  @ViewChildren(TuiTextAreaComponent)
  textAreaInputs?: QueryList<TuiTextAreaComponent>;
  @ViewChildren(TuiSelectComponent) selectInputs?: QueryList<TuiSelectComponent<any>>;

  readonly stringify: TuiStringHandler<
    { value: string; label: string } | TuiContextWithImplicit<{ value: string; label: string }>
  > = (item) => ('label' in item ? item.label : item.$implicit.label);

  readonly sizesDisplayLimit: number = 3;
  sizesDisplayAmount: number = this.sizesDisplayLimit;

  get sizesDisplayLimitActive(): boolean {
    return this.sizesDisplayAmount === this.sizesDisplayLimit;
  }

  showStyleButton = false;
  styleSearchValue = '';
  styleSearchOpt: ICustomImageTemplateMetadataOption | null = null;
  sub: Subscription = new Subscription();

  constructor(
    public readonly changes: ChangeDetectorRef,
    private readonly s: SnackbarService,
    private readonly t: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private stylesFormService: StylesFormService,
    private contentGeneratorUnsplashModalService: ContentGeneratorUnsplashModalService,
  ) {}

  ngOnInit(): void {
    this.handleInputRange();
  }

  ngOnChanges(): void {
    setTimeout(this.handleInputRange);
  }

  addControl(id: number): void {
    this.addControlEvent.emit(id);
  }

  removeControl(id: number, index: number): void {
    this.removeControlEvent.emit([id, index]);
  }

  getFormControl(id: number): UntypedFormControl {
    return this.form?.get(id.toString()) as UntypedFormControl;
  }

  getFormArray(id: number): UntypedFormArray {
    return this.form?.get(id.toString()) as UntypedFormArray;
  }

  getFormArrayControls(id: number): UntypedFormControl[] {
    return this.getFormArray(id).controls as UntypedFormControl[];
  }

  getAcceptedImagesExtensions(config: IntegrationInputConfiguration): string {
    return getAcceptedFileExtensions(config.validationPatterns);
  }

  rejectedImages(e: TuiFileLike | readonly TuiFileLike[], config: IntegrationInputConfiguration) {
    rejectedImages(e, this.s, this.t, this.getAcceptedImagesExtensions(config));
    this.changes.detectChanges();
  }

  detectFormFieldsChanges(): void {
    this.inputs?.forEach((input) => input.checkControlUpdate());
    this.textAreaInputs?.forEach((input) => input.checkControlUpdate());
    this.selectInputs?.forEach((input) => input.checkControlUpdate());
    this.changes.detectChanges();
  }

  viewMore($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.sizesDisplayAmount = this.sizesDisplayLimitActive ? 999 : this.sizesDisplayLimit;
    this.changes.detectChanges();
  }

  getMinMaxLength(validationPatterns: IntegrationValidationPattern[]): {
    min: number;
    max: number;
  } {
    let min = 0;
    let max = 0;
    validationPatterns.forEach((pattern) => {
      Object.keys(pattern.rules).forEach((key) => {
        if (key === 'regex') {
          const regex = pattern.rules[key].substring(1, pattern.rules[key].length - 1);
          const regexMatch = regex.match(Regex.min_max);
          if (regexMatch?.length === 1) {
            min = Number(regexMatch[0].split(',')[0].replace('.{', ''));
            max = Number(regexMatch[0].split(',')[1].replace('}', ''));
          }
        }
      });
    });
    return { min, max };
  }

  openStylesModal($event: Event, formControlId: number, metadata: any) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.stylesFormService.formControlId$.next(formControlId);
    this.stylesFormService.stylesModalSub$ = this.dialogService
      .open(new PolymorpheusComponent(ContentGeneratorStylesModalComponent, this.injector), {
        dismissible: false,
        closeable: false,
        size: 'page',
        data: {
          metadata: metadata as {
            options: Array<ICustomImageTemplateMetadataOption>;
          },
        },
      })
      .subscribe();
  }

  getStyleData(inputValue: Array<string> | null, metadata: any): ICustomImageTemplateMetadataOption | null {
    if (inputValue && inputValue.length > 0) {
      const options = (metadata as { options: Array<ICustomImageTemplateMetadataOption> }).options;
      const styleSearchValue = inputValue[0]; // one-element array
      if (styleSearchValue !== this.styleSearchOpt?.value) {
        this.styleSearchValue = styleSearchValue;
        this.styleSearchOpt = options.find((opt) => opt.value === styleSearchValue) || null;
      }
    }
    return this.styleSearchOpt;
  }

  openUnsplashModal($event: Event, fieldId: number): void {
    $event.preventDefault();

    this.contentGeneratorUnsplashModalService.tuiDialogSub$ = this.dialogService
      .open<boolean>(new PolymorpheusComponent(ContentGeneratorUnsplashModalComponent, this.injector), {
        size: 'page',
        dismissible: false,
        closeable: false,
        data: { fieldId },
      })
      .subscribe();
  }

  activateClickForOpenUnsplashModal($event: Event, fieldId: number) {
    $event.preventDefault();

    this.fileAddedEvent.emit(true);

    const val = this.getFormControl(fieldId).value;
    const tagName = ($event.target as HTMLElement).tagName;
    const disabled = this.getFormControl(fieldId).disabled;
    if (val || tagName === 'svg' || disabled) {
      // no action when input value is set & on removing input value (svg: icon-remove if tui-input-files)
      return;
    }
    this.openUnsplashModal($event, fieldId);
  }

  disableFieldlFile() {
    const backgroundDisable = !!this.getFormControl(this.confUnsplash!.id).value;
    const unsplashDisable = !!this.getFormControl(this.confBackground!.id).value;

    backgroundDisable
      ? this.getFormControl(this.confBackground!.id).disable({
          emitEvent: false,
        })
      : this.getFormControl(this.confBackground!.id).enable({
          emitEvent: false,
        });

    unsplashDisable
      ? this.getFormControl(this.confUnsplash!.id).disable({ emitEvent: false })
      : this.getFormControl(this.confUnsplash!.id).enable({ emitEvent: false });
  }

  hasError(configurationId: number): boolean {
    return (
      (this.getFormControl(configurationId).dirty || this.getFormControl(configurationId).touched) &&
      (this.getFormControl(configurationId).errors?.minlength || this.getFormControl(configurationId).errors?.maxlength)
    );
  }

  handleInputRange() {
    const rangeInputs = document.querySelectorAll('input[type="range"]');

    function handleInputChange(e: EventTarget) {
      const target = e as HTMLInputElement;

      const min = +target.min;
      const max = +target.max;
      const val = +target.value;

      target.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%';
    }

    rangeInputs.forEach(handleInputChange);

    rangeInputs.forEach((input) => {
      input.addEventListener('input', (e) => handleInputChange(e.target!));
    });
  }
}
