import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init, BrowserTracing, routingInstrumentation, Replay } from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { inIframe } from '@shared/helpers/in-iframe.helper';

/**
 * Temporary solution for semrush user cache problem
 */
if (inIframe()) {
  Object.defineProperty(window, 'localStorage', {
    value: sessionStorage,
    writable: true,
  });

  const script = document.createElement('script');
  script.src = 'https://static.semrush.com/app-center/sdk.js';
  document.head.appendChild(script);

  script.onload = () => {
    window.SM.init();
  };

  addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      console.log(document.querySelector('.intercom-lightweight-app-launcher'));
      document.querySelector('.intercom-lightweight-app-launcher')?.classList.add('hidden');
    }, 1000);
  });
}

init({
  dsn: environment.SENTRY_URL,
  ignoreErrors: [
    new RegExp('Invariant Violation', 'mi'),
    new RegExp('rdt', 'mi'),
    new RegExp('twq', 'mi'),
    new RegExp('Unauthorized', 'mi'),
    new RegExp('Could not find any entity', 'mi'),
  ],
  integrations: [
    new BrowserTracing({
      routingInstrumentation: routingInstrumentation,
    }),
    new Replay({
      networkDetailAllowUrls: [environment.API],
    }),
  ],
  environment: environment.SENTRY_ENV,
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
