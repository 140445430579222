import { BaseFiltersService } from '@shared/services/base-filters.service';
import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TacticListTypeEnum } from '@modules/graphql/graphql-types';
import { tacticsListTabsConst } from '@modules/tactics/modules/tactics-list/shared/components/tactics-list-header/tactics-list-tabs.const';
import { TranslateService } from '@ngx-translate/core';
import { HeaderTab } from '@shared/modules/ui/components/header-tabs/header-tabs.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { UsersFiltersService } from '@modules/users/modules/users-list/shared/services/users-filters.service';
import { FilterTypeService } from '@shared/services/filter-type.service';

@Component({
  selector: 'df-sort-list-sub-header',
  templateUrl: './sort-list-sub-header.component.html',
  styleUrls: ['./sort-list-sub-header.component.scss'],
})
export class SortListSubHeaderComponent implements OnInit, OnDestroy {
  readonly TacticListTypeEnum = TacticListTypeEnum;
  sub!: Subscription;
  tabs: HeaderTab[] = [];
  currentTab: HeaderTab | null = null;
  @Input() modalList = false;
  @Input() filtersService!: TacticsFiltersService | UsersFiltersService;

  constructor(
    private t: TranslateService,
    private n: NavigateService,
    private router: Router,
    private changes: ChangeDetectorRef,
    private baseFiltersService: BaseFiltersService,
    private filterTypeService: FilterTypeService,
  ) {}

  ngOnInit(): void {
    this.sub = new Subscription();

    this.tabs = tacticsListTabsConst(this.n, this.t, this.modalList);
    this.listenRouteChange();
  }

  listenRouteChange() {
    this.setCurrentTab(this.router.url);
    this.sub.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => this.setCurrentTab((event as NavigationEnd).url)),
    );
  }

  setCurrentTab(url: string) {
    this.currentTab = this.tabs.find((tab) => url.startsWith(tab.route)) ?? null;
    this.changes.detectChanges();
  }

  showFilters() {
    const sub = this.baseFiltersService.showAsDialog();
    // remember dialog sub - to unsubscribe from Observable to close a dialog
    this.filterTypeService.$dialogSub.next(sub);
  }

  ngOnDestroy(): void {
    this.filterTypeService.$dialogSub.unsubscribe();
  }
}
