<df-tactic-step-wrapper
  *ngIf="type === 'ads' && !isLoading"
  [stepText]="'1.Tactic step goes here'"
>
  <div
    *ngIf="itemsAds?.ads && itemsAds.ads.length > 0"
    class="grid grid-cols-2 gap-4"
  >
    <df-tactic-ad
      *ngFor="let item of itemsAds.ads; let i = index"
      [headerTitle]="'Facebook ad'"
      [headerSubTitle]="'(1200x640px)'"
      [imageSrc]="itemsAds.images[i]"
      [contentTitle]="'Ad description'"
      [contentText]="getText(item)"
      [isRegenerating]="isAddRegenerating(i)"
      (regenerateClick)="regenerateAdd(i)"
    ></df-tactic-ad>
  </div>
</df-tactic-step-wrapper>

<df-tactic-step-wrapper
  *ngIf="type === 'social' && !isLoading"
  [stepText]="'1.Tactic step goes here'"
>
  <div
    *ngIf="itemsAds?.ads && itemsAds.ads.length > 0"
    class="grid grid-cols-2 gap-4"
  >
    <df-tactic-ad
      *ngFor="let item of itemsAds.ads; let i = index"
      [headerTitle]="'Social ad'"
      [headerSubTitle]="'(1200x640px)'"
      [imageSrc]="itemsAds.images[i]"
      [contentTitle]="'Ad description'"
      [contentText]="getText(item)"
      [isRegenerating]="isAddRegenerating(i)"
      (regenerateClick)="regenerateAdd(i)"
    ></df-tactic-ad>
  </div>
</df-tactic-step-wrapper>

<df-tactic-step-wrapper
  *ngIf="type === 'newsletter' && !isLoading"
  [stepText]="'1.Tactic step goes here'"
>
  <df-tactic-newsletter
    *ngIf="emailItem"
    [item]="emailItem"
    (regenerateClick)="regenerateNewsletter()"
    [isRegenerating]="isRegeneratingNewsletter"
  ></df-tactic-newsletter>
</df-tactic-step-wrapper>

<df-tactic-step-wrapper
  class="w-full"
  *ngIf="type === 'landing' && !isLoading"
  [stepText]="'2.Tactic step goes here'"
>
  <df-tactic-landing [funnelId]="funnelId"></df-tactic-landing>
</df-tactic-step-wrapper>
<df-full-list-loader *ngIf="isLoading"></df-full-list-loader>
