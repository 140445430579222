import { FlowOutputGraphql } from '@modules/graphql/graphql-types';
import { FlowItem } from '@shared/models/flow-item.model';

export class Flow implements FlowOutputGraphql {
  id!: number;
  name!: string;
  items: FlowItem[] = [];
  highlighted?: boolean = false;
  tacticIds: number[] = [];

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
    this.setTacticsIds();
  }

  setTacticsIds() {
    this.tacticIds = [];
    this.items.map((flowItem: FlowItem) => {
      if (this.tacticIds.indexOf(flowItem.from) === -1) {
        this.tacticIds.push(flowItem.from);
      }
      if (this.tacticIds.indexOf(flowItem.to) === -1) {
        this.tacticIds.push(flowItem.to);
      }
    });
  }
}
