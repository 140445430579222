<div
  [class]="'novu-notifications' + userService.User?.isSemrushPlan ? 'hidden' : null"
  *ngIf="0 < list.length"
>
  <div class="list-container">
    <div class="notifications-container">
      <div class="notifications-container-label">
        <i
          aria-label="icon: fire"
          class="anticon anticon-fire"
        >
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="fire"
            width="1em"
            height="1em"
            fill="white"
            aria-hidden="true"
          >
            <path
              d="M834.1 469.2A347.49 347.49 0 0 0 751.2 354l-29.1-26.7a8.09 8.09 0 0 0-13 3.3l-13 37.3c-8.1 23.4-23 47.3-44.1 70.8-1.4 1.5-3 1.9-4.1 2-1.1.1-2.8-.1-4.3-1.5-1.4-1.2-2.1-3-2-4.8 3.7-60.2-14.3-128.1-53.7-202C555.3 171 510 123.1 453.4 89.7l-41.3-24.3c-5.4-3.2-12.3 1-12 7.3l2.2 48c1.5 32.8-2.3 61.8-11.3 85.9-11 29.5-26.8 56.9-47 81.5a295.64 295.64 0 0 1-47.5 46.1 352.6 352.6 0 0 0-100.3 121.5A347.75 347.75 0 0 0 160 610c0 47.2 9.3 92.9 27.7 136a349.4 349.4 0 0 0 75.5 110.9c32.4 32 70 57.2 111.9 74.7C418.5 949.8 464.5 959 512 959s93.5-9.2 136.9-27.3A348.6 348.6 0 0 0 760.8 857c32.4-32 57.8-69.4 75.5-110.9a344.2 344.2 0 0 0 27.7-136c0-48.8-10-96.2-29.9-140.9z"
            ></path>
          </svg>
        </i>
        <div
          class="close-btn"
          (click)="closeNotification()"
        >
          <i
            aria-label="icon: close-circle"
            class="anticon anticon-close-circle"
            style="color: white; font-size: 27px"
          >
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="close-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"
              ></path>
            </svg>
          </i>
        </div>
        What's new
      </div>
      <div class="annoucement-list-container">
        <div
          class="notification-item"
          *ngFor="let item of list"
          (click)="recivedNotification(item.id)"
        >
          <div class="notification-icon">
            <img
              src="/assets/img/nuva-notification.gif"
              alt="Novu Notification"
            />
          </div>
          <div class="notification-text-content">
            <div class="annoucement-type-container">
              <div
                class="annoucement-color"
                style="background: rgb(0, 208, 132)"
              ></div>
              <div class="annoucement-type">Feature</div>
              <div class="annoucement-date">
                {{ item?.createdAt | date: 'dd/MM/yyyy' }}
              </div>
            </div>
            <div
              class="notification-description"
              [innerHTML]="item?.message"
            ></div>
          </div>
          <div
            class="not-seen-red-dot"
            *ngIf="!item?.received"
          ></div>
        </div>
      </div>
      <div class="notifications-footer">
        <p class="notification-label">Notifications</p>
      </div>
    </div>
  </div>
</div>
