import { EOnboardingElementType, EOnboardingStep, OnboardingStep } from '@shared/models/onboarding-step.model';
import { OnboardingService } from '@shared/services/onboarding.service';
import { TranslateService } from '@ngx-translate/core';

export const OnboardingSteps = (o: OnboardingService, t: TranslateService): OnboardingStep[] => {
  return [
    {
      type: EOnboardingStep.TACTICS_INTRO,
      routeName: '/tactics/list/all',
      nextStep() {
        o.nextStep();
      },
      elements: [],
    },
    {
      type: EOnboardingStep.TACTICS_ADD_TO_FUNNEL,
      nextStep(customAction?: () => void) {
        customAction ? customAction() : '';
        o.loading = true;
        setTimeout(() => o.nextStep(800), 300);
      },
      elements: [
        {
          type: EOnboardingElementType.accentLabel,
          text: t.instant('Tactics.Onboarding.Add tactic to sample funnel'),
        },
        {
          type: EOnboardingElementType.tooltip,
          title: t.instant('Tactics.Onboarding.This tactic fits to your needs'),
          subtitle: t.instant('Tactics.Onboarding.Use the Funnel icon to add a tactic to a funnel'),
          position: 'bottom',
        },
        {
          type: EOnboardingElementType.arrow,
          position: 'right',
          distance: 55,
        },
      ],
    },
    {
      type: EOnboardingStep.FUNNEL_DASHBOARD,
      routeName: '/funnels/f/onboarding',
      nextStep(addTacticToFlow: () => void) {
        o.nextStep(window.innerWidth < 1440 ? 1500 : 500);
        setTimeout(() => addTacticToFlow && addTacticToFlow(), 500);
      },
      elements: [],
      helpMarks: [
        { text: t.instant('Funnels.Onboarding.tooltip icebreaker') },
        { text: t.instant('Funnels.Onboarding.tooltip cheeseburger') },
        { text: t.instant('Funnels.Onboarding.tooltip main-product') },
        { text: t.instant('Funnels.Onboarding.tooltip upsell') },
      ],
    },
    {
      type: EOnboardingStep.FUNNEL_TACTIC_DROP,
      nextStep() {
        setTimeout(() => o.nextStep(), 500);
      },
      elements: [
        {
          type: EOnboardingElementType.accentLabel,
          text: t.instant('Funnels.Onboarding.Drag & Drop added tactic'),
        },
      ],
    },
    {
      type: EOnboardingStep.FUNNEL_FLOW_FINISHED,
      nextStep() {
        o.nextStep();
      },
      elements: [],
    },
  ];
};
