import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FunnelGraphicsGeneratorService } from '../../../pages/funnel-panel/components/funnel-graphics/funnel-graphics-generator.service';
import { FunnelGraphicTab } from '../../interfaces/funnel-graphic-tab';

@Component({
  selector: 'df-funnel-graphic-tabs',
  templateUrl: './funnel-graphic-tabs.component.html',
  styleUrls: ['./funnel-graphic-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelGraphicTabsComponent {
  @Output() tabChange = new EventEmitter();

  activeItemIndex = 0;

  constructor(
    readonly graphicsGeneratorService: FunnelGraphicsGeneratorService,
    private readonly changes: ChangeDetectorRef,
  ) {}

  onTabSelect(tab: FunnelGraphicTab): void {
    this.activeItemIndex = tab.index;
    this.graphicsGeneratorService.setTab(tab);
    this.changes.detectChanges();
    this.tabChange.emit();
  }
}
