<header class="mb-8 print:mt-4 print:break-before-page">
  <h2 class="font-bold text-[24px] text-[#2E4FFF] col">
    {{ 'MarketingCampaign.Content strategy plan' | translate }}
  </h2>
  <p class="text-[#191C30BF] font-medium text-[16px] mt-4">
    {{ 'MarketingCampaign.Start educating clients about your product this week!' | translate }}
  </p>
</header>

<div
  *ngIf="campaign"
  class="controller grid grid-cols-1 lg:grid-cols-4 grid-rows-2 gap-6"
>
  <div
    class="bg-white py-6 px-4 rounded-[12px] print:py-1"
    *ngFor="let plan of campaign.data.contentStrategyPlan"
  >
    <tui-tag
      class="mb-4 bg-[#cbd3ff] text-[#2037b3]"
      [value]="plan.day"
    ></tui-tag>
    <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4">
      {{ plan.activity }}
    </h2>
    <p class="text-[14px] text-[#191C30BF]">{{ plan.objective }}</p>
  </div>
</div>

<df-skeleton
  [showSkeleton]="!campaign"
  [rows]="1"
  [cols]="3"
></df-skeleton>
