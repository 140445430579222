<div class="flex justify-center md:justify-between items-center w-full">
  <df-header-title class="flex-shrink-0"></df-header-title>

  <div class="fixed bottom-0 left-0 right-0 p-2 tui-base-01-bg z-50 md:hidden">
    <div class="w-full grid grid-cols-2 gap-x-3">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>
  </div>

  <div class="hidden md:flex flex-nowrap justify-end items-stretch w-full gap-x-3">
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>
</div>
<ng-template #buttons>
  <button
    *ngIf="!isPublic"
    class="hidden md:block"
    size="l"
    tuiButton
    type="button"
    appearance="flat"
    [routerLink]="n.getPath('/tactics/list/all')"
  >
    <svg-icon
      src="assets/img/_ic_book.svg"
      class="tui-primary mr-2"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
    ></svg-icon>
    <span class="tui-primary font-semibold">{{ 'Tactics.Header.library' | translate }}</span>
  </button>
  <button
    *ngIf="!isPublic"
    size="l"
    tuiButton
    type="button"
    appearance="outline"
    [disabled]="loading"
    (click)="cancel()"
  >
    <span class="tui-primary font-semibold">{{ 'cancel' | translate }}</span>
  </button>
  <button
    size="l"
    tuiButton
    type="submit"
    appearance="primary"
    [showLoader]="loading"
    [disabled]="loading || ((isEditMode || isPublic) && !anyChanges)"
    (click)="save()"
  >
    <span class="font-semibold">{{ 'save' | translate }}</span>
  </button>
</ng-template>
