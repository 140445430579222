/* eslint-disable max-len */
import { Regex } from '@shared/configs/regex';
import { ElementRef, Injector, Renderer2 } from '@angular/core';
import { ActivityBenchmark } from '@shared/models/activity-benchmark.model';
import { TuiDialogService } from '@taiga-ui/core';
import Quill from 'quill';
import { Subscription } from 'rxjs';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ActivityBenchmarkPreviewModalComponent } from '@modules/tactics/shared/components/activity-benchmark-preview-modal/activity-benchmark-preview-modal.component';
import { BenchmarkTypeEnum } from '@modules/graphql/graphql-types';
import { ActivityAssetPreviewModalComponent } from '@modules/tactics/shared/components/activity-asset-preview-modal/activity-asset-preview-modal.component';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import FileSaver from 'file-saver';
import { Config } from '@shared/configs/config';

const Embed = Quill.import('blots/embed');
const List = Quill.import('formats/list');

export enum EQuillElemClass {
  LINK = 'link',
  IMG = 'img',
  FILE = 'file',
  VIDEO = 'video',
}

export enum EQuillDenotationChar {
  BENCHMARKS = '@',
  ASSETS = '#',
}

export enum EQuillCustomBlot {
  CUSTOM_EMBED = 'customEmbed',
}

export enum EQuillListStyle {
  DECIMAL = 'list-style: decimal',
  DISC = 'list-style: disc',
}

export class ListNew extends List {
  allowedChildren = [ListItemNew];
  // UListItem.requiredContainer = UListContainer;
  static create(value) {
    const node = super.create();
    const numbered = value === 'ordered' || value === 'zamówił'; // handle PL translation
    numbered ? node.setAttribute('style', EQuillListStyle.DECIMAL) : node.setAttribute('style', EQuillListStyle.DISC);
    return node;
  }

  static formats(domNode) {
    if (domNode.getAttribute('style') === EQuillListStyle.DECIMAL) return 'ordered';
    if (domNode.tagName === 'UL') {
      if (domNode.hasAttribute('data-checked')) {
        return domNode.getAttribute('data-checked') === 'true' ? 'checked' : 'unchecked';
      } else {
        return 'bullet';
      }
    }
    return undefined;
  }
}

export class ListItemNew extends List {
  static create(value) {
    const node = super.create();
    const numbered = value === 'ordered' || value === 'zamówił';
    numbered
      ? node.setAttribute('style', 'list-style-type: decimal')
      : node.setAttribute('style', 'list-style-type: disc');
    return node;
  }
}

export class CustomEmbedBlot extends Embed {
  static blotName = 'customEmbed';
  static tagName = 'span';
  static className = 'customEmbed';

  constructor(scroll, node) {
    super(scroll, node);
  }

  static create(data) {
    const node = super.create();
    let innerLink: HTMLAnchorElement | HTMLIFrameElement = document.createElement('a');

    innerLink = document.createElement('a');
    innerLink.className = 'inner-link';
    innerLink.innerHTML = data.text;
    innerLink.href = data.href;

    node.appendChild(innerLink);

    // node.innerHTML += data.value;
    return CustomEmbedBlot.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

export function setCursorPosition(quill: Quill, position: number) {
  setTimeout(() => {
    // quill.setSelection only works with setTimeout
    quill.setSelection(position, 0);
    quill.focus();
  }, 1);
}

export function prepareQuillIcons(quillIcons: Record<string, string>) {
  quillIcons['undo'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 19 8" fill="none"><path d="M7.4 1.4C5.7 1.7 4.2 2.3 2.8 3.4L0 0.5V7.5H7L4.3 4.8C8 2.2 13.1 3 15.8 6.7C16 7 16.2 7.2 16.3 7.5L18.1 6.6C15.9 2.8 11.7 0.7 7.4 1.4Z" fill="black"/></svg>';
  quillIcons['redo'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 19 8" fill="none"><path d="M7.4 1.4C5.7 1.7 4.2 2.3 2.8 3.4L0 0.5V7.5H7L4.3 4.8C8 2.2 13.1 3 15.8 6.7C16 7 16.2 7.2 16.3 7.5L18.1 6.6C15.9 2.8 11.7 0.7 7.4 1.4Z" fill="black"/></svg>';
  quillIcons['link'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none"><path d="M9 10H5C3.67392 10 2.40215 9.47322 1.46447 8.53553C0.526784 7.59785 0 6.32608 0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0H9V2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5C2 5.79565 2.31607 6.55871 2.87868 7.12132C3.44129 7.68393 4.20435 8 5 8H9V10ZM15 0H11V2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5C18 5.79565 17.6839 6.55871 17.1213 7.12132C16.5587 7.68393 15.7956 8 15 8H11V10H15C16.3261 10 17.5979 9.47322 18.5355 8.53553C19.4732 7.59785 20 6.32608 20 5C20 3.67392 19.4732 2.40215 18.5355 1.46447C17.5979 0.526784 16.3261 0 15 0ZM14 4H6V6H14V4Z" fill="black"/></svg>';
  quillIcons['file'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none"><path d="M2 0C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H12C13.1 18 14 17.1 14 16V4.6L9.4 0H2ZM12 16H2V2H8V6H12V16Z" fill="black"/></svg>';
  quillIcons['video'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none"><path d="M12 2V10H2V2H12ZM14 4.5V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L16.29 9.79C16.4299 9.93103 16.6086 10.0272 16.8033 10.0663C16.9981 10.1054 17.2 10.0855 17.3835 10.0094C17.5669 9.9332 17.7235 9.80413 17.8333 9.63861C17.9431 9.47309 18.0012 9.27862 18 9.08V2.91C18.0012 2.71138 17.9431 2.51691 17.8333 2.35139C17.7235 2.18587 17.5669 2.0568 17.3835 1.98063C17.2 1.90446 16.9981 1.88465 16.8033 1.92372C16.6086 1.96279 16.4299 2.05897 16.29 2.2L14 4.5Z" fill="black"/></svg>';
  quillIcons['bold'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none"><path d="M9.25039 6.8C9.74002 6.50184 10.1532 6.09342 10.4571 5.60729C10.7609 5.12116 10.9469 4.57077 11.0004 4C11.0097 3.48388 10.9172 2.971 10.7282 2.49063C10.5392 2.01027 10.2575 1.57184 9.899 1.20039C9.54055 0.828938 9.11244 0.531741 8.63911 0.325776C8.16578 0.119811 7.65651 0.00911107 7.14039 0H0.650391V14H7.65039C8.14154 13.9948 8.62685 13.8929 9.07862 13.7001C9.53038 13.5073 9.93974 13.2274 10.2833 12.8764C10.6269 12.5253 10.898 12.1101 11.0811 11.6543C11.2642 11.1985 11.3557 10.7112 11.3504 10.22V10.1C11.3507 9.40711 11.1533 8.72852 10.7813 8.14395C10.4093 7.55939 9.87821 7.09315 9.25039 6.8ZM2.65039 2H6.85039C7.27656 1.98681 7.69657 2.10428 8.05407 2.33665C8.41156 2.56902 8.68943 2.90517 8.85039 3.3C9.01326 3.82779 8.96059 4.39859 8.70387 4.88765C8.44716 5.37672 8.00729 5.74428 7.48039 5.91C7.27577 5.96996 7.06361 6.00027 6.85039 6H2.65039V2ZM7.25039 12H2.65039V8H7.25039C7.67656 7.98681 8.09657 8.10428 8.45407 8.33665C8.81156 8.56902 9.08943 8.90517 9.25039 9.3C9.41326 9.82779 9.36059 10.3986 9.10388 10.8877C8.84716 11.3767 8.40729 11.7443 7.88039 11.91C7.67577 11.97 7.46361 12.0003 7.25039 12Z" fill="black"/></svg>';
  quillIcons['italic'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="6" height="14" viewBox="0 0 6 14" fill="none"><path d="M2.75957 4H4.75957L2.55957 14H0.55957L2.75957 4ZM4.43957 0C4.24179 0 4.04845 0.0586491 3.884 0.16853C3.71955 0.278412 3.59138 0.434591 3.51569 0.617317C3.44 0.800043 3.4202 1.00111 3.45879 1.19509C3.49737 1.38907 3.59261 1.56725 3.73246 1.70711C3.87232 1.84696 4.0505 1.9422 4.24448 1.98079C4.43846 2.01937 4.63953 1.99957 4.82225 1.92388C5.00498 1.84819 5.16116 1.72002 5.27104 1.55557C5.38092 1.39112 5.43957 1.19778 5.43957 1C5.43957 0.734784 5.33421 0.48043 5.14668 0.292893C4.95914 0.105357 4.70479 0 4.43957 0Z" fill="black"/></svg>';
  quillIcons['underline'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none"><path d="M14 15V17H0V15H14ZM11 8.215C10.9671 8.87498 10.7711 9.51653 10.4297 10.0823C10.0883 10.6481 9.61207 11.1205 9.04352 11.4572C8.47497 11.794 7.83184 11.9847 7.17161 12.0122C6.51138 12.0397 5.85461 11.9033 5.26 11.615C4.57464 11.3185 3.99341 10.8241 3.59077 10.1952C3.18813 9.56633 2.98242 8.83154 3 8.085V0.00500011H1V8.215C1.03383 9.15641 1.28885 10.0766 1.74442 10.9012C2.19998 11.7257 2.84329 12.4314 3.62227 12.9611C4.40125 13.4908 5.294 13.8296 6.22827 13.9502C7.16254 14.0707 8.11207 13.9696 9 13.655C10.1811 13.2613 11.2059 12.5012 11.9252 11.485C12.6446 10.4689 13.0211 9.24981 13 8.005V0.00500011H11V8.215ZM11 0H13H11ZM3 0H1H3Z" fill="black"/></svg>';
  quillIcons['strike'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none"><path d="M0 8.20281H18V9.70281H13.6342C13.8711 10.2 13.9906 10.745 13.9836 11.2957C13.9977 11.8182 13.8855 12.3364 13.6567 12.8063C13.428 13.2763 13.0893 13.6842 12.6694 13.9954C11.6579 14.7071 10.4378 15.0606 9.20251 14.9999C8.29996 15.006 7.4062 14.8223 6.57916 14.4609C5.82387 14.1475 5.16917 13.6328 4.68652 12.9727C4.24212 12.3425 4.00762 11.5883 4.01636 10.8172V10.7039H4.30359V10.7028H4.64508V10.7039H6.02V10.8172C6.00433 11.1686 6.07382 11.5186 6.22253 11.8373C6.37125 12.1561 6.59479 12.4341 6.87413 12.6479C7.55154 13.1302 8.372 13.3696 9.20249 13.3272C9.93562 13.3751 10.6645 13.1834 11.2792 12.781C11.507 12.6125 11.6901 12.3907 11.8124 12.135C11.9346 11.8793 11.9923 11.5976 11.9804 11.3144C11.9964 11.0408 11.9459 10.7675 11.8332 10.5177C11.7205 10.2679 11.5491 10.0491 11.3335 9.88002C11.2451 9.81599 11.1533 9.75681 11.0585 9.70277H0V8.20281ZM13.3447 3.05968C12.9232 2.40469 12.3271 1.88046 11.6236 1.54607C10.8394 1.17248 9.97928 0.985528 9.11072 0.999866C7.8996 0.947888 6.70883 1.32391 5.74719 2.062C5.33435 2.38084 5.00134 2.79135 4.77448 3.26107C4.54762 3.73078 4.43313 4.24683 4.44006 4.76842C4.43656 5.26271 4.54659 5.75121 4.76166 6.19628H7.35834C7.27496 6.14263 7.17297 6.09051 7.10565 6.03564C6.90219 5.88555 6.73702 5.68956 6.62358 5.4636C6.51014 5.23765 6.45164 4.98811 6.45282 4.73528C6.43773 4.44667 6.49242 4.15867 6.61226 3.89568C6.73209 3.63269 6.91354 3.40246 7.14124 3.22448C7.71733 2.82494 8.41088 2.6304 9.11072 2.67205C9.87165 2.62367 10.6231 2.86226 11.2167 3.34075C11.465 3.57454 11.6594 3.85955 11.7865 4.176C11.9136 4.49245 11.9703 4.83276 11.9527 5.17333V5.28674H13.9559V5.17333C13.9592 4.42472 13.7471 3.69097 13.3447 3.05968Z" fill="black"/></svg>';
  quillIcons['list']['ordered'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 32 24" fill="none"><path d="M2.5 16H4.5V16.5H3.5V17.5H4.5V18H2.5V19H5.5V15H2.5V16ZM3.5 9H4.5V5H2.5V6H3.5V9ZM2.5 11H4.3L2.5 13.1V14H5.5V13H3.7L5.5 10.9V10H2.5V11ZM7.5 6V8H21.5V6H7.5ZM7.5 18H21.5V16H7.5V18ZM7.5 13H21.5V11H7.5V13Z" fill="black"/><path d="M28 14.5L32 9.5H24L28 14.5Z" fill="black"/></svg>';
  quillIcons['list']['bullet'] =
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 32 24" fill="none"><path d="M4 10.5C3.2 10.5 2.5 11.2 2.5 12C2.5 12.8 3.2 13.5 4 13.5C4.8 13.5 5.5 12.8 5.5 12C5.5 11.2 4.8 10.5 4 10.5ZM4 5.5C3.2 5.5 2.5 6.2 2.5 7C2.5 7.8 3.2 8.5 4 8.5C4.8 8.5 5.5 7.8 5.5 7C5.5 6.2 4.8 5.5 4 5.5ZM4 15.5C3.2 15.5 2.5 16.2 2.5 17C2.5 17.8 3.2 18.5 4 18.5C4.8 18.5 5.5 17.8 5.5 17C5.5 16.2 4.8 15.5 4 15.5ZM7.5 6V8H21.5V6H7.5ZM7.5 18H21.5V16H7.5V18ZM7.5 13H21.5V11H7.5V13Z" fill="black"/><path d="M28 14.5L32 9.5H24L28 14.5Z" fill="black"/></svg>';
}

export function prepareQuillLinks() {
  const Link = Quill.import('formats/link');
  Link.sanitize = function (value) {
    // allow attr href with data:base64
    return value;
  };
}

export function setQuillCustomElementsEvents(
  descriptionSelector: string,
  elementRef: ElementRef,
  benchmarksList: ActivityBenchmark[],
  assetsList: ActivityAsset[],
  renderer: Renderer2,
  dialogService: TuiDialogService,
  sub: Subscription,
  injector: Injector,
):
  | {
      links: HTMLElement[];
      images: HTMLElement[];
      files: HTMLElement[];
      benchmarks: HTMLElement[];
      assets: HTMLElement[];
    }
  | undefined {
  const description = elementRef.nativeElement.querySelector(descriptionSelector);
  if (!description) {
    return;
  }

  // Benchmarks - Links
  const links = description.querySelectorAll(`.${EQuillCustomBlot.CUSTOM_EMBED}[data-class=${EQuillElemClass.LINK}]`);
  setLinksEvents(links, renderer);

  // Benchmarks - Files: images
  const images = description.querySelectorAll(
    `.${EQuillCustomBlot.CUSTOM_EMBED}[data-class=${EQuillElemClass.IMG}],.${EQuillCustomBlot.CUSTOM_EMBED}[data-class=${EQuillElemClass.VIDEO}]`,
  );
  setImagesEvents(images, benchmarksList, renderer, dialogService, sub, injector);

  // Benchmarks - Files: files for download
  const files = description.querySelectorAll(`.${EQuillCustomBlot.CUSTOM_EMBED}[data-class=${EQuillElemClass.FILE}]`);
  setFilesEvents(files, benchmarksList, renderer);

  // mentions @ benchmarks - links or files
  const mentionedBenchmarks = description.querySelectorAll(
    `.mention[data-denotation-char="${EQuillDenotationChar.BENCHMARKS}"]`,
  );
  setMentionedBenchmarksEvents(mentionedBenchmarks, benchmarksList, renderer, dialogService, sub, injector);

  // mentions # assets
  const mentionedAssets = description.querySelectorAll(
    `.mention[data-denotation-char="${EQuillDenotationChar.ASSETS}"]`,
  );
  setMentionedAssetsEvents(mentionedAssets, assetsList, renderer, dialogService, sub, injector);

  return {
    links,
    images,
    files,
    benchmarks: mentionedBenchmarks,
    assets: mentionedAssets,
  };
}

function setLinksEvents(links: HTMLElement[], renderer: Renderer2) {
  links.forEach((link) => {
    renderer.listen(link, 'click', (event) => {
      event.preventDefault();
      const href = link.getAttribute('data-href');
      href ? window.open(href, '_blank') : console.error('No data-href attribute');
    });
  });
}

export function setImagesEvents(
  images: HTMLElement[],
  benchmarksList: ActivityBenchmark[],
  renderer: Renderer2,
  dialogService: TuiDialogService,
  sub: Subscription,
  injector: Injector,
) {
  images.forEach((m) => {
    renderer.listen(m, 'click', (event) => {
      event.preventDefault();
      const uuid = m.getAttribute('data-id');
      if (uuid) {
        const benchmark = benchmarksList.find((b) => b.uuid === uuid.toString());
        benchmark
          ? openBenchmarkModal(benchmark, dialogService, sub, injector, m.getAttribute('data-class') || '')
          : console.error('No benchmark with uuid', uuid);
      }
    });
  });
}

function setFilesEvents(files: HTMLElement[], benchmarksList: ActivityBenchmark[], renderer: Renderer2) {
  files.forEach((f) => {
    renderer.listen(f, 'click', (event) => {
      event.preventDefault();
      const uuid = f.getAttribute('data-id');
      if (uuid) {
        const benchmark = benchmarksList.find((b) => b.uuid === uuid.toString());
        benchmark ? downloadAttachment(benchmark) : console.error('No benchmark with uuid', uuid);
      }
    });
  });
}

function setMentionedBenchmarksEvents(
  mentionedBenchmarks: HTMLElement[],
  benchmarksList: ActivityBenchmark[],
  renderer: Renderer2,
  dialogService: TuiDialogService,
  sub: Subscription,
  injector: Injector,
) {
  mentionedBenchmarks.forEach((f) => {
    renderer.listen(f, 'click', (event) => {
      event.preventDefault();
      const uuid = f.getAttribute('data-id');
      if (uuid) {
        const benchmark = benchmarksList.find((b) => b.uuid === uuid.toString());
        benchmark
          ? openBenchmarkModal(benchmark, dialogService, sub, injector)
          : console.error('No benchmark with uuid', uuid);
      }
    });
  });
}

function setMentionedAssetsEvents(
  assets: HTMLElement[],
  assetsList: ActivityAsset[],
  renderer: Renderer2,
  dialogService: TuiDialogService,
  sub: Subscription,
  injector: Injector,
) {
  assets.forEach((f) => {
    renderer.listen(f, 'click', (event) => {
      event.preventDefault();
      const uuid = f.getAttribute('data-id');
      if (uuid) {
        const asset = assetsList.find((a) => a.uuid === uuid.toString());
        asset ? openAssetPreview(asset, dialogService, sub, injector) : console.error('No asset with uuid', uuid);
      }
    });
  });
}

function openBenchmarkModal(
  benchmark: ActivityBenchmark,
  dialogService: TuiDialogService,
  sub: Subscription,
  injector: Injector,
  elementClass?: string,
): void {
  if (
    benchmark.type.type === BenchmarkTypeEnum.File ||
    (BenchmarkTypeEnum.Link && elementClass === EQuillElemClass.VIDEO)
  ) {
    if (
      Regex.fileImage.test(benchmark.attachment?.file!) ||
      Regex.fileVideo.test(benchmark.attachment?.file!) ||
      Regex.fileImage.test(benchmark.file?.name!) ||
      Regex.fileVideo.test(benchmark.file?.name!) ||
      benchmark.link
    ) {
      // display media in modal
      const subTmp = dialogService
        .open<number>(new PolymorpheusComponent(ActivityBenchmarkPreviewModalComponent, injector), {
          data: {
            benchmark: benchmark,
          },
          dismissible: false,
          closeable: false,
          size: 'm',
        })
        .subscribe();
      sub.add(subTmp);
    } else {
      // download file
      downloadAttachment(benchmark);
    }
  } else {
    window.open(benchmark.link, '_blank');
  }
}

function openAssetPreview(
  asset: ActivityAsset,
  dialogService: TuiDialogService,
  sub: Subscription,
  injector: Injector,
): void {
  dialogService
    .open<number>(new PolymorpheusComponent(ActivityAssetPreviewModalComponent, injector), {
      data: {
        asset: asset,
      },
      dismissible: true,
    })
    .subscribe();
}

function downloadAttachment(benchmark: ActivityBenchmark): void {
  benchmark.attachment
    ? FileSaver.saveAs(Config.ASSETS + benchmark.attachment!.file, benchmark.attachment!.name)
    : FileSaver.saveAs(benchmark.file!, benchmark.file?.name);
}

/**
 * Temporarly hide elements and mark them with class "remove"
 * @param editorElem
 * @param elemUUID
 * @returns
 */
export function removeCustomElementById(editorElem: Element | undefined, elemUUID: string | undefined) {
  if (!editorElem) {
    return;
  }
  const elemsToRemove: NodeListOf<HTMLElement> = editorElem.querySelectorAll(`[data-id="${elemUUID}"]`);

  elemsToRemove.forEach((elem) => {
    elem.classList.add('remove');
    elem.style.display = 'none';
  });
}

/**
 * Show an element that has been hidden
 * @param editorElem
 * @param elemUUID
 * @returns
 */
export function restoreCustomElementById(editorElem: Element | undefined, elemUUID: string | undefined) {
  if (!editorElem) {
    return;
  }
  const elemsToRemove: NodeListOf<HTMLElement> = editorElem.querySelectorAll(`[data-id="${elemUUID}"]`);

  elemsToRemove.forEach((elem) => {
    elem.classList.remove('remove');
    elem.style.display = 'inline';
  });
}

export function extractVideoUrl(url) {
  let match =
    url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
    url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
  if (match) {
    return (match[1] || 'https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
  }
  if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
    // eslint-disable-line no-cond-assign
    return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
  }
  return url;
}
