<div
  class="input-header-container"
  data-test="input-header-container"
>
  <span
    *ngIf="configuration?.metadata?.tooltip"
    [tuiHint]="tooltip"
    class="input-label-wrapper"
  >
    <span [class]="'input-label ' + configuration.property.toLowerCase()">{{ configuration.label }}</span>
    <svg-icon
      [src]="'/assets/img/ic_question_circle.svg'"
      class="ml-1"
    ></svg-icon>
    <span
      class="tui-accent-01 ml-1"
      *ngIf="configuration.required"
      >*</span
    >
  </span>
  <span
    *ngIf="!configuration?.metadata?.tooltip"
    class="input-label-wrapper"
  >
    <span [class]="'input-label ' + configuration.property.toLowerCase()">{{ configuration.label }}</span>
    <span
      class="tui-accent-01 ml-1"
      *ngIf="configuration.required"
      >*</span
    >
  </span>
  <div
    class="min-max-text"
    [class.tui-accent-01]="hasError(configuration.id)"
    *ngIf="
      getMinMaxLength(configuration.validationPatterns).max > 0 && configuration.type !== InputTypeEnum.InputNumber
    "
  >
    {{
      (getFormControl(configuration.id).value?.length || 0) +
        '/' +
        getMinMaxLength(configuration.validationPatterns).max
    }}
  </div>

  <div
    class="min-max-text"
    [class.tui-accent-01]="hasError(configuration.id)"
    *ngIf="configuration.type === InputTypeEnum.Bar && configuration.metadata.type !== 'select'"
  >
    {{
      (getFormControl(configuration.id).value || configuration.metadata.defaultValue) + '/' + configuration.metadata.max
    }}
  </div>
</div>
<ng-container
  [ngTemplateOutlet]="fieldTemplate"
  [ngTemplateOutletContext]="{ configuration: configuration, form: form }"
>
</ng-container>

<ng-template #tooltip>
  <p>{{ configuration?.metadata?.tooltip }}</p>
</ng-template>

<ng-template
  #fieldLabel
  let-config
>
  <span class="tui-text-05">{{ config.placeholder }}</span>
</ng-template>

<ng-template
  let-form="form"
  let-configuration="configuration"
  #fieldTemplate
>
  <ng-container
    [ngSwitch]="configuration.type"
    [formGroup]="form"
  >
    <ng-container *ngSwitchCase="InputTypeEnum.InputText">
      <tui-input
        data-test="tui-input"
        [tuiTextfieldLabelOutside]="true"
        [formControlName]="configuration.id"
        [attr.data-error-control]="configuration.id"
        [required]="configuration.required"
        [tuiHintContent]="configuration.metadata ? configuration.metadata['info'] : ''"
      >
        <ng-container
          [ngTemplateOutlet]="fieldLabel"
          [ngTemplateOutletContext]="{ $implicit: configuration }"
        >
        </ng-container>
      </tui-input>

      <div class="min-max-text mt-px flex justify-between">
        <!-- errors -->
        <df-error-input [control]="getFormControl(configuration.id)"></df-error-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="InputTypeEnum.InputNumber">
      <tui-input-number
        [tuiTextfieldLabelOutside]="true"
        [formControlName]="configuration.id"
        [attr.data-error-control]="configuration.id"
        [required]="configuration.required"
        [tuiHintContent]="configuration.metadata ? configuration.metadata['info'] : ''"
      >
        <ng-container
          [ngTemplateOutlet]="fieldLabel"
          [ngTemplateOutletContext]="{ $implicit: configuration }"
        >
        </ng-container>
      </tui-input-number>

      <div class="min-max-text mt-px flex justify-between">
        <!-- errors -->
        <df-error-input [control]="getFormControl(configuration.id)"></df-error-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="InputTypeEnum.Textarea">
      <tui-text-area
        [required]="configuration.required"
        [tuiTextfieldLabelOutside]="true"
        [tuiHintContent]="configuration.metadata ? configuration.metadata['info'] : ''"
        [formControlName]="configuration.id"
        [attr.data-error-control]="configuration.id"
        [expandable]="true"
      >
        <ng-container
          [ngTemplateOutlet]="fieldLabel"
          [ngTemplateOutletContext]="{ $implicit: configuration }"
        >
        </ng-container>
      </tui-text-area>
      <df-error-input [control]="getFormControl(configuration.id)"></df-error-input>
    </ng-container>

    <ng-container *ngSwitchCase="InputTypeEnum.Select">
      <tui-select
        [tuiTextfieldLabelOutside]="true"
        [valueContent]="stringify"
        [formControlName]="configuration.id"
        [attr.data-error-control]="configuration.id"
        [tuiHintContent]="configuration.metadata ? configuration.metadata['info'] : ''"
      >
        <ng-container
          [ngTemplateOutlet]="fieldLabel"
          [ngTemplateOutletContext]="{ $implicit: configuration }"
        >
        </ng-container>
        <tui-data-list *tuiDataList>
          <button
            tuiOption
            *ngFor="let item of configuration.metadata!['options']"
            [value]="item"
          >
            {{ item.label }}
          </button>
        </tui-data-list>
      </tui-select>
      <df-error-input
        *ngIf="getFormControl(configuration.id).enabled"
        [control]="getFormControl(configuration.id)"
      ></df-error-input>
    </ng-container>

    <ng-container *ngSwitchCase="InputTypeEnum.StringArray">
      <div
        class="relative mb-4 duration-200"
        [class.pr-8]="getFormArrayControls(configuration.id).length > 1"
        *ngFor="let control of getFormArrayControls(configuration.id); let i = index"
      >
        <tui-input
          [tuiTextfieldLabelOutside]="true"
          class="duration-200"
          [formControl]="control"
          [required]="configuration.required"
          [tuiHintContent]="configuration.metadata ? configuration.metadata['info'] : ''"
        >
          <ng-container
            [ngTemplateOutlet]="fieldLabel"
            [ngTemplateOutletContext]="{ $implicit: configuration }"
          >
          </ng-container>
        </tui-input>
        <div class="absolute right-0 top-0 bottom-0 flex flex-col justify-center items-end">
          <button
            *ngIf="getFormArrayControls(configuration.id).length > 1"
            tuiIconButton
            type="button"
            appearance="flat"
            size="xs"
            (click)="removeControl(configuration.id, i)"
          >
            <svg-icon
              class="tui-text-04"
              src="assets/img/_ic_trash.svg"
              [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
            ></svg-icon>
          </button>
        </div>
      </div>
      <button
        tuiButton
        type="button"
        appearance="outline"
        size="m"
        (click)="addControl(configuration.id)"
        class="mb-6 w-full"
      >
        <svg-icon
          [src]="'/assets/img/ic-add.svg'"
          [svgStyle]="{ width: '1rem', height: '1rem' }"
        ></svg-icon>
      </button>
    </ng-container>

    <ng-container
      *ngSwitchCase="InputTypeEnum.InputFile"
      class="graphic-input"
    >
      <df-input-file
        [form]="form"
        [tuiTextfieldLabelOutside]="true"
        #fileInput
        (ngModelChange)="fileAddedEvent.emit(true)"
        (removed)="fileRemovedEvent.emit(true)"
        [maxFileSize]="10000000"
        [accept]="getAcceptedImagesExtensions(configuration)"
        [formName]="configuration.id"
        [attr.data-error-control]="configuration.id"
        [multiple]="false"
        [link]="'choose an image' | translate"
        label=" "
        size="l"
        class="flex-grow file-input"
        [class.file-input__error]="
          (getFormControl(configuration.id).invalid || !fileInput.fileList.length) &&
          getFormControl(configuration.id).touched
        "
      >
      </df-input-file>
      <df-error-input
        *ngIf="
          (getFormControl(configuration.id).touched || getFormControl(configuration.id).dirty) &&
          !!getFormControl(configuration.id).errors
        "
        [control]="getFormControl(configuration.id)"
        [manualShowingError]="getFormControl(configuration.id).invalid && getFormControl(configuration.id).touched"
      >
      </df-error-input>
      <div class="mb-3"></div>
    </ng-container>

    <ng-container
      *ngSwitchCase="InputTypeEnum.InputImageBase64"
      class="graphic-input"
    >
      <df-input-file
        [form]="form"
        [tuiTextfieldLabelOutside]="true"
        #fileInput
        (ngModelChange)="fileAddedEvent.emit(true)"
        (removed)="fileRemovedEvent.emit(true)"
        [maxFileSize]="10000000"
        [accept]="getAcceptedImagesExtensions(configuration)"
        [formName]="configuration.id"
        [attr.data-error-control]="configuration.id"
        [multiple]="false"
        [link]="'choose an image' | translate"
        label=" "
        size="l"
        class="flex-grow file-input"
        [class.file-input__error]="
          (getFormControl(configuration.id).invalid || !fileInput.fileList.length) &&
          getFormControl(configuration.id).touched
        "
      >
      </df-input-file>
      <df-error-input
        *ngIf="
          (getFormControl(configuration.id).touched || getFormControl(configuration.id).dirty) &&
          !!getFormControl(configuration.id).errors
        "
        [control]="getFormControl(configuration.id)"
        [manualShowingError]="getFormControl(configuration.id).invalid && getFormControl(configuration.id).touched"
      >
      </df-error-input>
      <div class="mb-3"></div>
    </ng-container>

    <!-- Select Ad size -->
    <ng-container *ngSwitchCase="InputTypeEnum.CustomImageFormatMultiselect">
      <tui-island
        class="form-array"
        [ngClass]="{ 'form-array--more': !sizesDisplayLimitActive }"
      >
        <ng-container
          *ngFor="let item of configuration.metadata!['options']; let i = index; let first = first; let last = last"
        >
          <div
            data-test="ad-size-option"
            *ngIf="i + 1 <= sizesDisplayAmount"
            class="form-array__item w-full flex justify-between items-center"
            [ngClass]="{
              'border-bottom': !(last && configuration.metadata!['options'].length === sizesDisplayLimit)
            }"
            [formArrayName]="configuration.id.toString()"
          >
            <div class="image w-fit h-full flex items-start">
              <svg-icon [src]="item.photo"></svg-icon>
            </div>
            <div class="data ml-4 pr-4">
              <!-- tags -->
              <div class="data__size tui-base-02 tui-text-02">
                {{ item.size }}
              </div>
              <!-- title -->
              <div class="data__title tui-text_body-m-6 tui-text-03">
                {{ item.label }}
              </div>
              <!-- description -->
              <div class="data__description tui-text_body-m-10 tui-text-02">
                <p>{{ item.description }}</p>
              </div>
            </div>
            <div class="input h-full flex items-start">
              <tui-checkbox
                [formControlName]="i"
                [attr.data-error-control]="i"
                [tuiHintContent]="configuration.metadata ? configuration.metadata['info'] : ''"
                size="l"
              >
              </tui-checkbox>
            </div>
          </div>
        </ng-container>
        <!-- viewMore button -->
        <button
          *ngIf="configuration.metadata!['options'].length > sizesDisplayLimit"
          class="w-full tui-text-body-s-2 tui-primary my-1.5"
          (click)="viewMore($event)"
        >
          {{ 'View more' | translate }}
          <ng-container [ngSwitch]="sizesDisplayLimitActive">
            <tui-svg
              *ngSwitchCase="true"
              class="tui-space_left-3"
              src="tuiIconChevronDown"
            ></tui-svg>
            <tui-svg
              *ngSwitchDefault
              class="tui-space_left-3"
              src="tuiIconChevronUp"
            ></tui-svg>
          </ng-container>
        </button>
      </tui-island>
      <!-- errors -->
      <df-error-input
        class="form-array__error"
        *ngIf="
          (getFormControl(configuration.id).dirty || getFormControl(configuration.id).touched) &&
          getFormControl(configuration.id).invalid
        "
        [control]="getFormControl(configuration.id)"
      >
      </df-error-input>
      <div class="mb-3"></div>
    </ng-container>

    <!-- Select graphic style -->
    <ng-container *ngSwitchCase="InputTypeEnum.CustomImageTemplateSelect">
      <tui-island
        class="select-style-island flex items-center"
        (mouseenter)="showStyleButton = true"
        (mouseleave)="showStyleButton = false"
      >
        <!-- input hidden -->
        <div
          class="w-full flex"
          [tuiHintContent]="configuration.metadata ? configuration.metadata['info'] : ''"
        >
          <input
            type="hidden"
            [formControlName]="configuration.id"
            [attr.data-error-control]="configuration.id"
            [required]="configuration.required"
          />
          <!-- img -->
          <ng-container *ngIf="getFormControl(configuration.id).value; else noImageSelected">
            <img
              _ngcontent-dgq-c315=""
              [src]="styleSearchOpt?.photo || ''"
              class="h-16 w-16 rounded-2xl object-contain"
              [alt]="styleSearchOpt?.label"
            />
          </ng-container>
          <ng-template #noImageSelected>
            <img
              src="assets/img/graphic-empty.png"
              alt="empty graphic style"
            />
          </ng-template>
          <!-- texts -->
          <div class="flex flex-col justify-center ml-3">
            <p class="tui-text_body-m-10 tui-text-05">
              <!-- TODO some field from API -->
              {{ 'Template' }}
            </p>
            <p class="tui-text_body-m-9 tui-text-03 mt-px">
              <!-- set styleSearchOpt data -->
              {{
                getStyleData(getFormControl(configuration.id).value, configuration.metadata)?.label ||
                  configuration.description
              }}
            </p>
          </div>
        </div>
        <!-- button -->
        <button
          *ngIf="showStyleButton"
          class="rounded-lg"
          tuiButton
          appearance="secondary"
          size="s"
          (click)="openStylesModal($event, configuration.id, configuration?.metadata)"
        >
          <ng-container *ngIf="getFormControl(configuration.id).value; else notSelected">
            <p class="tui-text_body-m-11">
              {{ 'Funnels.Graphics.Change' | translate }}
            </p>
          </ng-container>
          <ng-template #notSelected>
            <p class="tui-text_body-m-11">
              {{ 'Funnels.Graphics.Select' | translate }}
            </p>
          </ng-template>
        </button>
      </tui-island>
      <!-- errors -->
      <df-error-input [control]="getFormControl(configuration.id)"></df-error-input>
    </ng-container>

    <!-- Unsplash input -->
    <ng-container *ngSwitchCase="InputTypeEnum.InputFileFromUnsplash">
      <div class="unsplash-field w-full">
        <!-- input file -->
        <df-input-file
          [form]="form"
          [tuiTextfieldLabelOutside]="true"
          (click)="activateClickForOpenUnsplashModal($event, configuration.id)"
          #fileInput
          [maxFileSize]="10000000"
          [accept]="getAcceptedImagesExtensions(configuration)"
          [formName]="configuration.id"
          [attr.data-error-control]="configuration.id"
          [multiple]="false"
          [link]="configuration.description || 'Funnels.Graphics.Choose from unsplash' | translate"
          label=" "
          size="l"
          class="flex-grow file-input"
          [class.file-input__error]="
            (getFormControl(configuration.id).invalid || !fileInput.fileList.length) &&
            getFormControl(configuration.id).touched
          "
        >
        </df-input-file>

        <!-- errors -->
        <df-error-input
          *ngIf="this.confBackground && !!getFormControl(this.confBackground!.id)?.value"
          [control]="getFormControl(configuration.id)"
        ></df-error-input>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="InputTypeEnum.Bar">
      <div>
        <div
          *ngIf="configuration?.metadata?.type === 'percentage'"
          class="pt-3"
        >
          <input
            class="w-full rounded-lg appearance-none cursor-pointer"
            type="range"
            max="100"
            min="1"
            step="1"
            size="s"
            [formControlName]="configuration.id"
            [attr.data-error-control]="configuration.id"
            [required]="configuration.required"
            [attr.list]="'markers-' + configuration.id"
          />

          <datalist
            [id]="'markers-' + configuration.id"
            class="mt-[6px]"
          >
            <option
              value="1"
              label="1"
              style="transform: translateX(6px) rotate(-90deg)"
            ></option>
            <option
              value="50"
              label="50"
              style="transform: translateX(9px) rotate(-90deg)"
            ></option>
            <option
              value="100"
              label="100"
              style="transform: translateX(6px) rotate(-90deg)"
            ></option>
          </datalist>
        </div>

        <div
          *ngIf="configuration?.metadata?.type === 'select'"
          class="pt-3"
        >
          <input
            class="w-full rounded-lg appearance-none cursor-pointer"
            type="range"
            [max]="configuration?.metadata?.values.length"
            min="1"
            step="1"
            size="s"
            [formControlName]="configuration.id"
            [attr.data-error-control]="configuration.id"
            [required]="configuration.required"
            [attr.list]="'markers-' + configuration.id"
          />

          <datalist
            [id]="'markers-' + configuration.id"
            class="markers-select mt-[6px]"
          >
            <option
              *ngFor="let item of configuration?.metadata?.values; let i = index"
              [value]="i + 1"
              [label]="item"
            ></option>
          </datalist>
        </div>

        <div
          *ngIf="configuration?.metadata?.type === 'int'"
          class="pt-3"
        >
          <input
            *ngIf="configuration?.metadata?.type === 'int'"
            class="w-full rounded-lg appearance-none cursor-pointer"
            type="range"
            [max]="configuration?.metadata?.max"
            [min]="configuration?.metadata?.min"
            step="1"
            size="s"
            [formControlName]="configuration.id"
            [attr.data-error-control]="configuration.id"
            [required]="configuration.required"
            [attr.list]="'markers-' + configuration.id"
          />

          <datalist
            [id]="'markers-' + configuration.id"
            class="mt-[6px]"
          >
            <option
              [value]="configuration?.metadata?.min"
              [label]="configuration?.metadata?.min"
              style="transform: translateX(2px) rotate(-90deg)"
            ></option>
            <option
              [value]="configuration?.metadata?.max"
              [label]="configuration?.metadata?.max"
              style="transform: translateX(16px) rotate(-90deg)"
            ></option>
          </datalist>
        </div>
        <df-error-input
          *ngIf="this.confBackground && !!getFormControl(this.confBackground!.id)?.value"
          [control]="getFormControl(configuration.id)"
        ></df-error-input>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
