<tui-accordion-item
  *ngIf="funnelTactic"
  class="activities"
  [showArrow]="false"
  [borders]="null"
  [noPadding]="true"
  (openChange)="onDropdownChange()"
  [disabled]="funnelTactic.tactic.activities.length <= 0"
  [open]="expandAll"
  #accordion
>
  <ng-container *ngIf="funnelTactic.isDone; else activities">
    <div class="tui-accent-07 uppercase flex justify-between items-center tui-text_body-xs font-bold">
      <svg-icon
        [src]="'/assets/img/ic_check_circle.svg'"
        class="mr-1"
        [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      ></svg-icon>
      <span
        [innerHTML]="'working' | translate"
        class="flex-grow"
      ></span>
      <svg-icon
        *ngIf="funnelTactic.tactic.activities.length > 0"
        [ngClass]="{ 'rotate-180': accordion.open }"
        class="all-done-arrow"
        [src]="'/assets/img/ic_arrow.svg'"
      ></svg-icon>
    </div>
  </ng-container>
  <ng-template #activities>
    <p
      class="flex flex-row justify-start items-center uppercase tui-text-04 tui-text_body-xs font-bold duration-200"
      [ngClass]="{
        'hover:text-blue-700': funnelTactic.tactic.activities.length > 0
      }"
    >
      <span
        [innerHTML]="
          accordion.open
            ? ('Funnels.Manage.Hide activities' | translate)
            : ('Funnels.Manage.:doneCount :count activities done'
              | translate
                : {
                    doneCount: doneActivities,
                    count: funnelTactic.tactic.activities.length
                  })
        "
      ></span>
      <svg-icon
        *ngIf="funnelTactic.tactic.activities.length > 0"
        class="ml-1"
        [src]="'assets/img/ic_arrow_full.svg'"
        [ngClass]="{ 'rotate-180': accordion.open }"
      ></svg-icon>
    </p>
  </ng-template>
  <ng-template tuiAccordionItemContent>
    <div>
      <ng-container *ngFor="let activity of funnelTactic.tactic.activities">
        <div
          class="mt-3 tui-text_body-xs cursor-pointer flex"
          (click)="toggleDone(activity)"
          *ngIf="!previewMode"
        >
          <tui-primitive-checkbox
            class="activity-checkbox mr-2"
            [ngClass]="{ checked: !!activity.done }"
            [value]="!!activity.done"
          ></tui-primitive-checkbox>
          <span>{{ activity.name }}</span>
        </div>
        <div
          class="mt-3 tui-text_body-xs flex"
          *ngIf="previewMode"
        >
          <svg-icon
            [src]="'/assets/img/ic-check.svg'"
            [svgStyle]="{ width: '1rem', height: '1rem' }"
            class="mr-2 tui-text-04"
            [ngClass]="{ 'tui-accent-07': !!activity.done }"
          ></svg-icon>
          <span>{{ activity.name }}</span>
        </div>
      </ng-container>
    </div>
  </ng-template>
</tui-accordion-item>
