import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { Tactic } from '@shared/models/tactic.model';

@Component({
  selector: 'df-tactic-single-draft-modal',
  templateUrl: './tactic-single-draft-modal.component.html',
  styleUrls: ['./tactic-single-draft-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticSingleDraftModalComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<TacticSingleDraftModalComponent, boolean>,
  ) {}

  get data(): Tactic {
    return this.context.data;
  }

  override(): void {
    this.context.completeWith(true);
  }

  edit(): void {
    this.context.completeWith(false);
  }
}
