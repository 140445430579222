<div class="absolute top-4 right-4">
  <button
    class="black-80-bg h-9 w-9 rounded-full"
    type="button"
    (click)="close()"
    [disabled]="loading"
  >
    <tui-svg
      src="tuiIconPlus"
      class="tuiIconPlus text-white rotate-45"
    ></tui-svg>
  </button>
</div>

<div class="metrics">
  <!-- heading -->
  <h1 class="metrics__heading tui-text_h5 tui-text-03 mt-9 mb-2">
    {{ 'Tactics.Settings.Metrics.New metric' | translate }}
  </h1>
  <!-- description -->
  <p
    class="tui-text-body-m-2 tui-text-03 mb-2 font-normal"
    [innerHtml]="'Tactics.Settings.Metrics.Select the metric and define how to use it.' | translate"
  ></p>

  <!-- FORM -->
  <form
    [formGroup]="form"
    [validate-form]="form"
    class="form"
  >
    <!-- metric -->
    <tui-select
      class="w-full"
      [tuiTextfieldSize]="'l'"
      [formControlName]="ETacticMetricForm.metric"
      [attr.data-error-control]="ETacticMetricForm.metric"
      [valueContent]="typeContent"
      [tuiTextfieldLabelOutside]="true"
    >
      <tui-data-list-wrapper
        *tuiDataList
        [items]="metrics"
        [itemContent]="typeContent"
      >
      </tui-data-list-wrapper>
    </tui-select>
    <ng-template
      #typeContent
      let-item
    >
      {{ item.name }}
    </ng-template>
    <df-error-input [control]="form.get(ETacticMetricForm.metric)"></df-error-input>

    <!-- period -->
    <div class="period">
      <h2 class="tui-text_body-m-2 font-normal tui-text-03 mb-2">
        {{ 'Tactics.Settings.Metrics.Period for Metric Measurement' | translate }}
      </h2>

      <tui-filter
        tuiFilterMaxItems
        [maxItems]="1"
        #tuiTypes
        [tuiFilter]="tuiTypes"
        [formControlName]="ETacticMetricForm.period"
        [attr.data-error-control]="ETacticMetricForm.period"
        size="m"
        [content]="periodContent"
        [identityMatcher]="identityMatcher"
        [items]="periods"
      ></tui-filter>
      <ng-template
        let-data
        #periodContent
      >
        <div class="flex items-center">
          <span class="font-medium">{{ data.name }}</span>
        </div>
      </ng-template>
      <df-error-input [control]="getControl(ETacticMetricForm.period)"></df-error-input>
    </div>

    <tui-island class="cycle-group">
      <!-- cycle -->
      <h2 class="tui-text_body-m-2 font-normal tui-text-03 mb-2">
        {{ 'Tactics.Settings.Metrics.Cycle measurement' | translate }}?
      </h2>

      <tui-radio-labeled
        [formControlName]="ETacticMetricForm.cycle"
        class="mb-2 mr-3"
        size="l"
        [item]="true"
      >
        <span class="tui-text-body-s-2">{{ 'Yes' | translate }}</span>
      </tui-radio-labeled>

      <tui-radio-labeled
        [formControlName]="ETacticMetricForm.cycle"
        class="mb-2 mr-3"
        size="l"
        [item]="false"
      >
        <span class="tui-text-body-s-2">{{ 'No' | translate }}</span>
      </tui-radio-labeled>
      <df-error-input
        *ngIf="
          form.get(ETacticMetricForm.cycle)?.invalid &&
          (form.get(ETacticMetricForm.cycle)?.dirty || form.get(ETacticMetricForm.cycle)?.touched)
        "
        [control]="form.get(ETacticMetricForm.cycle)"
      ></df-error-input>

      <!-- cycleMeasurement -->
      <div *ngIf="!!getControl(ETacticMetricForm.cycle).value">
        <tui-select
          class="w-[18.75rem]"
          [tuiTextfieldSize]="'l'"
          [formControlName]="ETacticMetricForm.cycleMeasurement"
          [attr.data-error-control]="ETacticMetricForm.cycleMeasurement"
          [valueContent]="typeContent"
          [tuiTextfieldLabelOutside]="true"
        >
          <tui-data-list-wrapper
            *tuiDataList
            [items]="periods"
            [itemContent]="typeContent"
          >
          </tui-data-list-wrapper>
        </tui-select>
        <ng-template
          #typeContent
          let-item
        >
          {{ item.name }}
        </ng-template>
        <df-error-input
          *ngIf="
            form.get(ETacticMetricForm.cycleMeasurement)?.invalid &&
            (form.get(ETacticMetricForm.cycleMeasurement)?.dirty ||
              form.get(ETacticMetricForm.cycleMeasurement)?.touched)
          "
          [control]="form.get(ETacticMetricForm.cycleMeasurement)"
        ></df-error-input>

        <!-- form cross fileds errors -->
        <div
          class="tui-accent-01 tui-text-body-xs pt-[0.3rem]"
          *ngIf="form.errors?.cycleMeasurementEmpty"
        >
          {{ 'Validation.Wrong value' | translate }}
        </div>
      </div>
    </tui-island>
  </form>

  <!-- buttons -->
  <div class="flex justify-end mt-[2.375rem]">
    <button
      size="l"
      tuiButton
      appearance="outline"
      type="button"
      (click)="close()"
      [disabled]="loading"
      class="mr-2"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      size="l"
      tuiButton
      appearance="primary"
      type="submit"
      (click)="send()"
      [disabled]="loading"
      [showLoader]="loading"
    >
      {{ 'Save' | translate }}
    </button>
  </div>
</div>
