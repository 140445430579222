<form
  class="flex items-center"
  *ngIf="activityControl"
>
  <button
    (click)="clone()"
    tuiIconButton
    type="button"
    appearance="outline"
    tuiHintDirection="top-left"
    [tuiHint]="'Tactics.Manage.duplicate' | translate"
    [icon]="iconDuplicate"
    [disabled]="disabled"
    size="s"
  ></button>
  <ng-template #iconDuplicate>
    <svg-icon
      class="tui-text-04"
      [svgStyle]="{ width: '1rem', height: '1rem' }"
      [src]="'/assets/img/_ic_duplicate.svg'"
    ></svg-icon>
  </ng-template>

  <button
    (click)="disabled ? restore() : remove()"
    class="ml-1"
    tuiIconButton
    type="button"
    appearance="outline"
    [icon]="disabled ? iconRestore : iconTrash"
    size="s"
  ></button>
  <ng-template #iconTrash>
    <svg-icon
      class="tui-text-04"
      [svgStyle]="{ width: '1rem', height: '1rem' }"
      [src]="'/assets/img/_ic_trash.svg'"
    ></svg-icon>
  </ng-template>
  <ng-template #iconRestore
    ><svg-icon
      class="tui-text-04"
      [src]="'/assets/img/ic_refresh.svg'"
      [svgStyle]="{ width: '1rem', height: '1rem' }"
    ></svg-icon
  ></ng-template>
</form>
