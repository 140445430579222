import { Pipe, PipeTransform } from '@angular/core';
import { FieldTypeEnum } from '@modules/graphql/graphql-types';

@Pipe({
  name: 'statementMaxItems',
  pure: true,
})
export class StatementMaxItemsPipe implements PipeTransform {
  transform(fieldTypeEnum: FieldTypeEnum | undefined): number {
    switch (fieldTypeEnum) {
      case FieldTypeEnum.Multiselect:
        return 100;
      case FieldTypeEnum.Select:
        return 1;
      default:
        return 1;
    }
  }
}
