<div class="flex flex-col -ml-6 -mr-6 md:-ml-8 md:-mr-8 pl-8 pr-8 pt-4 pb-4">
  <form
    [formGroup]="form"
    [validate-form]="form"
  >
    <div class="tui-form__row">
      <tui-input formControlName="name"> {{ 'Funnels.funnel name' | translate }}* </tui-input>
      <df-error-input [control]="form.get('name')"></df-error-input>
    </div>
    <div class="tui-form__row">
      <df-tag-select
        [maxItems]="1"
        [size]="'l'"
        [controlName]="'projectName'"
        [formGroup]="form"
        [query]="FilterProjectsDocument"
      >
        {{ 'Funnels.project' | translate }}
      </df-tag-select>
    </div>
    <div class="tui-form__row mt-8 flex items-center">
      <div
        class="w-64 h-24 mr-4 rounded-md overflow-hidden object-cover flex items-center border border-solid border-gray-200"
        *ngIf="funnelManageService?.funnel?.image"
      >
        <img
          alt=""
          [src]="Config.ASSETS + funnelManageService.funnel!.image!.file"
        />
      </div>
      <df-input-file
        #imageInput
        [form]="form"
        class="w-full"
        [maxFileSize]="10000000"
        [link]="'choose an image' | translate"
        [accept]="Config.ACCEPTED_IMAGES_EXTENSIONS"
        [formName]="'image'"
      ></df-input-file>
    </div>
  </form>
</div>
<div class="flex items-center mt-4 -mb-4 justify-end">
  <button
    size="xl"
    tuiButton
    type="button"
    class="mr-4"
    appearance="outline"
    [disabled]="loading"
    (click)="cancel()"
  >
    <svg-icon
      [src]="'/assets/img/ic-close.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'cancel' | translate }}
  </button>

  <button
    size="xl"
    tuiButton
    type="submit"
    appearance="primary"
    [showLoader]="loading"
    [disabled]="loading"
    (click)="save()"
  >
    <svg-icon
      [src]="'/assets/img/ic-check.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'save' | translate }}
  </button>
</div>
