import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GenerateContentCostBadgeService {
  private readonly _creditsPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  readonly creditsPrice$: Observable<number> = this._creditsPrice$.asObservable();

  setCreditsPrice(number: number): void {
    this._creditsPrice$.next(number);
  }
}
