<df-statement-content-wrapper>
  <form
    *ngIf="statementFormService.isFormReady"
    [formGroup]="statementFormService.form"
    [validate-form]="statementFormService.form"
    class="statement-container container overflow-hidden m-auto flex flex-col justify-start items-start"
  >
    <div
      *ngIf="statementService.loading$ | async"
      class="loader-container flex justify-center items-center w-full"
    >
      <df-page-list-loader></df-page-list-loader>
    </div>
    <div
      class="statement-text"
      *ngIf="(statementService.loading$ | async) === false"
      #statementStep1Container
    >
      <div class="w-full">
        <ng-container>
          <df-statement-section-header
            [header]="'Statement.General informations' | translate"
          ></df-statement-section-header>
          <span class="statement-text-fragment">{{ 'Statement.Our' | translate }}</span>
          <!-- Product Type -->
          <df-statement-select-wrapper
            class="custom-mobile-select custom-mobile-select-small-margin"
            #productTypeSelectWrapper
          >
            <df-statement-select
              #productType
              [name]="'Statement.product type' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.productType?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.ProductType"
              [items]="statementNewParametersService.productType?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="productTypeSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.ProductType"
              [form]="statementFormService.form"
              [component]="productType"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <!-- Product Name -->
          <df-statement-input
            [form]="statementFormService.form"
            [controlName]="FieldCategoryEnum.ProductName"
            [name]="'Statement.product name' | translate"
            [container]="statementStep1Container"
            [formSubmitted]="formSubmitted"
          >
          </df-statement-input>
          <span class="statement-text-fragment">{{ 'Statement.helps' | translate }}</span>
          <!-- Target segment -->
          <df-statement-select-wrapper
            class="custom-mobile-select"
            #targetSegmentSelectWrapper
          >
            <df-statement-select
              #targetSegment
              [name]="'Statement.target segment' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.targetSegment?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.TargetSegment"
              [items]="statementNewParametersService.targetSegment?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="targetSegmentSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.TargetSegment"
              [form]="statementFormService.form"
              [component]="targetSegment"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <!-- Represented by person/job -->
          <span class="statement-text-fragment">{{ 'Statement.represented by' | translate }}</span>
          <df-statement-select-wrapper
            class="custom-mobile-select"
            #segmentPeopleSelectWrapper
          >
            <df-statement-select
              *ngIf="!statementFormService.isB2C && !statementFormService.isB2B"
              [name]="'Statement.person / job' | translate"
              [form]="statementFormService.form"
              [maxItems]="0"
              [controlName]="''"
              [items]="[]"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="segmentPeopleSelectWrapper.onDropdownChange($event)"
            ></df-statement-select>
            <ng-container *ngIf="statementFormService.isB2C">
              <df-statement-select
                #segmentPeople
                [name]="'Statement.person' | translate"
                [form]="statementFormService.form"
                [maxItems]="statementNewParametersService.targetPerson?.type | statementMaxItems"
                [controlName]="FieldCategoryEnum.TargetPerson"
                [items]="statementNewParametersService.targetPerson?.options"
                [formSubmitted]="formSubmitted"
                (dropdownOpen)="segmentPeopleSelectWrapper.onDropdownChange($event)"
              >
              </df-statement-select>
              <df-statement-inline-value
                [controlName]="FieldCategoryEnum.TargetPerson"
                [form]="statementFormService.form"
                [component]="segmentPeople"
              ></df-statement-inline-value>
            </ng-container>

            <ng-container *ngIf="statementFormService.isB2B">
              <df-statement-select
                #segmentJobs
                [name]="'Statement.job' | translate"
                [form]="statementFormService.form"
                [maxItems]="statementNewParametersService.targetJob?.type | statementMaxItems"
                [controlName]="FieldCategoryEnum.TargetJob"
                [items]="statementNewParametersService.targetJob?.options"
                [formSubmitted]="formSubmitted"
                (dropdownOpen)="segmentPeopleSelectWrapper.onDropdownChange($event)"
              >
              </df-statement-select>
              <df-statement-inline-value
                [controlName]="FieldCategoryEnum.TargetJob"
                [form]="statementFormService.form"
                [component]="segmentJobs"
              ></df-statement-inline-value>
            </ng-container>
          </df-statement-select-wrapper>
          <!-- Product type based on B2C/B2B -->
          <span class="statement-text-fragment">{{ 'Statement.and could be included in the' | translate }}</span>
          <df-statement-select-wrapper
            class="custom-mobile-select"
            #b2cProductTypeSelectWrapper
          >
            <df-statement-select
              *ngIf="!statementFormService.isB2C && !statementFormService.isB2B"
              [name]="'Statement.product type' | translate"
              [form]="statementFormService.form"
              [maxItems]="0"
              [controlName]="''"
              [items]="[]"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="b2cProductTypeSelectWrapper.onDropdownChange($event)"
            ></df-statement-select>

            <ng-container *ngIf="statementFormService.isB2C">
              <df-statement-select
                #b2cProductType
                [name]="'Statement.product type' | translate"
                [form]="statementFormService.form"
                [maxItems]="statementNewParametersService.b2cProductType?.type | statementMaxItems"
                [controlName]="FieldCategoryEnum.B2cProductType"
                [items]="statementNewParametersService.b2cProductType?.options"
                [formSubmitted]="formSubmitted"
                (dropdownOpen)="b2cProductTypeSelectWrapper.onDropdownChange($event)"
              >
              </df-statement-select>
              <df-statement-inline-value
                [controlName]="FieldCategoryEnum.B2cProductType"
                [form]="statementFormService.form"
                [component]="b2cProductType"
              ></df-statement-inline-value>
            </ng-container>

            <ng-container *ngIf="statementFormService.isB2B">
              <df-statement-select
                #b2bProductType
                [name]="'Statement.product type' | translate"
                [form]="statementFormService.form"
                [maxItems]="statementNewParametersService.b2bProductType?.type | statementMaxItems"
                [controlName]="FieldCategoryEnum.B2bProductType"
                [items]="statementNewParametersService.b2bProductType?.options"
                [formSubmitted]="formSubmitted"
                (dropdownOpen)="b2cProductTypeSelectWrapper.onDropdownChange($event)"
              >
              </df-statement-select>
              <df-statement-inline-value
                [controlName]="FieldCategoryEnum.B2bProductType"
                [form]="statementFormService.form"
                [component]="b2bProductType"
              ></df-statement-inline-value>
            </ng-container>
          </df-statement-select-wrapper>
          <span class="statement-text-fragment">{{ 'Statement.sector' | translate }}</span>
        </ng-container>
        <ng-container>
          <df-statement-section-header
            class="custom-header"
            [header]="'Statement.Revenue characteristics' | translate"
          ></df-statement-section-header>
          <span class="statement-text-fragment">{{ 'Statement.Our product is dedicated for' | translate }}</span>
          <!-- Target characteristics -->
          <df-statement-select-wrapper
            class="custom-mobile-select"
            #targetCharacteristicSelectWrapper
          >
            <df-statement-select
              #targetCharacteristic
              [name]="'Statement.target characteristics' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.targetCharacteristics?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.TargetCharacteristics"
              [items]="statementNewParametersService.targetCharacteristics?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="targetCharacteristicSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.TargetCharacteristics"
              [form]="statementFormService.form"
              [component]="targetCharacteristic"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <br />
          <span class="statement-text-fragment">{{ 'Statement.and we sell our goods by' | translate }}</span>
          <!-- Selling Methods -->
          <df-statement-select-wrapper
            class="custom-mobile-select custom-mobile-select-small-margin m-d-inline-block lh-helper"
            [displayInline]="true"
            #sellingMethodSelectWrapper
          >
            <df-statement-select
              #sellingMethod
              [name]="'Statement.selling method' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.sellingMethod?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.SellingMethod"
              [items]="statementNewParametersService.sellingMethod?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="sellingMethodSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.SellingMethod"
              [form]="statementFormService.form"
              [component]="sellingMethod"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <span>.</span>
          <br />
          <div class="mt-[2rem]"></div>
          <span class="statement-text-fragment">{{
            'Statement.Products compared to the competitors have' | translate
          }}</span>
          <!-- Pricing characteristics -->
          <df-statement-select-wrapper
            class="custom-select custom-mobile-select"
            #pricingCharacteristicSelectWrapper
          >
            <df-statement-select
              #pricingCharacteristic
              [name]="'Statement.pricing' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.pricingCharacteristics?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.PricingCharacteristics"
              [items]="statementNewParametersService.pricingCharacteristics?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="pricingCharacteristicSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.PricingCharacteristics"
              [form]="statementFormService.form"
              [component]="pricingCharacteristic"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <br />
          <span class="statement-text-fragment">{{
            'Statement.and we earn average margin on product ' | translate
          }}</span>
          <!-- Average product margin % -->
          <df-statement-input
            class="custom-placeholder"
            [form]="statementFormService.form"
            [controlName]="FieldCategoryEnum.AvgProductMargin"
            [name]="'Statement.avg product margin' | translate"
            [container]="statementStep1Container"
            [formSubmitted]="formSubmitted"
            [isNumberInput]="true"
          >
          </df-statement-input>
          %
        </ng-container>
      </div>
    </div>
    <df-statement-controls
      class="w-full statement-controls"
      (onNextStep)="onNextStep()"
      (onPreviousStep)="onPreviousStep()"
    ></df-statement-controls>
  </form>
</df-statement-content-wrapper>
