import { CreateTacticMetricDocument } from './../../modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/mutations/create-tactic-metric.mutation';
import { getMetricsDocument } from './../../modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/queries/get-metrics.query';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CreateTacticDocument } from '@modules/tactics/shared/graphql/mutations/create-tactic.mutation';
import { CreateTacticMutation } from '@modules/tactics/shared/graphql/mutations/create-tactic.mutation.generated';
import {
  ActivityInputGraphql,
  AddAssetInputGraphql,
  AddBenchmarkInputGraphql,
  AdTypesListInputGraphql,
  AssetsPlacementsListInputGraphql,
  AssetsResolutionsListInputGraphql,
  CustomPromptOutputCreateGraphql,
  CustomPromptOutputUpdateGraphql,
  EditBenchmarksInputGraphql,
  FunnelTacticInputGraphql,
  MarkActivityDoneInputGraphql,
  PayedTacticInputGraphql,
  ReorderActivityInputGraphql,
  ReorderTacticInputGraphql,
  ResolvePromptInputGraphql,
  ResourcesTeamsListInputGraphql,
  TacticInputGraphql,
  TacticListTypeEnum,
  TacticMetricInputGraphql,
} from '@modules/graphql/graphql-types';
import { EditTacticMutation } from '@modules/tactics/shared/graphql/mutations/edit-tactic.mutation.generated';
import { EditTacticDocument } from '@modules/tactics/shared/graphql/mutations/edit-tactic.mutation';
import { CreateEmptyTacticMutation } from '@modules/tactics/shared/graphql/mutations/create-empty-tactic.mutation.generated';
import { CreateEmptyTacticDocument } from '@modules/tactics/shared/graphql/mutations/create-empty-tactic.mutation';
import { GetTacticQuery } from '@modules/tactics/shared/graphql/queries/get-tactic.query.generated';
import { GetTacticDocument } from '@modules/tactics/shared/graphql/queries/get-tactic.query';
import { RateTacticMutation } from '@modules/tactics/shared/graphql/mutations/rate-tatic.mutation.generated';
import { RateTacticDocument } from '@modules/tactics/shared/graphql/mutations/rate-tatic.mutation';
import { AddToFunnelDocument } from '@modules/tactics/shared/graphql/mutations/add-to-funnel.mutation';
import { AddToFunnelMutation } from '@modules/tactics/shared/graphql/mutations/add-to-funnel.mutation.generated';
import { RemoveFromFunnelMutation } from '@modules/tactics/shared/graphql/mutations/remove-from-funnel.mutation.generated';
import { RemoveFromFunnelDocument } from '@modules/tactics/shared/graphql/mutations/remove-from-funnel.mutation';
import { GetKanbanQuery } from '@modules/tactics/shared/graphql/queries/get-kanban.query.generated';
import { GetKanbanDocument } from '@modules/tactics/shared/graphql/queries/get-kanban.query';
import { RemoveTacticMutation } from '@modules/tactics/shared/graphql/mutations/remove-tactic.mutation.generated';
import { RemoveTacticDocument } from '@modules/tactics/shared/graphql/mutations/remove-tactic.mutation';
import { CreateActivityMutation } from '@modules/tactics/shared/graphql/mutations/create-activity.mutation.generated';
import { CreateActivityDocument } from '@modules/tactics/shared/graphql/mutations/create-activity.mutation';
import { RemoveActivityMutation } from '@modules/tactics/shared/graphql/mutations/remove-activity.mutation.generated';
import { RemoveActivityDocument } from '@modules/tactics/shared/graphql/mutations/remove-activity.mutation';
import { EditActivityMutation } from '@modules/tactics/shared/graphql/mutations/edit-activity.mutation.generated';
import { EditActivityDocument } from '@modules/tactics/shared/graphql/mutations/edit-activity.mutation';
import { ReorderActivityMutation } from '@modules/tactics/shared/graphql/mutations/reorder-activity.mutation.generated';
import { ReorderActivityDocument } from '@modules/tactics/shared/graphql/mutations/reorder-activity.mutation';
import { CreateActivityImageDocument } from '@modules/tactics/shared/graphql/mutations/create-activity-image.mutation';
import { CreateActivityLogoMutation } from '@modules/tactics/shared/graphql/mutations/create-activity-logo.mutation.generated';
import { CreateActivityLogoDocument } from '@modules/tactics/shared/graphql/mutations/create-activity-logo.mutation';
import { RemoveAttachmentMutation } from '@modules/tactics/shared/graphql/mutations/remove-attachment.mutation.generated';
import { RemoveAttachmentDocument } from '@modules/tactics/shared/graphql/mutations/remove-attachment.mutation';
import { CreateActivityImageMutation } from '@modules/tactics/shared/graphql/mutations/create-activity-image.mutation.generated';
import { CopyActivitiesToTacticMutation } from '@modules/tactics/shared/graphql/mutations/copy-activities-to-tactic.mutation.generated';
import { CopyActivitiesToTacticDocument } from '@modules/tactics/shared/graphql/mutations/copy-activities-to-tactic.mutation';
import { ShareTacticDocument } from '@modules/tactics/shared/graphql/mutations/share-tactic.mutation';
import { ShareTacticMutation } from '@modules/tactics/shared/graphql/mutations/share-tactic.mutation.generated';
import { CreateFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/create-funnel-tactic.mutation.generated';
import { CreateFunnelTacticDocument } from '@modules/tactics/shared/graphql/mutations/create-funnel-tactic.mutation';
import { ReorderFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/reorder-funnel-tactic.mutation.generated';
import { ReorderFunnelTacticDocument } from '@modules/tactics/shared/graphql/mutations/reorder-funnel-tactic.mutation';
import { map, Observable, tap } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { RemoveFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/remove-funnel-tactic.mutation.generated';
import { RemoveFunnelTacticDocument } from '@modules/tactics/shared/graphql/mutations/remove-funnel-tactic.mutation';
import { MarkFunnelTacticAsDoneMutation } from '@modules/tactics/shared/graphql/mutations/mark-funnel-tactic-as-done.mutation.generated';
import { MarkFunnelTacticAsDoneDocument } from '@modules/tactics/shared/graphql/mutations/mark-funnel-tactic-as-done.mutation';
import { SearchTacticsQuery } from '@modules/tactics/shared/graphql/queries/search-tactics.query.generated';
import { SearchTacticsDocument } from '@modules/tactics/shared/graphql/queries/search-tactics.query';
import { RemoveShareTacticDocument } from '@modules/tactics/shared/graphql/mutations/remove-share-tactic.mutation';
import { RemoveTacticShareMutation } from '@modules/tactics/shared/graphql/mutations/remove-share-tactic.mutation.generated';
import { ChangeTacticPrivacyMutation } from '@modules/tactics/shared/graphql/mutations/change-tactic-privacy.mutation.generated';
import { ChangeTacticPrivacyDocument } from '@modules/tactics/shared/graphql/mutations/change-tactic-privacy.mutation';
import { CloneActivityMutation } from '@modules/tactics/shared/graphql/mutations/clone-activity.mutation.generated';
import { CloneActivityDocument } from '@modules/tactics/shared/graphql/mutations/clone-activity.mutation';
import { AddTacticToFavouritesMutation } from '@modules/tactics/shared/graphql/mutations/add-tactic-to-favourite.mutation.generated';
import { AddTacticToFavouriteDocument } from '@modules/tactics/shared/graphql/mutations/add-tactic-to-favourite.mutation';
import { RemoveTacticFromFavouritesMutation } from '@modules/tactics/shared/graphql/mutations/remove-tactic-from-favourite.mutation.generated';
import { RemoveTacticFromFavouriteDocument } from '@modules/tactics/shared/graphql/mutations/remove-tactic-from-favourite.mutation';
import { MarkActivityDoneMutation } from '@modules/tactics/shared/graphql/mutations/mark-activity-done.mutation.generated';
import { MarkActivityDoneDocument } from '@modules/tactics/shared/graphql/mutations/mark-activity-done.mutation';
import { AddAssetToActivityMutation } from '@modules/tactics/shared/graphql/mutations/add-asset-to-activity.mutation.generated';
import { AddAssetToActivityDocument } from '@modules/tactics/shared/graphql/mutations/add-asset-to-activity.mutation';
import { RemoveAssetFromActivityMutation } from '@modules/tactics/shared/graphql/mutations/remove-asset-from-activity.mutation.generated';
import { RemoveAssetFromActivityDocument } from '@modules/tactics/shared/graphql/mutations/remove-asset-from-activity.mutation';
import { GetAssetsPlatformsDocument } from '@modules/tactics/shared/graphql/queries/get-assets-platforms.query';
import { GetAssetsPlatformsQuery } from '@modules/tactics/shared/graphql/queries/get-assets-platforms.query.generated';
import { GetAssetsAdTypesQuery } from '@modules/tactics/shared/graphql/queries/get-assets-ad-types.query.generated';
import { GetAssetsAdTypesDocument } from '@modules/tactics/shared/graphql/queries/get-assets-ad-types.query';
import { GetAssetsPlacementsQuery } from '@modules/tactics/shared/graphql/queries/get-assets-placements.query.generated';
import { GetAssetsPlacementsDocument } from '@modules/tactics/shared/graphql/queries/get-assets-placements.query';
import { GetAssetsResolutionsQuery } from '@modules/tactics/shared/graphql/queries/get-assets-resolutions.query.generated';
import { GetAssetsResolutionsDocument } from '@modules/tactics/shared/graphql/queries/get-assets-resolutions.query';
import { GetActivityTypesQuery } from '@modules/tactics/shared/graphql/queries/get-activity-types.query.generated';
import { GetActivityTypesDocument } from '@modules/tactics/shared/graphql/queries/get-activity-types.query';
import { AddBenchmarkToActivityMutation } from '@modules/tactics/shared/graphql/mutations/add-benchmark-to-activity.mutation.generated';
import { AddBenchmarkToActivityDocument } from '@modules/tactics/shared/graphql/mutations/add-benchmark-to-activity.mutation';
import { RemoveBenchmarkFromActivityMutation } from '@modules/tactics/shared/graphql/mutations/remove-benchmark-from-activity.mutation.generated';
import { RemoveBenchmarkFromActivityDocument } from '@modules/tactics/shared/graphql/mutations/remove-benchmark-from-activity.mutation';
import { GetBenchmarksTypesQuery } from '@modules/tactics/shared/graphql/queries/get-benchmark-types.query.generated';
import { GetBenchmarkTypesDocument } from '@modules/tactics/shared/graphql/queries/get-benchmark-types.query';
import { EditBenchmarkMutation } from '@modules/tactics/shared/graphql/mutations/edit-benchmark.mutation.generated';
import { EditBenchmarkDocument } from '@modules/tactics/shared/graphql/mutations/edit-benchmark.mutation';
import { UpdateFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/update-funnel-tactic.mutation.generated';
import { UpdateFunnelTacticDocument } from '@modules/tactics/shared/graphql/mutations/update-funnel-tactic.mutation';
import { DuplicateFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/duplicate-funnel-tactic.mutation.generated';
import { DuplicateFunnelTacticDocument } from '@modules/tactics/shared/graphql/mutations/duplicate-funnel-tactic.mutation';
import { GetPubliclyVisibleTacticDocument } from '@modules/tactics/shared/graphql/queries/get-publicly-visible-tactic.query';
import { GetPubliclyVisibleTacticQuery } from '@modules/tactics/shared/graphql/queries/get-publicly-visible-tactic.query.generated';
import { SetTacticAsPayedDocument } from '@modules/tactics/shared/graphql/mutations/set-tactic-as-payed.mutation';
import { SetTacticAsPayedMutation } from '@modules/tactics/shared/graphql/mutations/set-tactic-as-payed.mutation.generated';
import { GetPurchasedTacticByRootQuery } from '@modules/tactics/shared/graphql/queries/get-purchased-tactic-by-root.query.generated';
import { GetPurchasedTacticByRootDocument } from '@modules/tactics/shared/graphql/queries/get-purchased-tactic-by-root.query';
import { CloneTacticMutation } from '@modules/tactics/shared/graphql/mutations/clone-tactic.mutation.generated';
import { CloneTacticDocument } from '@modules/tactics/shared/graphql/mutations/clone-tactic.mutation';
import { GetTacticForEditDocument } from '@modules/tactics/shared/graphql/queries/get-tactic-for-edit.query';
import { CheckTacticStatusQuery } from '@modules/tactics/shared/graphql/queries/check-tactic-status.query.generated';
import { CheckTacticStatusDocument } from '@modules/tactics/shared/graphql/queries/check-tactic-status.query';
import { GetTacticForEditQuery } from '@modules/tactics/shared/graphql/queries/get-tactic-for-edit.query.generated';
import { GetResourceTeamsByResourceDocument } from '@modules/tactics/modules/tactic-settings/shared/graphql/queries/get-resource-teams-by-resource.query';
import { GetResourceTeamsByResourceQuery } from '@modules/tactics/modules/tactic-settings/shared/graphql/queries/get-resource-teams-by-resource.query.generated';
import { CreateTacticImageDocument } from '@modules/tactics/shared/graphql/mutations/create-tactic-image.mutation';
import { CreateTacticImageMutation } from '@modules/tactics/shared/graphql/mutations/create-tactic-image.mutation.generated';
import { ReorderActivitiesMutation } from '@modules/tactics/shared/graphql/mutations/reorder-activities.mutation.generated';
import { ReorderActivitiesDocument } from '@modules/tactics/shared/graphql/mutations/reorder-activities.mutation';
import { PrepareActivityAssetMutation } from '@modules/tactics/shared/graphql/mutations/prepare-activity-asset.mutation.generated';
import { PrepareActivityAssetDocument } from '@modules/tactics/shared/graphql/mutations/prepare-activity-asset.mutation';
import { GetMetricsQuery } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/queries/get-metrics.query.generated';
import { CreateTacticMetricMutation } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/mutations/create-tactic-metric.mutation.generated';
import { EditTacticMetricMutation } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/mutations/edit-tactic-metric.mutation.generated';
import { EditTacticMetricDocument } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/mutations/edit-tactic-metric.mutation';
import { GetTacticsMetricsQuery } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/queries/get-tactic-metrics.query.generated';
import { getTacticsMetricsDocument } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/queries/get-tactic-metrics.query';
import { RemoveTacticMetricDocument } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/mutations/remove-tactic-metric.mutation';
import { RemoveTacticMetricMutation } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-metrics/graphql/mutations/remove-tactic-metric.mutation.generated';
import { ResolveCustomPromptQuery } from '../graphql/queries/resolve-custom-prompt.query.generated';
import { ResolveCustomPrompt } from '../graphql/queries/resolve-custom-prompt.query';
import { CreateCustomPromptOutputMutation } from '../graphql/mutations/create-custom-prompt-output.mutation.generated';
import { CreateCustomPromptOutputDocument } from '../graphql/mutations/create-custom-prompt-output.mutation';
import { UpdateCustomPromptOutputMutation } from '../graphql/mutations/update-custom-prompt-output.mutation.generated';
import { UpdateCustomPromptOutputDocument } from '../graphql/mutations/update-custom-prompt-output.mutation';
import { GetCatalogByDefDocument } from '../graphql/queries/get-catalog-by-def.query';
import { GetCatalogByDefQuery } from '../graphql/queries/get-catalog-by-def.query.generated';
import { GetCustomPromptOutputDocument } from '../graphql/queries/get-custom-prompt-output.query';
import { GetCustomPromptOutputQuery } from '../graphql/queries/get-custom-prompt-output.query.generated';

import { FindAllPromptsQuery } from '@modules/prompt/shared/graphql/queries/find-all-prompt.query.generated';
import { FindAllPromptsDocument } from '../graphql/queries/find-all-prompts.query';
import { StatementGraphqlService } from '@modules/statement/shared/services/statement-graphql.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { UserService } from '@shared/services/user.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TacticGraphqlService {
  constructor(
    private apollo: Apollo,
    private statementGraphqlService: StatementGraphqlService,
    private userService: UserService,
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  createTactic(tactic: TacticInputGraphql) {
    return this.apollo.mutate<CreateTacticMutation>({
      mutation: CreateTacticDocument,
      variables: {
        input: tactic,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getAndAssignRecomendedTactic(
    funnelId: number,
    records: number,
    stepId: number | null = null,
    funnelManageService: FunnelManageService,
  ) {
    return this.statementGraphqlService.getRecommendedTactics(funnelId, records, true).pipe(
      map((res) => res.data.getTacticsRecommendations),
      tap((res) => {
        if (!res) return;
        res.forEach((tacticRecomendation) => {
          if (tacticRecomendation.step.id === stepId && tacticRecomendation.recommendations.length <= 0) {
            this.s.error(
              this.t.instant("Funnels.Manage.We don't have any new recommended tactics for that funnel step."),
            );
          }

          if (tacticRecomendation.step.id === stepId) {
            tacticRecomendation.recommendations.forEach((data) => {
              this.createFunnelTactic({
                funnelId: funnelId,
                tacticId: data.tactic.id,
                stepId: stepId,
                position: 0,
              }).subscribe((res) => {
                const funnelTactic = new FunnelTactic(res.data?.createFunnelTactic);
                this.userService.User?.contextFunnel.tactics.push(funnelTactic);
                funnelManageService.funnelTactics[stepId][funnelTactic.id] = funnelTactic;
                funnelManageService.funnel$.emit(funnelManageService.funnel);
              });
            });
          }
        });
      }),
    );
  }

  createEmptyTactic() {
    return this.apollo.mutate<CreateEmptyTacticMutation>({
      mutation: CreateEmptyTacticDocument,
    });
  }

  editTactic(tactic: TacticInputGraphql, id: number) {
    return this.apollo.mutate<EditTacticMutation>({
      mutation: EditTacticDocument,
      variables: {
        input: tactic,
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getPrompts() {
    return this.apollo.query<FindAllPromptsQuery>({
      query: FindAllPromptsDocument,
      fetchPolicy: 'no-cache',
    });
  }

  getTactic(id: number, forEdit = false): Observable<FetchResult<GetTacticQuery | GetTacticForEditQuery>> {
    return this.apollo.query<GetTacticQuery | GetTacticForEditQuery>({
      query: forEdit ? GetTacticForEditDocument : GetTacticDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  checkTacticStatus(id: number): Observable<FetchResult<CheckTacticStatusQuery>> {
    return this.apollo.query<CheckTacticStatusQuery>({
      query: CheckTacticStatusDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getPubliclyVisibleTactic(id: number): Observable<FetchResult<GetPubliclyVisibleTacticQuery>> {
    return this.apollo.query<GetPubliclyVisibleTacticQuery>({
      query: GetPubliclyVisibleTacticDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  rateTactic(tacticId: number, rate: number) {
    return this.apollo.mutate<RateTacticMutation>({
      mutation: RateTacticDocument,
      variables: {
        input: {
          rate,
          tacticId,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  shareTactic(tacticId: number, emails: string[]): Observable<FetchResult<ShareTacticMutation>> {
    return this.apollo.mutate<ShareTacticMutation>({
      mutation: ShareTacticDocument,
      variables: {
        input: {
          tacticId,
          emails,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeShareTactic(shareId: number): Observable<FetchResult<RemoveTacticShareMutation>> {
    return this.apollo.mutate<RemoveTacticShareMutation>({
      mutation: RemoveShareTacticDocument,
      variables: {
        shareId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  changeTacticPrivacy(isPrivate: boolean, tacticId: number) {
    return this.apollo.mutate<ChangeTacticPrivacyMutation>({
      mutation: ChangeTacticPrivacyDocument,
      variables: {
        private: isPrivate,
        tacticId: tacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  addToMyFunnel(tacticId: number) {
    return this.apollo.mutate<AddToFunnelMutation>({
      mutation: AddToFunnelDocument,
      variables: {
        id: tacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeFromFunnel(tacticId: number) {
    return this.apollo.mutate<RemoveFromFunnelMutation>({
      mutation: RemoveFromFunnelDocument,
      variables: {
        id: tacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getKanban() {
    return this.apollo.query<GetKanbanQuery>({
      query: GetKanbanDocument,
      fetchPolicy: 'no-cache',
    });
  }

  removeTactic(tacticId: number) {
    return this.apollo.mutate<RemoveTacticMutation>({
      mutation: RemoveTacticDocument,
      variables: {
        id: tacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createActivity(input: ActivityInputGraphql, tacticId: number) {
    return this.apollo.mutate<CreateActivityMutation>({
      mutation: CreateActivityDocument,
      variables: {
        input,
        tacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  editActivity(input: ActivityInputGraphql, activityId: number) {
    input.customPrompts =
      input?.customPrompts?.map((customPrompt) => {
        return {
          id: customPrompt.id,
          name: customPrompt.name,
          category: customPrompt.category,
          description: customPrompt.description,
          promptText: customPrompt.promptText,
          promptProperty: customPrompt.promptProperty,
          settingsIntegration: customPrompt.settingsIntegration,
          settingsActive: customPrompt.settingsActive,
        };
      }) ?? [];

    return this.apollo.mutate<EditActivityMutation>({
      mutation: EditActivityDocument,
      variables: {
        input,
        activityId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeActivity(activityId: number) {
    return this.apollo.mutate<RemoveActivityMutation>({
      mutation: RemoveActivityDocument,
      variables: {
        activityId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  cloneActivity(activityId: number, tacticId: number) {
    return this.apollo.mutate<CloneActivityMutation>({
      mutation: CloneActivityDocument,
      variables: {
        activityId,
        tacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  reorderActivity(activityId: number, position: number) {
    return this.apollo.mutate<ReorderActivityMutation>({
      mutation: ReorderActivityDocument,
      variables: {
        activityId,
        position,
      },
      fetchPolicy: 'no-cache',
    });
  }

  addAssetToActivity(input: AddAssetInputGraphql) {
    return this.apollo.mutate<AddAssetToActivityMutation>({
      mutation: AddAssetToActivityDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  prepareActivityAsset(input: AddAssetInputGraphql) {
    return this.apollo.mutate<PrepareActivityAssetMutation>({
      mutation: PrepareActivityAssetDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeAssetFromActivity(activityAssetId: number) {
    return this.apollo.mutate<RemoveAssetFromActivityMutation>({
      mutation: RemoveAssetFromActivityDocument,
      variables: {
        activityAssetId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getAssetsPlatforms() {
    return this.apollo.query<GetAssetsPlatformsQuery>({
      query: GetAssetsPlatformsDocument,
      fetchPolicy: 'no-cache',
    });
  }

  getAssetsAdTypes(input: AdTypesListInputGraphql) {
    return this.apollo.query<GetAssetsAdTypesQuery>({
      query: GetAssetsAdTypesDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getAssetsPlacements(input: AssetsPlacementsListInputGraphql) {
    return this.apollo.query<GetAssetsPlacementsQuery>({
      query: GetAssetsPlacementsDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getAssetsResolutions(input: AssetsResolutionsListInputGraphql) {
    return this.apollo.query<GetAssetsResolutionsQuery>({
      query: GetAssetsResolutionsDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getActivityTypes() {
    return this.apollo.query<GetActivityTypesQuery>({
      query: GetActivityTypesDocument,
      fetchPolicy: 'no-cache',
    });
  }

  addBenchmarkToActivity(input: AddBenchmarkInputGraphql, file: File | null, attachmentId?: number) {
    const variables = { input };
    file ? (variables['file'] = file) : '';
    attachmentId ? (variables['attachmentId'] = attachmentId) : '';
    return this.apollo.mutate<AddBenchmarkToActivityMutation>({
      mutation: AddBenchmarkToActivityDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        useMultipart: true,
      },
    });
  }

  editBenchmark(input: EditBenchmarksInputGraphql, file: File | null) {
    const variables = { input };
    file ? (variables['file'] = file) : '';
    return this.apollo.mutate<EditBenchmarkMutation>({
      mutation: EditBenchmarkDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        useMultipart: true,
      },
    });
  }

  removeBenchmarkFromActivity(benchmarkId: number, activityId: number) {
    return this.apollo.mutate<RemoveBenchmarkFromActivityMutation>({
      mutation: RemoveBenchmarkFromActivityDocument,
      variables: {
        benchmarkId,
        activityId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getBenchmarkTypes() {
    return this.apollo.query<GetBenchmarksTypesQuery>({
      query: GetBenchmarkTypesDocument,
      fetchPolicy: 'no-cache',
    });
  }

  createActivityLogo(activityId: number, file: File) {
    return this.apollo.mutate<CreateActivityLogoMutation>({
      mutation: CreateActivityLogoDocument,
      variables: {
        activityId,
        file,
      },
      context: {
        useMultipart: true,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createActivityImage(activityId: number, file: File) {
    return this.apollo.mutate<CreateActivityImageMutation>({
      mutation: CreateActivityImageDocument,
      variables: {
        activityId,
        file,
      },
      context: {
        useMultipart: true,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeAttachment(id: number) {
    return this.apollo.mutate<RemoveAttachmentMutation>({
      mutation: RemoveAttachmentDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  copyActivitiesToTactic(dstTacticId: number, srcTacticId: number) {
    return this.apollo.mutate<CopyActivitiesToTacticMutation>({
      mutation: CopyActivitiesToTacticDocument,
      variables: {
        dstTacticId,
        srcTacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createFunnelTactic(input: FunnelTacticInputGraphql): Observable<FetchResult<CreateFunnelTacticMutation>> {
    return this.apollo.mutate<CreateFunnelTacticMutation>({
      mutation: CreateFunnelTacticDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeFunnelTactic(funnelTacticId: number): Observable<FetchResult<RemoveFunnelTacticMutation>> {
    return this.apollo.mutate<RemoveFunnelTacticMutation>({
      mutation: RemoveFunnelTacticDocument,
      variables: {
        funnelTacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  duplicateFunnelTactic(funnelTacticId: number): Observable<FetchResult<DuplicateFunnelTacticMutation>> {
    return this.apollo.mutate<DuplicateFunnelTacticMutation>({
      mutation: DuplicateFunnelTacticDocument,
      variables: {
        funnelTacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updateFunnelTactic(variables: {
    name: string;
    funnelTacticId: number;
  }): Observable<FetchResult<UpdateFunnelTacticMutation>> {
    return this.apollo.mutate<UpdateFunnelTacticMutation>({
      mutation: UpdateFunnelTacticDocument,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  reorderFunnelTactic(input: ReorderTacticInputGraphql): Observable<FetchResult<ReorderFunnelTacticMutation>> {
    return this.apollo.mutate<ReorderFunnelTacticMutation>({
      mutation: ReorderFunnelTacticDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  markFunnelTacticAsDone(
    isDone: boolean,
    funnelTacticId: number,
  ): Observable<FetchResult<MarkFunnelTacticAsDoneMutation>> {
    return this.apollo.mutate<MarkFunnelTacticAsDoneMutation>({
      mutation: MarkFunnelTacticAsDoneDocument,
      variables: {
        isDone,
        funnelTacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  markActivityDone(input: MarkActivityDoneInputGraphql): Observable<FetchResult<MarkActivityDoneMutation>> {
    return this.apollo.mutate<MarkActivityDoneMutation>({
      mutation: MarkActivityDoneDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getTopBannerTactics() {
    return this.apollo.query<SearchTacticsQuery>({
      query: SearchTacticsDocument,
      variables: {
        query: {
          page: 1,
          records: 50,
          type: TacticListTypeEnum.Banner,
        },
      },
    });
  }

  addTacticToFavourites(tacticId: number): Observable<FetchResult<AddTacticToFavouritesMutation>> {
    return this.apollo.mutate<AddTacticToFavouritesMutation>({
      mutation: AddTacticToFavouriteDocument,
      variables: {
        tacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeTacticFromFavourites(tacticId: number): Observable<FetchResult<RemoveTacticFromFavouritesMutation>> {
    return this.apollo.mutate<RemoveTacticFromFavouritesMutation>({
      mutation: RemoveTacticFromFavouriteDocument,
      variables: {
        tacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  setTacticAsPayed(input: PayedTacticInputGraphql): Observable<FetchResult<SetTacticAsPayedMutation>> {
    return this.apollo.mutate<SetTacticAsPayedMutation>({
      mutation: SetTacticAsPayedDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getPurchasedTacticByRoot(rootId: number): Observable<FetchResult<GetPurchasedTacticByRootQuery>> {
    return this.apollo.query<GetPurchasedTacticByRootQuery>({
      query: GetPurchasedTacticByRootDocument,
      variables: {
        rootId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  cloneTactic(id: number): Observable<FetchResult<CloneTacticMutation>> {
    return this.apollo.mutate<CloneTacticMutation>({
      mutation: CloneTacticDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getResourceTeamsByResource(
    input: ResourcesTeamsListInputGraphql,
  ): Observable<FetchResult<GetResourceTeamsByResourceQuery>> {
    return this.apollo.query<GetResourceTeamsByResourceQuery>({
      query: GetResourceTeamsByResourceDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createTacticImage(tacticId: number, file: File) {
    return this.apollo.mutate<CreateTacticImageMutation>({
      mutation: CreateTacticImageDocument,
      variables: {
        tacticId,
        file,
      },
      context: {
        useMultipart: true,
      },
      fetchPolicy: 'no-cache',
    });
  }

  reorderActivities(
    tacticId: number,
    positions: ReorderActivityInputGraphql[],
  ): Observable<FetchResult<ReorderActivitiesMutation>> {
    return this.apollo.query<ReorderActivitiesMutation>({
      query: ReorderActivitiesDocument,
      variables: {
        tacticId,
        positions,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getMetrics(): Observable<FetchResult<GetMetricsQuery>> {
    return this.apollo.query<GetMetricsQuery>({
      query: getMetricsDocument,
      fetchPolicy: 'no-cache',
    });
  }

  getTacticsMetrics(tacticId: number): Observable<FetchResult<GetTacticsMetricsQuery>> {
    return this.apollo.query<GetTacticsMetricsQuery>({
      query: getTacticsMetricsDocument,
      variables: {
        tacticId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createTacticMetric(input: TacticMetricInputGraphql): Observable<FetchResult<CreateTacticMetricMutation>> {
    return this.apollo.mutate<CreateTacticMetricMutation>({
      mutation: CreateTacticMetricDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  editTacticMetric(input: TacticMetricInputGraphql, id: number): Observable<FetchResult<EditTacticMetricMutation>> {
    return this.apollo.mutate<EditTacticMetricMutation>({
      mutation: EditTacticMetricDocument,
      variables: {
        input,
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeTacticMetric(id: number): Observable<FetchResult<RemoveTacticMetricMutation>> {
    return this.apollo.mutate<RemoveTacticMetricMutation>({
      mutation: RemoveTacticMetricDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getDataWarehouse(defId: number): Observable<FetchResult<GetCatalogByDefQuery>> {
    return this.apollo.query<GetCatalogByDefQuery>({
      query: GetCatalogByDefDocument,
      variables: {
        defId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  resolveCustomPrompt(
    resolvePromptInput: ResolvePromptInputGraphql,
  ): Observable<FetchResult<ResolveCustomPromptQuery>> {
    return this.apollo.query<ResolveCustomPromptQuery>({
      query: ResolveCustomPrompt,
      variables: {
        resolvePromptInput,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getCustomPromptOutput(customPromptOutputId: number): Observable<FetchResult<GetCustomPromptOutputQuery>> {
    return this.apollo.query<GetCustomPromptOutputQuery>({
      query: GetCustomPromptOutputDocument,
      variables: {
        customPromptOutputId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createCustomPromptOutput(
    customPromptOutputCreate: CustomPromptOutputCreateGraphql,
  ): Observable<FetchResult<CreateCustomPromptOutputMutation>> {
    return this.apollo.mutate<CreateCustomPromptOutputMutation>({
      mutation: CreateCustomPromptOutputDocument,
      variables: {
        customPromptOutputCreate,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updateCustomPromptOutput(
    customPromptOutputUpdate: CustomPromptOutputUpdateGraphql,
  ): Observable<FetchResult<UpdateCustomPromptOutputMutation>> {
    return this.apollo.mutate<UpdateCustomPromptOutputMutation>({
      mutation: UpdateCustomPromptOutputDocument,
      variables: {
        customPromptOutputUpdate,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
