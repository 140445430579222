import { gql } from 'apollo-angular';

export const DeleteAssetDocument = gql`
  mutation deleteAsset($assetId: Int!) {
    deleteAsset(assetId: $assetId) {
      success
      process
    }
  }
`;
