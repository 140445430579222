import { gql } from 'apollo-angular';

export const DownloadOutputsDataZipDocument =
  gql(/* GraphQL*/ `mutation downloadOutputsDataZip($name: String!, $funnelId: Int!, $outputsDataIds: [Int!]!) {
  downloadOutputsDataZip(name: $name, funnelId: $funnelId, outputsDataIds: $outputsDataIds) {
    id
    name
    file
  }
}
`);
