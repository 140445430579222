<df-statement-content-wrapper>
  <form
    *ngIf="statementFormService.isFormReady"
    [formGroup]="statementFormService.form"
    [validate-form]="statementFormService.form"
    class="statement-container container overflow-hidden m-auto flex flex-col justify-start items-start"
  >
    <div class="statement-text">
      <div class="w-full">
        <ng-container>
          <div class="section-wrapper">
            <span class="statement-text-fragment">{{
              'Statement.I would describe my marketing skills as an' | translate
            }}</span>
            <df-statement-options-slider-input
              [form]="statementFormService.form"
              [controlName]="FieldCategoryEnum.MarketingAdvancement"
              [statementInput]="statementNewParametersService.marketingAdvancement"
            ></df-statement-options-slider-input>
          </div>
        </ng-container>
        <ng-container>
          <div class="section-wrapper">
            <span class="mt-[2rem] statement-text-fragment">{{
              'Statement.The ratio of my marketing engagement to spent budget I want to invest to archive goal'
                | translate
            }}</span>
            <df-statement-values-slider-input
              [form]="statementFormService.form"
              [controlName]="FieldCategoryEnum.Engagement"
              [statementInput]="statementNewParametersService.engagement"
            ></df-statement-values-slider-input>
          </div>
        </ng-container>
      </div>
    </div>
    <df-statement-controls
      class="w-full"
      (onNextStep)="onSubmit()"
      (onPreviousStep)="onPreviousStep()"
    ></df-statement-controls>
  </form>
</df-statement-content-wrapper>
