import { gql } from 'apollo-angular';

export const StatementConfigurationDocument = gql`
  query getStatementConfiguration {
    getStatementConfiguration {
      id
      fields {
        id
        category
        type
        required
        minValue {
          id
          name
          value
        }
        maxValue {
          id
          name
          value
        }
        dependsOnField {
          id
          category
        }
        dependsOnOption {
          id
          name
        }
        options {
          id
          name
        }
      }
    }
  }
`;
