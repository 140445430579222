export enum EActivityAssetForm {
  activityId = 'activityId',
  platformId = 'platformId',
  addTypeId = 'addTypeId',
  placementId = 'placementId',
  resolutionsIds = 'resolutionsIds',
  asset = 'asset',
  state = 'state',
  uuid = 'uuid',
}
