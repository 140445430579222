import { gql } from 'apollo-angular';

export const FilterAdvancementsDocument = gql(/* GraphQL*/ `query filterAdvancements($input: FilterInputGraphql!)
{
  filterAdvancements(input: $input)
  {
    records{
      name,
      id
    },
    total
  }
}

`);
