import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Step } from '@shared/models/step.model';
import { TuiDialogService } from '@taiga-ui/core';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { OnboardingService } from '@shared/services/onboarding.service';

@Component({
  selector: 'df-funnel-manage-content-step-header',
  templateUrl: './funnel-manage-content-step-header.component.html',
  styleUrls: ['./funnel-manage-content-step-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageContentStepHeaderComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  @Input() step!: Step;
  @Output() getRecomendedTacticsEvent: EventEmitter<Step> = new EventEmitter();
  @Input() loading = false;
  expanded: boolean;

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private changes: ChangeDetectorRef,
    public funnelManageService: FunnelManageService,
    private funnelFlowManageService: FunnelFlowManageService,
    public n: NavigateService,
    private readonly onboardingService: OnboardingService,
  ) {
    super();
    this.expanded = this.funnelManageService.expandSummary.value;
  }

  ngOnInit(): void {
    this.listenFunnelUpdate();
    this.listenExpandSummary();
  }

  private listenFunnelUpdate() {
    const sub = this.funnelManageService.funnel$.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  private listenExpandSummary(): void {
    this.sub.add(this.funnelManageService.expandSummary.valueChanges.subscribe((expanded) => this.toggle(expanded)));
  }

  onDropdownChange() {
    setTimeout(() => {
      this.funnelFlowManageService.paintConnections();
    }, 600);
  }

  toggle(value?: boolean | Event): void {
    if (this.onboardingService.onboardingRunning) return;
    this.expanded = typeof value === 'boolean' ? value : !this.expanded;
    this.onDropdownChange();
    this.changes.detectChanges();
  }

  getRecomendedTactics(event: Event, step: Step) {
    event.stopPropagation();
    this.getRecomendedTacticsEvent.emit(step);
  }
}
