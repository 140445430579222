<df-funnel-manage-content-sub-header
  *ngIf="showHeader"
  [empty]="isEmpty && (loading$ | async) === false"
></df-funnel-manage-content-sub-header>
<df-funnel-manage-onboarding-banner
  *ngIf="onboardingService.onboardingRunning"
  class="onboarding-focus onboarding-shadow onboarding-action"
  (buttonClick)="handleOnboardingNextStep()"
  [@onboardingFadeAndSlide]="
    (onboardingService.loading$ | async) === false &&
    onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_DASHBOARD)
      ? 'in'
      : 'out'
  "
  [@onboardingHide]="onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_DASHBOARD) ? 'in' : 'out'"
></df-funnel-manage-onboarding-banner>
<df-funnel-manage-onboarding-flow-banner
  *ngIf="onboardingService.onboardingRunning"
  class="onboarding-focus onboarding-shadow onboarding-action"
  (buttonClick)="onboardingService.currentStep?.nextStep()"
  [@onboardingFadeAndSlide]="onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_FLOW_FINISHED) ? 'in' : 'out'"
  [@onboardingHide]="onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_FLOW_FINISHED) ? 'in' : 'out'"
></df-funnel-manage-onboarding-flow-banner>
<div
  class="w-full h-full overflow-y-auto overflow-x-hidden pt-6 duration-300"
  [ngClass]="{
    'pb-12': funnelFlowManageService.editMode,
    'pt-3': !onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_DASHBOARD)
  }"
>
  <swiper
    #swiperComponent
    cdkDropListGroup
    class="funnel__container"
    [config]="swiperFunnelConfig"
    [spaceBetween]="0"
    [ngClass]="{
      'funnel__container--empty': isEmpty && (loading$ | async) === false,
      'funnel__container--editMode': funnelFlowManageService.editMode,
      'onboarding-next':
        onboardingService.onboardingRunning && !onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_TACTIC_DROP),
      'onboarding-focus onboarding-active-events': onboardingService.onboardingRunning
    }"
  >
    <ng-template
      swiperSlide
      *ngFor="let step of funnelStepsService.steps"
    >
      <tui-island
        [size]="'s'"
        class="column flex flex-col items-start justify-start h-full ml-2 mr-2 {{ step.type }}-faded p-0"
        [ngClass]="{
          'column--empty': !funnelManageService.funnel?.tactics?.length && (loading$ | async) === false,
          'onboarding-accent-parent':
            onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_TACTIC_DROP) && isOnboardingDropStep(step),
          'onboarding-help-mark-anchor': onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_DASHBOARD)
        }"
      >
        <!-- funnel header -->
        <df-funnel-manage-content-step-header
          (getRecomendedTacticsEvent)="getRecomendedTactics($event)"
          [step]="step"
          [loading]="recommendatedStepLoading[step.type]"
        ></df-funnel-manage-content-step-header>

        <div class="w-full h-full flex-1 flex flex-col items-start justify-start px-3 relative">
          <df-funnel-tactic-onboarding-placeholder
            *ngIf="
              onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_TACTIC_DROP) && isOnboardingDropStep(step)
            "
            class="onboarding-temp-anchor onboarding-fade"
          ></df-funnel-tactic-onboarding-placeholder>
          <!-- tactics in step -->
          <div
            cdkDropList
            [cdkDropListData]="{ step: step }"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListEnterPredicate]="onboardingDropEnabled"
            [class.mt-1]="!isEmpty"
            [class.mb-3]="!isEmpty"
            class="w-full h-full flex-1"
          >
            <!-- tactic tile -->
            <df-funnel-manage-tactic
              [class.cannotEdit]="!(canEdit && (canManage$ | async))"
              [cdkDragDisabled]="!(canEdit && (canManage$ | async))"
              (cdkDragMoved)="onDrag($event)"
              (cdkDragEnded)="onDragEnd()"
              [cdkDragStartDelay]="dragDelay()"
              [attr.dataFunnelTacticId]="funnelTactic.id.toString()"
              (click)="onFunnelTacticClick(funnelTactic)"
              [ngClass]="{
                highlight:
                  funnelTactic.isHighlighted &&
                  !(
                    funnelTactic.onboardingActionTactic &&
                    onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_TACTIC_DROP)
                  ),
                toConnect: funnelTactic.isActiveToConnect,
                done: funnelTactic.isDone,
                'onboarding-accent pulse onboarding-action':
                  onboardingService.isCurrentStepType(EOnboardingStep.FUNNEL_TACTIC_DROP) &&
                  funnelTactic.onboardingActionTactic,
                activePreviewTactic: isActiveTacticPreview
              }"
              #singleFunnelManageTactic
              cdkDrag
              [cdkDragData]="{ funnelTactic: funnelTactic }"
              [funnelTactic]="funnelTactic"
              class="{{ step.type }}-border"
              [step]="step"
              (tacticPreviewOpenEmit)="getTacticPreviewOpen($event)"
              *ngFor="let funnelTactic of funnelManageService.sortedFunnelTactics(step)"
              [shouldRedirect]="shouldRedirect"
            >
            </df-funnel-manage-tactic>
          </div>
        </div>
      </tui-island>
    </ng-template>
  </swiper>

  <ng-container *ngIf="isEmpty && (loading$ | async) === false">
    <df-funnel-manage-content-empty-state class="mt-10 mb-8"></df-funnel-manage-content-empty-state>
    <df-info-bar
      *ngIf="showInfoBar"
      class="funnel__infobar"
      [illustration]="'/assets/img/funnel-infobar.png'"
      [title]="'Funnels.Manage.Your new Funnel' | translate"
      [subtitle]="'Funnels.Manage.Create your funnel with best pro tips' | translate"
      [buttonText]="'Funnels.Manage.Read insights' | translate"
      (onButtonClick)="openBlog()"
    >
    </df-info-bar>
  </ng-container>
</div>

<ng-template #shareDialogTemplate>
  <h3 class="mb-3 text-lg">
    {{ 'Funnels.Manage.Share this funnel' | translate }}
  </h3>
  <df-funnel-settings-share></df-funnel-settings-share>
</ng-template>
