import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { GenerateContentCostBadgeService } from '@modules/funnels/modules/funnel-manage/shared/components/generate-content-cost-badge/generate-content-cost-badge.service';
import { ContentGeneratorEventService } from '@modules/funnels/modules/funnel-manage/shared/services/content-generator-event.service';
import { ContentGeneratorEventEnum } from '@modules/funnels/shared/enums/content-generator-selection-type.enum';
import { GenerateContentMutation } from '@modules/funnels/shared/graphql/mutations/generate-content.mutation.generated';
import { MarkOutputDataAsSelectedMutation } from '@modules/funnels/shared/graphql/mutations/mark-output-data-as-selected.mutation.generated';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractContentGeneratorService } from '@shared/abstracts/content-generator.service.abstract';
import { ContentGenerationOutput, Integration, IntegrationOutputData } from '@shared/models/integration.model';
import { CreditsService } from '@shared/services/credits.service';
import { UserService } from '@shared/services/user.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContentGeneratorTemplatesFormService extends AbstractContentGeneratorService {
  private readonly _collection$: BehaviorSubject<IntegrationOutputData[]> = new BehaviorSubject<
    IntegrationOutputData[]
  >([]);
  readonly collection$: Observable<IntegrationOutputData[]> = this._collection$.asObservable();

  constructor(
    protected readonly s: SnackbarService,
    protected readonly t: TranslateService,
    private readonly funnelGraphqlService: FunnelGraphqlService,
    private readonly userService: UserService,
    protected readonly contentGeneratorEventService: ContentGeneratorEventService,
    private readonly creditsService: CreditsService,
    protected readonly costBadgeService: GenerateContentCostBadgeService,
  ) {
    super(s, t, contentGeneratorEventService, costBadgeService);
  }

  generateContent(integration?: Integration): void {
    if (!integration) return;
    if (!this.creditsService.canGenerateContent(integration.creditsPrice)) {
      this.creditsService.showNotEnoughCreditsPopup();
      return;
    }
    this._loading$.next(true);
    const payload = {
      integrationId: integration.id,
      inputsData: this.getInputsData(),
      funnelId: this.userService.User?.contextFunnel.id,
    };
    this.funnelGraphqlService
      .generateContent(payload)
      .pipe(
        map(
          (response: FetchResult<GenerateContentMutation>) => response.data?.generateContent as ContentGenerationOutput,
        ),
      )
      .subscribe({
        next: (data: ContentGenerationOutput) => this.handleGenerateContentSuccess(data),
        error: () => this.handleGenerateContentError(),
        complete: () => this.handleGenerateContentComplete(),
      });
  }

  private handleGenerateContentSuccess(data: ContentGenerationOutput): void {
    this._collection$.next(data.outputs);
    this.userService.updateCredits(data.userCredits);
    this.generateContentSuccess();
  }

  private handleGenerateContentComplete(): void {
    this._loading$.next(false);
  }

  private handleGenerateContentError(): void {
    this.generateContentError.bind(this);
  }

  handleUpdateContent(edited: IntegrationOutputData): void {
    const collection = this._collection$.getValue();
    const index = collection.findIndex((output) => output.id === edited.id);
    if (index >= 0) collection[index] = edited;
  }

  initForm(integration: Integration): void {
    this.initFormFields(integration);
    this.costBadgeService.setCreditsPrice(integration.creditsPrice);
  }

  public clearData(): void {
    this._collection$.next([]);
  }

  markOutputDataAsSelected(outputData: IntegrationOutputData): void {
    const requests: Observable<FetchResult<MarkOutputDataAsSelectedMutation>>[] = [];
    requests.push(this.funnelGraphqlService.markOutputDataAsSelected(!outputData.isSelected, outputData.id));

    const collectionState: IntegrationOutputData[] = this._collection$.getValue();
    const currentlySelected: IntegrationOutputData | undefined = collectionState?.find((output) => output.isSelected);

    if (!!currentlySelected && currentlySelected.id !== outputData.id)
      requests.push(this.funnelGraphqlService.markOutputDataAsSelected(false, currentlySelected.id));

    forkJoin(requests).subscribe({
      next: () => {
        outputData.isSelected = !outputData.isSelected;
        if (!!currentlySelected && currentlySelected.id !== outputData.id) currentlySelected.isSelected = false;
      },
      error: () => this.s.defaultError(),
      complete: () => {
        this.showSuccessMessage(outputData.isSelected);
        this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
      },
    });
  }

  private showSuccessMessage(isSelected: boolean): void {
    const message: string = 'Funnels.Content generator.Copy ' + (isSelected ? '' : 'un') + 'selected successfully!';
    this.s.success(this.t.instant(message));
  }

  removeOutput(outputData: IntegrationOutputData): void {
    this.removeFromLocalCollection(outputData.id);
    this.funnelGraphqlService.removeOutputData(outputData.id).toPromise();
    this.s.success(this.t.instant('Funnels.Content generator.Copy removed successfully.'));
    this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
  }

  private removeFromLocalCollection(outputDataId: number): void {
    const collection = this._collection$.getValue();
    if (!outputDataId || !collection || !collection.length) return;
    this.handleRemoveItemFromCollection(collection, outputDataId);
    this._collection$.next(collection);
  }

  private handleRemoveItemFromCollection(collection: IntegrationOutputData[], itemId: number): void {
    for (let index = 0; index < collection.length; index++) {
      if (collection[index].id === itemId) {
        collection.splice(index, 1);
        index--;
      }
    }
  }
}
