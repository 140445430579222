import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Step } from '@shared/models/step.model';
import { TuiDialogService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { Subscription } from 'rxjs';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-funnel-manage-content-step-header',
  templateUrl: './funnel-manage-content-step-header.component.html',
  styleUrls: ['./funnel-manage-content-step-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageContentStepHeaderComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  sub: Subscription = new Subscription();

  @Input() step!: Step;
  @Input() showDoneCount = true;

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private changes: ChangeDetectorRef,
    private t: TranslateService,
    public funnelManageService: FunnelManageService,
    public n: NavigateService,
  ) {}

  ngOnInit(): void {
    this.listenFunnelUpdate();
  }

  listenFunnelUpdate() {
    const sub = this.funnelManageService.funnel$.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  goToTacticList() {
    this.n.go('tactics/list');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
