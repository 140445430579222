import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StatementInput } from '../../models/statement.interface';

interface SliderDot {
  left: string;
  value: number;
}

export const DOT_WIDTH = 4.5;

@Component({
  selector: 'df-statement-values-slider-input',
  templateUrl: './statement-values-slider-input.component.html',
  styleUrls: ['./statement-values-slider-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementValuesSliderInputComponent implements OnInit {
  selectedValue: number | null = null;
  isSliderResolved = false;
  dots: SliderDot[] = [];

  @Input() form!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() statementInput: StatementInput | null = null;

  constructor(public changes: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.resolveInitValue();
    this.resolveDots();
  }

  setNewValue(value: number | null): void {
    this.form.get(this.controlName)?.patchValue(value, { emitEvent: false });
    this.selectedValue = value;
  }

  onValueChange(value: any): void {
    this.setNewValue(value.target.value);
  }

  onDotClickChangeValue(value: number): void {
    this.setNewValue(value);
  }

  private resolveInitValue(): void {
    if (this.hasValue(this.form.get(this.controlName)?.value)) {
      this.setNewValue(this.form.get(this.controlName)?.value);
    } else if (
      this.hasValue(this.statementInput?.minValue?.value) &&
      this.hasValue(this.statementInput?.maxValue?.value)
    ) {
      this.setNewValue(
        this.getMiddleValue(this.statementInput?.minValue?.value ?? 0, this.statementInput?.maxValue?.value ?? 0),
      );
    } else {
      this.setNewValue(this.statementInput?.minValue?.value ?? 0);
    }
    this.changes.detectChanges();
  }

  private hasValue(value: number | null | undefined): boolean {
    return value !== null && value !== undefined;
  }

  private getMiddleValue(minValue: number, maxValue: number): number {
    return Math.round((minValue + maxValue) / 2);
  }

  private resolveDots(): void {
    const startIndex: number = this.statementInput?.minValue?.value ?? 0;
    const lastIndex: number = this.statementInput?.maxValue?.value ?? 0;
    const stepValue: number = 100 / (lastIndex - startIndex);
    for (let index = startIndex; index <= lastIndex; index++) {
      this.dots.push({
        left: this.resolveDotPosition(index, lastIndex, stepValue),
        value: index,
      });
    }
    this.changes.detectChanges();
  }

  private resolveDotPosition(currentIndex: number, maxIndex: number, stepValue: number): string {
    const step: number = stepValue * currentIndex;
    if (currentIndex === 0) return step + '%';
    const correctionType: string = currentIndex < maxIndex / 2 ? '+' : '-';
    return 'calc(' + step + '% ' + correctionType + ' ' + DOT_WIDTH + 'px)';
  }
}
