import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { UserService } from '@shared/services/user.service';
import { Config } from '@shared/configs/config';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { StatementService } from '@modules/statement/shared/services/statement.service';

@Component({
  selector: 'df-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentStatusComponent implements OnInit {
  status: 'success' | 'error' | null = null;
  trial: string | null = null;
  orderMode = false;
  private _url: string | null = null;

  constructor(
    private n: NavigateService,
    private userService: UserService,
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef,
    public t: TranslateService,
    private s: SnackbarService,
    private statementService: StatementService,
  ) {}

  ngOnInit(): void {
    this.userService.clearUser();
    this.userService.getMe(true);
    this.status = this.route.snapshot.params['status'];
    this.trial = this.userService.getSelectedTrial();
    this._url = this.userService.getBeforePaymentUrl();
    this.orderMode = !!this._url?.includes('order');
    this.changes.detectChanges();
  }

  goToApp() {
    const user = this.userService.User;
    let url = this._url;
    if (url) {
      if (url.includes('public/pricing') || url === '/' || url.includes('select-plan')) {
        this.statementService.openStatementOnboarding = true;
        this.n.go(Config.MAIN_VIEW)?.then(() => this.clearStorage());
      } else {
        if (url.includes('preview')) {
          url = url.replace('preview', 'manage');
        }
        this.n.router.navigateByUrl(url).then(() => {
          const hiddenUrl = this.userService.getHiddenBeforePaymentUrl();
          if (hiddenUrl) {
            this.n.router.navigate(['', { outlets: { statement: hiddenUrl } }], { skipLocationChange: true });
          }
          this.clearStorage();
        });
      }
    } else {
      this.n.go('funnels/f/d/:id', { id: user!.contextFunnel.id });
    }
  }

  private clearStorage(): void {
    this.userService.clearBeforePaymentUrl();
  }
}
