import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { ActivatedRoute } from '@angular/router';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { AbstractFunnelSubpageComponent } from '@shared/abstracts/funnel-subpage.component.abstract';

@Component({
  selector: 'df-funnel-statement',
  templateUrl: './funnel-statement.component.html',
  styleUrls: ['./funnel-statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelStatementComponent extends AbstractFunnelSubpageComponent implements OnInit, OnDestroy {
  @ViewChild('statementContainer') statementContainer!: ElementRef;

  constructor(
    private readonly statementService: StatementService,
    private readonly changes: ChangeDetectorRef,
    protected readonly route: ActivatedRoute,
    protected readonly funnelManageService: FunnelManageService,
  ) {
    super(route, funnelManageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.statementService.funnelMode = true;
    this.changes.detectChanges();
    this.listingForScrollTop();
  }

  private listingForScrollTop(): void {
    this.sub.add(this.statementService.statementContainerScrollTop$.subscribe(() => this.scrollContainerToTop()));
  }

  private scrollContainerToTop(): void {
    setTimeout(() => {
      if (this.statementContainer) this.statementContainer.nativeElement.scrollTop = 0;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.statementService.funnelMode = false;
  }
}
