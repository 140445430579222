import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tactic } from '@shared/models/tactic.model';

@Component({
  selector: 'df-tactic-verification-banner',
  templateUrl: './tactic-verification-banner.component.html',
  styleUrls: ['./tactic-verification-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticVerificationBannerComponent {
  @Input() tactic: Tactic | undefined | null;
  @Input() vertical = false;
}
