import { XPartnerService } from './../../../../shared/services/x-partner.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Config } from '@shared/configs/config';
import { ActivatedRoute } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { FetchResult } from '@apollo/client/core';
import { ConfirmEmailMutation } from '@modules/authorization/shared/graphql/mutations/confirm-email.mutation.generated';
import { AuthController } from '@modules/authorization/shared/controllers/auth.controller';
import { AuthOutputGraphql } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmEmailComponent implements OnInit {
  Config = Config;

  confirmed: boolean | null = null;
  loading = false;
  authController: AuthController = new AuthController();

  constructor(
    private route: ActivatedRoute,
    private n: NavigateService,
    private authGraphqlService: AuthGraphqlService,
    private changes: ChangeDetectorRef,
    public xPartnerService: XPartnerService,
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.params['token']) {
      this.confirmEmail(this.route.snapshot.params['token']);
    }
  }

  login() {
    this.n.go('sign-in');
  }

  confirmEmail(token: string) {
    this.loading = true;
    this.authGraphqlService
      .confirmEmail(token)
      .subscribe({
        next: (res: FetchResult<ConfirmEmailMutation>) => {
          this.confirmed = true;
          this.authController.saveAuthData(res.data?.confirmEmail as AuthOutputGraphql);
          setTimeout(() => {
            this.login();
          }, 3500);
        },
        error: () => {
          this.confirmed = false;
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }
}
