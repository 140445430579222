<div
  class="h-14 py-2 px-4 md:px-6 lg:px-8 tui-base-01-bg flex justify-between items-center border-b border-solid tui-base-04-border absolute w-full"
>
  <h1 class="tui-text_body-m-1">
    {{ 'Funnels.Content generator.Generate your content' | translate }}
  </h1>
  <div class="flex justify-end items-center">
    <button
      size="m"
      tuiIconButton
      type="button"
      appearance="outline"
      (click)="close()"
      [icon]="closeIcon"
    >
      <ng-template #closeIcon>
        <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
      </ng-template>
    </button>
  </div>
</div>
<div class="tui-base-02 h-screen w-full overflow-hidden pt-14">
  <div
    class="cg-container w-full h-full flex flex-nowrap justify-start items-stretch overflow-x-auto onboarding-active-events onboarding-accent-parent"
  >
    <div class="cg-container__form tui-base-01-bg flex flex-col flex-nowrap justify-start items-stretch">
      <div
        class="border-b border-solid tui-base-04-border px-5 py-3 flex justify-between items-center"
        *ngIf="activity"
      >
        <div class="flex justify-start items-center">
          <span
            class="mr-3 w-8 h-8 rounded-lg tui-text-05 flex items-center justify-center tui-text_body-m-3 flex-shrink-0 border border-solid tui-base-04-border font-medium"
          >
            {{ (funnelContentGeneratorService.selectedTactic?.tactic?.activities?.indexOf(activity) ?? 0) + 1 }}
          </span>
          <div>
            <p class="uppercase font-bold tui-text-04 tui-text-body-xs">
              {{ 'activity' | translate }}&nbsp;{{
                (funnelContentGeneratorService.selectedTactic?.tactic?.activities?.indexOf(activity) ?? 0) + 1
              }}
            </p>
            <h5 class="text-sm font-medium">{{ activity.name }}</h5>
          </div>
        </div>
      </div>
      <div class="flex-grow overflow-y-auto">
        <df-content-generator-form
          *ngIf="integration"
          [integration]="integration"
          [form]="funnelContentGeneratorService.form"
          [loading]="!!(funnelContentGeneratorService.loading$ | async)"
          [generateButtonLabel]="'Funnels.Content generator.Generate text' | translate"
          (formSubmitted)="handleDataSubmitted()"
        ></df-content-generator-form>
      </div>
    </div>
    <div class="cg-container__results relative">
      <div
        class="absolute w-full h-16 px-4 lg:px-8 flex justify-between items-center"
        *ngIf="asset"
      >
        <df-content-generator-asset-short [asset]="asset"></df-content-generator-asset-short>
      </div>
      <div class="p-4 pt-16 lg:px-8 lg:pb-8 h-full">
        <div class="border border-solid tui-base-04-border rounded-2xl h-full tui-base-01-bg relative">
          <df-full-list-loader *ngIf="funnelContentGeneratorService.loading$ | async"></df-full-list-loader>
          <ng-template #outputPlaceholder>
            <div class="w-full h-full flex flex-col justify-center items-center">
              <img
                src="assets/img/illustration-books.svg"
                alt="folder"
              />
              <p class="tui-text_body-m-1 tui-text-03 font-normal mt-12">
                {{ 'Funnels.Content generator.Your generated content will appear here' | translate }}
              </p>
            </div>
          </ng-template>
          <div
            *ngIf="asset?.integrationOutputs?.length; else outputPlaceholder"
            class="w-full h-full flex flex-col flex-nowrap justify-start items-stretch"
          >
            <div class="border-b border-solid tui-base-04-border p-4 font-medium text-sm">
              {{ 'Funnels.Content generator.Result' | translate }}
            </div>
            <div class="h-full flex flex-col gap-y-4 flex-grow py-5 px-4 overflow-y-auto">
              <df-content-generator-item-tile
                *ngFor="let item of asset?.integrationOutputs; let first = first"
                [data]="item"
                [asset]="asset"
                (selectData)="handleDataSelected(item)"
              ></df-content-generator-item-tile>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
