import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TUI_ARROW } from '@taiga-ui/kit';
import { User } from '@shared/models/user.model';
import {
  EPermissionResourceStatus,
  EResourceTeamStatus,
  ResourceTeam,
} from '@modules/funnels/modules/funnel-settings/shared/models/resource-team.model';
import { UserService } from '@shared/services/user.service';
import { TeamMember } from '@shared/models/team.model';
import { UntypedFormControl } from '@angular/forms';
import { FunnelPermissionEnum, PermissionType } from '@modules/graphql/graphql-types';
import { Config } from '@shared/configs/config';
import { ResourceTeamsService } from '@shared/modules/resource-teams/services/resource-teams.service';
import { ResourceTeamsSinglePermissionComponent } from '@shared/modules/resource-teams/components/resource-teams-single-permission/resource-teams-single-permission.component';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';

@Component({
  selector: 'df-resource-teams-single-team',
  templateUrl: './resource-teams-single-team.component.html',
  styleUrls: ['./resource-teams-single-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceTeamsSingleTeamComponent extends AbstractSubscriptionComponent implements OnInit {
  FunnelPermissionEnum = FunnelPermissionEnum;
  EResourceTeamStatus = EResourceTeamStatus;
  arrow = TUI_ARROW;
  Config = Config;
  currentUser: User;

  private _resourceTeam!: ResourceTeam;
  hostedOpen = false;
  ownerName = '';
  loading = false;

  @Input() set resourceTeam(resourceTeam: ResourceTeam) {
    this._resourceTeam = resourceTeam;
    const owner = this.resourceTeam.team.getOwner(this.currentUser);
    this.ownerName = owner?.user ? owner.user.fullName : owner?.email || '';
  }

  @ViewChildren('permissionResourceComponents')
  permissionResourceComponents!: QueryList<ResourceTeamsSinglePermissionComponent>;

  get resourceTeam(): ResourceTeam {
    return this._resourceTeam;
  }

  constructor(
    private userService: UserService,
    public resourceTeamsService: ResourceTeamsService,
    private changes: ChangeDetectorRef,
    private pricingService: PricingService,
  ) {
    super();
    this.currentUser = this.userService.User!;
  }

  ngOnInit(): void {
    this.listenLoading();
  }

  private listenLoading() {
    this.sub.add(
      this.resourceTeamsService.loading$.subscribe((val) => {
        this.loading = val;
        this.resourceTeam.setAddedAsPermission();
        this.changes.detectChanges();
      }),
    );
  }

  removeResourceTeam() {
    this.pricingService.checkPricing(PermissionType.TeamCollaboration).subscribe(() => {
      if (this.resourceTeam.status === EResourceTeamStatus.TO_CREATE) {
        this.resourceTeamsService.removeResourceTeam(this.resourceTeam);
      } else {
        this.resourceTeam.status = EResourceTeamStatus.TO_REMOVE;
        this.resourceTeam.permissions.map((permission) => {
          permission.status = EPermissionResourceStatus.TO_REMOVE_RESOURCE_TEAM;
          return permission;
        });
        this.refreshPermissionResourceComponents();
      }

      this.resourceTeamsService._resourceChanges$.next(true);
      this.changes.detectChanges();
    });
  }

  restoreResourceTeam() {
    this.resourceTeam.status = EResourceTeamStatus.ADDED;
    this.resourceTeam.permissions.map((permission) => {
      permission.status = EPermissionResourceStatus.ADDED;
      return permission;
    });
    this.refreshPermissionResourceComponents();
    this.changes.detectChanges();
  }

  private refreshPermissionResourceComponents() {
    this.permissionResourceComponents.map((p: ResourceTeamsSinglePermissionComponent) => {
      p.changes.detectChanges();
    });
  }

  addAllMembers(): void {
    this.pricingService.checkPricing(PermissionType.TeamCollaboration).subscribe(() => {
      this.resourceTeam.team.members
        .filter((m) => !m.addedAsPermission && m.user?.id !== this.currentUser.id)
        .map((m: TeamMember) => {
          this.addMember(m);
        });
      this.hostedOpen = false;
    });
  }

  addMember(member: TeamMember): void {
    if (member.user?.id === this.currentUser.id || member.addedAsPermission) return;

    this.pricingService.checkPricing(PermissionType.TeamCollaboration).subscribe(() => {
      this.resourceTeam.permissions.push({
        teamMember: member,
        status: EPermissionResourceStatus.TO_INVITE,
        permission: FunnelPermissionEnum.ViewOnly,
        id: member.id,
        permissionControl: new UntypedFormControl(FunnelPermissionEnum.ViewOnly),
      });
      this.resourceTeam.setAddedAsPermission();
      this.resourceTeamsService._resourceChanges$.next(true);
      this.changes.detectChanges();
    });
  }
}
