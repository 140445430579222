<div
  *ngIf="form"
  [formGroup]="form"
  [validate-form]="form"
>
  <df-business-data-form-header [type]="type"></df-business-data-form-header>
  <div *ngIf="form">
    <div *ngIf="getControl() as control">
      <tui-input-files
        [accept]="TUI_FILES_UPLOAD_FILES_EXTENTION"
        [formControl]="control"
        [multiple]="true"
        [maxFileSize]="maxFileSize"
        (ngModelChange)="control.markAsTouched()"
        (reject)="onReject($event)"
      ></tui-input-files>

      <tui-files
        data-test="files-upload-input"
        class="tui-space_top-1"
      >
        <tui-file
          *ngFor="let file of control.valueChanges | async"
          [file]="file"
          [showDelete]="control.enabled"
          (removed)="removeFile(file)"
        ></tui-file>

        <tui-file
          *ngFor="let file of rejectedFiles"
          state="error"
          [file]="file"
          [showDelete]="control.enabled"
          (removed)="clearRejected(file)"
        ></tui-file>
      </tui-files>
    </div>
  </div>
</div>
