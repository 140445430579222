import { Injectable } from '@angular/core';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { catchError, map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { UpdateFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/update-funnel-tactic.mutation.generated';
import { SnackbarService } from '@core/services/snackbar.service';

@Injectable()
export class FunnelManageTacticNameService {
  readonly form: UntypedFormGroup = new UntypedFormGroup({
    funnelTacticId: new UntypedFormControl(null, [Validators.required]),
    name: new UntypedFormControl(null, [Validators.maxLength(200)]),
  });

  constructor(
    private readonly tacticGraphqlService: TacticGraphqlService,
    private readonly s: SnackbarService,
  ) {}

  updateName(): Observable<FunnelTactic> {
    return this.tacticGraphqlService.updateFunnelTactic(this._getFormData()).pipe(
      map((res: FetchResult<UpdateFunnelTacticMutation>) => res.data?.updateFunnelTactic as FunnelTactic),
      catchError((err) => {
        this.s.defaultError();
        return throwError(err);
      }),
    );
  }

  private _getFormData() {
    const value = this.form.getRawValue();
    value['name'] = value['name']?.length ? value['name'] : null;
    return value;
  }
}
