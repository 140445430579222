import { Component, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ActionTypeEnum } from '@modules/business-data/business-data.model';
import { Regex } from '@shared/configs/regex';

@Component({
  selector: 'df-youtube-content',
  templateUrl: './youtube-content.component.html',
  styleUrl: './youtube-content.component.scss',
})
export class YoutubeContentComponent {
  type = ActionTypeEnum.YOUTUBE;
  @Input() form!: FormGroup;

  getFormArray() {
    return this.form.get(this.type) as FormArray;
  }

  addNewField() {
    this.getFormArray().push(new FormControl('', Validators.pattern(Regex.url)));
  }

  removeField(index: number) {
    this.getFormArray().removeAt(index);
  }
}
