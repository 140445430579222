import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  ViewChild,
  OnInit,
} from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { FunnelPanelManageContentComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/funnel-panel-manage-content.component';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { TuiIslandComponent } from '@taiga-ui/kit';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { FunnelDataModalComponent } from '@modules/funnels/shared/components/funnel-data-modal/funnel-data-modal.component';
import { TuiDialogService } from '@taiga-ui/core';
import { UserService } from '@shared/services/user.service';
import { FunnelTab } from '@modules/funnels/shared/enums/funnel-tab.enum';
import { Funnel } from '@shared/models/funnel.model';
import { AbstractFunnelSubpageComponent } from '@shared/abstracts/funnel-subpage.component.abstract';

@Component({
  selector: 'df-funnel-manage',
  templateUrl: './funnel-manage.component.html',
  styleUrls: ['./funnel-manage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageComponent extends AbstractFunnelSubpageComponent implements OnInit {
  readonly loading$: Observable<boolean>;
  islandVisible = true;
  edited = false;
  @ViewChild('funnelManageContentComponent')
  funnelManageContentComponent!: FunnelPanelManageContentComponent;
  @ViewChild('island') island!: TuiIslandComponent;

  constructor(
    private n: NavigateService,
    protected route: ActivatedRoute,
    public funnelManageService: FunnelManageService,
    public funnelFlowManageService: FunnelFlowManageService,
    private changes: ChangeDetectorRef,
    private t: TranslateService,
    private router: Router,
    public userService: UserService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(route, funnelManageService);
    this.loading$ = this.funnelManageService.loading$;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listenChangeFunnel();
    this.listenEditModeChange();
    this.listenLoadingFlow();
  }

  listenLoadingFlow() {
    this.sub.add(this.funnelFlowManageService.loading$.subscribe(() => this.changes.detectChanges()));
  }

  listenEditModeChange() {
    this.sub.add(this.funnelFlowManageService.editMode$.subscribe(() => this.changes.detectChanges()));
  }

  listenChangeFunnel() {
    this.sub.add(
      combineLatest([this.funnelManageService.funnel$, this.funnelManageService.currentTab$]).subscribe(
        ([funnel, tab]) => {
          if (tab === FunnelTab.MANAGE && !!funnel?.id) {
            if (this.route.snapshot.queryParams['toEdit'] && !this.edited) {
              this.edited = true;
              this.edit(funnel);
            }
          }
          this.changes.detectChanges();
          this.funnelManageContentComponent.changes.detectChanges();
        },
      ),
    );
  }

  edit(funnel: Funnel) {
    this.dialogService
      .open<number>(new PolymorpheusComponent(FunnelDataModalComponent, this.injector), {
        size: 'm',
        dismissible: true,
        closeable: true,
        data: {
          funnel,
        },
        label: this.t.instant('Funnels.Manage.settings'),
      })
      .subscribe()
      .add(() => {
        this.router.navigate([], {
          queryParams: {
            toEdit: undefined,
          },
          queryParamsHandling: 'merge',
        });
      });
  }
}
