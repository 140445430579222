<div class="auth-panel flex justify-center items-center min-h-screen flex-col pt-10 pb-10">
  <img
    src="/assets/img/logo-with-appsumo.svg"
    alt="{{ Config.SYSTEM_NAME }}"
  />

  <h1 class="text-4xl font-semibold tui-text-03 mt-8 lg:mt-14 mb-6 leading-normal text-center">
    <ng-container *ngIf="errorType">
      {{ 'Auth.AppSumo.' + errorType + ' title' | translate }}
    </ng-container>
    <ng-container *ngIf="!errorType">{{ 'Auth.AppSumo.something went wrong' | translate }}</ng-container>
  </h1>
  <p
    class="text-center w-1/2 tui-text-02"
    [innerHTML]="
      !errorType
        ? ('Auth.AppSumo.error description' | translate)
        : ('Auth.AppSumo.' + errorType + ' description' | translate: { email: userService.User?.email })
    "
  ></p>
  <ng-container *ngIf="errorType === AppSumoErrorEnum.ALREADY_LOGGED_IN">
    <div class="tui-form__row mt-10">
      <button
        (click)="logoutAndTryAgain()"
        size="xl"
        tuiButton
        class="w-full block"
        appearance="primary"
      >
        {{ 'Auth.AppSumo.try again' | translate }}
      </button>
    </div>
  </ng-container>
</div>
