import { finalize } from 'rxjs/operators';
import { GraphqlErrorEnum } from '@modules/graphql/enums/graphql-error.enum';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { ApolloError } from '@apollo/client/core';
import { UserProfileInputGraphql } from './../../../../graphql/graphql-types';
import { map } from 'rxjs/operators';
import { User } from '@shared/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TuiDialog } from '@taiga-ui/cdk';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { UserService } from '@shared/services/user.service';
@Component({
  selector: 'df-dialog-public-profile',
  templateUrl: './dialog-public-profile.component.html',
  styleUrls: ['./dialog-public-profile.component.scss'],
})
export class DialogPublicProfileComponent {
  loading = false;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<any, boolean>,
    private userService: UserService,
    private s: SnackbarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
  ) {}

  makeUserProfilePublic() {
    const userData: UserProfileInputGraphql = {
      firstName: this.userService.User?.firstName,
      email: this.userService.User?.email || '',
      isPublic: true,
    };
    this.loading = true;
    this.userService
      .editUser(userData)
      .pipe(
        map((res) => {
          if (res.data?.editProfile) {
            this.userService.User = res.data?.editProfile as User;
          }
          return res;
        }),
        finalize(() => {
          this.loading = false;
          this.changes.detectChanges();
        }),
      )
      .subscribe({
        next: () => {
          this.s.success(this.t.instant('Your profile is public now. You can have public tactics'));
          this.loading = false;
          this.changes.detectChanges();
          this.context.completeWith(true);
        },
        error: this.errorSaveProfile.bind(this),
      });
  }

  errorSaveProfile(error: ApolloError) {
    if (error.graphQLErrors?.length) {
      switch ((error.graphQLErrors[0] as CustomGraphqlErrorInterface).errorCode) {
        case GraphqlErrorEnum.ALREADY_EXISTS:
          this.s.error(this.t.instant('UserSettings.User with provided e-mail, already exists in platform'));
          break;
      }
    } else {
      this.s.error(this.t.instant('UserSettings.There is problem with saving data for your account. Try again.'));
    }
    this.context.completeWith(false);
  }

  close(): void {
    this.context.completeWith();
  }
}
