<ng-container *ngIf="tacticPreviewService.tactic as tactic">
  <div
    class="tactic-preview-activities__header h-16 w-full flex justify-between items-center p-6 pt-8 pb-8 border-solid border-gray-200"
    [class.border-b]="!paidCurtain"
  >
    <h5 class="tui-text_body-m-1 flex flex-row items-center">
      <svg-icon
        [src]="'/assets/img/ic-prev.svg'"
        class="inline-flex lg:hidden mr-2 -ml-2"
        (click)="closeMobile()"
      ></svg-icon>
      <span *ngIf="!paidCurtain">{{ 'Tactics.Preview.Tactic steps' | translate }}</span>
    </h5>
    <div
      *ngIf="!paidCurtain && tactic.activities?.length"
      class="tui-text_body-m-8"
    >
      {{ 'Tactics.Preview.expand all' | translate }}
      <tui-toggle
        #toggleComponent
        [(ngModel)]="toggle"
        (change)="onExpandChange($event)"
        class="ml-4"
        size="l"
      >
      </tui-toggle>
    </div>
  </div>
  <div class="tactic-preview-activities__list flex flex-col items-start justify-start pb-8 pt-2 px-6">
    <div class="relative w-full">
      <div
        class="flex flex-col items-center justify-start text-center"
        *ngIf="paidCurtain; else activities"
      >
        <div class="rounded-xl tui-accent-13-bg uppercase font-bold text-xs p-2.5 tui-primary md:mt-6 lg:mt-8 xl:mt-12">
          {{ 'Tactics.Preview.What’s inside?' | translate }}
        </div>
        <h3 class="tui-text_h2 my-2 font-semibold">
          {{ 'Tactics.Preview.Tactic features' | translate }}
        </h3>
        <p class="tui-text-02 tui-text_body-m max-w-md">
          {{ tactic.copyForSale }}
        </p>
        <div
          class="grid grid-cols-1 sm:grid-cols-3 grid-rows-3 sm:grid-rows-1 mt-6 gap-y-4 gap-x-3 text-center w-full max-w-2xl"
        >
          <tui-island
            class="text-center px-3 sm:py-7"
            size="s"
          >
            <div class="h-full flex sm:flex-col sm:justify-center items-center">
              <div class="tui-accent-13-bg h-20 w-20 flex flex-col justify-center items-center rounded-full">
                <svg-icon
                  src="assets/img/fi_check-circle-primary.svg"
                  [svgStyle]="{ width: '2.5rem', height: '2.5rem' }"
                ></svg-icon>
              </div>
              <span class="tui-text_body-m-1 ml-4 sm:mt-5 sm:ml-0">{{
                (tactic.activitiesCount > 1 ? 'Tactics.Preview.:number Activities' : 'Tactics.Preview.:number Activity')
                  | translate: { number: tactic.activitiesCount }
              }}</span>
            </div>
          </tui-island>
          <tui-island
            class="text-center px-3 sm:py-7"
            size="s"
          >
            <div class="h-full flex sm:flex-col sm:justify-center items-center">
              <div class="tui-accent-13-bg h-20 w-20 flex flex-col justify-center items-center rounded-full">
                <svg-icon
                  src="assets/img/ic_fire.svg"
                  [svgStyle]="{ width: '2.5rem', height: '2.5rem' }"
                ></svg-icon>
              </div>
              <span class="tui-text_body-m-1 ml-4 sm:mt-5 sm:ml-0">{{
                (tactic.benchmarksCount > 1
                  ? 'Tactics.Preview.:number Benchmarks'
                  : 'Tactics.Preview.:number Benchmark'
                ) | translate: { number: tactic.benchmarksCount }
              }}</span>
            </div>
          </tui-island>
          <tui-island
            class="text-center px-3 sm:py-7"
            size="s"
          >
            <div class="h-full flex sm:flex-col sm:justify-center items-center">
              <div class="tui-accent-13-bg h-20 w-20 flex flex-col justify-center items-center rounded-full">
                <svg-icon
                  src="assets/img/ic_attach.svg"
                  [svgStyle]="{ width: '2.5rem', height: '2.5rem' }"
                ></svg-icon>
              </div>
              <span class="tui-text_body-m-1 ml-4 sm:mt-5 sm:ml-0">{{
                (tactic.assetsCount > 1 ? 'Tactics.Preview.:number Assets' : 'Tactics.Preview.:number Asset')
                  | translate: { number: tactic.assetsCount }
              }}</span>
            </div>
          </tui-island>
        </div>
        <button
          tuiButton
          size="xl"
          appearance="primary"
          class="mt-10 mb-20"
          (click)="buyTactic.emit(true)"
        >
          <span class="font-semibold"
            >{{ 'Tactics.Preview.Buy tactic' | translate }}&nbsp;{{
              tactic.price! | price | currency: tactic.currency?.toUpperCase() : 'symbol' : '0.2-2'
            }}</span
          >
        </button>
      </div>
      <ng-template #activities>
        <df-tactic-accordion-activity
          *ngFor="let activity of tactic.activities; let i = index"
          [index]="i + 1"
          [steps]="tactic.funnelSteps"
          [activity]="activity"
          (openAccordionChange)="onOpenAccordionChange()"
          #accordionActivityComponents
        ></df-tactic-accordion-activity>
        <h3
          class="w-full tui-text_h5 text-center mt-10"
          *ngIf="!tactic.activities?.length && !tacticPreviewService.loading"
        >
          {{ 'Tactics.Preview.No activities' | translate }}
        </h3>
      </ng-template>
    </div>
  </div>
</ng-container>
