<div class="flex justify-center flex-col items-center">
  <h3 class="text-[20px] font-bold my-4">Login As User</h3>

  <form
    class="w-full px-4"
    [formGroup]="form"
    [validate-form]="form"
    (submit)="onSubmit()"
  >
    <tui-input formControlName="email">Email</tui-input>
    <df-error-input [control]="getControl()"></df-error-input>
    <button
      class="mt-4"
      tuiButton
    >
      Login
    </button>
  </form>
</div>
