import { gql } from 'apollo-angular';

export const GetCustomPromptOutputDocument = gql`
  query getCustomPromptOutput($customPromptOutputId: Int!) {
    getCustomPromptOutput(customPromptOutputId: $customPromptOutputId) {
      id
      customPromptId
      funnelTacticId
      input
      value
    }
  }
`;
