<div class="grid grid-cols-1 gap-3">
  <asl-google-signin-button
    #googleBtn
    type="standard"
    size="large"
    [width]="200"
    theme="filled_blue"
  ></asl-google-signin-button>

  <ng-template #icon_google>
    <svg-icon [src]="'/assets/img/ic_google.svg'"></svg-icon>
  </ng-template>
  <button
    size="m"
    class="facebook-btn"
    tuiButton
    type="button"
    appearance="outline"
    [icon]="icon_facebook"
    (click)="signInWithFacebook.emit(true)"
  >
    {{ 'Auth.Login.Facebook' | translate }}
  </button>
  <ng-template #icon_facebook>
    <svg-icon
      [src]="'/assets/img/ic_facebook.svg'"
      class="tui-primary"
    ></svg-icon>
  </ng-template>
</div>
