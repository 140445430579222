import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const CreateUserAvatarDocument = gql`
  mutation createUserAvatar($file: Upload!) {
    createUserAvatar(file: $file) {
      ...UserStorage
    }
  }
  ${UserStorageFragment}
`;
