import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StatementInput, StatementInputOption } from '../../models/statement.interface';

@Component({
  selector: 'df-statement-options-slider-input',
  templateUrl: './statement-options-slider-input.component.html',
  styleUrls: ['./statement-options-slider-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementOptionsSliderInputComponent implements OnInit {
  selectedValue = 0;
  isSliderResolved = false;

  @Input() form!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() statementInput: StatementInput | null = null;

  ngOnInit(): void {
    this.resolveInitValue();
  }

  setNewValue(statementInputOption: StatementInputOption | null): void {
    this.form.get(this.controlName)?.patchValue(statementInputOption ? [statementInputOption] : [], {
      emitEvent: false,
    });
    this.selectedValue = statementInputOption?.id ?? 0;
  }

  onValueChange(value: any): void {
    this.setNewValue(this.resolveSelectedOption(value.target.value));
  }

  private resolveInitValue(): void {
    if (this.form.get(this.controlName)?.value && this.form.get(this.controlName)?.value.length)
      this.setNewValue(this.form.get(this.controlName)?.value[0]);
    else {
      this.setNewValue(this.resolveSelectedOption(this.getMiddleIndex(this.statementInput?.options)));
    }
  }

  private resolveSelectedOption(optionId: number): StatementInputOption | null {
    if (!this.statementInput) return null;
    for (let index = 0; index < this.statementInput.options.length; index++) {
      if (this.statementInput.options[index].id == optionId) return this.statementInput.options[index];
    }
    return null;
  }

  private getMiddleIndex(options: StatementInputOption[] = []): number {
    if (!options || !options.length) return 0;
    return Math.round(options[options.length - 1].id / 2);
  }
}
