import { PermissionOutputGraphql, PermissionType, PlanPermissionOutputGraphql } from '@modules/graphql/graphql-types';

export class Permission implements PermissionOutputGraphql {
  id!: number;
  type!: PermissionType;
  name!: string;
  isAvailable!: boolean;
  isAvailableInAppSumo!: boolean;
  planPermissions: { [key: number]: PlanPermissionOutputGraphql }[] = [];
  plansCount!: number;

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
  }
}
