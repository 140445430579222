<df-full-list-loader *ngIf="loading || (statementService.loadingRecommendations$ | async)"></df-full-list-loader>
<div
  data-test="tactics"
  class="w-full h-full overflow-y-auto py-5 px-4 md:px-6 lg:px-7.5 tui-base-02"
>
  <h3 class="text-base md:text-xl font-semibold tui-text-03 mb-4 mt-0">
    {{ 'Funnels.recommended tactics' | translate }}
  </h3>

  <df-funnel-recommendations-empty
    [access]="recommendationsAccess"
    *ngIf="
      (!statementService.recommendations || statementService.recommendations?.length === 0) &&
      (statementService.loadingRecommendations$ | async) === false &&
      !statementService.statement
    "
  ></df-funnel-recommendations-empty>

  <div
    class="no-data-wrapper"
    *ngIf="
      (!statementService.recommendations || statementService.recommendations?.length === 0) &&
      (statementService.loadingRecommendations$ | async) === false &&
      statementService.statement
    "
  >
    <span class="tui-text_body-xl">
      {{ 'Funnels.No Data Available' | translate }}
    </span>
  </div>

  <df-funnel-recommendations-section
    *ngIf="recommendationsAccess && statementService.recommendations && statementService.recommendations.length > 0"
    [title]="'Funnels.Recommendations.Statement' | translate"
    [recommendations]="statementService.recommendations"
    (addSelectedToFunnel)="addRecommendationsToFunnel($event)"
  ></df-funnel-recommendations-section>
</div>
<df-onboarding-video [src]="'https://www.youtube.com/embed/ymWPQxox59A?si=zqomT7z4Ge8hIZ84'"></df-onboarding-video>
