import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { rejectedImages } from '@shared/helpers/file-input.helper';
import file2Base64 from '@shared/helpers/file-to-base64.helper';
import { TuiFileLike } from '@taiga-ui/kit';

import { Observable, Subject, of, timer } from 'rxjs';
import { switchMap, finalize, map } from 'rxjs/operators';

@Component({
  selector: 'df-input-file',
  styleUrls: ['./input-file.component.scss'],
  templateUrl: './input-file.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DfInputFileComponent {
  @Input() form!: FormGroup;
  @Input() formName!: string;
  @Input() maxFileSize!: number;
  @Input() link!: string;
  @Input() accept = Config.ACCEPTED_IMAGES_EXTENSIONS;
  @Input() multiple = false;
  @Input() type: 'legacy' | 'new' = 'legacy';

  @Output() ngModelChange = new EventEmitter<File | null>();
  @Output() removed = new EventEmitter<void>();

  @ViewChild('preview') preview!: HTMLImageElement;

  rejectedFiles$!: Subject<TuiFileLike | null>;
  loadingFiles$!: Subject<TuiFileLike | null>;
  loadedFiles$!: Observable<TuiFileLike | null>;

  parentForm!: FormGroup;
  file!: string | null;
  fileList: File[] = [];

  constructor(
    private s: SnackbarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.rejectedFiles$ = new Subject<TuiFileLike | null>();
    this.loadingFiles$ = new Subject<TuiFileLike | null>();
    this.loadedFiles$ =
      this.formControl?.valueChanges?.pipe(switchMap((file) => (file ? this.makeRequest(file) : of(null)))) ?? of(null);

    this.form.get(this.formName)?.valueChanges.subscribe(async (file: File) => {
      if (!file) return;
      this.file = await file2Base64(file);
      this.changes.detectChanges();
    });
  }

  get formControl(): FormControl | null {
    if (typeof this.formName === 'number') this.formName = (this.formName as number).toString();
    return this.form.get(this.formName) as FormControl;
  }

  rejectedImages(e: TuiFileLike | readonly TuiFileLike[]) {
    rejectedImages(e, this.s, this.t);
    this.removed.emit();
  }

  removeFile(): void {
    this.formControl?.setValue(null);
    this.file = null;
    this.removed.emit();
  }

  replaceFile() {
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    input.click();
  }

  clearRejected(): void {
    this.removeFile();
    this.rejectedFiles$.next(null);
  }

  makeRequest(file: TuiFileLike): Observable<TuiFileLike | null> {
    this.loadingFiles$.next(file);
    this.ngModelChange.emit(file as File);

    return timer(1000).pipe(
      map(() => {
        return file;
      }),
      finalize(() => this.loadingFiles$.next(null)),
    );
  }
}
