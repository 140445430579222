<div class="w-full h-full tui-base-02 relative statement-background">
  <div
    class="w-full h-full overflow-y-auto px-4 md:px-6 lg:px-8 xl:px-10"
    #statementContainer
  >
    <div class="w-full h-full mx-auto">
      <router-outlet></router-outlet>
      <df-onboarding-video
        [src]="'https://www.youtube.com/embed/ymWPQxox59A?si=zqomT7z4Ge8hIZ84'"
      ></df-onboarding-video>
    </div>
  </div>
</div>
