import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { TuiAlertService } from '@taiga-ui/core';
import { Notification } from '@shared/models/notification.model';
import { NotificationOutputGraphql, NotificationTypeEnum } from '@modules/graphql/graphql-types';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { NotificationModalComponent } from '@modules/notifications/shared/components/notification-modal/notification-modal.component';
import { NotificationsGraphqlService } from '@modules/notifications/shared/services/notifications-graphql.service';
import { catchError, map, retry } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { GetUnreceivedNotificationsQuery } from '@modules/notifications/shared/graphql/queries/get-unreceived-notifications.query.generated';
import { Subscription, of } from 'rxjs';
import { UserService } from '@shared/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService implements OnDestroy {
  sub = new Subscription();

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private readonly notificationsGraphqlService: NotificationsGraphqlService,
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    private userService: UserService,
  ) {}

  showNotification(notification: Notification | null, type?: NotificationTypeEnum): void {
    if (this.userService.User?.isSemrushPlan()) return;
    if (!notification && !type) return;

    const n: Notification =
      notification !== null
        ? notification
        : new Notification({
            type: type!,
            id: -1,
          } as NotificationOutputGraphql);
    this.notificationsService
      .open<NotificationModalComponent>(new PolymorpheusComponent(NotificationModalComponent, this.injector), {
        autoClose: false,
        hasCloseButton: true,
        hasIcon: false,
        data: n,
      })
      .subscribe()
      .add(() => this.markAsReceived(n));
  }

  fetchNotifications(): void {
    if (!this?.userService?.User || this.userService.User?.isSemrushPlan()) return;

    this.sub.add(
      this.notificationsGraphqlService
        .getUnreceivedNotifications()
        .pipe(
          map((res: FetchResult<GetUnreceivedNotificationsQuery>) =>
            res.data
              ? res.data.getUnreceivedNotifications
                  .filter((notification) => notification?.type !== 'novu')
                  .map((n) => new Notification(n as NotificationOutputGraphql))
              : [],
          ),
          catchError(() => of([])),
        )
        .subscribe((data: Notification[]) => data.forEach((n) => this.showNotification(n))),
    );
  }

  private markAsReceived(n: Notification): void {
    if (n.id === -1) return;
    this.notificationsGraphqlService.markNotificationAsReceived(n.id).pipe(retry(5)).subscribe();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
