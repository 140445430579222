import { gql } from 'apollo-angular';

export const GetMarketingCampaignSubscriptionsPlansDocument = gql`
  query getMarketingCampaignSubscriptionsPlans {
    getMarketingCampaignSubscriptionsPlans {
      id
      prices {
        id
        amount
        currency
        paymentInterval
        paymentIntervalCount
      }
      name
      description
      isPresale
      trialDays
      permissions {
        id
        hasLimit
        limit
        permissionType {
          id
          type
          name
          isAvailable
        }
      }
      credits
    }
  }
`;
