import { Injectable } from '@angular/core';
import { DashboardInputGraphql } from '@modules/graphql/graphql-types';
import { Apollo } from 'apollo-angular';
import { CreateDashboardDocument } from '../graphql/mutations/create-dashboard.mutation';
import { CreateDashboardMutation } from '../graphql/mutations/create-dashboard.mutation.generated';
import { RemoveDashboardDocument } from '../graphql/mutations/remove-dashboard.mutation';
import { RemoveDashboardMutation } from '../graphql/mutations/remove-dashboard.mutation.generated';
import { GetDashboardsDocument } from '../graphql/queries/get-dashboard.query';
import { GetDashboardsQuery } from '../graphql/queries/get-dashboard.query.generated';

@Injectable({
  providedIn: 'root',
})
export class FunnelStatisticsGraphqlService {
  constructor(private apollo: Apollo) {}

  getDashboards(funnelId: number, records: number) {
    return this.apollo.query<GetDashboardsQuery>({
      query: GetDashboardsDocument,
      variables: {
        records,
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeDashboard(id: number) {
    return this.apollo.mutate<RemoveDashboardMutation>({
      mutation: RemoveDashboardDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createDashboard(input: DashboardInputGraphql) {
    return this.apollo.mutate<CreateDashboardMutation>({
      mutation: CreateDashboardDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
