import { gql } from 'apollo-angular';
import { FunnelFragment } from '@modules/funnels/shared/graphql/fragments/funnel.fragment';

export const GetOnboardingFunnelDocument = gql`
  query getOnboardingFunnel {
    getOnboardingFunnel {
      ...Funnel
    }
  }
  ${FunnelFragment}
`;
