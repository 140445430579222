import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { fabric } from 'fabric';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

interface HTMLInputEvent extends Event {
  target: (HTMLInputElement & EventTarget) | null;
}

@Component({
  selector: 'df-image-drawing',
  styleUrls: ['./image-drawing.component.scss'],
  templateUrl: './image-drawing.component.html',
})
export class ImageDrawingComponent implements OnInit, OnChanges {
  @Input() public src?: string;
  @Input() public width?: number;
  @Input() public height?: number;

  @Input() public forceSizeCanvas = true;
  @Input() public forceSizeExport = false;
  @Input() public enableRemoveImage = false;
  @Input() public enableLoadAnotherImage = false;
  @Input() public enableTooltip = true;
  @Input() public showCancelButton = true;

  @Input() public loadingTemplate?: TemplateRef<Element>;
  @Input() public errorTemplate?: TemplateRef<Element>;

  @Input() public outputMimeType = 'image/jpeg';
  @Input() public outputQuality = 1;

  @Input() public borderCss = 'none';

  @Input() public drawingSizes: { [name: string]: number } = {
    small: 5,
    medium: 10,
    large: 25,
  };

  @Input() public colors: { [name: string]: string } = {
    black: '#000',
  };

  @Output() public save: EventEmitter<string> = new EventEmitter<string>();
  @Output() public cancel: EventEmitter<void> = new EventEmitter<void>();

  public currentTool = 'brush';
  public currentSize = 'large';
  public currentColor = 'black';

  public isLoading = false;
  public hasError = false;

  private canvas!: fabric.Canvas;

  public colorsName: string[] = [];
  public drawingSizesName: string[] = [];

  private imageUsed?: fabric.Image;

  public ngOnInit(): void {
    this.colorsName = Object.keys(this.colors);
    this.drawingSizesName = Object.keys(this.drawingSizes);

    this.canvas = new fabric.Canvas('canvas', {
      hoverCursor: 'pointer',
      isDrawingMode: true,
    });
    this.canvas.backgroundColor = 'white';

    this.canvas.on('path:created', () => {
      this.saveImage();
    });

    if (this.src) {
      this.importPhotoFromSrc(this.src);
    } else {
      if (!this.width || !this.height) {
        throw new Error('No width or hight given !');
      }

      this.canvas.setWidth(this.width);
      this.canvas.setHeight(this.height);
    }

    this.selectTool(this.currentTool);
    this.selectColor(this.currentColor);
    this.selectDrawingSize(this.currentSize);
  }

  // Tools
  public selectTool(tool: string) {
    this.currentTool = tool;
  }

  public selectDrawingSize(size: string) {
    this.currentSize = size;
    if (this.canvas) {
      this.canvas.freeDrawingBrush.width = this.drawingSizes[size];
    }
  }

  public selectColor(color: string) {
    this.currentColor = color;
    if (this.canvas) {
      this.canvas.freeDrawingBrush.color = this.colors[color];
    }
  }

  public clearCanvas() {
    if (this.canvas) {
      this.canvas.remove(...this.canvas.getObjects());
    }
  }

  public saveImage() {
    if (!this.forceSizeExport || (this.forceSizeExport && this.width && this.height)) {
      const canvasScaledElement: HTMLCanvasElement = document.createElement('canvas');
      const canvasScaled = new fabric.Canvas(canvasScaledElement);
      canvasScaled.backgroundColor = 'white';

      new Observable<fabric.Canvas>((observer) => {
        if (this.imageUsed) {
          if (this.forceSizeExport) {
            canvasScaled.setWidth(this.width as number);
            canvasScaled.setHeight(this.height as number);

            this.imageUsed.cloneAsImage((imageCloned) => {
              imageCloned.scaleToWidth(this.width, false);
              imageCloned.scaleToHeight(this.height, false);

              canvasScaled.setBackgroundImage(
                imageCloned,
                (img: HTMLImageElement) => {
                  if (!img) {
                    observer.error(new Error('Impossible to draw the image on the temporary canvas'));
                  }

                  observer.next(canvasScaled);
                  observer.complete();
                },
                {
                  crossOrigin: 'anonymous',
                  originX: 'left',
                  originY: 'top',
                },
              );
            });
          } else {
            canvasScaled.setBackgroundImage(
              this.imageUsed,
              (img: HTMLImageElement) => {
                if (!img) {
                  observer.error(new Error('Impossible to draw the image on the temporary canvas'));
                }

                canvasScaled.setWidth(img.width);
                canvasScaled.setHeight(img.height);

                observer.next(canvasScaled);
                observer.complete();
              },
              {
                crossOrigin: 'anonymous',
                originX: 'left',
                originY: 'top',
              },
            );
          }
        } else {
          canvasScaled.setWidth(this.width as number);
          canvasScaled.setHeight(this.height as number);
        }
      })
        .pipe(
          switchMap(() => {
            let process = of(canvasScaled);

            if (this.canvas.getObjects().length > 0) {
              const ratioX = canvasScaled.getWidth() / this.canvas.getWidth();
              const ratioY = canvasScaled.getHeight() / this.canvas.getHeight();

              this.canvas.getObjects().forEach((originalObject: fabric.Object, i: number) => {
                process = process.pipe(
                  switchMap(() => {
                    return new Observable<fabric.Canvas>((observerObject) => {
                      originalObject.clone((clonedObject: fabric.Object) => {
                        clonedObject.set('left', originalObject.left! * ratioX);
                        clonedObject.set('top', originalObject.top! * ratioY);
                        clonedObject.scaleToWidth(originalObject.width! * ratioX);
                        clonedObject.scaleToHeight(originalObject.height! * ratioY);

                        canvasScaled.insertAt(clonedObject, i, false);
                        canvasScaled.renderAll();

                        observerObject.next(canvasScaled);
                        observerObject.complete();
                      });
                    });
                  }),
                );
              });
            }
            return process;
          }),
        )
        .subscribe(() => {
          canvasScaled.renderAll();
          canvasScaled.getElement().toBlob(
            (data: Blob | null) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const data = (reader?.result as string).replace(/^data:image\/[a-z]+;base64,/, '');
                this.save.emit(data);
              };
              reader.readAsDataURL(data!);
            },
            this.outputMimeType,
            this.outputQuality,
          );
        });
    } else {
      this.canvas.getElement().toBlob(
        (data: Blob | null) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const data = (reader?.result as string).replace(/^data:image\/[a-z]+;base64,/, '');
            this.save.emit(data);
          };
          reader.readAsDataURL(data!);
        },
        this.outputMimeType,
        this.outputQuality,
      );
    }
  }

  public cancelAction() {
    this.cancel.emit();
  }

  public importPhotoFromFile($event: Event) {
    const event = $event as HTMLInputEvent;

    if (event?.target?.files && event?.target?.files.length > 0) {
      const file = event.target.files[0];
      if (file.type.match('image.*')) {
        this.importPhotoFromBlob(file);
      } else {
        throw new Error('Not an image !');
      }
    }
  }

  public removeImage() {
    if (this.imageUsed) {
      this.imageUsed.dispose();
      this.imageUsed = undefined;
    }
    this.canvas.backgroundImage = undefined;

    if (this.width && this.height) {
      this.canvas.setWidth(this.width);
      this.canvas.setHeight(this.height);
    }

    this.canvas.renderAll();
  }

  public get hasImage(): boolean {
    return !!this.canvas.backgroundImage;
  }

  private importPhotoFromSrc(src: string) {
    const imgEl = new Image();
    imgEl.setAttribute('crossOrigin', 'anonymous');
    imgEl.src = src;

    imgEl.onload = () => {
      this.imageUsed = new fabric.Image(imgEl);

      this.imageUsed.cloneAsImage((image) => {
        let width = imgEl.width;
        let height = imgEl.height;

        if (this.width) {
          width = this.width;
        }
        if (this.height) {
          height = this.height;
        }

        image.scaleToWidth(width, false);
        image.scaleToHeight(height, false);

        this.canvas.setBackgroundImage(
          image,
          (img: HTMLImageElement) => {
            if (img) {
              if (this.forceSizeCanvas) {
                this.canvas.setWidth(width);
                this.canvas.setHeight(height);
              } else {
                this.canvas.setWidth(image.getScaledWidth());
                this.canvas.setHeight(image.getScaledHeight());
              }
            }
          },
          {
            crossOrigin: 'anonymous',
            originX: 'left',
            originY: 'top',
          },
        );
      });
    };
  }

  private importPhotoFromBlob(file: Blob | File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (evtReader: ProgressEvent<FileReader>) => {
      if (evtReader?.target?.readyState == FileReader.DONE) {
        this.importPhotoFromSrc(evtReader?.target?.result as string);
      }
    };
  }

  public importPhotoFromUrl() {
    const url = prompt('Load image');
    if (url) {
      this.importPhotoFromSrc(url);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src && !changes.src.firstChange && changes.src.currentValue) {
      if (typeof changes.src.currentValue === 'string') {
        this.importPhotoFromSrc(changes.src.currentValue);
      } else if (changes.src.currentValue instanceof Blob) {
        this.importPhotoFromBlob(changes.src.currentValue);
      }
    }
  }
}
