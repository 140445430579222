import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { filter, take } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { PermissionType } from '@modules/graphql/graphql-types';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { DialogPublicProfileComponent } from '@modules/tactics/shared/components/dialog-public-profile/dialog-public-profile.component';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { TuiDialogService } from '@taiga-ui/core';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { UserService } from '@shared/services/user.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ETacticSettingsFormEnum } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-settings-form.enum';

@Component({
  selector: 'df-tactic-settings-accessibility',
  templateUrl: './tactic-settings-accessibility.component.html',
  styleUrls: ['./tactic-settings-accessibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticSettingsAccessibilityComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  private destroyed = new Subscription();
  readonly ETacticSettingsFormEnum = ETacticSettingsFormEnum;

  routerSub?: Subscription;

  get accessibilityControl(): UntypedFormControl {
    return this.tacticSettingsService.formGroup.get(ETacticSettingsFormEnum.accessibility) as UntypedFormControl;
  }

  constructor(
    public tacticSettingsService: TacticSettingsService,
    private t: TranslateService,
    private s: SnackbarService,
    private pricingService: PricingService,
    private injector: Injector,
    private dialogService: TuiDialogService,
    public userService: UserService,
    private navigateService: NavigateService,
    private changes: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenTacticChange();
    this.listenAccessibilityChange();
  }

  private listenTacticChange(): void {
    this.sub.add(this.tacticSettingsService.tactic$.subscribe(() => this.changes.detectChanges()));
  }

  private listenAccessibilityChange() {
    if (this.tacticSettingsService.tactic?.isPaid || !!this.tacticSettingsService.tactic?.root) {
      this.accessibilityControl.disable();
    }
  }

  changeAccessibility(event: Event, isPrivate: boolean): void {
    event.stopPropagation();
    event.preventDefault();

    if (!isPrivate && !this.userService.User?.isPublic) {
      this.openMakeProfilePublicModal().subscribe({
        next: (res: boolean) => {
          if (res) {
            isPrivate = false;
            this.setAccessibility(isPrivate);
          }
        },
      });
    } else {
      this.setAccessibility(isPrivate);
    }

    this.routerSub?.unsubscribe();
    this.routerSub = this.navigateService.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        take(1),
      )
      .subscribe();
    this.destroyed.add(this.routerSub);
  }

  setAccessibility(isPrivate: boolean) {
    this.pricingService
      .checkPricing(PermissionType.PublicTactics)
      .subscribe(() => this.accessibilityControl.setValue(isPrivate));
  }

  private openMakeProfilePublicModal() {
    return this.dialogService.open<boolean>(new PolymorpheusComponent(DialogPublicProfileComponent, this.injector), {
      dismissible: false,
      size: 'm',
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroyed.unsubscribe();
  }
}
