import { gql } from 'apollo-angular';

export const IntegrationFragment = gql/* GraphQL*/ `
  fragment Integration on IntegrationOutputGraphql {
    id
    type
    name
    outputType
    creditsPrice
    outputsConfigurations {
      id
      type
    }
    inputsConfigurations {
      id
      property
      position
      placeholder
      type
      default
      required
      label
      description
      metadata
      validationPatterns {
        id
        rules
      }
    }
  }
`;
