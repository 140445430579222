<div
  class="integration-output flex h-full tui-base-01-bg border border-solid tui-base-04-border tui-text-04-border-hvr rounded-lg p-4"
>
  <div
    class="flex flex-col justify-start items-stretch w-full"
    *ngIf="outputData"
  >
    <div class="flex mb-3">
      <div class="block mr-2 md:mr-3 w-8 flex-shrink-0 flex-grow-0 flex flex-col justify-center items-center">
        <svg-icon
          *ngIf="outputData?.integration"
          [src]="Config.ASSETS + outputData.integration.icon"
          [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
        ></svg-icon>
      </div>

      <div class="flex flex-col flex-grow justify-center max-w-90">
        <span class="tui-text_body-xs tui-text-05">
          <ng-container *ngIf="outputData?.integration">
            {{ outputData.integration.category?.name }}
          </ng-container>
        </span>
      </div>

      <div class="integration-output__buttons flex justify-end items-center gap-x-2.5 show-on-hover">
        <button
          class="rounded-lg"
          tuiIconButton
          size="s"
          [icon]="copy"
          type="button"
          appearance="outline"
          (click)="copyToClipboard()"
          [tuiHint]="copyTooltip"
          tuiHintDirection="top-right"
          [tuiHintShowDelay]="500"
        >
          <ng-template #copy>
            <svg-icon
              class="tui-text-04"
              src="assets/img/_ic_copy_to_clipboard.svg"
              [svgStyle]="{ width: '1.2rem', height: '1.2rem' }"
            ></svg-icon>
          </ng-template>
          <ng-template #copyTooltip>{{ 'Funnels.Content generator.Copy' | translate }}</ng-template>
        </button>

        <button
          class="rounded-lg tui-primary tui-accent-13-bg"
          tuiButton
          type="button"
          size="s"
          appearance="accent"
          (click)="openPreview()"
        >
          <p class="tui-text_body-s font-medium">{{ 'view' | translate }}</p>
        </button>
      </div>
    </div>
    <a
      *ngIf="outputData.tactic"
      [routerLink]="navigateService.getPath('tactics/preview/p/:id', {}, { id: outputData.tactic.id })"
      target="_blank"
      class="mb-3"
    >
      <button
        size="s"
        class="integration-output__tactic-btn w-52 rounded !h-7"
        tuiButton
        type="button"
        appearance="outline"
      >
        <p class="whitespace-nowrap overflow-hidden tui-text_body-m-4 !font-normal">
          {{ outputData.tactic.name }}
        </p>
        <svg-icon
          [src]="'/assets/img/ic_external_link.svg'"
          class="ml-2"
        ></svg-icon>
      </button>
    </a>
    <div class="flex justify-between items-center">
      <p class="tui-text-body-xs tui-text-05">
        <span>{{ outputData.modifiedAt ?? outputData.createdAt | date: 'medium' }}</span
        >&nbsp;
        <span *ngIf="!!outputData.modifiedAt"> • {{ 'Funnels.Content generator.Edited' | translate }} </span>
      </p>
    </div>
    <div
      class="tui-text-body-s grow !leading-6 i18n-ex my-2.5 tui-text-03 whitespace-pre-line generated-content-value"
      [innerHTML]="outputData.value ? outputData.value['data'] : ''"
      [class.no-tactic-tile]="!outputData.tactic"
    ></div>
  </div>
</div>
