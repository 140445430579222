<form
  *ngIf="activityControl"
  [formGroup]="activityControl"
  [validate-form]="activityControl"
  class="activity-form-data mb-4"
>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 mt-4">
    <div class="lg:col-span-2">
      <p
        class="w-full font-bold uppercase tui-text-05 tui-text_body-xs mb-2"
        [innerHTML]="'Tactics.Manage.Description' | translate"
      ></p>
      <tui-input
        class="activity-form-data__description w-full"
        [formControlName]="EActivityForm.name"
        [attr.data-error-control]="EActivityForm.name"
        [tuiTextfieldLabelOutside]="true"
      >
        {{ 'Tactics.Manage.Activity name' | translate }}
      </tui-input>
      <df-error-input [control]="activityControl.get(EActivityForm.name)"></df-error-input>
    </div>

    <div
      *ngIf="userService.User?.isAdmin"
      class="mb-4"
    >
      <tui-multi-select
        placeholder="Prompt"
        class="b-form"
        [formControlName]="'customPrompts'"
        [tuiTextfieldLabelOutside]="true"
        [stringify]="stringifyPrompt"
        [identityMatcher]="parsePrompts"
      >
        Prompt
        <ng-content></ng-content>
        <tui-data-list-wrapper
          [itemContent]="promptWrapper"
          *tuiDataList
          tuiMultiSelectGroup
          [items]="promptOptions$ | async"
        ></tui-data-list-wrapper>
      </tui-multi-select>

      <ng-template
        #promptWrapper
        let-prompt
      >
        {{ prompt.name }}
      </ng-template>
    </div>

    <div class="flex flex-col items-stretch lg:col-span-2 mb-3">
      <p
        class="w-full font-bold uppercase tui-text-05 tui-text_body-xs mb-2"
        [innerHTML]="'Tactics.Manage.Activity set up' | translate"
      ></p>
      <df-activity-form-activity-type [activityControl]="activityControl"></df-activity-form-activity-type>
    </div>

    <!-- <tui-text-area class="lg:col-span-2" [formControlName]="EActivityForm.description" [expandable]="true">
      {{ 'description' | translate }}
    </tui-text-area> -->

    <!-- hidden input file for quill-editor -->

    <df-input-file
      #imageInput
      class="hidden"
      [form]="activityControl"
      [accept]="Config.ACCEPTED_IMAGES_EXTENSIONS"
      [multiple]="false"
      [formControl]="imageControl"
    >
    </df-input-file>
    <df-input-file
      #fileInput
      class="hidden"
      [form]="activityControl"
      [formControl]="fileControl"
      [multiple]="false"
    ></df-input-file>
    <!-- Quill editor -->
    <quill-editor
      class="lg:col-span-2 mt-4"
      [formControlName]="EActivityForm.description"
      classes="i18n-ex"
      [modules]="modules"
      (onEditorCreated)="getEditorInstance($event)"
      (paste)="onPaste($event)"
    >
    </quill-editor>
    <df-error-input [control]="activityControl.get(EActivityForm.description)"></df-error-input>

    <p
      class="lg:col-span-2 tui-text-02 labels w-full"
      [innerHtml]="
        'Tactics.Manage.You can link Benchmarks in description text by typing @ and Assets by typing #' | translate
      "
    ></p>

    <tui-text-area
      class="lg:col-span-2 mt-4"
      [formControlName]="EActivityForm.copy"
      [expandable]="true"
    >
      {{ 'Tactics.Manage.Sample copy' | translate }}
    </tui-text-area>

    <df-activity-form-benchmarks
      *ngIf="benchmarksArray"
      [benchmarksArray]="benchmarksArray"
      class="mt-5"
      [editorElem]="editorElem"
    >
    </df-activity-form-benchmarks>

    <df-activity-form-assets
      *ngIf="assetsArray"
      [assetsArray]="assetsArray"
      class="mt-5"
    ></df-activity-form-assets>
  </div>
</form>
