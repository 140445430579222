import { gql } from 'apollo-angular';
import { UserStorageFragment } from '../fragments/user-storage.fragment';

export const RegisterByPartnerDocument = gql`
  mutation registerByPartner($input: PartnerRegisterInputGraphql!) {
    registerByPartner(input: $input) {
      token
      eventType
      user {
        ...UserStorage
      }
    }
  }
  ${UserStorageFragment}
`;
