<div class="h-full border-solid tui-base-04-border border generated-content-container">
  <div class="rounded-2xl h-full tui-base-01-bg relative">
    <ng-template #outputPlaceholder>
      <div class="background-placeholder w-full h-full flex flex-col justify-center items-center">
        <img
          src="assets/img/folder.svg"
          alt="folder"
        />
        <p class="tui-text_body-m-1 tui-text-03 font-normal mt-12">
          {{ 'Funnels.Content generator.Your generated content will appear here' | translate }}
        </p>
      </div>
    </ng-template>
    <div
      *ngIf="(templatesFormService.collection$ | async)?.length; else outputPlaceholder"
      class="w-full h-full flex flex-col flex-nowrap justify-start items-stretch"
    >
      <div class="border-b border-solid tui-base-04-border p-4 font-medium text-sm">
        {{ 'Funnels.Content generator.Results' | translate }}
      </div>
      <div class="h-full flex flex-col gap-y-4 flex-grow py-5 px-4 overflow-y-auto">
        <df-content-generator-item-tile
          *ngFor="let item of templatesFormService.collection$ | async; let first = first"
          [data]="item"
          [tileType]="ContentTypeEnum.TEMPLATE_LIBRARY"
          (selectData)="handleDataSelected(item)"
          (deleteData)="handleDataDeleted(item)"
        ></df-content-generator-item-tile>
      </div>
    </div>
  </div>
</div>
