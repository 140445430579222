import { UsersFiltersService } from './../../../modules/users/modules/users-list/shared/services/users-filters.service';
import { AfterContentInit, Component, Input } from '@angular/core';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { ActivatedRoute } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AbstractListFiltersComponent } from '@shared/abstracts/list-filters.component.abstract';
import { UntypedFormGroup } from '@angular/forms';
import { FilterTypeService } from '@shared/services/filter-type.service';
import { TacticListTypeEnum } from '@modules/graphql/graphql-types';
import { Observable, of } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Component({
  selector: 'df-filter-list-sub-header',
  templateUrl: './filter-list-sub-header.component.html',
  styleUrls: ['./filter-list-sub-header.component.scss'],
})
export class FilterListSubHeaderComponent extends AbstractListFiltersComponent implements AfterContentInit {
  @Input() filtersService!: TacticsFiltersService | UsersFiltersService;
  @Input() filtersVisible: boolean | null = null;
  filtersDesktopVisible = true;

  get form(): UntypedFormGroup {
    return this.filtersService.filterFormGroup;
  }

  get allItemsListMode$(): Observable<boolean> {
    if (!this.route?.firstChild?.data) {
      return of(true);
    }

    return this.route.firstChild!.data.pipe(
      pluck('type'),
      map((val) => val !== TacticListTypeEnum.My && val !== TacticListTypeEnum.DigitalFirst),
    );
  }

  get showFilters(): boolean {
    return this.filtersVisible ?? this.filtersDesktopVisible;
  }

  constructor(
    public n: NavigateService,
    private filterTypeService: FilterTypeService,
    private route: ActivatedRoute,
  ) {
    super(n.router);
  }

  ngAfterContentInit() {
    const set = this.filterTypeService.setServices(this.filtersService);
    this.listService = set.listService;
    this.filtersService = set.filtersService;
    this.search.patchValue(this.filtersService.filterFormGroup.get('search')?.value || '');
  }

  protected checkFilters(): void {
    this.filtersService.checkFilters();
  }

  onEnter(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    return false;
  }

  clearAllFilters() {
    this.filtersService.clearFilters();

    // redirect
    if (this.filtersService instanceof TacticsFiltersService) {
      this.n.go('/tactics/list/all');
    }

    //close filters dialog
    this.close();
  }

  applyFilters() {
    // TODO filters not set until button clicked
    this.close();
  }

  isFavouriteShowing() {
    return this.router.url.startsWith(this.n.getPath('tactics/list/favourite'));
  }

  close(): void {
    const sub = this.filterTypeService.$dialogSub.subscribe((item) => {
      item.unsubscribe();
    });
    sub ? sub.unsubscribe() : null;
  }

  toggleFilters() {
    this.filtersDesktopVisible = !this.filtersDesktopVisible;
  }
}
