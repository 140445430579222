import { gql } from 'apollo-angular';

export const GetSubscriptionsPlansDocument = gql`
  query getSubscriptionsPlans {
    getSubscriptionsPlans {
      id
      prices {
        id
        amount
        currency
        paymentInterval
        paymentIntervalCount
      }
      name
      description
      isPresale
      trialDays
      strength
      salePlanCounter
      permissions {
        id
        hasLimit
        limit
        permissionType {
          id
          type
          name
          isAvailable
        }
      }
      credits
    }
  }
`;
