import { Injectable } from '@angular/core';
import { Permission } from '@shared/models/permission.model';
import { Apollo } from 'apollo-angular';
import { GetPermissionsQuery } from '@modules/pricing/shared/graphql/queries/get-permissions.query.generated';
import { GetPermissionsDocument } from '@modules/pricing/shared/graphql/queries/get-permissions.query';
import { map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { PermissionType } from '@modules/graphql/graphql-types';

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  permissions: Permission[] = [];

  constructor(private apollo: Apollo) {}

  getPermissions(): Observable<Permission[]> {
    return this.apollo
      .query<GetPermissionsQuery>({
        query: GetPermissionsDocument,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((res: FetchResult<GetPermissionsQuery>) => {
          this.permissions = res?.data?.getPermissions.map((p) => new Permission(p)) || [];
          return this.permissions;
        }),
      );
  }

  getSinglePermission(type: PermissionType): Permission {
    return this.permissions.filter((p) => p.type === type)[0] || null;
  }
}
