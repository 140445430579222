import { XPartnerService } from './../../../../shared/services/x-partner.service';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Config } from '@shared/configs/config';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TuiDialogContext } from '@taiga-ui/core';

@Component({
  selector: 'df-success-reset-password',
  templateUrl: './success-reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessResetPasswordComponent {
  Config = Config;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<boolean>,
    public n: NavigateService,
    public xPartnerService: XPartnerService,
  ) {}

  close() {
    this.context.completeWith(true);
  }
}
