import { Component, EventEmitter, computed, input } from '@angular/core';
import { TacticStepIconComponent } from '../tactic-step-icon/tactic-step-icon.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'df-tactic-ad',
  standalone: true,
  imports: [TacticStepIconComponent, CommonModule],
  templateUrl: './tactic-ad.component.html',
  styleUrl: './tactic-ad.component.css',
})
export class TacticAddComponent {
  public headerTitle = input.required<string>();
  public headerSubTitle = input.required<string>();

  public imageSrc = input.required<string>();

  public contentTitle = input.required<string>();
  public contentText = input.required<string>();

  public regenerateClick = new EventEmitter<void>();
  public isRegenerating = input.required<boolean>();

  public onDownloadClickHandler(): void {
    const a = document.createElement('a');
    a.href = this.imageSrc();
    a.download = 'image';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
