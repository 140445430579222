<div
  class="flex items-center border border-solid tui-base-02-border rounded-full px-1.5 py-1 {{
    generated ? 'tui-accent-07' : 'tui-text-05'
  }}"
>
  <svg-icon
    [src]="'assets/img/ic_' + (generated ? 'check_circle' : 'x_circle') + '.svg'"
    [svgStyle]="{ width: '0.875rem', height: '0.875rem' }"
    class="mr-1"
  ></svg-icon>
  <span class="tui-text-body-xs tui-text-02">{{ integrationName }}</span>
</div>
