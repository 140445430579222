import { PublicTacticPreviewComponent } from '@modules/public/pages/public-tactic-preview/public-tactic-preview.component';
import { PublicStatementComponent } from '@modules/public/pages/public-statement/public-statement.component';
import { PublicPricingComponent } from '@modules/public/pages/public-pricing/public-pricing.component';
import { PublicLoginComponent } from '@modules/public/pages/public-login/public-login.component';
import { PublicRegisterComponent } from '@modules/public/pages/public-register/public-register.component';
import { PublicTacticManageComponent } from '@modules/public/pages/public-tactic-manage/public-tactic-manage.component';
import { PermissionsResolver } from '@shared/services/permissions.resolver';
import { PublicPricingCampaignComponent } from '@modules/public/pages/public-pricing-campaign/public-pricing-campaign.component';
import { PublicPricingGuard } from '@shared/guards/public-pricing.guard';
import { PublicPricingCampaignGuard } from '@shared/guards/public-pricing-campaign.guard';
import { PublicFunnelComponent } from '@modules/public/pages/public-funnel/public-funnel.component';

import { PublicRecommenderComponent } from '@modules/public/pages/public-recommender/public-recommender.component';
import { PublicTacticsComponent } from '@modules/public/pages/public-tactics/public-tactics.component';
import { TacticsTilesComponent } from '@modules/tactics/modules/tactics-list/pages/tactics-tiles/tactics-tiles.component';
import { TacticListTypeEnum } from '@modules/graphql/graphql-types';

export const PublicRoutes = [
  {
    path: 'tactics',
    component: PublicTacticsComponent,
    children: [
      {
        path: '',
        component: TacticsTilesComponent,
        data: {
          metaTitle: 'Tactics.all tactics',
          type: TacticListTypeEnum.All,
          viewTitle: 'Tactics.tactics',
          showRequestCard: true,
        },
      },
    ],
  },
  {
    path: 'tactic/:id',
    component: PublicTacticPreviewComponent,
  },
  {
    path: 'tactic/:id/edit',
    component: PublicTacticManageComponent,
  },
  {
    path: 'funnel/:id',
    component: PublicFunnelComponent,
  },
  {
    path: 'statement',
    component: PublicStatementComponent,
  },
  {
    component: PublicRecommenderComponent,
    path: 'recommender',
  },
  {
    path: 'pricing-campaign',
    name: 'public-pricing-campaign',
    component: PublicPricingCampaignComponent,
    canActivate: [PublicPricingCampaignGuard],
    resolve: {
      globalData: PermissionsResolver,
    },
  },
  {
    path: 'pricing',
    name: 'public-pricing',
    component: PublicPricingComponent,
    canActivate: [PublicPricingGuard],
    resolve: {
      globalData: PermissionsResolver,
    },
  },
  {
    path: 'login',
    name: 'public-login',
    component: PublicLoginComponent,
  },
  {
    path: 'register',
    name: 'public-register',
    component: PublicRegisterComponent,
  },
];
