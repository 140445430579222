import { gql } from 'apollo-angular';

export const CreateFunnelImageDocument = gql`
  mutation createFunnelImage($file: Upload!, $funnelId: Int!) {
    createFunnelImage(file: $file, funnelId: $funnelId) {
      id
      file
    }
  }
`;
