import { gql } from 'apollo-angular';
import { IntegrationInputDataFragment } from '@modules/funnels/shared/graphql/fragments/integration-input-data.fragment';

export const GetIntegrationInputsDataDocument = gql`
  query getIntegrationInputsData($input: InputsDataListInputGraphql!) {
    getIntegrationInputsData(input: $input) {
      ...IntegrationInputData
    }
  }
  ${IntegrationInputDataFragment}
`;
