<div class="funnel-tile flex flex-col overflow-hidden">
  <div
    (click)="preview()"
    class="funnel-tile__image w-full relative to-hover cursor-pointer"
  >
    <img
      loading="lazy"
      [src]="Config.ASSETS + funnel!.image!.file"
      class="w-full h-full object-cover"
      alt=""
      *ngIf="funnel?.image?.file"
    />
    <img
      loading="lazy"
      src="/assets/img/placeholder-funnel.jpg"
      class="w-full h-full object-cover"
      alt=""
      *ngIf="!funnel?.image?.file"
    />
  </div>
  <div
    data-test="star-funnel-button"
    (click)="toggleFavourite()"
    class="absolute top-2 right-2 cursor-pointer z-10 rounded-full p-2 tui-base-01-bg shadow-md"
  >
    <svg-icon
      [src]="'/assets/img/star_empty.svg'"
      *ngIf="!funnel?.isFavourite"
    ></svg-icon>
    <svg-icon
      [src]="'/assets/img/star.svg'"
      *ngIf="funnel?.isFavourite"
    ></svg-icon>
  </div>
  <div
    class="absolute top-2 left-2 rounded-md tui-base-01-bg h-6 tui-text_body-s lowercase flex tui-text-05 z-10 items-center pl-2 pr-2 max-w-36 overflow-hidden shadow-md"
    *ngIf="funnel?.project"
  >
    <span class="w-full truncate">
      {{ funnel?.project?.name }}
    </span>
  </div>
  <div
    class="flex flex-col justify-between items-start p-3 relative w-full"
    (click)="preview()"
  >
    <div class="flex flex-col justify-start">
      <h2
        class="tui-text-01 tui-text_h5 line-clamp-2 break-words"
        [innerHTML]="funnel?.name"
      ></h2>
      <p class="tui-text_body-s tui-text-04 w-full">
        {{ 'created' | translate }}
        {{ funnel?.created?.setLocale('en')?.toRelative() }}
      </p>
    </div>

    <div class="tui-text_body-s tui-text-03 w-full flex items-center justify-start mt-2">
      <tui-avatar
        [text]="funnel?.owner ? (funnel?.owner)!.fullName : '-'"
        [size]="'s'"
        [avatarUrl]="funnel?.owner?.avatar ? Config.ASSETS + (funnel?.owner?.avatar)!.file! : ''"
        [rounded]="true"
      ></tui-avatar>
      <tui-avatar
        *ngIf="funnel?.lastEditor && funnel?.lastEditor?.id !== funnel?.owner?.id"
        class="-ml-4 outline-avatar"
        [text]="funnel?.lastEditor ? (funnel?.lastEditor)!.fullName : '-'"
        [size]="'s'"
        [avatarUrl]="funnel?.lastEditor?.avatar ? Config.ASSETS + (funnel?.lastEditor?.avatar)!.file! : ''"
        [rounded]="true"
      ></tui-avatar>
      <!--      <svg-icon-->
      <!--        [src]="'/assets/img/ic_user.svg'"-->
      <!--        class="mr-2"-->
      <!--        [svgStyle]="{ width: '1rem', height: '1rem' }"-->
      <!--      ></svg-icon>-->
      <span
        class="ml-4 tui-text-body-s-2 tui-text-02 line-clamp-2 break-words"
        [innerHTML]="'Funnels.List.by user' | translate: { name: funnel?.owner?.fullName }"
      ></span>
    </div>

    <df-funnel-tile-actions
      class="flex md:hidden"
      [funnel]="funnel"
      *ngIf="funnel"
    ></df-funnel-tile-actions>
  </div>
  <div
    (click)="preview()"
    class="funnel-tile__actions cursor-pointer absolute w-full h-3/5 bottom-0 left-0 right-0 p-3 flex flex-col justify-between"
  >
    <h4 class="tui-text-03 line-clamp-3 tui-text_body-l-2">
      {{ funnel?.name }}
    </h4>
    <df-funnel-tile-actions
      [funnel]="funnel"
      *ngIf="funnel"
    ></df-funnel-tile-actions>
  </div>
</div>
