import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ChangeDetectorRef, Component, Inject, Injector, Input } from '@angular/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { copyToClipboard } from '@shared/helpers/clipboard.helper';
import { TuiDialogService } from '@taiga-ui/core';
import { OutputDataGraphql } from '@modules/graphql/graphql-types';
import { ContentGeneratorItemEditModalComponent } from '@modules/funnels/modules/funnel-manage/shared/components/content-generator-item-tile/components/content-generator-item-edit-modal/content-generator-item-edit-modal.component';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-funnel-content-generator-library-tile',
  templateUrl: './funnel-content-generator-library-tile.component.html',
  styleUrls: ['./funnel-content-generator-library-tile.component.scss'],
})
export class FunnelContentGeneratorLibraryTileComponent {
  readonly Config = Config;
  @Input() outputData!: OutputDataGraphql;

  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService,
    private readonly changes: ChangeDetectorRef,
    public readonly navigateService: NavigateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  copyToClipboard(): void {
    copyToClipboard(this?.outputData?.value['data'], this.snackbarService, this.translateService);
  }

  openPreview() {
    this.dialogService
      .open<OutputDataGraphql>(
        new PolymorpheusComponent(ContentGeneratorItemEditModalComponent, this.injector),
        this.getDialogConfig(),
      )
      .subscribe((data) => data && this.finishEditOutput(data));
  }

  private finishEditOutput(data: OutputDataGraphql): void {
    this.outputData.value = data?.value ?? null;
    this.outputData.modifiedAt = data?.modifiedAt ?? null;
    this.changes.detectChanges();
  }

  private getDialogConfig(): Record<string, unknown> {
    return {
      size: 's',
      data: {
        output: this.outputData,
        isContentLibrary: true,
      },
      dismissible: true,
      closeable: true,
    };
  }
}
