import { EventEmitter, Injectable } from '@angular/core';
import { Step } from '@shared/models/step.model';
import { FetchResult } from '@apollo/client/core';
import { FilterStepsQuery } from '@modules/funnels/shared/graphql/queries/filter-funnel-steps.query.generated';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FunnelStepsService {
  steps: Step[] = [];
  steps$: EventEmitter<Step[]> = new EventEmitter<Step[]>();

  constructor(
    private funnelGraphqlService: FunnelGraphqlService,
    private t: TranslateService,
    private s: SnackbarService,
  ) {}

  getSteps(): Observable<Step[]> {
    if (this.steps.length) {
      return of(this.steps);
    }
    return this.funnelGraphqlService.getSteps().pipe(
      map((res: FetchResult<FilterStepsQuery>) => {
        this.steps = res.data?.filterSteps.records.map((s) => s.object) as Step[];
        this.steps.map((s) => ({ ...s, loading: false }));
        this.steps$.emit(this.steps);
        return this.steps;
      }),
    );
  }
}
