<tui-accordion
  class="tactic-accordion-activity w-full relative z-10"
  [rounded]="false"
  #accordionComponent
>
  <tui-accordion-item
    [showArrow]="!emptyActivity"
    [(open)]="open"
    (openChange)="openToggle()"
    [disabled]="emptyActivity"
  >
    <div class="flex flex-row items-center w-full">
      <tui-primitive-checkbox
        *ngIf="funnelPreview && funnelPreviewPermission === FunnelPermissionEnum.Editor"
        class="activity-checkbox self-start"
        (click)="toggleActivityDone($event)"
        [ngClass]="{ checked: !!activity?.done }"
        [value]="!!activity?.done"
      ></tui-primitive-checkbox>

      <svg-icon
        *ngIf="funnelPreview && funnelPreviewPermission !== FunnelPermissionEnum.Editor && activity?.done"
        [src]="'/assets/img/ic-check.svg'"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
        class="mr-2 tui-text-04"
        [ngClass]="{ 'tui-accent-07': !!activity?.done }"
      ></svg-icon>

      <div class="flex flex-col justify-center h-full flex-grow">
        <h4
          class="block w-full tui-text_body-m-1 tui-text-01 font-semibold overflow-ellipsis overflow-hidden line-clamp-2 sm:line-clamp-1"
          [ngClass]="{ 'text-base font-bold': funnelPreview }"
        >
          {{ index }}. {{ activity?.name || ('empty' | translate) }}
        </h4>
        <div class="flex">
          <span class="text-xs leading-[18px] font-medium mt-2 text-[#AF3065] bg-[#FED0E3] px-[5px] rounded-[30px]">{{
            activity?.type?.name
          }}</span>
        </div>
      </div>
    </div>

    <ng-template tuiAccordionItemContent>
      <div class="flex-col">
        <!-- DESCRIPTION -->
        <section
          class="relative"
          *ngIf="activity?.description"
        >
          <div
            *ngIf="activity?.customPrompts?.length > 0"
            class="tactic-accordion-activity__description tui-text-03 mb-8 tui-text_body-m-9"
            [ngClass]="{
              'custom-width': getDescription(activity?.description ?? '')
            }"
            [innerHTML]="activity?.description | linkRemover | safeHtml"
          ></div>
          <button
            class="btn-toggle"
            *ngIf="
              !isOpenDescription &&
              activity?.customPrompts?.length > 0 &&
              (router.url.includes('tactics/preview')
                ? activity?.description && activity!.description!.length > 131
                : activity!.description.length > 75)
            "
            (click)="toggleDescription()"
          >
            {{ 'Tactics.Prompt.More' | translate }}
          </button>
          <button
            class="btn-toggle btn-toggle--bottom"
            *ngIf="isOpenDescription && activity?.customPrompts?.length > 0"
            (click)="toggleDescription()"
          >
            {{ 'Tactics.Prompt.Less' | translate }}
          </button>
          <div
            *ngIf="activity?.customPrompts?.length === 0 || activity?.customPrompts === null"
            class="tactic-accordion-activity__description tui-text-03 mb-4 tui-text_body-m-9"
            [innerHTML]="activity?.description | linkRemover | safeHtml"
          ></div>
        </section>

        <!-- PROMPT -->
        <section
          *ngIf="activity?.customPrompts?.length > 0 && refreshList"
          (click)="customPromptClick()"
        >
          <div class="tactic-accordion-activity__prompt tui-text-03 mb-4 tui-text_body-m-9">
            <span
              *ngIf="!router.url.includes('tactics/preview')"
              class="tui-text-body-m-14 tui-text-03 font-semibold mr-3"
              >{{ 'Tactics.Prompt.Prompt' | translate }}</span
            >
            <ng-container *ngFor="let item of activity?.customPrompts; let i = index">
              <span
                *ngIf="
                  (item?.customPromptOutput.length === 0 ||
                    getCustomPromptOutput(item.customPromptOutput).length === 0) &&
                  router.url.includes('tactics/preview') &&
                  i === 0
                "
                class="tui-text-body-m-14 tui-text-03 font-semibold mr-3"
                >{{ 'Tactics.Prompt.Prompt' | translate }}</span
              >
              <tui-island
                *ngIf="
                  (item?.customPromptOutput.length === 0 ||
                    getCustomPromptOutput(item.customPromptOutput).length === 0) &&
                  !router.url.includes('tactics/preview')
                "
                class="mt-2 tui-text_body-m-9 tui-text-03 font-medium text-[#1b1f3ba6] p-4"
                (click)="previewChat(true, activity?.id ?? 0); getCustomPromptData(item)"
              >
                <h5 class="text-[#191c30e6]">{{ item?.name }}</h5>
                <p *ngIf="mapCustomPromptsLabel.get(i).length > 0">
                  <ng-container *ngFor="let field of mapCustomPromptsLabel.get(i); let index = index">
                    {{ mapCustomPromptsText.get(i)[index] }}
                    <span>{{ field }}</span>
                  </ng-container>
                  <ng-container *ngIf="mapCustomPromptsLabel.get(i).length < mapCustomPromptsText.get(i).length">
                    {{ mapCustomPromptsText.get(i)[mapCustomPromptsText.get(i).length - 1] }}
                  </ng-container>
                </p>

                <p *ngIf="mapCustomPromptsLabel.get(i).length === 0">
                  {{ item.promptText }}
                </p>
              </tui-island>
              <tui-island
                *ngIf="
                  (item?.customPromptOutput.length === 0 ||
                    getCustomPromptOutput(item.customPromptOutput).length === 0) &&
                  router.url.includes('tactics/preview')
                "
                class="mt-2 tui-text_body-m-9 tui-text-03 font-medium text-[#1b1f3ba6] p-4"
                tuiHintDirection="right"
                [tuiHint]="tooltip"
                [tuiHintHideDelay]="10"
                [tuiHintShowDelay]="10"
              >
                <h5 class="text-[#191c30e6]">{{ item?.name }}</h5>
                <p>
                  <ng-container *ngFor="let field of mapCustomPromptsLabel.get(i); let index = index">
                    {{ mapCustomPromptsText.get(i)[index] }}
                    <span>{{ field }}</span>
                  </ng-container>
                  <ng-container *ngIf="mapCustomPromptsLabel.get(i).length < mapCustomPromptsText.get(i).length">
                    {{ mapCustomPromptsText.get(i)[mapCustomPromptsText.get(i).length - 1] }}
                  </ng-container>
                </p>
              </tui-island>
              <ng-template #tooltip>
                <strong>{{ 'Tactics.Prompt.Add a tactic to funnel to run asset' | translate }}</strong>
              </ng-template>

              <tui-island
                *ngIf="
                  item?.customPromptOutput.length > 0 &&
                  getCustomPromptOutput(item.customPromptOutput).length > 0 &&
                  !router.url.includes('tactics/preview')
                "
                class="tui-island-prompt--yellow mt-2 tui-text_body-m-9 tui-text-03 border-[#FCE65B] bg-[#FFFBE8]"
              >
                <ng-container *ngFor="let el of getCustomPromptOutput(item.customPromptOutput)">
                  <div class="flex items-center justify-between mb-3">
                    <h5>{{ item?.name }}</h5>
                    <div class="flex items-center gap-4">
                      <button
                        *ngIf="router.url.includes('funnels/f/d')"
                        (click)="previewChat(true, activity?.id ?? 0); getCustomPromptData(item, true)"
                        class="flex items-center justify-center w-[20px] h-[20px]"
                      >
                        <svg-icon [src]="'/assets/img/prompt-pencil.svg'"></svg-icon>
                      </button>
                      <button
                        *ngIf="router.url.includes('funnels/f/d')"
                        class="flex items-center justify-center w-[20px] h-[20px]"
                        (click)="copyToClipboard(el?.value)"
                      >
                        <svg-icon [src]="'/assets/img/prompt-copy.svg'"></svg-icon>
                      </button>
                      <button
                        *ngIf="router.url.includes('funnels/f/d')"
                        class="flex items-center justify-center w-[20px] h-[20px]"
                        (click)="addToAssetLibrary(el?.value)"
                      >
                        <svg-icon
                          [src]="'/assets/img/bookmark.svg'"
                          class="asset-icon cursor-pointer"
                        ></svg-icon>
                      </button>
                      <div
                        class="h-[20px] w-[1px] bg-[#1b1f3b66]"
                        *ngIf="router.url.includes('funnels/f/d')"
                      ></div>
                      <button
                        *ngIf="router.url.includes('funnels/f/d')"
                        class="flex items-center justify-center w-[20px] h-[20px]"
                        (click)="previewChat(true, activity?.id ?? 0); getCustomPromptData(item, false, true)"
                      >
                        <svg-icon [src]="'/assets/img/prompt-refresh.svg'"></svg-icon>
                      </button>
                    </div>
                  </div>
                  <div [innerText]="el?.value"></div>
                  <div class="flex justify-end">
                    <!-- <button tuiButton size="s" (click)="onClickPublishButton(el.id)">{{ 'Tactics.Preview.Publish' | translate }}</button> -->
                  </div>
                </ng-container>
              </tui-island>
            </ng-container>
          </div>
        </section>

        <!-- COPY -->
        <section *ngIf="activity?.copy">
          <tui-accordion
            class="tactic-accordion-activity__copy"
            [rounded]="false"
          >
            <tui-accordion-item class="rounded-lg">
              <div class="flex">
                <span class="tui-text_menu-header tui-primary uppercase font-semibold flex mb-2 mt-1">{{
                  'Tactics.Preview.Copy' | translate
                }}</span>
              </div>

              <ng-template tuiAccordionItemContent>
                <div
                  class="tui-text_body-s tui-text-13"
                  [innerHTML]="activity?.copy | paragraph"
                ></div>
              </ng-template>
            </tui-accordion-item>
          </tui-accordion>
        </section>

        <!-- BENCHMARKS Files -->
        <section *ngIf="benchmarkFiles?.length">
          <tui-island class="mt-2 before:border-none p-0">
            <div class="flex mb-2 mt-1">
              <span class="tui-text-body-m-14 tui-text-03 font-semibold mr-3">{{
                'Tactics.Preview.Files' | translate
              }}</span>
              <button
                (click)="downloadAllFiles()"
                class="rounded-lg ml-auto"
                tuiIconButton
                type="button"
                appearance="outline"
                [icon]="iconDownload"
                size="s"
                shape="square"
                [showLoader]="loadingDownloadAllFiles"
              ></button>
              <ng-template #iconDownload>
                <svg-icon
                  class="tui-text-04"
                  [svgStyle]="{ width: '1rem', height: '1rem' }"
                  [src]="'/assets/img/ic-download.svg'"
                ></svg-icon>
              </ng-template>
            </div>

            <div class="flex flex-wrap gap-2">
              <div
                class="w-24 h-[130px]"
                *ngFor="let benchmark of benchmarkFiles; let first = first"
              >
                <df-activity-benchmark-item-preview [benchmark]="benchmark"> </df-activity-benchmark-item-preview>
              </div>
            </div>
          </tui-island>
        </section>

        <!-- BENCHMARKS Links -->
        <section *ngIf="benchmarkLinks?.length && !userService.User?.isSemrushPlan()">
          <tui-island class="mt-2 before:border-none p-0">
            <div class="flex mb-2 mt-1">
              <span class="tui-text-body-m-14 tui-text-03 font-semibold mr-3">{{
                'Tactics.Preview.Links' | translate
              }}</span>
            </div>

            <div class="flex flex-wrap gap-2">
              <div
                class="w-[136px] h-[34px]"
                *ngFor="let benchmark of benchmarkLinks; let first = first"
              >
                <df-activity-benchmark-item-preview [benchmark]="benchmark"> </df-activity-benchmark-item-preview>
              </div>
            </div>
          </tui-island>
        </section>

        <!-- ASSETS -->
        <section *ngIf="activity?.assets?.length">
          <tui-island class="assets-list mt-2 before:border-none p-0">
            <div class="flex mb-2 mt-1">
              <span class="tui-text-body-m-14 tui-text-03 font-semibold mr-3">{{
                'Tactics.Preview.Assets' | translate
              }}</span>
            </div>

            <div class="flex flex-wrap gap-2">
              <div
                class="assets-list__asset-tile"
                *ngFor="let asset of activity?.assets; let first = first"
              >
                <df-activity-asset-item-preview
                  [asset]="asset"
                  [contentGenerateMode]="contentGenerateMode"
                  [activity]="activity"
                  [tactic]="tactic"
                >
                </df-activity-asset-item-preview>
              </div>
            </div>
          </tui-island>
        </section>
      </div>
    </ng-template>
  </tui-accordion-item>
</tui-accordion>
<df-activity-prompt-chat
  class="flex flex-col min-h-[calc(100vh+2px)] overflow-auto"
  (openChat)="closeChat($event)"
  [customPromptData]="customPromptData"
  [isEditValue]="isEditValue"
  [isRefreshValue]="isRefreshValue"
  [activityId]="activity?.id ?? 0"
  [funnelTacticId]="funnelTacticId"
  (refreshList)="geValRefreshList($event)"
  *tuiSidebar="openChat; direction: 'right'"
></df-activity-prompt-chat>

<div
  class="df-publications__wrapper"
  *tuiSidebar="openPublication; direction: 'left'"
>
  <df-publications
    (closePublication)="closePublication()"
    [id]="customPromptOutputId"
  ></df-publications>
</div>
