import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GetSubscriptionsPlansQuery } from '@modules/pricing/shared/graphql/queries/get-subscriptions-plans.query.generated';
import { GetSubscriptionsPlansDocument } from '@modules/pricing/shared/graphql/queries/get-subscriptions-plans.query';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { CreateSubscriptionMutation } from '@modules/pricing/shared/graphql/mutations/create-subscription.mutation.generated';
import { CreateSubscriptionDocument } from '@modules/pricing/shared/graphql/mutations/create-subscription.mutation';
import { CheckPromotionCodeQuery } from '@modules/pricing/shared/graphql/queries/check-promotion-code.query.generated';
import { CheckPromotionCodeDocument } from '@modules/pricing/shared/graphql/queries/check-promotion-code.query';
import { GetAppSumoSubscriptionsPlansQuery } from '@modules/pricing/shared/graphql/queries/get-app-sumo-subscriptions-plans.query.generated';
import { GetAppSumoSubscriptionsPlansDocument } from '@modules/pricing/shared/graphql/queries/get-app-sumo-subscriptions-plans.query';
import { GetMarketingCampaignSubscriptionsPlansDocument } from '../graphql/queries/get-campaign-subscriptions-plans.query';
import { GetMarketingCampaignSubscriptionsPlansQuery } from '../graphql/queries/get-campaign-subscriptions-plans.query.generated';

@Injectable({
  providedIn: 'root',
})
export class PricingGraphqlService {
  constructor(private apollo: Apollo) {}

  getPackets(): Observable<FetchResult<GetSubscriptionsPlansQuery>> {
    return this.apollo.query<GetSubscriptionsPlansQuery>({
      query: GetSubscriptionsPlansDocument,
      fetchPolicy: 'network-only',
    });
  }

  getCampaignPnackets(): Observable<FetchResult<GetMarketingCampaignSubscriptionsPlansQuery>> {
    return this.apollo.query<GetMarketingCampaignSubscriptionsPlansQuery>({
      query: GetMarketingCampaignSubscriptionsPlansDocument,
      fetchPolicy: 'network-only',
    });
  }

  getAppSumoPackets(): Observable<FetchResult<GetAppSumoSubscriptionsPlansQuery>> {
    return this.apollo.query<GetAppSumoSubscriptionsPlansQuery>({
      query: GetAppSumoSubscriptionsPlansDocument,
      fetchPolicy: 'network-only',
    });
  }

  createSubscription(
    planId: number,
    promotionCode?: string,
    isTrial = false,
    priceId?: number,
  ): Observable<FetchResult<CreateSubscriptionMutation>> {
    const variables = { planId, isTrial, priceId };
    promotionCode ? (variables['promotionCode'] = promotionCode) : null;
    priceId ? (variables['priceId'] = priceId) : null;
    return this.apollo.mutate<CreateSubscriptionMutation>({
      mutation: CreateSubscriptionDocument,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  checkPromotionCode(planId: number, code: string): Observable<FetchResult<CheckPromotionCodeQuery>> {
    return this.apollo.query<CheckPromotionCodeQuery>({
      query: CheckPromotionCodeDocument,
      variables: {
        planId,
        code,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
