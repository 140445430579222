<div class="flex flex-col justify-end items-start px-4 pb-4 pt-11">
  <svg-icon src="assets/img/ic_congratulations.svg"></svg-icon>
  <h4 class="mt-5 mb-3 text-xl tui-text-03 font-semibold">
    {{ 'Statement.Congratulations' | translate }}
  </h4>
  <h4 class="text-sm font-medium tui-text-05 max-w-xs">
    {{ 'Statement.Congratulations subtitle' | translate }}
  </h4>
  <button
    tuiButton
    appearance="primary"
    size="xl"
    class="mt-7"
    (click)="goToRecommendations()"
  >
    <tui-svg
      src="tuiIconArrowRightLarge"
      class="mr-1"
    ></tui-svg>
    <span>{{ 'Statement.View recommendations' | translate }}</span>
  </button>
</div>
