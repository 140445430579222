import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { IntegrationOutputData } from '@shared/models/integration.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FunnelContentGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/services/funnel-content-generator.service';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { getConfigurationValidatorsHelper } from '@shared/helpers/content-generator.helper';
import { ContentGeneratorTemplatesFormService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/services/content-generator-templates-form.service';

@Component({
  selector: 'df-content-generator-item-edit-modal',
  templateUrl: './content-generator-item-edit-modal.component.html',
  styleUrls: ['./content-generator-item-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FunnelContentGeneratorService],
})
export class ContentGeneratorItemEditModalComponent implements OnInit {
  get data(): IntegrationOutputData | undefined {
    return this.context.data?.output;
  }
  get asset(): ActivityAsset | undefined {
    return this.context.data?.asset;
  }

  get isContentLibrary(): boolean {
    return this.context.data?.isContentLibrary;
  }

  form: UntypedFormGroup = new UntypedFormGroup({
    output: new UntypedFormControl(null),
  });

  loading = false;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<any, boolean>,
    private readonly funnelContentGeneratorService: FunnelContentGeneratorService,
    private readonly changes: ChangeDetectorRef,
    private readonly templatesFormService: ContentGeneratorTemplatesFormService,
  ) {}

  getControl(path: string): UntypedFormControl {
    return this.form.get(path) as UntypedFormControl;
  }

  ngOnInit(): void {
    this.configureControl();
  }

  cancel(): void {
    this.context.completeWith();
  }

  confirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.setLoader(true);
    this.isContentLibrary ? this.performEditLibraryOutput() : this.performEditOutput();
  }

  private performEditOutput(): void {
    this.funnelContentGeneratorService.editOutput(this.asset!, this.data!.id, this.form.get('output')?.value).subscribe(
      () => this.context.completeWith(),
      () => this.setLoader(false),
    );
  }

  private performEditLibraryOutput(): void {
    this.funnelContentGeneratorService
      .editContentLibraryOutput(this.data!.id, this.form.get('output')?.value)
      .subscribe({
        next: (value) => {
          this.templatesFormService.handleUpdateContent(value);
          this.context.completeWith(value);
        },
        error: () => this.setLoader(false),
        complete: () => this.setLoader(false),
      });
  }

  private configureControl(): void {
    if (this.data) {
      this.form.get('output')?.setValue(this.data.value['data']);
      this.form
        .get('output')
        ?.setValidators([
          Validators.required,
          ...getConfigurationValidatorsHelper(this.data.configuration.validationPatterns),
        ]);
    }
  }

  private setLoader(value: boolean): void {
    this.loading = value;
    this.changes.detectChanges();
  }
}
