import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SEOService {
  constructor(
    private title: Title,
    private meta: Meta,
  ) {}

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  addOpengraphTags(imageUrl: string) {
    this.meta.updateTag({ name: 'og:title', content: this.title.getTitle() });
    this.meta.addTags([{ name: 'og:image', content: imageUrl }]);
    this.updateOgUrl(window.location.href);
  }
}
