import { permissionsConfigs } from './configs/permissions.configs';
import { PermissionType } from './enums/permission-type.enum';
import { PermissionDecorator } from './interfaces/permission-decorator.interface';
import { PermissionsAction } from './interfaces/permissions-action.interface';
import { PermissionsGroups } from './permissions.group';
import { PermissionsDecoratorService } from './services/permissions-decorator.service';
import { UserService } from '@shared/services/user.service';
import { User } from '@shared/models/user.model';

export class CheckPermission {
  userService!: UserService | null;
  user!: User;

  constructor(private permissionDecorator: PermissionDecorator) {
    this.init();
  }

  init() {
    this.userService = PermissionsDecoratorService.getUserService();
    this.user = this.userService?.User!;
  }

  checkCorrectInitAndSettings() {
    if (!this.userService) {
      console.error(`ERROR Error: UserService was not initialize in PermissionsDecoratorService`);
      return false;
    }

    if (!this.user) {
      console.error(`ERROR Error: User does not exists in storage! Can't check permissions`);
      return false;
    }

    if (this.permissionDecorator.objectCreatorId && typeof this.permissionDecorator.objectCreatorId !== 'number') {
      console.error(
        `ERROR Error: objectCreatorId expected number got: ${typeof this.permissionDecorator.objectCreatorId}`,
      );
      return false;
    }

    if (this.permissionDecorator.group !== 0 && !this.permissionDecorator.group) {
      console.error(`ERROR Error: There is no group field in your decorator / directive!`);
      return false;
    }

    if (!this.permissionDecorator.action) {
      console.error(`ERROR Error: There is no action field in your decorator / directive!`);
      return false;
    }

    return true;
  }

  check() {
    if (!this.checkCorrectInitAndSettings()) {
      return false;
    }

    let permissionExists = false;
    let isCreator = false;

    const action = this.permissionDecorator.action.toUpperCase();

    for (let i = 0; i <= permissionsConfigs.length - 1; i++) {
      const config = permissionsConfigs[i];
      if (config.type !== this.permissionDecorator.group) {
        continue;
      }

      if (config[action]) {
        // if action exists in config

        for (let j = 0; j <= config[action].length - 1; j++) {
          // iterate through action roles and options

          const option: string | PermissionsAction = config[action][j];

          if (typeof option !== 'object') {
            /* empty */
          } else {
            isCreator = this.isCreator(option);
          }
        }
      } else {
        console.error(
          `ERROR Error: Action ${action} does not exists in ${PermissionsGroups[this.permissionDecorator.group]} group`,
        );
        permissionExists = false;
        break;
      }

      if (isCreator) {
        permissionExists = true;
        return permissionExists;
      }
    }
    return permissionExists;
  }

  isCreator(option: PermissionsAction) {
    if (this.permissionDecorator.objectCreatorId && option.type === PermissionType.OBJECT_CREATOR) {
      if (this.permissionDecorator.objectCreatorId !== this.user.id) {
        return false;
      }
    } else {
      return false;
    }
    return true;
  }
}
