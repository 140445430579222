import { PartnerConfig } from '@shared/interfaces/partner-config.interface';
import { Partners } from '@shared/enums/partners.enum';
import { environment } from '@environments/environment';
import { IconsJSON } from '@shared/types/icons-json.type';
import oxfordIconsData from '@shared/json/oxford-icons.json';
import defaultIconsData from '@shared/json/default-icons.json';

import { Config } from '@shared/configs/config';
import { Languages } from '@core/services/language.service';

export const PartnersConfig: { [name: string]: PartnerConfig } = {
  [Partners.OXFORD_IE]: {
    SYSTEM_NAME: 'Infinitum Education',
    SYSTEM_LOGO_PATH: '/assets/img/oxford-logo.png',
    APPLE_REDIRECT_URL: environment.partners[Partners.OXFORD_IE].APPLE_REDIRECT_URL,
    SYSTEM_MENU_LOGO_PATH: '/assets/img/partners/logo-oxford-71x50.png',
    SYSTEM_SHORT_MENU_LOGO_PATH: '/assets/img/partners/logo-oxford-40x28.png',
    MENU_CLASS: 'oxford-menu-bg',
    MENU_LOGO_POSITION: 'justify-center',
    MENU_SHORT_LOGO_POSITION: 'justify-center',
    MENU_LOGO_MAX_HEIGHT: 50,
    MENU_SHORT_LOGO_MAX_HEIGHT: 40,
    WHITELABEL_PARTNER: false,
    DEFAULT_LANGUAGE: null,
    HIDE_LANGUAGE_SWITCHER: false,
    getIconsData(): IconsJSON {
      return oxfordIconsData;
    },
  },
  [Partners.PLAYSTRICT]: {
    SYSTEM_NAME: 'Playstrict',
    SYSTEM_LOGO_PATH: '',
    APPLE_REDIRECT_URL: '',
    SYSTEM_MENU_LOGO_PATH: '',
    SYSTEM_SHORT_MENU_LOGO_PATH: '',
    MENU_CLASS: '',
    MENU_LOGO_POSITION: '',
    MENU_SHORT_LOGO_POSITION: '',
    MENU_LOGO_MAX_HEIGHT: null,
    MENU_SHORT_LOGO_MAX_HEIGHT: null,
    WHITELABEL_PARTNER: true,
    DEFAULT_LANGUAGE: Languages.EN,
    HIDE_LANGUAGE_SWITCHER: true,
    getIconsData(): IconsJSON {
      return defaultIconsData;
    },
  },
  [Partners.SEMRUSH]: {
    SYSTEM_NAME: 'Semrush',
    SYSTEM_LOGO_PATH: '',
    APPLE_REDIRECT_URL: '',
    SYSTEM_MENU_LOGO_PATH: '',
    SYSTEM_SHORT_MENU_LOGO_PATH: '',
    MENU_CLASS: '',
    MENU_LOGO_POSITION: '',
    MENU_SHORT_LOGO_POSITION: '',
    MENU_LOGO_MAX_HEIGHT: null,
    MENU_SHORT_LOGO_MAX_HEIGHT: null,
    WHITELABEL_PARTNER: true,
    DEFAULT_LANGUAGE: Languages.EN,
    HIDE_LANGUAGE_SWITCHER: false,
    getIconsData(): IconsJSON {
      return defaultIconsData;
    },
  },
  default: {
    SYSTEM_NAME: Config.SYSTEM_NAME,
    SYSTEM_LOGO_PATH: Config.SYSTEM_LOGO_PATH,
    APPLE_REDIRECT_URL: environment.APPLE_REDIRECT_URL,
    SYSTEM_MENU_LOGO_PATH: '/assets/img/logo.svg',
    SYSTEM_SHORT_MENU_LOGO_PATH: '/assets/img/logo-signet.svg',
    MENU_CLASS: 'df-menu-bg',
    MENU_LOGO_POSITION: 'justify-start',
    MENU_SHORT_LOGO_POSITION: 'justify-center',
    MENU_LOGO_MAX_HEIGHT: null,
    MENU_SHORT_LOGO_MAX_HEIGHT: 30,
    WHITELABEL_PARTNER: false,
    DEFAULT_LANGUAGE: null,
    HIDE_LANGUAGE_SWITCHER: false,
    getIconsData(): IconsJSON {
      return defaultIconsData;
    },
  },
};
