import { Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
@Component({
  selector: 'df-close-alert',
  templateUrl: './close-alert.component.html',
  styleUrls: ['./close-alert.component.scss'],
})
export class CloseAlertComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<CloseAlertComponent, boolean>,
  ) {}

  confirmExit(): void {
    this.context.completeWith(true);
  }

  cancel(): void {
    this.context.completeWith(false);
  }
}
