import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TacticPreviewService } from '@modules/tactics/modules/tactic-preview/shared/services/tactic-preview.service';
import { Subscription } from 'rxjs';
import { TacticAccordionActivityComponent } from '@modules/tactics/modules/tactic-preview/pages/tactic-preview/components/tactic-preview-activities/components/tactic-accordion-activity/tactic-accordion-activity.component';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-tactic-preview-activities',
  templateUrl: './tactic-preview-activities.component.html',
  styleUrls: ['./tactic-preview-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticPreviewActivitiesComponent implements OnInit, OnDestroy {
  @Output() buyTactic: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() manualCurtain: boolean | null = null; // null - autoCurtain, true/false - manual value of paidCurtain

  sub: Subscription = new Subscription();
  mobileOpened = false;
  toggle = false;
  paidCurtain = false;

  @HostBinding('class.mobile-opened')
  get isMobileOpened() {
    return this.mobileOpened;
  }

  @HostBinding('class.isPublicView')
  get hasValue() {
    return this.tacticPreviewService.isPublic;
  }

  @ViewChildren('accordionActivityComponents')
  accordionActivityComponents!: QueryList<TacticAccordionActivityComponent>;

  constructor(
    public tacticPreviewService: TacticPreviewService,
    public changes: ChangeDetectorRef,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.listenTacticUpdate();
  }

  closeMobile() {
    this.mobileOpened = false;
    this.changes.detectChanges();
  }

  listenTacticUpdate() {
    const sub = this.tacticPreviewService.tactic$.subscribe((tactic) => {
      this.paidCurtain =
        this.manualCurtain !== null
          ? this.manualCurtain
          : this.userService.User?.id !== tactic.owner?.id && tactic?.isPaid && !tactic?.purchased;
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  onExpandChange(e) {
    e = e as { srcElement: HTMLInputElement };
    this.accordionActivityComponents
      .filter((a: TacticAccordionActivityComponent) => !a.emptyActivity)
      .map((a: TacticAccordionActivityComponent) => (e.srcElement.checked ? a.openToggle(true) : a.closeToggle()));
  }

  onOpenAccordionChange() {
    const opened = this.accordionActivityComponents.filter(
      (a: TacticAccordionActivityComponent) => a.open && !a.emptyActivity,
    );
    const closed = this.accordionActivityComponents.filter(
      (a: TacticAccordionActivityComponent) => !a.open && !a.emptyActivity,
    );
    const notEmpty = this.accordionActivityComponents.filter((a: TacticAccordionActivityComponent) => !a.emptyActivity);
    if (opened.length === notEmpty.length) {
      this.toggle = true;
    } else if (closed.length === notEmpty.length) {
      this.toggle = false;
    } else {
      this.toggle = false;
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
