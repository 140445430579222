import { gql } from 'apollo-angular';

export const SearchFunnelsDocument = gql(/* GraphQL*/ `query searchFunnels($query: FunnelsListInputGraphql!)
{
  searchFunnels(query: $query)
  {
    records
    {
      id
      name
      isFavourite
      createdAt
      currentUserPermission
      lastEditor
      {
        id
        firstName
        lastName
        avatar
        {
          file
        }
      }
      shares
      {
        id
      }
      image
      {
        file
        name
      }
      project
      {
        name
      }
      owner
      {
        id
        firstName
        lastName
        avatar
        {
          file
        }
      }
    }
    count
    page
    perPage
  }
}

`);
