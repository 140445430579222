import { XPartnerService } from './../../shared/services/x-partner.service';
import { ActivatedRoute, Data, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { SEOService } from '@core/services/seo.service';
import { UserService } from '@shared/services/user.service';
import { environment } from '@environments/environment';
import { AffiliationService } from '@shared/services/affiliation.service';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';

declare let GrTracking: (eventName, ...args) => void;

export class RouterNavigationController {
  publicRoute: boolean | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SEOService,
    private translate: TranslateService,
    private userService: UserService,
    private affiliationService: AffiliationService,
    private xPartnerService: XPartnerService,
    private eventService: EventsService,
  ) {
    this.listenNavigationEnd();
  }

  listenNavigationEnd() {
    this.router.events
      .pipe(
        tap((event) => this.setAffiliationId(event)),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.children[route.children.length - 1]) {
            route = route.children[route.children.length - 1];
          }
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((routeData) => {
        this.restorePosition(routeData);
        this.setMetatitle(routeData);
        this.sendGetResponseUrl();
        this.setUpGTM();
        this.sendGTMEvent();
      });
  }

  restorePosition(routeData) {
    if (!routeData.notRestorationPosition) {
      window.scrollTo(0, 0);
    }
  }

  setMetatitle(routeData: Data) {
    if (routeData.metaTitle) {
      this.seoService.updateTitle(
        this.xPartnerService.partner.SYSTEM_NAME + ' | ' + this.translate.instant(`Metatitles.${routeData.metaTitle}`),
      );
    } else {
      this.seoService.updateTitle(this.xPartnerService.partner.SYSTEM_NAME);
    }
  }

  sendGetResponseUrl() {
    if (environment.production) {
      if (this.userService.User) {
        GrTracking('setUserId', this.userService.User.email);
      }
      GrTracking('push');
    }
  }

  setUpGTM() {
    const publicRoute = window.location.pathname.startsWith('/public');
    if (this.publicRoute !== publicRoute) {
      if (this.publicRoute !== null && !publicRoute) {
        //  remove code
        const old = document.getElementById('GTM-public');
        const oldNo = document.getElementById('GTM-frame-public');
        if (old) {
          document.getElementsByTagName('head')[0].removeChild(old);
        }
        if (oldNo) {
          document.getElementsByTagName('body')[0].removeChild(oldNo);
        }
      }
      this.publicRoute = publicRoute;
    }
  }

  sendGTMEvent() {
    this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.VPN, {
      path: window.location.pathname,
    });
  }

  setAffiliationId(routeData): void {
    if (routeData instanceof NavigationEnd || routeData instanceof NavigationCancel) {
      const params = {};
      routeData.url
        ?.split('?')[1]
        ?.split('&')
        .forEach((param) => (params[param.split('=')[0]] = param.split('=')[1]));
      if (params['fpr']) {
        this.affiliationService.promoterId = params['fpr'];
      }
      this.affiliationService.saveUserPromoter();
    }
  }
}
