import { BehaviorSubject, Observable } from 'rxjs';
import { TipTapDocumentOutputGraphql, WorkFlowQueryInputGraphql } from '@modules/graphql/graphql-types';
import { BaseWorkFlowsService } from '@modules/work-flows/services/base-work-flows.service';
import { UserService } from '@shared/services/user.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TiptapMode } from '@modules/tiptap/tiptap-mode.enum';

export abstract class AbstractWorkFlowsService<WorkFlowType> {
  static readonly CURRENT_DOC_NAME_KEY = 'dfai-current-docName';

  protected readonly _workFlows$ = new BehaviorSubject<WorkFlowType[]>([]);
  readonly workFlows$ = this._workFlows$.asObservable();

  protected _filters: WorkFlowQueryInputGraphql = {};

  protected constructor(
    private readonly userService: UserService,
    private readonly navigateService: NavigateService,
  ) {}

  protected abstract getWorkFlows(input: WorkFlowQueryInputGraphql): Observable<WorkFlowType[]>;

  onFiltersChange(filters: WorkFlowQueryInputGraphql): void {
    this._filters = filters;
    this.loadWorkFlows();
  }

  loadWorkFlows(): void {
    this.getWorkFlows(this._filters).subscribe((workFlows) => this._workFlows$.next(workFlows));
  }

  redirectToTiptapDocument(
    tipTapDocument: TipTapDocumentOutputGraphql,
    workFlowName: string,
    goBackUrl = 'work-flows/list/my',
    mode: TiptapMode = TiptapMode.ALL,
  ): void {
    const funnelId = this.userService.User?.contextFunnel.id;
    localStorage.setItem(BaseWorkFlowsService.CURRENT_DOC_NAME_KEY, tipTapDocument.name);
    this.navigateService.go(
      `/funnels/f/d/${funnelId}/flow`,
      { id: funnelId },
      { queryParams: { goBackUrl, workFlowName, docName: tipTapDocument.name, mode } },
    );
  }
}
