import { gql } from 'apollo-angular';
import { IntegrationOutputDataFragment } from '@modules/funnels/shared/graphql/fragments/integration-output-data.fragment';

export const GetOutputsDataDocument = gql`
  query getOutputsData($outputsDataIds: [Int!]!) {
    getOutputsData(outputsDataIds: $outputsDataIds) {
      ...IntegrationOutputData
    }
  }
  ${IntegrationOutputDataFragment}
`;
