<form
  [formGroup]="form"
  class="form"
>
  <tui-select
    class="w-36 md:w-[9.875rem]"
    [valueContent]="stringify"
    formControlName="sort"
    [tuiTextfieldLabelOutside]="true"
  >
    {{ 'Tactics.List.Sort' | translate }}
    <tui-data-list *tuiDataList>
      <button
        tuiOption
        *ngFor="let item of filtersService.sorts"
        [value]="item"
      >
        {{ item.name }}
      </button>
    </tui-data-list>
  </tui-select>
</form>
