<header class="hidden md:block">
  <h3 class="text-2xl font-semibold text-[#191C30E5]">
    {{ config[type].title }}
  </h3>
  <p class="text-[16px] text-[#1B1F3BA6] font-medium mt-3 mb-5">
    {{ config[type].description }}
  </p>
</header>

<tui-accordion-item class="accordion mb-10 one-element-accordion">
  <div class="flex">
    <svg-icon
      class="mr-4"
      src="assets/img/tip.svg"
    ></svg-icon>
    <span class="text-wrap">{{ config[type].accordionTitle }}</span>
  </div>
  <ng-template tuiAccordionItemContent>
    {{ config[type].accordionDescription }}
  </ng-template>
</tui-accordion-item>
