/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GenerateAdDocument } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-columns/components/tactic-columns/graphql/mutations/generate-ad.mutation';
import { GenerateAdMutation } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-columns/components/tactic-columns/graphql/mutations/generate-ad.mutation.generated';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NewsletterOutputGraphql, TacticAdsOutputGraphql } from '@modules/graphql/graphql-types';
import { GenerateNewsletterDocument } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-columns/components/tactic-columns/graphql/mutations/generate-newsletter.mutation';
import { GenerateNewsletterMutation } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-columns/components/tactic-columns/graphql/mutations/generate-newsletter.mutation.generated';

@Injectable({
  providedIn: 'root',
})
export class SpecialTacticsService {
  constructor(private readonly apollo: Apollo) {}

  generateAds(docName: string, funnelId: number): Observable<TacticAdsOutputGraphql> {
    return this.apollo
      .mutate<GenerateAdMutation>({
        mutation: GenerateAdDocument,
        variables: {
          docName,
          funnelId,
        },
      })
      .pipe(map((res) => res.data?.generateAd!));
  }

  generateNewsletter(docName: string, funnelId: number): Observable<NewsletterOutputGraphql> {
    return this.apollo
      .mutate<GenerateNewsletterMutation>({
        mutation: GenerateNewsletterDocument,
        variables: {
          docName,
          funnelId,
        },
      })
      .pipe(map((res) => res.data?.generateNewsletter!));
  }
}
