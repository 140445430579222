export class RouteData {
  metaTitle = '';
  hideHeader?: boolean = false;
  hideLeftNavigation?: boolean = false;
  shortLeftNavigation?: boolean = true;
  isClose?: boolean = false;
  type?: any;
  headerComponent?: any;
  leftNavigationComponent?: any;
  viewTitle?: string;
  showRequestCard?: boolean;
}
