import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';
import { BenchmarkType } from '@shared/models/activity-benchmark.model';
import { FetchResult } from '@apollo/client/core';
import { GetBenchmarksTypesQuery } from '@modules/tactics/shared/graphql/queries/get-benchmark-types.query.generated';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { AssetAdType, AssetPlacement, AssetPlatform, AssetResolution } from '@shared/models/activity-asset.model';
import { GetAssetsPlatformsQuery } from '@modules/tactics/shared/graphql/queries/get-assets-platforms.query.generated';
import {
  AdTypesListInputGraphql,
  AssetsPlacementsListInputGraphql,
  AssetsResolutionsListInputGraphql,
} from '@modules/graphql/graphql-types';
import { GetAssetsAdTypesQuery } from '@modules/tactics/shared/graphql/queries/get-assets-ad-types.query.generated';
import { GetAssetsPlacementsQuery } from '@modules/tactics/shared/graphql/queries/get-assets-placements.query.generated';
import { GetAssetsResolutionsQuery } from '@modules/tactics/shared/graphql/queries/get-assets-resolutions.query.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { ActivityType } from '@shared/models/activity.model';
import { GetActivityTypesQuery } from '@modules/tactics/shared/graphql/queries/get-activity-types.query.generated';

@Injectable({
  providedIn: 'root',
})
export class TacticActivitiesParametersService {
  private readonly _benchmarkTypes$: BehaviorSubject<BenchmarkType[]> = new BehaviorSubject<BenchmarkType[]>([]);
  readonly benchmarkTypes$: Observable<BenchmarkType[]> = this._benchmarkTypes$.asObservable();

  private readonly _platforms$: BehaviorSubject<AssetPlatform[]> = new BehaviorSubject<AssetPlatform[]>([]);
  readonly platforms$: Observable<AssetPlatform[]> = this._platforms$.asObservable();

  private readonly _adTypes$: BehaviorSubject<AssetAdType[]> = new BehaviorSubject<AssetAdType[]>([]);
  readonly adTypes$: Observable<AssetAdType[]> = this._adTypes$.asObservable();

  private readonly _placements$: BehaviorSubject<AssetPlacement[]> = new BehaviorSubject<AssetPlacement[]>([]);
  readonly placements$: Observable<AssetPlacement[]> = this._placements$.asObservable();

  private readonly _resolutions$: BehaviorSubject<AssetResolution[] | null> = new BehaviorSubject<
    AssetResolution[] | null
  >(null);
  readonly resolutions$: Observable<AssetResolution[] | null> = this._resolutions$.asObservable();

  private readonly _activityTypes$: BehaviorSubject<ActivityType[]> = new BehaviorSubject<ActivityType[]>([]);
  readonly activityTypes$: Observable<ActivityType[]> = this._activityTypes$.asObservable();

  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  get resolutionsLength(): number {
    return this._resolutions$.value?.length ?? 0;
  }

  constructor(
    private readonly tacticGraphqlService: TacticGraphqlService,
    private readonly s: SnackbarService,
  ) {}

  fetchBenchmarkTypes(): void {
    this._loading$.next(true);
    this.tacticGraphqlService
      .getBenchmarkTypes()
      .subscribe({
        next: (res: FetchResult<GetBenchmarksTypesQuery>) => {
          this._benchmarkTypes$.next(res.data?.getBenchmarksTypes as BenchmarkType[]);
        },
        error: () => {
          this._benchmarkTypes$.next([]);
          this.s.defaultError();
        },
      })
      .add(() => this._loading$.next(false));
  }

  fetchPlatforms(): void {
    this._loading$.next(true);
    this.tacticGraphqlService
      .getAssetsPlatforms()
      .subscribe({
        next: (res: FetchResult<GetAssetsPlatformsQuery>) => {
          this._platforms$.next(res.data?.getAssetsPlatforms as AssetPlatform[]);
        },
        error: () => {
          this._platforms$.next([]);
          this.s.defaultError();
        },
      })
      .add(() => this._loading$.next(false));
  }

  fetchAdTypes(platformId: number): void {
    this._adTypes$.next([]);
    this._loading$.next(true);
    this.tacticGraphqlService
      .getAssetsAdTypes({ platformId } as AdTypesListInputGraphql)
      .subscribe({
        next: (res: FetchResult<GetAssetsAdTypesQuery>) => {
          this._adTypes$.next(res.data?.getAssetsAdTypes as AssetAdType[]);
        },
        error: () => {
          this.s.defaultError();
        },
      })
      .add(() => this._loading$.next(false));
  }

  resetAdTypes(): void {
    this._adTypes$.next([]);
  }

  fetchPlacements(platformId: number, adTypeId: number): void {
    this._placements$.next([]);
    this._loading$.next(true);
    this.tacticGraphqlService
      .getAssetsPlacements({
        platformId,
        adTypeId,
      } as AssetsPlacementsListInputGraphql)
      .subscribe({
        next: (res: FetchResult<GetAssetsPlacementsQuery>) => {
          this._placements$.next(res.data?.getAssetsPlacements as AssetPlacement[]);
        },
        error: () => {
          this.s.defaultError();
        },
      })
      .add(() => this._loading$.next(false));
  }

  resetPlacements(): void {
    this._placements$.next([]);
  }

  fetchResolutions(platformId: number, adTypeId: number, placementId: number): Subscription {
    this._resolutions$.next(null);
    this._loading$.next(true);
    return this.tacticGraphqlService
      .getAssetsResolutions({
        platformId,
        adTypeId,
        placementId,
      } as AssetsResolutionsListInputGraphql)
      .subscribe({
        next: (res: FetchResult<GetAssetsResolutionsQuery>) => {
          this._resolutions$.next(res.data?.getAssetsResolutions as AssetResolution[]);
        },
        error: () => {
          this.s.defaultError();
        },
        complete: () => this._loading$.next(false),
      });
  }

  resetResolutions(): void {
    this._resolutions$.next(null);
  }

  fetchActivityTypes(): void {
    this._activityTypes$.next([]);
    this.tacticGraphqlService.getActivityTypes().subscribe({
      next: (res: FetchResult<GetActivityTypesQuery>) =>
        this._activityTypes$.next(res.data?.getActivityTypes as ActivityType[]),
      error: () => this.s.defaultError(),
    });
  }
}
