<header class="mb-8 print:mt-4 print:break-before-page">
  <h2 class="font-bold text-[24px] text-[#2E4FFF] col">
    {{ 'MarketingCampaign.Target Audience' | translate }}
  </h2>
  <p class="text-[#191C30BF] font-medium text-[16px] mt-4">
    {{ 'MarketingCampaign.Understand your client better and use tools professional marketers use.' | translate }}
  </p>
</header>

<div
  *ngIf="campaign"
  class="controller grid grid-cols-1 lg:grid-cols-3 gap-6 print:grid-cols-1"
>
  <div
    class="bg-white py-6 px-4 rounded-[12px]"
    *ngFor="let target of campaign.data.targetAudience"
  >
    <div class="flex mb-6 items-center">
      <img
        [src]="target.image"
        class="max-w-[40%]"
        alt=""
      />
      <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4 ml-4">
        {{ target.audience }}
      </h2>
    </div>

    <h3 class="text-[16px] font-semibold text-[#191C30E5] mb-4">
      {{ 'MarketingCampaign.Interests' | translate }}
    </h3>
    <p class="text-[14px] text-[#191C30BF]">{{ target.interests }}</p>

    <div class="flex mb-6 mt-6 print:flex-row">
      <div class="pr-4">
        <h3 class="text-[16px] font-semibold text-[#191C30E5] mb-4">
          {{ 'MarketingCampaign.Pains' | translate }}
        </h3>
        <ul class="list-disc pl-4">
          <li
            class="text-[14px] text-[#191C30BF]"
            *ngFor="let pain of target.pains"
          >
            {{ pain }}
          </li>
        </ul>
      </div>
      <div class="pl-4">
        <h3 class="text-[16px] font-semibold text-[#191C30E5] mb-4">
          {{ 'MarketingCampaign.Gains' | translate }}
        </h3>
        <ul class="list-disc pl-4">
          <li
            class="text-[14px] text-[#191C30BF]"
            *ngFor="let gains of target.gains"
          >
            {{ gains }}
          </li>
        </ul>
      </div>
    </div>
    <h3 class="text-[16px] font-semibold text-[#191C30E5] mb-6">
      {{ 'MarketingCampaign.Jobs to be done' | translate }}
    </h3>
    <ul class="list-disc pl-4">
      <li
        class="text-[14px] text-[#191C30BF]"
        *ngFor="let job of target.jobs_to_be_done"
      >
        {{ job }}
      </li>
    </ul>

    <h3 class="text-[16px] font-semibold text-[#191C30E5] mb-6 mt-6">
      {{ 'MarketingCampaign.Communication channels' | translate }}
    </h3>
    <ul class="list-disc pl-4">
      <li
        class="text-[14px] text-[#191C30BF]"
        *ngFor="let channel of target.communication_channels"
      >
        {{ channel }}
      </li>
    </ul>
  </div>
</div>

<df-skeleton
  [showSkeleton]="!campaign"
  [rows]="1"
  [cols]="3"
></df-skeleton>
