import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { StatementGraphqlService } from '@modules/statement/shared/services/statement-graphql.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { copyToClipboard } from '@shared/helpers/clipboard.helper';

@Component({
  selector: 'df-statement-public-save-modal',
  templateUrl: './statement-public-save-modal.component.html',
  styleUrls: ['./statement-public-save-modal.component.scss'],
})
export class StatementPublicSaveModalComponent implements OnInit {
  sendingOnEmail = false;
  singleEmail: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.email)]);
  loading = false;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<any, boolean>,
    private statementService: StatementService,
    private s: SnackbarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private statementGraphqlService: StatementGraphqlService,
    private n: NavigateService,
  ) {}

  ngOnInit(): void {
    this.sendStatementToAirtable();
  }

  sendStatementToAirtable() {
    if (this.statementService.alreadyPublicStatementSend) return;
    this.statementGraphqlService.fillPublicFirstStep(this.statementService.publicStatementForm).subscribe({
      next: () => {
        //only for success block another sending
        this.statementService.alreadyPublicStatementSend = true;
      },
    });
  }

  openEmailInput() {
    this.sendingOnEmail = true;
  }

  sendOnEmail() {
    this.singleEmail.markAsTouched();
    if (!this.singleEmail.valid) return;
    this.loading = true;
    this.statementService.forScreenshot = true;
    setTimeout(() => {
      const screenshotTarget = document.querySelector('.statement-container') as HTMLElement;
      html2canvas(screenshotTarget).then((canvas) => {
        this.statementService.forScreenshot = false;
        canvas.toBlob((blob) => {
          const file = new File([blob!], 'fileName.jpg', {
            type: 'image/jpeg',
          });
          this.context.completeWith();
          this.statementGraphqlService
            .sendPublicFirstStepByEmail(this.singleEmail.value!, file, this.statementService.publicStatementForm)
            .subscribe({
              next: () => {
                this.s.success(this.t.instant('Statement.Statement sent to your e-mail address'));
              },
              complete: () => {
                this.loading = false;
                this.sendingOnEmail = false;
                this.changes.detectChanges();
              },
            });
        }, 'image/jpeg');
      });
    }, 50);
  }

  copyClipboard() {
    copyToClipboard(this.context?.data?.textToCopy, this.s, this.t);
  }

  register() {
    this.context.completeWith();
    this.n.go('sign-up');
  }
}
