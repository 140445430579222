import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActionTypeEnum } from '@modules/business-data/business-data.model';
import { BehaviorSubject } from 'rxjs';

declare const gapi: any;
declare const google: any;

interface ChoosenFile {
  name: string;
  id: string;
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleDrivePickerService {
  updateView = new BehaviorSubject<boolean>(false);

  private clientId = '647553307115-5t16mg9d2rkivtllq6i5jjfvnqbf16ut.apps.googleusercontent.com';
  private apiKey = 'AIzaSyBy9L6KVBArtN1iOUpeYFuFZFRqHmAdVqM';
  private appId = 'produkcja-1678689884281';
  private scope = 'https://www.googleapis.com/auth/drive.file';

  private tokenClient;
  private accessToken: string | null = null;
  private pickerInited = false;
  private gisInited = false;
  private form!: FormGroup;

  loaded = false;
  choosenFiles: ChoosenFile[] = [];

  setForm(form: FormGroup) {
    this.form = form;
  }

  /**
   * Callback after api.js is loaded.
   */
  gapiLoaded() {
    gapi.load('client:picker', this.initializePicker.bind(this));
  }

  /**
   * Callback after the API client is loaded. Loads the
   * discovery doc to initialize the API.
   */
  async initializePicker() {
    await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    this.pickerInited = true;
    this.maybeEnableButtons();
  }

  /**
   * Callback after Google Identity Services are loaded.
   */
  gisLoaded() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.clientId,
      scope: this.scope,
      callback: '', // defined later
    });
    this.gisInited = true;
    this.maybeEnableButtons();
  }

  /**
   * Enables user interaction after all libraries are loaded.
   */
  maybeEnableButtons() {
    if (this.pickerInited && this.gisInited) {
      this.loaded = true;
    }
  }

  /**
   *  Sign in the user upon button click.
   */
  handleAuthClick() {
    this.tokenClient.callback = async (response) => {
      if (response.error !== undefined) {
        throw response;
      }
      this.accessToken = response.access_token;
      await this.createPicker();
    };

    if (this.accessToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      this.tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  /**
   *  Create and render a Picker object for searching images.
   */
  createPicker() {
    const view = new google.picker.View(google.picker.ViewId.DOCS);
    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setDeveloperKey(this.apiKey)
      .setAppId(this.appId)
      .setOAuthToken(this.accessToken)
      .addView(view)
      .addView(new google.picker.DocsUploadView())
      .setCallback(this.pickerCallback.bind(this))
      .build();
    picker.setVisible(true);
  }

  /**
   * Displays the file details of the user's selection.
   * @param {object} data - Containers the user selection from the picker
   */
  async pickerCallback(data) {
    if (data.action === google.picker.Action.PICKED) {
      const document = data[google.picker.Response.DOCUMENTS][0];
      const fileId = document[google.picker.Document.ID];
      const control = this.form.get(ActionTypeEnum.GOOGLE_DRIVE) as FormArray;

      const res = await gapi.client.drive.files.get({
        fileId: fileId,
        fields: '*',
      });

      this.choosenFiles.push({
        id: res.result.id,
        name: res.result.name,
        token: this.accessToken as string,
      });
      control.push(
        new FormControl({
          id: res.result.id,
          name: res.result.name,
          token: this.accessToken as string,
        }),
      );
      this.updateView.next(true);
    }
  }

  removeField(id: string) {
    const control = this.form.get(ActionTypeEnum.GOOGLE_DRIVE) as FormArray;

    const index = control.controls.findIndex((x) => x.value.id === id);
    control.removeAt(index);

    this.choosenFiles = this.choosenFiles.filter((item) => item.id !== id);
    this.updateView.next(true);
  }
}
