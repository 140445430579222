import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FunnelOverviewHelpItemComponent } from './funnel-overview-help-item.component';

@NgModule({
  declarations: [FunnelOverviewHelpItemComponent],
  imports: [CommonModule, SharedModule],
  exports: [FunnelOverviewHelpItemComponent],
})
export class FunnelOverviewHelpItempModule {}
