<div class="flex flex-col">
  <div
    class="flex flex-col items-start justify-center sm:flex-row sm:justify-between sm:items-center w-full flex-nowrap overflow-hidden"
  >
    <div class="flex flex-col w-full sm:w-2/5 md:w-3/5">
      <h4 class="tui-text_body-l font-semibold tui-text-03 line-clamp-2 break-words">
        {{ resourceTeam.team.name }}
      </h4>
      <p class="tui-text-body-xs tui-text-05 font-semibold">
        {{ 'ResourceTeams.Owner' | translate: { name: ownerName ? ownerName : '-' } }}
      </p>
    </div>
    <div class="flex justify-between sm:justify-end items-center mt-4 sm:mt-0 self-end sm:self-start">
      <tui-hosted-dropdown
        tuiDropdownAlign="left"
        [(open)]="hostedOpen"
        [content]="dropdown"
      >
        <button
          tuiButton
          appearance="secondary"
          size="m"
          type="button"
          [iconRight]="arrow"
          [disabled]="loading"
          *ngIf="resourceTeam.status !== EResourceTeamStatus.TO_REMOVE"
        >
          {{ 'ResourceTeams.Add members' | translate }}
        </button>
      </tui-hosted-dropdown>
      <ng-template #dropdown>
        <tui-data-list class="members-list">
          <div class="flex justify-between items-center m-4 mt-0 mb-2">
            <p class="tui-text-body-s-2 tui-text-05">
              {{ resourceTeam.team.name }}
            </p>
            <a
              tuiLink
              icon="tuiIconPlus"
              iconAlign="left"
              class="tui-primary no-underline"
              (click)="addAllMembers()"
            >
              {{ 'ResourceTeams.Add all' | translate }}</a
            >
          </div>
          <ng-container *ngFor="let member of resourceTeam.team?.members">
            <a
              tuiOption
              (click)="addMember(member)"
              class="flex justify-between items-center pl-4 pr-4 pt-2 pb-2"
              [ngClass]="{
                'cursor-default': member.user?.id === currentUser.id || member.addedAsPermission
              }"
            >
              <div class="flex justify-start items-center w-1/2 sm:w-3/4">
                <tui-avatar
                  [text]="(member.user ? member.user.fullName : member.email) ?? ''"
                  size="s"
                  [autoColor]="true"
                  [rounded]="true"
                  [avatarUrl]="member.user?.avatar ? Config.ASSETS + member.user?.avatar?.file : ''"
                  class="mr-2"
                ></tui-avatar>
                <div class="flex flex-col flex-wrap">
                  <h5 class="tui-text-03 tui-text-body-m-2 break-words m-0 line-clamp-2">
                    {{ (member.user ? member.user.fullName : member.email) ?? '' }}
                    <span
                      class="tui-text-body-s-2 no-underline"
                      *ngIf="member.user?.id === currentUser.id"
                    >
                      {{ 'ResourceTeams.(you)' | translate }}
                    </span>
                  </h5>
                </div>
              </div>
              <a
                tuiLink
                icon="tuiIconPlus"
                iconAlign="left"
                *ngIf="member.user?.id !== currentUser.id && !member.addedAsPermission"
                class="tui-primary no-underline font-bold"
                >{{ 'add' | translate }}</a
              >

              <a
                tuiLink
                icon="tuiIconCheck"
                iconAlign="left"
                *ngIf="member.user?.id !== currentUser.id && member.addedAsPermission"
                class="tui-text-05 no-underline cursor-default font-bold"
                >{{ 'added' | translate }}</a
              >
            </a>
          </ng-container>
        </tui-data-list>
      </ng-template>
      <button
        tuiIconButton
        type="button"
        appearance="outline"
        size="m"
        mwlConfirmationPopover
        [popoverTitle]="'ResourceTeams.Removing team' | translate"
        [popoverMessage]="
          'ResourceTeams.Do you really want to remove team {name}?' | translate: { name: resourceTeam.team.name }
        "
        [appendToBody]="true"
        placement="top"
        popoverClass="team-popover"
        (confirm)="removeResourceTeam()"
        [icon]="close"
        class="ml-2"
        *ngIf="resourceTeam.status !== EResourceTeamStatus.TO_REMOVE"
      >
        <ng-template #close>
          <svg-icon
            class="tui-text-04"
            [src]="'/assets/img/ic-close.svg'"
            [svgStyle]="{ width: '1rem', height: '1rem' }"
          ></svg-icon>
        </ng-template>
      </button>
      <button
        tuiIconButton
        type="button"
        appearance="outline"
        size="m"
        [tuiHint]="tooltip"
        [tuiHintDirection]="'right'"
        [icon]="restore"
        class="ml-2"
        *ngIf="resourceTeam.status === EResourceTeamStatus.TO_REMOVE"
        (click)="restoreResourceTeam()"
      >
        <ng-template #tooltip>
          <div [innerHTML]="'restore' | translate"></div>
        </ng-template>
        <ng-template #restore>
          <svg-icon
            class="tui-text-04"
            [src]="'/assets/img/ic_refresh.svg'"
            [svgStyle]="{ width: '1rem', height: '1rem' }"
          ></svg-icon>
        </ng-template>
      </button>
    </div>
  </div>
  <div
    class="flex flex-col justify-start items-start w-full border-solid border-gray-200 rounded"
    [class.pt-2]="resourceTeam.permissions.length"
  >
    <df-resource-teams-single-permission
      class="mt-2"
      [resourceTeam]="resourceTeam"
      [permis]="permission"
      *ngFor="let permission of resourceTeam.permissions"
      #permissionResourceComponents
    ></df-resource-teams-single-permission>
  </div>
</div>
