import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';

@Component({
  selector: 'df-funnel-graphics-close-alert',
  templateUrl: './funnel-graphics-close-alert.component.html',
  styleUrls: ['./funnel-graphics-close-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelGraphicsCloseAlertComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<FunnelGraphicsCloseAlertComponent, boolean>,
  ) {}

  confirmExit(): void {
    this.context.completeWith(true);
  }

  cancel(): void {
    this.context.completeWith(false);
  }
}
