import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Config } from '@shared/configs/config';
import { DashboardTypeEnum } from '@modules/graphql/graphql-types';
import { StatisticReport } from '../../models/funnel-statistics.interface';
@Component({
  selector: 'df-funnel-statistics-report-tile',
  templateUrl: './funnel-statistics-report-tile.component.html',
  styleUrls: ['./funnel-statistics-report-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelStatisticsReportTileComponent implements OnInit {
  readonly Config = Config;

  @Input() report: StatisticReport | null = null;
  icon: string | null = null;

  ngOnInit(): void {
    if (this.report) this.icon = this.resolveIcon(this.report.type);
  }

  public resolveIcon(type: DashboardTypeEnum | null | undefined): string {
    switch (type) {
      case DashboardTypeEnum.Facebook:
        return 'ic_social_Facebook.svg';
      case DashboardTypeEnum.Instagram:
        return 'ic_social_instagram.svg';
      case DashboardTypeEnum.Google:
        return 'ic_social_google-ads.svg';
      default:
        return 'ic_social_www.svg';
    }
  }
}
