import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Input, Output } from '@angular/core';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { Config } from '@shared/configs/config';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { ActivityAssetPreviewModalComponent } from '@modules/tactics/shared/components/activity-asset-preview-modal/activity-asset-preview-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { OutputTypeEnum, PermissionType } from '@modules/graphql/graphql-types';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ContentGeneratorRouteParam } from '@modules/funnels/shared/enums/content-generator-route-param.enum';
import { Activity } from '@shared/models/activity.model';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';

@Component({
  selector: 'df-activity-asset-item',
  templateUrl: './activity-asset-item.component.html',
  styleUrls: ['./activity-asset-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityAssetItemComponent {
  readonly Config = Config;
  readonly OutputTypeEnum = OutputTypeEnum;

  @Input() asset!: ActivityAsset;
  @Input() editMode = false;
  @Input() removeLoading = false;
  @Input() contentGenerateMode = false;
  @Input() activity?: Activity;
  @Input() tactic?: FunnelTactic;
  @Input() disabled = false;

  @Output() assetRemoved: EventEmitter<ActivityAsset> = new EventEmitter<ActivityAsset>();
  @Output() assetRestored: EventEmitter<ActivityAsset> = new EventEmitter<ActivityAsset>();

  constructor(
    public t: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly pricingService: PricingService,
    private readonly funnelManageService: FunnelManageService,
    private readonly n: NavigateService,
  ) {}

  removeAsset(asset: ActivityAsset): void {
    this.assetRemoved.emit(asset);
  }

  restoreAsset(asset: ActivityAsset): void {
    this.assetRestored.emit(asset);
  }

  openAssetPreview(): void {
    this.dialogService
      .open<number>(new PolymorpheusComponent(ActivityAssetPreviewModalComponent, this.injector), {
        data: {
          asset: this.asset,
        },
        dismissible: true,
      })
      .subscribe();
  }

  goToContentGenerate(): void {
    this.pricingService.checkPricing(PermissionType.AiCopywriting).subscribe(() =>
      this.n.go(
        '/funnels/f/d/:id/content-generator',
        { id: this.funnelManageService.funnel?.id },
        {
          queryParams: {
            [ContentGeneratorRouteParam.TACTIC]: this.tactic?.id,
            [ContentGeneratorRouteParam.ACTIVITY]: this.activity?.id,
            [ContentGeneratorRouteParam.ASSET]: this.asset.id,
          },
        },
      ),
    );
  }
}
