<header class="mb-8 m-auto print:mt-4 print:break-before-page md:ml-6">
  <h2 class="font-bold text-[24px] text-[#2E4FFF] col">
    {{ 'MarketingCampaign.Communication' | translate }}
  </h2>
  <p class="text-[#191C30BF] font-medium text-[16px] mt-4">
    {{ 'MarketingCampaign.Effective communication strategies for every funnel stage' | translate }}
  </p>
</header>

<div id="typewriter"></div>

<df-skeleton
  [showSkeleton]="showSkeleton"
  [rows]="1"
  [cols]="3"
></df-skeleton>
