import { gql } from 'apollo-angular';

export const CustomPromptFragment = gql`
  fragment CustomPromptFragment on CustomPromptOutputGraphql {
    id
    name
    description
    settingsIntegration
    promptText
    promptProperty
    category
    settingsActive
    customPromptOutput {
      id
      customPromptId
      funnelTacticId
      value
      input
    }
    customPromptFields {
      fieldId
      label
      helpText
      fieldType
      optionValues
      defaultValueId
      defaultValue
      required
      order
      name
    }
  }
`;
