import { Config } from '@shared/configs/config';
import { Component } from '@angular/core';

@Component({
  selector: 'df-dialog-tally-rec',
  templateUrl: './dialog-tally-rec.component.html',
  styleUrls: ['./dialog-tally-rec.component.scss'],
})
export class DialogTallyRecComponent {
  Config = Config;
}
