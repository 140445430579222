/* eslint-disable no-case-declarations */
import { IntegrationInputConfiguration, IntegrationValidationPattern } from '@shared/models/integration.model';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { InputTypeEnum } from '@modules/graphql/graphql-types';

export const getDefaultControlHelper = (
  config: IntegrationInputConfiguration,
  defaultValue?: any,
): UntypedFormControl => {
  return new UntypedFormControl(defaultValue, getValidatorsForConfigurationHelper(config));
};

export const getValidatorsForConfigurationHelper = (config: IntegrationInputConfiguration): ValidatorFn[] => {
  const validators: ValidatorFn[] = [];
  if (config.required) {
    validators.push(Validators.required);
  }

  if (config.type === InputTypeEnum.InputNumber) {
    validators.push(...getConfigurationValidatorsHelper(config.validationPatterns, InputTypeEnum.InputNumber));
  } else {
    validators.push(...getConfigurationValidatorsHelper(config.validationPatterns));
  }

  return validators;
};

export const MIN_MAX_LENGTH_REGEX = /\.\{\d+,\d+}/;

export const getConfigurationValidatorsHelper = (
  validationPatterns: IntegrationValidationPattern[],
  type = InputTypeEnum.InputText,
): ValidatorFn[] => {
  const validators: ValidatorFn[] = [];
  validationPatterns.forEach((pattern) => {
    Object.keys(pattern.rules).forEach((key) => {
      switch (key) {
        case 'min':
          if (type === InputTypeEnum.InputNumber) {
            validators.push(Validators.min(+pattern.rules[key]));
          } else {
            validators.push(Validators.minLength(+pattern.rules[key]));
          }

          break;
        case 'max':
          if (type === InputTypeEnum.InputNumber) {
            validators.push(Validators.max(+pattern.rules[key]));
          } else {
            validators.push(Validators.maxLength(+pattern.rules[key]));
          }

          break;
        case 'regex':
          const regex = pattern.rules[key].substring(1, pattern.rules[key].length - 1);
          const regexMatch = regex.match(MIN_MAX_LENGTH_REGEX);
          if (regexMatch?.length === 1) {
            const min = Number(regexMatch[0].split(',')[0].replace('.{', ''));
            const max = Number(regexMatch[0].split(',')[1].replace('}', ''));
            validators.push(Validators.minLength(min));
            validators.push(Validators.maxLength(max));
          } else {
            validators.push(Validators.pattern(new RegExp(regex)));
          }
          break;
      }
    });
  });
  return validators;
};

export const getAcceptedFileExtensions = (validationPatterns: IntegrationValidationPattern[]): string => {
  const extensions: string[] = [];
  validationPatterns.forEach((pattern) =>
    Object.keys(pattern.rules)
      .filter((key) => key === 'mimeTypes' || key === 'mime-types')
      .forEach((key) => extensions.push(...pattern.rules[key])),
  );
  return extensions.join(',');
};
