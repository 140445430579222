import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigateService } from '@core/routes/services/navigate.service';
import { CreateMarketingCampaignInputGraphql } from '@modules/graphql/graphql-types';
import { MarketingCampaignService } from '@modules/marketing-campaign/shared/services/marketing-campaign.service';

import file2Base64 from '@shared/helpers/file-to-base64.helper';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'df-campaign-set-url',
  templateUrl: './campaign-set-url.component.html',
  styleUrls: ['./campaign-set-url.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignSetUrlComponent {
  form = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    businessName: new FormControl('', [Validators.required]),
    url: new FormControl(''),
    logo: new FormControl(),
    brief: new FormControl(''),
  });

  loading$ = new BehaviorSubject<boolean>(false);
  showBriefField = false;

  constructor(
    private marketingCampaignService: MarketingCampaignService,
    private n: NavigateService,
    private eventsService: EventsService,
    private changes: ChangeDetectorRef,
  ) {}

  async validateUrl() {
    const url = this.form.get('url')?.value as string;
    const response = await firstValueFrom(this.marketingCampaignService.validateUrl(url));

    if (!response) {
      this.showBriefField = true;
      this.form.get('brief')?.setValidators([Validators.required]);
      this.form.get('brief')?.updateValueAndValidity();
      this.changes.detectChanges();
    }

    return response;
  }

  async save() {
    this.form.markAllAsTouched();

    const urlIsValid = this.showBriefField ? true : await this.validateUrl();

    if (this.form.invalid || !urlIsValid) return;

    const data = this.form.getRawValue();

    if (this.showBriefField && !data.brief) return;

    if (data.logo) {
      data.logo = await file2Base64(data.logo as File);
    }

    this.loading$.next(true);
    this.marketingCampaignService.createNewCampaign(data as CreateMarketingCampaignInputGraphql).subscribe((res) => {
      localStorage.setItem('dfai-anonymous-user', JSON.stringify({ funnelId: res?.funnelId }));
      this.eventsService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.leadAistrategy, { email: data.email });
      this.loading$.next(false);
      this.n.go('marketing-campaign/campaign/:id', { id: res?.funnelId });
    });
  }
}
