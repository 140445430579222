import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Recommendation } from '@shared/models/recommendations.model';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'df-funnel-recommendations-item',
  templateUrl: './funnel-recommendations-item.component.html',
  styleUrls: ['./funnel-recommendations-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelRecommendationsItemComponent {
  @Output() recommendationSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  recommendationFormControl: UntypedFormControl | null = null;

  constructor(private readonly changes: ChangeDetectorRef) {}

  private _recommendation: Recommendation | null = null;

  get recommendation(): Recommendation | null {
    return this._recommendation;
  }

  @Input() set recommendation(value: Recommendation | null) {
    this._recommendation = value;
    this.recommendationFormControl = !!value && !value.usedInFunnel ? new UntypedFormControl(false) : null;
    this.changes.detectChanges();
  }

  toggleSelected() {
    this.recommendationSelected.emit(this.recommendationFormControl!.value);
  }

  select(value?: boolean) {
    if (this.recommendationFormControl) {
      this.recommendationFormControl!.setValue(value === undefined ? !this.recommendationFormControl.value : value);
      this.toggleSelected();
      this.changes.detectChanges();
    }
  }
}
