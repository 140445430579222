import { gql } from 'apollo-angular';
export const EditResourcePermissionDocument = gql`
  mutation editResourcePermission($input: EditResourcePermissionInputGraphql!) {
    editResourcePermission(input: $input) {
      id
      permission
      teamMember {
        id
        email
        user {
          id
          firstName
          lastName
          email
          avatar {
            file
          }
        }
      }
    }
  }
`;
