<div
  class="w-full h-full flex flex-row-reverse justify-between items-center"
  *ngIf="!funnelFlowManageService.editMode || !funnelFlowManageService.flow; else editFlowBar"
>
  <div
    class="flex justify-end items-center flex-shrink-0"
    [ngClass]="{ invisible: loading }"
  >
    <ng-template #exportIcon>
      <svg-icon
        src="/assets/img/ic_file_export.svg"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
      ></svg-icon>
    </ng-template>
    <ng-template #addIcon>
      <svg-icon
        src="/assets/img/ic_add_filled.svg"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
      ></svg-icon>
    </ng-template>

    <ng-template #addTacticsDropdown>
      <tui-data-list>
        <button
          tuiOption
          size="s"
          [routerLink]="n.getPath('/tactics/list/all')"
        >
          {{ 'Funnels.Manage.Add tactics from library' | translate }}
        </button>
        <button
          tuiOption
          size="s"
          (click)="createTactic()"
        >
          {{ 'Funnels.Manage.Create tactic' | translate }}
        </button>
      </tui-data-list>
    </ng-template>
    <!--    Full HD -->
    <button
      *ngIf="!empty"
      tuiButton
      appearance="accent"
      type="button"
      size="m"
      class="mr-2 hidden 2xl:block"
      (click)="exportToPDF()"
    >
      <ng-container *ngTemplateOutlet="exportIcon"></ng-container>
      <span class="ml-1">{{ 'Funnels.Manage.Export to PDF' | translate }}</span>
    </button>
    <tui-hosted-dropdown
      [content]="addTacticsDropdown"
      *ngIf="
        (!isSharedMode && funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor) ||
        onboardingService.onboardingRunning
      "
    >
      <button
        tuiButton
        appearance="accent"
        type="button"
        size="m"
        class="mr-2 hidden 2xl:block"
      >
        <ng-container *ngTemplateOutlet="addIcon"></ng-container>
        <span class="ml-1">{{ 'Funnels.Manage.Add tactics' | translate }}</span>
      </button>
    </tui-hosted-dropdown>
    <!--    MOBILE -->
    <button
      *ngIf="!empty"
      tuiIconButton
      appearance="accent"
      type="button"
      size="m"
      class="mr-2 2xl:hidden"
      [icon]="exportIcon"
      (click)="exportToPDF()"
    ></button>
    <tui-hosted-dropdown
      [content]="addTacticsDropdown"
      *ngIf="
        (!isSharedMode && funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor) ||
        onboardingService.onboardingRunning
      "
    >
      <button
        tuiIconButton
        appearance="accent"
        type="button"
        size="m"
        class="mr-2 2xl:hidden"
        [icon]="addIcon"
      ></button>
    </tui-hosted-dropdown>

    <button
      [disabled]="empty"
      *ngIf="
        (!isSharedMode && funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor) ||
        onboardingService.onboardingRunning
      "
      tuiButton
      appearance="primary"
      type="button"
      size="m"
      (click)="goToContentGenerate()"
    >
      {{ 'Funnels.Manage.Content generator' | translate }}
    </button>
  </div>
  <div
    *ngIf="!empty"
    class="hidden lg:flex justify-start items-stretch flex-shrink"
  >
    <tui-island
      size="s"
      class="h-10 px-4 py-2.5 rounded-xl box-border w-36 xl:w-52 flex-shrink flex-grow-0"
    >
      <div class="flex w-full h-full items-center">
        <svg-icon
          [src]="'/assets/img/ic-search.svg'"
          [svgStyle]="{ width: '1rem', height: '1rem' }"
          class="mr-3 cursor-pointer"
          (click)="input.nativeFocusableElement?.focus()"
        ></svg-icon>

        <tui-input-inline
          #input
          class="w-full tui-text-05 font-medium"
          [formControl]="funnelManageService.searchFunnelTactic"
        >
          {{ 'search' | translate }}
        </tui-input-inline>
      </div>
    </tui-island>

    <tui-island
      size="s"
      class="hidden xl:block h-10 px-4 py-2.5 rounded-xl box-border ml-1.5 2xl:ml-3"
    >
      <div class="flex w-full h-full items-center">
        <span class="w-full tui-text-01 tui-text_body-s mr-2 whitespace-nowrap">
          {{ 'Funnels.Manage.Expand all' | translate }}
        </span>
        <tui-toggle
          class="flex-shrink-0"
          [formControl]="funnelManageService.expandAll"
          size="m"
        ></tui-toggle>
      </div>
    </tui-island>

    <tui-island
      size="s"
      class="hidden xl:block h-10 px-4 py-2.5 rounded-xl box-border ml-1.5 2xl:ml-3"
    >
      <div class="flex w-full h-full items-center">
        <span class="w-full tui-text-01 tui-text_body-s mr-2">
          {{ 'Funnels.Manage.Summary' | translate }}
        </span>
        <tui-toggle
          class="flex-shrink-0"
          [formControl]="funnelManageService.expandSummary"
          size="m"
        ></tui-toggle>
      </div>
    </tui-island>
  </div>
</div>
<ng-template #editFlowBar>
  <div class="w-full h-full flex flex-row justify-between items-center">
    <h1
      class="tui-text_h5 tui-text-02 m-0 break-words line-clamp-1 hover:text-blue-700 cursor-pointer"
      (click)="editFlowName()"
    >
      {{ funnelFlowManageService.flow?.name }}
    </h1>

    <div class="flex flex-nowrap flex-shrink-0 justify-end items-center ml-4">
      <button
        tuiButton
        size="m"
        appearance="flat"
        type="button"
        class="bg-red-100"
        [showLoader]="removingLoading"
        [disabled]="removingLoading"
        mwlConfirmationPopover
        [popoverTitle]="'Funnels.Manage.Removing flow' | translate"
        [popoverMessage]="'Funnels.Manage.Do you really want to remove your flow? You will loose all data.' | translate"
        [appendToBody]="true"
        placement="bottom"
        (confirm)="removeFlow()"
      >
        <svg-icon
          [src]="'/assets/img/ic-trash.svg'"
          class="lg:hidden tui-accent-01"
        ></svg-icon>
        <span class="hidden lg:inline tui-accent-01">{{ 'Funnels.Manage.Delete flow' | translate }}</span>
      </button>
      <button
        tuiButton
        size="m"
        class="ml-2"
        appearance="primary"
        type="button"
        [disabled]="removingLoading"
        [showLoader]="funnelFlowManageService.loading"
        (click)="saveFlow()"
      >
        <svg-icon
          [src]="'/assets/img/ic_save.svg'"
          class="lg:hidden"
        ></svg-icon>
        <span class="hidden lg:inline">{{ 'save' | translate }}</span>
      </button>
      <button
        size="m"
        class="ml-2"
        tuiIconButton
        type="button"
        appearance="outline"
        [icon]="icon"
        (click)="turnOffEditModeFlow()"
      ></button>
      <ng-template #icon>
        <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
      </ng-template>
    </div>
  </div>
</ng-template>
