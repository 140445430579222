import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthOutputGraphql, RegistrationMethodsEnum, SocialLoginInputGraphql } from '@modules/graphql/graphql-types';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { LoginWithSocialMediaAccountMutation } from '@modules/authorization/shared/graphql/mutations/ login-with-social-media-account.mutation.generated';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { AppInjector } from '@core/services/app-injector.service';
import { AuthController } from '@modules/authorization/shared/controllers/auth.controller';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { GraphqlErrorEnum } from '@modules/graphql/enums/graphql-error.enum';
import { XPartnerService } from '@shared/services/x-partner.service';
import { Config } from '@shared/configs/config';
import { UntypedFormGroup } from '@angular/forms';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';

@Component({ template: '' })
export abstract class AbstractAuthComponent implements OnInit {
  readonly Config = Config;

  protected readonly injector: Injector;
  public t: TranslateService;
  public n: NavigateService;
  protected s: SnackbarService;
  authController: AuthController;
  protected socialAuthService: SocialAuthService;
  public xPartnerService: XPartnerService;
  protected statementService: StatementService;
  protected eventService: EventsService;

  socialsLoading = false;
  loading = false;
  socialUser!: SocialUser;
  wrongDataAmount = 0;

  abstract formGroup: UntypedFormGroup;

  protected constructor(
    protected changes: ChangeDetectorRef,
    protected recaptchaService: ReCaptchaV3Service,
  ) {
    this.injector = AppInjector.getInjector();
    this.t = this.injector.get(TranslateService);
    this.n = this.injector.get(NavigateService);
    this.s = this.injector.get(SnackbarService);
    this.socialAuthService = this.injector.get(SocialAuthService);
    this.authController = new AuthController(this.recaptchaService);
    this.xPartnerService = this.injector.get(XPartnerService);
    this.statementService = this.injector.get(StatementService);
    this.eventService = this.injector.get(EventsService);
  }

  ngOnInit() {
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
    });
  }

  getControl(name: string) {
    return this.formGroup.get(name);
  }

  protected signInBySocialAccount(request: Observable<SocialLoginInputGraphql>) {
    this.socialsLoading = true;
    this.changes.detectChanges();
    request.pipe(switchMap((loginData) => this.getSocialLoginRequest(loginData))).subscribe({
      next: (res) => {
        this.loading = true;
        this.successSignInBySocialAccount(res);
      },
      error: (error: ApolloError) => {
        this.loading = false;
        this.socialsLoading = false;
        this.changes.detectChanges();
        this.errorSignIn(error);
      },
    });
  }

  protected abstract getSocialLoginRequest(
    loginData: SocialLoginInputGraphql,
  ): Observable<FetchResult<LoginWithSocialMediaAccountMutation>>;

  protected signInByCredentialToken(credential: string) {
    this.socialsLoading = true;
    this.changes.detectChanges();
    this.getSocialLoginRequest({
      token: credential,
      method: RegistrationMethodsEnum.Google,
    }).subscribe({
      next: (res) => {
        this.loading = true;
        this.successSignInBySocialAccount(res);
      },
      error: (error: ApolloError) => {
        this.loading = false;
        this.socialsLoading = false;
        this.changes.detectChanges();
        this.errorSignIn(error);
      },
    });
  }

  protected successSignInBySocialAccount(res: FetchResult<LoginWithSocialMediaAccountMutation>) {
    this.s.success(this.t.instant("Auth.Login.You're logged. Welcome my lord!"));
    this.authController.saveAuthData(res.data?.loginWithSocialMediaAccount as AuthOutputGraphql);
    this.eventService.pushToGoogleAnalyticsEvent(
      res.data?.loginWithSocialMediaAccount?.eventType === 'sign-up'
        ? GoogleAnalyticsEvent.Signup
        : GoogleAnalyticsEvent.Signin,
      {
        userID: res.data?.loginWithSocialMediaAccount.user?.id,
        email: res.data?.loginWithSocialMediaAccount.user?.email,
      },
    );
  }

  signInWithGoogle() {
    this.signInBySocialAccount(
      from(this.socialAuthService.authState).pipe(
        catchError((err) => {
          console.log(err);
          return of(this.s.error(this.t.instant('Auth.Login.Something went wrong, try again')));
        }),
        map(() => ({
          token: this.socialUser.idToken,
          method: RegistrationMethodsEnum.Google,
        })),
      ),
    );
  }

  signInWithFB(): void {
    this.signInBySocialAccount(
      from(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
        catchError(() => of(this.s.error(this.t.instant('Auth.Login.Something went wrong, try again')))),
        map(() => ({
          token: this.socialUser.authToken,
          method: RegistrationMethodsEnum.Facebook,
        })),
      ),
    );
  }

  protected errorSignIn(error: ApolloError) {
    this.wrongDataAmount++;
    if (error.graphQLErrors?.length) {
      switch ((error.graphQLErrors[0] as CustomGraphqlErrorInterface).errorCode) {
        case GraphqlErrorEnum.INVALID_CREDENTIALS:
          this.s.error(this.t.instant('Auth.Login.Provided credentials are not correct.'));
          break;
        case GraphqlErrorEnum.VALIDATION_ERROR:
          this.s.error(this.t.instant('You provided wrong data. Please correct them and try again.'));
          break;
        case GraphqlErrorEnum.ALREADY_EXISTS:
          this.s.error(this.t.instant('Auth.Login.Account with specified email already exists'));
          break;
      }
    }
  }
}
