import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FunnelSettingsComponent } from '@modules/funnels/modules/funnel-settings/pages/funnel-settings/funnel-settings.component';

@Injectable()
export class FunnelSettingsCanDeactivateGuard implements CanDeactivate<FunnelSettingsComponent> {
  canDeactivate(
    component: FunnelSettingsComponent,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.openConfirmExitModal();
  }
}
