export type ChatMessageAction = {
  content: string;
};

export type ChatMessage = {
  role: string;
  content: string;
  historyId?: string;
  createdAt?: string;
  actions?: ChatMessageAction[];
  refNr?: string;
  type?: string;
};

export enum ChatgptRole {
  System = 'system',
  User = 'user',
  Assistant = 'assistant',
  Function = 'function',
}

export enum ChatgptModelVersion {
  GPTV4 = 'gpt-4',
  GPTV3Turbo = 'gpt-3.5-turbo',
}

export type SendMessageData = {
  message: string;
  role: ChatgptRole;
  modelVersion: ChatgptModelVersion;
  context: ChatMessage[]; //last two messages excluding the newest
};

export type MessageHistoryRawData = {
  content: string;
  createdAt: string;
  historyId: string;
  role: ChatgptRole;
};

export type MessageHistory = {
  [key: string]: ChatMessage[];
};

export type MessageStreamHistory = {
  [key: string]: ChatStreamMessage[];
};

export interface ChatStreamMessage {
  role: string;
  content: string;
}

export interface ChatStreamRequest {
  message: string;
  conversationId: string;
  modelVersion: ChatgptModelVersion;
  context: ChatStreamMessage[];
}

export interface ChatCompletionChunk {
  id: string;
  choices: Array<ChatCompletionChunkChoice>;
  created: number;
  model: string;
  object: string;
}

export interface ChatCompletionChunkChoice {
  delta: ChatCompletionChunkChoiceDelta;
  finish_reason: 'stop' | 'length' | 'function_call' | 'content_filter' | null;
  index: number;
}

export interface ChatCompletionChunkChoiceDelta {
  content: string;
  role: ChatgptRole;
}
