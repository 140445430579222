import { Injectable } from '@angular/core';
import { Languages, LanguageService } from '@core/services/language.service';
import { PartnerConfig } from '@shared/interfaces/partner-config.interface';

@Injectable({
  providedIn: 'root',
})
export class PartnerLanguageCustomizationService {
  constructor(private languageService: LanguageService) {}

  public handlePartnerLanguageCustomization(partnerConfig: PartnerConfig): void {
    if (!partnerConfig) return;
    this.checkPartnerDefaultLanguage(partnerConfig.DEFAULT_LANGUAGE);
    this.checkPartnerLanguageSwitcherVisibility(partnerConfig.HIDE_LANGUAGE_SWITCHER);
  }

  private checkPartnerDefaultLanguage(defaultLanguage: Languages | null): void {
    if (!defaultLanguage) return;
    if (!this.languageService.isValidLanguage(defaultLanguage)) return;
  }

  private checkPartnerLanguageSwitcherVisibility(hideLanguageSwitcher: boolean): void {
    if (hideLanguageSwitcher) return;
    document.body.classList.remove('hide-langauge-switcher');
  }
}
