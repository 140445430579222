import { gql } from 'apollo-angular';

export const FindALlPromptDocument = gql`
  query findAllPrompts {
    findAllPrompts {
      id
      name
      description
      settingsIntegration
      promptText
      promptProperty
      category
      status
      settingsActive
      customPromptFields {
        customPromptId
        fieldId
        name
        label
        helpText
        fieldType
        optionValues
        defaultValueId
        defaultValue
        required
      }
    }
  }
`;
