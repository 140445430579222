import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { FunnelContentGeneratorService } from '../../services/funnel-content-generator.service';
import { Activity } from '@shared/models/activity.model';
import { Integration, IntegrationOutputData } from '@shared/models/integration.model';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { ContentGeneratorEventService } from '@modules/funnels/modules/funnel-manage/shared/services/content-generator-event.service';
import { CreditsService } from '@shared/services/credits.service';

@Component({
  selector: 'df-content-generator-modal',
  templateUrl: './content-generator-modal.component.html',
  styleUrls: ['./content-generator-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorModalComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly EOnboardingStep = EOnboardingStep;

  get asset(): ActivityAsset | undefined {
    return this.context.data?.asset;
  }

  get integration(): Integration | null {
    return this.context.data?.integration ?? null;
  }

  get activity(): Activity | null {
    return this.funnelContentGeneratorService.selectedActivity;
  }

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { asset: ActivityAsset; integration: Integration } }, number>,
    public readonly funnelContentGeneratorService: FunnelContentGeneratorService,
    private readonly changes: ChangeDetectorRef,
    public readonly onboardingService: OnboardingService,
    private readonly contentGeneratorEventService: ContentGeneratorEventService,
    private readonly creditsService: CreditsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.funnelContentGeneratorService.initForm(this.asset!, this.integration!);
    if (!this.asset?.integrationOutputs && !this.onboardingService.onboardingRunning)
      this.funnelContentGeneratorService.fetchGeneratedData(this.asset!, this.integration!);
    this.listenContentGeneratorEvents();
    this.listenOnboardingStepChange();
  }

  private listenContentGeneratorEvents(): void {
    this.sub.add(this.contentGeneratorEventService.getSubscription().subscribe(() => this.changes.detectChanges()));
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  close(): void {
    this.context.completeWith(1);
  }

  handleDataSelected(data: IntegrationOutputData): void {
    this.funnelContentGeneratorService.markOutputDataAsSelected(data, this.asset!);
  }

  handleDataSubmitted(): void {
    this.performGenerateContent(this.asset!, this.integration!);
  }

  private performGenerateContent(activityAsset?: ActivityAsset, integration?: Integration): void {
    if (integration && !this.creditsService.canGenerateContent(integration.creditsPrice)) {
      this.creditsService.showNotEnoughCreditsPopup();
      this.close();
      return;
    }
    this.funnelContentGeneratorService.generateContent(activityAsset, integration);
  }
}
