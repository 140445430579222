<div class="w-full h-full flex flex-nowrap justify-start items-stretch relative">
  <div class="hidden lg:flex w-64 tui-support-26-bg justify-center items-center p-4 flex-shrink-0">
    <img
      src="assets/img/illustration_onboarding_congrats.svg"
      class="w-auto max-w-90"
      alt="star"
    />
  </div>
  <div class="flex flex-col justify-start items-stretch md:pl-10 md:py-12 md:pr-16 flex-grow">
    <h1 class="tui-text_h4 mt-0 mb-6 tui-primary font-semibold">
      {{ 'Funnels.Onboarding.Congrats!' | translate }}
    </h1>
    <p
      class="tui-text_body-xl tui-text-03 mb-3"
      [innerHTML]="'Funnels.Onboarding.Now you can...' | translate"
    ></p>
    <p class="tui-text_body-m-6 font-semibold tui-text-05 mb-8">
      {{ 'Funnels.Onboarding.Provide informations about...' | translate }}
    </p>

    <div class="shadow-lg rounded-xl self-start mb-10 hidden md:block">
      <img
        class="rounded"
        src="assets/img/illustration_default_funnel.png"
        alt="default"
      />
      <div class="w-full px-3 pb-5 pt-2">
        <p class="tui-text_body-m-6 font-semibold p-0 m-0">
          {{ 'Funnels.Onboarding.Your Default funnel' | translate }}
        </p>
      </div>
    </div>

    <button
      tuiButton
      type="button"
      appearance="primary"
      size="l"
      (click)="close()"
      class="w-full"
    >
      <svg-icon src="assets/img/_ic_arrow_right.svg"></svg-icon>
      <span>{{ 'Funnels.Onboarding.Create your first project' | translate }}</span>
    </button>

    <img
      src="assets/img/onboarding-arrow.svg"
      alt="arrow"
      class="hidden md:block arrow"
    />
  </div>
</div>
