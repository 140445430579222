<div class="w-full sm:w-3/4 m-auto m-auto">
  <p
    class="tui-text_body-m-2 text-center mb-10 break-words"
    [innerHTML]="
      userService.User?.contextFunnel
        ? ('Funnels.Manage.You can add funnel to your profile or clone it to your funnel. What would you like to do?'
          | translate
            : {
                context: userService.User?.contextFunnel?.name,
                promotion: funnel.name
              })
        : ('Funnels.Manage.Do you want to add funnel to your profile?' | translate: { promotion: funnel.name })
    "
  ></p>
</div>
<div class="flex items-center mt-10 justify-center w-100">
  <button
    size="xl"
    tuiButton
    type="button"
    class="mr-4"
    appearance="outline"
    (click)="copyContent()"
    *ngIf="userService.User?.contextFunnel"
    [showLoader]="loadingCopy"
    [disabled]="loadingCopy || loadingClone"
  >
    <svg-icon
      [src]="'/assets/img/ic_copy.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'Funnels.Manage.clone' | translate }}
  </button>

  <button
    size="xl"
    tuiButton
    type="button"
    class="mr-4"
    appearance="outline"
    (click)="cancel()"
    *ngIf="!userService.User?.contextFunnel"
  >
    <svg-icon
      [src]="'/assets/img/ic-close.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'cancel' | translate }}
  </button>

  <button
    size="xl"
    tuiButton
    type="button"
    (click)="clone()"
    appearance="primary"
    [showLoader]="loadingClone"
    [disabled]="loadingClone || loadingCopy"
  >
    <svg-icon
      [src]="'/assets/img/ic_plus.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'Funnels.Manage.add to profile' | translate }}
  </button>
</div>
