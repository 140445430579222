import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { FunnelListTypeEnum } from '@modules/graphql/graphql-types';
import { HeaderTab } from '@shared/modules/ui/components/header-tabs/header-tabs.component';
import { UserService } from '@shared/services/user.service';

export const funnelsListTabsConst = (
  n: NavigateService,
  t: TranslateService,
  userService: UserService,
): HeaderTab[] => {
  const tabs = [
    {
      name: t.instant('Funnels.All Funnels'),
      route: n.getPath('funnels/list/all'),
      type: FunnelListTypeEnum.All,
    },
    {
      name: t.instant('Funnels.Favourite'),
      route: n.getPath('funnels/list/favourites'),
      type: FunnelListTypeEnum.Favourite,
    },
  ];

  if (!userService.User?.isSemrushPlan()) {
    tabs.push({
      name: t.instant('Funnels.Shared for me'),
      route: n.getPath('funnels/list/shared-for-me'),
      type: FunnelListTypeEnum.SharedForMe,
    });
  }

  return tabs;
};
