<div class="w-full sm:w-3/4 m-auto m-auto">
  <p
    class="tui-text_body-m-2 text-center mb-10 break-words"
    [innerHTML]="
      'Tactics.Manage.Draft tactic named already exist. What would you like to do with it?'
        | translate: { name: data.name }
    "
  ></p>
</div>
<div class="flex items-center mt-10 justify-end">
  <button
    size="xl"
    tuiButton
    type="button"
    class="mr-4"
    appearance="outline"
    (click)="edit()"
  >
    <svg-icon
      [src]="'/assets/img/ic-edit.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'edit existing' | translate }}
  </button>

  <button
    size="xl"
    tuiButton
    type="button"
    (click)="override()"
    appearance="primary"
  >
    <svg-icon
      [src]="'/assets/img/ic_repeat.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'overwrite' | translate }}
  </button>
</div>
