import { gql } from 'apollo-angular';

export const IntegrationInputDataFragment = gql/* GraphQL*/ `
  fragment IntegrationInputData on InputDataGraphql {
    id
    value
    createdAt
    refNr
    funnel {
      id
    }
    configuration {
      id
      type
      metadata
      property
      validationPatterns {
        id
        rules
      }
    }
  }
`;
