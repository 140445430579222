<df-funnel-manage-tactic-preview
  *tuiSidebar="showModal; direction: 'left'"
  [funnelTactic]="funnelTactic"
  [skipServerRequest]="true"
  (onClose)="toggleTacticPreview(false)"
>
</df-funnel-manage-tactic-preview>

<h1
  (click)="toggleTacticPreview(true)"
  class="tui-text-01 tui-text_h5 line-clamp-3 break-words mt-2"
  [ngClass]="{ 'tui-text-04': funnelTactic.isDone }"
>
  {{ funnelTactic.name || funnelTactic.tactic.name }}
</h1>
