import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'df-statement-input-error',
  templateUrl: './statement-input-error.component.html',
  styleUrls: ['./statement-input-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementInputErrorComponent {
  @Input() control: AbstractControl | null = null;
  @Input() formSubmitted = false;
  @Input() skipTuiInputCheck = false;
  @Input() isInputError = false;
}
