<form
  [formGroup]="form"
  class="statement-container container overflow-hidden m-auto flex flex-col justify-start items-start pt-10 xl:pt-10"
>
  <h2
    *ngIf="!hideFooter"
    class="tui-text_h3 tui-accent-07"
  >
    {{ 'Statement.Fill in statement about your business' | translate }}
  </h2>
  <df-page-list-loader *ngIf="statementService.loading$ | async"></df-page-list-loader>
  <div
    class="statement-text mt-[2.875rem] public-statement"
    [ngClass]="{ 'mb-8': statementService.forScreenshot }"
    *ngIf="(statementService.loading$ | async) === false"
    #statementContainer
  >
    <div class="w-full">
      {{ 'Statement.Our' | translate }}
      <df-statement-select-wrapper>
        <df-statement-select
          #businessType
          class="ml-1"
          [name]="'Statement.business type' | translate"
          [form]="form"
          [maxItems]="1"
          [controlName]="EStep1Form.businessTypeId"
          [items]="statementParametersService.businessType"
        >
        </df-statement-select>
        <df-statement-inline-value
          [controlName]="EStep1Form.businessTypeId"
          [form]="form"
          [component]="businessType"
        >
        </df-statement-inline-value>
      </df-statement-select-wrapper>
      <df-statement-input
        class="mb-4 md:mb-0"
        [ngClass]="{ screenshot: statementService.forScreenshot }"
        [form]="form"
        [controlName]="EStep1Form.productName"
        [name]="'Statement.product name' | translate"
        [container]="statementContainer"
      >
      </df-statement-input>
      {{ 'Statement.helps' | translate }}
      <br class="sm:hidden" />
      <div class="inline-flex mt-4 mb-0 md:mt-0 md:-mb-2 md:ml-4 md:mr-4">
        <tui-filter
          tuiFilterMaxItems
          #tuiModel
          class="inline-block"
          [ngClass]="{ screenshot: statementService.forScreenshot }"
          size="s"
          [tuiFilter]="tuiModel"
          [maxItems]="1"
          [content]="filterContent"
          [identityMatcher]="identityMatcher"
          [formControlName]="EStep1Form.targetGroupsTypesIds"
          [items]="statementParametersService.model"
        >
          <ng-template
            let-data
            #filterContent
          >
            {{ data.name }}
          </ng-template>
        </tui-filter>
      </div>
      <df-statement-select-wrapper>
        <df-statement-select
          #segments
          class="ml-2"
          [name]="'Statement.target group' | translate"
          [form]="form"
          [maxItems]="40"
          [controlName]="isB2C() ? EStep1Form.segmentsB2cIds : isB2B() ? EStep1Form.segmentsB2bIds : ''"
          [items]="isB2C() ? statementParametersService.segmentB2c : statementParametersService.segmentB2b"
        >
        </df-statement-select>
        <df-statement-inline-value
          *ngIf="isB2B() || isB2C()"
          [controlName]="isB2C() ? EStep1Form.segmentsB2cIds : EStep1Form.segmentsB2bIds"
          [form]="form"
          [component]="segments"
        ></df-statement-inline-value>
      </df-statement-select-wrapper>
      .

      {{ 'Statement.It’s most important advantages are' | translate }}:
      <df-statement-select-wrapper>
        <df-statement-select
          #valueProposition
          class="ml-1"
          [name]="'Statement.value proposition' | translate"
          [form]="form"
          [maxItems]="3"
          [controlName]="EStep1Form.valuePropositionsIds"
          [items]="statementParametersService.valueProposition"
        >
        </df-statement-select>
        <df-statement-inline-value
          [controlName]="EStep1Form.valuePropositionsIds"
          [form]="form"
          [component]="valueProposition"
        ></df-statement-inline-value>
      </df-statement-select-wrapper>
      ,

      {{ 'Statement.but people might be afraid of' | translate }}:
      <df-statement-select-wrapper>
        <df-statement-select
          #customerPains
          class="ml-1"
          [name]="'Statement.customers pain' | translate"
          [form]="form"
          [maxItems]="40"
          [controlName]="EStep1Form.customersPainsIds"
          [items]="statementParametersService.customerSPain"
        >
        </df-statement-select>
        <df-statement-inline-value
          [controlName]="EStep1Form.customersPainsIds"
          [form]="form"
          [component]="customerPains"
        >
        </df-statement-inline-value>
      </df-statement-select-wrapper>
      .

      {{ 'Statement.to help me with' | translate }}:
      <df-statement-select-wrapper>
        <df-statement-select
          #problems
          class="ml-0 md:ml-1"
          [name]="'Statement.problems' | translate"
          [form]="form"
          [maxItems]="40"
          [controlName]="EStep1Form.problemsIds"
          [items]="statementParametersService.problem"
        >
        </df-statement-select>
        <df-statement-inline-value
          [controlName]="EStep1Form.problemsIds"
          [form]="form"
          [component]="problems"
        >
        </df-statement-inline-value>
      </df-statement-select-wrapper>
      .
    </div>
  </div>
  <div *ngIf="statementService.forScreenshot">
    <img
      [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
      alt="Digital First"
    />
  </div>
  <df-statement-step-footer
    *ngIf="!hideFooter"
    [isPublic]="true"
    [step]="1"
    [loading]="loading"
    [nextText]="'Statement.Save statement' | translate"
    (onNext)="openPublicSaveStatement()"
    (onSkip)="statementService.skipStatement()"
  ></df-statement-step-footer>
</form>
