import { environment } from '@environments/environment';

export class Config {
  static readonly DATE_DOTS = 'dd.MM.yyyy';

  static readonly DEBUG = !!environment.DEBUG;
  static readonly PLATFORM_SIGNATURE = environment.SIGNATURE;
  static readonly BASE_URL = environment.APP_URL;
  static readonly API = environment.API;
  static readonly API_WS = environment.API_WS;
  static readonly API_RECOMMENDATIONS = environment.API_RECOMMENDATIONS;
  static readonly API_RECOMMENDATIONS_LOGIN = environment.API_RECOMMENDATIONS_LOGIN;
  static readonly API_RECOMMENDATIONS_PASSWORD = environment.API_RECOMMENDATIONS_PASSWORD;
  static readonly GTAG = environment.GTAG;
  static readonly ASSETS = environment.ASSETS;
  static readonly MARKETPLACE_DOMAIN = environment.MARKETPLACE_DOMAIN;
  static readonly MARKETPLACE_CLIENT_ID = environment.MARKETPLACE_CLIENT_ID;
  static readonly MARKETPLACE_URL = environment.MARKETPLACE_URL;

  static readonly SYSTEM_NAME = 'Digitalfirst.ai';
  static readonly SYSTEM_LOGO_PATH = '/assets/img/Logo-dark.svg';
  static readonly CONTACT_EMAIL = environment.CONTACT_EMAIL;
  static readonly MAIN_VIEW = 'home';
  static readonly DEFAULT_TRIAL_PLAN = 'Unlimited';

  static readonly TERMS = '';
  static readonly PRIVACY_POLICY = 'https://www.digitalfirst.ai/legal/privacy';

  static readonly ACCEPTED_IMAGES_EXTENSIONS = '.jpg,.jpeg,.png,.bmp,.gif'; // regex.ts fileImage
  static readonly FORBIDDEN_BENCHMARKS_EXTENSIONS = '.exe,.mcs,.sh,.zip,.7z,.gz,.rar,.tar,.bz,.bz2,.php,.js,.bat'; // regex.ts fileBanned

  static readonly MAX_IMG_SIZE = 10000000;
  static readonly MAX_FILE_SIZE = 15000000;

  static readonly TALLY_URL = 'https://tally.so/';
  // Request a tactic
  static readonly TALLY_RAT = 'r/n0p20n';

  //  Request a statement parameter - OLD
  static readonly TALLY_RASP = 'rasp';
  //  Request a statement parameter
  static readonly TALLY_RASP_URLS = {
    BT: 'raspbt',
    TG: 'rasptg',
    CP: 'raspcp',
    P: 'raspp',
    VP: 'raspvp',
  };

  static readonly MAX_PRICE = 20000000;
  static readonly MAX_STRIPE_PRICE = 999999;
  static readonly MIN_STRIPE_PRICE = 0.5;

  static readonly UNSPLASH_URL = 'https://unsplash.com/';
  static readonly UNSPLASH_APP_NAME = 'DigitalFirstAI';
  static readonly API_UNSPLASH_URL = 'https://api.unsplash.com/';
  static readonly API_UNSPLASH_IMAGES_URL = 'https://images.unsplash.com/';
  static readonly API_UNSPLASH_ACCESS_KEY = 'Kpkg1q0ZaCfux288-oS7RjxsluyRXZG_NoXnRCLEW08';

  static readonly BOOK_A_DEMO_URL = 'https://meetings-eu1.hubspot.com/meetings/vlamot-majewska';
}
