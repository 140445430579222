<div class="w-full border border-solid tui-primary-border rounded-3xl tui-base-01-bg overflow-hidden">
  <div class="w-full h-full flex flex-col lg:flex-row justify-start items-start lg:items-center p-5">
    <img
      src="assets/img/onboarding-fire.png"
      alt="fire"
      class="mr-6 hidden lg:block"
    />
    <div class="lg:flex-grow mb-4 lg:mb-0">
      <h3 class="tui-text_body-xl font-semibold mb-2 lg:max-w-lg">
        {{ 'Funnels.Onboarding.Flow for campaign ready!' | translate }}
      </h3>
      <p class="text-sm md:text-base">
        {{ 'Funnels.Onboarding.You can do more!' | translate }}
      </p>
      <ul class="text-sm md:text-base list-inside list-disc">
        <li>
          {{ 'Funnels.Onboarding.Over 100 tactics in our library' | translate }}
        </li>
        <li>{{ 'Funnels.Onboarding.Create your own tactics' | translate }}</li>
      </ul>
    </div>
    <button
      tuiButton
      type="button"
      size="xl"
      appearance="primary"
      (click)="buttonClick.emit(true)"
    >
      {{ 'Funnels.Onboarding.Generate some Content' | translate }}
    </button>
  </div>
</div>
