<p class="text-center">
  <a
    tuiLink
    [routerLink]="n.getPath('sign-in')"
    (click)="close()"
    class="text-center mb-10"
  >
    {{
      'Auth.Reset Password.click here to continue in :systemName'
        | translate: { system: xPartnerService.partner.SYSTEM_NAME }
    }}
  </a>
</p>
