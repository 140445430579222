import { Injectable } from '@angular/core';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TacticBadgeEnum, TacticInputGraphql } from '@modules/graphql/graphql-types';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { CreateTacticMutation } from '@modules/tactics/shared/graphql/mutations/create-tactic.mutation.generated';
import { Tactic } from '@shared/models/tactic.model';
import { UserService } from '@shared/services/user.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { CreateFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/create-funnel-tactic.mutation.generated';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { User } from '@shared/models/user.model';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { Funnel } from '@shared/models/funnel.model';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';

@Injectable()
export class FunnelCreateTacticService {
  readonly form: UntypedFormGroup = new UntypedFormGroup({
    [EFunnelCreateTacticForm.name]: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(200),
    ]),
    [EFunnelCreateTacticForm.funnelSteps]: new UntypedFormControl([], [Validators.required, Validators.minLength(1)]),
    [EFunnelCreateTacticForm.funnelStepsIds]: new UntypedFormControl(
      [],
      [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
    ),
    [EFunnelCreateTacticForm.another]: new UntypedFormControl(false, [Validators.required]),
  });
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  constructor(
    private readonly tacticGraphqlService: TacticGraphqlService,
    private readonly userService: UserService,
    private readonly s: SnackbarService,
    private readonly t: TranslateService,
    private readonly funnelManageService: FunnelManageService,
    private readonly eventService: EventsService,
  ) {}

  createTactic(): Observable<FunnelTactic> {
    this._loading$.next(true);
    const user: User = this.userService.User!;
    return this.tacticGraphqlService.createTactic(this.getTacticInput()).pipe(
      catchError((error: ApolloError) => {
        this.s.defaultError();
        this._loading$.next(false);
        return throwError(error);
      }),
      map((res: FetchResult<CreateTacticMutation>) => {
        const tactic: Tactic = new Tactic(res.data?.createTactic);
        this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.TacticAdd, {
          userID: user?.id,
        });

        if (tactic.id === res.data?.createTactic.owner?.firstTactic?.id) {
          this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.TacticNew, {
            userID: user?.id,
          });
        }
        return tactic;
      }),
      switchMap((tactic: Tactic) =>
        this.tacticGraphqlService.createFunnelTactic({
          funnelId: user.contextFunnel.id,
          tacticId: tactic.id,
          stepId: tactic.funnelSteps[0].id,
          position: 0,
        }),
      ),
      catchError((error: ApolloError) => {
        this.s.defaultError();
        this._loading$.next(false);
        return throwError(error);
      }),
      map((res: FetchResult<CreateFunnelTacticMutation>) => {
        const funnelTactic: FunnelTactic = new FunnelTactic(res.data?.createFunnelTactic);
        this.s.success(this.t.instant('Funnels.Manage.Tactic created and added successfully!'));
        user?.contextFunnel.tactics.push(funnelTactic);
        this.userService.User = user;
        const funnel: Funnel = this.funnelManageService.funnel!;
        funnel.tactics = funnel.tactics.map((tactic) => {
          tactic.position = tactic.step.id === funnelTactic.step.id ? tactic.position + 1 : tactic.position;
          return tactic;
        });
        funnel.tactics.unshift(funnelTactic);
        this.funnelManageService.funnel = funnel;
        this.funnelManageService.recalculateStatsInFunnel();
        this._loading$.next(false);
        return funnelTactic;
      }),
    );
  }

  private getTacticInput(): TacticInputGraphql {
    return {
      name: this.form.get(EFunnelCreateTacticForm.name)?.value,
      categoriesIds: [],
      segmentsIds: [],
      effectsIds: [],
      funnelStepsIds: this.form.get(EFunnelCreateTacticForm.funnelStepsIds)?.value ?? [],
      badge: TacticBadgeEnum.NoData,
    };
  }
}

export enum EFunnelCreateTacticForm {
  name = 'name',
  funnelSteps = 'funnelSteps',
  funnelStepsIds = 'funnelStepsIds',
  another = 'another',
}
