import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActionTypeEnum } from '@modules/business-data/business-data.model';

@Component({
  selector: 'df-write-description-form',
  templateUrl: './write-description-form.component.html',
  styleUrl: './write-description-form.component.scss',
})
export class WriteDescriptionFormComponent {
  type = ActionTypeEnum.WRITE_DESCRIPTION;
  @Input() form!: FormGroup;

  get control() {
    return this.form.get(this.type);
  }
}
