import { TuiFileLike } from '@taiga-ui/kit';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';

export const rejectedImages = (
  e: TuiFileLike | readonly TuiFileLike[],
  s: SnackbarService,
  t: TranslateService,
  extensions: string = Config.ACCEPTED_IMAGES_EXTENSIONS,
  size = 10,
) => {
  if (!Array.isArray(e)) e = [e as any];

  if (e.length) {
    if (e[0].content?.toString().startsWith('Wrong file format')) {
      s.error(
        t.instant('Wrong file extensions. We accept only: {{extensions}}.', {
          extensions,
        }),
      );
    }
    if (e[0].content?.toString().startsWith('File exceeds size')) {
      s.error(t.instant('file too big error', { size }));
    }
  }
};
