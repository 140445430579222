<div class="w-full h-full flex flex-col flex-nowrap justify-start items-stretch">
  <df-onboarding-step-bar
    [ngClass]="{ 'custom-fixed': !isMarketingCampaignUser }"
    class="flex-shrink-0 mb-8"
    [activeIndex]="3"
    [alignLeft]="true"
  ></df-onboarding-step-bar>

  <df-packets
    class="h-padding"
    *ngIf="!isMarketingCampaignUser"
  ></df-packets>
  <df-packets-campaign *ngIf="isMarketingCampaignUser"></df-packets-campaign>
</div>

<button
  data-test="logout-button"
  tuiButton
  class="absolute top-4 right-3 md:right-7 lg:right-8"
  [ngClass]="{ 'custom-btn': !isMarketingCampaignUser }"
  type="button"
  appearance="outline"
  (click)="logout()"
>
  <svg-icon
    [src]="'/assets/img/turn-off.svg'"
    class="mr-2"
  ></svg-icon>
  {{ 'Header.logout' | translate }}
</button>
