import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TUI_FILES_UPLOAD_FILES_EXTENTION } from '@modules/business-data/business-data.const';
import { ActionTypeEnum } from '@modules/business-data/business-data.model';
import { TuiFileLike } from '@taiga-ui/kit';

@Component({
  selector: 'df-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrl: './upload-files.component.scss',
})
export class UploadFilesComponent {
  type = ActionTypeEnum.UPLOAD_FILES;
  maxFileSize = 100 * 1000 * 1000;

  @Input() form!: FormGroup;

  rejectedFiles: readonly TuiFileLike[] = [];
  TUI_FILES_UPLOAD_FILES_EXTENTION = TUI_FILES_UPLOAD_FILES_EXTENTION;

  getControl() {
    return this.form.get(this.type) as FormControl;
  }

  onReject(files: TuiFileLike | readonly TuiFileLike[]): void {
    this.rejectedFiles = [...this.rejectedFiles, ...(files as TuiFileLike[])];
  }

  removeFile({ name }: File): void {
    const control = this.getControl();
    control.setValue(control.value?.filter((current: File) => current.name !== name) ?? []);
  }

  clearRejected({ name }: TuiFileLike): void {
    this.rejectedFiles = this.rejectedFiles.filter((rejected) => rejected.name !== name);
  }
}
