export enum EActivityBenchmarkForm {
  activityId = 'activityId',
  benchmarkId = 'benchmarkId',
  benchmarkTypeId = 'benchmarkTypeId',
  link = 'link',
  file = 'file',
  benchmark = 'benchmark',
  state = 'state',
  attachmentId = 'attachmentId',
  uuid = 'uuid',
}
