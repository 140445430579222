import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { Component, OnInit } from '@angular/core';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'df-logout',
  template: '',
  styles: [''],
})
export class LogoutComponent implements OnInit {
  Config = Config;

  constructor(
    private n: NavigateService,
    private authStorageService: AuthStorageService,
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  ngOnInit(): void {
    this.authStorageService.logout();
    this.n.go('sign-in');
    this.s.error(
      this.t.instant('Auth.Login.Your account is blocked, please contact with hello@digitalfirst.ai', {
        email: Config.CONTACT_EMAIL,
      }),
      6000, // miliseconds - close after
    );
  }
}
