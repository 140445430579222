import { IUnsplashPhoto } from './interfaces/unsplash-photo.interface';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  Inject,
  isDevMode,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FunnelGraphicsGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-graphics/funnel-graphics-generator.service';
import { TuiDialog } from '@taiga-ui/cdk';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { ContentGeneratorUnsplashModalService } from './content-generator-unsplash-modal.service';
import { ContentGeneratorEventEnum } from '@modules/funnels/shared/enums/content-generator-selection-type.enum';
import { EUnsplashForm } from './enums/unsplash-form.enum';
import { Subscription } from 'rxjs';
import { Config } from '@shared/configs/config';
import { ContentGeneratorEventService } from '../../../../services/content-generator-event.service';

@Component({
  selector: 'df-content-generator-unsplash-modal',
  templateUrl: './content-generator-unsplash-modal.component.html',
  styleUrls: ['./content-generator-unsplash-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorUnsplashModalComponent implements OnInit, OnDestroy {
  readonly Config = Config;
  readonly ContentGeneratorEventEnum = ContentGeneratorEventEnum;
  readonly EUnsplashForm = EUnsplashForm;
  readonly unsplashLinkEnding = `utm_source=${Config.UNSPLASH_APP_NAME}&utm_medium=referral`;
  readonly unsplashUrl = Config.UNSPLASH_URL + '?' + this.unsplashLinkEnding;
  sub: Subscription = new Subscription();
  form: UntypedFormGroup;
  searchText = '';
  pageNr = 1;
  totalPages = 0;
  errorMessage = '';

  photosList: Array<IUnsplashPhoto> = [];
  constructor(
    private changes: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private unsplashModalService: ContentGeneratorUnsplashModalService,
    public readonly funnelGraphicsGeneratorService: FunnelGraphicsGeneratorService,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { fieldId: number } }, number>,
    private readonly contentGeneratorEventService: ContentGeneratorEventService,
  ) {
    this.form = this.fb.group({
      [EUnsplashForm.SEARCH]: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.onSearchChange();
  }

  onSearchChange() {
    const sub = this.form.controls[EUnsplashForm.SEARCH].valueChanges.subscribe();
    this.sub.add(sub);
  }

  saveSelection(photo: IUnsplashPhoto) {
    // field id from tuiDialog context
    const dialogData = this.context.data as { fieldId: number };
    if (dialogData.fieldId) {
      this.unsplashModalService.downloadFile(photo.regular).subscribe({
        next: (response) => {
          this.funnelGraphicsGeneratorService.form.controls[dialogData.fieldId].patchValue({
            src: photo.regular,
            name: photo.description + '.' + response.type.replace('image/', ''),
            size: response.size,
            type: response.type,
            triggerLink: photo.triggerDownloadLink,
          });
          this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
        },
        error: (err) => console.log(err),
      });
    }

    this.closeModal();
  }

  searchPhotos(pageNr = 1) {
    this.prepareSearchParams(pageNr);
    const sub = this.unsplashModalService.getSearchResult(this.searchText, this.pageNr).subscribe({
      next: (res) => {
        this.totalPages = res.total_pages;
        res.results.forEach((r) => {
          const name = r.urls?.raw.replace(Config.API_UNSPLASH_IMAGES_URL, '').split('?')[0];
          this.photosList.push({
            raw: r.urls.raw,
            regular: r.urls.regular,
            small: r.urls.small,
            description: name,
            userName: r.user.name,
            userLink: r.user.links.html,
            triggerDownloadLink: r.links.download_location,
          });
        });
        this.changes.detectChanges();
        this.errorMessage = '';
      },
      error: (err) => {
        if (isDevMode()) {
          this.photosList = [];
          this.totalPages = 0;
          this.errorMessage = '(Unsplash error)';
          this.changes.detectChanges();
          console.log('UNSPLASH error' + JSON.stringify(err));
        }
      },
    });
    this.sub.add(sub);
  }

  prepareSearchParams(pageNr: number) {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.changes.detectChanges();
      return;
    }
    this.searchText = this.form.controls[EUnsplashForm.SEARCH].value;
    if (pageNr === 1) {
      this.photosList = []; //reset for new search
    }
    this.pageNr = +pageNr;
  }

  loadMore() {
    +this.pageNr++;
    this.searchPhotos(this.pageNr);
  }

  closeModal() {
    this.unsplashModalService.tuiDialogSub$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.closeModal();
    this.sub.unsubscribe();
  }
}
