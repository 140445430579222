import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const LoginWithSocialMediaAccountDocument = gql`
  mutation loginWithSocialMediaAccount($input: SocialLoginInputGraphql!) {
    loginWithSocialMediaAccount(input: $input) {
      token
      eventType
      user {
        ...UserStorage
      }
    }
  }
  ${UserStorageFragment}
`;
