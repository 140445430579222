<div class="w-full h-full flex flex-col flex-nowrap justify-start items-stretch">
  <df-onboarding-step-bar
    class="flex-shrink-0"
    [activeIndex]="2"
  ></df-onboarding-step-bar>
  <div class="flex-grow-0 overflow-y-auto pt-16 pb-10 px-4">
    <div class="w-full max-w-lg m-auto relative">
      <df-full-list-loader *ngIf="loading$ | async"></df-full-list-loader>
      <form
        [formGroup]="form"
        [validate-form]="form"
        *ngIf="(loading$ | async) === false"
        (ngSubmit)="submit()"
      >
        <h2 class="tui-text_h5 tui-text-03">
          {{ 'Auth.Onboarding.For whom you work mostly' | translate }}
        </h2>
        <div class="tui-form__row">
          <div
            data-test="work-for-option"
            *ngFor="let option of workForOptions"
            class="work-for-option flex flex-row justify-start items-center relative mt-3 gap-x-3 py-6 pr-10 pl-3 sm:p-3"
            [class.checked]="form.get('worksFor')?.value === option"
            [class.error]="checkboxError"
            (click)="setWorkFor(option, $event)"
          >
            <img
              class="flex-shrink-0 hidden sm:block"
              [src]="'assets/img/illustration_setup_' + option + '.svg'"
              [alt]="option"
            />
            <div>
              <h6 class="tui-text_h6 tui-text-03 mb-2 font-semibold">
                {{ 'Auth.Onboarding.working for ' + option | translate }}
              </h6>
              <p class="tui-text-body-s-2 tui-text-02">
                {{ 'Auth.Onboarding.working for subtitle ' + option | translate }}
              </p>
            </div>
            <div class="absolute top-3 right-3">
              <tui-primitive-checkbox class="work-for-option__checkbox"></tui-primitive-checkbox>
            </div>
          </div>
          <df-error-input
            [control]="form.get('worksFor')"
            [manualShowingError]="checkboxError"
          ></df-error-input>
        </div>

        <h2 class="tui-text_h5 tui-text-03 my-4">
          {{ 'Auth.Onboarding.Set Up Account' | translate }}
        </h2>

        <form
          [formGroup]="parametersForm"
          [validate-form]="parametersForm"
        >
          <div
            class="tui-form__row"
            *ngFor="let question of parametersKeys"
          >
            <p class="mb-1">
              {{ 'Auth.Onboarding.question ' + question | translate }}
            </p>

            <tui-select
              [valueContent]="stringify"
              [formControlName]="question"
              [attr.data-error-control]="question"
              [tuiTextfieldLabelOutside]="true"
            >
              {{ 'Auth.Onboarding.Select an option' | translate }}
              <tui-data-list *tuiDataList>
                <button
                  tuiOption
                  *ngFor="let item of parameters[question].options"
                  [value]="item"
                >
                  {{ item.name }}
                </button>
              </tui-data-list>
            </tui-select>
            <df-error-input [control]="parametersForm.get(question)"></df-error-input>

            <ng-container *ngIf="parameters[question].other && getOtherSelected(question)">
              <tui-input
                [formControlName]="question + '_other'"
                [attr.data-error-control]="question + '_other'"
                [tuiTextfieldLabelOutside]="true"
              >
              </tui-input>
              <df-error-input [control]="parametersForm.get(question + '_other')"></df-error-input>
            </ng-container>
          </div>
        </form>
        <div class="w-full flex justify-center md:justify-end">
          <button
            data-test="save-and-select-your-plan-button"
            class="w-full md:w-auto"
            tuiButton
            appearance="primary"
            type="submit"
            size="l"
            [showLoader]="!!(submitLoading$ | async)"
            [disabled]="!!(submitLoading$ | async)"
            iconRight="tuiIconChevronRight"
          >
            {{
              (userService.User?.hasActivePlan()
                ? 'Auth.Onboarding.Save & Finish'
                : 'Auth.Onboarding.Save & Select your plan'
              ) | translate
            }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
