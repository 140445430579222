import { gql } from 'apollo-angular';

export const UpdateCustomPromptDocument = gql`
  mutation updateCustomPrompt($updateCustomPromptInput: UpdateCustomPromptInputGraphql!) {
    updateCustomPrompt(updateCustomPromptInput: $updateCustomPromptInput) {
      name
      description
      settingsIntegration
      promptText
      promptProperty
      category
      status
      settingsActive
      customPromptFields {
        customPromptId
        fieldId
        name
        label
        helpText
        fieldType
        optionValues
        defaultValueId
        defaultValue
        required
        order
      }
    }
  }
`;
