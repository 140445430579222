import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { AuthModalMode } from '@modules/public/shared/components/register-modal/auth-modal-mode.enum';
import { SubscriptionPlanOutputGraphql } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthModalComponent {
  viewMode: AuthModalMode = AuthModalMode.REGISTER;
  readonly viewModeEnum: typeof AuthModalMode = AuthModalMode;

  get planToBuy(): SubscriptionPlanOutputGraphql | null {
    return this.context.data?.plan ?? null;
  }
  get trial(): boolean {
    return !!this.context.data?.trial;
  }

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { plan: SubscriptionPlanOutputGraphql; trial: boolean } }, boolean>,
    private changes: ChangeDetectorRef,
  ) {}

  navigateToSignInHandler(): void {
    this.viewMode = AuthModalMode.LOGIN;
    this.changes.detectChanges();
  }

  navigateToSignUpHandler(): void {
    this.viewMode = AuthModalMode.REGISTER;
    this.changes.detectChanges();
  }

  authorizedUserHandler(): void {
    this.context.completeWith(true);
  }
}
