<div class="statement-select w-full md:w-auto flex items-center relative">
  <div *ngFor="let item of items">
    <button
      tuiButton
      type="button"
      [appearance]="selectedValue?.id === item.id ? 'secondary' : 'outline'"
      (click)="setNewValue(item)"
      class="tile-wrapper"
    >
      <span> {{ 'Statement.Form.' + item.name | translate }}</span>
    </button>
  </div>
  <df-statement-input-error
    [control]="form.get(controlName)"
    [formSubmitted]="formSubmitted"
  ></df-statement-input-error>
</div>
