import { gql } from 'apollo-angular';

export const GetAssetsAdTypesDocument = gql(/* GraphQL*/ `query getAssetsAdTypes($input: AdTypesListInputGraphql!)
{
  getAssetsAdTypes(input: $input)
  {
    id
    name
  }
}

`);
