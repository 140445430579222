import { gql } from 'apollo-angular';

export const UpdateFunnelTacticDocument = gql`
  mutation updateFunnelTactic($name: String, $funnelTacticId: Int!) {
    updateFunnelTactic(name: $name, funnelTacticId: $funnelTacticId) {
      id
      name
    }
  }
`;
