<div class="w-8 flex justify-center items-center">
  <svg-icon
    [src]="icon"
    class="{{ valueColorClass }}"
  ></svg-icon>
</div>
<div class="flex flex-col items-start justify-center ml-3">
  <label class="tui-text-02 tui-text_body-m-10 capitalize font-normal">{{ label }}</label>
  <span class="tui-text_body-m-4 {{ valueColorClass }}">{{ value }}</span>
</div>
