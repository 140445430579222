import { gql } from 'apollo-angular';

export const GetCampaningDocument = gql`
  query getCampaning($funnelId: Int!) {
    getCampaning(funnelId: $funnelId) {
      id
      data
    }
  }
`;
