import { gql } from 'apollo-angular';

export const CreateTacticImageDocument = gql(/* GraphQL*/ `mutation createTacticImage($tacticId: Int!, $file: Upload!) {
  createTacticImage(tacticId: $tacticId, file: $file) {
    name
    file
    id
  }
}
`);
