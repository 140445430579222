import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { EActivityForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-form.enum';
import { isControlToRemove } from '@modules/tactics/modules/tactic-settings/shared/helpers/tactic-form.helper';

@Component({
  selector: 'df-tactic-settings-activities',
  templateUrl: './tactic-settings-activities.component.html',
  styleUrls: ['./tactic-settings-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticSettingsActivitiesComponent {
  readonly EActivityForm = EActivityForm;

  get activitiesArray(): UntypedFormArray {
    return this.tacticSettingsService.activitiesArray;
  }

  constructor(public readonly tacticSettingsService: TacticSettingsService) {}

  isActivityToRemove(activityControl: AbstractControl): boolean {
    return isControlToRemove(activityControl);
  }

  addActivity(): void {
    this.tacticSettingsService.createActivity();
  }

  drop(event: CdkDragDrop<string[]>) {
    this.tacticSettingsService.reorderActivity(event.previousIndex, event.currentIndex);
  }

  getActivityFormGroup(activityControl: AbstractControl): UntypedFormGroup {
    return activityControl as UntypedFormGroup;
  }
}
