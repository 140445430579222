import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { FetchResult } from '@apollo/client/core';
import { RegisterMutation } from '@modules/authorization/shared/graphql/mutations/register.mutation.generated';
import { Config } from '@shared/configs/config';
import { AbstractRegisterComponent } from '@modules/authorization/shared/abstracts/abstract-register.component';
import { Observable } from 'rxjs';
import { SocialLoginInputGraphql } from '@modules/graphql/graphql-types';
import { LoginWithSocialMediaAccountMutation } from '@modules/authorization/shared/graphql/mutations/ login-with-social-media-account.mutation.generated';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';

@Component({
  selector: 'df-public-register',
  templateUrl: './public-register.component.html',
  styleUrls: ['../../../authorization/shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicRegisterComponent extends AbstractRegisterComponent<RegisterMutation> {
  constructor(
    recaptchaService: ReCaptchaV3Service,
    changes: ChangeDetectorRef,
    private authGraphqlService: AuthGraphqlService,
    protected funnelService: FunnelGraphqlService,
  ) {
    super(changes, recaptchaService, funnelService);
  }

  redirectAfterSign() {
    window!.top!.location.href = Config.BASE_URL;
  }

  protected getRegisterRequest(token: string): Observable<FetchResult<RegisterMutation>> {
    return this.authGraphqlService.register(this.formGroup.value, token);
  }

  protected successSignUp(res: FetchResult<RegisterMutation>) {
    super.successSignUp(res);
    this.redirectAfterSign();
  }

  protected getSocialLoginRequest(
    loginData: SocialLoginInputGraphql,
  ): Observable<FetchResult<LoginWithSocialMediaAccountMutation>> {
    return this.authGraphqlService.loginWithSocialMediaAccount({
      ...loginData,
    });
  }
}
