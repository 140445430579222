import { OnboardingArrow } from '@shared/models/onboarding-step.model';
import { AbstractOnboardingFactory } from '@shared/abstracts/onboarding-factory.abstract';

export class OnboardingArrowFactory extends AbstractOnboardingFactory<OnboardingArrow> {
  renderElement(element: OnboardingArrow): Element {
    const arrow = document.createElement('img');
    arrow.src = 'assets/img/onboarding-arrow.svg';
    arrow.classList.add('onboarding-arrow');
    arrow.style.top =
      Math.abs(this.containerRect.top - this.anchorRect.top) + this.anchorRect.height * (element.distance / 100) + 'px';
    arrow.style.left = Math.abs(this.containerRect.left - this.anchorRect.left) + this.anchorRect.width + 16 + 'px';
    this.container.appendChild(arrow);
    return arrow;
  }
}
