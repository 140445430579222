import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TacticPreviewService } from '@modules/tactics/modules/tactic-preview/shared/services/tactic-preview.service';
import { Subscription, timer } from 'rxjs';
import { TacticController } from '@modules/tactics/shared/controllers/tactic.controller';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { FetchResult } from '@apollo/client/core';
import { RateTacticMutation } from '@modules/tactics/shared/graphql/mutations/rate-tatic.mutation.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Tactic } from '@shared/models/tactic.model';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ListEvent, ListEventType } from '@shared/services/base-list.service';
import { UserService } from '@shared/services/user.service';
import { VerificationStatusEnum } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-tactic-preview-content',
  templateUrl: './tactic-preview-content.component.html',
  styleUrls: ['./tactic-preview-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticPreviewContentComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  readonly VerificationStatusEnum = VerificationStatusEnum;
  @Output() buyTactic: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() manualCurtain: boolean | null = null; // null - autoCurtain, true/false - manual value of paidCurtain

  openedRate = false;
  sub: Subscription = new Subscription();
  tacticController: TacticController = new TacticController();
  aboutLinesLimit = 7;
  paidCurtain = true;
  history = history;

  @HostBinding('class.isPublicView')
  get hasValue() {
    return this.tacticPreviewService.isPublic;
  }

  constructor(
    public tacticPreviewService: TacticPreviewService,
    public tacticListService: TacticsListService,
    private changes: ChangeDetectorRef,
    private s: SnackbarService,
    private t: TranslateService,
    public n: NavigateService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.listenListRecordsUpdate();
    this.listenTacticUpdate();
    // this.checkTacticsArrayExists(); commented because of removing prev / next button to preview tactics
  }

  showCloseButton() {
    return location.href.includes('public/tactic');
  }

  checkTacticsArrayExists() {
    if (!this.tacticListService.records.length) {
      this.tacticListService.initService(true);
      this.tacticListService.getBannerTactics();
    }
  }

  listenListRecordsUpdate() {
    const sub = this.tacticListService.emitter.subscribe((e: ListEvent) => {
      switch (e.type) {
        case ListEventType.END_GETTING_RECORDS:
          this.changes.detectChanges();
          break;
      }
    });
    this.sub.add(sub);
  }

  listenTacticUpdate() {
    const sub = this.tacticPreviewService.tactic$.subscribe((tactic) => {
      this.paidCurtain =
        this.manualCurtain !== null
          ? this.manualCurtain
          : this.userService.User?.id !== tactic.owner?.id && tactic?.isPaid && !tactic?.purchased;
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  getSegment(): string {
    return this.tacticPreviewService.tactic?.segments?.length ? this.tacticPreviewService.tactic.segments[0].name : '';
  }

  getEffect(): string {
    return this.tacticPreviewService.tactic?.effects?.length ? this.tacticPreviewService.tactic.effects[0].name : '';
  }

  openRate() {
    timer(100).subscribe(() => {
      this.openedRate = true;
      this.changes.detectChanges();
    });
  }

  closeRate() {
    if (!this.openedRate) return;
    timer(100).subscribe(() => {
      this.openedRate = false;
      this.changes.detectChanges();
    });
  }

  rateTactic(value: number) {
    this.closeRate();
    this.tacticController
      .rateTactic(this.tacticPreviewService.tactic?.id!, value)
      .subscribe((res: FetchResult<RateTacticMutation>) => {
        this.s.success(this.t.instant('Tactics.Preview.Tactic rated successfully.'));
        this.tacticPreviewService.tactic = new Tactic(
          Object.assign(this.tacticPreviewService.tactic as Tactic, res.data?.rateTactic.tactic),
        );
        this.changes.detectChanges();
      });
  }

  toggleAbout(): void {
    this.aboutLinesLimit = this.aboutLinesLimit === 7 ? 10000 : 7;
  }

  ngOnDestroy() {
    this.tacticPreviewService.clearService();
    this.sub.unsubscribe();
  }
}
