import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { NotificationOutputGraphql } from '@modules/graphql/graphql-types';
import { NotificationsGraphqlService } from '@modules/notifications/shared/services/notifications-graphql.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-novu-notifications',
  templateUrl: './novu-notifications.component.html',
  styleUrls: ['./novu-notifications.component.scss'],
})
export class NovuNotificationsComponent extends AbstractSubscriptionComponent {
  list: NotificationOutputGraphql[] = [];
  constructor(
    private basePanelService: BasePanelService,
    private notificationsGraphqlService: NotificationsGraphqlService,
    private router: Router,
    public userService: UserService,
  ) {
    super();
  }

  recivedNotification(id: number) {
    // this.notificationsGraphqlService.markNotificationAsReceived(id).subscribe({
    //   next: () => {
    //     this.notificationList();
    //   },
    // });
  }

  closeNotification() {
    this.basePanelService.showNovuNotification(false);
  }

  notificationList(): void {
    // if (!this.userService?.User?.id || this.userService.User.isSemrushPlan()) return;
    // this.sub.add(
    //   this.notificationsGraphqlService.getUnreceivedNotifications().subscribe({
    //     next: (res: FetchResult<GetUnreceivedNotificationsQuery>) => {
    //       if (res?.data && res?.data?.getUnreceivedNotifications?.length > 0) {
    //         this.list = res?.data?.getUnreceivedNotifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).slice(0, 10);
    //         if (!localStorage.getItem('novuNotificationList')) {
    //           localStorage.setItem('novuNotificationList', JSON.stringify(this.list));
    //           this.basePanelService.showNovuNotification(true);
    //         } else if (localStorage.getItem('novuNotificationList') && localStorage.getItem('novuNotificationList') !== JSON.stringify(this.list)) {
    //           localStorage.removeItem('novuNotificationList');
    //           localStorage.setItem('novuNotificationList', JSON.stringify(this.list));
    //           this.basePanelService.showNovuNotification(true);
    //         }
    //       }
    //     },
    //   }),
    // );
  }
}
