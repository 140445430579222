import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { NavigateService } from '@core/routes/services/navigate.service';
import { UserService } from '@shared/services/user.service';
import { TuiDialogContext } from '@taiga-ui/core';

@Component({
  selector: 'df-statement-congratulations-modal',
  templateUrl: './statement-congratulations-modal.component.html',
  styleUrls: ['./statement-congratulations-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementCongratulationsModalComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<boolean>,
    public n: NavigateService,
    private userService: UserService,
  ) {}

  goToRecommendations(): void {
    this.n.go('funnels/f/d/:id/recommendations', {
      id: this.userService.User?.contextFunnel.id,
    });
    this.context.completeWith(true);
  }
}
