<div
  *ngIf="(trialDaysLeft !== null || trialEnded) && !barHidden && finishedOnboarding"
  [ngClass]="{
    'trial-finished-bg pr-12': trialDaysLeft === null && trialEnded
  }"
  class="relative text-center w-full flex flex-row justify-center items-center px-4 py-2 flex-shrink-0 trial-bg"
>
  <p class="tui-text-03 text-xs md:text-sm font-medium md:font-semibold">
    <span
      [innerHTML]="
        (!!trialDaysLeft && trialDaysLeft > 0 ? getTrialText() : 'Trial.Your free trial has ended...')
          | translate: { days: trialDaysLeft }
      "
    ></span>
    <a
      tuiLink
      href="https://calendly.com/maria-isabel-felix/30min?utm_source=app&utm_medium=banner&utm_campaign=trial_started"
      target="_blank"
    >
      <span class="text-xs md:text-sm font-medium md:font-semibold">{{
        'Trial.Book an onboarding call' | translate
      }}</span> </a
    >. {{ 'Trial.Or' | translate }}
    <a
      tuiLink
      [routerLink]="['', { outlets: { pricing: pricingUrl } }]"
      [skipLocationChange]="true"
    >
      <span class="underline font-medium md:font-semibold tui-primary text-xs md:text-sm">
        {{ 'Trial.select a plan right away' | translate }}</span
      >
    </a>
    {{ 'Trial.to continue using Digital First AI.' | translate }}
  </p>
  <button
    *ngIf="trialDaysLeft === null && trialEnded"
    tuiIconButton
    size="m"
    appearance="flat"
    type="button"
    icon="tuiIconRemoveLarge"
    class="absolute right-0 top-0"
    (click)="hideBar()"
  ></button>
</div>
