import { gql } from 'apollo-angular';
import { FullActivityFragment } from '@modules/tactics/shared/graphql/fragments/full-activity.fragment';

export const EditTacticFragment = gql`
  fragment EditTactic on TacticOutputGraphql {
    id
    name
    target
    publiclyVisible
    categories {
      name
      id
    }
    currentUserPermission
    segments {
      name
      id
    }
    effects {
      name
      id
    }
    funnelSteps {
      name
      id
      type
    }
    type {
      id
      name
    }
    levelOfAdvancement {
      id
      name
    }
    requiresBudget
    tacticDetails
    owner {
      id
      firstName
      lastName
      avatar {
        file
      }
      firstTactic {
        id
      }
    }
    rate
    isPrivate
    imageUrl
    price
    currency
    isPaid
    root {
      id
      isPaid
      price
      currency
      owner {
        id
        firstName
        lastName
        avatar {
          file
        }
      }
    }
    copyForSale
    inMyFunnel
    activities {
      ...FullActivity
    }
    shares {
      id
      email
    }
    activitiesCount
    assetsCount
    benchmarksCount
    purchased
    sharetribeListingUuid
    verificationStatus
    createdAt
    image {
      id
      file
    }
  }
  ${FullActivityFragment}
`;
