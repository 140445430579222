import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Integration, IntegrationInputConfiguration } from '@shared/models/integration.model';
import { InputTypeEnum } from '@modules/graphql/graphql-types';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { getDefaultControlHelper } from '@shared/helpers/content-generator.helper';
import { TuiInputComponent, TuiSelectComponent, TuiTextAreaComponent } from '@taiga-ui/kit';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { StylesFormService } from '../../../../services/styles-form.service';
import { Subscription } from 'rxjs';
import { ICustomImageTemplateMetadataOption } from '../../../../interfaces/custom-image-template-metadata-option.interface';

@Component({
  selector: 'df-content-generator-form-fields',
  templateUrl: './content-generator-form-fields.component.html',
  styleUrls: ['./content-generator-form-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorFormFieldsComponent implements OnInit {
  @Input() integration!: Integration;
  @Input() form: UntypedFormGroup | null = null;
  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  readonly InputTypeEnum = InputTypeEnum;
  confUnsplash: IntegrationInputConfiguration | undefined;
  confBackground: IntegrationInputConfiguration | undefined;

  @ViewChildren(TuiInputComponent) inputs?: QueryList<TuiInputComponent>;
  @ViewChildren(TuiTextAreaComponent)
  textAreaInputs?: QueryList<TuiTextAreaComponent>;
  @ViewChildren(TuiSelectComponent) selectInputs?: QueryList<TuiSelectComponent<any>>;

  readonly stringify: TuiStringHandler<
    { value: string; label: string } | TuiContextWithImplicit<{ value: string; label: string }>
  > = (item) => ('label' in item ? item.label : item.$implicit.label);

  readonly sizesDisplayLimit: number = 3;
  sizesDisplayAmount: number = this.sizesDisplayLimit;

  get sizesDisplayLimitActive(): boolean {
    return this.sizesDisplayAmount === this.sizesDisplayLimit;
  }

  showStyleButton = false;
  styleSearchValue = '';
  styleSearchOpt: ICustomImageTemplateMetadataOption | null = null;
  sub: Subscription = new Subscription();

  constructor(
    public readonly changes: ChangeDetectorRef,
    private readonly s: SnackbarService,
    private readonly t: TranslateService,
    @Inject(Injector) private readonly injector: Injector,
    private stylesFormService: StylesFormService,
  ) {}

  ngOnInit(): void {
    this.listenContentGeneratorEvents();
    this.getSpecialFields();
  }

  getSpecialFields() {
    this.confUnsplash = this.integration.inputsConfigurations.find(
      (configuration) => configuration.type === InputTypeEnum.InputFileFromUnsplash,
    );
    this.confBackground = this.integration.inputsConfigurations.find(
      (configuration) =>
        configuration.type === InputTypeEnum.InputFile && configuration.property === 'elements.photo.image_url',
    );
    this.onBackgroundChanges();
  }

  private listenContentGeneratorEvents(): void {
    this.sub.add(this.stylesFormService.changesInGeneratorForm.subscribe(() => this.changes.detectChanges()));
  }

  onBackgroundChanges() {
    if (this.confUnsplash && this.confBackground) {
      [this.confUnsplash, this.confBackground].forEach((conf) => {
        const sub = this.getFormControl(conf!.id).valueChanges.subscribe(() => {
          this.disableFieldlFile();
        });
        this.sub.add(sub);
      });
    }
  }

  getFormControl(id: number): UntypedFormControl {
    return this.form?.get(id.toString()) as UntypedFormControl;
  }

  getFormArray(id: number): UntypedFormArray {
    return this.form?.get(id.toString()) as UntypedFormArray;
  }

  getFormArrayControls(id: number): UntypedFormControl[] {
    return this.getFormArray(id).controls as UntypedFormControl[];
  }

  addControl(id: number): void {
    const config: IntegrationInputConfiguration | undefined = this.integration.inputsConfigurations.find(
      (c) => c.id === id,
    );
    if (config) {
      this.getFormArray(id).push(getDefaultControlHelper(config));
      this.changes.detectChanges();
    }
  }

  removeControl([id, index]): void {
    if (this.getFormArrayControls(id).length > 1) {
      this.getFormArray(id).removeAt(index);
      this.changes.detectChanges();
    }
  }

  detectFormFieldsChanges(): void {
    this.inputs?.forEach((input) => input.checkControlUpdate());
    this.textAreaInputs?.forEach((input) => input.checkControlUpdate());
    this.selectInputs?.forEach((input) => input.checkControlUpdate());
    this.changes.detectChanges();
  }

  disableFieldlFile() {
    const backgroundDisable = !!this.getFormControl(this.confUnsplash!.id).value;
    const unsplashDisable = !!this.getFormControl(this.confBackground!.id).value;

    backgroundDisable
      ? this.getFormControl(this.confBackground!.id).disable({
          emitEvent: false,
        })
      : this.getFormControl(this.confBackground!.id).enable({
          emitEvent: false,
        });

    unsplashDisable
      ? this.getFormControl(this.confUnsplash!.id).disable({ emitEvent: false })
      : this.getFormControl(this.confUnsplash!.id).enable({ emitEvent: false });
  }
}
