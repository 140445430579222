<div class="flex flex-col justify-start items-center">
  <p
    class="text-center tui-text_body-m-3 tui-text-03 font-normal max-w-screen-sm"
    [innerHTML]="
      'Auth.AppSumo.Merge text'
        | translate
          : {
              email: userService.User?.email,
              pricing: (userService.User?.activePlans)![0].name
            }
    "
  ></p>
  <div class="flex justify-center items-center mt-8">
    <button
      size="l"
      tuiButton
      appearance="outline"
      type="button"
      (click)="close()"
      [disabled]="loading"
      class="mr-2"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      size="l"
      tuiButton
      appearance="primary"
      type="submit"
      (click)="confirm()"
      [disabled]="loading"
      [showLoader]="loading"
    >
      {{ 'Auth.AppSumo.Merge confirm' | translate }}
    </button>
  </div>
</div>
