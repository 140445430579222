<form [formGroup]="formGroup">
  <tui-multi-select
    #component
    class="control"
    *tuiLet="items$ | async as items"
    [stringify]="stringify"
    [tuiTextfieldSize]="size"
    [formControlName]="controlName"
    [tuiTextfieldLabelOutside]="false"
    [tuiTextfieldCleaner]="true"
    [readOnly]="disabled"
    [expandable]="false"
    [identityMatcher]="identityMatcher"
    (change)="change.emit($event)"
    (focusout)="change.emit($event)"
    (searchChange)="onSearchChange($event)"
  >
    <ng-content></ng-content>
    <tui-data-list-wrapper
      *tuiDataList
      tuiMultiSelectGroup
      [items]="items"
      [itemContent]="stringify"
    ></tui-data-list-wrapper>
  </tui-multi-select>
</form>
