export class BaseStorageService {
  static readonly PREFIX: string = 'dfai';

  get(key: string) {
    return localStorage.getItem(`${BaseStorageService.PREFIX}-${key}`);
  }

  put(key: string, val: string) {
    localStorage.setItem(`${BaseStorageService.PREFIX}-${key}`, val);
  }

  delete(key: string) {
    localStorage.removeItem(`${BaseStorageService.PREFIX}-${key}`);
  }
}
