<tui-breadcrumbs>
  <ng-container *ngFor="let item of items">
    <a
      *tuiBreadcrumb
      tuiLink
      [routerLink]="item.routerLink"
    >
      {{ item.caption }}
    </a>
  </ng-container>
</tui-breadcrumbs>

<div
  *ngIf="tacticPreviewService?.tactic"
  class="flex items-center"
>
  <div class="hidden lg:flex items-center h-full">
    <!--  SHARE -->
    <button
      *ngIf="
        tacticPreviewService.tactic?.currentUserPermission !== FunnelPermissionEnum.ViewOnly &&
        !userService.User?.isSemrushPlan()
      "
      class="mr-4"
      tuiIconButton
      size="l"
      appearance="outline"
      type="button"
      (click)="shareTactic()"
    >
      <svg-icon
        [src]="'/assets/img/ic_share.svg'"
        [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      ></svg-icon>
    </button>

    <!--  BACK TO ROOT -->
    <button
      *ngIf="tacticPreviewService.tactic?.root"
      tuiIconButton
      size="l"
      appearance="outline"
      icon="tuiIconUndoLarge"
      type="button"
      class="mr-4"
      [routerLink]="n.getPath('tactics/preview/p/:id', {}, { id: tacticPreviewService.tactic?.root?.id })"
      [tuiHint]="rootHint"
    >
      <ng-template #rootHint>
        <span>{{ 'Tactics.Back to root' | translate }}</span>
      </ng-template>
    </button>

    <!--  CLONE TACTIC -->
    <button
      *ngIf="canBeCloned"
      tuiButton
      size="l"
      appearance="outline"
      type="button"
      class="mr-4"
      [showLoader]="cloneLoading"
      (click)="clone()"
    >
      <svg-icon
        [src]="'/assets/img/ic_copy.svg'"
        class="mr-1"
        [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      ></svg-icon>
      <span class="inline-flex">{{ 'Tactics.Clone' | translate }}</span>
    </button>

    <!--  EDIT -->
    <button
      *ngIf="tacticPreviewService.tactic?.currentUserPermission === FunnelPermissionEnum.Editor"
      tuiButton
      size="l"
      appearance="outline"
      type="button"
      class="mr-4"
      (click)="edit()"
    >
      <svg-icon
        [src]="'/assets/img/ic-edit.svg'"
        class="mr-1"
        [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      ></svg-icon>
      <span class="inline-flex">{{ 'edit' | translate }}</span>
    </button>
  </div>
  <tui-hosted-dropdown
    tuiDropdownAlign="right"
    [content]="dropdown"
    tuiDropdownDirection="bottom"
    class="lg:hidden mr-4"
    *ngIf="!tacticPreviewService?.tactic?.isPaid || tacticPreviewService?.tactic?.owner?.id === userService.User?.id"
  >
    <button
      tuiIconButton
      appearance="outline"
      size="l"
      type="button"
    >
      <svg-icon [src]="'/assets/img/Settings.svg'"></svg-icon>
    </button>
  </tui-hosted-dropdown>
  <ng-template #dropdown>
    <tui-data-list>
      <button
        *ngIf="tacticPreviewService.tactic?.currentUserPermission === FunnelPermissionEnum.Editor"
        tuiOption
        size="m"
        class="justify-start"
        (click)="edit()"
      >
        <svg-icon
          [src]="'/assets/img/ic-edit.svg'"
          class="mr-2"
          [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
        ></svg-icon>
        <span class="inline-flex">{{ 'edit' | translate }}</span>
      </button>

      <!--  SHARE -->
      <button
        *ngIf="tacticPreviewService.tactic?.currentUserPermission !== FunnelPermissionEnum.ViewOnly"
        tuiOption
        size="m"
        class="justify-start"
        (click)="shareTactic()"
      >
        <svg-icon
          [src]="'/assets/img/ic_share.svg'"
          class="mr-2"
          [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
        ></svg-icon>
        <span class="inline-flex">{{ 'share' | translate }}</span>
      </button>

      <!--  CLONE TACTIC -->
      <button
        *ngIf="canBeCloned"
        tuiOption
        size="m"
        class="justify-start"
        (click)="clone()"
      >
        <svg-icon
          [src]="'/assets/img/ic_copy.svg'"
          class="mr-2"
          [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
        ></svg-icon>
        <span class="inline-flex">{{ 'Tactics.Clone' | translate }}</span>
      </button>

      <!--  BACK TO ROOT -->
      <button
        *ngIf="tacticPreviewService.tactic?.root"
        tuiOption
        size="m"
        class="justify-start"
        [routerLink]="n.getPath('tactics/preview/p/:id', {}, { id: tacticPreviewService.tactic?.root?.id })"
      >
        <tui-svg
          src="tuiIconUndoLarge"
          class="mr-2"
        ></tui-svg>
        <span>{{ 'Tactics.Back to root' | translate }}</span>
      </button>
    </tui-data-list>
  </ng-template>

  <df-add-to-funnel-button [tactic]="tacticPreviewService.tactic!"></df-add-to-funnel-button>

  <button
    *ngIf="copiedTacticId"
    tuiButton
    size="l"
    appearance="primary"
    type="button"
    [routerLink]="n.getPath('tactics/preview/p/:id', {}, { id: copiedTacticId })"
  >
    <tui-svg
      class="lg:mr-2"
      src="tuiIconRedoLarge"
    ></tui-svg>
    <span class="hidden lg:inline-flex">{{ 'Tactics.Go to copy' | translate }}</span>
  </button>

  <button
    class="ml-4"
    tuiIconButton
    type="button"
    appearance="outline"
    [icon]="icon"
    (click)="goBack()"
  ></button>
  <ng-template #icon>
    <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
  </ng-template>
</div>
