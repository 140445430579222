import { Injectable } from '@angular/core';
import { Funnel } from '@shared/models/funnel.model';
import { Apollo } from 'apollo-angular';
import { SearchFunnelsQuery } from '@modules/funnels/shared/graphql/queries/search-funnels.query.generated';
import { SearchSimpleFunnelsDocument } from '@modules/funnels/shared/graphql/queries/search-simple-funnels.query';
import { FunnelListTypeEnum } from '@modules/graphql/graphql-types';
import { FetchResult } from '@apollo/client/core';
import { UserService } from '@shared/services/user.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable()
export class UserFunnelsListService {
  private readonly _funnels$: BehaviorSubject<Funnel[]> = new BehaviorSubject<Funnel[]>([]);
  readonly funnels$: Observable<Funnel[]> = this._funnels$.asObservable();
  private readonly _sharedFunnels$: BehaviorSubject<Funnel[]> = new BehaviorSubject<Funnel[]>([]);
  readonly sharedFunnels$: Observable<Funnel[]> = this._sharedFunnels$.asObservable();

  constructor(
    private readonly _apollo: Apollo,
    private readonly _userService: UserService,
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  getMyFunnels(): void {
    this._apollo
      .query<SearchFunnelsQuery>({
        query: SearchSimpleFunnelsDocument,
        variables: {
          query: {
            page: 1,
            records: 100,
            type: FunnelListTypeEnum.OnlyMy,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(retry(2))
      .subscribe({
        next: (res: FetchResult<SearchFunnelsQuery>) => {
          const currentUser = this._userService.User;
          let isContextFunnel = false;
          const funnels: Funnel[] =
            res.data?.searchFunnels.records?.map((record) => {
              const r = new Funnel(record);
              r.isInContext = currentUser?.contextFunnel?.id === r.id;
              isContextFunnel = r.isInContext ? true : isContextFunnel;
              if (
                r.isInContext &&
                (r.id !== currentUser?.contextFunnel?.id || r.name !== currentUser?.contextFunnel?.name)
              ) {
                this._userService.setContextFunnel(r);
              }
              return r;
            }) || [];

          this._funnels$.next(funnels);

          if (!isContextFunnel) {
            if (funnels.length) {
              this.setContextFunnel(funnels[0]);
            } else if (currentUser && !!currentUser.contextFunnel) {
              currentUser.contextFunnel = null!;
              this._userService.User = currentUser;
            }
          }
        },
        error: () => {
          this._funnels$.next([]);
          this.s.error(this.t.instant('There is problem with getting your funnels. Refresh your view and try again.'));
        },
      });
  }

  getSharedFunnels(): void {
    this._apollo
      .query<SearchFunnelsQuery>({
        query: SearchSimpleFunnelsDocument,
        variables: {
          query: {
            page: 1,
            records: 100,
            type: FunnelListTypeEnum.SharedForMe,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(retry(2))
      .subscribe({
        next: (res: FetchResult<SearchFunnelsQuery>) => {
          const funnels: Funnel[] = res.data?.searchFunnels.records?.map((record) => new Funnel(record)) || [];
          this._sharedFunnels$.next(funnels);
        },
        error: () => {
          this._sharedFunnels$.next([]);
          this.s.error(this.t.instant('There is problem with getting your funnels. Refresh your view and try again.'));
        },
      });
  }

  setContextFunnel(funnel: Funnel) {
    if (funnel.id !== this._userService.User?.contextFunnel?.id) {
      this._userService.setContextFunnel(funnel)?.subscribe();
      this.s.success(
        this.t.instant('Context funnel changed to {{funnelName}}', {
          funnelName: funnel.name,
        }),
      );
      this._updateFunnelsContextList();
    }
  }

  private _updateFunnelsContextList() {
    const currentUser = this._userService.User;
    const funnels: Funnel[] = [...this._funnels$.value].map((f: Funnel) => {
      f.isInContext = currentUser?.contextFunnel?.id === f.id;
      return f;
    });
    this._funnels$.next(funnels);
  }
}
