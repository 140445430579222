import { EventEmitter, Injectable } from '@angular/core';
import { ContentGeneratorEventEnum } from '@modules/funnels/shared/enums/content-generator-selection-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ContentGeneratorEventService {
  private readonly contentGeneratorEvent: EventEmitter<ContentGeneratorEventEnum[]> = new EventEmitter<
    ContentGeneratorEventEnum[]
  >();

  emit(events: ContentGeneratorEventEnum[]): void {
    this.contentGeneratorEvent.emit(events);
  }

  getSubscription() {
    return this.contentGeneratorEvent.asObservable();
  }
}
