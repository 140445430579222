<tui-accordion-item
  [showArrow]="true"
  size="m"
  [open]="true"
  class="accordion"
  #accordion
>
  <div class="flex justify-between items-center px-3">
    <h4 class="text-base font-semibold flex-shrink-0 py-2">
      {{ 'Funnels.Recommendations.Based on' | translate }}&nbsp;<span class="tui-primary">{{ title }}</span>
    </h4>
    <hr class="flex-grow h-px tui-text-04-bg mx-6" />
    <div
      class="hidden sm:block"
      *ngIf="accordion.open"
    >
      <ng-container *ngTemplateOutlet="selectButton"></ng-container>
    </div>
  </div>
  <ng-template tuiAccordionItemContent>
    <div class="block sm:hidden w-full flex justify-end mb-4 -mt-4">
      <ng-container *ngTemplateOutlet="selectButton"></ng-container>
    </div>
    <div class="recommendations-grid gap-x-3 gap-y-4 md:gap-y-8 lg:gap-y-10 xl:gap-y-12 px-0 md:px-8 lg:px-14 xl:px-20">
      <df-funnel-recommendations-item
        *ngFor="let recommendation of recommendations"
        [recommendation]="recommendation"
        (recommendationSelected)="recommendationSelectedHandler(recommendation, $event)"
      ></df-funnel-recommendations-item>
    </div>
  </ng-template>
</tui-accordion-item>
<ng-template #selectButton>
  <button
    *ngIf="toSelect > 0"
    tuiButton
    appearance="primary"
    size="l"
    class="flex-shrink-0"
    (click)="selectedRecommendations.length ? addSelectedRecommendations($event) : selectAll($event)"
  >
    <svg-icon
      [src]="selectedRecommendations.length ? '/assets/img/funnel-add.svg' : '/assets/img/ic-check.svg'"
      class="mr-2"
    ></svg-icon>
    <span class="font-semibold">{{
      (selectedRecommendations.length
        ? 'Funnels.Recommendations.Add selected tactics'
        : 'Funnels.Recommendations.Select all tactics'
      ) | translate
    }}</span>
  </button>
</ng-template>
