<df-page-list-loader *ngIf="loading"></df-page-list-loader>

<df-funnel-recommendations-empty
  [access]="true"
  *ngIf="(!statementService.recommendations || !statementService.recommendations?.length) && inApp && !loading"
>
</df-funnel-recommendations-empty>

<df-public-funnel
  *ngIf="statementService.recommendations?.length || (!inApp && !loading)"
  [inCampaign]="true"
></df-public-funnel>
