import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FieldCategoryEnum } from '@modules/graphql/graphql-types';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { ActivatedRoute } from '@angular/router';
import { FunnelTab } from '@modules/funnels/shared/enums/funnel-tab.enum';
import { StatementNewParametersService } from '@modules/statement/shared/services/statement-new-parameters.service';
import { StatementFormService } from '@modules/statement/shared/services/statement-form.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { StatementEventsService } from '@modules/statement/shared/services/statement-events.service';
import { STEP_1_MANDATORY_FIELDS } from '@modules/statement/shared/models/statement.configuration';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { Funnel } from '@shared/models/funnel.model';
import { UserService } from '@shared/services/user.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'df-statement-form-step1',
  templateUrl: './statement-form-step1.component.html',
  styleUrls: ['./statement-form-step1.component.scss', '../../shared/scss/statement.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementFormStep1Component extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  loading = false;
  isInFunnel = false;
  FieldCategoryEnum = FieldCategoryEnum;
  formSubmitted = false;

  private resetFormOnLeave = false;

  private readonly STEP_NUMBER: number = 1;

  constructor(
    public statementNewParametersService: StatementNewParametersService,
    public statementFormService: StatementFormService,
    private changes: ChangeDetectorRef,
    public statementService: StatementService,
    private route: ActivatedRoute,
    private readonly statementEventsService: StatementEventsService,
    private readonly funnelManageService: FunnelManageService,
    private readonly userService: UserService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
    if (!this.statementFormService.isFormReady) this.statementNewParametersService.getParameters();
    this.document.querySelector('.new-statement-background')?.classList.add('mobile-no-bg-image');
  }

  ngOnInit(): void {
    this.statementService.performStatementContainerScrollTop();
    this.handleFunnelData();
    this.fetchStatement();
    this.listenEndLoadingParameters();
    this.listenChangeTargetSegment();
    this.resetFormOnLeave = true;
    this.listenChangeFunnel();
  }

  private fetchStatement(): void {
    if (!this.statementService.getRefreshStatement()) return;
    this.statementService.fetchStatement().subscribe((statement) => {
      if (statement) {
        this.statementFormService.patchValue(statement!);
      } else {
        this.statementFormService.clearForm();
        this.changes.detectChanges();
      }
    });
  }

  private handleFunnelData(): void {
    this.statementService.isPublic = false;
    this.statementService.stepNr.next(this.STEP_NUMBER);
    this.isInFunnel = this.route.snapshot.parent?.data['type'] === FunnelTab.STATEMENT;
    if (!this.isInFunnel) return;
    const funnelId: number | null = this.userService.User?.contextFunnel.id ?? null;
    if (funnelId === null) return;
    this.statementService.funnelId = funnelId;
    this.statementFormService.setFunnelId(funnelId);
  }

  private listenEndLoadingParameters() {
    this.sub.add(this.statementNewParametersService.onParametersLoaded.subscribe(() => this.changes.detectChanges()));
  }

  private listenChangeTargetSegment() {
    this.sub.add(
      this.statementFormService.form
        .get(FieldCategoryEnum.TargetSegment)
        ?.valueChanges.subscribe(() => this.changes.detectChanges()),
    );
  }

  onNextStep() {
    this.formSubmitted = true;
    this.resetFormOnLeave = false;
    this.changes.detectChanges();
    if (!this.statementFormService.validateForm(STEP_1_MANDATORY_FIELDS, this.STEP_NUMBER)) return;
    this.statementEventsService.completedStatementStep(this.STEP_NUMBER, this.statementService.funnelId);
    this.statementService.naviageToStep(this.STEP_NUMBER + 1);
  }

  onPreviousStep(): void {
    this.resetFormOnLeave = false;
    this.statementService.naviageToStep(this.STEP_NUMBER - 1);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.resetFormOnLeave) this.statementFormService.resetForm();
    this.document.querySelector('.new-statement-background')?.classList.remove('mobile-no-bg-image');
  }

  listenChangeFunnel() {
    this.sub.add(
      this.funnelManageService.funnel$.subscribe({
        next: (funnel: Funnel) => this.handleFunnelChanged(funnel),
      }),
    );
  }

  private handleFunnelChanged(funnel: Funnel): void {
    if (funnel && funnel.id !== this.statementService.funnelId) {
      this.handleFunnelData();
      this.fetchStatement();
    }
  }
}
