export enum EActivityForm {
  id = 'id',
  name = 'name',
  description = 'description',
  copy = 'copy',
  typeId = 'typeId',
  benchmarks = 'benchmarks',
  assets = 'assets',
  state = 'state',
  opened = 'opened',
  customPrompts = 'customPrompts',
}
