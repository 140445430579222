import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tactic } from '@shared/models/tactic.model';
import { VerificationStatusEnum } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-tactic-verification-badge',
  templateUrl: './tactic-verification-badge.component.html',
  styleUrls: ['./tactic-verification-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticVerificationBadgeComponent {
  @Input() tactic: Tactic | undefined | null;
  @Input() small = false;

  readonly VerificationStatusEnum = VerificationStatusEnum;
}
