<div class="p-4 lg:py-5 lg:px-9 w-full h-full">
  <tui-island
    size="s"
    class="box-border p-0 w-full h-full overflow-hidden"
  >
    <div
      class="gt-container flex flex-nowrap justify-start items-stretch w-full h-full overflow-x-auto onboarding-active-events"
    >
      <div class="gt-container__steps">
        <tui-tabs [(activeItemIndex)]="selectedTab.index">
          <button
            tuiTab
            *ngFor="let tab of CONTENT_GENERATOR_TABS"
            (click)="setTab(tab)"
            class="tab-items"
          >
            {{ tab.name | translate }}
          </button>
        </tui-tabs>
        <div class="gt-container__tabs_content p-3">
          <df-content-generator-steps
            *ngIf="ContentGeneratorTabEnum.TACTIC_ASSETS === selectedTab.type && isTabResolved"
          ></df-content-generator-steps>
          <df-content-generator-templates
            *ngIf="ContentGeneratorTabEnum.TEMPLATES === selectedTab.type && isTabResolved"
            (selectedIntegration)="onSelectIntegration($event)"
            (templateTabSelected)="onTemplateTabSelected()"
          ></df-content-generator-templates>
        </div>
      </div>
      <div class="gt-container__tactic">
        <df-content-generator-tactic
          *ngIf="ContentGeneratorTabEnum.TACTIC_ASSETS === selectedTab.type && isTabResolved"
        ></df-content-generator-tactic>
        <df-content-generator-template-form
          *ngIf="ContentGeneratorTabEnum.TEMPLATES === selectedTab.type && isTabResolved"
          [integration]="selectedIntegration"
        ></df-content-generator-template-form>
      </div>
      <div class="gt-container__tactic-details">
        <df-content-generator-tactic-details
          *ngIf="ContentGeneratorTabEnum.TACTIC_ASSETS === selectedTab.type && isTabResolved"
        ></df-content-generator-tactic-details>
        <df-content-generator-content-list
          *ngIf="ContentGeneratorTabEnum.TEMPLATES === selectedTab.type && isTabResolved"
        ></df-content-generator-content-list>
      </div>
    </div>
  </tui-island>
</div>
