import { XPartnerService } from './../../../../shared/services/x-partner.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Config } from '@shared/configs/config';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { TuiDialogService } from '@taiga-ui/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'df-no-authorization',
  templateUrl: './no-authorization.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
})
export class NoAuthorizationComponent implements OnInit, OnDestroy {
  Config = Config;
  private sub: Subscription = new Subscription();

  constructor(
    public n: NavigateService,
    private authStorageService: AuthStorageService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    public xPartnerService: XPartnerService,
  ) {}

  ngOnInit(): void {
    this.authStorageService.logout();
    this.closeAllDialogs();
  }

  closeAllDialogs() {
    this.sub.add(
      this.dialogService.subscribe((dialogOption) => dialogOption.map((dialog) => dialog.completeWith(true))),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
