import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { GetCategoryDocument } from '../graphql/queries/get-category.query';
import { GetCategoryQuery } from '../graphql/queries/get-category.query.generated';
import { CustomPromptInputGraphql, UpdateCustomPromptInputGraphql } from '@modules/graphql/graphql-types';
import { CreateCustomPromptMutation } from '../graphql/mutations/create-custom-prompt.mutation.generated';
import { CreateCustomPromptDocument } from '../graphql/mutations/create-custom-prompt.mutation';
import { FindOnePromptQuery } from '../graphql/queries/find-one-prompt.query.generated';
import { FindOnePromptDocument } from '../graphql/queries/find-one-prompt.query';
import { FindAllPromptsQuery } from '../graphql/queries/find-all-prompt.query.generated';
import { FindALlPromptDocument } from '../graphql/queries/find-all-prompt.query';
import { RemoveCustomPromptMutation } from '../graphql/mutations/remove-custom-prompt.mutation.generated';
import { RemoveCustomPromptDocument } from '../graphql/mutations/remove-custom-prompt.mutation';
import { UpdateCustomPromptDocument } from '../graphql/mutations/update-custom-prompt.mutation';
import { UpdateCustomPromptMutation } from '../graphql/mutations/update-custom-prompt.mutation.generated';

@Injectable({
  providedIn: 'root',
})
export class PromptService {
  constructor(private apollo: Apollo) {}

  getCategory(): Observable<FetchResult<GetCategoryQuery>> {
    return this.apollo.query<GetCategoryQuery>({
      query: GetCategoryDocument,
      fetchPolicy: 'network-only',
    });
  }

  createCustomPrompt(customPromptInput: CustomPromptInputGraphql): Observable<FetchResult<CreateCustomPromptMutation>> {
    return this.apollo.mutate<CreateCustomPromptMutation>({
      mutation: CreateCustomPromptDocument,
      variables: {
        customPromptInput,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updateCustomPrompt(
    updateCustomPromptInput: UpdateCustomPromptInputGraphql,
  ): Observable<FetchResult<UpdateCustomPromptMutation>> {
    return this.apollo.mutate<UpdateCustomPromptMutation>({
      mutation: UpdateCustomPromptDocument,
      variables: {
        updateCustomPromptInput,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getFindOnePrompt(customEntityId: number): Observable<FetchResult<FindOnePromptQuery>> {
    return this.apollo.query<FindOnePromptQuery>({
      query: FindOnePromptDocument,
      variables: {
        customEntityId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getFindAllPrompt(): Observable<FetchResult<FindAllPromptsQuery>> {
    return this.apollo.query<FindAllPromptsQuery>({
      query: FindALlPromptDocument,
      fetchPolicy: 'network-only',
    });
  }

  removeCustomPrompt(customPromptId: number) {
    return this.apollo.mutate<RemoveCustomPromptMutation>({
      mutation: RemoveCustomPromptDocument,
      variables: {
        customPromptId: customPromptId,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
