<div class="flex flex-col justify-start items-stretch">
  <div
    class="{{ step.type }}-lighter mb-2 rounded-lg p-3"
    *ngFor="let step of funnelStepsService.steps; let first = first"
    (click)="toggleStep(step)"
    [class.cursor-pointer]="funnelManageService.sortedFunnelTactics(step).length"
  >
    <div class="flex justify-between items-center w-full">
      <div class="flex justify-start items-center flex-grow">
        <svg-icon
          class="mr-2"
          [src]="'/assets/img/ic-' + step.type + '.svg'"
          [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
        ></svg-icon>

        <div class="tui-text-body-xs font-bold uppercase">
          <h4 class="m-0 {{ step.type }}-text">{{ step.name }}</h4>
          <p class="m-0 tui-text-03">
            {{
              'Funnels.Manage.:count tactics'
                | translate
                  : {
                      count: funnelManageService.sortedFunnelTactics(step).length
                    }
            }}
          </p>
        </div>
      </div>

      <button
        *ngIf="funnelManageService.sortedFunnelTactics(step).length"
        tuiIconButton
        type="button"
        size="xs"
        appearance="flat"
        icon="tuiIconChevronDown"
        class="duration-200"
        [ngClass]="{ 'rotate-180': !!expanded[step.id] }"
      ></button>
    </div>

    <tui-expand [expanded]="!!expanded[step.id]">
      <div
        (click)="selectTactic($event, funnelTactic)"
        class="mt-1 rounded p-2 tui-base-01-bg border border-solid border-transparent hover:border-blue-700"
        [ngClass]="{
          'tui-accent-13-bg tui-primary-border': highlighted === funnelTactic.id
        }"
        *ngFor="let funnelTactic of funnelManageService.sortedFunnelTactics(step)"
      >
        <h3 class="tui-text-03 text-sm font-medium break-words line-clamp-3">
          {{ funnelTactic.name ?? funnelTactic.tactic.name }}
        </h3>
        <div class="mt-2 flex justify-start items-stretch">
          <div
            class="border border-solid tui-base-04-border px-1.5 py-0.5 uppercase font-bold tui-text-02 tui-text-body-xs rounded-lg mr-2"
            [ngClass]="{
              'tui-base-02 border-transparent': highlighted === funnelTactic.id
            }"
          >
            {{ funnelTactic.tactic.activities.length }}&nbsp;{{ 'Funnels.Manage.Activities' | translate }}
          </div>
          <div
            class="border border-solid tui-base-04-border px-1.5 py-0.5 uppercase font-bold tui-text-02 tui-text-body-xs rounded-lg mr-2"
            [ngClass]="{
              'tui-base-02 border-transparent': highlighted === funnelTactic.id
            }"
          >
            {{ funnelTactic.tactic.assetsCount }}&nbsp;{{ 'Funnels.Manage.Assets' | translate }}
          </div>
        </div>
      </div>
    </tui-expand>
  </div>
</div>
