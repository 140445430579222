import { Injectable } from '@angular/core';
import {
  FilterTypes,
  FunnelListTypeEnum,
  FunnelsListInputGraphql,
  ListFiltersFieldInput,
  OrderTypes,
} from '@modules/graphql/graphql-types';
import { BaseListService, ListPageDirection } from '@shared/services/base-list.service';
import { BaseList } from '@shared/interfaces/base-list.interface';
import { Funnel } from '@shared/models/funnel.model';
import { SearchFunnelsDocument } from '@modules/funnels/shared/graphql/queries/search-funnels.query';
import { FunnelsFilterService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-filter.service';
import { ListSortItem } from '@shared/modules/ui/components/list-sub-header/list-sub-header.component';
import { IFilter } from '@shared/interfaces/filter.interface';

export enum FunnelListAvailableFilters {
  PROJECT = 'project.id',
}

@Injectable({
  providedIn: 'root',
})
export class FunnelsListService extends BaseListService<Funnel, FunnelsListInputGraphql> implements BaseList {
  static readonly defaultQuery = {
    page: 1,
    records: 30,
    search: '',
    sortDirection: OrderTypes.Asc,
    sortField: 'a.createdAt',
    type: FunnelListTypeEnum.All,
    filters: [],
  };

  constructor(public funnelsFiltersService: FunnelsFilterService) {
    super(FunnelsListService.defaultQuery, SearchFunnelsDocument);
  }

  initService(noUpdateUrl?: boolean) {
    super.initService(noUpdateUrl);
    this.listenFiltersChange();
  }

  recordTransform(record): Funnel {
    return new Funnel(record);
  }

  listenFiltersChange() {
    const sub = this.funnelsFiltersService.filterFormGroup.valueChanges.subscribe(() => {
      this.setFirstPage();
      this.getRecords(ListPageDirection.NONE).toPromise();
    });
    this.sub.add(sub);
  }

  prepareQuery() {
    this.query.sortField = this.funnelsFiltersService.filterFormGroup.get('sort')?.value['sortField'];
    this.query.sortDirection = this.funnelsFiltersService.filterFormGroup.get('sort')?.value['sortDirection'];
    this.query['type'] = this.funnelsFiltersService.filterFormGroup.get('type')?.value as FunnelListTypeEnum;
    this.query.search = this.funnelsFiltersService.filterFormGroup.get('search')?.value;
    const projects = this.funnelsFiltersService.filterFormGroup.get('projects')?.value as IFilter<any>[];
    this.query.filters = [
      {
        field: FunnelListAvailableFilters.PROJECT,
        operator: FilterTypes.In,
        value: projects?.length ? projects.map((v) => v.id.toString()) : [],
      },
    ];
  }

  transformQueryToFilters(params: FunnelsListInputGraphql) {
    this.funnelsFiltersService.sorts.map((sort: ListSortItem) => {
      if (sort.sortField === params.sortField) {
        this.funnelsFiltersService.filterFormGroup.get('sort')?.setValue(sort);
      }
    });
    this.funnelsFiltersService.filterFormGroup.get('search')?.setValue(params.search);
    params.filters?.map((obj: ListFiltersFieldInput) => {
      switch (obj.field) {
        case FunnelListAvailableFilters.PROJECT:
          this.funnelsFiltersService.initProjectsIds = obj.value?.map((i) => Number(i)) || [];
          break;
      }
    });
  }
}
