import { SocialAuthService } from '@abacritt/angularx-social-login';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'df-socials-authorization',
  templateUrl: './socials-authorization.component.html',
  styleUrls: ['./socials-authorization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialsAuthorizationComponent implements OnInit {
  @Output() signInWithGoogle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() signInWithFacebook: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private authService: SocialAuthService) {}

  ngOnInit() {
    this.authService.authState.subscribe(() => this.signInWithGoogle.emit());
  }
}
