export const appendHeadLink = (
  document: Document,
  rel: string,
  href?: string,
  sizes?: string,
  type?: string,
): HTMLLinkElement => {
  const link: HTMLLinkElement = document.createElement('link');
  link.setAttribute('rel', rel);
  sizes ? link.setAttribute('sizes', sizes) : null;
  href ? link.setAttribute('href', href) : '';
  type ? link.setAttribute('type', type) : '';
  document.head.append(link);
  return link;
};

export const appendManifest = (document: Document, href: string): void => {
  // setup manifest dynamically
  // firstly have a link tag with rel="manifest" but without href attribute
  const linkManifest = appendHeadLink(document, 'manifest');
  // then set href attribute
  linkManifest.setAttribute('href', href);
};

export const appendHeadMeta = (document: Document, name: string, content: string): void => {
  const meta: HTMLMetaElement = document.createElement('meta');
  meta.setAttribute('name', name);
  meta.setAttribute('content', content);
  document.head.append(meta);
};

export const appendTitle = (document: Document, title: string): void => {
  const titleDom: HTMLTitleElement = document.createElement('title');
  titleDom.textContent = title;
  document.head.append(titleDom);
};
