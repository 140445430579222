<div class="flex items-center justify-between w-full h-full">
  <div
    class="flex w-4/5 justify-center items-center h-full"
    (click)="input.nativeFocusableElement?.focus()"
  >
    <svg-icon
      [src]="'/assets/img/ic-search.svg'"
      [svgStyle]="{ width: '1rem', height: '1rem' }"
      class="mr-4 cursor-pointer"
      (click)="input.nativeFocusableElement?.focus()"
    ></svg-icon>

    <tui-input-inline
      #input
      class="search-input w-full"
      [formControl]="search"
    >
      {{ 'search' | translate }}
    </tui-input-inline>
  </div>
  <form
    [formGroup]="form"
    class="flex items-center justify-end w-1/5"
    *ngIf="sortControlName"
  >
    <tui-select
      class="w-40 sort-select"
      tuiTextfieldSize="s"
      (ngModelChange)="onSort.emit()"
      [valueContent]="stringify"
      [formControlName]="sortControlName"
    >
      <tui-data-list *tuiDataList>
        <button
          tuiOption
          size="xs"
          *ngFor="let item of sorts"
          [value]="item"
        >
          {{ item.name }}
        </button>
      </tui-data-list>
    </tui-select>
  </form>
</div>
