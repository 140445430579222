import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FilterXSS } from 'xss';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value: string | undefined): SafeHtml | string | undefined {
    if (!value) return value;

    const xss = new FilterXSS({
      onTagAttr: function (tag, name, value, isWhiteAttr) {
        if (name === 'class' || name === 'style') {
          return name + '="' + value + '"';
        }
      },
    });
    value = xss.process(value);

    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
