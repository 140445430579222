<iframe
  class="tallyTacticRec"
  [src]="
    Config.TALLY_URL + Config.TALLY_RAT + '?embed=1&alignLeft=1&hideTitle=1&transparentBackground=1'
      | sanitizeResourceUrl
  "
  width="100%"
  height="450"
  frameborder="0"
  marginheight="0"
  marginwidth="0"
  title="Request a tactic"
>
</iframe>
