<df-full-list-loader *ngIf="tacticPreviewService.loading"></df-full-list-loader>
<div class="h-full w-full flex flex-row items-stretch justify-start">
  <df-tactic-preview-activities
    [manualCurtain]="adminView ? false : null"
    (buyTactic)="buyTactic()"
    #tacticPreviewActivitiesComponent
  ></df-tactic-preview-activities>
  <df-tactic-preview-content
    [manualCurtain]="adminView ? false : null"
    (buyTactic)="buyTactic()"
  ></df-tactic-preview-content>
</div>
<button
  tuiIconButton
  type="button"
  size="xl"
  [appearance]="'outline'"
  class="fixed bg-white bottom-2 md:bottom-10 md:left-20 z-10 rounded-full shadow-lg block lg:hidden"
  [ngClass]="{
    'left-6 md:left-20 ': !isPublic,
    'left-4 ': isPublic
  }"
  [icon]="iconConfigure"
  (click)="openActivities()"
></button>
<ng-template #iconConfigure>
  <svg-icon [src]="'/assets/img/bullet-list.svg'"></svg-icon>
</ng-template>
