import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { OutputTypeEnum } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-content-generator-integration-placeholder',
  templateUrl: './content-generator-integration-placeholder.component.html',
  styleUrls: ['./content-generator-integration-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorIntegrationPlaceholderComponent {
  @Input() type: OutputTypeEnum | null = null;
  readonly OutputTypeEnum = OutputTypeEnum;
  @Output() generateContent: EventEmitter<boolean> = new EventEmitter<boolean>();
}
