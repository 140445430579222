import { gql } from 'apollo-angular';

export const GetCanvasAnswerSuggestionsDocument = gql`
  query getCanvasAnswerSuggestions($canvasTemplateId: Int!) {
    getCanvasAnswerSuggestions(canvasTemplateId: $canvasTemplateId) {
      businessName
      id
      suggestions
      url
    }
  }
`;
