import { Component, ChangeDetectionStrategy } from '@angular/core';
import { OnboardingService } from '@shared/services/onboarding.service';

@Component({
  selector: 'df-skip-onboarding-button',
  templateUrl: './skip-onboarding-button.component.html',
  styleUrls: ['./skip-onboarding-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkipOnboardingButtonComponent {
  constructor(private readonly onboardingService: OnboardingService) {}

  onSkipOnboarding(): void {
    this.onboardingService.skipOnboarding();
  }
}
