import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'df-funnel-overview-recommendations',
  templateUrl: './funnel-overview-recommendations.component.html',
  styleUrls: ['./funnel-overview-recommendations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelOverviewRecommendationsComponent {
  @Input() recommendedCount = 0;
}
