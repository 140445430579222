import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { FunnelManageFlowDataModalComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-manage-flow-data-modal/funnel-manage-flow-data-modal.component';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { UntypedFormControl } from '@angular/forms';
import { TuiAccordionItemComponent } from '@taiga-ui/kit';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { FlowItem } from '@shared/models/flow-item.model';
import { Tactic } from '@shared/models/tactic.model';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { Flow } from '@shared/models/flow.model';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { FunnelPermissionEnum, PermissionType } from '@modules/graphql/graphql-types';
import { FunnelTab } from '@modules/funnels/shared/enums/funnel-tab.enum';
import { OnboardingService } from '@shared/services/onboarding.service';

@Component({
  selector: 'df-funnel-manage-flows',
  templateUrl: './funnel-manage-flows.component.html',
  styleUrls: ['./funnel-manage-flows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageFlowsComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  FunnelPermissionEnum = FunnelPermissionEnum;
  Object = Object;
  sub: Subscription = new Subscription();
  mobileOpened = false;
  search: UntypedFormControl = new UntypedFormControl('');
  flowTactics: { [key: number]: Set<Tactic> } = {};
  currentOpened: TuiAccordionItemComponent | null = null;

  get canManage$(): Observable<boolean> {
    return this.funnelManageService.isSelectedTab(FunnelTab.MANAGE);
  }

  @HostBinding('class.mobileOpened')
  get isMobileOpened() {
    return this.mobileOpened;
  }

  @HostBinding('class.editMode')
  get isEditMode() {
    return this.funnelFlowManageService.editMode;
  }

  @HostBinding('class.shortPanel')
  get isShortPanel() {
    return this.onboardingService.onboardingRunning ? false : this.funnelFlowManageService.panelShort;
  }

  @ViewChildren('accordionItemComponent')
  accordionItemComponent!: QueryList<TuiAccordionItemComponent>;

  constructor(
    public changes: ChangeDetectorRef,
    private t: TranslateService,
    public funnelManageService: FunnelManageService,
    public funnelFlowManageService: FunnelFlowManageService,
    private pricingService: PricingService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    public readonly onboardingService: OnboardingService,
  ) {}

  ngOnInit(): void {
    this.changes.reattach();
    this.listenFunnelUpdate();
    this.listenEditModeChange();
    this.openFlowPanelOnOnboarding();
  }

  listenEditModeChange() {
    const sub = this.funnelFlowManageService.editMode$.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  listenFunnelUpdate() {
    this.updateFlowFunnelTactics();
    const sub = this.funnelManageService.funnel$.subscribe(() => {
      this.updateFlowFunnelTactics();
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  openFlowPanelOnOnboarding(): void {
    if (this.onboardingService.onboardingRunning) {
      this.togglePanel();
    }
  }

  updateFlowFunnelTactics() {
    this.flowTactics = {};

    const funnelTactics = this.funnelManageService.funnel?.tactics;
    //creating map with tactics for each flow
    this.funnelManageService.funnel?.flows.map((f: Flow) => {
      const flowFunnelTacticsIds: number[] = [];
      const flow =
        this.funnelFlowManageService.editMode && this.funnelFlowManageService.flow?.id === f.id
          ? this.funnelFlowManageService.flow
          : f;
      flow.items.forEach((flowItem: FlowItem) => flowFunnelTacticsIds.push(flowItem.from, flowItem.to));

      const funnelTacticsSet: Set<number> = new Set<number>(flowFunnelTacticsIds);
      funnelTacticsSet.forEach((funnelTacticId: number) => {
        const funnelTactic: FunnelTactic | undefined = funnelTactics?.find(
          (funnelTactic) => funnelTactic.id === funnelTacticId,
        );
        if (funnelTactic) {
          this._addFunnelTacticToFlow(flow, funnelTactic);
        }
      });
    });
    this.changes.detectChanges();
  }

  togglePanel() {
    this.funnelFlowManageService.panelShort = !this.funnelFlowManageService.panelShort;
    setTimeout(() => this.funnelFlowManageService.paintConnections(), 800);
    this.changes.detectChanges();
  }

  private _addFunnelTacticToFlow(flow: Flow, funnelTactic: FunnelTactic): void {
    if (this.flowTactics[flow.id]) {
      this.flowTactics[flow.id].add(funnelTactic.tactic);
    } else {
      this.flowTactics[flow.id] = new Set<Tactic>([funnelTactic.tactic]);
    }
  }

  onOpenChange(value: boolean, flow: Flow, item: TuiAccordionItemComponent) {
    this.currentOpened?.close();
    if (value) {
      this.highlightFlow(flow);
      this.currentOpened = item;
    } else {
      if (flow.highlighted) {
        this.funnelManageService.clearAllActiveFunnelTacticAndFlows();
        this.funnelFlowManageService.clearAllConnections();
        this.currentOpened = null;
      }
    }
  }

  toggleMobile() {
    this.mobileOpened = !this.mobileOpened;
    this.changes.detectChanges();
  }

  filter(flows?: Flow[]): Flow[] {
    if (flows && flows.length) {
      return flows.filter((f: Flow) => {
        if (this.funnelFlowManageService.editMode) {
          return f.id === this.funnelFlowManageService.flow?.id;
        }
        return f.name.toLowerCase().indexOf(this.search.value?.toLowerCase()) !== -1;
      });
    }
    return [];
  }

  addFlow() {
    this.pricingService.checkPricing(PermissionType.FlowManagement).subscribe((_) => {
      this.dialogService
        .open<number>(new PolymorpheusComponent(FunnelManageFlowDataModalComponent, this.injector), {
          size: 's',
          dismissible: true,
          label: this.t.instant('Funnels.Manage.Flow name'),
        })
        .subscribe();
    });
  }

  highlightFlow(flow: Flow) {
    this.toggleMobile();
    this.funnelManageService.clearHighlightForFunnelTactics();
    this.funnelManageService.funnel!.flows = this.funnelManageService.funnel!.flows.map((f: Flow) => {
      if (f.id === flow.id) {
        f.highlighted = !f.highlighted;
        if (f.highlighted) {
          this.funnelManageService.highlightFunnelTacticForFlow(f);
        }
      } else {
        f.highlighted = false;
      }
      return f;
    });
    this.funnelFlowManageService.paintConnections();
    this.changes.detectChanges();
  }

  editFlow(event, flow: Flow) {
    event.stopPropagation();
    this.pricingService.checkPricing(PermissionType.FlowManagement).subscribe((_) => {
      this.toggleMobile();
      this.funnelFlowManageService.flow = JSON.parse(JSON.stringify(flow));
      this.funnelFlowManageService.editMode = true;
    });
  }

  ngOnDestroy() {
    this.funnelFlowManageService.panelShort = true;
    this.sub.unsubscribe();
  }
}
