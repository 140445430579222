import { BehaviorSubject } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { Tactic } from '@shared/models/tactic.model';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { GetTacticQuery } from '@modules/tactics/shared/graphql/queries/get-tactic.query.generated';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { GetPubliclyVisibleTacticQuery } from '@modules/tactics/shared/graphql/queries/get-publicly-visible-tactic.query.generated';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { GraphqlErrorEnum } from '@modules/graphql/enums/graphql-error.enum';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '@core/services/seo.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TacticPreviewService {
  loading = false;
  isPublic = false;
  $changesInPreview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _tactic!: Tactic | null;

  tactic$: EventEmitter<Tactic> = new EventEmitter<Tactic>();
  refresh$: EventEmitter<boolean> = new EventEmitter<boolean>();

  set tactic(tactic: Tactic | null) {
    this._tactic = tactic;
    if (tactic) {
      this.tactic$.emit(tactic);
    }
  }

  get tactic(): Tactic | null {
    return this._tactic;
  }

  constructor(
    private tacticGraphqlService: TacticGraphqlService,
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
    private seoService: SEOService,
  ) {}

  getTactic(id: string, isPublic = false, admin = false) {
    this.loading = true;
    if (isPublic) {
      return this.tacticGraphqlService.getPubliclyVisibleTactic(Number(id)).subscribe({
        next: (res: FetchResult<GetPubliclyVisibleTacticQuery>) => {
          const tactic = res.data?.getPubliclyVisibleTactic as Tactic;
          this.loading = false;
          this.tactic = new Tactic(tactic);

          this.seoService.updateTitle(tactic.name);
          this.seoService.addOpengraphTags(`${environment.ASSETS}opengraph/tactic-${tactic.id}`);
        },
        error: () => {
          this.n.go('sign-in');
        },
      });
    } else {
      return this.tacticGraphqlService.getTactic(Number(id), admin).subscribe((res: FetchResult<GetTacticQuery>) => {
        const tactic = res.data?.getTactic as Tactic;
        this.loading = false;
        this.tactic = new Tactic(tactic);
      }, this.errorGetTactic.bind(this));
    }
  }

  protected errorGetTactic(error: ApolloError) {
    if (error.graphQLErrors?.length) {
      switch ((error.graphQLErrors[0] as CustomGraphqlErrorInterface).errorCode) {
        case GraphqlErrorEnum.NOT_ALLOWED:
          this.n.go('no-access');
          break;
        case GraphqlErrorEnum.ITEM_NOT_EXISTS:
          this.s.error(this.t.instant('Tactics.Tactic not found'));
          this.n.go('/tactics/list/all');
          break;
      }
    } else {
      this.s.defaultError();
    }
  }

  refreshTactic() {
    if (!this.tactic) return;
    this.loading = true;
    this.tacticGraphqlService.getTactic(this.tactic.id).subscribe((res: FetchResult<GetTacticQuery>) => {
      const tactic = res.data?.getTactic as Tactic;
      this.loading = false;
      this.tactic = new Tactic(tactic);
    });
  }

  clearService() {
    this.tactic = null;
    this.isPublic = false;
  }
}
