import { Environment } from '@shared/interfaces/environment.interface';
import { Partners } from '@shared/enums/partners.enum';

export const environment: Environment = {
  production: true,
  hmr: false,
  SIGNATURE: 'prod',
  INTERCOME_APP_ID: 'yc1ho306',
  APP_URL: 'https://app.digitalfirst.ai',
  API: 'https://api.digitalfirst.ai/api/graphql',
  API_WS: 'wss://api.digitalfirst.ai/api/graphql',
  API_SSE: 'https://api.digitalfirst.ai/api/sse',
  API_RECOMMENDATIONS: 'https://tqd79j26dx.eu-west-1.awsapprunner.com',
  API_RECOMMENDATIONS_LOGIN: 'digitalfirst_rd', // TODO remove when API will handle token
  API_RECOMMENDATIONS_PASSWORD: 'X7SBjRXhAzourA', // TODO remove when API will handle token
  MARKETPLACE_DOMAIN: 'digitalfirst.ai',
  MARKETPLACE_CLIENT_ID: 'af53e619-7a87-4e52-afdf-1bbdae95a5c6',
  MARKETPLACE_URL: 'https://marketplace.digitalfirst.ai',
  NEW_CDN_PATH: 'https://cdn.digitalfirst.ai',
  ASSETS: 'https://cdn.digitalfirst.ai/uploads/',
  DEBUG: false,
  GTAG: 'GTM-NWFRW4V',
  GTAG_PUBLIC: 'GTM-MTZHCJ8',
  CAPTCHA: '6LcYqPckAAAAAAZ85zWSBZ9XDMOBH2J2c1I00C5p',
  CONTACT_EMAIL: 'hello@digitalfirst.ai',
  SENTRY_ENV: 'prod',
  SENTRY_URL: 'https://780d5a5c8d2146e1be61cafc0794221c@sentry.seedigitalfirst.eu/2',
  FACEBOOK_CLIENT_ID: '224979240080583',
  GOOGLE_CLIENT_ID: '217474323804-u1f390q149n5jnp4ancjmrnmnh3uuid1.apps.googleusercontent.com',
  APPLE_CLIENT_ID: 'com.develtio.digitalfirst-dev',
  APPLE_REDIRECT_URL: 'https://app.digitalfirst.ai/api/auth/apple',
  DROPBOX_APP_KEY: 'saetb6hndyjc8px',
  partners: {
    [Partners.OXFORD_IE]: {
      APPLE_REDIRECT_URL: 'https://app.digitalfirst.ai/api/auth/apple',
    },
    [Partners.SEMRUSH]: {
      PUBLIC_KEY: 'f13196e5-e182-46d0-8c99-2963c5d78caa',
    },
  },
  TIPTAP_URL: 'https://flows.digitalfirst.ai',
  LANDING_PAGE_URL: 'https://mieh32x5zf.eu-west-1.awsapprunner.com',
  WEBSOCKET_URL: 'https://websocket.seedigitalfirst.eu',
  API_REST: 'https://api.digitalfirst.ai',
};
