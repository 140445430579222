import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FieldCategoryEnum } from '@modules/graphql/graphql-types';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { StatementNewParametersService } from '@modules/statement/shared/services/statement-new-parameters.service';
import { StatementFormService } from '@modules/statement/shared/services/statement-form.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { StatementEventsService } from '@modules/statement/shared/services/statement-events.service';
import { STATEMENT_STEP_ENUM, STEP_2_MANDATORY_FIELDS } from '@modules/statement/shared/models/statement.configuration';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { Funnel } from '@shared/models/funnel.model';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'df-statement-form-step2',
  templateUrl: './statement-form-step2.component.html',
  styleUrls: ['./statement-form-step2.component.scss', '../../shared/scss/statement.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementFormStep2Component extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  loading = false;
  FieldCategoryEnum = FieldCategoryEnum;
  formSubmitted = false;
  resetFormOnLeave = false;

  private readonly STEP_NUMBER: number = 2;

  constructor(
    public statementNewParametersService: StatementNewParametersService,
    public statementFormService: StatementFormService,
    private changes: ChangeDetectorRef,
    public statementService: StatementService,
    private readonly statementEventsService: StatementEventsService,
    private readonly funnelManageService: FunnelManageService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
    this.document.querySelector('.new-statement-background')?.classList.add('mobile-no-bg-image');
  }

  ngOnInit(): void {
    this.validateAccess();
    this.statementService.performStatementContainerScrollTop();
    this.setStatementData();
    this.listenChangeTargetSegment();
    this.listenChangeSalesValue();
    this.changes.detectChanges();
    this.resetFormOnLeave = true;
    this.listenChangeFunnel();
  }

  private validateAccess(): void {
    const validationResult: boolean = this.statementFormService.validateAccess(this.STEP_NUMBER);
    if (!validationResult) this.statementService.naviageToStep(1);
  }

  setStatementData() {
    this.statementService.isPublic = false;
    this.statementService.stepNr.next(this.STEP_NUMBER);
  }

  private listenChangeSalesValue(): void {
    this.sub.add(
      this.statementFormService.form.get(FieldCategoryEnum.GoalMeasureSales)?.valueChanges.subscribe({
        next: () => {
          this.statementFormService.form
            .get(FieldCategoryEnum.GoalMeasureLeads)
            ?.setValue(this.statementFormService.form.get(FieldCategoryEnum.GoalMeasureSales)?.value);
        },
      }),
    );
  }

  listenChangeTargetSegment() {
    this.sub.add(
      this.statementFormService.form
        .get(FieldCategoryEnum.TargetSegment)
        ?.valueChanges.subscribe(() => this.changes.detectChanges()),
    );
  }

  onNextStep() {
    this.resetFormOnLeave = false;
    this.formSubmitted = true;
    if (!this.statementFormService.validateForm(STEP_2_MANDATORY_FIELDS, this.STEP_NUMBER)) return;
    this.statementEventsService.completedStatementStep(this.STEP_NUMBER, this.statementService.funnelId);
    this.statementService.naviageToStep(this.STEP_NUMBER + 1);
  }

  onPreviousStep(): void {
    this.resetFormOnLeave = false;
    this.statementService.setReloadStatement(false);
    this.statementService.naviageToStep(this.STEP_NUMBER - 1);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.resetFormOnLeave) this.statementFormService.resetForm();
    this.document.querySelector('.new-statement-background')?.classList.remove('mobile-no-bg-image');
  }

  listenChangeFunnel() {
    this.sub.add(
      this.funnelManageService.funnel$.subscribe({
        next: (funnel: Funnel) => this.handleFunnelChanged(funnel),
      }),
    );
  }

  private handleFunnelChanged(funnel: Funnel): void {
    if (funnel && funnel.id !== this.statementService.funnelId)
      this.statementService.naviageToStep(STATEMENT_STEP_ENUM.ONE);
  }
}
