<div
  class="w-full h-full flex flex-col justify-start items-center lg:flex-row lg:justify-center px-3 py-6 md:px-7 lg:px-8 relative"
  [ngClass]="{ 'md:flex-row md:justify-center': !alignLeft }"
>
  <img
    *ngIf="!userService.User?.isSemrushPlan()"
    src="assets/img/logo-dark.png"
    [alt]="Config.SYSTEM_NAME"
    class="w-auto h-auto max-h-8 lg:absolute lg:left-8 lg:top-6 hidden md:block"
    [ngClass]="{
      'md:absolute md:left-7 md:top-6': !alignLeft,
      'self-start': alignLeft
    }"
  />
  <div
    class="flex flex-row justify-center items-center flex-wrap gap-x-4 sm:gap-x-6 gap-y-4 mt-6 lg:mt-0 lg:mx-48"
    [ngClass]="{ 'md:ml-48 md:mt-0': !alignLeft }"
  >
    <div
      *ngFor="let step of steps"
      class="flex flex-col sm:flex-row justify-start items-center tui-text-02"
      [class.tui-link-night-hover]="step.index < activeIndex"
    >
      <div
        class="w-8 h-8 flex justify-center items-center tui-base-03 tui-text_body-m rounded-full mb-2 sm:mb-0 mr-0 sm:mr-3 font-normal"
        [ngClass]="{
          'tui-primary-bg tui-base-01': step.index === activeIndex,
          'tui-secondary-bg': step.index < activeIndex
        }"
      >
        <span *ngIf="step.index >= activeIndex">{{ step.index }}</span>
        <svg-icon
          src="assets/img/_ic_check.svg"
          *ngIf="step.index < activeIndex"
        ></svg-icon>
      </div>
      <span
        class="tui-text_body-m-4 font-normal whitespace-nowrap"
        [class.tui-text-01]="step.index === activeIndex"
        >{{ step.name }}</span
      >
    </div>
  </div>
</div>
