import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CreateTeamMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/create-team.mutation.generated';
import { CreateTeamDocument } from '@modules/user-settings/modules/teams/shared/graphql/mutations/create-team.mutation';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { MyTeamsQuery } from '@modules/user-settings/modules/teams/shared/graphql/queries/my-teams.query.generated';
import { MyTeamsDocument } from '@modules/user-settings/modules/teams/shared/graphql/queries/my-teams.query';
import { GetTeamQuery } from '@modules/user-settings/modules/teams/shared/graphql/queries/get-team.query.generated';
import { GetTeamDocument } from '@modules/user-settings/modules/teams/shared/graphql/queries/get-team.query';
import { AddTeamMembersMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/add-team-members.mutation.generated';
import { AddTeamMembersDocument } from '@modules/user-settings/modules/teams/shared/graphql/mutations/add-team-members.mutation';
import { EditTeamMemberMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/edit-team-member.mutation.generated';
import { EditTeamMemberDocument } from '@modules/user-settings/modules/teams/shared/graphql/mutations/edit-team-member.mutation';
import { EditTeamInputGraphql, TeamMemberInputGraphql, TeamRoleEnum } from '@modules/graphql/graphql-types';
import { RemoveTeamMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/remove-team.mutation.generated';
import { RemoveTeamDocument } from '@modules/user-settings/modules/teams/shared/graphql/mutations/remove-team.mutation';
import { LeaveTeamMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/leave-team.mutation.generated';
import { LeaveTeamDocument } from '@modules/user-settings/modules/teams/shared/graphql/mutations/leave-team.mutation';
import { RemoveTeamMemberMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/remove-team-member.mutation.generated';
import { RemoveTeamMemberDocument } from '@modules/user-settings/modules/teams/shared/graphql/mutations/remove-team-member.mutation';
import { EditTeamMutation } from '@modules/user-settings/modules/teams/shared/graphql/mutations/edit-team.mutation.generated';
import { EditTeamDocument } from '@modules/user-settings/modules/teams/shared/graphql/mutations/edit-team.mutation';

@Injectable({
  providedIn: 'root',
})
export class TeamGraphqlService {
  constructor(private apollo: Apollo) {}

  createTeam(name: string, icon?: File): Observable<FetchResult<CreateTeamMutation>> {
    const variables = { input: { name } };
    icon ? (variables['icon'] = icon) : '';
    return this.apollo.mutate<CreateTeamMutation>({
      mutation: CreateTeamDocument,
      variables,
      context: {
        useMultipart: true,
      },
      fetchPolicy: 'no-cache',
    });
  }

  editTeam(input: EditTeamInputGraphql, icon?: File): Observable<FetchResult<EditTeamMutation>> {
    const variables = { input };
    icon ? (variables['icon'] = icon) : '';
    return this.apollo.mutate<EditTeamMutation>({
      mutation: EditTeamDocument,
      variables,
      context: {
        useMultipart: true,
      },
      fetchPolicy: 'no-cache',
    });
  }

  addTeamMembers(teamId: number, members: TeamMemberInputGraphql[]): Observable<FetchResult<AddTeamMembersMutation>> {
    return this.apollo.mutate<AddTeamMembersMutation>({
      mutation: AddTeamMembersDocument,
      variables: {
        input: {
          teamId,
          members,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  editTeamMember(id: number, role: TeamRoleEnum): Observable<FetchResult<EditTeamMemberMutation>> {
    return this.apollo.mutate<EditTeamMemberMutation>({
      mutation: EditTeamMemberDocument,
      variables: {
        input: {
          id,
          role,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeTeamMember(id: number): Observable<FetchResult<RemoveTeamMemberMutation>> {
    return this.apollo.mutate<RemoveTeamMemberMutation>({
      mutation: RemoveTeamMemberDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeTeam(id: number): Observable<FetchResult<RemoveTeamMutation>> {
    return this.apollo.mutate<RemoveTeamMutation>({
      mutation: RemoveTeamDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  leaveTeam(id: number): Observable<FetchResult<LeaveTeamMutation>> {
    return this.apollo.mutate<LeaveTeamMutation>({
      mutation: LeaveTeamDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  myTeams(): Observable<FetchResult<MyTeamsQuery>> {
    return this.apollo.query<MyTeamsQuery>({
      query: MyTeamsDocument,
      fetchPolicy: 'no-cache',
    });
  }

  getTeam(id: number): Observable<FetchResult<GetTeamQuery>> {
    return this.apollo.query<GetTeamQuery>({
      query: GetTeamDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
