import { gql } from 'apollo-angular';

export const GetAppSumoSubscriptionsPlansDocument = gql`
  query getAppSumoSubscriptionsPlans {
    getAppSumoSubscriptionsPlans {
      id
      prices {
        id
        amount
        currency
        paymentInterval
        paymentIntervalCount
      }
      name
      description
      permissions {
        id
        hasLimit
        limit
        permissionType {
          id
          type
          name
          isAvailable
          isAvailableInAppSumo
        }
      }
      credits
    }
  }
`;
