import { gql } from 'apollo-angular';

export const GetAyrshareAccountDocument = gql`
  query getAyrshareAccount {
    getAyrshareAccount {
      title
      createdUTC
      refId
      activeSocialAccounts
    }
  }
`;
