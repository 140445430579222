import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';

@Component({
  selector: 'df-tactic-settings-tab-title',
  templateUrl: './tactic-settings-tab-title.component.html',
  styleUrls: ['./tactic-settings-tab-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticSettingsTabTitleComponent extends AbstractSubscriptionComponent implements OnInit {
  constructor(
    public readonly tacticSettingsService: TacticSettingsService,
    private readonly changes: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenActiveTabChange();
  }

  private listenActiveTabChange(): void {
    this.sub.add(this.tacticSettingsService.activeTabChange$.subscribe(() => this.changes.detectChanges()));
  }
}
