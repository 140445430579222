import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({ template: '' })
export abstract class AbstractSubscriptionComponent implements OnDestroy {
  protected readonly sub: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
