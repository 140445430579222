import { BrowserJsPlumbInstance, newInstance } from '@jsplumb/browser-ui';
import { BezierConnector } from '@jsplumb/connector-bezier';
import { Connection, DotEndpoint, Endpoint } from '@jsplumb/core';
import { Flow } from '@shared/models/flow.model';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from '@core/services/app-injector.service';

export class FlowsController {
  jsPlumbInstance!: BrowserJsPlumbInstance;
  highlightedFlowIds: number[] = [];
  t!: TranslateService;

  constructor(
    public container: Element,
    public elementSelector: string,
  ) {
    this.initJsPlumb();
    const injector = AppInjector.getInjector();
    this.t = injector.get(TranslateService);
  }

  initJsPlumb() {
    this.jsPlumbInstance = newInstance({
      container: this.container,
    });
  }

  getConnectNode(id: number): HTMLElement {
    return document.querySelector(`[${this.elementSelector}="${id.toString()}"]`)!;
  }

  createEndpoint(id: number) {
    return this.jsPlumbInstance.addEndpoint(this.getConnectNode(id), {
      endpoint: {
        type: DotEndpoint.type,
        options: {
          radius: 5,
        },
      },
      anchor: {
        type: 'ContinuousLeftRight',
        options: {},
      },
    });
  }

  private getExpectedEndpointDirection(fromNode: HTMLElement, toNode: HTMLElement): 'left' | 'right' {
    return fromNode?.getBoundingClientRect()?.x > toNode?.getBoundingClientRect()?.x ? 'left' : 'right';
  }

  private connect(
    source: HTMLElement | Endpoint,
    target: HTMLElement | Endpoint,
    flow: Flow,
    fromId: number,
    toId: number,
  ): Connection {
    return this.jsPlumbInstance.connect({
      source,
      target,
      reattach: false,
      detachable: false,
      endpoint: {
        type: DotEndpoint.type,
        options: {
          radius: 5,
        },
      },
      paintStyle: {
        stroke: '#2E4FFF',
        strokeWidth: 2,
      },
      endpointStyle: {
        fill: '#2E4FFF',
      },
      data: {
        flow,
        fromId,
        toId,
      },
      connector: {
        type: BezierConnector.type,
        options: {
          curviness: 30,
        },
      },
      overlays: [
        {
          type: 'Custom',
          options: {
            id: 'toRemove',
            create: () => {
              const d = document.createElement('div');
              d.classList.add('removeLabel');
              d.innerHTML = this.t.instant('Funnels.Manage.click to remove');
              return d;
            },
          },
        },
      ],
      anchor: {
        type: 'ContinuousLeftRight',
        options: {},
      },
    });
  }

  createConnection(fromId: number, toId: number, flow: Flow): Connection {
    const fromNode: HTMLElement = this.getConnectNode(fromId);
    const toNode: HTMLElement = this.getConnectNode(toId);
    const expectedEndpointDirection = this.getExpectedEndpointDirection(fromNode, toNode);
    const fromNodeEndpoints = this.jsPlumbInstance.getEndpoints(fromNode);
    const toNodeEndpoints = this.jsPlumbInstance.getEndpoints(toNode);

    let source: HTMLElement | Endpoint = fromNode;
    let target: HTMLElement | Endpoint = toNode;

    if (fromNodeEndpoints.length) {
      let leftEndpoint: Endpoint | null = null;
      let rightEndpoint: Endpoint | null = null;
      fromNodeEndpoints.map((e: Endpoint) => {
        if (e['_continuousAnchorEdge'] === 'left') {
          leftEndpoint = e;
        }
        if (e['_continuousAnchorEdge'] === 'right') {
          rightEndpoint = e;
        }
      });

      if (expectedEndpointDirection === 'right' && rightEndpoint) {
        source = rightEndpoint;
      } else if (expectedEndpointDirection === 'left' && leftEndpoint) {
        source = leftEndpoint;
      }
    }

    if (toNodeEndpoints.length) {
      let leftEndpoint: Endpoint | null = null;
      let rightEndpoint: Endpoint | null = null;
      toNodeEndpoints.map((e: Endpoint) => {
        if (e['_continuousAnchorEdge'] === 'left') {
          leftEndpoint = e;
        }
        if (e['_continuousAnchorEdge'] === 'right') {
          rightEndpoint = e;
        }
      });

      if (expectedEndpointDirection === 'right' && leftEndpoint) {
        target = leftEndpoint;
      } else if (expectedEndpointDirection === 'left' && rightEndpoint) {
        target = rightEndpoint;
      }
    }

    const connection: Connection = this.connect(source, target, flow, fromId, toId);
    connection ? connection.hideOverlay('toRemove') : '';
    return connection;
  }
}

export enum ConnectionClass {
  HIGHLIGHT = 'highlight',
  LIGHT_SHADOW = 'light-shadow',
}
