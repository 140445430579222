<form
  *ngIf="activityControl"
  [formGroup]="activityControl"
  [validate-form]="activityControl"
  class="flex flex-row items-center w-full justify-between pr-2.5 py-1.5 min-h-12"
  [class.tui-base-02]="disabled"
>
  <div
    class="flex items-center justify-start flex-grow"
    [class.cursor-pointer]="!disabled"
    (click)="toggleAccordion.emit(true)"
  >
    <ng-content></ng-content>

    <span
      [class.invisible]="disabled"
      class="mr-[1.125rem] w-8 h-8 rounded-md tui-text-05 flex items-center justify-center tui-text_body-m-3 flex-shrink-0 border border-solid border-gray-200"
    >
      {{ index }}
    </span>

    <svg-icon
      class="mr-2"
      [src]="'/assets/img/ic_file_search-alt.svg'"
    ></svg-icon>

    <div
      *ngIf="activityControl.get(EActivityForm.typeId)?.value?.image as img"
      class="hidden sm:block mr-2 w-6 flex-shrink-0 flex-grow-0"
    >
      <img
        [src]="Config.ASSETS + img"
        class="w-6 h-6"
        alt=""
      />
    </div>

    <div class="flex flex-col justify-center h-full flex-grow">
      <span
        *ngIf="!disabled"
        class="hidden sm:block w-full tui-text_body-xs tui-text-04 font-bold overflow-ellipsis overflow-hidden sm:line-clamp-1"
      >
        {{ 'activity' | translate }}&nbsp;{{ index }}
      </span>
      <h4
        class="block w-full tui-text_body-m-9 tui-text-03 !font-medium overflow-ellipsis overflow-hidden line-clamp-2 sm:line-clamp-1"
        [class.tui-text-05]="disabled"
      >
        {{ activityControl.get(EActivityForm.name)?.value || ('empty' | translate) }}
      </h4>
    </div>
  </div>

  <div class="flex justify-end items-center flex-grow-0 flex-shrink-0 ml-3">
    <df-activity-form-actions
      [activityControl]="activityControl"
      [disabled]="disabled"
      class="hidden md:block"
    ></df-activity-form-actions>

    <button
      (click)="toggleAccordion.emit(true)"
      class="ml-1"
      [ngClass]="{ 'tui-primary': open }"
      tuiIconButton
      type="button"
      appearance="outline"
      [icon]="iconArrow"
      [disabled]="disabled"
      size="s"
    ></button>
    <ng-template #iconArrow>
      <svg-icon
        [src]="'/assets/img/ic_arrow_small.svg'"
        class="ease-in-out duration-200"
        [ngClass]="{ 'rotate-180': open }"
      ></svg-icon>
    </ng-template>
  </div>
</form>
