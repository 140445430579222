import { Component, OnInit } from '@angular/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { pluck } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';

@Component({ template: '' })
export abstract class AbstractFunnelSubpageComponent extends AbstractSubscriptionComponent implements OnInit {
  protected constructor(
    protected readonly route: ActivatedRoute,
    protected readonly funnelManageService: FunnelManageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setTabTypeObserver();
  }

  setTabTypeObserver(): void {
    this.sub.add(this.route.data.pipe(pluck('type')).subscribe((type) => (this.funnelManageService.currentTab = type)));
  }
}
