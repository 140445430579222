import { FillOutStripeData, NotificationOutputGraphql, NotificationTypeEnum } from '@modules/graphql/graphql-types';
import { DateTime } from 'luxon';

export class Notification implements NotificationOutputGraphql {
  createdAt!: DateTime;
  id!: number;
  data!: FillOutStripeData;
  message = '';
  received = false;
  type!: NotificationTypeEnum;

  constructor(data?: Notification | NotificationOutputGraphql) {
    data ? Object.assign(this, data) : '';
  }
}
