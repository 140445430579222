import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IntegrationOutputData } from '@shared/models/integration.model';
import { ContentGeneratorTemplatesFormService } from '../../services/content-generator-templates-form.service';
import { Integration } from '@shared/models/integration.model';
import { ContentTypeEnum } from '@modules/funnels/modules/funnel-manage/shared/enums/content-tile-type.enum';

@Component({
  selector: 'df-content-generator-content-list',
  templateUrl: './content-generator-content-list.component.html',
  styleUrls: ['./content-generator-content-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorContentListComponent implements OnInit {
  @Input() selectedIntegration: Integration | null = null;

  ContentTypeEnum = ContentTypeEnum;

  constructor(readonly templatesFormService: ContentGeneratorTemplatesFormService) {}

  ngOnInit(): void {
    this.templatesFormService.collection$;
  }

  handleDataSelected(data: IntegrationOutputData): void {
    this.templatesFormService.markOutputDataAsSelected(data);
  }

  handleDataDeleted(data: IntegrationOutputData): void {
    this.templatesFormService.removeOutput(data);
  }
}
