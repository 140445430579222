import { gql } from 'apollo-angular';
import { IntegrationOutputDataFragment } from '@modules/funnels/shared/graphql/fragments/integration-output-data.fragment';

export const GetIntegrationOutputsDataDocument = gql`
  query getIntegrationOutputsData($input: OutputsDataListInputGraphql!) {
    getIntegrationOutputsData(input: $input) {
      ...IntegrationOutputData
    }
  }
  ${IntegrationOutputDataFragment}
`;
