import { TacticsCanActivateGuard } from '@modules/tactics/guards/tactics-can-activate.guard';
import { StatementCanActivateGuard } from '@modules/statement/guards/statement-can-activate.guard';
import { UserSettingsCanActivateGuard } from '@modules/user-settings/guards/user-settings-can-activate.guard';
import { UsersCanActivateGuard } from '@modules/users/guards/users-can-activate.guard';
import { UserPreviewCanActivateGuard } from '@modules/users/modules/user-preview/guards/user-preview-can-activate.guard';
import { UsersListCanActivateGuard } from '@modules/users/modules/users-list/guards/users-list-can-activate.guard';
import { FunnelManageCanActivateGuard } from '@modules/funnels/modules/funnel-manage/guards/funnel-manage-can-activate.guard';
import { FunnelsListCanActivateGuard } from '@modules/funnels/modules/funnels-list/guards/funnels-list-can-activate.guard';
import { FaqCanActivateGuard } from '@modules/faq/guards/faq-can-activate.guard';
import { FunnelContentGenerateCanActivateGuard } from '@modules/funnels/modules/funnel-manage/guards/funnel-content-generate-can-activate.guard';
import { FunnelGraphicsCanDeactivateGuard } from '@modules/funnels/modules/funnel-manage/guards/funnel-graphics-can-deactivate.guard';
import { TeamManageCanDeactivateGuard } from '@modules/user-settings/modules/teams/modules/team-manage/guards/team-manage-can-deactivate.guard';
import { FunnelSettingsCanDeactivateGuard } from '@modules/funnels/modules/funnel-settings/guards/funnel-settings-can-deactivate.guard';
import { TacticSettingsCanDeactivateGuard } from '@modules/tactics/modules/tactic-settings/guards/tactic-settings-can-deactivate.guard';
import { TeamManageCanActivateGuard } from '@modules/user-settings/guards/team-manage-can-activate.guard';
import { PartnerCanActivateGuard } from '@shared/guards/partner-can-activate.guard';
import { OnboardingCanActivateGuard } from '@shared/guards/onboarding-can-activate.guard';
import { StatementNextStepInFunnelGuard } from '@modules/statement/guards/statement-next-step-in-funnel.guard';
import { StatementNextStepInOnboardingGuard } from '@modules/statement/guards/statement-next-step-in-onboarding.guard';
import { PricingGuard } from '@shared/guards/pricing.guard';
import { PricingCampaignGuard } from '@shared/guards/pricing-campaign.guard';
import { PublicPricingGuard } from '@shared/guards/public-pricing.guard';
import { PublicPricingCampaignGuard } from '@shared/guards/public-pricing-campaign.guard';
import { HasActiveSubscriptionGuard } from '@shared/guards/has-active-subscription.guard';
import { FormNotDirtyCanDeactivateGuard } from '@shared/guards/form-not-dirty.guard';

export const SHARED_GUARDS = [
  TacticsCanActivateGuard,
  StatementCanActivateGuard,
  UserSettingsCanActivateGuard,
  UsersCanActivateGuard,
  UserPreviewCanActivateGuard,
  UsersListCanActivateGuard,
  FunnelManageCanActivateGuard,
  FunnelsListCanActivateGuard,
  FaqCanActivateGuard,
  FunnelContentGenerateCanActivateGuard,
  FunnelGraphicsCanDeactivateGuard,
  TeamManageCanDeactivateGuard,
  FunnelSettingsCanDeactivateGuard,
  TacticSettingsCanDeactivateGuard,
  TeamManageCanActivateGuard,
  PartnerCanActivateGuard,
  OnboardingCanActivateGuard,
  StatementNextStepInFunnelGuard,
  StatementNextStepInOnboardingGuard,
  PricingGuard,
  PricingCampaignGuard,
  PublicPricingGuard,
  PublicPricingCampaignGuard,
  HasActiveSubscriptionGuard,
  FormNotDirtyCanDeactivateGuard,
];
