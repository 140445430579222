<div
  *ngIf="memoryLogs.length"
  class="fixed bg-[#975BFF] h-[52px] w-[52px] rounded-full border-[1px] border-solid border-white z-[1000] left-[34px] bottom-[34px]"
>
  <div
    class="w-full h-full rounded-full flex justify-center items-center cursor-pointer"
    (click)="toggle()"
  >
    @if (unreadMessagesCount && !isOpen) {
      <div
        class="absolute flex justify-center items-center right-[-10px] top-[-8px] h-6 w-6 rounded-full border-[1px] border-solid border-white z-50 text-white bg-[#FA4490] font-medium text-[14px]"
      >
        {{ unreadMessagesCount }}
      </div>
    }
    @if (completed) {
      <svg-icon [src]="'/assets/img/circle-check-white.svg'"></svg-icon>
    } @else {
      @if (isOpen) {
        <svg-icon [src]="'/assets/img/close-white.svg'"></svg-icon>
      } @else {
        <div class="dot-pulse"></div>
      }
    }
  </div>

  @if (isOpen) {
    <div class="absolute p-4 rounded shadow w-[380px] bottom-[66px] left-0 bg-white">
      <h4
        class="flex items-center pb-5 text-[#191C30E5] text-[16px] leading-[150%] font-semibold"
        [class.justify-between]="completed"
      >
        <span>Memory log</span>
        @if (completed) {
          <svg-icon
            class="cursor-pointer"
            [src]="'/assets/img/close-dark.svg'"
            (click)="toggle()"
          ></svg-icon>
        }
      </h4>
      <div
        class="max-h-[200px] overflow-y-auto pr-3"
        #scrollMe
        [scrollTop]="scrollMe.scrollHeight"
      >
        @for (log of memoryLogs; track $index) {
          <div
            class="w-full flex items-center text-[#191C30E5] text-[14px] leading-[150%] font-semibold"
            [class.mb-4]="!$last"
            [class.mb-0]="$last"
          >
            @if ($last && !completed) {
              <svg-icon
                [src]="'/assets/img/loading.svg'"
                class="spinner mr-3"
              ></svg-icon>
            } @else {
              <svg-icon
                [src]="'/assets/img/check-green.svg'"
                class="mr-3"
              ></svg-icon>
            }
            <span [class.text-[#1B1F3BA6]]="$last">
              {{ log }}
            </span>
          </div>
        }
      </div>
    </div>
  }
</div>
