import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-funnel-recommendations-empty',
  templateUrl: './funnel-recommendations-empty.component.html',
  styleUrls: ['./funnel-recommendations-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelRecommendationsEmptyComponent {
  @Input() access = false;
  pricingUrl: string;
  constructor(private readonly userService: UserService) {
    this.pricingUrl = this.userService.resolvePricingPacketsUrl();
  }
}
