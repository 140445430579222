<div
  (click)="togglePanel()"
  class="hidden md:flex rounded-full cursor-pointer w-6 h-6 bg-gray-200 hover:bg-gray-300 absolute top-10 -right-3 z-50 items-center justify-center tui-base-01 duration-500"
  [class.rotate-180]="!funnelFlowManageService.panelShort"
>
  <svg-icon
    [src]="'/assets/img/Forward.svg'"
    [svgStyle]="{ width: '1rem', height: '1rem' }"
  ></svg-icon>
</div>

<div class="flex justify-between items-center px-4 border-b border-solid tui-base-02-border">
  <div>
    <h3 class="uppercase tui-text_body-xs">
      {{ 'Funnels.Manage.flows' | translate }}
    </h3>
    <p class="py-2">
      What is
      <a
        class="text-[13px]"
        href="https://help.digitalfirst.ai/en/articles/9133098-manage-funnel-flows-and-tactic-flows-for-effective-campaigns#h_56ce6d0f60"
        target="_blank"
      >
        tactic flow?</a
      >
    </p>
  </div>

  <button
    tuiIconButton
    appearance="outline"
    class="ml-2 tui-primary"
    *ngIf="
      !funnelFlowManageService.editMode &&
      (canManage$ | async) &&
      funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor
    "
    (click)="addFlow()"
    [size]="'s'"
    [icon]="iconPlusSmall"
  ></button>
  <ng-template #iconPlusSmall>
    <svg-icon [src]="'/assets/img/ic-plus-small.svg'"></svg-icon>
  </ng-template>
</div>

<div class="p-1.5 w-full flow-container flex flex-col">
  <div
    class="tui-base-02 border border-solid border-transparent rounded-lg flex items-center justify-between select-none p-2 mt-1.5"
    [ngClass]="{ 'tui-base-01-bg tui-primary-border': flow.highlighted }"
    *ngFor="let flow of filter(funnelManageService?.funnel?.flows)"
  >
    <tui-accordion-item
      [showArrow]="false"
      [borders]="null"
      [noPadding]="true"
      [open]="funnelFlowManageService.editMode || onboardingService.onboardingRunning"
      (openChange)="onOpenChange($event, flow, item)"
      [disabled]="funnelFlowManageService.editMode"
      #accordionItemComponent
      [size]="'m'"
      #item
    >
      <div class="flex flex-col justify-start items-start">
        <h6 class="overflow-hidden break-words whitespace-normal line-clamp-1 font-semibold text-xs">
          {{ flow.name }}
        </h6>
        <p class="tui-text_body-xs tui-text-05 mt-0.5">
          {{
            'Funnels.Manage.:count tactics'
              | translate
                : {
                    count: flowTactics[flow.id] && flowTactics[flow.id].size ? flowTactics[flow.id].size : 0
                  }
          }}
          <span
            *ngIf="
              (canManage$ | async) &&
              !funnelFlowManageService.editMode &&
              funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor
            "
          >
            •
            <span (click)="editFlow($event, flow)">{{ 'edit' | translate }}</span></span
          >
        </p>
      </div>

      <ng-template tuiAccordionItemContent>
        <div
          class="flex flex-col justify-start items-start"
          *ngIf="flowTactics[flow.id]"
        >
          <span
            class="tui-text_body-xs block line-clamp-2 mt-2.5"
            *ngFor="let tactic of flowTactics[flow.id].values()"
          >
            {{ tactic.name }}
          </span>
        </div>
      </ng-template>
    </tui-accordion-item>
  </div>
</div>

<button
  tuiIconButton
  type="button"
  size="xl"
  appearance="outline"
  class="block md:hidden float-button bg-white fixed bottom-20 -right-20 rounded-full shadow-lg"
  [icon]="icon"
  (click)="toggleMobile()"
  *ngIf="!onboardingService.onboardingRunning"
></button>
<ng-template #icon>
  <svg-icon [src]="'/assets/img/flow-icon shape.svg'"></svg-icon>
</ng-template>
