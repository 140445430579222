import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorInputComponent } from './components/error-input/error-input.component';
import { VerticalTabsComponent } from './components/vertical-tabs/vertical-tabs.component';
import { TuiButtonModule, TuiDataListModule, TuiLinkModule, TuiLoaderModule } from '@taiga-ui/core';
import { RouterModule } from '@angular/router';
import { TuiAvatarModule, TuiInputInlineModule, TuiIslandModule, TuiSelectModule, TuiTabsModule } from '@taiga-ui/kit';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CreatedByComponent } from './components/created-by/created-by.component';
import { TranslateModule } from '@ngx-translate/core';
import { FullListLoaderComponent } from './components/full-list-loader/full-list-loader.component';
import { PageListLoaderComponent } from './components/page-list-loader/page-list-loader.component';
import { MobileCloseComponent } from './components/mobile-close/mobile-close.component';
import { IconTileComponent } from './components/icon-tile/icon-tile.component';
import { ListSubHeaderComponent } from './components/list-sub-header/list-sub-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderTitleComponent } from './components/header-title/header-title.component';
import { HeaderTabsComponent } from './components/header-tabs/header-tabs.component';
import { BlurIslandComponent } from './components/blur-island/blur-island.component';
import { ParameterBoxComponent } from './components/parameter-box/parameter-box.component';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { AddRemoveLinkButtonComponent } from './components/add-remove-link-button/add-remove-link-button.component';

@NgModule({
  declarations: [
    ErrorInputComponent,
    VerticalTabsComponent,
    CreatedByComponent,
    FullListLoaderComponent,
    PageListLoaderComponent,
    MobileCloseComponent,
    IconTileComponent,
    ListSubHeaderComponent,
    HeaderTitleComponent,
    HeaderTabsComponent,
    BlurIslandComponent,
    ParameterBoxComponent,
    InfoBarComponent,
    AddRemoveLinkButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TuiDataListModule,
    TuiTabsModule,
    TuiLinkModule,
    TuiAvatarModule,
    TuiButtonModule,
    TuiIslandModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule,
    TuiInputInlineModule,
    ReactiveFormsModule,
    TuiSelectModule,
    TuiLoaderModule,
  ],
  exports: [
    ErrorInputComponent,
    VerticalTabsComponent,
    CreatedByComponent,
    FullListLoaderComponent,
    PageListLoaderComponent,
    MobileCloseComponent,
    IconTileComponent,
    ListSubHeaderComponent,
    HeaderTitleComponent,
    HeaderTabsComponent,
    BlurIslandComponent,
    ParameterBoxComponent,
    InfoBarComponent,
    AddRemoveLinkButtonComponent,
  ],
})
export class UiModule {}
