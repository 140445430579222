import { Component, Input } from '@angular/core';

@Component({
  selector: 'df-statement-select-wrapper',
  templateUrl: './statement-select-wrapper.component.html',
  styleUrls: ['./statement-select-wrapper.component.scss'],
})
export class StatementSelectWrapperComponent {
  isOpen = false;

  @Input() displayInline = false;

  public onDropdownChange(isOpen: boolean): void {
    this.isOpen = isOpen;
  }
}
