<tui-hosted-dropdown
  class="min-w-4"
  [(open)]="onboardingService.onboardingRunning ? false : menuOpened"
  [tuiDropdownAlign]="tuiDropdownAlign"
  [content]="dropdown"
>
  <button
    tuiIconButton
    [appearance]="appearance"
    [size]="size"
    tuiHostedDropdownHost
    (click)="$event.stopPropagation(); menuOpened = !menuOpened"
    icon="tuiIconMoreVer"
    class="btn-border-helper"
  ></button>
</tui-hosted-dropdown>
<ng-template #dropdown>
  <tui-data-list>
    <ng-container *ngIf="funnelTactic.tactic.hasData()">
      <a
        tuiOption
        *ngIf="!short"
        (click)="n.go('tactics/preview/p/:id', { id: funnelTactic.tactic.id })"
        class="tui-text-body-m-2"
      >
        {{ 'Funnels.Manage.View tactic' | translate }}
      </a>
      <a
        tuiOption
        (click)="toggleDone()"
        *ngIf="
          !funnelTactic.isDone &&
          (canManage$ | async) &&
          funnelManageService.funnel?.currentUserPermission === FunnelPermissionEnum.Editor
        "
        class="tui-text-body-m-2"
      >
        {{ 'Funnels.Manage.Mark as done' | translate }}
      </a>

      <a
        tuiOption
        (click)="toggleDone()"
        *ngIf="
          funnelTactic.isDone &&
          (canManage$ | async) &&
          funnelManageService.funnel?.currentUserPermission === FunnelPermissionEnum.Editor
        "
        class="tui-text-body-m-2"
      >
        {{ 'Funnels.Manage.Mark as undone' | translate }}
      </a>

      <a
        tuiOption
        *ngIf="
          funnelManageService.funnel?.currentUserPermission === FunnelPermissionEnum.Editor &&
          (canManage$ | async) &&
          !short
        "
        (click)="editTacticName()"
        class="tui-text-body-m-2"
      >
        {{ 'Funnels.Manage.Edit tactic name' | translate }}
      </a>

      <a
        tuiOption
        *ngIf="
          funnelManageService.funnel?.currentUserPermission === FunnelPermissionEnum.Editor && (canManage$ | async)
        "
        (click)="duplicateTactic()"
        class="tui-text-body-m-2"
      >
        {{ 'Funnels.Manage.duplicate' | translate }}
      </a>
    </ng-container>

    <a
      tuiOption
      *ngIf="!short"
      (click)="highlightFunnelTacticFlows()"
      class="tui-text-body-m-2"
    >
      {{ 'Funnels.Manage.Highlight first flow' | translate }}
    </a>
    <a
      tuiOption
      *ngIf="funnelManageService.funnel?.currentUserPermission === FunnelPermissionEnum.Editor && (canManage$ | async)"
      (click)="removeTacticFromFunnel()"
      class="tui-text-body-m-2 tui-accent-01"
    >
      {{ 'Funnels.Manage.Remove from funnel' | translate }}
    </a>
  </tui-data-list>
</ng-template>
