import { ComponentRef, EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import {
  EOnboardingStep,
  OnboardingDOMElement,
  OnboardingElements,
  OnboardingStep,
} from '@shared/models/onboarding-step.model';
import { OnboardingSteps } from '@shared/consts/onboarding-steps.const';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@shared/models/user.model';
import { OnboardingElementFactory } from '@shared/factories/onboarding-element.factory';
import { OnboardingHelpMarkFactory } from '@shared/factories/onboarding-help-mark.factory';
import { debounce } from 'helpful-decorators';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { OnboardingCongratulationsModalComponent } from '@shared/components/onboarding-congratulations-modal/onboarding-congratulations-modal.component';
import { take } from 'rxjs/operators';
import { SkipOnboardingButtonComponent } from '@shared/components/skip-onboarding-button/skip-onboarding-button.component';
import { DomInjectorService } from './dom-injector.service';
import { StatementService } from '@modules/statement/shared/services/statement.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  static readonly FUNNEL_DROP_STEP_TYPE: string = 'upsell';
  private readonly _body: HTMLElement;
  private skipButtonComponentRef: ComponentRef<SkipOnboardingButtonComponent> | null = null;

  private static _stopPropagation = (clickEvent: MouseEvent) => {
    if (!clickEvent['path']?.some((i) => !!i.classList?.contains('onboarding-action'))) {
      clickEvent.stopImmediatePropagation();
      clickEvent.stopPropagation();
      clickEvent.preventDefault();
    }
  };

  @debounce(400)
  private _updateView() {
    if (!this.temporaryElementsArray.length) return;
    this.removeTemporaryElements();
    this.renderTemporaryElements();
  }

  private readonly _rerenderElements = () => this._updateView();
  private onboardingSteps!: OnboardingStep[];

  get onboardingCompleted(): boolean {
    return !!this.userService.User?.hasEndedOnboarding;
  }

  get onboardingRunning(): boolean {
    return !this.onboardingCompleted;
  }

  _currentStepIndex = -1;
  private _currentRouteName: string | null = null;

  get currentStep(): OnboardingStep | null {
    return this._currentStepIndex < this.onboardingSteps?.length ?? 0
      ? this.onboardingSteps[this._currentStepIndex]
      : null;
  }

  get currentRouteName(): string | null {
    return this._currentRouteName;
  }

  readonly nextStep$: EventEmitter<OnboardingStep | null> = new EventEmitter<OnboardingStep | null>();
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  private temporaryElementsArray: OnboardingDOMElement[] = [];

  set loading(loading: boolean) {
    this._loading$.next(loading);
  }

  constructor(
    private readonly userService: UserService,
    private readonly n: NavigateService,
    private readonly t: TranslateService,
    private readonly statementService: StatementService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly domInjectorService: DomInjectorService,
  ) {
    this._body = document.body;
  }

  initOnboarding(): void {
    this.loading = true;
    this.statementService.openStatementOnboarding = true;
    this._body.classList.add('onboarding-running');

    this.showSkipOnboardingButton();
    this.onboardingSteps = OnboardingSteps(this, this.t);
    this.onboardingSteps?.forEach((step, index) =>
      step.routeName ? '' : (step.routeName = this.onboardingSteps[index - 1].routeName),
    );
    this.nextStep();
  }

  nextStep(renderDelay = 300, retryUntilSuccess = false): void {
    this._currentStepIndex += 1;
    this.removeTemporaryElements();
    if (!this.currentStep) {
      setTimeout(() => this.finishOnboarding(), renderDelay);
    } else {
      if (this.currentStep.routeName && this.currentStep.routeName !== this.currentRouteName) {
        this._currentRouteName = this.currentStep.routeName;
        this.n.go(this.currentStep.routeName)?.then(() => this.updateView(renderDelay, retryUntilSuccess));
      } else {
        this.updateView(renderDelay, retryUntilSuccess);
      }
    }
  }

  isCurrentStepType(type: EOnboardingStep): boolean {
    return this.currentStep?.type === type;
  }

  private updateView(renderDelay = 300, retryUntilSuccess = false): void {
    this.nextStep$.emit(this.currentStep);
    setTimeout(() => this.renderTemporaryElements(retryUntilSuccess), renderDelay);
  }

  private removeTemporaryElements(): void {
    this.temporaryElementsArray.forEach((element: OnboardingDOMElement) => element.parent.removeChild(element.child));
    this.temporaryElementsArray = [];
  }

  private renderTemporaryElements(retryUntilSuccess = false): void {
    this.renderAccentElements(retryUntilSuccess);
    this.renderHelpMarks(retryUntilSuccess);
  }

  private renderAccentElements(retryUntilSuccess = false): void {
    const interval = setInterval(() => {
      const anchor: Element | undefined = document.getElementsByClassName('onboarding-temp-anchor')[0];
      const parent: Element = document.getElementsByClassName('onboarding-accent-parent')[0] ?? this._body;
      if (anchor) {
        const onboardingElementFactory: OnboardingElementFactory = new OnboardingElementFactory(parent, anchor);
        this.currentStep?.elements
          ?.map((element: OnboardingElements) => onboardingElementFactory.renderElement(element))
          ?.forEach((element: Element) => {
            element.classList.add('i18n-dynamic');
            this.temporaryElementsArray.push({ parent, child: element });
          });
      }
      if (anchor || !retryUntilSuccess) clearInterval(interval);
    }, 50);
  }

  private renderHelpMarks(retryUntilSuccess = false): void {
    const interval = setInterval(() => {
      const onboardingHelpMarkFactory: OnboardingHelpMarkFactory = new OnboardingHelpMarkFactory();
      const rendered = onboardingHelpMarkFactory.renderElements(this.currentStep?.helpMarks ?? []);
      rendered.forEach((element: OnboardingDOMElement) => element.child.classList.add('i18n-dynamic'));
      this.temporaryElementsArray.push(...rendered);
      if (rendered.length === (this.currentStep?.helpMarks?.length ?? 0) * 2 || !retryUntilSuccess)
        clearInterval(interval);
    }, 50);
  }

  private setEventListeners(): void {
    this._body.addEventListener('click', OnboardingService._stopPropagation, true);
    this._body.addEventListener('mousedown', OnboardingService._stopPropagation, true);
    window.addEventListener('resize', this._rerenderElements);
  }

  private clearEventListeners(): void {
    this._body.removeEventListener('click', OnboardingService._stopPropagation, true);
    this._body.removeEventListener('mousedown', OnboardingService._stopPropagation, true);
    window.removeEventListener('resize', this._rerenderElements);
  }

  private openCongratulationsModal(): Subscription {
    return this.dialogService
      .open(new PolymorpheusComponent(OnboardingCongratulationsModalComponent, this.injector), {
        dismissible: false,
        closeable: true,
        size: 'm',
      })
      .subscribe();
  }

  private finishOnboarding(): void {
    this.removeSkipOnboardingButton();
    this.clearEventListeners();
    this._body.classList.remove('onboarding-running');
    this._currentRouteName = null;
    this.userService.userEndedOnboarding().toPromise();
    const user: User = this.userService.User!;
    user.hasEndedOnboarding = true;
    this.userService.User = user;
    this.openCongratulationsModal().add(() => {
      this.dialogService
        .pipe(take(1))
        .subscribe((dialogOption) => dialogOption.map((dialog) => dialog.completeWith(true)));
      this.n.go('funnels/list/all')?.then(() => this.nextStep$.emit(null));
    });
  }

  skipOnboarding(): void {
    this.removeTemporaryElements();
    this.finishOnboarding();
  }

  redirectMobileOnboarding() {
    if (!this.userService?.User?.hasEndedOnboarding) {
      this.removeTemporaryElements();
      this.removeSkipOnboardingButton();
      this.clearEventListeners();
      this._body.classList.remove('onboarding-running');
      this._currentRouteName = null;
      this.userService.userEndedOnboarding().toPromise();
      const user: User = this.userService.User!;
      user.hasEndedOnboarding = true;
      this.userService.User = user;
      this.n.go('funnels/list/all')?.then(() => this.nextStep$.emit(null));
    }
  }

  private showSkipOnboardingButton(): void {
    if (this.skipButtonComponentRef) return;
    this.skipButtonComponentRef = this.domInjectorService.appendComponentToBody(SkipOnboardingButtonComponent);
  }

  private removeSkipOnboardingButton(): void {
    this.domInjectorService.removeComponent(this.skipButtonComponentRef);
    this.skipButtonComponentRef = null;
  }
}
