import { FieldCategoryEnum } from '@modules/graphql/graphql-types';

export const EXPECTED_LENGTH_FOR_SINGLE_SELECT = 1;
export const EXPECTED_LENGTH_FOR_MULTI_SELECT = 0;
export const SHORT_TEXT_MAX_LENGTH = 200;
export const B2B_NAME = 'B2B';
export const B2C_NAME = 'B2C';
export const SALES_GOAL_NAME = 'SALES';
export const LEADS_GOAL_NAME = 'LEADS';
export const FUNNEL_ID_FORM_CONTROL_NAME = 'funnelId';
export const NUMBER_OF_STEPS = 3;

export const STEP_3_MANDATORY_FIELDS: FieldCategoryEnum[] = [
  FieldCategoryEnum.MarketingAdvancement,
  FieldCategoryEnum.Engagement,
];

export const STEP_2_MANDATORY_FIELDS: FieldCategoryEnum[] = [
  FieldCategoryEnum.PaymentMethod,
  FieldCategoryEnum.PaymentFrequency,
  FieldCategoryEnum.OfferPolicy,
  FieldCategoryEnum.TargetChannels,
  FieldCategoryEnum.TargetLocation,
  FieldCategoryEnum.ObjectiveType,
  FieldCategoryEnum.GoalType,
  FieldCategoryEnum.GoalMeasureSales,
  FieldCategoryEnum.GoalMeasureLeads,
  FieldCategoryEnum.GoalPeriod,
];

export const STEP_1_MANDATORY_FIELDS: FieldCategoryEnum[] = [
  FieldCategoryEnum.ProductType,
  FieldCategoryEnum.ProductName,
  FieldCategoryEnum.TargetSegment,
  FieldCategoryEnum.TargetJob,
  FieldCategoryEnum.TargetPerson,
  FieldCategoryEnum.B2bProductType,
  FieldCategoryEnum.B2cProductType,
  FieldCategoryEnum.TargetCharacteristics,
  FieldCategoryEnum.SellingMethod,
  FieldCategoryEnum.PricingCharacteristics,
  FieldCategoryEnum.AvgProductMargin,
];

export enum STATEMENT_STEP_ENUM {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}
