import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'price', pure: true })
export class PricePipe implements PipeTransform {
  transform(value: number): number {
    if (value && value > 0) {
      const val = value / 100;
      return Math.round(((val + Number.EPSILON) * 100) / 100);
    }
    return value;
  }
}
