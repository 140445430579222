import { OnboardingDOMElementFactory, OnboardingElement } from '@shared/models/onboarding-step.model';

export abstract class AbstractOnboardingFactory<T extends OnboardingElement> implements OnboardingDOMElementFactory<T> {
  protected get container(): Element {
    return this._container;
  }

  protected get containerRect(): DOMRect {
    return this.container.getBoundingClientRect();
  }

  protected get anchor(): Element {
    return this._anchor;
  }

  protected get anchorRect(): DOMRect {
    return this.anchor.getBoundingClientRect();
  }
  constructor(
    private readonly _container: Element,
    private readonly _anchor: Element,
  ) {}
  abstract renderElement(element: T): Element;
}
