import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { XPartnerService } from '@shared/services/x-partner.service';
import { Config } from '@shared/configs/config';

@Injectable()
export class PartnerCanActivateGuard implements CanActivate {
  constructor(
    private n: NavigateService,
    private xPartnerService: XPartnerService,
  ) {}

  canActivate(): boolean {
    if (this.xPartnerService.isWhitelabelPartner) {
      this.n.go(Config.MAIN_VIEW);
      return false;
    }
    return true;
  }
}
