import { SHARED_MODULES } from '@shared/consts/shared-modules.const';
import { SHARED_DIRECTIVES } from '@shared/consts/shared-directives.const';
import { SHARED_PIPES } from '@shared/consts/shared-pipes.const';
import { SHARED_COMPONENTS } from '@shared/consts/shared-components.const';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SEOService } from '@core/services/seo.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { SHARED_GUARDS } from '@shared/consts/shared-guards';
import { GoogleAnalyticsService } from '@core/services/google-analytics.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Page404Component } from './components/page404/page404.component';
import { SHARED_RESOLVERS } from '@shared/consts/shared-resolvers.const';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { of } from 'rxjs';
import { TUI_DIALOG_CLOSES_ON_BACK } from '@taiga-ui/cdk';
import { PartnerNoAuthComponent } from './components/partner-no-auth/partner-no-auth.component';
import { OnboardingCongratulationsModalComponent } from './components/onboarding-congratulations-modal/onboarding-congratulations-modal.component';
import { AudioRecorderService } from './services/audio-recorder.service';
import { ShareTiptapDocumentModalComponent } from './components/business-flow-header/share-tiptap-document-modal/share-tiptap-document-modal.component';
@NgModule({
  declarations: [
    SHARED_COMPONENTS,
    SHARED_PIPES,
    SHARED_DIRECTIVES,
    Page404Component,
    PaymentStatusComponent,
    PartnerNoAuthComponent,
    OnboardingCongratulationsModalComponent,
    ShareTiptapDocumentModalComponent,
  ],
  imports: [SHARED_MODULES, FormsModule, ReactiveFormsModule],
  exports: [SHARED_MODULES, SHARED_COMPONENTS, SHARED_PIPES, SHARED_DIRECTIVES],
  providers: [
    AudioRecorderService,
    SHARED_PIPES,
    SHARED_GUARDS,
    SHARED_RESOLVERS,
    {
      provide: TUI_DIALOG_CLOSES_ON_BACK,
      useValue: of(false),
    },
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [SEOService, SnackbarService, GoogleAnalyticsService],
    };
  }
}
