import { FilterTypeService } from '@shared/services/filter-type.service';
import { Component, AfterContentInit, Input } from '@angular/core';
import { AbstractListFiltersComponent } from '@shared/abstracts/list-filters.component.abstract';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { UsersFiltersService } from '@modules/users/modules/users-list/shared/services/users-filters.service';

@Component({
  selector: 'df-sort-list-filter',
  templateUrl: './sort-list-filter.component.html',
  styleUrls: ['./sort-list-filter.component.scss'],
})
export class SortListFilterComponent extends AbstractListFiltersComponent implements AfterContentInit {
  @Input() filtersService!: TacticsFiltersService | UsersFiltersService;

  get form(): UntypedFormGroup {
    return this.filtersService.filterFormGroup;
  }

  constructor(
    router: Router,
    public n: NavigateService,
    private filterTypeService: FilterTypeService,
  ) {
    super(router);
  }

  ngAfterContentInit() {
    const set = this.filterTypeService.setServices(this.filtersService);
    this.listService = set.listService;
    this.filtersService = set.filtersService;
  }

  protected checkFilters(): void {}
}
