import { gql } from 'apollo-angular';
import { IntegrationInputDataFragment } from '@modules/funnels/shared/graphql/fragments/integration-input-data.fragment';

export const GetLastGeneratedIntegrationInputsDataDocument = gql`
  query getLastGeneratedIntegrationInputsData($integrationId: Int!) {
    getLastGeneratedIntegrationInputsData(integrationId: $integrationId) {
      ...IntegrationInputData
    }
  }
  ${IntegrationInputDataFragment}
`;
