import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';

@Component({
  selector: 'df-confim-leave-page-dialog',
  templateUrl: './confim-leave-page-dialog.component.html',
  styleUrls: ['./confim-leave-page-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfimLeavePageDialogComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<ConfimLeavePageDialogComponent, boolean>,
  ) {}

  confirmExit(): void {
    this.context.completeWith(true);
  }

  cancel(): void {
    this.context.completeWith(false);
  }
}
