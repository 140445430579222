<df-full-list-loader *ngIf="funnelManageService.loading$ | async"></df-full-list-loader>
<div
  class="w-full h-full overflow-y-auto tui-base-02 py-3 sm:py-5 px-3 sm:px-6 xl:px-9"
  *ngIf="funnelManageService.funnel as funnel"
>
  <div class="w-full flex justify-end mb-4">
    {{
      'Funnels.Overview.Last modified by user on date'
        | translate
          : {
              name: funnel.lastEditor?.fullName ? funnel.lastEditor?.fullName : '-',
              date: funnel.modifiedAt | dateFormat: 'DDD' : language
            }
    }}
  </div>
  <div class="overview">
    <div class="overview__content-funnel">
      <df-funnel-overview-content-funnel></df-funnel-overview-content-funnel>
    </div>
    <div class="overview__recommendations">
      <df-funnel-overview-recommendations
        [recommendedCount]="funnel.recommendationsCount"
      ></df-funnel-overview-recommendations>
    </div>
    <div class="overview__tactics">
      <df-funnel-overview-stats
        [icon]="'assets/img/overview-tactics.svg'"
        [title]="'Funnels.Overview.Tactics' | translate"
        [done]="funnel.doneTacticsCount"
        [todo]="funnel.tactics.length"
      ></df-funnel-overview-stats>
    </div>
    <div class="overview__activities">
      <df-funnel-overview-stats
        [icon]="'assets/img/overview-activities.svg'"
        [title]="'Funnels.Overview.Activities' | translate"
        [done]="funnel.doneActivitiesCount"
        [todo]="todoActivitiesCount"
      ></df-funnel-overview-stats>
    </div>
    <div class="overview__content-generator">
      <df-funnel-overview-content-generator></df-funnel-overview-content-generator>
    </div>
  </div>
  <df-funnel-overview-help *ngIf="!userService.User?.isSemrushPlan()"></df-funnel-overview-help>
</div>
