import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { IFilter } from '@shared/interfaces/filter.interface';
import { debounceTime, filter } from 'rxjs/operators';
import { ListEvent, ListEventType } from '@shared/services/base-list.service';
import { UsersListService } from '@modules/users/modules/users-list/shared/services/users-list.service';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';

@Component({ template: '' })
export abstract class AbstractListFiltersComponent implements OnInit, AfterViewInit, OnDestroy {
  sub: Subscription = new Subscription();
  search: UntypedFormControl = new UntypedFormControl('');
  firstSetValue = false;
  filtersOpened = false;
  _listService!: TacticsListService | UsersListService;

  protected constructor(protected router: Router) {}

  set listService(l: TacticsListService | UsersListService) {
    this._listService = l;
  }
  get listService() {
    return this._listService;
  }

  abstract get form(): UntypedFormGroup;

  readonly stringify: TuiStringHandler<
    | { sortField: string; sortDirection: string; name: string }
    | TuiContextWithImplicit<{
        sortField: string;
        sortDirection: string;
        name: string;
      }>
  > = (item) => ('name' in item ? item.name : item.$implicit.name);
  readonly stringifyFilter: TuiStringHandler<IFilter<any> | TuiContextWithImplicit<IFilter<any>>> = (item) =>
    'name' in item ? item.name : item.$implicit.name;

  selectedInArray(formControlName: string): string {
    return `Selected (${this.form.get(formControlName)?.value.length ?? 0})`;
  }

  ngOnInit(): void {
    this.listenChangeSearchInFilters();
    this.listenRouteChange();
  }

  ngAfterViewInit() {
    if (this.form.get('search')?.value) {
      this.search.patchValue(this.form.get('search')?.value);
    }
    // when  this.listService is set
    this.debounceSearching();
    this.listenReadUrlParams();
  }

  protected listenRouteChange() {
    const sub = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.checkFilters();
    });
    this.sub.add(sub);
  }

  protected listenReadUrlParams(): void {
    const sub = this.listService.emitter.subscribe((event: ListEvent) => {
      if (event.type === ListEventType.READ_URL_PARAMS) {
        this.checkFilters();
      }
    });
    this.sub.add(sub);
  }

  protected abstract checkFilters(): void;

  protected listenChangeSearchInFilters() {
    const sub = this.form.get('search')?.valueChanges.subscribe((val) => {
      if (val && !this.firstSetValue) {
        this.search.patchValue(val, { emitEvent: false });
        this.firstSetValue = true;
      }
      if (!val) {
        this.search.patchValue('', { emitEvent: false });
      }
    });
    this.sub.add(sub);
  }

  protected debounceSearching() {
    const sub = this.search?.valueChanges.pipe(debounceTime(100)).subscribe((value) => {
      const currentValue = this.form.get('search')?.value;
      if ((currentValue && currentValue.trim().toLowerCase() !== value.trim().toLowerCase()) || !currentValue) {
        this.listService.setFirstPage();
        this.form.get('search')?.setValue(value.trim());
      }
    });
    this.sub.add(sub);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
