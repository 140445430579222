import { NgModule } from '@angular/core';
import { MyFunnelComponent } from './pages/my-funnel/my-funnel.component';
import { SharedModule } from '@shared/shared.module';
import { TacticsListPanelComponent } from './pages/tactics-list-panel/tactics-list-panel.component';
import { RouterModule } from '@angular/router';
import { TacticsListHeaderComponent } from './shared/components/tactics-list-header/tactics-list-header.component';
import { TacticsListSidebarComponent } from './pages/tactics-list-panel/components/tactics-list-sidebar/tactics-list-sidebar.component';
import { TacticsTilesComponent } from './pages/tactics-tiles/tactics-tiles.component';
import { TacticsModule } from '@modules/tactics/tactics.module';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { TacticsListCategoriesComponent } from './pages/tactics-list-panel/components/tactics-list-categories/tactics-list-categories.component';
import { SwiperModule } from 'swiper/angular';
import { TacticModalListComponent } from '@modules/tactics/modules/tactics-list/pages/tactic-modal-list/tactic-modal-list.component';
import { TacticPreviewModule } from '@modules/tactics/modules/tactic-preview/tactic-preview.module';
import { TacticsListSubHeaderComponent } from './shared/components/tactics-list-sub-header/tactics-list-sub-header.component';
import { TacticTopBannerTileComponent } from './pages/tactics-tiles/components/tactic-top-banner-tile/tactic-top-banner-tile.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TacticPurchasedFilterComponent } from './pages/tactics-tiles/components/tactic-purchased-filter/tactic-purchased-filter.component';
import { TacticsListSortComponent } from './shared/components/tactics-list-sort/tactics-list-sort.component';
import { DialogTallyRecComponent } from './shared/components/dialog-tally-rec/dialog-tally-rec.component';
import { DialogTallyRecStmtComponent } from './shared/components/dialog-tally-rec-stmt/dialog-tally-rec-stmt.component';
import { TacticsListOnboardingBannerComponent } from './pages/tactics-list-panel/components/tactics-list-onboarding-banner/tactics-list-onboarding-banner.component';
import { TacticsListRoutes } from '@core/routes/configs/tactics.routes';

@NgModule({
  declarations: [
    MyFunnelComponent,
    TacticsListPanelComponent,
    TacticsListHeaderComponent,
    TacticsListSidebarComponent,
    TacticsTilesComponent,
    TacticsListCategoriesComponent,
    TacticModalListComponent,
    TacticsListSubHeaderComponent,
    TacticTopBannerTileComponent,
    TacticPurchasedFilterComponent,
    TacticsListSortComponent,
    DialogTallyRecComponent,
    DialogTallyRecStmtComponent,
    TacticsListOnboardingBannerComponent,
  ],
  imports: [
    SharedModule,
    TacticsModule,
    TacticPreviewModule,
    VirtualScrollerModule,
    InfiniteScrollModule,
    SwiperModule,
    RouterModule.forChild(TacticsListRoutes),
  ],
  exports: [TacticsListPanelComponent],
  providers: [TacticsListPanelComponent],
})
export class TacticsListModule {}
