/* eslint-disable @angular-eslint/component-selector */
import { PromptOptions } from './prompt-options';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TuiDialog } from '@taiga-ui/cdk';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'content-access-dialog',
  templateUrl: './content-access-dialog.component.html',
  styleUrls: ['./content-access-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentAccessDialogComponent {
  list: Array<string> = [];
  // Here you get options + content + id + observer
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<PromptOptions, boolean>,
    private router: Router,
    private readonly userService: UserService,
  ) {
    this.fillList();
  }

  fillList() {
    this.list = [
      'Tactics.Preview.Copy that writes itself with AI',
      'Tactics.Preview.40+ copy templates',
      'Tactics.Preview.Get more leads, sales and sign ups.',
    ];
  }

  goToPackets() {
    this.router.navigate(['', { outlets: { pricing: this.userService.resolvePricingPacketsUrl() } }], {
      skipLocationChange: true,
    });
    this.context.completeWith(true);
  }

  close(): void {
    this.context.completeWith(false);
  }
}
