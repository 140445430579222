<div class="statement-constrols">
  <button
    size="l"
    tuiButton
    appearance="outline"
    type="button"
    icon="tuiIconChevronLeft"
    (click)="previousStep()"
    [disabled]="STATEMENT_STEP_ENUM.ONE === step"
  >
    <span>{{ 'Statement.Prev step' | translate }}</span>
  </button>
  <div class="dots-container">
    <div
      class="dot"
      [class.selected-dot]="STATEMENT_STEP_ENUM.ONE === step"
    ></div>
    <div
      class="dot"
      [class.selected-dot]="STATEMENT_STEP_ENUM.TWO === step"
    ></div>
    <div
      class="dot"
      [class.selected-dot]="STATEMENT_STEP_ENUM.THREE === step"
    ></div>
  </div>
  <button
    size="l"
    tuiButton
    appearance="primary"
    type="button"
    iconRight="tuiIconChevronRight"
    (click)="nextStep()"
    [showLoader]="!!(statementService.creatingStatementLoader$ | async)"
    [disabled]="!!(statementService.creatingStatementLoader$ | async)"
  >
    <span>{{ (step === LAST_STEP ? 'Statement.Finish' : 'Statement.Next') | translate }}</span>
  </button>
</div>
