import { NgModule } from '@angular/core';
import { TacticSettingsComponent } from './pages/tactic-settings/tactic-settings.component';
import { SharedModule } from '@shared/shared.module';
import { TacticSettingsCloseAlertComponent } from './shared/components/tactic-settings-close-alert/tactic-settings-close-alert.component';
import { TacticSettingsHeaderComponent } from './shared/components/tactic-settings-header/tactic-settings-header.component';
import { RouterModule } from '@angular/router';
import { TacticSettingsShareComponent } from './pages/tactic-settings/components/tactic-settings-share/tactic-settings-share.component';
import { ResourceTeamsModule } from '@shared/modules/resource-teams/resource-teams.module';
import { TacticsModule } from '@modules/tactics/tactics.module';
import { TacticSettingsAccessibilityComponent } from './pages/tactic-settings/components/tactic-settings-accessibility/tactic-settings-accessibility.component';
import { TacticSettingsAboutComponent } from './pages/tactic-settings/components/tactic-settings-about/tactic-settings-about.component';
import { TacticSettingsActivitiesComponent } from './pages/tactic-settings/components/tactic-settings-activities/tactic-settings-activities.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TacticSettingsActivityComponent } from './pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/tactic-settings-activity.component';
import { ActivityAssetModalComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-asset-modal/activity-asset-modal.component';
import { ActivityBenchmarkModalComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-benchmark-modal/activity-benchmark-modal.component';
import { ActivityFormActionsComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-actions/activity-form-actions.component';
import { ActivityFormAssetsComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-assets/activity-form-assets.component';
import { ActivityFormBenchmarksComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-benchmarks/activity-form-benchmarks.component';
import { ActivityFormDataComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-data/activity-form-data.component';
import { ActivityFormHeaderComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-header/activity-form-header.component';
import { ActivityFormActivityTypeComponent } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-data/components/activity-form-activity-type/activity-form-activity-type.component';
import { TacticSingleDraftModalComponent } from '@modules/tactics/modules/tactic-settings/shared/components/tactic-single-draft-modal/tactic-single-draft-modal.component';
import { SellOnMarketplaceButtonComponent } from '@modules/tactics/modules/tactic-settings/shared/components/sell-on-marketplace-button/sell-on-marketplace-button.component';
import { TacticSettingsMetricsComponent } from './pages/tactic-settings/components/tactic-settings-metrics/tactic-settings-metrics.component';
import { TacticMetricFormModalComponent } from './pages/tactic-settings/components/tactic-settings-metrics/components/tactic-metric-form-modal/tactic-metric-form-modal.component';
import { TacticSettingsTabTitleComponent } from './shared/components/tactic-settings-tab-title/tactic-settings-tab-title.component';
import { TacticSettingsRoutes } from '@core/routes/configs/tactics.routes';
import { TuiTagModule } from '@taiga-ui/kit';

@NgModule({
  declarations: [
    TacticSettingsComponent,
    TacticSettingsCloseAlertComponent,
    TacticSettingsHeaderComponent,
    TacticSettingsShareComponent,
    TacticSettingsAccessibilityComponent,
    TacticSettingsAboutComponent,
    TacticSettingsActivitiesComponent,
    TacticSettingsActivityComponent,
    ActivityAssetModalComponent,
    ActivityBenchmarkModalComponent,
    ActivityFormActionsComponent,
    ActivityFormAssetsComponent,
    ActivityFormBenchmarksComponent,
    ActivityFormDataComponent,
    ActivityFormHeaderComponent,
    ActivityFormActivityTypeComponent,
    TacticSingleDraftModalComponent,
    SellOnMarketplaceButtonComponent,
    TacticSettingsMetricsComponent,
    TacticMetricFormModalComponent,
    TacticSettingsTabTitleComponent,
  ],
  imports: [
    SharedModule,
    ResourceTeamsModule,
    RouterModule.forChild(TacticSettingsRoutes),
    TacticsModule,
    DragDropModule,
    TuiTagModule,
  ],
  exports: [TacticSettingsComponent, TacticSettingsHeaderComponent],
})
export class TacticSettingsModule {}
