<div
  class="asset-tile cursor-pointer"
  (click)="showSidebar = true"
>
  <header [ngSwitch]="asset.integration">
    <h1
      class="asset-tile__header"
      *ngSwitchCase="'prompt'"
    >
      {{ 'AssetsLibrary.Prompt content' | translate }}
    </h1>
    <h1
      class="asset-tile__header"
      *ngSwitchCase="'writesonic'"
    >
      {{ 'AssetsLibrary.Writesonic content' | translate }}
    </h1>
    <h1
      class="asset-tile__header"
      *ngSwitchCase="'stablediffusion'"
    >
      {{ 'AssetsLibrary.Image (StableDiffusion)' | translate }}
    </h1>
    <h1
      class="asset-tile__header"
      *ngSwitchCase="'stablediffusionvideo'"
    >
      {{ 'AssetsLibrary.Image (StableDiffusion)' | translate }}
    </h1>
    <h1
      class="asset-tile__header"
      *ngSwitchCase="'chatgpt'"
    >
      {{ 'AssetsLibrary.ChatGPT content' | translate }}
    </h1>
    <h1
      class="asset-tile__header"
      *ngSwitchCase="'abyssales'"
    >
      {{ 'AssetsLibrary.Banner (Abyssale)' | translate }}
    </h1>
  </header>

  <div class="h-[340px] flex justify-center items-center">
    <p
      class="bg-[#F6F6F6] text-[#191C30E5] text-[14px] font-medium p-2 w-full h-[340px]"
      *ngIf="asset.type === 'string'"
    >
      {{ removeHTML(truncateText(asset.value)) }}
    </p>
    <img
      class="p-2 max-h-[340px]"
      *ngIf="asset.type === 'file'"
      [src]="getImagePath(asset.fileId)"
      alt=""
    />
    <video
      *ngIf="asset.type === 'video'"
      width="320"
      height="240"
      controls
    >
      <source
        [src]="asset.fileId"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  </div>
</div>

<!-- Sidebar -->
<div
  class="sidebar relative h-full"
  *tuiSidebar="showSidebar; direction: 'right'"
>
  <header class="py-4 px-8 sidebar__border flex items-center">
    <h1 class="text-[1rem] font-semibold text-[#191C30E5]">
      {{ 'Asset details' }}
    </h1>
    <button
      appearance="icon"
      tuiIconButton
      size="s"
      icon="tuiIconClose"
      class="ml-auto"
      (click)="showSidebar = false"
    ></button>
  </header>

  <tui-scrollbar class="h-[110%]">
    <div class="py-6 px-8 sidebar__border">
      <p
        class="bg-[#F6F6F6] text-[#191C30E5] text-[14px] font-medium p-2 flex justify-between"
        *ngIf="asset.type === 'string'"
      >
        <span class="block whitespace-pre-line">{{ removeHTML(asset.value) }}</span>
        <button
          class="w-[20px] h-[20px]"
          (click)="copyToClipboard(asset.value)"
        >
          <svg-icon [src]="'/assets/img/prompt-copy.svg'"></svg-icon>
        </button>
      </p>
      <img
        class="p-2 m-auto"
        *ngIf="asset.type === 'file'"
        [src]="getImagePath(asset.fileId)"
        alt=""
      />
      <video
        *ngIf="asset.type === 'video'"
        width="320"
        height="240"
        controls
      >
        <source
          [src]="asset.fileId"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="py-6 px-8 middle-wrapper">
      <div
        *ngFor="let context of asset.context"
        class="mb-6"
      >
        <p class="font-medium text-[14px] text-[#1B1F3B66]">
          {{ context.key }}
        </p>
        <p class="font-semibold text-[14px] text-[#191C30BF]">
          {{ removeHTML(context.value) }}
        </p>
      </div>
    </div>
  </tui-scrollbar>
  <footer
    class="py-6 px-3 flex justify-center items-center gap-2 absolute bottom-0 w-full bg-[#fff]"
    [class.sidebar__border-top]="asset.context.length"
  >
    <button
      class="text-[#1B1F3BA6]"
      tuiButton
      appearance="outline"
      icon="tuiIconTrash2"
      (click)="deleteAsset()"
    >
      {{ 'AssetsLibrary.Delete' | translate }}
    </button>
    <button
      *ngIf="asset.type === 'file'"
      tuiButton
      icon="tuiIconDownload"
      (click)="downloadAsset(asset.fileId)"
    >
      {{ 'AssetsLibrary.Download' | translate }}
    </button>
  </footer>
</div>
