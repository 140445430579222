<a
  size="m"
  tuiButton
  type="button"
  appearance="outline"
  [showLoader]="loading"
  [disabled]="loading"
  (click)="loadPrevPage()"
>
  {{ text }}
</a>
