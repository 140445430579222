import { gql } from 'apollo-angular';

export const GetCatalogByDefDocument = gql(/* GraphQL*/ `query getCatalogByDef($defId: Int!)
{
  getCatalogByDef(defId: $defId)
  {
    id
    name
    valueType
    lifetime
    searchVisibility
    data {
      id
      value
    }
  }
}

`);
