import { AppInjector } from '@core/services/app-injector.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { Observable, of, throwError } from 'rxjs';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { FunnelTacticInputGraphql, PermissionType, TacticInputGraphql } from '@modules/graphql/graphql-types';
import { EditTacticMutation } from '@modules/tactics/shared/graphql/mutations/edit-tactic.mutation.generated';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { TuiDialogService } from '@taiga-ui/core';
import { Injector } from '@angular/core';
import { Tactic } from '@shared/models/tactic.model';
import { UserService } from '@shared/services/user.service';
import { TacticSingleDraftModalComponent } from '@modules/tactics/modules/tactic-settings/shared/components/tactic-single-draft-modal/tactic-single-draft-modal.component';
import { ListEventType } from '@shared/services/base-list.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { GraphqlErrorEnum } from '@modules/graphql/enums/graphql-error.enum';
import { catchError, switchMap } from 'rxjs/operators';
import { CloneTacticMutation } from '@modules/tactics/shared/graphql/mutations/clone-tactic.mutation.generated';
import { EContainerTabType } from '@shared/interfaces/container-tab.interface';

export class TacticController {
  n: NavigateService;
  t: TranslateService;
  s: SnackbarService;
  tacticGraphqlService!: TacticGraphqlService;
  pricingService: PricingService;
  dialogService: TuiDialogService;
  userService: UserService;
  tacticsListService: TacticsListService;
  injector: Injector;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.n = this.injector.get(NavigateService);
    this.t = this.injector.get(TranslateService);
    this.s = this.injector.get(SnackbarService);
    this.tacticGraphqlService = this.injector.get(TacticGraphqlService);
    this.pricingService = this.injector.get(PricingService);
    this.dialogService = this.injector.get(TuiDialogService);
    this.userService = this.injector.get(UserService);
    this.tacticsListService = this.injector.get(TacticsListService);
  }

  createTactic() {
    return new Observable(() => {
      this.pricingService.checkPricing(PermissionType.TacticsCreate).subscribe({
        next: () => {
          const draft = this.userService.User?.tacticDraft;
          if (draft) {
            this.dialogService
              .open<TacticSingleDraftModalComponent>(
                new PolymorpheusComponent(TacticSingleDraftModalComponent, this.injector),
                {
                  dismissible: true,
                  closeable: true,
                  data: draft,
                  label: this.t.instant('Tactics.Manage.You already have one draft of tactic'),
                },
              )
              .subscribe((override) => {
                if (override) {
                  this.remove(draft.id)
                    .pipe(catchError(this._handleUnexistingItemRemoval.bind(this)))
                    .subscribe({
                      next: () => {
                        const user = this.userService.User!;
                        user.tacticDraft = undefined;
                        this.userService.User = user;
                        this.s.success(
                          this.t.instant('Tactics.Draft tactic successfully removed', { name: draft.name }),
                        );
                        this.tacticsListService.records = this.tacticsListService.records.filter(
                          (t) => t.id !== draft.id,
                        );
                        this.tacticsListService.emitter.emit({
                          type: ListEventType.END_GETTING_RECORDS,
                        });
                        this.goToTacticCreate();
                      },
                      error: () => this.s.defaultError(),
                    });
                } else {
                  // edit existing
                  this.openEdit(draft.id);
                }
              });
          } else {
            this.goToTacticCreate();
          }
        },
      });
    });
  }

  private _handleUnexistingItemRemoval(error: ApolloError) {
    if (error.graphQLErrors?.length) {
      switch ((error.graphQLErrors[0] as CustomGraphqlErrorInterface).errorCode) {
        case GraphqlErrorEnum.ITEM_NOT_EXISTS:
          return of(null);
        default:
          return throwError(error);
      }
    } else {
      return throwError(error);
    }
  }

  goToTacticCreate() {
    this.n.go('tactics/settings/create');
  }

  shareTactic(tactic: Tactic) {
    this.n.go('tactics/settings/t/:id', { id: tactic.id }, { queryParams: { tab: EContainerTabType.SHARE } });
  }

  cloneTactic(id) {
    return this.pricingService
      .checkPricing(PermissionType.TacticsCreate)
      .pipe(switchMap(() => this.tacticGraphqlService.cloneTactic(id)))
      .subscribe(
        (res: FetchResult<CloneTacticMutation>) => {
          this.s.success(this.t.instant('Tactics.Manage.Tactic copied successfully'));
          this.n.go('tactics/preview/p/:id', { id: res.data?.cloneTactic.id });
        },
        () => this.s.error(this.t.instant('Tactics.Manage.Error during copying tactic. Try again.')),
      );
  }

  edit(tactic: TacticInputGraphql, id: number): Observable<FetchResult<EditTacticMutation>> {
    return this.tacticGraphqlService.editTactic(tactic, id);
  }

  openEdit(tacticId: number) {
    this.n.go('tactics/settings/t/:id', { id: tacticId });
  }

  preview(tacticId: number) {
    this.n.go('tactics/preview/p/:id', { id: tacticId });
  }

  publicPreview(tacticId: number) {
    this.n.go('public/tactic/:id', { id: tacticId });
  }

  rateTactic(tacticId: number, rate: number) {
    return this.tacticGraphqlService.rateTactic(tacticId, rate);
  }

  addToMyFunnel(tacticId: number) {
    return this.tacticGraphqlService.addToMyFunnel(tacticId);
  }

  removeFromFunnel(tacticId: number) {
    return this.tacticGraphqlService.removeFromFunnel(tacticId);
  }

  createFunnelTactic(input: FunnelTacticInputGraphql) {
    return this.tacticGraphqlService.createFunnelTactic(input);
  }

  remove(tacticId: number) {
    return this.tacticGraphqlService.removeTactic(tacticId);
  }
}
