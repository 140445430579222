import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Config } from '@shared/configs/config';
import { AppSumoErrorEnum } from '@modules/authorization/pages/app-sumo-error/app-sumo-error.enum';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-app-sumo-error',
  templateUrl: './app-sumo-error.component.html',
  styleUrls: ['./app-sumo-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSumoErrorComponent implements OnInit {
  readonly Config = Config;
  readonly AppSumoErrorEnum = AppSumoErrorEnum;
  errorType: AppSumoErrorEnum | null = null;

  constructor(
    private readonly route: ActivatedRoute,
    public readonly userService: UserService,
    private authService: AuthStorageService,
    private n: NavigateService,
  ) {}

  ngOnInit(): void {
    this.errorType = AppSumoErrorEnum[this.route.snapshot.queryParams['error']] ?? null;
    if (this.errorType === AppSumoErrorEnum.ALREADY_LOGGED_IN && !this.route.snapshot.queryParams['token']) {
      this.errorType = null;
    }
  }

  logoutAndTryAgain(): void {
    this.authService.logout();
    this.n.go('appsumo-sign-up', {}, { queryParams: { token: this.route.snapshot.queryParams['token'] } });
  }
}
