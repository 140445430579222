import { Component, OnInit } from '@angular/core';
import { StatementService } from '@modules/statement/shared/services/statement.service';

@Component({
  selector: 'df-public-statement',
  templateUrl: './public-statement.component.html',
  styleUrls: ['./public-statement.component.scss'],
})
export class PublicStatementComponent implements OnInit {
  constructor(private statementService: StatementService) {}

  ngOnInit(): void {
    this.statementService.publicStatementForm = {};
  }
}
