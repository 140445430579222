import { gql } from 'apollo-angular';

export const GenerateAdDocument = gql`
  mutation generateAd($docName: String!, $funnelId: Float!) {
    generateAd(docName: $docName, funnelId: $funnelId) {
      ads
      images
    }
  }
`;
