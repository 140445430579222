export async function dataURLtoFileOrBlob(
  url: string,
  { filename = '', mainType = '', returnBlob = false },
): Promise<File | Blob> {
  const ret = await fetch(url);
  const blob = await ret.blob();
  if (returnBlob) return blob;

  return new File([blob], filename, { type: mainType });
}
