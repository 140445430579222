import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { ActionTypeEnum } from './business-data.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { InputsFilesInputGraphql, InputsInputGraphql } from '@modules/graphql/graphql-types';
import file2Base64 from '@shared/helpers/file-to-base64.helper';
import { BusinessDataService } from './business-data.service';
import { Regex } from '@shared/configs/regex';
import { catchError } from 'rxjs';
import { BusinessDataListComponent } from './components/business-data-list/business-data-list.component';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TuiDialogService } from '@taiga-ui/core';
import { BusinessDataErrorComponent } from './components/business-data-error/business-data-error.component';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { GetInputDataResponse } from './graphql/get-input-data.query';
import { urlToFile } from '@shared/helpers/url-to-file.helper';
import { environment } from '@environments/environment';
import { DateTime } from 'luxon';
import { RECORDING_FILES_EXTENTIONS, UPLOAD_FILES_EXTENTIONS } from './business-data.const';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { MemoryLogService } from '@shared/services/memory-log.service';

enum FileType {
  Brief = 'Brief',
  Url = 'Url',
  File = 'File',
  Dropbox = 'Dropbox',
  Google = 'Google',
  Youtube = 'Youtube',
}

@Component({
  selector: 'df-business-data',
  templateUrl: './business-data.component.html',
  styleUrls: ['./business-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessDataComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild(BusinessDataListComponent) child!: BusinessDataListComponent;

  selectedFormType = ActionTypeEnum.SCRAPE_WEBSITE;
  form = new FormGroup({
    [ActionTypeEnum.SCRAPE_WEBSITE]: new FormArray([new FormControl('', Validators.pattern(Regex.softUrl))]),
    [ActionTypeEnum.WRITE_DESCRIPTION]: new FormControl(''),
    [ActionTypeEnum.UPLOAD_FILES]: new FormControl<File[]>([]),
    [ActionTypeEnum.RECORDINGS]: new FormControl<File[]>([]),
    [ActionTypeEnum.YOUTUBE]: new FormArray([new FormControl('', Validators.pattern(Regex.url))]),
    [ActionTypeEnum.GOOGLE_DRIVE]: new FormArray([]),
    [ActionTypeEnum.DROPBOX]: new FormArray([]),
  });

  ActionTypeEnum = ActionTypeEnum;
  loading = false;
  funnelId!: number;
  isEditMode = false;
  inputData: GetInputDataResponse | null = null;

  private checkCanvasIsGeneratedInterval: NodeJS.Timeout | null = null;

  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private changes: ChangeDetectorRef,
    private route: ActivatedRoute,
    private businessDataService: BusinessDataService,
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
    public userService: UserService,
    private memoryLogService: MemoryLogService,
  ) {
    super();
  }

  ngOnInit() {
    this.funnelId = +this.route?.parent?.snapshot.params['id'];

    if (!this.funnelId) {
      this.funnelId = +this.userService.User!.contextFunnel!.id;
    }

    this.fetchData();
  }

  ngOnDestroy() {
    this.memoryLogService.stopListening();
    clearInterval(this.checkCanvasIsGeneratedInterval!);
    this.sub.unsubscribe();
  }

  getLastEditDate() {
    const nowDate = DateTime.now();
    const editedDate = DateTime.fromISO(this.inputData?.editedAt as string);

    return nowDate === editedDate ? 'just now' : editedDate.setLocale('en').toRelative();
  }

  fetchData() {
    this.businessDataService.getInputData(this.funnelId).subscribe((res) => {
      if (res?.data) {
        this.isEditMode = true;
        this.inputData = res.data.getInputData as GetInputDataResponse;
        this.patchValue();
      }
    });
  }

  async patchValue() {
    const data = this.inputData;
    const scrappField = this.form.get(ActionTypeEnum.SCRAPE_WEBSITE) as FormArray;
    const youtubeField = this.form.get(ActionTypeEnum.YOUTUBE) as FormArray;

    scrappField.clear();
    youtubeField.clear();

    data?.urls?.forEach((url) => {
      if (!url.url.includes('youtube')) scrappField.push(new FormControl(url.url, Validators.pattern(Regex.softUrl)));
      else youtubeField.push(new FormControl(url.url, Validators.pattern(Regex.url)));
    });

    if (scrappField.length === 0) scrappField.push(new FormControl('', Validators.pattern(Regex.softUrl)));
    if (youtubeField.length === 0) youtubeField.push(new FormControl('', Validators.pattern(Regex.url)));

    this.form.patchValue({
      [ActionTypeEnum.WRITE_DESCRIPTION]: data?.brief ?? '',
    });

    for (const file of data?.files ?? []) {
      const fileObject = await urlToFile(
        file.name,
        `${environment.NEW_CDN_PATH}/${file.url}`,
        file.mimeType,
        file.extension,
      );

      if (fileObject) {
        if (UPLOAD_FILES_EXTENTIONS.includes(file.extension))
          this.form
            .get(ActionTypeEnum.UPLOAD_FILES)
            ?.setValue([...(this.form.get(ActionTypeEnum.UPLOAD_FILES)?.value ?? []), fileObject]);
        if (RECORDING_FILES_EXTENTIONS.includes(file.extension))
          this.form
            .get(ActionTypeEnum.RECORDINGS)
            ?.setValue([...(this.form.get(ActionTypeEnum.RECORDINGS)?.value ?? []), fileObject]);
      }
    }

    this.changes.detectChanges();
  }

  changeShowedForm($event: ActionTypeEnum) {
    this.selectedFormType = $event;
    this.changes.detectChanges();
  }

  onContinueClick() {
    switch (this.selectedFormType) {
      case ActionTypeEnum.SCRAPE_WEBSITE:
        this.selectedFormType = ActionTypeEnum.WRITE_DESCRIPTION;
        break;

      case ActionTypeEnum.WRITE_DESCRIPTION:
        this.selectedFormType = ActionTypeEnum.UPLOAD_FILES;
        break;

      case ActionTypeEnum.UPLOAD_FILES:
        this.selectedFormType = ActionTypeEnum.RECORDINGS;
        break;

      case ActionTypeEnum.RECORDINGS:
        this.selectedFormType = ActionTypeEnum.YOUTUBE;
        break;

      case ActionTypeEnum.YOUTUBE:
        this.selectedFormType = ActionTypeEnum.GOOGLE_DRIVE;
        break;

      case ActionTypeEnum.GOOGLE_DRIVE:
        this.selectedFormType = ActionTypeEnum.DROPBOX;
        break;

      case ActionTypeEnum.DROPBOX:
        this.selectedFormType = ActionTypeEnum.SCRAPE_WEBSITE;
        break;
    }

    this.child.changeActionByType(this.selectedFormType);
    this.changes.detectChanges();
  }

  async mapFields() {
    const formData = this.form.getRawValue();
    const files: InputsFilesInputGraphql[] = [];

    for (const key in formData) {
      switch (key) {
        case ActionTypeEnum.SCRAPE_WEBSITE:
        case ActionTypeEnum.YOUTUBE: {
          const data = formData[key];
          data?.forEach((value: string | null) => {
            if (value && key === ActionTypeEnum.SCRAPE_WEBSITE) {
              value = value.replace(/^www\./, '');
              value = value.replace(/^https:\/\/www\./, 'https://');
              value = value.replace(/^http:\/\/www\./, 'http://');
              value = value.startsWith('https://') || value.startsWith('http://') ? value : 'https://' + value;
            }
            if (value) files.push({ type: key === ActionTypeEnum.YOUTUBE ? FileType.Youtube : FileType.Url, value });
          });
          break;
        }

        case ActionTypeEnum.WRITE_DESCRIPTION: {
          const data = formData[key];
          if (data) files.push({ type: FileType.Brief, value: data });
          break;
        }

        case ActionTypeEnum.UPLOAD_FILES: {
          const control = this.form.get(ActionTypeEnum.UPLOAD_FILES);
          for (const file of control?.value ?? []) {
            const content = await file2Base64(file);
            files.push({
              type: FileType.File,
              value: content,
              extension: file.type,
            });
          }

          break;
        }
        case ActionTypeEnum.RECORDINGS: {
          const control = this.form.get(ActionTypeEnum.RECORDINGS);
          for (const file of control?.value ?? []) {
            const content = await file2Base64(file);
            files.push({
              type: FileType.File,
              value: content,
              extension: file.type,
            });
          }
          break;
        }
        case ActionTypeEnum.DROPBOX: {
          const data = formData[key];
          data?.forEach((value: string | null) => {
            if (value) files.push({ type: FileType.Dropbox, value });
          });
          break;
        }
        case ActionTypeEnum.GOOGLE_DRIVE: {
          const data = formData[key];
          data?.forEach((value: Record<string, string>) => {
            if (value?.id)
              files.push({
                type: FileType.Google,
                value: value.id,
                authToken: value.token,
              });
          });
          break;
        }
      }
    }

    const noInputData = files.length === 0;
    if (noInputData) {
      this.s.error(this.t.instant('BusinessData.Error.NoData'));
      return;
    }

    return files;
  }

  saveAndExit() {
    this.onSubmit(true);
  }

  onBackClick() {
    history.back();
  }

  generate() {
    this.onSubmit(false, this.n.getPath('/funnels/f/d/:id/marketing-campaign/campaign', {}, { id: this.funnelId }));
  }

  async onSubmit(back = false, redirect = '') {
    this.loading = true;
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.changes.detectChanges();

    if (!this.form.valid) {
      this.loading = false;
      this.changes.detectChanges();
      return;
    }

    const files = await this.mapFields();

    if (!files) {
      this.loading = false;
      this.changes.detectChanges();
      return;
    }

    const input: InputsInputGraphql = {
      funnelId: this.funnelId,
      files,
    };

    this.memoryLogService.listen();

    this.sub.add(
      this.businessDataService
        .setUserInputData(input)
        .pipe(
          catchError((err) => {
            this.loading = false;
            this.changes.detectChanges();
            this.dialogs
              .open<number>(new PolymorpheusComponent(BusinessDataErrorComponent, this.injector), {
                data: { errors: err },
              })
              .subscribe();
            return err;
          }),
        )
        .subscribe((res) => {
          if (res) {
            // TODO: Convert to Websocket in a future
            this.checkCanvasIsGeneratedInterval = setInterval(() => {
              this.businessDataService.checkCanvasIsGenerated(this.funnelId).subscribe((res) => {
                if (res) {
                  clearInterval(this.checkCanvasIsGeneratedInterval!);
                  if (back) {
                    if (this.n.previousUrl === '/funnels/settings/create') {
                      this.n.go('/funnels/list/all');
                      return;
                    }
                    history.length > 0 ? history.back() : this.n.go('funnels/f/d/:id/manage', { id: this.funnelId });
                    return;
                  }

                  if (redirect) {
                    this.n.go(redirect);
                    return;
                  }
                }
              });
            }, 5000);
          }
        }),
    );
  }
}
