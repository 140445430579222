import { EventEmitter } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ListInput } from '@modules/graphql/graphql-types';
import { ListUrlController } from '@shared/controllers/list-url.controller';
import { AppInjector } from '@core/services/app-injector.service';
import { Apollo } from 'apollo-angular';
import { SnackbarService } from '@core/services/snackbar.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { cloneDeep } from '@apollo/client/utilities';
import { DocumentNode, FetchResult } from '@apollo/client/core';

export abstract class BaseListService<T, LQ> {
  records: T[] = [];
  loading = false;
  loadingPrevPage = false;
  loadingNextPage = false;
  currentApolloQuery$!: Subscription | null;
  noNextPage = false;
  emitter: EventEmitter<ListEvent> = new EventEmitter<ListEvent>();
  sub: Subscription = new Subscription();
  query: LQ | ListInput = {};
  loadedPages: number[] = [];
  inited = false;
  shouldUpdateUrl = true;
  allRecordsCount = 0;

  private listUrlController: ListUrlController;
  private apollo: Apollo;
  private s: SnackbarService;
  private n: NavigateService;
  private t: TranslateService;
  public router: Router;
  public route: ActivatedRoute;
  public location: Location;

  constructor(
    public defaultQuery: LQ | ListInput,
    public graphqlDocument: DocumentNode,
  ) {
    const injector = AppInjector.getInjector();
    this.apollo = injector.get(Apollo);
    this.s = injector.get(SnackbarService);
    this.n = injector.get(NavigateService);
    this.t = injector.get(TranslateService);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.location = injector.get(Location);
    this.listUrlController = new ListUrlController(this.router, this.route, this.location);
  }

  abstract prepareQuery();

  initService(noUpdateUrl?: boolean) {
    this.sub = new Subscription();
    this.records = [];
    this.loadedPages = [];
    this.query = cloneDeep(this.defaultQuery);
    this.readUrlParams();
    this.getRecords(ListPageDirection.NONE, noUpdateUrl).toPromise();
    this.updateUrl();
  }

  setFirstPage() {
    this.query['page'] ? (this.query['page'] = 1) : '';
    this.loadedPages = [];
    this.noNextPage = false;
  }

  readUrlParams() {
    const params = this.listUrlController.readParamsFromUrl() as LQ;
    if (params['page'] && params['records']) {
      this.query = params;
    }
    this.emitter.emit({ type: ListEventType.READ_URL_PARAMS });
  }

  getRecords(pageDirection: ListPageDirection = ListPageDirection.NONE, noUpdateUrl = false): Observable<T[] | null> {
    this.prepareQuery();
    return new Observable((observer) => {
      if (pageDirection === ListPageDirection.NEXT && this.noNextPage) {
        observer.next(null);
        observer.complete();
        return;
      } // check if exist next page

      if (typeof this.query['filters'] === 'string' && this.query['filters'] === '[]') {
        this.query['filters'] = [];
      }

      this.setPage(pageDirection);

      if (this.loadedPages.indexOf(this.query['page']) !== -1) {
        observer.next(null);
        observer.complete();
        return;
      }
      !noUpdateUrl ? this.updateUrl() : '';
      this.setLoading(pageDirection);
      this.emitter.emit({ type: ListEventType.START_GETTING_RECORDS });
      const sub = this.apollo
        .query({
          query: this.graphqlDocument,
          variables: {
            query: this.query,
          },
          fetchPolicy: 'no-cache',
        })
        .subscribe({
          next: (res) => this.successGetRecords(res, pageDirection, observer),
          error: this.errorGetRecords.bind(this),
        });

      sub.add(() => {
        this.inited = true;
        this.loading = false;
        this.loadingNextPage = false;
        this.loadingPrevPage = false;
        this.currentApolloQuery$ = null;
        this.emitter.emit({ type: ListEventType.END_GETTING_RECORDS });
        observer.complete();
      });

      this.currentApolloQuery$ = sub;
    });
  }

  initNewSearch() {
    // cancel prev query
    this.currentApolloQuery$?.unsubscribe();
    this.loading = true;
    this.emitter.emit({ type: ListEventType.START_GETTING_RECORDS });
  }

  private successGetRecords(res: FetchResult<any>, pageDirection: ListPageDirection, observer: Subscriber<T[] | null>) {
    const records = this.getFieldFromResponse<T[]>(res, 'records');
    if (records && this.recordTransform) {
      for (let i = 0; i < records?.length; i++) {
        records[i] = this.recordTransform(records[i]);
      }
    }
    this.allRecordsCount = this.getFieldFromResponse<number>(res, 'count')!;
    switch (pageDirection) {
      case ListPageDirection.NEXT:
        if (!records?.length) {
          this.noNextPage = true;
          this.query['page'] && this.query['page'] > 1 ? this.query['page']!-- : '';
          this.updateUrl();
          observer.next(this.records);
          return;
        }
        this.query['page'] ? this.loadedPages.push(this.query['page']) : '';
        records ? (this.records = this.records.concat(records)) : '';
        this.noNextPage = this.records.length === this.allRecordsCount;
        break;
      case ListPageDirection.NONE:
        records ? (this.records = records) : '';
        records?.length && this.query['page'] ? this.loadedPages.push(this.query['page']) : '';
        break;
      case ListPageDirection.PREV:
        records ? (this.records = records.concat(this.records)) : '';
        records?.length && this.query['page'] ? this.loadedPages.push(this.query['page']) : '';
        break;
    }
    this.loadedPages = this.loadedPages.sort();
    this.emitter.emit({ type: ListEventType.LIST_UPDATE });
    observer.next(this.records);
  }

  private getFieldFromResponse<T>(res: FetchResult<any>, lookingKey: string): T | null {
    const findField = (obj: any) => {
      const keys = obj ? Object.keys(obj) : [];
      let looking: T | null = null;
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key === lookingKey) {
          looking = obj[key];
          break;
        } else if (typeof obj[key] === 'object') {
          looking = findField(obj[key]);
        }
      }
      return looking;
    };
    return findField(res?.data ?? null);
  }

  private errorGetRecords() {
    this.s.error(this.t.instant('There is problem with getting list. Refresh your view and try again.'));
  }

  private setPage(pageDirection: ListPageDirection): void {
    switch (pageDirection) {
      case ListPageDirection.NEXT:
        this.query['page'] ? (this.query['page'] = this.loadedPages[this.loadedPages.length - 1] + 1) : '';
        break;
      case ListPageDirection.PREV:
        this.query['page'] ? (this.query['page'] = this.loadedPages[0] - 1) : '';
        break;
    }
  }

  private setLoading(pageDirection: ListPageDirection): void {
    switch (pageDirection) {
      case ListPageDirection.NEXT:
        this.loadingNextPage = true;
        break;
      case ListPageDirection.NONE:
        this.loading = true;
        break;
      case ListPageDirection.PREV:
        this.loadingPrevPage = true;
        break;
    }
  }

  updateUrl() {
    this.shouldUpdateUrl ? this.listUrlController.setParamsToUrl(this.query) : '';
  }

  public isPrevPageLoaded() {
    if (!this.query['page']) return false;
    return this.loadedPages.indexOf(this.query['page']! - 1) !== -1;
  }

  recordTransform(record: T): T {
    return record as T;
  }

  clearService() {
    this.noNextPage = false;
    this.loading = false;
    this.loadingNextPage = false;
    this.loadingPrevPage = false;
    this.sub.unsubscribe();
    this.inited = false;
  }
}

export enum ListPageDirection {
  NONE,
  PREV,
  NEXT,
}

export class ListEvent {
  type!: ListEventType;
  data?: any;
}

export enum ListEventType {
  START_GETTING_RECORDS,
  END_GETTING_RECORDS,
  LIST_UPDATE,
  READ_URL_PARAMS,
}
