import { gql } from 'apollo-angular';
import { FunnelFragment } from '../fragments/funnel.fragment';

export const SetFunnelPubliclyDocument = gql`
  mutation setFunnelPublicly($input: FunnelPubliclyInputGraphql!) {
    setFunnelPublicly(input: $input) {
      ...Funnel
    }
  }
  ${FunnelFragment}
`;
