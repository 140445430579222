import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TacticPreviewService } from '@modules/tactics/modules/tactic-preview/shared/services/tactic-preview.service';
import { Subscription, throwError, timer } from 'rxjs';
import { TacticController } from '@modules/tactics/shared/controllers/tactic.controller';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ActivatedRoute } from '@angular/router';
import { IBreadcrumbItem } from '@shared/interfaces/breadcrumb-item.interface';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { TuiDialogService } from '@taiga-ui/core';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { UserService } from '@shared/services/user.service';
import { FetchResult } from '@apollo/client/core';
import { GetPurchasedTacticByRootQuery } from '@modules/tactics/shared/graphql/queries/get-purchased-tactic-by-root.query.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { mergeMap, retryWhen } from 'rxjs/operators';
import { FunnelPermissionEnum } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-tactic-preview-header',
  templateUrl: './tactic-preview-header.component.html',
  styleUrls: ['./tactic-preview-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticPreviewHeaderComponent implements OnInit, OnDestroy {
  window = window;
  PermissionsGroups = PermissionsGroups;
  FunnelPermissionEnum = FunnelPermissionEnum;

  items: IBreadcrumbItem[] = [];

  sub: Subscription = new Subscription();
  private tacticController = new TacticController();
  private goToMainView = false;
  canBeCloned = false;
  cloneLoading = false;
  copiedTacticId: number | null = null;
  @Input() inModal = false;
  @Input() context: any;

  constructor(
    public tacticPreviewService: TacticPreviewService,
    public userService: UserService,
    private changes: ChangeDetectorRef,
    public n: NavigateService,
    private t: TranslateService,
    private s: SnackbarService,
    private route: ActivatedRoute,
    private tacticGraphqlService: TacticGraphqlService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    this.items = [
      {
        caption: this.t.instant('Tactics.List.All tactics'),
        routerLink: this.n.getPath('/tactics/list/all'),
      },
      {
        caption: this.tacticPreviewService.tactic?.name!,
      },
    ];
  }

  ngOnInit(): void {
    this.listenTacticRefresh();
    this.listenTacticChange();
    if (this.n.previousUrl.indexOf('preview') !== -1) {
      this.goToMainView = true;
    }
    if (!this.n.previousUrl) {
      this.goToMainView = true;
    }
  }

  listenTacticChange() {
    const sub = this.tacticPreviewService.tactic$.subscribe((tactic) => {
      this.setBreadcrumbs();
      this.canBeCloned = tactic.owner?.id !== this.userService.User?.id && (!tactic.isPaid || tactic.purchased);
      tactic.isPaid && tactic.purchased ? this.fetchPaidTacticBacklink(tactic.id) : (this.copiedTacticId = null);
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  listenTacticRefresh() {
    this.sub.add(this.tacticPreviewService.refresh$.subscribe(() => this.tacticPreviewService.refreshTactic()));
  }

  fetchPaidTacticBacklink(id) {
    this.sub.add(
      this.tacticGraphqlService
        .getPurchasedTacticByRoot(id)
        .pipe(retryWhen(genericRetryStrategy()))
        .subscribe(
          (res: FetchResult<GetPurchasedTacticByRootQuery>) => {
            if (this.route.snapshot.queryParamMap.has('customer-redirect')) {
              this.n.go('tactics/preview/p/:id', {
                id: res.data?.getPurchasedTacticByRoot?.id!,
              });
            } else {
              this.copiedTacticId = res.data?.getPurchasedTacticByRoot?.id ?? null;
            }
            this.changes.detectChanges();
          },
          () =>
            this.s.error(
              this.t.instant(
                'Tactics.Preview.There has been an error during fetching your copy of purchased tactic. Please try again.',
              ),
            ),
        ),
    );
  }

  setBreadcrumbs() {
    this.items = [
      {
        caption: this.t.instant('Tactics.List.All tactics'),
        routerLink: this.n.getPath('/tactics/list/all'),
      },
      {
        caption: this.tacticPreviewService.tactic?.name!,
      },
    ];
  }

  edit() {
    this.tacticController.openEdit(this.tacticPreviewService.tactic?.id!);
  }

  clone() {
    this.cloneLoading = true;
    this.changes.detectChanges();
    this.tacticController.cloneTactic(this.tacticPreviewService.tactic?.id!).add(() => {
      this.cloneLoading = false;
      this.changes.detectChanges();
    });
  }

  shareTactic(): void {
    this.tacticController.shareTactic(this.tacticPreviewService.tactic!);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  goBack(): void {
    if (this.n.previousUrl && !this.n.previousUrl.includes('settings')) {
      this.n.router.navigate([this.n.previousUrl]);
    } else if (this.tacticPreviewService.tactic?.currentUserPermission !== null) {
      this.n.go('tactics/list/my-tactics');
    } else {
      this.n.go('/tactics/list/all');
    }
  }
}

const genericRetryStrategy =
  (maxRetryAttempts = 10, scalingDuration = 1000) =>
  (attempts) => {
    return attempts.pipe(
      mergeMap((error, i) => (i + 1 > maxRetryAttempts ? throwError(error) : timer(scalingDuration))),
    );
  };
