import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelStepsService } from '@shared/services/funnel-steps.service';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { Flow } from '@shared/models/flow.model';
import { Config } from '@shared/configs/config';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';

import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import file2Base64 from '@shared/helpers/file-to-base64.helper';
import { SnackbarService } from '@core/services/snackbar.service';
import FileSaver from 'file-saver';

@Component({
  selector: 'df-funnel-generate-pdf-modal',
  templateUrl: './funnel-generate-pdf-modal.component.html',
  styleUrls: ['./funnel-generate-pdf-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FunnelFlowManageService],
})
export class FunnelGeneratePdfModalComponent extends AbstractSubscriptionComponent implements OnInit, AfterViewInit {
  readonly Config = Config;

  loading = false;
  image = '';
  form!: UntypedFormGroup;

  constructor(
    public readonly funnelManageService: FunnelManageService,
    public readonly funnelStepsService: FunnelStepsService,
    public readonly funnelFlowManageService: FunnelFlowManageService,
    private changes: ChangeDetectorRef,
    private s: SnackbarService,
  ) {
    super();
  }

  get highlightedFlow(): Flow | null {
    return this.funnelManageService.funnel?.flows.find((flow) => flow.highlighted) ?? null;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      logo: new FormControl(null),
    });
  }

  ngAfterViewInit() {
    if (this.highlightedFlow) {
      this.funnelFlowManageService.initFlowsController(
        document.getElementById('funnel-wrapper')!,
        'dataPDFFunnelTacticId',
      );
      this.funnelFlowManageService.paintConnections();
      this.parseSVGtoPNG();
    }
  }

  generate(): void {
    this.loading = true;
    this.changes.detectChanges();

    this.funnelManageService.generatePDF(this.image!).subscribe((res) => {
      this.loading = false;
      this.changes.detectChanges();

      if (!res) {
        this.s.error('Something went wrong. Please try again');
        return;
      }

      FileSaver.saveAs(res!.data!.funnelGeneratePDF, 'funnel.pdf');
    });
  }

  async logoChange(event: File | null) {
    this.image = event ? await file2Base64(event) : '';
    this.changes.detectChanges();
  }

  private getFilename(): string {
    let filename = this.funnelManageService.funnel?.name.replaceAll(/\s/g, '-');
    if (this.highlightedFlow) {
      filename += '_' + this.highlightedFlow.name.replaceAll(/\s/g, '-');
    }
    return filename ?? 'funnel';
  }

  private parseSVGtoPNG(): void {
    document
      .getElementById('pdf-container')!
      .querySelectorAll('svg')
      .forEach(function (item) {
        const svg = item.outerHTML.trim();

        const canvas = document.createElement('canvas');
        canvas.width = item.getBoundingClientRect().width;
        canvas.height = item.getBoundingClientRect().height;
        canvas.style.position += item.style.position;
        canvas.style.left += item.style.left;
        canvas.style.top += item.style.top;

        const img = document.createElement('img');
        img.src = canvas.toDataURL('image/png', 1);
        img.width = canvas.width;
        img.height = canvas.height;
        img.style.position += canvas.style.position;
        img.style.left += canvas.style.left;
        img.style.top += canvas.style.top;

        item.parentNode?.appendChild(img);
        item.style.display = 'none';
      });
  }
}
