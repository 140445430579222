import { gql } from 'apollo-angular';

export const CheckPromotionCodeDocument = gql`
  query checkPromotionCode($planId: Int!, $code: String!) {
    checkPromotionCode(planId: $planId, code: $code) {
      value
      currency
      type
      durationInMonths
    }
  }
`;
