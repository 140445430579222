<div
  *ngIf="asset"
  class="border border-solid tui-base-04-border rounded-lg tui-base-01-bg p-3"
  [ngClass]="{
    'tui-text-04-border-hvr': textIntegrations.length
  }"
>
  <div
    class="flex justify-between items-center"
    (click)="toggleAsset()"
    [ngClass]="{ 'cursor-pointer': textIntegrations.length }"
  >
    <df-content-generator-asset-short [asset]="asset"></df-content-generator-asset-short>
    <div class="flex justify-end gap-x-1">
      <df-asset-content-generated-badge
        *ngFor="let integration of textIntegrations"
        [generated]="!!asset.integrationSelectedOutput"
        [integrationName]="'Funnels.Content generator.Integrations.' + integration.outputType | translate"
      ></df-asset-content-generated-badge>
      <button
        tuiIconButton
        type="button"
        size="xs"
        appearance="flat"
        icon="tuiIconChevronDown"
        class="duration-200"
        [ngClass]="{ 'rotate-180': expanded }"
        *ngIf="textIntegrations.length"
      ></button>
    </div>
  </div>
  <tui-expand [expanded]="expanded">
    <div
      class="mt-3"
      *ngFor="let integration of textIntegrations"
    >
      <p class="tui-text-body-xs tui-text-04 uppercase mb-1 font-bold">
        {{ 'Funnels.Content generator.Integrations.' + integration.outputType | translate }}
      </p>
      <ng-container *ngIf="asset.integrationSelectedOutput; else generatePlaceholder">
        <df-content-generator-item-tile
          [asset]="asset"
          [displayMode]="true"
          [data]="asset.integrationSelectedOutput"
          (regenerateContent)="openContentGenerator(integration)"
        ></df-content-generator-item-tile>
      </ng-container>
      <ng-template #generatePlaceholder>
        <df-content-generator-integration-placeholder
          [type]="integration.outputType"
          (generateContent)="openContentGenerator(integration)"
        ></df-content-generator-integration-placeholder>
      </ng-template>
    </div>
  </tui-expand>
</div>
