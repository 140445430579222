import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { FunnelSettingsService } from '@modules/funnels/modules/funnel-settings/shared/services/funnel-settings.service';
import { combineLatest, Observable, of } from 'rxjs';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { FunnelSettingsCloseAlertComponent } from '@modules/funnels/modules/funnel-settings/shared/components/funnel-settings-close-alert/funnel-settings-close-alert.component';
import { pluck } from 'rxjs/operators';
import { FunnelSettingsViewModeEnum } from '@modules/funnels/modules/funnel-settings/shared/enums/funnel-settings-view-mode.enum';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { TuiFileLike } from '@taiga-ui/kit';
import { rejectedImages } from '@shared/helpers/file-input.helper';
import { EFunnelSettingsForm } from '@modules/funnels/modules/funnel-settings/shared/enums/funnel-settings-form.enum';
import { FilterProjectsDocument } from '@modules/funnels/shared/graphql/queries/filter-projects.query';
import { LanguageService } from '@core/services/language.service';
import { FunnelPermissionEnum } from '@modules/graphql/graphql-types';
import { EContainerTabType } from '@shared/interfaces/container-tab.interface';
import { ResourceTeamsService } from '@shared/modules/resource-teams/services/resource-teams.service';
import { EResourceViewType } from '@shared/modules/resource-teams/enums/resource-view-type.enum';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-funnel-settings',
  templateUrl: './funnel-settings.component.html',
  styleUrls: ['./funnel-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelSettingsComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  Config = Config;
  EFunnelSettingsForm = EFunnelSettingsForm;
  FilterProjectsDocument = FilterProjectsDocument;
  FunnelPermissionEnum = FunnelPermissionEnum;
  EContainerTabType = EContainerTabType;
  EResourceViewType = EResourceViewType;

  language = '';
  loading = false;
  anyChanges = false;
  isEditMode = false;
  tabIndex = 0;

  get form(): UntypedFormGroup {
    return this.funnelSettingsService.formGroup;
  }

  get imageUrl(): string {
    return Config.ASSETS + this.funnelSettingsService.funnel?.image.file;
  }

  constructor(
    private readonly s: SnackbarService,
    private readonly t: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly languageService: LanguageService,
    readonly funnelSettingsService: FunnelSettingsService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private changes: ChangeDetectorRef,
    public userService: UserService,
    public n: NavigateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.language = this.languageService.getCurrentLanguage();
    this.funnelSettingsService.initService();
    this.listenLoading();
    this.listenChanges();
    this.listenViewModeChange();
  }

  onResourceTeamsComponentReady(resourceTeamsService: ResourceTeamsService) {
    this.funnelSettingsService.resourceTeamsService = resourceTeamsService;
  }

  setActiveTab(index: number) {
    this.funnelSettingsService.activeTab = this.funnelSettingsService.tabs[index];
    this.tabIndex = index;
    this.changes.detectChanges();
  }

  private initTabs() {
    if (this.funnelSettingsService.funnel?.currentUserPermission === FunnelPermissionEnum.Editor || !this.isEditMode) {
      this.funnelSettingsService.tabs.push({
        name: this.t.instant('Funnels.Settings.Team'),
        index: 0,
        type: EContainerTabType.TEAM,
      });
    }
    if (this.funnelSettingsService.funnel?.currentUserPermission !== FunnelPermissionEnum.ViewOnly && this.isEditMode) {
      this.funnelSettingsService.tabs.push({
        name: this.t.instant('share'),
        index: 1,
        type: EContainerTabType.SHARE,
      });
    }
    this.funnelSettingsService.activeTab = this.funnelSettingsService.tabs[0];
  }

  private listenLoading(): void {
    const sub = this.funnelSettingsService.loading$.subscribe((val: boolean) => {
      this.loading = val;
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  private listenViewModeChange(): void {
    this.sub.add(
      combineLatest([this.route.data.pipe(pluck('type')), this.route.params]).subscribe(
        ([data, params]: [FunnelSettingsViewModeEnum, Params]) => {
          this.funnelSettingsService.viewMode = data;
          this.isEditMode = data === FunnelSettingsViewModeEnum.EDIT;
          if (this.isEditMode) {
            const sub = this.funnelSettingsService.fetchFunnel(+params.id);
            sub.add(() => {
              this.initTabs();
              this.changes.detectChanges();
            });
          } else {
            this.initTabs();
          }
          this.changes.detectChanges();
        },
      ),
    );
  }

  getControl(path: string): UntypedFormControl {
    return this.form.get(path) as UntypedFormControl;
  }

  private listenChanges(): void {
    this.sub.add(
      this.funnelSettingsService.changesInForm$.subscribe((changes) => {
        this.anyChanges = changes;
        this.changes.detectChanges();
      }),
    );
  }

  openConfirmExitModal(): Observable<boolean> {
    if (this.anyChanges) {
      return new Observable<boolean>((subscriber) => {
        this.dialogService
          .open<boolean>(new PolymorpheusComponent(FunnelSettingsCloseAlertComponent, this.injector), {
            closeable: true,
            dismissible: false,
            size: 's',
            label: this.t.instant('Funnels.Settings.Leaving this page?'),
          })
          .subscribe((val) => {
            subscriber.next(val);
          });
      });
    } else {
      return of(true);
    }
  }

  rejectedImages(e: TuiFileLike | readonly TuiFileLike[]) {
    rejectedImages(e, this.s, this.t);
    this.changes.detectChanges();
  }

  deleteFunnel(): void {
    this.funnelSettingsService.removeFunnel().subscribe({
      next: () => {
        this.n.go('funnels/list/all');
        this.s.success(this.t.instant('Funnels.List.Funnel removed successfully'));
      },
      error: () => {
        this.s.defaultError();
      },
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.funnelSettingsService.clearService();
  }
}
