/* eslint-disable @angular-eslint/component-selector */
import { AppInjector } from '@core/services/app-injector.service';
import { XPartnerService } from '@shared/services/x-partner.service';
import { NotifWidgetService } from '@shared/services/notif-widget.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SEOService } from '@core/services/seo.service';
import { GoogleAnalyticsService } from '@core/services/google-analytics.service';
import { RouterNavigationController } from '@core/controllers/router-navigation.controller';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user.service';
import { DOCUMENT } from '@angular/common';
import { AffiliationService } from '@shared/services/affiliation.service';
import { SwUpdate } from '@angular/service-worker';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';
import { MixpanelEventName, MixpanelService } from '@shared/services/mixpanel.service';
import { filter } from 'rxjs';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  protected readonly injector: Injector;
  private t: TranslateService;
  public router: Router;
  public seoService: SEOService;
  private gaService: GoogleAnalyticsService;
  private userService: UserService;
  private affiliationService: AffiliationService;
  private notifWidgetService: NotifWidgetService;
  private swUpdate: SwUpdate;
  private xPartnerService: XPartnerService;
  private eventService: EventsService;

  timeoutNotifWidget;

  constructor(
    public route: ActivatedRoute,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private mixpanelService: MixpanelService,
    private n: NavigateService,
  ) {
    this.injector = AppInjector.getInjector();
    this.t = this.injector.get(TranslateService);
    this.router = this.injector.get(Router);
    this.seoService = this.injector.get(SEOService);
    this.gaService = this.injector.get(GoogleAnalyticsService);
    this.userService = this.injector.get(UserService);
    this.affiliationService = this.injector.get(AffiliationService);
    this.notifWidgetService = this.injector.get(NotifWidgetService);
    this.swUpdate = this.injector.get(SwUpdate);
    this.xPartnerService = this.injector.get(XPartnerService);
    this.eventService = this.injector.get(EventsService);

    this.initApplication();
  }

  ngOnInit() {
    this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe({
      next: (event: NavigationEnd) => {
        this.n.pushPreviousUrl(event.url);
        this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.VirtualPageView, {
          pageUrl: location.href,
          pageTitle: document.title,
        });

        if (this.userService.User) {
          this.mixpanelService.trackEvent(MixpanelEventName.PageView, {
            time: Date.now(),
            URL: location.href,
          });
        }
      },
    });
  }

  private initApplication() {
    this.gaService.initGA();
    this.setNavigationCtrl();
    this.notifWidgetService.setRenderer(this.renderer);
    this.eventService.setUpIntercome();
  }

  private setNavigationCtrl() {
    new RouterNavigationController(
      this.router,
      this.route,
      this.seoService,
      this.t,
      this.userService,
      this.affiliationService,
      this.xPartnerService,
      this.eventService,
    );
  }

  @HostListener('window:click', ['$event'])
  onClick() {
    const domNotifWidget = this.document.querySelector('#kompassify-notif-widget') as HTMLElement;
    // waiting for TaigaUI tui-dialog close
    const ms = 500;
    this.timeoutNotifWidget = setTimeout(() => {
      const domTuiDialog = this.elementRef.nativeElement.querySelector('tui-dialog-host section');

      if (domTuiDialog) {
        domNotifWidget ? domNotifWidget.classList.add('hidden') : null;
        this.setTestAttributes();
      } else if (domNotifWidget?.classList.contains('hidden')) {
        domNotifWidget ? domNotifWidget.classList.remove('hidden') : null;
      }
    }, ms);
  }

  appUpdate() {
    // SwUpdate for managing application version updates
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(() => {
        if (confirm(this.t.instant('appUpdate'))) {
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        }
      });
    }
  }

  /**
   * On route change - set static attributes for Cypress testing
   */
  setTestAttributes() {
    // fixed order of appearance in DOM tree
    let i = 1;
    this.document.querySelectorAll('input').forEach((input) => {
      input.setAttribute('data-test', 'input-' + i.toString());
      i++;
    });
  }

  ngAfterViewInit(): void {
    this.xPartnerService.setHead(this.document);
    if (this.xPartnerService.isPartner) {
      this.notifWidgetService.setXPartnerClass();
    }
    // set static attributes for Cypress testing
    this.setTestAttributes();
  }

  ngOnDestroy(): void {
    if (this.timeoutNotifWidget) {
      clearTimeout(this.timeoutNotifWidget);
    }
  }
}
