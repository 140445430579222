<div class="statement-select w-full flex items-center">
  <div
    *ngIf="statementInput"
    class="slider-wrapper"
  >
    <div class="slider-placeholder-container">
      <span
        *ngFor="let option of statementInput.options"
        class="input-range-placeholder tui-text_body-s"
      >
        {{ 'Statement.' + option.name | translate }}
      </span>
    </div>
    <input
      (change)="onValueChange($event)"
      type="range"
      [min]="statementInput.options[0].id"
      [max]="statementInput.options[statementInput.options.length - 1].id"
      [value]="selectedValue"
      class="slider"
      step="1"
    />
  </div>
</div>
