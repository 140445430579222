import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { QuillModules } from 'ngx-quill';
import { FilterTacticsCategoriesDocument } from '@modules/tactics/shared/graphql/queries/filter-tactics-categories.query';
import { FilterSegmentsDocument } from '@modules/tactics/shared/graphql/queries/filter-segments.query';
import { FilterEffectsDocument } from '@modules/tactics/shared/graphql/queries/filter-effects.query';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ETacticSettingsFormEnum } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-settings-form.enum';
import { TuiIdentityMatcher } from '@taiga-ui/cdk';
import { TacticParametersService } from '@modules/tactics/shared/services/tactic-parameters.service';
import { editorToolbarConfig } from '@shared/configs/editor-toolbar.config';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TuiInputComponent } from '@taiga-ui/kit';
import { MultiSelectComponent } from '@shared/modules/custom-selects/components/multi-select/multi-select.component';
import { SingleSelectComponent } from '@shared/modules/custom-selects/components/single-select/single-select.component';
import Quill from 'quill';
import { ListNew } from '../tactic-settings-activities/components/tactic-settings-activity/components/activity-form-data/helpers/quill.helper';
import {
  TACTIC_FORM_MAX_SELECTED_CATEGORIES,
  TACTIC_FORM_MAX_SELECTED_SEGMENTS,
} from '@modules/tactics/shared/tactic.configuration';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-tactic-settings-about',
  templateUrl: './tactic-settings-about.component.html',
  styleUrls: ['./tactic-settings-about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticSettingsAboutComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly FilterTacticsCategoriesDocument = FilterTacticsCategoriesDocument;
  readonly FilterSegmentsDocument = FilterSegmentsDocument;
  readonly FilterEffectsDocument = FilterEffectsDocument;
  readonly ETacticSettingsFormEnum = ETacticSettingsFormEnum;

  modules: QuillModules = {
    toolbar: editorToolbarConfig,
  };

  readonly identityMatcher: TuiIdentityMatcher<{ id: number; name: string }> = (obj1, obj2) => obj1.id === obj2.id;
  @ViewChildren(TuiInputComponent) inputs?: QueryList<TuiInputComponent>;
  @ViewChildren(SingleSelectComponent)
  selects?: QueryList<SingleSelectComponent>;
  @ViewChildren(MultiSelectComponent)
  multiSelects?: QueryList<MultiSelectComponent>;

  get form(): UntypedFormGroup {
    return this.tacticSettingsService.formGroup;
  }

  constructor(
    public readonly tacticSettingsService: TacticSettingsService,
    public readonly tacticParametersService: TacticParametersService,
    private userService: UserService,
    private readonly changes: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.listenFormControlUpdates();
    this.prepareQuill();
    this.listenCategoriesValueChange();
    this.listenSegmentsValueChange();
  }

  prepareQuill() {
    Quill.register(ListNew);
    setTimeout(() => {
      if (this.userService.User?.isSemrushPlan()) {
        const video = document.querySelector('.ql-video');
        video?.classList.add('hidden');

        const link = document.querySelector('.ql-link');
        link?.classList.add('hidden');
      }
    }, 250);
  }

  getControl(path: string): UntypedFormControl {
    return this.form.get(path) as UntypedFormControl;
  }

  private listenFormControlUpdates(): void {
    this.sub.add(
      this.tacticSettingsService.updateFormControls$.asObservable().subscribe(() => this.detectFormFieldsChanges()),
    );
  }

  detectFormFieldsChanges(): void {
    this.inputs?.forEach((input) => input.checkControlUpdate());
    this.selects?.forEach((select) => select.detectChanges());
    this.multiSelects?.forEach((select) => select.detectChanges());
    this.changes.detectChanges();
  }

  private listenCategoriesValueChange(): void {
    this.sub.add(
      this.form.get(ETacticSettingsFormEnum.categoriesIds)?.valueChanges.subscribe({
        next: (newValue) =>
          this.handleLimitedFormFieldValueChange(
            ETacticSettingsFormEnum.categoriesIds,
            newValue,
            TACTIC_FORM_MAX_SELECTED_CATEGORIES,
          ),
      }),
    );
  }

  private listenSegmentsValueChange(): void {
    this.sub.add(
      this.form.get(ETacticSettingsFormEnum.segmentsIds)?.valueChanges.subscribe({
        next: (newValue) =>
          this.handleLimitedFormFieldValueChange(
            ETacticSettingsFormEnum.segmentsIds,
            newValue,
            TACTIC_FORM_MAX_SELECTED_SEGMENTS,
          ),
      }),
    );
  }

  private handleLimitedFormFieldValueChange(name: ETacticSettingsFormEnum, newValue, limit: number): void {
    if (newValue && newValue.length > limit) {
      newValue.shift();
      this.form.get(name)?.setValue(newValue);
      this.changes.detectChanges();
    }
  }
}
