import { gql } from 'apollo-angular';

export const GetTacticsRecommendationsDocument = gql/* GraphQL*/ `
  query getTacticsRecommendations($records: Int!, $funnelId: Int!, $uniqueReccommendations: Boolean!) {
    getTacticsRecommendations(funnelId: $funnelId, records: $records, uniqueReccommendations: $uniqueReccommendations) {
      recommendations {
        funnel {
          id
        }
        usedInFunnel
        points
        tactic {
          id
          name
          target
          type {
            id
            name
          }
          levelOfAdvancement {
            id
            name
          }
          categories {
            name
            id
          }
          segments {
            name
            id
          }
          effects {
            name
            id
          }
          funnelSteps {
            name
            id
            type
          }
          inMyFunnel
          owner {
            id
            firstName
            lastName
            avatar {
              file
            }
          }
          rate
        }
      }
      step {
        id
        name
        type
      }
    }
  }
`;
