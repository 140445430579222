import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { AyrshareCreatePostInputGraphql } from '@modules/graphql/graphql-types';

import { catchError, map, of } from 'rxjs';
import { captureException } from '@sentry/angular-ivy';
import { CreateAyrshareAccountDocument } from '@shared/graphql/mutations/create-ayrshare-account.mutation';
import { CreateAyrshareAccountMutation } from '@shared/graphql/mutations/create-ayrshare-account.mutation.generated';
import { DeletePostDocument } from '@shared/graphql/mutations/delete-post.mutation';
import { DeletePostMutation } from '@shared/graphql/mutations/delete-post.mutation.generated';
import { PublicPostDocument } from '@shared/graphql/mutations/publish-post.mutation';
import { PublishPostMutation } from '@shared/graphql/mutations/publish-post.mutation.generated';
import { GenerateAyrshareAccountUrlDocument } from '@shared/graphql/queries/generate-ayrshare-account-url.query';
import { GenerateAyrshareAccountUrlQuery } from '@shared/graphql/queries/generate-ayrshare-account-url.query.generated';
import { GetAyrshareAccountDocument } from '@shared/graphql/queries/get-ayrshare-account.query';
import { GetAyrshareAccountQuery } from '@shared/graphql/queries/get-ayrshare-account.query.generated';
import { GetScheduledPostsQuery } from '@shared/graphql/queries/get-scheduled-post.query.generated';
import { GetScheduledPostsDocument } from '@shared/graphql/queries/get-scheduled-posts.query';
import { GetPostQuery } from '@shared/graphql/queries/get-post.query.generated';
import { GetPostDocument } from '@shared/graphql/queries/get-post.query';

export interface AyrsharePost {
  id: string;
  post: string;
  platforms: string[];
  mediaUrls: string[];
  created: string;
  status: string;
  errors: any;
}

export interface AyrshareAccount {
  activeSocialAccounts: string[];
  createdUTC: string;
  refId: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class AyrshareService {
  constructor(private apollo: Apollo) {}

  getScheduledPosts() {
    return this.apollo
      .query<GetScheduledPostsQuery>({
        query: GetScheduledPostsDocument,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        catchError((err) => {
          captureException(err);
          return of(null);
        }),
        map((res) => (res?.data?.getScheduledPosts ?? []) as AyrsharePost[]),
      );
  }

  deletePost(id: string) {
    return this.apollo
      .mutate<DeletePostMutation>({
        mutation: DeletePostDocument,
        variables: {
          id,
        },
      })
      .pipe(
        catchError((err) => {
          captureException(err);
          return of(null);
        }),
        map((res) => res?.data?.deletePost ?? false),
      );
  }

  publishPost(input: AyrshareCreatePostInputGraphql) {
    return this.apollo
      .mutate<PublishPostMutation>({
        mutation: PublicPostDocument,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        catchError((err) => {
          captureException(err);
          return of(null);
        }),
        map((res) => res?.data?.publishPost ?? false),
      );
  }

  getAccount() {
    return this.apollo
      .query<GetAyrshareAccountQuery>({
        query: GetAyrshareAccountDocument,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        catchError((err) => {
          captureException(err);
          return of(null);
        }),
        map((res) => res?.data?.getAyrshareAccount ?? null),
      );
  }

  createAccount() {
    return this.apollo
      .mutate<CreateAyrshareAccountMutation>({
        mutation: CreateAyrshareAccountDocument,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        catchError((err) => {
          captureException(err);
          return of(null);
        }),
        map((res) => res?.data?.createAyrshareAccount ?? null),
      );
  }

  generateAccountUrl() {
    return this.apollo
      .query<GenerateAyrshareAccountUrlQuery>({
        query: GenerateAyrshareAccountUrlDocument,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data.generateAyrshareAccountUrl));
  }

  getPost(id: string) {
    return this.apollo.query<GetPostQuery>({
      query: GetPostDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
