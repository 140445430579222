import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivityBenchmark } from '@shared/models/activity-benchmark.model';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Activity } from '@shared/models/activity.model';
import { Config } from '@shared/configs/config';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import {
  BenchmarkTypeEnum,
  FunnelPermissionEnum,
  SaveLibraryAssetInputGraphql,
  StepOutputGraphql,
} from '@modules/graphql/graphql-types';
import * as JSZip from 'jszip';
import FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { setQuillCustomElementsEvents } from '@modules/tactics/modules/tactic-settings/pages/tactic-settings/components/tactic-settings-activities/components/tactic-settings-activity/components/activity-form-data/helpers/quill.helper';
import { Regex } from '@shared/configs/regex';
import { TuiDialogService } from '@taiga-ui/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { copyToClipboard } from '@shared/helpers/clipboard.helper';
import { SnackbarService } from '@core/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { UserService } from '@shared/services/user.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AssetsService } from '@modules/assets/shared/assets.service';

@Component({
  selector: 'df-tactic-accordion-activity',
  templateUrl: './tactic-accordion-activity.component.html',
  styleUrls: ['./tactic-accordion-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticAccordionActivityComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  readonly BenchmarkTypeEnum = BenchmarkTypeEnum;
  readonly Regex = Regex;
  FunnelPermissionEnum = FunnelPermissionEnum;
  customPromptOutputId!: number;

  get activity(): Activity | null {
    return this._activity;
  }

  Config = Config;
  private _activity!: Activity | null;
  customPromptId: number | null = null;

  open = false;
  @Input() index = 1;
  @Input() funnelPreview = false;
  @Input() funnelPreviewPermission: FunnelPermissionEnum | undefined = FunnelPermissionEnum.ViewOnly;
  @Input() activityDone = false;
  @Input() contentGenerateMode = false;
  @Input() tactic?: FunnelTactic;
  @Input() funnelTacticId: number | null = null;
  @Input() steps: StepOutputGraphql[] = [];

  @Input() set activity(activity: Activity | null) {
    this._activity = activity;
    this.emptyActivity =
      !activity?.description && !activity?.copy && activity!.assets?.length <= 0 && activity!.benchmarks?.length <= 0;
  }

  @Output() openAccordionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleDone: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refreshList: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() activityIdEmit: EventEmitter<number> = new EventEmitter<number>();

  emptyActivity!: boolean;

  loadingDownloadAllFiles = false;

  customPromptsLabelArray: string[] = [];
  activityId: number | null = null;

  get benchmarkFiles() {
    return this.activity?.benchmarks.filter((v: ActivityBenchmark) => v.type.type === BenchmarkTypeEnum.File);
  }

  get benchmarkLinks() {
    return this.activity?.benchmarks.filter((v: ActivityBenchmark) => v.type.type === BenchmarkTypeEnum.Link);
  }

  public openChat = false;
  public openPublication = false;
  public customPromptData: any;
  public isOpenDescription = false;
  public isEditValue = false;
  public promptTextArray: string[] = [];
  public nameFieldArray: string[] = [];
  public isRefreshValue = false;
  mapCustomPromptsLabel = new Map();
  mapCustomPromptsText = new Map();
  customProptOutputArray: any[] = [];
  mapCustomPromptOutput = new Map();
  mapCustomPromptsLabelValues: any;

  constructor(
    public changes: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private http: HttpClient,
    private t: TranslateService,
    private s: SnackbarService,
    private n: NavigateService,
    private injector: Injector,
    private assetsService: AssetsService,
    private tacticGraphqlService: TacticGraphqlService,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
  }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'h-funnels');
    if (this.activity && this.activity.customPrompts) {
      this.activity.customPrompts.map((item, i) => {
        this.customPromptsLabelArray = [];
        const promptText = item?.promptText;
        const customPromptFields = item?.customPromptFields;
        const arr: string[] = [];
        let arrText: string[] = [];

        if (null !== promptText) {
          const arrNameField = promptText.match(/([^${]+(?=}))/g);
          const wordsArray = promptText.split(/\${(.*?)\}/g);
          if (wordsArray.length > 0 && null !== arrNameField) {
            arrText = wordsArray.filter((i) => !arrNameField.includes(i));
            this.nameFieldArray = arrNameField;
          }

          if (null !== arrNameField && arrNameField.length > 0) {
            arrNameField.map((item) => {
              customPromptFields.map((el) => {
                if (item === el.name) {
                  arr.push(el.label);
                }
              });
            });
          }
        }

        this.promptTextArray = arrText;
        this.mapCustomPromptsText.set(i, arrText);
        this.mapCustomPromptsLabel.set(i, arr);
      });
    }
  }

  onClickPublishButton(customPromptOutputId: number) {
    this.customPromptOutputId = customPromptOutputId;
    this.openPublication = true;
  }

  closePublication() {
    this.openPublication = false;
  }

  customPromptClick() {
    if (this.steps.length <= 0) return;

    this.route.params.subscribe((params) => {
      const tacticId = params.id;

      this.tacticGraphqlService
        .createFunnelTactic({
          funnelId: this.userService.User!.contextFunnel.id,
          tacticId: Number(tacticId),
          stepId: this.steps[0].id,
          position: 0,
        })
        .subscribe({
          next: () => {
            this.s.success(this.t.instant('Tactics.Preview.Tactic added to funnel successfully.'));
            this.n.go(
              'funnels/f/d/:id/manage',
              { id: this.userService.User!.contextFunnel.id },
              { queryParams: { showTactic: tacticId } },
            );
          },
        })
        .add(() => {
          this.changes.detectChanges();
        });
    });
  }

  getCustomPromptOutput(data): any[] {
    return data.filter((el) => el.funnelTacticId === this.funnelTacticId);
  }

  addToAssetLibrary(content: string) {
    const params: SaveLibraryAssetInputGraphql = {
      integration: 'prompt',
      funnelId: this.userService.User!.contextFunnel.id,
      content,
      context: [
        {
          key: 'tacticId',
          value: String(this?.tactic?.id ?? ''),
        },
        {
          key: 'activityId',
          value: String(this.activity?.id ?? ''),
        },
        {
          key: 'tacticName',
          value: this.tactic?.tactic?.name ?? '',
        },
        {
          key: 'activityName',
          value: this.activity?.name ?? '',
        },
        {
          key: 'activityDescription',
          value: this.activity?.description ?? '',
        },
      ],
    };

    this.assetsService.saveLibraryAsset(params).subscribe();
  }

  openToggle(open?: boolean) {
    open ? (this.open = open) : null;
    if (this.open) {
      this.openAccordionChange.emit(this.open);
      setTimeout(() => {
        this.setDescriptionEvents();
      }, 500);
    } else {
      this.openAccordionChange.emit(false);
    }
    this.changes.detectChanges();
  }

  closeToggle() {
    this.open = false;
    this.openAccordionChange.emit(this.open);
    this.changes.detectChanges();
  }

  toggleActivityDone(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.toggleDone.emit(true);
  }

  setDescriptionEvents() {
    setQuillCustomElementsEvents(
      '.tactic-accordion-activity__description',
      this.elementRef,
      this.activity!.benchmarks,
      this.activity!.assets,
      this.renderer,
      this.dialogService,
      this.sub,
      this.injector,
    );
  }

  downloadAllFiles() {
    const zip: JSZip = new JSZip();

    this.loadingDownloadAllFiles = true;

    const obs = new Observable((observer) => {
      this.benchmarkFiles?.forEach((b: ActivityBenchmark, key) => {
        if (b.attachment) {
          this.http
            .get(Config.ASSETS + b.attachment!.file, {
              responseType: 'blob' as 'json',
            })
            .subscribe((v: any) => {
              zip.file(b.attachment!.name, v);
              if (this.benchmarkFiles && key === this.benchmarkFiles.length - 1) {
                observer.next(true);
                observer.complete();
              }
            });
        }
      });
    });
    const fileName = `${this.activity?.name}_${this.t.instant('Tactics.Preview.Benchmarks')}_${this.t.instant('Tactics.Preview.Files')}`;
    obs.subscribe({
      next: () => {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          FileSaver.saveAs(content, fileName.replace(/\s/g, '_').toLocaleLowerCase());
        });
      },
      complete: () => {
        this.loadingDownloadAllFiles = false;
      },
    });
  }

  previewChat(open: boolean, id: number) {
    if (this.router.url.includes('funnels/f/d')) {
      this.openChat = open;
      this.activityIdEmit.emit(id);
      this.changes.detectChanges();
    }
  }

  closeChat(val: boolean) {
    this.openChat = val;
    this.changes.detectChanges();
  }

  getCustomPromptData(customPrompt: any, edit?: boolean, refresh?: boolean) {
    if (this.router.url.includes('funnels/f/d')) {
      this.customPromptData = customPrompt;
    }
    if (edit) {
      this.isEditValue = true;
    } else {
      this.isEditValue = false;
    }

    if (refresh) {
      this.isRefreshValue = true;
    } else {
      this.isRefreshValue = false;
    }
  }

  getDescription(data: string): boolean {
    if (this.router.url.includes('tactics/preview')) {
      if (data.length > 131 && !this.isOpenDescription) {
        return true;
      } else {
        return false;
      }
    } else {
      if (data.length > 75 && !this.isOpenDescription) {
        return true;
      } else {
        return false;
      }
    }
  }

  toggleDescription() {
    this.isOpenDescription = !this.isOpenDescription;
  }

  copyToClipboard(data): void {
    this.renderer.addClass(this.document.body, 'h-notification-success');
    copyToClipboard(data, this.s, this.t);
  }

  geValRefreshList(val: boolean) {
    this.refreshList.emit(val);

    if (val) {
      this.openAccordionChange.emit(val);
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'h-funnels');
    this.renderer.removeClass(this.document.body, 'h-notification-success');
  }
}
