import { gql } from 'apollo-angular';
import { BenchmarkFragment } from '@modules/tactics/shared/graphql/fragments/benchmark.fragment';
import { AssetFragment } from '@modules/tactics/shared/graphql/fragments/asset.fragment';
import { CustomPromptFragment } from '@modules/tactics/shared/graphql/fragments/custom-prompt.fragment';

export const FullActivityFragment = gql`
  fragment FullActivity on ActivityOutputGraphql {
    id
    name
    description
    copy
    position
    type {
      id
      name
      image
    }
    assets {
      ...AssetFragment
    }
    benchmarks {
      ...BenchmarkFragment
    }
    customPrompts {
      ...CustomPromptFragment
    }
  }
  ${BenchmarkFragment}
  ${AssetFragment}
  ${CustomPromptFragment}
`;
