import { Component, Input } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';
import { PublicUserOutputGraphql } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-created-by',
  templateUrl: './created-by.component.html',
  styleUrls: ['./created-by.component.scss'],
})
export class CreatedByComponent {
  Config = Config;

  @Input() user?: PublicUserOutputGraphql;
  @Input() size: 'xs' | 's' | 'm' | 'l' = 's';

  constructor(private n: NavigateService) {}

  userPreview() {
    if (this.user) {
      this.n.go('users/preview/:id', { id: this.user.id });
    }
  }

  userName(): string {
    if (this.user?.firstName) {
      return `${this.user.firstName} ${this.user.lastName}`;
    }

    return 'DF.AI';
  }
}
