<div class="w-full">
  <df-tactic-settings-tab-title></df-tactic-settings-tab-title>

  <form
    [formGroup]="formGroup"
    [validate-form]="formGroup"
    (submit)="inviteEmail()"
  >
    <div class="tui-form__row w-full flex flex-nowrap flex-col md:flex-row">
      <tui-input
        [tuiTextfieldSize]="'l'"
        class="w-full md:w-9/12 md:mr-4"
        formControlName="email"
      >
        {{ 'Tactics.Preview.Add Email, separated by comma' | translate }}
      </tui-input>
      <button
        size="l"
        tuiButton
        [showLoader]="loading"
        [disabled]="loading"
        type="button"
        class="w-full md:w-1/3 mt-2 md:mt-0"
        appearance="primary"
        (click)="inviteEmail()"
      >
        <svg-icon
          [src]="'/assets/img/ic-email.svg'"
          class="mr-2"
        ></svg-icon>
        {{ 'Tactics.Preview.Send invite' | translate }}
      </button>
    </div>

    <div class="mb-4">
      <div
        *ngFor="let mail of tacticSettingsService.tactic?.shares"
        class="w-full mt-2 h-16 p-2 border border-gray-200 border-1 border-solid rounded-xl flex flex-nowrap justify-between content-center"
      >
        <div class="flex items-center flex-grow-0 overflow-x-auto mr-2">
          <div class="rounded-full tui-accent-08 w-11 h-11 mr-2 flex justify-center flex-shrink-0">
            <svg-icon
              [src]="'/assets/img/at_sign.svg'"
              class="self-center"
            ></svg-icon>
          </div>
          <span class="font-semibold">{{ mail.email }}</span>
        </div>
        <div class="self-center flex">
          <tui-hosted-dropdown
            #tuiHostedDropdownComponent
            tuiDropdownAlign="right"
            [content]="content"
          >
            <button
              tuiButton
              type="button"
              appearance="secondary"
              [iconRight]="arrow"
              size="m"
            >
              {{ 'Tactics.Preview.Invited' | translate }}
            </button>
          </tui-hosted-dropdown>
        </div>
        <ng-template #content>
          <tui-data-list>
            <button
              tuiOption
              *ngIf="invitedAgainSharesId.indexOf(mail.id) === -1"
              (click)="tuiHostedDropdownComponent.open = false; onNextInvite(mail)"
            >
              <span class="max-w-xs whitespace-normal block">{{ 'Tactics.Preview.Invite again' | translate }}</span>
            </button>
            <button
              tuiOption
              (click)="onRemove(mail)"
            >
              <span class="max-w-xs whitespace-normal block tui-accent-01">{{
                'Tactics.Preview.Remove' | translate
              }}</span>
            </button>
          </tui-data-list>
        </ng-template>
      </div>
    </div>

    <div
      *ngIf="showSharedLink"
      class="mt-4"
    >
      <p>Click to copy link:</p>
      <tui-island
        class="island-copy"
        (click)="onPublicLinkCopy(APP_URL + '/public/tactic/' + this.tacticSettingsService.tactic?.id!)"
      >
        <tui-line-clamp
          [content]="content"
          [lineHeight]="10"
          [linesLimit]="2"
        ></tui-line-clamp>
      </tui-island>
      <ng-template #content>
        <div class="hint">{{ APP_URL }}/public/tactic/{{ this.tacticSettingsService.tactic?.id! }}</div>
      </ng-template>
    </div>
  </form>
</div>
