import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { FunnelsListService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-list.service';
import { Subscription } from 'rxjs';
import { ListEvent, ListEventType } from '@shared/services/base-list.service';
import { FunnelsFilterService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-filter.service';
import { FunnelController } from '@modules/funnels/shared/controllers/funnel.controller';

@Component({
  selector: 'df-funnels-tiles-empty-state',
  templateUrl: './funnels-tiles-empty-state.component.html',
  styleUrls: ['./funnels-tiles-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelsTilesEmptyStateComponent implements OnInit {
  sub: Subscription = new Subscription();
  funnelController: FunnelController = new FunnelController();

  constructor(
    public n: NavigateService,
    private changes: ChangeDetectorRef,
    public funnelsListService: FunnelsListService,
    public funnelsFiltersService: FunnelsFilterService,
  ) {}

  ngOnInit(): void {
    this.listenListEvents();
  }

  listenListEvents() {
    const sub = this.funnelsListService.emitter.subscribe((e: ListEvent) => {
      switch (e.type) {
        case ListEventType.END_GETTING_RECORDS:
          this.changes.detectChanges();
          break;
      }
    });
    this.sub.add(sub);
  }

  createNewFunnel(): void {
    this.funnelController.createFunnel();
  }
}
