<div class="auth-panel flex justify-center items-center min-h-screen flex-col pt-10 pb-10">
  <div class="auth-panel__container flex flex-col justify-start items-start">
    <img
      [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
      [alt]="xPartnerService.partner.SYSTEM_NAME"
      class="login__logo mb-0"
    />
    <h1 class="tui-text_h2 font-semibold tui-text-03 pl-0 pr-0 mt-4 w-auto">
      {{ 'Auth.EndTrial.title' | translate }}
    </h1>
    <p
      class="tui-text-02"
      [innerHTML]="'Auth.EndTrial.description' | translate"
    ></p>
    <hr class="mb-3" />
    <div class="tui-form__row mt-6 md:mt-10 text-center">
      <button
        (click)="sendEmailAgain()"
        [showLoader]="loading"
        [disabled]="loading || lastSendTime !== null"
        size="xl"
        tuiButton
        type="submit"
        class="w-full block"
        appearance="primary"
      >
        <span *ngIf="!lastSendTime">
          {{ 'Auth.EndTrial.button' | translate }}
        </span>
        <span *ngIf="lastSendTime">
          {{
            'Auth.EndTrial.Send again for :counts'
              | translate
                : {
                    count: lastSendTime.diffNow('seconds').seconds | number: '1.0-0'
                  }
          }}
        </span>
      </button>
      <a
        (click)="backToSignIn()"
        class="mt-4 md:mt-8 block text-center"
        tuiLink
      >
        {{ 'Auth.EndTrial.Back to sign in' | translate }}
      </a>
    </div>
    <hr class="mt-8" />
  </div>
</div>
