import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'df-asset-content-generated-badge',
  templateUrl: './asset-content-generated-badge.component.html',
  styleUrls: ['./asset-content-generated-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetContentGeneratedBadgeComponent {
  @Input() generated = false;
  @Input() integrationName = '';
}
