import { Component, Input } from '@angular/core';
import { ActionTypeEnum } from '@modules/business-data/business-data.model';

@Component({
  selector: 'df-business-data-form-header',
  templateUrl: './business-data-form-header.component.html',
  styleUrl: './business-data-form-header.component.scss',
})
export class BusinessDataFormHeaderComponent {
  @Input() type!: ActionTypeEnum;

  config = {
    [ActionTypeEnum.SCRAPE_WEBSITE]: {
      title: 'Enter your website address',
      description: 'Let us learn your business from website',
      accordionTitle: 'Why share a website URL?',
      accordionDescription:
        'By providing a URL, you enable our AI to gather information from the website, enhancing the results generated for your business. You can share main pages, landing pages, product pages, or about us sections – essentially, any URL containing written information about your business.',
    },
    [ActionTypeEnum.WRITE_DESCRIPTION]: {
      title: 'Write a description',
      description: 'Add a brief business description with more details.',
      accordionTitle: 'What should I include in my description?',
      accordionDescription:
        'Providing a summary of your business will help us gain a clearer understanding of your objectives. Feel free to share valuable information such as your company overview, goals, challenges, target audience, primary products or services, and any other pertinent details.',
    },
    [ActionTypeEnum.UPLOAD_FILES]: {
      title: 'Upload files',
      description: 'Upload TXT, DOCX, PPTX, CSV OR PDF.',
      accordionTitle: 'What types of files should I provide?',
      accordionDescription:
        "Whether it's your latest marketing campaigns, a blog post, or a brief on a new product launch, feel free to share them with us. Your information is secure, and our AI will utilize it to gain a deeper understanding of your business.",
    },
    [ActionTypeEnum.RECORDINGS]: {
      title: 'Add recordings',
      description: 'Upload MPEG, MP4, MOV, AVI',
      accordionTitle: 'What content should be included in my recordings?',
      accordionDescription:
        'Feel welcome to share any new insights about your business and details not explicitly mentioned in other types of documents. You can incorporate your tone of voice, specific channels of focus, and any recent updates.',
    },
    [ActionTypeEnum.YOUTUBE]: {
      title: 'YouTube',
      description: 'Include a link to a YouTube video or channel',
      accordionTitle: 'Why share YouTube videos?',
      accordionDescription:
        'YouTube serves as a valuable source of information. If you have a channel, please share it with us. Additionally, feel free to provide any compelling review videos or content that resonates with your business.',
    },
    [ActionTypeEnum.GOOGLE_DRIVE]: {
      title: 'Upload content form Google Drive',
      description: 'Add any type of file from your Google Drive account',
      accordionTitle: 'What type of content should I share?',
      accordionDescription:
        'You can share any content that provides value to your business and marketing. This includes campaign briefings, materials, articles, and previous strategies.',
    },
    [ActionTypeEnum.DROPBOX]: {
      title: 'Upload content form Dropbox',
      description: 'Add any type of file from your Dropbox account',
      accordionTitle: 'What type of content should I share?',
      accordionDescription:
        'You can share any content that provides value to your business and marketing. This includes campaign briefings, materials, articles, and previous strategies.',
    },
  };
}
