import { gql } from 'apollo-angular';

export const CreateDashboardDocument = gql`
  mutation createDashboard($input: DashboardInputGraphql!) {
    createDashboard(input: $input) {
      id
      name
      type
      link
    }
  }
`;
