import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { ActionTypeEnum } from '@modules/business-data/business-data.model';
interface DropboxFile {
  id: string;
  name: string;
  link: string;
  bytes: number;
  icon: string;
  thumbnailLink?: string | undefined;
  isDir: boolean;
}
@Component({
  selector: 'df-dropbox',
  templateUrl: './dropbox.component.html',
  styleUrl: './dropbox.component.scss',
})
export class DropboxComponent implements AfterViewInit {
  type = ActionTypeEnum.DROPBOX;
  @Input() form!: FormGroup;
  filesList: DropboxFile[] = [];

  constructor(private changes: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.initDropboxScript();
  }

  getFormArray() {
    return this.form.get(this.type) as FormArray;
  }

  initDropboxScript() {
    const script = document.createElement('script');
    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
    script.id = 'dropboxjs';
    script.dataset['appKey'] = environment.DROPBOX_APP_KEY;

    document.head.appendChild(script);

    script.onload = () => {
      this.createDropboxComponent();
    };
  }

  removeField(url: string) {
    const control = this.form.get(this.type) as FormArray;
    if (control) {
      const index = control.controls.findIndex((x) => x.value === url);
      control.removeAt(index);
    }

    //remove from list
    const index = this.filesList.findIndex((x) => x.link === url);
    if (index > -1) {
      this.filesList.splice(index, 1);
    }

    this.changes.detectChanges();
  }

  createDropboxComponent() {
    const options = {
      success: (files: DropboxFile[]) => {
        this.filesList.push(...files);
        const urls = files.map((file) => file.link) as string[];
        const control = this.form.get(this.type) as FormArray;
        if (control) {
          urls.forEach((url) => control.push(new FormControl(url)));
        }

        this.changes.detectChanges();
      },
      cancel: function () {},

      linkType: 'direct' as const,
      multiselect: true,
      folderselect: false,
      sizeLimit: 10000000, //10Mb
    };

    const button = window?.Dropbox?.createChooseButton(options) as HTMLButtonElement;
    document?.getElementById('dropbox-box')?.appendChild(button);
  }
}
