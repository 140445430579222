import { gql } from 'apollo-angular';

export const GetBenchmarkTypesDocument = gql(/* GraphQL*/ `query getBenchmarksTypes
{
  getBenchmarksTypes
  {
    id
    name
    type
    image
  }
}

`);
