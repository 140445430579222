import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { CampaignStatementComponent } from '../statement/statement.component';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { ActivatedRoute } from '@angular/router';
import { PublicFunnelComponent } from '@modules/public/pages/public-funnel/public-funnel.component';

@Component({
  selector: 'df-marketing-funnel',
  templateUrl: './marketing-funnel.component.html',
  styleUrls: ['./marketing-funnel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingFunnelComponent implements OnInit {
  @ViewChild(PublicFunnelComponent)
  childPublicFunnelComponent!: PublicFunnelComponent;

  constructor(
    public readonly statementService: StatementService,
    private changes: ChangeDetectorRef,
    private funnelGraphqlService: FunnelGraphqlService,
    private route: ActivatedRoute,
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  inApp = false;
  loading = true;

  ngOnInit(): void {
    this.loading = true;
    this.inApp = location.href.includes('funnels');

    if (!this.inApp) {
      this.loading = false;
      this.changes.detectChanges();
      return;
    }

    this.statementService.fetchRecommendations(true);

    setTimeout(() => {
      this.loading = false;
      this.changes.detectChanges();
    }, 500);
  }

  showDialogIfNoRecommendations() {
    const funnelId = +this.route?.snapshot.params['funnelId'];

    this.funnelGraphqlService.getPublicFunnelById(funnelId).subscribe((res) => {
      if (!res?.data?.getPublicFunnelById?.tactics?.length) {
        this.loading = true;
        const dialog = this.dialogs.open<number>(new PolymorpheusComponent(CampaignStatementComponent, this.injector), {
          dismissible: true,
          size: 'page',
        });

        dialog.subscribe(() => {
          setTimeout(() => {
            this.childPublicFunnelComponent.ngOnInit();
            this.loading = false;
            this.changes.detectChanges();
          });
        });
      }
    });
  }
}
