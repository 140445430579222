import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MemoryLogService } from '@shared/services/memory-log.service';

@Component({
  selector: 'df-memory-log',
  templateUrl: './memory-log.component.html',
  styleUrl: './memory-log.component.scss',
})
export class MemoryLogComponent implements OnInit, OnDestroy {
  isOpen = true;
  unreadMessagesCount = 0;
  completed = false;
  memoryLogs: string[] = [];

  constructor(
    private readonly memoryLogService: MemoryLogService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.watchLogChanges();
  }

  ngOnDestroy(): void {
    this.memoryLogService.stopListening();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.unreadMessagesCount = 0;
    }
  }

  watchLogChanges(): void {
    this.memoryLogService.memoryLogs$.subscribe((logs: string[]) => {
      this.memoryLogs = logs;

      if (logs.length === 0) {
        this.cd.detectChanges();
        return;
      }

      const latestLog = logs[logs.length - 1];
      this.completed = latestLog === 'Process finished' || latestLog === 'References';

      if (!this.isOpen) {
        this.unreadMessagesCount += 1;
      }

      this.cd.detectChanges();
    });
  }
}
