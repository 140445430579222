import { EventEmitter, Injectable } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { Funnel } from '@shared/models/funnel.model';
import { filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KompassifyService {
  readonly tourEvent$: EventEmitter<KompassifyTourEvent> = new EventEmitter<KompassifyTourEvent>();
  private sub: Subscription = new Subscription();

  constructor(private readonly userService: UserService) {
    // listen Kompassify events
    window.addEventListener('message', this.parseTourEvent.bind(this));
    this.listenLanguageChange();
  }

  private listenLanguageChange(): void {
    const interval = setInterval(() => {
      if (window['kompassifyMultiLanguage']) {
        clearInterval(interval);
      }
    }, 50);
  }

  private runTour(tourUuid, step = 0, skipIfWasFinished = true) {
    const interval = setInterval(() => {
      //checking if its alreacy loaded kompasiffy
      if (window['kompassifyLaunchTour']) {
        window['kompassifyLaunchTour'](tourUuid, step, skipIfWasFinished);
        clearInterval(interval);
      }
    }, 50);
  }

  private parseTourEvent(event): void {
    switch (event.data.TYPE) {
      case KompassifyTourEventType.STARTED:
      case KompassifyTourEventType.ENDED:
      case KompassifyTourEventType.SKIPPED:
      case KompassifyTourEventType.NEXT:
      case KompassifyTourEventType.SHOWING:
        this.tourEvent$.emit(new KompassifyTourEvent(event.data.TYPE, event.data.tourUuid));
        break;
      case KompassifyTourEventType.STEP:
        this.tourEvent$.emit(new KompassifyTourEvent(event.data.TYPE, event.data.tourUuid, event.data.stepIndex));
        break;
    }
  }

  runTutorial(tourUuid: KompassifyTourUuid, step = 0): void {
    this.runTour(tourUuid, step, false);
  }

  runLaunchTour(): void {
    if (!this.userService.User?.filledStatement) {
      this.runTour(KompassifyTourUuid.STATEMENT, 0, true);
    }
  }

  runFunnelLaunchTour(funnel: Funnel): void {
    if (this.userService.User?.firstFunnel?.id === funnel.id && this.userService.User?.funnelsCount === 1) {
      this.runTour(KompassifyTourUuid.FUNNEL, 0, true);
    }
  }

  listenBlockKompassify(afterStep: number): void {
    this.sub.add(
      this.tourEvent$
        .pipe(
          filter(
            (event: KompassifyTourEvent) =>
              event.type === KompassifyTourEventType.STEP && event.stepIndex === afterStep,
          ),
          take(1),
        )
        .subscribe(() => this.blockKompassify()),
    );
  }

  blockKompassify(block = true): void {
    block ? document.body.classList.add('kompassify-block') : document.body.classList.remove('kompassify-block');
  }

  unlockKompassify(): void {
    this.sub.unsubscribe();
    this.sub = new Subscription();
    this.blockKompassify(false);
  }
}

export enum KompassifyTourEventType {
  STARTED = 'KOMPASSIFY_TOUR_STARTED',
  ENDED = 'KOMPASSIFY_TOUR_ENDED',
  STEP = 'KOMPASSIFY_TOUR_STEP',
  SKIPPED = 'KOMPASSIFY_TOUR_SKIPPED',
  SHOWING = 'POPOVER_RECT_MOUNTED',
  NEXT = 'NEXT_STEP',
}

export class KompassifyTourEvent {
  type: KompassifyTourEventType;
  tourUuid: string;
  stepIndex?: number;

  constructor(type: KompassifyTourEventType, tourUuid: string, stepIndex?: number) {
    this.type = type;
    this.tourUuid = tourUuid;
    this.stepIndex = stepIndex;
  }
}

export enum KompassifyTourUuid {
  STATEMENT = '5024d9d8-aae5-40ee-ba84-36e9a82777b2',
  FUNNEL = 'cc859c0c-cb08-4051-890d-75816153f9c8',
  FUNNEL_STATEMENT = '5f96fc85-8e55-4e85-b970-869d89329986',
}
