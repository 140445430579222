<div
  *ngIf="statisticReport && statisticReport.link"
  class="iframe-wrapper"
>
  <iframe
    [src]="statisticReport.link | sanitizeResourceUrl"
    frameborder="0"
    style="overflow: hidden; height: 100%; width: 100%; min-height: 85vh"
    height="100%"
    width="100%"
  ></iframe>
</div>
