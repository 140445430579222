<div class="flex flex-col justify-start items-stretch">
  <h3 class="tui-text_h5 mb-2 mt-0">
    {{ 'Funnels.Content generator.Edit text' | translate }}
  </h3>
  <p class="tui-text-02 mb-5">
    {{ 'Funnels.Content generator.Ad description' | translate }}
  </p>

  <form
    [formGroup]="form"
    [validate-form]="form"
    class="edit-modal-form-container"
  >
    <tui-text-area
      formControlName="output"
      [expandable]="true"
      [tuiTextfieldLabelOutside]="true"
    ></tui-text-area>
    <df-error-input [control]="getControl('output')"></df-error-input>
  </form>

  <div class="flex justify-center items-center mt-8">
    <button
      size="xl"
      tuiButton
      appearance="outline"
      type="button"
      (click)="cancel()"
      [disabled]="loading"
      class="mr-2"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      size="xl"
      tuiButton
      appearance="primary"
      type="submit"
      (click)="confirm()"
      [disabled]="loading"
      [showLoader]="loading"
    >
      {{ 'confirm' | translate }}
    </button>
  </div>
</div>
