<div
  class="flex flex-row items-center justify-start w-full"
  (click)="userPreview()"
  [ngClass]="{ 'cursor-pointer': user }"
>
  <tui-avatar
    class="mr-2"
    [text]="userName()"
    [size]="size"
    [avatarUrl]="!user ? '/assets/img/ic-logo.png' : user.avatar ? Config.ASSETS + user.avatar!.file! : ''"
    [rounded]="true"
  ></tui-avatar>
  <div class="flex flex-col justify-between items-start w-3/4">
    <h5
      class="tui-text_body-m-10 tui-text-01 truncate w-full overflow-ellipsis break-words whitespace-nowrap"
      [title]="userName()"
    >
      {{ userName() }}
    </h5>
    <span class="tui-text_body-xs tui-text-02 opacity-50">
      {{ 'created by' | translate }}
    </span>
  </div>
</div>
