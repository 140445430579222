import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  ViewChild,
  OnInit,
} from '@angular/core';
import { FunnelGraphicsGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-graphics/funnel-graphics-generator.service';
import { Integration, IntegrationOutputData } from '@shared/models/integration.model';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { PermissionType } from '@modules/graphql/graphql-types';
import { UserService } from '@shared/services/user.service';
import { GlobalDataService } from '@shared/services/global-data.service';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, take } from 'rxjs/operators';
import { AbstractFunnelSubpageComponent } from '@shared/abstracts/funnel-subpage.component.abstract';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { ContentGeneratorEventService } from '@modules/funnels/modules/funnel-manage/shared/services/content-generator-event.service';
import { FunnelGraphicTabEnum } from '@modules/funnels/modules/funnel-manage/shared/enums/funnel-graphic-tab';
import { AiDesignGraphicTileSize } from '@modules/funnels/modules/funnel-manage/shared/enums/ai-design-graphic-tile-size.enum';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { AiDesignUploadedFile } from './components/funnel-graphics-ai-design-form/funnel-graphics-ai-design-form.model';
import { ImageDrawingComponent } from '@shared/components/image-drawing/image-drawing.component';
import FileSaver from 'file-saver';

const FUNNEL_CHANGE_DEBOUNCE_TIME_MS = 500;
const FUNNEL_CHANGE_SUBSCRIPTION_START_DELAY_TIME_MS = 2000;

@Component({
  selector: 'df-funnel-graphics',
  templateUrl: './funnel-graphics.component.html',
  styleUrls: ['./funnel-graphics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FunnelGraphicsGeneratorService],
})
export class FunnelGraphicsComponent extends AbstractFunnelSubpageComponent implements OnInit {
  loadingDownload$!: Observable<boolean>;
  fileName = '';
  access = true;
  TabEnum = FunnelGraphicTabEnum;
  AiDesignGraphicTileSize = AiDesignGraphicTileSize;
  selectedPreviewItem: IntegrationOutputData | null = null;
  aiDesignFile: AiDesignUploadedFile | null = null;
  deleteImageMaskButton = false;
  videoMode = false;

  @ViewChild(ImageDrawingComponent)
  imageDrawingComponent?: ImageDrawingComponent;

  get integration(): Integration | null {
    return this.graphicService.integration;
  }

  get form(): UntypedFormGroup {
    return this.graphicService.form;
  }

  get permissionName(): string {
    return this.globalData.getSinglePermission(PermissionType.AiCopywriting)?.name ?? '';
  }

  constructor(
    public readonly graphicService: FunnelGraphicsGeneratorService,
    private readonly changes: ChangeDetectorRef,
    private readonly userService: UserService,
    private readonly globalData: GlobalDataService,
    protected readonly route: ActivatedRoute,
    protected readonly funnelManageService: FunnelManageService,
    private readonly contentGeneratorEventService: ContentGeneratorEventService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {
    super(route, funnelManageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listenContentGeneratorEvents();
    this.checkUserPermission();
    this.graphicService.handleFunnelChanged();
    this.listenChangeFunnel();

    this.loadingDownload$ = this.graphicService.loadingZip$;
  }

  async handleAiDesignFile($event: AiDesignUploadedFile) {
    this.deleteMask();
    await this.graphicService.saveFormImageField($event.base64!);
    this.aiDesignFile = $event;
    this.videoMode = false;
  }

  private listenContentGeneratorEvents(): void {
    this.sub.add(this.contentGeneratorEventService.getSubscription().subscribe(() => this.changes.detectChanges()));
  }

  listenChangeFunnel() {
    setTimeout(() => {
      this.sub.add(
        this.funnelManageService.funnel$
          .pipe(debounceTime(FUNNEL_CHANGE_DEBOUNCE_TIME_MS))
          .subscribe(() => this.graphicService.handleFunnelChanged()),
      );
    }, FUNNEL_CHANGE_SUBSCRIPTION_START_DELAY_TIME_MS);
  }

  private checkUserPermission(): void {
    this.access = this.userService.User!.hasAccess(PermissionType.AiCopywriting);
    this.changes.detectChanges();
  }

  handleDataSubmitted(): void {
    this.graphicService.generateContent();
  }

  handleVideoDataSubmitted(): void {
    this.graphicService.generateVideo();
  }

  async onUpscaleImage() {
    await this.graphicService.upscaleAiDesignGraphics(this.aiDesignFile);
  }

  async onMakeVideo() {
    this.videoMode = true;
    this.changes.detectChanges();
  }

  downloadAll(graphics: IntegrationOutputData[]): void {
    this.graphicService.downloadOutputsZip(graphics, this.fileName);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: Event) {
    this.graphicService.generatedGraphics$.pipe(take(1)).subscribe((graphics) => {
      if (graphics.length > 0 || this.form.dirty) {
        $event.returnValue = true;
      }
    });
  }

  showDialog(item: IntegrationOutputData, content: PolymorpheusContent<TuiDialogContext>): void {
    this.selectedPreviewItem = item;
    this.dialogService.open(content, { size: 'fullscreen' }).subscribe();
  }

  saveMask(base64: string) {
    this.deleteImageMaskButton = true;
    this.graphicService.saveMask(base64);
  }

  deleteMask() {
    this.imageDrawingComponent?.clearCanvas();
    this.deleteImageMaskButton = false;
  }

  async downloadMask() {
    const mask = this.graphicService.aiDesignImageMask ?? this.aiDesignFile?.base64;

    FileSaver.saveAs(`data:image/png;base64,${mask!}`, 'AI Design.png');
  }
}
