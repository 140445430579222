import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Tactic } from '@shared/models/tactic.model';
import { TacticSettingsViewModeEnum } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-settings-view-mode.enum';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { catchError, debounceTime, filter, map, skip, switchMap, tap } from 'rxjs/operators';
import { ApolloError, FetchResult } from '@apollo/client/core';
import {
  AddBenchmarkInputGraphql,
  BenchmarkTypeEnum,
  EditBenchmarksInputGraphql,
  FunnelPermissionEnum,
  ReorderActivityInputGraphql,
  TacticOutputGraphql,
} from '@modules/graphql/graphql-types';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { GetTacticQuery } from '@modules/tactics/shared/graphql/queries/get-tactic.query.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ResourceTeamsService } from '@shared/modules/resource-teams/services/resource-teams.service';
import { IContainerTab } from '@shared/interfaces/container-tab.interface';
import { UserService } from '@shared/services/user.service';
import { ETacticSettingsFormEnum } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-settings-form.enum';
import { EActivityForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-form.enum';
import { CreateTacticMutation } from '@modules/tactics/shared/graphql/mutations/create-tactic.mutation.generated';
import { User } from '@shared/models/user.model';
import { EditTacticMutation } from '@modules/tactics/shared/graphql/mutations/edit-tactic.mutation.generated';
import { AdminTokenService } from '@shared/services/admin-token.service';
import { ETacticControlState } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-edit-status.enum';
import {
  combineRequests,
  getActiveArrayLength,
  getActivityFormObject,
  getAssetFormObject,
  getBenchmarkForm,
  getBenchmarkFormObject,
  getFormTabsValidity,
  getNewAssetForm,
  getTacticActivityForm,
  getTacticForm,
  getTacticFormObject,
  patchTacticActivitiesFormData,
  patchTacticFormData,
  removeControlFromArray,
  restoreControlInArray,
} from '@modules/tactics/modules/tactic-settings/shared/helpers/tactic-form.helper';
import { RemoveActivityMutation } from '@modules/tactics/shared/graphql/mutations/remove-activity.mutation.generated';
import { CreateActivityMutation } from '@modules/tactics/shared/graphql/mutations/create-activity.mutation.generated';
import { EditActivityMutation } from '@modules/tactics/shared/graphql/mutations/edit-activity.mutation.generated';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivityBenchmark } from '@shared/models/activity-benchmark.model';
import { EActivityBenchmarkForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-benchmark-form.enum';
import { Regex } from '@shared/configs/regex';
import { TacticActivitiesParametersService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-activities-parameters.service';
import { PrepareActivityAssetMutation } from '@modules/tactics/shared/graphql/mutations/prepare-activity-asset.mutation.generated';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { EActivityAssetForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-asset-form.enum';
import { TacticMetricFormService } from '../../pages/tactic-settings/components/tactic-settings-metrics/services/tactic-metric-form.service';
import * as uuidLib from 'uuid';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';

@Injectable({
  providedIn: 'root',
})
export class TacticSettingsService {
  static readonly ACTIVITY_OPERATION_PRIORITY: ETacticControlState[] = [
    ETacticControlState.REMOVE,
    ETacticControlState.CREATE,
    ETacticControlState.EDIT,
  ];
  private readonly _viewMode$: BehaviorSubject<TacticSettingsViewModeEnum> =
    new BehaviorSubject<TacticSettingsViewModeEnum>(TacticSettingsViewModeEnum.CREATE);
  readonly viewMode$: Observable<TacticSettingsViewModeEnum> = this._viewMode$.asObservable();

  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  readonly _changesInForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly changesInForm$: Observable<boolean> = this._changesInForm$.asObservable();

  readonly updateFormControls$: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly _tactic$: BehaviorSubject<Tactic> = new BehaviorSubject<Tactic>(new Tactic());
  readonly tactic$: Observable<Tactic> = this._tactic$.asObservable();

  private _tactic: Tactic | null = null;
  private _resourceTeamsService: ResourceTeamsService | null = null;

  // private _tacticMetricFormService: TacticMetricFormService | null = null;

  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  sub: Subscription = new Subscription();

  tabs: IContainerTab[] = [];
  private _activeTab: IContainerTab | null = null;
  get activeTab(): IContainerTab | null {
    return this._activeTab;
  }

  set activeTab(value: IContainerTab | null) {
    this._activeTab = value;
    this.activeTabChange$.emit(true);
  }
  readonly activeTabChange$: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _listenValidity = false;

  set resourceTeamsService(resourceTeamsService: ResourceTeamsService | null) {
    this._resourceTeamsService = resourceTeamsService;
  }

  get resourceTeamsService(): ResourceTeamsService | null {
    return this._resourceTeamsService;
  }

  set viewMode(mode: TacticSettingsViewModeEnum) {
    this._viewMode$.next(mode);
  }

  get tactic(): Tactic | null {
    return this._tactic;
  }

  set tactic(tactic: Tactic | null) {
    this._tactic = tactic;
  }

  get activitiesArray(): UntypedFormArray {
    return this.formGroup.get(ETacticSettingsFormEnum.activities) as UntypedFormArray;
  }

  constructor(
    private tacticGraphqlService: TacticGraphqlService,
    private s: SnackbarService,
    private t: TranslateService,
    private n: NavigateService,
    private userService: UserService,
    private adminTokenService: AdminTokenService,
    private tacticActivitiesParametersService: TacticActivitiesParametersService,
    private tacticMetricFormService: TacticMetricFormService,
    private eventService: EventsService,
  ) {
    this.initForm();
  }

  initService(): void {
    this.sub = new Subscription();
    this.listenChangesInForm();
    this.fetchParametersData();
  }

  fetchTactic(id: string) {
    this._loading$.next(true);
    const sub = this.tacticGraphqlService
      .getTactic(Number(id))
      .pipe(
        map((res: FetchResult<GetTacticQuery>) => res.data?.getTactic as Tactic),
        map((res: Tactic) => {
          if (res?.currentUserPermission === FunnelPermissionEnum.ViewOnly) {
            this.n.go('tactics/preview/p/:id', { id: res.id });
            this.s.error(this.t.instant('Tactics.Settings.You do not have edit access to this tactic.'));
            return null;
          }
          return res;
        }),
      )
      .subscribe({
        next: (res: Tactic | null) => {
          if (res) {
            this.tactic = new Tactic(res);
            this._tactic$.next(this.tactic);
            this.patchTacticData(this.tactic);
          }
        },
        error: (error: ApolloError) => {
          this.n.go('/tactics/list/all');
          if (error.graphQLErrors?.length) {
            switch ((error.graphQLErrors[0] as CustomGraphqlErrorInterface).message) {
              case 'Not allowed':
                this.s.error(this.t.instant('Tactics.Settings.You do not have access to this tactic.'));
                break;
              default:
                this.s.error(this.t.instant('Tactics.Settings.There is problem with getting tactic data. Try again.'));
                break;
            }
          }
        },
      });
    sub.add(() => {
      this._loading$.next(false);
    });

    return sub;
  }

  getPrompts() {
    return this.tacticGraphqlService.getPrompts().pipe(map((res) => res?.data?.findAllPrompts));
  }

  private fetchParametersData(): void {
    this.tacticActivitiesParametersService.fetchBenchmarkTypes();
    this.tacticActivitiesParametersService.fetchPlatforms();
    this.tacticActivitiesParametersService.fetchActivityTypes();
  }

  resetAssetsParametersData(): void {
    this.tacticActivitiesParametersService.resetPlacements();
    this.tacticActivitiesParametersService.resetAdTypes();
    this.tacticActivitiesParametersService.resetResolutions();
  }

  private initForm(): void {
    this.formGroup = getTacticForm();
  }

  private patchTacticData(tactic: Tactic): void {
    patchTacticFormData(this.formGroup, tactic);
    patchTacticActivitiesFormData(this.activitiesArray, tactic.activities);
  }

  listenChangesInForm(): void {
    this.sub.add(
      this.formGroup.valueChanges
        .pipe(
          skip(1),
          filter((val) => !!val),
          debounceTime(500),
        )
        .subscribe(() => {
          this._changesInForm$.next(true);
        }),
    );
  }

  updateFormControls(): void {
    this.updateTabsValidity();
    this.updateFormControls$.emit(true);
  }

  createTactic(): Observable<boolean> {
    this._loading$.next(true);
    return new Observable<boolean>((observer) => {
      this.tacticGraphqlService.createTactic(getTacticFormObject(this.formGroup)).subscribe(
        (res: FetchResult<CreateTacticMutation>) => {
          if (res.data) {
            const user = this.userService.User!;
            this.saveDraftTactic(res, user);
            this.sendDataLayerEvents(res, user);
            this.tactic = new Tactic(res.data?.createTactic as TacticOutputGraphql);
            this.getRequestsForTacticChanges().subscribe(
              () => observer.next(true),
              () => observer.next(false),
              () => {
                this._loading$.next(false);
                observer.complete();
              },
            );
          }
        },
        () => {
          observer.next(false);
          this._loading$.next(false);
          observer.complete();
        },
      );
    });
  }

  editTactic(): Observable<boolean> {
    this._loading$.next(true);
    return new Observable<boolean>((observer) => {
      const tactic = getTacticFormObject(this.formGroup);
      tactic.publiclyVisible = !!this.tactic?.publiclyVisible;

      this.tacticGraphqlService.editTactic(tactic, this.tactic!.id).subscribe(
        (res: FetchResult<EditTacticMutation>) => {
          if (res.data) {
            this.tactic = new Tactic(res.data?.editTactic as TacticOutputGraphql);
            this.removeDraftFromUser(res);
            this.getRequestsForTacticChanges().subscribe(
              () => observer.next(true),
              () => observer.next(false),
              () => {
                this._loading$.next(false);
                observer.complete();
              },
            );
          }
        },
        () => {
          observer.next(false);
          this._loading$.next(false);
          observer.complete();
        },
      );
    });
  }

  getBenchmarksArray(activityControl: AbstractControl): UntypedFormArray {
    return activityControl.get(EActivityForm.benchmarks) as UntypedFormArray;
  }

  getAssetsArray(activityControl: AbstractControl): UntypedFormArray {
    return activityControl.get(EActivityForm.assets) as UntypedFormArray;
  }

  private getRequestsForTacticChanges() {
    return forkJoin([
      ...this.prepareTacticImageRequest(),
      ...this.prepareAccessibilityRequest(),
      this.prepareActivitiesRequests(),
      this.resourceTeamsService!.sendRequestsForResourceTeams({
        tacticId: this.tactic!.id,
      }),
      this.tacticMetricFormService!.sendRequestsForTacticMetrics({
        tacticId: this.tactic!.id,
      }),
    ]);
  }

  private prepareTacticImageRequest(): Observable<FetchResult>[] {
    return this.formGroup.get(ETacticSettingsFormEnum.image)?.value
      ? [
          this.tacticGraphqlService.createTacticImage(
            this.tactic!.id,
            this.formGroup.get(ETacticSettingsFormEnum.image)?.value,
          ),
        ]
      : [];
  }

  private prepareAccessibilityRequest(): Observable<FetchResult>[] {
    return !this.tactic!.isPaid &&
      !this.tactic!.root &&
      this.tactic!.owner?.id === this.userService.User?.id &&
      this.formGroup.value[ETacticSettingsFormEnum.accessibility] !== this.tactic!.isPrivate
      ? [
          this.tacticGraphqlService.changeTacticPrivacy(
            this.formGroup.value[ETacticSettingsFormEnum.accessibility],
            this.tactic!.id,
          ),
        ]
      : [];
  }

  private prepareActivitiesRequests(): Observable<FetchResult | boolean> {
    const activities = this.activitiesArray.getRawValue();
    const removeActivitiesRequests: Observable<FetchResult>[] = [];
    const activitiesRequests: Observable<FetchResult | ETacticControlState>[] = activities.map((a) => {
      const operation: ETacticControlState | undefined = TacticSettingsService.ACTIVITY_OPERATION_PRIORITY.find((p) =>
        a[EActivityForm.state].includes(p),
      );
      switch (operation) {
        case ETacticControlState.REMOVE:
          if (a[EActivityForm.id] !== null) {
            removeActivitiesRequests.push(this.tacticGraphqlService.removeActivity(a[EActivityForm.id]));
          }
          return of(ETacticControlState.REMOVE);
        case ETacticControlState.CREATE:
          return this.tacticGraphqlService.createActivity(getActivityFormObject(a), this.tactic!.id);
        case ETacticControlState.EDIT:
          return this.tacticGraphqlService.editActivity(getActivityFormObject(a), a[EActivityForm.id]);
        default:
          return of(ETacticControlState.UNTOUCHED);
      }
    });

    return combineRequests(activitiesRequests).pipe(
      tap(
        (results: FetchResult<RemoveActivityMutation | CreateActivityMutation | EditActivityMutation>[] | boolean) => {
          if (results && Array.isArray(results)) {
            let i = 0;
            results.forEach((r: any) => {
              if (r === ETacticControlState.REMOVE) {
                this.activitiesArray.removeAt(i);
                i--;
              } else if (r.data && !!r.data['createActivity']) {
                this.activitiesArray
                  .at(i)
                  .get(EActivityForm.id)
                  ?.setValue(r.data['createActivity'].id, { emitEvent: false });
              }
              i++;
            });
          }
        },
      ),
      switchMap(() => combineRequests(this.prepareBenchmarksRequests())),
      switchMap(() => combineRequests(this.prepareAssetsRequests())),
      switchMap(() => combineRequests(removeActivitiesRequests)),
      switchMap(() => this.getReorderActivitiesRequest()),
    );
  }

  private prepareBenchmarksRequests(): Observable<FetchResult>[] {
    const benchmarkRequests: Observable<FetchResult>[] = [];
    this.activitiesArray.controls.forEach((activityControl) => {
      const benchmarks = this.getBenchmarksArray(activityControl).getRawValue();

      benchmarks.forEach((b) => {
        const operation: ETacticControlState | undefined = TacticSettingsService.ACTIVITY_OPERATION_PRIORITY.find((p) =>
          b[EActivityBenchmarkForm.state].includes(p),
        );
        switch (operation) {
          case ETacticControlState.REMOVE:
            if (b[EActivityBenchmarkForm.benchmarkId]) {
              benchmarkRequests.push(
                this.tacticGraphqlService.removeBenchmarkFromActivity(
                  b[EActivityBenchmarkForm.benchmarkId],
                  activityControl.get(EActivityForm.id)?.value,
                ),
              );
            }
            break;
          case ETacticControlState.CREATE:
            b[EActivityBenchmarkForm.activityId] = activityControl.get(EActivityForm.id)?.value;
            benchmarkRequests.push(
              this.tacticGraphqlService.addBenchmarkToActivity(
                getBenchmarkFormObject(b) as AddBenchmarkInputGraphql,
                b[EActivityBenchmarkForm.file] ? b[EActivityBenchmarkForm.file] : null,
                b[EActivityBenchmarkForm.attachmentId] && !b[EActivityBenchmarkForm.file]
                  ? b[EActivityBenchmarkForm.attachmentId].id
                  : null,
              ),
            );
            break;
          case ETacticControlState.EDIT:
            benchmarkRequests.push(
              this.tacticGraphqlService.editBenchmark(
                getBenchmarkFormObject(b) as EditBenchmarksInputGraphql,
                b[EActivityBenchmarkForm.file] ? b[EActivityBenchmarkForm.file] : null,
              ),
            );
            break;
        }
      });
    });
    return benchmarkRequests;
  }

  private prepareAssetsRequests(): Observable<FetchResult>[] {
    const assetsRequests: Observable<FetchResult>[] = [];
    this.activitiesArray.controls.forEach((activityControl) => {
      const assets = this.getAssetsArray(activityControl).getRawValue();

      assets.forEach((a) => {
        const operation: ETacticControlState | undefined = TacticSettingsService.ACTIVITY_OPERATION_PRIORITY.find((p) =>
          a[EActivityAssetForm.state].includes(p),
        );
        switch (operation) {
          case ETacticControlState.REMOVE:
            if (!!a[EActivityAssetForm.asset].id && a[EActivityAssetForm.asset].id !== -1) {
              assetsRequests.push(this.tacticGraphqlService.removeAssetFromActivity(a[EActivityAssetForm.asset].id));
            }
            break;
          case ETacticControlState.CREATE:
            a[EActivityAssetForm.activityId] = activityControl.get(EActivityForm.id)?.value;
            assetsRequests.push(this.tacticGraphqlService.addAssetToActivity(getAssetFormObject(a)));
            break;
        }
      });
    });
    return assetsRequests;
  }

  private getReorderActivitiesRequest(): Observable<FetchResult | boolean> {
    if (this.activitiesArray.length === 0) return of(true);
    const positions: ReorderActivityInputGraphql[] = this.activitiesArray
      .getRawValue()
      .map((a, position) => ({ activityId: a[EActivityForm.id], position }));
    return this.tacticGraphqlService.reorderActivities(this.tactic!.id, positions);
  }

  removeTactic(): Observable<any> {
    this._loading$.next(true);
    return this.tacticGraphqlService.removeTactic(this.tactic!.id).pipe(
      map((res) => {
        const user = this.userService.User!;
        if (this.tactic?.id === user.tacticDraft?.id) {
          user.tacticDraft = undefined;
        }
        if (this.tactic?.isPaid) {
          user.paidTacticCount--;
        }
        this.userService.User = user;
        this._changesInForm$.next(false);
        return res;
      }),
    );
  }

  private saveDraftTactic(res: FetchResult<CreateTacticMutation>, user: User): void {
    user.tacticDraft = {
      id: res.data?.createTactic.id,
      name: res.data?.createTactic.name,
    } as TacticOutputGraphql;
    this.userService.User = user;
  }

  private sendDataLayerEvents(res: FetchResult<CreateTacticMutation>, user: User): void {
    this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.TacticAdd, { userID: this.userService.User?.id });

    if (user.tacticDraft?.id === res.data?.createTactic.owner?.firstTactic?.id) {
      this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.TacticNew, {
        userID: this.userService.User?.id,
      });
    }
  }

  private removeDraftFromUser(res: FetchResult<EditTacticMutation>): void {
    const user = this.userService.User!;
    if (this.adminTokenService.token === null && res.data?.editTactic?.id === user.tacticDraft?.id) {
      user.tacticDraft = undefined;
      this.userService.User = user;
    }
  }

  createActivity(): void {
    const index: number = getActiveArrayLength(this.activitiesArray);
    this.activitiesArray.insert(index, getTacticActivityForm(ETacticControlState.CREATE));
    setTimeout(() => this.activitiesArray.at(index).get(EActivityForm.opened)?.setValue(true), 150);
  }

  removeActivity(activityControl: UntypedFormGroup): void {
    activityControl.get(EActivityForm.opened)?.setValue(false);
    removeControlFromArray(this.activitiesArray, activityControl);
  }

  restoreActivity(activityControl: UntypedFormGroup): void {
    restoreControlInArray(this.activitiesArray, activityControl);
  }

  cloneActivity(activityControl: UntypedFormGroup): void {
    const copy = getTacticActivityForm();
    copy.patchValue(activityControl.value);
    copy.get(EActivityForm.state)?.setValue([ETacticControlState.CREATE]);
    copy.get(EActivityForm.id)?.setValue(null);
    this.cloneBenchmarks(activityControl, copy);
    this.cloneAssets(activityControl, copy);
    const index: number = this.activitiesArray.controls.findIndex((a) => a === activityControl);
    this.activitiesArray.insert(index + 1, copy);
    this.activitiesArray.at(index).get(EActivityForm.opened)?.setValue(false);
    setTimeout(
      () =>
        this.activitiesArray
          .at(index + 1)
          .get(EActivityForm.opened)
          ?.setValue(true),
      150,
    );
  }

  private cloneBenchmarks(activityControl: UntypedFormGroup, copy: UntypedFormGroup): void {
    this.getBenchmarksArray(activityControl)
      .controls.filter((c) => !c.get(EActivityBenchmarkForm.state)?.value.includes(ETacticControlState.REMOVE))
      .forEach((benchmarkControl) => {
        const benchmark: ActivityBenchmark = benchmarkControl.get(EActivityBenchmarkForm.benchmark)?.value;
        const benchmarkForm: UntypedFormGroup = getBenchmarkForm(ETacticControlState.CREATE);
        const newUuid = uuidLib.v4();
        const benchmarkCopy: ActivityBenchmark = {
          id: -1,
          type: { ...benchmark.type },
          uuid: newUuid,
        };
        benchmarkForm.get(EActivityBenchmarkForm.benchmarkTypeId)?.setValue(benchmark.type, { emitEvent: true });
        if (benchmark.type.type === BenchmarkTypeEnum.Link) {
          benchmarkForm.addControl(
            EActivityBenchmarkForm.link,
            new UntypedFormControl(benchmark.link, [Validators.required, Validators.pattern(Regex.url)]),
          );
          benchmarkCopy.link = benchmark.link;
        } else if (benchmark.attachment) {
          benchmarkForm.addControl(EActivityBenchmarkForm.attachmentId, new UntypedFormControl(benchmark.attachment));
          benchmarkForm.addControl(EActivityBenchmarkForm.file, new UntypedFormControl());
          benchmarkCopy.attachment = { ...benchmark.attachment! };
        } else {
          benchmarkForm.addControl(
            EActivityBenchmarkForm.file,
            new UntypedFormControl(benchmarkControl.get(EActivityBenchmarkForm.file)?.value, [Validators.required]),
          );
          benchmarkCopy.file = benchmark.file;
        }
        benchmarkForm.get(EActivityBenchmarkForm.benchmark)?.setValue(benchmarkCopy, { emitEvent: false });
        this.getBenchmarksArray(copy).push(benchmarkForm, { emitEvent: false });
      });
  }

  private cloneAssets(activityControl: UntypedFormGroup, copy: UntypedFormGroup): void {
    this.getAssetsArray(activityControl)
      .controls.filter((c) => !c.get(EActivityAssetForm.state)?.value.includes(ETacticControlState.REMOVE))
      .forEach((assetControl) => {
        const asset: ActivityAsset = assetControl.get(EActivityAssetForm.asset)?.value;
        const assetForm: UntypedFormGroup = getNewAssetForm();
        const assetCopy: ActivityAsset = {
          id: -1,
          asset: { ...asset.asset },
          selectedResolutions: [...asset.selectedResolutions],
        } as ActivityAsset;
        const newUuid = uuidLib.v4();
        assetForm.patchValue(
          {
            [EActivityAssetForm.platformId]: asset.asset.platform,
            [EActivityAssetForm.addTypeId]: asset.asset.adType,
            [EActivityAssetForm.placementId]: asset.asset.placement,
            [EActivityAssetForm.asset]: assetCopy,
            [EActivityAssetForm.uuid]: newUuid,
          },
          { emitEvent: false },
        );
        if (asset.selectedResolutions.length) {
          assetForm.get(EActivityAssetForm.resolutionsIds)?.setValue(asset.selectedResolutions, { emitEvent: false });
        } else {
          assetForm.removeControl(EActivityAssetForm.resolutionsIds);
        }
        this.getAssetsArray(copy).push(assetForm, { emitEvent: false });
      });
  }

  editActivity(activityControl: UntypedFormGroup): void {
    let state: ETacticControlState[] = activityControl.get(EActivityForm.state)?.value;
    state = state.filter((s) => s !== ETacticControlState.EDIT);
    activityControl.get(EActivityForm.state)?.setValue([...state, ETacticControlState.EDIT], { emitEvent: false });
  }

  reorderActivity(fromIndex: number, toIndex: number): void {
    moveItemInArray(this.activitiesArray.controls, fromIndex, toIndex);
    this._changesInForm$.next(true);
  }

  createBenchmark(benchmarksArray: UntypedFormArray, benchmarkControl: UntypedFormGroup, uuid?: string): void {
    const benchmark: ActivityBenchmark = {
      id: -1,
      type: benchmarkControl.get(EActivityBenchmarkForm.benchmarkTypeId)?.value,
      uuid: uuid ? uuid : uuidLib.v4(),
    };
    if (benchmark.type.type === BenchmarkTypeEnum.Link) {
      benchmark.link = benchmarkControl.get(EActivityBenchmarkForm.link)?.value;
    } else {
      benchmark.file = benchmarkControl.get(EActivityBenchmarkForm.file)?.value as File;
    }
    benchmarkControl.get(EActivityBenchmarkForm.benchmark)?.setValue(benchmark, { emitEvent: false });
    benchmarksArray.insert(getActiveArrayLength(benchmarksArray), benchmarkControl);
  }

  editBenchmark(benchmarkControl: UntypedFormGroup): void {
    const benchmark: ActivityBenchmark = {
      id: benchmarkControl.get(EActivityBenchmarkForm.benchmarkId)?.value,
      type: benchmarkControl.get(EActivityBenchmarkForm.benchmarkTypeId)?.value,
    };
    if (benchmark.type.type === BenchmarkTypeEnum.Link) {
      benchmark.link = benchmarkControl.get(EActivityBenchmarkForm.link)?.value;
    } else {
      if (benchmarkControl.get(EActivityBenchmarkForm.file)?.value) {
        benchmark.file = benchmarkControl.get(EActivityBenchmarkForm.file)?.value as File;
      } else {
        benchmark.attachment = benchmarkControl.get(EActivityBenchmarkForm.attachmentId)?.value;
      }
    }
    benchmarkControl.get(EActivityBenchmarkForm.benchmark)?.setValue(benchmark, { emitEvent: false });

    let state: ETacticControlState[] = benchmarkControl.get(EActivityBenchmarkForm.state)?.value;
    state = state.filter((s) => s !== ETacticControlState.EDIT);
    benchmarkControl.get(EActivityForm.state)?.setValue([...state, ETacticControlState.EDIT], { emitEvent: false });
  }

  removeBenchmark(benchmarksArray: UntypedFormArray, benchmarkControl: UntypedFormGroup): void {
    removeControlFromArray(benchmarksArray, benchmarkControl);
  }

  restoreBenchmark(benchmarksArray: UntypedFormArray, benchmarkControl: UntypedFormGroup): void {
    restoreControlInArray(benchmarksArray, benchmarkControl);
  }

  createAsset(assetsArray: UntypedFormArray, assetControl: UntypedFormGroup): Observable<ActivityAsset | null> {
    const newUuid = uuidLib.v4();
    const assetValue = assetControl.value;
    assetValue.uuid = newUuid;
    assetControl.setValue(assetValue, { emitEvent: false });
    return this.tacticGraphqlService.prepareActivityAsset(getAssetFormObject(assetControl.getRawValue())).pipe(
      map(
        (res: FetchResult<PrepareActivityAssetMutation>) => {
          if (res.data) {
            const asset = res.data.prepareActivityAsset as ActivityAsset;
            assetControl.get(EActivityAssetForm.asset)?.setValue(asset, { emitEvent: false });
            assetsArray.insert(getActiveArrayLength(assetsArray), assetControl);
            return asset;
          } else {
            return null;
          }
        },
        catchError((err) => {
          this.s.defaultError();
          throw err;
        }),
      ),
    );
  }

  removeAsset(assetsArray: UntypedFormArray, assetControl: UntypedFormGroup): void {
    removeControlFromArray(assetsArray, assetControl);
  }

  restoreAsset(assetsArray: UntypedFormArray, assetControl: UntypedFormGroup): void {
    restoreControlInArray(assetsArray, assetControl);
  }

  updateTabsValidity(): void {
    const tabsValidity = getFormTabsValidity(this.formGroup);
    this.tabs.forEach((tab) => (tab.invalid = tabsValidity[tab.type] ?? false));
    if (!this._listenValidity) {
      this._listenValidity = true;
      this.sub.add(this.formGroup.statusChanges.subscribe(() => this.updateTabsValidity()));
    }
  }

  clearService() {
    this.initForm();
    this._tactic = null;
    this.tabs = [];
    this._activeTab = null;
    this.sub.unsubscribe();
    this._loading$.next(false);
    this._changesInForm$.next(false);
    this._listenValidity = false;
  }
}
