import { CanDeactivate, UrlTree } from '@angular/router';
import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ConfimLeavePageDialogComponent } from '@shared/components/confim-leave-page-dialog/confim-leave-page-dialog.component';
import { TranslateService } from '@ngx-translate/core';

export interface HasFormCanDeactivate {
  form: FormGroup;
  formSubmitted: boolean;
}

@Injectable()
export class FormNotDirtyCanDeactivateGuard implements CanDeactivate<HasFormCanDeactivate> {
  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private t: TranslateService,
  ) {}
  canDeactivate(
    component: HasFormCanDeactivate,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.form.touched && !component.formSubmitted) {
      return new Observable<boolean>((subscriber) => {
        this.dialogService
          .open<boolean>(new PolymorpheusComponent(ConfimLeavePageDialogComponent, this.injector), {
            closeable: true,
            dismissible: false,
            size: 's',
            label: this.t.instant('Navigation.Guards.Leaving'),
          })
          .subscribe(
            (res) => subscriber.next(res),
            () => subscriber.next(false),
            () => subscriber.complete(),
          );
      });
    }

    return true;
  }
}
