import { SwiperOptions } from 'swiper';

export const swiperFunnelGraphicsConfig: SwiperOptions = {
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    1024: {
      slidesPerView: 2,
    },
    1440: {
      slidesPerView: 3,
    },
  },
  pagination: { clickable: true },
  direction: 'horizontal',
  centeredSlidesBounds: true,
  allowTouchMove: window.innerWidth < 1024,
  edgeSwipeDetection: 'prevent',
  autoplay: true,
};
