<header class="mb-8 print:mt-4 print:break-before-page">
  <h2 class="font-bold text-[24px] text-[#2E4FFF] col leading-none">
    {{ 'MarketingCampaign.Marketing channels & Tactics' | translate }}
  </h2>
  <p class="text-[#191C30BF] font-medium text-[16px] mt-4">
    {{ 'MarketingCampaign.Reach your clients in their natural environment.' | translate }}
  </p>
</header>

<div
  *ngIf="campaign"
  class="controller grid grid-cols-1 lg:grid-cols-2 grid-rows-3 gap-6"
>
  <div
    class="bg-white py-6 px-4 rounded-[12px] print:py-2"
    *ngFor="let channel of campaign.data.marketingChannelsAndTactics"
  >
    <h2 class="text-[20px] font-semibold text-[#191C30E5] mb-4">
      {{ channel.channel_name }}
    </h2>
    <p class="text-[14px] text-[#191C30BF]">{{ channel.description }}</p>
  </div>
</div>

<df-skeleton
  [showSkeleton]="!campaign"
  [rows]="1"
  [cols]="3"
></df-skeleton>
