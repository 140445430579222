<section
  class="bg-[#f2f3f5] h-full p-5 overflow-y-scroll"
  *ngIf="assets.length && !loading"
>
  <div class="grid asset-grid gap-4">
    <df-asset-tile
      (deleteAssetEvent)="deleteAsset($event)"
      *ngFor="let asset of assets"
      [asset]="asset"
    ></df-asset-tile>
  </div>
</section>

<section
  *ngIf="assets.length === 0 && !loading"
  class="flex min-h-full flex-col justify-center items-center"
>
  <h1 class="font-bold text-[2rem] text-[#000]">
    {{ 'AssetsLibrary.Your asset library is empty' | translate }}
  </h1>

  <p>
    {{ 'To add assets for your library use the' | translate }}
    <button
      size="xs"
      tuiIconButton
      appearance="outline"
      icon="tuiIconBookmark"
    ></button>
    {{ 'button after generated content or graphic.' | translate }}
  </p>
</section>

<df-onboarding-video
  [src]="
    'https://help.digitalfirst.ai/en/articles/9082464-store-ai-generated-content-and-graphics-in-the-asset-library#h_39252c1329'
  "
  type="link"
></df-onboarding-video>
