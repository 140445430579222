import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TUI_FILES_RECORDING_FILES_EXTENTION } from '@modules/business-data/business-data.const';
import { ActionTypeEnum } from '@modules/business-data/business-data.model';
import { AudioRecorderService, OutputFormat } from '@shared/services/audio-recorder.service';
import { UserService } from '@shared/services/user.service';
import { TuiFileLike } from '@taiga-ui/kit';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'df-recordings',
  templateUrl: './recordings.component.html',
  styleUrl: './recordings.component.scss',
})
export class RecordingsComponent {
  type = ActionTypeEnum.RECORDINGS;
  maxFileSize = 100 * 1000 * 1000;
  @Input() form!: FormGroup;

  noAccessToMicrophoneMessage = false;
  isRecording = false;
  TUI_FILES_RECORDING_FILES_EXTENSION = TUI_FILES_RECORDING_FILES_EXTENTION;

  constructor(
    private readonly audioRecorderService: AudioRecorderService,
    private readonly cd: ChangeDetectorRef,
    public userService: UserService,
  ) {}

  rejectedFiles: readonly TuiFileLike[] = [];

  getControl() {
    return this.form.get(this.type) as FormControl;
  }

  onReject(files: TuiFileLike | readonly TuiFileLike[]): void {
    this.rejectedFiles = [...this.rejectedFiles, ...(files as TuiFileLike[])];
  }

  removeFile(file: File): void {
    const control = this.getControl();
    control.setValue(control.value?.filter((current: File) => current.name !== file.name) ?? []);
  }

  createSource(file: File) {
    return URL.createObjectURL(file);
  }

  clearRejected({ name }: TuiFileLike): void {
    this.rejectedFiles = this.rejectedFiles.filter((rejected) => rejected.name !== name);
  }

  startRecording() {
    this.audioRecorderService.startRecording().then((microphoneAvailable) => {
      this.isRecording = microphoneAvailable;
      this.noAccessToMicrophoneMessage = !microphoneAvailable;
      this.cd.detectChanges();
    });
  }

  stopRecording() {
    this.isRecording = false;
    this.audioRecorderService
      .stopRecording(OutputFormat.WEBM_BLOB)
      .then((output: any) => {
        const record = new File([output as Blob], `record-${uuidv4()}.webm`, {
          type: output.type,
        });
        (record as any).src = URL.createObjectURL(output as Blob);
        this.getControl().setValue([...this.getControl().value, record]);
      })
      .catch((errrorCase) => {
        console.log('error', errrorCase);
      });
  }
}
