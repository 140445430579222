import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { TuiDialogService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT, PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { TranslateService } from '@ngx-translate/core';
import { SuccessResetPasswordComponent } from '@modules/authorization/pages/success-reset-password/success-reset-password.component';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { SnackbarService } from '@core/services/snackbar.service';

@Component({
  selector: 'df-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
  token = '';
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.password)]),
  });
  loading = false;

  constructor(
    private changes: ChangeDetectorRef,
    private authGraphqlService: AuthGraphqlService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { token: string } }, boolean>,
    private t: TranslateService,
    private s: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.token = this.context.data.token;
  }

  submit() {
    if (!this.formGroup.valid) return;
    this.loading = true;
    this.authGraphqlService
      .confirmPasswordReset(this.token, this.formGroup.get('password')?.value)
      .subscribe({
        next: this.success.bind(this),
        error: this.error.bind(this),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  success() {
    this.context.completeWith(true);
    this.dialogService
      .open(new PolymorpheusComponent(SuccessResetPasswordComponent, this.injector), {
        dismissible: true,
        label: this.t.instant('Auth.Reset Password.Password Changed'),
      })
      .subscribe();
  }

  error() {
    this.s.defaultError();
  }
}
