import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { FunnelStepsService } from '@shared/services/funnel-steps.service';
import { Subscription } from 'rxjs';
import { Tactic } from '@shared/models/tactic.model';
import { Step } from '@shared/models/step.model';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { cloneDeep } from '@apollo/client/utilities';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { FetchResult } from '@apollo/client/core';
import { CreateFunnelTacticMutation } from '@modules/tactics/shared/graphql/mutations/create-funnel-tactic.mutation.generated';
import { TacticPreviewService } from '@modules/tactics/modules/tactic-preview/shared/services/tactic-preview.service';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { PermissionType } from '@modules/graphql/graphql-types';
import { MixpanelEventName, MixpanelService } from '@shared/services/mixpanel.service';

@Component({
  selector: 'df-add-to-funnel-button',
  templateUrl: './add-to-funnel-button.component.html',
  styleUrls: ['./add-to-funnel-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToFunnelButtonComponent implements OnInit, OnDestroy {
  AddToFunnelButtonType = AddToFunnelButtonType;

  sub: Subscription = new Subscription();
  dropdownOpened = false;
  extendedOpened = false;
  steps: Step[] = [];
  loading = false;

  @Input() type: AddToFunnelButtonType = AddToFunnelButtonType.DROPDOWN;
  @Input() tactic!: Tactic;

  constructor(
    public userService: UserService,
    private changes: ChangeDetectorRef,
    public funnelStepService: FunnelStepsService,
    private tacticGraphqlService: TacticGraphqlService,
    private s: SnackbarService,
    private t: TranslateService,
    private tacticPreviewService: TacticPreviewService,
    private pricingService: PricingService,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit(): void {
    this.listenGetSteps();
    this.listenUserChange();
    this.listenTacticUpdate();
  }

  mixpanelAddToFunnelButtonClick(buttonId: string, step: Step | null = null) {
    const params = {
      time: Date.now(),
      URL: location.href,
      button_name: buttonId,
    };

    if (step) {
      params['child_name'] = step.name;
    }

    this.mixpanelService.trackEvent(MixpanelEventName.ButtonClick, params);
  }

  listenTacticUpdate() {
    const sub = this.tacticPreviewService.tactic$.subscribe(() => {
      this.loadSteps();
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  listenUserChange() {
    const sub = this.userService.currentUser$?.subscribe(() => {
      this.loadSteps();
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  listenGetSteps() {
    const sub = this.funnelStepService.getSteps().subscribe(() => {
      this.loadSteps();
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  loadSteps() {
    this.steps = this.funnelStepService.steps.map((s: Step) => {
      const step = cloneDeep(s);
      step.contextFunnelTacticCount = this.countTacticInStep(step);
      return step;
    });
    this.changes.detectChanges();
  }

  countTacticInStep(step: Step): number {
    const user = this.userService.User;
    return (
      user!.contextFunnel?.tactics?.filter(
        (funnelTactic) => funnelTactic.tactic.id === this.tactic?.id && funnelTactic.step.id === step.id,
      ).length ?? 0
    );
  }

  addToFunnel(step: Step) {
    const user = this.userService.User;
    this.pricingService.checkPricing(PermissionType.FunnelManagement, user?.funnelsCount ?? 0).subscribe({
      next: () => {
        step.contextFunnelTacticCount ? (step.contextFunnelTacticCount += 1) : (step.contextFunnelTacticCount = 1);
        step.loading = true;
        this.loading = true;
        this.changes.detectChanges();
        this.tacticGraphqlService
          .createFunnelTactic({
            funnelId: user!.contextFunnel.id,
            tacticId: this.tactic.id,
            stepId: step.id,
            position: 0,
          })
          .subscribe({
            next: (res: FetchResult<CreateFunnelTacticMutation>) => {
              this.s.success(this.t.instant('Tactics.Preview.Tactic added to funnel successfully.'));
              user?.contextFunnel.tactics.push(new FunnelTactic(res.data?.createFunnelTactic));
              this.userService.User = user;
            },
          })
          .add(() => {
            this.loading = false;
            step.loading = false;
            this.changes.detectChanges();
          });
      },
    });
  }

  removeFromFunnel(step: Step) {
    this.pricingService
      .checkPricing(PermissionType.FunnelManagement, this.userService.User?.funnelsCount ?? 0)
      .subscribe({
        next: () => {
          step.contextFunnelTacticCount ? (step.contextFunnelTacticCount -= 1) : (step.contextFunnelTacticCount = 0);
          step.loading = true;
          this.loading = true;
          this.changes.detectChanges();
          this.tacticGraphqlService
            .removeFunnelTactic(this.getFunnelTacticId(step))
            .subscribe(() => {
              this.s.success(this.t.instant('Tactics.Preview.Tactic removed from funnel successfully.'));
              this.removeFunnelTacticFromContextFunnel(step);
            })
            .add(() => {
              this.loading = false;
              step.loading = false;
              this.changes.detectChanges();
            });
        },
      });
  }

  removeFunnelTacticFromContextFunnel(step: Step) {
    const user = this.userService.User;
    for (let i = 0; i < user!.contextFunnel?.tactics?.length; i++) {
      const funnelTactic = user!.contextFunnel.tactics[i];
      if (funnelTactic.tactic.id === this.tactic.id && funnelTactic.step.id === step.id) {
        user!.contextFunnel.tactics.splice(i, 1);
        break;
      }
    }
    this.userService.User = user;
  }

  getFunnelTacticId(step: Step): number {
    const user = this.userService.User;
    let fT: FunnelTactic;
    for (let i = 0; i < user!.contextFunnel?.tactics?.length; i++) {
      const funnelTactic = user!.contextFunnel.tactics[i];
      if (funnelTactic.tactic.id === this.tactic.id && funnelTactic.step.id === step.id) {
        fT = funnelTactic;
        break;
      }
    }
    return fT!.id;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

export enum AddToFunnelButtonType {
  DROPDOWN,
  FLOAT,
  ICON,
}
