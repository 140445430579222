import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'df-statement-section-header',
  templateUrl: './statement-section-header.component.html',
  styleUrls: ['./statement-section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementSectionHeaderComponent {
  @Input() header: string | null = null;
}
