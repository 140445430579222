import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Config } from '@shared/configs/config';
import { UntypedFormGroup } from '@angular/forms';
import { EActivityForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-form.enum';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';

@Component({
  selector: 'df-activity-form-header',
  templateUrl: './activity-form-header.component.html',
  styleUrls: ['./activity-form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityFormHeaderComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly Config = Config;
  readonly EActivityForm = EActivityForm;

  @Input() index = 1;
  @Input() open = false;
  @Output() toggleAccordion: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() activityControl: UntypedFormGroup | null = null;
  @Input() disabled = false;

  constructor(
    private readonly tacticSettingsService: TacticSettingsService,
    private readonly changes: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.sub.add(this.tacticSettingsService.changesInForm$.subscribe(() => this.changes.detectChanges()));
  }
}
