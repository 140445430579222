import { BasePanelService } from './../../../../../base-panel/pages/base-panel/services/base-panel.service';
import { ChangeDetectorRef, Component, HostBinding, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  TacticsListEventType,
  TacticsListService,
} from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { ActivatedRoute } from '@angular/router';
import { TacticListTypeEnum } from '@modules/graphql/graphql-types';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Step } from '@shared/models/step.model';
import { ListEvent, ListEventType, ListPageDirection } from '@shared/services/base-list.service';
import { Funnel } from '@shared/models/funnel.model';
import { Tactic } from '@shared/models/tactic.model';
import { UserService } from '@shared/services/user.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { onboardingFade } from '@shared/animations/onboarding.animations';

@Component({
  selector: 'df-tactics-tiles',
  templateUrl: './tactics-tiles.component.html',
  styleUrls: ['./tactics-tiles.component.scss'],
  animations: [onboardingFade()],
})
export class TacticsTilesComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  topBannerLoading = false;
  topBannerDisabled = true;
  @Input() modalList = false;
  @Input() step: Step | null = null;
  @Input() toCopy = false;
  @Input() funnel?: Funnel;
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  @HostBinding('class.onboarding-active-events')
  get isOnboardingRunning(): boolean {
    return (
      this.onboardingService.onboardingRunning &&
      this.onboardingService.isCurrentStepType(EOnboardingStep.TACTICS_ADD_TO_FUNNEL)
    );
  }

  constructor(
    public tacticsListService: TacticsListService,
    public tacticsFiltersService: TacticsFiltersService,
    public changes: ChangeDetectorRef,
    private route: ActivatedRoute,
    public userService: UserService,
    public basePanelService: BasePanelService,
    public onboardingService: OnboardingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.tacticsListService.records = [];
    const type: TacticListTypeEnum = this.modalList
      ? TacticListTypeEnum.All
      : this.onboardingService.onboardingRunning
        ? TacticListTypeEnum.Onboarding
        : this.route.snapshot?.data?.type;
    this.setType(type);
    this.checkBannerEnabled(type);
    this.tacticsListService.setFirstPage();
    this.getBannerTactics();
    this.listenLoadFilters();
    this.listenListEvents();
    this.listenOnboardingStepChange();
  }

  private setType(type: TacticListTypeEnum) {
    this.tacticsFiltersService.filterFormGroup.get('type')?.setValue(type, { emitEvent: false });
    type === TacticListTypeEnum.All
      ? this.tacticsFiltersService.addDefaultSort()
      : this.tacticsFiltersService.removeDefaultSort();
  }

  private checkBannerEnabled(type: TacticListTypeEnum) {
    this.topBannerDisabled = [
      TacticListTypeEnum.Favourite,
      TacticListTypeEnum.My,
      TacticListTypeEnum.Onboarding,
    ].includes(type);
    this.changes.detectChanges();
  }

  private getBannerTactics() {
    if (!this.topBannerDisabled) {
      this.topBannerLoading = true;
      this.changes.detectChanges();
      this.tacticsListService.getBannerTactics().add(() => {
        this.topBannerLoading = false;
        this.changes.detectChanges();
      });
    }
  }

  private listenLoadFilters() {
    this.tacticsListService.shouldUpdateUrl = !this.modalList;
    this.tacticsFiltersService.areFiltersLoaded ? this.tacticsListService.initService() : '';
    const sub = this.tacticsFiltersService.$filtersLoaded.subscribe(() => {
      this.tacticsListService.initService();
    });
    this.sub.add(sub);
  }

  private listenListEvents() {
    const sub = this.tacticsListService.emitter.subscribe((res: ListEvent) => {
      switch (res.type) {
        case ListEventType.START_GETTING_RECORDS:
        case ListEventType.END_GETTING_RECORDS:
          this.onboardingService.loading = this.tacticsListService.loading;
          this.changes.detectChanges();
          break;
      }
    });
    const sub2 = this.tacticsFiltersService.filterFormGroup.valueChanges.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
    this.sub.add(sub2);
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  reloadFunnel() {
    this.tacticsListService.tacticsListEmitter.emit({
      type: TacticsListEventType.RELOAD_FUNNEL,
    });
  }

  showLoadPrev() {
    return this.tacticsListService.loadedPages.sort()[0] > 1;
  }

  onEnd() {
    if (
      this.tacticsListService.loading ||
      this.tacticsListService.loadingNextPage ||
      this.tacticsListService.loadingPrevPage
    )
      return;
    this.tacticsListService.getTactics(ListPageDirection.NEXT).toPromise();
  }

  identify(index, item: Tactic) {
    return item.id;
  }

  onLoadPrevPage() {
    this.tacticsListService.getTactics(ListPageDirection.PREV).toPromise();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.tacticsListService.clearService();
    this.tacticsFiltersService.removeDefaultSort();
  }
}
