import { Component, OnInit } from '@angular/core';
import { HeaderTab } from '@shared/modules/ui/components/header-tabs/header-tabs.component';
import { funnelsListTabsConst } from '@modules/funnels/modules/funnels-list/shared/components/funnels-list-header/funnels-list-tabs.const';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-funnels-list-header',
  templateUrl: './funnels-list-header.component.html',
  styleUrls: ['./funnels-list-header.component.scss'],
})
export class FunnelsListHeaderComponent implements OnInit {
  tabs: HeaderTab[] = [];

  constructor(
    public n: NavigateService,
    public t: TranslateService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.tabs = funnelsListTabsConst(this.n, this.t, this.userService);
  }
}
