<div class="w-full h-full flex flex-col justify-center items-center no-genereted-content-contianer">
  <img
    _ngcontent-yls-c298=""
    src="assets/img/illustration_content_generator.png"
    alt="DigitalFirst Folder"
  />
  <p class="section-description">
    {{ description | translate }}
  </p>
  <p class="section-description">
    {{ descriptionLine2 | translate }}
  </p>
  <button
    tuiButton
    type="button"
    [size]="'l'"
    appearance="secondary"
    class="create-content-button"
    (click)="onCreateNewContent()"
  >
    {{ buttonLabel | translate }}
  </button>
</div>
