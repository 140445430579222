<div
  class="container flex items-center justify-between px-4 md:px-6 lg:px-8"
  [class.no-events]="noEvents"
>
  <div
    class="flex items-center"
    *ngIf="!isPublic"
  >
    <span
      class="rounded-full w-2 h-2 tui-text-04-bg mr-3"
      [ngClass]="{ 'tui-primary-bg': step === 1 }"
    ></span>
    <span
      class="rounded-full w-2 h-2 tui-text-04-bg mr-3"
      [ngClass]="{ 'tui-primary-bg': step === 2 }"
    ></span>
    <span
      class="rounded-full w-2 h-2 tui-text-04-bg mr-3"
      [ngClass]="{ 'tui-primary-bg': step === 3 }"
    ></span>
  </div>
  <div
    *ngIf="isPublic"
    class="hidden md:inline-flex"
  >
    <img
      [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
      alt="Digital First"
    />
  </div>

  <div class="flex items-center">
    <button
      *ngIf="prevLink && prevText"
      size="l"
      tuiButton
      type="button"
      class="mr-4"
      appearance="outline"
      [disabled]="loading"
      [routerLink]="prevLink"
      [skipLocationChange]="prevSkipLocationChange"
    >
      <svg-icon
        [src]="'/assets/img/systems-arrow-right-s-line.svg'"
        class="mr-2"
      ></svg-icon>
      {{ prevText }}
    </button>
    <button
      *ngIf="skipText"
      size="l"
      tuiButton
      type="button"
      class="mr-4"
      appearance="outline"
      (click)="onSkip.emit(true)"
    >
      <span class="md:hidden">{{ 'skip' | translate }}</span>
      <span class="hidden md:inline">{{ skipText }}</span>
    </button>
    <button
      size="l"
      tuiButton
      type="submit"
      appearance="primary"
      [disabled]="loading"
      [showLoader]="loading"
      (click)="onNext.emit(true)"
    >
      {{ nextText }}
      <svg-icon
        [src]="'/assets/img/systems-arrow-left-s-line.svg'"
        class="ml-2"
      ></svg-icon>
    </button>
  </div>
</div>
