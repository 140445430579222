import { gql } from 'apollo-angular';

export const TeamFragment = gql`
  fragment TeamFragment on TeamOutputGraphql {
    id
    name
    icon {
      id
      file
      name
    }
    createdAt
    members {
      id
      role
      email
      user {
        id
        firstName
        lastName
        email
        avatar {
          id
          file
        }
      }
    }
  }
`;
