import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TacticMetricInputGraphql, TacticMetricOutputGraphql } from '@modules/graphql/graphql-types';
import { ETacticMetricForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-metric-form.enum';
import { combineLatest, Observable, BehaviorSubject } from 'rxjs';
import { cycleMeasurementValidator } from '../validators/cycle-measurement.validator';
import { TacticMetricData } from '../interfaces/tactic-metric-data.interface';
import { ETacticMetricControlState } from '../enums/tactic-metric-control-state.enum';

@Injectable({
  providedIn: 'root',
})
export class TacticMetricFormService {
  readonly ETacticMetricForm = ETacticMetricForm;
  readonly ETacticMetricControlState = ETacticMetricControlState;
  tacticMetricIndex = new BehaviorSubject<number | null>(null);
  tacticMetricId = new BehaviorSubject<number | null>(null);
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private tacticGraphqlService: TacticGraphqlService,
  ) {
    this.form = this.fb.group(
      {
        [ETacticMetricForm.metric]: [null, [Validators.required]],
        [ETacticMetricForm.period]: [null, [Validators.required]],
        [ETacticMetricForm.cycle]: [null, [Validators.required]],
        [ETacticMetricForm.cycleMeasurement]: [null],
      },
      { validators: cycleMeasurementValidator },
    );
  }

  public tacticMetricsInputList: TacticMetricData[] = [];

  removeTacticMetric(index: number) {
    if (this.tacticMetricsInputList[index].output?.id) {
      this.tacticMetricsInputList[index].status = ETacticMetricControlState.REMOVE;
    } else {
      // not send by API yet
      delete this.tacticMetricsInputList[index];
    }
  }

  sendRequestsForTacticMetrics(data: { tacticId?: number }) {
    const obsArr = [
      ...this.prepareAddTacticMetricResources(data),
      ...this.prepareEditTacticMetricResources(data),
      ...this.prepareRemoveTacticMetricResources(),
    ];

    return obsArr.length
      ? combineLatest(obsArr)
      : new Observable((observer) => {
          observer.next(true);
          observer.complete();
        });
  }

  prepareAddTacticMetricResources(data: { tacticId?: number }) {
    return this.tacticMetricsInputList
      .filter((res) => res.status === ETacticMetricControlState.ADD)
      .map((tacticMetricData: TacticMetricData) => {
        tacticMetricData.input!.tacticId = +data.tacticId!;
        return tacticMetricData;
      })
      .map((tacticMetricData: TacticMetricData) => {
        return this.tacticGraphqlService.createTacticMetric(tacticMetricData.input!);
      });
  }

  prepareEditTacticMetricResources(data: { tacticId?: number }) {
    return this.tacticMetricsInputList
      .filter((res) => res.status === ETacticMetricControlState.EDIT)
      .map((tacticMetricData: TacticMetricData) => {
        tacticMetricData.input!.tacticId = +data.tacticId!;
        return tacticMetricData;
      })
      .map((tacticMetricData: TacticMetricData) => {
        return this.tacticGraphqlService.editTacticMetric(tacticMetricData.input!, tacticMetricData.id!);
      });
  }

  prepareRemoveTacticMetricResources() {
    return this.tacticMetricsInputList
      .filter((res) => res.status === ETacticMetricControlState.REMOVE)
      .map((tacticMetricData: TacticMetricData) => {
        return this.tacticGraphqlService.removeTacticMetric(tacticMetricData.output?.id!);
      });
  }

  pushTacticMetric(tacticId?: number) {
    const index = this.tacticMetricIndex.getValue();
    const id = this.tacticMetricId.getValue();

    const { input, output } = this.prepareInputOutput();
    const formValue = this.form.value;

    if (this.form.valid && tacticId && index) {
      // edit
      if (id) {
        // already Saved
        this.tacticMetricsInputList[index] = {
          index,
          id,
          input,
          output,
          formValue,
          status: ETacticMetricControlState.EDIT,
        };
      } else {
        // not Saved - newly Added and then Edited
        this.tacticMetricsInputList[index] = {
          index,
          input,
          output,
          formValue,
          status: ETacticMetricControlState.ADD,
        };
      }
    } else if (this.form.valid) {
      // add
      this.tacticMetricsInputList.push({
        input,
        output,
        formValue,
        status: ETacticMetricControlState.ADD,
      });
    }
  }

  prepareInputOutput() {
    const measurementPeriods = this.form.get(ETacticMetricForm.period)?.value || [];
    const input: TacticMetricInputGraphql = {
      metricId: this.form.get(ETacticMetricForm.metric)?.value.id,
      tacticId: 0, // fill in prepar...TacticMetricResources()
      measurementPeriod: measurementPeriods[0]?.name,
      isCyclical: this.form.get(ETacticMetricForm.cycle)?.value,
      cycleMeasurementPeriod: this.form.get(ETacticMetricForm.cycleMeasurement)?.value?.name || null,
    };

    const output: TacticMetricOutputGraphql = {
      id: 0, // not needed - only for display
      measurementPeriod: measurementPeriods[0]?.name,
      isCyclical: this.form.get(ETacticMetricForm.cycle)?.value,
      cycleMeasurementPeriod: this.form.get(ETacticMetricForm.cycleMeasurement)?.value?.name || null,
      metric: {
        id: this.form.get(ETacticMetricForm.metric)?.value.id,
        name: this.form.get(ETacticMetricForm.metric)?.value.name,
      },
    };

    return { input, output };
  }
}
