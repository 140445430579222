<df-full-list-loader *ngIf="funnelStatisticsService.loading$ | async"></df-full-list-loader>
<ng-container *ngIf="(funnelStatisticsService.loading$ | async) === false">
  <div
    *ngFor="let statisticReport of statisticReports"
    class="report-tile-wrapper"
  >
    <df-funnel-statistics-report-tile
      class="report-tile"
      [report]="statisticReport"
      (click)="onOpenIframe(statisticReport)"
    ></df-funnel-statistics-report-tile>
  </div>
  <div class="button-wrapper">
    <button
      size="m"
      tuiButton
      type="submit"
      appearance="outline"
      class="danger"
      (click)="onNavigateToForm()"
    >
      {{ 'Funnels.Statistics.Create New Report' | translate }}
    </button>
  </div>
</ng-container>
