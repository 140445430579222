<div *ngIf="type === 'legacy'; else newComponent">
  <tui-input-files
    [multiple]="multiple"
    [accept]="accept"
    [maxFileSize]="maxFileSize"
    [link]="link"
    (reject)="rejectedImages($event)"
    *ngIf="!formControl?.value"
    [formControlName]="formName"
  ></tui-input-files>

  <tui-files class="tui-space_top-1">
    <tui-file
      *ngIf="loadedFiles$ | async as file"
      [file]="file"
      [showDelete]="!!formControl?.enabled"
      (removed)="removeFile()"
    ></tui-file>

    <tui-file
      *ngIf="rejectedFiles$ | async as file"
      state="error"
      [file]="file"
      [showDelete]="!!formControl?.enabled"
      (removed)="clearRejected()"
    ></tui-file>

    <tui-file
      *ngIf="loadingFiles$ | async as file"
      state="loading"
      [file]="file"
      [showDelete]="!!formControl?.enabled"
    ></tui-file>
  </tui-files>
</div>

<ng-template #newComponent>
  <div>
    <tui-input-files
      [class.hidden]="file"
      (reject)="rejectedImages($event)"
      [accept]="accept"
      [maxFileSize]="maxFileSize"
      class="file-control"
      [formControlName]="formName"
    >
      <ng-template let-dragged>
        <div
          *ngIf="dragged; else base"
          class="content"
        >
          <div>Drop it like it's hot!</div>
        </div>
        <ng-template #base>
          <div class="content py-6">
            <p class="text-[30px] mb-8">🏞️</p>
            <h3 class="font-semibold text-[1rem] text-[#191C30E5]">No image</h3>
            <p class="font-medium text-[14px] text-[#1B1F3BA6]">
              You can upload a custom image to include with your published content.
            </p>
            <p class="font-medium text-[12px] text-[#1B1F3B66] mb-6">PNG, JPG. Maximum upload file size: 2mb</p>
            <button
              class="w-[100px] upload-button"
              appearance="whiteblock"
              [icon]="'tuiIconDownloadLarge'"
              tuiIconButton
            >
              Upload
            </button>
          </div>
        </ng-template>
      </ng-template>
    </tui-input-files>

    <div
      class="preview"
      [class.hidden]="!file"
    >
      <img
        #preview
        [src]="'data:image/png;base64,' + file"
        alt=""
      />

      <div class="flex justify-around items-center my-5">
        <button
          class="font-semibold text-[14px] text-[#1B1F3BA6] hover:text-[#2e4fff]"
          tuiIconButton
          [icon]="'tuiIconDownloadLarge'"
          appearance="Textfield"
          (click)="replaceFile()"
        >
          Replace
        </button>
        <button
          class="font-semibold text-[14px] text-[#F5222D] hover:text-[#2e4fff]"
          tuiIconButton
          [icon]="'tuiIconTrashLarge'"
          appearance="Textfield"
          (click)="removeFile()"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>
