<div class="w-full">
  <tui-island
    size="s"
    class="mb-4"
  >
    <div class="flex flex-col">
      <label class="tui-text-03">{{
        'Funnels.General access (everyone with the link can access the funnel)' | translate
      }}</label>
    </div>
    <div
      class="w-full flex flex-nowrap justify-start mt-3"
      [formGroup]="shareForm"
    >
      <tui-checkbox-labeled
        class="w-full block mt-1 mb-1"
        (change)="onChangeFunnelPublic()"
        size="l"
        formControlName="isPublic"
      >
        {{ 'Funnels.Make funnel public' | translate }}
      </tui-checkbox-labeled>
    </div>
  </tui-island>

  <div
    *ngIf="showPublicLink"
    class="mt-4 mb-4"
  >
    <p class="tui-text-03">
      {{ 'Funnels.Copy link to clipboard' | translate }}
    </p>
    <tui-island
      size="s"
      class="island-copy"
      (click)="onPublicLinkCopy(APP_URL + '/public/funnel/' + getFunnel().id)"
    >
      <tui-line-clamp
        [content]="content"
        [lineHeight]="10"
        [linesLimit]="2"
      ></tui-line-clamp>
    </tui-island>
    <ng-template #content>
      <div class="hint">{{ APP_URL }}/public/funnel/{{ getFunnel().id }}</div>
    </ng-template>
  </div>

  <form (submit)="addEmail()">
    <label class="tui-text-03">{{ 'Funnels.Manage.Send invite to funnel' | translate }}</label>
    <div class="tui-form__row w-full flex flex-nowrap flex-col md:flex-row">
      <div class="w-full">
        <tui-input [formControl]="singleEmail">
          {{ 'Funnels.Manage.Add e-mail, separated by comma' | translate }}
        </tui-input>
        <df-error-input [control]="singleEmail"></df-error-input>
      </div>
      <button
        size="l"
        tuiButton
        type="button"
        [showLoader]="loading"
        [disabled]="loading"
        appearance="primary"
        class="ml-0 md:ml-2 whitespace-nowrap"
        (click)="addEmail()"
      >
        <svg-icon
          [src]="'/assets/img/ic-email.svg'"
          class="mr-2"
        ></svg-icon>
        {{ 'Funnels.Manage.Send invite' | translate }}
      </button>
    </div>

    <div class="mb-4">
      <div
        *ngFor="let share of funnelSettingsService.funnel?.shares"
        class="w-full mt-2 h-16 p-2 border border-gray-200 border-1 border-solid rounded-xl flex flex-nowrap justify-between content-center"
      >
        <div class="flex items-center flex-grow-0 overflow-x-auto mr-2">
          <div class="rounded-full tui-accent-08 w-11 h-11 mr-2 flex justify-center flex-shrink-0">
            <svg-icon
              [src]="'/assets/img/at_sign.svg'"
              class="self-center"
            ></svg-icon>
          </div>
          <span class="font-semibold">{{ share.email }}</span>
        </div>
        <div class="self-center flex">
          <tui-hosted-dropdown
            #dropdown
            tuiDropdownAlign="right"
            [content]="content"
          >
            <button
              tuiButton
              appearance="secondary"
              size="m"
              type="button"
              [iconRight]="arrow"
              [disabled]="loading"
            >
              {{ 'Funnels.Manage.Invited' | translate }}
            </button>
          </tui-hosted-dropdown>
        </div>
        <ng-template #content>
          <tui-data-list>
            <button
              *ngIf="invitedAgainSharesId.indexOf(share.id) === -1"
              tuiOption
              (click)="onNextInvite(share)"
            >
              <span class="max-w-xs whitespace-normal block">{{ 'Funnels.Manage.Invite again' | translate }}</span>
            </button>
            <button
              tuiOption
              (click)="onRemoveInvite(share)"
            >
              <span class="max-w-xs whitespace-normal block tui-accent-01">{{
                'Funnels.Manage.Remove' | translate
              }}</span>
            </button>
          </tui-data-list>
        </ng-template>
      </div>
    </div>
  </form>
</div>
