import { IFilter } from '@shared/interfaces/filter.interface';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { IntegrationCategoryOutputGraphql } from '@modules/graphql/graphql-types';
import { FunnelContentGeneratorLibraryService } from '../../services/funnel-content-generator-library.service';
import { ContentLibraryFormField as FormField } from '../../models/funnel-content-generator-library.enum';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Integration } from '@shared/models/integration.model';

@Component({
  selector: 'df-funnel-content-generator-library-filters',
  templateUrl: './funnel-content-generator-library-filters.component.html',
  styleUrls: ['./funnel-content-generator-library-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelContentGeneratorLibraryFiltersComponent implements OnInit, OnDestroy {
  FormField = FormField;
  filtersDesktopVisible = true;
  categories: IFilter<Integration>[] = [];

  private readonly subscription: Subscription = new Subscription();

  constructor(
    public contentLibraryService: FunnelContentGeneratorLibraryService,
    private changes: ChangeDetectorRef,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.getFilters();
    this.listenFormControlsChange();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getFilters() {
    this.contentLibraryService.fetchCategories().subscribe({
      next: (response: IntegrationCategoryOutputGraphql[]) => {
        response.forEach((item: IntegrationCategoryOutputGraphql) => {
          this.categories.push({
            name: item.name,
            id: item.id,
          } as IFilter<Integration>);
        });
      },
    });
    this.changes.detectChanges();
  }

  private listenFormControlsChange(): void {
    const subscription: Subscription = this.contentLibraryService.form.valueChanges.subscribe({
      next: () => this.contentLibraryService.startNewSearch(),
    });
    this.subscription.add(subscription);
  }

  selectedInArray(formControlName: FormField): string {
    const labelTranslation: string = this.translateService.instant('Funnels.Content generator.Selected');
    const numberOfSelectedItems: number = this.contentLibraryService.form.get(formControlName)?.value?.length ?? 0;
    return `${labelTranslation} (${numberOfSelectedItems})`;
  }

  clearFilters() {
    this.contentLibraryService.clearFilters();
    this.changes.detectChanges();
  }

  toggleFilters() {
    this.filtersDesktopVisible = !this.filtersDesktopVisible;
    this.changes.detectChanges();
  }
}
