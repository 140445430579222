import {
  CreateResourcePermissionInputGraphql,
  CreateResourceTeamInputGraphql,
  EditResourcePermissionInputGraphql,
  ResourcesTeamsListInputGraphql,
} from '@modules/graphql/graphql-types';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';

import { Apollo } from 'apollo-angular';
import { CreateResourceTeamMutation } from '@shared/modules/resource-teams/graphql/mutations/create-resource-team.mutation.generated';
import { CreateResourceTeamDocument } from '@shared/modules/resource-teams/graphql/mutations/create-resource-team.mutation';
import { RemoveResourceTeamMutation } from '@shared/modules/resource-teams/graphql/mutations/remove-resource-team.mutation.generated';
import { RemoveResourceTeamDocument } from '@shared/modules/resource-teams/graphql/mutations/remove-resource-team.mutation';
import { AddResourcePermissionMutation } from '@shared/modules/resource-teams/graphql/mutations/add-resource-permission.mutation.generated';
import { AddResourcePermissionDocument } from '@shared/modules/resource-teams/graphql/mutations/add-resource-permission.mutation';
import { EditResourcePermissionMutation } from '@shared/modules/resource-teams/graphql/mutations/edit-resource-permission.mutation.generated';
import { EditResourcePermissionDocument } from '@shared/modules/resource-teams/graphql/mutations/edit-resource-permission.mutation';
import { RemoveResourcePermissionMutation } from '@shared/modules/resource-teams/graphql/mutations/remove-resource-permission.mutation.generated';
import { RemoveResourcePermissionDocument } from '@shared/modules/resource-teams/graphql/mutations/remove-resource-permission.mutation';
import { GetResourceTeamsByResourceQuery } from '@shared/modules/resource-teams/graphql/queries/get-resource-teams-by-resource.query.generated';
import { GetResourceTeamsByResourceDocument } from '@shared/modules/resource-teams/graphql/queries/get-resource-teams-by-resource.query';
import { Injectable } from '@angular/core';

@Injectable()
export class ResourceTeamsGraphQLService {
  constructor(private apollo: Apollo) {}

  createResourceTeam(input: CreateResourceTeamInputGraphql): Observable<FetchResult<CreateResourceTeamMutation>> {
    return this.apollo.mutate<CreateResourceTeamMutation>({
      mutation: CreateResourceTeamDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeResourceTeam(id: number): Observable<FetchResult<RemoveResourceTeamMutation>> {
    return this.apollo.mutate<RemoveResourceTeamMutation>({
      mutation: RemoveResourceTeamDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  addResourcePermission(
    input: CreateResourcePermissionInputGraphql,
  ): Observable<FetchResult<AddResourcePermissionMutation>> {
    return this.apollo.mutate<AddResourcePermissionMutation>({
      mutation: AddResourcePermissionDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  editResourcePermission(
    input: EditResourcePermissionInputGraphql,
  ): Observable<FetchResult<EditResourcePermissionMutation>> {
    return this.apollo.mutate<EditResourcePermissionMutation>({
      mutation: EditResourcePermissionDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeResourcePermission(id: number): Observable<FetchResult<RemoveResourcePermissionMutation>> {
    return this.apollo.mutate<RemoveResourcePermissionMutation>({
      mutation: RemoveResourcePermissionDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getResourceTeamsByResource(
    input: ResourcesTeamsListInputGraphql,
  ): Observable<FetchResult<GetResourceTeamsByResourceQuery>> {
    return this.apollo.query<GetResourceTeamsByResourceQuery>({
      query: GetResourceTeamsByResourceDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
