import { Injectable } from '@angular/core';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { Activity } from '@shared/models/activity.model';
import { ContentGeneratorEventEnum } from '@modules/funnels/shared/enums/content-generator-selection-type.enum';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { UntypedFormArray } from '@angular/forms';
import {
  ContentGenerationOutput,
  Integration,
  IntegrationInputConfiguration,
  IntegrationInputData,
  IntegrationOutputData,
} from '@shared/models/integration.model';
import { ContentGenerationOutputGraphql, InputTypeEnum } from '@modules/graphql/graphql-types';
import { FetchResult } from '@apollo/client/core';
import { GenerateContentMutation } from '@modules/funnels/shared/graphql/mutations/generate-content.mutation.generated';
import { ActivityAsset } from '@shared/models/activity-asset.model';
import { MarkOutputDataAsSelectedMutation } from '@modules/funnels/shared/graphql/mutations/mark-output-data-as-selected.mutation.generated';
import { forkJoin, Observable } from 'rxjs';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { EditOutputDataMutation } from '@modules/funnels/shared/graphql/mutations/edit-output-data.mutation.generated';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GetIntegrationOutputsDataQuery } from '@modules/funnels/shared/graphql/queries/get-integration-outputs-data.query.generated';
import { GetIntegrationInputsDataQuery } from '@modules/funnels/shared/graphql/queries/get-integration-inputs-data.query.generated';
import { GetLastGeneratedIntegrationInputsDataQuery } from '@modules/funnels/shared/graphql/queries/get-last-generated-integration-inputs-data.query.generated';
import { AbstractContentGeneratorService } from '@shared/abstracts/content-generator.service.abstract';
import { getDefaultControlHelper } from '@shared/helpers/content-generator.helper';
import { UserService } from '@shared/services/user.service';
import { ContentGeneratorEventService } from '@modules/funnels/modules/funnel-manage/shared/services/content-generator-event.service';
import { CreditsService } from '@shared/services/credits.service';
import { GenerateContentCostBadgeService } from '@modules/funnels/modules/funnel-manage/shared/components/generate-content-cost-badge/generate-content-cost-badge.service';

@Injectable()
export class FunnelContentGeneratorService extends AbstractContentGeneratorService {
  set selectedTactic(value: FunnelTactic | null) {
    this._selectedTactic = value;
    this.parseIntegrationOutputData();
  }

  private _selectedTactic: FunnelTactic | null = null;
  private _selectedActivity: Activity | null = null;

  get selectedActivity(): Activity | null {
    return this._selectedActivity;
  }

  get selectedTactic(): FunnelTactic | null {
    return this._selectedTactic;
  }

  constructor(
    private readonly funnelGraphqlService: FunnelGraphqlService,
    protected readonly s: SnackbarService,
    protected readonly t: TranslateService,
    private readonly userService: UserService,
    protected readonly contentGeneratorEventService: ContentGeneratorEventService,
    private readonly creditsService: CreditsService,
    protected readonly costBadgeService: GenerateContentCostBadgeService,
  ) {
    super(s, t, contentGeneratorEventService, costBadgeService);
  }

  selectTactic(funnelTactic: FunnelTactic | null): void {
    const events: ContentGeneratorEventEnum[] = [];
    this._selectedTactic = funnelTactic;
    events.push(ContentGeneratorEventEnum.TACTIC_CHANGE);
    this._selectedActivity = null;
    events.push(ContentGeneratorEventEnum.ACTIVITY_CHANGE);
    this.contentGeneratorEventService.emit(events);
  }

  selectActivity(activity: Activity): void {
    this._selectedActivity = activity;
    this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.ACTIVITY_CHANGE]);
  }

  generateContent(activityAsset?: ActivityAsset, integration?: Integration): void {
    if (!integration) return;
    if (!this.creditsService.canGenerateContent(integration.creditsPrice)) {
      this.creditsService.showNotEnoughCreditsPopup();
      return;
    }
    this._loading$.next(true);
    this.funnelGraphqlService
      .generateContent({
        activityAssetId: activityAsset!.id,
        integrationId: integration.id!,
        funnelTacticId: this.selectedTactic!.id,
        inputsData: this.getInputsData(),
      })
      .subscribe((res) => this.generateContentSuccess(res, activityAsset), this.generateContentError.bind(this));
  }

  protected generateContentSuccess(res?: FetchResult<GenerateContentMutation>, activityAsset?: ActivityAsset) {
    const output: ContentGenerationOutput = new ContentGenerationOutput(
      res!.data?.generateContent as ContentGenerationOutputGraphql,
    );
    activityAsset!.integrationOutputs = output.outputs;
    activityAsset!.integrationSelectedOutput = null;
    this.userService.updateCredits(output.userCredits);
    super.generateContentSuccess();
  }

  private parseIntegrationOutputData(): void {
    if (!this._selectedTactic || !this._selectedTactic.selectedOutputsData.length) return;
    this._selectedTactic.tactic.activities.forEach((activity) =>
      activity.assets.forEach(
        (asset) =>
          (asset.integrationSelectedOutput =
            this._selectedTactic!.selectedOutputsData.find((output) => output.activityAsset?.id === asset.id) ?? null),
      ),
    );
    this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
  }

  initForm(asset: ActivityAsset, integration: Integration): void {
    this.initFormFields(integration);
    this.costBadgeService.setCreditsPrice(integration.creditsPrice);
    this.fetchPreviousInputData(asset, integration);
  }

  private fetchPreviousInputData(asset: ActivityAsset, integration: Integration): void {
    this.funnelGraphqlService
      .getIntegrationInputsData({
        activityAssetId: asset.id,
        funnelTacticId: this._selectedTactic!.id,
        integrationId: integration.id,
      })
      .pipe(
        map(
          (res: FetchResult<GetIntegrationInputsDataQuery>) =>
            res.data?.getIntegrationInputsData as IntegrationInputData[],
        ),
        filter((inputs: IntegrationInputData[]) => !this.setInputsData(integration, inputs)),
        switchMap(() => this.funnelGraphqlService.getLastGeneratedIntegrationInputsData(integration.id)),
        map(
          (res: FetchResult<GetLastGeneratedIntegrationInputsDataQuery>) =>
            res.data?.getLastGeneratedIntegrationInputsData as IntegrationInputData[],
        ),
      )
      .subscribe((inputs: IntegrationInputData[]) => this.setInputsData(integration, inputs));
  }

  private setInputsData(integration: Integration, inputsData?: IntegrationInputData[]): boolean {
    if (!!inputsData && inputsData.length) {
      integration.inputsConfigurations.forEach((configuration) => {
        const input: IntegrationInputData = inputsData.find((i) => i.configuration.id === configuration.id)!;
        switch (configuration.type) {
          case InputTypeEnum.StringArray:
            (this.form.get(configuration.id.toString()) as UntypedFormArray).controls = (
              input.value['data'] as string[]
            ).map((value) => getDefaultControlHelper(configuration, value));
            this.form.get(configuration.id.toString())?.updateValueAndValidity({ emitEvent: false });
            break;
          default:
            this.form.get(configuration.id.toString())?.setValue(this.getFormInputValue(configuration, input), {
              emitEvent: false,
            });
            break;
        }
      });
      return true;
    } else return false;
  }

  private getFormInputValue(configuration: IntegrationInputConfiguration, input: IntegrationInputData) {
    switch (configuration.type) {
      case InputTypeEnum.Select:
        return this.getSelectOption(configuration, input.value['data']);
      default:
        return input.value['data'];
    }
  }

  markOutputDataAsSelected(outputData: IntegrationOutputData, asset: ActivityAsset): void {
    const req: Observable<FetchResult<MarkOutputDataAsSelectedMutation>>[] = [
      this.funnelGraphqlService.markOutputDataAsSelected(!outputData.isSelected, outputData.id),
    ];
    const currentlySelected = asset.integrationOutputs?.find((output) => output.isSelected);
    if (!!currentlySelected && currentlySelected.id !== outputData.id) {
      req.push(this.funnelGraphqlService.markOutputDataAsSelected(false, currentlySelected.id));
    }
    forkJoin(req).subscribe(
      () => {
        outputData.isSelected = !outputData.isSelected;
        if (!!currentlySelected && currentlySelected.id !== outputData.id) {
          currentlySelected.isSelected = false;
        }
        asset.integrationSelectedOutput = outputData.isSelected ? outputData : null;
        this.s.success(
          this.t.instant(
            'Funnels.Content generator.Copy ' + (outputData.isSelected ? '' : 'un') + 'selected successfully!',
          ),
        );
        this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
      },
      () => this.s.defaultError(),
    );
  }

  removeAssetSelectedOutput(asset: ActivityAsset): void {
    if (asset.integrationSelectedOutput) {
      this.funnelGraphqlService.markOutputDataAsSelected(false, asset.integrationSelectedOutput.id).subscribe(() => {
        const index: number =
          asset.integrationOutputs?.findIndex((output) => output.id === asset.integrationSelectedOutput?.id) ?? -1;
        asset.integrationSelectedOutput = null;
        if (index >= 0 && asset.integrationOutputs) {
          asset.integrationOutputs[index].isSelected = false;
        }
        this.s.success(this.t.instant('Funnels.Content generator.Copy removed successfully.'));
        this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
      });
    }
  }

  editOutput(asset: ActivityAsset, outputId: number, data): Observable<IntegrationOutputData> {
    return this.funnelGraphqlService.editOutputData({ outputId, value: { data } }).pipe(
      map((res: FetchResult<EditOutputDataMutation>) => res.data?.editOutputData as IntegrationOutputData),
      tap(
        (res: IntegrationOutputData) => this.editOutputSuccess(asset, res),
        () => this.handleEditContentError(),
      ),
    );
  }

  editContentLibraryOutput(outputId: number, data: any): Observable<IntegrationOutputData> {
    return this.funnelGraphqlService.editOutputData({ outputId, value: { data } }).pipe(
      map((response: FetchResult<EditOutputDataMutation>) => response.data?.editOutputData as IntegrationOutputData),
      tap(
        () => this.handleEditContentSuccess(),
        () => this.handleEditContentError(),
      ),
    );
  }

  private handleEditContentError(): void {
    this.s.error(this.t.instant('Funnels.Content generator.Error during saving generated content. Try again.'));
  }

  private handleEditContentSuccess(): void {
    this.s.success(this.t.instant('Funnels.Content generator.Copy edited successfully!'));
    this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
  }

  private editOutputSuccess(asset: ActivityAsset, edited: IntegrationOutputData): void {
    if (asset?.integrationOutputs) {
      const index = asset.integrationOutputs?.findIndex((output) => output.id === edited.id);
      if (index >= 0) asset.integrationOutputs[index] = edited;
    }
    if (asset?.integrationSelectedOutput?.id === edited.id) asset.integrationSelectedOutput = edited;
    this.handleEditContentSuccess();
  }

  removeOutput(output: IntegrationOutputData, asset: ActivityAsset): void {
    if (asset?.integrationOutputs) {
      const index: number = asset.integrationOutputs?.findIndex((o) => o.id === output.id);
      asset.integrationOutputs.splice(index, 1);
      if (asset.integrationSelectedOutput?.id === output.id) {
        asset.integrationSelectedOutput = null;
      }
    }
    this.funnelGraphqlService.removeOutputData(output.id).toPromise();
    this.s.success(this.t.instant('Funnels.Content generator.Copy removed successfully.'));
    this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
  }

  fetchGeneratedData(asset: ActivityAsset, integration: Integration): void {
    this._loading$.next(true);
    this.funnelGraphqlService
      .getIntegrationOutputsData({
        activityAssetId: asset.id,
        funnelTacticId: this._selectedTactic!.id,
        integrationId: integration.id,
      })
      .subscribe(
        (res: FetchResult<GetIntegrationOutputsDataQuery>) => {
          asset.integrationOutputs = res.data?.getIntegrationOutputsData as IntegrationOutputData[];
          this.contentGeneratorEventService.emit([ContentGeneratorEventEnum.CHANGE]);
        },
        () => this.s.defaultError(),
        () => this._loading$.next(false),
      );
  }
}
