import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { FetchResult } from '@apollo/client/core';
import { SetAppSumoSubscriptionMutation } from '@shared/graphql/mutations/set-app-sumo-subscription.mutation.generated';
import { User } from '@shared/models/user.model';
import { UserOutputGraphql } from '@modules/graphql/graphql-types';
import { SnackbarService } from '@core/services/snackbar.service';

@Component({
  selector: 'df-merge-app-sumo-account-modal',
  templateUrl: './merge-app-sumo-account-modal.component.html',
  styleUrls: ['./merge-app-sumo-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeAppSumoAccountModalComponent {
  loading = false;

  get token(): string {
    return this.context.data?.token ?? '';
  }

  constructor(
    public readonly userService: UserService,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { token: string } }, boolean>,
    private readonly changes: ChangeDetectorRef,
    private readonly s: SnackbarService,
  ) {}

  close(): void {
    this.context.completeWith(false);
  }

  confirm(): void {
    this.loading = true;
    this.changes.detectChanges();
    this.userService.setAppSumoSubscription(this.token).subscribe(
      (res: FetchResult<SetAppSumoSubscriptionMutation>) => {
        this.userService.User = new User(res.data?.setAppSumoSubscription as UserOutputGraphql);
        this.context.completeWith(true);
      },
      () => {
        this.s.defaultError();
        this.loading = false;
        this.changes.detectChanges();
      },
    );
  }
}
