import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StatementInputOption } from '../../models/statement.interface';

@Component({
  selector: 'df-statement-tiles-radio',
  templateUrl: './statement-tiles-radio.component.html',
  styleUrls: ['./statement-tiles-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementTilesRadioComponent implements OnInit {
  selectedValue: StatementInputOption | null = null;

  @Input() form!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() items: StatementInputOption[] = [];
  @Input() formSubmitted = false;

  constructor(public changes: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.resolveInitValue();
  }

  private resolveInitValue(): void {
    if (this.form.get(this.controlName)?.value?.length) {
      const option: StatementInputOption | null = this.resolveSelectedOption(
        this.form.get(this.controlName)?.value[0].id,
      );
      this.setNewValue(option);
    }
  }

  private resolveSelectedOption(id: number): StatementInputOption | null {
    for (let index = 0; index < this.items.length; index++) {
      if (this.items[index].id === id) return this.items[index];
    }
    return null;
  }

  setNewValue(statementInputOption: StatementInputOption | null): void {
    if (statementInputOption && this.selectedValue?.id !== statementInputOption.id) {
      this.form.get(this.controlName)?.patchValue([statementInputOption], { emitEvent: true });
      this.selectedValue = statementInputOption;
    } else {
      this.selectedValue = null;
      this.form.get(this.controlName)?.patchValue([], { emitEvent: true });
    }
    this.changes.detectChanges();
  }
}
