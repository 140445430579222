<div
  class="w-full h-full absolute top-0 left-0 z-50 tui-base-01-bg"
  *ngIf="public && buyFromParam && (buyPlanLoading || loading)"
></div>
<df-full-list-loader *ngIf="loading || buyPlanLoading"></df-full-list-loader>

<div class="container-center pb-10 md:pb-20 lg:pb-36">
  <header
    class="px-4 md:px-8 lg:px-16 pb-8 md:pb-10 text-center sm:text-left flex flex-col items-center sm:items-start"
  >
    <h1
      class="{{
        !public && noActivePlan ? 'tui-text_h3' : 'tui-text_h1'
      }} tui-primary mb-1.5 md:mb-3.5 mt-4 md:mt-6 lg:mt-16"
    >
      {{ (!public && noActivePlan ? 'Pricing.Select your plan to continue' : 'Pricing.Pricing Plans') | translate }}
    </h1>
    <p
      class="tui-text_h5"
      [class.max-w-md]="usedTrial"
    >
      {{
        (false
          ? 'Pricing.See details of your AppSumo Lifetime Plan'
          : !usedTrial
            ? 'Pricing.Start your free trial'
            : 'Pricing.Start growing with the best options for you!'
        ) | translate
      }}
    </p>
  </header>

  <div
    id="packets"
    class="packets-container tui-base-01-bg rounded-3xl border-0 shadow-2xl px-2 sm:px-4 md:px-8 xl:px-16 py-5 packets-{{
      plans.length
    }}"
  >
    <div
      class="packets__trial my-6"
      *ngIf="!public && noActivePlan"
    >
      <svg-icon
        src="assets/img/ic_exclamation_mark.svg"
        class="mb-3 md:mb-0 md:mr-3"
      ></svg-icon>
      <p
        class="tui-text_h6 font-semibold tui-text-03"
        [innerHTML]="'Pricing.Select a plan that fits to your needs!' | translate"
      ></p>
    </div>

    <div class="packets py-5">
      <div
        class="packets__container mb-10"
        *ngIf="!loading"
      >
        <div class="packets__packet text-center sm:text-left">
          <span class="tui-text_h2 tui-text-03 font-semibold">{{ 'Pricing.Our Plans' | translate }}</span>
        </div>
        <ng-container *ngFor="let plan of plans; let i = index">
          <tui-island
            *ngIf="getPriceForInterval(plan.prices) as price"
            class="packets__packet"
            [ngClass]="{
              active: isPlanActive(plan),
              presale: plan.isPresale,
              popular: plan.name === defaultTrialPlan?.name
            }"
          >
            <span class="tui-text_h5 tui-text-03 packets__packet--name">{{ plan.name }}</span>
            <span class="tui-text-body-s-2 tui-text-02 h-10 mb-1.5">{{ plan.description }}</span>
            <span class="i18n-ex tui-text_h1 tui-primary packets__packet--price">
              <span>{{ getIntegerMonthlyPriceAmount(price.amount)! | price }}</span>
              <span class="tui-text_body-l-2 tui-text-04"> PLN </span>
            </span>

            <span class="tui-text-body-s-2 tui-text-05 mb-5">
              <ng-container>
                {{ 'Pricing.Monthly (Monthly billing)' | translate }}
              </ng-container>
            </span>

            <ng-container>
              <button
                class="mb-1.5 self-center w-full"
                [ngClass]="{
                  'tui-base-01-bg': price.amount > 0,
                  'mb-7': !plan.trialDays
                }"
                tuiButton
                (click)="price.amount > 0 ? choosePlan(plan) : goToFunnel()"
                [appearance]="noActivePlan || (usingTrial && !urlToPayments) ? 'primary' : 'outline'"
                size="l"
                type="button"
              >
                <span class="font-medium">{{
                  (price.amount === 0
                    ? 'Pricing.free plan'
                    : !(noActivePlan || (usingTrial && !urlToPayments))
                      ? 'Pricing.Manage'
                      : !usedTrial
                        ? 'Pricing.start trial'
                        : 'Pricing.select plan'
                  ) | translate
                }}</span>
              </button>

              <p
                class="m-0 tui-text_body-s tui-text-04 self-center packets__packet--promo"
                *ngIf="price.amount > 0 && (noActivePlan || (usingTrial && !urlToPayments))"
              >
                {{ 'Pricing.or' | translate }}&nbsp;<a
                  class="tui-text_body-s tui-primary no-underline hover:underline cursor-pointer"
                  (click)="choosePlan(plan, true)"
                  >{{ 'Pricing.use promotional code' | translate }}</a
                >
              </p>
            </ng-container>
          </tui-island>
        </ng-container>
      </div>

      <div
        class="packets__details"
        *ngIf="!loading"
      >
        <div class="packets__details--row">
          <div class="permission-name">{{ 'Pricing.Plans' | translate }}</div>
          <div
            class="permission-value"
            *ngFor="let plan of plans"
          >
            {{ plan.name }}
          </div>
        </div>

        <ng-container *ngFor="let permission of permissions">
          <!-- special row -->
          <ng-container *ngIf="permission.name === 'Export pdf'; then creditsRow"></ng-container>
          <!-- row -->
          <div class="packets__details--row">
            <div class="permission-name">{{ permission.name }}</div>
            <div
              class="permission-value"
              *ngFor="let plan of plans"
            >
              <ng-container *ngIf="permission.planPermissions[plan.id]">
                <svg-icon
                  *ngIf="!permission.planPermissions[plan.id]['hasLimit']"
                  src="/assets/img/ic-pricing-check.svg"
                >
                </svg-icon>
                <df-packets-permission-limit
                  [pricePermission]="permission"
                  [plan]="plan"
                ></df-packets-permission-limit>
              </ng-container>

              <svg-icon
                *ngIf="!permission.planPermissions[plan.id]"
                src="/assets/img/ic-pricing-x.svg"
              ></svg-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #creditsRow>
  <div class="packets__details--row">
    <div class="permission-name">
      {{ 'Pricing.AI credits' | translate }}
    </div>
    <div
      class="permission-value"
      *ngFor="let number of credits"
    >
      <span class="tui-primary font-normal">{{ number }}</span>
    </div>
  </div>
</ng-template>
