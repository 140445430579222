import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'round', pure: true })
export class RoundPipe implements PipeTransform {
  transform(value: number | string | undefined | null, digits = 2, direction: 'up' | 'down' | 'round' = 'up'): string {
    let output = Number(value);
    if (isNaN(output)) return '';
    const negative: boolean = output < 0;
    negative ? (output *= -1) : '';
    output *= Math.pow(10, digits);
    output = direction === 'up' ? Math.ceil(output) : direction === 'round' ? Math.round(output) : Math.floor(output);
    output /= Math.pow(10, digits);
    negative ? (output *= -1) : '';
    return output.toString();
  }
}
