import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { Config } from '@shared/configs/config';

@Pipe({ name: 'dateFormat', pure: true })
export class DateFormatPipe implements PipeTransform {
  transform(value?: string, format?: string, locale?: string): string {
    return value ? DateTime.fromISO(value).toFormat(format ? format : Config.DATE_DOTS, locale ? { locale } : {}) : '';
  }
}
