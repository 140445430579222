import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const LoginAsUserDocument = gql`
  mutation loginAsUser($email: String!) {
    loginAsUser(email: $email) {
      token
      eventType
      user {
        ...UserStorage
      }
    }
  }
  ${UserStorageFragment}
`;
