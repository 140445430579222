import { gql } from 'apollo-angular';

export const CreateActivityLogoDocument =
  gql(/* GraphQL*/ `mutation createActivityLogo($activityId: Int!, $file: Upload!) {
  createActivityLogo(activityId: $activityId, file: $file) {
    name
    id
    file
  }
}
`);
