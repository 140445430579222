import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { StorageService } from '@core/services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { TiptapMode } from '@modules/tiptap/tiptap-mode.enum';

@Component({
  selector: 'df-iframe',
  templateUrl: './iframe.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeComponent implements OnInit {
  readonly readyToReceiveTokenMessage = 'readyToReceiveToken';

  @Input() funnelId!: number;
  @Input() inputType!: 'tiptap' | 'landing page' | 'master-visuals';
  @Input() tiptapMode: TiptapMode | undefined;

  @Output() tiptapShareUrl = new EventEmitter<string>();

  @ViewChild('iframe') iframe!: ElementRef<HTMLIFrameElement>;

  link = '';
  iframeOrigin = '';
  docName!: string;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly storageService: StorageService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.docName = this.route.parent?.snapshot.queryParams['docName'];

    switch (this.inputType) {
      case 'tiptap':
        this.iframeOrigin = environment.TIPTAP_URL;
        break;
      case 'landing page':
      case 'master-visuals':
        this.iframeOrigin = environment.LANDING_PAGE_URL;
        break;
    }
    this.link = this.getLink();

    window.onmessage = (e: MessageEvent<string>) => {
      if (e.origin === this.iframeOrigin) {
        if (e.data === this.readyToReceiveTokenMessage) {
          console.log('Access token send');
          this.sendAccessTokenToIFrame();
        }
      }
    };
  }

  sendAccessTokenToIFrame(): void {
    const token = this.storageService.jwt;
    if (!token) return;
    this.iframe.nativeElement.contentWindow?.postMessage({ token }, this.iframeOrigin);
  }

  private getLink(): string {
    switch (this.inputType) {
      case 'tiptap':
        return this.getTiptapLink();
      case 'landing page':
        return this.getLandingPageLink();
      case 'master-visuals':
        return this.getMasterVisualsLink();
      default:
        return '';
    }
  }

  private getTiptapLink(): string {
    const baseUrl = `${this.iframeOrigin}/${this.docName}?docName=${this.docName}&funnelId=${this.funnelId}`;

    const shareUrl = `${baseUrl}&mode=${TiptapMode.READ_ONLY}`;
    this.tiptapShareUrl.emit(shareUrl);

    const mode = this.tiptapMode ?? TiptapMode.ALL;
    const iframeUrl = `${baseUrl}&mode=${mode}`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl) as string;
  }

  private getLandingPageLink(): string {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.iframeOrigin}/?funnelId=${this.funnelId}&edit=true&docName=${this.docName}`,
    ) as string;
  }

  private getMasterVisualsLink() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.iframeOrigin}/editor/master/${this.funnelId}?docName=${this.docName}`,
    ) as string;
  }
}
