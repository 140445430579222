<section class="w-full h-screen flex flex-col">
  <df-business-flow-header
    [docName]="docName"
    [loading]="loading"
    (onNextButtonClick)="onNextButtonClick()"
    [tiptapShareUrl]="tiptapShareUrl"
  ></df-business-flow-header>

  <df-iframe
    [funnelId]="funnelId"
    [tiptapMode]="mode"
    (tiptapShareUrl)="tiptapShareUrl = $event"
    inputType="tiptap"
    class="w-full h-full"
  ></df-iframe>
</section>
