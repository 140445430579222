import { gql } from 'apollo-angular';

export const FilterSegmentsDocument = gql(/* GraphQL*/ `query filterSegments($input: FilterInputGraphql!)
{
  filterSegments(input: $input)
  {
    records{
      name,
      id
    },
    total
  }
}

`);
