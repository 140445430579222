import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SetupAccountService } from '@modules/authorization/pages/onboarding-setup-form/setup-account.service';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { AccountSetupParameterCategoriesEnum, WorksForEnum } from '@modules/graphql/graphql-types';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { Observable } from 'rxjs';
import { TuiInputComponent, TuiSelectComponent } from '@taiga-ui/kit';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-onboarding-setup-form',
  templateUrl: './onboarding-setup-form.component.html',
  styleUrls: ['./onboarding-setup-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SetupAccountService],
})
export class OnboardingSetupFormComponent extends AbstractSubscriptionComponent implements OnInit {
  get parameters() {
    return this.setupAccountService.parameters;
  }

  get parametersKeys() {
    return Object.keys(this.setupAccountService.parameters);
  }

  get form(): UntypedFormGroup {
    return this.setupAccountService.form;
  }

  get parametersForm(): UntypedFormGroup {
    return this.setupAccountService.parametersForm;
  }

  get workForOptions() {
    return Object.values(WorksForEnum);
  }

  loading$: Observable<boolean>;
  submitLoading$: Observable<boolean>;
  checkboxError = false;

  readonly stringify: TuiStringHandler<
    { id: number | null; name: string } | TuiContextWithImplicit<{ id: number | null; name: string }>
  > = (item) => ('name' in item ? item.name : item.$implicit.name);

  @ViewChildren(TuiInputComponent) inputs?: QueryList<TuiInputComponent>;
  @ViewChildren(TuiSelectComponent) selects?: QueryList<TuiSelectComponent<{ id: string | null; name: string }>>;

  constructor(
    private readonly setupAccountService: SetupAccountService,
    private readonly changes: ChangeDetectorRef,
    public readonly userService: UserService,
  ) {
    super();
    this.loading$ = this.setupAccountService.loading$;
    this.submitLoading$ = this.setupAccountService.submitLoading$;
  }

  ngOnInit(): void {
    this.listenOtherOptionSelection();
  }

  private listenOtherOptionSelection(): void {
    Object.keys(this.parameters).forEach((key) => {
      this.sub.add(
        this.parametersForm.get(key)?.valueChanges.subscribe((val) => {
          val.id === null
            ? this.setupAccountService.addOtherControl(key)
            : this.setupAccountService.removeOtherControl(key);
          this.changes.detectChanges();
        }),
      );
    });
  }

  submit(): void {
    this.form.markAllAsTouched();
    this.detectFormFieldsChanges();
    if (!this.form.valid) return;
    this.setupAccountService.saveForm();
  }

  setWorkFor(worksFor: string, event: Event): void {
    this.checkboxError = false;
    event.stopPropagation();
    event.preventDefault();
    this.form.get('worksFor')?.setValue(worksFor);
    this.changes.detectChanges();
  }

  getOtherSelected(category: AccountSetupParameterCategoriesEnum | string): boolean {
    return this.parametersForm.get(category)?.value?.id === null;
  }

  detectFormFieldsChanges(): void {
    this.inputs?.forEach((input) => input.checkControlUpdate());
    this.selects?.forEach((select) => select.checkControlUpdate());
    this.checkboxError = !this.form.get('worksFor')?.valid;
    this.changes.detectChanges();
  }
}
