import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const LoginDocument = gql`
  mutation login($email: String!, $password: String!, $rememberMe: Boolean!, $redirectedFromTacticsLpPage: Boolean) {
    login(
      email: $email
      password: $password
      rememberMe: $rememberMe
      redirectedFromTacticsLpPage: $redirectedFromTacticsLpPage
    ) {
      token
      eventType
      user {
        ...UserStorage
      }
    }
  }
  ${UserStorageFragment}
`;
