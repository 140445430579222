import { Injectable } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AuthController } from '@modules/authorization/shared/controllers/auth.controller';
import { AuthOutputGraphql, PartnerRegisterInputGraphql } from '@modules/graphql/graphql-types';
import { RegisterByPartnerDocument } from '@shared/graphql/mutations/register-by-partner.mutation';
import { RegisterByPartnerMutation } from '@shared/graphql/mutations/register-by-partner.mutation.generated';
import { Apollo } from 'apollo-angular';

declare global {
  interface Window {
    SM: {
      init(): Promise<void>;
      client(name: string): Promise<string>;
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class SemrushService {
  localStorageKey = 'dfai_semrush_item';

  constructor(
    private apollo: Apollo,
    private n: NavigateService,
  ) {
    localStorage.setItem(this.localStorageKey, JSON.stringify([]));
  }

  async loginClient(token = '', decoded: Record<string, string[]> | null = null) {
    const input: PartnerRegisterInputGraphql = {
      firstName: 'Semrush User',
      rulesAccepted: true,
      email: '',
    };
    if (!token && !decoded) [token, decoded] = await this.getAccessToken();

    input.tokenLifetime = 10000;

    if (decoded) {
      input.metadata = { semrushViewerId: decoded['viewer_id'], token: token };
      input.email = `${decoded['viewer_id']}@digitalfirst.ai`;
      input.unlimitedPlan = false;

      if (decoded?.active_products && decoded?.active_products?.length > 0) {
        input.unlimitedPlan = true;
        localStorage.setItem(this.localStorageKey, JSON.stringify(decoded?.active_products));
      }
    }

    return this.apollo.use('SemrushClient').mutate<RegisterByPartnerMutation>({
      mutation: RegisterByPartnerDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  async getAccessToken() {
    try {
      const token = await window.SM.client('getAccessToken');
      const decoded = this.parseJwt(token);
      const items = JSON.parse((localStorage.getItem(this.localStorageKey) as string) ?? '[]');
      console.log('wykonuje');
      if (decoded.active_products.length === items.length) {
        return [token, decoded];
      }

      (await this.loginClient(token, decoded)).subscribe((res) => {
        if (res.data) {
          const auth = res.data['registerByPartner'] as AuthOutputGraphql;

          const authController = new AuthController();
          authController.saveAuthData(auth);
        }
      });

      return [token, decoded];
    } catch (e) {
      console.log(e);
      localStorage.clear();
      this.n.go('no-auth-semrush');
      return '';
    }
  }

  async showUpsell() {
    await window.SM.client('requestInAppPurchase');
  }

  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
}
