<div class="w-full sm:w-3/4 m-auto flex flex-col">
  <p class="tui-text_body-m-2 text-center mb-10">
    {{ 'Auth.Forgot Password.description' | translate }}
  </p>
  <form
    [formGroup]="formGroup"
    [validate-form]="formGroup"
    (submit)="submit()"
  >
    <div class="tui-form__row">
      <tui-input formControlName="email">
        {{ 'email' | translate }}
      </tui-input>
      <df-error-input [control]="formGroup.get('email')"></df-error-input>
    </div>

    <div class="tui-form__row mt-10 flex justify-center">
      <button
        size="xl"
        tuiButton
        type="submit"
        appearance="primary"
        [showLoader]="loading"
        [disabled]="loading || !formGroup.valid"
      >
        {{ 'submit' | translate }}
      </button>
    </div>
  </form>
</div>
