<df-full-list-loader *ngIf="funnelFlowManageService.loading || (loading$ | async)"></df-full-list-loader>
<df-funnel-manage-flows
  *ngIf="!!funnelManageService?.funnel?.tactics?.length && (loading$ | async) === false"
></df-funnel-manage-flows>
<df-funnel-panel-manage-content #funnelManageContentComponent></df-funnel-panel-manage-content>
<tui-island
  *ngIf="funnelFlowManageService.editMode && islandVisible"
  size="s"
  class="right-4 left-20 fixed flex justify-between items-center bottom-4 z-50 shadow-lg"
>
  <div class="flex w-full flex-col">
    <h4 class="tui-primary tui-text_h5">
      {{ 'Funnels.Manage.Edit mode' | translate }}
    </h4>
    <p class="tui-text_body-m-4 tui-text-02 mt4">
      {{
        'Funnels.Manage.Link cards by clicking on them using the mouse. Links will be added in the order that you click '
          | translate
      }}
    </p>
  </div>
  <button
    class="ml-4"
    tuiIconButton
    type="button"
    appearance="outline"
    (click)="islandVisible = false"
    [icon]="icon"
  ></button>
  <ng-template #icon>
    <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
  </ng-template>
</tui-island>

<df-onboarding-video [src]="'https://www.youtube.com/embed/ypyNsKsVryQ?si=4X0kuKtScy2PToGT'"></df-onboarding-video>
