import { Injectable } from '@angular/core';
import {
  CreateManyFunnelTacticInputGraphql,
  StatementFirstInputGraphql,
  StatementInputGraphql,
} from '@modules/graphql/graphql-types';
import { Apollo } from 'apollo-angular';
import { GetTacticsRecommendationsQuery } from '@modules/statement/shared/graphql/queries/get-recomended-tactics.query.generated';
import { GetTacticsRecommendationsDocument } from '@modules/statement/shared/graphql/queries/get-recomended-tactics.query';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { FillPublicFirstStepMutation } from '@modules/statement/shared/graphql/mutations/fill-public-first-step.mutation.generated';
import { FillPublicFirstStepDocument } from '@modules/statement/shared/graphql/mutations/fill-public-first-step.mutation';
import { SendPublicFirstStepByEmailMutation } from '@modules/statement/shared/graphql/mutations/send-public-first-step-by-email.mutation.generated';
import { SendPublicFirstStepByEmailDocument } from '@modules/statement/shared/graphql/mutations/send-public-first-step-by-email.mutation';
import { CreateManyFunnelsTacticsMutation } from '@modules/tactics/shared/graphql/mutations/create-many-funnel-tactics.mutation.generated';
import { CreateManyFunnelTacticsDocument } from '@modules/tactics/shared/graphql/mutations/create-many-funnel-tactics.mutation';
import { CreateStatementMutation } from '../graphql/mutations/create-statement.mutation.generated';
import { createStatementDocument } from '../graphql/mutations/create-statement.mutation';
import { GetStatementQuery } from '../graphql/queries/get-new-statement.query.generated';
import { GetStatementDocument } from '../graphql/queries/get-new-statement.query';

@Injectable({
  providedIn: 'root',
})
export class StatementGraphqlService {
  constructor(private apollo: Apollo) {}

  createStatement(input: StatementInputGraphql) {
    return this.apollo.mutate<CreateStatementMutation>({
      mutation: createStatementDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  fillPublicFirstStep(input: StatementFirstInputGraphql): Observable<FetchResult<FillPublicFirstStepMutation>> {
    return this.apollo.mutate<FillPublicFirstStepMutation>({
      mutation: FillPublicFirstStepDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  sendPublicFirstStepByEmail(
    email: string,
    file: File,
    input: StatementFirstInputGraphql,
  ): Observable<FetchResult<SendPublicFirstStepByEmailMutation>> {
    return this.apollo.mutate<SendPublicFirstStepByEmailMutation>({
      mutation: SendPublicFirstStepByEmailDocument,
      variables: {
        input,
        email,
        file,
      },
      context: {
        useMultipart: true,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getRecommendedTactics(funnelId: number, records: number, uniqueReccommendations: boolean | null = false) {
    return this.apollo.query<GetTacticsRecommendationsQuery>({
      query: GetTacticsRecommendationsDocument,
      variables: {
        funnelId,
        records,
        uniqueReccommendations,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createManyFunnelsTactics(
    inputs: CreateManyFunnelTacticInputGraphql[],
    funnelId: number,
  ): Observable<FetchResult<CreateManyFunnelsTacticsMutation>> {
    return this.apollo.mutate<CreateManyFunnelsTacticsMutation>({
      mutation: CreateManyFunnelTacticsDocument,
      variables: {
        inputs,
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getNewStatement(funnelId: number) {
    return this.apollo.query<GetStatementQuery>({
      query: GetStatementDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
