<div
  class="flex flex-row flex-wrap md:flex-nowrap justify-between border border-solid border-gray-200 p-3 mb-1 rounded-lg"
  *ngIf="report"
>
  <div class="block mr-2 md:mr-4 w-6 flex-shrink-0 flex-grow-0 flex flex-col justify-center items-center">
    <svg-icon
      [src]="Config.ASSETS + icon"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
      *ngIf="icon"
    ></svg-icon>
  </div>

  <div class="flex flex-col flex-grow justify-center">
    <span class="tui-text_body-s-2 tui-text-01">{{ report.name }}</span>
    <span class="tui-text_body-xs tui-text-01">{{ report.link }} </span>
  </div>
</div>
