<div class="flex flex-col -ml-6 -mr-6 md:-ml-8 md:-mr-8 pl-8 pr-8 pt-4 pb-4">
  <p class="tui-text_body-m-2 tui-text-02 mb-8 text-center">
    {{ 'Funnels.Manage.flow data description' | translate }}
  </p>
  <form
    [formGroup]="funnelFlowManageService.form"
    [validate-form]="funnelFlowManageService.form"
    (submit)="submit()"
  >
    <div class="tui-form__row">
      <tui-input
        tuiAutoFocus
        #tuiInputComponent
        formControlName="name"
      >
        {{ 'Funnels.Flow name' | translate }}*
      </tui-input>
      <df-error-input [control]="funnelFlowManageService.form.get('name')"></df-error-input>
    </div>
  </form>
</div>
<div class="flex items-center mt-4 justify-end">
  <button
    size="xl"
    tuiButton
    type="button"
    class="mr-4"
    appearance="outline"
    [disabled]="loading"
    (click)="cancel()"
  >
    <svg-icon
      [src]="'/assets/img/ic-close.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'cancel' | translate }}
  </button>

  <button
    size="xl"
    tuiButton
    type="submit"
    appearance="primary"
    [showLoader]="loading"
    [disabled]="loading"
    (click)="submit()"
  >
    <svg-icon
      [src]="'/assets/img/ic-check.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'save' | translate }}
  </button>
</div>
