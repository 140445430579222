<div class="tactic-rec-tile flex flex-col justify-between items-center py-6 px-3 h-full w-full overflow-hidden">
  <svg-icon
    class="tactic-rec-tile__icon"
    [src]="'/assets/img/ic_question_circle_card.svg'"
    [svgStyle]="{ width: '150px', height: '100px' }"
  ></svg-icon>
  <h2 class="tactic-rec-tile__title tui-text-01 tui-text_h5 text-center">
    {{ 'Tactics.List.Request a tactic' | translate }}
  </h2>
  <p class="tactic-rec-tile__subtitle tui-text-body-m-2">
    {{ 'Tactics.List.Get special tactic' | translate }}
  </p>
</div>
