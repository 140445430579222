import { gql } from 'apollo-angular';

export const CreateActivityImageDocument =
  gql(/* GraphQL*/ `mutation createActivityImage($activityId: Int!, $file: Upload!) {
  createActivityImage(activityId: $activityId, file: $file) {
    name
    file
    id
  }
}
`);
