import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { URL_REGEX, WHATAGRAPH_NAMES } from '../models/funnel-statistics.model';

export function whatagraphUrlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isUrl: boolean = new RegExp(URL_REGEX, 'gm').test(control.value);
    if (!isUrl) return { incorrectValue: { value: true } };
    for (let index = 0; index < WHATAGRAPH_NAMES.length; index++) {
      if (control.value && control.value.indexOf(WHATAGRAPH_NAMES[index]) > -1) return null;
    }
    return { incorrectValue: { value: true } };
  };
}
