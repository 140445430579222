import { NgModule } from '@angular/core';
import { ResourceTeamsComponent } from './components/resource-teams/resource-teams.component';
import { ResourceTeamsSingleTeamComponent } from './components/resource-teams-single-team/resource-teams-single-team.component';
import { ResourceTeamsSinglePermissionComponent } from './components/resource-teams-single-permission/resource-teams-single-permission.component';
import { SharedModule } from '@shared/shared.module';
import { ResourceTeamsGraphQLService } from '@shared/modules/resource-teams/services/resource-teams-graph-ql.service';
import { ResourceTeamsService } from '@shared/modules/resource-teams/services/resource-teams.service';

@NgModule({
  declarations: [ResourceTeamsComponent, ResourceTeamsSingleTeamComponent, ResourceTeamsSinglePermissionComponent],
  imports: [SharedModule],
  exports: [ResourceTeamsComponent],
  providers: [ResourceTeamsGraphQLService, ResourceTeamsService],
})
export class ResourceTeamsModule {}
