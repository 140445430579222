import { gql } from 'apollo-angular';

export const GetAssetsPlatformsDocument = gql(/* GraphQL*/ `query getAssetsPlatforms
{
  getAssetsPlatforms
  {
    id
    name
    image
  }
}

`);
