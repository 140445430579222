<div class="ai-design-form-container">
  <div class="flex-grow overflow-y-auto px-5 py-3">
    <form
      *ngIf="form"
      [formGroup]="form"
      [validate-form]="form"
      (ngSubmit)="onSubmit()"
    >
      <div
        class="mt-5 flex flex-col justify-start items-stretch"
        style="min-height: 85vh"
      >
        <div *ngIf="getConfiguration('text_prompts') as configuration">
          <df-content-generator-form-fields-template
            [form]="form"
            [configuration]="configuration"
          ></df-content-generator-form-fields-template>
        </div>

        <div class="mt-1">
          <h4 class="section__header mb-1">
            {{ 'Funnels.Graphics.Video settings' | translate }}
          </h4>

          <div class="ml-4">
            <div *ngIf="getConfiguration('seed') as configuration">
              <df-content-generator-form-fields-template
                [form]="form"
                [configuration]="configuration"
              ></df-content-generator-form-fields-template>
            </div>

            <div *ngIf="getConfiguration('cfg_scale') as configuration">
              <df-content-generator-form-fields-template
                [form]="form"
                [configuration]="configuration"
              ></df-content-generator-form-fields-template>
            </div>

            <div *ngIf="getConfiguration('motion_bucket_id') as configuration">
              <df-content-generator-form-fields-template
                [form]="form"
                [configuration]="configuration"
              ></df-content-generator-form-fields-template>
            </div>
          </div>
        </div>

        <div class="mt-6 image-referance-border pt-5">
          <button
            class="w-full"
            tuiButton
            appearance="primary"
            type="submit"
            size="xl"
          >
            <span class="tui-text-body-m-3 ml-2.5">{{ 'Funnels.Graphics.Generate video' | translate }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
