import { Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { FlowItem } from '@shared/models/flow-item.model';
import { TuiDialogContext } from '@taiga-ui/core';

@Component({
  selector: 'df-funnel-cancel-confirm-flow-modal',
  templateUrl: './funnel-cancel-confirm-flow-modal.component.html',
  styleUrls: ['./funnel-cancel-confirm-flow-modal.component.scss'],
})
export class FunnelCancelConfirmFlowModalComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<boolean>,
    private funnelFlowManageService: FunnelFlowManageService,
  ) {}

  cancel() {
    this.context.completeWith(false);
  }

  submit() {
    this.funnelFlowManageService.flow!.items = this.funnelFlowManageService.flow!.items.filter(
      (flowItem: FlowItem) => !flowItem.toRemoveWithoutSave,
    );
    this.funnelFlowManageService.editMode = false;
    this.context.completeWith(true);
  }
}
