import { gql } from 'apollo-angular';
import { FunnelFragment } from '@modules/funnels/shared/graphql/fragments/funnel.fragment';

export const CreateFunnelDocument = gql`
  mutation createFunnel($input: FunnelInputGraphql!, $file: Upload) {
    createFunnel(input: $input, file: $file) {
      ...Funnel
    }
  }
  ${FunnelFragment}
`;
