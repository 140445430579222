import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { Apollo } from 'apollo-angular';

import { BaseCustomSelectComponent } from '@shared/modules/custom-selects/components/base-custom-select.component';

@Component({
  selector: 'df-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent extends BaseCustomSelectComponent {
  constructor(
    public apollo: Apollo,
    public changes: ChangeDetectorRef,
  ) {
    super(apollo, changes);
  }
}
