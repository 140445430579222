import { Component } from '@angular/core';
import { FunnelsFilterService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-filter.service';
import { FunnelsListService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-list.service';

@Component({
  selector: 'df-funnels-list-sub-header',
  templateUrl: './funnels-list-sub-header.component.html',
  styleUrls: ['./funnels-list-sub-header.component.scss'],
})
export class FunnelsListSubHeaderComponent {
  constructor(
    public funnelsFilterService: FunnelsFilterService,
    public funnelsListService: FunnelsListService,
  ) {}
}
