import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { TacticSettingsCloseAlertComponent } from '@modules/tactics/modules/tactic-settings/shared/components/tactic-settings-close-alert/tactic-settings-close-alert.component';
import { EContainerTabType } from '@shared/interfaces/container-tab.interface';
import { pluck } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { FunnelPermissionEnum, VerificationStatusEnum } from '@modules/graphql/graphql-types';
import { EResourceViewType } from '@shared/modules/resource-teams/enums/resource-view-type.enum';
import { ResourceTeamsService } from '@shared/modules/resource-teams/services/resource-teams.service';
import { LanguageService } from '@core/services/language.service';
import { TacticSettingsViewModeEnum } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-settings-view-mode.enum';
import { TuiFileLike, TuiInputComponent } from '@taiga-ui/kit';
import { rejectedImages } from '@shared/helpers/file-input.helper';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';
import { ETacticSettingsFormEnum } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-settings-form.enum';
import { AdminTokenService } from '@shared/services/admin-token.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-tactic-settings',
  templateUrl: './tactic-settings.component.html',
  styleUrls: ['./tactic-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticSettingsComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  Config = Config;
  FunnelPermissionEnum = FunnelPermissionEnum;
  EContainerTabType = EContainerTabType;
  EResourceViewType = EResourceViewType;
  VerificationStatusEnum = VerificationStatusEnum;
  ETacticSettingsFormEnum = ETacticSettingsFormEnum;

  language = '';
  loading = true;
  anyChanges = false;
  isEditMode = false;

  @Input() isPublic = false;
  @ViewChildren(TuiInputComponent) inputs?: QueryList<TuiInputComponent>;

  get form(): UntypedFormGroup {
    return this.tacticSettingsService.formGroup;
  }

  get imageUrl(): string {
    return Config.ASSETS + this.tacticSettingsService.tactic?.image?.file;
  }

  constructor(
    private readonly n: NavigateService,
    public tacticSettingsService: TacticSettingsService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private changes: ChangeDetectorRef,
    private t: TranslateService,
    private s: SnackbarService,
    private route: ActivatedRoute,
    private readonly languageService: LanguageService,
    private readonly adminTokenService: AdminTokenService,
    public userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.language = this.languageService.getCurrentLanguage();
    this.checkAdmin();
    this.tacticSettingsService.initService();
    this.listenLoading();
    this.listenChanges();
    this.listenViewModeChange();
    this.listenFormControlUpdates();
  }

  private checkAdmin(): void {
    if (this.isPublic && this.route.snapshot.queryParamMap.has('auth')) {
      this.adminTokenService.token = this.route.snapshot.queryParams.auth ?? null;
      this.n.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: null,
      });
    }
  }

  private listenLoading() {
    this.sub.add(
      this.tacticSettingsService.loading$.subscribe((val: boolean) => {
        this.loading = val;
        this.changes.detectChanges();
      }),
    );
  }

  private listenFormControlUpdates(): void {
    this.sub.add(
      this.tacticSettingsService.updateFormControls$.asObservable().subscribe(() => this.detectFormFieldsChanges()),
    );
  }

  detectFormFieldsChanges(): void {
    this.inputs?.forEach((input) => input.checkControlUpdate());
    this.changes.detectChanges();
  }

  onResourceTeamsComponentReady(resourceTeamsService: ResourceTeamsService) {
    this.tacticSettingsService.resourceTeamsService = resourceTeamsService;
  }

  setActiveTab(index: number) {
    this.tacticSettingsService.activeTab = this.tacticSettingsService.tabs[index];
    this.changes.detectChanges();
  }

  private initTabs(activeTab: EContainerTabType = EContainerTabType.ABOUT) {
    this.tacticSettingsService.tabs = [];
    if (this.tacticSettingsService.tactic?.currentUserPermission === FunnelPermissionEnum.Editor || !this.isEditMode) {
      this.tacticSettingsService.tabs.push(
        {
          name: this.t.instant('Tactics.Settings.about'),
          index: 0,
          type: EContainerTabType.ABOUT,
        },
        {
          name: this.t.instant('Tactics.Settings.activities'),
          index: 1,
          type: EContainerTabType.ACTIVITIES,
        },
        {
          name: this.t.instant('Tactics.Settings.metrics'),
          index: 2,
          type: EContainerTabType.METRICS,
        },
        {
          name: this.t.instant('Tactics.Settings.teams'),
          index: 3,
          type: EContainerTabType.TEAM,
        },
      );
    }
    if (this.tacticSettingsService.tactic?.currentUserPermission !== FunnelPermissionEnum.ViewOnly && this.isEditMode) {
      this.tacticSettingsService.tabs.push({
        name: this.t.instant('share'),
        index: this.tacticSettingsService.tabs.length,
        type: EContainerTabType.SHARE,
        sectionTitle: this.t.instant('Tactics.Settings.invite'),
      });
    }

    if (this.userService.User?.isSemrushPlan()) {
      this.tacticSettingsService.tabs = this.tacticSettingsService.tabs.filter(
        (tab) => tab.type !== EContainerTabType.TEAM && tab.type !== EContainerTabType.SHARE,
      );
    }

    this.tacticSettingsService.activeTab =
      this.tacticSettingsService.tabs.find((tab) => tab.type === activeTab) ?? this.tacticSettingsService.tabs[0];
    this.changes.detectChanges();
  }

  private listenViewModeChange(): void {
    this.sub.add(
      combineLatest([this.route.data.pipe(pluck('type')), this.route.params, this.route.queryParams]).subscribe(
        ([data, params, queryParams]: [TacticSettingsViewModeEnum, Params, Params]) => {
          this.tacticSettingsService.viewMode = data;
          this.isEditMode = data === TacticSettingsViewModeEnum.EDIT;
          if (this.isEditMode || this.isPublic) {
            const sub = this.tacticSettingsService.fetchTactic(params.id);
            sub.add(() => {
              this.initTabs(queryParams.tab ? queryParams.tab : EContainerTabType.ABOUT);
              this.changes.detectChanges();
            });
          } else {
            this.initTabs();
          }
          this.changes.detectChanges();
        },
      ),
    );
  }

  private listenChanges(): void {
    this.sub.add(
      this.tacticSettingsService.changesInForm$.subscribe((changes) => {
        this.anyChanges = changes;
        this.changes.detectChanges();
      }),
    );
  }

  getControl(path: string): UntypedFormControl {
    return this.form.get(path) as UntypedFormControl;
  }

  deleteTactic() {
    this.tacticSettingsService.removeTactic().subscribe({
      next: () => {
        this.n.go('/tactics/list/all');
        this.s.success(this.t.instant('Tactics.Tactic successfully removed'));
      },
      error: () => {
        this.s.defaultError();
      },
    });
  }

  openConfirmExitModal(): Observable<boolean> {
    if (this.anyChanges) {
      return new Observable<boolean>((subscriber) => {
        this.dialogService
          .open<boolean>(new PolymorpheusComponent(TacticSettingsCloseAlertComponent, this.injector), {
            closeable: true,
            dismissible: false,
            size: 's',
            label: this.t.instant('Funnels.Settings.Leaving this page?'),
          })
          .subscribe((val) => {
            subscriber.next(val);
            subscriber.complete();
          });
      });
    } else {
      return of(true);
    }
  }

  rejectedImages(e: TuiFileLike | readonly TuiFileLike[]) {
    rejectedImages(e, this.s, this.t);
    this.changes.detectChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.tacticSettingsService.clearService();
  }
}
