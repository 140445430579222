import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { MarkNotificationAsReceivedMutation } from '@modules/notifications/shared/graphql/mutations/mark-notification-as-received.mutation.generated';
import { MarkNotificationAsReceivedDocument } from '@modules/notifications/shared/graphql/mutations/mark-notification-as-received.mutation';
import { GetUnreceivedNotificationsQuery } from '@modules/notifications/shared/graphql/queries/get-unreceived-notifications.query.generated';
import { GetUnreceivedNotificationsDocument } from '@modules/notifications/shared/graphql/queries/get-unreceived-notifications.query';

@Injectable({
  providedIn: 'root',
})
export class NotificationsGraphqlService {
  constructor(private readonly apollo: Apollo) {}

  getUnreceivedNotifications(): Observable<FetchResult<GetUnreceivedNotificationsQuery>> {
    return this.apollo.query<GetUnreceivedNotificationsQuery>({
      query: GetUnreceivedNotificationsDocument,
      fetchPolicy: 'no-cache',
    });
  }

  markNotificationAsReceived(id: number): Observable<FetchResult<MarkNotificationAsReceivedMutation>> {
    return this.apollo.mutate<MarkNotificationAsReceivedMutation>({
      mutation: MarkNotificationAsReceivedDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
