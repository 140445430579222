import { gql } from 'apollo-angular';

export const GetKanbanDocument = gql(/* GraphQL*/ `query getKanban
{
  getKanban
  {
    type
    name
    id
    tactics
    {
      id
      name
      funnelSteps
      {
        type
      }
      requiresBudget
      tacticDetails
      owner
      {
        id
        firstName
        lastName
      }
      levelOfAdvancement
      {
        name
      }
      rate
    }
  }
}

`);
