import { gql } from 'apollo-angular';

export const CreateTacticMetricDocument = gql`
  mutation createTacticMetric($input: TacticMetricInputGraphql!) {
    createTacticMetric(input: $input) {
      id
      measurementPeriod
      isCyclical
      cycleMeasurementPeriod
      metric {
        id
        name
      }
    }
  }
`;
