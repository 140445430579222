<div class="flex items-start justify-start w-full">
  <div class="flex flex-col justify-center items-start mt-2">
    <svg-icon
      class="mt-8"
      [src]="'/assets/img/ic_profile.svg'"
    ></svg-icon>
    <h2 class="tui-text-01 tui-text_h4 mt-4 mb-6 font-light">
      <span class="font-bold block tui-text_h4">{{ 'Tactics.Public profile necessary' | translate }}</span>
    </h2>
  </div>
</div>
<div class="flex-col flex items-start justify-start w-full">
  <h3 class="tui-text-05 tui-text_h6 mb-8 font-light">
    {{ 'Tactics.For publishing tactics in library your profile should become visible for others' | translate }}
  </h3>
  <div class="lg:flex items-center h-full">
    <button
      class="cursor-pointer inline-block mr-4 mb-4 sm:mb-0"
      size="l"
      tuiButton
      type="submit"
      (click)="close()"
      appearance="outline"
    >
      {{ 'Cancel' | translate }}
    </button>

    <button
      class="cursor-pointer inline-block"
      size="l"
      tuiButton
      [showLoader]="loading"
      [disabled]="loading"
      type="submit"
      (click)="makeUserProfilePublic()"
      appearance="primary"
    >
      {{ 'Ok, got it' | translate }}
    </button>
  </div>
</div>
