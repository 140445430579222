import { Component, input } from '@angular/core';

@Component({
  selector: 'df-tactic-step-wrapper',
  standalone: true,
  imports: [],
  templateUrl: './tactic-step-wrapper.component.html',
  styleUrl: './tactic-step-wrapper.component.css',
})
export class TacticStepWrapperComponent {
  public stepText = input.required<string>();
}
