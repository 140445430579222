import { OnboardingDOMElement, OnboardingMultiDOMElementFactory } from '@shared/models/onboarding-step.model';

export abstract class AbstractOnboardingMultiElementsFactory<T> implements OnboardingMultiDOMElementFactory<T> {
  private readonly _anchors: HTMLCollection;
  private readonly _body: HTMLElement;

  protected get anchors(): HTMLCollection {
    return this._anchors;
  }

  protected get container(): HTMLElement {
    return this._body;
  }

  protected constructor(anchorClass: string) {
    this._anchors = document.getElementsByClassName(anchorClass);
    this._body = document.body;
  }

  renderElements(elements: T[]): OnboardingDOMElement[] {
    const rendered: OnboardingDOMElement[] = [];
    elements
      .filter((element: T, index: number) => !!this.anchors.item(index))
      .forEach((element: T, index: number) => {
        const [renderedElement, frontLayerElement] = this._renderElement(element, this.anchors.item(index)!);
        rendered.push({
          parent: this.anchors.item(index)!,
          child: renderedElement,
        });
        rendered.push({ parent: this.container, child: frontLayerElement });
      });
    return rendered;
  }

  protected abstract _renderElement(element: T, anchor: Element): [Element, Element];
}
