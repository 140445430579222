import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Injectable, Injector } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { FilterListSubHeaderComponent } from '@shared/components/filter-list-sub-header/filter-list-sub-header.component';

@Injectable({
  providedIn: 'root',
})
export class BaseFiltersService {
  readonly injector!: Injector;
  readonly dialogService!: TuiDialogService;

  constructor(injector: Injector, dialogService: TuiDialogService) {
    this.injector = injector;
    this.dialogService = dialogService;
  }

  showAsDialog() {
    return this.dialogService
      .open(new PolymorpheusComponent(FilterListSubHeaderComponent, this.injector), {
        dismissible: true,
        closeable: false,
      })
      .subscribe();
  }
}
