<div class="flex flex-col justify-start items-center">
  <h3 class="text-center tui-text-03 font-semibold tui-text_h5 max-w-md">
    {{ 'Funnels.Graphics.Welcome banner header' | translate }}
  </h3>

  <p class="mt-2 tui-text-02">
    {{ 'Funnels.Graphics.Generating graphics may take up to 20 seconds.' | translate }}
  </p>

  <div class="w-full mt-9">
    <swiper
      [config]="swiperConfig"
      [spaceBetween]="24"
      class="w-full pb-12"
    >
      <ng-template
        swiperSlide
        *ngFor="let item of graphics"
      >
        <df-graphics-generator-item-tile
          [data]="item"
          class="w-full"
          size="m"
        ></df-graphics-generator-item-tile>
      </ng-template>
    </swiper>
  </div>

  <div class="mt-12 flex justify-center items-stretch gap-x-2">
    <button
      tuiButton
      appearance="outline"
      type="button"
      size="l"
      (click)="skip()"
    >
      <span class="font-medium">{{ 'skip' | translate }}</span>
    </button>
    <button
      tuiButton
      appearance="accent"
      type="button"
      size="l"
      (click)="downloadAll()"
      [disabled]="!!(loadingDownload$ | async)"
      [showLoader]="!!(loadingDownload$ | async)"
    >
      <svg-icon
        [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
        class="mr-2"
        [src]="'/assets/img/ic-download.svg'"
      ></svg-icon>
      {{ 'Funnels.Graphics.download graphics' | translate }}
    </button>
  </div>
</div>
