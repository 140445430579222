<button
  class="absolute top-4 right-11"
  size="m"
  tuiIconButton
  type="button"
  appearance="outline"
  (click)="closeModal()"
  [icon]="closeIcon"
>
  <ng-template #closeIcon>
    <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
  </ng-template>
</button>

<div class="wrapper flex flex-col py-[3.18rem] px-11">
  <h1 class="heading tui-text-03">
    {{ 'Funnels.Graphics.Choose a graphic style that suits to your business' | translate }}
  </h1>

  <ng-container [formGroup]="form">
    <div class="styles-list flex flex-wrap overflow-y-auto justify-center w-full">
      <div
        *ngFor="let style of stylesList"
        class="flex py-3 px-1.5"
      >
        <!-- style item -->
        <tui-island class="style-island relative flex flex-col items-center">
          <div class="tui-text_body-m-9 !font-semibold tui-text-03 mt-1 mb-3.5">
            {{ style.label }}
          </div>
          <div class="style-island__graphic w-64 h-64 flex justify-items-center items-center">
            <img
              class="object-contain"
              [src]="style.photo"
              [alt]="style.photo"
            />
          </div>

          <tui-radio-labeled
            [formControlName]="EContentGeneratorStylesForm.STYLE"
            [item]="style.value"
            size="l"
            class="absolute top-3.5 right-1"
          ></tui-radio-labeled>
        </tui-island>
      </div>
    </div>
    <!-- button -->
    <div class="flex justify-center mt-6">
      <button
        tuiButton
        appearance="primary"
        (click)="saveSelection()"
      >
        {{ 'Funnels.Graphics.Save selection' | translate }}
      </button>
    </div>
  </ng-container>
</div>
