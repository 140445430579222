import { gql } from 'apollo-angular';

export const GetDashboardsDocument = gql`
  query getDashboards($funnelId: Int!) {
    getDashboards(funnelId: $funnelId) {
      id
      name
      type
      link
    }
  }
`;
