import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { TuiDialog } from '@taiga-ui/cdk';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { StatisticReport } from '../../models/funnel-statistics.interface';

@Component({
  selector: 'df-funnel-statistics-dashboard-iframe',
  templateUrl: './funnel-statistics-dashboard-iframe.component.html',
  styleUrls: ['./funnel-statistics-dashboard-iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelStatisticsDashboardIframeComponent implements OnInit {
  statisticReport: StatisticReport | null = null;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { report: StatisticReport } }, boolean>,
    private changes: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.context.data?.report) {
      this.statisticReport = this.context.data?.report;
      this.changes.detectChanges();
    }
  }
}
