import { AfterViewInit, Component } from '@angular/core';
import { SemrushService } from '@shared/services/semrush.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { AuthOutputGraphql } from '@modules/graphql/graphql-types';
import { FetchResult } from '@apollo/client/core';
import { RegisterByPartnerMutation } from '@shared/graphql/mutations/register-by-partner.mutation.generated';
import { AuthController } from '@modules/authorization/shared/controllers/auth.controller';
import { NavigateService } from '@core/routes/services/navigate.service';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';
import { ActivatedRoute } from '@angular/router';
import { inIframe } from '@shared/helpers/in-iframe.helper';
import { sleep } from '@shared/helpers/sleep.helper';

@Component({
  selector: 'df-semrush',
  templateUrl: './semrush.component.html',
  styleUrls: ['./semrush.component.scss'],
})
export class SemrushComponent implements AfterViewInit {
  authController!: AuthController;
  showInfo = false;

  constructor(
    private semrushService: SemrushService,
    private s: SnackbarService,
    private n: NavigateService,
    private eventService: EventsService,
    private route: ActivatedRoute,
  ) {
    this.authController = new AuthController();
  }

  async ngAfterViewInit() {
    await sleep(500);
    document?.querySelector('.intercom-lightweight-app')?.classList?.add('hidden');
    const token = this.route.snapshot.queryParams['jwt'];

    if (!token || !inIframe()) {
      this.showInfo = true;
      return;
    }

    await this.loginClient();
  }

  async loginClient() {
    const observable = await this.semrushService.loginClient();

    observable.subscribe({
      next: this.successSignUp.bind(this),
      error: this.errorSignUp.bind(this),
    });
  }

  successSignUp(res: FetchResult<RegisterByPartnerMutation>) {
    let hasRegisterAlready = false;

    if (res.data) {
      const auth = res.data['registerByPartner'] as AuthOutputGraphql;
      hasRegisterAlready = auth.eventType !== 'sign-up';
      this.authController.saveAuthData(auth);

      this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.Signin, {
        userID: auth.user!.id,
        email: auth.user!.email,
      });
    }

    if (!hasRegisterAlready) {
      this.n.go('statement');
    } else {
      this.n.go('home');
    }
  }

  errorSignUp() {
    this.s.defaultError();
  }
}
