import { AppInjector } from '@core/services/app-injector.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { TuiDialogService } from '@taiga-ui/core';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { PermissionType } from '@modules/graphql/graphql-types';
import { UserService } from '@shared/services/user.service';
import { FetchResult } from '@apollo/client/core';
import { CloneFunnelMutation } from '@modules/funnels/shared/graphql/mutations/clone-funnel.mutation.generated';
import { Funnel } from '@shared/models/funnel.model';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { map, switchMap } from 'rxjs/operators';
import { CopyFunnelContentMutation } from '@modules/funnels/shared/graphql/mutations/copy-funnel-content.mutation.generated';

export class FunnelController {
  n: NavigateService;
  t: TranslateService;
  pricingService: PricingService;
  dialogService: TuiDialogService;
  injector: Injector;
  userService: UserService;
  funnelGraphqlService: FunnelGraphqlService;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.n = this.injector.get(NavigateService);
    this.t = this.injector.get(TranslateService);
    this.pricingService = this.injector.get(PricingService);
    this.dialogService = this.injector.get(TuiDialogService);
    this.userService = this.injector.get(UserService);
    this.funnelGraphqlService = this.injector.get(FunnelGraphqlService);
  }

  createFunnel(): void {
    this.pricingService
      .checkPricing(PermissionType.FunnelManagement, this.userService.User!.funnelsCount + 1)
      .subscribe({
        next: () => {
          this.n.go('funnels/settings/create');
        },
      });
  }

  copyFunnel(funnel: Funnel): Observable<boolean> {
    return this.pricingService
      .checkPricing(PermissionType.FunnelManagement, this.userService.User!.funnelsCount + 1)
      .pipe(
        switchMap((res) => (res ? this.funnelGraphqlService.cloneFunnel(funnel!.id) : of(false))),
        map((res: FetchResult<CloneFunnelMutation> | boolean) => {
          if (typeof res === 'boolean') {
            return false;
          }
          const user = this.userService.User!;
          user.funnelsCount += 1;
          this.userService.User = user;
          this.n.go('funnels/f/d/:id/manage', { id: res.data?.cloneFunnel.id }, { queryParams: { toEdit: true } });
          return true;
        }),
      );
  }

  copyFunnelContent(funnelToCopyId: number): Observable<boolean> {
    return this.pricingService.checkPricing(PermissionType.FunnelManagement, this.userService.User!.funnelsCount).pipe(
      switchMap((res) =>
        res
          ? this.funnelGraphqlService.copyFunnelContent(this.userService.User!.contextFunnel!.id, funnelToCopyId)
          : of(false),
      ),
      map((res: FetchResult<CopyFunnelContentMutation> | boolean) => {
        if (typeof res === 'boolean') {
          return false;
        }
        this.n.go('funnels/f/d/:id/manage', {
          id: res.data?.copyFunnelContent.id,
        });
        return true;
      }),
    );
  }
}
