/* eslint-disable @angular-eslint/no-input-rename */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EActivityForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-form.enum';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'df-tactic-settings-activity',
  templateUrl: './tactic-settings-activity.component.html',
  styleUrls: ['./tactic-settings-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticSettingsActivityComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly EActivityForm = EActivityForm;
  open = false;
  @Input() index = 1;
  @Input('activity') activityControl: UntypedFormGroup | null = null;
  @Input() disabled = false;

  constructor(
    private readonly changes: ChangeDetectorRef,
    private readonly tacticSettingsService: TacticSettingsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenFormChanges();
    this.listenActivityChanges();
    this.listenOpenedChanges();
  }

  private listenFormChanges(): void {
    this.sub.add(this.tacticSettingsService.changesInForm$.subscribe(() => this.changes.detectChanges()));
  }

  private listenActivityChanges(): void {
    this.listenFormFieldChange(this.activityControl!.get(EActivityForm.name) as UntypedFormControl);
    this.listenFormFieldChange(this.activityControl!.get(EActivityForm.description) as UntypedFormControl);
    this.listenFormFieldChange(this.activityControl!.get(EActivityForm.copy) as UntypedFormControl);
    this.listenFormFieldChange(this.activityControl!.get(EActivityForm.typeId) as UntypedFormControl);
  }

  private listenOpenedChanges(): void {
    this.sub.add(
      this.activityControl?.get(EActivityForm.opened)?.valueChanges.subscribe((val: boolean) => {
        this.open = val;
        this.changes.detectChanges();
      }),
    );
  }

  private listenFormFieldChange(formControl: UntypedFormControl): void {
    this.sub.add(
      formControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        this.tacticSettingsService.editActivity(this.activityControl!);
        this.changes.detectChanges();
      }),
    );
  }

  toggleAccordionItem(state?: boolean) {
    if (!this.disabled) {
      this.open = state === undefined ? !this.open : state;
      this.changes.detectChanges();
    }
  }
}
