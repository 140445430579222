<span
  *ngIf="pricePermission.planPermissions[plan.id]['hasLimit']"
  class="tui-primary font-normal"
>
  <span *ngIf="pricePermission.planPermissions[plan.id]['limit']">
    {{ 'Pricing.max' | translate }}
    {{ pricePermission.planPermissions[plan.id]['limit'] }}
  </span>
  <span *ngIf="!pricePermission.planPermissions[plan.id]['limit']">
    {{ 'Pricing.no limit' | translate }}
  </span>
</span>
