import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { Funnel } from '@shared/models/funnel.model';
import { UserService } from '@shared/services/user.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { FunnelController } from '@modules/funnels/shared/controllers/funnel.controller';

@Component({
  selector: 'df-funnel-add-to-profile-modal',
  templateUrl: './funnel-add-to-profile-modal.component.html',
  styleUrls: ['./funnel-add-to-profile-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelAddToProfileModalComponent {
  get loadingClone(): boolean {
    return this._loadingClone;
  }

  set loadingClone(value: boolean) {
    this._loadingClone = value;
    this.changes.detectChanges();
  }

  get loadingCopy(): boolean {
    return this._loadingCopy;
  }

  set loadingCopy(value: boolean) {
    this._loadingCopy = value;
    this.changes.detectChanges();
  }

  private _loadingCopy = false;
  private _loadingClone = false;
  private funnelController: FunnelController = new FunnelController();

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { funnel: Funnel } }, number>,
    public userService: UserService,
    private s: SnackbarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
  ) {}

  get funnel(): Funnel {
    return this.context.data.funnel;
  }

  cancel(): void {
    this.context.completeWith(0);
  }

  copyContent(): void {
    this.loadingCopy = true;
    this.funnelController
      .copyFunnelContent(this.funnel.id)
      .pipe(finalize(() => (this.loadingCopy = false)))
      .subscribe({
        next: (res: boolean) => {
          this.context.completeWith(0);
          if (res) {
            this.s.success(this.t.instant('Funnels.Manage.Clone funnel content success'));
          }
        },
        error: () => {
          this.s.error(this.t.instant('Funnels.Manage.Clone funnel content error'));
        },
        complete: () => this.context.completeWith(0),
      });
  }

  clone(): void {
    this.loadingClone = true;
    this.funnelController
      .copyFunnel(this.funnel)
      .pipe(finalize(() => (this.loadingClone = false)))
      .subscribe({
        next: (res: boolean) => {
          this.context.completeWith(0);
          if (res) {
            this.s.success(this.t.instant('Funnels.Manage.Add to profile success'));
          }
        },
        error: () => {
          this.s.error(this.t.instant('Funnels.Manage.Add to profile error'));
        },
        complete: () => this.context.completeWith(0),
      });
  }
}
