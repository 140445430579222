import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'df-funnel-overview-help-item',
  templateUrl: './funnel-overview-help-item.component.html',
  styleUrls: ['./funnel-overview-help-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelOverviewHelpItemComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() icon = '';
  @Output() onRedirect: EventEmitter<boolean> = new EventEmitter<boolean>();
}
