import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { FetchResult } from '@apollo/client/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Activity } from '@shared/models/activity.model';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { FunnelContentGeneratorService } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/services/funnel-content-generator.service';
import { ContentGeneratorEventEnum } from '@modules/funnels/shared/enums/content-generator-selection-type.enum';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { GetFunnelTacticQuery } from '@modules/funnels/shared/graphql/queries/get-funnel-tactic.query.generated';
import { filter, map, pluck, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentGeneratorRouteParam } from '@modules/funnels/shared/enums/content-generator-route-param.enum';
import { Subscription } from 'rxjs';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { ContentGeneratorEventService } from '@modules/funnels/modules/funnel-manage/shared/services/content-generator-event.service';

@Component({
  selector: 'df-content-generator-tactic',
  templateUrl: './content-generator-tactic.component.html',
  styleUrls: ['./content-generator-tactic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentGeneratorTacticComponent extends AbstractSubscriptionComponent implements OnInit, OnDestroy {
  readonly EOnboardingStep = EOnboardingStep;
  loading = false;
  highlighted: number | null = null;

  paramsSub: Subscription = new Subscription();

  constructor(
    private readonly funnelGraphqlService: FunnelGraphqlService,
    private readonly s: SnackbarService,
    private readonly changes: ChangeDetectorRef,
    public readonly n: NavigateService,
    public readonly funnelContentGeneratorService: FunnelContentGeneratorService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly onboardingService: OnboardingService,
    private readonly contentGeneratorEventService: ContentGeneratorEventService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenSelectionChange();
    this.listenOnboardingStepChange();
  }

  private listenSelectionChange(): void {
    this.sub.add(
      this.contentGeneratorEventService
        .getSubscription()
        .pipe(
          tap(() => this.changes.detectChanges()),
          filter((types: ContentGeneratorEventEnum[]) => types.includes(ContentGeneratorEventEnum.TACTIC_CHANGE)),
        )
        .subscribe(() => {
          this.highlighted = null;
          if (this.funnelContentGeneratorService.selectedTactic) {
            this.loading = true;
            this.changes.detectChanges();
            this.fetchTacticData();
          }
        }),
    );
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  private fetchTacticData(): void {
    this.funnelGraphqlService.getFunnelTactic(this.funnelContentGeneratorService.selectedTactic!.id).subscribe(
      (res: FetchResult<GetFunnelTacticQuery>) => {
        const tactic = new FunnelTactic(res.data?.getFunnelTactic);
        this.loading = false;
        this.funnelContentGeneratorService.selectedTactic = tactic;
        this.changes.detectChanges();
        this.listenToActivityParamChanges();
        this.onboardingService.loading = false;
      },
      () => this.s.defaultError(),
    );
  }

  selectActivity(activity: Activity): void {
    this.router.navigate(['.'], {
      queryParams: {
        [ContentGeneratorRouteParam.TACTIC]: this.funnelContentGeneratorService.selectedTactic?.id,
        [ContentGeneratorRouteParam.ACTIVITY]: activity.id,
      },
      relativeTo: this.route,
    });
  }

  private highlightActivity(activity: Activity): void {
    this.highlighted = activity.id;
    this.funnelContentGeneratorService.selectActivity(activity);
    this.changes.detectChanges();
  }

  private listenToActivityParamChanges(): void {
    this.paramsSub.unsubscribe();
    this.paramsSub = this.route.queryParams
      .pipe(
        pluck(ContentGeneratorRouteParam.ACTIVITY),
        filter((activityId) => !!activityId && this.funnelContentGeneratorService.selectedActivity?.id !== +activityId),
        map((activityId: number) =>
          this.funnelContentGeneratorService.selectedTactic?.tactic.activities.find((a) => a.id === +activityId),
        ),
        filter((activity) => !!activity),
      )
      .subscribe((activity) => this.highlightActivity(activity!));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.paramsSub.unsubscribe();
  }
}
