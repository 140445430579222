import { OnboardingAccentLabel } from '@shared/models/onboarding-step.model';
import { AbstractOnboardingFactory } from '@shared/abstracts/onboarding-factory.abstract';

export class OnboardingAccentLabelFactory extends AbstractOnboardingFactory<OnboardingAccentLabel> {
  renderElement(element: OnboardingAccentLabel): Element {
    const label = document.createElement('div');
    label.innerHTML = `<p>${element.text}</p>`;
    label.classList.add('onboarding-accent__label');

    switch (element.position) {
      case 'bottom':
      case 'below':
      case 'above':
        label.style.top = Math.abs(this.containerRect.top - this.anchorRect.top) - 64 + 'px';
        break;
      case 'top':
      default:
        label.style.top = Math.abs(this.containerRect.top - this.anchorRect.top) - 32 + 'px';
        break;
    }

    switch (element.rotate) {
      case 'right':
        label.style.animation = 'popInRight 400ms';
        label.style.transform = 'rotate(4deg)';
        break;
      case 'left':
      default:
        label.style.animation = 'popInLeft 400ms';
        label.style.transform = 'rotate(-4deg)';
        break;
    }

    element.maxWidth ? (label.style.maxWidth = element.maxWidth) : null;

    this.container.appendChild(label);
    label.style.left =
      Math.abs(this.containerRect.left - this.anchorRect.left) +
      this.anchorRect.width * 0.5 -
      label.offsetWidth * 0.5 +
      'px';
    return label;
  }
}
