import { gql } from 'apollo-angular';
import { TeamFragment } from '@modules/user-settings/modules/teams/shared/graphql/fragments/team.fragment';

export const MyTeamsDocument = gql`
  query myTeams {
    myTeams {
      ...TeamFragment
    }
  }
  ${TeamFragment}
`;
