import { gql } from 'apollo-angular';
import { BenchmarkFragment } from '@modules/tactics/shared/graphql/fragments/benchmark.fragment';

export const EditBenchmarkDocument = gql/* GraphQL*/ `
  mutation editBenchmark($input: EditBenchmarksInputGraphql!, $file: Upload) {
    editBenchmark(input: $input, file: $file) {
      ...BenchmarkFragment
    }
  }
  ${BenchmarkFragment}
`;
