import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StatementFormService } from '../shared/services/statement-form.service';
import { StatementService } from '../shared/services/statement.service';

@Injectable()
export class StatementNextStepInOnboardingGuard implements CanActivate {
  constructor(
    private readonly statementFormService: StatementFormService,
    private readonly statementService: StatementService,
    private readonly router: Router,
  ) {}

  canActivate() {
    if (this.statementFormService.isFormReady) return true;
    return this.router.navigate(['', { outlets: { statement: 'statement/2' } }], {
      skipLocationChange: !!this.statementService.startPreviousUrl,
    });
  }
}
