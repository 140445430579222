import { gql } from 'apollo-angular';

export const SearchTacticsDocument = gql(/* GraphQL*/ `query searchTactics($query: TacticListInputGraphql!)
{
  searchTactics(query: $query)
  {
    records
    {
      id
      name
      target
      isBanner
      isTopRated
      imageUrl
      currentUserPermission
      isFavourite
      activities {
        customPrompts {
          id
        }
      }
      levelOfAdvancement
      {
        id
        name
      }
      funnelSteps
      {
        name
        id
        type
      }
      owner
      {
        id
        firstName
        lastName
        avatar
        {
          file
        }
      }
      rate
      myRate
      {
        rate
      }
      isPrivate
      isPaid
      price
      currency
      purchased
      root
      {
        id
        isPaid
        price
        currency
        owner
        {
          id
          firstName
          lastName
          avatar
          {
            file
          }
        }
      }
      verificationStatus
    }
    count
    page
    perPage
  }
}

`);
