import { PermissionsConfig } from '../interfaces/permissions-config';
import { PermissionsAction } from '@core/permissions/interfaces/permissions-action.interface';
import { PermissionType } from '@core/permissions/enums/permission-type.enum';
import { PermissionsGroups } from '@core/permissions/permissions.group';

export class TacticsPermissionsConfig implements PermissionsConfig {
  static readonly EDIT: (PermissionsAction | string)[] = [
    {
      type: PermissionType.OBJECT_CREATOR,
    },
  ];

  static readonly SHARE: (PermissionsAction | string)[] = [
    {
      type: PermissionType.OBJECT_CREATOR,
    },
  ];

  static readonly REMOVE: (PermissionsAction | string)[] = [
    {
      type: PermissionType.OBJECT_CREATOR,
    },
  ];

  static readonly RATE: (PermissionsAction | string)[] = [
    {
      type: PermissionType.OBJECT_CREATOR,
    },
  ];

  // static readonly PREVIEW: (PermissionsAction | string)[] = [];

  static readonly type: PermissionsGroups = PermissionsGroups.TACTICS;
}
