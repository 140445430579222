<div
  class="tabs-container"
  *ngIf="graphicsGeneratorService.selectedTab$ | async"
>
  <tui-tabs [(activeItemIndex)]="activeItemIndex">
    <button
      tuiTab
      *ngFor="let tab of graphicsGeneratorService.tabs"
      (click)="onTabSelect(tab)"
      class="tab"
      [disabled]="!!(graphicsGeneratorService.loading$ | async)"
    >
      {{ tab.name | translate }}
    </button>
  </tui-tabs>
</div>
