import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const MeDocument = gql`
  query me {
    me {
      successPlanPaid
      ...UserStorage
    }
  }
  ${UserStorageFragment}
`;
