import { gql } from 'apollo-angular';
import { EditTacticFragment } from '@modules/tactics/shared/graphql/fragments/edit-tactic.fragment';

export const EditTacticDocument = gql/* GraphQL*/ `
  mutation editTactic($input: TacticInputGraphql!, $id: Float!) {
    editTactic(input: $input, id: $id) {
      ...EditTactic
    }
  }
  ${EditTacticFragment}
`;
