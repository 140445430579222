import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TuiRootModule } from '@taiga-ui/core';
import { MultiTranslateHttpLoader } from '@core/services/multi-translate-http-loader.service';
import { LANG_FILES } from '@shared/configs/lang-files';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { appInitializerFactory } from './app-initializer.factory';
import { LanguageService } from '@core/services/language.service';

import { SharedModule } from '@shared/shared.module';
import { AppInjector } from '@core/services/app-injector.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from '@modules/graphql/graphql.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@environments/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { Config } from '@shared/configs/config';
import {
  FacebookLoginProvider,
  GoogleInitOptions,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { NotificationsModule } from '@modules/notifications/notifications.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PROMPT_PROVIDER } from '@modules/tactics/shared/components/content-access-dialog/content-access-dialog.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ApolloModule } from 'apollo-angular';
import { MarketingCampaignModule } from '@modules/marketing-campaign/marketing-campaign.module';
import { MixpanelService } from '@shared/services/mixpanel.service';
import { SocketIoModule } from 'ngx-socket-io';

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, LANG_FILES);
}

const googleLoginOptions: GoogleInitOptions = {
  oneTapEnabled: location.href.includes('sign-in') || location.href.includes('sign-up'),
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TuiRootModule,
    ApolloModule,
    AngularSvgIconModule.forRoot(),
    SharedModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GoogleTagManagerModule.forRoot({
      id: Config.GTAG,
    }),
    GraphQLModule,
    SocialLoginModule,
    NotificationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SocketIoModule.forRoot({
      url: environment.WEBSOCKET_URL,
      options: {
        autoConnect: true,
        withCredentials: false,
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (lang: LanguageService) => {
        return lang.getCurrentLanguage();
      },
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID, googleLoginOptions),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FACEBOOK_CLIENT_ID),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    MixpanelService,
    NavigateService,
    CookieService,
    PROMPT_PROVIDER,
    MarketingCampaignModule,
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
