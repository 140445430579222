import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const AddUserSkillDocument = gql`
  mutation addUserSkill($skillId: Int!) {
    addUserSkill(skillId: $skillId) {
      ...UserStorage
    }
  }
  ${UserStorageFragment}
`;
