import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const SetAffiliationIdDocument = gql`
  mutation setAffiliationId($affiliationId: String!) {
    setAffiliationId(affiliationId: $affiliationId) {
      ...UserStorage
    }
  }
  ${UserStorageFragment}
`;
