export interface IContainerTab {
  name: string;
  index: number;
  type: EContainerTabType;
  sectionTitle?: string;
  invalid?: boolean;
}

export enum EContainerTabType {
  SHARE = 'share',
  TEAM = 'team',
  ABOUT = 'about',
  ACTIVITIES = 'activities',
  METRICS = 'metrics',
}
