import { gql } from 'apollo-angular';

export const CreateFlowDocument = gql`
  mutation createFlow($input: FlowInputGraphql!, $funnelId: Int!) {
    createFlow(input: $input, funnelId: $funnelId) {
      id
      name
      items {
        from
        to
      }
    }
  }
`;
