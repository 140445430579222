<ng-container *ngIf="isTabResolved">
  <div class="switch-container">
    <div class="switch-tabs">
      <tui-tabs [(activeItemIndex)]="selectedTab.index">
        <div
          tuiTab
          class="switch-tab-wrapper"
          (click)="setPricing(tab.interval); setTab(tab)"
          *ngFor="let tab of PAYMENT_PERIOD_TABS"
        >
          <div class="switch-tab">
            <span class="tui-text_body-m-10 tab-name">
              {{ tab.name | translate }}
            </span>
            <span
              *ngIf="!!tab.discount"
              class="discount-badge"
              [class.active]="selectedTab.interval === tab.interval"
            >
              {{ tab.discount!.name | translate }}
            </span>
          </div>
        </div>
      </tui-tabs>
    </div>
  </div>
</ng-container>
