import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { PaidTacticValidationService } from '@modules/tactics/modules/tactic-settings/shared/services/paid-tactic-validation.service';
import { TacticSettingsService } from '@modules/tactics/modules/tactic-settings/shared/services/tactic-settings.service';
import { Activity } from '@shared/models/activity.model';
import { UntypedFormGroup } from '@angular/forms';
import { EActivityForm } from '@modules/tactics/modules/tactic-settings/shared/enums/tactic-activity-form.enum';

@Component({
  selector: 'df-activity-form-actions',
  templateUrl: './activity-form-actions.component.html',
  styleUrls: ['./activity-form-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityFormActionsComponent {
  @Input() activityControl: UntypedFormGroup | null = null;
  @Input() disabled = false;

  constructor(
    private t: TranslateService,
    private s: SnackbarService,
    private changes: ChangeDetectorRef,
    private paidTacticValidationService: PaidTacticValidationService,
    private readonly tacticSettingsService: TacticSettingsService,
  ) {}

  remove() {
    if (this.tacticSettingsService.tactic?.isPaid) {
      const activities: Activity[] = [
        ...this.tacticSettingsService.tactic!.activities.filter(
          (a) => a.id !== this.activityControl?.get(EActivityForm.id)?.value,
        ),
      ];
      if (activities.length <= 0 || !this.paidTacticValidationService.validateActivities(activities)) {
        this.s.error(this.t.instant('Tactics.Manage.You cannot remove this activity.'));
        return;
      }
    }
    this.tacticSettingsService.removeActivity(this.activityControl!);
    this.changes.detectChanges();
  }

  restore() {
    this.tacticSettingsService.restoreActivity(this.activityControl!);
    this.changes.detectChanges();
  }

  clone() {
    this.tacticSettingsService.cloneActivity(this.activityControl!);
    this.changes.detectChanges();
  }
}
