import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { UserService } from '@shared/services/user.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { AppInjector } from '@core/services/app-injector.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { AuthOutputGraphql } from '@modules/graphql/graphql-types';
import { Config } from '@shared/configs/config';
import { User } from '@shared/models/user.model';
import { AffiliationService } from '@shared/services/affiliation.service';

export class AuthController {
  s!: SnackbarService;
  t!: TranslateService;
  authGraphQLService!: AuthGraphqlService;
  userService!: UserService;
  authService!: AuthStorageService;
  recaptchaV3Service?: ReCaptchaV3Service;
  affiliationService!: AffiliationService;

  constructor(recaptchaService?: ReCaptchaV3Service) {
    const injector = AppInjector.getInjector();
    this.s = injector.get(SnackbarService);
    this.t = injector.get(TranslateService);
    this.authGraphQLService = injector.get(AuthGraphqlService);
    this.userService = injector.get(UserService);
    this.authService = injector.get(AuthStorageService);
    this.recaptchaV3Service = recaptchaService;
    this.affiliationService = injector.get(AffiliationService);
  }

  getCaptcha(): Observable<string | null> {
    return new Observable((observer) => {
      if (Config.DEBUG) {
        observer.next('token');
        observer.complete();
        return;
      }
      this.captchaValidation()?.then((token?: string) => {
        if (!token?.length) {
          this.s?.error(this.t?.instant('Validation.Wrong captcha validation'));
          observer.next(null);
          observer.complete();
        }
        observer.next(token);
        observer.complete();
      });
    });
  }

  saveAuthData(data: AuthOutputGraphql) {
    if (this.authService && this.userService) {
      this.authService.jwt = data.token as string;
      this.userService.User = data.user as User;
      this.affiliationService.saveUserPromoter();
    }
  }

  captchaValidation() {
    return this.recaptchaV3Service ? this.recaptchaV3Service.execute('signUp').toPromise() : null;
  }
}
