import { Component, OnInit, ChangeDetectionStrategy, Injector, Inject } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user.service';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { StatisticReport } from '../../models/funnel-statistics.interface';
import { FunnelStatisticsService } from '../../services/funnel-statistics.service';
import { FunnelStatisticsDashboardIframeComponent } from '../funnel-statistics-dashboard-iframe/funnel-statistics-dashboard-iframe.component';

@Component({
  selector: 'df-funnel-statistics-report-list',
  templateUrl: './funnel-statistics-report-list.component.html',
  styleUrls: ['./funnel-statistics-report-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelStatisticsReportListComponent implements OnInit {
  statisticReports: StatisticReport[] = [];
  funnelId: number;

  constructor(
    public funnelStatisticsService: FunnelStatisticsService,
    private navigateService: NavigateService,
    private userService: UserService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private translateService: TranslateService,
  ) {
    this.funnelId = this.userService.User?.contextFunnel.id ?? 0;
  }

  ngOnInit(): void {
    this.fetchDashboards();
  }

  private fetchDashboards(): void {
    this.funnelStatisticsService.fetchDashboards(this.funnelId).subscribe({
      next: (response) => {
        this.statisticReports = response;
        this.finishFetchingDashboards();
      },
      error: () => this.finishFetchingDashboards(),
    });
  }

  private finishFetchingDashboards(): void {
    this.funnelStatisticsService.setLoader(false);
    if (!this.statisticReports || !this.statisticReports.length) this.onNavigateToForm();
  }

  public onNavigateToForm(): void {
    this.navigateService.go('/funnels/f/d/:id/statistics/create-new-report', {
      id: this.funnelId,
    });
  }

  public onOpenIframe(report: StatisticReport): void {
    this.dialogService
      .open<number>(new PolymorpheusComponent(FunnelStatisticsDashboardIframeComponent, this.injector), {
        size: 'page',
        dismissible: false,
        closeable: true,
        label: this.translateService.instant('Funnels.Statistics.Statistics'),
        data: {
          report: report,
        },
      })
      .subscribe();
  }
}
