import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'df-add-remove-link-button',
  templateUrl: './add-remove-link-button.component.html',
  styleUrls: ['./add-remove-link-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddRemoveLinkButtonComponent {
  @Input() count = 0;
  @Input() loading = false;
  @Input() disabled = true;

  @Output() onAdd: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onRemove: EventEmitter<boolean> = new EventEmitter<boolean>();

  remove(): void {
    if (!this.disabled) {
      this.onRemove.emit(true);
    }
  }

  add(): void {
    if (!this.disabled) {
      this.onAdd.emit(true);
    }
  }
}
