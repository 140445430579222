import { Component, Input } from '@angular/core';

@Component({
  selector: 'df-statement-placeholder',
  templateUrl: './statement-placeholder.component.html',
  styleUrls: ['./statement-placeholder.component.scss'],
})
export class StatementPlaceholderComponent {
  @Input() name = '';
}
