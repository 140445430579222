import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const RemoveUserSkillDocument = gql`
  mutation removeUserSkill($skillId: Int!) {
    removeUserSkill(skillId: $skillId) {
      ...UserStorage
    }
  }
  ${UserStorageFragment}
`;
