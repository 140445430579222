import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { XPartnerService } from '@shared/services/x-partner.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Config } from '@shared/configs/config';

import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamController } from '@modules/user-settings/modules/teams/shared/controllers/team.controller';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { Regex } from '@shared/configs/regex';
import { TeamsService } from '@modules/user-settings/modules/teams/shared/services/teams.service';
import { rejectedImages } from '@shared/helpers/file-input.helper';
import { TuiFileLike } from '@taiga-ui/kit';

@Component({
  selector: 'df-no-team',
  templateUrl: './no-team.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoTeamComponent implements OnInit {
  Config = Config;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern(Regex.whiteSpace),
    ]),
    icon: new UntypedFormControl(null),
  });

  teamController: TeamController = new TeamController();
  loading = false;

  constructor(
    public xPartnerService: XPartnerService,
    private changes: ChangeDetectorRef,
    private s: SnackbarService,
    private t: TranslateService,
    private n: NavigateService,
    private authStorageService: AuthStorageService,
    private teamsService: TeamsService,
  ) {}

  ngOnInit(): void {
    this.checkIfNoTeamIsNecessary();
  }

  private checkIfNoTeamIsNecessary() {
    if (!this.authStorageService.jwt || this.teamsService.teams.length) {
      this.n.go('sign-in');
      return false;
    }
    return true;
  }

  createTeam() {
    if (!this.formGroup.valid) return;
    this.loading = true;
    this.changes.detectChanges();
    this.teamController
      .createTeam(this.formGroup.value.name, this.formGroup.value.icon)
      .subscribe({
        next: () => {
          this.s.success(this.t.instant('Auth.NoTeam.TeamCreated'));
          this.n.go(Config.MAIN_VIEW);
        },
        error: () => {
          this.s.defaultError();
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  rejectedImages(e: TuiFileLike | readonly TuiFileLike[]) {
    rejectedImages(e, this.s, this.t);
    this.changes.detectChanges();
  }

  getControl(name: string) {
    return this.formGroup.get(name);
  }
}
