import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import {
  HelpItem,
  helpItemConst,
} from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-overview/components/funnel-overview-help/help-items.const';
import { NavigateService } from '@core/routes/services/navigate.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-funnel-overview-help',
  templateUrl: './funnel-overview-help.component.html',
  styleUrls: ['./funnel-overview-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelOverviewHelpComponent {
  helpItems: HelpItem[] = [];

  constructor(
    public readonly basePanelService: BasePanelService,
    public n: NavigateService,
    public userService: UserService,
  ) {
    this.helpItems = helpItemConst(this.basePanelService, this.n);
  }
}
