import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { CreateNewCampaningDocument } from '../graphql/mutations/create-new-campaning.mutation';
import { CreateMarketingCampaignInputGraphql } from '@modules/graphql/graphql-types';
import { CreateNewCampanignMutation } from '../graphql/mutations/create-new-campaning.mutation.generated';
import { GetCampaningQuery } from '../graphql/queries/get-campaning.query.generated';
import { GetCampaningDocument } from '../graphql/queries/get-campaning.query';
import { catchError, map, of } from 'rxjs';
import { GetDataByTypeDocument } from '../graphql/queries/get-data-by-type.query';
import { GetDataByTypeQuery } from '../graphql/queries/get-data-by-type.query.generated';
import { RegenerateMutation } from '../graphql/mutations/regenerate-campaign.mutation.generated';
import { RegenerateCampaignDocument } from '../graphql/mutations/regenerate-campaign.mutation';
import { ValidateURLDocument } from '../graphql/queries/validate-url.query';
import { ValidateUrlQuery } from '../graphql/queries/validate-url.query.generated';
import { AIStrategyGeneratePDFDocument } from '../graphql/queries/genetrate-pdf.query';
import { AiStrategyGeneratePdfQuery } from '../graphql/queries/genetrate-pdf.query.generated';

@Injectable({
  providedIn: 'root',
})
export class MarketingCampaignService {
  constructor(private apollo: Apollo) {}

  generatePDF(funnelId: number, logo: string) {
    return this.apollo.query<AiStrategyGeneratePdfQuery>({
      query: AIStrategyGeneratePDFDocument,
      variables: { funnelId, logo },
      fetchPolicy: 'no-cache',
    });
  }

  createNewCampaign(input: CreateMarketingCampaignInputGraphql) {
    return this.apollo
      .mutate<CreateNewCampanignMutation>({
        mutation: CreateNewCampaningDocument,
        variables: { input },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data?.createNewCampanign));
  }

  validateUrl(url: string) {
    return this.apollo
      .query<ValidateUrlQuery>({
        query: ValidateURLDocument,
        variables: { url },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data.validateURL));
  }

  regenerateCampaign(funnelId: number) {
    return this.apollo
      .mutate<RegenerateMutation>({
        mutation: RegenerateCampaignDocument,
        variables: { funnelId },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data?.regenerate));
  }

  getCampaign(funnelId: number) {
    return this.apollo
      .query<GetCampaningQuery>({
        query: GetCampaningDocument,
        variables: { funnelId },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data.getCampaning));
  }

  getDataByType(funnelId: number, type: string) {
    return this.apollo
      .query<GetDataByTypeQuery>({
        query: GetDataByTypeDocument,
        variables: { funnelId, type },
        fetchPolicy: 'no-cache',
      })

      .pipe(
        map((res) => res.data.getDataByType),
        catchError(() => of(null)),
      );
  }
}
