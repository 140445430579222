import { gql } from 'apollo-angular';

export const CopyActivitiesToTacticDocument =
  gql(/* GraphQL*/ `mutation copyActivitiesToTactic($dstTacticId: Int!, $srcTacticId: Int!) {
  copyActivitiesToTactic(dstTacticId: $dstTacticId, srcTacticId: $srcTacticId)
  {
    id
  }
}
`);
