<div class="flex flex-col justify-start items-center">
  <p class="text-center tui-text_body-m-3 tui-text-03 font-normal max-w-screen-sm">
    {{ 'Funnels.List.Do you really want to remove your funnel? You will loose all data.' | translate }}
  </p>
  <div class="flex justify-center items-center mt-8">
    <button
      data-test="funnel-delete-popup-cancel"
      size="l"
      tuiButton
      appearance="outline"
      type="button"
      (click)="close()"
      [disabled]="loading"
      class="mr-2"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      data-test="funnel-delete-popup-confirm"
      size="l"
      tuiButton
      appearance="flat"
      type="button"
      class="bg-red-100"
      (click)="confirm()"
      [disabled]="loading"
      [showLoader]="loading"
    >
      <span class="tui-accent-01">{{ 'confirm' | translate }}</span>
    </button>
  </div>
</div>
