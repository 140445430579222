import { gql } from 'apollo-angular';
import { FullTacticFragment } from '@modules/tactics/shared/graphql/fragments/full-tactic.fragment';

export const GetTacticDocument = gql/* GraphQL*/ `
  query getTactic($id: Int!) {
    getTactic(id: $id) {
      ...FullTactic
    }
  }
  ${FullTacticFragment}
`;
