import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import {
  EPermissionResourceStatus,
  ResourcePermission,
  ResourceTeam,
} from '@modules/funnels/modules/funnel-settings/shared/models/resource-team.model';
import { FunnelPermissionEnum, PermissionType } from '@modules/graphql/graphql-types';
import { UserService } from '@shared/services/user.service';
import { Config } from '@shared/configs/config';
import { ResourceTeamsService } from '@shared/modules/resource-teams/services/resource-teams.service';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';

@Component({
  selector: 'df-resource-teams-single-permission',
  templateUrl: './resource-teams-single-permission.component.html',
  styleUrls: ['./resource-teams-single-permission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceTeamsSinglePermissionComponent extends AbstractSubscriptionComponent implements OnInit {
  readonly EPermissionResourceStatus = EPermissionResourceStatus;
  readonly Config = Config;

  @Input() permis!: ResourcePermission;
  @Input() resourceTeam!: ResourceTeam;

  permissions: FunnelPermissionEnum[] = Object.values(FunnelPermissionEnum);
  loading = false;
  teamCollaborationAccess = false;

  constructor(
    public userService: UserService,
    public changes: ChangeDetectorRef,
    private resourceTeamsService: ResourceTeamsService,
    private pricingService: PricingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkPermission();
    this.listenChangesInControl();
    this.listenLoading();
  }

  private checkPermission(): void {
    this.teamCollaborationAccess = this.userService.User?.hasAccess(PermissionType.TeamCollaboration) ?? false;
    this.changes.detectChanges();
    if (!this.teamCollaborationAccess) {
      this.permis.permissionControl.disable();
    }
  }

  private listenLoading() {
    this.sub.add(
      this.resourceTeamsService.loading$.subscribe((val) => {
        this.loading = val;
        this.changes.detectChanges();
      }),
    );
  }

  private listenChangesInControl() {
    this.sub.add(
      this.permis.permissionControl.valueChanges.subscribe(() => {
        if (this.permis.status === EPermissionResourceStatus.ADDED) {
          this.permis.status = EPermissionResourceStatus.TO_EDIT;
        }
        this.resourceTeamsService._resourceChanges$.next(true);
      }),
    );
  }

  removeUserFromPermission(): void {
    this.pricingService.checkPricing(PermissionType.TeamCollaboration).subscribe(() => {
      if (this.permis.status === EPermissionResourceStatus.TO_INVITE) {
        this.resourceTeam.permissions = this.resourceTeam.permissions.filter((p) => p.id !== this.permis.id);
        this.resourceTeam.setAddedAsPermission();
      } else {
        this.permis.status = EPermissionResourceStatus.TO_REMOVE;
      }
      this.resourceTeamsService._resourceChanges$.next(true);
      this.changes.detectChanges();
    });
  }

  restoreUser(): void {
    this.permis.status = EPermissionResourceStatus.TO_EDIT;
    this.permis.permissionControl.setValue(FunnelPermissionEnum.ViewOnly, {
      emitEvent: false,
    });
    this.changes.detectChanges();
  }
}
