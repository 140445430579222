import { gql } from 'apollo-angular';

export const ResolveCustomPrompt = gql/* GraphQL*/ `
  query resolveCustomPrompt($resolvePromptInput: ResolvePromptInputGraphql!) {
    resolveCustomPrompt(resolvePromptInput: $resolvePromptInput) {
      output
      full_output
    }
  }
`;
