import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Step } from '@shared/models/step.model';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { StepOutputGraphql } from '@modules/graphql/graphql-types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'df-funnel-tactic-badges',
  templateUrl: './funnel-tactic-badges.component.html',
  styleUrls: ['./funnel-tactic-badges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelTacticBadgesComponent {
  @Input() steps: (Step | StepOutputGraphql)[] = [];
  @Input() appearance: 'outline' | 'flat' = 'flat';
  @Input() doneEffect = false;

  @Input() set funnelTactic(value: FunnelTactic | undefined) {
    this._funnelTactic = value;
    this.setTacticBadges();
  }

  get funnelTactic(): FunnelTactic | undefined {
    return this._funnelTactic;
  }

  private _funnelTactic?: FunnelTactic;
  badges: string[] = [];

  constructor(
    private readonly changes: ChangeDetectorRef,
    private readonly t: TranslateService,
  ) {}

  private setTacticBadges(): void {
    this.badges = [];
    if (this._funnelTactic?.tactic?.badge) {
      this.badges.push(this.t.instant('Funnels.Manage.Tactic badge.' + this._funnelTactic?.tactic?.badge));
    } else {
      if (this._funnelTactic?.tactic?.type) {
        this.badges.push(this._funnelTactic.tactic?.type.name);
      }
      if (this._funnelTactic?.tactic?.effects?.length) {
        this.badges.push(this._funnelTactic?.tactic.effects[0].name);
      }
    }
    this.changes.detectChanges();
  }
}
