import { gql } from 'apollo-angular';
import { UserStorageFragment } from '@shared/graphql/fragments/user-storage.fragment';

export const EditProfileDocument = gql`
  mutation editProfile($input: UserProfileInputGraphql!) {
    editProfile(input: $input) {
      ...UserStorage
      experienceYears
      country
      website
      description
      discordName
    }
  }
  ${UserStorageFragment}
`;
