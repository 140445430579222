<p
  class="w-full text-center mt-8 tui-text_body-m-4 tui-text-02"
  [innerHTML]="'Funnels.Manage.If you turn off edit mode, then you will loose your changes. Are you sure?' | translate"
></p>

<div class="flex items-center mt-8 justify-end">
  <button
    size="xl"
    tuiButton
    type="button"
    class="mr-4"
    appearance="outline"
    (click)="cancel()"
  >
    <svg-icon
      [src]="'/assets/img/ic-close.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'no' | translate }}
  </button>

  <button
    size="xl"
    tuiButton
    type="submit"
    appearance="primary"
    (click)="submit()"
  >
    <svg-icon
      [src]="'/assets/img/ic-check.svg'"
      class="mr-2"
    ></svg-icon>
    {{ 'yes' | translate }}
  </button>
</div>
