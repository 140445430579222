import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
})
export class InfoBarComponent {
  @Input() illustration = '';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() buttonText = '';

  @Output() onButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public userService: UserService) {}
}
