<div class="features flex flex-col justify-start items-stretch gap-y-4">
  <div>
    <h2 class="tui-text_h4 mt-6 mb-1 font-semibold text-center">Add your email address to send this funnel.</h2>
  </div>

  <div class="features__item flex flex-row justify-start items-start gap-x-3">
    <form
      [formGroup]="form"
      [validate-form]="form"
      (submit)="onSubmit()"
    >
      <div class="tui-form__row">
        <tui-input
          required=""
          formControlName="email"
        >
          {{ 'email' | translate }} *
        </tui-input>
        <df-error-input [control]="form.get('email')"></df-error-input>
      </div>

      <div class="tui-form__row">
        <tui-checkbox-labeled
          size="l"
          class="inline-flex"
          formControlName="privacyPolicy"
        >
          <span
            [innerHTML]="
              'Auth.Register.I agree to Digital First Terms and Privacy Policy'
                | translate
                  : {
                      system: Config.SYSTEM_NAME,
                      privacy: Config.PRIVACY_POLICY
                    }
            "
          ></span>
        </tui-checkbox-labeled>
        <df-error-input
          required
          [control]="form.get('privacyPolicy')"
        ></df-error-input>
      </div>

      <button
        type="submit"
        tuiButton
      >
        Save
      </button>
    </form>
  </div>
</div>
