<div class="w-full flex flex-col items-stretch">
  <div class="flex justify-between items-center mb-2">
    <p
      class="w-full font-bold uppercase tui-text-05 tui-text_body-xs"
      [innerHTML]="'Tactics.Manage.Assets' | translate"
    ></p>
    <a
      (click)="openNewAssetModal()"
      class="flex items-center tui-primary cursor-pointer no-underline tui-text_body-m-8 whitespace-nowrap"
    >
      <svg-icon
        class="mr-2"
        [svgStyle]="{ width: '1rem', height: '1rem' }"
        src="/assets/img/ic_add_filled.svg"
      ></svg-icon>
      {{ 'Tactics.Manage.Add asset' | translate }}
    </a>
  </div>
  <df-activity-asset-item
    *ngFor="let asset of assetsArray.controls"
    [asset]="getAssetData(asset)"
    (assetRemoved)="removeAsset(asset)"
    (assetRestored)="restoreAsset(asset)"
    [editMode]="true"
    [disabled]="isAssetToRemove(asset)"
  ></df-activity-asset-item>
</div>
