import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { Config } from '@shared/configs/config';

@Injectable()
export class SetUpAccountCanActivateGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authStorageService: AuthStorageService,
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  canActivate(): boolean {
    this.userService.getMe();
    if (this.userService.User && this.authStorageService.jwt) {
      if (this.userService.User?.setupFormFilled) {
        this.n.currentUrl ? this.n.router.navigate([this.n.currentUrl]) : this.n.go(Config.MAIN_VIEW);
        return false;
      }
      return true;
    }
    this.s.error(this.t.instant('Sign in to access the application'));
    this.n.go('sign-in');
    return false;
  }
}
