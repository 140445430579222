import { gql } from 'apollo-angular';
import { FullActivityFragment } from '@modules/tactics/shared/graphql/fragments/full-activity.fragment';

export const EditActivityDocument = gql/* GraphQL*/ `
  mutation editActivity($input: ActivityInputGraphql!, $activityId: Int!) {
    editActivity(input: $input, activityId: $activityId) {
      ...FullActivity
    }
  }
  ${FullActivityFragment}
`;
