import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { XPartnerService } from '@shared/services/x-partner.service';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-public-pricing-campaign',
  templateUrl: './public-pricing-campaign.component.html',
  styleUrls: ['./public-pricing-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicPricingCampaignComponent {
  constructor(
    public readonly userService: UserService,
    public readonly xPartnerService: XPartnerService,
    public readonly n: NavigateService,
  ) {}
}
