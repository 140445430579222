import { gql } from 'apollo-angular';

export const FunnelPublicFragment = gql/* GraphQL*/ `
  fragment FunnelPublic on FunnelOutputGraphql {
    id
    name
    createdAt
    modifiedAt
    lastEditor {
      id
      firstName
      lastName
      avatar {
        file
      }
    }
    stats {
      allCount
      doneCount
      step {
        id
        name
        type
      }
    }
    project {
      id
      name
    }
    image {
      id
      file
    }
    flows {
      id
      name
      items {
        from
        to
      }
    }
    shares {
      id
      email
    }
    tactics {
      id
      position
      name
      isDone
      doneActivities {
        activity {
          id
        }
      }
      step {
        id
      }
      selectedOutputsData {
        id
        activityAsset {
          id
        }
      }
      tactic {
        id
        name
        activities {
          id
          name
          description
          copy
          benchmarks {
            link
            attachment {
              name
              file
              id
            }
            type {
              id
              name
              type
              image
            }
            uuid
            iconUrl
            name
            description
          }
          assets {
            id
            asset {
              id
              native
              fileType
              ratio
              primaryText
              headline
              description
              maxFileSize
              adType {
                id
                name
              }
              platform {
                id
                name
                image
              }
              placement {
                id
                name
              }
              properties {
                key
                value
              }
              canvaCreationsUrl
            }
          }
          customPrompts {
            id
            name
            category
            description
            status
            customPromptFields {
              fieldId
              customPromptId
              fieldType
              name
              defaultValueId
              defaultValue
              optionValues
              label
              required
              helpText
              order
            }
            customPromptOutput {
              id
              customPromptId
              funnelTacticId
              input
              value
            }
            promptText
            promptProperty
            settingsIntegration
            settingsActive
          }
        }
        type {
          name
        }
        effects {
          name
        }
        funnelSteps {
          id
          type
          name
        }
        assetsCount
        badge
      }
    }
    doneTacticsCount
    doneActivitiesCount
    recommendationsCount
  }
`;
