<tui-island
  [size]="'s'"
  class="flex flex-col p-6 xl:p-10 justify-center items-center md:items-start text-center md:text-left"
>
  <svg-icon
    [src]="icon"
    [svgStyle]="{ width: '2.25rem', height: '2.25rem' }"
  ></svg-icon>
  <h3 class="text-2xl font-medium tui-text-03 mt-3 mb-2 flex">
    {{ title }}
    <svg-icon
      src="assets/img/ic_external_link.svg"
      class="self-start tui-primary ml-2 cursor-pointer"
      (click)="onRedirect.emit(true)"
    ></svg-icon>
  </h3>
  <p class="text-xs tui-text-05 m-0">{{ subtitle }}</p>
</tui-island>
