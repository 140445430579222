<div class="flex-grow overflow-y-auto h-full">
  <df-full-list-loader *ngIf="!!(loading$ | async)"></df-full-list-loader>
  <div
    *ngIf="!integration"
    class="h-full border-solid tui-base-04-border flex-grow p-3 flex flex-col justify-start items-stretch"
  >
    <div class="flex-grow flex flex-col justify-center items-center text-center">
      <h4 class="w-7/12 tui-text_body-m-1 tui-text-03 font-normal">
        {{ 'Funnels.Content generator.Select integration' | translate }}
      </h4>
    </div>
  </div>

  <df-content-generator-form
    *ngIf="integration"
    [integration]="integration"
    [form]="form"
    [generateButtonLabel]="'Funnels.Content generator.Generate text' | translate"
    (formSubmitted)="handleDataSubmitted()"
  ></df-content-generator-form>
</div>
