<tui-island
  size="s"
  class="p-3 w-full box-border"
  *ngIf="permis"
  [class.bg-gray-50]="
    permis.status === EPermissionResourceStatus.TO_REMOVE ||
    permis.status === EPermissionResourceStatus.TO_REMOVE_RESOURCE_TEAM
  "
>
  <div class="flex justify-between items-center w-full flex-nowrap overflow-hidden">
    <div class="flex justify-start items-center flex-shrink-1 overflow-hidden flex-nowrap">
      <tui-avatar
        [text]="(permis.teamMember.user ? permis.teamMember.user.fullName : permis.teamMember.email) ?? ''"
        size="s"
        [autoColor]="true"
        [rounded]="true"
        [avatarUrl]="permis.teamMember.user?.avatar ? Config.ASSETS + permis.teamMember.user?.avatar?.file : ''"
        class="mr-2"
      ></tui-avatar>

      <div class="w-name flex flex-col justify-start items-stretch flex-grow">
        <h6 class="w-full tui-text-body-s-2 font-semibold tui-text-03 text-clamp">
          {{ (permis.teamMember.user ? permis.teamMember.user.fullName : permis.teamMember.email) ?? '' }}
        </h6>
        <p
          *ngIf="permis.teamMember.user?.fullName"
          class="w-full tui-text-body-xs tui-text-05 text-clamp"
        >
          {{ permis.teamMember.user?.email }}
        </p>
      </div>
    </div>

    <div class="flex justify-end items-center">
      <div class="hidden sm:block">
        <ng-container *ngTemplateOutlet="roleSelect"></ng-container>
      </div>

      <button
        tuiIconButton
        type="button"
        appearance="outline"
        size="s"
        mwlConfirmationPopover
        [popoverTitle]="'Funnels.Settings.Removing team member' | translate"
        [popoverMessage]="'Funnels.Settings.Do you really want to remove this team member from funnel?' | translate"
        [appendToBody]="true"
        placement="top"
        popoverClass="team-popover"
        [disabled]="loading"
        (confirm)="removeUserFromPermission()"
        [icon]="close"
        class="ml-2"
        *ngIf="
          permis.teamMember.user?.id !== userService.User?.id &&
          permis.status !== EPermissionResourceStatus.TO_REMOVE &&
          permis.status !== EPermissionResourceStatus.TO_REMOVE_RESOURCE_TEAM
        "
      >
        <ng-template #close>
          <svg-icon
            class="tui-text-04"
            [src]="'/assets/img/ic-close.svg'"
            [svgStyle]="{ width: '1rem', height: '1rem' }"
          ></svg-icon>
        </ng-template>
      </button>
      <button
        tuiIconButton
        type="button"
        appearance="outline"
        size="s"
        [tuiHint]="tooltip"
        [tuiHintDirection]="'right'"
        [disabled]="loading"
        [icon]="restore"
        class="ml-2"
        *ngIf="permis.status === EPermissionResourceStatus.TO_REMOVE"
        (click)="restoreUser()"
      >
        <ng-template #tooltip>
          <div [innerHTML]="'restore' | translate"></div>
        </ng-template>
        <ng-template #restore>
          <svg-icon
            class="tui-text-04"
            [src]="'/assets/img/ic_refresh.svg'"
            [svgStyle]="{ width: '1rem', height: '1rem' }"
          ></svg-icon>
        </ng-template>
      </button>
    </div>
  </div>

  <div
    class="w-full flex justify-end mt-2 sm:hidden"
    *ngIf="permis.teamMember.user?.id !== userService.User?.id"
  >
    <ng-container *ngTemplateOutlet="roleSelect"></ng-container>
  </div>
</tui-island>
<ng-template #roleSelect>
  <tui-select
    class="role-control"
    [class.opacity-50]="!teamCollaborationAccess"
    tuiTextfieldSize="s"
    [valueContent]="label"
    [formControl]="permis.permissionControl"
    [tuiTextfieldLabelOutside]="true"
    *ngIf="
      permis.status !== EPermissionResourceStatus.TO_REMOVE &&
      permis.status !== EPermissionResourceStatus.TO_REMOVE_RESOURCE_TEAM &&
      permis.teamMember.user?.id !== userService.User?.id
    "
  >
    <ng-template
      #label
      let-item
      ><span class="font-medium">{{ 'Funnels.Settings.Permission ' + item | translate }}</span></ng-template
    >
    <tui-data-list *tuiDataList>
      <button
        tuiOption
        size="xs"
        *ngFor="let item of permissions"
        [value]="item"
      >
        {{ 'Funnels.Settings.Permission ' + item | translate }}
      </button>
    </tui-data-list>
  </tui-select>
</ng-template>
