import { PublicUserOutputGraphql, SubscriptionOutputGraphql } from './../../modules/graphql/graphql-types';
import {
  AttachmentOutputGraphql,
  FunnelOutputGraphql,
  PermissionType,
  PlanPermissionOutputGraphql,
  RegistrationMethodsEnum,
  SkillOutputGraphql,
  SubscriptionPlanOutputGraphql,
  TacticOutputGraphql,
  UserOutputGraphql,
} from '@modules/graphql/graphql-types';
import { Tactic } from '@shared/models/tactic.model';
import { Funnel } from '@shared/models/funnel.model';
import { Service } from '@shared/models/service.model';

export class PublicUser implements PublicUserOutputGraphql {
  id!: number;
  firstName = '';
  lastName = '';
  fullName = '';
  avatar?: AttachmentOutputGraphql;
  website?: string;
  discordName?: string;
  description?: string;
  country?: string;
  experienceYears = 0;
  skills: SkillOutputGraphql[] = [];
  services: Service[] = [];
  isPro = false;
  isAdmin = false;
  isBlocked = false;
  tacticsCount = 0;
  favouritesCount!: number;
  firstFunnel?: FunnelOutputGraphql;

  constructor(data?: PublicUser | PublicUserOutputGraphql | User | UserOutputGraphql) {
    data ? Object.assign(this, data) : '';
    this.setFullName();
  }

  setFullName() {
    this.fullName = [this.firstName, this.lastName].filter(Boolean).join(' ');
  }
}
export class User extends PublicUser implements UserOutputGraphql {
  email = '';
  newEmail = '';
  tactics: Tactic[] = [];
  isPublic = false;
  createdAt!: string;
  funnels: FunnelOutputGraphql[] = [];
  registrationMethod!: RegistrationMethodsEnum;
  contextFunnel!: Funnel;
  funnelsCount = 0;
  permissions: PlanPermissionOutputGraphql[] = [];
  activeSubscription!: SubscriptionOutputGraphql;
  activePlans: SubscriptionPlanOutputGraphql[] = [];
  tutorialEnded!: boolean;
  tacticDraft?: TacticOutputGraphql;
  firstTactic?: TacticOutputGraphql;
  filledStatement!: boolean;
  registeredThroughAppSumo!: boolean;
  paidTacticCount!: number;
  affiliationId: string | null = null;
  credits = 0;
  usedTrial = false;
  trialDaysLeft?: number;
  recentlyEndedTrial?: boolean;
  setupFormFilled = false;
  hasEndedOnboarding = false;
  isMarketingCampaignUser = false;
  successPlanPaid!: boolean;

  constructor(data?: PublicUser | PublicUserOutputGraphql | User | UserOutputGraphql) {
    super(data);
    data ? Object.assign(this, data) : '';
  }

  hasPermission(permissionType: PermissionType): boolean {
    return this.permissions.filter((p) => p.permissionType.type === permissionType).length > 0;
  }

  getPlanPermissions(permissionType: PermissionType): PlanPermissionOutputGraphql[] {
    return this.permissions.filter((p) => p.permissionType.type === permissionType) || [];
  }

  checkPermissionLimit(permissionType: PermissionType, currentAmount: number) {
    let isInLimit = false;
    this.permissions.map((planPermission: PlanPermissionOutputGraphql) => {
      if (planPermission.permissionType.type === permissionType) {
        if (planPermission.hasLimit && planPermission.limit === null) {
          //null means permission has limit, but in this plan there is no limit
          isInLimit = true;
        }

        if (planPermission.hasLimit && planPermission.limit !== null && planPermission.limit! >= currentAmount) {
          isInLimit = true;
        }
      }
    });
    return isInLimit;
  }

  hasAccess(permissionType: PermissionType, currentAmount = 0): boolean {
    if (this.hasPermission(permissionType)) {
      if (this.getPlanPermissions(permissionType)[0].hasLimit) {
        if (this.checkPermissionLimit(permissionType, currentAmount)) {
          //can do the thing
          return true;
        }
      } else {
        //can do the thing
        return true;
      }
    }
    return false;
  }

  hasActivePlan(): boolean {
    return this.activeSubscription.price.amount > 0 || this.activePlans.some((p) => p.price > 0);
  }

  isSemrushPlan() {
    return this.activePlans?.some((plan) => plan?.strength === 601 || plan?.strength === 602) ?? false;
  }
}
