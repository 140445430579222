import { gql } from 'apollo-angular';
import { CanvaCreationFragment } from '@modules/content-library/shared/graphql/fragments/canva-creation.fragment';

export const AssetFragment = gql`
  fragment AssetFragment on ActivityAssetOutputGraphql {
    id
    uuid
    asset {
      id
      native
      fileType
      ratio
      primaryText
      headline
      description
      maxFileSize
      adType {
        id
        name
      }
      platform {
        id
        name
        image
      }
      placement {
        id
        name
      }
      properties {
        key
        value
      }
      canvaCreationsUrl
    }
    selectedResolutions {
      id
      height
      width
    }
    creations {
      ...CanvaCreationFragment
    }
  }
  ${CanvaCreationFragment}
`;
