import { Injectable } from '@angular/core';
import { BaseStorageService } from '@core/services/base-storage.service';
import { StorageKey } from '@shared/enums/storage-key.enum';
import { UserService } from '@shared/services/user.service';
import { Apollo } from 'apollo-angular';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { Config } from '@shared/configs/config';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { XPartnerService } from '@shared/services/x-partner.service';
import { DomInjectorService } from '@shared/services/dom-injector.service';
import { StatementFormService } from '@modules/statement/shared/services/statement-form.service';
import { StatementEventsService } from '@modules/statement/shared/services/statement-events.service';
import { EventsService } from '@shared/services/events.service';

@Injectable({ providedIn: 'root' })
export class AuthStorageService extends BaseStorageService {
  readonly Config = Config;
  private readonly MARKETPLACE_COOKIE_KEY = `st-${Config.MARKETPLACE_CLIENT_ID}-token`;

  constructor(
    private userService: UserService,
    private apollo: Apollo,
    private authService: SocialAuthService,
    private cookieService: CookieService,
    private authGraphqlService: AuthGraphqlService,
    private xPartnerService: XPartnerService,
    private domInjectorService: DomInjectorService,
    private statementFormService: StatementFormService,
    private statementEventsService: StatementEventsService,
    private eventsService: EventsService,
  ) {
    super();
  }

  get isLogged(): boolean {
    return !!this.jwt?.toString().length && !!this.userService.User?.toString().length;
  }

  get jwt(): string | null {
    const jwtStr = this.xPartnerService.isWhitelabelPartner
      ? this.xPartnerService.partnerJwt
      : this.get(StorageKey.JWT);
    if (!jwtStr) {
      return null;
    }

    const jwt = JSON.parse(jwtStr);
    const now = Date.now();
    if (now > jwt.expire) {
      this.logout();
      return null;
    }
    return jwt.token;
  }

  set jwt(jwt: string | null) {
    if (!jwt) return;

    const expireIn = 1000 * 60 * 60 * 24 * 30; // 30 days;
    const jwtModel = {
      token: jwt,
      expire: Date.now() + expireIn,
    };
    this.put(StorageKey.JWT, JSON.stringify(jwtModel));
  }

  deleteJWT() {
    this.delete(StorageKey.JWT);
  }

  set marketplaceCookie(token: string | null) {
    if (!token) {
      return;
    }

    this.cookieService.set(this.MARKETPLACE_COOKIE_KEY, token, {
      expires: 30,
      path: '/',
      domain: Config.MARKETPLACE_DOMAIN,
      sameSite: 'None',
      secure: true,
    });
  }

  deleteMarketplaceCookie() {
    this.cookieService.delete(this.MARKETPLACE_COOKIE_KEY, '/', Config.MARKETPLACE_DOMAIN, true, 'None');
  }

  async logout() {
    this.deleteJWT();
    this.deleteMarketplaceCookie();
    this.clearDfLocalStorage();
    this.domInjectorService.destroyInjectedComponents();
    this.statementFormService.resetForm();
    this.statementEventsService.clearStoredEvents();
    this.userService.User = null;
    this.eventsService.setUpIntercome();

    try {
      await this.apollo.client.resetStore();
      await this.authService.signOut();
    } catch {
      //silent
    }
  }

  clearDfLocalStorage() {
    localStorage.removeItem('dfai-jwt');
    localStorage.removeItem('dfai-user');
    localStorage.removeItem('dfai_semrush_item');
    localStorage.removeItem('dfai-teams');
  }
}
