<div
  data-test="funnel-header-section"
  class="w-full flex justify-end md:justify-between items-center lg:px-4"
  *ngIf="funnelManageService.funnel as funnel"
>
  <div class="hidden md:flex items-center">
    <div class="flex flex-col max-w-2xl">
      <span
        *ngIf="currentTab === FunnelTab.CONTENT_GENERATE || currentTab === FunnelTab.CONTENT_GENERATOR_LIBRARY"
        class="uppercase font-bold tui-text-05 text-xs -mt-4 pb-2"
        >{{ 'Metatitles.Funnels.content generator' | translate }}</span
      >

      <!-- funnel Name switch -->
      <!-- <h1 class="tui-text_body-m-1 line-clamp-1 break-words max-w-2xl m-0">{{ funnel.name }}</h1> -->
      <tui-select
        class="funnel-switch"
        *tuiLet="funnelsList as items"
        [valueContent]="stringify"
        [(ngModel)]="funnelVal"
        [tuiTextfieldLabelOutside]="true"
      >
        <tui-data-list
          *tuiDataList
          class="pb-0"
        >
          <button
            [ngClass]="{ 'active-funnel': f.id === funnel.id }"
            tuiOption
            size="m"
            *ngFor="let f of items"
            [value]="f"
            (click)="setContextFunnel(f)"
          >
            <p class="tui-text_body-m-1 max-w-2xl m-0 !font-normal">
              {{ f.name }}
            </p>
          </button>
          <a
            class="bg-[#2A319B] text-[#fff] font-bold add-new-funnel"
            [routerLink]="'funnels/settings/create'"
            >{{ 'Funnels.Manage.Add new funnel' | translate }}</a
          >
        </tui-data-list>
      </tui-select>
    </div>
    <df-funnel-panel-subheader
      *ngIf="detailsMode$ | async"
      class="hidden xl:flex embed mx-4"
    >
    </df-funnel-panel-subheader>
  </div>

  <ng-container [ngSwitch]="currentTab">
    <ng-container *ngSwitchCase="FunnelTab.MANAGE">
      <tui-hosted-dropdown
        data-test="settings-dropdown"
        #dropdownComponent
        *ngIf="!funnelFlowManageService.editMode"
        tuiDropdownAlign="right"
        [content]="dropdown"
        tuiDropdownDirection="bottom"
      >
        <button
          tuiIconButton
          appearance="outline"
          size="m"
          type="button"
          icon="tuiIconMoreVertLarge"
        ></button>
      </tui-hosted-dropdown>
      <ng-template #dropdown>
        <tui-data-list>
          <ng-container>
            <button
              *ngIf="!!funnelManageService?.funnel?.tactics?.length"
              class="xl:hidden"
              tuiOption
              size="m"
              (click)="toggleActivities()"
            >
              <span class="inline-flex">{{
                (funnelManageService.expandAll.value
                  ? 'Funnels.Manage.Collapse activities'
                  : 'Funnels.Manage.Expand activities'
                ) | translate
              }}</span>
            </button>

            <button
              *ngIf="!!funnelManageService?.funnel?.tactics?.length"
              class="xl:hidden"
              tuiOption
              size="m"
              (click)="toggleSummary()"
            >
              <span class="inline-flex">{{
                (funnelManageService.expandSummary.value
                  ? 'Funnels.Manage.Hide summary'
                  : 'Funnels.Manage.Show summary'
                ) | translate
              }}</span>
            </button>

            <button
              data-test="settings-dropdown-share"
              *ngIf="
                funnelManageService?.funnel?.currentUserPermission !== FunnelPermissionEnum.ViewOnly &&
                !userService.User?.isSemrushPlan()
              "
              tuiOption
              size="m"
              (click)="share()"
            >
              <span class="inline-flex">{{ 'share' | translate }}</span>
            </button>

            <button
              *ngIf="!!funnelManageService?.funnel?.tactics?.length"
              tuiOption
              size="m"
              (click)="exportToPDF()"
            >
              <span class="inline-flex">{{ 'Funnels.Manage.Export to PDF' | translate }}</span>
            </button>

            <button
              data-test="settings-dropdown-edit"
              *ngIf="funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor"
              tuiOption
              size="m"
              (click)="edit()"
            >
              <span class="inline-flex">{{ 'edit' | translate }}</span>
            </button>

            <button
              data-test="settings-dropdown-delete"
              *ngIf="funnelManageService?.funnel?.currentUserPermission === FunnelPermissionEnum.Editor"
              tuiOption
              size="m"
              (click)="delete()"
            >
              <span class="inline-flex text-red-500">{{ 'delete' | translate }}</span>
            </button>
          </ng-container>
        </tui-data-list>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="FunnelTab.PROMOTION">
      <button
        tuiButton
        size="l"
        appearance="primary"
        type="button"
        (click)="addToMyProfile()"
      >
        <svg-icon
          [src]="'/assets/img/funnel-add.svg'"
          class="md:mr-1"
        ></svg-icon>
        <span class="hidden md:inline-flex">{{ 'Funnels.Manage.Add to my profile' | translate }}</span>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="FunnelTab.PREVIEW">
      <button
        tuiButton
        size="l"
        appearance="outline"
        type="button"
        (click)="goToManage()"
      >
        <svg-icon
          [src]="'/assets/img/Settings.svg'"
          class="md:mr-2"
        ></svg-icon>
        <span class="hidden md:inline-flex">{{ 'Funnels.Manage.Manage funnel' | translate }}</span>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="FunnelTab.CONTENT_GENERATE">
      <button
        class="ml-4"
        tuiIconButton
        type="button"
        size="m"
        appearance="outline"
        [icon]="close"
        (click)="goToContentLibrary()"
      ></button>
    </ng-container>
  </ng-container>

  <ng-template #help>
    <svg-icon [src]="'/assets/img/ic_question_circle.svg'"></svg-icon>
  </ng-template>
  <ng-template #close>
    <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
  </ng-template>
</div>
