import { gql } from 'apollo-angular';

export const EditTacticMetricDocument = gql`
  mutation editTacticMetric($input: TacticMetricInputGraphql!, $id: Int!) {
    editTacticMetric(input: $input, id: $id) {
      id
      measurementPeriod
      isCyclical
      cycleMeasurementPeriod
      metric {
        id
        name
      }
    }
  }
`;
