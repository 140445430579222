<div class="flex flex-col justify-start items-start">
  <df-full-list-loader *ngIf="loading"></df-full-list-loader>
  <div class="w-full flex justify-between flex-row-reverse items-center">
    <tui-hosted-dropdown
      tuiDropdownAlign="left"
      [(open)]="hostedOpen"
      [content]="dropdown"
    >
      <button
        data-test="add-team-button"
        tuiButton
        appearance="secondary"
        type="button"
        [iconRight]="arrow"
        [disabled]="loading"
      >
        {{ 'ResourceTeams.Add team' | translate }}
      </button>
    </tui-hosted-dropdown>
    <h3
      class="df-column-title m-0"
      *ngIf="title"
    >
      {{ title }}
    </h3>
  </div>
  <div class="w-full flex flex-col">
    <df-resource-teams-single-team
      data-test="single-team-section"
      [resourceTeam]="resourceTeam"
      *ngFor="let resourceTeam of resourceTeams"
    ></df-resource-teams-single-team>
  </div>
  <ng-template #dropdown>
    <tui-data-list>
      <p class="tui-text-body-s-2 tui-text-05 m-4 mt-0 mb-2">
        {{ 'Funnels.Settings.Your teams' | translate }}
      </p>
      <ng-container *ngFor="let team of teams">
        <a
          data-test="dropdown-item"
          tuiOption
          *ngIf="team.getMyRole(user) === TeamRoleEnum.Creator"
          (click)="addResourceTeam(team)"
          class="flex justify-start pl-4 pr-4 pt-2 pb-2"
        >
          <svg-icon
            [src]="'/assets/img/team-icon.svg'"
            class="mr-4"
          ></svg-icon>
          <div class="flex flex-col flex-wrap">
            <h5
              data-test="team-name"
              class="tui-text-03 tui-text-body-m-2 break-words"
            >
              {{ team.name }}
            </h5>
            <p
              data-test="team-member-number"
              class="tui-text-05 tui-text-body-s-2"
            >
              {{ team.members.length }} {{ 'members' | translate }}
            </p>
          </div>
        </a>
      </ng-container>
    </tui-data-list>
  </ng-template>
</div>
