<tui-accordion
  *ngIf="activityControl"
  class="w-full relative z-10 activity-form"
  [rounded]="false"
>
  <tui-accordion-item
    [showArrow]="false"
    [(open)]="open"
    [disabled]="true"
  >
    <df-activity-form-header
      (toggleAccordion)="toggleAccordionItem()"
      [open]="open"
      [index]="index"
      [activityControl]="activityControl"
      [disabled]="disabled"
    >
      <ng-content></ng-content>
    </df-activity-form-header>

    <ng-template tuiAccordionItemContent>
      <df-activity-form-data [activityControl]="activityControl"></df-activity-form-data>
      <div class="md:hidden mt-4 flex justify-end">
        <df-activity-form-actions
          [activityControl]="activityControl"
          [disabled]="disabled"
        ></df-activity-form-actions>
      </div>
    </ng-template>
  </tui-accordion-item>
</tui-accordion>
