import { XPartnerService } from './../../../../shared/services/x-partner.service';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Config } from '@shared/configs/config';
import { EAuthViewMode } from '@modules/authorization/shared/enums/auth-view-mode.enum';

@Component({
  templateUrl: './auth-panel.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPanelComponent implements OnInit, OnDestroy {
  isFullWidth = false;
  Config = Config;
  appSumo = false;

  get isRegister() {
    return this.route.snapshot?.firstChild?.data?.type === EAuthViewMode.REGISTER;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    public xPartnerService: XPartnerService,
  ) {
    this.setRecaptchaClass();
  }

  ngOnInit() {
    this.appSumo = this.router.url.includes('appsumo');
    this.updateFullWidth();
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(() => this.updateFullWidth());
  }

  setRecaptchaClass(): void {
    this.renderer.addClass(document.body, 'recaptcha');
  }

  removeRecaptchaClass(): void {
    this.renderer.removeClass(document.body, 'recaptcha');
  }

  updateFullWidth() {
    this.isFullWidth = !!this.deepestChild?.params['confirmation-token'];
  }

  get deepestChild(): ActivatedRouteSnapshot {
    const getChild = (child: ActivatedRouteSnapshot | null) => {
      if (!child?.firstChild) {
        return child;
      }
      return getChild(child.firstChild);
    };
    return getChild(this.route.snapshot.firstChild);
  }

  ngOnDestroy() {
    this.removeRecaptchaClass();
  }
}
