import { OnboardingTooltip } from '@shared/models/onboarding-step.model';
import { AbstractOnboardingFactory } from '@shared/abstracts/onboarding-factory.abstract';

export class OnboardingTooltipFactory extends AbstractOnboardingFactory<OnboardingTooltip> {
  renderElement(element: OnboardingTooltip): Element {
    const tooltip = document.createElement('div');
    tooltip.appendChild(this._renderTitle(element));
    tooltip.appendChild(this._renderSubtitle(element));
    tooltip.classList.add('onboarding-tooltip');
    tooltip.style.left = Math.abs(this.containerRect.left - this.anchorRect.left) + 'px';
    tooltip.style.top = Math.abs(this.containerRect.top - this.anchorRect.bottom) + 16 + 'px';
    this.container.appendChild(tooltip);
    return tooltip;
  }

  private _renderTitle(element: OnboardingTooltip): Element {
    const tooltipTitle = document.createElement('h5');
    tooltipTitle.innerText = element.title;
    tooltipTitle.classList.add('onboarding-tooltip__title');
    return tooltipTitle;
  }

  private _renderSubtitle(element: OnboardingTooltip): Element {
    const tooltipSubtitle = document.createElement('p');
    tooltipSubtitle.innerText = element.subtitle;
    tooltipSubtitle.classList.add('onboarding-tooltip__subtitle');
    return tooltipSubtitle;
  }
}
