<div
  [formGroup]="position"
  class="sm:pb-8 xl:pb-4 border-b border-solid position"
>
  <div
    formArrayName="positionValues"
    class="relative"
  >
    <div
      *ngFor="let positionValue of positionValues.controls; let i = index"
      [formGroupName]="i"
      class="flex items-center rounded-lg border pr-4 py-1 mb-2 position-item last:mb-0"
    >
      <tui-text-area
        formControlName="value"
        [expandable]="true"
        class="textarea-custom"
      >
      </tui-text-area>

      <div class="ar flex">
        <svg-icon
          (click)="allowEdit(positionValue)"
          [src]="'/assets/img/_ic_edit.svg'"
          [svgStyle]="{ width: '1rem', height: '1rem' }"
          class="cursor-pointer ml-auto"
          [ngClass]="positionValue.get('value')!.disabled ? 'tui-base-06' : 'tui-base-09'"
        >
        </svg-icon>
        <svg-icon
          (click)="deletePositionValue(i)"
          [src]="'/assets/img/Close.svg'"
          [svgStyle]="{ width: '1rem', height: '1rem' }"
          class="tui-base-06 cursor-pointer ml-2"
        >
        </svg-icon>
      </div>
    </div>
    <svg-icon
      (click)="addPositionValue()"
      [src]="'/assets/img/ic-add.svg'"
      [svgStyle]="{ width: '1rem', height: '1rem' }"
      class="tui-base-09 flex cursor-pointer w-7 pt-2 pb-4 mr-auto absolute bottom-2 sm:-bottom-10 xl:bottom-2 xl:-right-10"
    >
    </svg-icon>
  </div>
</div>
