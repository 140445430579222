import { gql } from 'apollo-angular';
import { BenchmarkFragment } from '@modules/tactics/shared/graphql/fragments/benchmark.fragment';

export const AddBenchmarkToActivityDocument = gql/* GraphQL*/ `
  mutation addBenchmarkToActivity($input: AddBenchmarkInputGraphql!, $file: Upload, $attachmentId: Int) {
    addBenchmarkToActivity(input: $input, file: $file, attachmentId: $attachmentId) {
      ...BenchmarkFragment
    }
  }
  ${BenchmarkFragment}
`;
