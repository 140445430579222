<div class="tactics-header flex items-center justify-center">
  <p class="tactics-header__desc">
    Create account to get access to biggest marketing tactics library, manage own funnels and use AI tools to automate
    marketing execution.
    <a
      target="_blank"
      href="https://app.digitalfirst.ai/public/pricing"
      >🔥 Free trial 🔥</a
    >
  </p>
</div>

<df-tactics-list-panel></df-tactics-list-panel>
