import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FunnelsListService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-list.service';
import { FunnelsFilterService } from '@modules/funnels/modules/funnels-list/shared/services/funnels-filter.service';
import { ListEvent, ListEventType, ListPageDirection } from '@shared/services/base-list.service';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { Subscription } from 'rxjs';
import { FunnelListTypeEnum } from '@modules/graphql/graphql-types';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'df-funnels-tiles',
  templateUrl: './funnels-tiles.component.html',
  styleUrls: ['./funnels-tiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelsTilesComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();

  constructor(
    public funnelsListService: FunnelsListService,
    public funnelsFiltersService: FunnelsFilterService,
    private changes: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.sub = new Subscription();
    this.setType(this.route.snapshot?.data?.type);
    this.funnelsListService.setFirstPage();
    this.listenLoadFilters();
    this.listenListEvents();
  }

  listenLoadFilters() {
    this.funnelsFiltersService.areFiltersLoaded ? this.funnelsListService.initService() : '';
    const sub = this.funnelsFiltersService.$filtersLoaded.subscribe(() => {
      this.funnelsListService.initService();
    });
    this.sub.add(sub);
  }

  listenListEvents() {
    const sub = this.funnelsListService.emitter.subscribe((res: ListEvent) => {
      switch (res.type) {
        case ListEventType.END_GETTING_RECORDS:
          this.changes.detectChanges();
          break;
      }
    });
    const sub2 = this.funnelsFiltersService.filterFormGroup.valueChanges.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
    this.sub.add(sub2);
  }

  setType(type: FunnelListTypeEnum) {
    this.funnelsFiltersService.filterFormGroup.get('type')?.patchValue(type, { emitEvent: false });
  }

  showLoadPrev() {
    if (this.funnelsListService.loadedPages.sort()[0] > 1) {
      return true;
    }
    return false;
  }

  onEnd(event: IPageInfo) {
    if (
      !this.funnelsListService.records.length ||
      event.endIndex !== this.funnelsListService.records.length - 1 ||
      event.endIndex === -1
    )
      return;
    this.funnelsListService.getRecords(ListPageDirection.NEXT).toPromise();
  }

  onLoadPrevPage() {
    this.funnelsListService.getRecords(ListPageDirection.PREV).toPromise();
  }

  ngOnDestroy() {
    this.funnelsListService.clearService();
    this.sub.unsubscribe();
  }
}
