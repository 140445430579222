import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { Tactic } from '@shared/models/tactic.model';
import { SwiperComponent } from 'swiper/angular';
import { Subscription } from 'rxjs';
import Swiper, { Autoplay } from 'swiper';
import { NavigateService } from '@core/routes/services/navigate.service';
Swiper.use([Autoplay]);

@Component({
  selector: 'df-tactic-top-banner-tile',
  templateUrl: './tactic-top-banner-tile.component.html',
  styleUrls: ['./tactic-top-banner-tile.component.scss'],
})
export class TacticTopBannerTileComponent implements AfterViewInit, OnDestroy {
  private _tactics: Tactic[] = [];
  activeIndex = 0;

  @Input()
  set tactics(tactics: Tactic[]) {
    this._tactics = tactics;
    this.changes.detectChanges();
  }

  get tactics(): Tactic[] {
    return this._tactics;
  }

  sub: Subscription = new Subscription();

  @ViewChild('swiper') swiper!: SwiperComponent;

  @HostBinding('class')
  get activeTacticFunnelType() {
    return this.tactics[this.activeIndex]?.funnelSteps[0]?.type;
  }

  constructor(
    private changes: ChangeDetectorRef,
    public n: NavigateService,
  ) {}

  ngAfterViewInit() {
    const sub = this.swiper.indexChange.subscribe((index) => {
      this.activeIndex = index;
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
