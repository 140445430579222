<df-statement-content-wrapper>
  <form
    *ngIf="statementFormService.isFormReady"
    [formGroup]="statementFormService.form"
    [validate-form]="statementFormService.form"
    class="statement-container container overflow-hidden m-auto flex flex-col justify-start items-start"
  >
    <div
      class="statement-text"
      #statementStep2Container
    >
      <div class="w-full">
        <ng-container>
          <df-statement-section-header
            class="custom-header"
            [header]="'Statement.Selling Info' | translate"
          ></df-statement-section-header>
          <span class="statement-text-fragment">{{ 'Statement.Clients are charged by' | translate }}</span>
          <!-- Payment Method -->
          <df-statement-select-wrapper
            class="custom-mobile-select custom-mobile-select-small-margin m-d-inline-block lh-helper"
            [displayInline]="true"
            #paymentMethodSelectWrapper
          >
            <df-statement-select
              #paymentMethod
              [name]="'Statement.payment method' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.paymentMethod?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.PaymentMethod"
              [items]="statementNewParametersService.paymentMethod?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="paymentMethodSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.PaymentMethod"
              [form]="statementFormService.form"
              [component]="paymentMethod"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <span class="statement-text-fragment">{{ 'Statement.with' | translate }}</span>
          <br />
          <span class="statement-text-fragment">{{ 'Statement.frequency of:' | translate }}</span>
          <!-- payment Frequency -->
          <df-statement-select-wrapper
            class="custom-mobile-select"
            #paymentFrequencySelectWrapper
          >
            <df-statement-select
              #paymentFrequency
              [name]="'Statement.payment frequency' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.paymentFrequency?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.PaymentFrequency"
              [items]="statementNewParametersService.paymentFrequency?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="paymentFrequencySelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.PaymentFrequency"
              [form]="statementFormService.form"
              [component]="paymentFrequency"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <span>.</span>
          <br />
          <span class="statement-text-fragment">{{ 'Statement.Our offer policy uses' | translate }}</span>
          <!-- Offer policy -->
          <df-statement-select-wrapper
            class="custom-mobile-select custom-mobile-select-small-margin m-d-inline-block lh-helper"
            [displayInline]="true"
            #offerPolicySelectWrapper
          >
            <df-statement-select
              #offerPolicy
              [name]="'Statement.promotions' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.offerPolicy?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.OfferPolicy"
              [items]="statementNewParametersService.offerPolicy?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="offerPolicySelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.OfferPolicy"
              [form]="statementFormService.form"
              [component]="offerPolicy"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <span>.</span>
        </ng-container>

        <ng-container>
          <df-statement-section-header
            class="custom-header"
            [header]="'Statement.Target group' | translate"
          ></df-statement-section-header>
          <span class="statement-text-fragment">{{
            'Statement.Product target group uses digital tools like:' | translate
          }}</span>
          <!-- target channel -->
          <df-statement-select-wrapper
            class="custom-mobile-select m-d-inline-block lh-helper"
            [displayInline]="true"
            #targetChannelsSelectWrapper
          >
            <df-statement-select
              #targetChannels
              [name]="'Statement.target channels' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.targetChannels?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.TargetChannels"
              [items]="statementNewParametersService.targetChannels?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="targetChannelsSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.TargetChannels"
              [form]="statementFormService.form"
              [component]="targetChannels"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <br />
          <span class="statement-text-fragment">{{ 'Statement.and is located in' | translate }}</span>
          <!-- target location -->
          <df-statement-select-wrapper
            class="custom-mobile-select"
            #targetLocationSelectWrapper
          >
            <df-statement-select
              #targetLocation
              [name]="'Statement.location' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.targetLocation?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.TargetLocation"
              [items]="statementNewParametersService.targetLocation?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="targetLocationSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.TargetLocation"
              [form]="statementFormService.form"
              [component]="targetLocation"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <span>.</span>
        </ng-container>

        <ng-container>
          <df-statement-section-header
            class="custom-header"
            [header]="'Statement.Goal' | translate"
          ></df-statement-section-header>
          <span class="statement-text-fragment">{{ 'Statement.Our challenge is' | translate }}</span>
          <!-- Objective Type -->
          <df-statement-select-wrapper
            class="custom-mobile-select m-d-inline-block lh-helper"
            [displayInline]="true"
            #objectiveTypeSelectWrapper
          >
            <df-statement-select
              #objectiveType
              [name]="'Statement.objective type' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.objectiveType?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.ObjectiveType"
              [items]="statementNewParametersService.objectiveType?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="objectiveTypeSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.ObjectiveType"
              [form]="statementFormService.form"
              [component]="objectiveType"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
          <span>.</span>
          <br />
          <span class="statement-text-fragment">{{ 'Statement.Business goal is to generate' | translate }}</span>
          <!-- Goal GoalMeasureSales/GoalMeasureLeads-->
          <df-statement-input
            class="min-width-180 custom-placeholder"
            [form]="statementFormService.form"
            [controlName]="FieldCategoryEnum.GoalMeasureSales"
            [name]="'Statement.amount' | translate"
            [container]="statementStep2Container"
            [formSubmitted]="formSubmitted"
            [isNumberInput]="true"
          >
          </df-statement-input>
          <!-- Goal Type -->
          <df-statement-tiles-radio
            class="mr-[0.5rem]"
            [form]="statementFormService.form"
            [controlName]="FieldCategoryEnum.GoalType"
            [items]="statementNewParametersService.goalType?.options ?? []"
            [formSubmitted]="formSubmitted"
          ></df-statement-tiles-radio>
          <span class="statement-text-fragment">{{ 'Statement.in' | translate }}</span>
          <!-- goal period -->
          <df-statement-select-wrapper
            class="custom-mobile-select"
            #goalPeriodSelectWrapper
          >
            <df-statement-select
              #goalPeriod
              [name]="'Statement.time' | translate"
              [form]="statementFormService.form"
              [maxItems]="statementNewParametersService.goalPeriod?.type | statementMaxItems"
              [controlName]="FieldCategoryEnum.GoalPeriod"
              [items]="statementNewParametersService.goalPeriod?.options"
              [formSubmitted]="formSubmitted"
              (dropdownOpen)="goalPeriodSelectWrapper.onDropdownChange($event)"
            >
            </df-statement-select>
            <df-statement-inline-value
              [controlName]="FieldCategoryEnum.GoalPeriod"
              [form]="statementFormService.form"
              [component]="goalPeriod"
            >
            </df-statement-inline-value>
          </df-statement-select-wrapper>
        </ng-container>
      </div>
    </div>
    <df-statement-controls
      class="w-full statement-controls"
      (onNextStep)="onNextStep()"
      (onPreviousStep)="onPreviousStep()"
    ></df-statement-controls>
  </form>
</df-statement-content-wrapper>
