<tui-island
  size="s"
  *ngIf="
    !tacticSettingsService.tactic ||
    (!tacticSettingsService.tactic?.isPaid &&
      !tacticSettingsService.tactic?.root &&
      tacticSettingsService.tactic?.owner?.id === (userService.currentUser$ | async)?.id)
  "
>
  <div class="flex flex-col">
    <label class="df-column-title mb-1">{{ 'Tactics.Settings.Tactic visibility' | translate }}</label>
    <p class="tui-text-05 tui-text-body-s-2">
      {{ 'Tactics.Settings.No one will see this tactic until you make public' | translate }}
    </p>
  </div>
  <div class="w-full flex flex-nowrap justify-start mt-3">
    <tui-radio-labeled
      [formControl]="accessibilityControl"
      class="mr-3"
      size="l"
      [item]="true"
      (click)="changeAccessibility($event, true)"
    >
      <span class="tui-text-body-s-2">{{ 'Tactics.Preview.Private' | translate }}</span>
    </tui-radio-labeled>
    <tui-radio-labeled
      [formControl]="accessibilityControl"
      class=""
      size="l"
      [item]="false"
      (click)="changeAccessibility($event, false)"
    >
      <span class="tui-text-body-s-2">{{ 'Tactics.Preview.Public' | translate }}</span>
    </tui-radio-labeled>
  </div>
</tui-island>
