import { UserService } from '@shared/services/user.service';
import { XPartnerService } from './../../../../shared/services/x-partner.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Config } from '@shared/configs/config';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { FetchResult } from '@apollo/client/core';
import { RegisterMutation } from '@modules/authorization/shared/graphql/mutations/register.mutation.generated';
import {
  AuthOutputGraphql,
  SocialLoginInputGraphql,
  SubscriptionPlanOutputGraphql,
} from '@modules/graphql/graphql-types';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ActivatedRoute } from '@angular/router';
import { AbstractRegisterComponent } from '@modules/authorization/shared/abstracts/abstract-register.component';
import { Observable } from 'rxjs';
import { LoginWithSocialMediaAccountMutation } from '@modules/authorization/shared/graphql/mutations/ login-with-social-media-account.mutation.generated';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { User } from '@sentry/angular-ivy';

@Component({
  selector: 'df-register',
  templateUrl: './register.component.html',
  styleUrls: ['../../shared/scss/auth-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent extends AbstractRegisterComponent<RegisterMutation> implements OnInit {
  @Input() dialogMode = false;
  @Input() trial = false;
  @Input() planToBuy: SubscriptionPlanOutputGraphql | null = null;
  @Output() navigateToSignIn: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() signedUp: EventEmitter<boolean> = new EventEmitter<boolean>();

  tacticRedirect = false;

  constructor(
    changes: ChangeDetectorRef,
    recaptchaService: ReCaptchaV3Service,
    private route: ActivatedRoute,
    private authGraphqlService: AuthGraphqlService,
    public xPartnerService: XPartnerService,
    private userService: UserService,
    protected eventService: EventsService,
    protected funnelService: FunnelGraphqlService,
  ) {
    super(changes, recaptchaService, funnelService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.tacticRedirect = this.route.snapshot.queryParamMap.has('tactic-redirect');
  }

  goToSignIn(): void {
    this.navigateToSignIn.emit(true);
  }

  notifySign(): void {
    this.signedUp.emit(true);
  }

  protected getRegisterRequest(token: string): Observable<FetchResult<RegisterMutation>> {
    return this.authGraphqlService.register(
      {
        ...this.formGroup.value,
        redirectedFromTacticsLpPage: this.tacticRedirect,
        isMarketingCampaignUser: this.resolveIsMarketingCampaignUser(),
      },
      token,
    );
  }

  private resolveIsMarketingCampaignUser(): boolean {
    return !!(
      this.planToBuy &&
      this.planToBuy.prices.length &&
      this.planToBuy.prices[0].currency.toUpperCase() == 'PLN'
    );
  }

  protected successSignUp(res: FetchResult<RegisterMutation>) {
    this.authController.saveAuthData(res.data?.register as AuthOutputGraphql);
    this.eventService.pushToGoogleAnalyticsEvent(
      res.data?.register.eventType === 'sign-up' ? GoogleAnalyticsEvent.Signup : GoogleAnalyticsEvent.Signin,
      {
        userID: res.data!.register.user!.id,
        email: res.data!.register.user!.email,
      },
    );

    try {
      this.eventService.pushToSmartlookEvent(res.data!.register.user!.id!, {
        email: res.data?.register.user?.email,
      });
      this.checkUserHaveAnonymousFunnel(res.data!.register.user as User);
    } catch (e) {
      console.log(e);
    }

    this.dialogMode ? this.notifySign() : this.redirectAfterSign();
  }

  protected redirectAfterSign() {
    // set info in the localStorage
    this.userService.setNewUserRegistered();
    this.s.success(this.t.instant('Auth.Register.Thank you for register a new account. Confirm your e-mail.'));
    this.loading = false;
    this.changes.detectChanges();
    this.statementService.openStatementOnboarding = true;
    this.n.go(this.route.snapshot.queryParamMap.has('tactic-redirect') ? 'tactics/list/all' : Config.MAIN_VIEW);
  }

  protected getSocialLoginRequest(
    loginData: SocialLoginInputGraphql,
  ): Observable<FetchResult<LoginWithSocialMediaAccountMutation>> {
    return this.authGraphqlService.loginWithSocialMediaAccount({
      ...loginData,
      redirectedFromTacticsLpPage: this.tacticRedirect,
    });
  }

  protected successSignInBySocialAccount(res: FetchResult<LoginWithSocialMediaAccountMutation>) {
    super.successSignInBySocialAccount(res);
    this.redirectAfterSign();
  }
}
