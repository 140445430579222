<tui-island class="flex justify-center items-center payment__container shadow-lg">
  <div class="flex justify-between items-center">
    <div
      *ngIf="status === 'success'"
      class="flex flex-col justify-start items-center md:items-start"
    >
      <div class="rounded-full w-10 h-10 flex items-center justify-center">
        <svg-icon
          [src]="'/assets/img/fi_check-circle.svg'"
          [svgStyle]="{ width: '3rem', height: '3rem' }"
        ></svg-icon>
      </div>
      <h1
        class="tui-text_h2 tui-accent-07 text-center md:text-left"
        [innerHTML]="
          (trial ? 'Pricing.Success!<br>Trial started.' : 'Pricing.Success!<br>Payment finished.') | translate
        "
      ></h1>
      <p class="tui-text-02 tui-text-body-s-2 w-[20rem] mb-8 text-center md:text-left">
        {{
          (trial
            ? 'Pricing.Your trial started successfully.'
            : orderMode
              ? 'Pricing.Your payment processed successfully. Growth Hacker received your payment. Check your inbox for related documents.'
              : 'Pricing.Your payment processed successfully. Now you can use your new permissions in application.'
          ) | translate: { planName: trial }
        }}
      </p>
      <button
        tuiButton
        appearance="primary"
        (click)="goToApp()"
      >
        {{ 'Go to app' | translate }}
      </button>
    </div>
    <div
      *ngIf="status === 'error'"
      class="flex flex-col justify-start items-start"
    >
      <div class="rounded-full w-10 h-10 flex items-center justify-center">
        <svg-icon
          [src]="'/assets/img/problem-pyament.svg'"
          [svgStyle]="{ width: '3rem', height: '3rem' }"
        ></svg-icon>
      </div>
      <h1
        class="tui-text_h2 tui-accent-01"
        [innerHTML]="(trial ? 'Pricing.Error.<br>Trial problem.' : 'Pricing.Error.<br>Payment\'s problem.') | translate"
      ></h1>
      <p class="tui-text-02 tui-text-body-s-2 w-[20rem] mb-8">
        {{
          (trial
            ? 'Pricing.There is some problem with starting your trial.'
            : orderMode
              ? 'Pricing.There is some problem with your payment. Go back to your order and try again.'
              : 'Pricing.There is some problem with your payment. Go back to app and try again buy the plan.'
          ) | translate
        }}
      </p>
      <button
        tuiButton
        appearance="primary"
        (click)="goToApp()"
      >
        {{ 'Go to app' | translate }}
      </button>
    </div>
    <img
      src="/assets/img/payment-icon.png"
      class="hidden md:flex ml-8 w-[20rem]"
      alt=""
    />
  </div>
</tui-island>
