import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UserSettingsCanActivateGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authStorageService: AuthStorageService,
    private n: NavigateService,
    private s: SnackbarService,
    private t: TranslateService,
  ) {}

  canActivate(): boolean {
    if (this.userService.User && this.authStorageService.jwt) {
      return true;
    }
    this.s.error(this.t.instant('Sign in to access the application'));
    this.n.go('sign-in');
    return false;
  }
}
