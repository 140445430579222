import { FunnelFragment } from '@modules/funnels/shared/graphql/fragments/funnel.fragment';
import { gql } from 'apollo-angular';

export const CreateTacticsDocument = gql`
  mutation createTactics($funnelId: Int!, $docName: String!) {
    createTactics(funnelId: $funnelId, docName: $docName) {
      ...Funnel
    }
  }

  ${FunnelFragment}
`;
