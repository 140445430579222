import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { EContentGeneratorStylesForm } from '../enums/content-generator-styles-form';
import { Subscription, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StylesFormService {
  stylesModalSub$: Subscription = new Subscription();
  formControlId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  changesInGeneratorForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      [EContentGeneratorStylesForm.STYLE]: [null, [Validators.required]],
    });
  }
}
