<div
  class="container flex justify-center items-center h-full min-w-full"
  *ngIf="!loadingCategory"
>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
<div
  class="p-4 border-b border-[#E0E0E0] border-solid flex items-center justify-between"
  *ngIf="loadingCategory"
>
  <p class="m-0 font-semibold text-xl text-[#191c31e6]">
    {{ outputCustomPrompt }}
  </p>
  <svg-icon
    [src]="'/assets/img/icon-close-chat.svg'"
    [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
    class="cursor-pointer"
    (click)="showDialog(template)"
  ></svg-icon>
</div>

<ng-template
  #template
  let-observer
>
  <div class="flex items-start justify-center w-full">
    <div class="flex flex-col justify-center items-start mt-2">
      <h2 class="tui-text-01 text-base mt-12 mb-10 text-center">
        <span class="font-semibold block text-base"
          >{{
            'Tactics.Prompt.You dont have any prompt results saved.Are you sure you want to quit' | translate
          }}?</span
        >
      </h2>
    </div>
  </div>

  <div class="flex justify-center">
    <button
      class="mr-2"
      appearance="outline"
      tuiButton
      type="button"
      size="m"
      (click)="observer.complete()"
    >
      No
    </button>

    <button
      tuiButton
      type="button"
      size="m"
      (click)="closeDialog(observer)"
    >
      Yes
    </button>
  </div>
</ng-template>
<div
  class="py-2 px-6"
  *ngIf="loadingCategory"
>
  <tui-scrollbar class="h-[70vh]">
    <div class="p-6 flex items-center rounded-2xl">
      <svg-icon
        class="mr-4 self-start"
        [src]="'/assets/img/icon-robot-chat.svg'"
      ></svg-icon>
      <div class="font-medium text-[#191c31e6] text-base max-w-[calc(100%-64px)] md:max-w-none">
        {{ 'Tactics.Prompt.Hello! Fill the prompt form to generate results' | translate }}.
      </div>
    </div>

    <div
      *ngIf="isEditValue"
      class="p-6 flex items-center rounded-2xl"
    >
      <svg-icon
        class="mr-4 self-start"
        [src]="'/assets/img/ic_you.svg'"
      ></svg-icon>
      <div
        *ngIf="null !== outputCustomPrompt"
        class="font-medium text-[#191c31e6] text-base mr-auto max-w-[calc(100%-64px)] md:max-w-none"
        [innerText]="outputCustomPrompt"
      ></div>
    </div>
    <div
      *ngIf="isEditValue"
      class="p-6 flex items-center rounded-2xl flex-wrap md:flex-nowrap"
    >
      <svg-icon
        class="mr-4 self-start"
        [src]="'/assets/img/icon-robot-chat.svg'"
      ></svg-icon>
      <div
        *ngIf="null !== fullOutputCustomPrompt && !isEditValueShowBtn"
        class="font-medium text-[#191c31e6] text-base mr-auto max-w-[calc(100%-64px)] md:max-w-none"
        [innerText]="fullOutputCustomPrompt"
      ></div>
      <div
        *ngIf="null !== editForm && isEditValueShowBtn"
        class="font-medium text-[#191c31e6] text-base mr-4 flex-1 min-w-[calc(100%-80px)] md:min-w-0"
      >
        <form
          (ngSubmit)="onSubmitEditMessage()"
          [formGroup]="editForm"
          id="editForm"
        >
          <tui-text-area
            class="mb-0 custom-textarea"
            formControlName="message"
          ></tui-text-area>
        </form>
      </div>
      <div
        *ngIf="null !== fullOutputCustomPrompt"
        class="flex items-center gap-2 self-start mt-4 ml-auto md:ml-2 md:mt-0"
      >
        <button
          *ngIf="!isEditValueShowBtn"
          type="button"
          class="rounded-lg text-center tui-text_body-m-14 btn-helper"
          tuiButton
          appearance="outline"
          size="s"
          (click)="editMessage(fullOutputCustomPrompt, true)"
        >
          <svg-icon
            class="mr-1"
            [src]="'/assets/img/icon-edit-chat.svg'"
          ></svg-icon>
          {{ 'Tactics.Prompt.Edit' | translate }}
        </button>
        <button
          *ngIf="isEditValueShowBtn"
          type="button"
          class="rounded-lg text-center tui-text_body-m-14 btn-helper"
          tuiButton
          appearance="outline"
          size="s"
          (click)="cancelMessage(true)"
        >
          {{ 'Tactics.Prompt.Cancel' | translate }}
        </button>
        <button
          *ngIf="!isEditValueShowBtn && (customPromptId === null || customPromptId === undefined)"
          type="button"
          class="rounded-lg text-center tui-text_body-m-14 btn-helper"
          tuiButton
          appearance="primary"
          size="s"
          (click)="save()"
          [disabled]="isDisabledBtn"
        >
          <svg-icon
            class="mr-1"
            [src]="'/assets/img/icon-save-chat.svg'"
          ></svg-icon>
          {{ 'Tactics.Prompt.Save' | translate }}
        </button>
        <button
          *ngIf="!isEditValueShowBtn && customPromptId !== null && customPromptId !== undefined"
          type="button"
          class="rounded-lg text-center tui-text_body-m-14 btn-helper"
          tuiButton
          appearance="primary"
          size="s"
          (click)="onSubmitEditMessage()"
          [disabled]="fullOutputCustomPrompt === originalFullOutputCustomPrompt ? true : false"
        >
          <svg-icon
            class="mr-1"
            [src]="'/assets/img/icon-save-chat.svg'"
          ></svg-icon>
          {{ 'Tactics.Prompt.Save' | translate }}
        </button>
        <button
          *ngIf="isEditValueShowBtn"
          (click)="onUpdate('edit-value-form', true)"
          class="rounded-lg text-center tui-text_body-m-14 btn-helper"
          tuiButton
          appearance="primary"
          size="s"
          [disabled]="editFormText === editForm?.get('message')?.value ? true : false"
        >
          {{ 'Tactics.Prompt.Update' | translate }}
        </button>
      </div>
    </div>
    <ng-container *ngIf="messages.length > 0">
      <ng-container *ngFor="let message of messages; let i = index">
        <div
          class="p-6 flex items-center rounded-2xl flex-wrap md:flex-nowrap"
          [ngClass]="{ 'bg-[#F6F6F6]': message.role === 'user' }"
        >
          <svg-icon
            *ngIf="message.role === 'assistant'"
            class="mr-4 self-start"
            [src]="'/assets/img/icon-robot-chat.svg'"
          ></svg-icon>
          <svg-icon
            *ngIf="message.role === 'user'"
            class="mr-4 self-start"
            [src]="'/assets/img/ic_you.svg'"
          ></svg-icon>
          <div
            *ngIf="message.content && message.role === 'user'"
            class="font-medium text-[#191c31e6] text-base max-w-[calc(100%-64px)] md:max-w-none"
            [innerText]="message.content"
          ></div>
          <div
            *ngIf="
              message.content && !mapEditMessageChat.has(i) && message.role === 'assistant' && !mapEditMessage.has(i)
            "
            class="font-medium text-[#191c31e6] text-base max-w-[calc(100%-64px)] md:max-w-none"
            [innerText]="message.content"
          ></div>
          <div
            *ngIf="
              message.content && !mapEditMessageChat.has(i) && message.role === 'assistant' && mapEditMessage.has(i)
            "
            class="font-medium text-[#191c31e6] text-base max-w-[calc(100%-64px)] md:max-w-none"
            [innerText]="mapEditMessage.get(i)"
          ></div>
          <div
            class="font-medium text-[#191c31e6] text-base mr-4 flex-1"
            *ngIf="message.role === 'assistant'"
          >
            <form
              *ngIf="isEditMessageChat && mapEditMessageChat.has(i)"
              [formGroup]="chatFormEdit"
              id="chatFormEdit"
            >
              <tui-text-area
                class="mb-0 pr-12 min-h-[30rem]"
                formControlName="message"
                [tuiTextfieldLabelOutside]="true"
              >
                {{ 'Tactics.Prompt.Ask anything' | translate }}
              </tui-text-area>
            </form>
          </div>

          <div
            *ngIf="null !== message"
            class="flex items-center gap-2 self-start mt-4 ml-auto md:ml-2 md:mt-0"
          >
            <button
              *ngIf="
                message.role === 'assistant' &&
                !isEditMessageChat &&
                message.content &&
                !mapEditMessage.has(i) &&
                !mapEditMessageChat.has(i)
              "
              type="button"
              class="rounded-lg text-center tui-text_body-m-14 btn-helper"
              tuiButton
              appearance="outline"
              size="s"
              (click)="editMessageChat(i, message.content)"
            >
              <svg-icon
                class="mr-1"
                [src]="'/assets/img/icon-edit-chat.svg'"
              ></svg-icon>
              {{ 'Tactics.Prompt.Edit' | translate }}
            </button>
            <button
              *ngIf="
                message.role === 'assistant' &&
                !isEditMessageChat &&
                message.content &&
                mapEditMessage.has(i) &&
                !mapEditMessageChat.has(i)
              "
              type="button"
              class="rounded-lg text-center tui-text_body-m-14 btn-helper"
              tuiButton
              appearance="outline"
              size="s"
              (click)="editMessageChat(i, mapEditMessage.get(i)!)"
            >
              <svg-icon
                class="mr-1"
                [src]="'/assets/img/icon-edit-chat.svg'"
              ></svg-icon>
              {{ 'Tactics.Prompt.Edit' | translate }}
            </button>
            <button
              *ngIf="message.role === 'assistant' && isEditMessageChat && mapEditMessageChat.has(i)"
              type="button"
              class="rounded-lg text-center tui-text_body-m-14 btn-helper"
              tuiButton
              appearance="outline"
              size="s"
              (click)="cancelMessageChat()"
            >
              {{ 'Tactics.Prompt.Cancel' | translate }}
            </button>
            <button
              *ngIf="
                message.role === 'assistant' &&
                !isEditMessageChat &&
                (id === null || id === undefined) &&
                !mapEditMessage.has(i) &&
                !mapEditMessageChat.has(i) &&
                !isRefreshValue
              "
              type="button"
              class="rounded-lg text-center tui-text_body-m-14 btn-helper"
              tuiButton
              appearance="primary"
              size="s"
              (click)="save(message.content)"
              [disabled]="isDisabledBtn"
            >
              <svg-icon
                class="mr-1"
                [src]="'/assets/img/icon-save-chat.svg'"
              ></svg-icon>
              {{ 'Tactics.Prompt.Save' | translate }}
            </button>
            <button
              *ngIf="
                message.role === 'assistant' &&
                !isEditMessageChat &&
                (id === null || id === undefined) &&
                mapEditMessage.has(i) &&
                !mapEditMessageChat.has(i) &&
                !isRefreshValue
              "
              type="button"
              class="rounded-lg text-center tui-text_body-m-14 btn-helper"
              tuiButton
              appearance="primary"
              size="s"
              (click)="save(mapEditMessage.get(i))"
              [disabled]="isDisabledBtn"
            >
              <svg-icon
                class="mr-1"
                [src]="'/assets/img/icon-save-chat.svg'"
              ></svg-icon>
              {{ 'Tactics.Prompt.Save' | translate }}
            </button>
            <button
              *ngIf="
                message.role === 'assistant' &&
                !isEditMessageChat &&
                id !== null &&
                id !== undefined &&
                !mapEditMessageChat.has(i) &&
                !isRefreshValue
              "
              type="button"
              class="rounded-lg text-center tui-text_body-m-14 btn-helper"
              tuiButton
              appearance="primary"
              size="s"
              (click)="onSubmitEditMessage()"
              [disabled]="isDisabledBtn"
            >
              <svg-icon
                class="mr-1"
                [src]="'/assets/img/icon-save-chat.svg'"
              ></svg-icon>
              {{ 'Tactics.Prompt.Save' | translate }}
            </button>
            <button
              *ngIf="
                message.role === 'assistant' &&
                !isEditMessageChat &&
                isRefreshValue &&
                !mapEditMessage.has(i) &&
                !mapEditMessageChat.has(i)
              "
              type="button"
              class="rounded-lg text-center tui-text_body-m-14 btn-helper"
              tuiButton
              appearance="primary"
              size="s"
              (click)="onSubmitEditMessage(message.content)"
              [disabled]="isDisabledBtn"
            >
              <svg-icon
                class="mr-1"
                [src]="'/assets/img/icon-save-chat.svg'"
              ></svg-icon>
              {{ 'Tactics.Prompt.Save' | translate }}
            </button>
            <button
              *ngIf="
                message.role === 'assistant' &&
                !isEditMessageChat &&
                isRefreshValue &&
                isRefreshValueEdit &&
                mapEditMessage.has(i) &&
                !mapEditMessageChat.has(i)
              "
              type="button"
              class="rounded-lg text-center tui-text_body-m-14 btn-helper"
              tuiButton
              appearance="primary"
              size="s"
              (click)="onSubmitEditMessage()"
              [disabled]="isDisabledBtn"
            >
              <svg-icon
                class="mr-1"
                [src]="'/assets/img/icon-save-chat.svg'"
              ></svg-icon>
              {{ 'Tactics.Prompt.Save' | translate }}
            </button>
            <button
              *ngIf="message.role === 'assistant' && isEditMessageChat && mapEditMessageChat.has(i)"
              form="chatFormEdit"
              class="rounded-lg text-center tui-text_body-m-14 btn-helper"
              (click)="onUpdate('edit-chat-form', false, i)"
              tuiButton
              appearance="primary"
              size="s"
              [disabled]="chatEditFormText === chatFormEdit.get('message')?.value ? true : false"
            >
              {{ 'Tactics.Prompt.Update' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div
      class="p-6 flex items-center rounded-2xl"
      *ngIf="loading"
    >
      <svg-icon
        class="mr-4 self-start"
        [src]="'/assets/img/icon-robot-chat.svg'"
      ></svg-icon>
      <div class="container flex mr-16">
        <svg
          class="spinner"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1391_4532"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="-1"
            y="0"
            width="25"
            height="24"
          >
            <circle
              cx="11.9756"
              cy="12"
              r="12"
              fill="#44546F"
            />
          </mask>
          <g mask="url(#mask0_1391_4532)">
            <path
              d="M10.0439 0.122088C10.2097 0.0883957 10.3729 0.0514251 10.5394 0.0213764C11.2304 -0.0962908 11.811 0.286585 11.9581 0.954907C12.1015 1.60501 11.7575 2.18341 11.0751 2.35246C9.91178 2.63825 8.75938 2.95027 7.73209 3.59166C4.55206 5.57753 2.74593 8.41496 2.55321 12.2393C2.5378 12.5327 2.53754 12.8269 2.52971 13.1207C2.50662 13.8748 2.03913 14.3626 1.3371 14.3611C0.687745 14.3602 0.203516 13.861 0.165391 13.1288C0.085439 11.6461 0.272926 10.1869 0.741405 8.79013C2.28004 4.2027 5.42304 1.35159 10.0439 0.122088Z"
              fill="#191C30"
              fill-opacity="0.9"
            />
          </g>
        </svg>
      </div>
    </div>
  </tui-scrollbar>
</div>

<div
  class="p-6 mt-auto"
  *ngIf="loadingCategory && !isEditValue"
>
  <div class="bg-[#EAEDFF] p-2 rounded-lg relative h-box-shadow">
    <form
      *ngIf="resetForm"
      [formGroup]="chatForm"
      (ngSubmit)="onFormSubmit()"
    >
      <tui-text-area
        class="mb-0 pr-12"
        formControlName="message"
        [tuiTextfieldLabelOutside]="true"
        (keydown.enter)="chatForm.valid && onPressEnter($event)"
      >
        Ask anything
      </tui-text-area>
      <button
        type="send"
        tuiIconButton
        appearance="none"
        size="xs"
        class="absolute bottom-4 right-6 btn-send z-10"
        [disabled]="chatForm.invalid"
      >
        <svg-icon
          class="mr-1"
          [src]="'/assets/img/icon-send.svg'"
        ></svg-icon>
      </button>
    </form>

    <tui-scrollbar
      class="min-h-[calc(100%-70vh-215px)] bg-white"
      *ngIf="!resetForm"
    >
      <form
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
      >
        <div
          class="bg-white font-medium text-[#1b1f3ba6] text-base rounded-lg p-4 pr-12 mt-auto"
          *ngIf="customPromptFields.length > 0 && promptTextArray.length > 0 && loadingCustomPromptData"
        >
          <ng-container *ngFor="let field of formDataMapVal; let index = index">
            {{ promptTextArray[index] }}
            <tui-input
              *ngIf="field.fieldType === 'input'"
              formControlName="{{ field.name }}"
              class="custom-input inline-block font-medium text-base text-[#191c30e6] placeholder-[#1b1f3b66] focus:border-[#2E4FFF] mb-1"
              [tuiTextfieldLabelOutside]="true"
              tuiHintContent="{{ field.helpText }}"
              >{{ field.label }}</tui-input
            >

            <tui-select
              *ngIf="field.fieldType === 'select' && field?.optionValues"
              formControlName="{{ field.name }}"
              class="custom-input outline-none inline-block h-[32px] px-1 border-b-2 border-[#E0E0E0] border-solid border-l-0 border-r-0 border-t-0 font-medium text-base text-[#191c30e6] placeholder-[#1b1f3b66] focus:border-[#2E4FFF]"
              [tuiTextfieldLabelOutside]="true"
              tuiHintContent="{{ field.helpText }}"
            >
              <tui-data-list-wrapper
                *tuiDataList
                [items]="field?.optionValues"
              ></tui-data-list-wrapper>
            </tui-select>
          </ng-container>
          <ng-container *ngIf="promptTextArray.length > formDataMap.size">
            {{ promptTextArray[promptTextArray.length - 1] }}
          </ng-container>
        </div>
        <div
          class="bg-white font-medium text-[#1b1f3ba6] text-base rounded-lg p-4 pr-12"
          *ngIf="customPromptFields && promptTextArray.length === 0"
          [innerHTML]="promptText"
        ></div>
        <button
          type="send"
          tuiIconButton
          appearance="none"
          size="xs"
          class="absolute bottom-4 right-6 btn-send z-10"
          [disabled]="isDisabledBtnCustomPrompt"
        >
          <svg-icon
            class="mr-1"
            [src]="'/assets/img/icon-send.svg'"
          ></svg-icon>
        </button>
      </form>
    </tui-scrollbar>
  </div>
</div>
