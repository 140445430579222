import { gql } from 'apollo-angular';
import { AssetFragment } from '@modules/tactics/shared/graphql/fragments/asset.fragment';

export const PrepareActivityAssetDocument = gql`
  mutation prepareActivityAsset($input: AddAssetInputGraphql!) {
    prepareActivityAsset(input: $input) {
      ...AssetFragment
    }
  }
  ${AssetFragment}
`;
