<div
  class="asset-data relative flex flex-row flex-wrap md:flex-nowrap justify-between border border-solid border-[#E0E0E0] py-3 pl-3 pr-2.5 mb-1 rounded-lg cursor-pointer items-center h-full h-box-shadow-box"
  [title]="asset.asset.placement.name"
  (click)="contentGenerateMode ? null : openAssetPreview()"
>
  <div class="asset-data__img mr-2 flex flex-col justify-center items-center">
    <svg-icon
      [src]="Config.ASSETS + asset.asset.platform.image!"
      [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
      *ngIf="asset.asset.platform"
    ></svg-icon>
  </div>

  <div class="flex flex-col justify-center w-3/4">
    <span class="asset-data__oneline tui-text_body-m-14 tui-text-01">
      {{ asset.asset.placement.name }}
    </span>
    <span class="asset-data__resolution asset-data__oneline">
      <ng-container *ngFor="let resolution of asset.selectedResolutions">
        {{ resolution.width }}x{{ resolution.height }}px,</ng-container
      >
      {{ asset.asset.fileType | or: asset.asset.headline | or: asset.asset.primaryText }}
    </span>
  </div>
  <!-- buttons -->
  <div
    *ngIf="contentGenerateMode"
    class="h-hover-effects md:absolute md:right-0 md:top-0 md:left-0 md:bottom-0 flex flex-row justify-end items-center flex-shrink-0 w-full md:w-auto mt-2 md:mt-0 duration-200 buttons-hidden"
  >
    <!-- content generate -->
  </div>
</div>
