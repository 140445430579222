import { gql } from 'apollo-angular';

export const ReorderActivitiesDocument =
  gql(/* GraphQL*/ `mutation reorderActivities($tacticId: Int!, $positions: [ReorderActivityInputGraphql!]!) {
  reorderActivities(tacticId: $tacticId, positions: $positions) {
    id
    position
  }
}
`);
